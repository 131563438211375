import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import React from 'react';

interface Props {
  onClose(): void;
}

export const DataPortalCreatePostModal: React.FC<Props> = ({
  onClose,
}: Props): React.ReactElement => {
  return (
    <Modal.Content title="Sorry, feature not available">
      <div className="w-modal-sm mt-6">
        <p>
          This is a data portal. Users are not allowed to post their own content here. If you want
          to share your thoughts please visit{' '}
          <a href="https://capnote.com" className="text-blue">
            capnote.com
          </a>
        </p>
        <div className="mt-6 flex justify-end">
          <Button type="button" variant="secondary" label="Close" onClick={onClose} />
        </div>
      </div>
    </Modal.Content>
  );
};
