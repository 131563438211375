import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { CountriesTable } from 'views/DataAnalysis/Country/countriesTable';

const Countries: React.FC = () => {
  useEffect(() => {
    mixpanel.track('PageView D&A Country List');
  }, []);

  return (
    <MainOutletContent>
      <Breadcrumbs path={['DATA & ANALYSIS', 'COUNTRY']} />
      <TextLine content="Country pages are coming soon! The below table shows some information but is not yet clickable." />
      <div className="h-2" />

      <Tabs shrinkFactor={6} variant={TabsVariant.buttons}>
        <Tab label="All Countries" element={<CountriesTable tab={DataAndAnalysisMainTabs.All} />} />
        <Tab
          label="Popular Countries"
          element={<CountriesTable tab={DataAndAnalysisMainTabs.Popular} />}
          path="popular"
        />
      </Tabs>
    </MainOutletContent>
  );
};

export default Countries;
