import React from 'react';
import { PortfolioArtVideoItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioArtVideoItem;
}

export const VideoArtItemForm: React.FC<Props> = ({ item: _ }: Props): React.ReactElement => {
  return <></>;
};
