import { Dispatch } from '@reduxjs/toolkit';
import { RECORD_SEPARATOR } from 'assets/constants/recordSeparator';
import SpinnerLoader from 'components/spinnerLoader';
import { levelRank } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { Level } from 'components/table/types';
import { Tabs } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { FilterValues } from 'hooks/useFilters';
import { useSort } from 'hooks/useSort';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { KeyFactor, KeyFactorType, loadingSelector } from 'redux/reducers/keyFactorsReducer';
import { ApplicationState } from 'redux/reducers/store';
import { FactorPath } from 'redux/services/keyFactorsService';
import { OrganizationDetails } from 'types/organization/types';
import { KeyFactors } from 'views/DataAnalysis/common/KeyFactors';
import { IndustryDetails } from 'views/DataAnalysis/Industry/types';

interface Props {
  readonly paramsKey: 'industryId' | 'companyId';

  dataSelector(state: ApplicationState): readonly KeyFactor[];
  detailsSelector(state: ApplicationState): IndustryDetails | OrganizationDetails | undefined;
  service(id: string, path: FactorPath): (dispatch: Dispatch) => void;
}

export const KeyFactorsSection: React.FC<Props> = ({
  dataSelector,
  detailsSelector,
  service,
  paramsKey,
}: Props): React.ReactElement => {
  const { pathname } = useLocation();
  const [filteredAndSortedData, setFilteredAndSortedData] = useState<readonly KeyFactor[]>([]);

  const loading = useSelector(loadingSelector);
  const factorsData = useSelector(dataSelector);
  const details = useSelector(detailsSelector);

  const [search] = useSearchParams();
  const dispatch = useDispatch<any>();

  const factorType = useMemo((): KeyFactorType | null => {
    if (pathname.endsWith('risk')) {
      return 'risk';
    } else if (pathname.endsWith('growth')) {
      return 'growth';
    } else {
      return null;
    }
  }, [pathname]);

  const [sorting, handleSortingChange] = useSort<KeyFactor>('level', SortOrder.descending);
  const { [paramsKey]: objectId } = useParams();

  const filters = useMemo((): readonly FilterValues[] => {
    return Array.from(search.entries())
      .filter(([key]: [string, string]): boolean =>
        ['type', 'impact', 'probability', 'level'].includes(key),
      )
      .map(
        ([key, value]: [string, string]): FilterValues => ({
          key: key,
          values: value.split(RECORD_SEPARATOR).map(decodeURIComponent),
        }),
      );
  }, [search]);

  useEffect((): void => {
    if (!objectId) {
      return;
    }

    switch (factorType) {
      case 'risk':
        dispatch(service(objectId, 'krf'));
        break;
      case 'growth':
        dispatch(service(objectId, 'kgf'));
        break;
    }
  }, [dispatch, factorType, objectId, service]);

  useEffect((): void => {
    // FIXME: interesting case :/
    const filterEntry = Object.entries(sorting)[0];
    const filteredAndSortedData = factorsData
      .filter((keyFactor: KeyFactor): boolean => {
        return filters.every((filterItem: FilterValues): boolean => {
          const { values } = filterItem;
          if (values === null || values.length === 0) {
            return true;
          }
          const filterKey = filterItem.key;
          const keyFactorValue = ((): string => {
            switch (filterKey) {
              case 'impact':
              case 'level':
              case 'probability':
                return keyFactor[filterKey] as string;
              case 'type':
                return keyFactor.name;
              default:
                throw new Error(`cannot filter by column: ${filterKey}`);
            }
          })();

          return values.includes(keyFactorValue);
        });
      })
      .sort((keyFactor1: KeyFactor, keyFactor2: KeyFactor): number => {
        const value = ((): number => {
          const sortKey = filterEntry[0];
          switch (sortKey) {
            case 'impact':
            case 'level':
            case 'probability':
              return levelRank(keyFactor1[sortKey]) - levelRank(keyFactor2[sortKey]);
            case 'name':
              return keyFactor1['name'].localeCompare(keyFactor2['name']);
            default:
              throw new Error(`cannot sort by column: ${sortKey}`);
          }
        })();
        const sign = filterEntry[1] === SortOrder.descending ? -1 : 1;

        return value * sign;
      });

    setFilteredAndSortedData(filteredAndSortedData);
  }, [factorsData, filters, sorting]);

  const averageRisk = useMemo((): Level => {
    if (!details) {
      return Level.average;
    }

    if ('average_risk_rating' in details) {
      return details.average_risk_rating;
    } else if ('average_risk_profile' in details) {
      return details.average_risk_profile;
    } else {
      return Level.average;
    }
  }, [details]);

  const averageGrowth = useMemo((): Level => {
    if (!details) {
      return Level.average;
    }

    if ('average_growth_rating' in details) {
      return details.average_growth_rating;
    } else if ('average_growth_profile' in details) {
      return details.average_growth_profile;
    } else {
      return Level.average;
    }
  }, [details]);

  return (
    <div className="relative flex flex-col flex-1">
      <Tabs shrinkFactor={3}>
        <Tab
          label="Risk Factors"
          element={
            <KeyFactors
              keyFactors={filteredAndSortedData}
              factorType="risk"
              average={averageRisk}
              sorting={sorting}
              onSort={handleSortingChange}
            />
          }
          path="risk"
        />
        <Tab
          label="Growth Factors"
          element={
            <KeyFactors
              keyFactors={filteredAndSortedData}
              factorType="growth"
              average={averageGrowth}
              sorting={sorting}
              onSort={handleSortingChange}
            />
          }
          path="growth"
        />
      </Tabs>
      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default KeyFactors;
