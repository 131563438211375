import SVGIcon from 'components/icons/SVGIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { financialQuotesSelector, MarketEntry } from 'redux/reducers/sharedReducer';

export const FinancialQuotes: React.FC = (): React.ReactElement | null => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const financialQuotes = useSelector(financialQuotesSelector);
  const items = useMemo((): MarketEntry[] => financialQuotes.data, [financialQuotes.data]);

  useEffect((): void | VoidFunction => {
    if (element === null || element.offsetWidth === 0) {
      return;
    }

    const parent = element.parentElement;
    if (parent === null) {
      return;
    }

    // FIXME: Ideally use a resize observer here
    const copiesCount = Math.ceil(window.innerWidth / element.offsetWidth) + 1;
    const duration = 120;

    element.style.animationDuration = `${duration}s`;
    for (let i = 0; i < copiesCount; i++) {
      parent.append(element.cloneNode(true));
    }

    const moveElementBack = (): void => {
      setTimeout((): void => {
        const firstChild = parent.firstElementChild;
        if (firstChild !== null) {
          parent.append(firstChild);
        }
      }, 5000);
    };

    element.addEventListener('animationend', moveElementBack);
    return (): void => {
      element.removeEventListener('animationend', moveElementBack);
      for (const child of Array.from(parent.children)) {
        if (child === element) {
          continue;
        }

        child.remove();
      }
    };
  }, [element, items.length]);

  return (
    <div className="flex items-center max-w-full overflow-x-hidden bg-gray-darkest h-6">
      <div ref={setElement} className={containerClass}>
        {items.map((item: MarketEntry): React.ReactElement => {
          const type = item?.change >= 0 ? 'increase' : 'decrease';

          return (
            <div key={item.name} className={itemClass}>
              <span>{item.name}</span>
              <span>{priceFormatter.format(item.price)}</span>
              <span className={changeClass[type]}>{changeFormatter.format(item.change)}</span>

              <SVGIcon name={`${type}-icon`} className={iconClass[type]} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const changeClass = {
  increase: 'text-green',
  decrease: 'text-red',
};

const iconClass = {
  increase: 'fill-current text-green w-3 h-3',
  decrease: 'fill-current text-red w-3 h-3',
};

const priceFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const changeFormatter = new Intl.NumberFormat(undefined, {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const containerClass =
  'relative flex items-center text-white leading-6 text-xs xs:text-xxs font-poppins marquee';
const itemClass = 'flex items-center font-poppinsSemiBold uppercase whitespace-nowrap gap-2 px-2';
