import React from 'react';
import { Industry } from 'redux/reducers/smartToolsRecentSearchesReducer';

interface Props {
  readonly industry: Industry;
}

export const IndustryItemRenderer: React.FC<Props> = ({ industry }: Props): React.ReactElement => {
  return (
    <div>
      <p className="block">{industry.name}</p>
      <p className="text-xs text-gray block">Industry</p>
    </div>
  );
};
