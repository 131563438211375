import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useScrollEffect = (
  viewport: HTMLDivElement | null,
  effect: (viewport: HTMLDivElement) => void,
  debounceAmount = 50,
): void => {
  const [scroller, setScroller] = useState<HTMLElement | null>(null);

  const run = useCallback((): void => {
    if (scroller !== null && scroller instanceof HTMLDivElement) {
      effect(scroller);
    }
  }, [effect, scroller]);

  useEffect((): void => {
    if (viewport === null) {
      return;
    }

    const scroller = getScrollerParent(viewport);
    if (scroller === null) {
      return;
    }

    setScroller(scroller);
  }, [viewport]);

  useEffect((): VoidFunction | void => {
    let timeout = setTimeout(noop, 0);
    if (scroller === null) {
      return;
    }

    const onScroll = (): void => {
      clearTimeout(timeout);

      timeout = setTimeout((): void => {
        window.requestAnimationFrame(run);
      }, debounceAmount);
    };

    scroller.addEventListener('scroll', onScroll);
    return (): void => {
      scroller.removeEventListener('scroll', onScroll);
    };
  }, [debounceAmount, run, scroller]);
};

const getScrollerParent = (element: HTMLElement | null): HTMLElement | null => {
  if (element === null) {
    return null;
  }

  const parent = element.parentElement;
  if (parent === null) {
    return null;
  }

  const style = getComputedStyle(parent);
  if (
    style.overflow === 'auto' ||
    style.overflowY === 'auto' ||
    style.overflow === 'scroll' ||
    style.overflowY === 'scroll'
  ) {
    return parent;
  }

  return getScrollerParent(parent.parentElement);
};
