import { AmountInput } from 'components/amountInput';
import { Select } from 'components/select';
import React from 'react';
import { PortfolioCommodityItem, TypeOfCommodity } from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCommodityItem;
}

export const CommodityItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCommodityItem>();

  return (
    <>
      <Select
        items={typesOfCommodities}
        value={item.typeOfCommodity}
        label="Type of Commodity"
        onChange={updaterFor('typeOfCommodity')}
      />
      <AmountInput
        name="pruchasedAmount"
        value={item.amount}
        label="Amount of purchase"
        currency={item.currency}
        onCurrencyChange={updaterFor('currency')}
        onValueChange={updaterFor('amount')}
      />
    </>
  );
};

const typesOfCommodities = enumToSelectItems<TypeOfCommodity>(TypeOfCommodity, {
  [TypeOfCommodity.gold]: 'Gold',
  [TypeOfCommodity.silver]: 'Silver',
  [TypeOfCommodity.otherPreciousMetal]: 'Other Precious Metal',
});
