import { Clickable } from 'components/clickable';
import { HeaderMenuItem } from 'components/header/LandingPageHeader/menuItem';
import SVGIcon from 'components/icons/SVGIcon';
import Popup from 'components/popup';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FeatureKey, landingPageSelector } from 'redux/reducers/landingPageReducer';

interface Props {
  readonly selected: boolean;

  onFeatureClick(feature: FeatureKey, block: 'start' | 'center'): void;
}

export const FeaturesButton: React.FC<Props> = ({
  selected,
  onFeatureClick,
}: Props): React.ReactElement => {
  const { featuresList } = useSelector(landingPageSelector);

  const handleFeatureClick = useCallback(
    (key: FeatureKey): void => {
      onFeatureClick(key, 'start');
    },
    [onFeatureClick],
  );

  return (
    <Popup>
      <Popup.Button>
        <div className="group rounded-md inline-flex items-center text-md focus:outline-none text-gray cursor-pointer">
          <HeaderMenuItem label="Features" selected={selected} />
          <SVGIcon name="down-arrow" className="pl-3 focus:outline-none fill-current h-3 w-full" />
        </div>
      </Popup.Button>

      <Popup.Content>
        <div className="bg-gray-light py-3 shadow-lg rounded w-72">
          {featuresList.map(item => {
            return (
              <Clickable
                key={item.id}
                clickData={item.id}
                stopPropagation={false}
                onClick={handleFeatureClick}
              >
                <div className="text-left text-md font-poppins text-gray capitalize cursor-pointer py-2 px-5 hover:bg-gray-medium w-full">
                  {item.label}
                </div>
              </Clickable>
            );
          })}
        </div>
      </Popup.Content>
    </Popup>
  );
};
