import 'components/spinnerLoader/SpinnerLoader.scss';

import React, { CSSProperties, useMemo } from 'react';

interface Props {
  readonly borderRadius?: number;
  readonly classes?: string;
  readonly innerCssClass?: string;

  readonly visible: boolean;
}

const SpinnerLoader: React.FC<Props> = ({
  borderRadius,
  classes = 'mt-0 absolute z-0',
  innerCssClass = 'h-12 w-12',
  visible,
}: Props): React.ReactElement | null => {
  const containerStyle = useMemo(
    (): CSSProperties | undefined => (borderRadius ? { borderRadius } : undefined),
    [borderRadius],
  );

  const containerClassName = useMemo((): string => `loader-container ${classes}`, [classes]);

  const progressBarClassName = useMemo((): string => `loader ${innerCssClass}`, [innerCssClass]);

  if (!visible) {
    return null;
  }

  return (
    <div className={containerClassName} style={containerStyle}>
      <div className={progressBarClassName} role="progressbar" />
    </div>
  );
};

export default SpinnerLoader;
