import { Breadcrumbs } from 'components/breadcrumbs';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import React from 'react';
import Overview from 'views/Connections/Overview';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { NotificationsRightBar } from 'views/Home/components/notificationsRightBar';

const Connections: React.FC = (): React.ReactElement => {
  return (
    <div className="flex flex-grow relative gap-3 pr-3 pb-3 min-h-full">
      <div className="relative bg-white rounded p-3 flex-grow min-w-0">
        <div className="flex flex-col mb-3 justify-between items-start">
          <div className="flex-1">
            <Breadcrumbs path={['CONNECTIONS']} />
            <TextLine content="" />
          </div>
        </div>

        <Tabs variant={TabsVariant.buttons}>
          <Tab label="Overview" element={<Overview />} path="overview/*" />
          <Tab label="Teammates" element={null} path="teammates" comingSoon />
          <Tab label="Network" element={null} path="network" comingSoon />
          <Tab label="Groups" element={null} path="groups" comingSoon />
        </Tabs>
      </div>

      <NotificationsRightBar />
    </div>
  );
};

export default Connections;
