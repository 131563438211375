import { CommonFilter } from 'components/filters/helpers';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const badFilterConfig: readonly CommonFilter[] = [
  {
    name: 'entity_name',
    type: 'local',
    label: 'Entity Name',
    options: [],
  },
];

const createFiltersConfig = (node: ValueChainNode | undefined): readonly CommonFilter[] => {
  const { ownerOrganization: parent } = node ?? {};
  if (node === undefined) {
    return badFilterConfig;
  }

  const { entityType } = node ?? {};

  const type = entityType.replaceAll('_', '-');
  const id =
    entityType === ValueChainEntityType.company || entityType === ValueChainEntityType.industry
      ? node.entityId
      : parent?.entityId;

  if (id === undefined) {
    return [];
  }

  return [
    {
      name: 'entity_name',
      type: 'backend',
      label: 'Entity Name',
      url: `${API_V1_PATH}/filters/value-chain/entity-name/${id}/${type}`,
    },
    {
      name: 'country_of_domicile',
      type: 'backend',
      label: 'Country Of Domicile',
      url: `${API_V1_PATH}/filters/value-chain/country-of-domicile/${id}/${type}`,
    },
  ];
};

export default createFiltersConfig;
