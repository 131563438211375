import { CommonViewHeader } from 'components/commonViewHeader';
import React, { useMemo } from 'react';

interface Props {
  readonly category: 'company' | 'industry' | 'indicator' | 'country' | 'peer group';

  readonly name?: string;
  readonly description?: string;
  readonly tags?: readonly string[];

  onShowDetails(): void;
  onTagClick?(tag: string): void;
}

export const OverviewHeader: React.FC<Props> = ({
  category,
  name,
  description,
  tags,
  onShowDetails,
  onTagClick,
}: Props): React.ReactElement => {
  const breadcrumbs = useMemo(
    (): readonly string[] => [
      'DATA & ANALYSIS',
      category.toUpperCase(),
      name?.toUpperCase() ?? 'NO NAME PROVIDED',
    ],
    [category, name],
  );

  return (
    <div className="flex-1 h-20">
      <CommonViewHeader
        path={breadcrumbs}
        description={description ?? 'No description provided'}
        tags={tags}
        onExpand={onShowDetails}
        onTagClick={onTagClick}
      />
    </div>
  );
};
