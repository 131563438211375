import Button from 'components/buttons/Button/Button';
import React from 'react';

interface Props {
  readonly isSelected: boolean;
  readonly label: string;

  onClick(): void;
}

export const FeatureButton: React.FC<Props> = ({
  isSelected,
  label,
  onClick,
}: Props): React.ReactElement => {
  return (
    <Button
      useClassNameOnly={!isSelected}
      width="w-1/2 xl:w-1/5"
      rounded="rounded-sm"
      margin="m-1"
      truncate
      className={buttonClasses.join(' ')}
      label={label}
      onClick={onClick}
    />
  );
};

const buttonClasses = [
  'w-1/2',
  'xl:w-1/5',
  'h-10',
  'bg-white',
  'border',
  'border-blue',
  'text-blue',
  'transition-colors',
  'flex',
  'items-center',
  'justify-center',
  'font-poppins',
  'focus:outline-none',
  'uppercase',
  'text-base',
  'lg:text-md',
  'p-2',
  'hover:bg-blue',
  'hover:text-white',
  'rounded-sm',
  'm-1',
  'truncate',
];
