import Input from 'components/input/Input2';
import SpinnerLoader from 'components/spinnerLoader';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { Comment, FinancialCommentsError } from 'redux/reducers/financialCommentsReducer';
import { CommentsPopupCommentList } from 'views/DataAnalysis/common/commentsPopupCommentList';

interface Props {
  readonly comments: Comment[];
  readonly loading: boolean;
  readonly error: FinancialCommentsError | string | null;

  onAddComment?(text: string): void;
}

const CommentsPopupContent: React.FC<Props> = ({ comments, error, loading, onAddComment }) => {
  const [text, setText] = useState('');

  const handleSend = async (): Promise<void> => {
    const trimmedText = text.trim();
    if (trimmedText.length > 0) {
      onAddComment?.(text);
      setText('');
    }
  };

  const errorComponent = useMemo((): React.ReactElement | null => {
    if (error === null) {
      return null;
    } else if (typeof error === 'string') {
      return <div>{error}</div>;
    } else {
      if (error.code === 406) {
        return (
          <div className="text-xs font-poppins text-red">
            You have used reserved or inappropriate words
          </div>
        );
      } else {
        return null;
      }
    }
  }, [error]);

  return (
    <div className="flex flex-col relative p-6 bg-gray-light w-96 rounded-md shadow-md">
      <div className="relative min-h-popup-md max-h-popup-md scroller">
        <h4 className="text-gray-darkest mb-4">COMMENTS</h4>
        <CommentsPopupCommentList comments={comments} loading={loading} />
      </div>
      {errorComponent}
      <Input
        id="new-comment-input"
        inputClassName="pl-3 py-3 bg-transparent"
        placeholder="Add comment"
        showLabel={false}
        name="add_comment"
        onChange={({ target: { value } }) => setText(value)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === 'Return') {
            void handleSend();
          }
        }}
        value={text}
      >
        <button
          id="post-comment-button"
          onClick={handleSend}
          type="button"
          className="px-4 py-2 text-blue font-poppinsSemiBold uppercase text-sm focus:outline-none focus:opacity-50"
        >
          Post
        </button>
      </Input>
      <SpinnerLoader borderRadius={5} visible={loading} />
    </div>
  );
};
export default CommentsPopupContent;
