import { ReactComponent as NoCompaniesIcon } from 'assets/icons/svg/no-companies-icon.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import EmptySection from 'components/emptySection/EmptySection';
import { MainOutletContent } from 'components/mainOutletContent';
import { OrganizationSelectionHeading } from 'components/organizationSelectionHeading';
import ValueChainMain from 'components/valueChain/valueChainMain';
import { useSearchInputOrganization } from 'hooks/useSearchInputOrganization';
import { noop } from 'lodash';
import React, { useMemo } from 'react';
import { OrganizationTypes } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';

export const ValueChains: React.FC = (): React.ReactElement => {
  const currentOrganization = useSearchInputOrganization();

  const rootNode = useMemo((): ValueChainNode | null => {
    return currentOrganization === null
      ? null
      : {
          label: currentOrganization.name,
          entityId: currentOrganization.id,
          entityType: valueChainEntityTypeFromOrganizationType[currentOrganization.type],
          count: null,
          ownerOrganization: null,
        };
  }, [currentOrganization]);

  const tags = React.useMemo(
    (): readonly string[] =>
      currentOrganization?.tags ?? [
        'Supply chain',
        'Customers',
        'Investors',
        'Competitors',
        'Industries',
        'Sales geographies',
        'Subsidiaries',
      ],
    [currentOrganization?.tags],
  );

  return (
    <MainOutletContent>
      <OrganizationSelectionHeading
        description={description}
        path={path}
        organization={currentOrganization}
        tags={tags}
        allowOnly={OrganizationTypes.all}
      />
      <div className="relative flex-grow mt-3">
        <ValueChainMain rootNode={rootNode} onClear={noop} />
        <ConditionalRender renderIf={currentOrganization === null}>
          <EmptySection
            title="Get started by searching for a company above"
            icon={NoCompaniesIcon}
          />
        </ConditionalRender>
      </div>
    </MainOutletContent>
  );
};

const valueChainEntityTypeFromOrganizationType: Record<OrganizationTypes, ValueChainEntityType> = {
  [OrganizationTypes.company]: ValueChainEntityType.company,
  [OrganizationTypes.industry]: ValueChainEntityType.industry,
  [OrganizationTypes.all]: ValueChainEntityType.other,
  [OrganizationTypes.none]: ValueChainEntityType.other,
};

const path = ['SMART TOOLS', 'VALUE CHAIN'];
const description =
  'Premium Feature. Value chains are interactive visualizations of key qualities, ' +
  ' relationships and stakeholders including customers, suppliers, investors and more.';
