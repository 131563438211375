import { Clickable } from 'components/clickable';
import { ConditionalRender } from 'components/conditionalRenderer';
import { Input } from 'components/forms/input';
import SVGIcon from 'components/icons/SVGIcon';
import { Tag } from 'components/tag';
import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';

interface Props {
  readonly id?: string;
  readonly value: readonly string[];
  readonly name: string;

  onChange?(value: readonly string[]): void;
}

export const TagsInput: React.FC<Props> = ({
  id,
  value,
  name,
  onChange,
}: Props): React.ReactElement => {
  const [currentTag, setCurrentTag] = useState<string>('');

  const addTag = useCallback((): void => {
    const normalizedTag = capitalize(currentTag.trim());
    if (normalizedTag.length === 0 || value.includes(normalizedTag)) {
      return;
    }

    onChange?.([...value, normalizedTag]);
    setCurrentTag('');
  }, [currentTag, onChange, value]);

  const removeTag = useCallback(
    (index: number): void => {
      onChange?.([...value.slice(0, index), ...value.slice(index + 1)]);
    },
    [onChange, value],
  );

  return (
    <div>
      <Input
        id={id}
        name={name}
        label="Tags (press Return/Enter to add)"
        value={currentTag}
        onChange={setCurrentTag}
        onReturn={addTag}
      />

      <ConditionalRender renderIf={value.length === 0}>
        <div className="flex flex-wrap items-center gap-2 mt-2">
          <Tag variant="empty">0 tags</Tag>
        </div>
      </ConditionalRender>

      <ConditionalRender renderIf={value.length > 0}>
        <div className="relative flex flex-wrap items-center gap-2 mt-2 z-1">
          {value.map(
            (tag: string, index: number): React.ReactElement => (
              <Tag key={tag}>
                <div className="flex items-center gap-1.5 pr-1">
                  <Clickable clickData={index} onClick={removeTag}>
                    <div className="flex items-center justify-center w-3 h-4 hover:text-red">
                      <SVGIcon name="close-popup-icon" className="w-2.5 h-2.5 fill-current" />
                    </div>
                  </Clickable>
                  <div>{capitalize(tag)}</div>
                </div>
              </Tag>
            ),
          )}
        </div>
      </ConditionalRender>
    </div>
  );
};
