import { Dispatch } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import {
  listIndicatorsFailed,
  listIndicatorsStarted,
  listIndicatorsSucceeded,
} from 'redux/reducers/indicatorsReducer';
import { listIndicators, listPopularIndicators } from 'redux/services/indicatorsServices';
import { ThunkLikeAction } from 'types/APIAction';

export const listIndicatorsAction =
  (token: CancelToken, parameters: Record<string, string>): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(listIndicatorsStarted());
    try {
      const indicators = await listIndicators(token, parameters);
      dispatch(listIndicatorsSucceeded(indicators));
    } catch (error: any) {
      if ('response' in error) {
        dispatch(listIndicatorsFailed(error.response?.data ?? 'Unknown error'));
      } else {
        dispatch(listIndicatorsFailed(error.message));
      }
    }
  };

export const listPopularIndicatorsAction =
  (token: CancelToken, parameters: Record<string, string>): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(listIndicatorsStarted());
    try {
      const indicators = await listPopularIndicators(token, parameters);
      dispatch(listIndicatorsSucceeded(indicators));
    } catch (error: any) {
      if ('response' in error) {
        dispatch(listIndicatorsFailed(error.response?.data ?? 'Unknown error'));
      } else {
        dispatch(listIndicatorsFailed(error.message));
      }
    }
  };
