// Library
import 'styles/shared/ScrollBar.scss';

import axios from 'axios';
import { AddObjectButton } from 'components/addObjectButton';
import { Disabler } from 'components/disabler';
import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { Alignment, ChildrenRows, ColumnDefinition, ColumnType } from 'components/table/types';
import { Tabs } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { Tooltip } from 'components/tooltip';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { noop } from 'lodash';
// Components
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  CrazyKeyFactors,
  CrazyKeyFactorsCompany,
  KeyFactorType,
  loadingSelector,
  portfolioKeyFactorsSelector,
} from 'redux/reducers/keyFactorsReducer';
import { getPortfolioFactorsService } from 'redux/services/keyFactorsService';
import { LevelCell } from 'views/DataAnalysis/common/KeyFactors/levelCell';
import { createColumns } from 'views/Portfolios/KeyFactors/columns';
import { createFiltersConfig } from 'views/Portfolios/KeyFactors/filters';
// Redux Actions

const KeyFactors: React.FC = (): React.ReactElement => {
  const [expandedRows, setExpandedRows] = React.useState<
    Record<string, ChildrenRows<CrazyKeyFactorsCompany>>
  >({});
  const location = useLocation();
  const queryParameters = useQueryParameters();

  const { portfolioId } = useParams<{ portfolioId: string }>();

  const factorType = useMemo(
    (): KeyFactorType => (location.pathname.endsWith('/risk') ? 'risk' : 'growth'),
    [location.pathname],
  );

  const filtersConfig = useMemo(
    (): CommonFilter[] => createFiltersConfig(portfolioId, factorType),
    [factorType, portfolioId],
  );

  const handleExpandRow = useCallback(
    (row: CrazyKeyFactors, expanded: boolean): void => {
      if (expanded) {
        const { companies } = row;

        const expandedRows: Record<string, ChildrenRows<CrazyKeyFactorsCompany>> = {
          [row.id]: {
            rows: companies,
            columns: [
              {
                name: 'name',
                type: ColumnType.text,
                label: 'Company Name',
                alignment: Alignment.left,
              },
              {
                type: ColumnType.custom,
                name: 'ticker',
                label: 'Ticker/Exchange',
                render: (_: string, row: CrazyKeyFactorsCompany): React.ReactElement => (
                  <div className="flex items-center justify-center">
                    <span className="text-green">{row.ticker}</span> : {row.exchange}
                  </div>
                ),
              },
              {
                name: 'probability',
                type: ColumnType.custom,
                render: (value: CrazyKeyFactorsCompany['probability']): React.ReactNode => {
                  return (
                    <div className="flex items-center justify-center">
                      <LevelCell factorType={factorType} value={value} type="level" />
                    </div>
                  );
                },
                label: 'Probability',
              },
              {
                name: 'impact',
                type: ColumnType.custom,
                render: (value: CrazyKeyFactorsCompany['impact']): React.ReactNode => {
                  return (
                    <div className="flex items-center justify-center">
                      <LevelCell factorType={factorType} value={value} type="level" />
                    </div>
                  );
                },
                label: 'Impact',
              },
              {
                name: 'level',
                type: ColumnType.custom,
                render: (value: CrazyKeyFactorsCompany['level']): React.ReactNode => {
                  return (
                    <div className="flex items-center justify-center">
                      <LevelCell factorType={factorType} value={value} type="level" />
                    </div>
                  );
                },
                label: 'Level',
              },
              {
                name: 'portfolios',
                type: ColumnType.custom,
                label: 'Portfolios',
                render: (portfolios: CrazyKeyFactorsCompany['portfolios']): React.ReactElement => {
                  return <p className="text-center">{portfolios.count}</p>;
                },
              },
            ],
          },
        };

        setExpandedRows(expandedRows);
      } else {
        setExpandedRows({});
      }
    },
    [factorType],
  );

  const columns = useMemo(
    (): ReadonlyArray<ColumnDefinition<CrazyKeyFactors>> =>
      createColumns(factorType, handleExpandRow),
    [factorType, handleExpandRow],
  );

  const handleFiltersChange = useFilters(filtersConfig, { resetOnConfigChange: false });

  const loading = useSelector(loadingSelector);
  const keyFactors = useSelector(portfolioKeyFactorsSelector);
  const dispatch = useDispatch();

  useEffect((): void | VoidFunction => {
    const cancelToken = axios.CancelToken.source();

    dispatch(getPortfolioFactorsService(portfolioId, queryParameters, factorType));

    return (): void => {
      cancelToken.cancel();
    };
  }, [dispatch, factorType, portfolioId, queryParameters]);

  return (
    <div className="relative border-b-2 border-gray-light flex-1">
      <div className="flex w-full my-6 items-center">
        <div className="w-max relative">
          <Tooltip content="Coming soon" variant="light">
            <div className="inline-block">
              <Disabler disabled={true}>
                <AddObjectButton onClick={noop} title={`KEY ${factorType.toUpperCase()} FACTOR`} />
              </Disabler>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="mb-5">
        <Tooltip content="Coming soon" variant="light">
          <div className="inline-block">
            <Disabler disabled={true}>
              <Filters config={filtersConfig} onChange={handleFiltersChange} />
            </Disabler>
          </div>
        </Tooltip>
      </div>

      <Tabs shrinkFactor={3}>
        <Tab
          label="Risk Factors"
          element={
            <Table
              columns={columns}
              rows={keyFactors}
              rowChildren={expandedRows}
              showChildrenHeader={true}
            />
          }
          path="risk"
        />
        <Tab
          label="Growth Factors"
          element={
            <Table
              columns={columns}
              rows={keyFactors}
              rowChildren={expandedRows}
              showChildrenHeader={true}
            />
          }
          path="growth"
        />
      </Tabs>

      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default KeyFactors;
