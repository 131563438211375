import { CommonActionButtons } from 'components/commonActionButtons';
import { ConfirmationBox } from 'components/confirmationBox';
import { Modal } from 'components/modal';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  PeerGroup,
  updateUserPeerGroupProcessingStateSelector,
} from 'redux/reducers/userPeerGroupsReducer';
import { deleteUserPeerGroupAction } from 'redux/services/userPeerGroupsService';
import { ProcessingStateEnum } from 'types/processingState';
import { AddPeerGroupModal } from 'views/SmartTools/PeerGroups/modals/addPeerGroup';

interface Props {
  readonly peerGroup: PeerGroup;
}

export const ActionButtons: React.FC<Props> = ({ peerGroup }: Props): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const confirmDeleteModal = useModal();
  const editModal = useModal();
  const processingState = useSelector(updateUserPeerGroupProcessingStateSelector);
  const navigate = useNavigate();

  const handleFetchStart = useCallback((): void => {
    setLoading(true);
  }, []);

  const handleFetchEnd = useCallback((): void => {
    setLoading(false);
  }, []);

  useEffect((): void => {
    setLoading(
      (loading: boolean): boolean =>
        loading || processingState.state === ProcessingStateEnum.processing,
    );
  }, [processingState.state]);

  useEffect((): void => {
    if (
      processingState.state === ProcessingStateEnum.success ||
      processingState.state === ProcessingStateEnum.fatalError ||
      processingState.state === ProcessingStateEnum.error
    ) {
      editModal.close();
    }
  }, [editModal, processingState.state]);

  const handleDeleteRequest = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      confirmDeleteModal.open();
    },
    [confirmDeleteModal],
  );

  const handleDelete = useCallback((): void => {
    dispatch(deleteUserPeerGroupAction(peerGroup.id));
    confirmDeleteModal.close();

    navigate('/peer-groups');
  }, [confirmDeleteModal, dispatch, navigate, peerGroup.id]);

  const handleEdit = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      editModal.open();
    },
    [editModal],
  );

  return (
    <>
      <CommonActionButtons
        title="Peer Group"
        section="Peer Groups"
        onDelete={handleDeleteRequest}
        onEdit={handleEdit}
      />

      <Modal isOpen={editModal.isOpen} onClose={editModal.close} spinning={loading}>
        <Modal.Content title="Update Peer Group">
          <AddPeerGroupModal
            peerGroup={peerGroup}
            onFetchStart={handleFetchStart}
            onFetchEnd={handleFetchEnd}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={confirmDeleteModal.isOpen} onClose={confirmDeleteModal.close}>
        <Modal.Content title="Delete Peer Group">
          <ConfirmationBox
            message="You are about to delete the peer group, are you sure?"
            onYes={handleDelete}
            onNo={confirmDeleteModal.close}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
