import { FinancialQuotes } from 'components/header/FinancialQuotes';
import { useLogoURI } from 'hooks/useLogoURI';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  onIndicatorsClick?(): void;
}

export const DesktopHeaderBase: React.ForwardRefExoticComponent<
  React.PropsWithChildren<Props> & React.RefAttributes<HTMLDivElement>
> = React.forwardRef<HTMLDivElement>(function HeaderBase(
  { children, onIndicatorsClick }: React.PropsWithChildren<Props>,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const logoURI = useLogoURI();

  const indicatorsClassName = useMemo(
    (): string => (onIndicatorsClick ? 'cursor-pointer w-full' : 'w-full'),
    [onIndicatorsClick],
  );

  return (
    <div className="h-header w-full bg-white z-1" ref={ref}>
      <div className="flex items-center py-4 justify-between">
        <Link className="block pt-2 mx-8" to="/">
          <img src={logoURI} alt="application-logo" className="max-w-45.69 h-12" />
        </Link>
        {children}
      </div>
      <button onClick={onIndicatorsClick} className={indicatorsClassName}>
        <FinancialQuotes />
      </button>
    </div>
  );
});
