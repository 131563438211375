import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessMode } from 'types/accessMode';
import { Stream, TimelineViewType } from 'types/timeline';

export interface TimelineViewState {
  viewType: TimelineViewType;
  selection: Record<string, boolean>;
}

export const initialState: TimelineViewState = {
  viewType: TimelineViewType.calendar,
  selection: {},
};

const slice = createSlice({
  name: 'timelineView',
  initialState: initialState,
  reducers: {
    updateSelection: (
      state: TimelineViewState,
      { payload }: PayloadAction<{ id: string; value: boolean }>,
    ): void => {
      state.selection = { ...state.selection, [payload.id]: payload.value };
    },
    setViewType: (state: TimelineViewState, { payload }: PayloadAction<TimelineViewType>): void => {
      state.viewType = payload;
    },
    resetSelection: (state: TimelineViewState): void => {
      state.selection = {};
    },
    toggleSelectAll: (
      state: TimelineViewState,
      { payload }: PayloadAction<readonly Stream[]>,
    ): void => {
      const allSelected = !payload
        .filter((stream: Stream): boolean => stream.access_mode !== AccessMode.readOnly)
        .some((stream: Stream): boolean => !state.selection[stream.id]);

      if (!allSelected) {
        state.selection = payload.reduce(
          (selection: Record<string, boolean>, next: Stream): Record<string, boolean> => {
            if (next.access_mode === AccessMode.readOnly) {
              return selection;
            }

            return { ...selection, [next.id]: true };
          },
          {},
        );
      } else {
        state.selection = {};
      }
    },
  },
});

export const { updateSelection, resetSelection, setViewType, toggleSelectAll } = slice.actions;

export default slice.reducer;
