import NoCompaniesIcon from 'assets/icons/svg/no-companies-icon.svg';
import axios from 'axios';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import EmptySection from 'components/emptySection/EmptySection';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { Sorting, SortOrder } from 'components/table/sorting';
import { ColumnDefinition } from 'components/table/types';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadingMyWatchlistSelector } from 'redux/reducers/companyReducer';
import { deleteWatchlistService, getMyWatchlistService } from 'redux/services/companyService';
import { Company } from 'types/organization/types';
import createColumns from 'views/Watchlist/columns';

interface Props {
  readonly rows: Company[];
  readonly sorting: Sorting<Company>;

  onSort(name: keyof Company, order: SortOrder): void;
  onAdd(): void;
}

const CompaniesTable: React.FC<Props> = ({
  rows,
  sorting,
  onSort,
  onAdd,
}: Props): React.ReactElement => {
  const loadingMyCompanies = useSelector(loadingMyWatchlistSelector);
  const dispatch = useDispatch<any>();
  const queryParameters = useQueryParameters();
  const navigate = useNavigate();
  const closeDeleteModal = useCallback((): void => setShowDeleteModal(false), []);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [company, setCompany] = useState<Pick<Company, 'id' | 'name'>>({ id: '', name: '' });

  const handleDelete = useCallback((name: string, id: string | null) => {
    if (id) {
      setCompany({ id, name });
      setShowDeleteModal(true);
    }
  }, []);

  const handleRowClicked = useCallback(
    (company: Company): void => {
      if (company.company_id) navigate(`/data-analysis/company/details/${company.company_id}`);
    },
    [navigate],
  );

  const columns = useMemo(
    (): ReadonlyArray<ColumnDefinition<Company>> => createColumns(handleDelete),
    [handleDelete],
  );

  useEffect((): VoidFunction => {
    const tokenSource = axios.CancelToken.source();

    dispatch(getMyWatchlistService(queryParameters, tokenSource.token));
    return (): void => {
      tokenSource.cancel();
    };
  }, [dispatch, queryParameters]);

  const hideDeleteModal = useCallback((): void => setShowDeleteModal(false), []);
  const handleDeleteAccepted = useCallback((): void => {
    dispatch(deleteWatchlistService(company.id));
    setShowDeleteModal(false);
  }, [company.id, dispatch]);

  return (
    <div className="relative flex-1">
      <Table
        columns={columns}
        rows={rows}
        sortBy={sorting}
        onSortChange={onSort}
        onRowClick={handleRowClicked}
      />

      <ConditionalRender renderIf={rows.length === 0 && !loadingMyCompanies}>
        <EmptySection
          title="No companies added"
          subtitle="Add a company to your watchlist to see it here"
          buttonText="ADD A COMPANY"
          icon={NoCompaniesIcon}
          onClick={onAdd}
        />
      </ConditionalRender>
      <SpinnerLoader visible={loadingMyCompanies} />

      <Modal isOpen={showDeleteModal} onClose={closeDeleteModal}>
        <Modal.Content title="Delete Company from watchlist">
          <ConfirmationBox
            message={`You are about to delete ${company.name} from your watchlist, are you sure?`}
            onYes={handleDeleteAccepted}
            onNo={hideDeleteModal}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default CompaniesTable;
