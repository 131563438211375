import { PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
import { ApplicationModule, exceedsUsageLimit, usePermission } from 'context/authorization';
import { useCurrentEvent } from 'hooks/useCurrentEvent';
import { useModal } from 'hooks/useModal';
import { noop } from 'lodash';
import ExceedAccess from 'modals/exceedAccess';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStreamsAction } from 'redux/actions/timelineActions';
import { UsageAction } from 'redux/reducers/subscriptionPlansReducer';
import {
  clearEvents,
  processingStateSelector,
  sponsoredEventsSelector,
  streamsSelector,
  TimelineAction,
} from 'redux/reducers/timelineReducer';
import { deleteStreamService } from 'redux/services/timelineService';
import { AccessMode } from 'types/accessMode';
import { ProcessingStateEnum } from 'types/processingState';
import { defaultStreamFilters, isYearStreamFilters, Stream, TimelineFilters } from 'types/timeline';
import { EventManager } from 'views/SmartTools/TimelineAndEvents/components/eventManager';
import { StreamList } from 'views/SmartTools/TimelineAndEvents/components/streamList';
import CreateEditStreamModal from 'views/SmartTools/TimelineAndEvents/modals/createEditStreamModal';
import { DeleteStreamModal } from 'views/SmartTools/TimelineAndEvents/modals/deleteStreamModal';
import reducer, {
  initialState,
  resetSelection,
  TimelineViewState,
  toggleSelectAll,
  updateSelection,
} from 'views/SmartTools/TimelineAndEvents/reducer';
import SponsoredStream from 'views/SmartTools/TimelineAndEvents/sponsoredStream';
import { AddStreamAndDateFilterBar } from 'views/SmartTools/TimelineAndEvents/toolbars/addStreamAndDateFilterBar';
import { SelectAllAndToggleLinkedItemsBar } from 'views/SmartTools/TimelineAndEvents/toolbars/selectAllAndToggleLinkedItemsBar';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export const Timelines: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const [filters, setFilters] = useState<TimelineFilters>(defaultStreamFilters);
  const [state, localDispatch] = useReducer<Reducer<TimelineViewState, PayloadAction<any>>>(
    reducer,
    initialState,
  );
  const permission = usePermission(ApplicationModule.timeline, UsageAction.create);
  const sponsoredEvents = useSelector(sponsoredEventsSelector);
  const streams = useSelector(streamsSelector);
  const processingState = useSelector(processingStateSelector);
  const [currentEvent, currentStream, loadingEvent] = useCurrentEvent(streams);

  const createModal = useModal();
  const editModal = useModal();
  const deleteModal = useModal();
  const exceededUsageModal = useModal();

  const compact = useMemo((): boolean => isYearStreamFilters(filters), [filters]);
  const loading = useMemo(
    (): boolean => processingState.state === ProcessingStateEnum.processing,
    [processingState],
  );

  const noSelectableItems = useMemo(
    (): boolean =>
      streams.reduce((totalCount: number, stream: Stream): number => {
        if (stream.access_mode && stream.access_mode === AccessMode.readOnly) {
          return totalCount;
        }

        return totalCount + 1;
      }, 0) === 0,
    [streams],
  );

  const accessibleStreamCount = useMemo(
    (): number =>
      streams.filter(stream => stream.access_mode && stream.access_mode !== AccessMode.readOnly)
        .length,
    [streams],
  );

  const allSelected = useMemo(
    (): boolean =>
      !streams
        .filter((stream: Stream): boolean => stream.access_mode !== AccessMode.readOnly)
        .some((stream: Stream): boolean => !state.selection[stream.id]),
    [state.selection, streams],
  );

  /*const handleEditRequest = useMemo((): VoidFunction | undefined => {
    if (selectedStreamsCount === 1) {
      return (): void => {
        editModal.open();
      };
    } else {
      return undefined;
    }
  }, [editModal, selectedStreamsCount]);*/

  const handleDelete = useCallback((): void => {
    const ids = Object.keys(state.selection);

    dispatch(deleteStreamService(ids));
    // Reset selection
    localDispatch(resetSelection());
  }, [dispatch, state.selection]);

  /*const handleDeleteRequest = useMemo((): VoidFunction | undefined => {
    if (selectedStreamsCount > 0) {
      return (): void => {
        deleteModal.open();
      };
    } else {
      return undefined;
    }
  }, [deleteModal, selectedStreamsCount]);*/

  const handleSelectionChange = useCallback((id: string, value: boolean): void => {
    localDispatch(updateSelection({ id, value }));
  }, []);

  const handleSelectAll = useCallback((): void => {
    localDispatch(toggleSelectAll(streams));
  }, [streams]);

  useEffect((): VoidFunction => {
    const timeout = setTimeout((): void => {
      dispatch(clearEvents());
      dispatch(getStreamsAction(filters));
    }, 0);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [filters, dispatch]);

  useEffect((): void => {
    // FIXME: looks to complicated for the real use case? we have to finalize its design
    switch (processingState.state) {
      case ProcessingStateEnum.idle:
      case ProcessingStateEnum.processing:
        break;
      case ProcessingStateEnum.error:
      case ProcessingStateEnum.fatalError:
        break;
      case ProcessingStateEnum.success:
        switch (processingState.data) {
          case TimelineAction.creatingStream:
            createModal.close();
            break;
        }
        break;
    }
  }, [createModal, processingState]);

  const handleAddTimelineAndEvent = useCallback((): void => {
    if (exceedsUsageLimit(permission, accessibleStreamCount)) {
      exceededUsageModal.open();
    } else {
      createModal.open();
    }
  }, [createModal, exceededUsageModal, permission, accessibleStreamCount]);

  // Will only be defined if there's 1 stream selected
  const selectedStreams = useMemo((): Stream[] => {
    const ids = Object.keys(state.selection);

    return ids.map(
      (id: string): Stream => streams.find((stream: Stream): boolean => id === stream.id) as Stream,
    );
  }, [state.selection, streams]);

  return (
    <div className="flex-1 relative">
      <AddStreamAndDateFilterBar
        filters={filters}
        onFilterChange={setFilters}
        onAddClick={handleAddTimelineAndEvent}
      />
      <SelectAllAndToggleLinkedItemsBar
        allSelected={allSelected}
        noSelectableItems={noSelectableItems}
        onSelectAll={handleSelectAll}
      />
      <SponsoredStream
        events={sponsoredEvents}
        date={currentDate}
        year={currentYear}
        onPressEvent={noop}
      />
      <StreamList
        streams={streams}
        compact={compact}
        mode="select"
        selection={state.selection}
        onSelect={handleSelectionChange}
      />

      <SpinnerLoader visible={loading} />

      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <>
          <Modal isOpen={createModal.isOpen} onClose={createModal.close}>
            <Modal.Content title="Create Timeline">
              <CreateEditStreamModal onClose={createModal.close} />
            </Modal.Content>
          </Modal>
          <Modal isOpen={editModal.isOpen} onClose={editModal.close}>
            <Modal.Content title="Update Timeline">
              {/* For this to be open, only 1 stream can be selected, and has to be */}
              <CreateEditStreamModal stream={selectedStreams[0]} onClose={editModal.close} />
            </Modal.Content>
          </Modal>
          <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.close}>
            <Modal.Content title="Delete Streams">
              <DeleteStreamModal
                streams={selectedStreams}
                onAccept={handleDelete}
                onClose={deleteModal.close}
              />
            </Modal.Content>
          </Modal>
          <Modal isOpen={exceededUsageModal.isOpen} onClose={exceededUsageModal.close}>
            <Modal.Content>
              <ExceedAccess
                title="Unlock Event Timelines"
                description="Upgrade your plan to add new event timelines immediately."
              />
            </Modal.Content>
          </Modal>
        </>
      )}
      <EventManager event={currentEvent} stream={currentStream} loading={loadingEvent} />
    </div>
  );
};
