import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface Props<T> {
  readonly row: T;
  onExpand(row: T, expanded: boolean): void;
}

export function Expander<T>({ row, onExpand }: Props<T>): React.ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleClick = useCallback((event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setExpanded((expanded: boolean): boolean => !expanded);
  }, []);

  const iconClassName = useMemo((): string => {
    if (expanded) {
      return 'w-3 transform rotate-180 transition:transform duration-500 ease-in-out';
    }

    return 'w-3 transition:transform duration-500 ease-in-out';
  }, [expanded]);

  useEffect((): void => {
    onExpand(row, expanded);
  }, [expanded, onExpand, row]);

  return (
    <div className="flex items-center justify-end">
      <div
        className="flex items-center justify-center w-6 h-6 hover:bg-gray-medium rounded-full"
        onClick={handleClick}
      >
        <SVGIcon name="down-arrow" className={iconClassName} />
      </div>
    </div>
  );
}
