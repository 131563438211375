import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'views/Landing/LandingPage/components/Slider/Slider.scss';

import DevicesImg from 'assets/images/Slider-Hero-0.png';
import UserFeed from 'assets/images/Slider-Hero-1.png';
import PortfolioMaps from 'assets/images/Slider-Hero-2.png';
import PortfolioRisks from 'assets/images/Slider-Hero-5.png';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const sliderImages = [DevicesImg, UserFeed, PortfolioMaps, PortfolioRisks];

const Slider: React.FC = () => {
  const CurrentImage = (value: string, index: number) => (
    <div className="mt-7 flex justify-center pb-14" key={index}>
      <img className={index !== 0 ? 'shadow-slider' : ''} src={value} alt={`${value} image`} />
    </div>
  );

  const renderControllers = (
    onClickHandler: React.MouseEventHandler<HTMLLIElement> | undefined,
    isSelected: boolean,
    index: number,
    label: string,
  ) => {
    if (isSelected) {
      return (
        <li
          className="inline-block mr-3 dots-size rounded-full border cursor-pointer bg-blue-dot"
          aria-label={`Selected: ${label} ${index + 1}`}
          title={`Selected: ${label} ${index + 1}`}
        />
      );
    }
    return (
      <li
        onClick={onClickHandler}
        className="inline-block dots-size mr-3 rounded-full border cursor-pointer bg-gray-dot"
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };
  const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    swipeable: true,
    emulateTouch: true,
    transitionTime: 700,
    interval: 10000,
  });

  return (
    <div style={{ zIndex: -1 }}>
      <Carousel {...getConfigurableProps()} renderIndicator={renderControllers}>
        {sliderImages.map(CurrentImage)}
      </Carousel>
    </div>
  );
};

export default Slider;
