import { Checkbox } from 'components/checkbox';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portfoliosSelector, updateSelection } from 'redux/reducers/portfoliosReducer';

interface Props {
  readonly portfolioId: string;
}

export const RowCheckbox: React.FC<Props> = ({ portfolioId }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const { selection } = useSelector(portfoliosSelector);

  const handleChange = React.useCallback(() => {
    dispatch(updateSelection({ id: portfolioId, selected: !selection?.[portfolioId] }));
  }, [dispatch, portfolioId, selection]);

  const handleClick = React.useCallback((event: React.MouseEvent): void => {
    event.stopPropagation();
  }, []);

  const checked = useMemo((): boolean => !!selection?.[portfolioId], [portfolioId, selection]);

  return (
    <Checkbox
      label=""
      checked={checked}
      size="large"
      onChange={handleChange}
      onClick={handleClick}
    />
  );
};
