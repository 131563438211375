import { ReactComponent as EditIcon } from 'assets/icons/svg/edit-icon.svg';
// GENERAL COMPONENTS
import LocationIcon from 'assets/icons/svg/gray-location-icon.svg';
import axios from 'axios';
import { ConditionalRender } from 'components/conditionalRenderer';
import DetailsModal from 'components/detailsModal';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
import { Tabs } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { Tag } from 'components/tag';
import { UserAvatar } from 'components/userAvatar';
import { format } from 'date-fns';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import { companiesSelector, loadingCompaniesSelector } from 'redux/reducers/companyReducer';
// REDUX
import {
  loadingDataSelector,
  submittingEditFormSelector,
  userProfileSelector,
} from 'redux/reducers/profileReducer';
import { countriesSelector, Country } from 'redux/reducers/sharedReducer';
import { getCompaniesService } from 'redux/services/companyService';
import { getUserProfileService } from 'redux/services/profileService';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { SocialUser } from 'views/Home/types';
import EditJobInformationModal from 'views/Profile/Details/editJobInformationModal';
import EditProfileModal from 'views/Profile/Details/editProfileModal';
import ProfileLinksErrorModal from 'views/Profile/Details/profileLinksErrorModal';
import { ItemsOfInterest } from 'views/Profile/ItemsOfInterest';

interface ItemsOfInterestList {
  readonly name: string;
  readonly itemsOfInterest: string[];
}

interface Props {
  readonly userId?: string;
}

const Details: React.FC<Props> = ({ userId }: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  // FIXME: this should probably belong to the modal
  const [currentDetailsList, setCurrentDetailsList] = useState<ItemsOfInterestList | null>(null);

  const loading = useSelector(loadingDataSelector);
  const loadingCompanies = useSelector(loadingCompaniesSelector);
  const profile = useSelector(userProfileSelector);
  const myCompanies = useSelector(companiesSelector);
  const submittingEditForm = useSelector(submittingEditFormSelector);
  const loggedInUser = useSelector(userSelector);
  const countries = useSelector(countriesSelector);
  const user = useMemo((): SocialUser => SocialUser.fromProfile(profile), [profile]);

  const { first_name, last_name } = profile?.personal_information ?? {};
  const {
    country,
    city,
    about_me,
    connection_to_finance,
    accredited_investor,
    linkedin_url,
    website_url,
  } = profile?.about ?? {};
  const { recent_job, recent_company, job_position_start_date, job_position_end_date } =
    profile?.job_information ?? {};
  const modals = {
    editPersonalInformation: useModal(),
    editJobInformation: useModal(),
    linkError: useModal(),
    deleteAccount: useModal(),
  };
  const myCompaniesList = useMemo(
    (): string[] => myCompanies.data.map(company => company.name),
    [myCompanies],
  );
  const myTopicsOfInterest = useMemo((): string[] => {
    return [
      ...(profile?.topics_of_interest ?? []),
      ...(profile?.additional_topics_of_interest ?? []),
    ];
  }, [profile?.additional_topics_of_interest, profile?.topics_of_interest]);
  const myRegionsOfInterest = useMemo(
    (): string[] => profile?.regions_of_interest ?? [],
    [profile?.regions_of_interest],
  );

  useEffect((): void => {
    if (!userId && !loggedInUser?.id) {
      return;
    }

    if (userId === undefined) {
      dispatch(getUserProfileService(loggedInUser.id));
    } else {
      dispatch(getUserProfileService(userId));
    }
  }, [dispatch, loggedInUser.id, userId]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (userId === undefined) {
      dispatch(getCompaniesService(DataAndAnalysisMainTabs.My, source.token));
    }
  }, [dispatch, userId]);

  const countryName = useMemo((): string => {
    const countryObject = countries.find((item: Country): boolean => item.id === country);
    if (!countryObject) {
      return '';
    }

    return countryObject.name;
  }, [countries, country]);

  const startDate = useMemo((): string => {
    return job_position_start_date ? format(new Date(job_position_start_date), 'MMM yyyy') : '';
  }, [job_position_start_date]);

  const endDate = useMemo((): string => {
    if (job_position_start_date !== null && job_position_end_date !== null) {
      try {
        return `- ${format(new Date(job_position_end_date ?? ''), 'MMM yyyy')}`;
      } catch {
        return '';
      }
    }
    if (job_position_start_date !== null && job_position_end_date === null) {
      return `- Present`;
    }

    return '';
  }, [job_position_start_date, job_position_end_date]);

  // Kind of not liking this too much :/
  const getClickableLink = useCallback((link: string) => {
    if (link === '') {
      return;
    }

    return link.startsWith('https://') ? link : `https://${link}`;
  }, []);

  const showAllCompaniesOfInterest = useCallback(
    (): void =>
      setCurrentDetailsList({ name: 'Companies of interest', itemsOfInterest: myCompaniesList }),
    [myCompaniesList],
  );

  const showAllTopicsOfInterest = useCallback(
    (): void =>
      setCurrentDetailsList({ name: 'Topics of interest', itemsOfInterest: myTopicsOfInterest }),
    [myTopicsOfInterest],
  );

  const showAllIndustriesOfInterest = useCallback(
    (): void =>
      setCurrentDetailsList({
        name: 'Industries of interest',
        itemsOfInterest: profile.industries_of_interest,
      }),
    [profile.industries_of_interest],
  );

  const showAllRegionsOfInterest = useCallback(
    (): void =>
      setCurrentDetailsList({
        name: 'Regions of interest',
        itemsOfInterest: profile?.regions_of_interest ?? [],
      }),
    [profile?.regions_of_interest],
  );

  return (
    <>
      <SpinnerLoader visible={submittingEditForm} />

      <div className={submittingEditForm ? 'opacity-50 pointer-events-none' : ''}>
        <div className="px-5 py-4 my-6 rounded-md shadow-md">
          <div className="">
            <div className="flex justify-between w-full items-start">
              <div className="flex flex-row items-center space-x-4">
                <UserAvatar user={user} size="large" />

                <div className="flex flex-col space-y-3 w-full">
                  <div className="inner-profile__user-name">
                    <div className="flex items-center gap-3 my-2">
                      <h1 className="m-0 leading-4">
                        {first_name} {last_name}
                      </h1>
                      {profile.follows_you && <Tag>Follows You</Tag>}
                    </div>
                    <p className="text-base">{connection_to_finance ?? ''}</p>
                  </div>
                  <div className="flex items-start -m-1">
                    <img className="w-5 h-5 mr-1" src={LocationIcon} alt="location" />
                    <p>
                      {[city, countryName]
                        .filter((item: string | undefined): item is string => !!item)
                        .join(', ')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col space-y-6">
                <div className="flex justify-end">
                  <p className="text-base font-gray">
                    {accredited_investor ? 'Accredited Investor' : 'Not an Accredited Investor'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {about_me && (
            <div className="mt-10 pb-4 border-b border-gray-light">
              <h4 className="font-poppinsSemiBold text-base normal-case">About Me:</h4>
              <p>{about_me}</p>
            </div>
          )}
          <div className="flex items-center pt-4 text-blue">
            <a
              href={getClickableLink(linkedin_url ?? '')}
              className="text-base pr-4 border-r border-gray-light"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              href={getClickableLink(website_url ?? '')}
              className="text-base pl-4"
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>
            <button
              className="flex items-center gap-2 text-base ml-auto text-blue font-poppinsMedium"
              onClick={modals.editPersonalInformation.open}
            >
              <EditIcon className="w-4 h-4 text-blue fill-current" /> <span>Edit</span>
            </button>
          </div>
          <SpinnerLoader visible={loading && !profile} />
        </div>
        <div className="relative px-5 py-4 my-6 rounded-md shadow-md">
          <div className="w-full flex gap-8 items-start">
            <div className="w-2/5 pr-2">
              <h4 className="text-base normal-case mb-4">Job Information</h4>
              <p className="mb-1 break-words">{recent_job ?? ''}</p>
              <p className="mb-4 break-words">{recent_company ?? ''}</p>
              <p>{`${startDate} ${endDate}`}</p>
              <button
                className="flex items-center gap-2 text-base text-blue font-poppinsMedium"
                onClick={modals.editJobInformation.open}
              >
                <EditIcon className="w-4 h-4 text-blue fill-current" />
                <span>Edit</span>
              </button>
            </div>

            <ItemsOfInterest
              title="Companies of interest"
              items={myCompaniesList}
              onShowMore={showAllCompaniesOfInterest}
            />
            <ItemsOfInterest
              title="Topics of interest"
              items={myTopicsOfInterest}
              onShowMore={showAllTopicsOfInterest}
            />
            <ItemsOfInterest
              title="Industries of interest"
              items={profile.industries_of_interest}
              onShowMore={showAllIndustriesOfInterest}
            />
            <ItemsOfInterest
              title="Geographies of interest"
              items={myRegionsOfInterest}
              onShowMore={showAllRegionsOfInterest}
            />
          </div>

          <SpinnerLoader visible={loadingCompanies} />
        </div>
        <ConditionalRender renderIf={userId === undefined}>
          <div className="w-full mt-6">
            <h4 className="text-base normal-case pl-5 mb-6">Activity</h4>
            <Tabs shrinkFactor={6}>
              <Tab label="Posts" element={null} comingSoon />
              <Tab label="Comments" element={null} path="comments" comingSoon />
              <Tab label="Reactions" element={null} path="reactions" comingSoon />
            </Tabs>
          </div>
        </ConditionalRender>
      </div>

      <Modal isOpen={currentDetailsList !== null} onClose={(): void => setCurrentDetailsList(null)}>
        <Modal.Content title={currentDetailsList?.name}>
          <DetailsModal description="" tags={currentDetailsList?.itemsOfInterest ?? []} />
        </Modal.Content>
      </Modal>

      <Modal
        isOpen={modals.editPersonalInformation.isOpen}
        onClose={modals.editPersonalInformation.close}
      >
        <Modal.Content title="Edit Personal Information">
          <EditProfileModal onClose={modals.editPersonalInformation.close} />
        </Modal.Content>
      </Modal>

      <Modal isOpen={modals.editJobInformation.isOpen} onClose={modals.editJobInformation.close}>
        <Modal.Content title="Edit Job Information">
          <EditJobInformationModal onClose={modals.editJobInformation.close} />
        </Modal.Content>
      </Modal>

      <Modal isOpen={modals.linkError.isOpen} onClose={modals.linkError.close}>
        <Modal.Content>
          <ProfileLinksErrorModal />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Details;
