import { useCallback } from 'react';

export interface KeyValue<T> {
  readonly key: keyof T;
  readonly value: string | null;
}

export interface FormValueSetterOptions {
  readonly nullifyEmptyString: boolean;
}

export const useFormValueSetter = <T>(
  setValue: (keyValue: KeyValue<T>) => void,
  options: FormValueSetterOptions,
  dependencies: any[],
): ((name: keyof T, value: any) => void) => {
  return useCallback(
    (name: keyof T, value: any): void => {
      if (options.nullifyEmptyString && value !== null && typeof value === 'string') {
        const trimmedValue = value.trim();
        setValue({
          key: name,
          value: trimmedValue === '' ? null : value,
        });
      } else {
        setValue({
          key: name,
          value: value,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, setValue],
  );
};
