// Libraries
import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import React from 'react';
import { Route } from 'react-router-dom';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { AllAndUpcomingEvents } from 'views/SmartTools/TimelineAndEvents/AllAndUpcomingEvents';
import { Timelines } from 'views/SmartTools/TimelineAndEvents/Timelines';

const TimelineAndEvents: React.FC = (): React.ReactElement => {
  return (
    <MainOutletContent>
      <Breadcrumbs path={['TIMELINE & EVENTS', 'INTELLIGENT TIMELINES']} />
      <TextLine content={description} />
      <div className="h-2" />

      <Tabs shrinkFactor={6} variant={TabsVariant.buttons}>
        <Tab label={myTimelinesLabel} element={<Timelines />} />
        <Tab label="All & Upcoming" element={<AllAndUpcomingEvents />} path="all-and-upcoming" />

        <Route path=":eventId" element={<Timelines />} />
        <Route path="all-and-upcoming/:eventId" element={<AllAndUpcomingEvents />} />
      </Tabs>
    </MainOutletContent>
  );
};

export default TimelineAndEvents;

const myTimelinesLabel = process.env.REACT_APP_DATA_PORTAL ? 'Timelines' : 'My Timelines';

const description =
  'Discover and track important dates. Entries can be automated or manually created. Scroll over and click on events for more details.';
