import React from 'react';
import { AccessMode } from 'types/accessMode';
import { Stream } from 'types/timeline';
import { StreamItem } from 'views/SmartTools/TimelineAndEvents/components/streamItem';

interface BaseProps {
  readonly streams: readonly Stream[];
  readonly compact: boolean;

  readonly selection: Record<string, boolean>;
  readonly mode: 'select' | 'expand';
}

interface SelectableProps extends BaseProps {
  readonly mode: 'select';

  onSelect(id: string, value: boolean): void;
}

interface ExpandableProps extends BaseProps {
  readonly mode: 'expand';

  onExpand(id: string, value: boolean): void;
}

type Props = ExpandableProps | SelectableProps;

export const StreamList: React.FC<Props> = (props: Props): React.ReactElement => {
  const { streams } = props;
  return (
    <>
      {streams.map((stream: Stream): React.ReactElement => {
        const readOnly = stream.access_mode === AccessMode.readOnly;
        if (readOnly) {
          return (
            <StreamItem
              key={stream.id}
              stream={stream}
              compact={props.compact}
              variant="regular"
              readOnly={true}
            />
          );
        } else if (props.mode === 'select') {
          return (
            <StreamItem
              key={stream.id}
              readOnly={false}
              stream={stream}
              compact={props.compact}
              mode="select"
              variant="regular"
              selected={props.selection[stream.id] ?? false}
              onSelect={props.onSelect}
            />
          );
        } else {
          return (
            <StreamItem
              key={stream.id}
              readOnly={true}
              stream={stream}
              compact={props.compact}
              mode="expand"
              variant="regular"
              expanded={props.selection[stream.id] ?? false}
              onExpand={props.onExpand}
            />
          );
        }
      })}
    </>
  );
};
