import { format } from 'date-fns';
import { formatInteger, formatNumber, formatPercentage, isNumeric } from 'utils';

const ONE_THOUSAND = 1000;
export const ONE_MILLION = 1000 * ONE_THOUSAND;
const ONE_BILLION = 1000 * ONE_MILLION;
const ONE_TRILLION = 1000 * ONE_BILLION;
export const ONE_HUNDRED_TRILLIONS = 100 * ONE_TRILLION;

const toNumberHelper = (value: string | number | null): number => {
  if (typeof value === 'string') {
    // This one will automatically return `NaN` for non-numeric values
    return Number(value);
  } else if (typeof value === 'number') {
    return value;
  } else {
    return NaN;
  }
};

const VALUES_FORMATTERS: {
  [key: string]: (value: string | number, largestNumber?: number) => string;
} = {
  percentage: (value: string | number | null) => {
    if (value === null) return '-';
    const numericValue = toNumberHelper(value);
    if (isNaN(numericValue)) {
      return '-';
    }

    return formatPercentage(numericValue);
  },
  currency: (value: string | number | null, largestNumber = 0) => {
    if (value === null) return '-';
    const numericValue = toNumberHelper(value);
    if (isNaN(numericValue)) {
      return '-';
    }

    const scale = ((): number => {
      if (largestNumber >= ONE_HUNDRED_TRILLIONS) {
        return ONE_MILLION;
      } else if (largestNumber > ONE_MILLION) {
        return ONE_THOUSAND;
      } else {
        return 1;
      }
    })();

    const scaledValue = numericValue / scale;
    const withDecimals =
      (scale === 1 && Math.abs(scaledValue) < 1000) || Math.abs(scaledValue) < 10;

    return formatNumber(scaledValue, !withDecimals);
  },
  'currency-non-scalable': (value: string | number | null) => {
    if (value === null) return '-';
    const numericValue = toNumberHelper(value);
    if (isNaN(numericValue)) {
      return '-';
    }

    return formatNumber(numericValue, false);
  },
  integer: (value: string | number | null) => {
    if (value === null) return '-';
    const numericValue = toNumberHelper(value);
    if (isNaN(numericValue)) {
      return '-';
    }

    return formatInteger(numericValue);
  },
  string: (value: string | number) => {
    return value ? String(value) : '-';
  },
  boolean: (value: string | number | boolean) => (value ? 'Yes' : 'No'),
  'date-day': (value: string | number) => {
    return value ? format(new Date(value), 'dd MMM yy') : '-';
  },
  value: (value: string | number) => {
    return value ? value.toString() : '-';
  },
  nil: (value: string | number) => {
    if (isNumeric(value as string)) {
      const numericValue = parseFloat(value.toString());
      return formatNumber(numericValue, false);
    } else {
      return value as string;
    }
  },
};

export default VALUES_FORMATTERS;

export const formatDate = (value: Date): string => format(value, 'dd MMM yy');
