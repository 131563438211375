import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import { ACCESS_TOKEN_STORAGE_KEY, logoutCompleted } from 'redux/reducers/authReducer';
import {
  deleteUserAccountFailed,
  deleteUserAccountStarted,
  deleteUserAccountSucceeded,
  getPreferencesSucceeded,
  getProfileData,
  getProfileDataFailed,
  getProfileDataSuccess,
  getUserProfile,
  getUserProfileFailed,
  getUserProfileSucceeded,
  getUserRegionsOfInterestSuccess,
  getUserTopicsOfInterestSuccess,
  PersonalInformationModalState,
  submitPersonalInformationCompleted,
  submitPersonalInformationStarted,
  updatePermissionsFailed,
  updatePermissionsStarted,
  updatePermissionsSucceeded,
  UserPermission,
} from 'redux/reducers/profileReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { me } from 'redux/services/authServices';
import { ActionsGenerator, createAPIAction, HttpClient, ThunkLikeAction } from 'types/APIAction';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';
import { ActivableComboBoxItem } from 'views/Landing/Onboarding/components/helpers';

interface PersonalInfoPayload {
  first_name: string | null;
  last_name: string | null;
  account_type: string | null;
}

interface AboutMePayload {
  about_me: string | null;
  country: number;
  city: string | null;
  accredited_investor: boolean;
  connection_to_finance: string | null;
  linkedin_url: string | null;
  website_url: string | null;
}

interface JobInformationPayload {
  career_level: string | null;
  recent_job: string | null;
  recent_company: string | null;
  industry: string | null;
  job_position_start_date: string | null;
  job_position_end_date: string | null;
  certifications: string[];
}

export const getMyProfileService = createAPIAction(function getMyProfile(
  client: HttpClient,
): ActionsGenerator {
  return async function* () {
    yield getProfileData();

    try {
      const response = await client.GET(`${API_V1_PATH}/profile`);
      yield getProfileDataSuccess(response.data);
    } catch (error: any) {
      yield getProfileDataFailed();
    }
  };
});

export const getUserProfileService =
  (userId: string): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getUserProfile());
    try {
      const response = await api.get(`${API_V1_PATH}/users/${userId}`);
      dispatch(getUserProfileSucceeded(response.data));
    } catch (error: any) {
      const message = error?.message || errorMessageDefault;
      dispatch(showInfoModal({ type: 'error', message }));
      // Should not happen
      dispatch(getUserProfileFailed());
    }
  };

export const deleteAccount =
  (): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteUserAccountStarted());
    try {
      const response = await api.delete(
        `${API_V1_PATH}/profile/erase-personal-identifying-information`,
      );
      if (response.status === HttpStatusOk) {
        dispatch(deleteUserAccountSucceeded());
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        dispatch(logoutCompleted());
      } else {
        dispatch(deleteUserAccountFailed(new Error(response.statusText)));
      }
    } catch (error: any) {
      if (error instanceof Error) {
        dispatch(deleteUserAccountFailed(error));
      } else {
        dispatch(
          deleteUserAccountFailed(new Error(error.message ? error.message : 'Unknown Error')),
        );
      }
    }
  };

export const disableAccountService = (disabled: boolean) => (dispatch: Dispatch) => {
  api
    .post(`${API_V1_PATH}/auth/disable-account`, {
      disable: disabled,
    })
    .then(res => {
      if (res.data) {
        dispatch(
          showInfoModal({
            message: 'Your account has been disabled. Now you will be redirected to the home page.',
            type: 'success',
            shouldLogout: true,
          }),
        );
      } else throw new Error();
    })
    .catch(err => {
      dispatch(
        showInfoModal({
          type: 'error',
          message: err?.response?.data?.Message || errorMessageDefault,
        }),
      );
    });
};

export const unlockAccountService =
  (email: string): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    const res = await api.post(`${API_V1_PATH}/auth/request-unlock-account`, { email });
    try {
      if (res.data) {
        dispatch(
          showInfoModal({
            message: 'An email with instructions has been sent to unlock your account.',
            type: 'success',
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: error?.response?.data?.Message || errorMessageDefault,
        }),
      );
    }
  };

export const submitPersonalInformationForm = createAPIAction(function submitPersonalInformationForm(
  client: HttpClient,
): ActionsGenerator {
  return async function* (data: PersonalInformationModalState): AsyncGenerator<AnyAction> {
    const personalInfo: PersonalInfoPayload = {
      first_name: data.firstName,
      last_name: data.lastName,
      account_type: data.accountType,
    };

    const aboutMe: AboutMePayload = {
      about_me: data.about,
      country: data.country,
      city: data.city,
      accredited_investor: data.accreditedInvestor,
      connection_to_finance: data.connectionToFinance,
      linkedin_url: data.linkedinUrl,
      website_url: data.websiteUrl,
    };

    yield submitPersonalInformationStarted();
    const requests = [
      async (): Promise<AxiosResponse> => client.PUT(`${API_V1_PATH}/profile/about`, aboutMe),
      async (): Promise<AxiosResponse> =>
        client.PUT(`${API_V1_PATH}/profile/personal-information`, personalInfo),
      async (): Promise<AxiosResponse> =>
        client.PUT(`${API_V1_PATH}/profile/topics-of-interest`, {
          topics_of_interest: [
            ...(data.topicsOfInterest ?? []),
            ...(data.deletedTopicsOfInterest ?? [])
              .filter((item: ActivableComboBoxItem<number>): boolean => item.active)
              .map((item: ActivableComboBoxItem<number>): number => item.id),
          ],
        }),
      async (): Promise<AxiosResponse> =>
        client.PUT(`${API_V1_PATH}/profile/additional-topics-of-interest`, {
          additional_topics_of_interest: data.additionalTopicsOfInterest,
        }),
      async (): Promise<AxiosResponse> =>
        client.PUT(`${API_V1_PATH}/profile/regions-of-interest`, {
          regions_of_interest: data.regionsOfInterest,
        }),
    ];
    try {
      for (const request of requests) {
        await request();
      }

      yield getMyProfileService();
      yield me();
    } catch (error: any) {
      yield showInfoModal({
        type: 'error',
        message: error?.response?.data?.Message || errorMessageDefault,
      });
      console.warn(error);
    } finally {
      yield submitPersonalInformationCompleted();
    }
  };
});

export const updateJobInformation =
  (jobInformation: JobInformationPayload) => (dispatch: Dispatch) => {
    api
      .put(`${API_V1_PATH}/profile/job-information`, jobInformation)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(
            showInfoModal({
              message: 'Job information updated',
              type: 'success',
            }),
          );
          dispatch(getMyProfileService() as any);
        } else throw new Error();
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data?.Message || errorMessageDefault,
          }),
        );
      });
  };

export const getUsersTopicsOfInterest =
  (): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    api
      .get(`${API_V1_PATH}/profile/topics-of-interest`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getUserTopicsOfInterestSuccess(response.data));
        } else throw new Error();
      })
      .catch(err => {
        console.warn(err);
      });
  };

export const getUsersRegionsOfInterest =
  (): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    api
      .get(`${API_V1_PATH}/profile/regions-of-interest`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getUserRegionsOfInterestSuccess(response.data));
        } else throw new Error();
      })
      .catch(err => {
        console.warn(err);
      });
  };

export const updatePermission =
  (permission: UserPermission): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(updatePermissionsStarted(permission.code));
    api
      .post(`${API_V1_PATH}/profile/preferences/permission`, permission)
      .then((response: AxiosResponse): void => {
        if (response.status === HttpStatusOk) {
          dispatch(updatePermissionsSucceeded(permission));
        } else {
          dispatch(updatePermissionsFailed(new Error(response.data)));
        }
      })
      .catch((error: Error): void => {
        dispatch(updatePermissionsFailed(error));
      });
  };

export const getPreferences =
  (): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    api
      .get(`${API_V1_PATH}/profile/preferences`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(getPreferencesSucceeded(response.data));
        } else throw new Error(response.data);
      })
      .catch(err => {
        console.warn(err);
      });
  };
