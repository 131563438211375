import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

interface Props {
  readonly text: string | React.ReactElement;
}

export const ReadOnlyLabel: React.FC<Props> = ({ text }: Props): React.ReactElement => {
  return (
    <span className="flex items-center gap-1 pr-2 m-1 cursor-pointer">
      <span className="block">{text}</span>
      <span className="block">
        <SVGIcon name="lock" className="fill-current w-3.5 h-3.5 -mt-0.5" />
      </span>
    </span>
  );
};
