import { CapnoteMap } from 'components/capnoteMap';
import { DynamicColor, MarkerDot } from 'components/capnoteMap/components/markerDot';
import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import { noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CompaniesLocations,
  portfolioStateSelector,
  SubsidiariesMapCompany,
} from 'redux/reducers/portfolioReducer';
import {
  getPortfolioRevenuesMapService,
  getPortfolioSubsidiariesMapService,
} from 'redux/services/portfolioService';
import { OperationType } from 'types/operationType';
import { Portfolio } from 'types/organization/types';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { LegendItem } from 'views/Portfolios/Maps/legendItem';

const PortfolioDetailsMaps: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const { subsidiariesMap: subsidiaries } = useSelector(portfolioStateSelector);
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const [currentFilter, setCurrentFilter] = useState<string>(OperationType.subsidiaries);
  const [active, setActive] = useState<string | null>(null);

  useEffect((): VoidFunction | void => {
    if (!portfolioId) {
      return;
    }

    dispatch(getPortfolioSubsidiariesMapService(portfolioId));
    dispatch(getPortfolioRevenuesMapService(portfolioId));
    // const cancel1 = dispatch(getPortfoliosCompaniesMapAction());
    // const cancel2 = dispatch(getPortfoliosRevenuesMapAction());

    return (): void => {
      // cancel1();
      // cancel2();
    };
  }, [dispatch, portfolioId]);

  const markers = useMemo((): ReadonlyArray<CompaniesLocations<SubsidiariesMapCompany>> => {
    switch (currentFilter) {
      case OperationType.subsidiaries:
        return subsidiaries;
      case OperationType.revenues:
        return [];
      default:
        return [];
    }
  }, [subsidiaries, currentFilter]);

  const colors = useMemo((): Record<string, DynamicColor> => {
    return markers.reduce(
      (
        colors: Record<string, DynamicColor>,
        { companies }: CompaniesLocations<SubsidiariesMapCompany>,
      ): Record<string, DynamicColor> =>
        companies.reduce(
          (
            colors: Record<string, DynamicColor>,
            company: SubsidiariesMapCompany,
          ): Record<string, DynamicColor> => {
            const keys = Object.keys(colors);
            if (colors[company.id]) {
              return colors;
            }

            return { ...colors, [company.id]: ((keys.length + 1) % 15) as DynamicColor };
          },
          colors,
        ),
      {},
    );
  }, [markers]);

  const portfolios = useMemo((): readonly Portfolio[] => {
    return [];
  }, []);

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex items-center justify-end my-4">
        <DropdownFilter selectedId={currentFilter} options={options} onSelect={setCurrentFilter} />
      </div>
      <div className="flex-grow">
        <CapnoteMap markers={markers} colors={colors} active={active} onMarkerClick={noop} />
        <div className="flex items-center gap-8 flex-wrap mt-2">
          {portfolios.map((portfolio: Portfolio): React.ReactNode => {
            return (
              <LegendItem key={portfolio.id} data={portfolio.id} onHover={setActive}>
                <div className="flex items-center gap-1 cursor-pointer">
                  <MarkerDot size="normal" color={colors[portfolio.id]} disableHover={true} />
                  <p className="text-gray">
                    <span>{portfolio.name}</span>
                  </p>
                </div>
              </LegendItem>
            );
          })}
        </div>
      </div>
      <DataDisclaimer />
    </div>
  );
};

export default PortfolioDetailsMaps;

const options: ReadonlyArray<DropdownItem<OperationType>> = [
  { id: OperationType.subsidiaries, label: 'Show: Companies' },
  // TODO: enable someday
  /* { id: OperationType.revenues, label: 'Show: Revenues' } */
];
