import { ConditionalRender } from 'components/conditionalRenderer';
import { Disabler } from 'components/disabler';
import SVGIcon from 'components/icons/SVGIcon';
import MenuOptionButton from 'components/sidebar/components/MenuOptionButton';
import { MenuGroupItem } from 'components/sidebar/types';
import { ClassResolverFn } from 'components/tabs/types';
import { Tooltip } from 'components/tooltip';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  readonly disabled: boolean;
  readonly content: MenuGroupItem;
  readonly isOpen: boolean;

  onToggleOpen(): void;
}

const MenuOptionContent: React.FC<Props> = ({ content, disabled, isOpen, onToggleOpen }: Props) => {
  const { children } = content;
  const linkClassName = useMemo((): ClassResolverFn | string => {
    if (!content.selectable) {
      return classResolver({ isActive: false });
    } else {
      return classResolver;
    }
  }, [content.selectable]);

  const expanderClassName = useMemo((): string => {
    const base = 'w-4 h-4 text-gray fill-current cursor-pointer duration-500';
    if (isOpen) {
      return `${base} transform rotate-180`;
    } else {
      return base;
    }
  }, [isOpen]);

  const labelElement = useMemo((): React.ReactElement => {
    return typeof content.label === 'string' ? (
      <p className="font-poppinsSemiBold uppercase text-sm cursor-default">{content.label}</p>
    ) : (
      content.label
    );
  }, [content.label]);

  const TooltipComponent = React.useCallback(
    ({ children }: React.PropsWithChildren): React.ReactElement => {
      if (content.comingSoon) {
        return (
          <Tooltip content="COMING SOON" variant="light" placement="right">
            <div>
              <Disabler disabled={true}>{children}</Disabler>
            </div>
          </Tooltip>
        );
      } else if (content.tooltipText) {
        return (
          <Tooltip content={content.tooltipText} placement="right">
            <div>{children}</div>
          </Tooltip>
        );
      }

      return <>{children}</>;
    },
    [content.comingSoon, content.tooltipText],
  );

  return (
    <>
      <div className="flex items-center relative justify-between">
        <div>
          {!disabled && !!content.path ? (
            <TooltipComponent>
              <NavLink className={linkClassName} to={content.path}>
                {content.label}
              </NavLink>
            </TooltipComponent>
          ) : (
            <TooltipComponent>{labelElement}</TooltipComponent>
          )}
        </div>

        <Disabler disabled={!!content.comingSoon}>
          <ConditionalRender renderIf={children.length > 0}>
            <SVGIcon className={expanderClassName} name="down-arrow" onClick={onToggleOpen} />
          </ConditionalRender>
        </Disabler>
      </div>

      <Disabler disabled={!!content.comingSoon}>
        <ConditionalRender renderIf={isOpen}>
          {content.children.map(option => (
            <div key={option.id} className="pl-4 my-3 relative w-10/12" id={`sidebar-${option.id}`}>
              <MenuOptionButton item={option} />
            </div>
          ))}
        </ConditionalRender>
      </Disabler>
    </>
  );
};

export default MenuOptionContent;

const classResolver = ({ isActive }: { isActive: boolean }): string => {
  if (isActive) {
    return 'font-poppinsSemiBold text-sm text-blue';
  } else {
    return 'font-poppinsSemiBold text-sm text-gray';
  }
};
