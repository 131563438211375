import React from 'react';
import { useSelector } from 'react-redux';
import { userNotificationsSelector } from 'redux/reducers/sharedReducer';

export const AlertsMenuLabel: React.FC = (): React.ReactElement => {
  const notifications = useSelector(userNotificationsSelector);

  return (
    <p>
      ALERTS {notifications.total_count > 0 ? <span>({notifications.total_count})</span> : null}
    </p>
  );
};
