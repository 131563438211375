import Button from 'components/buttons/Button/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import { FeatureItem } from 'redux/reducers/landingPageReducer';
import { properCase } from 'utils';
import strings from 'views/Landing/LandingPage/components/Features/texts';

interface Props {
  readonly item: FeatureItem | null;
}

export const Feature: React.FC<Props> = ({ item }: Props): React.ReactElement | null => {
  if (item === null) return null;

  return (
    <div className="w-full flex justify-center flex-column md:flex-row description-container">
      <div className="md:w-full md:mx-auto md:pr-4 lg:w-1/2 2xl:w-5/12 text-left mt-20 mr-auto lg:pr-12 4xl:pr-0 ">
        <h1 className="2xl:text-4xl">{properCase(item.label)}</h1>
        <h3 className="2xl:text-xl text-blue my-4">{item.subtitle}</h3>
        <p className="lg:text-left 2xl:text-md mb-12">{item.body}</p>
        <Link to="/sign-up">
          <Button width="w-60" rounded="rounded-sm" label={strings.signUpBtn} />
        </Link>
      </div>
      <div className=" md:w-full lg:w-1/2 2xl:w-7/12 flex">
        <div style={{ maxWidth: '684px' }} className="ml-auto mt-14">
          <img style={imagesStyle} src={item.image} alt="data and analysis" />
        </div>
      </div>
    </div>
  );
};

const imagesStyle = { boxShadow: '-10px 20px 50px -27px rgba(0, 0, 0, 0.58)' };
