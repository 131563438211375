import React from 'react';
import { portfolioItemTypeLabels } from 'types/portfolioItem';
import {
  isPortfolioCompanyItemSearchResult,
  PortfolioItemSearchResult,
} from 'views/Portfolios/PortfoliosModal/api';

interface Props {
  readonly item: PortfolioItemSearchResult | null;
  readonly typed?: boolean;
}

export const ItemRenderer: React.FC<Props> = ({
  item,
  typed = true,
}: Props): React.ReactElement => {
  if (!item) {
    return (
      <div className="opacity-30">
        <div className="flex items-center gap-1">
          <p>Please select an item</p>
          <p className="italic text-blue">&nbsp;</p>
        </div>
        {typed && <p className="text-xs text-gray">Item Type</p>}
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center gap-1">
        <p>{item.name}</p>
        {isPortfolioCompanyItemSearchResult(item) ? (
          <p className="italic text-blue">{item.data.ticker}</p>
        ) : (
          <></>
        )}
      </div>
      {typed && <p className="text-xs text-gray">{portfolioItemTypeLabels[item.type]}</p>}
    </div>
  );
};
