import { useMemo } from 'react';
import { PeerComparisons, PeerComparisonsSummary } from 'types/peerComparisons';

export const useSummary = (peerComparisons: PeerComparisons | null): PeerComparisonsSummary => {
  return useMemo((): PeerComparisonsSummary => {
    return (
      peerComparisons?.summary ?? {
        high: {},
        low: {},
        median: {},
        mean: {},
      }
    );
  }, [peerComparisons]);
};
