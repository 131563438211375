import { Elements } from '@stripe/react-stripe-js';
import { Appearance, CssFontSource, StripeElementsOptions, StripeError } from '@stripe/stripe-js';
import { ErrorBox } from 'components/errorBox';
import { Modal } from 'components/modal';
import { PaymentForm } from 'components/paymentForm';
import SpinnerLoader from 'components/spinnerLoader';
import { StarRating } from 'components/starRating';
import { SuccessBox } from 'components/successBox';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initializeStripe } from 'utils/initializeStripe';
import {
  useCheckoutMarketplaceItemQuery,
  useGetMarketplaceItemQuery,
  useLazyLibraryQuery,
} from 'views/EarnAndBuy/api';

enum PaymentStatus {
  uninitialized = 'uninitialized',
  started = 'started',
  succeeded = 'succeeded',
  failed = 'failed',
  canceled = 'canceled',
}

export const MarketplaceItemCheckout: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const queryParameters = useQueryParameters();

  const [refetchLibrary] = useLazyLibraryQuery();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.uninitialized);
  const [error, setError] = useState<StripeError | null>(null);

  const { listingId = '' } = useParams<{ readonly listingId: string }>();
  const { data: payment, isLoading: loadingPayment } = useCheckoutMarketplaceItemQuery(listingId);
  const { data: item, isLoading: loadingItem } = useGetMarketplaceItemQuery(listingId);

  const stripeOptions = useMemo((): StripeElementsOptions => {
    if (!payment || payment.client_secret === null) {
      return { appearance: stripeAppearance };
    }

    return {
      clientSecret: payment.client_secret,
      fonts: stripeFonts,
      appearance: stripeAppearance,
    };
  }, [payment]);

  const handlePaymentCancel = useCallback((): void => {
    setPaymentStatus(PaymentStatus.canceled);
    setTimeout((): void => {
      navigate(-1);
    }, 0);
  }, [navigate]);

  const handlePaymentStart = useCallback((): void => {
    setPaymentStatus(PaymentStatus.started);
  }, []);

  const handleSuccess = useCallback((): void => {
    setPaymentStatus(PaymentStatus.succeeded);
  }, []);

  const handleFailure = useCallback((error: StripeError): void => {
    setPaymentStatus(PaymentStatus.failed);
    setError(error);
  }, []);

  const handlePaymentSoftError = useCallback((): void => {
    setPaymentStatus(PaymentStatus.failed);
  }, []);

  const paymentCompleted = useCallback((): void => {
    void refetchLibrary(queryParameters);
    setTimeout((): void => {
      navigate('/earn-n-buy/library');
    }, 0);
  }, [navigate, queryParameters, refetchLibrary]);

  const retryPayment = useCallback((): void => {
    setPaymentStatus(PaymentStatus.uninitialized);
    setError(null);
  }, []);

  if (!payment || !item) {
    if (loadingItem || loadingPayment) {
      return <SpinnerLoader visible={true} />;
    }

    return <></>;
  }

  return (
    <div className="relative flex flex-grow mx-auto w-full gap-4">
      <div className="flex flex-col slef-stretch w-1/3 mb-2 mt-3">
        <h1>Payment Information</h1>
        <p className="text-md text-gray">Please review your order and enter your payment details</p>
        <Elements key={stripeOptions.clientSecret} stripe={globalStripe} options={stripeOptions}>
          <PaymentForm
            payment={payment}
            withTnc={false}
            onPaymentCanceled={handlePaymentCancel}
            onPaymentStarted={handlePaymentStart}
            onPaymentSucceeded={handleSuccess}
            onPaymentFailed={handleFailure}
            onPaymentSoftError={handlePaymentSoftError}
          />
        </Elements>
      </div>
      <div className="bg-blue text-white self-stretch w-96 p-5 mb-2 mt-3 rounded">
        <h3 className="mb-4">Your order</h3>
        <h4 className="mb-2">{item.title}</h4>
        <p className="opacity-90">{item.description}</p>
        <p className="my-5">
          <a className="underline" href={item.content_uri}>
            Preview
          </a>
        </p>
        <div className="inline-block">
          <StarRating value={item.star_rating.average} color="#ffffff" />
        </div>
      </div>

      <SpinnerLoader visible={paymentStatus === PaymentStatus.started} />

      <Modal isOpen={paymentStatus === PaymentStatus.failed} onClose={retryPayment}>
        <ErrorBox
          title="There was a problem"
          message="We were unable to process your payment, please try again"
          details={error?.message}
          onClose={retryPayment}
        />
      </Modal>

      <Modal isOpen={paymentStatus === PaymentStatus.succeeded} onClose={paymentCompleted}>
        <SuccessBox
          title="Purchase Completed"
          message={successMessage}
          onClose={paymentCompleted}
        />
      </Modal>
    </div>
  );
};

const globalStripe = initializeStripe();
const stripeAppearance: Appearance = {
  theme: 'stripe',
  variables: { fontFamily: 'Poppins', fontWeightNormal: '500' },
};

const fontUrl = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900';
const stripeFonts: CssFontSource[] = [
  {
    cssSrc: fontUrl,
  },
];

const successMessage =
  'Your payment was process successfully, go to your library and enjoy your new content. Close this window to go to your library.';
