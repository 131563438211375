import { CommonFilter } from 'components/filters/helpers';
import { ColumnDefinition } from 'components/table/types';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import VALUES_FORMATTERS, {
  ONE_HUNDRED_TRILLIONS,
  ONE_MILLION,
} from 'views/DataAnalysis/valuesFormatters';

const defaultLocale = 'en';

const numericFormatters = {
  currencyWithDecimals: new Intl.NumberFormat(defaultLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  }),
  currencyWithoutDecimals: new Intl.NumberFormat(defaultLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  }),
  percentage: new Intl.NumberFormat(defaultLocale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent',
    useGrouping: true,
  }),
  integer: new Intl.NumberFormat(defaultLocale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    useGrouping: false,
  }),
};

export const properCase = (str: string) =>
  str?.toLowerCase().replace(/^\w|\s\w/g, (str: string) => str.toUpperCase());

export const capitalizeFirstLetters = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export const formatPercentage = (value: number | null): string => {
  if (value === null || isNaN(value)) {
    return '-';
  }

  const formatter = numericFormatters.percentage;
  return formatter.format(value);
};

export const formatInteger = (value: number | null): string => {
  if (value === null || isNaN(value)) {
    return '-';
  }

  const formatter = numericFormatters.integer;
  return formatter.format(value);
};

export const formatNumber = (value: number | null, withoutDecimals?: boolean) => {
  if (value === null || isNaN(value)) {
    return '-';
  }

  const formatter = withoutDecimals
    ? numericFormatters.currencyWithoutDecimals
    : numericFormatters.currencyWithDecimals;

  return formatter.format(value);
};

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTodayFormatDate = () => format(new Date(), 'dd MMM yy HH:mm');

export const getFormatDate = (d: string | Date, details?: boolean) => {
  if (details) return format(new Date(d), "EEEE dd MMM yy '\xa0\xa0\xa0' HH:mm");
  return format(new Date(d), 'dd MMM yy');
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent as string;
};

export const truncate = (input: string, numberToCut: number) =>
  input.length > numberToCut ? `${input.substring(0, numberToCut)}...` : input;

export function isValidEmail(email: string) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email);
}

export const getMarketChangeValue = (marketPercentage: number) => {
  if (marketPercentage > 0) return 'Gain';
  if (marketPercentage < 0) return 'Loss';
  return 'Neutral';
};

export const getLegendForFinancialStatements = (largestNumber = 0, currency = 'USD') => {
  if (largestNumber >= ONE_HUNDRED_TRILLIONS) {
    return `${currency} 000000 Except Percentages, Ratios and Share Data`;
  } else if (largestNumber > ONE_MILLION) {
    return `${currency} 000 Except Percentages, Ratios and Share Data`;
  }
  return currency;
};

export const formatToTrainCase = (identifierText: string) => {
  return identifierText.toLocaleLowerCase().replace(/\s/g, '-');
};

export const preRegisterMode: boolean = process.env.REACT_APP_WORKING_MODE === 'PreRegister';
export const isBeta = process.env.REACT_APP_BITBUCKET_BRANCH === 'beta';
export const isDev =
  process.env.REACT_APP_BITBUCKET_BRANCH === 'develop' ||
  process.env.REACT_APP_BITBUCKET_BRANCH === 'local';

export const experimentalFeature = <T>(thing: T, fallbackValue: T): T => {
  switch (process.env.REACT_APP_BITBUCKET_BRANCH) {
    case 'develop':
    case 'local':
      return thing;
    default:
      return fallbackValue;
  }
};

export const experimentalColumn = <T>(column: ColumnDefinition<T>): Array<ColumnDefinition<T>> => {
  return experimentalFeature<Array<ColumnDefinition<T>>>([column], []);
};

export const experimentalFilter = (filter: CommonFilter): CommonFilter[] => {
  return experimentalFeature<CommonFilter[]>([filter], []);
};

export const title = (value: string): string => {
  const parts = value.split(' ');
  return parts.map(capitalize).join(' ');
};

export const formatValue = (value: any, unit: string, largestPossibleNumber: number): string => {
  const formatter = VALUES_FORMATTERS[unit];
  return formatter?.(value, largestPossibleNumber) ?? value;
};

export const formatPrice = (value: number) => {
  const dollars = Math.floor(value);
  const cents = Math.round((value - dollars) * 100);

  return `$${dollars}<sup>${cents < 10 ? `0${cents}` : cents}</sup>`;
};
