import { AlgoNotesTable } from 'components/algoNotesTable';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  userPeerGroupAlgoNotesSelector,
  userPeerGroupProcessingStateSelector,
} from 'redux/reducers/userPeerGroupsReducer';
import { fetchUserPeerGroupAlgoNotesAction } from 'redux/services/userPeerGroupsService';
import { ProcessingStateEnum } from 'types/processingState';

export const AlgoNotes: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const processingState = useSelector(userPeerGroupProcessingStateSelector);
  const { algo_notes: notes, focus_company: company } = useSelector(userPeerGroupAlgoNotesSelector);
  const { peerGroupId, noteId } = useParams<{
    readonly peerGroupId: string;
    readonly noteId: string;
  }>();

  if (!peerGroupId) {
    throw new Error('cannot reach this page without the company id in the path');
  }

  const handleRefresh = useCallback(
    (parameters: Record<string, string>): VoidFunction => {
      return dispatch(fetchUserPeerGroupAlgoNotesAction(peerGroupId, parameters));
    },
    [dispatch, peerGroupId],
  );

  return (
    <div className="mt-4">
      <p>
        These notes are for the focus company -{' '}
        <Link
          className="text-blue font-poppinsMedium"
          to={`/data-analysis/company/details/${company.id}`}
        >
          {company.name}
        </Link>
      </p>

      <div>
        <AlgoNotesTable
          algoNotesPage={notes}
          loading={processingState.state === ProcessingStateEnum.processing}
          type="peer-group"
          currentNoteId={noteId}
          objectId={peerGroupId}
          refresh={handleRefresh}
        />
      </div>
    </div>
  );
};
