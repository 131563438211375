import React from 'react';
import { Link } from 'react-router-dom';

export const UpgradeButton: React.FC = (): React.ReactElement => {
  return (
    <Link className={buttonClassName} to="/upgrade">
      Change Plan
    </Link>
  );
};

export const buttonClassName =
  'bg-blue uppercase font-poppinsSemiBold text-sm text-white py-2 px-6 rounded block' +
  ' hover:bg-blue transition-colors duration-300 flex justify-center select-plan-btn mt-5 w-max mx-auto';
