import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SensitivityCategory } from 'types/sensitivities';
import { PortfolioSensitivities } from 'views/Portfolios/Details/Sensitivities/index';

export const Sensitivities: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      {items.map(
        (value: SensitivityCategory): React.ReactElement => (
          <Route key={value} path={value} element={<PortfolioSensitivities category={value} />} />
        ),
      )}

      <Route path="*" element={<Navigate to={SensitivityCategory.tags} />} />
    </Routes>
  );
};

const items: readonly SensitivityCategory[] = Object.values(SensitivityCategory);
