import { ConditionalRender } from 'components/conditionalRenderer';
import SVGIcon from 'components/icons/SVGIcon';
import { Tooltip } from 'components/tooltip';
import React, { useCallback, useMemo, useState } from 'react';
import { ValidationError } from 'yup';

interface Props {
  readonly value: string;
  readonly label: string;

  readonly autoComplete?: 'new-password';

  readonly id?: string;
  readonly type?: 'text' | 'email' | 'password';
  readonly error?: ValidationError;
  readonly readOnly?: boolean;

  readonly rightAdornment?: React.ReactElement;

  onChange(value: string): void;
  onBlur?(): void;
  onFocus?(): void;
  onSubmit?(): void;
}

// FIXME: Use this as the main input everywhere
export const Input: React.FC<Props> = ({
  value,
  id,
  type = 'text',
  label,
  error,
  rightAdornment,
  autoComplete,
  readOnly = false,
  onChange,
  onSubmit,
}: Props): React.ReactElement => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const placeholderClass = useMemo((): string => {
    const baseClass = 'absolute inset-0 pointer-events-none text-xs transition-all duration-150';
    if (hasFocus || value.trim() !== '') {
      return [baseClass, 'font-poppinsMedium'].join(' ');
    } else {
      return [baseClass, 'text-sm leading-tall pt-5 px-3 font-poppins opacity-30'].join(' ');
    }
  }, [hasFocus, value]);

  const focusLineClass = useMemo((): string => {
    const baseClass = 'absolute -bottom-0.5 h-0.5 bg-blue transition-all duration-150';
    if (hasFocus) {
      return [baseClass, 'left-0 w-full'].join(' ');
    } else {
      return [baseClass, 'left-1/2 w-0'].join(' ');
    }
  }, [hasFocus]);

  const inputClass = useMemo((): string => {
    const baseClass =
      'relative font-poppins text-sm leading-tall outline-none w-full bg-transparent';
    if (rightAdornment) {
      return [baseClass, 'pl-3 pr-12'].join(' ');
    } else {
      return [baseClass, 'px-3'].join(' ');
    }
  }, [rightAdornment]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (readOnly) {
        event.preventDefault();
        return;
      }

      setHasFocus(true);
    },
    [readOnly],
  );
  const handleBlur = useCallback((): void => setHasFocus(false), []);
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      switch (event.key) {
        case 'Enter':
        case 'Return':
          onSubmit?.();
      }
    },
    [onSubmit],
  );

  const errorMessage = useMemo((): string => error?.message ?? '', [error]);

  return (
    <div id={id} className={inputContainerClass}>
      <label htmlFor={`${id}-input`} className={placeholderClass}>
        {label}
      </label>
      <input
        id={`${id}-input`}
        name={id}
        type={type}
        value={value}
        autoComplete={autoComplete}
        className={inputClass}
        readOnly={readOnly}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
      />
      <div className={focusLineClass} />
      <div className="flex items-center mx-1 px-2 absolute w-auto top-5 right-0 bottom-0">
        <ConditionalRender renderIf={!!rightAdornment}>
          <div className="w-5 mr-2 transition-all duration-150">{rightAdornment}</div>
        </ConditionalRender>
        <ConditionalRender renderIf={!!error}>
          <Tooltip content={errorMessage} variant="error">
            <SVGIcon id={`${id}-error`} name="exclamation-circle" className="w-5 h-5" />
          </Tooltip>
        </ConditionalRender>
      </div>
    </div>
  );
};

const inputContainerClass = 'relative pt-6 text-left border-b border-gray-medium';
