import { BaseTabProps } from 'components/tabs/components/baseTabProps';
import { LinkTab } from 'components/tabs/components/linkTab';
import { Tab } from 'components/tabs/components/tab';
import { TabsLayout } from 'components/tabs/components/tabsLayout';
import { TabsVariant } from 'components/tabs/types';
import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

/**
 * Shrink the whole tab bar, for aesthetic reasons
 *
 * 0: Means do not stretch
 * 2: Means reserve twice the needed space
 * 3: Means reserve three times the needed space
 * 6: Means reserve six times the needed space
 */
export type ShrinkFactor = 0 | 2 | 3 | 4 | 5 | 6;

interface Props {
  readonly variant?: TabsVariant;
  readonly shrinkFactor?: ShrinkFactor;
  readonly alignment?: 'center' | 'left';
}

export const Tabs: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  shrinkFactor = 0,
  variant = TabsVariant.tabs,
  alignment = 'left',
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const childNodes = React.Children.toArray(children);

  const elements = useMemo((): React.ReactElement[] => {
    return childNodes.map((node: React.ReactNode): React.ReactElement => {
      if (React.isValidElement<BaseTabProps>(node)) {
        switch (node.type) {
          case Tab:
          case LinkTab:
          case Route:
            break;
          default:
            throw new Error(
              'only <Tab /> or <Route /> elements are acceptable as <Tabs /> children',
            );
        }

        return node;
      } else {
        throw new Error('only <Tab /> element nodes are acceptable as <Tabs /> children');
      }
    });
  }, [childNodes]);

  // Exclude route elements
  const tabElements = useMemo(
    (): React.ReactElement[] =>
      elements.filter((element: React.ReactElement): boolean => element.type === Tab),
    [elements],
  );

  const tabBarItems = useMemo((): React.ReactElement[] => {
    return elements
      .filter(
        (element: React.ReactElement): boolean => element.type === LinkTab || element.type === Tab,
      )
      .map((element: React.ReactElement): React.ReactElement => {
        const { props } = element;
        return React.cloneElement(element, {
          ...props,
          type: element.type === Tab ? 'tab' : 'link',
        });
      });
  }, [elements]);

  const implicitIndexRoutesCount = useMemo(
    (): number =>
      elements
        .filter(
          (node: React.ReactNode): boolean => React.isValidElement(node) && node.type !== LinkTab,
        )
        .reduce(
          (count: number, { props }: React.ReactElement): number =>
            count + (props.path !== undefined ? 0 : 1),
          0,
        ),
    [elements],
  );

  // Get the first non-disabled tab props
  const firstTabProps = useMemo(
    (): BaseTabProps =>
      tabElements.find((tab: React.ReactElement): boolean => {
        const { props } = tab;
        if (props.comingSoon || props.disabled) {
          return false;
        }

        return !!props.path;
      })?.props,
    [tabElements],
  );

  if (implicitIndexRoutesCount > 1) {
    throw new Error('you cannot have more than 1 implicit index route');
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <TabsLayout
            items={tabBarItems}
            tabButtonVariant={variant}
            shrinkFactor={shrinkFactor}
            alignment={alignment}
          />
        }
      >
        {elements.map((node: React.ReactElement): React.ReactElement => {
          const { props } = node;
          const { path } = props;

          if (path) {
            if (!path.match(/(?:\/?[a-z-]+\/)*[a-z-]*(?:\/\*)?/)) {
              throw new Error('invalid path for route: ' + path);
            }

            return <Route key={node.key} element={props.element} path={props.path} />;
          } else {
            return <Route key={node.key} element={props.element} index />;
          }
        })}

        {/* Create a redirection route when we don't have an implicit index */}
        {implicitIndexRoutesCount === 0 && firstTabProps?.path && (
          <Route path="*" element={<Navigate to={convertToUrl(firstTabProps.path)} replace />} />
        )}
      </Route>
    </Routes>
  );
};

export { TabsVariant };

const convertToUrl = (path: string): string => {
  if (path.match(/\/?\*$/)) {
    return path.replace(/\/?\*$/, '');
  }

  return path;
};
