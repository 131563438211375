import axios, { CancelToken } from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { authenticatedSelector } from 'redux/reducers/authReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const useCreateReferrerCode = (): void => {
  const authenticated = useSelector(authenticatedSelector);

  const create = React.useCallback(async (cancelToken: CancelToken): Promise<void> => {
    try {
      await api.post(`${API_V1_PATH}/referrals/code`, null, {
        cancelToken: cancelToken,
      });
    } catch (error: any) {
      console.warn(error);
    }
  }, []);

  React.useEffect((): void | VoidFunction => {
    if (process.env.REACT_APP_DATA_PORTAL) {
      return;
    }

    if (!authenticated) {
      return;
    }
    const cancelToken = axios.CancelToken.source();

    void create(cancelToken.token);
    return (): void => {
      cancelToken.cancel();
    };
  }, [authenticated, create]);
};
