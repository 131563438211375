import BbcNews from 'assets/images/bbc.png';
import FallbackFeedItemImage from 'assets/images/error_icon_capnote.png';
import FortuneNews from 'assets/images/fortune.png';
import UserPlaceholder from 'assets/images/genderless-icon.svg';
import { PostBody } from 'components/postBodyInput/types';
import { ProfileResponse } from 'redux/reducers/profileReducer';

export interface DeleteFeedCommentResult {
  readonly id: string;
  readonly children_count: number;
  readonly participated: boolean;
  readonly item_type: 'feed-comments' | 'posts' | 'news';
}

export interface FeedResponse<PublisherType extends string | SocialUser> {
  readonly id: string;
  readonly title: string;
  readonly body: PostBody;
  readonly tags: any[];
  readonly publisher: PublisherType;
  readonly url: string;
  readonly date: string;
  readonly category: string;
  readonly category_label: string;
  readonly saved: boolean;
  readonly liked: boolean;
  readonly commented: boolean;
  readonly disliked: boolean;
  readonly reported: boolean;
  readonly likes_count: number;
  readonly dislikes_count: number;
  readonly comments_count: number;
  readonly shares_count: number;
}

// eslint-disable-next-line no-unused-vars
type NewsResponse = FeedResponse<string>;

const isNewsResponse = (response: FeedResponse<any>): response is NewsResponse =>
  response.category === FeedCategory.news;

export interface SocialUser {
  readonly id: string;
  readonly name: string;
  readonly about: string;
  readonly followed_by_you: boolean;
  readonly follows_you: boolean;
  readonly accredited_investor: boolean;
  readonly photo_url?: string;
}

export class SocialUser {
  public static fromProfile(profile: ProfileResponse): SocialUser {
    const { about, personal_information } = profile;

    return {
      id: '',

      about: about.about_me,
      accredited_investor: about.accredited_investor,

      followed_by_you: profile.followed_by_you ?? false,
      follows_you: profile.follows_you ?? false,

      photo_url: profile.photo_url,
      name: `${personal_information.first_name} ${personal_information.last_name}`,
    };
  }
}

const isPublisher = (candidate: string | SocialUser): candidate is SocialUser =>
  typeof candidate !== 'string';

export interface GenericFeedItem<PublisherType extends string | SocialUser> {
  readonly type: 'feedItem';
  readonly id: string;
  readonly title: string;
  readonly body: PostBody;
  readonly image: string;
  readonly source: string;
  readonly tags: string[];
  readonly publisher: PublisherType;
  readonly url: string;
  readonly date: string;
  readonly category: FeedCategory;
  readonly saved: boolean;
  readonly liked: boolean;
  readonly disliked: boolean;
  readonly reported: boolean;
  readonly commented: boolean;
  readonly likesCount: number;
  readonly dislikesCount: number;
  readonly commentsCount: number;
  readonly sharesCount: number;
  readonly publisherId?: string;
  readonly publisherName: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class GenericFeedItem<PublisherType extends string | SocialUser> {
  public static fromOtherObject<PublisherType extends string | SocialUser>(
    item: FeedResponse<PublisherType>,
  ): GenericFeedItem<PublisherType> {
    const { publisher } = item;

    return {
      type: 'feedItem',
      id: item.id as string,
      title: item.title as string,
      body: item.body,
      tags: item.tags as string[],
      publisher: item.publisher,
      url: item.url as string,
      date: item.date as string,
      category: item.category as FeedCategory,
      saved: item.saved as boolean,
      liked: item.liked as boolean,
      disliked: item.disliked as boolean,
      reported: item.reported as boolean,
      commented: item.commented as boolean,
      likesCount: item.likes_count as number,
      dislikesCount: item.dislikes_count as number,
      commentsCount: item.comments_count as number,
      sharesCount: item.shares_count as number,
      source: '',
      image: isNewsResponse(item) ? getImage(item) : UserPlaceholder,
      publisherName: isPublisher(publisher)
        ? publisher.name
        : typeof publisher === 'string'
        ? publisher
        : '',
      publisherId: isPublisher(publisher) ? publisher.id : undefined,
    };
  }

  public static fromJson<PublisherType extends string | SocialUser>(
    json: FeedResponse<PublisherType>,
  ): GenericFeedItem<PublisherType> {
    return GenericFeedItem.fromOtherObject(json);
  }
}

export type FeedItem = GenericFeedItem<string | SocialUser>;
export type Post = GenericFeedItem<SocialUser>;
export type News = GenericFeedItem<string>;

export enum FeedCategory {
  post = 'POST',
  news = 'NEWS',
  notification = 'NOTIFICATION',
  none = '',
}

export const postCategoryLabels: { [key in FeedCategory]: string } = {
  [FeedCategory.post]: 'User Post',
  [FeedCategory.news]: 'News & Events',
  [FeedCategory.notification]: 'Notification',
  [FeedCategory.none]: '',
};

export enum FeedFilterCategory {
  none = 'NONE',
  news = 'NEWS',
  bookmarks = '',
  post = 'POST',
  myInterests = 'MY_INTERESTS',
}

const newsLogos: Record<string, string> = {
  BBC: BbcNews,
  Fortune: FortuneNews,
};

const getImage = (response: NewsResponse | News): string => {
  if (response.url) {
    return `https://www.google.com/s2/favicons?sz=48&domain=${response.url}`;
  } else if (newsLogos[response.publisher]) {
    return newsLogos[response.publisher];
  } else {
    return FallbackFeedItemImage;
  }
};
