import { Filters } from 'components/filters';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { ComingSoon } from 'components/tooltip/comingSoon';
import { useSort } from 'hooks/useSort';
import { noop } from 'lodash';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  IndustryStats,
  loadingIndustriesSelector,
  myIndustriesSelector,
} from 'redux/reducers/industryReducer';
import { CountryDropdown } from 'views/DataAnalysis/Industry/Details/countryDropdown';
import columns from 'views/DataAnalysis/Industry/myIndustriesColumns';

export const MyIndustries: React.FC = (): React.ReactElement => {
  const [sorting, handleSortChange] = useSort<IndustryStats>(
    'percentage_of_total_number_of_companies',
    SortOrder.descending,
  );

  const navigate = useNavigate();
  const loading = useSelector(loadingIndustriesSelector);
  const myIndustriesPage = useSelector(myIndustriesSelector);

  const handleRowClicked = useCallback(
    (row: IndustryStats): void => {
      navigate(`/data-analysis/industry/details/${row.id}/overview`);
    },
    [navigate],
  );

  return (
    <div className="relative flex-1">
      <div className="mt-3 flex justify-between">
        <ComingSoon>
          <Filters config={[]} onChange={noop} />
        </ComingSoon>
        <CountryDropdown />
      </div>
      <Pagination totalPages={myIndustriesPage.page_count}>
        <Table
          columns={columns}
          rows={myIndustriesPage.data}
          sortBy={sorting}
          onSortChange={handleSortChange}
          onRowClick={handleRowClicked}
        />
      </Pagination>

      <SpinnerLoader visible={loading} />
    </div>
  );
};
