import React, { useCallback } from 'react';

interface Props {
  readonly renderIf: boolean;
  readonly disabled?: boolean;
}

export const ConditionalRender: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  disabled = false,
  renderIf,
}: React.PropsWithChildren<Props>): React.ReactElement | null => {
  const ignoreClicks = useCallback((event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  if (!renderIf) {
    return null;
  }

  if (disabled) {
    return (
      <div onClickCapture={ignoreClicks} className="opacity-50">
        {children}
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
