import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { passwordSchema, YupError } from 'utils/forms';
import { Input } from 'views/Landing/component/input';
import { PasswordError } from 'views/Landing/component/passwordError';
import { ValidationError } from 'yup';

interface Props {
  readonly id?: string;
  readonly value: string;
  readonly label?: string;
  readonly error?: ValidationError;
  readonly withHelp: boolean;
  readonly create: boolean;

  onChange(value: string): void;
  onError?(error: YupError | undefined): void;
}

export const PasswordInput: React.FC<Props> = ({
  id,
  value,
  error,
  create = false,
  withHelp = false,
  label = 'Password',
  onChange,
  onError,
}: Props): React.ReactElement => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password');
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const toggleShowPassword = useCallback((): void => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }, [inputType]);

  useEffect((): void => {
    if (withHelp) {
      return;
    }

    if (value.trim() === '') {
      onError?.(undefined);
      return;
    }

    passwordSchema
      .validate(value, { abortEarly: true })
      .then((): void => {
        onError?.(undefined);
      })
      .catch((error: any): void => {
        onError?.(error?.message);
      });
  }, [onError, value, withHelp]);

  const requiredError = useMemo(
    (): ValidationError | undefined => (error?.type !== 'requirements' ? error : undefined),
    [error],
  );

  return (
    <>
      <Input
        id={id}
        value={value}
        type={inputType}
        label={label}
        error={requiredError}
        autoComplete={create ? 'new-password' : undefined}
        rightAdornment={
          <SVGIcon
            ref={setAnchor}
            name="see-password-icon"
            className="w-full cursor-pointer"
            onClick={toggleShowPassword}
          />
        }
        onChange={onChange}
      />

      <PasswordError anchor={anchor} visible={withHelp} error={error} />
    </>
  );
};
