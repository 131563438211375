import 'views/Landing/SignUp/SignUp.scss';

// Components
import { ConditionalRender } from 'components/conditionalRenderer';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { preRegisterMode } from 'utils';
import SectionTitle from 'views/Landing/Login/components/sectionTitle';
import { HubspotForm } from 'views/Landing/SignUp/HubspotForm';
import { SignUpForm } from 'views/Landing/SignUp/signUpForm';

const REGION = 'na1';
const PORTAL_ID = '21090525';
const FORM_ID = '53ca30f9-4b2a-4dda-833f-0652e44650bc';

const SignUp: React.FC = (): React.ReactElement => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });

    mixpanel.track('PageView Sign Up');
    return () => {
      mixpanel.track('SignUp Dropping');
    };
  }, []);

  return (
    <>
      <SectionTitle
        title="Sign Up"
        subTitle="Get started by completing the following information"
      />
      <ConditionalRender renderIf={!preRegisterMode}>
        <SignUpForm />
      </ConditionalRender>
      <ConditionalRender renderIf={preRegisterMode}>
        <HubspotForm region={REGION} portalId={PORTAL_ID} formId={FORM_ID} />
      </ConditionalRender>
    </>
  );
};

export default SignUp;
