import SpinnerLoader from 'components/spinnerLoader';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { processingStateSelector } from 'redux/reducers/subscriptionPlansReducer';
import { getSubscriptionPlansAction } from 'redux/services/subscriptionPlansService';
import { ProcessingStateEnum } from 'types/processingState';
import { SubscriptionPlans } from 'views/SubscriptionPlans/subscriptionPlans';

const OurPlans: React.FC = (): React.ReactElement => {
  const processingState = useSelector(processingStateSelector);
  const dispatch = useDispatch();

  useEffect((): VoidFunction => {
    // Always ensure fresh features/subscription plans
    return dispatch(getSubscriptionPlansAction());
  }, [dispatch]);

  return (
    <>
      <SubscriptionPlans />
      <SpinnerLoader visible={processingState.state === ProcessingStateEnum.processing} />
    </>
  );
};

export default OurPlans;
