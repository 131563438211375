type int = number;

export enum PortfolioItemType {
  publicCompany = 'publicCompany',
  privateCompany = 'privateCompany',
  cash = 'cash',
  cryptoCurrency = 'cryptoCurrency',
  etf = 'etf',
  commodity = 'commodity',
  realEstate = 'realEstate',
  art = 'art',
  other = 'other',

  invalid = 'invalid',
}

export enum InsuranceStatus {
  yes = 'yes',
  no = 'yes',
  seeking = 'seeking',
}

export enum TaxBenefitValues {
  yes = 'yes',
  no = 'no',
  doesNotKnow = 'doesNotKnow',
}

export enum TypeOfArt {
  painting = 'painting',
  sculpture = 'sculpture',
  digitalArt = 'digitalArt',
  photography = 'photography',
  video = 'video',
  fineWinesOrBeverages = 'fineWinesOrBeverages',
}

export const portfolioItemTypeLabels: Record<PortfolioItemType, string> = {
  [PortfolioItemType.publicCompany]: 'Public Company',
  [PortfolioItemType.privateCompany]: 'Private Company',
  [PortfolioItemType.cash]: 'Cash',
  [PortfolioItemType.cryptoCurrency]: 'Crypto Currency',
  [PortfolioItemType.etf]: 'Exchange Traded Funds (ETF)',
  [PortfolioItemType.commodity]: 'Commodity',
  [PortfolioItemType.realEstate]: 'Real Estate',
  [PortfolioItemType.art]: 'Art',
  [PortfolioItemType.other]: 'Other',
  [PortfolioItemType.invalid]: '',
};

export enum PortfolioCompanyRelationshipType {
  investment = 'investment',
  clientOrCustomer = 'clientOrCustomer',
  partner = 'partner',
  supplier = 'supplier',
  employer = 'employer',
  other = 'other',
}

export const portfolioCompanyRelationshipTypeLabels: Record<
  PortfolioCompanyRelationshipType,
  string
> = {
  [PortfolioCompanyRelationshipType.investment]: 'Investment',
  [PortfolioCompanyRelationshipType.clientOrCustomer]: 'Client or Customer',
  [PortfolioCompanyRelationshipType.partner]: 'Partner',
  [PortfolioCompanyRelationshipType.supplier]: 'Supplier',
  [PortfolioCompanyRelationshipType.employer]: 'Employer',
  [PortfolioCompanyRelationshipType.other]: 'Other',
};

export enum PortfolioCompanyInvestmentType {
  sharesOrEquity = 'sharesOrEquity',
  bondsOrDebt = 'bondsOrDebt',
  options = 'options',
  other = 'other',
}

export const portfolioCompanyInvestmentTypeLabels: Record<PortfolioCompanyInvestmentType, string> =
  {
    [PortfolioCompanyInvestmentType.sharesOrEquity]: 'Shares',
    [PortfolioCompanyInvestmentType.bondsOrDebt]: 'Bonds or Debt-Related',
    [PortfolioCompanyInvestmentType.options]: 'Options',
    [PortfolioCompanyInvestmentType.other]: 'Other',
  };

export enum PortfolioCompanyProductType {
  advisoryOrConsulting = 'advisoryOrConsulting',
  cashManagementOrDeposits = 'cashManagementOrDeposits',
  foreignExchange = 'foreignExchange',
  hedgingOrRiskManagement = 'hedgingOrRiskManagement',
  loansOrDebtRelated = 'loansOrDebtRelated',
  other = 'other',
}

export const portfolioCompanyProductTypeLabels: Record<PortfolioCompanyProductType, string> = {
  [PortfolioCompanyProductType.advisoryOrConsulting]: 'Advisory or Consulting',
  [PortfolioCompanyProductType.cashManagementOrDeposits]: 'Cash Management or Deposits',
  [PortfolioCompanyProductType.foreignExchange]: 'Foreign Exchange',
  [PortfolioCompanyProductType.hedgingOrRiskManagement]: 'Hedging or Risk Management',
  [PortfolioCompanyProductType.loansOrDebtRelated]: 'Loans or Debt-Related',
  [PortfolioCompanyProductType.other]: 'Other Services or Products',
};

export enum AdvisoryOrConsultingType {
  managersAndAcquisition = 'managersAndAcquisition',
  debtAdvisory = 'debtAdvisory',
  riskManagementHedging = 'riskManagementHedging',
  wealthAdvisory = 'wealthAdvisory',
}

export const advisoryOrConsultingTypeLabels: Record<AdvisoryOrConsultingType, string> = {
  [AdvisoryOrConsultingType.managersAndAcquisition]: 'Managers and Acquisition',
  [AdvisoryOrConsultingType.debtAdvisory]: 'Debt Advisory',
  [AdvisoryOrConsultingType.riskManagementHedging]: 'Risk Management/Hedging',
  [AdvisoryOrConsultingType.wealthAdvisory]: 'Wealth Advisory',
};

export enum HedgingOrRiskManagementSubject {
  commodities = 'commodities',
  foreignExchange = 'foreignExchange',
  interestRates = 'interestRates',
  stockPrices = 'stockPrices',
}

export const hedgingOrRiskManagementSubjectLabels: Record<HedgingOrRiskManagementSubject, string> =
  {
    [HedgingOrRiskManagementSubject.commodities]: 'Commodities',
    [HedgingOrRiskManagementSubject.foreignExchange]: 'Foreign Exchange',
    [HedgingOrRiskManagementSubject.interestRates]: 'Interest Rates',
    [HedgingOrRiskManagementSubject.stockPrices]: 'Stock Prices',
  };

export enum MajorityOrMinority {
  majority = 'majority',
  minority = 'minority',
}

export interface HistoryPoint {
  date: string;
  value: number;
}

interface PortfolioItemBase {
  id: string;
  type: PortfolioItemType;

  valueHistory: readonly HistoryPoint[];
}

export interface PortfolioCompanyItemBase extends PortfolioItemBase {
  type: PortfolioItemType.publicCompany | PortfolioItemType.privateCompany;
  relationshipType: PortfolioCompanyRelationshipType;
  companyId: string;
  name: string;
}

export interface PortfolioCompanyInvestmentItem extends PortfolioCompanyItemBase {
  relationshipType: PortfolioCompanyRelationshipType.investment;
  investmentType: PortfolioCompanyInvestmentType;
}

export interface PortfolioCompanyInvestmentSharesOrEquityItem
  extends PortfolioCompanyInvestmentItem {
  investmentType: PortfolioCompanyInvestmentType.sharesOrEquity;
  purchaseTimestamp: number;
  numberOfShares: int;
  currency: string;
  averagePricePerShare: number;
  totalCostBasis: number;
  totalValueOfShares: number;
}

export interface PortfolioCompanyInvestmentBondsOrDebtItem extends PortfolioCompanyInvestmentItem {
  investmentType: PortfolioCompanyInvestmentType.bondsOrDebt;
  purchaseTimestamp: number;
  maturityTimestamp: number;
  annualCouponRateOrInterestRateCharged: number;
  bondOrDebtPrincipalAmount: number;
  averagePurchasePriceOfBond: number;
}

export interface PortfolioCompanyInvestmentOptionsItem extends PortfolioCompanyInvestmentItem {
  investmentType: PortfolioCompanyInvestmentType.options;
  purchaseTimestamp: number;
  strikePrice: number;
  numberOfOptions: int;
  optionType: string;
  optionExpiryTimestamp: number;
}

export interface PortfolioCompanyInvestmentOtherItem extends PortfolioCompanyInvestmentItem {
  investmentType: PortfolioCompanyInvestmentType.other;
  investmentTypeDescription: string;
  purchaseTimestamp: number;
  expectedAnnualReturnPercent: number;
  expectedHoldingPeriodMonths: number;
}

export interface PortfolioCompanyClientOrCustomerItemBase extends PortfolioCompanyItemBase {
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer;
  productType: PortfolioCompanyProductType;
}

export interface PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem
  extends PortfolioCompanyClientOrCustomerItemBase {
  productType: PortfolioCompanyProductType.loansOrDebtRelated;
  currency: string;
  insuranceTimestamp: number;
  maturityTimestamp: number;
  principalAmount: number;
  annualInterestRateCharged: number;
}

export interface PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem
  extends PortfolioCompanyClientOrCustomerItemBase {
  productType: PortfolioCompanyProductType.advisoryOrConsulting;
  advisoryOrConsultingType: AdvisoryOrConsultingType;
  currency: string;
  annualMonetaryIncomeFromClient: number;
}

export interface PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem
  extends PortfolioCompanyClientOrCustomerItemBase {
  productType: PortfolioCompanyProductType.cashManagementOrDeposits;
  currency: string;
  annualMonetaryIncomeFromClient: number;
}

export interface PortfolioCompanyClientOrCustomerForeignExchangeItem
  extends PortfolioCompanyClientOrCustomerItemBase {
  productType: PortfolioCompanyProductType.foreignExchange;
  currenciesOfInterest: string[];
  currency: string;
  annualMonetaryIncomeFromClient: number;
}

export interface PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem
  extends PortfolioCompanyClientOrCustomerItemBase {
  productType: PortfolioCompanyProductType.hedgingOrRiskManagement;
  subjects: HedgingOrRiskManagementSubject[];
  currency: string;
  annualMonetaryIncomeFromClient: number;
}

export interface PortfolioCompanyClientOrCustomerOtherItem
  extends PortfolioCompanyClientOrCustomerItemBase {
  productType: PortfolioCompanyProductType.other;
  productTypeDescription: string;
  currency: string;
  annualMonetaryIncomeFromClient: number;
}

export type PortfolioCompanyClientOrCustomerItem =
  | PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem
  | PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem
  | PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem
  | PortfolioCompanyClientOrCustomerForeignExchangeItem
  | PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem
  | PortfolioCompanyClientOrCustomerOtherItem;

export interface PortfolioCompanyPartnerItem extends PortfolioCompanyItemBase {
  relationshipType: PortfolioCompanyRelationshipType.partner;
  majorityOrMinority: MajorityOrMinority | null;
  primaryCurrency: string;
  annualMonetaryIncomeFromPartner: number;
}

export interface PortfolioCompanySupplierItem extends PortfolioCompanyItemBase {
  relationshipType: PortfolioCompanyRelationshipType.supplier;
  productsOrServicesSupplied: string;
  currency: string;
  annualAmountPaidToSupplier: number;
}

export interface PortfolioCompanyEmployerItem extends PortfolioCompanyItemBase {
  relationshipType: PortfolioCompanyRelationshipType.employer;
  receiveStockOptions: boolean | null;
}

export interface PortfolioCompanyOtherItem extends PortfolioCompanyItemBase {
  relationshipType: PortfolioCompanyRelationshipType.other;
  name: string;
  description: string;
}

export enum TypeOfCash {
  currentAccount = 'currentAccount',
  savings = 'savings',
  investment = 'investment',
  other = 'other',
}

export const typeOfArtLabels: Record<TypeOfArt, string> = {
  [TypeOfArt.painting]: 'Painting',
  [TypeOfArt.sculpture]: 'Sculpture',
  [TypeOfArt.digitalArt]: 'Digital Art',
  [TypeOfArt.photography]: 'Photography',
  [TypeOfArt.video]: 'Video',
  [TypeOfArt.fineWinesOrBeverages]: 'Fine Wines or Beverages',
};

export const typeOfCashLabels: Record<TypeOfCash, string> = {
  [TypeOfCash.currentAccount]: 'Current Account',
  [TypeOfCash.savings]: 'Savings',
  [TypeOfCash.investment]: 'Investment',
  [TypeOfCash.other]: 'Other',
};

export interface PortfolioCashItem extends PortfolioItemBase {
  type: PortfolioItemType.cash;
  typeOfCash: TypeOfCash;
  currency: string;
  size: number;
  annualInterestRateEarnedOnCash: number;
  locationOfCashAccount: string | null;
}

export enum RealEstatePurpose {
  yourResidence = 'Your Residence',
  investmentResidential = 'Investment Residential',
  commercial = 'Commercial',
  industrial = 'Industrial',
  land = 'Land',
}

export enum YourResidenceType {
  apartment = 'Apartment',
  detached = 'Detached',
  semiDetached = 'Semi-Detached',
  townHouse = 'Town House',
}

export enum InvestmentResidentialType {
  singleFamily = 'Single Family',
  multiFamily = 'Multi Family',
  shortLetOrVacationProperty = 'Short Let or Vacation Property',
}

export enum CommercialResidenceType {
  officeBuildings = 'Office Buildings',
  retail = 'Retail',
  logistics = 'Logistics',
  hospitalsOrMedical = 'Hospitals or Medical',
  agriculture = 'Agriculture',
  otherSpecializedCommercial = 'Other Specialized Commercial',
}

export enum IndustrialResidenceType {
  manufacturing = 'Manufacturing',
  wasteHouse = 'Waste House',
  energyRelated = 'Energy-Related',
  otherSpecializedIndustrial = 'Other Specialized Industrial',
}

export enum CryptoCurrency {
  bitcoin = 'bitcoin',
  ethereum = 'ethereum',
  tether = 'tether',
  usdCoin = 'usdCoin',
  binanceCoin = 'binanceCoin',
  cardano = 'cardano',
  xrp = 'xrp',
  dogecoin = 'dogecoin',
  solana = 'solana',
  shibaInu = 'shibaInu',
  algorand = 'algorand',
  others = 'others',
}

export interface PortfolioCryptoCurrencyItem extends PortfolioItemBase {
  readonly type: PortfolioItemType.cryptoCurrency;
  readonly name: CryptoCurrency;
  readonly amount: number;
  readonly averagePurchasePriceAmount: number;
  readonly averagePurchasePriceCurrency: string;
}

export interface PortfolioETFItem extends PortfolioItemBase {
  readonly id: string;
  readonly type: PortfolioItemType.etf;
  readonly name: string;
  readonly averagePricePerShareCurrency: string;
  readonly averagePricePerShareAmount: number;
  readonly numberOfShares: number;
  readonly totalAmount: number;
}

export enum TypeOfCommodity {
  gold = 'Gold',
  silver = 'Silver',
  otherPreciousMetal = 'Other Precious Metal',
}

export interface PortfolioCommodityItem extends PortfolioItemBase {
  readonly id: string;
  readonly type: PortfolioItemType.commodity;
  readonly typeOfCommodity: TypeOfCommodity;
  readonly currency: string;
  readonly amount: number;
}

export interface PortfolioRealStateItemBase extends PortfolioItemBase {
  readonly id: string;
  readonly type: PortfolioItemType.realEstate;
  readonly purpose: RealEstatePurpose;
  readonly country: string;
  readonly purchasePriceCurrency: string;
  readonly purchasePriceAmount: number;
  readonly currentValuation: number;
  readonly mortgageRatePercent: number;
  readonly mortgageAmount: number;
}

export interface PortfolioYourResidenceRealStateItem extends PortfolioRealStateItemBase {
  readonly purpose: RealEstatePurpose.yourResidence;
  readonly typeOfResidence: YourResidenceType;
}

export interface PortfolioInvestmentResidentialRealStateItem extends PortfolioRealStateItemBase {
  readonly purpose: RealEstatePurpose.investmentResidential;
  readonly typeOfResidence: InvestmentResidentialType;
  readonly rentalAnnualIncomeAmount: number;
  readonly rentalAnnualIncomeCurrency: string;
}

export interface PortfolioCommercialRealStateItem extends PortfolioRealStateItemBase {
  readonly purpose: RealEstatePurpose.commercial;
  readonly typeOfResidence: CommercialResidenceType;
  readonly annualNetIncomeAmount: number;
  readonly annualNetIncomeCurrency: string;
}

export interface PortfolioIndustrialRealStateItem extends PortfolioRealStateItemBase {
  readonly purpose: RealEstatePurpose.industrial;
  readonly typeOfResidence: IndustrialResidenceType;
  readonly annualNetIncomeAmount: number;
  readonly annualNetIncomeCurrency: string;
}

export interface PortfolioLandRealStateItem extends PortfolioRealStateItemBase {
  readonly purpose: RealEstatePurpose.land;
  readonly annualNetIncomeAmount: number;
  readonly annualNetIncomeCurrency: string;
}

export type PortfolioRealEstateItem =
  | PortfolioYourResidenceRealStateItem
  | PortfolioInvestmentResidentialRealStateItem
  | PortfolioCommercialRealStateItem
  | PortfolioIndustrialRealStateItem
  | PortfolioLandRealStateItem;

export interface PortfolioUnavailableItem extends PortfolioItemBase {
  type: PortfolioItemType.other | PortfolioItemType.invalid;
}

export interface PortfolioTableItemBase {
  readonly id: string;
  readonly type: PortfolioItemType;
  readonly name: string;
  readonly currency: string;
  readonly value: number;
  readonly change: number;
  readonly added_at: string;
  readonly date_of_last_change: string;
}

export interface PortfolioTableItemPublicCompanyData {
  readonly companyId: string;
  readonly relationshipType: PortfolioCompanyRelationshipType;
}

export interface PortfolioTableItemCashData {
  readonly typeOfCash: TypeOfCash;
  readonly locationOfCashAccount: string | null;
}

export interface PortfolioTableItemPublicCompany extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.publicCompany;
  readonly data: PortfolioTableItemPublicCompanyData;
}

export interface PortfolioTableItemCash extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.cash;
  readonly data: PortfolioTableItemCashData;
}

export interface PortfolioTableItemPrivateCompany extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.privateCompany;
  readonly data: PortfolioTableItemPublicCompanyData;
}

export interface PortfolioTableItemCryptoCurrency extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.cryptoCurrency;
  readonly data: PortfolioCryptoCurrencyItem;
}

export interface PortfolioTableItemETF extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.etf;
  readonly data: PortfolioETFItem;
}

export interface PortfolioTableItemCommodity extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.commodity;
  readonly data: PortfolioCommodityItem;
}

export interface PortfolioTableItemRealEstate extends PortfolioTableItemBase {
  readonly type: PortfolioItemType.realEstate;
  readonly data: PortfolioRealEstateItem;
}

export type PortfolioTableItem =
  | PortfolioTableItemPublicCompany
  | PortfolioTableItemPrivateCompany
  | PortfolioTableItemCash
  | PortfolioTableItemETF
  | PortfolioTableItemCryptoCurrency
  | PortfolioTableItemRealEstate
  | PortfolioTableItemCommodity;

export const isPortfolioArtPaintingArtItem = (
  item: PortfolioArtItem,
): item is PortfolioArtPaintingItem => item.typeOfArt === TypeOfArt.painting;

export const isPortfolioArtSculptureArtItem = (
  item: PortfolioArtItem,
): item is PortfolioArtSculptureItem => item.typeOfArt === TypeOfArt.sculpture;

export const isPortfolioArtDigitalArtArtItem = (
  item: PortfolioArtItem,
): item is PortfolioArtDigitalArtItem => item.typeOfArt === TypeOfArt.digitalArt;

export const isPortfolioArtPhotographyArtItem = (
  item: PortfolioArtItem,
): item is PortfolioArtPhotographyItem => item.typeOfArt === TypeOfArt.photography;

export const isPortfolioArtVideoItem = (item: PortfolioArtItem): item is PortfolioArtVideoItem =>
  item.typeOfArt === TypeOfArt.video;

export const isPortfolioArtFineWinesOrBeveragesItem = (
  item: PortfolioArtItem,
): item is PortfolioArtFineWinesOrBeveragesItem =>
  item.typeOfArt === TypeOfArt.fineWinesOrBeverages;

export interface PortfolioArtItemBase extends PortfolioItemBase {
  readonly type: PortfolioItemType.art;
  readonly typeOfArt: TypeOfArt;
}

export interface PortfolioArtPaintingItem extends PortfolioArtItemBase {
  readonly pictureUrl: string;
  readonly artistName: string;
  readonly artistNationality: string;
  readonly artOrigin: string;
  readonly yearOfProduction: number;
  readonly provenance: string;
  readonly holdingLocation: string;
  readonly purchasePriceAmount: number;
  readonly purchasePriceCurrency: string;
  readonly appraisalValueAmount: number;
  readonly appraisalValueCurrency: string;
  readonly wantsToBorrow: boolean;
  readonly wantsToSell: boolean;
  readonly insured: InsuranceStatus;
  readonly receivingTaxBenefits: TaxBenefitValues;
  readonly earningIncomeFromDisplay: boolean;
}

export interface PortfolioArtSculptureItem extends PortfolioArtItemBase {
  readonly typeOfArt: TypeOfArt.sculpture;
}

export interface PortfolioArtDigitalArtItem extends PortfolioArtItemBase {
  readonly typeOfArt: TypeOfArt.digitalArt;
}

export interface PortfolioArtPhotographyItem extends PortfolioArtItemBase {
  readonly typeOfArt: TypeOfArt.photography;
}

export interface PortfolioArtVideoItem extends PortfolioArtItemBase {
  readonly typeOfArt: TypeOfArt.video;
}

export interface PortfolioArtFineWinesOrBeveragesItem extends PortfolioArtItemBase {
  readonly typeOfArt: TypeOfArt.fineWinesOrBeverages;
}

export type PortfolioArtItem =
  | PortfolioArtPaintingItem
  | PortfolioArtSculptureItem
  | PortfolioArtDigitalArtItem
  | PortfolioArtPhotographyItem
  | PortfolioArtVideoItem
  | PortfolioArtFineWinesOrBeveragesItem;

export type PortfolioCompanyItem =
  | PortfolioCompanyInvestmentSharesOrEquityItem
  | PortfolioCompanyInvestmentBondsOrDebtItem
  | PortfolioCompanyInvestmentOptionsItem
  | PortfolioCompanyInvestmentOtherItem
  | PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem
  | PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem
  | PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem
  | PortfolioCompanyClientOrCustomerForeignExchangeItem
  | PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem
  | PortfolioCompanyClientOrCustomerOtherItem
  | PortfolioCompanySupplierItem
  | PortfolioCompanyEmployerItem
  | PortfolioCompanyPartnerItem
  | PortfolioCompanyOtherItem;

export type PortfolioItem =
  | PortfolioCompanyItem
  | PortfolioCashItem
  | PortfolioArtItem
  | PortfolioCryptoCurrencyItem
  | PortfolioETFItem
  | PortfolioCommodityItem
  | PortfolioRealEstateItem
  | PortfolioUnavailableItem;

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export const emptyInvestmentSharesAndEquityPrivateCompanyItem: Nullable<PortfolioCompanyInvestmentSharesOrEquityItem> =
  {
    id: null,
    companyId: null,
    name: null,
    type: PortfolioItemType.privateCompany,
    relationshipType: PortfolioCompanyRelationshipType.investment,
    investmentType: PortfolioCompanyInvestmentType.sharesOrEquity,
    purchaseTimestamp: Date.now(),
    currency: 'USD',
    numberOfShares: null,
    averagePricePerShare: null,
    totalCostBasis: null,
    totalValueOfShares: null,
    valueHistory: [],
  };

export const emptyInvestmentSharesAndEquityPublicCompanyItem: Nullable<PortfolioCompanyInvestmentSharesOrEquityItem> =
  {
    id: null,
    companyId: null,
    name: null,
    type: PortfolioItemType.publicCompany,
    relationshipType: PortfolioCompanyRelationshipType.investment,
    investmentType: PortfolioCompanyInvestmentType.sharesOrEquity,
    purchaseTimestamp: Date.now(),
    currency: 'USD',
    numberOfShares: null,
    averagePricePerShare: null,
    totalCostBasis: null,
    totalValueOfShares: null,
    valueHistory: [],
  };

export const emptyRealStateItem: Nullable<PortfolioRealEstateItem> = {
  id: '',
  type: PortfolioItemType.realEstate,
  purpose: RealEstatePurpose.yourResidence,
  typeOfResidence: YourResidenceType.apartment,
  country: 'US',
  purchasePriceCurrency: 'USD',
  purchasePriceAmount: 0,
  currentValuation: 0,
  mortgageRatePercent: 0,
  mortgageAmount: 0,
  valueHistory: [],
};

export const emptyCommodityItem: Nullable<PortfolioCommodityItem> = {
  id: '',
  type: PortfolioItemType.commodity,
  typeOfCommodity: TypeOfCommodity.gold,
  currency: 'USD',
  amount: 0,
  valueHistory: [],
};

export const emptyETFItem: Nullable<PortfolioETFItem> = {
  id: '',
  type: PortfolioItemType.etf,
  name: '',
  averagePricePerShareAmount: 0,
  averagePricePerShareCurrency: 'USD',
  numberOfShares: 0,
  totalAmount: 0,
  valueHistory: [],
};

export const emptyCryptoCurrencyItem: Nullable<PortfolioCryptoCurrencyItem> = {
  id: '',
  type: PortfolioItemType.cryptoCurrency,
  name: CryptoCurrency.bitcoin,
  amount: 0,
  averagePurchasePriceCurrency: 'USD',
  averagePurchasePriceAmount: 0,
  valueHistory: [],
};

export const emptyArtItem: Nullable<PortfolioArtItem> = {
  id: '',
  type: PortfolioItemType.art,
  typeOfArt: TypeOfArt.painting,
  pictureUrl: '',
  artistName: '',
  artistNationality: '',
  artOrigin: '',
  yearOfProduction: 2024,
  provenance: '',
  holdingLocation: '',
  purchasePriceAmount: 0,
  purchasePriceCurrency: 'USD',
  appraisalValueAmount: 0,
  appraisalValueCurrency: 'USD',
  wantsToBorrow: false,
  wantsToSell: false,
  insured: InsuranceStatus.no,
  receivingTaxBenefits: TaxBenefitValues.no,
  earningIncomeFromDisplay: false,
  valueHistory: [],
};

export const emptyCashItem: Nullable<PortfolioCashItem> = {
  id: null,
  type: PortfolioItemType.cash,
  currency: 'USD',
  typeOfCash: TypeOfCash.currentAccount,
  locationOfCashAccount: 'US',
  size: null,
  annualInterestRateEarnedOnCash: null,
  valueHistory: [],
};

export const isPortfolioCompanyItem = (
  item: Nullable<PortfolioItemBase>,
): item is PortfolioCompanyItemBase =>
  item.type === PortfolioItemType.publicCompany || item.type === PortfolioItemType.privateCompany;

export const isPortfolioCompanyInvestmentItem = (
  item: Nullable<PortfolioItemBase>,
): item is PortfolioCompanyInvestmentItem =>
  isPortfolioCompanyItem(item) &&
  item.relationshipType === PortfolioCompanyRelationshipType.investment;

export const isPortfolioCompanyClientOrCustomerItem = (
  item: Nullable<PortfolioItemBase>,
): item is PortfolioCompanyClientOrCustomerItem =>
  isPortfolioCompanyItem(item) &&
  item.relationshipType === PortfolioCompanyRelationshipType.clientOrCustomer;

export const isPortfolioCompanyPartnerItem = (
  item: PortfolioItemBase,
): item is PortfolioCompanyPartnerItem =>
  isPortfolioCompanyItem(item) &&
  item.relationshipType === PortfolioCompanyRelationshipType.partner;

export const isPortfolioCompanySupplierItem = (
  item: Nullable<PortfolioItemBase>,
): item is PortfolioCompanySupplierItem =>
  isPortfolioCompanyItem(item) &&
  item.relationshipType === PortfolioCompanyRelationshipType.supplier;

export const isPortfolioCompanyEmployerItem = (
  item: Nullable<PortfolioItemBase>,
): item is PortfolioCompanyEmployerItem =>
  isPortfolioCompanyItem(item) &&
  item.relationshipType === PortfolioCompanyRelationshipType.employer;

export const isPortfolioCompanyOtherItem = (
  item: Nullable<PortfolioItemBase>,
): item is PortfolioCompanyOtherItem =>
  isPortfolioCompanyItem(item) && item.relationshipType === PortfolioCompanyRelationshipType.other;

export const isPortfolioCompanyInvestmentSharesOrEquityItem = (
  item: Nullable<PortfolioCompanyInvestmentItem>,
): item is PortfolioCompanyInvestmentSharesOrEquityItem =>
  isPortfolioCompanyInvestmentItem(item) &&
  item.investmentType === PortfolioCompanyInvestmentType.sharesOrEquity;

export const isPortfolioCompanyInvestmentBondsOrDebt = (
  item: Nullable<PortfolioCompanyInvestmentItem>,
): item is PortfolioCompanyInvestmentBondsOrDebtItem =>
  isPortfolioCompanyInvestmentItem(item) &&
  item.investmentType === PortfolioCompanyInvestmentType.bondsOrDebt;

export const isPortfolioCompanyInvestmentOptions = (
  item: Nullable<PortfolioCompanyInvestmentItem>,
): item is PortfolioCompanyInvestmentOptionsItem =>
  isPortfolioCompanyInvestmentItem(item) &&
  item.investmentType === PortfolioCompanyInvestmentType.options;

export const isPortfolioCompanyInvestmentOther = (
  item: Nullable<PortfolioCompanyInvestmentItem>,
): item is PortfolioCompanyInvestmentOtherItem =>
  isPortfolioCompanyInvestmentItem(item) &&
  item.investmentType === PortfolioCompanyInvestmentType.other;

export const isPortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem = (
  item: Nullable<PortfolioCompanyClientOrCustomerItem>,
): item is PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem =>
  isPortfolioCompanyClientOrCustomerItem(item) &&
  item.productType === PortfolioCompanyProductType.advisoryOrConsulting;

export const isPortfolioCompanyClientOrCustomerCashManagementOrDepositsItem = (
  item: Nullable<PortfolioCompanyClientOrCustomerItem>,
): item is PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem =>
  isPortfolioCompanyClientOrCustomerItem(item) &&
  item.productType === PortfolioCompanyProductType.cashManagementOrDeposits;

export const isPortfolioCompanyClientOrCustomerForeignExchangeItem = (
  item: Nullable<PortfolioCompanyClientOrCustomerItem>,
): item is PortfolioCompanyClientOrCustomerForeignExchangeItem =>
  isPortfolioCompanyClientOrCustomerItem(item) &&
  item.productType === PortfolioCompanyProductType.foreignExchange;

export const isPortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem = (
  item: Nullable<PortfolioCompanyClientOrCustomerItem>,
): item is PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem =>
  isPortfolioCompanyClientOrCustomerItem(item) &&
  item.productType === PortfolioCompanyProductType.hedgingOrRiskManagement;

export const isPortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem = (
  item: Nullable<PortfolioCompanyClientOrCustomerItem>,
): item is PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem =>
  isPortfolioCompanyClientOrCustomerItem(item) &&
  item.productType === PortfolioCompanyProductType.loansOrDebtRelated;

export const isPortfolioCompanyClientOrCustomerOtherItem = (
  item: Nullable<PortfolioCompanyClientOrCustomerItem>,
): item is PortfolioCompanyClientOrCustomerOtherItem =>
  isPortfolioCompanyClientOrCustomerItem(item) &&
  item.productType === PortfolioCompanyProductType.other;

export interface AddPortfolioItemPayload {
  readonly portfolioId: string;
  readonly item: PortfolioItem;
}

export interface UpdatePortfolioItemPayload {
  readonly portfolioId: string;
  readonly item: PortfolioItem;
}
