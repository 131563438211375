import React from 'react';
import { NotificationMetadata, NotificationType } from 'redux/reducers/sharedReducer';

interface Props {
  readonly description: string;
  readonly metadata?: NotificationMetadata;
}

export const DescriptionText: React.FC<Props> = ({
  metadata,
  description,
}: Props): React.ReactElement => {
  switch (metadata?.type) {
    case NotificationType.Connections:
      return (
        <div className="font-poppins text-base text-gray leading-5 truncate">
          <strong>{metadata.follower_name}</strong> <span>followed you</span>
        </div>
      );
    case NotificationType.YouMayKnow:
      return (
        <div className="font-poppins text-base text-gray leading-5 whitespace-normal">
          {description}
        </div>
      );
    case NotificationType.PortfoliosNewsAndNotifications:
    case NotificationType.FINVARNotifications:
    case NotificationType.Account:
    case NotificationType.CompanyPriceAlert:
    case NotificationType.KRF:
    case NotificationType.News:
    case NotificationType.Organization:
    case NotificationType.Portfolio:
    case NotificationType.Event:
      break;
    case NotificationType.Post:
      return (
        <div className="font-poppins text-base text-gray leading-5 truncate">
          <strong>{metadata.commenter_name}</strong> <span>commented on your post</span>
        </div>
      );
    case NotificationType.Comment:
      return (
        <div className="font-poppins text-base text-gray leading-5 truncate">
          <strong>{metadata.commenter_name}</strong> <span>replied to your comment</span>
        </div>
      );
  }

  return <div className="font-poppins text-base text-gray leading-5 truncate">{description}</div>;
};
