import SVGIcon from 'components/icons/SVGIcon';
import styles from 'components/searchBox/styles.module.scss';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  readonly value: string;
  readonly searching?: boolean;
  readonly placeholder?: string;
  readonly fullWidth?: boolean;
  readonly hideSearchIcon?: boolean;
  readonly autoFocus?: boolean;

  onChange(keyword: string): void;
  onFocus?(): void;
  onBlur?(): void;
}

export const SearchBox: React.FC<React.PropsWithoutRef<Props> & React.RefAttributes<any>> =
  React.forwardRef(function SearchBox(
    {
      value,
      searching = false,
      placeholder = 'Search',
      fullWidth = false,
      hideSearchIcon = false,
      autoFocus = false,
      onChange,
      onFocus,
      onBlur,
    }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactElement {
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [focused, setFocused] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleSearchKeywordChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setSearchKeyword(value ?? '');
      },
      [],
    );

    const clearSearchKeyword = useCallback((): void => {
      onChange('');

      setTimeout((): void => {
        inputRef.current?.focus();
      }, 0);
    }, [onChange]);

    useEffect((): void => {
      setSearchKeyword(value ?? '');
    }, [value]);

    useEffect((): VoidFunction => {
      const timeout = setTimeout((): void => {
        onChange(searchKeyword);
      }, 500);

      return (): void => {
        clearTimeout(timeout);
      };
    }, [onChange, searchKeyword]);

    const derivedContainerClassName = React.useMemo((): string => {
      return [
        containerClassName,
        fullWidth ? 'w-full' : 'w-80',
        focused ? 'border-blue' : undefined,
      ].join(' ');
    }, [focused, fullWidth]);

    const handleFocus = React.useCallback((): void => {
      setFocused(true);
      onFocus?.();
    }, [onFocus]);

    const handleBlur = React.useCallback((): void => {
      setFocused(false);
      onBlur?.();
    }, [onBlur]);

    const searchIconContainerClassName = React.useMemo((): string => {
      if (hideSearchIcon) {
        return 'invisible w-0 transition-width duration-300';
      } else {
        return 'w-4 transition-width duration-300 mr-2';
      }
    }, [hideSearchIcon]);

    return (
      <div ref={ref} className={derivedContainerClassName}>
        <div className={searchIconContainerClassName}>
          <SVGIcon name="dark-search-icon" className={iconClassName} />
        </div>
        <input
          ref={inputRef}
          className={inputClassName}
          placeholder={placeholder}
          value={searchKeyword}
          readOnly={searching}
          autoFocus={autoFocus}
          onChange={handleSearchKeywordChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        <div className={rightDecorationClassName}>
          {searching ? <div className={styles.searchSpinner} /> : null}

          {!searching && searchKeyword !== null && searchKeyword.trim() !== '' ? (
            <SVGIcon
              name="close-popup-icon"
              onClick={clearSearchKeyword}
              className="fill-current w-3 h-3 cursor-pointer hover:text-red-500 text-gray"
            />
          ) : null}
        </div>
      </div>
    );
  });

const containerClassName = 'flex items-center border-b-2 border-gray-medium h-12 px-2';
const inputClassName =
  'leading-12 h-12 flex-1 w-full font-poppins text-sm outline-none focus:border-blue bg-transparent';
const iconClassName = 'w-4 h-4';
const rightDecorationClassName = 'relative w-4 h-4 flex items-center justify-center';
