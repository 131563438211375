import { ConditionalRender } from 'components/conditionalRenderer';
import React from 'react';

interface Props {
  readonly message?: string;

  readonly danger?: boolean;

  onYes?(): void;
  onNo(): void;
}

export const ConfirmationBox: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  danger = false,
  children,
  onYes,
  onNo,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  return (
    <div className="md:min-w-modal-md md:max-w-lg sm:w-full">
      <ConditionalRender renderIf={!!message}>
        <h6 className="text-gray text-md whitespace-pre-wrap">{message}</h6>
      </ConditionalRender>
      {children}
      <div className="w-full flex items-center justify-end gap-2 font-poppinsSemiBold text-sm mt-10">
        <button
          className="text-gray-darkest bg-gray-200 py-2 px-8 rounded-5 hover:bg-gray-300"
          onClick={onNo}
        >
          No
        </button>
        {danger ? (
          <button
            className="bg-red-600 text-white py-2 px-8 rounded-5 hover:bg-red-700"
            onClick={onYes}
          >
            Yes
          </button>
        ) : (
          <button className={yesButtonClassName} disabled={!onYes} onClick={onYes}>
            Yes
          </button>
        )}
      </div>
    </div>
  );
};

const yesButtonClassName =
  'bg-blue text-white py-2 px-8 rounded-5 hover:bg-blue-800 disabled:opacity-20 disabled:pointer-events-none';
