import { TruncatedTags } from 'components/truncatedTags';
import React from 'react';

interface Props {
  readonly title: string;
  readonly items: string[];

  onShowMore(): void;
}

export const ItemsOfInterest: React.FC<Props> = ({
  title: groupTitle,
  items,
  onShowMore,
}: Props): React.ReactElement => {
  return (
    <div className="flex flex-col relative w-2/5 min-w-0">
      <h4 className="text-base normal-case mb-4">{groupTitle}</h4>
      <TruncatedTags tags={items} lines={3} onExpand={onShowMore} />
      <div className="flex-1" />
    </div>
  );
};
