import { Dropdown } from 'components/dropdown';
import SVGIcon from 'components/icons/SVGIcon';
import { format, parse } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import Calendar from 'react-calendar';

interface Props {
  onChange(value: Date | Date[]): void;
  readonly value: string | null;
  readonly label: string;
  readonly maxDate?: Date;
  readonly minDate?: Date;
  readonly id: string;
}

export const DatePicker: React.FC<Props> = ({
  onChange,
  value,
  label,
  maxDate,
  minDate,
  id,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const date = useMemo((): Date | null => {
    if (value === null) {
      return null;
    }

    return parse(value, 'MM/dd/yyyy', new Date());
  }, [value]);

  const hideDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showDropdown = useCallback(() => {
    setIsOpen(true);
  }, []);
  return (
    <>
      <div id={id} ref={setAnchor} onClick={showDropdown} className="flex-1 cursor-pointer">
        <div className="w-full rounded pl-3 pr-4 py-1 text-center hover:bg-gray-medium focus:bg-gray-medium focus:outline-none flex">
          <SVGIcon
            name="calendar-icon"
            className="w-4 h-4 mt-px mr-2 fill-current text-gray-dark"
          />
          <div className="text-sm text-gray-darkest flex flex-col font-poppins items-start">
            <span className="uppercase font-poppinsSemiBold text-gray-darkest">{label}</span>
            <span>{date ? format(date, 'MMM yyyy') : 'Select date'}</span>
          </div>
        </div>
      </div>
      <Dropdown isOpen={isOpen} onClose={hideDropdown} anchor={anchor} className="z-dropdown">
        <div className="p-3">
          <Calendar
            nextLabel={<SVGIcon name="next-icon" className="fill-current text-gray w-3 h-3" />}
            prevLabel={<SVGIcon name="previous-icon" className="fill-current text-gray w-3 h-3" />}
            next2Label={<div />}
            prev2Label={<div />}
            onChange={onChange}
            value={date}
            maxDetail="year"
            view="month"
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      </Dropdown>
    </>
  );
};
