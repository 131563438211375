import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import MarketCapColumn from 'components/valueChain/marketCapColumn';
import ValueChainBubbleLink from 'components/valueChain/valueChainBubbleLink';
import React from 'react';
import { Link } from 'react-router-dom';
import { ValueChainEntityType, ValueChainTableRow } from 'types/organization/types';

const columns: ReadonlyArray<ColumnDefinition<ValueChainTableRow>> = [
  {
    name: 'type',
    type: ColumnType.text,
    alignment: Alignment.left,
    sortable: false,
    label: 'Relationship type',
    weight: 2,
  },
  {
    name: 'name',
    type: ColumnType.custom,
    sortable: false,
    label: 'Entity name',
    headerAlignment: Alignment.left,
    weight: 3,
    render: function (_: string, row: ValueChainTableRow): React.ReactNode {
      const url = getValueLinkURL(row);

      return (
        <>
          {url ? (
            <Link className="font-poppins text-blue" to={url}>
              {row.name}
            </Link>
          ) : (
            <div className="normal-case">{row.name}</div>
          )}
        </>
      );
    },
  },
  {
    name: 'marketcap',
    type: ColumnType.custom,
    headerAlignment: Alignment.center,
    sortable: true,
    label: 'Market Cap (USD 000)',
    weight: 2,
    render: function (value: number | null): React.ReactNode {
      return <MarketCapColumn value={value} />;
    },
  },
  {
    name: 'id',
    type: ColumnType.custom,
    sortable: false,
    label: '',
    headerAlignment: Alignment.left,
    weight: 2,
    render: function (_: string, row: ValueChainTableRow): React.ReactNode {
      return <ValueChainBubbleLink row={row} />;
    },
  },
  {
    name: 'description',
    type: ColumnType.text,
    sortable: false,
    label: 'Notes',
    weight: 6,
    alignment: Alignment.left,
  },
];

const getValueLinkURL = (row: ValueChainTableRow): string | undefined => {
  switch (row.entity_type) {
    case ValueChainEntityType.company:
      // FIXME: we should make this like the one below /overview
      return `/data-analysis/company/details/${row.entity_id}`;
    case ValueChainEntityType.industry:
      return `/data-analysis/industry/details/${row.entity_id}/overview`;
    case ValueChainEntityType.salesGeographies:
    case ValueChainEntityType.subsidiaries:
    case ValueChainEntityType.institutionalInvestors:
    case ValueChainEntityType.relatedIndustries:
    case ValueChainEntityType.regulators:
    case ValueChainEntityType.peers:
    case ValueChainEntityType.keyCustomers:
    case ValueChainEntityType.keySuppliers:
    case ValueChainEntityType.purchasingIndustries:
    case ValueChainEntityType.supplyingIndustries:
    case ValueChainEntityType.competingIndustries:
    case ValueChainEntityType.complementaryIndustries:
    case ValueChainEntityType.other:
      return undefined;
  }
};

export default columns;
