import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { LinkTab } from 'components/tabs/components/linkTab';
import { Tab } from 'components/tabs/components/tab';
import React from 'react';
import TermsAndConditions from 'views/Landing/TermsAndConditions';
import { Faqs } from 'views/TourAndFAQs/faqs';

export const Help: React.FC = (): React.ReactElement => {
  return (
    <MainOutletContent>
      <Tabs variant={TabsVariant.buttons} shrinkFactor={6}>
        <Tab label="FAQs" element={<Faqs />} />
        <Tab
          label="TERMS & CONDITIONS"
          path="terms-and-conditions"
          element={<TermsAndConditions />}
        />
        <LinkTab label="CONTACT US" to="mailto:team@capnote.com" />
      </Tabs>
    </MainOutletContent>
  );
};
