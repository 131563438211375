// FIXME: this is ok for now, but we really should try to do it right
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import api from 'utils/config/axiosConfig';
import { delayedExecution } from 'utils/delayedExecution';
import { toQueryString } from 'utils/toQueryString';

// FIXME: this does not belong here, it's just an accident
export type ThunkLikeAction = (dispatch: Dispatch) => void;
const cancellableActionUUID = '4b7985e5-dac1-4e22-a18b-86901a93ba36';

export interface APIAction {
  readonly uuid: '4b7985e5-dac1-4e22-a18b-86901a93ba36';
  readonly type: string;

  (...args: any[]): (dispatch: Dispatch) => VoidFunction;
}

export interface HttpClient {
  POST(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse>;
  PUT(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse>;
  GET(url: string, query?: any, options?: AxiosRequestConfig): Promise<AxiosResponse>;
  DELETE(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse>;

  cancel(): void;
}

export class HttpClient {
  // This is "NEVER" used in principle, it is just initialized here so that the
  // compiler is not sad about it
  private cancelTokenSources: CancelTokenSource[] = [];
  private usedCancelTokenSources: Record<string, CancelTokenSource> = {};

  private createCancelToken = (options?: AxiosRequestConfig): void => {
    if (options?.cancelToken) {
      console.warn(manualCancelTokenWarning);
    }

    this.cancelTokenSources.push(axios.CancelToken.source());
  };

  private nextCancelToken(url: string): CancelToken {
    const next = this.cancelTokenSources.pop();
    if (next) {
      this.usedCancelTokenSources[url] = next;
      return next.token;
    }

    throw new Error('no cancel token available');
  }

  public POST = (url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    this.createCancelToken(options);
    return delayedExecution(
      api.post(url, data, { ...options, cancelToken: this.nextCancelToken(url) }),
      execDelay,
    );
  };

  public PUT = (url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    this.createCancelToken(options);
    return delayedExecution(
      api.put(url, data, { ...options, cancelToken: this.nextCancelToken(url) }),
      execDelay,
    );
  };

  public GET = (url: string, query?: any, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    this.createCancelToken(options);
    return delayedExecution(
      api.get(url + toQueryString(query), { ...options, cancelToken: this.nextCancelToken(url) }),
      execDelay,
    );
  };

  public DELETE = (url: string, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
    this.createCancelToken(options);
    return delayedExecution(
      api.delete(url, { ...options, cancelToken: this.nextCancelToken(url) }),
      execDelay,
    );
  };

  public cancel = (): void => {
    const { usedCancelTokenSources } = this;
    const entries = Object.entries(usedCancelTokenSources);

    for (const [_, tokenSource] of entries) {
      tokenSource.cancel();
    }
  };
}

export type ActionsGenerator = (...args: any[]) => AsyncGenerator<AnyAction>;

export const createAPIAction = (
  createExecutor: (client: HttpClient) => ActionsGenerator,
): ((...args: any[]) => APIAction) => {
  const httpClient = new HttpClient();
  const executor = createExecutor(httpClient);

  return (...args: any[]): APIAction => {
    const run = async (dispatch: Dispatch): Promise<void> => {
      // Call the actual "action"
      for await (const action of executor(...args)) {
        dispatch(action);
      }
    };

    const action = (dispatch: Dispatch): VoidFunction => {
      void run(dispatch);

      return (): void => {
        httpClient.cancel();
      };
    };

    return Object.defineProperties<APIAction>(<any>action, {
      uuid: {
        value: cancellableActionUUID,
        enumerable: false,
        configurable: false,
        writable: false,
      },
      type: {
        value: toActionName(createExecutor.name),
        enumerable: true,
        configurable: false,
        writable: false,
      },
    });
  };
};

const toActionName = (baseName: string): string => {
  return 'api/' + baseName.replace('ActionGeneratorCreator', '');
};

const isAPIAction = (action: AnyAction | APIAction): action is APIAction => {
  if (typeof action === 'function' && 'uuid' in action) {
    return action.uuid === cancellableActionUUID;
  }

  return false;
};

export const apiMiddleware: Middleware =
  (store: MiddlewareAPI): ((next: Dispatch) => (action: AnyAction | APIAction) => any) =>
  (next: (action: AnyAction | APIAction) => AnyAction): ((action: AnyAction | APIAction) => any) =>
  (action: AnyAction | APIAction): any => {
    if (isAPIAction(action)) {
      return action(store.dispatch);
    }

    return next(action);
  };

const manualCancelTokenWarning =
  'please do not pass cancel tokens directly, this is not supported and the token will be ignored';

const execDelay = 0.35;
