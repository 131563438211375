import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setFormValue } from 'redux/reducers/addPortfolioItemFormReducer';

type SetterFunction<T, R> = (arg: T) => R;
export type UpdaterFor<T> = (key: keyof T) => SetterFunction<any, void>;

export const useUpdaterFor = <T>(): UpdaterFor<T> => {
  const dispatch = useDispatch();

  return useCallback(
    (key: keyof T): SetterFunction<any, void> => {
      return (value: any): void => {
        dispatch(setFormValue({ key: String(key), value: value }));
      };
    },
    [dispatch],
  );
};
