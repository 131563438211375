import { Breadcrumbs } from 'components/breadcrumbs';
import Button from 'components/buttons/Button/Button';
import { ConditionalRender } from 'components/conditionalRenderer';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { useFollowHandler } from 'hooks/useFollowUnfollow';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { userSelector } from 'redux/reducers/authReducer';
import { userProfileSelector } from 'redux/reducers/profileReducer';
import FollowButton, { FollowAction } from 'views/Connections/Overview/components/FollowButton';
import Details from 'views/Profile/Details';
import ProfilePayments from 'views/Profile/Payments';
import Settings from 'views/Profile/Settings';

const Profile: React.FC = (): React.ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const { trackEvent } = useGtag();

  const navigate = useNavigate();
  const handleFollow = useFollowHandler();
  const goToProfile = useCallback((): void => navigate('/profile'), [navigate]);
  const profile = useSelector(userProfileSelector);
  const loggedInUser = useSelector(userSelector);

  const handleFollowButton = (followAction: FollowAction, userId: string): void => {
    handleFollow(followAction, userId);
    trackEvent(`${followAction}-user`, {
      section: 'profile',
      user_id: userId,
    });
  };

  return (
    <MainOutletContent>
      <div className="flex justify-between mb-4">
        <Breadcrumbs path={['PROFILE']} />
        <ConditionalRender renderIf={userId !== undefined}>
          <div className="absolute right-3 top-2">
            {userId === loggedInUser.id ? (
              <Button label="Edit" width="w-24" onClick={goToProfile} />
            ) : (
              <FollowButton
                followed={!!profile?.followed_by_you}
                clickData={userId}
                onClick={handleFollowButton}
              />
            )}
          </div>
        </ConditionalRender>
      </div>

      <ConditionalRender renderIf={userId === undefined}>
        <Tabs variant={TabsVariant.buttons}>
          <Tab label="Profile" element={<Details />} path="overview/*" />
          <Tab label="Payments" element={<ProfilePayments />} path="payments" />
          <Tab label="Settings" element={<Settings />} path="settings" />
        </Tabs>
      </ConditionalRender>

      <ConditionalRender renderIf={userId !== undefined}>
        <Details userId={userId} />
      </ConditionalRender>
    </MainOutletContent>
  );
};

export default Profile;
