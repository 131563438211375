import React, { useMemo } from 'react';
import { BillingInterval, SubscriptionPlan } from 'redux/reducers/subscriptionPlansReducer';
import { SubscriptionPlanItemHeaderContainer } from 'views/SubscriptionPlans/subscriptionPlanItemHeaderContainer';

interface Props {
  readonly subscription: SubscriptionPlan;
  readonly billingInterval: BillingInterval;
  readonly active: boolean;
  readonly selected: boolean;
}

export const SubscriptionPlanItemHeader: React.FC<Props> = ({
  subscription,
  billingInterval,
  active,
  selected,
}: Props): React.ReactElement => {
  const specialOffer = useMemo((): string => {
    if (subscription.free_trial_days !== undefined && subscription.free_trial_days !== null) {
      return `FREE ${subscription.free_trial_days}-DAY TRIAL`;
    } else if (subscription.discount_percent !== undefined) {
      return `${subscription.discount_percent}% OFF`;
    } else {
      return '';
    }
  }, [subscription.discount_percent, subscription.free_trial_days]);

  const specialOfferColor = useMemo((): 'red' | 'blue' | 'gray' => {
    if (subscription.free_trial_days !== undefined && subscription.free_trial_days !== null) {
      return 'blue';
    } else if (subscription.discount_percent !== undefined) {
      return 'red';
    } else {
      return 'gray';
    }
  }, [subscription.discount_percent, subscription.free_trial_days]);

  const price = useMemo((): string => {
    if (subscription.monthly_price === 0 && subscription.yearly_price === 0) {
      return 'Free';
    }

    switch (billingInterval) {
      case BillingInterval.yearly:
        return noCentsFormatter.format(subscription.yearly_price);
      case BillingInterval.monthly:
        return noCentsFormatter.format(subscription.monthly_price);
      default:
        throw new Error('invalid billing cycle');
    }
  }, [billingInterval, subscription.monthly_price, subscription.yearly_price]);

  return (
    <SubscriptionPlanItemHeaderContainer
      title={subscription.name}
      active={active}
      selected={selected}
      priceString={price}
      billingInterval={billingIntervalLabels[billingInterval]}
      specialOffer={specialOffer}
      specialOfferColor={specialOfferColor}
    />
  );
};

const billingIntervalLabels: { [key in BillingInterval]: string } = {
  [BillingInterval.monthly]: 'Per month',
  [BillingInterval.yearly]: 'Per year',
};

const noCentsFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
