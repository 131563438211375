import React from 'react';

interface Props {
  readonly label: string;
  readonly count: number | null;
}

export const GraphNodeLabel: React.FC<Props> = ({ label, count }: Props): React.ReactElement => {
  if (count === null) {
    return <span>{label}</span>;
  } else {
    return (
      <span>
        {label} ({count})
      </span>
    );
  }
};
