import { useGtag } from 'hooks/useGtag';
import React, { useCallback, useEffect, useState } from 'react';
import { FilterDropdown } from 'views/Home/components/filterDropdown';
import KeywordSearch from 'views/Home/components/shared/keywordSearch';
import { FeedFilterCategory } from 'views/Home/types';

export interface FeedFilter {
  readonly keyword: string;
  readonly category: FeedFilterCategory;
}

export class FeedFilter {
  public static empty(): FeedFilter {
    return {
      keyword: '',
      category: process.env.REACT_APP_DATA_PORTAL
        ? FeedFilterCategory.none
        : FeedFilterCategory.myInterests,
    };
  }
}

interface Props {
  readonly filter: FeedFilter;
  onFilter(filter: FeedFilter): void;
}

export const FiltersBar: React.FC<Props> = ({ filter, onFilter }: Props): React.ReactElement => {
  const [keywordInputValue, setKeywordInputValue] = useState<string>('');
  const { trackEvent } = useGtag();

  const handleKeywordInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setKeywordInputValue(value);
    },
    [],
  );

  const handleKeywordInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const trimmedKeywordValue = keywordInputValue.trim();
      if (event.key === 'Enter') {
        if (trimmedKeywordValue) {
          setKeywordInputValue(trimmedKeywordValue);
          trackEvent('search-feed', { keyword: trimmedKeywordValue });
          onFilter({ ...filter, keyword: trimmedKeywordValue });
        } else {
          setKeywordInputValue('');
        }
      }
    },
    [filter, keywordInputValue, onFilter, trackEvent],
  );

  const handleKeywordInputReset = useCallback((): void => {
    setKeywordInputValue('');
    onFilter({ ...filter, keyword: '' });
  }, [filter, onFilter]);

  const handleCategoryChange = useCallback(
    (categoryId: string): void => {
      switch (categoryId) {
        case FeedFilterCategory.none:
        case FeedFilterCategory.post:
        case FeedFilterCategory.news:
        case FeedFilterCategory.bookmarks:
        case FeedFilterCategory.myInterests:
          onFilter({ ...filter, category: categoryId as FeedFilterCategory });
          break;
        default:
          console.warn('invalid category provided for category change handler');
      }
    },
    [filter, onFilter],
  );

  useEffect((): void => {
    setKeywordInputValue(filter.keyword);
  }, [filter.keyword]);

  return (
    <div className="lg:px-6 px-4 bg-white py-5 rounded-tl rounded-tr border-b border-gray-light z-1 sticky top-0">
      <div className="flex justify-between">
        <h4 className="text-base mb-6 text-gray-darkest">FEED</h4>
      </div>
      <div className="flex justify-between flex-col md:flex-row">
        <KeywordSearch
          id="feed-keyword-search-input"
          value={keywordInputValue}
          placeholder="Search feed by keyword"
          onChange={handleKeywordInputChange}
          onKeyPress={handleKeywordInputKeyPress}
          onClickReset={handleKeywordInputReset}
        />
        {process.env.REACT_APP_DATA_PORTAL ? null : (
          <FilterDropdown category={filter.category} onChange={handleCategoryChange} />
        )}
      </div>
    </div>
  );
};
