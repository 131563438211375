// Library
import NoNotificationsIcon from 'assets/icons/svg/no-notification-icon.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import EmptySection from 'components/emptySection/EmptySection';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { ColumnDefinition } from 'components/table/types';
import { useSort } from 'hooks/useSort';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'redux/reducers/notificationsReducer';
import { Page } from 'types/page';

interface Props {
  readonly notificationsPage: Page<Notification>;
  readonly columns: ReadonlyArray<ColumnDefinition<Notification>>;
  readonly loading: boolean;
  readonly rootPath: string;
}

const NotificationsView: React.FC<Props> = ({
  columns,
  loading,
  notificationsPage,
}: Props): React.ReactElement => {
  const { data: notifications } = notificationsPage;
  const [sorting, onSort] = useSort<Notification>('date', SortOrder.descending);
  const navigate = useNavigate();

  const handleNotificationClick = useCallback(
    (notification: Notification): void => {
      navigate(notification.id);
    },
    [navigate],
  );

  return (
    <div className="flex flex-col flex-1 relative">
      <ConditionalRender renderIf={notifications.length > 0 && !loading}>
        <Table
          columns={columns}
          rows={notifications}
          sortBy={sorting}
          onRowClick={handleNotificationClick}
          onSortChange={onSort}
        />
        <Pagination totalPages={notificationsPage.page_count} />
      </ConditionalRender>

      <ConditionalRender renderIf={notifications.length === 0 && !loading}>
        <EmptySection title="No pending notifications" icon={NoNotificationsIcon} />
      </ConditionalRender>

      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default NotificationsView;
