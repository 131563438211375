import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'views/Landing/LandingPage/components/Layout';
import { Main } from 'views/Landing/LandingPage/components/Main';
import { OurPlans } from 'views/Landing/LandingPage/components/OurPlans';
import { PublicEarnAndBuy } from 'views/Landing/LandingPage/components/PublicEarnAndBuy';

const LandingPage: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route element={<Main />} index />
        <Route path="our-plans" element={<OurPlans />} />
        <Route path="earn-n-buy/*" element={<PublicEarnAndBuy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default LandingPage;
