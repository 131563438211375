import { AnyAction } from '@reduxjs/toolkit';
import {
  createUserPeerGroupCompleted,
  createUserPeerGroupFailed,
  createUserPeerGroupStarted,
  createUserPeerGroupSucceeded,
  deleteUserPeerGroupCompleted,
  deleteUserPeerGroupFailed,
  deleteUserPeerGroupStarted,
  deleteUserPeerGroupSucceeded,
  fetchUserPeerGroupAlgoNotesFailed,
  fetchUserPeerGroupAlgoNotesStarted,
  fetchUserPeerGroupAlgoNotesSucceeded,
  fetchUserPeerGroupComparisonsCompleted,
  fetchUserPeerGroupComparisonsFailed,
  fetchUserPeerGroupComparisonsStarted,
  fetchUserPeerGroupComparisonsSucceeded,
  fetchUserPeerGroupsCompleted,
  fetchUserPeerGroupsFailed,
  fetchUserPeerGroupsStarted,
  fetchUserPeerGroupsSucceeded,
  updateUserPeerGroupCompleted,
  updateUserPeerGroupFailed,
  updateUserPeerGroupStarted,
  updateUserPeerGroupSucceeded,
} from 'redux/reducers/userPeerGroupsReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import settings from 'utils/config/appSettings';
import { API_V1_PATH } from 'utils/config/axiosConfig';
import { toQueryString } from 'utils/toQueryString';

export interface PeerCompany {
  readonly id: string;
  readonly focus: boolean;
  readonly name: string;
  readonly ticker: string;
}

export interface CreatePeerGroupPayload {
  readonly name: string;
  readonly description?: string;
  readonly tags: readonly string[];
  readonly companies: Array<Pick<PeerCompany, 'id' | 'focus'>>;
}

export const fetchUserPeerGroupsAction = createAPIAction(
  function fetchUserPeerGroupsActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (query: Record<string, string>): AsyncGenerator<AnyAction> {
      yield fetchUserPeerGroupsStarted();
      try {
        const queryString = toQueryString({
          page_number: 1,
          page_size: settings.defaultPageSize,
          ...query,
        });
        const response = await client.GET(`${API_V1_PATH}/smart-tools/peer-groups${queryString}`);
        yield fetchUserPeerGroupsSucceeded(response.data);
      } catch (error: any) {
        yield fetchUserPeerGroupsFailed(error);
      } finally {
        yield fetchUserPeerGroupsCompleted();
      }
    };
  },
);

export const createUserPeerGroupAction = createAPIAction(
  function fetchUserPeerGroupsActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (postPayload: CreatePeerGroupPayload): AsyncGenerator<AnyAction> {
      yield createUserPeerGroupStarted();
      try {
        const response = await client.POST(`${API_V1_PATH}/smart-tools/peer-groups`, postPayload);
        yield createUserPeerGroupSucceeded(response.data);
      } catch (error: any) {
        yield createUserPeerGroupFailed(error);
      } finally {
        yield createUserPeerGroupCompleted();
      }
    };
  },
);

export const fetchUserPeerGroupAction = createAPIAction(function fetchUserPeerGroupActionGenerator(
  client: HttpClient,
): ActionsGenerator {
  return async function* (peerGroupId: string): AsyncGenerator<AnyAction> {
    yield fetchUserPeerGroupComparisonsStarted();
    try {
      const response = await client.GET(`${API_V1_PATH}/smart-tools/peer-groups/${peerGroupId}`);
      yield fetchUserPeerGroupComparisonsSucceeded(response.data);
    } catch (error: any) {
      yield fetchUserPeerGroupComparisonsFailed(error);
    } finally {
      yield fetchUserPeerGroupComparisonsCompleted();
    }
  };
});

export const updateUserPeerGroupAction = createAPIAction(
  function updateUserPeerGroupActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (
      peerGroupId: string,
      putPayload: CreatePeerGroupPayload,
    ): AsyncGenerator<AnyAction> {
      yield updateUserPeerGroupStarted();
      try {
        const response = await client.PUT(
          `${API_V1_PATH}/smart-tools/peer-groups/${peerGroupId}`,
          putPayload,
        );
        yield updateUserPeerGroupSucceeded(response.data);
      } catch (error: any) {
        yield updateUserPeerGroupFailed(error);
      } finally {
        yield updateUserPeerGroupCompleted();
      }
    };
  },
);

export const deleteUserPeerGroupAction = createAPIAction(
  function updateUserPeerGroupActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (peerGroupId: string): AsyncGenerator<AnyAction> {
      yield deleteUserPeerGroupStarted(peerGroupId);
      try {
        await client.DELETE(`${API_V1_PATH}/smart-tools/peer-groups/${peerGroupId}`);
        yield deleteUserPeerGroupSucceeded();
        yield fetchUserPeerGroupsAction();
      } catch (error: any) {
        yield deleteUserPeerGroupFailed(error);
      } finally {
        yield deleteUserPeerGroupCompleted();
      }
    };
  },
);

export const fetchUserPeerGroupAlgoNotesAction = createAPIAction(function fetchAlgoNotes(
  client: HttpClient,
): ActionsGenerator {
  return async function* (
    peerGroupId: string,
    filters: Record<string, string>,
  ): AsyncGenerator<AnyAction> {
    yield fetchUserPeerGroupAlgoNotesStarted();

    try {
      const response = await client.GET(`${API_V1_PATH}/algo-notes/peer-group/${peerGroupId}`, {
        page_number: 1,
        page_size: settings.defaultPageSize,
        ...filters,
      });
      yield fetchUserPeerGroupAlgoNotesSucceeded(response.data);
    } catch (error: any) {
      yield fetchUserPeerGroupAlgoNotesFailed(error);
    }
  };
});
