import { ReactComponent as BellIcon } from 'assets/icons/svg/finvar-notification-icon.svg';
import { ReactComponent as Avatar } from 'assets/images/genderless-icon.svg';
import React from 'react';
import { NotificationType } from 'redux/reducers/sharedReducer';

export const getNotificationIcon = (
  source: string,
  notificationType: NotificationType,
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
  void source;
  switch (notificationType) {
    case NotificationType.PortfoliosNewsAndNotifications:
    case NotificationType.FINVARNotifications:
    case NotificationType.Account:
    case NotificationType.CompanyPriceAlert:
    case NotificationType.KRF:
    case NotificationType.News:
    case NotificationType.Organization:
    case NotificationType.Portfolio:
    case NotificationType.Event:
    case NotificationType.Indicators:
      return BellIcon;
    case NotificationType.YouMayKnow:
    case NotificationType.Connections:
    case NotificationType.Post:
    case NotificationType.Comment:
    case NotificationType.UserTag:
      return Avatar;
  }
};
