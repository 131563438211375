import { DynamicColor } from 'components/capnoteMap/components/markerDot';
import { PortfolioCompaniesSubsidiariesMarker } from 'components/capnoteMap/components/portfolioCompaniesSubsidiariesMarker';
import { PortfoliosCompaniesMarker } from 'components/capnoteMap/components/portfoliosCompaniesMarker';
import { PortfoliosRevenuesMarker } from 'components/capnoteMap/components/portfoliosRevenuesMarker';
import { RevenuesMarker } from 'components/capnoteMap/components/revenuesMarker';
import { SubsidiaryMarker } from 'components/capnoteMap/components/subsidiaryMarker';
import { MarkerType } from 'components/capnoteMap/types';
import React from 'react';

interface Props {
  readonly marker: MarkerType;

  readonly colors?: Record<string, DynamicColor>;
  readonly active?: string | null;

  onClick(marker: MarkerType | null): void;
}

export const CommonMarker: React.FC<Props> = ({
  marker,
  colors,
  active,
  onClick,
}: Props): React.ReactElement => {
  switch (marker.kind) {
    case 'subsidiary':
      return <SubsidiaryMarker marker={marker} onClick={onClick} />;
    case 'revenues':
      return <RevenuesMarker marker={marker} />;
    case 'portfoliosCompanies':
      return <PortfoliosCompaniesMarker marker={marker} colors={colors} active={active} />;
    case 'portfoliosRevenues':
      return <PortfoliosRevenuesMarker marker={marker} />;
    case 'portfolioCompaniesSubsidiaries':
      return (
        <PortfolioCompaniesSubsidiariesMarker marker={marker} colors={colors} active={active} />
      );
    case 'portfolioCompaniesRevenues':
      return <></>;
    default:
      return <></>;
  }
};
