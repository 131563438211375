import SVGIcon from 'components/icons/SVGIcon';
import { PleaseUpgradeTableItem } from 'components/pleaseUpgradeTableItem';
import { ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Company } from 'types/organization/types';
import companyColumns from 'views/DataAnalysis/Company/columns';

const createColumns = (
  deleteHandler: (name: string, id: string) => void,
): ReadonlyArray<ColumnDefinition<Company>> => [
  ...companyColumns,
  {
    name: 'company_id',
    type: ColumnType.custom,
    label: '',
    width: 64,
    render: (value: Company[keyof Company] | null, item: Company): React.ReactNode => {
      if (!item.company_id) {
        return (
          <div>
            <PleaseUpgradeTableItem anchorToNext="tr" />
          </div>
        );
      }
      const handleClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (item.company_id) {
          deleteHandler(item.name, item.company_id);
        }
      };

      return (
        <div
          className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer text-gray hover:bg-gray-200 transition-all"
          onClick={handleClick}
        >
          <SVGIcon name="trash-generic" className="block fill-current w-5 h-5" />
        </div>
      );
    },
  },
];

export default createColumns;
