import { ReminderValue } from 'utils/dateTime';

export const getReminderObject = (
  reminderDate: string /* serialized with: serialize() */,
  eventStartDate: string /* serialized with: serialize() */,
): ReminderValue | null => {
  void reminderDate;
  void eventStartDate;
  // TODO: to be implemented
  return null;
};
