import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { useFilters } from 'hooks/useFilters';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { companyDetailsSelector } from 'redux/reducers/companyReducer';
import { companyKeyFactorsSelector, KeyFactorType } from 'redux/reducers/keyFactorsReducer';
import { getCompanyFactorsService } from 'redux/services/keyFactorsService';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { createFiltersConfig } from 'views/DataAnalysis/Company/Details/KeyFactors/filters';
import { KeyFactorsSection } from 'views/DataAnalysis/Company/Details/KeyFactors/section';

const CompanyKeyFactors: React.FC = (): React.ReactElement => {
  const { pathname } = useLocation();
  const factorsData = useSelector(companyKeyFactorsSelector);

  const factorType = useMemo(
    (): KeyFactorType => (pathname.endsWith('risk') ? 'risk' : 'growth'),
    [pathname],
  );

  const filtersConfig = useMemo(
    (): readonly CommonFilter[] => createFiltersConfig(factorType, factorsData),
    [factorType, factorsData],
  );

  const handleFiltersChange = useFilters(filtersConfig);

  return (
    <>
      <div className="flex items-center justify-between my-3">
        <Filters config={filtersConfig} onChange={handleFiltersChange} />
      </div>
      <KeyFactorsSection
        paramsKey={'companyId'}
        dataSelector={companyKeyFactorsSelector}
        detailsSelector={companyDetailsSelector}
        service={getCompanyFactorsService}
      />
      <DataDisclaimer />
    </>
  );
};

export default CompanyKeyFactors;
