import { ConditionalRender } from 'components/conditionalRenderer';
import Checkbox from 'components/DEPRECATED/checkbox/Checkbox';
import React, { useMemo } from 'react';

interface Props {
  readonly error?: string;

  readonly amountString: string;
  readonly checked: boolean;

  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const TermsAndConditionsForRecurringPayments: React.FC<Props> = ({
  error,
  amountString,
  checked,
  onChange,
}: Props): React.ReactElement => {
  const termsAndConditions = useMemo((): string => createTncText(amountString), [amountString]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <Checkbox
          id="terms-and-agreement-checkbox"
          className="mb-6"
          checked={checked}
          onChange={onChange}
        >
          <div className="relative leading-none aligned">
            <ConditionalRender renderIf={!!error}>
              <div className="absolute mt-2 top-full left-0 bg-gray text-white p-2 rounded-md shadow-md font-poppins text-sm">
                {error}
              </div>
            </ConditionalRender>
            <span className="font-poppins text-gray text-base ">{termsAndConditions}</span>
          </div>
        </Checkbox>
      </div>
    </div>
  );
};

const createTncText = (amountString: string): string => {
  return `I acknowledge my credit card will be charged automatically ${amountString}/monthly for the subscription 
    to Capnote after any applicable one-off discounts. I further acknowledge I can cancel my automatic payments at any time by 
    going to my online profile and changing my payment preferences to avoid being charged for the next billing cycle.`;
};
