import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { BackendMember, Member } from 'sharable/types';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { EventDetails, Stream } from 'types/timeline';
import { parseDate, parseTime } from 'utils/dateTime';
import { serialize } from 'utils/serializer';

export interface EventDetailsState {
  loading: boolean;
  event: EventDetails | null;
  stream: Stream | null;
}

const initialState: EventDetailsState = {
  loading: false,
  event: null,
  stream: null,
};

const slice = createSlice({
  name: 'currentTimelineEvent',
  initialState,
  reducers: {
    fetchStarted: (state: EventDetailsState): void => {
      state.loading = true;
    },
    fetchSucceeded: (state: EventDetailsState, { payload }: PayloadAction<any>): void => {
      const { members } = payload;
      // This is ALWAYS there
      const startDate = serialize(
        parseDate(payload.start_date, payload.start_time, payload.timezone),
      );
      // These are trickier
      const endDate = serialize(parseDate(payload.end_date, payload.end_time, payload.timezone));
      const startTime = parseTime(payload.start_date, payload.start_time, payload.timezone);
      const endTime = parseTime(payload.end_date, payload.end_time, payload.timezone);

      if (startDate === null) {
        throw new Error('start-date cannot be null for an event');
      }

      state.event = {
        id: payload.id,
        title: payload.title,
        summary: payload.summary,
        creator: payload.creator,
        detail: payload.detail,
        urgency: payload.urgency,
        importance: payload.importance,
        tags: payload.tags,
        linked_events: payload.linked_events,
        country: payload.country,
        stream_id: payload.stream_id,
        description: payload.description,
        timezone: payload.timezone,
        access_mode: payload.access_mode,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        reminder: '',
        members:
          members?.map(
            (item: BackendMember): Member => ({
              type: 'internal',
              value: item.id,
              label: item.fullname,
              accessMode: item.access_mode,
            }),
          ) ?? null,
      };
      state.loading = false;
    },
    fetchFailed: (state: EventDetailsState): void => {
      state.loading = false;
    },
    setStream: (state: EventDetailsState, { payload }: PayloadAction<Stream | null>): void => {
      state.stream = payload;
    },
    clearSelectedEvent: (state: EventDetailsState): void => {
      state.event = null;
      state.stream = null;
      state.loading = false;
    },
  },
});

const actions = slice.actions;

export const { setStream, fetchSucceeded: setEventDetails, clearSelectedEvent } = actions;

export const fetchEvent = createAPIAction(function fetchEvent(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (eventId: string): AsyncGenerator<AnyAction> {
    yield actions.fetchStarted();
    try {
      const response = await httpClient.GET(`api/v1/events/${eventId}`);
      yield actions.fetchSucceeded(response.data);
    } catch (error: any) {
      console.warn(error);
      yield actions.fetchFailed();
    }
  };
});

export default slice.reducer;

export const eventDetailsEventSelector = (
  applicationState: ApplicationState,
): EventDetails | null => applicationState.eventDetails.event;

export const eventDetailsStreamSelector = (applicationState: ApplicationState): Stream | null =>
  applicationState.eventDetails.stream;

export const eventDetailsLoadingSelector = (applicationState: ApplicationState): boolean =>
  applicationState.eventDetails.loading;
