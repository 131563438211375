import MenuOptionContent from 'components/sidebar/components/MenuOptionContent';
import { MenuGroupItem, MenuItem } from 'components/sidebar/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  readonly group: MenuGroupItem;
  readonly disabled?: boolean;
}

export const MenuGroup: React.FC<Props> = ({
  group,
  disabled = false,
}: Props): React.ReactElement => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(!!group.openByDefault);

  const { pathname } = location;
  const { children } = group;

  const handleToggleOpen = useCallback(() => {
    setIsOpen((prevState: boolean): boolean => !prevState);
  }, []);

  useEffect((): void => {
    if (isOpen) {
      return;
    }

    setIsOpen(
      children.some((child: MenuItem): boolean => {
        if (child.kind !== 'nav') {
          return false;
        }

        return child.path === pathname.slice(1);
      }),
    );
  }, [children, isOpen, pathname]);

  return (
    <div className="sticky top-0 bg-white mb-8 landscape:mb-2">
      <MenuOptionContent
        disabled={disabled}
        isOpen={isOpen}
        content={group}
        onToggleOpen={handleToggleOpen}
      />
    </div>
  );
};
