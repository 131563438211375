import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  loginSucceeded,
  Session,
  setClient,
  SubscriptionStatus,
} from 'redux/reducers/authReducer';
import { baseURL } from 'utils/config/axiosConfig';
import { zeroUUID } from 'views/SubscriptionPlans/individualsTab';

export const useClientAuthentication = (): void => {
  const dispatch = useDispatch();

  useEffect((): VoidFunction | void => {
    if (!process.env.REACT_APP_DATA_PORTAL) {
      return;
    }

    const handler = async (event: MessageEvent): Promise<void> => {
      const { data } = event;
      const { type } = data;

      if (type === 'access-token') {
        const url = `${baseURL}/api/v1/data-portal/client`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        const client = await response.json();
        if (client.origin !== event.origin) {
          throw new Error('Access denied: ' + event.origin);
        }
        dispatch(setClient(client));

        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, data.token);
        dispatch(loginSucceeded(fakeSession));
      }

      return;
    };

    window.addEventListener('message', handler);
    return (): void => {
      window.removeEventListener('message', handler);
    };
  }, [dispatch]);
};

const fakeSession: Session = {
  session_count: 2,
  token: 'data-portal',
  user: {
    id: 'data-portal',
    email: 'data-portal',
    firstname: 'data-portal',
    lastname: 'data-portal',
    account_type: 'Public',
    payment_methods: [],
    accredited_investor: false,
    created_at: '',
    has_verified_email: true,
    last_session: '',
    sessions_count: 0,
    onboarded: true,
    subscription: {
      id: zeroUUID,
      name: '',
      description: '',
      price: 0,
      features: {},
      expires_at: '',
      purchased_at: '',
      free_trial_days: -1,
      is_automatic_renewal_on: false,
      is_free_trial: true,
      upgradable: false,
      authorization: {},
      status: SubscriptionStatus.free,
      feature_level: Number.MAX_SAFE_INTEGER,
    },
  },
};
