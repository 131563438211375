import { AxiosResponse } from 'axios';
import { Connection, ConnectionsCount } from 'redux/reducers/connectionsReducer';
import { Page } from 'types/page';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { delayedExecution } from 'utils/delayedExecution';
import { ConnectionType } from 'views/Connections/Overview/types';

export const getConnections = async (
  pageNumber: number,
  pageSize: number,
  type: ConnectionType,
): Promise<Page<Connection>> => {
  const response: AxiosResponse = await delayedExecution(
    api.get(`${API_V1_PATH}/connections/${type}`, {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
      },
    }),
    0.5,
  );
  return response.data;
};

export const getConnectionsCount = async (): Promise<ConnectionsCount> => {
  const response: AxiosResponse = await api.get(`${API_V1_PATH}/connections/count`);
  return response.data;
};

export const followUser = async (id: string): Promise<AxiosResponse<Connection>> => {
  const response = await api.post(`${API_V1_PATH}/connections/${id}/follow`);
  if (response.status !== 200) {
    throw new Error('Failed to follow user');
  }

  return response;
};

export const unfollowUser = async (id: string): Promise<void> => {
  await api.post(`/api/v1/connections/${id}/unfollow`);
};

export const searchUsersByKeyword = async (keyword: string): Promise<Connection[]> => {
  const response = await api.get(`${API_V1_PATH}/users/search`, { params: { keyword } });
  return response.data;
};
