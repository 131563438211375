import SpinnerLoader from 'components/spinnerLoader';
import { Tabs } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionsCountAction } from 'redux/actions/connectionsActions';
import {
  processingStateSelector,
  ProcessingType,
  reset,
  socialCountSelector,
} from 'redux/reducers/connectionsReducer';
import { ProcessingStateEnum } from 'types/processingState';
import { AllConnections } from 'views/Connections/Overview/AllConnections';
import { Followees } from 'views/Connections/Overview/Followees';
import { Followers } from 'views/Connections/Overview/Followers';

const Overview: React.FC = () => {
  const dispatch = useDispatch<any>();
  const socialCount = useSelector(socialCountSelector);

  const { following, followers, total } = socialCount;
  const processingState = useSelector(processingStateSelector);

  const isLoadingConnections = useMemo(
    (): boolean =>
      processingState.state === ProcessingStateEnum.processing &&
      processingState.data === ProcessingType.loadingConnections,
    [processingState.data, processingState.state],
  );

  useEffect(() => {
    dispatch(reset());
    dispatch(getConnectionsCountAction());
  }, [dispatch]);

  return (
    <div className="flex-1">
      <div className="h-8" />

      <Tabs shrinkFactor={6}>
        <Tab label={`All (${total})`} element={<AllConnections />} path="all" />
        <Tab label={`Following (${following})`} element={<Followees />} path="following" />
        <Tab label={`Followers (${followers})`} element={<Followers />} path="followers" />
      </Tabs>

      <SpinnerLoader visible={isLoadingConnections} />
    </div>
  );
};

export default Overview;
