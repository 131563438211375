export const text = {
  Referrals: 'Referrals',
  Preferences: 'Preferences',
  ChangePassword: 'Change Password',
  PasswordRules: 'Your password must be at least 8 characters long and contain special characters.',
  DisableAccount: 'Disable Account',
  DisableAccountWarning:
    'By disabling your account your data will be saved and you can restore any time. ' +
    'Are you sure you want to disable your account?',
  DeleteAccount: 'Delete Account',
  DeleteAccountWarning:
    'By deleting your account you ask to delete all your data and activity on the platform. ' +
    'It will not be possible to restore it later.',
  CreateReferralCode: 'Create Referral Code',
};
