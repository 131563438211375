import { PressEscapeMessage } from 'components/postBodyInput/pressEscapeMessage';
import { SuggestionsEmptyItem } from 'components/postBodyInput/suggestionsEmptyItem';
import { TagSuggestionItem } from 'components/postBodyInput/tagSuggestionItem';
import { EditorState } from 'components/postBodyInput/types';
import { UserSuggestionItem } from 'components/postBodyInput/userSuggestionItem';
import React, { CSSProperties } from 'react';
import { SocialUser } from 'views/Home/types';

interface Props {
  readonly suggestions: readonly SocialUser[] | readonly string[];
  readonly style?: CSSProperties;
  readonly editorState: EditorState;
  readonly currentSuggestionIndex: number;

  onSuggestionSelect(value: SocialUser | string): void;
}

export const Suggestions: React.FC<Props> = ({
  suggestions,
  editorState,
  currentSuggestionIndex,
  style,
  onSuggestionSelect,
}: Props): React.ReactElement | null => {
  if (!style) {
    return null;
  }

  if (suggestions.length === 0) {
    return (
      <div className="fixed bg-white shadow-md rounded w-72 overflow-y-auto scroller" style={style}>
        <ul className="w-full">
          <SuggestionsEmptyItem mode={editorState} />
          <PressEscapeMessage />
        </ul>
      </div>
    );
  } else {
    return (
      <div className="fixed bg-white shadow-md rounded w-72 overflow-y-auto scroller" style={style}>
        <ul className="w-full">
          {suggestions.map((item: SocialUser | string, index: number): React.ReactElement => {
            if (typeof item === 'string') {
              return (
                <TagSuggestionItem
                  key={item}
                  tag={item}
                  highlighted={index === currentSuggestionIndex}
                  onClick={onSuggestionSelect}
                />
              );
            } else {
              return (
                <UserSuggestionItem
                  key={item.id}
                  user={item}
                  highlighted={index === currentSuggestionIndex}
                  onClick={onSuggestionSelect}
                />
              );
            }
          })}
        </ul>
      </div>
    );
  }
};
