import { MarkerDot } from 'components/capnoteMap/components/markerDot';
import { Tooltip } from 'components/tooltip';
import { DivIcon, divIcon } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Marker } from 'react-leaflet';
import { CompanyRevenuesLocation } from 'redux/reducers/geographiesAndMapsReducer';
import VALUES_FORMATTERS from 'views/DataAnalysis/valuesFormatters';

interface Props {
  readonly marker: CompanyRevenuesLocation;
}

export const RevenuesMarker: React.FC<Props> = ({ marker }: Props): React.ReactElement => {
  const [icon, setIcon] = useState<DivIcon>(new DivIcon());
  const { coordinates } = marker;

  useEffect(
    (): void =>
      setIcon(
        divIcon({
          html: createDot(marker),
        }),
      ),
    [marker],
  );

  if (coordinates.length !== 1) {
    // If region is "Other" there is no dot!
    return <></>;
  }

  return <Marker position={[coordinates[0].latitude, coordinates[0].longitude]} icon={icon} />;
};

const createDot = (marker: CompanyRevenuesLocation): HTMLElement => {
  const element = document.createElement('div');
  const root = createRoot(element);

  root.render(
    <Tooltip
      content={
        <div className="text-center">
          <h4 className="flex items-center justify-between mt-1">
            <span>{marker.name}</span>
            <span>&ensp;</span>
            <span className="text-xs font-poppinsSemiBold bg-blue text-white px-1 rounded">
              {VALUES_FORMATTERS['percentage'](marker.percentage_of_total)}
            </span>
          </h4>
          <p className="text-gray whitespace-nowrap">
            <span className="font-jostSemiBold">
              ${VALUES_FORMATTERS['currency'](marker.sales)}
            </span>
          </p>
        </div>
      }
      variant="light"
      fitContent={true}
    >
      <MarkerDot size="normal" color="green" />
    </Tooltip>,
  );

  return element;
};
