import SVGIcon from 'components/icons/SVGIcon';
import { StarRating } from 'components/starRating';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import { defaultLocale } from 'constants/defaultLocale';
import React from 'react';
import { LibraryItem, ListingOwner } from 'views/EarnAndBuy/api';
import { ListingOwnerLink } from 'views/EarnAndBuy/Earn/components/listingOwnerLink';
import { Ownership } from 'views/EarnAndBuy/Library/ownership';

const columns: ReadonlyArray<ColumnDefinition<LibraryItem>> = [
  {
    type: ColumnType.date,
    label: 'Date Added',
    name: 'added_at',
    alignment: Alignment.center,
    sortable: true,
  },

  {
    type: ColumnType.text,
    label: 'Title',
    name: 'title',
    alignment: Alignment.left,
    weight: 2,
  },
  {
    type: ColumnType.custom,
    label: 'Price',
    name: 'price_amount',
    sortable: true,
    render: (value: number, item: LibraryItem): React.ReactNode => {
      const formatter = Intl.NumberFormat(defaultLocale, {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: item.price_currency,
      });

      return <p className="text-center">{formatter.format(value)}</p>;
    },
  },
  {
    type: ColumnType.custom,
    label: 'Rating',
    name: 'star_rating',
    sortable: true,
    headerAlignment: Alignment.center,
    weight: 2,
    render: (rating: number): React.ReactNode => {
      return <StarRating value={rating} />;
    },
  },
  {
    type: ColumnType.custom,
    label: 'Listing Owner',
    name: 'listing_owner',
    headerAlignment: Alignment.center,
    sortable: true,
    width: 300,
    render: (owner: ListingOwner): React.ReactNode => {
      return <ListingOwnerLink owner={owner} />;
    },
  },
  {
    type: ColumnType.custom,
    label: 'Content Link',
    name: 'content_uri',
    headerAlignment: Alignment.center,
    sortable: false,
    render: (contentUri: string): React.ReactNode => {
      return (
        <a
          className="flex items-center justify-center text-blue font-poppinsMedium underline"
          href={contentUri}
          target="_blank"
          rel="noreferrer"
        >
          <p>View</p>
          <SVGIcon name="external-link" className="w-4 h-4 ml-1 text-blue fill-current" />
        </a>
      );
    },
  },
  {
    type: ColumnType.custom,
    label: 'Ownership',
    name: 'id',
    headerAlignment: Alignment.center,
    sortable: true,
    render: (_: string, item: LibraryItem): React.ReactNode => {
      return <Ownership item={item} />;
    },
    width: 200,
  },
];

export default columns;
