import { CSSProperties } from 'react';

export const SINGLE_SPACE = '\u00A0';

export const isBeforeInputEventAvailable = (): boolean =>
  window.InputEvent && typeof InputEvent.prototype.getTargetRanges === 'function';

export const getDropdownStyle = (targetNode: Node): CSSProperties => {
  const selection = document.getSelection();
  if (selection == null || selection.rangeCount === 0) {
    return {};
  }

  const range = document.createRange();
  const text = targetNode.textContent ?? '';

  range.setStart(targetNode, text.length);

  const rectangles = range.getClientRects();
  if (rectangles.length === 0) {
    return {};
  }

  const top = rectangles[0].bottom + 8;
  const maxHeight = window.innerHeight - top - 10;
  const left = rectangles[0].left;

  return { top, left, maxHeight };
};
