import NoIcon from 'assets/icons/svg/no-portfolios-icon.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import EmptySection from 'components/emptySection/EmptySection';
import { Filters } from 'components/filters';
import Pagination from 'components/pagination/Pagination';
import { SearchBox } from 'components/searchBox';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import { noop } from 'lodash';
import React, { useMemo } from 'react';
import { LibraryItem, useLibraryQuery } from 'views/EarnAndBuy/api';
import { useKeywordSearch } from 'views/EarnAndBuy/hooks/useKeywordSearch';
import columns from 'views/EarnAndBuy/Library/columns';
import filters from 'views/EarnAndBuy/Library/filters';

export const Library: React.FC = (): React.ReactElement => {
  const queryParameters = useQueryParameters();
  const { data: page, isLoading } = useLibraryQuery(queryParameters);

  const [sortBy, handleSortChange] = useSort<LibraryItem>('added_at', SortOrder.descending);

  const rows = useMemo((): readonly LibraryItem[] => page?.data ?? [], [page]);
  const pageCount = useMemo((): number => page?.page_count ?? 0, [page]);
  const handleFiltersChange = useFilters(filters);
  const [searchKeyword, setSearchKeyword] = useKeywordSearch();

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-end justify-between mt-4 mb-3">
        <div className="w-full">
          <div className="-mt-2 mb-3">
            <SearchBox value={searchKeyword} onChange={setSearchKeyword} />
          </div>
          <div className="flex items-center justify-between w-full">
            <Filters config={filters} onChange={handleFiltersChange} />
          </div>
        </div>
      </div>

      <div className="relative flex-1">
        <ConditionalRender renderIf={pageCount === 0 && !isLoading}>
          <EmptySection title="There are no listings" icon={NoIcon} />
        </ConditionalRender>

        <ConditionalRender renderIf={pageCount > 0}>
          <Pagination totalPages={pageCount}>
            <Table
              columns={columns}
              rows={rows}
              sortBy={sortBy}
              onRowClick={noop}
              onSortChange={handleSortChange}
            />
          </Pagination>
        </ConditionalRender>

        <SpinnerLoader visible={isLoading} />
      </div>
    </div>
  );
};
