import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hasSameId, Page } from 'types/page';
import { ProcessingState } from 'types/processingState';

export enum ProcessingType {
  loadingConnections = 'loadingConnections',
  loadingConnectionsCount = 'loadingConnectionsCount',
  following = 'following',
  unfollowing = 'unfollowing',
  searching = 'searching',
}

export interface Connection {
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly fullname: string;
  readonly about_me: string;
  readonly followed_by_you: boolean;
  readonly follows_you: boolean;
  readonly photo_url?: string;
  readonly accredited_investor: boolean;
}

export interface ConnectionsCount {
  readonly followers: number;
  readonly following: number;
  readonly total: number;
}

export interface ConnectionsState {
  connections: Page<Connection>;
  followers: Page<Connection>;
  following: Page<Connection>;
  connectionsCount: ConnectionsCount;
  searchingUsers: boolean;
  userSearchResults: Connection[];
  processingState: ProcessingState<any>;
}

const initialState: ConnectionsState = {
  connections: Page.empty(),
  followers: Page.empty(),
  following: Page.empty(),
  connectionsCount: { following: 0, followers: 0, total: 0 },
  processingState: ProcessingState.idle(),
  searchingUsers: false,
  userSearchResults: [],
};

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setProcessingState: (
      state: ConnectionsState,
      { payload }: PayloadAction<ProcessingState<any>>,
    ) => {
      state.processingState = payload;
    },
    setAllConnections: (state: ConnectionsState, { payload }: PayloadAction<Page<Connection>>) => {
      state.connections = payload;
    },
    setFollowing: (state: ConnectionsState, { payload }: PayloadAction<Page<Connection>>) => {
      state.following = payload;
    },
    setFollowers: (state: ConnectionsState, { payload }: PayloadAction<Page<Connection>>) => {
      state.followers = payload;
    },
    setSocialCount: (state: ConnectionsState, { payload }: PayloadAction<ConnectionsCount>) => {
      state.connectionsCount = payload;
    },
    userFollowed: (state: ConnectionsState, { payload: connection }: PayloadAction<Connection>) => {
      const isSame = hasSameId(connection);
      const mapper = (currentConnection: Connection): Connection => {
        if (currentConnection.id === connection.id) {
          return { ...currentConnection, followed_by_you: true };
        } else {
          return currentConnection;
        }
      };

      state.connections = Page.addOrReplace(state.connections, connection, isSame);
      state.following = Page.addOrReplace(state.following, connection, isSame);
      state.followers.data = state.followers.data.map(mapper);
    },
    userUnfollowed: (state: ConnectionsState, { payload: connectionId }: PayloadAction<string>) => {
      const mapper = (connection: Connection): Connection => {
        if (connection.id === connectionId) {
          return { ...connection, followed_by_you: false };
        } else {
          return connection;
        }
      };

      state.connections.data = state.connections.data.map(mapper);
      state.following.data = state.following.data.map(mapper);
      state.followers.data = state.followers.data.map(mapper);
    },
    startSearchUserByKeyword: (state: ConnectionsState): void => {
      state.searchingUsers = true;
    },
    setUserSearchResults: (
      state: ConnectionsState,
      { payload }: PayloadAction<Connection[]>,
    ): void => {
      state.userSearchResults = payload;
    },
    finalizeSearchUserByKeyword: (state: ConnectionsState): void => {
      state.searchingUsers = false;
    },
    clearSearchResults: (state: ConnectionsState): void => {
      state.userSearchResults = [];
    },
    reset: (state: ConnectionsState): void => {
      state.connections = Page.empty();
      state.following = Page.empty();
      state.followers = Page.empty();
    },
  },
});

export const followingSelector = (state: { connections: ConnectionsState }): Page<Connection> =>
  state.connections.following;
export const followersSelector = (state: { connections: ConnectionsState }): Page<Connection> =>
  state.connections.followers;
export const allConnectionsSelector = (state: {
  connections: ConnectionsState;
}): Page<Connection> => state.connections.connections;

export const socialCountSelector = (state: { connections: ConnectionsState }): ConnectionsCount =>
  state.connections.connectionsCount;
export const processingStateSelector = (state: {
  connections: ConnectionsState;
}): ProcessingState<any> => state.connections.processingState;
export const userSearchResultsSelector = (state: { connections: ConnectionsState }): Connection[] =>
  state.connections.userSearchResults;
export const searchingUsersSelector = (state: { connections: ConnectionsState }): boolean =>
  state.connections.searchingUsers;

export const {
  setProcessingState,
  setFollowing,
  setFollowers,
  setAllConnections,
  setSocialCount,
  startSearchUserByKeyword,
  setUserSearchResults,
  finalizeSearchUserByKeyword,
  clearSearchResults,
  reset,

  userFollowed,
  userUnfollowed,
} = connectionsSlice.actions;

export default connectionsSlice.reducer;
