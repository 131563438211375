import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Indicator } from 'redux/reducers/indicatorsReducer';

const columns: ReadonlyArray<ColumnDefinition<Indicator>> = [
  {
    name: 'group',
    type: ColumnType.custom,
    sortable: true,
    label: 'Indicator Group',
    // TODO: if they decide to remove the rule of "first column darker" we have to make
    //       this column a regular text column
    render: function IndicatorGroup(value: string): React.ReactElement {
      if (value.trim() === '') {
        return (
          <span className="text-gray opacity-30">
            &mdash; <em className="italic">No Group</em>
          </span>
        );
      }
      return <span className="text-gray">{value}</span>;
    },
    headerAlignment: Alignment.left,
    weight: 1.5,
    description: (
      <div className="w-60 py-1">
        Each indicator belongs to a high level group. This field groups the indicators into broad
        categories such as Index, Industrial, Agricultural, and so on.
      </div>
    ),
  },
  {
    name: 'name',
    type: ColumnType.text,
    sortable: true,
    label: 'Indicator Name',
    weight: 1,
    alignment: Alignment.center,
    description: <div className="w-40 py-1">The name of the asset or instrument in question.</div>,
  },
  {
    name: 'ticker',
    type: ColumnType.text,
    sortable: true,
    label: 'Ticker',
    alignment: Alignment.center,
  },
  {
    name: 'current_value',
    type: ColumnType.numeric,
    sortable: true,
    precision: 2,
    label: 'Current Value\n(Last Close)',
    alignment: Alignment.center,
    description: (
      <div className="w-40 py-1">The last reported end-of-the-day value for this indicator.</div>
    ),
  },
  {
    name: 'current_level',
    type: ColumnType.level,
    sortable: true,
    label: 'Current Indicator Level',
    description: (
      <div className="w-60 py-2">
        The indicator level is based on historical values on a 5-level scale from very low to very
        high. It is calculated as the number of standard deviations the current level is above or
        below the current 5-year moving average.
        <ul className="list-disc mt-4 pl-4 pr-2">
          <li className="mt-2">
            If the number of standard deviations is greater than two times above the 5-year moving
            average, the indicator level is very high.
          </li>
          <li className="mt-2">
            {
              "If it's between 1 and 2 times above the 5-year moving average, the indicator level is high."
            }
          </li>
          <li className="mt-2">
            {'If it’s between one and minus one, the indicator level is average.'}
          </li>
          <li className="mt-2">{'If it’s below minus one and higher than minus two it is low.'}</li>
          <li className="mt-2">{'If less than minus two it is very low.'}</li>
        </ul>
      </div>
    ),
  },
  {
    name: 'moving_avg_5_years',
    type: ColumnType.numeric,
    sortable: true,
    precision: 2,
    label: '5-yr Moving Average',
    alignment: Alignment.center,
    description: (
      <div className="w-60 py-1">
        This is the simple average for the last 1,260 measures. 1,260 represents the number of
        market trading days over the last 5 years. The value is calculated by summing the last 1,260
        close prices (end-of-the-day values) of a given indicator, then dividing by 1,260.
      </div>
    ),
  },
  {
    name: 'standard_deviation',
    type: ColumnType.numeric,
    sortable: true,
    precision: 2,
    label: 'Standard Deviation',
    alignment: Alignment.center,
    description: (
      <div className="w-60 py-1">
        The standard deviation is a measure of the amount of variation or dispersion of a set of
        values. It is calculated as the square root of the variance in the last 1,260 end-of-the-day
        values of the specific indicator.
      </div>
    ),
  },
  {
    name: 'current_standard_deviation_from_mean',
    type: ColumnType.numeric,
    sortable: true,
    precision: 2,
    label: 'Current Standard Deviation from Mean',
    alignment: Alignment.center,
    description: (
      <div className="w-60 py-1">
        This value represents the number of standard deviations the current value is away from the
        average. It is calculated dividing the difference between current value and the 5-year
        moving average by the standard deviation.
      </div>
    ),
  },
  {
    name: 'price_60d',
    type: ColumnType.numeric,
    sortable: true,
    precision: 2,
    label: '60-Day Close Price',
    alignment: Alignment.center,
    description: <div className="w-40 py-1">The closing price 60 trading days ago.</div>,
  },
  {
    name: 'move_60d',
    type: ColumnType.percent,
    sortable: true,
    precision: 2,
    label: '60-Day Price Change',
    alignment: Alignment.center,
    description: (
      <div className="w-60 py-1">
        The price change from 60 trading days ago and last close. It is calculated as the difference
        between last close price and 60 trading days ago close.
      </div>
    ),
  },
  {
    name: 'std_move_60d',
    type: ColumnType.numeric,
    sortable: true,
    precision: 2,
    label: '60-Day Standard Deviation',
    alignment: Alignment.center,
    description: (
      <div className="w-60 py-1">
        This value represents the number of standard deviations the move in the last 60 trading days
        is. It is calculated by dividing the difference between the current value and the 60-day
        Close Price by the standard deviation.
      </div>
    ),
  },
];

export default columns;
