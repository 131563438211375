import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import { useNavigate } from 'react-router-dom';

// TODO: Figure out how to use theme colors from tailwind config
const joyrideStyles = {
  options: {
    fontFamily: 'Poppins',
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#197ACF',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#197ACF',
    zIndex: 100,
  },
};

export type TourConfig = {
  nextPath: string;
  steps: Step[];
  isLastTour?: boolean;
  lastLabel?: string;
};

const useTour = (tourConfig: TourConfig): [StoreHelpers | null, ReactNode] => {
  const [run, setRun] = useState(false);
  const navigate = useNavigate();
  const [storeHelpers, setStoreHelpers] = useState<StoreHelpers | null>(null);

  useEffect(() => {
    const tourViewed = localStorage.getItem('guided-tour');
    if (!tourViewed) {
      setRun(true);
    }
  }, []);

  const joyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status } = data;
      const lastTourFinished = status === STATUS.FINISHED && tourConfig.isLastTour;

      if (status === STATUS.SKIPPED || lastTourFinished) {
        localStorage.setItem('guided-tour', 'viewed');
      }
      if (status === STATUS.FINISHED && tourConfig.nextPath) {
        navigate(tourConfig.nextPath);
      }
    },
    [navigate, tourConfig],
  );

  const locale = useMemo(() => {
    return {
      last: tourConfig.lastLabel,
    };
  }, [tourConfig]);

  const getHelpers = (storeHelpers: StoreHelpers) => {
    setStoreHelpers(storeHelpers);
  };

  const tour = useMemo<ReactNode>(() => {
    return (
      <Joyride
        callback={joyrideCallback}
        getHelpers={getHelpers}
        run={run}
        steps={tourConfig.steps}
        showSkipButton
        continuous
        styles={joyrideStyles}
        locale={locale}
        disableScrollParentFix
        disableScrolling
        hideCloseButton
        disableOverlayClose
        disableCloseOnEsc
      />
    );
  }, [joyrideCallback, tourConfig, run, locale]);

  return [storeHelpers, tour];
};

export default useTour;
