import NoCompaniesIcon from 'assets/icons/svg/no-companies-icon.svg';
import EmptySection from 'components/emptySection/EmptySection';
import { CommonFilter } from 'components/filters/helpers';
import { Body } from 'components/peerComparisonsView/components/body';
import { Header } from 'components/peerComparisonsView/components/header';
import { Summary } from 'components/peerComparisonsView/components/summary';
import { QuickLinks } from 'components/peerComparisonsView/quickLinks';
import SpinnerLoader from 'components/spinnerLoader';
import { isEmpty } from 'lodash';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import {
  PeerComparisons,
  PeerComparisonsColumn,
  PeerComparisonsColumnGroup,
  PeerComparisonsSummary,
} from 'types/peerComparisons';
import { formatValue } from 'utils';

interface Props {
  readonly comparisons: PeerComparisons | null;
  readonly loading: boolean;
  readonly filters: readonly CommonFilter[];
  readonly summary: PeerComparisonsSummary;
  readonly type: 'company' | 'industry';
  readonly largestValueInDataset: number;
}

export const PeerComparisonsView: React.FC<Props> = ({
  type,
  comparisons,
  loading,
  summary,
  largestValueInDataset,
}: Props): React.ReactElement => {
  const { notes = [] } = comparisons ?? {};

  const columnGroups = useMemo((): readonly PeerComparisonsColumnGroup[] => {
    return comparisons?.groups ?? [];
  }, [comparisons?.groups]);

  const rows = useMemo((): ReadonlyArray<Record<string, any>> => {
    return comparisons?.rows ?? [];
  }, [comparisons]);

  const columnCount = useMemo((): number => {
    return countColumns(columnGroups);
  }, [columnGroups]);

  const gridStyle = useMemo(
    (): CSSProperties => ({
      display: 'grid',
      gridTemplateColumns: `250px repeat(${columnCount}, 140px)`,
    }),
    [columnCount],
  );

  const valueFormatter = useCallback(
    (rawValue: number, column: PeerComparisonsColumn): string => {
      return formatValue(rawValue, column.unit, largestValueInDataset ?? 0);
    },
    [largestValueInDataset],
  );

  const extractHeader = useCallback(
    (_: number, column: PeerComparisonsColumn): React.ReactElement => (
      <h4 className="text-gray-darkest px-4 text-center text-xs font-poppinsSemiBold mx-auto">
        {column.label}
      </h4>
    ),
    [],
  );

  const targetCompany = useMemo((): Record<string, any> | null => {
    if (type === 'company') {
      return rows[0];
    }

    return null;
  }, [rows, type]);

  const otherCompanies = useMemo((): ReadonlyArray<Record<string, any>> => {
    if (type === 'company') {
      return rows.slice(1);
    }

    return rows;
  }, [rows, type]);

  return (
    <div className="relative flex flex-col flex-1">
      {loading && isEmpty(columnGroups) ? (
        <SpinnerLoader visible={true} />
      ) : isEmpty(columnGroups) ? (
        <EmptySection
          title="No peers available at the moment."
          subtitle="No data to be displayed in Peers Comparison table yet."
          icon={NoCompaniesIcon}
        />
      ) : (
        <div className="flex-1">
          <QuickLinks columnGroups={columnGroups} />

          <div className={gridContainerClassName}>
            <Header
              columnGroups={columnGroups}
              gridStyle={gridStyle}
              firstRow={targetCompany}
              valueFormatter={valueFormatter}
            />
            <Body
              rows={otherCompanies}
              columnGroups={columnGroups}
              gridStyle={gridStyle}
              valueFormatter={valueFormatter}
              extractHeader={extractHeader}
            />
            <Summary
              summary={summary}
              columnGroups={columnGroups}
              gridStyle={gridStyle}
              valueFormatter={valueFormatter}
              extractHeader={extractHeader}
            />
          </div>

          {loading /* FIXME: this should not really be needed */ && (
            <div className="z-1">
              <SpinnerLoader visible={loading} />
            </div>
          )}
        </div>
      )}
      {(notes?.length ?? 0) > 0 && (
        <div id="automated-notes" className="pt-3 pb-24">
          <h2 className="text-md uppercase text-center mb-4">Notes</h2>
          <ul className="list-disc mx-4 px-4 text-base text-gray">
            {notes.map(
              (note: string): React.ReactElement => (
                <li key={note} className="font-poppins py-2">
                  {note}
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

const countColumns = (group: readonly PeerComparisonsColumnGroup[]): number => {
  return group.reduce((totalColumns: number, group: PeerComparisonsColumnGroup): number => {
    const { columns } = group;
    return totalColumns + columns.length;
  }, 0);
};

const gridContainerClassName =
  'relative top-0 overflow-x-auto overflow-y-auto font-poppins text-sm text-gray bg-white mt-4 z-0';
