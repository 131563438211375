import Button from 'components/buttons/Button/Button';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'views/Landing/component/input';
import { SignUpFormState } from 'views/Landing/SignUp/types';

const SignUpBasic: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const formData = [
    { name: 'First Name', placeholder: 'First Name', setter: setFirstName, value: firstName },
    { name: 'Last Name', placeholder: 'Last Name', setter: setLastName, value: lastName },
    { name: 'Email', placeholder: 'Email Address', setter: setEmail, value: email },
  ];

  const signUpInputBoxes = formData.map((o, i) => (
    <div key={i} className="my-8 text-white">
      <Input label={o.placeholder} value={o.value} onChange={o.setter} />
    </div>
  ));

  const data = useMemo(
    (): Partial<SignUpFormState> => ({
      firstName,
      lastName,
      email,
    }),
    [email, firstName, lastName],
  );

  const signUpButton = (
    <Link to="/sign-up" state={data}>
      <Button rounded="rounded-sm" label="SIGN UP FOR FREE" width="w-full" />
    </Link>
  );

  return (
    <>
      <h1 className="text-left text-white font-poppinsSemiBold" style={{ fontSize: '18px' }}>
        SIGN-UP
      </h1>
      {signUpInputBoxes}
      {signUpButton}
    </>
  );
};

export default SignUpBasic;
