import { FilterValues } from 'hooks/useFilters';
import { isEqual } from 'lodash';
import { AnyAction } from 'redux';

export enum ActionTypes {
  loadOptions = '/filters/category-group/load-options',
  loadOptionsCompleted = '/filters/category-group/load-options-completed',
  loadOptionsFailed = '/filters/category-group/load-options-failed',
  setSearchKeyword = '/filters/category-group/set-search-keyword',
  showDropdown = '/filters/category-group/show-dropdown',
  hideDropdown = '/filters/category-group/hide-dropdown',
  updateFilterValues = '/filters/category-group/update-filter-values',
  reset = '/filters/category-group/reset',
}

export interface FiltersCategoryGroupState {
  readonly searchKeyword: string;
  readonly resolvedOptions: string[] | null;
  readonly loading: boolean;
  readonly lastError: string;
  readonly optionsFilter: readonly FilterValues[];
  readonly isDropdownOpen: boolean;
}

export const initialState: FiltersCategoryGroupState = {
  optionsFilter: [],
  searchKeyword: '',
  resolvedOptions: null,
  loading: false,
  lastError: '',
  isDropdownOpen: false,
};

const reducer = (
  state: FiltersCategoryGroupState,
  action: AnyAction,
): FiltersCategoryGroupState => {
  switch (action.type) {
    case ActionTypes.setSearchKeyword:
      return { ...state, resolvedOptions: null, searchKeyword: action.payload };
    case ActionTypes.loadOptions:
      return { ...state, loading: true };
    case ActionTypes.loadOptionsCompleted:
      return { ...state, loading: false, resolvedOptions: action.payload };
    case ActionTypes.loadOptionsFailed:
      return { ...state, loading: false, resolvedOptions: [], lastError: action.payload };
    case ActionTypes.showDropdown:
      return { ...state, isDropdownOpen: true };
    case ActionTypes.hideDropdown:
      return { ...state, isDropdownOpen: false };
    case ActionTypes.updateFilterValues:
      if (isEqual(state.optionsFilter, action.payload)) {
        return state;
      } else {
        return { ...state, optionsFilter: action.payload };
      }
    case ActionTypes.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
