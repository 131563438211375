import React from 'react';
import {
  isPortfolioCompanyInvestmentBondsOrDebt,
  isPortfolioCompanyInvestmentOptions,
  isPortfolioCompanyInvestmentOther,
  isPortfolioCompanyInvestmentSharesOrEquityItem,
  PortfolioCompanyInvestmentItem,
} from 'types/portfolioItem';
import { InvestmentBondsOrDebtItemForm } from 'views/Portfolios/PortfoliosModal/investmentBondsOrDebtItemForm';
import { InvestmentOptionsItemForm } from 'views/Portfolios/PortfoliosModal/investmentOptionsItemForm';
import { InvestmentOtherItemForm } from 'views/Portfolios/PortfoliosModal/investmentOtherItemForm';
import { InvestmentSharesOrEquityItemForm } from 'views/Portfolios/PortfoliosModal/investmentSharesOrEquityItemForm';

interface Props {
  readonly item: PortfolioCompanyInvestmentItem;
}

export const InvestmentItemFormSelector: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  if (isPortfolioCompanyInvestmentSharesOrEquityItem(item)) {
    return <InvestmentSharesOrEquityItemForm item={item} />;
  } else if (isPortfolioCompanyInvestmentBondsOrDebt(item)) {
    return <InvestmentBondsOrDebtItemForm item={item} />;
  } else if (isPortfolioCompanyInvestmentOptions(item)) {
    return <InvestmentOptionsItemForm item={item} />;
  } else if (isPortfolioCompanyInvestmentOther(item)) {
    return <InvestmentOtherItemForm item={item} />;
  } else {
    throw new Error(`unknown investment item type: ${item.investmentType}`);
  }
};
