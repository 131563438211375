import React from 'react';
import { getFormatDate } from 'utils';

type Data = {
  id: string | number;
  photo: string;
  username: string;
  name: string;
  date: Date;
  text: string;
};
interface Props {
  index?: number;
  data: Data;
  dividerColor?: string;
  margin?: string;
}

export const Comment: React.FC<Props> = ({
  data,
  dividerColor = 'border-gray-light',
  margin = 'mb-5',
  index = NaN,
}) => {
  return (
    <div
      id={!isNaN(index) ? `user-comment-${index}` : undefined}
      className={`${margin} pb-3 px-4 border-b ${dividerColor}`}
    >
      <div className="flex justify-center">
        <div className="w-16">
          <img alt="Profile pic" src={data.photo} className="object-contain w-full h-full" />
        </div>
        <div className="w-full ml-4">
          <div className="flex items-center">
            <h2>{data.username}</h2>
            <p className="text-gray-darkest">{data.name}</p>
          </div>
          <h6 className="text-gray">{getFormatDate(data.date)}</h6>
        </div>
      </div>
      <p className="text-gray mt-3 overflow-hidden overflow-ellipsis whitespace-nowrap w-full h-6">
        {data.text ?? 'Deleted Comment'}
      </p>
    </div>
  );
};
