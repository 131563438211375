import React from 'react';
import { Link } from 'react-router-dom';
import { UpcomingEventsEntry } from 'redux/reducers/upcomingEventsReducer';
import UpcomingEventItem from 'views/SmartTools/TimelineAndEvents/upcomingEventItem';

interface Props {
  entries: UpcomingEventsEntry[];
}

export const UpcomingEventsView: React.FC<Props> = ({ entries }: Props): React.ReactElement => {
  return (
    <div
      style={{ minHeight: entries.length > 0 ? 200 : 150 }}
      className="bg-white rounded pt-4 flex flex-col"
    >
      <h4 className="text-gray-darkest mb-4 mx-4 text-base">UPCOMING EVENTS</h4>
      <div className="h-px bg-gray-light" />
      {entries.length === 0 && <div className="flex-grow" />}
      {entries.map(({ day, events }: UpcomingEventsEntry) => (
        <UpcomingEventItem key={day} day={day} events={events} />
      ))}
      {entries.length === 0 && (
        <p className="text-gray text-sm font-poppins mt-4 text-center">No upcoming events</p>
      )}
      <div className="flex justify-end p-4">
        <Link to="/timeline">
          <h4 className="text-blue">ALL EVENTS</h4>
        </Link>
      </div>
    </div>
  );
};
