import { preRegisterMode } from 'utils';

export default {
  title: 'Discover The Future of Financial Analysis',
  list: [
    'Explore and interpret critical data about assets and exposures',
    'Save time with analysis',
    'Make smarter financial decisions and become more connected with Capnote.',
  ],
  signUpBtn: preRegisterMode ? 'SIGN UP FOR EARLY ACCESS' : 'sign up for free',
  paragraph: `Explore and interpret critical data about assets and exposures, save time 
    with analysis, make smarter financial decisions and become more connected with Capnote.`,
};
