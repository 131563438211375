import React from 'react';

const content: Content = {
  title: 'Terms & Conditions',
  header: (
    <>
      <p>
        By using capnote.com (&ldquo;Capnote&rdquo;, &ldquo;Finvar&rdquo;, &ldquo;we,&rdquo;
        &ldquo;us,&rdquo; together with all other websites, mobile applications and services
        operated by or on behalf of Finvar Corporation, the &ldquo;Site&rdquo;) you agree to the
        following terms. These terms are legally binding contract between you and Finvar.
      </p>
    </>
  ),
  tabs: [
    {
      label: 'I. Agreement with Finvar',
      id: 'agreementWithFinvar',
      description: (
        <p>
          By using the Site, you agree to be bound by our Terms and Conditions (the
          &ldquo;TAC&rdquo;). If you do not agree to the TAC and Finvar privacy policy, please do
          not access or use the Site or any information contained on the Site. You affirm that you
          are over the age of 18, as the Site is not intended for children under 18. If it comes to
          Finvar&apos;s attention through reliable source that a registered user is a child under 18
          years of age, Finvar will cancel that user&apos;s account.
        </p>
      ),
    },
    {
      label: 'II. Changes to the Terms and Conditions',
      id: 'changesToTermsAndConditions',
      description: (
        <>
          <p>
            Finvar reserves the right at any time to: (1) Change the terms and conditions of the
            TAC; (2) Change the Site, including eliminating or discontinuing any content on the Site
            (Content) or feature of the Site; or (3) Impose fees, charges or other conditions for
            use of the Site or parts thereof. Finvar may modify the Site at any time without prior
            notice, and you accept those modifications if you continue to use the Site. You should
            check the Site frequently to see recent changes.
          </p>
        </>
      ),
    },
    {
      label: 'III. Important Securities Disclaimer',
      id: 'importantSecurityDisclaimer',
      description: (
        <>
          <p>
            You understand that no content published on the Site constitutes a recommendation that
            any particular security, portfolio of securities, transaction or investment strategy is
            suitable for any specific person. You further understand that none of the bloggers,
            information or content providers, app providers, or their affiliates are advising you
            personally concerning the nature, potential, value or suitability of any particular
            security or asset, portfolio of securities, assets, transaction, investment strategy or
            other matters. To the extent that any of the content published on the Site may be deemed
            to be investment advice or recommendations in connection with a particular security or
            asset, such information is impersonal and not tailored to the investment needs of any
            specific person.
          </p>
          <p>
            You understand that an investment in any security or asset is subject to a number of
            risks, and that discussions of any security or asset published on the Site do not or may
            not contain a complete list or description of relevant risk factors.
          </p>
          <p>
            In addition, please note that some of the stocks about which content is published on the
            Site have a low market capitalization and/or insufficient public float. Such stocks are
            subject to more risk than stocks of larger companies, including greater volatility,
            lower liquidity and less publicly available information. Blogs, postings or content on
            the Site which may or may not be deemed by you to be recommendations may have an effect
            on their stock prices. You understand that the Site may contain opinions from time to
            time with regard to securities and assets mentioned in other Finvar blog, post, content
            or product, and that opinion in one post, blog, content or product may be different from
            those in another blog, post, content or product. We require all employees to disclose
            every stock in which they, their immediate family, or any entity under their control,
            have a personal interest, if such stock is mentioned in a blog, post, or content which
            they write.However, you understand and agree that non-employees, including outside
            bloggers or other content contributors or their affiliates, may write about securities
            and assets in which they or their firms have a position, that they may trade for their
            own account, and that they may or may not be subject to a disclosure policy. In cases
            where Finvar becomes aware that one of its employees has violated his or her disclosure
            obligation, Finvar will take appropriate action.
          </p>
          <p>
            You understand that performance data is supplied by sources believed to be reliable,
            that the calculations on our Site are made using such data, and that such calculations
            are not guaranteed by these sources, the information providers, or any other person or
            entity, and may not be complete.
          </p>
          <p>
            From time to time, reference may be made on our Site to prior articles and opinions that
            has been published on our Site. These references may be selective, may reference only a
            portion of an article or opinion, and are likely not to be current. As markets change
            continuously, previously published information and data may not be current and should
            not be relied upon.
          </p>
          <p>
            All content on the Site is presented only as of the date published or indicated, and may
            be superseded by subsequent market events or for other reasons. In addition, you are
            responsible for setting the cache settings on your browser to ensure you are receiving
            the most recent data.
          </p>
        </>
      ),
    },
    {
      label: 'IV. No Investment Recommendations or Professional Advice',
      id: 'noInvestmentRecommendationsOrProfessionalAdvice',
      description: (
        <p>
          The Site is not intended to provide tax, legal, insurance, investment or financial advice,
          and nothing on the Site should be construed as an offer to sell, a solicitation of an
          offer to buy, or a recommendation for any security or investment by Finvar or any third
          party. You alone are solely responsible for determining whether any investment, security
          or strategy, or any other product or service, is appropriate or suitable for you based on
          your investment objectives and personal and financial situation. You should consult an
          attorney or tax professional regarding your specific legal or tax situation. Your use of
          the information on the Site or materials linked from other sources is at your own risk.
        </p>
      ),
    },
    {
      label: 'V. Intellectual Property Rights',
      id: 'intellectualPropertyRights',
      description: (
        <>
          <p>
            The Site and the Content contained herein, as well as all proprietary rights including
            without limitation, to copyrights, the text, documents, articles, products, software,
            graphics, photos, sounds, videos, interactive features, services, links, user
            submissions, third-party apps, and any other content on the Site and the trademarks,
            service marks and logos contained therein are the property of Finvar and its third-party
            licensors or providers. Each Site and any and all Content contained herein is protected
            by copyright in addition to other intellectual property laws. Any of the trademarks,
            service marks or logos (collectively, the “Marks”) displayed on a Site may be registered
            or unregistered marks of Finvar or others. Nothing contained on a Site shall be
            construed as granting any license or right to use any of the Marks displayed on the Site
            absent the express written permission of Finvar or a third party owner of such Marks.
            You agree to honor all reasonable requests by Finvar or its suppliers to protect and
            preserve their respective proprietary interests in each Site and any and all Content
            contained therein.
          </p>
          <p>
            Copying of, use of, access to or distribution of a Site or any Content contained therein
            in breach of these TAC may cause Finvar and/or its suppliers irreparable injury that
            cannot be adequately compensated for by means of monetary damages. Any breach of TAC by
            you may be enforced by Finvar or its suppliers by means of equitable relief (including,
            but not limited to, injunctive relief) in addition to any other available rights and
            remedies. Any actual or attempted illegal or unauthorized uses of a Site, the Marks or
            any Content are strictly prohibited and may result in criminal and/or civil prosecution.
          </p>
          <p>
            You may access and use the Content, and download and/or print out copies of any content
            from the Site, solely for your personal, non-commercial use. If you download or print a
            copy of the Content for personal use, you must retain all copyright and other
            proprietary notices contained therein. You acknowledge that you do not acquire any
            ownership rights by using the Site. Finvar reserves all rights not expressly granted in
            and to the Site. You may also, on an occasional, ad hoc basis, share, reproduce,
            distribute, display or transmit an insubstantial portion of other content, for a
            non-commercial purpose and without charge, to a limited number of individuals, provided
            you include all copyright and other proprietary rights notices and the Finvar disclaimer
            with such portion of the content in the same form in which the notices and disclaimer
            appear on the Site, the original source attribution, and the phrase &ldquo;Used with
            permission from Finvar.&rdquo;
          </p>
          <p>
            The Site contains links to other Internet websites or links to Content created by third
            parties which is published on the Site. We neither control nor endorse such other
            websites or Content, nor have we reviewed or approved any Content that appears on such
            other websites or on our Site. Please read the terms of use and privacy policy of any
            such third party sites that you interact with before you engage in any activity. You are
            solely responsible and liable for your use of and linking to all third party sites. You
            acknowledge and agree that we shall not be held responsible for the legality, accuracy,
            or appropriateness of any Content, advertising, products, services, or information
            located on our Site or any other websites, nor for any loss or damages caused or alleged
            to have been caused by the use of or reliance on any such content. Similarly, while we
            endeavor to facilitate the provision of quality Site, we are not responsible for any
            loss or damages caused or alleged to have been caused by their use. You may link to any
            content on the Site. If you are interested in reprinting, republishing or distributing
            content from Finvar, please contact Finvar to obtain written consent. Finvar™ is a
            trademark and/or service mark of Finvar Corporation. All other trademarks, service
            marks, and logos used on our websites are the trademarks, service marks, or logos of
            their respective owners.
          </p>
          <p>This section shall survive any termination of these TAC.</p>
        </>
      ),
    },
    {
      label: 'VI. User Conduct',
      id: 'userConduct',
      description: (
        <>
          <p>
            You may not use, copy, display, sell, license, de-compile, republish, reproduce, upload,
            post, transmit, distribute, create derivative works or otherwise exploit Content from
            the Site to online bulletin boards, message boards, newsgroups, chat rooms, or in any
            other manner, without our prior written permission. Modification of the Content or use
            of the Content for any purpose other than your own personal, noncommercial use is a
            violation of our copyright and other proprietary rights, and can subject you to legal
            liability.
          </p>
          <p>
            In addition, in connection with your use of the Site and its services, you agree not to:
          </p>
          <p>
            (1) Use the Site for any unlawful purpose; (2) Express or imply that any statements you
            make are endorsed by us, without our prior written consent; (3) Modify, adapt,
            sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of
            the Site; (4) Disable, damage or alter the functioning or appearance of the Site,
            including the presentation of advertising; (5) &ldquo;Frame&rdquo; or
            &ldquo;mirror&rdquo; any part of the Site without our prior written authorization; (6)
            Use any robot, spider, site search/retrieval application, or other manual or automatic
            device or process to download, retrieve, index, &ldquo;data mine&rdquo;,
            &ldquo;scrape&rdquo;, &ldquo;harvest&rdquo; or in any way reproduce or circumvent the
            navigational structure or presentation of the Site or its contents; (7) Harvest or
            collect information about visitors to the Site without their express consent; (8) Send
            unsolicited or unauthorized advertisements, spam, chain letters, etc to other users of
            the Site; (9) Transmit any Content which contains software viruses, or other harmful
            computer code, files or programs. (10) Restrict or inhibit any other visitor from using
            the Site, including, without limitation, by means of &ldquo;hacking&rdquo; or defacing
            any portion of the Site; (11) Use the Content or any portion of the Site as part of any
            intranet or other internal network without our prior written consent. (12) Use or
            attempt to use any &ldquo;deep-link,&rdquo; &ldquo;scraper,&rdquo; &ldquo;robot,&rdquo;
            &ldquo;bot,&rdquo; &ldquo;spider,&rdquo; &ldquo;data-mining,&rdquo; &ldquo;computer
            code&rdquo; or any other automated device, program, tool, algorithm, process, or
            methodology or manual process having similar processes or functionality, to access,
            acquire, copy, or monitor any portion of a Site, any Content found on or accessed
            through a Site, or any User Content without the prior express written consent of Finvar.
            (13) Obtain or attempt to obtain through any means any Content on a Site that has not
            been intentionally made publicly available, either by their public display on a Site or
            through their accessibility by a visible link on a Site. (13) misuse a Site by knowingly
            introducing viruses, trojans, worms, logic bombs or other material that is malicious or
            technologically harmful.
          </p>
          <p>
            You may not use the Site or any Content contained therein, in whole or in part in any
            manner that either competes or is intended to compete with Finvar or its affiliates,
            including without limitation, any distribution of any Content or a derivative work based
            thereon.
          </p>
          <p>
            You also agree to comply with all applicable laws, rules and regulations in connection
            with your use of the Site and the content made available therein. We aim to prevent,
            detect and not knowingly facilitate money laundering and terrorism financing activities.
            You may not use the Site in a manner which violates our anti-money laundering, counter
            terrorist financing or similar regulatory obligations.
          </p>
          <p>
            We reserve the right to close, suspend, or limit access to your Subscription, account
            and/or the Site in the event your use violates such obligations or if, after reasonable
            inquiry, we are unable to obtain information about you which is required to verify your
            identity. We may require you to provide additional information or documents to verify
            your identity, including your date of birth, physical address, your physical address,
            government issued identification document or other information that will allow us to
            reasonably identify you.
          </p>
          <p>
            In order to access some of the services of the Site, you will have to create an account.
            By creating this account you agree to the following: (1) You may only maintain a single
            account; (2) You may never share your account username or password or knowingly provide
            or authorize access to your account (including without limitation a
            &ldquo;Subscription&rdquo;, as defined below in “Subscription” Section); (3) You may
            never use another user&apos;s account without permission; (4) When creating your
            account, you must provide accurate and complete information; (5) You are solely
            responsible for the activity that occurs on your account, and you must keep your account
            password secure; (6) You must notify us immediately of any breach of security or
            unauthorized use of your account.
          </p>
          <p>
            You will be liable for any use made of your account or password and the losses of Finvar
            or others due to such unauthorized use. We will not be liable for your losses caused by
            any unauthorized use of your account. Finvar has the right to terminate your access to
            the Site, in its sole discretion.
          </p>
        </>
      ),
    },
    {
      label: 'VII. On Posting and Monitoring Content',
      id: 'onPostingAndMonitoringContent',
      description: (
        <>
          <p>
            The Site permits the submissions and posts of Content by users of the Site, including
            without limitation comments, articles, links, streams, private messages sent to other
            registered users through the Site&apos;s messaging system, and items in our feed
            sections, including from those who give permission to Finvar to post their Content and
            the hosting, sharing and publishing of such submissions on the Site. Finvar has the
            right in its sole discretion and without further notice to you, to monitor, censor,
            edit, move, delete, and/or remove any and all Content posted on its Site or any Content
            transmitted by direct messaging or by any other method to or from your Finvar user
            account at any time and for any reason. Without limiting the foregoing, Finvar has the
            right to delete any comment or Content that it believes, in its sole discretion, does or
            may violate the TAC of the Site by you.
          </p>
          <p>
            On user-generated streams and any content including comments and messages, Finvar
            reserves the right to remove content that violates one of the following principles, as
            determined by Finvar, at our discretion: (1) No incitement to hatred. Material that
            promotes hatred toward groups based on race or ethnic origin, religion, disability,
            gender, age, veteran status, or sexual orientation/gender identity will be removed. (2)
            No pornography or pedophilia; (3) No direct or veiled threats against any person or
            group of people. (4) No copyright infringement. (5) No plagiarism. This includes posting
            content verbatim from other sources without proper attribution and/or repurposing
            content from other sources and presenting it without reference to the content&apos;s
            creator. (6) No publishing of other people&apos;s personal information, such as credit
            card numbers, Social Security Numbers, and driver&apos;s and other license numbers. (7)
            No impersonation of others in a manner that is intended to or does mislead or confuse
            others. (8) No use for unlawful purposes or for promotion of dangerous and illegal
            activities. Your account may be terminated and you may be reported to the appropriate
            authorities. (9) No spamming, link-spamming or transmitting malware and viruses. (10) No
            personal attacks. (11) No profanity or vulgarity. (12) No business solicitations or
            advertising. (13) No inappropriate, unethical or misleading behavior.
          </p>
          <p>
            <b>Important note:</b> Finvar encourages civil debate and idea-sharing among financial
            professionals, investors, students and markets followers. In order to maintain a level
            of discourse appropriate for our user base, we are strongly opposed to trolling,
            uncivilized discussion, mudslinging, inappropriate language, and blanket dismissal of
            other&apos;s ideas. At our discretion, we may delete comments, messages, post and/or
            blogs, and block/delete accounts of users we believe lower the level of discourse and
            courtesy we strive to achieve.
          </p>
          <p>
            Moderating decisions are subjective, and we strive to make them carefully and
            consistently. Due to the volume of content, we cannot review moderation decisions with
            users and cannot reverse decisions.
          </p>
          <p>
            Without derogating from the above, Finvar editors, at their discretion, may refrain from
            posting or remove User Submissions that violate these standards or which are otherwise
            inappropriate. These standards are designed to ensure that the dialogue on the Site is
            credible, responsible, intelligent and informative. We cannot guarantee that users will
            tell the truth, and we will not monitor the veracity of names and positions or the
            content of any posts. However, by setting out the above guidelines, we hope to raise the
            credibility of the discussion and foster a spirit of open, honest exchanges of
            information.
          </p>
          <p>
            If an author has a business relationship with a company named in an article that he or
            she has authored, that relationship must be fully and accurately disclosed.
          </p>
          <p>
            If you have any comments on our policies, or complaints or concerns of any kind about
            any posts, please contact us at support@finvar.com. We will review all of the
            information that you communicate to us, but we may not be able to take action or respond
            directly to each email.
          </p>
        </>
      ),
    },
    {
      label: 'VIII. User Submissions; Online Rules of Conduct',
      id: 'userSubmissionsOnlineRulesOfConduct',
      description: (
        <>
          <p>
            When you post any user submission on the Site or give Finvar permission to post your
            Content, you agree to: (1) Post contents in both tone and content that contribute in a
            positive and high quality manner to the substantive exchange of information and the
            subject matter of the Site. (2) Automatically grant Finvar a royalty-free, perpetual,
            worldwide, irrevocable, non-exclusive and fully transferable and sublicensable right and
            license to use, reproduce, modify, adapt, publish, translate, create derivative works
            from, distribute, perform and display any submission (in whole or in part) and/or to
            incorporate any of your submission in other works now or in the future and in any media
            formats and through any media channels, and you confirm and warrant to Finvar that you
            own the copyright in each of your submissions and have all the rights, power and
            authority necessary to grant the above license and rights.
          </p>
          <p>
            When you post any submission on the Site, you also agree to abide by the following
            disclosure rules: (1) To disclose the existence at the time of writing of a long or
            short position (including stocks, options or other instruments) in any stock mentioned
            in any User Submission (except for &ldquo;Comments&rdquo;); (2) You may not write about
            a stock with the intention to boost or reduce the stock&apos;s price and sell (or buy)
            the stock into the resulting strength or weakness; (3) If you intend at the time or
            writing to sell or buy a stock within three days of publication of a submission that
            discusses that stock, you must disclose this intention; (4) Abide by the following
            conflict of interest rule: You will disclose any material relationships with companies
            whose stocks you write about in a submission or parties that stand to gain in any way
            from the viewpoint you are outlining. Examples: You must disclose if you are employed by
            a company whose stock you are writing about; perform consulting for a company you write
            about; receive paid advertising revenue or any other form of sponsorship fee from a
            company you write about. (5) If you choose an alias, be responsible for all statements
            made and acts or omissions that occur by use of your alias. (6) Waive any and all rights
            against Finvar and hold Finvar harmless in connection with any claims relating to any
            action taken by Finvar as part of its investigation of a suspected violation or result
            of its conclusion that a violation of these TAC has occurred, including but not limited
            to the removal of your submission from the Site or a suspension or termination of your
            access to the Site. (7) Maintain and promptly update your registration data to keep it
            true, accurate, current and complete.
          </p>
          <p>
            You agree NOT to: (1) Choose an alias that is threatening, abusive, offensive,
            harassing, derisive, defamatory, vulgar, obscene, libelous, hatefully, racially,
            ethnically or otherwise or objectionable. (2) Post or transmit any Content that you
            either know or should know is false, deceptive or misleading, or misrepresent or deceive
            others as to the source, accuracy, integrity or completeness of any comment you post.
            (3) Post or transmit any Content that is unlawful, harmful or injurious to others,
            contains software viruses, or other harmful computer code, files or programs,
            threatening, abusive, offensive, harassing, derisive, defamatory, vulgar, obscene,
            libelous, hatefully, racially, ethnically or otherwise tortious or objectionable. (4)
            Post or transmit any Content that does or may invade the privacy or violate or infringe
            on any rights of others, including, without limitation, copyrights and other
            intellectual property rights. (5) By use of your alias or in any comment, impersonate
            any person or entity, falsely or deceptively state, infer or otherwise misrepresent your
            affiliation with or connection to any person or entity. (6) Post or transmit any Content
            which, either the act of posting or the comment itself, you do not have a right to do
            under any law, regulation or order of any court, or as a result of an employment,
            contractual, fiduciary or other legal obligation or relationship. (7) Post or transmit
            any advertising, promotional materials or schemes or invitations to participate in any
            other form of solicitation or promotion. (8) Post or transmit any non-public or
            otherwise restricted, confidential or proprietary information without authorization. (9)
            Violate any local, state, national or international law, regulation or order of any
            court, including but not limited to regulations of the U.S. Securities and Exchange
            Commission or any rules of any securities exchange, including without limitation, the
            New York Stock Exchange, the American Stock Exchange or The Nasdaq Stock Market.
          </p>
          <p>
            While we believe that the Site can and should be a positive environment for the exchange
            of information, you understand that the Site is open for posting to all users. Some
            individuals may post comments that may be offensive, indecent, objectionable, false,
            misleading or simply inappropriate.
          </p>
        </>
      ),
    },
    {
      label: 'IX. Responsibility of User Submission',
      id: 'responsibilityOfUserSubmission',
      description: (
        <p>
          Each individual who submits a User Submission, whether published on the Site or not, is
          solely responsible for her or his own acts, including the content, context or information
          in the submission he or she submits. This means that each individual, and not Finvar, is
          entirely responsible for anything and everything she or he posts on the Site. Finvar does
          not, and does not intend to, pre-screen any comments posted on its Site, and Finvar cannot
          and does not guarantee the accuracy, integrity or quality of anything that may appear on
          its Site.
        </p>
      ),
    },
    {
      label: 'X. Disclosure',
      id: 'disclosure',
      description: (
        <>
          We reserve the right to access, read, preserve, and disclose any user submissions (whether
          published or not) or any other information we believe is reasonably necessary to (a)
          comply with any applicable law, regulation, legal process, subpoena or governmental or
          regulatory request, (b) enforce these TAC, including investigation of potential violations
          of it, (c) detect, prevent, or otherwise address fraud, security or technical issues, (d)
          respond to user support requests, or (e) protect the rights, property or safety of Finvar,
          its users, yourself or the public.
        </>
      ),
    },
    {
      label: 'XI. Disclaimer of Warranties',
      id: 'disclaimerOfWarranties',
      description: (
        <>
          <p>
            THE SITE, AND ANY PRODUCT OR SERVICE OBTAINED OR ACCESSED THROUGH THE SITE, IS PROVIDED
            &ldquo;AS IS&rdquo; AND WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, FINVAR, ITS
            OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, SUPPLIERS, ADVERTISERS, AGENTS, INFORMATION
            PROVIDERS AND ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR COMPILING OF
            THE SITE DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT
            LIMITED TO, WARRANTIES OF TITLE AND NON-INFRINGEMENT, IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ALL WARRANTIES RELATING TO THE
            ORIGINALITY, ADEQUACY, ACCURACY, TIMELINESS, MERCHANTABILITY OR COMPLETENESS OF ANY
            INFORMATION ON OUR SITE.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of implied warranties, so the above
            exclusions may not apply to you. FINVAR AND ITS AFFILIATES, SUPPLIERS, AGENTS, SPONSORS,
            INFORMATION PROVIDERS AND ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR
            COMPILING OF THE SITE DO NOT WARRANT THAT YOUR USE OF THE SITE WILL BE UNINTERRUPTED,
            ERROR-FREE OR SECURE, OR THAT THE SITE OR THE SERVER(S) ON WHICH THE SITE IS HOSTED ARE
            FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME TOTAL RESPONSIBILITY AND RISK
            FOR YOUR USE OF THE SITE AND YOUR RELIANCE THEREON. NO OPINION, ADVICE, OR STATEMENT OF
            FINVAR OR ITS AFFILIATES, SUPPLIERS, AGENTS, MEMBERS, SPONSORS, INFORMATION PROVIDERS OR
            VISITORS, WHETHER MADE ON THE SITE OR OTHERWISE, SHALL CREATE ANY WARRANTY.
          </p>
        </>
      ),
    },
    {
      label: 'XII. Limitation of Liability',
      id: 'limitationOfLiability',
      description: (
        <>
          <p>
            NEITHER FINVAR NOR ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
            SUPPLIERS, ADVERTISERS, AGENTS, SPONSORS, INFORMATION PROVIDERS NOR ANY OTHER THIRD
            PARTY INVOLVED IN OR RELATED TO THE MAKING OR COMPILING OF THE SITE ARE LIABLE FOR LOST
            PROFITS, LOST SAVINGS OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,
            PUNITIVE OR OTHER DAMAGES UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER
            THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE AND/OR ANY CONTENT CONTAINED
            THEREIN, OR ANY PRODUCT OR SERVICE USED OR PURCHASED THROUGH FINVAR. SUCH LIMITATION
            SHALL APPLY REGARDLESS OF THE FORM OF ACTION, EVEN IF FINVAR, ITS INFORMATION PROVIDERS,
            OR ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR COMPILING OF ANY PART
            OF THE SITE HAS BEEN ADVISED OF OR OTHERWISE MIGHT HAVE ANTICIPATED THE POSSIBILITY OF
            SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO STOP USING IT.
            THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO FINVAR FOR ALL DAMAGES, LOSSES, AND CAUSES
            OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR
            OTHERWISE) SHALL NOT EXCEED THE TOTAL AMOUNT PAID TO US BY YOU, IF ANY, FOR ACCESS TO
            THE SITE OR ANY SERVICES, DURING THE PREVIOUS SIX (6) MONTHS PRIOR TO BRINGING THE
            CLAIM. IN NO EVENT SHALL FINVAR, ITS INFORMATION PROVIDERS OR ANY THIRD PARTY, BE LIABLE
            TO YOU, OR ANY OTHER PERSON, FOR ANY DIRECT OR INDIRECT DAMAGES, INCLUDING, WITHOUT
            LIMITATION, ANY LOST PROFITS, LOST SAVINGS OR OTHER INCIDENTAL OR CONSEQUENTIAL DAMAGES
            ARISING OUT OF THE USE.
          </p>
          <p>
            Some jurisdictions do not allow the limitation or exclusion of liability for incidental
            or consequential damages, so the above limitations may not apply to you.
          </p>
        </>
      ),
    },
    {
      label: 'XIII. Indemnification',
      id: 'indemnification',
      description: (
        <p>
          As a condition of your use of the Site, you agree to indemnify, defend and hold us, our
          officers, directors, employees, agents, representatives, information providers, and any
          other third party involved in or related to the making or compiling of the Site harmless
          from and against any and all claims, damages, losses, costs (including reasonable
          attorneys&apos; fees), or other expenses that arise directly or indirectly out of or from
          (a) your violation of the TAC; (b) your use or any third party’s use of, or inability to
          use, the Site; (c) your violation of the rights of any third party, or (d) any claim that
          one of your User Submissions caused damage to a third party. This defense and
          indemnification obligation will survive these TAC and your use of the Site.
        </p>
      ),
    },
    {
      label: 'XIV. Termination',
      id: 'termination',
      description: (
        <p>
          You understand and agree that Finvar may, under certain circumstances and without prior
          notice to you, terminate your access to and use of the Site. Cause for such termination
          shall include, but not be limited to, (i) breaches or violations of the TAC or other
          agreements or guidelines, (ii) requests by law enforcement or other government or
          regulatory authorities or (iii) repeat violators of third party copyrights or other
          intellectual property.
        </p>
      ),
    },
    {
      label: 'XVII. Copyright Policy',
      id: 'copyrightPolicy',
      description: (
        <p>
          Finvar respects the intellectual property of others, and we ask our users to do the same.
          Finvar may, in appropriate circumstances and at its discretion, terminate the account or
          access of users who infringe the intellectual property rights of others. If you believe
          that your work has been copied in a way that constitutes copyright infringement, please
          contact support@finvar.com
        </p>
      ),
    },
    {
      label: 'XVIII. Additional Terms that Apply to Quandl Services',
      id: 'additionalTermsThatApplyToQuandlService',
      description: (
        <>
          <p>
            Neither Quandl or any of Quandl’s subsidiaries (collectively, “Quandl”) or its
            affiliates nor any of their suppliers shall have any liability for the accuracy or
            completeness of the data and information furnished through the Site, or for delays,
            interruptions or omissions therein nor for any lost profits, indirect, special or
            consequential damages.
          </p>
          <p>
            Quandl services are not investment advice and a reference to a particular investment or
            security, a credit rating or any observation concerning a security or investment
            provided in the Quandl services is not a recommendation to buy, sell or hold such
            investment or security or make any other investment decisions.
          </p>
          <p>
            Quandl, their affiliates or their suppliers have exclusive proprietary rights in their
            services and any data, information and software received in connection therewith.
          </p>
          <p>
            You shall not use or permit anyone to use the Quandl services for any unlawful or
            unauthorized purpose. The Quandl services are being provided for your internal use only
            and you are not authorized or permitted to distribute or otherwise furnish such
            information or software to any third-party without prior written approval of Quandl.
          </p>
          <p>
            Access to the Quandl services is subject to termination in the event that the
            corresponding agreement between Finvar and Quandl terminates for any reason.
          </p>
          <p>
            In the event that you use any portion of the Quandl services in breach of the TAC,
            Quandl may enforce its rights against you as the third-party beneficiary of the TAC,
            even though Quandl is not a party to the TAC.
          </p>
        </>
      ),
    },
    {
      label: 'XIX. Additional Terms that Apply to Finvar Paid Subscriptions',
      id: 'additionalTermsThatApplyToFinvarPaidSubscription',
      description: (
        <>
          <p>
            By subscribing to Finvar subscription products (the “Subscription”), you agree to pay
            the applicable Subscription fees set forth on the Site. Finvar reserves the right to
            revise Subscription fees upon reasonable notice. Unless we notify you in writing
            otherwise, you are not permitted to share Content available through your Subscriptions.
          </p>
          <p>Unless stated otherwise in writing, Subscription fees are nonrefundable.</p>
          <p>
            Finvar reserves the right to cancel a Subscription at any time. If we cancel a
            Subscription due to a breach of these Terms of Use you will not be eligible for any
            refund.
          </p>
          <p>
            You may download the Finvar services to a desktop/computing device used solely for your
            individual use and not for any centralized, interdepartmental or shared use.
          </p>
          <p>
            Once downloaded to your computing device, the Finvar services must remain on such
            computing device and may not be uploaded, copied or transferred to, or stored or managed
            in, any other desktop/computing device, including that of another user of the Site, or
            any device, directory, database or other repository that is shared or can be accessed by
            others (e.g. the download functionality may not be used to populate master security
            databases).
          </p>
          <p>
            You may download the Finvar services only as a convenience in connection with your use
            of the Site and not as an alternative to obtaining the Finvar services from and/or
            pursuant to an agreement with Finvar.{' '}
          </p>
        </>
      ),
    },
    {
      label: 'XX. Miscelaneous',
      id: 'miscelaneous',
      description: (
        <>
          <p>
            The Site is directed solely to individuals residing in jurisdictions in which provision
            of the Site&apos;s content is legal. We make no representation that materials provided
            on the Site are appropriate or available for use in other locations. Those who choose to
            access the Site from other locations do so on their own initiative and at their own
            risk, and are responsible for compliance with local laws, if and to the extent
            applicable. We reserve the right to limit the availability of the Site to any person,
            geographic area, or jurisdiction we so desire, at any time and in our sole discretion,
            and to limit the quantities of any such service or product that we provide.
          </p>
          <p>
            The TAC, together with all Finvar policies referred to herein, constitutes the entire
            agreement between you and Finvar relating to your use of the Site and supersedes and any
            all prior or contemporaneous written or oral agreements on that subject between us. The
            TAC, privacy policy and the relationship between you and Finvar are governed by and
            construed in accordance with the laws of the State of New York, without regard to its
            principles of conflict of laws. You and Finvar agree to submit to the personal and
            exclusive jurisdiction of the federal and state courts located within New York County,
            New York, and waive any jurisdictional, venue, or inconvenient forum objections to such
            courts. If any provision of the TAC is found to be unlawful, void, or for any reason
            unenforceable, then that provision shall be deemed severable from the TAC and shall not
            affect the validity and enforceability of any remaining provisions. No waiver by either
            party of any breach or default hereunder shall be deemed to be a waiver of any preceding
            or subsequent breach or default. Any heading, caption or section title contained in the
            TAC is inserted only as a matter of convenience and in no way defines or explains any
            section or provision hereof. We reserve the right to require you to sign a
            non-electronic version of the TAC.{' '}
          </p>
        </>
      ),
    },
  ],
};

export default content;
