import { DropdownItem } from 'components/dropdownFilter/DropdownFilter';

export type CountryOfInterest = '' | 'US' | 'GB' | 'NG' | 'ZA';

const countries: DropdownItem[] = [
  {
    id: 'US',
    label: 'United States',
  },
  {
    id: 'GB',
    label: 'United Kingdom',
  },
  {
    id: 'NG',
    label: 'Nigeria',
  },
  {
    id: 'ZA',
    label: 'South Africa',
  },
];

export const isCountryOfInterest = (value: string | null): value is CountryOfInterest => {
  switch (value) {
    case '':
    case 'US':
    case 'GB':
    case 'NG':
    case 'ZA':
      return true;
    default:
      return false;
  }
};

export default countries;
