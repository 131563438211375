import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  readonly title: string;
  readonly className?: string;
  readonly subtitle?: string;
  readonly buttonText?: string;
  readonly link?: string;
  readonly icon: string | React.JSXElementConstructor<React.HTMLAttributes<HTMLOrSVGElement>>;

  onClick?(): void;
}

const EmptySection: React.FC<Props> = ({
  title,
  subtitle,
  buttonText,
  link,
  icon,
  onClick,
}: Props): React.ReactElement => {
  const Icon = icon;

  const buttonClassName = useMemo(
    (): string => (onClick === undefined ? 'opacity-25 cursor-default' : 'cursor-pointer'),
    [onClick],
  );

  return (
    <div id="empty-section" className="flex flex-col justify-center items-center absolute inset-0">
      {typeof Icon === 'string' ? (
        <img src={Icon} className="h-16 w-13 mb-4" alt="icon" />
      ) : (
        <div className="text-gray-medium">
          <Icon className="w-13 h-16 mb-4 fill-current" />
        </div>
      )}
      <p className="text-base text-gray-darkest mb-1">{title}</p>
      <p className="text-sm text-gray mb-8">{subtitle}</p>
      {link ? (
        <Link to={link}>
          <p className="uppercase font-poppinsSemiBold text-sm text-blue">{buttonText}</p>
        </Link>
      ) : (
        <div className={buttonClassName} onClick={onClick}>
          <p className="uppercase font-poppinsSemiBold text-sm text-blue">{buttonText}</p>
        </div>
      )}
    </div>
  );
};

export default EmptySection;
