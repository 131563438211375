export interface HeaderMenuItemData {
  readonly id: string;
  readonly label: string;
}

export const menu: readonly HeaderMenuItemData[] = [
  {
    label: 'About Us',
    id: 'about-us',
  },
];
