import { Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { loginSucceeded, Session } from 'redux/reducers/authReducer';
import {
  ConnectionToFinance,
  OnboardingReferenceData,
  setInitializing,
  setReferenceData,
} from 'redux/reducers/onboardingReducer';
import { ThunkLikeAction } from 'types/APIAction';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';
import { SelectOption } from 'views/Landing/Onboarding/components/Select';

export interface AboutFormPayload {
  readonly about_me: string | null;
  readonly country: number;
  readonly state: number | null;
  readonly city: string | null;
  readonly accredited_investor: boolean;
  readonly connection_to_finance: ConnectionToFinance | null;
}

export interface ExperienceFormPayload {
  readonly career_level: string | null;
  readonly recent_job: string | null;
  readonly recent_company: string | null;
  readonly industry: string | null;
  readonly job_position_start_date: string | null;
  readonly job_position_end_date: string | null;
  readonly certifications: string[];
}

export interface OnboardingPayload {
  about: AboutFormPayload;
  job_information: ExperienceFormPayload;
  regions_of_interest: number[];
  topics_of_interest: number[];
  additional_topics_of_interest: string[];
}

export const submitOnboardingForm =
  (payload: OnboardingPayload): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    api
      .post(`${API_V1_PATH}/onboarding/create`, payload)
      .then((response: AxiosResponse<Session>): void => {
        dispatch(loginSucceeded(response.data));
      })
      .catch(console.warn);
  };

export const loadOnboardingReferenceData =
  (): ThunkLikeAction =>
  (dispatch: Dispatch): void => {
    dispatch(setInitializing(true));
    loadReferenceData()
      .then((referenceData: OnboardingReferenceData): void => {
        dispatch(setReferenceData(referenceData));
      })
      .catch((error: any): void => {
        console.warn(error);
      });
  };

const loadReferenceData = async (): Promise<OnboardingReferenceData> => {
  const countriesResponse: AxiosResponse<Array<ComboBoxItem<number>>> = await api.get(
    `${API_V1_PATH}/reference-data/countries`,
  );
  const regionsOfInterestResponse: AxiosResponse<Array<ComboBoxItem<number>>> = await api.get(
    `${API_V1_PATH}/reference-data/profile/regions-of-interest`,
  );
  const topicsOfInterestResponse: AxiosResponse<Array<ComboBoxItem<number>>> = await api.get(
    `${API_V1_PATH}/reference-data/profile/topics-of-interest`,
  );
  const countries = countriesResponse.data;

  return {
    countries: countries.map(
      (item: ComboBoxItem<number>): SelectOption<number> => ({ label: item.name, value: item.id }),
    ),
    regionsOfInterest: regionsOfInterestResponse.data,
    topicsOfInterest: topicsOfInterestResponse.data,
  };
};
