import { Elements } from '@stripe/react-stripe-js';
import { Appearance, CssFontSource, StripeElementsOptions, StripeError } from '@stripe/stripe-js';
import SVGIcon from 'components/icons/SVGIcon';
import { PaymentForm } from 'components/paymentForm';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  actionCompleted,
  actionFailed,
  actionStarted,
  actionSucceeded,
  ApplicationFeature,
  BillingInterval,
  Feature,
  featuresSelector,
  isProcessingStateForActions,
  processingStateSelector,
  SubscriptionPlan,
  SubscriptionPlansReducerActions,
  subscriptionPlansSelector,
} from 'redux/reducers/subscriptionPlansReducer';
import { getSubscriptionPlansAction } from 'redux/services/subscriptionPlansService';
import { ProcessingStateEnum } from 'types/processingState';
import api, { API_V1_PATH, isAxiosError } from 'utils/config/axiosConfig';
import { delayedExecution } from 'utils/delayedExecution';
import { initializeStripe } from 'utils/initializeStripe';
import {
  HttpStatusGone,
  HttpStatusNotFound,
  HttpStatusPreconditionFailed,
  HttpStatusTooManyRequests,
} from 'utils/statusCodes';
import { DiscountInput } from 'views/Checkout/SubscriptionCheckout/discountInput';
import { amountFormatter } from 'views/Checkout/SubscriptionCheckout/formatters';
import { InvoiceView } from 'views/Checkout/SubscriptionCheckout/invoice';
import {
  DiscountCodeErrors,
  isPaymentIntentPayment,
  Payment,
} from 'views/Checkout/SubscriptionCheckout/payment';
import { PaymentViewPlanFeatureSummary } from 'views/SubscriptionPlans/paymentViewPlanFeatureSummary';

export const CheckoutForm: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const [payment, setPayment] = useState<Payment>(state);
  const [discountCode, setDiscountCode] = useState<string>('');

  const processingState = useSelector(processingStateSelector);
  const subscriptionPlans = useSelector(subscriptionPlansSelector);
  const features = useSelector(featuresSelector);

  const [applyingDiscountCode, setApplyingDiscountCode] = useState<boolean>(false);
  const [currentDiscountCodeError, setCurrentDiscountCodeError] = useState<DiscountCodeErrors>(
    DiscountCodeErrors.none,
  );
  const amountString = useMemo((): string => {
    if (isPaymentIntentPayment(payment)) {
      const { invoice } = payment;
      if (!invoice) {
        return amountFormatter.format(0);
      } else {
        return amountFormatter.format(invoice.sub_total / 100);
      }
    }

    return amountFormatter.format(0);
  }, [payment]);

  const stripeOptions = useMemo((): StripeElementsOptions => {
    if (!payment || payment.client_secret === null) {
      return { appearance: stripeAppearance };
    }

    return {
      clientSecret: payment.client_secret,
      fonts: stripeFonts,
      appearance: stripeAppearance,
    };
  }, [payment]);

  const { planId, billingCycle } = useParams<{
    readonly planId: string;
    readonly billingCycle: BillingInterval;
  }>();

  const selectedPlan = useMemo((): SubscriptionPlan | undefined => {
    if (!subscriptionPlans) {
      return undefined;
    }

    return subscriptionPlans.find((plan: SubscriptionPlan) => plan.id === planId);
  }, [planId, subscriptionPlans]);

  const handlePaymentCancel = useCallback((): void => {
    // Cancel the payment intent
    if (payment) {
      api
        .delete(`${API_V1_PATH}/payments/${payment.id}`)
        .then((): void => {
          // TODO: fix this
        })
        .catch(console.warn);
    }

    navigate(-1);
  }, [navigate, payment]);

  const handlePaymentStart = useCallback((): void => {
    dispatch(actionStarted(SubscriptionPlansReducerActions.payment));
  }, [dispatch]);

  const handleApplyCode = useCallback(async (): Promise<void> => {
    if (!payment) {
      console.warn('cannot apply a discount yet');
      return;
    }

    try {
      setApplyingDiscountCode(true);
      setCurrentDiscountCodeError(DiscountCodeErrors.none);

      const response = await delayedExecution(
        api.put(`${API_V1_PATH}/payments/${payment.id}/apply-discount`, {
          subscription_plan_id: planId,
          code: discountCode,
        }),
        0.5,
      );

      setPayment(response.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response } = error;
        switch (response?.status) {
          case HttpStatusGone:
            setCurrentDiscountCodeError(DiscountCodeErrors.expired);
            break;
          case HttpStatusNotFound:
            setCurrentDiscountCodeError(DiscountCodeErrors.notFound);
            break;
          case HttpStatusTooManyRequests:
            setCurrentDiscountCodeError(DiscountCodeErrors.exceededUsageLimit);
            break;
          case HttpStatusPreconditionFailed:
            setCurrentDiscountCodeError(DiscountCodeErrors.notApplicable);
            break;
        }
      } else {
        console.warn(error);
      }
    } finally {
      setApplyingDiscountCode(false);
    }
  }, [discountCode, payment, planId]);

  const handleSuccess = useCallback((): void => {
    dispatch(actionSucceeded());
  }, [dispatch]);

  const handlePaymentSoftError = useCallback((): void => {
    dispatch(actionCompleted());
  }, [dispatch]);

  const handleFailure = useCallback(
    (error: StripeError): void => {
      dispatch(actionFailed(error));
    },
    [dispatch],
  );

  useEffect((): void => {
    if (payment) {
      if (isPaymentIntentPayment(payment)) {
        const code = payment.invoice?.discount?.code ?? '';
        // Apply discount
        setDiscountCode(code);
      }
    }
  }, [payment]);

  useEffect((): VoidFunction => {
    // Always ensure fresh features/subscription plans
    return dispatch(getSubscriptionPlansAction());
  }, [dispatch]);

  const selectedFeatures = useMemo((): readonly ApplicationFeature[] => {
    if (!features || !selectedPlan) {
      return [];
    }

    return features
      .map((feature: Feature): ApplicationFeature | undefined => selectedPlan.features[feature.id])
      .filter(
        (feature: ApplicationFeature | undefined): feature is ApplicationFeature =>
          feature !== undefined,
      );
  }, [features, selectedPlan]);

  const isPaymentFormSpinnerVisible = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.processing,
      SubscriptionPlansReducerActions.getSubscriptionPlans,
      SubscriptionPlansReducerActions.payment,
    );
  }, [processingState]);

  return (
    <div className="relative flex flex-grow items-stretch justify-center p-4 mx-auto gap-6">
      <div className="flex flex-col flex-grow w-modal-sm">
        <div className="flex items-end justify-between gap-12">
          <div>
            <h2 className="font-semibold text-2xl font-sans m-0">Payment Information</h2>
            <p className="font-poppins text-md text-gray">
              Please review your plan and enter your payment details
            </p>
          </div>
        </div>

        <Elements key={stripeOptions.clientSecret} stripe={globalStripe} options={stripeOptions}>
          <PaymentForm
            payment={payment}
            onPaymentCanceled={handlePaymentCancel}
            onPaymentStarted={handlePaymentStart}
            onPaymentSucceeded={handleSuccess}
            onPaymentFailed={handleFailure}
            onPaymentSoftError={handlePaymentSoftError}
          />
        </Elements>
      </div>

      <div className="flex flex-col min-w-max">
        <div className="flex-shrink bg-blue rounded-xl p-4 overflow-auto">
          <div className="flex items-start justify-between mb-5">
            <h3 className="flex gap-1 items-center font-jostSemiBold text-md uppercase text-white m-0 leading-4">
              <SVGIcon name="shared-icons-crown" className="w-6 h-6" /> {selectedPlan?.name} Plan
              Details
            </h3>
            <div>
              <h2 className="font-bold text-lg m-0 uppercase text-white leading-4 text-right">
                {amountString}
              </h2>
              <p className="text-white text-sm opacity-70">
                {billingCycle === BillingInterval.monthly ? 'per month' : 'per annum'}
              </p>
            </div>
          </div>
          <PaymentViewPlanFeatureSummary features={selectedFeatures} />
          {isPaymentIntentPayment(payment) ? <InvoiceView payment={payment} /> : null}
        </div>

        <DiscountInput
          payment={payment}
          discountCode={discountCode}
          busy={applyingDiscountCode}
          currentError={currentDiscountCodeError}
          onDiscountCodeChange={setDiscountCode}
          onApply={handleApplyCode}
        />
      </div>

      <SpinnerLoader visible={isPaymentFormSpinnerVisible} />
    </div>
  );
};

const globalStripe = initializeStripe();
const stripeAppearance: Appearance = {
  theme: 'stripe',
  variables: { fontFamily: 'Poppins', fontWeightNormal: '500' },
};

const fontUrl = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900';
const stripeFonts: CssFontSource[] = [
  {
    cssSrc: fontUrl,
  },
];
