import NoIcon from 'assets/icons/svg/no-portfolios-icon.svg';
import { AddObjectButton } from 'components/addObjectButton';
import { ConditionalRender } from 'components/conditionalRenderer';
import EmptySection from 'components/emptySection/EmptySection';
import { Filters } from 'components/filters';
import Pagination from 'components/pagination/Pagination';
import { SearchBox } from 'components/searchBox';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { useFilters } from 'hooks/useFilters';
import { useSort } from 'hooks/useSort';
import { noop } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import columns from 'views/EarnAndBuy/Earn/columns';
import filters from 'views/EarnAndBuy/Earn/filters';
import { useKeywordSearch } from 'views/EarnAndBuy/hooks/useKeywordSearch';
import {
  Listing,
  useListListingsQuery,
} from 'views/Landing/LandingPage/components/PublicEarnAndBuy/EarnAndBuy/api';

enum ModalType {
  none = 'none',
  details = 'details',
}

export const Earn: React.FC = (): React.ReactElement => {
  const [sortBy, handleSortChange] = useSort<Listing>('posted_at', SortOrder.descending);
  const [searchKeyword, setSearchKeyword] = useKeywordSearch();

  const navigate = useNavigate();
  const handleFiltersChange = useFilters(filters);

  const { data: jobs, isLoading } = useListListingsQuery();

  const rows = React.useMemo((): readonly Listing[] => jobs?.data ?? [], [jobs?.data]);

  const handleRowClick = React.useCallback(
    (row: Listing): void => {
      navigate(row.id, { state: ModalType.details });
    },
    [navigate],
  );

  const pagesCount = React.useMemo((): number => jobs?.page_count ?? 0, [jobs?.page_count]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-end justify-between mt-4 mb-3">
        <div className="w-full">
          <div className="-mt-2 mb-3">
            <SearchBox value={searchKeyword} onChange={setSearchKeyword} />
          </div>
          <div className="flex items-center justify-between w-full">
            <Filters config={filters} onChange={handleFiltersChange} />
            <AddObjectButton verb="New" title="Listing" onClick={noop} />
          </div>
        </div>
      </div>

      <div className="relative flex-1">
        <ConditionalRender renderIf={pagesCount === 0 && !isLoading}>
          <EmptySection title="There are no listings" icon={NoIcon} />
        </ConditionalRender>

        <ConditionalRender renderIf={pagesCount > 0}>
          <Pagination totalPages={pagesCount}>
            <Table
              columns={columns}
              rows={rows}
              sortBy={sortBy}
              onRowClick={handleRowClick}
              onSortChange={handleSortChange}
            />
          </Pagination>
        </ConditionalRender>
        <SpinnerLoader visible={isLoading} />
      </div>
    </div>
  );
};
