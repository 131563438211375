import { ReactComponent as DeleteIcon } from 'assets/icons/svg/delete-paperbin-icon.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/svg/info-gray.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/svg/save-icon.svg';
import { ReactComponent as SavedIcon } from 'assets/icons/svg/saved-icon.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/svg/share-icon.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import { Modal } from 'components/modal';
import { Tooltip } from 'components/tooltip';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import { deleteIdSelector } from 'redux/reducers/feedReducer';
import { ReportableType } from 'redux/reducers/reportsReducer';
import {
  deletePostService,
  saveNewsService,
  savePostService,
  undoSaveNewsService,
  undoSavePostService,
} from 'redux/services/feedService';
import { MoreButton } from 'views/Home/components/FeedCard/components/moreButton';
import { FeedCardMoreMenuItem } from 'views/Home/components/FeedCard/components/moreMenuItem';
import { ReportModal } from 'views/Home/modals/ReportModal';
import {
  DELETE,
  DELETE_POST_QUESTION,
  DELETE_POST_TITLE,
  POST_REPORTED_WARNING,
  REPORT,
  SHARE,
} from 'views/Home/strings';
import { FeedCategory, FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;
}

enum ModalType {
  none,
  sharePost,
  reportPost,
  deletePost,
}

export const TitleRightTool: React.FC<Props> = ({ feedItem }: Props): React.ReactElement => {
  const [currentModal, setCurrentModal] = useState<ModalType>(ModalType.none);
  const dispatch = useDispatch<any>();
  const user = useSelector(userSelector);
  const deleteId = useSelector(deleteIdSelector);
  const { trackEvent } = useGtag();

  const handleSave = () => {
    if (!feedItem.saved) {
      feedItem.category === FeedCategory.post
        ? dispatch(savePostService(feedItem.id))
        : dispatch(saveNewsService(feedItem.id));
    } else {
      feedItem.category === FeedCategory.post
        ? dispatch(undoSavePostService(feedItem.id, feedItem.category))
        : dispatch(undoSaveNewsService(feedItem.id, feedItem.category));
    }
  };

  const handleDelete = useCallback((): void => {
    dispatch(deletePostService(feedItem.id));
  }, [feedItem.id, dispatch]);

  const showDeleteModal = useCallback((): void => setCurrentModal(ModalType.deletePost), []);
  const showReportModal = useCallback((): void => setCurrentModal(ModalType.reportPost), []);
  const showShareModal = useCallback((): void => {
    setCurrentModal(ModalType.sharePost);
    trackEvent('share_post', { post_id: feedItem.id });
  }, [feedItem.id, trackEvent]);

  const closeModal = useCallback((): void => setCurrentModal(ModalType.none), []);

  useEffect((): void => {
    if (deleteId !== null && currentModal === ModalType.deletePost) {
      closeModal();
    }
  }, [closeModal, currentModal, deleteId]);

  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        <ConditionalRender renderIf={feedItem.reported}>
          <Tooltip content={POST_REPORTED_WARNING}>
            <div className="w-5 h-5 text-red-500">
              <ReportIcon className="fill-current" />
            </div>
          </Tooltip>
        </ConditionalRender>
        <div className="w-5 h-5 cursor-pointer text-blue" onClick={handleSave}>
          {feedItem.saved ? (
            <SavedIcon className="h-full fill-current" />
          ) : (
            <SaveIcon className="h-full fill-current" />
          )}
        </div>
        <MoreButton>
          <FeedCardMoreMenuItem label={SHARE} icon={ShareIcon} onClick={showShareModal} />
          <ConditionalRender renderIf={user.id === feedItem.publisherId}>
            <FeedCardMoreMenuItem label={DELETE} icon={DeleteIcon} onClick={showDeleteModal} />
          </ConditionalRender>
          <FeedCardMoreMenuItem
            label={REPORT}
            icon={ReportIcon}
            onClick={showReportModal}
            disabled={feedItem.reported}
          />
        </MoreButton>
      </div>
      <Modal isOpen={currentModal === ModalType.deletePost} onClose={closeModal}>
        <Modal.Content title={DELETE_POST_TITLE}>
          <ConfirmationBox message={DELETE_POST_QUESTION} onYes={handleDelete} onNo={closeModal} />
        </Modal.Content>
      </Modal>
      <Modal isOpen={currentModal === ModalType.reportPost} onClose={closeModal}>
        <ReportModal
          reportableId={feedItem.id}
          reportableType={
            feedItem.category === FeedCategory.post ? ReportableType.Posts : ReportableType.News
          }
          onReport={(): void => {
            return;
          }}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
};
