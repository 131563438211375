import { loadStripe, Stripe } from '@stripe/stripe-js';

export const initializeStripe = async (): Promise<Stripe | null> => {
  if (process.env.REACT_APP_DATA_PORTAL) {
    return null;
  } else {
    const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    if (!stripePublicKey) {
      return null;
    }

    return loadStripe(stripePublicKey);
  }
};
