import Button from 'components/buttons/Button/Button';
import CompaniesInput from 'components/companiesInput/CompaniesInput';
import { useGtag } from 'hooks/useGtag';
import React, { useState } from 'react';
import { FormErrors, ValidationErrors } from 'utils/forms';
import * as yup from 'yup';
import { ValidationError } from 'yup';

interface Props {
  onAddCompany(companyId: string): void;
}

const schema = yup.object().shape({
  companyName: yup.string().required('Please enter a company name'),
});

const AddCompanyWatchlist: React.FC<Props> = ({ onAddCompany }: Props): React.ReactElement => {
  const [companyName, setCompanyName] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({});
  const { trackEvent } = useGtag();

  const handleAddCompany = async (): Promise<void> => {
    try {
      setErrors({});
      await schema.validate({ companyName }, { abortEarly: false });

      onAddCompany(companyId);
      trackEvent('end-add-company-watchlist', { company_id: companyId, company_name: companyName });
    } catch (error) {
      const { inner } = error as ValidationError;
      setErrors(ValidationErrors.reduce(inner));
    }
  };

  return (
    <div className="relative w-full md:w-modal-sm min-h-88">
      <h1 className="text-gray-darkest mb-2">Add Company to Watchlist</h1>
      <CompaniesInput
        name="companyName"
        placeholder="Search for a US public company"
        value={companyName}
        setCompany={setCompanyName}
        setCompanyId={setCompanyId}
        labelClassName="left-4 top-0.5 text-gray"
        paddingLeft="pl-5 pr-2 truncate"
        containerClassName="w-full"
        searchIcon
        showLabel={false}
        error={errors.companyName}
        section="watchlist"
      />
      <div className="absolute bottom-0 right-0">
        <Button
          id="submit-companies-button"
          rounded="rounded-sm"
          label="ADD"
          width="w-28"
          height="h-full"
          padding="p-4"
          onClick={handleAddCompany}
        />
      </div>
    </div>
  );
};

export default AddCompanyWatchlist;
