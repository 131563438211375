import { NumericInput } from 'components/numericInput';
import { Select, SelectItem } from 'components/select';
import React from 'react';
import {
  AdvisoryOrConsultingType,
  advisoryOrConsultingTypeLabels,
  PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem;
}

export const ClientOrCustomerAdvisoryOrConsultingForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <Select
        id="typeOfAdvisoryOrConsulting"
        label="Type of Advisory or Consulting"
        items={advisoryOrConsultingTypes}
        value={item.advisoryOrConsultingType}
        onChange={updaterFor('advisoryOrConsultingType')}
      />
      <Select
        id="currency"
        label="Currency of billing"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <div className="col-span-2">
        <NumericInput
          name="annualMonetaryIcomFromClient"
          value={item.annualMonetaryIncomeFromClient}
          label="Annual monetary income from client"
          onValueChange={updaterFor('annualMonetaryIncomeFromClient')}
        />
      </div>
    </div>
  );
};

const advisoryOrConsultingTypes: ReadonlyArray<SelectItem<AdvisoryOrConsultingType>> =
  enumToSelectItems(AdvisoryOrConsultingType, advisoryOrConsultingTypeLabels);
