import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';

export enum OrganizationTypes {
  all = 'all',
  none = 'none',
  company = 'company',
  industry = 'industry',
}

interface OrganizationBase {
  readonly id: string;
  readonly name: string;
  readonly type: OrganizationTypes;
  readonly tags: readonly string[];
}

export interface Company extends OrganizationBase {
  readonly type: OrganizationTypes.company;
  readonly ticker: string;
}

export interface Industry extends OrganizationBase {
  readonly type: OrganizationTypes.industry;
}

export type Organization = Company | Industry;

export interface SmartToolsRecentSearchesState {
  recentSearches: readonly Organization[];
  loading: boolean;

  searchResults: readonly Organization[];
  searching: boolean;

  error: string | null;
}

const initialState: SmartToolsRecentSearchesState = {
  recentSearches: [],
  loading: false,
  searchResults: [],
  searching: false,
  error: null,
};

const slice = createSlice({
  name: 'smartToolsRecentSearches',
  initialState: initialState,
  reducers: {
    fetchSmartToolsRecentSearchesStarted: state => {
      state.loading = true;
    },
    fetchSmartToolsRecentSearchesSucceeded: (
      state: SmartToolsRecentSearchesState,
      action: PayloadAction<readonly Organization[]>,
    ) => {
      state.loading = false;
      state.recentSearches = action.payload;
    },
    fetchSmartToolsRecentSearchesFailed: (
      state: SmartToolsRecentSearchesState,
      action: PayloadAction<string | null>,
    ): void => {
      state.loading = false;
      state.error = action.payload;
    },
    smartToolsSearchOrganizationsStarted: (state: SmartToolsRecentSearchesState): void => {
      state.searching = true;
    },
    smartToolsSearchOrganizationsSucceeded: (
      state: SmartToolsRecentSearchesState,
      action: PayloadAction<readonly Organization[]>,
    ): void => {
      state.searching = false;
      state.searchResults = action.payload;
    },
    smartToolsSearchOrganizationsFailed: (
      state: SmartToolsRecentSearchesState,
      action: PayloadAction<string | null>,
    ): void => {
      state.searching = false;
      state.error = action.payload;
    },
    smartToolsResetSearchResults: (state: SmartToolsRecentSearchesState): void => {
      state.searchResults = [];
    },
  },
});

export default slice.reducer;

export const {
  fetchSmartToolsRecentSearchesStarted,
  fetchSmartToolsRecentSearchesSucceeded,
  fetchSmartToolsRecentSearchesFailed,
  smartToolsSearchOrganizationsStarted,
  smartToolsSearchOrganizationsSucceeded,
  smartToolsSearchOrganizationsFailed,
  smartToolsResetSearchResults,
} = slice.actions;

export const smartToolsRecentSearchesSelector = (
  state: ApplicationState,
): SmartToolsRecentSearchesState => state.smartToolsRecentSearches;
