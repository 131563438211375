import { AnyAction } from '@reduxjs/toolkit';
import { setCurrentPlan } from 'redux/reducers/authReducer';
import {
  fetchingSubscriptionPlansHistoryFailed,
  fetchingSubscriptionPlansHistoryStarted,
  fetchingSubscriptionPlansHistorySucceeded,
} from 'redux/reducers/profileReducer';
import {
  actionFailed,
  actionStarted,
  actionSucceeded,
  setSubscriptionPlans,
  SubscriptionPlansReducerActions,
} from 'redux/reducers/subscriptionPlansReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import settings from 'utils/config/appSettings';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export const toggleActiveSubscriptionPlanAutoRenewalAction = createAPIAction(
  function toggleActiveSubscriptionPlanAutoRenewal(httpClient: HttpClient): ActionsGenerator {
    return async function* (): AsyncGenerator<AnyAction> {
      yield actionStarted(SubscriptionPlansReducerActions.toggleActiveSubscriptionAutoRenewal);

      try {
        const response = await httpClient.PUT(
          `${API_V1_PATH}/subscriptions/toggle-automatic-renewal`,
          {},
        );
        yield setCurrentPlan(response.data);
        yield actionSucceeded();
      } catch (error: unknown) {
        yield actionFailed(error);
      }
    };
  },
);

export const requestFreeTrialAction = createAPIAction(function requestFreeTrial(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (subscriptionId: string): AsyncGenerator<AnyAction> {
    yield actionStarted(SubscriptionPlansReducerActions.requestFreeTrial);

    try {
      const response = await httpClient.POST(
        `${API_V1_PATH}/products/subscriptions/${subscriptionId}/free-trial`,
        {},
      );
      yield setCurrentPlan(response.data);
      yield actionSucceeded();
    } catch (error: unknown) {
      // TODO: parse errors
      yield actionFailed(error);
    }
  };
});

export const cancelActiveSubscriptionPlanAction = createAPIAction(
  function cancelActiveSubscriptionPlan(httpClient: HttpClient): ActionsGenerator {
    return async function* (reason: string): AsyncGenerator<AnyAction> {
      yield actionStarted(SubscriptionPlansReducerActions.cancelActiveSubscription);

      try {
        const response = await httpClient.POST(`${API_V1_PATH}/subscriptions/active/cancel`, {
          reason: reason,
        });
        yield setCurrentPlan(response.data);
        yield actionSucceeded();
      } catch (error: unknown) {
        yield actionFailed(error);
      }
    };
  },
);

export const listUserSubscriptionsAction = createAPIAction(function listUserSubscriptions(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (query: Record<string, string>): AsyncGenerator<AnyAction> {
    yield fetchingSubscriptionPlansHistoryStarted();

    const fixedQuery = {
      page_number: 1,
      page_size: settings.defaultPageSize,
      ...query,
    };

    try {
      const response = await httpClient.GET(`${API_V1_PATH}/subscriptions/history`, fixedQuery);
      yield fetchingSubscriptionPlansHistorySucceeded(response.data);
    } catch (error: unknown) {
      console.warn(error);
      yield fetchingSubscriptionPlansHistoryFailed();
    }
  };
});

export const getActiveSubscriptionForLoggedInUserAction = createAPIAction(
  function getActiveSubscriptionForLoggedInUser(httpClient: HttpClient): ActionsGenerator {
    return async function* (): AsyncGenerator<AnyAction> {
      yield actionStarted(SubscriptionPlansReducerActions.getActiveSubscription);

      try {
        const response = await httpClient.GET(`${API_V1_PATH}/subscriptions/active`);
        yield setCurrentPlan(response.data);
        yield actionSucceeded();
      } catch (error: unknown) {
        yield actionFailed(error);
      }
    };
  },
);

export const getSubscriptionPlansAction = createAPIAction(function getFeatures(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (): AsyncGenerator<AnyAction> {
    yield actionStarted(SubscriptionPlansReducerActions.getSubscriptionPlans);

    try {
      // Note that we don't await here, because if we do, then only
      // one of the requests will be canceled. Also, this way both requests
      // are sent "at the same time"
      const subscriptionPlansRequest = httpClient.GET(`${API_V1_PATH}/products/subscriptions`);
      const featureListRequest = httpClient.GET(`${API_V1_PATH}/products/subscriptions/features`);

      yield setSubscriptionPlans({
        plans: (await subscriptionPlansRequest).data,
        features: (await featureListRequest).data,
      });
    } catch (error: unknown) {
      console.warn('error', error);
      yield actionFailed(SubscriptionPlansReducerActions.getSubscriptionPlans);
      yield setSubscriptionPlans({
        plans: [],
        features: [],
      });
    }
  };
});
