import React from 'react';

interface Props {
  readonly count: number | undefined;
  readonly withRing: boolean;
}

export const OverflowCountNode: React.FC<Props> = ({
  count,
  withRing,
}: Props): React.ReactElement => {
  return (
    <div className={withRing ? 'p-2 border-4 border-blue rounded-full' : undefined}>
      <div className="flex flex-col items-center justify-center w-24 h-24 bg-blue rounded-full">
        <span className="font-poppinsSemiBold text-white text-base">+{count}</span>
        <span className="font-poppinsMedium text-white text-base">SEE BELOW</span>
      </div>
    </div>
  );
};
