import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceInMilliseconds } from 'date-fns';
import { AlgoNote } from 'redux/reducers/algoNotesReducer';
import { ApplicationState } from 'redux/reducers/store';
import { Page } from 'types/page';
import { PeerComparisons } from 'types/peerComparisons';
import { ProcessingState } from 'types/processingState';

export enum UserPeerGroupActions {
  fetch = 'fetch',
  fetchGroup = 'fetchGroup',
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export interface PeerGroup {
  id: string;
  name: string;
  description?: string;
  created_at: string;
  tags: string[];
  number_of_companies: number;
  comparisons?: PeerComparisons;
}

export class PeerGroup {
  static empty(): PeerGroup {
    return {
      id: '',
      name: '',
      description: '',
      created_at: '',
      tags: [],
      number_of_companies: 0,
      comparisons: PeerComparisons.empty(),
    };
  }
}

export interface AlgoNotes {
  readonly algo_notes: Page<AlgoNote>;
  readonly focus_company: {
    readonly id: string;
    readonly name: string;
  };
}

export interface UserPeerGroupsState {
  peerGroups: Page<PeerGroup>;
  currentPeerGroup: PeerGroup;
  processingState: ProcessingState<any>;
  algoNotes: AlgoNotes;
}

const initialState: UserPeerGroupsState = {
  peerGroups: Page.empty(),
  processingState: ProcessingState.idle(),
  currentPeerGroup: PeerGroup.empty(),
  algoNotes: {
    algo_notes: Page.empty(),
    focus_company: {
      id: '',
      name: '',
    },
  },
};

const createOrUpdateSucceeded = (
  state: UserPeerGroupsState,
  { payload }: PayloadAction<PeerGroup>,
): void => {
  state.processingState = ProcessingState.success(state.processingState.data);
  state.peerGroups = Page.addOrReplace(
    state.peerGroups,
    payload,
    (existingGroup: { id: string }): boolean => existingGroup.id === payload.id,
    (group1: PeerGroup, group2: PeerGroup): number => {
      const date1 = new Date(group1.created_at);
      const date2 = new Date(group2.created_at);

      return differenceInMilliseconds(date2, date1);
    },
  );
};

const slice = createSlice({
  name: 'userPeerGroups',
  initialState: initialState,
  reducers: {
    fetchUserPeerGroupsStarted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.processing(UserPeerGroupActions.fetch);
    },
    fetchUserPeerGroupsSucceeded: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<Page<PeerGroup>>,
    ): void => {
      state.processingState = ProcessingState.success(state.processingState.data);
      state.peerGroups = payload;
    },
    fetchUserPeerGroupsFailed: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<Error>,
    ): void => {
      state.processingState = ProcessingState.error(state.processingState.data);
      console.warn(payload);
    },
    fetchUserPeerGroupAlgoNotesStarted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.processing(UserPeerGroupActions.fetch);
    },
    fetchUserPeerGroupAlgoNotesSucceeded: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<AlgoNotes>,
    ): void => {
      state.processingState = ProcessingState.success(state.processingState.data);
      state.algoNotes = payload;
    },
    fetchUserPeerGroupAlgoNotesFailed: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<Error>,
    ): void => {
      state.processingState = ProcessingState.error(state.processingState.data);
      console.warn(payload);
    },
    fetchUserPeerGroupsCompleted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.idle();
    },
    createUserPeerGroupStarted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.processing(UserPeerGroupActions.create);
    },
    createUserPeerGroupSucceeded: createOrUpdateSucceeded,
    createUserPeerGroupFailed: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<Error>,
    ): void => {
      state.processingState = ProcessingState.error(state.processingState.data);
      console.warn(payload);
    },
    createUserPeerGroupCompleted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.idle();
    },
    updateUserPeerGroupStarted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.processing(UserPeerGroupActions.update);
    },
    updateUserPeerGroupSucceeded: createOrUpdateSucceeded,
    updateUserPeerGroupFailed: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<Error>,
    ): void => {
      console.warn(payload);
      state.processingState = ProcessingState.error(state.processingState.data);
    },
    updateUserPeerGroupCompleted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.idle();
    },
    fetchUserPeerGroupComparisonsStarted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.processing(UserPeerGroupActions.fetchGroup);
      state.currentPeerGroup = PeerGroup.empty();
    },
    fetchUserPeerGroupComparisonsSucceeded: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<PeerGroup>,
    ): void => {
      state.processingState = ProcessingState.success(state.processingState.data);
      state.currentPeerGroup = payload;
    },
    fetchUserPeerGroupComparisonsFailed: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<Error>,
    ): void => {
      state.processingState = ProcessingState.error(state.processingState.data);
      console.warn(payload);
    },
    fetchUserPeerGroupComparisonsCompleted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.idle();
    },
    deleteUserPeerGroupStarted: (
      state: UserPeerGroupsState,
      { payload }: PayloadAction<string>,
    ): void => {
      state.processingState = ProcessingState.processing({
        action: UserPeerGroupActions.delete,
        peerGroupId: payload,
      });
    },
    deleteUserPeerGroupSucceeded: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.success(state.processingState.data);
    },
    deleteUserPeerGroupFailed: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.error(state.processingState.data);
    },
    deleteUserPeerGroupCompleted: (state: UserPeerGroupsState): void => {
      state.processingState = ProcessingState.idle();
    },
  },
});

export const {
  fetchUserPeerGroupsStarted,
  fetchUserPeerGroupsSucceeded,
  fetchUserPeerGroupsFailed,
  fetchUserPeerGroupsCompleted,
  createUserPeerGroupStarted,
  createUserPeerGroupSucceeded,
  createUserPeerGroupFailed,
  createUserPeerGroupCompleted,
  updateUserPeerGroupStarted,
  updateUserPeerGroupSucceeded,
  updateUserPeerGroupFailed,
  updateUserPeerGroupCompleted,
  deleteUserPeerGroupStarted,
  deleteUserPeerGroupSucceeded,
  deleteUserPeerGroupFailed,
  deleteUserPeerGroupCompleted,
  fetchUserPeerGroupComparisonsStarted,
  fetchUserPeerGroupComparisonsSucceeded,
  fetchUserPeerGroupComparisonsFailed,
  fetchUserPeerGroupComparisonsCompleted,
  fetchUserPeerGroupAlgoNotesStarted,
  fetchUserPeerGroupAlgoNotesSucceeded,
  fetchUserPeerGroupAlgoNotesFailed,
} = slice.actions;

export const userPeerGroupsSelector = (state: ApplicationState): Page<PeerGroup> =>
  state.userPeerGroups.peerGroups;

export const userPeerGroupAlgoNotesSelector = (state: ApplicationState): AlgoNotes =>
  state.userPeerGroups.algoNotes;

export const userPeerGroupProcessingStateSelector = (
  state: ApplicationState,
): ProcessingState<any> => state.userPeerGroups.processingState;

const idleState = ProcessingState.idle();

export const fetchUserPeerGroupsProcessingState = (
  state: ApplicationState,
): ProcessingState<any> => {
  const { processingState } = state.userPeerGroups;
  if (processingState?.data === UserPeerGroupActions.fetch) {
    return processingState;
  }

  return idleState;
};

export const fetchUserPeerGroupProcessingState = (
  state: ApplicationState,
): ProcessingState<any> => {
  const { processingState } = state.userPeerGroups;
  if (processingState?.data === UserPeerGroupActions.fetchGroup) {
    return processingState;
  }

  return idleState;
};

export const createUserPeerGroupProcessingStateSelector = (
  state: ApplicationState,
): ProcessingState<any> => {
  const { processingState } = state.userPeerGroups;
  if (processingState?.data === UserPeerGroupActions.create) {
    return processingState;
  }

  return idleState;
};

export const updateUserPeerGroupProcessingStateSelector = (
  state: ApplicationState,
): ProcessingState<any> => {
  const { processingState } = state.userPeerGroups;
  if (processingState?.data === UserPeerGroupActions.update) {
    return processingState;
  }

  return idleState;
};

export const userPeerGroupSelector = (state: ApplicationState): PeerGroup =>
  state.userPeerGroups.currentPeerGroup;

export default slice.reducer;
