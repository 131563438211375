import { MainOutletContent } from 'components/mainOutletContent';
import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import { ErrorView } from 'views/ErrorView';
import SectionTitle from 'views/Landing/Login/components/sectionTitle';
import OurPlans from 'views/SubscriptionPlans';

const PlanUpgrade: React.FC = (): React.ReactElement => {
  const user = useSelector(userSelector);

  if (user?.account_type === 'Owner') {
    return (
      <ErrorView
        title="You are the super user"
        message="The super user is a special type of user that requires no paid plans or subscriptions."
      />
    );
  }

  return (
    <MainOutletContent>
      <SectionTitle
        title="SELECT YOUR PLAN"
        subTitle="Click to select a plan that best suits your needs"
      />
      <OurPlans />
    </MainOutletContent>
  );
};

export default PlanUpgrade;
