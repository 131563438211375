import { ProcessingState } from 'types/processingState';
import { FormErrors } from 'utils/forms';

enum ActionType {
  setCurrentPassword = '@actions/changePasswordModal/setCurrentPassword',
  setNewPassword = '@actions/changePasswordModal/setNewPassword',
  setConfirmNewPassword = '@actions/changePasswordModal/setConfirmNewPassword',
  submissionStarted = '@actions/changePasswordModal/submissionStarted',
  submissionFailed = '@actions/changePasswordModal/submissionFailed',
  submissionCompleted = '@actions/changePasswordModal/submissionCompleted',
  submissionSucceeded = '@actions/changePasswordModal/submissionSucceeded',
  reset = '@actions/changePasswordModal/reset',
  editValidationCompleted = '@actions/changePasswordModal/editValidationCompleted',
  closeErrorMessage = '@actions/changePasswordModal/closeErrorMessage',
}

interface ActionBase {
  readonly type: ActionType;
}

export interface SetCurrentPasswordAction extends ActionBase {
  readonly type: ActionType.setCurrentPassword;
  readonly payload: string;
}

export class SetCurrentPasswordAction {
  public static create(value: string): SetCurrentPasswordAction {
    return {
      type: ActionType.setCurrentPassword,
      payload: value,
    };
  }
}

export interface SetNewPasswordAction extends ActionBase {
  readonly type: ActionType.setNewPassword;
  readonly payload: string;
}

export class SetNewPasswordAction {
  public static create(value: string): SetNewPasswordAction {
    return {
      type: ActionType.setNewPassword,
      payload: value,
    };
  }
}

export interface SetConfirmNewPasswordAction extends ActionBase {
  readonly type: ActionType.setConfirmNewPassword;
  readonly payload: string;
}

export class SetConfirmNewPasswordAction {
  public static create(value: string): SetConfirmNewPasswordAction {
    return {
      type: ActionType.setConfirmNewPassword,
      payload: value,
    };
  }
}

export interface SubmissionStartedAction extends ActionBase {
  readonly type: ActionType.submissionStarted;
}

export class SubmissionStartedAction {
  public static create(): SubmissionStartedAction {
    return {
      type: ActionType.submissionStarted,
    };
  }
}

export interface SubmissionFailedAction extends ActionBase {
  readonly type: ActionType.submissionFailed;
  readonly payload: Error;
}

export class SubmissionFailedAction {
  public static create(error: Error): SubmissionFailedAction {
    return {
      type: ActionType.submissionFailed,
      payload: error,
    };
  }
}

export interface SubmissionSucceededAction extends ActionBase {
  readonly type: ActionType.submissionSucceeded;
}

export class SubmissionSucceededAction {
  public static create(): SubmissionSucceededAction {
    return {
      type: ActionType.submissionSucceeded,
    };
  }
}

export interface SubmissionCompletedAction extends ActionBase {
  readonly type: ActionType.submissionCompleted;
}

export class SubmissionCompletedAction {
  public static create(): SubmissionCompletedAction {
    return {
      type: ActionType.submissionCompleted,
    };
  }
}

export interface ResetAction extends ActionBase {
  readonly type: ActionType.reset;
}

export class ResetAction {
  public static create(): ResetAction {
    return {
      type: ActionType.reset,
    };
  }
}

export interface EditValidationCompletedAction extends ActionBase {
  readonly type: ActionType.editValidationCompleted;
  readonly payload: FormErrors;
}

export class EditValidationCompletedAction {
  public static create(errors: FormErrors): EditValidationCompletedAction {
    return {
      type: ActionType.editValidationCompleted,
      payload: errors,
    };
  }
}

export interface CloseErrorMessageAction extends ActionBase {
  readonly type: ActionType.closeErrorMessage;
}

export class CloseErrorMessageAction {
  public static create(): CloseErrorMessageAction {
    return {
      type: ActionType.closeErrorMessage,
    };
  }
}

export type Action =
  | SetCurrentPasswordAction
  | SetNewPasswordAction
  | SetConfirmNewPasswordAction
  | SubmissionStartedAction
  | SubmissionFailedAction
  | SubmissionCompletedAction
  | SubmissionSucceededAction
  | EditValidationCompletedAction
  | CloseErrorMessageAction
  | ResetAction;

export interface ChangePasswordForm {
  readonly currentPassword: string;
  readonly newPassword: string;
  readonly confirmNewPassword: string;
}

export interface State extends ChangePasswordForm {
  readonly processingState: ProcessingState<never | Error>;
  readonly errors: FormErrors<ChangePasswordForm>;
  readonly submitted: boolean;
}

export const initialState: State = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  processingState: ProcessingState.idle(),
  errors: {},
  submitted: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.submissionSucceeded:
      return { ...state, processingState: ProcessingState.success() };
    case ActionType.reset:
      return initialState;
    case ActionType.setCurrentPassword:
      return { ...state, currentPassword: action.payload };
    case ActionType.setNewPassword:
      return { ...state, newPassword: action.payload };
    case ActionType.setConfirmNewPassword:
      return { ...state, confirmNewPassword: action.payload };
    case ActionType.submissionStarted:
      return { ...state, errors: {}, processingState: ProcessingState.processing() };
    case ActionType.submissionFailed:
      return { ...state, processingState: ProcessingState.error(action.payload) };
    case ActionType.submissionCompleted:
      return { ...state, submitted: true };
    case ActionType.editValidationCompleted:
      return { ...state, errors: action.payload };
    case ActionType.closeErrorMessage:
      return { ...state, processingState: ProcessingState.idle() };
  }

  return state;
};

export default reducer;
