import React from 'react';

interface Props {
  readonly path: readonly string[];

  readonly rightComponent?: React.ReactElement;
}

export const Breadcrumbs: React.FC<Props> = ({
  path,
  rightComponent,
}: Props): React.ReactElement => {
  return (
    <div className="flex items-center gap-1 md:gap-2 relative">
      <h3 className={textClass}>{path[0]}</h3>
      {path.slice(1).map(
        (name: string): React.ReactElement => (
          <React.Fragment key={name}>
            <h3 className={textClass}>&bull;</h3>
            <h3 className={textClass}>{name}</h3>
          </React.Fragment>
        ),
      )}

      <div className="absolute right-0 top-0">{rightComponent}</div>
    </div>
  );
};

const textClass = 'block text-xs md:text-base text-gray-darkest leading-none truncate';
