import { ReactComponent as AddIcon } from 'assets/icons/svg/add-icon.svg';
import { Modal } from 'components/modal';
import { Tooltip } from 'components/tooltip';
import { useGtag } from 'hooks/useGtag';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { processingStateSelector, TimelineAction } from 'redux/reducers/timelineReducer';
import { ProcessingStateEnum } from 'types/processingState';
import { Stream, StreamEvent } from 'types/timeline';
import { parseDate } from 'utils/dateTime';
import { EventItem } from 'views/SmartTools/TimelineAndEvents/components/eventItem';
import { EmptyView } from 'views/SmartTools/TimelineAndEvents/components/streamItem/components/emptyView';
import { LeftPanel } from 'views/SmartTools/TimelineAndEvents/components/streamItem/components/leftPanel';
import { StateButton } from 'views/SmartTools/TimelineAndEvents/components/streamItem/components/stateButton';
import CreateEditEventModal from 'views/SmartTools/TimelineAndEvents/modals/createEditEventModal';

interface BaseProps {
  readonly stream: Stream;
  readonly compact: boolean;
  readonly readOnly: boolean;
  readonly variant: 'regular' | 'sponsored';
  readonly mode?: 'expand' | 'select';
}

interface RWProps extends BaseProps {
  readonly readOnly: false;
  readonly selected: boolean;
  readonly mode: 'select';

  onSelect(id: string, value: boolean): void;
}

interface ROProps extends BaseProps {
  readonly readOnly: true;
}

interface ROExpandableProps extends ROProps {
  readonly expanded: boolean;
  readonly mode: 'expand';

  onExpand(id: string, value: boolean): void;
}

type Props = ROProps | RWProps | ROExpandableProps;

const isROExpandable = (props: Props): props is ROExpandableProps => props.mode === 'expand';
const isRW = (props: Props): props is RWProps => props.mode === 'select';

export const StreamItem: React.FC<Props> = (props: Props): React.ReactElement => {
  const processingState = useSelector(processingStateSelector);

  const { stream } = props;
  const { id, category, events } = stream;

  const createModal = useModal();
  const { trackEvent } = useGtag();

  const handleValueChange = useCallback(
    (value: boolean): void => {
      if (!props.readOnly) {
        if (props.mode === 'select') {
          props.onSelect(id, value);
        }
      } else if (isROExpandable(props)) {
        props.onExpand(id, value);
      }
    },
    [props, id],
  );

  const containerClass = useMemo((): string => {
    if (props.variant === 'sponsored') {
      return [baseContainerClass, 'border-transparent bg-gray-light h-16'].join(' ');
    }

    return [
      baseContainerClass,
      colors[category],
      'h-60 shadow-md hover:bg-blue-light transition',
    ].join(' ');
  }, [category, props.variant]);

  const itemContainerClass = useMemo(
    (): string => (props.compact ? compactLayout : normalLayout),
    [props.compact],
  );

  const past = useMemo(
    (): StreamEvent[] =>
      events.filter(
        ({ start_date: date, start_time: time, timezone }: StreamEvent): boolean =>
          (parseDate(date, time, timezone)?.getTime() ?? -1) < Date.now(),
      ),
    [events],
  );

  const upcoming = useMemo(
    (): StreamEvent[] => events.slice(past.length, past.length + 5),
    [events, past.length],
  );

  const future = useMemo(
    (): StreamEvent[] => events.slice(past.length + upcoming.length),
    [events, upcoming.length, past.length],
  );

  const selectionButton = useMemo((): React.ReactElement | null => {
    if (!isRW(props) && !isROExpandable(props)) {
      return null;
    }

    const value = ((): boolean => {
      if (isROExpandable(props)) {
        return props.expanded;
      } else if (isRW(props)) {
        return props.selected;
      }

      // Should never be reached
      return false;
    })();

    return <StateButton mode={props.mode} value={value} onStateChange={handleValueChange} />;
  }, [handleValueChange, props]);

  const addEventButton = useMemo((): React.ReactElement | null => {
    if (props.readOnly) {
      return null;
    }

    const handleOpenModal = (): void => {
      createModal.open();
      trackEvent('start-add-event', {
        streamId: stream.id,
        streamName: stream.name,
      });
    };

    return (
      <Tooltip content="Add Event" placement="top">
        <button
          className="bg-blue text-white w-8 h-8 border border-blue rounded text-white hover:bg-white hover:text-blue transition"
          onClick={handleOpenModal}
        >
          <AddIcon className="w-5 h-5 mx-auto fill-current" />
        </button>
      </Tooltip>
    );
  }, [createModal, props, trackEvent, stream.id, stream.name]);

  useEffect((): void => {
    switch (processingState.state) {
      case ProcessingStateEnum.idle:
      case ProcessingStateEnum.processing:
        break;
      case ProcessingStateEnum.error:
      case ProcessingStateEnum.fatalError:
        break;
      case ProcessingStateEnum.success:
        switch (processingState.data) {
          case TimelineAction.creatingEvent:
            createModal.close();
            break;
        }
        break;
    }
  }, [createModal, processingState, processingState.data, processingState.state]);

  return (
    <div className={containerClass}>
      <LeftPanel
        stream={stream}
        variant={props.variant}
        selectionButton={selectionButton}
        addEventButton={addEventButton}
      />

      <div className="flex-shrink min-w-0 w-full h-full p-0.5">
        {events.length === 0 && props.variant !== 'sponsored' ? (
          <EmptyView onPressCreate={!props.readOnly ? createModal.open : undefined} />
        ) : (
          <div className={itemContainerClass}>
            {/* Events that happened in the past */}
            {past.map(
              (event: StreamEvent): React.ReactElement => (
                <EventItem key={event.id} event={event} compact={props.compact} upcoming={false} />
              ),
            )}
            {/* Upcoming events */}
            {upcoming.map(
              (event: StreamEvent): React.ReactElement => (
                <EventItem key={event.id} event={event} compact={props.compact} upcoming={true} />
              ),
            )}
            {/* Events happening in the future */}
            {future.map(
              (event: StreamEvent): React.ReactElement => (
                <EventItem key={event.id} event={event} compact={props.compact} upcoming={false} />
              ),
            )}
          </div>
        )}
      </div>

      <Modal isOpen={createModal.isOpen} onClose={createModal.close}>
        <Modal.Content>
          <CreateEditEventModal stream={stream} event={null} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const baseContainerClass = 'flex my-2 rounded-md border-l-4 font-poppins text-gray text-sm';

const colors: Record<string, string> = {
  USER: 'border-yellow',
  'WATCHLIST & PORTFOLIOS': 'border-green',
  COMPANY: 'border-blue',
  INDUSTRY: 'border-blue',
  GENERAL: 'border-gray-darkest',
};

const compactLayout =
  'flex flex-wrap items-start justify-start max-h-full w-full overflow-y-auto scroller p-2.5 gap-2';
const normalLayout =
  'grid grid-cols-4 auto-rows-stream w-full h-full overflow-y-auto scroller p-2.5 gap-2';
