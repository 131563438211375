import BaseInput from 'components/input/Input2';
import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly id: string;
  readonly value: string;
  readonly name: string;
  readonly label: string;
  readonly placeholder: string;

  onFocus?(): void;
  onChange(value: string): void;
}

export const Input: React.FC<Props> = ({
  id,
  name,
  value,
  label,
  placeholder,
  onFocus,
  onChange,
}: Props): React.ReactElement => {
  const onChangeWrapper = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange],
  );

  const mangledName = useMemo((): string => name + Date.now(), [name]);

  return (
    <BaseInput
      id={id}
      name={mangledName}
      value={value}
      className="py-1.5 px-3"
      inputClassName="bg-transparent"
      label={label}
      placeholder={placeholder}
      labelClassName="left-3"
      onFocus={onFocus}
      onChange={onChangeWrapper}
    />
  );
};
