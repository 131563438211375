import { Clickable } from 'components/clickable';
import { UserAvatar } from 'components/userAvatar';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import React, { useMemo } from 'react';
import { SocialUser } from 'views/Home/types';

interface Props {
  readonly user: SocialUser;
  readonly highlighted: boolean;

  onClick(user: SocialUser): void;
}

export const UserSuggestionItem: React.FC<Props> = ({
  user,
  highlighted,
  onClick,
}: Props): React.ReactElement => {
  const containerRef = useScrollIntoView<HTMLLIElement>(highlighted);
  const containerClassName = useMemo((): string => {
    const base =
      'flex items-center justify-items-stretch gap-4 px-4 py-2 w-full cursor-pointer hover:bg-gray-50';

    return [base, highlighted ? 'bg-gray-100' : undefined].join(' ');
  }, [highlighted]);

  return (
    <Clickable clickData={user} onClick={onClick}>
      <li className={containerClassName} ref={containerRef}>
        <div className="w-8">
          <UserAvatar user={user} />
        </div>
        <div className="flex-1 overflow-hidden">
          <p className="font-poppinsMedium">{user.name}</p>
          <p className="truncate overflow-ellipsis text-gray">{user.about}</p>
        </div>
      </li>
    </Clickable>
  );
};
