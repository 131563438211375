import { ChangeCell } from 'components/changeCell';
import { CountryFlag } from 'components/countryFlag';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Company } from 'types/organization/types';
import { experimentalColumn } from 'utils';

const columns: ReadonlyArray<ColumnDefinition<Company>> = [
  {
    name: 'name',
    type: ColumnType.text,
    label: 'Company',
    weight: 1.5,
    alignment: Alignment.left,
    sortable: true,
  },
  ...experimentalColumn({
    name: 'market_cap',
    type: ColumnType.numeric,
    label: 'Market Cap\n(USD Billions)',
    weight: 1,
    alignment: Alignment.center,
    precision: 2,
    sortable: true,
  }),
  {
    name: 'ticker',
    type: ColumnType.text,
    weight: 1,
    label: 'Ticker',
    alignment: Alignment.center,
    sortable: true,
  },
  ...experimentalColumn({
    name: 'price',
    type: ColumnType.numeric,
    alignment: Alignment.center,
    weight: 1,
    label: 'Last Close Price',
    sortable: true,
    precision: 2,
  }),
  {
    name: 'price_change',
    type: ColumnType.custom,
    weight: 1,
    label: 'Last Price Change',
    headerAlignment: Alignment.center,
    sortable: true,
    render: (value: Company['price_change'] | null): React.ReactNode => {
      if (value === undefined || value === null) {
        return <div className="flex justify-center items-center pl-2.5">-</div>;
      }
      // pl-2.5 is used for properly centering the value, because the arrow takes
      // 5 units, and it pushes the text to the left by that much
      return (
        <div className="flex justify-center items-center pl-2.5">
          <ChangeCell value={value} />
        </div>
      );
    },
  },
  ...experimentalColumn({
    name: 'ebitda_margin',
    type: ColumnType.percent,
    label: 'EBITDA Margin',
    weight: 1,
    precision: 2,
    alignment: Alignment.center,
    sortable: true,
  }),
  ...experimentalColumn({
    name: 'pe_ratio',
    type: ColumnType.numeric,
    label: 'P/E Ratio',
    weight: 1,
    precision: 2,
    alignment: Alignment.center,
    sortable: true,
  }),
  ...experimentalColumn({
    name: 'fifty_two_week_price_change',
    type: ColumnType.custom,
    weight: 1,
    label: '52 Week Price Change',
    headerAlignment: Alignment.center,
    sortable: true,
    render: (value: Company['fifty_two_week_price_change'] | null): React.ReactNode => {
      if (value === undefined || value === null) {
        return <div className="flex justify-center items-center pl-2.5">-</div>;
      }
      // pl-2.5 is used for properly centering the value, because the arrow takes
      // 5 units, and it pushes the text to the left by that much
      return (
        <div className="flex justify-center items-center pl-2.5">
          <ChangeCell value={value} />
        </div>
      );
    },
  }),
  {
    name: 'country_of_domicile',
    type: ColumnType.custom,
    weight: 1.5,
    label: 'Country of Domicile',
    headerAlignment: Alignment.left,
    sortable: true,
    render: (value: Company['country_of_domicile'] | null): React.ReactNode => {
      if (!value) {
        return null;
      }

      return (
        <div className="flex w-auto items-center justify-start gap-1.5">
          <CountryFlag iso2={value.iso2} size="small" />
          {value.name ?? '-'}
        </div>
      );
    },
  },
];

export default columns;
