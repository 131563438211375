import { ReactComponent as NoCompaniesIcon } from 'assets/icons/svg/no-companies-icon.svg';
import { Breadcrumbs } from 'components/breadcrumbs';
import { CapnoteMap } from 'components/capnoteMap';
import { MarkerType } from 'components/capnoteMap/types';
import { ConditionalRender } from 'components/conditionalRenderer';
import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import EmptySection from 'components/emptySection/EmptySection';
import { MainOutletContent } from 'components/mainOutletContent';
import { TruncatedTags } from 'components/truncatedTags';
import { useCompanyGeographiesAndMaps } from 'hooks/useCompanyGeographiesAndMaps';
import { useCompanyGeographiesAndMapsMarkers } from 'hooks/useCompanyGeographiesAndMapsMarkers';
import { useSearchInputOrganization } from 'hooks/useSearchInputOrganization';
import { noop } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  CompanyRevenuesLocation,
  CompanySubsidiaryLocation,
} from 'redux/reducers/geographiesAndMapsReducer';
import { OrganizationTypes } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { OperationType } from 'types/operationType';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { DataTable } from 'views/SmartTools/GeographiesAndMaps/components/dataTable';
import { MarkerDetails } from 'views/SmartTools/GeographiesAndMaps/components/markerDetails';
import { OrganizationSearchInput } from 'views/SmartTools/ValueChains/components/OrganizationSearchInput';

export const GeographiesAndMaps: React.FC = (): React.ReactElement => {
  const [currentFilter, setCurrentFilter] = useState<string>(OperationType.subsidiaries);
  const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);

  const currentOrganization = useSearchInputOrganization('companyId');
  const clearSelectedMarker = React.useCallback((): void => {
    setSelectedMarker(null);
  }, []);
  const markers: ReadonlyArray<CompanySubsidiaryLocation | CompanyRevenuesLocation> =
    useCompanyGeographiesAndMapsMarkers(currentFilter);

  const tags = React.useMemo(
    (): readonly string[] =>
      currentOrganization?.tags ?? [
        'Footprint',
        'Geographic Exposure',
        'Sales Locations',
        'Geo-political Risk',
      ],
    [currentOrganization?.tags],
  );

  const path = useMemo((): readonly string[] => {
    const base = ['SMART TOOLS', 'MAPS'];
    if (currentOrganization) {
      const { name } = currentOrganization;
      return [...base, name.toUpperCase()];
    } else {
      return base;
    }
  }, [currentOrganization]);

  useCompanyGeographiesAndMaps(currentOrganization);

  return (
    <MainOutletContent>
      <div className="mb-6">
        <Breadcrumbs path={path} />
        <TextLine
          onExpand={noop}
          content="Select a company or an industry to visualize its geographic information"
        />
        <div className="my-2">
          <TruncatedTags tags={tags ?? []} onExpand={noop} onClick={noop} />
        </div>
      </div>

      <div className="flex-grow flex flex-col">
        <ConditionalRender renderIf={currentOrganization === null}>
          <OrganizationSearchInput
            allowOnly={OrganizationTypes.company}
            showRecentItems={!currentOrganization}
          />
          <div className="flex-grow relative mt-3">
            <EmptySection
              title="Get started by searching for a company above"
              icon={NoCompaniesIcon}
            />
          </div>
        </ConditionalRender>

        <ConditionalRender renderIf={currentOrganization !== null}>
          <div className="flex items-center justify-between mb-2">
            <DropdownFilter
              selectedId={currentFilter}
              options={options}
              onSelect={setCurrentFilter}
            />
          </div>

          <CapnoteMap markers={markers} onMarkerClick={setSelectedMarker} />
        </ConditionalRender>
      </div>

      <ConditionalRender renderIf={currentOrganization !== null}>
        <MarkerDetails marker={selectedMarker} onClose={clearSelectedMarker} />
        <DataTable filter={currentFilter} company={currentOrganization} />
        <DataDisclaimer />
      </ConditionalRender>
    </MainOutletContent>
  );
};

const options: ReadonlyArray<DropdownItem<OperationType>> = [
  { id: OperationType.subsidiaries, label: 'Show: Subsidiaries' },
  { id: OperationType.revenues, label: 'Show: Revenues' },
];
