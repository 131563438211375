import { DynamicColor, MarkerDot } from 'components/capnoteMap/components/markerDot';
import { Tooltip } from 'components/tooltip';
import { DivIcon, divIcon } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Marker } from 'react-leaflet';
import { CompaniesLocations, SubsidiariesMapCompany } from 'redux/reducers/portfolioReducer';

interface Props {
  readonly marker: CompaniesLocations<SubsidiariesMapCompany>;

  readonly active?: string | null;
  readonly colors?: Record<string, DynamicColor>;
}

export const PortfolioCompaniesSubsidiariesMarker: React.FC<Props> = ({
  marker,
  colors = {},
}: Props): React.ReactElement => {
  const [icon, setIcon] = useState<DivIcon>(new DivIcon());
  const { coordinates } = marker;

  useEffect(
    (): void =>
      setIcon(
        divIcon({
          html: createDot(marker, colors, null),
        }),
      ),
    [colors, marker],
  );

  return <Marker position={[coordinates.latitude, coordinates.longitude]} icon={icon} />;
};

const createDot = (
  marker: CompaniesLocations<SubsidiariesMapCompany>,
  colors: Record<string, DynamicColor>,
  _: /* active */ string | null,
): HTMLElement => {
  const { companies } = marker;

  const element = document.createElement('div');
  const root = createRoot(element);

  root.render(
    <div className="flex items-center justify-around flex-wrap w-12">
      {companies.map((company: SubsidiariesMapCompany): React.ReactElement => {
        const { subsidiaries } = company;

        return (
          <Tooltip
            key={company.id}
            content={
              <div>
                <h4 className="flex items-center justify-between mt-1">
                  <span>{company.name}</span>
                  {companies.length > 0 ? (
                    <>
                      <span>&ensp;</span>
                      <span className="text-xs font-poppinsSemiBold bg-blue text-white px-1 rounded">
                        {subsidiaries.length}
                      </span>
                    </>
                  ) : null}
                </h4>
                <ul className="text-left my-2">
                  {subsidiaries.map(
                    (name: string): React.ReactElement => (
                      <li key={name} className="py-1">
                        <p className="text-gray whitespace-nowrap">{name}</p>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            }
            variant="light"
            fitContent={true}
          >
            <MarkerDot size="normal" color={colors[company.id]} />
          </Tooltip>
        );
      })}
    </div>,
  );

  return element;
};
