export const textJoiner = (value?: readonly string[]): string => {
  if (!value) {
    return '-';
  }

  if (value.length === 1) {
    return value[0];
  } else if (value.length === 2) {
    return value //
      .slice()
      .reverse()
      .join(' & ');
  } else {
    const head = value.slice(0, value.length - 1);
    const last = value[value.length - 1];

    return `${head.join(', ')} & ${last}`;
  }
};
