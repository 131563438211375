import { AnyAction } from '@reduxjs/toolkit';
import {
  fetchSmartToolsRecentSearchesFailed,
  fetchSmartToolsRecentSearchesStarted,
  fetchSmartToolsRecentSearchesSucceeded,
  smartToolsSearchOrganizationsFailed,
  smartToolsSearchOrganizationsStarted,
  smartToolsSearchOrganizationsSucceeded,
} from 'redux/reducers/smartToolsRecentSearchesReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export const fetchSmartToolsRecentSearches = createAPIAction(
  function fetchSmartToolsRecentSearchesActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (): AsyncGenerator<AnyAction> {
      yield fetchSmartToolsRecentSearchesStarted();
      try {
        const response = await client.GET(`${API_V1_PATH}/organizations/recent/`);
        yield fetchSmartToolsRecentSearchesSucceeded(response.data);
      } catch (error: any) {
        yield fetchSmartToolsRecentSearchesFailed(error);
      }
    };
  },
);

export const updateSmartToolsRecentSearches = createAPIAction(
  function updateSmartToolsRecentSearchesActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (organizationId: string): AsyncGenerator<AnyAction> {
      try {
        const response = await client.POST(
          `${API_V1_PATH}/organizations/recent/${organizationId}`,
          {},
        );
        yield fetchSmartToolsRecentSearchesSucceeded(response.data);
      } catch (error: any) {
        console.warn(error);
      }
    };
  },
);

export const removeSmartToolsRecentSearches = createAPIAction(
  function updateSmartToolsRecentSearchesActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (organizationId: string): AsyncGenerator<AnyAction> {
      try {
        const response = await client.DELETE(
          `${API_V1_PATH}/organizations/recent/${organizationId}`,
          {},
        );
        yield fetchSmartToolsRecentSearchesSucceeded(response.data);
      } catch (error: any) {
        console.warn(error);
      }
    };
  },
);

export const smartToolsSearchOrganizations = createAPIAction(
  function updateSmartToolsRecentSearchesActionGenerator(client: HttpClient): ActionsGenerator {
    return async function* (keyword: string, category: string): AsyncGenerator<AnyAction> {
      yield smartToolsSearchOrganizationsStarted();
      try {
        const response = await client.GET(`${API_V1_PATH}/organizations/search/`, {
          category: category,
          keyword: keyword,
        });
        yield smartToolsSearchOrganizationsSucceeded(response.data);
      } catch (error: any) {
        yield smartToolsSearchOrganizationsFailed(error);
      }
    };
  },
);
