import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Level } from 'components/table/types';
import { Page } from 'types/page';
import { ProcessingState } from 'types/processingState';

export interface Indicator {
  readonly id: string;
  readonly group: string;
  readonly name: string;
  readonly ticker: string;
  readonly current_value: number;
  readonly current_level: Level;
  readonly moving_avg_5_years: number;
  readonly standard_deviation: number;
  readonly current_standard_deviation_from_mean: number;
  readonly price_60d: number;
  readonly move_60d: number;
  readonly std_move_60d: number;
}

export interface IndicatorsState {
  indicators: Page<Indicator>;
  processingState: ProcessingState<string>;
}

const initialState: IndicatorsState = {
  indicators: Page.empty(),
  processingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'indicators',
  initialState: initialState,
  reducers: {
    listIndicatorsStarted: (state: IndicatorsState): void => {
      state.processingState = ProcessingState.processing();
      state.indicators = Page.empty();
    },
    listIndicatorsSucceeded: (
      state: IndicatorsState,
      { payload }: PayloadAction<Page<Indicator>>,
    ): void => {
      state.processingState = ProcessingState.idle();
      state.indicators = payload;
    },
    listIndicatorsFailed: (state: IndicatorsState, { payload }: PayloadAction<string>): void => {
      state.processingState = ProcessingState.error(payload);
    },
  },
});

export const indicatorsSelector = (state: { indicators: IndicatorsState }): IndicatorsState =>
  state.indicators;

export const { listIndicatorsStarted, listIndicatorsSucceeded, listIndicatorsFailed } =
  slice.actions;

export default slice.reducer;
