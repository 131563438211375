import React, { useMemo } from 'react';

type ButtonVariant = 'flat' | 'primary' | 'secondary' | 'danger';

interface Props {
  readonly type: 'submit' | 'reset' | 'button';
  readonly variant: ButtonVariant;
  readonly label: string | React.ReactElement;
  readonly icon?: React.ReactElement;

  readonly disabled?: boolean;

  onClick?(event: React.MouseEvent): void;
}

export const Button: React.FC<Props> = ({
  type,
  variant,
  label,
  icon,
  disabled,
  onClick,
}: Props): React.ReactElement => {
  const iconProps = useMemo((): any => {
    return {
      className: 'w-3 h-3',
    };
  }, []);

  const reallyDisabled = useMemo((): boolean => {
    if (disabled) {
      return true;
    } else if (type === 'submit') {
      return false;
    }

    return !onClick;
  }, [disabled, onClick, type]);

  return (
    <button type={type} disabled={reallyDisabled} className={className[variant]} onClick={onClick}>
      <span className="flex items-center justify-center gap-2">
        {icon ? React.cloneElement(icon, iconProps) : null}
        {typeof label === 'string' ? (
          <p className="text-md font-poppinsMedium">{label}</p>
        ) : (
          <div className="text-md font-poppinsMedium">{label}</div>
        )}
      </span>
    </button>
  );
};

const baseClassName =
  'px-4 h-10 rounded min-w-button disabled:opacity-30 disabled:cursor-default disabled:pointer-events-none';

const className: { [key in ButtonVariant]: string } = {
  flat: [baseClassName, 'text-blue hover:bg-blue-100'].join(' '),
  primary: [baseClassName, 'bg-blue hover:bg-blue-600 text-white'].join(' '),
  secondary: [
    baseClassName,
    'bg-gray-200 hover:bg-gray-300 text-gray-dark hover:text-gray-darker',
  ].join(' '),
  danger: [baseClassName, 'bg-red-500 hover:bg-red-600 text-white'].join(' '),
};
