import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { followUserAction } from 'redux/actions/connectionsActions';
import { processingStateSelector } from 'redux/reducers/connectionsReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ProcessingStateEnum } from 'types/processingState';

export const FollowLink: React.FC = (): React.ReactElement | null => {
  const dispatch = useDispatch<any>();
  const state = useSelector(processingStateSelector);
  const navigate = useNavigate();

  const { userId } = useParams<{ readonly userId: string }>();

  useEffect((): void => {
    if (!userId) {
      return;
    }

    dispatch(followUserAction(userId));
  }, [dispatch, userId]);

  useEffect((): void => {
    if (state.state === ProcessingStateEnum.success) {
      navigate('/connections/overview/following');
    } else if (state.state === ProcessingStateEnum.error) {
      dispatch(showInfoModal({ type: 'error', message: errorMessageDefault }));
      navigate('/connections/overview/following');
    }
  }, [dispatch, navigate, state.state]);

  return null;
};
