import { Clickable } from 'components/clickable';
import React, { useMemo } from 'react';
import { PortfolioItemSearchResult } from 'views/Portfolios/PortfoliosModal/api';
import { ItemRenderer } from 'views/Portfolios/PortfoliosModal/itemRenderer';

interface OwnProps {
  readonly items: readonly PortfolioItemSearchResult[];
  readonly style: React.CSSProperties;
  readonly properties: any;

  onItemClicked?(item: PortfolioItemSearchResult): void;
}

type Props = React.PropsWithoutRef<OwnProps> & React.RefAttributes<HTMLDivElement>;

export const SearchResultsDropdown: React.FC<Props> = React.forwardRef(
  function SearchResultsDropdown(
    { items, properties, style, onItemClicked }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactElement {
    // NOTE: this is just because the dropdown is not a child of the search box
    const currentStyle = useMemo((): React.CSSProperties => {
      const { offsetHeight: containerHeight } = document.body;

      const computeTop = (): number => {
        const re = /translate\s*\((\d+)px,\s*(\d+)px\)/;
        if (typeof style.transform === 'string') {
          const match = re.exec(style.transform);
          if (match?.length == 3) {
            return Number(match[2]);
          }
        }

        return 0;
      };

      return { maxHeight: containerHeight - computeTop() - 8, ...style };
    }, [style]);

    return (
      <div ref={ref} className={containerClassName} style={currentStyle} {...properties}>
        <ul className="flex-1 overflow-y-auto scroller-squared">
          {items?.map(
            (item: PortfolioItemSearchResult): React.ReactElement => (
              <Clickable key={item.id} clickData={item} onClick={onItemClicked}>
                <li className="cursor-pointer hover:bg-gray-light px-8 py-3">
                  <ItemRenderer item={item} />
                </li>
              </Clickable>
            ),
          )}
        </ul>
      </div>
    );
  },
);

const containerClassName = 'flex flex-col rounded-b bg-white overflow-hidden shadow-lg z-2';
