import React from 'react';

interface Props {
  readonly seen: boolean;
}

export const ReadIndicator: React.FC<Props> = ({ seen }: Props): React.ReactElement => {
  return (
    <div className="w-8 md:w-10 h-10 flex-shrink-0 flex items-center justify-center">
      {seen ? <div className="w-2" /> : <div className="w-2 h-2 rounded bg-blue" />}
    </div>
  );
};
