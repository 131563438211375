import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { defaultOptions } from 'utils/popper';

interface Props {
  readonly none?: never;
}

type ButtonProps = React.PropsWithRef<any>;

interface ChildComponents {
  Button: React.FC<ButtonProps>;
  Content: React.FC<any>;
}

type CompleteComponent = React.FC<React.PropsWithChildren<Props>> & ChildComponents;

const Popup: React.FC<React.PropsWithChildren<Props>> & Partial<ChildComponents> = ({
  children,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorDOMElement, setAnchorDOMElement] = useState<HTMLDivElement | null>(null);
  const [popperDOMElement, setPopperDOMElement] = useState<HTMLDivElement | null>(null);

  const options = useMemo(
    (): any => defaultOptions(document.body, { withSameWidth: false, offset: [0, 10] }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchorDOMElement, popperDOMElement, options);
  const childrenArray = React.Children.toArray(children);

  const showPopup = useCallback((): void => setIsOpen(true), []);
  const hidePopup = useCallback((): void => setIsOpen(false), []);

  useEffect((): void => {
    update?.();
  }, [update]);

  if (childrenArray.length !== 2) {
    throw new Error('only 2 children allowed for popup elements');
  }

  const anchor = childrenArray[0];
  const popper = childrenArray[1];

  if (!React.isValidElement(anchor) || anchor.type !== Popup.Button) {
    throw new Error("first element must be of type `Popup.Button'");
  }

  if (!React.isValidElement(popper) || popper.type !== Popup.Content) {
    throw new Error("first element must be of type `Popup.Button'");
  }

  return (
    <>
      <div ref={setAnchorDOMElement} onClick={showPopup}>
        {anchor}
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" onClick={hidePopup}>
            <div ref={setPopperDOMElement} style={styles.popper} {...attributes.popper}>
              {popper}
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

Popup.Button = function PopupButton({ children }: React.PropsWithChildren): React.ReactElement {
  return <>{children}</>;
};

Popup.Content = function PopupContent({ children }: React.PropsWithChildren): React.ReactElement {
  return <>{children}</>;
};

export default Popup as CompleteComponent;
