import { ColumnDefinition } from 'components/table/types';
import React, { useCallback, useMemo } from 'react';

interface Props<T> {
  readonly columns: ReadonlyArray<ColumnDefinition<T>>;
  readonly boundingRectangle: DOMRect;
}

export function TableColumns<T>({ columns, boundingRectangle }: Props<T>): React.ReactElement {
  const totalWeight = useMemo(
    (): number =>
      columns.reduce((totalWeight: number, column: ColumnDefinition<T>): number => {
        if (column.width !== undefined) {
          return totalWeight;
        } else if (column.weight === undefined) {
          return totalWeight + 1;
        } else {
          return totalWeight + column.weight;
        }
      }, 0),
    [columns],
  );

  const fixedColumnsWidth = useMemo(
    (): number =>
      columns.reduce((width: number, column: ColumnDefinition<T>): number => {
        if (column.width === undefined) {
          return width;
        } else {
          return width + column.width;
        }
      }, 0),
    [columns],
  );

  const availableWidth = useMemo(
    (): number => boundingRectangle.width - fixedColumnsWidth,
    [boundingRectangle.width, fixedColumnsWidth],
  );

  const getColumnWidth = useCallback(
    (column: ColumnDefinition<T>): number => {
      if (column.width !== undefined) {
        return column.width;
      } else if (column.weight === undefined) {
        return Math.floor(availableWidth / totalWeight);
      } else {
        return Math.floor((column.weight * availableWidth) / totalWeight);
      }
    },
    [availableWidth, totalWeight],
  );

  return (
    <colgroup>
      {columns.map((column: ColumnDefinition<T>): React.ReactElement => {
        return <col key={String(column.name)} width={getColumnWidth(column)} />;
      })}
    </colgroup>
  );
}
