import { Select } from 'components/select';
import React, { useEffect } from 'react';
import {
  InvestmentResidentialType,
  PortfolioInvestmentResidentialRealStateItem,
  RealEstatePurpose,
} from 'types/portfolioItem';
import { enumToSelectItems, isEnumOf } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioInvestmentResidentialRealStateItem;
}

export const InvestmentResidentialRealEstateForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioInvestmentResidentialRealStateItem>();

  useEffect((): void => {
    if (item.purpose !== RealEstatePurpose.investmentResidential) {
      return;
    } else if (!isEnumOf(InvestmentResidentialType, item.typeOfResidence)) {
      const updateTypeOfResidence = updaterFor('typeOfResidence');
      updateTypeOfResidence(InvestmentResidentialType.singleFamily);
    }
  }, [item.purpose, item.typeOfResidence, updaterFor]);

  return (
    <>
      <Select
        items={residenceTypes}
        value={item.typeOfResidence}
        label="Select type of investment residential real estate"
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onChange={updaterFor('typeOfResidence')}
      />
    </>
  );
};

const residenceTypes = enumToSelectItems(InvestmentResidentialType, {
  [InvestmentResidentialType.singleFamily]: 'Single Family',
  [InvestmentResidentialType.multiFamily]: 'Multi Family',
  [InvestmentResidentialType.shortLetOrVacationProperty]: 'Short Let Or Vacation Property',
});
