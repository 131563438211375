import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { useFilters } from 'hooks/useFilters';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getIndustryDetails, industryDetailsSelector } from 'redux/reducers/industryReducer';
import { valueChainStateSelector } from 'redux/reducers/valueChainReducer';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';
import ValueChain from 'views/DataAnalysis/common/ValueChain';
import createFiltersConfig from 'views/DataAnalysis/common/ValueChain/Filters/config';
import { CountryDropdown } from 'views/DataAnalysis/Industry/Details/countryDropdown';

const IndustryValueChain: React.FC = (): React.ReactElement => {
  const { industryId } = useParams<{ industryId: string }>();

  const dispatch = useDispatch<any>();
  const industryDetails = useSelector(industryDetailsSelector);
  const valueChainState = useSelector(valueChainStateSelector);
  const chain = valueChainState.stack;
  const currentNode = useMemo((): ValueChainNode | undefined => {
    return chain[chain.length - 1];
  }, [chain]);

  const filtersConfig = useMemo(
    (): readonly CommonFilter[] => createFiltersConfig(currentNode),
    [currentNode],
  );

  useEffect((): void => {
    if (industryDetails || !industryId) {
      return;
    }

    dispatch(getIndustryDetails(industryId));
  }, [dispatch, industryId, industryDetails]);

  const handleFiltersChange = useFilters(filtersConfig);

  const handleClear = useCallback((): void => {
    handleFiltersChange([]);
  }, [handleFiltersChange]);

  return (
    <>
      <div className="flex items-center justify-between my-3">
        <Filters config={filtersConfig} onChange={handleFiltersChange} />
        <CountryDropdown />
      </div>
      <ValueChain
        rootNodeId={industryId}
        rootNodeLabel={industryDetails?.name}
        rootNodeType={ValueChainEntityType.industry}
        currentNode={currentNode}
        onClear={handleClear}
      />
    </>
  );
};

export default IndustryValueChain;
