import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Clickable } from 'components/clickable';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AuthProcessingStateData,
  authProcessingStateSelector,
  clearProcessingState,
} from 'redux/reducers/authReducer';
import { login, requestEmailVerification, requestUnlockAccount } from 'redux/services/authServices';
import { APIAction } from 'types/APIAction';
import { createValidationError, FormErrors, YupError } from 'utils/forms';
import { Button } from 'views/Landing/component/button';
import { Input } from 'views/Landing/component/input';
import { MessageView } from 'views/Landing/component/messageView';
import { PasswordInput } from 'views/Landing/component/passwordInput';
import { useLocationEmail } from 'views/Landing/hooks/useLocationEmail';

interface LoginFormState {
  readonly email: string;
  readonly password: string;
}

const LoginForm: React.FC = (): React.ReactElement => {
  const { trackEvent } = useGtag();
  const dispatch = useDispatch<Dispatch<APIAction | AnyAction>>();
  const locationEmail = useLocationEmail();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>(locationEmail);
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors<LoginFormState>>({});

  const processingState = useSelector(authProcessingStateSelector);

  const clearAuthProcessingState = useCallback((): void => {
    dispatch(clearProcessingState());
  }, [dispatch]);

  const handleNavigationProperly = useCallback(
    (path: string): void => {
      // WARNING: horrible, hideous things need to be done to
      //          tame this horse
      setTimeout(clearAuthProcessingState, 0);
      setTimeout((): void => {
        navigate(path);
      }, 0);
    },
    [clearAuthProcessingState, navigate],
  );

  const handleUnlockAccount = useCallback((): void => {
    dispatch(requestUnlockAccount(email));
  }, [dispatch, email]);

  const handleResendVerificationEmail = useCallback((): void => {
    dispatch(requestEmailVerification(email));
  }, [dispatch, email]);

  const handleMessageViewAction = useCallback((): void => {
    switch (processingState.data) {
      case AuthProcessingStateData.tooManyWrongPasswordAttempts:
      case AuthProcessingStateData.requestUnlockAccount:
        handleUnlockAccount();
        break;
      case AuthProcessingStateData.userNotVerified:
      case AuthProcessingStateData.requestEmailVerification:
      case AuthProcessingStateData.registrationSucceeded:
        handleResendVerificationEmail();
        break;
    }
  }, [handleResendVerificationEmail, handleUnlockAccount, processingState.data]);

  const submitForm = useCallback((): void => {
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    if (trimmedEmail !== '' && trimmedPassword !== '') {
      // Clear errors right now
      setErrors({});
      dispatch(login(trimmedEmail, trimmedPassword));
    } else {
      const getErrorMessage = (message: string): YupError | undefined =>
        message ? undefined : createValidationError('Field is required', 'password');
      setErrors({ email: getErrorMessage(trimmedEmail), password: getErrorMessage(password) });
    }
  }, [dispatch, email, password]);

  const handleFormSubmit = useCallback(
    (event: React.FormEvent): void => {
      event.preventDefault();
      submitForm();
      trackEvent('login', {
        method: 'email',
        info: {
          email,
        },
      });
    },
    [submitForm, trackEvent, email],
  );

  return (
    <form className="flex flex-col gap-5 w-full" onSubmit={handleFormSubmit}>
      <div className="my-5">
        <MessageView
          state={processingState.data}
          onClear={clearAuthProcessingState}
          onAction={handleMessageViewAction}
        />
      </div>

      <Input
        id="login-email"
        value={email}
        onChange={setEmail}
        label="Email Address"
        error={errors.email}
      />
      <PasswordInput
        id="login-password"
        value={password}
        error={errors.password}
        create={false}
        withHelp={false}
        onChange={setPassword}
      />

      <Button id="login-button" label="Login" />
      <div className="text-center mt-1">
        <div className="my-4">
          <Clickable clickData="/forgot-password" onClick={handleNavigationProperly}>
            <a className={linkClassName}>Forgot Password?</a>
          </Clickable>
        </div>
        <div className="text-sm font-poppins text-gray my-4">
          New to Capnote?{' '}
          <Clickable clickData="/sign-up" onClick={handleNavigationProperly}>
            <a className={linkClassName}>Sign Up</a>
          </Clickable>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;

const linkClassName = 'text-sm text-blue font-poppinsSemiBold normal-case';
