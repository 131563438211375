import { format, isToday, parse, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Time } from 'types/time';

const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export interface EventDateTime {
  readonly startDate: string;
  readonly startTime: string | null;

  readonly endDate: string | null;
  readonly endTime: string | null;

  readonly isWholeDay: boolean;

  readonly timezone: string;
}

export interface ReminderValue {
  id: string | number;
  label: string;
}

export const isTimeAfter = (start: Time | null, end: Time | null): boolean => {
  if (start === null || end === null) return false;
  if (start.hours === end.hours) return start.minutes > end.minutes;

  return start.hours > end.hours;
};

export const mustParseDate = (
  dateString: string | null,
  timeString: string | null,
  timezone: string,
): Date => {
  if (dateString === null) {
    throw new Error('unexpected null date string');
  }

  if (timeString !== null) {
    return zonedTimeToUtc(parse(`${dateString} ${timeString}`, 'MM/dd/yyyy HH:mm:ss', 0), timezone);
  }

  return zonedTimeToUtc(parse(dateString, 'MM/dd/yyyy', 0), LOCAL_TIMEZONE);
};

export function parseDate(dateString: string, timeString: string | null, timezone: string): Date;
export function parseDate(dateString: null, timeString: string | null, timezone: string): null;
export function parseDate(
  dateString: string | null,
  timeString: string | null,
  timezone: string,
): Date | null;

export function parseDate(
  dateString: string | null,
  timeString: string | null,
  timezone: string,
): Date | null {
  if (dateString === null) {
    return null;
  }

  return mustParseDate(dateString, timeString, timezone);
}

export const serializeDate = (date: Date | null): string | null => {
  if (date === null) {
    return null;
  }

  return format(date, 'yyyy-MM-dd');
};

export const parseTime = (
  dateString: string,
  timeString: string | null,
  timezone: string,
): Time | null => {
  if (timeString === null) {
    return null;
  } else if (dateString === null) {
    const matched = timeString.match(/(\d{2}):(\d{2}):(\d{2})/);
    if (matched === null) {
      throw new Error('time strings must be in format: HH:mm:ss');
    }

    return {
      hours: Number(matched[1]),
      minutes: Number(matched[2]),
      seconds: Number(matched[3]),
    };
  } else {
    const date = mustParseDate(dateString, timeString, timezone);

    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    };
  }
};

export function fromStringToFormattedDate(stringValue: string | null | undefined): string | null {
  if (!stringValue) {
    return '-';
  }

  try {
    const date: Date = parseISO(stringValue);
    if (isToday(date)) {
      return format(date, 'dd MMM yy, hh:mm a');
    } else {
      return format(date, 'dd MMM yy');
    }
  } catch {
    return '';
  }
}
