import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

export const Pin: React.FC = (): React.ReactElement => {
  return (
    <div className="absolute -top-1.5 -right-1.5">
      <SVGIcon name="upcoming-events-icon" className="w-4 h-4" />
    </div>
  );
};
