import DefaultAvatar from 'assets/images/genderless-icon.svg';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  markAsSeenAction,
  notificationSelector,
  singleNotificationLoadingSelector,
} from 'redux/reducers/notificationsReducer';
import { getNotification } from 'redux/services/notificationsService';

interface Props {
  readonly id: string | null;
}

const NotificationDetails: React.FC<Props> = ({ id }: Props): React.ReactElement | null => {
  const dispatch = useDispatch<any>();
  const notification = useSelector(notificationSelector);
  const loading = useSelector(singleNotificationLoadingSelector);

  const source = useMemo((): string => {
    if (notification.source === 'User') {
      return 'capnote.com';
    } else {
      return notification.source;
    }
  }, [notification.source]);

  useEffect((): void => {
    if (id === null) {
      return;
    }
    dispatch(getNotification(id));
    dispatch(markAsSeenAction({ notification_ids: [id] }));
  }, [dispatch, id]);

  return (
    <div className="relative flex flex-col flex-start w-full md:w-modal-sm">
      <div className="flex flex-row mb-9 items-center">
        <img
          alt="Profile pic"
          src={notification.photo ?? DefaultAvatar}
          className="w-11 h-11 mr-2"
        />
        <div className={`${regularClass}`}>From {source}</div>
      </div>
      <div className={`${regularClass} mb-6`}>{notification.date}</div>
      <div className="flex flex-col mb-6">
        <h4 className="text-gray-darkest">Description</h4>
        <p className="text-gray text-base leading-5 font-poppins">{notification.description}</p>
      </div>

      <div className="flex justify-end p-1">
        {notification.url && (
          <Link to={notification.url}>
            <h3 className="text-blue text-base uppercase">GO TO </h3>
          </Link>
        )}
      </div>

      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default NotificationDetails;

const regularClass = 'leading-5 font-poppins text-base';
