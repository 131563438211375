import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import { Modal } from 'components/modal';
import { PostBody } from 'components/postBodyInput/types';
import { parseISO } from 'date-fns';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { User, userSelector } from 'redux/reducers/authReducer';
import { FeedComment } from 'redux/reducers/feedDetailsReducer';
import { ReportableType } from 'redux/reducers/reportsReducer';
import {
  deleteFeedComment,
  dislikeFeedComment,
  getFeedCommentReplies,
  likeFeedComment,
  postReply,
} from 'redux/services/feedDetailsService';
import config from 'utils/config/appSettings';
import classes from 'views/Home/modals/PostDetailsModal/components/cardContent.module.scss';
import { CommentCardActionButtons } from 'views/Home/modals/PostDetailsModal/components/commentCardActionButtons';
import { CommentCardHeader } from 'views/Home/modals/PostDetailsModal/components/commentCardHeader';
import { CommentContentView } from 'views/Home/modals/PostDetailsModal/components/commentContentView';
import { CommentFlag } from 'views/Home/modals/PostDetailsModal/components/commentFlag';
import { CommentsList } from 'views/Home/modals/PostDetailsModal/components/commentsList';
import { CurrentUserInput } from 'views/Home/modals/PostDetailsModal/components/currentUserInput';
import {
  CommentCardEditMode,
  CommentCardModal,
  CommentCardState,
  initialState,
} from 'views/Home/modals/PostDetailsModal/reducers/commentCardReducer';
import { emit, EventType, listenTo } from 'views/Home/modals/PostDetailsModal/utils/events';
import { ReportModal } from 'views/Home/modals/ReportModal';
import {
  DELETE_COMMENT_QUESTION,
  DELETE_COMMENT_TITLE,
  FLAGGED_COMMENT_LINK,
  FLAGGED_COMMENT_TEXT,
  WRITE_A_REPLY,
} from 'views/Home/strings';

interface Props {
  readonly comment: FeedComment;
  readonly parentId: string;
  readonly depth: number;

  onDelete(commentId: string): void;
}

export const CommentCard: React.FC<Props> = ({
  comment: originalComment,
  parentId,
  depth,

  onDelete,
}: Props): React.ReactElement => {
  const user = useSelector(userSelector);

  const [repliesCount, setRepliesCount] = useState<number>(originalComment.repliesCount);
  const [replies, setReplies] = useState<readonly FeedComment[] | null>(null);
  const [state, setState] = useState<CommentCardState>(initialState);
  const [scrollToElement, setScrollToElement] = useState<HTMLDivElement | null>(null);
  const [comment, setComment] = useState<FeedComment>(originalComment);

  useEffect((): void => {
    setComment(originalComment);
  }, [originalComment]);

  const handleToggleEditing = useCallback((): void => {
    if (state.editMode === CommentCardEditMode.editing) {
      setState(
        (state: CommentCardState): CommentCardState => ({
          ...state,
          inputInitialValue: PostBody.empty(),
          editMode: CommentCardEditMode.none,
        }),
      );
    } else {
      setState(
        (state: CommentCardState): CommentCardState => ({
          ...state,
          editMode: CommentCardEditMode.editing,
        }),
      );
    }
  }, [state.editMode]);

  const handleToggleWritingReply = useCallback((): void => {
    if (state.editMode === CommentCardEditMode.replying) {
      setState(
        (state: CommentCardState): CommentCardState => ({
          ...state,
          inputInitialValue: PostBody.empty(),
          editMode: CommentCardEditMode.none,
        }),
      );
    } else {
      setState(
        (state: CommentCardState): CommentCardState => ({
          ...state,
          editMode: CommentCardEditMode.replying,
        }),
      );
    }
  }, [state.editMode]);

  const handleReply = useCallback(
    async (body: PostBody): Promise<void> => {
      const reply = await postReply(comment.id, body);

      setReplies((replies: readonly FeedComment[] | null): readonly FeedComment[] =>
        replies ? [...replies, reply] : [reply],
      );
      setRepliesCount(repliesCount + 1);
      emit(parentId, EventType.added);
    },
    [comment.id, parentId, repliesCount],
  );

  useEffect((): VoidFunction => {
    const handleRemoved = (event: Event): void => {
      const customEvent = event as CustomEvent<number>;
      setRepliesCount((count: number): number => count - customEvent.detail);
    };

    return listenTo(comment.id, parentId, EventType.removed, handleRemoved);
  }, [comment.id, parentId]);

  useEffect((): VoidFunction => {
    const handleAdded = (): void => {
      setRepliesCount((currentRepliesCount: number): number => currentRepliesCount + 1);
    };

    return listenTo(comment.id, parentId, EventType.added, handleAdded);
  }, [comment.id, parentId]);

  const handleEdit = useCallback(async (body: PostBody): Promise<void> => {
    // try {
    //   const newComment = await editFeedComment(comment.id, body);
    //   onEdit(newComment);
    // } catch (error) {
    //   console.warn(error);
    // }
    console.warn(body);
  }, []);

  const handleDelete = useCallback(async (): Promise<void> => {
    onDelete(comment.id);
    // await deleteFeedComment(comment.id);
    // Emit the event to the parent
    emit(parentId, EventType.removed, repliesCount + 1);
  }, [comment.id, onDelete, parentId, repliesCount]);

  const handleSubmitInput = useCallback(
    async (body: PostBody): Promise<void> => {
      switch (state.editMode) {
        case CommentCardEditMode.none:
          break;
        case CommentCardEditMode.replying:
          await handleReply(body);
          break;
        case CommentCardEditMode.editing:
          await handleEdit(body);
          break;
      }
      setState(
        (state: CommentCardState): CommentCardState => ({
          ...state,
          inputInitialValue: PostBody.empty(),
          editMode: CommentCardEditMode.none,
        }),
      );
    },
    [handleEdit, handleReply, state.editMode],
  );

  const handleLike = useCallback(async (): Promise<void> => {
    if (!comment.liked) {
      await likeFeedComment(comment.id);

      setComment(
        (comment: FeedComment): FeedComment => ({
          ...comment,
          disliked: false,
          dislikesCount: comment.disliked ? comment.dislikesCount - 1 : comment.dislikesCount,
          liked: true,
          likesCount: comment.likesCount + 1,
        }),
      );
    }
  }, [comment.id, comment.liked]);

  const handleDislike = useCallback(async (): Promise<void> => {
    if (!comment.disliked) {
      await dislikeFeedComment(comment.id);

      setComment(
        (comment: FeedComment): FeedComment => ({
          ...comment,
          liked: false,
          likesCount: comment.liked ? comment.likesCount - 1 : comment.likesCount,
          disliked: true,
          dislikesCount: comment.dislikesCount + 1,
        }),
      );
    }
  }, [comment.disliked, comment.id]);

  const handleShowReplies = useCallback(async (): Promise<void> => {
    setReplies(await getFeedCommentReplies(comment.id));
  }, [comment.id]);

  const handleAbortReporting = useCallback((): void => {
    setState(
      (state: CommentCardState): CommentCardState => ({
        ...state,
        modal: CommentCardModal.none,
      }),
    );
  }, []);

  const handleStartReporting = useCallback((): void => {
    setState(
      (state: CommentCardState): CommentCardState => ({
        ...state,
        modal: CommentCardModal.report,
      }),
    );
  }, []);

  const handleAbortDeleting = useCallback((): void => {
    setState(
      (state: CommentCardState): CommentCardState => ({
        ...state,
        modal: CommentCardModal.none,
      }),
    );
  }, []);

  const handleStartDeleting = useCallback((): void => {
    setState(
      (state: CommentCardState): CommentCardState => ({
        ...state,
        modal: CommentCardModal.delete,
      }),
    );
  }, []);

  const handleCommentReported = useCallback((): void => {
    setState(
      (state: CommentCardState): CommentCardState => ({
        ...state,
        reported: true,
        modal: CommentCardModal.none,
      }),
    );
  }, []);

  const createdAt = useMemo((): Date => {
    return parseISO(comment.createdAt);
  }, [comment.createdAt]);

  const editedAt = useMemo(
    (): Date | null => parseISOWithNull(comment.editedAt),
    [comment.editedAt],
  );

  useEffect((): void => {
    setState(
      (state: CommentCardState): CommentCardState => ({
        ...state,
        editable: isEditable(comment, user),
      }),
    );
  }, [comment, createdAt, editedAt, user]);

  useLayoutEffect((): void => {
    if (scrollToElement === null) {
      return;
    }

    scrollToElement.scrollIntoView({ behavior: 'smooth' });
  }, [scrollToElement]);

  useEffect((): VoidFunction | void => {
    if (comment.flagged) {
      return;
    }

    const timer = setTimeout((): void => {
      setState(
        (state: CommentCardState): CommentCardState => ({
          ...state,
          editable: false,
        }),
      );
    }, comment.timeLeftToEdit);

    return (): void => {
      clearTimeout(timer);
    };
  }, [comment.flagged, comment.timeLeftToEdit]);

  const inputPlaceholder = useMemo(
    (): string => (state.editMode === CommentCardEditMode.editing ? '' : WRITE_A_REPLY),
    [state.editMode],
  );

  const cardClassName = useMemo((): string => {
    const base = 'pb-2';
    if (depth >= 1) {
      return [base, `ml-${4 + 8 * depth}`, 'mr-4'].join(' ');
    } else {
      return [base, 'mx-4'].join(' ');
    }
  }, [depth]);

  const handleChildDelete = useCallback(async (commentId: string): Promise<void> => {
    await deleteFeedComment(commentId);

    setReplies((replies: readonly FeedComment[] | null): readonly FeedComment[] | null => {
      if (replies === null) {
        return null;
      }

      return replies.filter((reply: FeedComment): boolean => reply.id !== commentId);
    });
  }, []);

  return (
    <>
      <div className="relative font-poppins">
        {comment.flagged ? <CommentFlag /> : <div className="h-4" />}
        <div className={cardClassName}>
          <div className={classes.cardContent}>
            <CommentCardHeader commenter={comment.commenter} date={createdAt} />
            <span className={comment.body === null ? 'text-gray-medium italic' : ''}>
              {comment.reported ? (
                <i>
                  {FLAGGED_COMMENT_TEXT}{' '}
                  <a
                    href="/terms-and-conditions"
                    target="_blank"
                    className="normal-case text-blue"
                    rel="noreferrer"
                  >
                    {FLAGGED_COMMENT_LINK}
                  </a>
                </i>
              ) : comment.body ? (
                <CommentContentView body={comment.body} />
              ) : (
                'Comment & User Deleted'
              )}
            </span>
            <ConditionalRender renderIf={editedAt !== null}>
              <span className="text-xs text-gray-medium ml-auto"> (edited)</span>
            </ConditionalRender>
          </div>
          <div className="px-3">
            <CommentCardActionButtons
              comment={comment}
              editable={false /* FIXME: make editable in the future again */}
              repliesCount={repliesCount}
              onLike={handleLike}
              onDislike={handleDislike}
              onShowReplies={handleShowReplies}
              onEdit={handleToggleEditing}
              onReply={handleToggleWritingReply}
              onDelete={handleStartDeleting}
              onReport={handleStartReporting}
            />
            <ConditionalRender renderIf={state.editMode !== CommentCardEditMode.none}>
              <div className="my-4" />
              <CurrentUserInput
                placeholder={inputPlaceholder}
                submitLabel={editSubmitLabel[state.editMode]}
                initialValue={state.inputInitialValue}
                maxLength={config.maxCommentLength}
                onSubmit={handleSubmitInput}
              />
              <div ref={setScrollToElement} />
            </ConditionalRender>
          </div>
        </div>
        <CommentsList
          comments={replies}
          parentId={comment.id}
          depth={depth + 1}
          fetching={false}
          onDelete={handleChildDelete}
        />
      </div>
      <Modal isOpen={state.modal === CommentCardModal.delete} onClose={handleAbortDeleting}>
        <Modal.Content title={DELETE_COMMENT_TITLE}>
          <ConfirmationBox
            message={DELETE_COMMENT_QUESTION}
            onYes={handleDelete}
            onNo={handleAbortDeleting}
          />
        </Modal.Content>
      </Modal>
      <Modal isOpen={state.modal === CommentCardModal.share} onClose={handleAbortReporting}></Modal>
      <Modal isOpen={state.modal === CommentCardModal.report} onClose={handleAbortReporting}>
        <ReportModal
          reportableId={comment.id}
          reportableType={ReportableType.Comments}
          onReport={handleCommentReported}
          onClose={handleAbortReporting}
        />
      </Modal>
    </>
  );
};

const editSubmitLabel: { [key in CommentCardEditMode]: string } = {
  [CommentCardEditMode.none]: '',
  [CommentCardEditMode.editing]: 'Submit',
  [CommentCardEditMode.replying]: 'Reply',
};

const isEditable = (comment: FeedComment, potentialEditor: User): boolean => {
  const { commenter, timeLeftToEdit } = comment;
  return !comment.reported && potentialEditor.id === commenter.id && timeLeftToEdit > 0;
};

const parseISOWithNull = (value: string | null): Date | null => {
  if (value === null) {
    return null;
  }

  return parseISO(value);
};
