import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userSelector } from 'redux/reducers/authReducer';
import { stopPropagation } from 'utils/ignoreEvent';
import { ListingOwner } from 'views/EarnAndBuy/api';

interface Props {
  readonly owner: ListingOwner;
}

export const ListingOwnerLink: React.FC<Props> = ({ owner }: Props): React.ReactElement => {
  const user = useSelector(userSelector);

  if (user.id === '' || user.id === owner.id) {
    return <p className="block w-full text-center font-poppins">{owner.name}</p>;
  }

  return (
    <div onClick={stopPropagation}>
      <Link className="block w-full text-center font-poppins text-blue" to={`/users/${owner.id}`}>
        {owner.name}
      </Link>
    </div>
  );
};
