import { Clickable } from 'components/clickable';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { defaultOptions } from 'utils/popper';
import { ListingStatus } from 'views/EarnAndBuy/api';

interface Props {
  readonly status?: ListingStatus;
  readonly editable?: boolean;
  readonly variant?: 'flat' | 'contained';

  onChange?(status: ListingStatus): void;
}

export const StatusIndicator: React.FC<Omit<Props, 'editable' | 'onChange'>> = ({
  status,
  variant = 'contained',
}: Omit<Props, 'editable' | 'onChange'>): React.ReactElement => {
  switch (status) {
    case ListingStatus.workInProgress:
      return (
        <div className="inline-block">
          <div className={containerClassName('yellow-150', 'orange', variant)}>
            <SVGIcon name="info-gray" className="h-4 fill-current" />
            <p className="font-jostSemiBold text-sm uppercase">In Progress</p>
          </div>
        </div>
      );
    case ListingStatus.completed:
      return (
        <div className="inline-block">
          <div className={containerClassName('green-200', 'green-700', variant)}>
            <SVGIcon name="check-circle" className="h-4 fill-current" />
            <p className="font-jostSemiBold text-sm uppercase">Completed</p>
          </div>
        </div>
      );
    case ListingStatus.closed:
      return (
        <div className="inline-block">
          <div className={containerClassName('red-200', 'red-600', variant)}>
            <SVGIcon name="exclamation-circle" className="h-4 fill-current" />
            <p className="font-jostSemiBold text-sm uppercase">Closed</p>
          </div>
        </div>
      );
    case ListingStatus.open:
      return (
        <div className="inline-block">
          <div className={containerClassName('blue-200', 'blue', variant)}>
            <SVGIcon name="check-circle" className="h-4 fill-current" />
            <p className="font-jostSemiBold text-sm uppercase">Open</p>
          </div>
        </div>
      );
    case ListingStatus.applied:
      return (
        <div className="inline-block">
          <div className={containerClassName('gray-200', 'gray-500', variant)}>
            <SVGIcon name="clock" className="h-4 fill-current" />
            <p className="font-jostSemiBold text-sm uppercase">Applied</p>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export const Status: React.FC<Props> = ({
  status,
  editable = false,
  variant = 'contained',

  onChange,
}: Props): React.ReactElement => {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [popup, setPopup] = React.useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const popperOptions = useMemo(
    (): any => defaultOptions(document.body, { withSameWidth: true, offset: [0, 4] }),
    [],
  );

  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  const toggleOpen = useCallback((): void => {
    setIsOpen((isOpen: boolean): boolean => !isOpen);
  }, []);

  const handleClick = useCallback(
    (newStatus: ListingStatus): void => {
      setTimeout(toggleOpen, 0);
      if (status === newStatus) {
        return;
      }

      onChange?.(newStatus);
    },
    [onChange, status, toggleOpen],
  );

  useLayoutEffect((): void => {
    update?.();
  }, [update]);

  if (!editable) {
    return <StatusIndicator status={status} variant={variant} />;
  } else {
    return (
      <>
        <button
          ref={setAnchor}
          className="flex items-center justify-between w-44 hover:bg-gray-300 bg-gray-200 rounded-full pr-3 cursor-pointer"
          onClick={toggleOpen}
        >
          <StatusIndicator status={status} variant="flat" />
          <SVGIcon name="down-arrow" className="h-3 fill-current" />
        </button>

        {isOpen &&
          ReactDOM.createPortal(
            <div className="fixed z-1 inset-0" onClick={toggleOpen}>
              <div ref={setPopup} style={styles.popper} {...attributes}>
                <ul className="-mx-1 bg-white rounded shadow-sm overflow-hidden">
                  {Object.values(ListingStatus)
                    .filter((status: ListingStatus): boolean => status !== ListingStatus.applied)
                    .map(
                      (status: ListingStatus): React.ReactElement => (
                        <Clickable key={status} clickData={status} onClick={handleClick}>
                          <li className="hover:bg-gray-100 cursor-pointer p-1">
                            <StatusIndicator status={status} variant="flat" />
                          </li>
                        </Clickable>
                      ),
                    )}
                </ul>
              </div>
            </div>,
            document.body,
          )}
      </>
    );
  }
};

const containerClassName = (
  backgroundColor: string,
  textColor: string,
  variant: 'flat' | 'contained',
): string => {
  if (variant === 'flat') {
    return `flex items-center gap-2 pl-2 px-3 h-7 text-${textColor} rounded-full`;
  }

  return `flex items-center gap-2 pl-2 px-3 h-7 bg-${backgroundColor} text-${textColor} rounded-full`;
};
