import Button from 'components/buttons/Button/Button';
import SpinnerLoader from 'components/spinnerLoader';
import React from 'react';

interface Props {
  text?: string;
  subText?: string;
  acceptText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onAccept?: () => void;
  loading?: boolean;
  items?: string[];
  itemType?: string;
}

const DeleteModal: React.FC<Props> = ({
  text,
  acceptText = 'Yes',
  cancelText = 'No',
  onCancel,
  onAccept,
  loading,
  items,
  itemType = '',
  subText,
}) => {
  return (
    <div className="w-full md:w-modal-sm outline-debug">
      <span className="text-gray font-poppins text-base leading-none">{text}</span>

      {items && (
        <>
          <div className="max-h-60 my-6 pl-4 scroller overflow-auto">
            <ul className="list-none">
              {items.map((item, index) => (
                <li
                  key={item + index}
                  className="text-blue font-poppinsSemiBold text-base truncate"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <p className="text-gray font-poppins text-base mb-10">
            Would you like to permanently delete these {itemType}?
          </p>
        </>
      )}
      <div className="flex flex-row space-x-6 mt-6 justify-end items-center">
        {subText && (
          <p className="text-red-500 text-base font-poppinsSemiBold my-2.5 mr-auto">{subText}</p>
        )}
        <a
          id="cancel-delete"
          onClick={onCancel}
          className="upper-case text-blue font-poppinsSemiBold text-base"
        >
          {cancelText}
        </a>
        <Button
          id="confirm-delete-button"
          lettercase="normal"
          rounded="rounded-md"
          onClick={onAccept}
          label={acceptText}
          width="w-max"
        />
      </div>
      <SpinnerLoader visible={!!loading} />
    </div>
  );
};
export default DeleteModal;
