import { Clickable } from 'components/clickable';
import { SelectItemProps } from 'components/select';
import React from 'react';
import { formatToTrainCase } from 'utils';

type Props<T> = SelectItemProps<T>;

export function DefaultItem<T>({ label, onClick, value, disabled }: Props<T>): React.ReactElement {
  return (
    <Clickable key={String(value)} clickData={value} disabled={disabled} onClick={onClick}>
      <div
        id={`option-${formatToTrainCase(label ?? '')}`}
        className="w-full py-2.5 px-2 text-gray capitalize cursor-pointer hover:bg-gray-light text-sm"
      >
        {label}
      </div>
    </Clickable>
  );
}
