import { TableRow } from 'components/peerComparisonsView/components/tableRow';
import { useSummaryGroups } from 'components/peerComparisonsView/hooks/useSummaryGroups';
import React, { CSSProperties } from 'react';
import {
  PeerComparisonsColumn,
  PeerComparisonsColumnGroup,
  PeerComparisonsSummary,
} from 'types/peerComparisons';

interface Props {
  readonly summary: PeerComparisonsSummary;
  readonly columnGroups: readonly PeerComparisonsColumnGroup[];
  readonly gridStyle: CSSProperties;

  valueFormatter(rawValue: number, column: PeerComparisonsColumn): string;
  extractHeader(_: number, column: PeerComparisonsColumn): React.ReactElement;
}

export const Summary: React.FC<Props> = ({
  columnGroups,
  gridStyle,
  valueFormatter,
  extractHeader,
  summary,
}: Props): React.ReactElement => {
  const summaryGroups = useSummaryGroups(summary);

  return (
    <div
      className="w-min border-t border-divider bg-white sticky bottom-0"
      id="summary-statistics"
      style={gridStyle}
    >
      {/* Summary Heading Element */}
      <TableRow
        data={{}}
        columnGroups={columnGroups}
        cellTitle={<h4 className="text-gray-darkest py-2 text-xs">Summary Statistics</h4>}
        extractValue={extractHeader}
      />

      {/* Summary Table */}
      {summaryGroups.map(
        (group: any): React.ReactElement => (
          <TableRow
            key={group.name}
            data={group.data}
            columnGroups={columnGroups}
            cellTitle={
              <div className="flex items-center h-full px-2 border-b border-divider">
                <h5 className="normal-case">{group.name}</h5>
              </div>
            }
            extractValue={valueFormatter}
          />
        ),
      )}
    </div>
  );
};
