import { ReactComponent as Radio } from 'assets/graphics/svg/radio-button.svg';
import { ReactComponent as RadioChecked } from 'assets/graphics/svg/radio-button-checked.svg';
import React, { useCallback } from 'react';

interface Props {
  readonly id: string;
  readonly label: string;
  readonly value: string | number | boolean;
  readonly checked: boolean;

  onChange(value: string | number | boolean): void;
}

export const RadioButton: React.FC<Props> = ({
  id,
  label,
  value,
  checked,
  onChange,
}: Props): React.ReactElement => {
  const handleClick = useCallback((): void => {
    onChange(value);
  }, [value, onChange]);

  return (
    <div id={id} className={containerClass} onClick={handleClick}>
      {checked ? (
        <div className="text-blue">
          <RadioChecked className="w-5 h-5 fill-current stroke-current" />
        </div>
      ) : (
        <div className="text-gray">
          <Radio className="w-5 h-5 stroke-current" />
        </div>
      )}
      <div>{label}</div>
    </div>
  );
};

const containerClass = 'flex items-center gap-2 font-poppins text-sm text-gray mb-4 cursor-pointer';
