import { ReactComponent as AddIcon } from 'assets/icons/svg/add-icon.svg';
import { ReactComponent as AddIconThick } from 'assets/icons/svg/add-icon-thick.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/svg/arrow-up-icon.svg';
import { ReactComponent as BasicDownArrow } from 'assets/icons/svg/basic-down-arrow.svg';
import { ReactComponent as BuildingIconWhite } from 'assets/icons/svg/building-icon-white.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/svg/calendar-icon.svg';
import { ReactComponent as Camera } from 'assets/icons/svg/camera.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/svg/check-circle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/svg/check-icon.svg';
import { ReactComponent as CheckIconGeneric } from 'assets/icons/svg/check-icon-generic.svg';
import { ReactComponent as Clock } from 'assets/icons/svg/clock.svg';
import { ReactComponent as ClosePopupIcon } from 'assets/icons/svg/close-popup-icon.svg';
import { ReactComponent as CloseSurveysIcon } from 'assets/icons/svg/close-surveys-icon.svg';
import { ReactComponent as CommentOffIcon } from 'assets/icons/svg/comment-off-icon.svg';
import { ReactComponent as CommentOnIcon } from 'assets/icons/svg/comment-on-icon.svg';
import { ReactComponent as Copy } from 'assets/icons/svg/copy.svg';
import { ReactComponent as CreatePostIcon } from 'assets/icons/svg/create-post-icon.svg';
import { ReactComponent as DarkSearchIcon } from 'assets/icons/svg/dark-search-icon.svg';
import { ReactComponent as DecreaseIcon } from 'assets/icons/svg/decrease-icon.svg';
import { ReactComponent as DecreasingIcon } from 'assets/icons/svg/decreasing-icon.svg';
import { ReactComponent as DeleteBlueIcon } from 'assets/icons/svg/delete-blue-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/svg/delete-icon.svg';
import { ReactComponent as DeletePaperbinIcon } from 'assets/icons/svg/delete-paperbin-icon.svg';
import { ReactComponent as DeleteStreamIcon } from 'assets/icons/svg/delete-stream-icon.svg';
import { ReactComponent as DirectMessageIcon } from 'assets/icons/svg/direct-message-icon.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/svg/dislike-icon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/svg/down-arrow.svg';
import { ReactComponent as DownArrowGeneric } from 'assets/icons/svg/down-arrow-generic.svg';
import { ReactComponent as DownDecreasingIcon } from 'assets/icons/svg/down-decreasing-icon.svg';
import { ReactComponent as Download } from 'assets/icons/svg/download.svg';
import { ReactComponent as EditIcon } from 'assets/icons/svg/edit-icon.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/svg/envelope-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/svg/error-icon.svg';
import { ReactComponent as ExceedAccess } from 'assets/icons/svg/exceedAccess.svg';
import { ReactComponent as ExclamationCircle } from 'assets/icons/svg/exclamation-circle.svg';
import { ReactComponent as ExpandButtonIcon } from 'assets/icons/svg/expand-button-icon.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/svg/export-icon.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/svg/facebook-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/svg/filter-icon.svg';
import { ReactComponent as FinvarChoiceIcon } from 'assets/icons/svg/finvar-choice-icon.svg';
import { ReactComponent as FinvarNotificationIcon } from 'assets/icons/svg/finvar-notification-icon.svg';
import { ReactComponent as Flagged } from 'assets/icons/svg/flagged.svg';
import { ReactComponent as GrayLocationIcon } from 'assets/icons/svg/gray-location-icon.svg';
import { ReactComponent as GreenArrowDownIcon } from 'assets/icons/svg/green-arrow-down-icon.svg';
import { ReactComponent as GreenArrowUpIcon } from 'assets/icons/svg/green-arrow-up-icon.svg';
import { ReactComponent as GreenDoubleArrowDownIcon } from 'assets/icons/svg/green-double-arrow-down-icon.svg';
import { ReactComponent as GreenDoubleArrowUpIcon } from 'assets/icons/svg/green-double-arrow-up-icon.svg';
import { ReactComponent as HqIcon } from 'assets/icons/svg/hq-icon.svg';
import { ReactComponent as IncreaseIcon } from 'assets/icons/svg/increase-icon.svg';
import { ReactComponent as IncreasingIcon } from 'assets/icons/svg/increasing-icon.svg';
import { ReactComponent as IndividualsPlanIcon } from 'assets/icons/svg/individuals-plan-icon.svg';
import { ReactComponent as InfoGray } from 'assets/icons/svg/info-gray.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/svg/info-icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/svg/instagram-icon.svg';
import { ReactComponent as InviteIcon } from 'assets/icons/svg/invite-icon.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/svg/like-icon.svg';
import { ReactComponent as LinkResearchIcon } from 'assets/icons/svg/link-research-icon.svg';
import { ReactComponent as LinkedButton_icon } from 'assets/icons/svg/linked-button_icon.svg';
import { ReactComponent as LinkedIcon } from 'assets/icons/svg/linked-icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/svg/linkedin-icon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/svg/location-icon.svg';
import { ReactComponent as Lock } from 'assets/icons/svg/lock.svg';
import { ReactComponent as LogoDark } from 'assets/icons/svg/Logo-dark.svg';
import { ReactComponent as LogoFooter } from 'assets/icons/svg/Logo-Footer.svg';
import { ReactComponent as LowRiskIcon } from 'assets/icons/svg/low-risk-icon.svg';
import { ReactComponent as Mastercard } from 'assets/icons/svg/mastercard.svg';
import { ReactComponent as MediumGrayImpactIcon } from 'assets/icons/svg/medium-gray-impact-icon.svg';
import { ReactComponent as MediumIcon } from 'assets/icons/svg/medium-icon.svg';
import { ReactComponent as MediumImpactIcon } from 'assets/icons/svg/medium-impact-icon.svg';
import { ReactComponent as MediumRiskIcon } from 'assets/icons/svg/medium-risk-icon.svg';
import { ReactComponent as MembersIcon } from 'assets/icons/svg/members-icon.svg';
import { ReactComponent as MobileMenuIcon } from 'assets/icons/svg/mobile-menu-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/svg/more-icon.svg';
import { ReactComponent as MoreOptionsIcon } from 'assets/icons/svg/more-options-icon.svg';
import { ReactComponent as NegativeIcon } from 'assets/icons/svg/negative-icon.svg';
import { ReactComponent as NeutralIcon } from 'assets/icons/svg/neutral-icon.svg';
import { ReactComponent as NeutralIcon1 } from 'assets/icons/svg/neutral-icon-1.svg';
import { ReactComponent as NewCapnoteFooter } from 'assets/icons/svg/new-capnote-footer.svg';
import { ReactComponent as NewCheckIcon } from 'assets/icons/svg/new-check-icon.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/svg/news-icon.svg';
import { ReactComponent as NextIcon } from 'assets/icons/svg/next-icon.svg';
import { ReactComponent as NoCompaniesIcon } from 'assets/icons/svg/no-companies-icon.svg';
import { ReactComponent as NoIcon } from 'assets/icons/svg/no-icon.svg';
import { ReactComponent as NoIconGeneric } from 'assets/icons/svg/no-icon-generic.svg';
import { ReactComponent as NoNotificationIcon } from 'assets/icons/svg/no-notification-icon.svg';
import { ReactComponent as NoPortfoliosIcon } from 'assets/icons/svg/no-portfolios-icon.svg';
import { ReactComponent as NoStreamsIcon } from 'assets/icons/svg/no-streams-icon.svg';
import { ReactComponent as OrganizationPlanIcon } from 'assets/icons/svg/organization-plan-icon.svg';
import { ReactComponent as PlusButtonIcon } from 'assets/icons/svg/plus-button-icon.svg';
import { ReactComponent as PortfoliosIcon } from 'assets/icons/svg/portfolios-icon.svg';
import { ReactComponent as PositiveIcon } from 'assets/icons/svg/positive-icon.svg';
import { ReactComponent as PreviousIcon } from 'assets/icons/svg/previous-icon.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/svg/print-icon.svg';
import { ReactComponent as ProfilePhotoIcon } from 'assets/icons/svg/profile-photo-icon.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/svg/question-icon.svg';
import { ReactComponent as RateOffIcon } from 'assets/icons/svg/rate-off-icon.svg';
import { ReactComponent as RateOnIcon } from 'assets/icons/svg/rate-on-icon.svg';
import { ReactComponent as RedArrowDownIcon } from 'assets/icons/svg/red-arrow-down-icon.svg';
import { ReactComponent as RedArrowUpIcon } from 'assets/icons/svg/red-arrow-up-icon.svg';
import { ReactComponent as RedDoubleArrowDownIcon } from 'assets/icons/svg/red-double-arrow-down-icon.svg';
import { ReactComponent as RedDoubleArrowUpIcon } from 'assets/icons/svg/red-double-arrow-up-icon.svg';
import { ReactComponent as RedditIcon } from 'assets/icons/svg/reddit-icon.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/svg/refresh-icon.svg';
import { ReactComponent as ReminderIcon } from 'assets/icons/svg/reminder-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/svg/save-icon.svg';
import { ReactComponent as SavedIcon } from 'assets/icons/svg/saved-icon.svg';
import { ReactComponent as ScrollIcon } from 'assets/icons/svg/scroll-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/svg/search-icon.svg';
import { ReactComponent as SeePasswordIcon } from 'assets/icons/svg/see-password-icon.svg';
import { ReactComponent as SelectedIcon } from 'assets/icons/svg/selected-icon.svg';
import { ReactComponent as Send } from 'assets/icons/svg/send.svg';
import { ReactComponent as Settings } from 'assets/icons/svg/settings.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/svg/share-icon.svg';
import { ReactComponent as ShareOffIcon } from 'assets/icons/svg/share-off-icon.svg';
import { ReactComponent as ShareOnIcon } from 'assets/icons/svg/share-on-icon.svg';
import { ReactComponent as SharedIconsCheck } from 'assets/icons/svg/shared-icons-check.svg';
import { ReactComponent as SharedIconsCheckGeneric } from 'assets/icons/svg/shared-icons-check-generic.svg';
import { ReactComponent as SharedIconsCrown } from 'assets/icons/svg/shared-icons-crown.svg';
import { ReactComponent as SharedIconsCrownGeneric } from 'assets/icons/svg/shared-icons-crown-generic.svg';
import { ReactComponent as ShowMore } from 'assets/icons/svg/show-more.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/svg/success-icon.svg';
import { ReactComponent as TableDownArrow } from 'assets/icons/svg/table-down-arrow.svg';
import { ReactComponent as TableUpArrow } from 'assets/icons/svg/table-up-arrow.svg';
import { ReactComponent as TeammatesIcon } from 'assets/icons/svg/teammates-icon.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/svg/time-icon.svg';
import { ReactComponent as TimelineEventsIcon } from 'assets/icons/svg/timeline-events-icon.svg';
import { ReactComponent as TrashGeneric } from 'assets/icons/svg/trash-generic.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/svg/twitter-icon.svg';
import { ReactComponent as UpArrow } from 'assets/icons/svg/up-arrow.svg';
import { ReactComponent as UpArrowGeneric } from 'assets/icons/svg/up-arrow-generic.svg';
import { ReactComponent as UpIncreasingIcon } from 'assets/icons/svg/up-increasing-icon.svg';
import { ReactComponent as UpcomingEventsIcon } from 'assets/icons/svg/upcoming-events-icon.svg';
import { ReactComponent as Visa } from 'assets/icons/svg/visa.svg';
import { ReactComponent as WhiteFacebookIcon } from 'assets/icons/svg/white-facebook-icon.svg';
import { ReactComponent as WhiteLinkedinIcon } from 'assets/icons/svg/white-linkedin-icon.svg';
import { ReactComponent as WhiteTwitterIcon } from 'assets/icons/svg/white-twitter-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/svg/x-icon.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/svg/youtube-icon.svg';
import React from 'react';

export type SvgIconName =
  | 'Logo-Footer'
  | 'Logo-dark'
  | 'add-icon-thick'
  | 'add-icon'
  | 'arrow-up-icon'
  | 'basic-down-arrow'
  | 'building-icon-white'
  | 'calendar-icon'
  | 'camera'
  | 'check-circle'
  | 'check-icon-generic'
  | 'check-icon'
  | 'clock'
  | 'close-popup-icon'
  | 'close-surveys-icon'
  | 'comment-off-icon'
  | 'comment-on-icon'
  | 'copy'
  | 'create-post-icon'
  | 'dark-search-icon'
  | 'decrease-icon'
  | 'decreasing-icon'
  | 'delete-blue-icon'
  | 'delete-icon'
  | 'delete-paperbin-icon'
  | 'delete-stream-icon'
  | 'direct-message-icon'
  | 'dislike-icon'
  | 'down-arrow-generic'
  | 'down-arrow'
  | 'down-decreasing-icon'
  | 'download'
  | 'edit-icon'
  | 'envelope-icon'
  | 'error-icon'
  | 'exceedAccess'
  | 'exclamation-circle'
  | 'expand-button-icon'
  | 'export-icon'
  | 'external-link'
  | 'facebook-icon'
  | 'filter-icon'
  | 'finvar-choice-icon'
  | 'finvar-notification-icon'
  | 'flagged'
  | 'gray-location-icon'
  | 'green-arrow-down-icon'
  | 'green-arrow-up-icon'
  | 'green-double-arrow-down-icon'
  | 'green-double-arrow-up-icon'
  | 'hq-icon'
  | 'increase-icon'
  | 'increasing-icon'
  | 'individuals-plan-icon'
  | 'info-gray'
  | 'info-icon'
  | 'instagram-icon'
  | 'invite-icon'
  | 'like-icon'
  | 'link-research-icon'
  | 'linked-button_icon'
  | 'linked-icon'
  | 'linkedin-icon'
  | 'location-icon'
  | 'lock'
  | 'low-risk-icon'
  | 'mastercard'
  | 'medium-gray-impact-icon'
  | 'medium-icon'
  | 'medium-impact-icon'
  | 'medium-risk-icon'
  | 'members-icon'
  | 'mobile-menu-icon'
  | 'more-icon'
  | 'more-options-icon'
  | 'negative-icon'
  | 'neutral-icon-1'
  | 'neutral-icon'
  | 'new-capnote-footer'
  | 'new-check-icon'
  | 'news-icon'
  | 'next-icon'
  | 'no-companies-icon'
  | 'no-icon-generic'
  | 'no-icon'
  | 'no-notification-icon'
  | 'no-portfolios-icon'
  | 'no-streams-icon'
  | 'organization-plan-icon'
  | 'plus-button-icon'
  | 'portfolios-icon'
  | 'positive-icon'
  | 'previous-icon'
  | 'print-icon'
  | 'profile-photo-icon'
  | 'question-icon'
  | 'rate-off-icon'
  | 'rate-on-icon'
  | 'red-arrow-down-icon'
  | 'red-arrow-up-icon'
  | 'red-double-arrow-down-icon'
  | 'red-double-arrow-up-icon'
  | 'reddit-icon'
  | 'refresh-icon'
  | 'reminder-icon'
  | 'save-icon'
  | 'saved-icon'
  | 'scroll-icon'
  | 'search-icon'
  | 'see-password-icon'
  | 'selected-icon'
  | 'send'
  | 'settings'
  | 'share-icon'
  | 'share-off-icon'
  | 'share-on-icon'
  | 'shared-icons-check-generic'
  | 'shared-icons-check'
  | 'shared-icons-crown-generic'
  | 'shared-icons-crown'
  | 'show-more'
  | 'success-icon'
  | 'table-down-arrow'
  | 'table-up-arrow'
  | 'teammates-icon'
  | 'time-icon'
  | 'timeline-events-icon'
  | 'trash-generic'
  | 'twitter-icon'
  | 'up-arrow-generic'
  | 'up-arrow'
  | 'up-increasing-icon'
  | 'upcoming-events-icon'
  | 'visa'
  | 'white-facebook-icon'
  | 'white-linkedin-icon'
  | 'white-twitter-icon'
  | 'x-icon'
  | 'youtube-icon';

interface Props {
  readonly id?: string;
  readonly name: SvgIconName;
  readonly className?: string;
  readonly onClick?: (e: any) => void;
  readonly onMouseEnter?: (e: any) => void;
  readonly onMouseLeave?: (e: any) => void;
}

const SVGIcon: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<Props> & React.RefAttributes<HTMLDivElement>
> = React.forwardRef(function SVGIcon(
  props: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const Component = iconsMap[props.name] as React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { readonly title: string }
  >;

  return (
    <div
      id={props.id}
      ref={ref}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Component className={props.className} title="" />
    </div>
  );
});

export default SVGIcon;

/// Map to names
const iconsMap: { [key in SvgIconName]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  'Logo-Footer': LogoFooter,
  'Logo-dark': LogoDark,
  'add-icon': AddIcon,
  'add-icon-thick': AddIconThick,
  'arrow-up-icon': ArrowUpIcon,
  'basic-down-arrow': BasicDownArrow,
  'building-icon-white': BuildingIconWhite,
  'calendar-icon': CalendarIcon,
  camera: Camera,
  'check-circle': CheckCircle,
  'check-icon': CheckIcon,
  'check-icon-generic': CheckIconGeneric,
  clock: Clock,
  'close-popup-icon': ClosePopupIcon,
  'close-surveys-icon': CloseSurveysIcon,
  'comment-off-icon': CommentOffIcon,
  'comment-on-icon': CommentOnIcon,
  copy: Copy,
  'create-post-icon': CreatePostIcon,
  'dark-search-icon': DarkSearchIcon,
  'decrease-icon': DecreaseIcon,
  'decreasing-icon': DecreasingIcon,
  'delete-blue-icon': DeleteBlueIcon,
  'delete-icon': DeleteIcon,
  'delete-paperbin-icon': DeletePaperbinIcon,
  'delete-stream-icon': DeleteStreamIcon,
  'direct-message-icon': DirectMessageIcon,
  'dislike-icon': DislikeIcon,
  'down-arrow': DownArrow,
  'down-arrow-generic': DownArrowGeneric,
  'down-decreasing-icon': DownDecreasingIcon,
  download: Download,
  'edit-icon': EditIcon,
  'envelope-icon': EnvelopeIcon,
  'error-icon': ErrorIcon,
  exceedAccess: ExceedAccess,
  'exclamation-circle': ExclamationCircle,
  'expand-button-icon': ExpandButtonIcon,
  'export-icon': ExportIcon,
  'external-link': ExternalLink,
  'facebook-icon': FacebookIcon,
  'filter-icon': FilterIcon,
  'finvar-choice-icon': FinvarChoiceIcon,
  'finvar-notification-icon': FinvarNotificationIcon,
  flagged: Flagged,
  'gray-location-icon': GrayLocationIcon,
  'green-arrow-down-icon': GreenArrowDownIcon,
  'green-arrow-up-icon': GreenArrowUpIcon,
  'green-double-arrow-down-icon': GreenDoubleArrowDownIcon,
  'green-double-arrow-up-icon': GreenDoubleArrowUpIcon,
  'hq-icon': HqIcon,
  'increase-icon': IncreaseIcon,
  'increasing-icon': IncreasingIcon,
  'individuals-plan-icon': IndividualsPlanIcon,
  'info-gray': InfoGray,
  'info-icon': InfoIcon,
  'instagram-icon': InstagramIcon,
  'invite-icon': InviteIcon,
  'like-icon': LikeIcon,
  'link-research-icon': LinkResearchIcon,
  'linked-button_icon': LinkedButton_icon,
  'linked-icon': LinkedIcon,
  'linkedin-icon': LinkedinIcon,
  'location-icon': LocationIcon,
  lock: Lock,
  'low-risk-icon': LowRiskIcon,
  mastercard: Mastercard,
  'medium-gray-impact-icon': MediumGrayImpactIcon,
  'medium-icon': MediumIcon,
  'medium-impact-icon': MediumImpactIcon,
  'medium-risk-icon': MediumRiskIcon,
  'members-icon': MembersIcon,
  'mobile-menu-icon': MobileMenuIcon,
  'more-icon': MoreIcon,
  'more-options-icon': MoreOptionsIcon,
  'negative-icon': NegativeIcon,
  'neutral-icon': NeutralIcon,
  'neutral-icon-1': NeutralIcon1,
  'new-capnote-footer': NewCapnoteFooter,
  'new-check-icon': NewCheckIcon,
  'news-icon': NewsIcon,
  'next-icon': NextIcon,
  'no-companies-icon': NoCompaniesIcon,
  'no-icon': NoIcon,
  'no-icon-generic': NoIconGeneric,
  'no-notification-icon': NoNotificationIcon,
  'no-portfolios-icon': NoPortfoliosIcon,
  'no-streams-icon': NoStreamsIcon,
  'organization-plan-icon': OrganizationPlanIcon,
  'plus-button-icon': PlusButtonIcon,
  'portfolios-icon': PortfoliosIcon,
  'positive-icon': PositiveIcon,
  'previous-icon': PreviousIcon,
  'print-icon': PrintIcon,
  'profile-photo-icon': ProfilePhotoIcon,
  'question-icon': QuestionIcon,
  'rate-off-icon': RateOffIcon,
  'rate-on-icon': RateOnIcon,
  'red-arrow-down-icon': RedArrowDownIcon,
  'red-arrow-up-icon': RedArrowUpIcon,
  'red-double-arrow-down-icon': RedDoubleArrowDownIcon,
  'red-double-arrow-up-icon': RedDoubleArrowUpIcon,
  'reddit-icon': RedditIcon,
  'refresh-icon': RefreshIcon,
  'reminder-icon': ReminderIcon,
  'save-icon': SaveIcon,
  'saved-icon': SavedIcon,
  'scroll-icon': ScrollIcon,
  'search-icon': SearchIcon,
  'see-password-icon': SeePasswordIcon,
  'selected-icon': SelectedIcon,
  send: Send,
  settings: Settings,
  'share-icon': ShareIcon,
  'share-off-icon': ShareOffIcon,
  'share-on-icon': ShareOnIcon,
  'shared-icons-check': SharedIconsCheck,
  'shared-icons-check-generic': SharedIconsCheckGeneric,
  'shared-icons-crown': SharedIconsCrown,
  'shared-icons-crown-generic': SharedIconsCrownGeneric,
  'show-more': ShowMore,
  'success-icon': SuccessIcon,
  'table-down-arrow': TableDownArrow,
  'table-up-arrow': TableUpArrow,
  'teammates-icon': TeammatesIcon,
  'time-icon': TimeIcon,
  'timeline-events-icon': TimelineEventsIcon,
  'trash-generic': TrashGeneric,
  'twitter-icon': TwitterIcon,
  'up-arrow': UpArrow,
  'up-arrow-generic': UpArrowGeneric,
  'up-increasing-icon': UpIncreasingIcon,
  'upcoming-events-icon': UpcomingEventsIcon,
  visa: Visa,
  'white-facebook-icon': WhiteFacebookIcon,
  'white-linkedin-icon': WhiteLinkedinIcon,
  'white-twitter-icon': WhiteTwitterIcon,
  'x-icon': XIcon,
  'youtube-icon': YoutubeIcon,
};
