import { ReactComponent as DownArrow } from 'assets/icons/svg/basic-down-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/svg/x-icon.svg';
import axios, { CancelTokenSource } from 'axios';
import { Dropdown } from 'components/filters/components/Dropdown';
import { FilterOptionsDropdown } from 'components/filters/components/FilterOptionsDropdown';
import { GenericFilter, isLocalFilterItem, resolveOptions } from 'components/filters/helpers';
import reducer, {
  ActionTypes,
  initialState,
} from 'components/filters/reducers/categoryGroupReducer';
import { FilterValues } from 'hooks/useFilters';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { delayedExecution } from 'utils/delayedExecution';

interface Props {
  readonly label: string;
  readonly filterValues: readonly FilterValues[];
  readonly filterDefinition: GenericFilter;

  onChange(filterValues: readonly FilterValues[]): void;
  onRemove(name: string): void;
}

export const FilterCategoryGroup: React.FC<Props> = ({
  label,
  filterDefinition,
  filterValues,
  onChange,
  onRemove,
}: Props): React.ReactElement => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLDivElement | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const otherValues = useMemo(
    (): readonly FilterValues[] =>
      filterValues.filter((item: FilterValues): boolean => item.key !== filterDefinition.name),
    [filterDefinition.name, filterValues],
  );

  const fetchFilterOptions = useCallback(
    async (keyword: string | null, tokenSource: CancelTokenSource): Promise<string[]> => {
      const keywordFilter: FilterValues | undefined = keyword
        ? { key: 'keyword', values: [keyword] }
        : undefined;
      const appliedFilters = keywordFilter
        ? [...state.optionsFilter, keywordFilter]
        : state.optionsFilter;

      return await resolveOptions(filterDefinition, appliedFilters, tokenSource.token);
    },
    [filterDefinition, state.optionsFilter],
  );

  const onFetchOptionsSuccess = useCallback((options: string[]): void => {
    dispatch({ type: ActionTypes.loadOptionsCompleted, payload: options });
  }, []);

  const onFetchOptionsError = useCallback(
    (err: any): void => dispatch({ type: ActionTypes.loadOptionsFailed, payload: err }),
    [],
  );

  const onValuesChange = useCallback(
    (values: string[]): void => {
      const newFilterValues = filterValues.map((item: FilterValues): FilterValues => {
        if (item.key === filterDefinition.name) {
          return { ...item, values: values };
        } else {
          return item;
        }
      });
      onChange(newFilterValues);
    },
    [filterDefinition.name, filterValues, onChange],
  );

  const showOptions = useCallback((): void => dispatch({ type: ActionTypes.showDropdown }), []);
  const hideOptions = useCallback((): void => dispatch({ type: ActionTypes.hideDropdown }), []);

  const onXClick = useCallback(
    (): void => onRemove(filterDefinition.name),
    [filterDefinition.name, onRemove],
  );

  const onSearch = useCallback(
    (keyword: string): void => {
      const trimmed = keyword.trim();
      if (trimmed === state.searchKeyword) {
        return;
      }

      dispatch({ type: ActionTypes.setSearchKeyword, payload: trimmed });
    },
    [state.searchKeyword],
  );

  const onClearAll = useCallback((): void => {
    onChange([...otherValues, { key: filterDefinition.name, values: [] }]);
  }, [filterDefinition.name, onChange, otherValues]);

  /// Base effect that resolves the options when they are out of date
  useEffect((): void | VoidFunction => {
    // Local filters.ts don't need fetching
    if (isLocalFilterItem(filterDefinition)) {
      return;
    }
    // If the dropdown is not isDropdownOpen, we don't want to fetch the options
    if (!state.isDropdownOpen) return;
    // If the options where already fetched, we do not need to fetch them
    // again
    if (state.resolvedOptions !== null) return;
    // We do need to fetch the options, so let's do it
    const tokenSource = axios.CancelToken.source();
    // Start up any feedback
    dispatch({ type: ActionTypes.loadOptions });
    // Perform the action
    delayedExecution<string[]>(fetchFilterOptions(state.searchKeyword, tokenSource), 0.25)
      .then(onFetchOptionsSuccess)
      .catch(onFetchOptionsError);

    return (): void => {
      tokenSource.cancel();
    };
  }, [
    fetchFilterOptions,
    filterDefinition,
    onFetchOptionsSuccess,
    state.searchKeyword,
    state.isDropdownOpen,
    state.resolvedOptions,
    onFetchOptionsError,
  ]);

  useEffect((): VoidFunction => {
    const timer = setTimeout((): void => {
      onSearch(searchKeyword);
    }, 300);

    return (): void => {
      clearTimeout(timer);
    };
  }, [onSearch, searchKeyword]);

  useEffect((): void => {
    dispatch({
      type: ActionTypes.updateFilterValues,
      payload: otherValues,
    });
  }, [filterValues, filterDefinition.name, otherValues]);

  /// Memoized stuff
  const selectedOptions = useMemo(
    (): string[] | null | undefined =>
      filterValues.find((item: FilterValues): boolean => item.key === filterDefinition.name)
        ?.values ?? [],
    [filterDefinition, filterValues],
  );

  const computedLabel = useMemo((): string => {
    if (selectedOptions && selectedOptions.length > 0) {
      return `${label}: ${selectedOptions.join(', ')}`;
    }

    return label;
  }, [label, selectedOptions]);

  const labelColor = useMemo(
    (): string => ((selectedOptions?.length ?? 0) > 0 ? 'blue' : 'gray'),
    [selectedOptions],
  );

  const options = useMemo(
    (): GenericFilter[] =>
      (isLocalFilterItem(filterDefinition)
        ? typeof filterDefinition.options === 'function'
          ? filterDefinition.options(filterDefinition.name)
          : filterDefinition.options
        : state.resolvedOptions
      )?.map(
        (name: string): GenericFilter => ({
          name: name,
          label: name,
          type: 'local',
        }),
      ) ?? [],
    [filterDefinition, state.resolvedOptions],
  );

  return (
    <>
      <div ref={setDropdownAnchor} className={rootClasses} onClick={showOptions}>
        <div
          className="absolute top-1 left-1 leading-none text-blue cursor-pointer w-2.5 h-2.5"
          onClick={onXClick}
        >
          <CloseIcon stroke="#197ACF" strokeWidth={2} className="w-full h-full" />
        </div>
        <span
          className={`font-poppins text-${labelColor} ml-1 select-none overflow-ellipsis overflow-hidden`}
        >
          {computedLabel}
        </span>
        <div className="w-3 h-3 ml-1 flex-shrink-0">
          <DownArrow fill="#197ACF" />
        </div>
      </div>
      <Dropdown isOpen={state.isDropdownOpen} anchor={dropdownAnchor} onClose={hideOptions}>
        <FilterOptionsDropdown
          options={options}
          selectedOptions={selectedOptions ?? []}
          searchable={true}
          loading={state.loading}
          searchKeyword={searchKeyword}
          onSearch={setSearchKeyword}
          onClearAll={onClearAll}
          onChange={onValuesChange}
        />
      </Dropdown>
    </>
  );
};

const rootClasses = `relative 
flex justify-between 
items-center text-sm 
bg-blue-light px-3 w-52 rounded-5 overflow-hidden whitespace-nowrap border-blue border py-2 md:py-0`;
