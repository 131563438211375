import { WithID, WithOptionalID } from 'types/withId';
import { SpecialOfferDetails } from 'views/EarnAndBuy/api';

export enum ActionType {
  setValue,
  initialize,
  reset,
}

export interface CreateSpecialOfferPayload {
  readonly title: string;
  readonly description: string;
  readonly category: number | null;
  readonly tags: readonly string[];
  readonly minimum_required_plan_id: string;
  readonly url: string;
}

export type UpdateSpecialOfferPayload = WithID<CreateSpecialOfferPayload>;

export const isUpdateSpecialOfferPayload = (
  payload: WithOptionalID<CreateSpecialOfferPayload>,
): payload is UpdateSpecialOfferPayload => {
  return 'id' in payload && typeof payload.id === 'string';
};

interface ActionBase {
  readonly type: ActionType;
  readonly data?: any;
}

export interface SetValueAction extends ActionBase {
  readonly type: ActionType.setValue;
  readonly data: {
    readonly name: keyof CreateSpecialOfferPayload;
    readonly value: CreateSpecialOfferPayload[keyof CreateSpecialOfferPayload];
  };
}

export interface ResetAction extends ActionBase {
  readonly type: ActionType.reset;
}

export interface InitializeAction extends ActionBase {
  readonly type: ActionType.initialize;
  readonly data: SpecialOfferDetails;
}

export type Action = SetValueAction | ResetAction | InitializeAction;

export const initialState: CreateSpecialOfferPayload = {
  title: '',
  description: '',
  tags: [],
  minimum_required_plan_id: '',
  category: null,
  url: '',
};

export const reducer = (
  state: CreateSpecialOfferPayload,
  action: Action,
): CreateSpecialOfferPayload => {
  const { type, data } = action;

  switch (type) {
    case ActionType.setValue:
      return { ...state, [data.name]: data.value };
    case ActionType.reset:
      return initialState;
    case ActionType.initialize:
      return {
        title: data.title,
        description: data.description,
        tags: data.tags,
        minimum_required_plan_id: data.minimum_required_plan_id,
        category: data.category.id,
        url: data.url,
      };
    default:
      return state;
  }
};
