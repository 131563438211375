import { Breadcrumbs } from 'components/breadcrumbs';
import { TruncatedTags } from 'components/truncatedTags';
import React from 'react';
import { TextLine } from 'views/DataAnalysis/common/textLine';

interface Props {
  readonly path: readonly string[];
  readonly description: React.ReactElement | string;
  readonly tags?: readonly string[];

  onExpand?(): void;
  onTagClick?(tag: string): void;
}

export const CommonViewHeader: React.FC<Props> = ({
  path,
  description,
  tags,
  onExpand,
  onTagClick,
}: Props): React.ReactElement => {
  return (
    <div>
      <Breadcrumbs path={path} />
      <TextLine onExpand={onExpand} content={description} />
      <div className="my-2">
        <TruncatedTags tags={tags ?? []} onExpand={onExpand} onClick={onTagClick} />
      </div>
    </div>
  );
};
