export const computeBaseUrl = (envValue: string | undefined): string => {
  const { protocol, hostname } = window.location;

  if (envValue) {
    return envValue;
  } else if (map[hostname] !== undefined) {
    return `${protocol}//${map[hostname]}`;
  } else {
    return `${protocol}//${hostname}`;
  }
};

const map: Record<string, string> = {
  'web-dev.finvar.net': 'api-dev.finvar.net',
  'web-qa.finvar.net': 'api-qa.finvar.net',
  'beta.capnote.com': 'beta.capnote.com',
};
