import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import { Level } from 'components/table/types';
import React, { useMemo } from 'react';
import { KeyFactorType } from 'redux/reducers/keyFactorsReducer';

interface Props {
  readonly value: Level | null;
  readonly factorType: KeyFactorType;
  readonly type: 'level' | 'impact' | 'probability';
}

export const LevelCell: React.FC<Props> = ({
  factorType,
  value,
  type,
}: Props): React.ReactElement | null => {
  const iconName = useMemo(
    (): SvgIconName => getIcon(factorType, value, type),
    [factorType, type, value],
  );

  if (value === null) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <SVGIcon name={iconName} className="w-3 h-4" />
      <p>{value}</p>
    </div>
  );
};

const getIcon = (
  factorType: KeyFactorType,
  level: Level | null,
  type: 'level' | 'impact' | 'probability',
): SvgIconName => {
  switch (level) {
    case Level.veryHigh:
      return factorType === 'risk' ? 'red-double-arrow-up-icon' : 'green-double-arrow-up-icon';
    case Level.high:
      return factorType === 'risk'
        ? type === 'level'
          ? 'exclamation-circle'
          : 'red-arrow-up-icon'
        : type === 'level'
        ? 'low-risk-icon'
        : 'green-arrow-up-icon';
    case Level.veryLow:
      return factorType === 'risk' ? 'green-double-arrow-up-icon' : 'red-double-arrow-down-icon';
    case Level.low:
      return factorType === 'risk' ? 'low-risk-icon' : 'exclamation-circle';
    default:
      return type === 'level' ? 'medium-risk-icon' : 'medium-impact-icon';
  }
};
