import { Select } from 'components/select';
import React, { useEffect } from 'react';
import {
  PortfolioYourResidenceRealStateItem,
  RealEstatePurpose,
  YourResidenceType,
} from 'types/portfolioItem';
import { enumToSelectItems, isEnumOf } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioYourResidenceRealStateItem;
}

export const YourResidenceRealEstateForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioYourResidenceRealStateItem>();

  useEffect((): void => {
    if (item.purpose !== RealEstatePurpose.yourResidence) {
      return;
    } else if (!isEnumOf(YourResidenceType, item.typeOfResidence)) {
      const updateTypeOfResidence = updaterFor('typeOfResidence');
      updateTypeOfResidence(YourResidenceType.apartment);
    }
  }, [item.purpose, item.typeOfResidence, updaterFor]);

  return (
    <>
      <Select
        items={residenceTypes}
        value={item.typeOfResidence}
        label="Select type of residential real estate"
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onChange={updaterFor('typeOfResidence')}
      />
    </>
  );
};

const residenceTypes = enumToSelectItems(YourResidenceType, {
  [YourResidenceType.apartment]: 'Apartment',
  [YourResidenceType.detached]: 'Detached',
  [YourResidenceType.semiDetached]: 'Semi-Detached',
  [YourResidenceType.townHouse]: 'Town House',
});
