import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AuthProcessingStateData,
  authProcessingStateSelector,
  clearProcessingState,
} from 'redux/reducers/authReducer';
import { unlockAccount, verifyEmail } from 'redux/services/authServices';
import LoginForm from 'views/Landing/Login/components/loginForm';
import SectionTitle from 'views/Landing/Login/components/sectionTitle';

interface Props {
  readonly action?: 'login' | 'unlock' | 'verify';
}

const Login: React.FC<Props> = ({ action = 'login' }: Props): React.ReactElement => {
  // const { errorVerifyEmail, isVerifyingEmail, verifiedEmail } = useSelector(authSelector);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const code = searchParams.get('code');
  const navigate = useNavigate();
  const processingState = useSelector(authProcessingStateSelector);

  useEffect((): void | VoidFunction => {
    if (action === 'login') {
      return;
    }

    if (code === null || code === undefined) {
      navigate('/login');
      return;
    }

    switch (action) {
      case 'unlock':
        dispatch(unlockAccount(code));
        break;
      case 'verify':
        dispatch(verifyEmail(code));
        break;
    }
  }, [action, code, dispatch, navigate]);

  useEffect((): void => {
    if (
      processingState.data === AuthProcessingStateData.accountUnlocked ||
      processingState.data === AuthProcessingStateData.emailVerified
    ) {
      navigate('', {});
    }
  }, [navigate, processingState.data]);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(clearProcessingState());
    };
  }, [dispatch]);

  return (
    <>
      <SectionTitle title="Login" subTitle="Welcome to Capnote" />
      <LoginForm />
    </>
  );
};

export default Login;
