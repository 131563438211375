import SVGIcon from 'components/icons/SVGIcon';
import { loadingNode } from 'components/valueChain/specialLoadingNode';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addValueChainNode,
  addValueChainNodeWithCategory,
  valueChainStateSelector,
} from 'redux/reducers/valueChainReducer';
import { ValueChainEntityType, ValueChainNode, ValueChainTableRow } from 'types/organization/types';

interface Props {
  readonly row: ValueChainTableRow;
}

const ValueChainBubbleLink: React.FC<Props> = ({ row }: Props) => {
  const { stack: valueChain } = useSelector(valueChainStateSelector);
  const dispatch = useDispatch<any>();

  const handleClick = useCallback(() => {
    const { entity_id, label, entity_type } = row;

    const lastNode = valueChain[valueChain.length - 1];
    const children = lastNode?.children ?? [];

    const categoryNode = children.find((child: ValueChainNode): boolean => {
      switch (row.type) {
        case 'Investor':
          return child.entityType === ValueChainEntityType.institutionalInvestors;
        case 'Peer':
          return child.entityType === ValueChainEntityType.peers;
        case 'Subsidiary':
          return child.entityType === ValueChainEntityType.subsidiaries;
        case 'Supplier':
          return child.entityType === ValueChainEntityType.keySuppliers;
        case 'Customer':
          return child.entityType === ValueChainEntityType.keyCustomers;
        default:
          return false;
      }
    });

    const entityNode: ValueChainNode = {
      entityId: entity_id,
      label: label,
      count: null,
      entityType: entity_type as ValueChainEntityType,
      ownerOrganization: null,
    };

    if (categoryNode) {
      dispatch(
        addValueChainNodeWithCategory([
          { ...categoryNode, children: [] },
          { ...entityNode, children: [loadingNode] },
        ]),
      );
    } else {
      dispatch(addValueChainNode({ ...entityNode, children: [loadingNode] }));
    }
  }, [dispatch, row, valueChain]);

  if (canBeAddedToChain(row.entity_type)) {
    return (
      <button className={buttonClassName} onClick={handleClick}>
        <div className="flex items-center justify-center gap-2 ml-2 pr-3">
          <div className="flex items-center justify-center">
            <SVGIcon name="external-link" className="w-4 h-4 fill-current" />
          </div>
          <p className="font-poppinsMedium">Add to chain</p>
        </div>
      </button>
    );
  }

  return null;
};

export default ValueChainBubbleLink;

const canBeAddedToChain = (entityType: ValueChainEntityType): boolean => {
  switch (entityType) {
    case ValueChainEntityType.company:
    case ValueChainEntityType.industry:
      return true;
    case ValueChainEntityType.indicator:
    case ValueChainEntityType.salesGeographies:
    case ValueChainEntityType.subsidiaries:
    case ValueChainEntityType.institutionalInvestors:
    case ValueChainEntityType.relatedIndustries:
    case ValueChainEntityType.regulators:
    case ValueChainEntityType.peers:
    case ValueChainEntityType.keyCustomers:
    case ValueChainEntityType.keySuppliers:
    case ValueChainEntityType.purchasingIndustries:
    case ValueChainEntityType.supplyingIndustries:
    case ValueChainEntityType.competingIndustries:
    case ValueChainEntityType.complementaryIndustries:
    case ValueChainEntityType.other:
      return false;
  }
};

const buttonClassName =
  'font-poppinsMedium text-sm text-white bg-blue rounded py-0 transition border border-blue' +
  ' hover:text-blue hover:bg-white disabled:pointer-events-none leading-9 w-36';
