import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import useOldTour from 'hooks/useOldTour';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { CompaniesWatchlist } from 'views/Watchlist/components/Companies';
import { SensitivitiesRoutes } from 'views/Watchlist/components/Sensitivities/routes';
import tourConfig from 'views/Watchlist/tourConfig';

const Watchlist: React.FC = () => {
  const [_, tour] = useOldTour(tourConfig);
  const location = useLocation();

  const breadcrumbs = useMemo((): readonly string[] => {
    const { pathname } = location;

    if (pathname.endsWith('watchlist')) {
      return ['WATCHLIST', 'COMPANIES'];
    } else if (pathname.endsWith('industries')) {
      return ['WATCHLIST', 'SENSITIVITIES', 'INDUSTRIES'];
    } else if (pathname.endsWith('indicators')) {
      return ['WATCHLIST', 'SENSITIVITIES', 'INDICATORS'];
    } else {
      return ['WATCHLIST'];
    }
  }, [location]);

  return (
    <MainOutletContent>
      {tour}
      {/* This should be dynamic */}
      <Breadcrumbs path={breadcrumbs} />
      <TextLine content="Add items to Capnote's intelligent watchlists to receive personalized insights and see related sensitivities." />
      <div className="h-2" />

      <Tabs shrinkFactor={6} variant={TabsVariant.buttons}>
        <Tab label="Companies" element={<CompaniesWatchlist />} />
        <Tab label="Sensitivities" element={<SensitivitiesRoutes />} path="sensitivities/*" />
      </Tabs>
    </MainOutletContent>
  );
};

export default Watchlist;
