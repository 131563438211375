import React from 'react';

type Props = React.PropsWithChildren & React.PropsWithRef<any>;

export const MainOutletContent: React.ForwardRefExoticComponent<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(function MainOutletContent(
  props: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  return (
    <div
      ref={ref}
      data-name="MainOutletContent"
      className="flex flex-1 flex-col min-h-full w-full md:pr-3 md:pb-3"
    >
      {/* FIXME: breadcrumbs should probably be here */}
      <div className="relative flex-1 flex flex-col bg-white flex-grow min-h-full p-3 md:rounded">
        {props.children}
      </div>
    </div>
  );
});
