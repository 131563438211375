import React, { useCallback } from 'react';

interface Props {
  onKeyPressed(event: React.KeyboardEvent<HTMLDivElement>): boolean;
}

export const KeyboardListener: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onKeyPressed,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (onKeyPressed(event)) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [onKeyPressed],
  );

  return <div onKeyDown={handleKeyDown}>{children}</div>;
};
