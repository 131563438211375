import { TourConfig } from 'hooks/useOldTour';
import { Step } from 'react-joyride';

const steps: Step[] = [
  {
    target: '#tab-portfolios-geographies-maps',
    title: 'Geographic Distribution',
    content: 'See where companies in your Portfolio are located on the Geographies and Maps page',
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '#tab-portfolios-key-factors',
    title: 'Track Key Risks',
    content: 'Monitor the key risks that companies in your portfolio are exposed to',
    placement: 'bottom',
    placementBeacon: 'bottom',
  },
  {
    target: '#tab-portfolios-timeline',
    title: 'Events',
    content:
      "Here's a summary of key events pertaining to companies in your portfolio. " +
      'Add Events of your own to keep track of key dates specific to you',
    placement: 'bottom',
    placementBeacon: 'bottom',
  },
];

const tourConfig: TourConfig = {
  nextPath: '/data-analysis/company',
  steps,
  lastLabel: 'Next',
};

export default tourConfig;
