import React, { useCallback, useMemo, useState } from 'react';
import { StatementValue } from 'types/financial';
import { CommentsPopup } from 'views/DataAnalysis/common/commentsPopup';
import VALUES_FORMATTERS from 'views/DataAnalysis/valuesFormatters';

interface Props {
  readonly data?: StatementValue;
  readonly largestValue: number;
  readonly statement: string;
}

export const StatementCellView: React.FC<Props> = ({
  data,
  largestValue,
  statement,
}: Props): React.ReactElement => {
  const [buttonReference, setButtonReference] = useState<HTMLElement | null>(null);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

  const commented = useMemo((): boolean => (data?.comments_count ?? 0) > 0, [data]);
  const formatter = VALUES_FORMATTERS[data?.unit ?? ''];
  const filingId = data?.id ?? '';
  const formattedValue = useMemo((): string => {
    const value = data?.value;
    if (!value && value !== 0) {
      return '-';
    }

    return formatter?.(value, largestValue) ?? '-';
  }, [data?.value, formatter, largestValue]);

  const showPopup = useCallback((): void => setPopupOpen(true), []);
  const hidePopup = useCallback((): void => setPopupOpen(false), []);

  return (
    <div className="text-gray text-sm text-center z-0 last:bg-gray-100 py-2">
      <button className={contentClassName} onClick={showPopup}>
        <div ref={setButtonReference}>{formattedValue}</div>
        {commented && <div className="w-full h-px bg-gray mx-auto -mt-px" />}
      </button>
      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <CommentsPopup
          statement={statement}
          anchor={buttonReference}
          filingId={filingId}
          entityName="organizations"
          isOpen={isPopupOpen}
          onClose={hidePopup}
        />
      )}
    </div>
  );
};

const contentClassName = process.env.REACT_APP_DATA_PORTAL
  ? 'px-3 rounded-md inline-block'
  : 'px-3 cursor-pointer hover:bg-gray-100 rounded-md active:transform translate-y-1 inline-block';
