import SVGIcon from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';

interface Props {
  readonly title: string;
  readonly priceString: string;
  readonly billingInterval: string;
  readonly specialOffer: string;
  readonly specialOfferColor: 'blue' | 'red' | 'gray';
  readonly selected?: boolean;
  readonly active?: boolean;
}

export const SubscriptionPlanItemHeaderContainer: React.FC<Props> = ({
  title,
  priceString,
  billingInterval,
  specialOffer,
  specialOfferColor,
  active,
  selected,
}: Props): React.ReactElement => {
  const activeIndicatorClassName = useMemo((): string => {
    const base =
      'absolute flex items-center gap-1.5 -top-3 right-0 h-6 leading-6 rounded-bl ' +
      'rounded-md-tr pl-3 pr-4 text-center font-poppinsSemiBold uppercase text-xs';
    if (selected) {
      return [base, 'text-white bg-blue'].join(' ');
    } else {
      return [base, 'text-green bg-gray-light'].join(' ');
    }
  }, [selected]);

  return (
    <div className="relative py-3 px-8">
      {active && (
        <div className={activeIndicatorClassName}>
          <SVGIcon name="check-circle" className="fill-current w-3.5" />
          <span>Your plan</span>
        </div>
      )}
      <h3 className="my-1 text-gray-darkest text-center truncate">{title}&nbsp;</h3>
      <h3 className="mb-2 text-gray-darkest text-center truncate">{priceString}&nbsp;</h3>
      <p className="font-poppins mb-1 text-gray text-md text-center truncate">
        {billingInterval}&nbsp;
      </p>
      <h5
        className={`mt-2 text-md font-jostSemiBold text-center uppercase font-bold truncate h-10 text-${specialOfferColor}`}
      >
        {specialOffer}&nbsp;
      </h5>
    </div>
  );
};
