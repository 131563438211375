// Library
import { KeywordAndDateRangeFilter } from 'components/keywordAndDateRangeFilter';
import NotificationsView from 'components/notificationsView';
import { useKeywordAndDateRangeFilter } from 'hooks/useKeywordAndDateRangeFilter';
import { usePager } from 'hooks/usePager';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadingPortfoliosNotificationsSelector,
  portfoliosNotificationsSelector,
} from 'redux/reducers/portfoliosReducer';
import { getPortfoliosNotificationsAction } from 'redux/services/portfolioService';
import columns from 'views/SmartTools/Notifications/columns';

const Notifications: React.FC = () => {
  const dispatch = useDispatch<any>();
  const notificationsPage = useSelector(portfoliosNotificationsSelector);
  const loading = useSelector(loadingPortfoliosNotificationsSelector);
  const currentPage = usePager();
  const queryParameters = useQueryParameters();
  const [keyword, , , searching] = useKeywordAndDateRangeFilter(null);

  useEffect((): void => {
    dispatch(getPortfoliosNotificationsAction(queryParameters));
  }, [currentPage, dispatch, queryParameters]);

  return (
    <>
      <KeywordAndDateRangeFilter keyword={keyword} searching={searching} />
      <NotificationsView
        notificationsPage={notificationsPage}
        columns={columns}
        loading={loading}
        rootPath="/portfolios/notifications"
      />
    </>
  );
};

export default Notifications;
