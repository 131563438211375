import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AuthProcessingStateData,
  authProcessingStateSelector,
  clearProcessingState,
  isAuthProcessingStateData,
} from 'redux/reducers/authReducer';
import { requestPasswordReset } from 'redux/services/authServices';
import { APIAction } from 'types/APIAction';
import { FormErrors, ValidationErrors } from 'utils/forms';
import { Button } from 'views/Landing/component/button';
import { Input } from 'views/Landing/component/input';
import { MessageView } from 'views/Landing/component/messageView';
import SectionTitle from 'views/Landing/Login/components/sectionTitle';
import * as yup from 'yup';
import { ValidationError } from 'yup';

export const RequestPasswordReset: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<Dispatch<APIAction | AnyAction>>();

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({});
  const processingState = useSelector(authProcessingStateSelector);
  const navigate = useNavigate();

  const authProcessingState = useMemo((): AuthProcessingStateData => {
    const { data } = processingState;
    if (isAuthProcessingStateData(data)) {
      return data;
    }

    return AuthProcessingStateData.none;
  }, [processingState]);

  const submitError = useMemo((): ValidationError | undefined => {
    if (!isEmpty(errors)) {
      return errors.email;
    }

    return undefined;
  }, [errors]);

  const handleBackToLogin = useCallback((): void => {
    navigate('/login');
  }, [navigate]);

  const handleRequestPasswordReset = useCallback((): void => {
    dispatch(requestPasswordReset(emailAddress.toLowerCase()));
  }, [dispatch, emailAddress]);

  const submitForm = useCallback(async (): Promise<void> => {
    try {
      setErrors({});
      await schema.validate({ email: emailAddress }, { abortEarly: false });

      handleRequestPasswordReset();
    } catch (error: any) {
      setErrors(ValidationErrors.reduce(error.inner));
    }
  }, [emailAddress, handleRequestPasswordReset]);

  const handleFormSubmit = useCallback(
    (event: React.FormEvent): void => {
      event.preventDefault();
      void submitForm();
    },
    [submitForm],
  );

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(clearProcessingState());
    };
  }, [dispatch]);

  return (
    <form onSubmit={handleFormSubmit}>
      <SectionTitle
        title="Forgot Password?"
        subTitle="Enter your email below and we’ll send you a link to reset your password."
      />
      <div className="my-5">
        {authProcessingState !== AuthProcessingStateData.none && (
          <MessageView state={authProcessingState} onAction={handleRequestPasswordReset} />
        )}
      </div>
      <div className="text-center my-5">
        <Input
          value={emailAddress}
          label="Email Address"
          readOnly={authProcessingState === AuthProcessingStateData.requestPasswordReset}
          error={submitError}
          onChange={setEmailAddress}
        />
        <div className="my-5">
          {authProcessingState !== AuthProcessingStateData.requestPasswordReset && (
            <Button label="Submit" />
          )}
          <button
            className="block text-blue font-poppinsSemiBold text-sm normal-case mx-auto my-5"
            onClick={handleBackToLogin}
          >
            Go back to Log In
          </button>
        </div>
      </div>
    </form>
  );
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address. For example johndoe@mail.com')
    .required('Please enter an email address'),
});
