import { ApplicationModule, usePermission } from 'context/authorization';
import React from 'react';
import { ViewAccess } from 'redux/reducers/subscriptionPlansReducer';
import NoAccessUpgradePlan from 'views/NoAccessUpgradePlan';

type WithIntrinsicProps<T> = T & JSX.IntrinsicAttributes;

export interface DenyViewData {
  readonly title: string;
  readonly body: string;
}

export function withViewAccess<T>(
  module: ApplicationModule,
  denyViewData: DenyViewData,
): (
  Component: React.ComponentType<WithIntrinsicProps<T>>,
) => React.FunctionComponent<WithIntrinsicProps<T>> {
  if (process.env.REACT_APP_DATA_PORTAL) {
    return (
      Component: React.ComponentType<WithIntrinsicProps<T>>,
    ): React.FunctionComponent<WithIntrinsicProps<T>> => {
      function WrappedComponent(props: WithIntrinsicProps<T>): React.ReactElement<T> {
        return <Component {...props} />;
      }

      return WrappedComponent;
    };
  } else {
    return (
      Component: React.ComponentType<WithIntrinsicProps<T>>,
    ): React.FunctionComponent<WithIntrinsicProps<T>> => {
      function WrappedComponent(props: WithIntrinsicProps<T>): React.ReactElement<T> {
        const permission = usePermission(module, ViewAccess.viewAccess);

        if (permission.forbidden) {
          return <NoAccessUpgradePlan title={denyViewData.title} body={denyViewData.body} />;
        }

        return <Component {...props} />;
      }

      return WrappedComponent;
    };
  }
}
