import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ConnectionsImg from 'assets/images/connections.png';
import FeedImg from 'assets/images/feed.png';
import PortfolioImg from 'assets/images/portfolios.png';
import TimelineImg from 'assets/images/timeline.png';

export enum FeatureKey {
  personalizedOnStopFeed = 'personalized-one-stop-feed',
  dynamicValueChains = 'dynamic-value-chains',
  intelligentTimelines = 'intelligent-timelines',
  socialNetworking = 'social-networking',
}

export interface LandingPageState {
  selectedFeatureKey: FeatureKey | null;
  featuresMap: Record<FeatureKey, FeatureItem>;
  featuresList: readonly FeatureItem[];
}

export interface FeatureItem {
  readonly id: FeatureKey;
  readonly label: string;
  readonly subtitle: string;
  readonly body: string;
  readonly image: string;
}

const landingPageFeatures: Record<FeatureKey, FeatureItem> = {
  [FeatureKey.personalizedOnStopFeed]: {
    id: FeatureKey.personalizedOnStopFeed,
    label: 'Personalized One-Stop Feed',
    subtitle: 'Custom alerts, updated news and information',
    body: `Capnote aggregates relevant news, posts, and notifications to keep you informed and give you an edge. 
     Customize your feed to track the trends you care about.`,
    image: FeedImg,
  },
  [FeatureKey.dynamicValueChains]: {
    id: FeatureKey.dynamicValueChains,
    label: 'Dynamic Value Chains & Portfolios',
    subtitle: 'In-depth analysis at your fingertips.',
    body: `Build multi-asset portfolios,  track data over time and connect-the-dots. 
     Discover key risks and sensitivities, receive notifications as conditions change and relationships evolve.`,
    image: PortfolioImg,
  },
  [FeatureKey.intelligentTimelines]: {
    id: FeatureKey.intelligentTimelines,
    label: 'Intelligent Timelines & Events',
    subtitle: 'Automated tracking of essential dates and investment catalysts',
    body: `Get notified of key events that impact your portfolios, companies, countries or industries. 
     Create topic-specific calendars, link events to analysis, 
     share with your connections and subscribe to specialized time-series datasets.`,
    image: TimelineImg,
  },
  [FeatureKey.socialNetworking]: {
    id: FeatureKey.socialNetworking,
    label: 'Social Networking',
    subtitle: 'Connect and collaborate with clients, colleagues, friends and experts',
    body: `Expand your network, find professional groups and beneficial connections. Share and discuss strategies, 
     theories and research to stay in the know. Enhance communication by contextualizing your conversations with relevant data.`,
    image: ConnectionsImg,
  },
};

const initialState: LandingPageState = {
  selectedFeatureKey: FeatureKey.personalizedOnStopFeed,
  featuresMap: landingPageFeatures,
  featuresList: Object.values(landingPageFeatures),
};

const slice = createSlice({
  name: 'landingPageReducer',
  initialState: initialState,
  reducers: {
    setSelectedFeature: (state: LandingPageState, { payload }: PayloadAction<FeatureKey>): void => {
      state.selectedFeatureKey = payload;
    },
  },
});

export const landingPageSelector = ({
  landingPage,
}: {
  landingPage: LandingPageState;
}): LandingPageState => landingPage;
export const { setSelectedFeature } = slice.actions;

export default slice.reducer;
