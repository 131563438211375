import { Modal } from 'components/modal';
import ExceedAccess from 'modals/exceedAccess';
import React from 'react';
import { SpecialOffer } from 'views/EarnAndBuy/api';
import { ActionItem, OwnerActions } from 'views/EarnAndBuy/common/ownerActions';
import { ActionsContext, ActionType, EarnAndBuyActions } from 'views/EarnAndBuy/context';

interface Props {
  readonly item: SpecialOffer;
}

enum ModalType {
  none,
  notAllowed,
}

export const Actions: React.FC<Props> = ({ item: specialOffer }: Props): React.ReactElement => {
  const context = React.useContext<EarnAndBuyActions | null>(ActionsContext);

  const [currentModal, setCurrentModal] = React.useState<ModalType>(ModalType.none);

  const closeCurrentModal = React.useCallback((): void => {
    setCurrentModal(ModalType.none);
  }, []);

  const handleAction = React.useCallback(
    (action: ActionType): void => {
      switch (action) {
        case ActionType.edit:
          context?.edit(specialOffer.id);
          break;
        case ActionType.remove:
          context?.remove(specialOffer.id);
          break;
      }
    },
    [context, specialOffer.id],
  );

  return (
    <div className="flex items-center gap-2 justify-end">
      <button className={buttonClassName}>View Details</button>
      <OwnerActions
        actions={actions}
        accessMode={specialOffer.access_mode}
        actionStatuses={context?.actionStatuses}
        onHandleAction={handleAction}
      />

      <Modal isOpen={currentModal === ModalType.notAllowed} onClose={closeCurrentModal}>
        <Modal.Content>
          <div className="w-modal-sm">
            <ExceedAccess
              title="Upgrade your plan"
              description="You need to upgrade your subscription plan to apply for this listing."
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

const actions: readonly ActionItem[] = [
  {
    label: 'Share',
    icon: 'share-icon',
    disabled: true,
    action: ActionType.share,
  },
  {
    label: 'Edit',
    icon: 'edit-icon',
    disabled: false,
    action: ActionType.edit,
  },
  {
    label: 'Remove',
    icon: 'trash-generic',
    disabled: false,
    action: ActionType.remove,
  },
];

const buttonClassName =
  'text-center gap-2 px-3 w-36 mr-3 leading-10 bg-blue text-white text-md rounded' +
  ' font-poppinsMedium hover:bg-white hover:text-blue hover:border hover:border-blue transition-all' +
  ' disabled:bg-gray disabled:opacity-10 disabled:pointer-events-none';
