const delay = (timeInSeconds: number, startedAt: number): Promise<void> => {
  const delay = 1000 * timeInSeconds - (Date.now() - startedAt);

  if (delay > 0) {
    return new Promise<void>((r: () => void): void => {
      setTimeout(r, delay);
    });
  }

  return Promise.resolve();
};
/**
 * Await [promise] and then wait `delay` ms before resolving the promise.
 * If the promise takes more than [timeInSeconds], then it will not wait at all.
 * Otherwise, it will wait for the remaining time to last at least `timeInSeconds`
 * seconds.
 */
export const delayedExecution = async <K>(
  promise: Promise<K>,
  timeInSeconds: number,
): Promise<K> => {
  const startedAt = Date.now();
  try {
    const result = await promise;
    await delay(timeInSeconds, startedAt);

    return result;
  } catch (exception: any) {
    await delay(timeInSeconds, startedAt);
    throw exception;
  }
};
