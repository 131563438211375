import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { AccessMode } from 'types/accessMode';
import {
  ProfileStatementGroup,
  Statement,
  StatementGroup,
  StatementRow,
  StatementValue,
} from 'types/financial';
import {
  Company,
  CompanyRow,
  FinancialInfo,
  FinancialRow,
  OrganizationDetails,
} from 'types/organization/types';
import { Page } from 'types/page';
import { KeyTrend, PeerComparisons, SwotAnalysis } from 'types/peerComparisons';
import { Stream } from 'types/timeline';

export enum AlgoNoteCategory {
  FinancialStatements = 'Financial Statements',
  PeerComparisons = 'Peer Comparisons',
}

export interface CompanyState {
  loadingCompanyDetails: boolean;
  companyDetails: OrganizationDetails;
  summaryMap?: { [key: string]: CompanyRow };
  loadingSummary: boolean;
  showUsageLimitExceededModal: boolean;

  summary: ProfileStatementGroup[];
  forecast: ProfileStatementGroup[];

  financialHighlights: Statement;
  incomeStatement: Statement;
  cashFlow: Statement;
  balanceSheet: Statement;
  metricsAndIndicators: Statement;

  loadingFinancialHighlights: boolean;
  loadingIncomeStatement: boolean;
  loadingCashFlow: boolean;
  loadingBalanceSheet: boolean;
  loadingMetricsAndIndicators: boolean;

  loadingCompanyTimeline: boolean;
  companyStream: Stream;

  operationsHighlights?: FinancialInfo;
  operationsHighlightsRows: FinancialRow[];
  loadingOperationsHighlights: boolean;
  businessCharacterization?: FinancialInfo;
  businessCharacterizationRows: FinancialRow[];
  loadingBusinessCharacterization: boolean;
  operatingAssets?: FinancialInfo;
  operatingAssetsRows: FinancialRow[];
  loadingOperatingAssets: boolean;
  loadingKeyTrends: boolean;
  keyTrends: KeyTrend[];
  lastPeriodSelected?: number;
  loadingSwotsAnalysis: boolean;
  swotsAnalysis: SwotAnalysis[];
  loadingPeerComparisons: boolean;
  peerComparison: PeerComparisons | null;
  myWatchlist: Company[];
  companies: Page<Company>;
  loadingMyWatchlist: boolean;
  updatingCompanyInWatchlist: boolean;
  myWatchlistError: string;
  loadingAllCompanies: boolean;

  generatingOneClickReport: boolean;
  generatingOneClickReportProgress: number;
  oneClickMemoQuotaExceededError: boolean;
}

const emptyStream: Stream = {
  id: '',
  author: '',
  access_mode: AccessMode.readOnly,
  category: '',
  name: '',
  members_count: 0,
  summary: '',
  tags: [],
  events: [],
};

const initialState: CompanyState = {
  showUsageLimitExceededModal: false,
  companyDetails: {} as OrganizationDetails,
  operationsHighlightsRows: [],
  loadingCompanyDetails: false,
  updatingCompanyInWatchlist: false,
  summary: [],
  summaryMap: undefined,
  loadingSummary: false,
  forecast: [],
  financialHighlights: Statement.empty(),
  loadingFinancialHighlights: false,
  operationsHighlights: undefined,
  loadingOperationsHighlights: false,
  metricsAndIndicators: Statement.empty(),
  loadingMetricsAndIndicators: false,
  balanceSheet: Statement.empty(),
  loadingBalanceSheet: false,
  cashFlow: Statement.empty(),
  loadingCashFlow: false,
  incomeStatement: Statement.empty(),
  loadingIncomeStatement: false,
  businessCharacterization: undefined,
  businessCharacterizationRows: [],
  loadingBusinessCharacterization: false,
  operatingAssets: undefined,
  operatingAssetsRows: [],
  loadingOperatingAssets: false,
  loadingKeyTrends: false,
  keyTrends: [],
  loadingSwotsAnalysis: false,
  swotsAnalysis: [],
  loadingPeerComparisons: false,
  peerComparison: null,
  companies: Page.empty(),
  myWatchlist: [],
  loadingMyWatchlist: false,
  myWatchlistError: '',
  loadingAllCompanies: false,
  loadingCompanyTimeline: false,
  companyStream: emptyStream,
  generatingOneClickReport: false,
  generatingOneClickReportProgress: 0,
  oneClickMemoQuotaExceededError: false,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setLastPeriodSelected: (state, { payload }: PayloadAction<number>) => {
      state.lastPeriodSelected = payload;
    },
    getMyWatchlist: state => {
      state.myWatchlist = [];
      state.loadingMyWatchlist = true;
    },
    getMyWatchlistSuccess: (state, { payload }: PayloadAction<Company[]>) => {
      state.loadingMyWatchlist = false;
      state.myWatchlist = payload;
    },
    getMyWatchlistFailed: (state, { payload }: PayloadAction<string>) => {
      state.loadingMyWatchlist = false;
      //This error state will be used for the error handle improvement
      state.myWatchlistError = payload;
    },
    closeUsageExceededLimitModal: (state: CompanyState): void => {
      state.showUsageLimitExceededModal = false;
    },
    addCompanyToMyWatchlistUsageLimitExceeded: (state: CompanyState): void => {
      state.loadingMyWatchlist = false;
      state.showUsageLimitExceededModal = true;
    },
    addCompanyToMyWatchlistSuccess: state => {
      state.loadingMyWatchlist = false;
      state.companyDetails = { ...state.companyDetails, following: true };
    },
    startUpdatingCompanyInWatchlist: (state: CompanyState): void => {
      state.updatingCompanyInWatchlist = true;
    },
    stopUpdatingCompanyInWatchlist: (state: CompanyState): void => {
      state.updatingCompanyInWatchlist = false;
    },
    addCompanyToMyWatchlistFailed: (state: CompanyState, { payload }: PayloadAction<string>) => {
      state.loadingMyWatchlist = false;
      //This error state will be used for the error handle improvement
      state.myWatchlistError = payload;
    },
    deleteCompanyWatchlistSuccess: (state: CompanyState, { payload }: PayloadAction<string>) => {
      const { myWatchlist } = state;

      state.loadingMyWatchlist = false;
      if (state.companyDetails.id === payload) {
        state.companyDetails.following = false;
      }
      state.myWatchlist = myWatchlist.filter(({ id }: Company): boolean => payload !== id);
    },
    deleteCompanyWatchlistFailed: (state: CompanyState, { payload }: PayloadAction<string>) => {
      state.loadingMyWatchlist = false;
      //This error state will be used for the error handle improvement
      state.myWatchlistError = payload;
    },
    // All Company
    getCompanies: state => {
      state.loadingAllCompanies = true;
      state.companies = Page.empty();
    },
    getCompaniesSuccess: (state: CompanyState, { payload }: PayloadAction<Page<Company>>) => {
      state.loadingAllCompanies = false;
      state.companies = payload;
    },
    getCompaniesFailed: state => {
      state.loadingAllCompanies = false;
    },
    // Company Details
    getCompanyDetails: (state: CompanyState, { payload: companyId }: PayloadAction<string>) => {
      state.loadingCompanyDetails =
        !state.companyDetails || state.companyDetails.organization_id !== companyId;
      state.companyDetails = {} as OrganizationDetails;
    },
    getCompanyDetailsSuccess: (
      state: CompanyState,
      { payload }: PayloadAction<OrganizationDetails>,
    ) => {
      state.loadingCompanyDetails = false;
      state.companyDetails = payload;
    },
    getCompanyDetailsFailed: (state: CompanyState): void => {
      state.loadingCompanyDetails = false;
    },
    clearCompanyDetails: (state: CompanyState): void => {
      state.companyDetails = {} as OrganizationDetails;
    },

    // OverviewAndAlgoNotes Summary
    getSummary: state => {
      state.loadingSummary = true;
    },
    getSummarySuccess: (state, { payload }: PayloadAction<ProfileStatementGroup[]>) => {
      state.loadingSummary = false;
      state.summary = payload;
    },
    getSummaryFailed: state => {
      state.loadingSummary = false;
    },
    // Financials Highlights
    getFinancialHighlights: state => {
      state.loadingFinancialHighlights = true;
    },
    getFinancialHighlightsSuccess: (state, { payload }: PayloadAction<Statement>) => {
      state.loadingFinancialHighlights = false;
      state.financialHighlights = payload;
    },
    getFinancialHighlightsFailed: state => {
      state.loadingFinancialHighlights = false;
    },
    // Financials Income Statements
    getIncomeStatement: state => {
      state.loadingIncomeStatement = true;
    },
    getIncomeStatementSuccess: (state, { payload }: PayloadAction<Statement>) => {
      state.loadingIncomeStatement = false;
      state.incomeStatement = payload;
    },
    getIncomeStatementFailed: state => {
      state.loadingIncomeStatement = false;
    },
    // Financials Cash Flow Statements
    getCashFlow: state => {
      state.loadingCashFlow = true;
    },
    getCashFlowSuccess: (state, { payload }: PayloadAction<Statement>) => {
      state.loadingCashFlow = false;
      state.cashFlow = payload;
    },
    getCashFlowFailed: state => {
      state.loadingCashFlow = false;
    },
    // Financials Balance Sheet
    getBalanceSheet: state => {
      state.loadingBalanceSheet = true;
    },
    getBalanceSheetSuccess: (state, { payload }: PayloadAction<Statement>) => {
      state.loadingBalanceSheet = false;
      state.balanceSheet = payload;
    },
    getBalanceSheetFailed: state => {
      state.loadingBalanceSheet = false;
    },
    // Financials Metrics and Indicators
    getMetricsAndIndicators: state => {
      state.loadingMetricsAndIndicators = true;
    },
    getMetricsAndIndicatorsSuccess: (state, { payload }: PayloadAction<Statement>) => {
      state.loadingMetricsAndIndicators = false;
      state.metricsAndIndicators = payload;
    },
    getMetricsAndIndicatorsFailed: state => {
      state.loadingMetricsAndIndicators = false;
    },
    // Operations Highlights
    getOperationsHighlights: state => {
      state.loadingOperationsHighlights = true;
    },
    getOperationsHighlightsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        operationsHighlights: FinancialInfo;
        operationsHighlightsRows: FinancialRow[];
      }>,
    ) => {
      state.loadingOperationsHighlights = false;
      state.operationsHighlights = payload.operationsHighlights;
      state.operationsHighlightsRows = payload.operationsHighlightsRows;
    },
    getOperationsHighlightsFailed: state => {
      state.loadingOperationsHighlights = false;
    },
    // Operations Business Characterization
    getBusinessCharacterization: state => {
      state.loadingBusinessCharacterization = true;
    },
    getBusinessCharacterizationSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessCharacterization: FinancialInfo;
        businessCharacterizationRows: FinancialRow[];
      }>,
    ) => {
      state.loadingBusinessCharacterization = false;
      state.businessCharacterization = payload.businessCharacterization;
      state.businessCharacterizationRows = payload.businessCharacterizationRows;
    },
    getBusinessCharacterizationFailed: state => {
      state.loadingBusinessCharacterization = false;
    },
    // Operations Operating Assets
    getOperatingAssets: state => {
      state.loadingOperatingAssets = true;
    },
    getOperatingAssetsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ operatingAssets: FinancialInfo; operatingAssetsRows: FinancialRow[] }>,
    ) => {
      state.loadingOperatingAssets = false;
      state.operatingAssets = payload.operatingAssets;
      state.operatingAssetsRows = payload.operatingAssetsRows;
    },
    getOperatingAssetsFailed: state => {
      state.loadingOperatingAssets = false;
    },
    // Trends and Frameworks
    // Key Trends
    getKeyTrends: state => {
      state.loadingKeyTrends = true;
    },
    getKeyTrendsSuccess: (state, { payload }: PayloadAction<{ keyTrends: KeyTrend[] }>) => {
      state.loadingKeyTrends = false;
      state.keyTrends = payload.keyTrends;
    },
    getKeyTrendsFailed: state => {
      state.loadingKeyTrends = false;
    },
    // Swot Analysis
    getSwotsAnalysis: state => {
      state.loadingSwotsAnalysis = true;
    },
    getSwotsAnalysisSuccess: (
      state,
      { payload }: PayloadAction<{ swotsAnalysis: SwotAnalysis[] }>,
    ) => {
      state.loadingSwotsAnalysis = false;
      state.swotsAnalysis = payload.swotsAnalysis;
    },
    getSwotsAnalysisFailed: state => {
      state.loadingSwotsAnalysis = false;
    },
    // Peer Comparison
    getPeerComparisonCompanies: state => {
      state.loadingPeerComparisons = true;
    },
    resetPeerComparisons: state => {
      state.peerComparison = null;
    },
    getPeerComparisonCompaniesSuccess: (
      state,
      { payload }: PayloadAction<{ peerComparison: PeerComparisons }>,
    ) => {
      state.loadingPeerComparisons = false;
      state.peerComparison = payload.peerComparison;
    },
    getPeerComparisonCompaniesFailed: state => {
      state.loadingPeerComparisons = false;
    },
    updateStatementCommentsCount: (
      state,
      {
        payload,
      }: PayloadAction<{
        readonly filingId: string;
        readonly statementName: string;
        readonly comments: any[];
      }>,
    ): void => {
      const { filingId, statementName, comments } = payload;
      const commentsCountReplacer = (group: StatementGroup): StatementGroup => {
        return {
          ...group,
          rows: group.rows.map(
            (row: StatementRow): StatementRow => ({
              ...row,
              values: Object.entries(row.values).reduce(
                (
                  values: Record<string, StatementValue>,
                  [year, oldValue]: [string, StatementValue],
                ): Record<string, StatementValue> => {
                  const commentsCount =
                    oldValue.id === filingId ? comments.length : oldValue.comments_count;
                  const newValue = { ...oldValue, comments_count: commentsCount };

                  return { ...values, [year]: newValue };
                },
                {},
              ),
            }),
          ),
        };
      };

      function updateStatementComments(
        statement: Statement,
        mapper: (group: StatementGroup) => StatementGroup,
      ): Statement {
        const { groups } = statement;
        if (groups === null) {
          console.warn('attempting to add a comment to an empty statement, how did you do this?');
          return statement;
        }

        return { ...statement, groups: groups.map(mapper) };
      }

      // Check which statement we need to update
      switch (statementName.toLocaleLowerCase()) {
        case 'financials':
          state.financialHighlights = updateStatementComments(
            state.financialHighlights,
            commentsCountReplacer,
          );
          break;
        case 'net cash flow':
          state.cashFlow = updateStatementComments(state.cashFlow, commentsCountReplacer);
          break;
        case 'overview':
          state.incomeStatement = updateStatementComments(
            state.incomeStatement,
            commentsCountReplacer,
          );
          break;
        case 'valuation':
          state.metricsAndIndicators = updateStatementComments(
            state.metricsAndIndicators,
            commentsCountReplacer,
          );
          break;
        case 'assets':
          state.balanceSheet = updateStatementComments(state.balanceSheet, commentsCountReplacer);
          break;
      }
    },
    startLoadingCompanyTimeline: (state: CompanyState): void => {
      state.loadingCompanyTimeline = true;
      state.companyStream = { ...state.companyStream, events: [] };
    },
    setCompanyTimelineStream: (
      state: CompanyState,
      { payload }: PayloadAction<readonly Stream[]>,
    ): void => {
      state.companyStream = payload[0] ?? emptyStream;
    },
    stopLoadingCompanyTimeline: (state: CompanyState): void => {
      state.loadingCompanyTimeline = false;
    },
    startGeneratingOneClickReport: (state: CompanyState): void => {
      state.generatingOneClickReport = true;
      state.generatingOneClickReportProgress = 0;
    },
    stopGeneratingOneClickReport: (state: CompanyState): void => {
      state.generatingOneClickReport = false;
      state.generatingOneClickReportProgress = 0;
    },
    updateGeneratingOneClickReportProgress: (
      state: CompanyState,
      { payload }: PayloadAction<number>,
    ): void => {
      state.generatingOneClickReportProgress = payload;
    },
    oneClickMemoQuotaExceeded: (state: CompanyState): void => {
      state.oneClickMemoQuotaExceededError = true;
    },
    oneClickMemoQuotaModalDismissed: (state: CompanyState): void => {
      state.oneClickMemoQuotaExceededError = false;
    },
    clearMetricsAndIndicatorsStatement: (state: CompanyState): void => {
      state.metricsAndIndicators = Statement.empty();
    },
    clearFinancialHighlightsStatement: (state: CompanyState): void => {
      state.financialHighlights = Statement.empty();
    },
    clearIncomeStatement: (state: CompanyState): void => {
      state.incomeStatement = Statement.empty();
    },
    clearCashFlowStatement: (state: CompanyState): void => {
      state.cashFlow = Statement.empty();
    },
    clearBalanceSheetStatement: (state: CompanyState): void => {
      state.balanceSheet = Statement.empty();
    },
  },
});

export const {
  clearIncomeStatement,
  clearMetricsAndIndicatorsStatement,
  clearFinancialHighlightsStatement,
  clearCashFlowStatement,
  clearBalanceSheetStatement,
  updateStatementCommentsCount,
  getMyWatchlist,
  getMyWatchlistFailed,
  getMyWatchlistSuccess,
  deleteCompanyWatchlistSuccess,
  deleteCompanyWatchlistFailed,
  addCompanyToMyWatchlistFailed,
  getCompanies,
  getCompaniesFailed,
  getCompaniesSuccess,
  getCompanyDetails,
  getCompanyDetailsFailed,
  getCompanyDetailsSuccess,
  clearCompanyDetails,
  getSummary,
  getSummaryFailed,
  getSummarySuccess,
  addCompanyToMyWatchlistUsageLimitExceeded,
  addCompanyToMyWatchlistSuccess,
  getFinancialHighlights,
  getFinancialHighlightsSuccess,
  getFinancialHighlightsFailed,
  getIncomeStatement,
  getIncomeStatementFailed,
  getIncomeStatementSuccess,
  getCashFlow,
  getCashFlowSuccess,
  getCashFlowFailed,
  getBalanceSheet,
  getBalanceSheetSuccess,
  getBalanceSheetFailed,
  getMetricsAndIndicators,
  getMetricsAndIndicatorsSuccess,
  getMetricsAndIndicatorsFailed,

  getPeerComparisonCompanies,
  getPeerComparisonCompaniesFailed,
  getPeerComparisonCompaniesSuccess,

  startLoadingCompanyTimeline,
  stopLoadingCompanyTimeline,
  setCompanyTimelineStream,

  startUpdatingCompanyInWatchlist,
  stopUpdatingCompanyInWatchlist,

  startGeneratingOneClickReport,
  stopGeneratingOneClickReport,

  oneClickMemoQuotaExceeded,
  oneClickMemoQuotaModalDismissed,

  updateGeneratingOneClickReportProgress,
  resetPeerComparisons,

  closeUsageExceededLimitModal,
} = companySlice.actions;

// My Watchlist
export const loadingMyWatchlistSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingMyWatchlist;
export const myWatchlistSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.myWatchlist;
// All companies list
export const loadingCompaniesSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingAllCompanies;
export const companiesSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.companies;
// Company Details
export const loadingCompanyDetailsSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingCompanyDetails;
export const companyDetailsSelector = (state: ApplicationState) =>
  state.companyReducer.companyDetails;
export const companyNameSelector = (state: ApplicationState) =>
  state.companyReducer.companyDetails.name;
export const updatingCompanyInWatchlist = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.updatingCompanyInWatchlist;
// Value Chain

export const companyStreamSelector = (state: { companyReducer: CompanyState }): Stream =>
  state.companyReducer.companyStream;
export const loadingCompanyTimeline = (state: { companyReducer: CompanyState }): boolean =>
  state.companyReducer.loadingCompanyTimeline;

// OverviewAndAlgoNotes
export const loadingSummarySelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingSummary;
export const summarySelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.summary;
// Financials
export const loadingFinancialHighlightsSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingFinancialHighlights;
export const financialHighlightsSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.financialHighlights;
export const loadingIncomeStatementSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingIncomeStatement;
export const incomeStatementSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.incomeStatement;
export const loadingCashFlowSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingCashFlow;
export const cashFlowSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.cashFlow;
export const loadingBalanceSheetSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingBalanceSheet;
export const balanceSheetSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.balanceSheet;
export const loadingMetricsAndIndicatorsSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingMetricsAndIndicators;
export const metricsAndIndicatorsSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.metricsAndIndicators;

export const generatingOneClickReportSelector = (state: {
  companyReducer: CompanyState;
}): boolean => state.companyReducer.generatingOneClickReport;
export const generatingOneClickReportProgressSelector = (state: {
  companyReducer: CompanyState;
}): number => state.companyReducer.generatingOneClickReportProgress;

export const oneClickMemoQuotaExceededErrorSelector = (state: {
  companyReducer: CompanyState;
}): boolean => state.companyReducer.oneClickMemoQuotaExceededError;

export const loadingPeerComparisonSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.loadingPeerComparisons;
export const peerComparisonSelector = (state: { companyReducer: CompanyState }) =>
  state.companyReducer.peerComparison;
export const showUsageLimitExceededModalSelector = (state: ApplicationState): boolean =>
  state.companyReducer.showUsageLimitExceededModal;

export default companySlice.reducer;
