import { Clickable } from 'components/clickable';
import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BillingInterval,
  Feature,
  SubscriptionPlan,
} from 'redux/reducers/subscriptionPlansReducer';
import { useFeaturesQuery, useSubscriptionPlansQuery } from 'views/Landing/LandingPage/api';
import { SubscriptionPlanItem } from 'views/SubscriptionPlans/subscriptionPlanItem';
import { SubscriptionPlanItemHeaderPlaceholder } from 'views/SubscriptionPlans/subscriptionPlanItemHeaderPlaceholder';

export const OurPlans: React.FC = (): React.ReactElement => {
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);

  const { data: subscriptions = [] } = useSubscriptionPlansQuery();
  const { data: features = [] } = useFeaturesQuery();

  const navigate = useNavigate();

  const goToSignUp = useCallback((): void => {
    navigate('/sign-up', { state: { origin: 'landing-page' } });
  }, [navigate]);

  const hideSelectedFeature = useCallback((): void => setSelectedFeature(null), []);

  return (
    <div className="flex flex-col items-center justify-center gap-8 mt-24">
      <h1 className="text-gray-darkest text-2xl md:text-4xl title">Our Plans</h1>
      <div className="flex items-center justify-center overflow-auto">
        <div className="flex items-start justify-center gap-6">
          <div className="border-2 border-transparent py-3">
            <SubscriptionPlanItemHeaderPlaceholder />
            {features.map((feature: Feature): React.ReactElement => {
              return (
                <Clickable key={feature.id} clickData={feature} onClick={setSelectedFeature}>
                  <p className="h-8 leading-8 odd:bg-gray-light px-4 truncate cursor-pointer text-blue">
                    {feature.name}
                  </p>
                </Clickable>
              );
            })}
          </div>

          <div className="flex items-start justify-evenly gap-3 overflow-auto">
            {subscriptions.map(
              (plan: SubscriptionPlan): React.ReactElement => (
                <SubscriptionPlanItem
                  key={plan.id}
                  features={features}
                  busy={false}
                  subscription={plan}
                  selected={false}
                  active={false}
                  billingInterval={BillingInterval.monthly}
                  onSelected={goToSignUp}
                />
              ),
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={selectedFeature !== null} onClose={hideSelectedFeature}>
        <Modal.Content title={selectedFeature?.name}>
          <div className="w-modal-sm pt-5">
            <p>{selectedFeature?.description}</p>
          </div>
          <div className="flex items-center justify-end mt-8">
            <Button type="button" variant="secondary" label="Close" onClick={hideSelectedFeature} />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};
