import { AnyAction, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { ContactForm } from 'components/contactUs/ContactUs';
import { PerModuleAuthorization } from 'context/authorization';
import mixpanel from 'mixpanel-browser';
import { ApplicationState } from 'redux/reducers/store';
import { BillingInterval } from 'redux/reducers/subscriptionPlansReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { ProcessingState } from 'types/processingState';
import { API_V1_PATH, isAxiosError } from 'utils/config/axiosConfig';
import {
  HttpStatusConflict,
  HttpStatusCreated,
  HttpStatusOk,
  HttpStatusPreconditionFailed,
  HttpStatusQuotaExceeded,
  HttpStatusTooManyRequests,
  HttpStatusUnauthorized,
  HttpStatusUnverifiedRegisteredUser,
  HttpStatusUserBlocked,
  HttpStatusUserSuspended,
} from 'utils/statusCodes';
import { SignUpFormState } from 'views/Landing/SignUp/types';

export const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';

export enum AuthProcessingStateData {
  unknownError = -2,
  none = -1,

  // HTTP status codes
  created = HttpStatusCreated,
  loginSuccess = HttpStatusOk,
  conflict = HttpStatusConflict,
  quotaFullOrInWaitingList = HttpStatusQuotaExceeded,
  unverifiedRegisteredUser = HttpStatusUnverifiedRegisteredUser,
  wrongPassword = HttpStatusUnauthorized,
  userNotVerified = HttpStatusPreconditionFailed,
  tooManyWrongPasswordAttempts = HttpStatusTooManyRequests,
  userSuspended = HttpStatusUserSuspended,
  userBlocked = HttpStatusUserBlocked,

  // Non-status codes
  requestEmailVerification = 1,
  requestUnlockAccount = 2,
  requestPasswordReset = 3,
  emailVerified = 4,
  accountUnlocked = 5,
  passwordUpdated = 6,
  contactUsSucceeded = 7,

  requestEmailVerificationError = 8,
  requestUnlockAccountError = 9,
  requestPasswordResetError = 10,
  verifyEmailError = 11,
  unlockAccountError = 12,
  updatePasswordError = 13,
  contactUsError = 14,

  registrationSucceeded = 15,

  me = 16,
  refresh = 17,
}

export interface RedirectState {
  readonly processingState: AuthProcessingStateData;
  readonly email: string;
}

export interface AuthState {
  authenticated: boolean;
  session: Session;
  processingState: ProcessingState<AuthProcessingStateData>;
  client: ApplicationClient | null;
}

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  free = 'free',
}

export enum InvoiceStatus {
  draft = 'draft',
  open = 'open',
  void = 'void',
  paid = 'paid',
  uncollectible = 'uncollectible',
  freeTrial = 'free-trial',
}

export interface Invoice {
  readonly id: string;
  readonly type: 'subscription' | 'other';
}

export interface SubscriptionInvoice extends Invoice {
  readonly type: 'subscription';
  readonly subscription_plan_id: string;
  readonly subscription_plan_name: string;
  readonly status: InvoiceStatus;
  readonly billing_interval: BillingInterval;
  readonly total: number;
  readonly amount_remaining: number;
  readonly amount_paid: number;
  readonly created_at: string;
  readonly expires_at: string;
}

export type CardBrand = 'visa' | 'mastercard';

export interface Card {
  readonly id: string;
  readonly card_holder_name: string;
  readonly last4: string;
  readonly brand: CardBrand;
  readonly exp_month: number;
  readonly exp_year: number;
}

export interface UserSubscription {
  id: string;
  name: string;
  description: string;
  price: number;
  features: {
    [key: string]: boolean;
  };
  billing_cycle?: BillingInterval;
  expires_at: string;
  purchased_at: string;
  free_trial_days: number;
  is_automatic_renewal_on: boolean;
  is_free_trial?: boolean;
  upgradable: boolean;
  authorization: PerModuleAuthorization;
  status: SubscriptionStatus;
  payment_method?: Card;
  feature_level: number;
}

export interface User {
  readonly id: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly email: string;
  readonly account_type: 'Public' | 'Private' | 'Hidden' | 'Invalid' | 'Owner';
  readonly created_at: string;
  readonly sessions_count: number;
  readonly last_session: string;
  readonly has_verified_email: boolean;
  readonly onboarded: boolean;
  readonly accredited_investor: boolean;
  readonly photo_url?: string;
  readonly subscription?: UserSubscription;
  readonly payment_methods?: readonly Card[];
}

export class User {
  public static empty(): User {
    return {
      id: '',
      firstname: '',
      lastname: '',
      email: '',
      account_type: 'Invalid',
      created_at: '',
      sessions_count: 2,
      last_session: '',
      has_verified_email: false,
      accredited_investor: false,
      onboarded: true,
      subscription: {
        id: '',
        name: '',
        description: '',
        price: 0,
        features: {},
        billing_cycle: BillingInterval.monthly,
        expires_at: '',
        free_trial_days: 0,
        is_automatic_renewal_on: false,
        is_free_trial: false,
        upgradable: true,
        authorization: {},
        purchased_at: '',
        status: SubscriptionStatus.active,
        feature_level: Number.MAX_SAFE_INTEGER,
      },
    };
  }
}

interface ApplicationClient {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly origin: string;
  readonly logo_uri: string;
}

export interface Session {
  readonly token: string;
  readonly session_count: number;
  readonly user: User;
}

const initialState: AuthState = {
  authenticated: false,
  session: {
    token: '',
    session_count: 0,
    user: User.empty(),
  },
  client: null,
  processingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setClient: (state: AuthState, action: PayloadAction<ApplicationClient>): void => {
      state.client = action.payload;
    },
    started: (state: AuthState): void => {
      const { data } = state.processingState;
      // Copy current auth processing state so that UI keeps consistent
      state.processingState = ProcessingState.processing(data);
    },
    failed: (state: AuthState, { payload }: PayloadAction<AuthProcessingStateData>): void => {
      state.processingState = ProcessingState.error(payload);
    },
    succeeded: (state: AuthState, { payload }: PayloadAction<AuthProcessingStateData>): void => {
      state.processingState = ProcessingState.success(payload);
    },
    meSucceeded: (state: AuthState, { payload }: PayloadAction<User>): void => {
      state.session = { ...state.session, user: payload };
      state.processingState = ProcessingState.idle();
    },
    loginSucceeded: (state: AuthState, { payload }: PayloadAction<Session>): void => {
      state.processingState = ProcessingState.success();
      state.session = { ...state.session, ...payload };
      state.authenticated = true;
    },
    logoutCompleted: (state: AuthState): void => {
      state.authenticated = false;
    },
    clearProcessingState: (state: AuthState): void => {
      state.processingState = ProcessingState.idle();
    },
    setCurrentPlan: (state: AuthState, { payload }: PayloadAction<UserSubscription>): void => {
      const { user } = state.session;
      state.session = { ...state.session, user: { ...user, subscription: payload } };
    },
    setProbablyAuthenticated: (state: AuthState): void => {
      state.authenticated = true;
    },
  },
});

const actions = slice.actions;

export const {
  clearProcessingState,
  loginSucceeded,
  logoutCompleted,
  started,
  succeeded,
  failed,
  meSucceeded,
  setCurrentPlan,
  setClient,
  setProbablyAuthenticated,
} = actions;

export const logoURISelector = (state: ApplicationState): string =>
  state.auth.client?.logo_uri ?? '/logo.svg';

export const authenticatedSelector = (state: ApplicationState): boolean => state.auth.authenticated;
export const userSelector = (state: ApplicationState): User => state.auth.session.user;
export const sessionSelector = (state: ApplicationState): Session => state.auth.session;
export const authProcessingStateSelector = (
  state: ApplicationState,
): ProcessingState<AuthProcessingStateData> => state.auth.processingState;

export default slice.reducer;

export const isAuthProcessingStateData = (
  value: any | AuthProcessingStateData,
): value is AuthProcessingStateData => {
  const values = Object.values(AuthProcessingStateData);
  return values.includes(value);
};

export const isRedirectState = (value: any | RedirectState): value is RedirectState => {
  if (value === null) {
    return false;
  }

  return (
    'processingState' in value &&
    isAuthProcessingStateData(value.processingState) &&
    'email' in value &&
    typeof value.email === 'string'
  );
};

export const logout =
  (): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    dispatch(actions.logoutCompleted());
  };

export const login = createAPIAction(function login(client: HttpClient): ActionsGenerator {
  return async function* (email: string, password: string): AsyncGenerator<AnyAction> {
    yield actions.started();

    try {
      const response = await client.POST(`${API_V1_PATH}/auth/login`, { email, password });
      const session: Session = response.data;
      const { user } = session;

      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, session.token);
      mixpanel.identify(user.email);

      const utm_source = mixpanel.get_property('utm_source');
      const utm_campaign = mixpanel.get_property('utm_campaign');

      mixpanel.people.set({
        $name: `${user.firstname} ${user.lastname}`,
        $email: user.email,
        utm_campaign,
        utm_source,
      });
      yield actions.loginSucceeded(response.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        yield actions.failed(error.response?.status ?? AuthProcessingStateData.none);
      } else {
        yield actions.failed(AuthProcessingStateData.unknownError);
      }
    }
  };
});

export const signUp = createAPIAction(function login(client: HttpClient): ActionsGenerator {
  return async function* (data: SignUpFormState): AsyncGenerator<AnyAction> {
    yield actions.started();

    try {
      await client.POST(`${API_V1_PATH}/auth/register`, data);
      yield actions.succeeded(AuthProcessingStateData.registrationSucceeded);
    } catch (error: any) {
      if (isAxiosError(error)) {
        yield actions.failed(error.response?.status ?? AuthProcessingStateData.none);
      } else {
        yield actions.failed(AuthProcessingStateData.unknownError);
      }
    }
  };
});

export const requestUnlockAccount = createAPIAction(function requestUnlockAccount(
  client: HttpClient,
): ActionsGenerator {
  return async function* (email: string): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.POST(`${API_V1_PATH}/auth/request-unlock-account`, { email });
      yield actions.succeeded(AuthProcessingStateData.requestUnlockAccount);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.requestUnlockAccountError);
    }
  };
});

export const requestEmailVerification = createAPIAction(function requestEmailVerification(
  client: HttpClient,
): ActionsGenerator {
  return async function* (email: string): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.POST(`${API_V1_PATH}/auth/request-verify-email`, { email });
      yield actions.succeeded(AuthProcessingStateData.requestEmailVerification);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.requestEmailVerificationError);
    }
  };
});

export const unlockAccount = createAPIAction(function unlockAccount(
  client: HttpClient,
): ActionsGenerator {
  return async function* (code: string): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.GET(`${API_V1_PATH}/auth/unlock-account`, { code });
      yield actions.succeeded(AuthProcessingStateData.accountUnlocked);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.unlockAccountError);
    }
  };
});

export const verifyEmail = createAPIAction(function verifyEmail(
  client: HttpClient,
): ActionsGenerator {
  return async function* (code: string): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.GET(`${API_V1_PATH}/auth/verify-email`, { code });
      yield actions.succeeded(AuthProcessingStateData.emailVerified);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.verifyEmailError);
    }
  };
});

export const requestPasswordReset = createAPIAction(function requestPasswordReset(
  client: HttpClient,
): ActionsGenerator {
  return async function* (email: string): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.POST(`${API_V1_PATH}/auth/request-reset-password`, { email });
      yield actions.succeeded(AuthProcessingStateData.requestPasswordReset);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.requestPasswordResetError);
    }
  };
});

export const updatePassword = createAPIAction(function updatePassword(
  client: HttpClient,
): ActionsGenerator {
  return async function* (code: string, password: string): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.POST(`${API_V1_PATH}/auth/reset-password`, { password, code });
      yield actions.succeeded(AuthProcessingStateData.passwordUpdated);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.updatePasswordError);
    }
  };
});

export const contactUs = createAPIAction(function contactUs(client: HttpClient): ActionsGenerator {
  return async function* (data: ContactForm): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await client.POST(`${API_V1_PATH}/auth/contact-us`, data);
      yield actions.succeeded(AuthProcessingStateData.contactUsSucceeded);
    } catch (error: any) {
      yield actions.failed(AuthProcessingStateData.contactUsError);
    }
  };
});
