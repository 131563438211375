import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import { TimestampDateInput } from 'components/timestampDateInput';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFormValue } from 'redux/reducers/addPortfolioItemFormReducer';
import { PortfolioCompanyInvestmentSharesOrEquityItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyInvestmentSharesOrEquityItem;
}

export const InvestmentSharesOrEquityItemForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyInvestmentSharesOrEquityItem>();

  useEffect((): void => {
    if (item.numberOfShares === null || item.numberOfShares === undefined) {
      return;
    }

    if (item.averagePricePerShare === null || item.averagePricePerShare === undefined) {
      return;
    }

    dispatch(
      setFormValue({
        key: 'totalCostBasis',
        value: item.numberOfShares * item.averagePricePerShare,
      }),
    );
    dispatch(
      setFormValue({
        key: 'totalValueOfShares',
        value: item.numberOfShares * item.averagePricePerShare,
      }),
    );
  }, [item.numberOfShares, item.averagePricePerShare, dispatch]);

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <TimestampDateInput
        name="purchaseTimestamp"
        label="Purchase Date"
        value={item.purchaseTimestamp}
        onChange={updaterFor('purchaseTimestamp')}
      />
      <NumericInput
        name="numberOfShares"
        label="Number of shares"
        value={item.numberOfShares}
        precision={0}
        onValueChange={updaterFor('numberOfShares')}
      />
      <Select
        id="currency"
        label="Currency"
        items={currencies}
        value={item.currency}
        readOnly={true}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="averagePricePerShare"
        label="Average price per share"
        value={item.averagePricePerShare}
        onValueChange={updaterFor('averagePricePerShare')}
      />
      <NumericInput
        name="totalCostBasis"
        label="Total cost basis"
        value={item.totalCostBasis}
        readOnly={true}
        onValueChange={updaterFor('totalCostBasis')}
      />
      <NumericInput
        name="totalValueOfShares"
        label="Total Value of shares"
        value={item.totalValueOfShares}
        readOnly={true}
        onValueChange={updaterFor('totalValueOfShares')}
      />
    </div>
  );
};
