import { AmountInput } from 'components/amountInput';
import { Input } from 'components/forms/input';
import { RadioButton } from 'components/radioButton';
import { Select, SelectItem } from 'components/select';
import React from 'react';
import { useSelector } from 'react-redux';
import { countriesSelector, Country } from 'redux/reducers/sharedReducer';
import { PortfolioArtPaintingItem, TaxBenefitValues } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioArtPaintingItem;
}

export const PaintingArtItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioArtPaintingItem>();
  const rawCountries = useSelector(countriesSelector);

  const countries = React.useMemo((): ReadonlyArray<SelectItem<string>> => {
    return rawCountries.map(
      (country: Country): SelectItem<string> => ({
        value: country.iso2,
        label: country.name,
      }),
    );
  }, [rawCountries]);

  return (
    <div>
      {/* FIXME: Upload files here */}
      <Input
        name="pictureUrl"
        label="Picture URL"
        value={item.pictureUrl}
        onChange={updaterFor('pictureUrl')}
      />
      <Input
        name="artistName"
        label="Artist Name"
        value={item.artistName}
        onChange={updaterFor('artistName')}
      />
      <div className="grid grid-cols-2 gap-x-3 gap-y-2">
        <Select
          id="artistNationality"
          label="Artist Nationality"
          items={countries}
          value={item.artistNationality}
          onChange={updaterFor('artistNationality')}
        />
        <Select
          id="artOrigin"
          label="Art Origin"
          items={artOrigins}
          value={item.artOrigin}
          onChange={updaterFor('artOrigin')}
        />
        <Select
          id="yearOfProduction"
          label="Year of Production"
          items={years}
          value={item.yearOfProduction}
          onChange={updaterFor('yearOfProduction')}
        />
        <Input
          name="provenance"
          label="Provenance"
          value={item.provenance}
          onChange={updaterFor('provenance')}
        />
        <Select
          id="holdingLocation"
          label="Holding Location"
          items={countries}
          value={item.holdingLocation}
          onChange={updaterFor('holdingLocation')}
        />
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <AmountInput
          name="purchasePrice"
          value={item.purchasePriceAmount}
          currency={item.purchasePriceCurrency}
          label="Purchase Price"
          onCurrencyChange={updaterFor('purchasePriceCurrency')}
          onValueChange={updaterFor('purchasePriceAmount')}
        />
        <AmountInput
          name="appraisalValue"
          value={item.appraisalValueAmount}
          currency={item.appraisalValueCurrency}
          label="Appraisal Value"
          onCurrencyChange={updaterFor('appraisalValueCurrency')}
          onValueChange={updaterFor('appraisalValueAmount')}
        />
      </div>
      {item.appraisalValueAmount > item.purchasePriceAmount &&
        item.appraisalValueCurrency === item.purchasePriceCurrency && (
          <div className="mt-4">
            <div className="mt-2 mb-4">
              <p className="font-poppinsMedium text-xs text-gray">
                Do you want to borrow against it?
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-3">
              <RadioButton
                id="wantsToBorrowYes"
                label="Yes"
                value={true}
                checked={item.wantsToBorrow}
                onChange={updaterFor('wantsToBorrow')}
              />
              <RadioButton
                id="wantsToBorrowNo"
                label="No"
                value={false}
                checked={!item.wantsToBorrow}
                onChange={updaterFor('wantsToBorrow')}
              />
            </div>
            <div className="mt-2 mb-4">
              <p className="font-poppinsMedium text-xs text-gray">Do you want to borrow sell?</p>
            </div>
            <div className="grid grid-cols-2 gap-x-3">
              <RadioButton
                id="wantsToSellYes"
                label="Yes"
                value={true}
                checked={item.wantsToSell}
                onChange={updaterFor('wantsToSell')}
              />
              <RadioButton
                id="wantsToSelNo"
                label="No"
                value={false}
                checked={!item.wantsToSell}
                onChange={updaterFor('wantsToSell')}
              />
            </div>
          </div>
        )}
      <div className="mt-2 mb-4">
        <p className="font-poppinsMedium text-xs text-gray">Receiving tax benefits? </p>
      </div>
      <div className="grid grid-cols-2 gap-x-3">
        <RadioButton
          id="recevingTaxBenefitsYes"
          label="Yes"
          value={TaxBenefitValues.yes}
          checked={item.receivingTaxBenefits === TaxBenefitValues.yes}
          onChange={updaterFor('receivingTaxBenefits')}
        />
        <RadioButton
          id="recevingTaxBenefitsNo"
          label="No"
          value={TaxBenefitValues.no}
          checked={item.receivingTaxBenefits === TaxBenefitValues.no}
          onChange={updaterFor('receivingTaxBenefits')}
        />
        <RadioButton
          id="recevingTaxBenefitsDoesNotKnow"
          label="Don't Know"
          value={TaxBenefitValues.doesNotKnow}
          checked={item.receivingTaxBenefits === TaxBenefitValues.doesNotKnow}
          onChange={updaterFor('receivingTaxBenefits')}
        />
      </div>
      <div className="mt-2 mb-4">
        <p className="font-poppinsMedium text-xs text-gray">Earning Income From Display?</p>
      </div>
      <div className="grid grid-cols-2 gap-x-3">
        <RadioButton
          id="earningIncomeFromDisplayYes"
          label="Yes"
          value={true}
          checked={item.earningIncomeFromDisplay}
          onChange={updaterFor('earningIncomeFromDisplay')}
        />
        <RadioButton
          id="earningIncomeFromDisplayYes"
          label="No"
          value={false}
          checked={!item.earningIncomeFromDisplay}
          onChange={updaterFor('earningIncomeFromDisplay')}
        />
      </div>
    </div>
  );
};

const artOrigins = [
  { value: 'USA', label: 'USA' },
  { value: 'UK', label: 'UK' },
  { value: 'Africa', label: 'Africa' },
];

const years = new Array(400).fill(0).map((_, index) => {
  const year = new Date().getFullYear() - index;
  return { value: year, label: year.toString() };
});
