import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { ApplicationState } from 'redux/reducers/store';
import { StatementRequestOptions } from 'redux/services/companyService';
import { StatementPeriod } from 'types/financial';

export interface FinancialStatementsState {
  options: StatementRequestOptions;
}

const defaultPeriodsCount = 5;
const now = new Date();
const nowKey = format(now, 'yyyy-MM-dd');

const initialState: FinancialStatementsState = {
  options: {
    dimension: 'MRY',
    convert_to_usd: false,
    periods_final_year: now.getFullYear(),
    periods_final_key: '',
    periods_count: 5,
  },
};

const slice = createSlice({
  name: 'financialStatements',
  initialState: initialState,
  reducers: {
    setConvertToUSDFlag: (
      state: FinancialStatementsState,
      { payload }: PayloadAction<boolean>,
    ): void => {
      state.options = { ...state.options, convert_to_usd: payload };
    },
    setStatementDimension: (
      state: FinancialStatementsState,
      { payload }: PayloadAction<'MRQ' | 'MRY'>,
    ): void => {
      switch (payload) {
        case 'MRQ':
          state.options = {
            dimension: payload,
            periods_final_quarter: 4,
            periods_final_year: now.getFullYear(),
            periods_final_key: nowKey,
            periods_count: defaultPeriodsCount,
          };
          break;
        case 'MRY':
          state.options = {
            dimension: payload,
            periods_final_year: now.getFullYear(),
            periods_final_key: nowKey,
            periods_count: defaultPeriodsCount,
          };
          break;
        default:
          throw new Error('invalid statement dimension');
      }
    },
    setStatementPeriod: (
      state: FinancialStatementsState,
      { payload }: PayloadAction<StatementPeriod>,
    ): void => {
      switch (payload.dimension) {
        case 'MRQ':
          state.options = {
            ...state.options,
            periods_final_year: payload.year,
            periods_final_quarter: payload.quarter,
            periods_final_key: payload.access_key,
            periods_count: defaultPeriodsCount,
          };
          break;
        case 'MRY':
          state.options = {
            ...state.options,
            periods_final_year: payload.year,
            periods_final_key: payload.access_key,
            periods_count: defaultPeriodsCount,
          };
          break;
      }
    },
    resetFinancialStatementsOptions: (state: FinancialStatementsState): void => {
      state.options = initialState.options;
    },
  },
});

export const financialStatementsOptionsSelector = (
  state: ApplicationState,
): StatementRequestOptions => state.financialStatements.options;

export const {
  setConvertToUSDFlag,
  setStatementPeriod,
  setStatementDimension,
  resetFinancialStatementsOptions,
} = slice.actions;

export default slice.reducer;
