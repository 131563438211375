import { Modal } from 'components/modal';
import React, { useMemo } from 'react';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { PortfolioItemFormEditCreateForm } from 'views/Portfolios/PortfoliosModal/portfolioItemFormEditCreateForm';

export interface Props {
  readonly name: string;
  readonly portfolioId: string;
  readonly relationshipId?: string;
  readonly itemId?: string;

  onClose(): void;
}

const PortfolioItemForm: React.FC<Props> = ({
  name,
  portfolioId,
  itemId,
  onClose,
}: Props): React.ReactElement => {
  const modalTitle = useMemo(
    (): string => `${itemId ? 'Edit an' : 'Add'} item ${itemId ? 'of' : 'to'} ${name}`,
    [itemId, name],
  );

  return (
    <Modal.Content title={modalTitle}>
      <div className="flex flex-col relative gap-2 min-h-80 overflow-hidden">
        {isEmptyOrNullOrUndefined(itemId) ? (
          <PortfolioItemFormEditCreateForm portfolioId={portfolioId} onClose={onClose} />
        ) : (
          <>
            <div className="w-modal-sm">
              <div className="transition duration-500 overflow-hidden">
                <div className="inline-block w-modal-sm outline-debug">
                  <PortfolioItemFormEditCreateForm
                    portfolioId={portfolioId}
                    itemId={itemId}
                    onClose={onClose}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal.Content>
  );
};

export default PortfolioItemForm;
