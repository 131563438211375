import React from 'react';
import { Organization } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { OperationType } from 'types/operationType';
import { OrganizationDetails } from 'types/organization/types';
import { RevenuesTable } from 'views/SmartTools/GeographiesAndMaps/components/revenuesTable';
import { SubsidiariesTable } from 'views/SmartTools/GeographiesAndMaps/components/subsidiariesTable';

interface Props {
  readonly filter: string | null;
  readonly company: Organization | OrganizationDetails | null;
}

export const DataTable: React.FC<Props> = ({ filter, company }: Props): React.ReactElement => {
  if (company === null) {
    return <></>;
  }

  switch (filter) {
    case OperationType.subsidiaries:
      return <SubsidiariesTable company={company} />;
    case OperationType.revenues:
      return <RevenuesTable />;
  }

  return <></>;
};
