import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Modal } from 'components/modal';
import { noop } from 'lodash';
import React from 'react';
import { ListingStatus, useGetSpecialOfferQuery } from 'views/EarnAndBuy/api';
import { Status } from 'views/EarnAndBuy/common/status';
import { TagsList } from 'views/Home/components/shared/tagsList';

interface Props {
  readonly offerId?: string;
}

export const Details: React.FC<Props> = (props: Props): React.ReactElement => {
  const { offerId } = props;
  const { data: specialOffer, isFetching } = useGetSpecialOfferQuery(offerId);

  if (!offerId) {
    throw new Error('offer id cannot be null');
  }

  // Something is wrong with the IDE?
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const status = React.useMemo((): ListingStatus | undefined => {
    return specialOffer?.status;
  }, [specialOffer?.status]);

  return (
    <Modal.Content title={specialOffer?.title} closeable={true}>
      <div className="px-1 mt-8">
        <div className="relative w-modal-sm grid grid-cols-2 gap-4">
          <h6 className="font-poppinsMedium text-gray-dark">Description</h6>
          <p>{specialOffer?.description}</p>
          <h6 className="font-poppinsMedium text-gray-dark">Tags</h6>
          <TagsList tags={specialOffer?.tags ?? []} expanded={false} onExpand={noop} />
          <h6 className="font-poppinsMedium text-gray-dark leading-7">Status</h6>
          <div>
            <Status status={status} />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <a
            href={specialOffer?.url}
            target="_blank"
            rel="noreferrer"
            className="font-jostSemiBold text-blue"
          >
            Go to special offer
          </a>
        </div>
        <FullScreenLoadingSpinner visible={isFetching} />
      </div>
    </Modal.Content>
  );
};
