import 'components/toggle/Toggle.scss';

import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly label: string;
  readonly fullWidth?: boolean;
  readonly id?: string;
  readonly toggleId?: string;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly flat?: boolean;

  onChange(value: boolean): void;
}

const Toggle: React.FC<Props> = ({
  id,
  label,
  flat = false,
  toggleId,
  checked,
  fullWidth = false,
  disabled = false,
  onChange,
}) => {
  const handleToggled = useCallback((): void => {
    if (disabled) {
      return;
    }

    onChange(!checked);
  }, [checked, disabled, onChange]);

  const containerClass = useMemo(
    (): string =>
      [
        'flex items-center',
        fullWidth ? 'justify-between' : undefined,
        disabled ? 'opacity-40' : 'cursor-pointer',
      ].join(' '),
    [disabled, fullWidth],
  );

  const switchButtonClass = useMemo((): string => {
    return [
      'relative inline-block w-9 h-5 align-middle select-none rounded-full focus:outline-none overflow-hidden',
      checked ? 'bg-blue' : 'bg-gray-500',
      !flat && 'shadow-md',
    ].join(' ');
  }, [checked, flat]);

  const labelClass = useMemo((): string | undefined => {
    return label.trim() === ''
      ? undefined
      : 'text-base text-gray-darkest text-left mr-3 font-poppins leading-8';
  }, [label]);

  const checkIndicatorClass = useMemo((): string => {
    return [
      'bg-white w-5 h-5 rounded-full border transition transform',
      checked ? 'border-blue translate-x-4' : 'border-gray-500 translate-x-0',
    ].join(' ');
  }, [checked]);

  return (
    <div id={id} className={containerClass} onClick={handleToggled}>
      <label htmlFor={toggleId} className={labelClass}>
        {label}
      </label>
      <div className={switchButtonClass}>
        <div className={checkIndicatorClass} />
      </div>
    </div>
  );
};

export default Toggle;
