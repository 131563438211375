import { RadioButton } from 'components/radioButton';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setFormValue } from 'redux/reducers/addPortfolioItemFormReducer';
import { PortfolioCompanyEmployerItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioCompanyEmployerItem;
}

export const EmployerItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const yes = useMemo((): boolean => item.receiveStockOptions === true, [item.receiveStockOptions]);
  const no = useMemo((): boolean => item.receiveStockOptions === false, [item.receiveStockOptions]);
  const unknown = useMemo(
    (): boolean => item.receiveStockOptions === null,
    [item.receiveStockOptions],
  );

  const handleYes = useCallback((): void => {
    dispatch(setFormValue({ key: 'receiveStockOptions', value: true }));
  }, [dispatch]);

  const handleNo = useCallback((): void => {
    dispatch(setFormValue({ key: 'receiveStockOptions', value: false }));
  }, [dispatch]);

  const handleUnknown = useCallback((): void => {
    dispatch(setFormValue({ key: 'receiveStockOptions', value: null }));
  }, [dispatch]);

  return (
    <>
      <div className="mt-2">
        <p className="font-poppinsMedium text-xs text-gray">Do you receive stock options?</p>
      </div>
      <div className="grid grid-cols-4 gap-x-8">
        <RadioButton id="yes" label="Yes" value="Yes" checked={yes} onChange={handleYes} />
        <RadioButton id="no" label="No" value="No" checked={no} onChange={handleNo} />
        <RadioButton
          id="dontKnow"
          label="I don't know"
          value="-"
          checked={unknown}
          onChange={handleUnknown}
        />
      </div>
    </>
  );
};
