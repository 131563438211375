import React from 'react';
import { Connection } from 'redux/reducers/connectionsReducer';
import { Page } from 'types/page';
import { SearchBox } from 'views/Connections/components/SearchBox';
import { FollowAction } from 'views/Connections/Overview/components/FollowButton';
import FollowListItem from 'views/Connections/Overview/components/FollowListItem';

interface Props {
  readonly connections: Readonly<Page<Connection>>;

  onFollowAction(followAction: FollowAction, userId: string): void;
}

const ConnectionsTable: React.FC<Props> = ({
  connections,
  onFollowAction,
}: Props): React.ReactElement => {
  return (
    <>
      <div className="mt-6 w-1/2">
        <SearchBox container={document.body} />
      </div>
      <div className="relative mt-6 mb-4 w-full flex-grow">
        {connections.data.map(
          (user: Connection): React.ReactElement => (
            <FollowListItem key={user.id} user={user} onClick={onFollowAction} />
          ),
        )}
      </div>
    </>
  );
};

export default ConnectionsTable;
