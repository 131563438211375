import { TruncateType } from 'components/truncate/baseProps';
import { TruncateElements, TruncateElementsProps } from 'components/truncate/truncateElements';
import { TruncateText, TruncateTextProps } from 'components/truncate/truncateText';
import React from 'react';

type Props = TruncateTextProps | TruncateElementsProps;

export const Truncate: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { children, ellipsis, contentType } = props;
  // Let's handle 2 cases.
  //
  // 1. When there's a single child <p /> and it has a single text node inside. We split the text
  //    word by word and detect the potential overflow.
  //
  //    Upon the overflow, we stop. In this case the ellipsis can only possibly be a React.ReactElement and
  //    will not be changing its width when rendered.
  //
  // 2. We have a single list of elements. Same approach but one child at a time. In this case rendering the
  //    ellipsis is required and needs to be done with by passing the number of total items and the number of
  //    rendered items so far. This way we can keep track of the width of the ellipsis and also give the renderer
  //    the opportunity to display the number of hidden items.
  if (contentType === TruncateType.elements) {
    return (
      <TruncateElements
        lines={props.lines}
        ellipsis={ellipsis}
        contentType={TruncateType.elements}
        spacing={props.spacing}
      >
        {children}
      </TruncateElements>
    );
  } else if (contentType === TruncateType.text) {
    return (
      <TruncateText
        lines={props.lines}
        ellipsis={ellipsis}
        contentType={TruncateType.text}
        component={props.component}
      >
        {children}
      </TruncateText>
    );
  } else {
    throw new Error('it is impossible to reach this line, the compiler will not let you');
  }
};
