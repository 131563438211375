import { ReactComponent as CloseIcon } from 'assets/icons/svg/close-popup-icon.svg';
import React from 'react';

interface Props {
  readonly title: string;

  onClose(): void;
}

export const Title: React.FC<Props> = ({ title, onClose }: Props): React.ReactElement => {
  return (
    <div className="flex items-center justify-between mb-7">
      <h1 className="text-gray-darkest">{title}</h1>
      <div className="text-gray p-1 hover:text-red-500 rounded-full">
        <CloseIcon className="w-4 h-4 fill-current cursor-pointer" onClick={onClose} />
      </div>
    </div>
  );
};
