import { useSearchParams } from 'react-router-dom';

export const useUrlEmail = (key: string): string => {
  const [parameters] = useSearchParams();
  const value = parameters.get(key);
  if (value) {
    const parts = value.split('.');
    // Is it a probable JWT?
    if (parts.length !== 3) {
      return '';
    }

    const payload = JSON.parse(atob(parts[1]));
    if (payload.email) {
      return payload.email;
    }
  }

  return '';
};
