import { ReactComponent as ArrowIcon } from 'assets/icons/svg/down-arrow.svg';
import { Dropdown } from 'components/dropdown';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DropdownOption from 'views/Landing/Onboarding/components/DropdownOption';

export interface SelectOption<T extends string | number | null> {
  readonly label: string;
  readonly value: T;
}

interface Props<T extends string | number> {
  readonly id: string;
  readonly value: T | null;
  readonly options: ReadonlyArray<SelectOption<T>>;
  readonly label: string;

  readonly required?: boolean;

  onChange?(value: T | null): void;
}

export function Select<T extends string | number>({
  id,
  options,
  value,
  label,
  required = false,
  onChange,
}: Props<T>): React.ReactElement {
  const [highlightedOption, setHighlightedOption] = useState<number>(-1);
  const [reference, setReference] = useState<HTMLDivElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [currentKeyword, setCurrentKeyword] = useState<string | null>(null);

  const toggleDropdown = useCallback((): void => setDropdownOpen(!dropdownOpen), [dropdownOpen]);
  const handleSearchKeywordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setCurrentKeyword(value);
    },
    [],
  );

  const selectedOption = useMemo((): SelectOption<T | null> => {
    const found = options.find((item: SelectOption<T | null>): boolean => item.value === value);
    if (!found) {
      return { value: null, label: label ?? '' };
    } else {
      return found;
    }
  }, [options, value, label]);

  const showRequiredIndicator = useMemo(
    (): boolean => required && selectedOption.value === null,
    [required, selectedOption.value],
  );

  const onDropdownClose = useCallback((): void => setDropdownOpen(false), []);

  const createOptionClickHandler = useCallback(
    (option: SelectOption<T>): VoidFunction =>
      (): void => {
        onChange?.(option.value);
        setDropdownOpen(false);
      },
    [onChange],
  );

  const textColor = useMemo(
    (): string => (selectedOption.value === null ? 'placeholder' : 'gray'),
    [selectedOption],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      switch (event.key) {
        case ' ':
          if (!dropdownOpen) {
            setDropdownOpen(true);
          }
          break;
        case 'Enter':
          if (highlightedOption >= 0 && highlightedOption < options.length) {
            onChange?.(options[highlightedOption].value);
            setDropdownOpen(false);
          } else {
            setDropdownOpen(true);
          }
          break;
        case 'Escape':
          setDropdownOpen(false);
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (dropdownOpen) {
            setHighlightedOption((highlightedOption: number): number =>
              Math.min(highlightedOption + 1, options.length - 1),
            );
          } else {
            setHighlightedOption(0);
            setDropdownOpen(true);
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (dropdownOpen) {
            setHighlightedOption((highlightedOption: number): number =>
              Math.max(highlightedOption - 1, 0),
            );
          } else {
            setHighlightedOption(0);
            setDropdownOpen(true);
          }
          break;
        default:
          break;
      }
    },
    [dropdownOpen, highlightedOption, onChange, options],
  );

  useEffect((): void => {
    if (dropdownOpen) {
      return;
    }

    setHighlightedOption(-1);
  }, [dropdownOpen]);

  useEffect((): void => {
    if (!dropdownOpen) {
      return;
    }

    const indexOfSelectedOption = options.findIndex(
      (option: SelectOption<T>): boolean => option.value === selectedOption.value,
    );

    if (indexOfSelectedOption !== -1) {
      setHighlightedOption(indexOfSelectedOption);
    }
  }, [dropdownOpen, options, selectedOption]);

  useEffect((): void | VoidFunction => {
    if (currentKeyword === null) {
      return;
    }

    const matchingIndex = options.findIndex(({ label }: SelectOption<T>): boolean => {
      const normalizedLabel = label.toLowerCase();
      return normalizedLabel.startsWith(currentKeyword.toLowerCase());
    });

    if (matchingIndex !== -1) {
      setHighlightedOption(matchingIndex);
    }

    const timeout = setTimeout((): void => {
      setCurrentKeyword(null);
    }, 1500);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [currentKeyword, options]);

  return (
    <div id={id} ref={setReference} className={rootClasses.join(' ')} onClick={toggleDropdown}>
      <input
        value={currentKeyword ?? ''}
        className="absolute inset-0 text-transparent bg-transparent cursor-default"
        tabIndex={0}
        onKeyDownCapture={handleKeyDown}
        onChange={handleSearchKeywordChange}
      />
      {selectedOption.value !== null && (
        <div className="absolute text-xxs left-3 -top-3.5">{label}</div>
      )}

      <div className={`text-${textColor} flex justify-between items-center outline-none`}>
        <span className="cursor-default">
          {selectedOption.label} {showRequiredIndicator && <span className="text-red-500">*</span>}
        </span>
        <ArrowIcon className="w-4 h-3" />
      </div>
      <Dropdown
        isOpen={dropdownOpen}
        anchor={reference}
        onClose={onDropdownClose}
        className="z-dropdown"
      >
        {options.map(
          (option: SelectOption<T>, index: number): React.ReactElement => (
            <DropdownOption
              key={option.value}
              label={option.label}
              value={option.value}
              highlighted={index === highlightedOption}
              onClick={createOptionClickHandler(option)}
            />
          ),
        )}
      </Dropdown>
    </div>
  );
}

const rootClasses = [
  'text-left',
  'border-b-2',
  'text-base',
  'font-poppins',
  'border-gray-300',
  'relative',
  'py-2.5',
  'px-3',
  'outline:none',
  'cursor-pointer',
  'hover:border-blue',
  'focus:border-blue',
];
