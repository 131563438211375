import React from 'react';
import { useSelector } from 'react-redux';
import {
  CompanyRevenuesLocation,
  geographiesAndMapsSelector,
} from 'redux/reducers/geographiesAndMapsReducer';
import { getLegendForFinancialStatements } from 'utils';
import VALUES_FORMATTERS from 'views/DataAnalysis/valuesFormatters';

export const RevenuesTable: React.FC = (): React.ReactElement => {
  const { revenues } = useSelector(geographiesAndMapsSelector);
  const largest = revenues.reduce((largest: number, item: CompanyRevenuesLocation): number => {
    if (largest < item.sales) {
      return item.sales;
    }

    return largest;
  }, 0);

  return (
    <>
      <div className="text-right mt-2">
        <p>{getLegendForFinancialStatements(largest)}</p>
      </div>
      <div className="grid grid-cols-3 grid-flow-row w-2/3 mt-2">
        <h4 className="my-3">Country/Region</h4>
        <h4 className="my-3">Metric</h4>
        <h4 className="my-3">Revenues (% OF TOTAL)</h4>
        {revenues.map(
          (item: CompanyRevenuesLocation): React.ReactElement => (
            <React.Fragment key={item.name}>
              <p className="py-3 border-t border-gray-light text-gray">{item.name}</p>
              <p className="py-3 border-t border-gray-light text-gray">Revenues</p>
              <p className="py-3 border-t border-gray-light text-gray">
                ${formatSales(item.sales, largest)} ({percentageFormatter(item.percentage_of_total)}
                )
              </p>
            </React.Fragment>
          ),
        )}
      </div>
    </>
  );
};

const formatSales = VALUES_FORMATTERS['currency'];
const percentageFormatter = VALUES_FORMATTERS['percentage'];
