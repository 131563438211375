import { FilterCategoryGroup } from 'components/filters/components/FitlerCategoryGroup';
import { GenericFilter } from 'components/filters/helpers';
import { FilterValues } from 'hooks/useFilters';
import React from 'react';

interface Props {
  readonly items: GenericFilter[];
  readonly values: readonly FilterValues[];

  onChange(filterValues: readonly FilterValues[]): void;
  onRemove(name: string): void;
}

export const Categories: React.FC<Props> = ({
  items,
  values,
  onChange,
  onRemove,
}: Props): React.ReactElement => {
  return (
    <>
      {items.map(
        (item: GenericFilter): React.ReactElement => (
          <FilterCategoryGroup
            key={item.name}
            label={item.label}
            filterDefinition={item}
            filterValues={values}
            onChange={onChange}
            onRemove={onRemove}
          />
        ),
      )}
    </>
  );
};
