import { AlgoNotesTable } from 'components/algoNotesTable';
import { MainOutletContent } from 'components/mainOutletContent';
import { OrganizationSelectionHeading } from 'components/organizationSelectionHeading';
import { useSearchInputOrganization } from 'hooks/useSearchInputOrganization';
import { noop } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { algoNotesSelector } from 'redux/reducers/algoNotesReducer';
import { OrganizationTypes } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { fetchAlgoNotesAction } from 'redux/services/algoNotesServices';

export const AlgoNotes: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { notes, loading } = useSelector(algoNotesSelector);

  const currentOrganization = useSearchInputOrganization('companyId');

  const { noteId } = useParams<{
    readonly noteId: string;
  }>();

  const handleRefresh = useCallback(
    (parameters: Record<string, string>): VoidFunction => {
      if (currentOrganization === null) {
        return noop;
      }

      return dispatch(fetchAlgoNotesAction(currentOrganization.id, parameters));
    },
    [currentOrganization, dispatch],
  );

  const tags = React.useMemo(
    (): readonly string[] =>
      currentOrganization?.tags ?? [
        'Financial statements',
        'Valuation',
        'Peer comps',
        'Industry position',
        'Indicators',
      ],
    [currentOrganization?.tags],
  );

  return (
    <MainOutletContent>
      <OrganizationSelectionHeading
        description={description}
        path={path}
        organization={currentOrganization}
        allowOnly={OrganizationTypes.company}
        tags={tags}
      />
      <AlgoNotesTable
        algoNotesPage={notes}
        loading={loading}
        objectId={currentOrganization?.id}
        type="company"
        currentNoteId={noteId}
        refresh={handleRefresh}
      />
    </MainOutletContent>
  );
};

const path = ['SMART TOOLS', 'ALGO NOTES'];
const description =
  'Algo-notes are automated analytical summaries and tips from financial position, operational qualities, peer comparisons and more.';
