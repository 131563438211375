import CreatePost from 'assets/icons/svg/create-post-icon.svg';
import Placeholder from 'assets/images/genderless-icon.svg';
import { KeyboardListener } from 'components/keyboardListener';
import { Modal } from 'components/modal';
import React, { useCallback, useState } from 'react';
import { DataPortalCreatePostModal } from 'views/Home/components/dataPortalCreatePostModal';
import CreatePostModal from 'views/Home/modals/createPostModal';

export const AddPostButton: React.FC = (): React.ReactElement => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = useCallback((): void => setModalOpen(true), []);
  const closeModal = useCallback((): void => setModalOpen(false), []);

  const handleGlobalKeyPressed = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): boolean => {
      if (event.key === 'Escape') {
        closeModal();

        return true;
      }

      return false;
    },
    [closeModal],
  );

  return (
    <>
      <div
        className="flex items-center bg-white hover:bg-blue-light p-4 md:rounded md:p-6 cursor-pointer"
        onClick={openModal}
      >
        <img
          className="w-10 h-10 mr-2 md:w-14 md:h-14 md:mr-4"
          src={Placeholder}
          alt="placeholder"
        />
        <div id="create-post-button" className={textOvalClass}>
          <img src={CreatePost} className="w-5 h-5 mr-2" alt="create" />
          <span className="font-poppins text-md">Create a post</span>
        </div>
        <div className="ml-4 px-5 bg-blue rounded-5 py-1">
          <p className="text-white text-sm font-poppinsMedium leading-8 md:leading-10">Post</p>
        </div>
      </div>
      <KeyboardListener onKeyPressed={handleGlobalKeyPressed}>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {process.env.REACT_APP_DATA_PORTAL ? (
            <DataPortalCreatePostModal onClose={closeModal} />
          ) : (
            <CreatePostModal onClose={closeModal} />
          )}
        </Modal>
      </KeyboardListener>
    </>
  );
};

const textOvalClass =
  'w-full rounded-full border-2 border-gray-300 bg-white flex justify-start pl-5 items-center text-gray h-10 md:h-12';
