import { TruncatedTags } from 'components/truncatedTags';
import React, { useMemo } from 'react';
import { TagsList } from 'views/Home/components/shared/tagsList';
import { FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;
  readonly expanded: boolean;

  onClick?(tag: string): void;
  onExpand(): void;
}

export const Tags: React.FC<Props> = ({
  feedItem,
  expanded,
  onClick,
  onExpand,
}: Props): React.ReactElement | null => {
  const tags = useMemo((): string[] => feedItem.tags ?? [], [feedItem.tags]);
  if (tags.length === 0) {
    return null;
  }

  if (expanded) {
    return <TagsList tags={tags} expanded={expanded} onExpand={onExpand} onClick={onClick} />;
  } else {
    return <TruncatedTags tags={tags} onClick={onClick} />;
  }
};
