import { EditorState } from 'components/postBodyInput/types';
import React from 'react';

interface Props {
  readonly mode: EditorState;
}

export const SuggestionsEmptyItem: React.FC<Props> = ({ mode }: Props): React.ReactElement => {
  switch (mode) {
    case EditorState.insertTag:
      return (
        <li className="text-center text-sm text-gray p-4">
          <p>Press Enter/Return to create a new tag</p>
        </li>
      );
    case EditorState.insertUser:
      return (
        <li className="text-center text-sm text-gray p-4">
          <p>There were 0 matches for your search, we have nothing to suggest</p>
        </li>
      );
    default:
      throw new Error('invalid state, there should be no dropdown in this state');
  }
};
