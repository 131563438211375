export default {
  autocomplete: {
    placeholder: 'Search',
    data: [
      {
        tags: ['PORTAFOLIOS', 'DIVERSIFIED'],
        route: '/portfolios/details/0',
        text: 'PORTAFOLIOS - DIVERSIFIED - Delta Airlines',
      },
      {
        tags: ['PORTAFOLIOS', 'ENVIRONMENTAL'],
        route: '/portfolios/details/1',
        text: 'PORTAFOLIOS - ENVIRONMENTAL - Kosmos Energy',
      },
      {
        tags: ['PORTAFOLIOS', 'GLOBAL'],
        route: '/portfolios/details/2',
        text: 'PORTAFOLIOS - GLOBAL - Air Products & Chemicals',
      },
    ],
  },
  notFound: {
    title: 'No matching found',
    text: 'Please make sure your keyword are spelled correctly',
  },
  buttons: {
    viewProfile: 'View Profile',
    upgradePack: 'Upgrade Pack',
    logout: 'Log Out',
  },
  bannerData: [
    {
      label: 'DJIA 27791.44',
      value: '1.30%',
      type: 'increase',
    },
    {
      label: 'S&P 500 3360.47',
      value: '0.27%',
      type: 'increase',
    },
    {
      label: 'u.s 10 yr-2/32 yield',
      value: '0.584%',
      type: 'decrease',
    },
    {
      label: 'WTI Crude Oil 31.05',
      value: '24.53%',
      type: 'decrease',
    },
    {
      label: 'euro 1.1741',
      value: '0.02%',
      type: 'increase',
    },
    {
      label: 'dax index 12687.53',
      value: '0.10%',
      type: 'increase',
    },
    {
      label: 'nikkei 225',
      value: '3.5%',
      type: 'decrease',
    },
  ],
};
