import image from 'assets/images/refer-a-friend.png';
import SVGIcon from 'components/icons/SVGIcon';
import { MainOutletContent } from 'components/mainOutletContent';
import { useReferrerCode } from 'hooks/useReferrerCode';
import React, { useCallback, useState } from 'react';

export const ReferFriendScreen: React.FC = (): React.ReactElement => {
  const [animating, setAnimating] = useState<boolean>(false);
  const referrer = useReferrerCode();

  React.useEffect((): void | VoidFunction => {
    if (!animating) {
      return;
    }
    const timer = setTimeout((): void => {
      setAnimating(false);
    }, 2500);

    return (): void => {
      clearTimeout(timer);
    };
  }, [animating]);

  const animate = useCallback((): void => {
    setAnimating(true);
  }, []);

  const url = React.useMemo((): string => {
    if (referrer.code === null) {
      return '';
    }

    return `${location.protocol}//${location.host}/sign-up/?referrer=${referrer.code}`;
  }, [referrer.code]);

  const copyURL = React.useCallback((): void => {
    clipboard.writeText(url).then(animate);
  }, [animate, url]);

  return (
    <MainOutletContent>
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="flex flex-col items-center justify-center w-2/3 flex-grow my-10">
          <div className="h-24"></div>
          <h1 className="text-3xl">Refer Your Friends - And Start Earning</h1>
          <div className="my-3 text-center">
            <p className="text-gray-darkest font-poppinsMedium text-lg">
              <span>
                You&apos;ll get $10 the first time your friend subscribes to a premium or unlimited
                tier
              </span>
            </p>
          </div>
          <div className="my-10 bg-gray-light">
            <img src={image} alt="people" />
          </div>
          <div className="flex w-full items-center justify-center">
            <div className={inputClassName}>
              <p className="text-md font-poppinsMedium text-gray-darkest">{url}</p>
            </div>
            <button type="button" className={buttonClassName} onClick={copyURL}>
              <SVGIcon name="copy" className="h-5 w-5 fill-current" />
              <span>Copy</span>
            </button>
          </div>
          <div className="mt-6 text-center">
            <p className="text-gray-darkest font-poppinsMedium text-lg">
              <span>
                Your referral code is{' '}
                <span className="py-0.5 px-2 bg-gray-light text-gray-dark rounded">
                  {referrer.code}
                </span>
              </span>
            </p>
          </div>
          <div className="flex items-center justify-center h-24">
            {animating && (
              <div className="flex items-center px-2 py-2 gap-3 text-white bg-green rounded-full">
                <SVGIcon name="check-circle" className="fill-current w-8 h-8" />
                <h3 className="pr-4 uppercase">Copied</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainOutletContent>
  );
};

const { location } = window;
const { clipboard } = window.navigator;

const inputClassName = 'px-4 leading-10 text-sm border border-gray-medium rounded-l';
const buttonClassName =
  'flex items-center gap-2 px-6 leading-10 font-poppinsSemiBold text-white bg-blue border border-blue ' +
  'rounded-r hover:bg-blue-800 hover:border-blue-800 -ml-px';
