import { ConditionalRender } from 'components/conditionalRenderer';
import React from 'react';
import { CommonActionButton } from 'views/Home/components/shared/commonActionButton';

interface Props {
  readonly likesCount: number;
  readonly liked: boolean;
  readonly dislikesCount: number;
  readonly disliked: boolean;
  readonly commentsCount: number;
  readonly commented: boolean;
  readonly extraButtons?: React.ReactElement[];

  onLiked(): void;
  onDisliked(): void;
  onComment?(): void;
  onShare?(): void;
  onReport?(): void;
}

export const FeedItemInteractionButtons: React.FC<Props> = ({
  likesCount,
  liked,
  dislikesCount,
  disliked,
  commentsCount,
  commented,
  extraButtons,
  onLiked,
  onDisliked,
  onComment,
  onShare,
}: Props): React.ReactElement => {
  return (
    <div className="flex items-center gap-4">
      <CommonActionButton
        icon="like"
        tooltip="Useful"
        count={likesCount}
        highlighted={liked}
        onClick={onLiked}
      />
      <CommonActionButton
        icon="dislike"
        tooltip="Not useful"
        count={dislikesCount}
        highlighted={disliked}
        onClick={onDisliked}
      />
      <CommonActionButton
        icon="comment"
        tooltip="Comments"
        count={commentsCount}
        highlighted={commented}
        onClick={onComment}
      />
      <ConditionalRender renderIf={onShare !== undefined}>
        <CommonActionButton
          icon="share"
          tooltip="Share"
          count={null}
          highlighted={false}
          onClick={onShare}
        />
      </ConditionalRender>
      {extraButtons?.map((button: React.ReactElement): React.ReactElement => button)}
    </div>
  );
};
