import { ReactComponent as ReportIcon } from 'assets/icons/svg/info-gray.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/svg/trash-generic.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import { FeedComment } from 'redux/reducers/feedDetailsReducer';
import { FeedItemInteractionButtons } from 'views/Home/components/shared/feedItemInteractionButtons';
import { GenericActionButton } from 'views/Home/components/shared/genericActionButton';
import { COMMENT_ALREADY_REPORTED_TOOLTIP, REPORT_TOOLTIP } from 'views/Home/strings';

interface Props {
  readonly comment: FeedComment;
  readonly editable: boolean;
  readonly repliesCount: number;

  onLike(): void;
  onDislike(): void;
  onShowReplies(): void;
  onDelete?(): void;
  onReport?(): void;
  onEdit(): void;
  onReply(): void;
}

interface Action {
  readonly label: string;
  readonly visible: boolean;
  onClick(): void;
}

export const CommentCardActionButtons: React.FC<Props> = ({
  comment,
  repliesCount,
  editable,
  onLike,
  onDislike,
  onShowReplies,
  onEdit,
  onReply,
  onDelete,
  onReport,
}: Props): React.ReactElement => {
  const { commenter } = comment;
  const user = useSelector(userSelector);

  const reportIconTooltip = useMemo(
    (): string => (comment.reported ? COMMENT_ALREADY_REPORTED_TOOLTIP : REPORT_TOOLTIP),
    [comment.reported],
  );

  const reportIconHandler = useMemo(
    (): VoidFunction | undefined => (!comment.reported ? onReport : undefined),
    [comment.reported, onReport],
  );

  const deleteIconHandler = useMemo(
    (): VoidFunction | undefined => (user.id === commenter.id ? onDelete : undefined),
    [user.id, commenter.id, onDelete],
  );

  const actions = React.useMemo((): readonly Action[] => {
    return [
      {
        label: 'Reply',
        visible: !comment.reported,
        onClick: onReply,
      },
      {
        label: 'Edit',
        visible: editable,
        onClick: onEdit,
      },
    ].filter((action: Action): boolean => action.visible);
  }, [comment.reported, editable, onEdit, onReply]);

  return (
    <div className="flex items-center gap-4">
      <FeedItemInteractionButtons
        likesCount={comment.likesCount}
        liked={comment.liked}
        dislikesCount={comment.dislikesCount}
        disliked={comment.disliked}
        commentsCount={repliesCount}
        commented={repliesCount > 0}
        onLiked={onLike}
        onDisliked={onDislike}
        onComment={onShowReplies}
      />
      <ConditionalRender key="report" renderIf={comment.reported || user.id !== commenter.id}>
        <GenericActionButton
          icon={ReportIcon}
          tooltip={reportIconTooltip}
          count={null}
          highlighted={comment.reported}
          variant="danger"
          onClick={reportIconHandler}
        />
      </ConditionalRender>
      <ConditionalRender key="delete" renderIf={user.id === commenter.id}>
        <GenericActionButton
          icon={DeleteIcon}
          tooltip="Delete"
          count={null}
          highlighted={false}
          variant="danger"
          onClick={deleteIconHandler}
        />
      </ConditionalRender>
      <div className="flex flex-grow items-center -ml-2">
        {actions.map(
          (action: Action, index: number): React.ReactElement => (
            <React.Fragment key={action.label}>
              {index > 0 ? <div className="w-1 h-1 bg-blue rounded mx-2" /> : null}
              <button
                key={action.label}
                className="font-poppinsMedium text-blue text-xs hover:bg-gray-light leading-none pt-0.5"
                onClick={action.onClick}
              >
                {action.label}
              </button>
            </React.Fragment>
          ),
        )}
      </div>
    </div>
  );
};
