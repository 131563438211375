export enum ProcessingStateEnum {
  idle = 'idle',
  processing = 'processing',
  error = 'error',
  fatalError = 'fatalError',
  success = 'success',
}

export interface ProcessingState<D> {
  readonly state: ProcessingStateEnum;
  readonly data?: D;

  readonly error?: unknown;
}

// eslint-disable-next-line no-unused-vars
export class ProcessingState<D> {
  public readonly data?: D;

  public static processing<T>(data?: T): ProcessingState<T> {
    return {
      state: ProcessingStateEnum.processing,
      data: data,
    };
  }

  public static idle(): ProcessingState<never> {
    return {
      state: ProcessingStateEnum.idle,
    };
  }

  public static success<D>(data?: D): ProcessingState<D> {
    return {
      state: ProcessingStateEnum.success,
      data: data,
    };
  }

  public static error<D>(data?: D, error?: unknown): ProcessingState<D> {
    return {
      state: ProcessingStateEnum.error,
      error: error,
      data: data,
    };
  }

  public static fatalError<D>(data?: D, error?: unknown): ProcessingState<D> {
    return {
      state: ProcessingStateEnum.fatalError,
      error: error,
      data: data,
    };
  }

  public static transition<T>(
    current: ProcessingState<T>,
    toState: ProcessingStateEnum,
    error?: unknown,
  ): ProcessingState<T> {
    switch (toState) {
      case ProcessingStateEnum.idle:
        return ProcessingState.idle();
      case ProcessingStateEnum.processing:
        return ProcessingState.processing(current.data);
      case ProcessingStateEnum.success:
        return ProcessingState.success(current.data);
      case ProcessingStateEnum.error:
        return ProcessingState.error(current.data, error);
      case ProcessingStateEnum.fatalError:
        return ProcessingState.fatalError(current.data, error);
    }
  }
}
