import type { TourConfig } from 'hooks/useOldTour';
import { Step } from 'react-joyride';

const steps: Step[] = [
  {
    target: '#add-company-button',
    title: 'Add to Watchlist',
    content: 'Click here to add a company to your watchlist.',
    placement: 'right',
    disableBeacon: true,
  },
];

const tourConfig: TourConfig = {
  nextPath: '/portfolios',
  steps,
  lastLabel: 'Next',
};

export default tourConfig;
