import { Tooltip } from 'components/tooltip';
import React from 'react';

export const ComingSoon: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren): React.ReactElement => (
  <Tooltip
    content={<div className="font-poppinsSemiBold uppercase">Coming Soon</div>}
    variant="light"
  >
    <div className="opacity-50">
      <div className="pointer-events-none">{children}</div>
    </div>
  </Tooltip>
);
