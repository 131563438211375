import React, { useEffect, useRef } from 'react';

export const useScrollIntoView = <T extends HTMLElement>(highlighted: boolean): React.Ref<T> => {
  const ref = useRef<T>(null);

  useEffect((): void => {
    const element = ref.current;
    if (element === null || !highlighted) {
      return;
    }

    const boundingBox = element.getBoundingClientRect();
    if (boundingBox.bottom > window.innerHeight || boundingBox.top < 0) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted]);

  return ref;
};
