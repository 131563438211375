import DropdownFilter from 'components/dropdownFilter/DropdownFilter';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedCountrySelector, setSelectedCountry } from 'redux/reducers/industryReducer';
import countries, { CountryOfInterest } from 'views/DataAnalysis/Industry/countries';

export const CountryDropdown: React.FC = (): React.ReactElement => {
  const selectedCountry = useSelector(selectedCountrySelector);
  const dispatch = useDispatch();

  const handleCategoryChange = useCallback(
    (country: CountryOfInterest): void => {
      dispatch(setSelectedCountry(country));
    },
    [dispatch],
  );

  return (
    <DropdownFilter
      options={countries}
      selectedId={selectedCountry}
      onSelect={handleCategoryChange}
    />
  );
};
