import SpinnerLoader from 'components/spinnerLoader';
import { useCurrentEvent } from 'hooks/useCurrentEvent';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { companyStreamSelector, loadingCompanyTimeline } from 'redux/reducers/companyReducer';
import { getCompanyTimeline } from 'redux/services/companyService';
import { defaultStreamFilters, isYearStreamFilters, TimelineFilters } from 'types/timeline';
import { EventManager } from 'views/SmartTools/TimelineAndEvents/components/eventManager';
import { StreamItem } from 'views/SmartTools/TimelineAndEvents/components/streamItem';
import { TimelineDateFilterBar } from 'views/SmartTools/TimelineAndEvents/toolbars/timelineDateFilterBar';

export const CommonTimelineRoute: React.FC = (): React.ReactElement => {
  const [filters, setFilters] = useState<TimelineFilters>(defaultStreamFilters);
  const { companyId } = useParams<{ companyId: string; eventId: string }>();

  const compact = useMemo((): boolean => isYearStreamFilters(filters), [filters]);
  const stream = useSelector(companyStreamSelector);
  const loading = useSelector(loadingCompanyTimeline);
  const dispatch = useDispatch<any>();

  const [currentEvent, _, loadingCurrentEvent] = useCurrentEvent([]);

  useEffect((): void => {
    if (!companyId) {
      console.warn('this route should not be accessible without specifying a company id');
      return;
    }

    // FIXME: add cancellation here
    dispatch(getCompanyTimeline(companyId, filters));
  }, [companyId, filters, dispatch]);

  return (
    <div className="flex-1 relative">
      <div className="pt-5">
        <TimelineDateFilterBar value={filters} onChange={setFilters} />
      </div>

      <StreamItem readOnly={true} stream={stream} compact={compact} variant="regular" />
      <EventManager event={currentEvent} stream={stream} loading={loadingCurrentEvent} />

      <SpinnerLoader visible={loading} />
    </div>
  );
};
