import React from 'react';
import { ProfileStatementGroup, ProfileStatementRow } from 'types/financial';
import { Value } from 'views/DataAnalysis/common/SummaryTable/value';

interface Props {
  readonly data?: ProfileStatementGroup[];
  readonly largestNumber?: number;

  onValueExpand?(name: string, value: string): void;
}

const SummaryTable: React.FC<Props> = ({
  data,
  largestNumber = 0,
  onValueExpand,
}: Props): React.ReactElement => {
  return (
    <div className="font-poppins px-px">
      <div className="py-3 md:py-1">
        {data?.map((group: ProfileStatementGroup, index: number): React.ReactElement => {
          const { rows } = group;
          const style = { gridRow: `span ${rows.length} / span ${rows.length}` };

          return (
            <div key={group.key} className={groupClassName}>
              <div
                id={group.key}
                className="self-start absolute top-2 font-bold md:font-normal md:static"
                style={style}
              >
                <span className="font-poppinsMedium text-base">{group.name}</span>
              </div>
              {rows.map((row: ProfileStatementRow): React.ReactElement => {
                const valueId = `field-${index}-item-${row.key}`;
                const labelId = `field-${index}-${row.key}`;
                const className = [
                  'text-sm font-poppins pb-3 flex text-center',
                  row.unit === 'paragraph' ? 'self-start' : undefined,
                ].join(' ');

                return (
                  <React.Fragment key={row.key}>
                    <span id={labelId} key={row.key} className={className}>
                      {row.name + ':'}
                    </span>
                    <div className="text-sm pb-3 text-gray">
                      <Value
                        id={valueId}
                        value={row.value}
                        name={row.name}
                        unit={row.unit}
                        largestNumber={largestNumber}
                        onExpand={onValueExpand}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SummaryTable;

const groupClassName =
  'grid grid-cols-2 md:grid-cols-3 items-center pb-1.5 border-t border-gray-light first:border-t-0 relative pt-14 md:pt-2';
