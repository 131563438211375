import { GraphContainer } from 'components/valueChain/graphContainer';
import { GraphNodeContent } from 'components/valueChain/graphNodeContent';
import { GraphNodeLabel } from 'components/valueChain/graphNodeLabel';
import { OverflowCountNode } from 'components/valueChain/overflowCountNode';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { valueChainStateSelector } from 'redux/reducers/valueChainReducer';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';

interface Props {
  readonly node: ValueChainNode;
  readonly isFirst: boolean;
  readonly isLast: boolean;
  readonly totalCount: number;

  onCentralNodeClick(): void;
  onChildNodeClick?(node: ValueChainNode): void;
}

export const Graph: React.FC<Props> = ({
  node,
  totalCount,
  isFirst,
  isLast,
  onCentralNodeClick,
  onChildNodeClick,
}: Props): React.ReactElement => {
  const [childrenHidden, setChildrenHidden] = useState<boolean>(false);

  const children = node.children ?? [];

  // Make it cause an effect when hiding, animation
  // is implemented with css, but this helps it happen
  useEffect((): VoidFunction | void => {
    const timer = setTimeout((): void => {
      setChildrenHidden(!isLast);
    }, 0);

    return (): void => {
      setChildrenHidden(!isLast);
      clearTimeout(timer);
    };
  }, [isLast]);

  const overflows = useMemo((): boolean => totalCount > 8, [totalCount]);
  const visibleChildren = children.slice(0, overflows ? 7 : 8);
  const label = <GraphNodeLabel label={node.label} count={node.count} />;
  const { stack: chain } = useSelector(valueChainStateSelector);

  if (childrenHidden) {
    return (
      <div className="flex items-center cursor-pointer" onClick={onCentralNodeClick}>
        <GraphContainer label={label} isFirst={isFirst} />
      </div>
    );
  } else {
    return (
      <div className="flex items-center">
        <GraphContainer label={label} isFirst={isFirst}>
          {visibleChildren.map((child: ValueChainNode): React.ReactElement => {
            return (
              <GraphNodeContent
                key={child.entityId}
                withRing={!isFirst}
                node={child}
                onClick={isClickable(child, chain) ? onChildNodeClick : undefined}
              />
            );
          })}
          {overflows ? (
            <OverflowCountNode withRing={!isFirst} count={totalCount - visibleChildren.length} />
          ) : null}
        </GraphContainer>
      </div>
    );
  }
};

const isClickable = (node: ValueChainNode, chain: readonly ValueChainNode[]): boolean => {
  if (chain.some((targetNode: ValueChainNode): boolean => targetNode.entityId === node.entityId)) {
    return false;
  }

  switch (node.entityType) {
    case ValueChainEntityType.company:
    case ValueChainEntityType.industry:
      return true;
    default:
      return node.count !== null && node.count > 0;
  }
};
