// Libraries
import { Modal } from 'components/modal';
import { useGtag } from 'hooks/useGtag';
import React from 'react';
import { useSelector } from 'react-redux';
import { companyNameSelector } from 'redux/reducers/companyReducer';
import { KeyFactor, KeyFactorType } from 'redux/reducers/keyFactorsReducer';
import { LevelCell } from 'views/DataAnalysis/common/KeyFactors/levelCell';

interface Props {
  readonly data: KeyFactor | null;
  readonly factorType: KeyFactorType;

  onClose(): void;
}

const KeyFactorDetailsModal: React.FC<Props> = ({
  data,
  factorType,
  onClose,
}: Props): React.ReactElement | null => {
  const factorContainerClassName = 'flex items-center mb-6';
  const companyName = useSelector(companyNameSelector);
  const { trackEvent } = useGtag();

  if (data === null) {
    return null;
  }

  const handleonClick = () => {
    trackEvent('clickOnaffiliateLink', {
      action: 'affiliateLink pressed',
      category: 'keyFactors',
      name: data.name,
      id: data.id,
      companyName: companyName,
    });
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Content>
        <div id={data.id} className="flex flex-col flex-start w-full md:w-modal-sm">
          <h1 className="text-gray-darkest mb-6">{data.name}</h1>

          <div className={factorContainerClassName}>
            <h4 className="text-gray-darkest text-base mr-2">Probability</h4>
            <LevelCell value={data.probability} factorType={factorType} type="probability" />
          </div>

          <div className={factorContainerClassName}>
            <h4 className="text-gray-darkest text-base mr-2">Impact</h4>
            <LevelCell value={data.impact} factorType={factorType} type="impact" />
          </div>

          <div className={factorContainerClassName}>
            <h4 className="text-gray-darkest text-base mr-2">{factorType} Level</h4>
            <LevelCell value={data.level} factorType={factorType} type="level" />
          </div>

          <div className="mb-6">
            <h4 className="text-gray-darkest text-base mb-2">Summary</h4>
            <p>{data.summary}</p>
          </div>

          {data.affiliate_fine_print && data.affiliate_link && (
            <>
              <div className="w-full h-px bg-gray-medium mb-6" />
              <div className="flex flex-col mb-6">
                <h2 className="text-gray-darkest text-base mb-2">LEARN MORE</h2>
                <p className="mb-3">
                  {data.affiliate_summary}{' '}
                  <a
                    onClick={handleonClick}
                    href={data.affiliate_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text-blue-400">Click here</span>
                  </a>
                </p>
                <p className="text-gray-dark italic">{data.affiliate_fine_print}</p>
              </div>
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default KeyFactorDetailsModal;
