import { AddObjectButton } from 'components/addObjectButton';
import { Filters } from 'components/filters';
import { Modal } from 'components/modal';
import { ApplicationModule, exceedsUsageLimit, usePermission } from 'context/authorization';
import { useFilters } from 'hooks/useFilters';
import { useModal } from 'hooks/useModal';
import { useSort } from 'hooks/useSort';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myWatchlistSelector } from 'redux/reducers/companyReducer';
import { UsageAction } from 'redux/reducers/subscriptionPlansReducer';
import { addCompanyToWatchlistService } from 'redux/services/companyService';
import { Company } from 'types/organization/types';
import filtersConfig from 'views/DataAnalysis/Company/filters';
import AddCompanyWatchlist from 'views/Portfolios/PortfoliosModal/addCompanyWatchlist';
import CompaniesTable from 'views/Watchlist/components/Companies/companiesTable';

export const CompaniesWatchlist: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const rows = useSelector(myWatchlistSelector);
  const permission = usePermission(ApplicationModule.watchlist, UsageAction.addItem);

  const exceededUsageModal = useModal();
  const addCompanyModal = useModal();
  const [sorting, onSort] = useSort<Company>('name');

  const handleFiltersChange = useFilters(filtersConfig.watchlist, {
    resetOnConfigChange: true,
  });

  const handleAddCompanyRequest = useCallback((): void => {
    if (exceedsUsageLimit(permission, rows.length)) {
      exceededUsageModal.open();
    } else {
      addCompanyModal.open();
    }
  }, [addCompanyModal, exceededUsageModal, permission, rows.length]);

  const handleAddCompany = useCallback(
    (companyId: string): void => {
      dispatch(addCompanyToWatchlistService(companyId));
      addCompanyModal.close();
    },
    [addCompanyModal, dispatch],
  );

  return (
    <>
      <div className="flex items-center justify-between my-4">
        <Filters config={filtersConfig.watchlist} onChange={handleFiltersChange} />
        <AddObjectButton title="Company" onClick={handleAddCompanyRequest} />
      </div>

      <div className="w-full flex justify-end mt-2">
        <h4 className="text-sm font-poppins lowercase text-gray">{rows?.length ?? 0} companies</h4>
      </div>

      <CompaniesTable
        rows={rows}
        sorting={sorting}
        onSort={onSort}
        onAdd={handleAddCompanyRequest}
      />

      <Modal isOpen={addCompanyModal.isOpen} onClose={addCompanyModal.close}>
        <Modal.Content>
          <AddCompanyWatchlist onAddCompany={handleAddCompany} />
        </Modal.Content>
      </Modal>

      {/* description="To add more companies to the Watchlist, please upgrade your current plan." */}
      <Modal isOpen={exceededUsageModal.isOpen} onClose={exceededUsageModal.close}>
        <Modal.Content>
          <ExceedAccess
            title="Unlock More Companies"
            description="Upgrade your plan to add new companies to your watchlist."
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
