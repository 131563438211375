import SVGIcon from 'components/icons/SVGIcon';
import { MainOutletContent } from 'components/mainOutletContent';
import React from 'react';

interface Props {
  readonly title: string;
  readonly message: string | React.ReactElement;
}

export const ErrorView: React.FC<Props> = ({ title, message }: Props): React.ReactElement => {
  return (
    <MainOutletContent>
      <div className="flex-grow flex flex-col items-center justify-center text-center">
        <div className="text-center w-96">
          <SVGIcon name="error-icon" className="text-gray fill-current w-20 h-20 mb-8 mx-auto" />
          <h2 className="text-gray-darkest mb-2">{title}</h2>
          <p className="text-gray">{message}</p>
        </div>
      </div>
    </MainOutletContent>
  );
};
