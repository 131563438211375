import React from 'react';

interface Props {
  onClick?(): void;
}

export const DefaultTextEllipsis: React.FC<Props> = ({ onClick }: Props): React.ReactElement => {
  return (
    <span className="hover:opacity-50 cursor-pointer font-poppins text-sm" onClick={onClick}>
      <span>&hellip;</span>
      <span className="text-blue">[+]</span>
    </span>
  );
};
