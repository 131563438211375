import { ApplicationModule } from 'context/authorization';
import { withViewAccess } from 'hoc/withViewAccess';
import { FinancialStatementTabs } from 'views/DataAnalysis/Company/Details/Financials/financialStatementTabs';

const hoc = withViewAccess(ApplicationModule.quarterlyReports, {
  title: 'Unlock Quarterly Reports',
  body: 'Upgrade your plan to view quarterly & annual reports immediately',
});

export default hoc(FinancialStatementTabs);
