import React, { useCallback, useMemo } from 'react';
import { Icon } from 'views/Connections/Overview/components/FollowButton/icon';

export enum FollowAction {
  follow = 'follow',
  unfollow = 'unfollow',
}

export interface FollowButtonLabels {
  readonly follow: string;
  readonly unfollow: string;
}

interface Props<T> {
  readonly followed: boolean;
  readonly compact?: boolean;
  readonly loading?: boolean;
  readonly labels?: FollowButtonLabels;

  // Data that shall be sent back via the onClick
  // callback.
  //
  // This saves a lot of trouble when using the button in
  // a loop, which is often the case.
  //
  // Instead of creating 1 callback per rendered element, we
  // only need a single callback for all. Improving rendering
  // and memory.
  //
  // The additional data needed by the function, is temporarily
  // saved within the component
  readonly clickData?: T;

  onClick?(followed: FollowAction, data?: T): void;
}

function FollowButton<T>({
  labels = defaultLabels,
  compact,
  followed,
  loading = false,
  clickData,
  onClick,
}: Props<T>): React.ReactElement {
  const iconType = useMemo(
    (): 'plus' | 'check' | 'spinner' => (loading ? 'spinner' : followed ? 'check' : 'plus'),
    [loading, followed],
  );

  const disabled = useMemo((): boolean => loading || onClick === undefined, [loading, onClick]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      if (onClick) {
        event.stopPropagation();
        event.preventDefault();
        onClick(followed ? FollowAction.unfollow : FollowAction.follow, clickData);
      }
    },
    [followed, onClick, clickData],
  );

  return (
    <button
      className={compact ? compactClass : regularClass}
      disabled={disabled || !onClick}
      onClickCapture={handleClick}
    >
      <div className="flex items-center justify-center gap-2 ml-2 pr-3">
        <div className="flex items-center justify-center w-4 h-4">
          <Icon type={iconType} />
        </div>
        {followed ? labels.unfollow : labels.follow}
      </div>
    </button>
  );
}

export default FollowButton;

const commonClass =
  'font-poppinsMedium text-sm text-white bg-blue rounded py-0 transition border border-blue ' +
  'hover:text-blue hover:bg-white disabled:pointer-events-none disabled:opacity-20';
const regularClass = commonClass + ' leading-9 h-10 w-36';
const compactClass = commonClass + ' leading-7 h-8 w-32';

const defaultLabels: FollowButtonLabels = { follow: 'Follow', unfollow: 'Following' };
