import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetAll } from 'redux/reducers/geographiesAndMapsReducer';
import { Organization } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { fetchRevenuesMap, fetchSubsidiariesMap } from 'redux/services/geographiesAndMapsServices';
import { OrganizationDetails } from 'types/organization/types';

export const useCompanyGeographiesAndMaps = (
  company: Organization | OrganizationDetails | null,
): void => {
  const dispatch = useDispatch();

  useEffect((): VoidFunction | void => {
    if (!company) {
      return;
    }

    const cancel1 = dispatch(fetchSubsidiariesMap(company.id));
    const cancel2 = dispatch(fetchRevenuesMap(company.id));
    return (): void => {
      cancel1();
      cancel2();

      dispatch(resetAll());
    };
  }, [company, dispatch]);
};
