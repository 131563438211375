import { DatePicker } from 'components/dateTimePicker/components/datePicker';
import SVGIcon from 'components/icons/SVGIcon';
import { useSearchParametersDate } from 'hooks/useSearchParametersDate';
import React from 'react';

export const DateRangeFilter: React.FC = (): React.ReactElement => {
  const [fromDate, handleFromDateChange] = useSearchParametersDate('from_date');
  const [toDate, handleToDateChange] = useSearchParametersDate('to_date');

  return (
    <div className="flex items-center gap-2">
      <div className="bg-gray-light rounded hover:opacity-70 relative">
        <DatePicker
          label="From Date"
          value={fromDate}
          labelFormat="EEEE - MMM dd, yyyy"
          nullable={true}
          className={datePickerClassName}
          onChange={handleFromDateChange}
        />
        <div className="flex items-center absolute right-2 top-0 bottom-0 text-gray pointer-events-none">
          <SVGIcon className="fill-current w-3.5" name="down-arrow" />
        </div>
      </div>
      <div className="bg-gray-light rounded hover:opacity-70 relative">
        <DatePicker
          label="To Date"
          value={toDate}
          labelFormat="EEEE - MMM dd, yyyy"
          nullable={true}
          className={datePickerClassName}
          onChange={handleToDateChange}
        />
        <div className="flex items-center absolute right-2 top-0 bottom-0 text-gray pointer-events-none">
          <SVGIcon className="fill-current w-3.5" name="down-arrow" />
        </div>
      </div>
    </div>
  );
};

const datePickerClassName = 'px-4 py-1 my-1 w-60 hover:bg-gray-light rounded';
