import { CompanyLink } from 'components/peerComparisonsView/components/companyLink';
import { TableRow } from 'components/peerComparisonsView/components/tableRow';
import { SortIndicator } from 'components/table/sortIndicator';
import { SortOrder } from 'components/table/sorting';
import { useSort } from 'hooks/useSort';
import React, { CSSProperties, useCallback } from 'react';
import { PeerComparisonsColumn, PeerComparisonsColumnGroup } from 'types/peerComparisons';

interface Props {
  readonly columnGroups: readonly PeerComparisonsColumnGroup[];
  readonly gridStyle: CSSProperties;
  readonly firstRow: Record<string, any> | null;

  valueFormatter(rawValue: number, column: PeerComparisonsColumn): string;
}

export const Header: React.FC<Props> = ({
  columnGroups,
  gridStyle,
  firstRow,
  valueFormatter,
}: Props): React.ReactElement => {
  const [sorting, onSort] = useSort<any>('name');

  const createSortHandler = useCallback(
    (name: string, order: SortOrder): VoidFunction =>
      (): void => {
        onSort(name, order);
      },
    [onSort],
  );

  return (
    <div className="sticky top-0 w-max bg-white z-1">
      <div className="w-min" style={gridStyle}>
        {/* Header Groups */}
        <div className="sticky left-0 border-r border-divider bg-white" />
        {columnGroups.map(
          (group: PeerComparisonsColumnGroup, index: number): React.ReactElement => {
            const { columns } = group;
            const style = { gridColumn: `auto / span ${columns.length}` };
            const className = [
              groupHeaderClassName,
              index === 0 ? undefined : 'border-l border-divider',
            ].join(' ');

            return (
              <div id={group.name} key={group.name} style={style} className={className}>
                {group.label}
              </div>
            );
          },
        )}
      </div>

      {/* Header Elements */}
      <div className="relative w-min bg-white" style={gridStyle}>
        <div className={companyNameHeaderClassName}>Company Name</div>
        {columnGroups.map(
          (group: PeerComparisonsColumnGroup, groupIndex: number): React.ReactNode => {
            const { columns } = group;

            return columns.map(
              (column: PeerComparisonsColumn, index: number): React.ReactElement => {
                const dividerClassName = groupIndex > 0 && index === 0 ? 'border-l' : undefined;
                const className = [headerClassName, dividerClassName].join(' ');
                const sortKey = `${group.name}.${column.name}`;

                return (
                  <div key={column.name} className={className}>
                    <div className="flex-1 text-center pl-5">{column.label}</div>
                    <div>
                      <SortIndicator
                        sortOrder={sorting[sortKey]}
                        onSortAscending={createSortHandler(sortKey, SortOrder.ascending)}
                        onSortDescending={createSortHandler(sortKey, SortOrder.descending)}
                      />
                    </div>
                  </div>
                );
              },
            );
          },
        )}
        {!!firstRow && (
          <TableRow
            data={firstRow}
            columnGroups={columnGroups}
            cellTitle={<CompanyLink data={firstRow['company']} clickable={true} />}
            extractValue={valueFormatter}
            highlight={true}
          />
        )}
      </div>
    </div>
  );
};

const headerClassName =
  'flex items-center gap-3 text-gray-darkest text-center first:px-2 px-3 py-2 uppercase h-full leading-4 border-b border-divider' +
  ' cursor-default text-xs font-poppinsSemiBold';

const groupHeaderClassName =
  'font-poppinsSemiBold text-gray-darkest text-center py-2 uppercase text-xs';
const companyNameHeaderClassName = `${headerClassName} whitespace-nowrap sticky left-0 bg-white border-r z-1`;
