import React from 'react';
import {
  isPortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem,
  isPortfolioCompanyClientOrCustomerCashManagementOrDepositsItem,
  isPortfolioCompanyClientOrCustomerForeignExchangeItem,
  isPortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem,
  isPortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem,
  isPortfolioCompanyClientOrCustomerOtherItem,
  PortfolioCompanyClientOrCustomerItem,
} from 'types/portfolioItem';
import { ClientOrCustomerAdvisoryOrConsultingForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerAdvisoryOrConsultingForm';
import { ClientOrCustomerCashManagementOrDepositsForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerCashManagementOrDepositsForm';
import { ClientOrCustomerForeignExchangeForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerForeignExchangeForm';
import { ClientOrCustomerHedgingOrRiskManagementForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerHedgingOrRiskManagementForm';
import { ClientOrCustomerLoanOrDebtRelatedForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerLoanOrDebtRelatedForm';
import { ClientOrCustomerOtherForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerOther';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerItem;
}

export const ClientOrCustomerItemFormSelector: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  if (isPortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem(item)) {
    return <ClientOrCustomerAdvisoryOrConsultingForm item={item} />;
  } else if (isPortfolioCompanyClientOrCustomerCashManagementOrDepositsItem(item)) {
    return <ClientOrCustomerCashManagementOrDepositsForm item={item} />;
  } else if (isPortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem(item)) {
    return <ClientOrCustomerLoanOrDebtRelatedForm item={item} />;
  } else if (isPortfolioCompanyClientOrCustomerForeignExchangeItem(item)) {
    return <ClientOrCustomerForeignExchangeForm item={item} />;
  } else if (isPortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem(item)) {
    return <ClientOrCustomerHedgingOrRiskManagementForm item={item} />;
  } else if (isPortfolioCompanyClientOrCustomerOtherItem(item)) {
    return <ClientOrCustomerOtherForm item={item} />;
  } else {
    return <></>;
  }
};
