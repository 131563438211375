import { Clickable } from 'components/clickable';
import { ConditionalRender } from 'components/conditionalRenderer';
import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import { capitalize } from 'lodash';
import React from 'react';
import { Organization, OrganizationTypes } from 'redux/reducers/smartToolsRecentSearchesReducer';

interface Props {
  readonly items: readonly Organization[];
  readonly fetching: boolean;

  onItemDeleted(id: string): void;
  onItemClicked(item: Organization): void;
}

export const RecentItems: React.FC<Props> = ({
  items,
  fetching,
  onItemClicked,
  onItemDeleted,
}: Props): React.ReactElement => {
  return (
    <div className="relative mt-4 z-0 bg-white overflow-y-auto scroller flex-grow">
      <ConditionalRender renderIf={items.length > 0}>
        <h4 className="sticky top-0 pb-1 mb-1 bg-white">Recent Searches</h4>
      </ConditionalRender>
      {items.map((item: Organization): React.ReactElement => {
        return (
          <Clickable key={item.id} clickData={item} onClick={onItemClicked}>
            <div className="flex items-center justify-between p-2 border-b border-gray-medium hover:bg-gray-light cursor-pointer">
              <div>
                <div className="flex items-center py-0">
                  <h5 className="leading-4 py-0 my-0">{item.name}</h5>
                  <span>&ensp;</span>
                  <span className="text-blue italic text-sm">
                    {item.type === OrganizationTypes.company ? item.ticker ?? 'None' : 'Industry'}
                  </span>
                </div>
                <div>
                  <p className="text-gray text-xs">{capitalize(item.type)}</p>
                </div>
              </div>
              <Clickable clickData={item.id} onClick={onItemDeleted}>
                <div className="flex items-center justify-center w-7 h-7 rounded-full cursor-pointer hover:text-red-700">
                  <SVGIcon name="trash-generic" className="w-4 h-4 fill-current" />
                </div>
              </Clickable>
            </div>
          </Clickable>
        );
      })}
      <SpinnerLoader visible={fetching} />
    </div>
  );
};
