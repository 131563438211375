import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { AccessMode } from 'types/accessMode';
import { ProfileStatementGroup, Statement } from 'types/financial';
import { CompanyRow, FinancialInfo, FinancialRow } from 'types/organization/types';
import { Page } from 'types/page';
import { KeyTrend, PeerComparisons, SwotAnalysis } from 'types/peerComparisons';
import { Stream } from 'types/timeline';
import { CountryDetails } from 'views/DataAnalysis/Country/types';
import { CountryOfInterest } from 'views/DataAnalysis/Industry/countries';
import { Industry, IndustryDetails } from 'views/DataAnalysis/Industry/types';

export interface IndustryWCountries extends IndustryDetails {
  countries: CountryDetails[];
}

export interface IndustryStats {
  readonly id: string;
  readonly name: string;
  readonly sectors: string[];
  readonly number_of_companies_as_primary_industry: number;
  readonly percentage_of_companies_as_primary_industry: number;
  readonly total_number_of_companies: number;
  readonly percentage_of_total_number_of_companies: number;
}

export interface IndustriesState {
  loadingIndustries: boolean;
  industries: Page<Industry>;
  myIndustries: Page<IndustryStats>;
  // Industry Details
  loadingIndustryDetails: boolean;
  industryDetails?: IndustryDetails;
  financialMetrics: Statement;
  fetchingFinancialMetrics: boolean;
  // Country OverviewAndAlgoNotes
  loadingSummary: boolean;
  summary: ProfileStatementGroup[];
  summaryMap?: { [key: string]: CompanyRow };
  loadingForecast: boolean;
  forecast: ProfileStatementGroup[];
  industryFinancialHighlights?: FinancialInfo;
  industryFinancialHighlightsRows: FinancialRow[];
  loadingIndustryFinancialHighlights: boolean;
  // Industry metrics and indicators
  industryMetricsAndIndicators?: FinancialInfo;
  industryMetricsAndIndicatorsRows: FinancialRow[];
  loadingIndustryMetricsAndIndicators: boolean;
  // Industry income statement
  industryIncomeStatement?: FinancialInfo;
  industryIncomeStatementRows: FinancialRow[];
  loadingIndustryIncomeStatement: boolean;
  // Industry Cash Flow statement
  industryCashFlowStatement?: FinancialInfo;
  industryCashFlowStatementRows: FinancialRow[];
  loadingIndustryCashFlowStatement: boolean;
  // Industry Balance Sheet
  industryBalanceSheet?: FinancialInfo;
  industryBalanceSheetRows: FinancialRow[];
  loadingIndustryBalanceSheet: boolean;
  // Industry characterization & strategy
  industryCharacterization?: FinancialInfo;
  industryCharacterizationRows: FinancialRow[];
  loadingIndustryCharacterization: boolean;
  // Industry operations highlights
  industryOperationsHighlights?: FinancialInfo;
  industryOperationsHighlightsRows: FinancialRow[];
  loadingIndustryOperationsHighlights: boolean;
  // Industry assets and resources
  industryAssetsAndResources?: FinancialInfo;
  industryAssetsAndResourcesRows: FinancialRow[];
  loadingIndustryAssetsAndResources: boolean;
  // Trends and Frameworks
  // Key Trend
  loadingKeyTrends: boolean;
  keyTrends: KeyTrend[];
  // Swot Analysis
  loadingSwotsAnalysis: boolean;
  swotsAnalysis: SwotAnalysis[];
  // Geo And Maps
  industriesWCountries: IndustryWCountries[];

  peerComparisons: PeerComparisons;
  fetchingPeerComparisons: boolean;

  industryTimeline: Stream;
  fetchingIndustryTimeline: boolean;
  selectedCountry: CountryOfInterest;
}

const emptyPeerComparisons: PeerComparisons = {
  groups: [],
  notes: [],
  rows: [],
  summary: {
    low: {},
    median: {},
    high: {},
    mean: {},
  },
};

const initialState: IndustriesState = {
  loadingIndustries: false,
  industries: Page.empty(),
  myIndustries: Page.empty(),
  // Industry Details
  loadingIndustryDetails: false,
  financialMetrics: Statement.empty(),
  fetchingFinancialMetrics: false,
  // Country OverviewAndAlgoNotes
  loadingSummary: false,
  summary: [],
  summaryMap: undefined,
  loadingForecast: false,
  forecast: [],
  // Industry Financial Highlights
  industryFinancialHighlights: undefined,
  industryFinancialHighlightsRows: [],
  loadingIndustryFinancialHighlights: false,
  // Industry metrics and indicators
  industryMetricsAndIndicators: undefined,
  industryMetricsAndIndicatorsRows: [],
  loadingIndustryMetricsAndIndicators: false,
  // Industry income statement
  industryIncomeStatement: undefined,
  industryIncomeStatementRows: [],
  loadingIndustryIncomeStatement: false,
  // Industry Cash Flow statement
  industryCashFlowStatement: undefined,
  industryCashFlowStatementRows: [],
  loadingIndustryCashFlowStatement: false,
  // Industry Balance Sheet
  industryBalanceSheet: undefined,
  industryBalanceSheetRows: [],
  loadingIndustryBalanceSheet: false,
  // Industry characterization and strategy
  industryCharacterization: undefined,
  industryCharacterizationRows: [],
  loadingIndustryCharacterization: false,
  // Industry operations highlights
  industryOperationsHighlights: undefined,
  industryOperationsHighlightsRows: [],
  loadingIndustryOperationsHighlights: false,
  // Industry assets and resources
  industryAssetsAndResources: undefined,
  industryAssetsAndResourcesRows: [],
  loadingIndustryAssetsAndResources: false,
  // Trends And Frameworks
  // Key Trends
  loadingKeyTrends: false,
  keyTrends: [],
  // Swot Analysis
  loadingSwotsAnalysis: false,
  swotsAnalysis: [],
  // Geo And Maps
  industriesWCountries: [],

  peerComparisons: emptyPeerComparisons,
  fetchingPeerComparisons: false,

  industryTimeline: {
    id: '',
    name: '',
    tags: [],
    members_count: 0,
    summary: '',
    category: 'INDUSTRY',
    events: [],
    access_mode: AccessMode.readOnly,
    author: '',
  },
  fetchingIndustryTimeline: false,
  selectedCountry: 'US',
};

const industrySlice = createSlice({
  name: 'industry',
  initialState,
  reducers: {
    getIndustries: state => {
      state.loadingIndustries = true;
      state.industries = Page.empty();
    },
    getMyIndustriesSuccess: (state, { payload }: PayloadAction<Page<IndustryStats>>): void => {
      state.loadingIndustries = false;
      state.myIndustries = payload;
    },
    getIndustriesSuccess: (state, { payload }: PayloadAction<Page<Industry>>) => {
      state.loadingIndustries = false;
      state.industries = payload;
    },
    getIndustriesFailed: state => {
      state.loadingIndustries = false;
    },
    // Industry Details
    getIndustryDetails: (state, { payload: industryId }: PayloadAction<string>) => {
      state.loadingIndustryDetails =
        !state.industryDetails || state.industryDetails.id !== industryId;
    },
    getIndustryDetailsSuccess: (state, { payload }: PayloadAction<IndustryDetails>) => {
      state.loadingIndustryDetails = false;
      state.industryDetails = payload;
    },
    getIndustryDetailsFailed: state => {
      state.loadingIndustryDetails = false;
    },
    // OverviewAndAlgoNotes Summary
    getSummary: state => {
      state.loadingSummary = true;
    },
    getSummarySuccess: (state, { payload }: PayloadAction<ProfileStatementGroup[]>) => {
      state.loadingSummary = false;
      state.summary = payload;
    },
    getFinancialMetrics: (state: IndustriesState): void => {
      state.fetchingFinancialMetrics = true;
    },
    getFinancialMetricsFailed: (state: IndustriesState): void => {
      state.fetchingFinancialMetrics = false;
    },
    getFinancialMetricsSuccess: (
      state: IndustriesState,
      { payload }: PayloadAction<Statement>,
    ): void => {
      state.fetchingFinancialMetrics = false;
      state.financialMetrics = payload;
    },
    clearFinancialMetrics: (state: IndustriesState): void => {
      state.financialMetrics = Statement.empty();
    },
    getSummaryFailed: state => {
      state.loadingSummary = false;
    },
    // OverviewAndAlgoNotes Forecast and Trends
    getForecast: state => {
      state.loadingForecast = true;
    },
    getForecastSuccess: (state, { payload }: PayloadAction<ProfileStatementGroup[]>) => {
      state.loadingForecast = false;
      state.forecast = payload;
    },
    getForecastFailed: state => {
      state.loadingForecast = false;
    },
    // Financials Highlights
    getIndustryFinancialHighlights: state => {
      state.loadingIndustryFinancialHighlights = true;
    },
    getIndustryFinancialHighlightsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryFinancialHighlights: FinancialInfo;
        industryFinancialHighlightsRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryFinancialHighlights = false;
      state.industryFinancialHighlights = payload.industryFinancialHighlights;
      state.industryFinancialHighlightsRows = payload.industryFinancialHighlightsRows;
    },
    getIndustryFinancialHighlightsFailed: state => {
      state.loadingIndustryFinancialHighlights = false;
    },
    // Industry Metrics and Indicators
    getIndustryMetricsAndIndicators: state => {
      state.loadingIndustryMetricsAndIndicators = true;
    },
    getIndustryMetricsAndIndicatorsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryMetricsAndIndicators: FinancialInfo;
        industryMetricsAndIndicatorsRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryMetricsAndIndicators = false;
      state.industryMetricsAndIndicators = payload.industryMetricsAndIndicators;
      state.industryMetricsAndIndicatorsRows = payload.industryMetricsAndIndicatorsRows;
    },
    getIndustryMetricsAndIndicatorsFailed: state => {
      state.loadingIndustryMetricsAndIndicators = false;
    },
    // Industry Income Statement
    getIndustryIncomeStatement: state => {
      state.loadingIndustryIncomeStatement = true;
    },
    getIndustryIncomeStatementSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryIncomeStatement: FinancialInfo;
        industryIncomeStatementRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryIncomeStatement = false;
      state.industryIncomeStatement = payload.industryIncomeStatement;
      state.industryIncomeStatementRows = payload.industryIncomeStatementRows;
    },
    getIndustryIncomeStatementFailed: state => {
      state.loadingIndustryIncomeStatement = false;
    },
    // Industry Cash Flow Statement
    getIndustryCashFlowStatement: state => {
      state.loadingIndustryCashFlowStatement = true;
    },
    getIndustryCashFlowStatementSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryCashFlowStatement: FinancialInfo;
        industryCashFlowStatementRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryCashFlowStatement = false;
      state.industryCashFlowStatement = payload.industryCashFlowStatement;
      state.industryCashFlowStatementRows = payload.industryCashFlowStatementRows;
    },
    getIndustryCashFlowStatementFailed: state => {
      state.loadingIndustryCashFlowStatement = false;
    },
    // Industry Balance Sheet
    getIndustryBalanceSheet: state => {
      state.loadingIndustryBalanceSheet = true;
    },
    getIndustryBalanceSheetSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryBalanceSheet: FinancialInfo;
        industryBalanceSheetRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryBalanceSheet = false;
      state.industryBalanceSheet = payload.industryBalanceSheet;
      state.industryBalanceSheetRows = payload.industryBalanceSheetRows;
    },
    getIndustryBalanceSheetFailed: state => {
      state.loadingIndustryBalanceSheet = false;
    },
    // Industry Characterization
    getIndustryCharacterization: state => {
      state.loadingIndustryCharacterization = true;
    },
    getIndustryCharacterizationSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryCharacterization: FinancialInfo;
        industryCharacterizationRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryCharacterization = false;
      state.industryCharacterization = payload.industryCharacterization;
      state.industryCharacterizationRows = payload.industryCharacterizationRows;
    },
    getIndustryCharacterizationFailed: state => {
      state.loadingIndustryCharacterization = false;
    },
    // Industry Operations Highlights
    getIndustryOperationsHighlights: state => {
      state.loadingIndustryOperationsHighlights = true;
    },
    getIndustryOperationsHighlightsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryOperationsHighlights: FinancialInfo;
        industryOperationsHighlightsRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryOperationsHighlights = false;
      state.industryOperationsHighlights = payload.industryOperationsHighlights;
      state.industryOperationsHighlightsRows = payload.industryOperationsHighlightsRows;
    },
    getIndustryOperationsHighlightsFailed: state => {
      state.loadingIndustryOperationsHighlights = false;
    },
    // Industry Assets And Resources
    getIndustryAssetsAndResources: state => {
      state.loadingIndustryAssetsAndResources = true;
    },
    getIndustryAssetsAndResourcesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        industryAssetsAndResources: FinancialInfo;
        industryAssetsAndResourcesRows: FinancialRow[];
      }>,
    ) => {
      state.loadingIndustryAssetsAndResources = false;
      state.industryAssetsAndResources = payload.industryAssetsAndResources;
      state.industryAssetsAndResourcesRows = payload.industryAssetsAndResourcesRows;
    },
    getIndustryAssetsAndResourcesFailed: state => {
      state.loadingIndustryAssetsAndResources = false;
    },
    // Trends And Frameworks
    // Key Trends
    getKeyTrends: state => {
      state.loadingKeyTrends = true;
    },
    getKeyTrendsSuccess: (state, { payload }: PayloadAction<{ keyTrends: KeyTrend[] }>) => {
      state.loadingKeyTrends = false;
      state.keyTrends = payload.keyTrends;
    },
    getKeyTrendsFailed: state => {
      state.loadingKeyTrends = false;
    },
    // Swot Analysis
    getSwotsAnalysis: state => {
      state.loadingSwotsAnalysis = true;
    },
    getSwotsAnalysisSuccess: (
      state,
      { payload }: PayloadAction<{ swotsAnalysis: SwotAnalysis[] }>,
    ) => {
      state.loadingSwotsAnalysis = false;
      state.swotsAnalysis = payload.swotsAnalysis;
    },
    getSwotsAnalysisFailed: state => {
      state.loadingSwotsAnalysis = false;
    },
    getIndustryWCountries: (
      state,
      { payload }: PayloadAction<{ industriesWCountries: IndustryWCountries[] }>,
    ) => {
      state.industriesWCountries = payload.industriesWCountries;
    },
    peerComparisonsReset: (state: IndustriesState): void => {
      state.peerComparisons = emptyPeerComparisons;
    },
    peerComparisonsFetchingStarted: (state: IndustriesState): void => {
      state.fetchingPeerComparisons = true;
    },
    peerComparisonsFetchingSucceeded: (
      state: IndustriesState,
      { payload }: PayloadAction<PeerComparisons>,
    ): void => {
      state.fetchingPeerComparisons = false;
      state.peerComparisons = payload;
    },
    peerComparisonsFetchingFailed: (
      state: IndustriesState,
      { payload }: PayloadAction<any>,
    ): void => {
      console.warn(payload);
      state.fetchingPeerComparisons = false;
    },
    timelineFetchingStarted: (state: IndustriesState): void => {
      state.fetchingIndustryTimeline = true;
    },
    timelineFetchingSucceeded: (
      state: IndustriesState,
      { payload }: PayloadAction<Stream>,
    ): void => {
      state.fetchingIndustryTimeline = false;
      state.industryTimeline = payload;
    },
    timelineFetchingFailed: (state: IndustriesState): void => {
      state.fetchingIndustryTimeline = false;
    },
    setSelectedCountry: (
      state: IndustriesState,
      action: PayloadAction<CountryOfInterest>,
    ): void => {
      state.selectedCountry = action.payload;
    },
  },
});

export const {
  getIndustries,
  getIndustriesSuccess,
  getIndustriesFailed,
  getMyIndustriesSuccess,

  // Industry Details
  getIndustryDetails,
  getIndustryDetailsFailed,
  getIndustryDetailsSuccess,
  // OverviewAndAlgoNotes
  getSummary,
  getSummaryFailed,
  getSummarySuccess,
  // Financial Metrics
  getFinancialMetrics,
  getFinancialMetricsFailed,
  getFinancialMetricsSuccess,
  clearFinancialMetrics,
  // Peer comparisons
  peerComparisonsReset,
  peerComparisonsFetchingStarted,
  peerComparisonsFetchingSucceeded,
  peerComparisonsFetchingFailed,
  // TimelineAndEvents
  timelineFetchingStarted,
  timelineFetchingSucceeded,
  timelineFetchingFailed,
  setSelectedCountry,
} = industrySlice.actions;

export const selectedCountrySelector = (state: ApplicationState): CountryOfInterest =>
  state.industries.selectedCountry;

// My Industry
export const loadingIndustriesSelector = (state: ApplicationState) =>
  state.industries.loadingIndustries;
export const industriesSelector = (state: ApplicationState): Page<Industry> =>
  state.industries.industries;

export const industryDetailsSelector = (state: ApplicationState) =>
  state.industries.industryDetails;

// OverviewAndAlgoNotes
export const loadingSummarySelector = (state: ApplicationState) => state.industries.loadingSummary;
export const summarySelector = (state: ApplicationState) => state.industries.summary;

export const fetchingPeerComparisonsSelector = (state: ApplicationState): boolean =>
  state.industries.fetchingPeerComparisons;

export const peerComparisonsSelector = (state: ApplicationState): PeerComparisons =>
  state.industries.peerComparisons;

export const fetchingIndustryTimelineSelector = (state: ApplicationState): boolean =>
  state.industries.fetchingIndustryTimeline;

export const industryTimelineSelector = (state: ApplicationState): Stream =>
  state.industries.industryTimeline;

export const myIndustriesSelector = (state: ApplicationState): Page<IndustryStats> =>
  state.industries.myIndustries;

export const industryFinancialMetricsSelector = (state: ApplicationState): Statement =>
  state.industries.financialMetrics;

export const industryFinancialMetricsLoadingSelector = (state: ApplicationState): boolean =>
  state.industries.fetchingFinancialMetrics;

export default industrySlice.reducer;
