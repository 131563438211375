export const HttpStatusUserBlocked = 456;
export const HttpStatusOk = 200;
export const HttpStatusCreated = 201;
export const HttpStatusAlreadyReported = 208;
export const HttpStatusPaymentRequired = 402;
export const HttpStatusNotFound = 404;
export const HttpStatusConflict = 409;
export const HttpStatusGone = 410;
export const HttpStatusUnauthorized = 401;
export const HttpStatusPreconditionFailed = 412;
// Custom codes
export const HttpStatusTooManyRequests = 429;
export const HttpStatusQuotaExceeded = 453;
export const HttpStatusUnverifiedRegisteredUser = 454;
export const HttpStatusUserSuspended = 455;
