import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  financialStatementsOptionsSelector,
  resetFinancialStatementsOptions,
} from 'redux/reducers/financialStatementsReducer';
import QuarterlyFinancialStatementSelector from 'views/DataAnalysis/Company/Details/Financials/quarterlyFinancialStatements';
import YearlyFinancialStatements from 'views/DataAnalysis/Company/Details/Financials/yearlyFinancialStatements';

export const ByPeriodFinancialStatementSelector: React.FC = (): React.ReactElement => {
  const options = useSelector(financialStatementsOptionsSelector);
  const dispatch = useDispatch();

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(resetFinancialStatementsOptions());
    };
  }, [dispatch]);

  if (options.dimension === 'MRY') {
    return <YearlyFinancialStatements />;
  } else {
    return <QuarterlyFinancialStatementSelector />;
  }
};
