import { ConditionalRender } from 'components/conditionalRenderer';
import Checkbox from 'components/DEPRECATED/checkbox/Checkbox';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { YupError } from 'utils/forms';

interface Props {
  readonly termsAndConditionsAccepted: boolean;
  readonly communicationAccepted: boolean;
  readonly error?: YupError;

  onChange(termsAndConditionsAccepted: boolean, communicationAccepted: boolean): void;
}

export const TermsConditionsCommunicationCheckboxes: React.FC<Props> = ({
  error,

  termsAndConditionsAccepted,
  communicationAccepted,

  onChange,
}: Props): React.ReactElement => {
  const handleTermsAndConditionsAcceptedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      onChange(target.checked, communicationAccepted);
    },
    [communicationAccepted, onChange],
  );

  const handleCommunicationAcceptedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      onChange(termsAndConditionsAccepted, target.checked);
    },
    [onChange, termsAndConditionsAccepted],
  );

  return (
    <div className="flex items-start mt-8 flex-col justify-start w-full">
      <div>
        <Checkbox
          id="terms-and-agreement-checkbox"
          className="mb-6"
          checked={termsAndConditionsAccepted}
          onChange={handleTermsAndConditionsAcceptedChange}
        >
          <div className="relative leading-none aligned">
            <ConditionalRender renderIf={!!error}>
              <div className="absolute mt-2 top-full left-0 bg-gray text-white p-2 rounded-md shadow-md font-poppins text-sm">
                {error?.message}
              </div>
            </ConditionalRender>
            <span className="font-poppins text-gray text-base ">I have read and agree to the </span>
            <Link
              target="_blank"
              to="/terms-and-conditions"
              className="normal-case text-blue font-poppinsSemiBold text-base"
            >
              Terms & Conditions
            </Link>
          </div>
        </Checkbox>
      </div>
      <Checkbox
        id="email-communication-checkbox"
        className="mb-6"
        label={receiveCommunicationsText}
        checked={communicationAccepted}
        onChange={handleCommunicationAcceptedChange}
      />
    </div>
  );
};

const receiveCommunicationsText =
  'I would like to receive communication from Finvar and external partners on special offers, deals and opportunities from time to time.';
