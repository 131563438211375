import React, { useEffect, useMemo, useState } from 'react';
import { getTimeZone, getTodayFormatDate } from 'utils';

export const Clock: React.FC = (): React.ReactElement => {
  const [date, setDate] = useState<string>(getTodayFormatDate());
  const timeZone = useMemo((): string => getTimeZone(), []);

  useEffect((): VoidFunction => {
    const interval = setTimeout(() => {
      setDate(getTodayFormatDate());
    }, 30000);

    return (): void => {
      clearInterval(interval);
    };
  }, []);

  return <span className="header-date">{`${date} ${timeZone}`}</span>;
};
