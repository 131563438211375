import Button from 'components/buttons/Button/Button';
import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import { noop } from 'lodash';
import React, { FormEvent, useCallback, useEffect, useMemo, useReducer } from 'react';
import { ProcessingStateEnum } from 'types/processingState';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { passwordSchema, ValidationErrors } from 'utils/forms';
import { HttpStatusUnauthorized } from 'utils/statusCodes';
import { PasswordInput } from 'views/Landing/component/passwordInput';
import reducer, {
  Action,
  ChangePasswordForm,
  CloseErrorMessageAction,
  EditValidationCompletedAction,
  initialState,
  ResetAction,
  SetConfirmNewPasswordAction,
  SetCurrentPasswordAction,
  SetNewPasswordAction,
  State,
  SubmissionCompletedAction,
  SubmissionFailedAction,
  SubmissionStartedAction,
  SubmissionSucceededAction,
} from 'views/Profile/Settings/ChangePasswordModal/reducer';
import * as yup from 'yup';
import { ValidationError } from 'yup';

const UnauthorizedError = new Error('not authorized to change password');
const UnknownError = new Error('unknown error');

interface Props {
  onClose(): void;
}

export const ChangePasswordModal: React.FC<Props> = ({ onClose }: Props): React.ReactElement => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(reducer, initialState);
  const { errors, processingState } = state;

  const form = useMemo(
    (): ChangePasswordForm => ({
      currentPassword: state.currentPassword,
      newPassword: state.newPassword,
      confirmNewPassword: state.confirmNewPassword,
    }),
    [state.confirmNewPassword, state.currentPassword, state.newPassword],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent): Promise<void> => {
      event.preventDefault();

      try {
        await schema.validate(form, { abortEarly: false });
        dispatch(SubmissionStartedAction.create());

        await updatePassword(form);
        dispatch(SubmissionSucceededAction.create());
      } catch (error: any) {
        if (error === UnauthorizedError || error === UnknownError) {
          dispatch(SubmissionFailedAction.create(error));
        }
      } finally {
        dispatch(SubmissionCompletedAction.create());
      }
    },
    [form],
  );

  const setCurrentPassword = useCallback((value: string): void => {
    dispatch(SetCurrentPasswordAction.create(value));
  }, []);

  const setNewPassword = useCallback((value: string): void => {
    dispatch(SetNewPasswordAction.create(value));
  }, []);

  const setConfirmNewPassword = useCallback((value: string): void => {
    dispatch(SetConfirmNewPasswordAction.create(value));
  }, []);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(ResetAction.create());
    };
  }, []);

  useEffect((): VoidFunction => {
    let canceled = false;

    const timeout = setTimeout((): void => {
      schema
        .validate(form, {
          context: { isInEditMode: !state.submitted },
          abortEarly: false,
        })
        .then((): void => {
          if (!canceled) {
            dispatch(EditValidationCompletedAction.create({}));
          }
        })
        .catch((error: ValidationError): void => {
          if (!canceled) {
            dispatch(EditValidationCompletedAction.create(ValidationErrors.reduce(error.inner)));
          }
        });
    }, 300);

    return (): void => {
      canceled = true;

      clearTimeout(timeout);
    };
  }, [form, state.submitted]);

  const handleCloseErrorMessage = useCallback((): void => {
    dispatch(CloseErrorMessageAction.create());
  }, []);

  if (processingState.state === ProcessingStateEnum.success) {
    return (
      <div className="md:w-modal-xs w-full">
        <div className="flex items-start gap-4 p-4">
          <SVGIcon name="success-icon" className="w-14" />
          <div>
            <h2 className="mb-2 text-2xl">Password Changed</h2>
            <h6 className="text-gray text-md">Your password was updated successfully</h6>
          </div>
        </div>
        <div className="w-full flex items-center justify-end font-poppinsSemiBold text-sm mt-10">
          <button className="w-32 text-blue p-2 rounded-5 hover:bg-gray-light" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  } else if (processingState.state === ProcessingStateEnum.error) {
    return (
      <div className="md:w-modal-xs w-full">
        <div className="flex items-start gap-4 p-4">
          <SVGIcon name="error-icon" className="w-14" />
          <div>
            <h2 className="mb-2 text-2xl">There was an error</h2>
            <h6 className="text-gray text-md">
              {processingState.data === UnauthorizedError
                ? 'You are not authorized to change the password. Please check that your current password is correct'
                : "We we're not able to update your password."}
            </h6>
          </div>
        </div>
        <div className="w-full flex items-center justify-end font-poppinsSemiBold text-sm mt-10">
          <button
            className="w-32 text-blue p-2 rounded-5 hover:bg-gray-light"
            onClick={handleCloseErrorMessage}
          >
            Close
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative md:w-modal-xs w-full p-4">
        <h3 className="text-center">Change Password</h3>
        <form onSubmit={handleSubmit} className="mt-4" autoComplete="off" noValidate>
          <div className="flex flex-col gap-4">
            <PasswordInput
              id="current-password"
              value={state.currentPassword}
              withHelp={false}
              create={false}
              label="Current Password"
              error={errors.currentPassword}
              onChange={setCurrentPassword}
            />
            <PasswordInput
              id="new-password"
              value={state.newPassword}
              withHelp={true}
              create={true}
              label="New Password"
              error={errors.newPassword}
              onChange={setNewPassword}
            />
            <PasswordInput
              id="confirm-new-password"
              value={state.confirmNewPassword}
              withHelp={false}
              create={true}
              label="Confirm New Password"
              error={errors.confirmNewPassword}
              onChange={setConfirmNewPassword}
            />
            <Button
              type="submit"
              label="Submit"
              width="w-full"
              padding="p-3"
              height="h-auto"
              onClick={noop}
            />
          </div>
        </form>
        <SpinnerLoader visible={processingState.state === ProcessingStateEnum.processing} />
      </div>
    );
  }
};

const schema = yup.object().shape({
  newPassword: passwordSchema.when('$isInEditMode', (isInEditMode: boolean, schema: any): any =>
    isInEditMode
      ? schema
      : schema.notOneOf(
          [yup.ref('currentPassword')],
          'New password must not match with the current',
        ),
  ),
  confirmNewPassword: yup
    .string()
    .when('$isInEditMode', (isInEditMode: boolean, schema: any): any =>
      isInEditMode
        ? schema
        : schema.required().oneOf([yup.ref('newPassword')], 'Confirm password does not match'),
    ),
});

export const updatePassword = async (form: ChangePasswordForm): Promise<void> => {
  try {
    await api.post(`${API_V1_PATH}/account/change-password`, {
      new_password: form.newPassword,
      old_password: form.currentPassword,
      repeat_new_password: form.confirmNewPassword,
    });
  } catch (axiosError: any) {
    const { response } = axiosError;

    if (response && response.status === HttpStatusUnauthorized) {
      throw UnauthorizedError;
    }

    throw UnknownError;
  }
};
