import Toggle from 'components/toggle/Toggle';
import React, { useCallback } from 'react';
import { UserPermission } from 'redux/reducers/profileReducer';

interface Props {
  readonly permission: UserPermission;

  onChange(permission: UserPermission): void;
}

export const PermissionToggle: React.FC<Props> = (props: Props): React.ReactElement => {
  const { permission, onChange } = props;

  const handleChange = useCallback(
    (value: boolean): void => {
      onChange({ ...permission, granted: value });
    },
    [onChange, permission],
  );

  return (
    <Toggle
      id={permission.code}
      toggleId={`${permission.code}-toggle`}
      label=""
      checked={permission.granted}
      flat={true}
      onChange={handleChange}
    />
  );
};
