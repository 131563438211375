import Button from 'components/buttons/Button/Button';
import { ConditionalRender } from 'components/conditionalRenderer';
import Tooltip from 'components/DEPRECATED/tooltips/tooltip';
import { Disabler } from 'components/disabler';
import { Input } from 'components/forms/input';
import { TagsInput } from 'components/forms/tagsInput';
import { FormValue } from 'components/formValue';
import SVGIcon from 'components/icons/SVGIcon';
import { InfoLabel } from 'components/infoLabel';
import MembersInput from 'components/membersInput/MembersInput';
import { Modal } from 'components/modal';
import RadioInput from 'components/radioInput/RadioInput';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Portfolio } from 'redux/reducers/portfolioReducer';
import {
  AddPortfolioPayload,
  addPortfolioService,
  editPortfolioService,
} from 'redux/services/portfolioService';
import {
  isExternalMember,
  isInternalMember,
  Member,
  toExternalMemberPayload,
  toInternalMemberPayload,
} from 'sharable/types';
import { AccessMode } from 'types/accessMode';
import { isBeta } from 'utils';
import { FormErrors, YupError } from 'utils/forms';
import * as yup from 'yup';

type Props = {
  initialData?: Portfolio;

  onClose(): void;
};

type State = Omit<Portfolio, 'members_count'> & {
  readonly members: readonly Member[];
};

const initialState: State = {
  access_mode: AccessMode.readOnly,
  id: '',
  name: '',
  summary: '',
  type: 'Public',
  tags: [],
  members: [],
};

const schema = yup.object().shape({
  name: yup.string().required('Please enter a Name for your Portfolio'),
});

const CreateEditPortfoliosModal: React.FC<Props> = ({
  initialData,
  onClose,
}: Props): React.ReactElement => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useDispatch<any>();
  // TODO: need to add error handling
  const [_, setErrors] = useState<FormErrors>({});
  const { trackEvent } = useGtag();

  useEffect(() => {
    if (initialData === undefined) {
      setState(initialState);
    } else {
      setState({
        id: initialData.id,
        // FIXME: load from backend
        members: [],
        tags: initialData.tags,
        name: initialData.name,
        summary: initialData.summary,
        type: initialData.type,
        access_mode: initialData.access_mode,
      });
    }
  }, [initialData]);

  const handleMembersChange = useCallback((members: readonly Member[]): void => {
    setState((previousState: State): State => ({ ...previousState, members: members }));
  }, []);

  const onCreate = async () => {
    const { members } = state;

    const portfolioData = {
      name: state.name,
      summary: state.summary,
      type: state.type,
      tags: state.tags,
    };

    try {
      setErrors({});
      await schema.validate(portfolioData, { abortEarly: true });

      const payload: AddPortfolioPayload = {
        ...portfolioData,

        members: members.filter(isInternalMember).map(toInternalMemberPayload),
        external_members: members.filter(isExternalMember).map(toExternalMemberPayload),
        route: '/portfolios/details/{{.ID}}',
      };

      if (initialData !== undefined) {
        dispatch(editPortfolioService(payload, state.id));

        trackEvent('end-edit-portfolio', {
          ...payload,
          portfolio_id: state.id,
        });
      } else {
        dispatch(addPortfolioService(payload));

        trackEvent('end-create-portfolio', {
          ...payload,
        });
      }

      onClose();
    } catch (error: any) {
      const errors = error.inner.reduce((errors: FormErrors, item: YupError): FormErrors => {
        const { path } = item;
        if (!path) {
          return errors;
        }

        return { ...errors, [path]: item };
      }, {});

      setErrors(errors);
    }
  };

  const handleFieldChange = useCallback((name: string, value: any): void => {
    setState((previousState: State): State => ({ ...previousState, [name]: value }));
  }, []);

  const privatePublicTooltipText = useMemo((): string => {
    return state.type === 'Public'
      ? 'Information on this portfolio will be seen by any member of Finvar'
      : 'Information on this portfolio will be seen by you and members only';
  }, [state.type]);

  const setPrivate = useCallback((): void => {
    setState((previousState: State): State => ({ ...previousState, type: 'Private' }));
  }, []);

  const setPublic = useCallback((): void => {
    setState((previousState: State): State => ({ ...previousState, type: 'Public' }));
  }, []);

  return (
    <Modal.Content title={`${initialData === undefined ? 'New' : 'Edit'} Portfolio`}>
      <form className="w-full md:w-modal-sm" autoComplete="off">
        <ConditionalRender renderIf={!isBeta}>
          <div className="flex items-center w-full justify-between">
            <InfoLabel label="TYPE" tooltipText={privatePublicTooltipText} />
            <div className="flex items-center">
              <RadioInput
                id="fv-portfolio-modal-1"
                label="Public"
                value={state.type}
                checked={state.type === 'Public'}
                className="ml-5"
                onChange={setPublic}
              />
              <RadioInput
                id="fv-portfolio-modal-2"
                label="Private"
                value={state.type}
                checked={state.type === 'Private'}
                className="ml-12 cursor-pointer"
                onChange={setPrivate}
              />
            </div>
          </div>
        </ConditionalRender>

        <div className="flex flex-col gap-2 my-5">
          <FormValue onChange={handleFieldChange}>
            <Input
              id="portfolio-name"
              name="name"
              value={state.name}
              label="Portfolio Name"
              maxLength={40}
            />
          </FormValue>

          <FormValue onChange={handleFieldChange}>
            <Input
              id="portfolio-summary"
              name="summary"
              value={state.summary}
              label="Portfolio Summary (Optional)"
            />
          </FormValue>

          <FormValue onChange={handleFieldChange}>
            <TagsInput id="portfolio-tags" value={state.tags} name="tags" />
          </FormValue>
        </div>

        {!isBeta && (
          <Disabler disabled={true}>
            <>
              <div className="flex items-center">
                <h4 className="text-gray-darkest mr-2">SHARE PORTFOLIO</h4>
                <Tooltip title="Share portfolio with members">
                  <SVGIcon name="info-icon" className="cursor-pointer w-4 h-4" />
                </Tooltip>
              </div>

              <MembersInput members={state?.members} onChange={handleMembersChange} />
            </>
          </Disabler>
        )}

        <div className="flex items-center justify-end mt-8">
          <Button
            id="create-portfolio-button"
            label={initialData === undefined ? 'Create' : 'Save'}
            onClick={onCreate}
            width="w-24"
            padding="p-1"
            height="h-12"
          />
        </div>
      </form>
    </Modal.Content>
  );
};

export default CreateEditPortfoliosModal;
