import { Report } from 'redux/reducers/suggestionReducer';
import { ITitleColumn } from 'redux/services/companyService';

export type TimePeriod = 'Short Term' | 'Long Term';
export type CurrentLevel = 'High' | 'Low' | 'Medium';
export type TrendDirection =
  | 'Up / Increasing'
  | 'Down / Decreasing'
  | 'Netural'
  | 'Increasing'
  | 'Decreasing';
export type ImpactCompany = 'Negative' | 'Positive' | 'Netrual';
export type SwotCategory = 'Strength' | 'Weakness' | 'Opportunities' | 'Threats';
export type PeerComparisonCell = number | null;
export interface KeyTrend {
  id: string;
  factor: string;
  time_period: TimePeriod;
  current_level: CurrentLevel;
  trend_direction: TrendDirection;
  impact_on_company: ImpactCompany;
  notes: string[];
  reports: Report[];
  tags: string[];
}

export interface SwotAnalysis {
  id: string;
  date: string;
  category: SwotCategory;
  factor: string[];
  current_rating: string;
  notes: string[];
  reports: Report[] | null;
  tags: string[];
}

interface StringIndexObj {
  [key: string]: PeerComparisonCell | string;
}

/**
 * @deprecated
 */
export interface PeerComparisonCompany extends StringIndexObj {
  ['company_name']: string;
  ['id']: string;
  ['total_enterprise_value']: PeerComparisonCell;
  ['market_capitalization']: PeerComparisonCell;
  ['ltm_diluted_eps_excl_extra_items']: PeerComparisonCell;
  ['ltm_revenue']: PeerComparisonCell;
  ['ltm_ebitda']: PeerComparisonCell;
  ['ltm_ebit']: PeerComparisonCell;
  ['ebitda_margin']: PeerComparisonCell;
  ['ebit_margin']: PeerComparisonCell;
  ['fy_cash_and_equivalents']: PeerComparisonCell;
  ['ltm_capital_expenditure']: PeerComparisonCell;
  ['fy_total_debt']: PeerComparisonCell;
  ['fy_net_debt']: PeerComparisonCell;
  ['ltm_interest_expense']: PeerComparisonCell;
  ['fy_accounts_receivable']: PeerComparisonCell;
  ['fy_accounts_payable']: PeerComparisonCell;
  ['fy_cost_of_goods_sold']: PeerComparisonCell;
  ['fy_inventory']: PeerComparisonCell;
  ['fy_raw_materials_inventory']: PeerComparisonCell;
  ['ltm_effective_tax_rate']: PeerComparisonCell;
  ['fy_cost_of_borrowing']: PeerComparisonCell;
  ['5_year_beta']: PeerComparisonCell;
}

export interface Insight {
  date: string;
  topic: string;
  notes: string[];
  reports: Report[];
  tags: string[];
}

// @deprecated
export interface PeerComparisonInfo {
  companies: PeerComparisonCompany[];
  insights: Insight[];
  tableTitles: ITitleColumn[];
}

export interface ValuesComparisonDB {
  tag: string;
  title: string;
  value: string;
  above: null;
  filling_id: string;
  group: null;
  highlighted: boolean;
  social_id: null;
  subgroup: null;
  unit: string;
}

export interface PeerComparisonDB {
  company_id: string;
  company_name: string;
  values: ValuesComparisonDB[];
}

export interface ColumnDefinition {
  unit: string;
  name: string;
  label: string;
}

export interface PeerComparisonsSummary {
  high: Record<string, Record<string, number | null>>;
  low: Record<string, Record<string, number | null>>;
  mean: Record<string, Record<string, number | null>>;
  median: Record<string, Record<string, number | null>>;
}

export interface PeerComparisonsColumn {
  name: string;
  key: string;
  label: string;
  unit: string;
}

export interface PeerComparisonsColumnGroup {
  name: string;
  label: string;
  columns: PeerComparisonsColumn[];
}

export interface PeerComparisons {
  rows: Array<Record<string, number | null>>;
  groups: PeerComparisonsColumnGroup[];
  summary: PeerComparisonsSummary;
  notes: string[];
}

export class PeerComparisons {
  public static empty(): PeerComparisons {
    return {
      rows: [],
      groups: [],
      notes: [],
      summary: {
        high: {},
        low: {},
        mean: {},
        median: {},
      },
    };
  }
}
