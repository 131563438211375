import React, { useCallback, useEffect, useState } from 'react';
import { ComboBoxBase } from 'views/Landing/Onboarding/components/ComboBoxBase';
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';

interface Props {
  readonly id: string;
  readonly label: string;
  readonly name: string;
  readonly value: string | null;
  readonly placeholder: string;

  onChange(value: string): void;
  onEdit(value: string): Promise<readonly ComboBoxItem[]>;
}

export const AutocompleteInput: React.FC<Props> = ({
  id,
  name,
  label,
  value,
  placeholder,
  onChange,
  onEdit,
}: Props): React.ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState<readonly ComboBoxItem[]>([]);

  const showDropdown = useCallback((): void => setIsDropdownOpen(true), []);
  const hideDropdown = useCallback((): void => setIsDropdownOpen(false), []);

  useEffect((): void => {
    if (value === null) {
      return;
    }

    onEdit(value).then((items: readonly ComboBoxItem[]): void => {
      setAutocompleteOptions(items);
    });
  }, [value, onEdit]);

  const onChangeWrapper = useCallback(
    (value: string): void => {
      onChange(value);
    },
    [onChange],
  );

  const onValuePicked = useCallback(
    (item: ComboBoxItem): void => {
      setIsDropdownOpen(false);
      onChange(item.name);
    },
    [onChange],
  );

  return (
    <ComboBoxBase
      id={id}
      value={value ?? ''}
      name={name}
      label={label}
      options={autocompleteOptions}
      placeholder={placeholder}
      isDropdownOpen={isDropdownOpen}
      onDropdownClose={hideDropdown}
      onFocus={showDropdown}
      onChange={onChangeWrapper}
      onValuePicked={onValuePicked}
    />
  );
};
