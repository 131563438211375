import { CommonFilter } from 'components/filters/helpers';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const filters: readonly CommonFilter[] = [
  {
    name: 'name',
    type: 'backend',
    label: 'Name',
    url: `${API_V1_PATH}/filters/country/name`,
  },
  {
    name: 'population',
    type: 'backend',
    label: 'Population Size',
    url: `${API_V1_PATH}/filters/country/population`,
  },
  {
    name: 'gdp_growth',
    type: 'backend',
    label: 'GDP Growth',
    url: `${API_V1_PATH}/filters/country/gdp-growth`,
  },
  {
    name: 'credit_rating',
    type: 'backend',
    label: 'Credit Rating',
    url: `${API_V1_PATH}/filters/country/credit-rating`,
  },
];

export default filters;
