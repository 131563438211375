import { AmountInput } from 'components/amountInput';
import { NumericInput } from 'components/numericInput';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFormValue, setSearchResult } from 'redux/reducers/addPortfolioItemFormReducer';
import { PortfolioETFItem } from 'types/portfolioItem';
import { PortfolioItemSearchResult } from 'views/Portfolios/PortfoliosModal/api';
import { AssetSearchInput } from 'views/Portfolios/PortfoliosModal/assetSearchInput';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioETFItem;
}

export const ETFItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const updaterFor = useUpdaterFor<PortfolioETFItem>();

  const handleSearchResult = useCallback(
    (result: PortfolioItemSearchResult): void => {
      dispatch(setSearchResult(result));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      setFormValue({
        key: 'totalAmount',
        value: item.numberOfShares * item.averagePricePerShareAmount,
      }),
    );
  }, [dispatch, item.averagePricePerShareAmount, item.numberOfShares]);

  return (
    <div className="flex flex-col gap-2">
      <AssetSearchInput assetType={item.type} onSelected={handleSearchResult} />
      <AmountInput
        name="averagePricePerShare"
        value={item.averagePricePerShareAmount}
        currency={item.averagePricePerShareCurrency}
        label="Average price per share purchased"
        onCurrencyChange={updaterFor('averagePricePerShareCurrency')}
        onValueChange={updaterFor('averagePricePerShareAmount')}
      />
      <div className="grid grid-cols-2 gap-x-3 gap-y-2">
        <NumericInput
          name="numberOfShares"
          value={item.numberOfShares}
          label="Number of Shares Purchased"
          onValueChange={updaterFor('numberOfShares')}
        />
        <NumericInput
          name="totalAmount"
          value={item.totalAmount}
          label="Total Amount"
          onValueChange={updaterFor('totalAmount')}
        />
      </div>
    </div>
  );
};
