import {
  BodyElement,
  bodyElementFromNode,
  NodeType,
  PostBody,
  PostBodyInputAPI,
} from 'components/postBodyInput/types';
import React from 'react';

export const usePostBodyAPIRef = (
  apiRef: React.Ref<PostBodyInputAPI>,
  elementRef: React.RefObject<HTMLDivElement>,
): void => {
  const element = elementRef.current;

  if (!isRefObject(apiRef)) {
    console.warn(apiRef);
    throw new Error('must be a react ref');
  }

  const apiObject = apiRef.current;

  const getContentLength = (): number => {
    if (element === null) {
      // Unlikely but let's say it is technically possible to have a null
      // here
      return 0;
    }

    const children = Array.from(element.childNodes);
    // Compute the total length
    return children.reduce((total: number, node: Node): number => {
      const { textContent } = node;
      if (!textContent) {
        return total;
      }

      return total + textContent.length;
    }, 0);
  };

  const getContent = (): PostBody => {
    const elements: BodyElement[] = [];

    if (element === null) {
      // Unlikely but let's say it is technically possible to have a null
      // here
      return { elements };
    }

    for (const child of element.childNodes) {
      elements.push(bodyElementFromNode(child));
    }

    return { elements };
  };

  const getTags = (): readonly string[] => {
    const tags: string[] = [];

    if (element === null) {
      // Unlikely but let's say it is technically possible to have a null
      // here
      return [];
    }

    for (const child of element.childNodes) {
      if (child.nodeType !== Node.ELEMENT_NODE) {
        continue;
      }

      const element = child as HTMLElement;
      if (element.tagName !== 'SPAN') {
        continue;
      }

      const type = element.getAttribute('data-type');
      if (type !== NodeType.tag) {
        continue;
      }

      const tag = element.getAttribute('data-value');
      if (tag === null) {
        continue;
      }

      tags.push(tag);
    }

    return tags;
  };

  if (apiObject !== null) {
    Object.defineProperty(apiObject, 'getContent', {
      value: getContent,
      enumerable: false,
      configurable: true,
      writable: false,
    });

    Object.defineProperty(apiObject, 'getContentLength', {
      value: getContentLength,
      enumerable: false,
      configurable: true,
      writable: false,
    });

    Object.defineProperty(apiObject, 'getTags', {
      value: getTags,
      enumerable: false,
      configurable: true,
      writable: false,
    });
  }
};

const isRefObject = <T>(ref: React.Ref<T> | null | undefined): ref is React.RefObject<T> => {
  if (ref === null || ref === undefined) {
    return false;
  }

  return 'current' in ref;
};
