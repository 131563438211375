import { Modal } from 'components/modal';
import { SuccessBox } from 'components/successBox';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BillingInterval } from 'redux/reducers/subscriptionPlansReducer';
import { ActiveSubscription } from 'views/Profile/Payments/activeSubscription';
import SubscriptionsTable from 'views/Profile/Payments/subscriptionsTable';

const ProfilePayments: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const freeTrialChangedModal = useModal();
  const { state: payment } = location;

  const handleCloseFreeTrialModal = useCallback((): void => {
    navigate('', { state: undefined });
    freeTrialChangedModal.close();
  }, [freeTrialChangedModal, navigate]);

  useEffect((): void => {
    if (payment?.type === 'free-trial') {
      freeTrialChangedModal.open();
    }
  }, [payment?.type, freeTrialChangedModal]);

  return (
    <div className="flex flex-col flex-grow mt-2">
      <ActiveSubscription />
      <SubscriptionsTable />

      <Modal isOpen={freeTrialChangedModal.isOpen} onClose={handleCloseFreeTrialModal}>
        <SuccessBox
          title="Your free trial was updated"
          message="Your free trial plan was successfully updated"
          onClose={handleCloseFreeTrialModal}
        />
      </Modal>
    </div>
  );
};

export default ProfilePayments;

export const billingCycleLabel: { [key in BillingInterval]: string } = {
  [BillingInterval.monthly]: 'Monthly',
  [BillingInterval.yearly]: 'Yearly',
};
