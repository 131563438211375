import React from 'react';
import {
  ApplicationFeature,
  DefaultFeature,
  isUsageLimitFeature,
  ModuleAccessFeature,
  UsageAction,
  UsageLimitFeature,
  ViewAccess,
} from 'redux/reducers/subscriptionPlansReducer';

export enum ApplicationModule {
  timeline = 'timeline',
  watchlist = 'watchlist',
  portfolios = 'portfolios',
  companies = 'companies',
  industries = 'industries',
  allIndicators = 'all-indicators',
  valueChains = 'value-chains',
  quarterlyReports = 'quarterly-reports',
  earnAndBuy = 'earn-n-buy',
  specialOffers = 'special-offers',
  marketplace = 'marketplace',
}

export type PerModuleAuthorization = {
  [key: string]: { [key in UsageAction | ViewAccess]: ApplicationFeature };
};

export class Authorization {
  private readonly authorization: PerModuleAuthorization = {};

  constructor(authorization: PerModuleAuthorization = {}) {
    this.authorization = authorization;
  }

  public resolve = (module: string, action: UsageAction | ViewAccess): ApplicationFeature => {
    return this.authorization[module]?.[action] ?? DefaultFeature(action);
  };
}

export const AuthorizationContext = React.createContext<Authorization>(new Authorization());
export const AuthorizationProvider = AuthorizationContext.Provider;

export function usePermission(module: ApplicationModule, action: UsageAction): UsageLimitFeature;

export function usePermission(module: ApplicationModule, action: ViewAccess): ModuleAccessFeature;

export function usePermission(
  module: ApplicationModule,
  action: UsageAction | ViewAccess,
): ApplicationFeature {
  const authorization = React.useContext(AuthorizationContext);
  if (module === undefined) {
    return DefaultFeature(action);
  }

  return authorization.resolve(module, action);
}

export const exceedsUsageLimit = (
  permission: UsageLimitFeature,
  currentUsage: number,
  expectedDiff = 1,
): boolean => {
  if (!isUsageLimitFeature(permission)) {
    console.warn('expected usage limit feature, but got something else');
    // If something is wrong, fallback to denial because it is the safest option
    return true;
  }

  // If we have unlimited usage, we can't exceed the limit
  if (!isFinite(permission.usage_limit)) {
    return false;
  }

  return permission.usage_limit < currentUsage + expectedDiff;
};
