import { CountryFlag } from 'components/countryFlag';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  CompanySubsidiaryLocation,
  geographiesAndMapsSelector,
} from 'redux/reducers/geographiesAndMapsReducer';
import { Organization } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { OrganizationDetails } from 'types/organization/types';

interface Props {
  readonly company: Organization | OrganizationDetails;
}

export const SubsidiariesTable: React.FC<Props> = ({ company }: Props): React.ReactElement => {
  const { subsidiaries: raw } = useSelector(geographiesAndMapsSelector);

  const subsidiaries = useMemo((): readonly CompanySubsidiaryLocation[] => {
    return raw
      .filter((subsidiary: CompanySubsidiaryLocation): boolean => subsidiary.type !== 'Headquarter')
      .sort((s1: CompanySubsidiaryLocation, s2: CompanySubsidiaryLocation): number => {
        const { country: n1 } = s1;
        const { country: n2 } = s2;

        return n1.localeCompare(n2);
      });
  }, [raw]);

  const headquarter = React.useMemo((): CompanySubsidiaryLocation | null => {
    return (
      raw.find(
        (subsidiary: CompanySubsidiaryLocation): boolean => subsidiary.type === 'Headquarter',
      ) ?? null
    );
  }, [raw]);

  if (subsidiaries.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="flex items-center justify-between mt-2">
        <div className="flex gap-2 items-center cursor-default">
          <div className="relative w-4 h-4 rounded-full bg-marker-outer hover:transform hover:scale-125 transition-all z-1">
            <div className="absolute left-1 top-1 w-2 h-2 rounded-full bg-marker-inner" />
          </div>
          <p className="block text-blue">{company?.name}&nbsp;</p>
        </div>
        <div className="flex items-center">
          {company && headquarter ? (
            <>
              <SVGIcon name="hq-icon" className="w-5 h-5 mr-1" />
              <p>
                <span>Headquarter</span>
                <span> - </span>
                <span>{headquarter.country}</span>
              </p>
            </>
          ) : (
            <p />
          )}
        </div>
      </div>
      <div className="grid grid-cols-3 grid-flow-row w-2/3 mt-2">
        <h4 className="my-3">Country/Region</h4>
        <h4 className="my-3">Relationship</h4>
        <h4 className="my-3">Subsidiary Names</h4>
        {subsidiaries.map((subsidiary: CompanySubsidiaryLocation): React.ReactElement => {
          const { subsidiaries } = subsidiary;
          return (
            <React.Fragment key={subsidiary.id}>
              <div className="flex items-start gap-3 py-3 border-t border-gray-light">
                <div className="border border-gray-dark">
                  <CountryFlag iso2={subsidiary.country_id} size="small" />
                </div>
                <p className="text-gray">{subsidiary.country}</p>
              </div>
              <div className="flex flex-col gap-2 py-3 border-t border-gray-light">
                {subsidiaries.map(
                  (name: string): React.ReactElement => (
                    <p key={name} className="text-gray">
                      Subsidiary
                    </p>
                  ),
                )}
              </div>
              <div className="flex flex-col gap-2 py-3 border-t border-gray-light">
                {subsidiaries.map(
                  (name: string): React.ReactElement => (
                    <p key={name} className="text-gray">
                      {name}
                    </p>
                  ),
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
