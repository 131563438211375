import axios, { AxiosResponse } from 'axios';
import { Modal } from 'components/modal';
import React, { useEffect, useMemo } from 'react';
import { AlgoNote } from 'redux/reducers/algoNotesReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

interface Props {
  readonly noteId?: string;

  onFetchStarted(): void;
  onFetchCompleted(): void;
}

export const DetailsModalContent: React.FC<Props> = ({
  noteId,
  onFetchStarted,
  onFetchCompleted,
}: Props): React.ReactElement | null => {
  const [note, setNote] = React.useState<AlgoNote | null>(null);

  if (!noteId) {
    throw new Error('noteId is undefined');
  }

  useEffect((): void | VoidFunction => {
    if (!noteId) {
      return;
    }
    onFetchStarted();

    const tokenSource = axios.CancelToken.source();
    api
      .get(`${API_V1_PATH}/algo-notes/${noteId}`, {
        method: 'GET',
        cancelToken: tokenSource.token,
      })
      .then((response: AxiosResponse): void => {
        setNote(response.data);
      })
      .catch(console.warn)
      .finally(onFetchCompleted);

    return (): void => {
      tokenSource.cancel();
    };
  }, [noteId, onFetchCompleted, onFetchStarted]);

  const formattedDate = useMemo((): string => {
    if (!note) {
      return '';
    }

    return dateFormatter.format(new Date(note.date));
  }, [note]);

  return (
    <Modal.Content title={note?.name ?? 'Note Details'}>
      <div className="relative w-modal-sm mt-4 min-h-24">
        <p>{note?.content}</p>
        <div className="mt-3 pt-3 border-t border-gray-medium">
          <div className="flex items-center gap-2">
            <p>Category:</p>
            <p className="font-poppinsSemiBold">{note?.category}</p>
          </div>
          <div className="w-full flex items-center gap-2 mt-3">
            <p className="text-sm text-gray ml-auto">Last updated {formattedDate}</p>
          </div>
        </div>
      </div>
    </Modal.Content>
  );
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});
