import { ReactComponent as DownArrow } from 'assets/icons/svg/down-arrow.svg';
import {
  Select,
  SelectButtonProps,
  SelectItem,
  SelectItemProps,
} from 'components/DEPRECATED/select';
import React, { useMemo } from 'react';
import { TimezonesList } from 'utils/timezones';

interface Props {
  readonly value: string;

  onChange(value: string): void;
}

export const TimezonePicker: React.FC<Props> = ({ onChange, value }: Props): React.ReactElement => {
  const zones = useMemo(
    (): string[] =>
      Array.from(
        new Set(
          TimezonesList.reduce((list: string[], item: any): string[] => [...list, ...item.utc], []),
        ),
      ).sort((a: string, b: string): number => a.localeCompare(b)),
    [],
  );

  const items = useMemo(
    (): Array<SelectItem<string>> =>
      zones.map((zone: string): SelectItem<string> => ({ label: zone, value: zone })),
    [zones],
  );

  return (
    <Select
      items={items}
      value={value}
      buttonComponent={Button}
      itemComponent={Item}
      onChange={onChange}
    />
  );
};

const Button: React.FC<SelectButtonProps<string>> = React.forwardRef(function TimeSelectButton(
  { value, onClick }: SelectButtonProps<string>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  return (
    <div ref={ref} className={buttonContainerClass} onClick={onClick}>
      <span className="min-w-0 truncate overflow-ellipsis">
        <>{value}</>
      </span>
      <DownArrow className="w-3" />
    </div>
  );
});

const Item: React.FC<SelectItemProps<string>> = ({
  label,
  onClick,
}: SelectItemProps<string>): React.ReactElement => {
  return (
    <div
      className="text-xs text-gray p-2 cursor-pointer hover:bg-gray-light truncate overflow-ellipsis"
      onClick={onClick}
    >
      {label}
    </div>
  );
};

const buttonContainerClass =
  'flex items-center justify-between text-xs font-poppinsSemiBold px-2 py-1 rounded hover:bg-gray-medium cursor-pointer uppercase';
