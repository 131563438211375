import React from 'react';
import { preRegisterMode } from 'utils';
import AboutUs from 'views/Landing/LandingPage/components/AboutUs';
import Features from 'views/Landing/LandingPage/components/Features';
import FinvarEnhances from 'views/Landing/LandingPage/components/FinvarEnhances';
import NextGeneration from 'views/Landing/LandingPage/components/NextGeneration';
import SignUpBasic from 'views/Landing/LandingPage/components/SignUpBasic';
import Slider from 'views/Landing/LandingPage/components/Slider';

export const Main: React.FC = (): React.ReactElement => {
  return (
    <main>
      <div className="flex flex-col xl:flex-row mb-16 w-11/12 md:w-86/100 mx-auto pt-10">
        <div className="xl:w-6/12 w-full flex flex-col flex-between md:justify-center lg:justify-start">
          <Slider />
        </div>
        <div className="lg:pt-20 md:pt-28 xl:w-6/12 lg:ml-20">
          <NextGeneration />
        </div>
      </div>

      <div style={negativeMarginStyle} />
      <div className="mt-3 md:mt-20 pt-4" id="features">
        <div style={headerHeightStyle} />
        <Features />
      </div>

      <div style={negativeMarginStyle} />
      <div id="about-us" className="mt-3 md:mt-20 pt-8">
        <div style={headerHeightStyle} />
        <AboutUs />
      </div>
      <div className="flex flex-col xl:flex-row mt-10 md:mt-36">
        {!preRegisterMode && (
          <>
            <div
              className="xl:w-6/12 px-4 py-8 3xl:px-44 md:px-24 3xl:py-52 md:py-32 bg-footer"
              style={enhancementsStyle}
            >
              <FinvarEnhances />
            </div>
            <div className="bg-gray-darkest px-4 py-10 3xl:px-44 md:px-24 3xl:py-52 md:py-32 xl:w-6/12">
              <SignUpBasic />
            </div>
          </>
        )}
      </div>
    </main>
  );
};

const enhancementsStyle = { backgroundColor: '#F0F1F3' };
const headerHeightStyle = { height: 114 };
const negativeMarginStyle = { marginBottom: -headerHeightStyle.height };
