import 'components/footer/Footer.scss';

import CapNoteLogo from 'assets/icons/svg/new-capnote-footer.svg';
import FooterText from 'components/footer/FooterText';
import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { preRegisterMode } from 'utils';

const socialIcons: ReadonlyArray<{ readonly icon: SvgIconName; readonly path: string }> = [
  { icon: 'white-linkedin-icon', path: 'https://www.linkedin.com/company/capnotebyfinvar' },
  { icon: 'white-twitter-icon', path: 'https://twitter.com/Capnote_finance' },
  { icon: 'youtube-icon', path: 'https://www.youtube.com/channel/UCssEwipimucnWDPVsEtMBXg' },
  { icon: 'instagram-icon', path: 'https://www.instagram.com/capnote/' },
];

const Footer: React.FC = () => {
  const showFooter = ['/', '/terms-and-conditions'];

  const location = useLocation().pathname;

  if (!showFooter.includes(location)) {
    return null;
  } else {
    return (
      <div className="bg-gray-darkest">
        <div className="pt-16 flex justify-center mb-12">
          <img src={CapNoteLogo} className="h-12" />
        </div>
        <div
          className={`flex ${
            preRegisterMode ? 'w-10/12 md:w-1/4 xl:w-1/5 4xl:w-footer' : 'lg:w-3/5 my-12'
          } w-11/12  text-gray-light mx-auto
        font-poppinsSemiBold text-base items-center`}
        >
          <div className={`${preRegisterMode ? 'w-2/5' : 'w-2/6'} border-r`}>
            <Link to="/contact-us">
              <p className="text-center cursor-pointer capitalize leading-none">
                {FooterText.buttons[0]}
              </p>
            </Link>
          </div>
          <div className={`${preRegisterMode ? 'w-3/5' : 'w-2/6 border-r'} `}>
            <Link to="/terms-and-conditions">
              <p className="text-center cursor-pointer capitalize leading-none">
                {FooterText.buttons[1]}
              </p>
            </Link>
          </div>

          <div className="w-2/6">
            {' '}
            <Link to="/privacy-policy">
              <p className="text-center">{FooterText.buttons[2]}</p>
            </Link>
          </div>
        </div>
        <div className="flex justify-center my-12">
          <div className="md:w-3/12 flex justify-center space-x-6 text-white">
            {socialIcons.map(({ icon, path }) => (
              <a rel="noopener noreferrer" target="_blank" key={icon} href={path}>
                <SVGIcon name={icon} className="h-8 w-8 fill-current" />
              </a>
            ))}
          </div>
        </div>
        <p className="text-gray-light text-center pb-12">{FooterText.rights}</p>
      </div>
    );
  }
};

export default Footer;
