import { PostBodyInput } from 'components/postBodyInput';
import { PostBody, PostBodyInputAPI } from 'components/postBodyInput/types';
import { UserAvatar } from 'components/userAvatar';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';

interface Props {
  readonly placeholder: string;
  readonly submitLabel: string;
  readonly maxLength?: number;
  readonly initialValue: PostBody;

  onSubmit(body: PostBody): void;
}

export const CurrentUserInput: React.FC<Props> = ({
  placeholder,
  submitLabel,
  maxLength,
  initialValue,
  onSubmit,
}: Props): React.ReactElement => {
  const [contentLength, setContentLength] = useState<number>(0);
  const [focused, setFocused] = useState<boolean>(false);

  const inputApiRef = useRef<PostBodyInputAPI>(new PostBodyInputAPI());
  const user = useSelector(userSelector);
  const inputApi = inputApiRef.current;

  const handleChange = useCallback((): void => {
    setContentLength(inputApi.getContentLength());
  }, [inputApi]);

  const submit = useCallback(
    (body: PostBody): void => {
      onSubmit(body);
    },
    [onSubmit],
  );

  const handleSubmit = useCallback((): void => {
    submit(inputApi.getContent());
  }, [inputApi, submit]);

  const inputClass = useMemo((): string => {
    if (focused) {
      return [...inputClassBase, 'border-blue'].join(' ');
    }

    return inputClassBase.join(' ');
  }, [focused]);

  const handleFocus = useCallback((): void => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback((): void => {
    setFocused(false);
  }, []);

  return (
    <div className="font-poppins">
      <div className="flex gap-2 items-center relative">
        <UserAvatar user={user} fillColor="gray-light" />

        <div className="relative flex-1">
          <PostBodyInput
            ref={inputApiRef}
            initialValue={initialValue}
            className={inputClass}
            placeholder={placeholder}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {maxLength !== undefined && (
            <div className="absolute text-xxs text-gray-medium right-3 bottom-0 px-2 py-1">
              {/* FIXME: use the api to get the length */}
              {maxLength - contentLength}
            </div>
          )}
        </div>
        <button disabled={contentLength === 0} className={postButtonClass} onClick={handleSubmit}>
          {submitLabel}
        </button>
      </div>
    </div>
  );
};

const postButtonClass =
  'px-4 py-2 font-poppinsSemiBold text-white bg-blue disabled:text-gray-medium disabled:bg-gray-light rounded-5 uppercase text-sm';
const inputClassBase = [
  'px-4',
  'py-0',
  'w-full',
  'h-full',
  'outline-none',
  'border',
  'border-gray-medium',
  'rounded-line-input',
  'text-sm',
  'hover:border-blue',
  'bg-white',
  'leading-8',
  'h-8',
];
