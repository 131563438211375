import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult, SetupIntentResult, StripeError } from '@stripe/stripe-js';
import { ConditionalRender } from 'components/conditionalRenderer';
import { Button } from 'components/genericButton';
import { TermsAndConditionsForRecurringPayments } from 'components/paymentForm/termsAndConditionsForRecurringPayments';
import { useDebouncedErrorReset } from 'components/paymentForm/useDebouncedErrorReset';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { createValidationError, FormErrors, YupError } from 'utils/forms';
import { useAmountFormatter } from 'views/Checkout/SubscriptionCheckout/hooks/useAmountFormatter';
import {
  isPaymentIntentPayment,
  isSetupIntentPayment,
  Payment,
} from 'views/Checkout/SubscriptionCheckout/payment';
import { PaymentFormState } from 'views/Landing/SignUp/types';

interface Props {
  readonly payment: Payment;
  readonly withTnc?: boolean;

  onPaymentStarted(): void;
  onPaymentCanceled(): void;
  onPaymentSucceeded(): void;
  onPaymentFailed(error: StripeError): void;
  onPaymentSoftError(): void;
}

export const NewPaymentMethod: React.FC<Props> = ({
  payment,
  withTnc = true,
  onPaymentCanceled,
  onPaymentSucceeded,
  onPaymentFailed,
  onPaymentStarted,
  onPaymentSoftError,
}: Props): React.ReactElement => {
  const elements = useElements();
  const stripe = useStripe();
  const amountString = useAmountFormatter(payment);
  const [tncChecked, setTncChecked] = useState(false);
  const [errors, setErrors] = useState<FormErrors<PaymentFormState>>({});
  const [ready, setReady] = useState<boolean>(false);

  const submitButtonLabel = useMemo((): string => {
    if (isPaymentIntentPayment(payment)) {
      return `Pay ${amountString}`;
    } else if (isSetupIntentPayment(payment)) {
      return 'Save';
    } else {
      return '';
    }
  }, [amountString, payment]);

  const handlePaymentIntentPayment = useCallback(async (): Promise<void> => {
    if (!stripe || !elements) {
      throw new Error('stripe not properly initialized');
    }

    const result: PaymentIntentResult = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        return_url: location.href,
      },
      redirect: 'if_required',
    });

    const { error, paymentIntent } = result;

    if (error) {
      if (error.type === 'validation_error') {
        onPaymentSoftError();
        return;
      }

      onPaymentFailed(error);
    } else if (paymentIntent) {
      switch (paymentIntent.status) {
        case 'succeeded':
          onPaymentSucceeded();
          break;
        case 'requires_action':
        case 'processing':
        case 'canceled':
        case 'requires_capture':
        case 'requires_confirmation':
        case 'requires_payment_method':
          break;
      }
    }
  }, [elements, onPaymentFailed, onPaymentSoftError, onPaymentSucceeded, stripe]);

  useDebouncedErrorReset(errors, setErrors);

  const handleTermsAndConditionsAcceptedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      setTncChecked(target.checked);
    },
    [],
  );

  const handleReady = useCallback((): void => {
    setReady(true);
  }, []);

  const handeSetupIntentPayment = useCallback(async (): Promise<void> => {
    if (!stripe || !elements) {
      throw new Error('stripe not properly initialized');
    }

    const result: SetupIntentResult = await stripe.confirmSetup({
      elements: elements,
      confirmParams: {
        return_url: location.href,
      },
      redirect: 'if_required',
    });

    const { error, setupIntent } = result;

    if (error) {
      if (error.type === 'validation_error') {
        onPaymentSoftError();
        return;
      }

      onPaymentFailed(error);
    } else if (setupIntent) {
      switch (setupIntent.status) {
        case 'succeeded':
          onPaymentSucceeded();
          break;
        case 'requires_action':
        case 'processing':
        case 'canceled':
        case 'requires_confirmation':
        case 'requires_payment_method':
          break;
      }
    }
  }, [elements, onPaymentFailed, onPaymentSoftError, onPaymentSucceeded, stripe]);

  const handleSubmit = useCallback(
    async (event: FormEvent): Promise<void> => {
      event.preventDefault();
      if (withTnc && !tncChecked) {
        setErrors({
          termsAndConditionsAccepted: createValidationError(
            'Please confirm you agree with Terms & Conditions to continue.',
            'termsAndConditionsAccepted',
          ) as YupError,
        });
        return;
      }

      onPaymentStarted();
      if (isPaymentIntentPayment(payment)) {
        await handlePaymentIntentPayment();
      } else if (isSetupIntentPayment(payment)) {
        await handeSetupIntentPayment();
      }
    },
    [
      handeSetupIntentPayment,
      handlePaymentIntentPayment,
      onPaymentStarted,
      payment,
      tncChecked,
      withTnc,
    ],
  );

  if (!isPaymentIntentPayment(payment) && !isSetupIntentPayment(payment)) {
    return <></>;
  }

  return (
    <form className="flex flex-col justify-between flex-1 mt-12" onSubmit={handleSubmit}>
      <PaymentElement onReady={handleReady} />
      <ConditionalRender renderIf={withTnc}>
        <TermsAndConditionsForRecurringPayments
          checked={tncChecked}
          amountString={amountString}
          error={errors.termsAndConditionsAccepted?.message}
          onChange={handleTermsAndConditionsAcceptedChange}
        />
      </ConditionalRender>
      <ConditionalRender renderIf={ready}>
        <div className="flex items-center justify-end gap-2 pt-8">
          <Button type="reset" variant="secondary" label="Cancel" onClick={onPaymentCanceled} />
          <Button type="submit" variant="primary" label={submitButtonLabel} />
        </div>
      </ConditionalRender>
    </form>
  );
};
