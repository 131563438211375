import React from 'react';
import { SubscriptionPlanItemHeaderContainer } from 'views/SubscriptionPlans/subscriptionPlanItemHeaderContainer';

export const SubscriptionPlanItemHeaderPlaceholder: React.FC = (): React.ReactElement => {
  return (
    <SubscriptionPlanItemHeaderContainer
      title=""
      priceString=""
      billingInterval=""
      specialOffer=""
      specialOfferColor="gray"
    />
  );
};
