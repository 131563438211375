import { AxiosResponse } from 'axios';
import Button from 'components/buttons/Button/Button';
import { CheckboxGroup } from 'components/checkboxGroup';
import { ConditionalRender } from 'components/conditionalRenderer';
import { TagInput } from 'components/tagInput';
import Toggle from 'components/toggle/Toggle';
import { KeyValue, useFormValueSetter } from 'hooks/useFormValueSetter';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  aboutFormSelector,
  AboutFormState,
  CareerLevels,
  ConnectionToFinance,
  experienceFormSelector,
  ExperienceFormState,
  onboardingSelector,
  resetState,
  updateAboutFormValue,
  updateAdditionalTopicsOfInterest,
  updateCity,
  updateCountry,
  updateExperienceFormValue,
  updateRegionsOfInterest,
  updateState,
  updateTopicsOfInterest,
} from 'redux/reducers/onboardingReducer';
import { OnboardingPayload, submitOnboardingForm } from 'redux/services/onboardingServices';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { AutocompleteInput } from 'views/Landing/Onboarding/components/AutocompleteInput';
import { ACCREDITED_INVESTOR_URL, ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';
import { Select, SelectOption } from 'views/Landing/Onboarding/components/Select';

export const connectionToFinanceOptions = Object.values(ConnectionToFinance).map(
  (value: ConnectionToFinance): SelectOption<ConnectionToFinance> => ({
    value: value,
    label: value,
  }),
);

export const carrelLevelOptions = Object.values(CareerLevels).map(
  (value: CareerLevels): SelectOption<CareerLevels> => ({
    value: value,
    label: value,
  }),
);

const AboutForm: React.FC = () => {
  const aboutFormState = useSelector(aboutFormSelector);
  const experienceFormState = useSelector(experienceFormSelector);
  const onboarding = useSelector(onboardingSelector);
  const dispatch = useDispatch<any>();
  const { countries, topicsOfInterest, regionsOfInterest } = onboarding.referenceData;
  const [states, setStates] = useState<ReadonlyArray<SelectOption<number>>>([]);
  const [missingCountryError, setMissingCountryError] = useState<boolean>(false);

  const updateAboutForm = useFormValueSetter(
    (keyValue: KeyValue<AboutFormState>): void => {
      dispatch(updateAboutFormValue(keyValue));
    },
    { nullifyEmptyString: true },
    [dispatch],
  );

  const updateExperienceForm = useFormValueSetter(
    (keyValue: KeyValue<ExperienceFormState>): void => {
      dispatch(updateExperienceFormValue(keyValue));
    },
    { nullifyEmptyString: true },
    [dispatch],
  );

  const onAccreditedInvestorChange = useCallback(
    (checked: boolean): void => {
      updateAboutForm('accreditedInvestor', checked);
    },
    [updateAboutForm],
  );

  const onConnectionToFinanceChange = useCallback(
    (value: ConnectionToFinance): void => {
      updateAboutForm('connectionToFinance', value);
    },
    [updateAboutForm],
  );

  const onCareerLevelChange = useCallback(
    (value: ConnectionToFinance): void => {
      updateExperienceForm('careerLevel', value);
    },
    [updateExperienceForm],
  );

  const onCountryChange = useCallback(
    (value: number | null): void => {
      dispatch(updateCountry(value));
    },
    [dispatch],
  );

  const onStateChange = useCallback(
    (value: number | null): void => {
      dispatch(updateState(value));
    },
    [dispatch],
  );

  const onCityChange = useCallback(
    (value: string): void => {
      dispatch(updateCity(value));
    },
    [dispatch],
  );

  const onAdditionalTopicsOfInterestChange = useCallback(
    (value: string[]): void => {
      dispatch(updateAdditionalTopicsOfInterest([...value]));
    },
    [dispatch],
  );

  const onTopicsOfInterestChange = useCallback(
    (values: number[]): void => {
      dispatch(updateTopicsOfInterest(values));
    },
    [dispatch],
  );

  const onRegionsOfInterestChange = useCallback(
    (values: number[]): void => {
      dispatch(updateRegionsOfInterest(values));
    },
    [dispatch],
  );

  useEffect((): void => {
    setStates([]);

    if (aboutFormState.country === null) {
      return;
    }
    setMissingCountryError(false);

    api
      .get(`${API_V1_PATH}/reference-data/countries/${aboutFormState.country}/admin-zones`)
      .then((response: AxiosResponse): void => {
        if (response.status !== 200) {
          return;
        }

        const { data } = response;

        setStates(
          data.map(
            (item: { readonly id: number; readonly name: string }): SelectOption<number> => ({
              label: item.name,
              value: item.id,
            }),
          ),
        );
      })
      .catch(console.warn);
  }, [aboutFormState.country]);

  useEffect((): VoidFunction => {
    return (): void => {
      setStates([]);
      dispatch(resetState());
    };
  }, [dispatch]);

  const onCityAutocomplete = useCallback(
    async (value: string): Promise<readonly ComboBoxItem[]> => {
      if (aboutFormState.country === null) {
        return [];
      }

      const countryId = aboutFormState.country;
      const stateId = aboutFormState.state;

      const trimmedValue = value.trim();
      if (trimmedValue.length > 0) {
        const url = !stateId
          ? `${API_V1_PATH}/reference-data/countries/${countryId}/cities`
          : `/api/v1/reference-data/countries/${countryId}/${stateId}/cities`;
        const response: AxiosResponse = await api.get(url, {
          params: { keyword: trimmedValue },
        });

        if (response.status !== 200) {
          return [];
        }

        return response.data;
      } else {
        return [];
      }
    },
    [aboutFormState.country, aboutFormState.state],
  );

  const onSubmit = useCallback((): void => {
    const { aboutForm, experienceForm } = onboarding;
    const country = aboutForm.country;
    if (!country) {
      setMissingCountryError(true);
      return;
    }

    const { state } = aboutForm;
    const payload: OnboardingPayload = {
      about: {
        about_me: aboutForm.aboutMe,
        country: country,
        state: state,
        city: aboutForm.city,
        accredited_investor: aboutForm.accreditedInvestor,
        connection_to_finance: aboutForm.connectionToFinance ?? null,
      },
      job_information: {
        career_level: experienceForm.careerLevel,
        recent_job: null,
        recent_company: null,
        industry: null,
        job_position_start_date: null,
        job_position_end_date: null,
        certifications: [],
      },
      regions_of_interest: aboutForm.regionsOfInterest,
      topics_of_interest: aboutForm.topicsOfInterest,
      additional_topics_of_interest: aboutForm.additionalTopicsOfInterest,
    };
    dispatch(submitOnboardingForm(payload));
  }, [dispatch, onboarding]);

  return (
    <form className="flex flex-col gap-7 mb-10" autoComplete="off">
      <h2 className="text-gray-darkest mb-2">ACCOUNT SETUP - ABOUT ME</h2>
      <div>
        <Select
          id="connection-finance"
          value={aboutFormState.connectionToFinance ?? ''}
          label="Connection to Finance"
          options={connectionToFinanceOptions}
          onChange={onConnectionToFinanceChange}
        />
      </div>
      <div>
        <Select
          id="career-level"
          value={experienceFormState.careerLevel ?? ''}
          label="Career Level"
          options={carrelLevelOptions}
          onChange={onCareerLevelChange}
        />
      </div>
      <div className="text-left px-3">
        <Toggle
          id="accredited-investor"
          checked={aboutFormState.accreditedInvestor}
          label={
            aboutFormState.accreditedInvestor
              ? "Yes, I'm an Accredited Investor"
              : "No, I'm not an Accredited Investor"
          }
          flat={true}
          fullWidth={true}
          onChange={onAccreditedInvestorChange}
        />
        <p className="font-poppins text-xs text-gray mt-3">
          Click
          <span className="font-poppins text-xs text-blue">
            <a
              href={ACCREDITED_INVESTOR_URL}
              rel="noopener noreferrer"
              target="_blank"
              className="normal-case mx-1"
            >
              here
            </a>
          </span>
          for more details
        </p>
      </div>
      <div>
        <Select
          id="country"
          value={aboutFormState.country}
          label="Country"
          options={countries}
          required={true}
          onChange={onCountryChange}
        />
        {missingCountryError && (
          <div className="font-poppinsMedium text-xs text-left text-red-500 py-1">
            Please select country to proceed
          </div>
        )}
      </div>
      <ConditionalRender renderIf={states.length > 0}>
        <div>
          <Select
            id="state"
            value={aboutFormState.state}
            label="State"
            options={states}
            onChange={onStateChange}
          />
        </div>
      </ConditionalRender>
      <div>
        <AutocompleteInput
          id="city"
          name="city"
          value={aboutFormState.city}
          label="City"
          placeholder="City"
          onChange={onCityChange}
          onEdit={onCityAutocomplete}
        />
      </div>
      <div className="flex flex-col space-y-4">
        <h3 className="text-base text-left">Topics of Interest</h3>
        <div className="flex flex-wrap items-center">
          <CheckboxGroup
            options={topicsOfInterest}
            selectedOptions={aboutFormState.topicsOfInterest}
            onChange={onTopicsOfInterestChange}
          />
        </div>
        <TagInput
          id="other-interests"
          name="other-interests"
          items={aboutFormState.additionalTopicsOfInterest}
          placeholder="Other Interests"
          onChange={onAdditionalTopicsOfInterestChange}
        />
      </div>
      <div className="flex flex-col space-y-4">
        <h3 className="text-base text-left">Regions of Interest</h3>
        <div className="flex flex-wrap items-center">
          <CheckboxGroup
            options={regionsOfInterest}
            selectedOptions={aboutFormState.regionsOfInterest}
            onChange={onRegionsOfInterestChange}
          />
        </div>
      </div>
      <div className="w-full flex justify-end">
        <Button
          id="submit-button"
          label="Submit"
          width="w-36"
          cursor="cursor-pointer"
          onClick={onSubmit}
        />
      </div>
    </form>
  );
};

export default AboutForm;
