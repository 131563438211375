import classes from 'components/filters/components/spinner.module.scss';
import React from 'react';

export const Loader: React.FC = (): React.ReactElement => {
  return (
    <div className={classes.spinner}>
      <div className={classes.inner} />
    </div>
  );
};
