import { Alignment, ColumnDefinition, ColumnType, Level } from 'components/table/types';

export const getColumnAlignment = <T>(column: ColumnDefinition<T>): Alignment => {
  switch (column.type) {
    case ColumnType.level:
      return Alignment.center;
    case ColumnType.text:
    case ColumnType.date:
      return column.alignment ?? Alignment.center;
    case ColumnType.percent:
    case ColumnType.numeric:
      return column.alignment ?? Alignment.right;
    case ColumnType.custom:
      return column.headerAlignment ?? Alignment.center;
  }

  return Alignment.left;
};

export const levelRank = (level: Level): number => {
  switch (level) {
    case Level.veryLow:
      return -2;
    case Level.low:
      return -1;
    case Level.average:
      return 0;
    case Level.medium:
      return 1;
    case Level.high:
      return 2;
    case Level.veryHigh:
      return 3;
  }

  return 0;
};
