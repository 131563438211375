import { Alignment } from 'components/table/types';
import React, { PropsWithChildren } from 'react';

interface Props {
  readonly alignment?: Alignment;
  readonly truncated?: boolean;

  readonly type?: 'span' | 'div' | 'h4';
}

export const AlignedText: React.FC<PropsWithChildren<Props>> = ({
  alignment,
  truncated = false,
  type = 'div',
  children,
}: PropsWithChildren<Props>): React.ReactElement => {
  const Component = type;
  if (truncated) {
    switch (alignment) {
      case Alignment.center:
        return (
          <Component className="text-center truncate text-current leading-8">{children}</Component>
        );
      case Alignment.right:
        return (
          <Component className="text-right truncate text-current leading-8">{children}</Component>
        );
      case Alignment.left:
        return (
          <Component className="text-left truncate text-current leading-8">{children}</Component>
        );
      default:
        return (
          <Component className="text-left truncate text-current leading-8">{children}</Component>
        );
    }
  } else {
    switch (alignment) {
      case Alignment.center:
        return <Component className="text-center text-current leading-8">{children}</Component>;
      case Alignment.right:
        return <Component className="text-right text-current leading-8">{children}</Component>;
      case Alignment.left:
        return <Component className="text-left text-current leading-8">{children}</Component>;
      default:
        return <Component className="text-left text-current leading-8">{children}</Component>;
    }
  }
};
