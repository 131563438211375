import SVGIcon from 'components/icons/SVGIcon';
import { TruncatedTags } from 'components/truncatedTags';
import { capitalize } from 'lodash';
import React from 'react';
import { Stream } from 'types/timeline';

interface Props {
  readonly stream: Stream;
}

export const LeftPanelDetails: React.FC<Props> = ({ stream }: Props): React.ReactElement => {
  return (
    <>
      <div className="text-xs mt-2">
        <div className="inline-block align-middle">
          <SVGIcon name="members-icon" className="h-3" />
        </div>
        <div className="inline-block align-middle ml-1">{stream.members_count ?? 0}</div>
      </div>
      <div>By {stream.author}</div>
      <div className="mb-4">{capitalize(stream.category)} Timeline</div>
      <TruncatedTags tags={stream.tags} />
    </>
  );
};
