import React, { useMemo } from 'react';

interface Props {
  readonly value: number | null;
}

export const AmountRenderer: React.FC<Props> = ({ value }: Props): React.ReactElement => {
  const formattedText = useMemo((): string => {
    if (value === null || value === 0) {
      return '-';
    }

    return amountFormatter.format(value / 100);
  }, [value]);

  return <div className="text-center truncate text-current leading-8">{formattedText}</div>;
};

const amountFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
