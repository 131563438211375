import 'components/input/input.scss';

import Tooltip from 'components/DEPRECATED/tooltips/tooltip';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';

interface Props {
  name: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
  value: string;
  required?: boolean;
  placeholder?: string;
  error?: any;
  className?: string;
  innerClassName?: string;
  rows?: number;
  maxLength?: number;
  showRemainingChars?: boolean;
}

const TextAreaWithTags: React.FC<Props> = ({
  id,
  name,
  onChange,
  textAreaRef,
  value,
  required = true,
  placeholder = '',
  error = null,
  className = '',
  innerClassName = '',
  rows = 5,
  maxLength = 3000,
  showRemainingChars = false,
}) => {
  const remainingCharacters = useMemo(() => maxLength - value.length, [maxLength, value.length]);

  return (
    <div
      className={`flex ${
        value !== '' ? 'flex-column flex-wrap' : 'flex-row'
      } relative border-b-2 w-full items-center  ${
        error ? 'border-red' : 'border-gray-300'
      } ${className}`}
    >
      {value !== '' && (
        <span className="absolute -top-3 text-gray text-xxs font-poppins w-full">
          {placeholder}
        </span>
      )}
      <textarea
        id={id}
        ref={textAreaRef}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        required={required}
        className={`pb-4 pt-1 w-full font-poppins text-base focus:outline-none text-gray scroller ${innerClassName}`}
        rows={rows}
      />

      {showRemainingChars && (
        <span className="absolute bottom-0 right-2 text-gray text-xxs font-poppins">
          {remainingCharacters}
        </span>
      )}

      {error && (
        <div className="absolute bottom-2 right-0 w-full">
          <div className="relative w-full flex justify-end">
            <Tooltip title={error} errorClassName="top-8 right-0" error>
              <SVGIcon name="exclamation-circle" className="mr-6 w-6 h-5" />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextAreaWithTags;
