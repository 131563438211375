import { Select, SelectItem } from 'components/select';
import React from 'react';
import { Category, useCategoriesQuery } from 'views/EarnAndBuy/api';

interface Props {
  readonly value: number | null;
  onChange(value: number): void;
}

export const CategoriesDropdown: React.FC<Props> = ({
  value,
  onChange,
}: Props): React.ReactElement => {
  const { data, isSuccess } = useCategoriesQuery();

  const categories = React.useMemo(
    (): Array<SelectItem<number>> =>
      data?.map(
        (category: Category): SelectItem<number> => ({
          value: category.id,
          label: category.name,
        }),
      ) ?? [],
    [data],
  );

  React.useEffect((): void => {
    if (!isSuccess) {
      return;
    }

    if (value === null && data[0]) {
      onChange(data[0].id);
    }
  }, [data, isSuccess, onChange, value]);

  return <Select label="Category" items={categories} value={value} onChange={onChange} />;
};
