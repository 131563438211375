import { Modal } from 'components/modal';
import React from 'react';
import { CompanySubsidiaryLocation } from 'redux/reducers/geographiesAndMapsReducer';

interface Props {
  readonly marker: CompanySubsidiaryLocation;
  onClose(): void;
}

export const SubsidiaryMarkerDetails: React.FC<Props> = ({
  marker,
  onClose,
}: Props): React.ReactElement => {
  const { subsidiaries } = marker;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Modal.Content title={marker.country}>
        <div className="w-modal-sm mt-4 max-h-96 overflow-y-scroll scroller">
          {subsidiaries.map(
            (item: string): React.ReactElement => (
              <p key={item} className="my-1">
                {item}
              </p>
            ),
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};
