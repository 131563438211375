import { ReactComponent as DeleteIcon } from 'assets/icons/svg/delete-paperbin-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/svg/edit-icon.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/svg/share-icon.svg';
import { Disabler } from 'components/disabler';
import { Tooltip } from 'components/tooltip';
import React, { useMemo } from 'react';

interface Props {
  readonly title: string;
  readonly section: string;

  onShare?(event: React.MouseEvent): void;
  onEdit?(event: React.MouseEvent): void;
  onDelete?(event: React.MouseEvent): void;
}

interface Button {
  readonly id: string;
  readonly label: string;
  readonly icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  readonly action?: (event: React.MouseEvent) => void;
}

export const CommonActionButtons: React.FC<Props> = ({
  title,
  section,
  onShare,
  onEdit,
  onDelete,
}: Props): React.ReactElement => {
  const buttons: readonly Button[] = useMemo(
    (): readonly Button[] => [
      {
        id: 'share-icon',
        label: onShare ? `Share ${title}` : 'Coming soon',
        icon: ShareIcon,
        action: onShare,
      },
      {
        id: 'edit-icon',
        label: `Edit ${title}`,
        icon: EditIcon,
        action: onEdit,
      },
      {
        id: 'delete-icon',
        label: `Delete ${title}`,
        icon: DeleteIcon,
        action: onDelete,
      },
    ],
    [onDelete, onEdit, onShare, title],
  );

  const name = useMemo((): string => title.toLowerCase(), [title]);
  return (
    <div className="flex flex-row justify-end items-center gap-1">
      {buttons.map((button: Button): React.ReactElement => {
        const id = `${name}-${section}-${button.id}`;
        const Icon = button.icon;

        return (
          <Disabler key={id} disabled={!button.action}>
            <Tooltip content={button.label}>
              <div key={button.id} className={buttonClassName} onClick={button.action}>
                <Icon className="w-5 h-5 fill-current" />
              </div>
            </Tooltip>
          </Disabler>
        );
      })}
    </div>
  );
};

const buttonClassName =
  'flex items-center justify-center cursor-pointer w-10 h-10 text-gray hover:bg-gray-200 rounded-full';
