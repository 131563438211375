import { ReactComponent as AlertIcon } from 'assets/icons/svg/info-gray.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import EmptySection from 'components/emptySection/EmptySection';
import { ErrorBox } from 'components/errorBox';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
import { SuccessBox } from 'components/successBox';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSharingProcessingState,
  sharingProcessingStateSelector,
} from 'redux/reducers/feedReducer';
import { ProcessingStateEnum } from 'types/processingState';
import FeedCard from 'views/Home/components/FeedCard';
import { FeedItem } from 'views/Home/types';

interface Props {
  readonly feed: readonly FeedItem[];
  readonly loading: boolean;

  onTagClick(tag: string): void;
  onPostClick(feedItem: FeedItem): void;
}

export const Feed: React.FC<Props> = ({
  feed,
  loading,
  onTagClick,
  onPostClick,
}: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const sharingProcessingState = useSelector(sharingProcessingStateSelector);

  const handleShareProcessingStateReset = useCallback((): void => {
    dispatch(resetSharingProcessingState());
  }, [dispatch]);

  if (feed.length === 0 && !loading) {
    return (
      <EmptySection
        title="No Feed Posts Found"
        subtitle="There aren't posts to show in the Feed board yet."
        icon={AlertIcon}
      />
    );
  } else {
    return (
      <>
        {feed.map(
          (feedItem: FeedItem): React.ReactElement => (
            <FeedCard
              key={feedItem.id}
              feedItem={feedItem}
              onPostClick={onPostClick}
              onTagClick={onTagClick}
            />
          ),
        )}

        <Modal
          isOpen={sharingProcessingState.state === ProcessingStateEnum.error}
          onClose={handleShareProcessingStateReset}
        >
          <ErrorBox
            title="Something Went Wrong"
            message="An error happened while trying to share this post"
            onClose={handleShareProcessingStateReset}
          />
        </Modal>

        <Modal
          isOpen={sharingProcessingState.state === ProcessingStateEnum.success}
          onClose={handleShareProcessingStateReset}
        >
          <SuccessBox
            title="Post Shared"
            message="The post has been shared and will be delivered to valid email addresses shortly."
            onClose={handleShareProcessingStateReset}
          />
        </Modal>

        <ConditionalRender renderIf={loading}>
          <div className="absolute inset-0 flex items-center justify-center rounded overflow-hidden">
            <SpinnerLoader visible={true} />
          </div>
        </ConditionalRender>
      </>
    );
  }
};
