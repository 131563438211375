import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import {
  getUserNotificationsBellError,
  markBellNotificationsAsSeen,
  NotificationMetadata,
  NotificationType,
} from 'redux/reducers/sharedReducer';
import { getNotificationUrl, getUrlText } from 'redux/services/notificationsService';
import { Page } from 'types/page';
import API, { API_V1_PATH } from 'utils/config/axiosConfig';
import { markMultipleAsSeen } from 'utils/notifications';
import { HttpStatusOk } from 'utils/statusCodes';
import { formatDate } from 'views/DataAnalysis/valuesFormatters';

export interface NotificationKind {
  readonly type: NotificationType;
  readonly source: string;
}

export interface NotificationDTO {
  readonly id: string;
  readonly title: string;
  readonly source: string;
  readonly type: NotificationType;
  readonly created_at: string;
  readonly is_read: boolean;
  readonly metadata: any;
}

export interface QuickLink {
  readonly label: string;
  readonly url: string;
}

export interface Notification {
  readonly id: string;
  readonly description: string;
  readonly source: string;
  readonly photo: string | null;
  readonly date: string;
  readonly seen: boolean;
  readonly type: NotificationType;
  readonly kind: NotificationKind;
  readonly metadata?: NotificationMetadata;

  // FIXME: maybe remove this
  readonly urlText?: string;
  readonly url?: string;

  readonly quickLink?: QuickLink;
}

const getPhoto = (item: NotificationDTO): string | null => {
  // FIXME: should not hardcode stuff no?
  if (item.source === 'Finvar') {
    // TODO: maybe show a nice logo here?
    return null;
  }

  return null;
};

export class Notification {
  public static fromJson(item: NotificationDTO): Notification {
    const date = parseISO(item.created_at);
    const meta = item.metadata ? { ...item.metadata, type: item.type } : undefined;
    const urlText = getUrlText(meta);

    return {
      id: item.id,
      photo: getPhoto(item),
      date: formatDate(date),
      description: item.title,
      seen: item.is_read,
      type: item.type,
      kind: {
        source: item.source,
        type: item.type,
      },
      ...(urlText
        ? {
            quickLink: {
              label: urlText,
              url: getNotificationUrl(meta) ?? '',
            },
          }
        : {}),
      source: item.source,
      urlText: getUrlText(meta),
      url: getNotificationUrl(meta) ?? undefined,
      metadata: meta,
    };
  }
}

export interface NotificationsState {
  loading: boolean;
  notifications: Page<Notification>;
  error: string;
  notification: Notification;

  singleNotificationLoading: boolean;
}

const initialState: NotificationsState = {
  loading: false,
  error: '',
  notifications: Page.empty(),
  notification: {
    id: '',
    description: '',
    source: '',
    photo: null,
    date: '',
    seen: false,
    type: 'Account' as NotificationType,
    kind: { type: 'Account' as NotificationType, source: '' },
  },

  singleNotificationLoading: false,
};

const notificationsSlide = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications: (state: NotificationsState): void => {
      state.loading = true;
    },
    getNotificationsSuccess: (
      state: NotificationsState,
      action: PayloadAction<Page<Notification>>,
    ) => {
      state.loading = false;
      state.notifications = action.payload;
    },
    getNotificationsFailed: (state: NotificationsState): void => {
      state.loading = false;
    },
    getSingleNotificationStarted: (state: NotificationsState): void => {
      state.singleNotificationLoading = true;
    },
    getSingleNotificationSuccess: (
      state: NotificationsState,
      action: PayloadAction<Notification>,
    ): void => {
      state.singleNotificationLoading = false;
      state.notification = action.payload;
    },
    getSingleNotificationFailed: (state: NotificationsState): void => {
      state.singleNotificationLoading = false;
    },
    markAsSeenSuccess: (
      state: NotificationsState,
      { payload }: PayloadAction<Notification[] | null>,
    ): void => {
      const { notifications } = state;

      if (payload === null) {
        state.notifications = Page.map(
          notifications,
          (item: Notification): Notification => ({ ...item, seen: true }),
        );
      } else {
        const newNotifications = markMultipleAsSeen(notifications.data, payload);

        state.notifications = {
          ...notifications,
          data: newNotifications,
        };
      }
    },
  },
});

export const {
  getNotifications,
  getNotificationsFailed,
  getNotificationsSuccess,
  markAsSeenSuccess,
  getSingleNotificationSuccess,
  getSingleNotificationFailed,
  getSingleNotificationStarted,
} = notificationsSlide.actions;

export const notificationsSelector = (state: { notifications: NotificationsState }) => {
  return state.notifications.notifications;
};

export const notificationSelector = (state: { notifications: NotificationsState }) => {
  return state.notifications.notification;
};

export const singleNotificationLoadingSelector = (state: {
  notifications: NotificationsState;
}): boolean => state.notifications.singleNotificationLoading;

export const loadingNotificationsSelector = (state: {
  notifications: NotificationsState;
}): boolean => state.notifications.loading;

export default notificationsSlide.reducer;

export const markAsSeenAction = (payload: {
  notification_ids: string[];
}): ((dispatch: Dispatch) => void) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.put(`${API_V1_PATH}/notifications/mark-multiple-as-seen`, payload);
      if (response.status === HttpStatusOk) {
        const { data } = response;
        const notifications = data.map(Notification.fromJson);

        dispatch(markAsSeenSuccess(notifications));
        dispatch(markBellNotificationsAsSeen(notifications));
      }
    } catch (error) {
      dispatch(getUserNotificationsBellError());
    }
  };
};
