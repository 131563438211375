import Button from 'components/buttons/Button/Button';
import SVGIcon from 'components/icons/SVGIcon';
import { useScrollEffect } from 'hooks/useScrollEffect';
import React, { useCallback, useState } from 'react';

interface Props {
  readonly viewport: HTMLDivElement | null;

  onRefreshFeed(): void;
}

export const RefreshButton: React.FC<Props> = ({
  viewport,
  onRefreshFeed,
}: Props): React.ReactElement | null => {
  const [visible, setVisible] = useState<boolean>(false);

  const onScroll = useCallback(
    (viewport: HTMLDivElement): void => {
      if (viewport.scrollTop > 500 === visible) {
        return;
      }

      setVisible(!visible);
    },
    [visible],
  );
  useScrollEffect(viewport, onScroll, 40);

  if (viewport === null) {
    return null;
  }

  if (!visible) {
    return null;
  }

  return (
    <div className="sticky top-3 left-0 right-0 -mb-14 flex items-center justify-center flex z-1">
      <div className="shadow-lg rounded-full">
        <Button
          id="refresh-feed-button"
          onClick={onRefreshFeed}
          width="w-44"
          bgHoverColor="hover:bg-blue"
          textColorHover="hover:text-white"
          rounded="rounded-full"
          padding="p-1"
          height="h-12"
        >
          <div className="flex items-center">
            <SVGIcon name="arrow-up-icon" className="fill-current text-white w-3 h-3" />
            <p className="font-poppinsSemiBold ml-4">REFRESH FEED</p>
          </div>
        </Button>
      </div>
    </div>
  );
};
