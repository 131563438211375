export const toQueryString = (object?: any): string => {
  if (!object) {
    return '';
  }

  const entries = Object.entries(object);
  if (entries.length === 0) {
    return '';
  }

  return (
    '?' +
    entries
      .map(([key, value]: [string, any]): [string, string] => {
        if (!value) {
          return [key, ''];
        }

        if (typeof value === 'string' && value.startsWith(' ')) {
          return [encodeURIComponent(key), encodeURIComponent(`+${value.slice(1)}`)];
        } else {
          return [encodeURIComponent(key), encodeURIComponent(value)];
        }
      })
      .map(([key, value]): string => `${key}=${value}`)
      .join('&')
  );
};
