/* eslint-disable react-hooks/exhaustive-deps */
import 'views/Landing/SignUp/Hubspot.css';

import SpinnerLoader from 'components/spinnerLoader';
import { useTrackingCode } from 'hooks/useTrackingCode';
import React, { useEffect, useRef, useState } from 'react';

declare global {
  interface Window {
    hbspt: {
      forms: {
        // fix type in future for hubspot form
        create: (params: any) => any;
      };
    };
  }
}

interface Props {
  region: string;
  portalId: string;
  formId: string;
}

export const HubspotForm = ({ region, portalId, formId }: Props) => {
  const { setPathPageView } = useTrackingCode();

  const refContainer = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const createForm = () => {
    if (window.hbspt && refContainer.current !== null) {
      window.hbspt.forms.create({
        region,
        portalId,
        formId,
        onFormSubmit: () => {
          //form sent
        },
      });
      return true;
    }

    return null;
  };

  const findFormElement = () => {
    if (refContainer.current === null) {
      return;
    }
    const form = document.querySelector('iframe');

    return form ? setLoading(false) : setTimeout(findFormElement, 1);
  };

  const loadScript = () => {
    setLoading(true);
    const script = document.createElement(`script`);
    script.defer = true;
    script.onload = () => {
      createForm();
      findFormElement();
    };
    script.src = `//js.hsforms.net/forms/v2.js`;
    document.head.appendChild(script);
  };

  useEffect(() => {
    if (!window.hbspt) {
      loadScript();
    } else {
      findFormElement();
    }

    setPathPageView('/sign-up');
  }, []);

  return (
    <div>
      <div ref={refContainer} id="hubspotForm" style={{ display: loading ? 'none' : 'block' }} />
      <SpinnerLoader visible={loading} />
    </div>
  );
};
