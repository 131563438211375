import Button from 'components/buttons/Button/Button';
import { KeyboardListener } from 'components/keyboardListener';
import { RadioButton } from 'components/radioButton';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProcessingState,
  processingStateSelector,
  ReportableType,
  ReportReason,
  reportReasonsSelector,
  reset,
} from 'redux/reducers/reportsReducer';
import { getReportReasons, submitReport } from 'redux/services/reportsService';
import config from 'utils/config/appSettings';
import { Textarea } from 'views/Home/modals/ReportModal/component/textarea';
import { Title } from 'views/Home/modals/title';

interface Props {
  readonly reportableId: string;
  readonly reportableType: ReportableType;

  onReport(): void;
  onClose(): void;
}

export const ReportModal: React.FC<Props> = ({
  reportableId,
  reportableType,
  onReport,
  onClose,
}: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const reportReasons = useSelector(reportReasonsSelector);
  const processingState = useSelector(processingStateSelector);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [reason, setReason] = useState<number>(-1);
  const [explanation, setExplanation] = useState<string>('');

  const handleReasonChange = useCallback((value: string | number): void => {
    if (typeof value !== 'number') {
      throw new Error('we are passing numbers, we must get numbers');
    }

    setReason(value);
  }, []);

  const handleExplanationChange = useCallback((value: string): void => {
    setExplanation(value);
  }, []);

  const handleSubmit = useCallback((): void => {
    dispatch(submitReport(reportableId, reportableType, reason, explanation));
  }, [dispatch, explanation, reason, reportableId, reportableType]);

  const submitEnabled = useMemo((): boolean => {
    if (reason !== 0) {
      return true;
    } else {
      return explanation.trim() !== '';
    }
  }, [explanation, reason]);

  useEffect((): VoidFunction => {
    dispatch(getReportReasons());

    return (): void => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect((): void => {
    setReason(reportReasons[0]?.id ?? -1);
  }, [reportReasons]);

  useEffect((): void => {
    const textarea = textareaRef.current;
    if (textarea === null) {
      return;
    }

    if (reason === 0) {
      textarea.focus();
    } else {
      setExplanation('');
    }
  }, [reason]);

  useEffect((): void => {
    if (processingState === ProcessingState.completed) {
      onReport();
      onClose();
    }
  }, [onClose, onReport, processingState]);

  const handleGlobalKeyPressed = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): boolean => {
      if (event.key === 'Escape') {
        onClose();

        return true;
      }

      return false;
    },
    [onClose],
  );

  return (
    <KeyboardListener onKeyPressed={handleGlobalKeyPressed}>
      <div className="p-6 md:min-w-modal-md">
        <Title title="Why are you reporting this?" onClose={onClose} />
        {reportReasons.map((r: ReportReason): React.ReactElement => {
          const id = `reason-${r.id}`;
          const checked = r.id == reason;

          return (
            <RadioButton
              id={id}
              key={id}
              label={r.label}
              value={r.id}
              checked={checked}
              onChange={handleReasonChange}
            />
          );
        })}
        <div className="mt-4">
          <Textarea
            label="Please explain"
            value={explanation}
            ref={textareaRef}
            id="explanation"
            disabled={reason !== 0}
            limit={config.maxReportReasonLength}
            onChange={handleExplanationChange}
          />
        </div>
        <div className="flex items-center justify-end mt-2">
          <Button
            width="w-auto"
            padding="px-6"
            label="submit"
            disabled={!submitEnabled}
            cursor={!submitEnabled ? 'cursor-default' : 'cursor-pointer'}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </KeyboardListener>
  );
};
