import { useFollowHandler } from 'hooks/useFollowUnfollow';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionsAction } from 'redux/actions/connectionsActions';
import { followingSelector } from 'redux/reducers/connectionsReducer';
import ConnectionsTable from 'views/Connections/Overview/ConnectionsTable';
import { ConnectionType } from 'views/Connections/Overview/types';

export const Followees: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const connections = useSelector(followingSelector);
  const handler = useFollowHandler();

  useEffect((): void => {
    dispatch(getConnectionsAction(1, 20, ConnectionType.followees));
  }, [dispatch]);

  return <ConnectionsTable connections={connections} onFollowAction={handler} />;
};
