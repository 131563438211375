import 'views/Landing/LandingPage/components/Features/features.scss';

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FeatureItem,
  landingPageSelector,
  setSelectedFeature,
} from 'redux/reducers/landingPageReducer';
import { Feature } from 'views/Landing/LandingPage/components/Features/Feature';
import { FeatureButton } from 'views/Landing/LandingPage/components/Features/FeatureButton';

const Features: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const createFeatureClickHandler = useCallback(
    (feature: FeatureItem): VoidFunction => {
      return (): void => {
        dispatch(setSelectedFeature(feature.id));
      };
    },
    [dispatch],
  );

  const { featuresMap, featuresList, selectedFeatureKey } = useSelector(landingPageSelector);
  const selectedFeature = selectedFeatureKey ? featuresMap[selectedFeatureKey] : null;

  return (
    <div className="w-full my-6">
      <div className="w-11/12 md:w-5/6 mx-auto">
        <div className="flex flex-col space-y-4 mb-8 items-center">
          <h1 className="text-2xl md:text-4xl">Features</h1>
          <p className="font-poppins text-md text-center">
            A modern set of tools for empowered finance & investing. Click the buttons below for
            more details.
          </p>
        </div>
        <div className="btns-row">
          {featuresList.map(feature => (
            <FeatureButton
              key={feature.id}
              isSelected={selectedFeatureKey === feature.id}
              label={feature.label}
              onClick={createFeatureClickHandler(feature)}
            />
          ))}
        </div>
        <Feature item={selectedFeature} />
      </div>
    </div>
  );
};

export default Features;
