import React, { useMemo, useState } from 'react';

interface Props {
  readonly iso2: string | null;
  readonly size?: 'normal' | 'small';
}

export const CountryFlag: React.FC<Props> = ({
  iso2,
  size = 'normal',
}: Props): React.ReactElement => {
  const [imageNotFound, setImageNotFound] = useState<boolean>(false);
  const flag = useMemo((): string | null => (iso2 ? iso2.toLowerCase() : null), [iso2]);

  const sizeClass = useMemo((): string => {
    switch (size) {
      case 'normal':
        return 'w-6 h-5 leading-5 align-middle';
      case 'small':
        return 'w-5 h-4 leading-4 align-middle';
    }
  }, [size]);

  const fallbackViewClass = useMemo(
    (): string =>
      [sizeClass, 'bg-gray-light font-poppinsSemiBold text-xs text-gray-medium text-center'].join(
        ' ',
      ),
    [sizeClass],
  );

  const onImageLoadError = (): void => {
    setImageNotFound(true);
  };

  return imageNotFound ? (
    <div className={fallbackViewClass}>{iso2}</div>
  ) : flag ? (
    <img src={`/flags/${flag}.jpg`} className={sizeClass} onError={onImageLoadError} alt="" />
  ) : (
    <div className={fallbackViewClass}>&times;</div>
  );
};
