import DesktopLandingPageHeader from 'components/header/LandingPageHeader/desktopHeader';
import { MobileLoginHeader } from 'components/header/LandingPageHeader/mobileHeader';
import React from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
  readonly compact: boolean;
}

export const LandingPageHeader: React.FC<Props> = ({ compact }: Props): React.ReactElement => {
  if (isMobile) {
    return <MobileLoginHeader />;
  } else {
    return <DesktopLandingPageHeader compact={compact} />;
  }
};
