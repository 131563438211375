import { CommonFilter } from 'components/filters/helpers';

const filters: readonly CommonFilter[] = [
  {
    name: 'category',
    label: 'Category',
    type: 'backend',
    url: '/api/v1/filters/marketplace/category',
  },
  {
    name: 'amount',
    label: 'Amount',
    type: 'backend',
    url: '/api/v1/filters/marketplace/amount',
  },
  {
    name: 'bookmarked',
    label: 'Bookmarked',
    type: 'backend',
    url: '/api/v1/filters/marketplace/bookmarked',
  },
];

export default filters;
