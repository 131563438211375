import { Select, SelectItem } from 'components/select';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setInvestmentType } from 'redux/reducers/addPortfolioItemFormReducer';
import {
  PortfolioCompanyInvestmentItem,
  PortfolioCompanyInvestmentType,
  portfolioCompanyInvestmentTypeLabels,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { InvestmentItemFormSelector } from 'views/Portfolios/PortfoliosModal/investmentFormSelector';

interface Props {
  readonly item: PortfolioCompanyInvestmentItem;
}

export const InvestmentItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const handleInvestmentTypeChange = useCallback(
    (value: PortfolioCompanyInvestmentType): void => {
      dispatch(setInvestmentType(value));
    },
    [dispatch],
  );

  return (
    <>
      <Select
        id="typeOfInvestment"
        label="Type of Investment"
        items={investmentTypes}
        value={item.investmentType}
        onChange={handleInvestmentTypeChange}
      />

      <InvestmentItemFormSelector item={item} />
    </>
  );
};

const investmentTypes: ReadonlyArray<SelectItem<PortfolioCompanyInvestmentType>> =
  enumToSelectItems(PortfolioCompanyInvestmentType, portfolioCompanyInvestmentTypeLabels);
