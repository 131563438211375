import React from 'react';
import { PortfolioArtDigitalArtItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioArtDigitalArtItem;
}

export const DigitalArtArtItemForm: React.FC<Props> = ({ item: _ }: Props): React.ReactElement => {
  return <></>;
};
