import { isCustomMenuItem, isNavMenuItem, isUrlMenuItem, MenuItem } from 'components/sidebar/types';
import { Tooltip } from 'components/tooltip';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  readonly item: MenuItem;
}

const ItemButton: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const TooltipComponent = React.useCallback(
    ({ children }: React.PropsWithChildren): React.ReactElement => {
      if (item.tooltipText) {
        return (
          <Tooltip content={item.tooltipText} placement="right">
            {children}
          </Tooltip>
        );
      }

      return <>{children}</>;
    },
    [item.tooltipText],
  );

  if (item.hidden) {
    return <></>;
  } else if (item.comingSoon) {
    return (
      <Tooltip
        content={<p className="text-xs font-poppinsMedium">COMING SOON</p>}
        variant="light"
        placement="right"
      >
        <p
          className={`${baseClassName} opacity-50 uppercase text-gray cursor-default inline-block`}
        >
          {item.label}
        </p>
      </Tooltip>
    );
  } else if (isNavMenuItem(item)) {
    return (
      <TooltipComponent>
        <NavLink className={classResolver} to={item.path}>
          {item.label}
        </NavLink>
      </TooltipComponent>
    );
  } else if (isCustomMenuItem(item)) {
    return (
      <TooltipComponent>
        <div className={`${baseClassName} text-gray cursor-pointer uppercase`}>{item.label}</div>
      </TooltipComponent>
    );
  } else if (isUrlMenuItem(item)) {
    return (
      <TooltipComponent>
        <a href={item.url} className={`block ${baseClassName} text-gray cursor-pointer uppercase`}>
          {item.label}
        </a>
      </TooltipComponent>
    );
  } else {
    return <></>;
  }
};

export default ItemButton;

const classResolver = ({ isActive }: { isActive: boolean }): string => {
  if (isActive) {
    return baseClassName + ' inline-block text-blue normal-case';
  } else {
    return baseClassName + ' inline-block text-gray normal-case';
  }
};

const baseClassName =
  'whitespace-nowrap overflow-hidden font-poppins overflow-ellipsis text-sm pr-1 hover:text-gray-darkest';
