import React from 'react';
import { PortfolioArtSculptureItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioArtSculptureItem;
}

export const SculptureArtItemForm: React.FC<Props> = ({ item: _ }: Props): React.ReactElement => {
  return <></>;
};
