import { AutoParagraph } from 'components/autoParagraph/AutoParagraph';
import { Clickable } from 'components/clickable';
import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faqsSelector, getFaqsAction, loadingFaqsSelector } from 'redux/reducers/sharedReducer';

export const Faqs: React.FC = (): React.ReactElement => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const faqs = useSelector(faqsSelector);
  const loading = useSelector(loadingFaqsSelector);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getFaqsAction());
  }, [dispatch]);

  const toggleTab = useCallback(
    (tab: string) => {
      setActiveTab(activeTab !== tab ? tab : null);
    },
    [activeTab],
  );

  return (
    <div className="flex-1 relative">
      <div className="flex flex-col w-full">
        {faqs.map(faq => (
          <div className="w-8/12 m-auto mt-6" key={faq.section}>
            {faq.faqs.map(item => (
              <div key={item.question}>
                <Clickable clickData={item.question} onClick={toggleTab}>
                  <div className="flex items-center justify-between bg-gray-light p-3 cursor-pointer my-3 rounded">
                    <p className="text-left font-poppins text-md">{item.question}</p>
                    <div className="flex items-center">
                      <SVGIcon
                        name="down-arrow"
                        className={`cursor-pointer duration-500 w- 3h-3 ${
                          activeTab === item.question ? 'transform rotate-180' : ''
                        }`}
                      />
                    </div>
                  </div>
                </Clickable>
                {activeTab === item.question && (
                  <div className="my-4 px-5 pb-2 border-b border-gray-light">
                    <AutoParagraph className="text-left font-poppins text-md" text={item.answer} />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <SpinnerLoader visible={loading} />
    </div>
  );
};
