import { Expander } from 'components/table/expander';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';

export type SensitivityGroup = 'watchlist' | 'portfolio' | 'portfolios';

export enum SensitivityCategory {
  industries = 'industries',
  indicators = 'indicators',
  suppliers = 'suppliers',
  customers = 'customers',
  investors = 'investors',
  tags = 'tags',
}

export interface Sensitivity {
  readonly id: string;
  readonly name: string;
  readonly count: number;
  readonly percentage: number;
}

export interface SensitivityCompany {
  readonly id: string;
  readonly name: string;
  readonly ticker: string;
  readonly exchange: string;
  readonly description: string;
}

export const createSensitivitiesColumns = (
  category: SensitivityCategory,
  section: SensitivityGroup,
  expandHandler: (row: Sensitivity, expanded: boolean) => void | Promise<void>,
): ReadonlyArray<ColumnDefinition<Sensitivity>> => [
  {
    type: ColumnType.text,
    name: 'name',
    label: `${labels[category].entity} Name`,
    alignment: Alignment.left,
    sortable: true,
    weight: 1.25,
  },
  {
    type: ColumnType.numeric,
    name: 'count',
    label: `${labels[category].columnHeader('count', section)}`,
    precision: 0,
    alignment: Alignment.center,
    sortable: true,
  },
  {
    type: ColumnType.percent,
    name: 'percentage',
    label: `${labels[category].columnHeader('percentage', section)}`,
    precision: 2,
    alignment: Alignment.center,
    sortable: true,
  },
  {
    type: ColumnType.custom,
    name: 'id',
    label: '',
    weight: 2,
    render(_: string, row: any): React.ReactNode {
      return <Expander row={row} onExpand={expandHandler} />;
    },
  },
];

interface Label {
  readonly entity: string;
  readonly columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup) => string;
}

const sectionNames: Readonly<Record<SensitivityGroup, string>> = {
  watchlist: 'Watchlist',
  portfolio: 'Portfolio',
  portfolios: 'Portfolio',
};

const labels: Readonly<Record<SensitivityCategory, Label>> = {
  [SensitivityCategory.industries]: {
    entity: 'Industry',
    columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup): string =>
      `${typeOfLabel} of ${sectionNames[section]} Items\nIn Industry`,
  },
  [SensitivityCategory.indicators]: {
    entity: 'Indicator',
    columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup): string =>
      `${typeOfLabel} of ${sectionNames[section]} Items\nUsing Indicator`,
  },
  [SensitivityCategory.suppliers]: {
    entity: 'Suppliers',
    columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup): string =>
      `${typeOfLabel} of ${sectionNames[section]} Items\nWith this Supplier`,
  },
  [SensitivityCategory.customers]: {
    entity: 'Customers',
    columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup): string =>
      `${typeOfLabel} of ${sectionNames[section]} Items\nWith this Customer`,
  },
  [SensitivityCategory.investors]: {
    entity: 'Investors',
    columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup): string =>
      `${typeOfLabel} of ${sectionNames[section]} Items\nWith this Investor`,
  },
  [SensitivityCategory.tags]: {
    entity: 'Tags',
    columnHeader: (typeOfLabel: 'count' | 'percentage', section: SensitivityGroup): string =>
      `${typeOfLabel} of ${sectionNames[section]} Items\nWith this Tag`,
  },
};
