import { Clickable } from 'components/clickable';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import { ErrorBox } from 'components/errorBox';
import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import { SuccessBox } from 'components/successBox';
import Toggle from 'components/toggle/Toggle';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSelector } from 'redux/reducers/authReducer';
import {
  actionCompleted,
  BillingInterval,
  Feature,
  featuresSelector,
  isProcessingStateForActions,
  processingStateSelector,
  SubscriptionPlan,
  SubscriptionPlansReducerActions,
  subscriptionPlansSelector,
} from 'redux/reducers/subscriptionPlansReducer';
import { cancelActiveSubscriptionPlanAction } from 'redux/services/subscriptionPlansService';
import { ProcessingStateEnum } from 'types/processingState';
import api, { API_V1_PATH, baseURL, isAxiosError } from 'utils/config/axiosConfig';
import { fromStringToFormattedDate } from 'utils/dateTime';
import { delayedExecution } from 'utils/delayedExecution';
import { HttpStatusConflict } from 'utils/statusCodes';
import { Payment } from 'views/Checkout/SubscriptionCheckout/payment';
import { ErrorView } from 'views/ErrorView';
import { CancelConsentModal } from 'views/Profile/Payments/cancelConsentModal';
import { SubscriptionPlanItem } from 'views/SubscriptionPlans/subscriptionPlanItem';
import { SubscriptionPlanItemHeaderPlaceholder } from 'views/SubscriptionPlans/subscriptionPlanItemHeaderPlaceholder';

export const zeroUUID = '00000000-0000-0000-0000-000000000000';
export const IndividualsTab: React.FC = (): React.ReactElement => {
  const features = useSelector(featuresSelector);
  const navigate = useNavigate();
  const subscriptions = useSelector(subscriptionPlansSelector);
  const dispatch = useDispatch<any>();
  const processingState = useSelector(processingStateSelector);
  const conflictModal = useModal();
  const changeConfirmation = useModal();
  const { subscription } = useSelector(userSelector);

  const [billingCycle, setBillingCycle] = useState<BillingInterval>(BillingInterval.monthly);
  const [currentPlanId, setCurrentPlanId] = useState<string>('');
  const [creatingCheckoutId, setCreatingCheckoutId] = useState<string | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);

  const cancelRequestModal = useModal();

  const handleCancelRequested = useCallback((): void => {
    cancelRequestModal.open();
  }, [cancelRequestModal]);

  useEffect((): void => {
    if (subscription) {
      setCurrentPlanId(subscription.id);
    }
  }, [subscription]);

  const handleBillingCycleChange = useCallback((): void => {
    setBillingCycle((billingCycle: BillingInterval): BillingInterval => {
      switch (billingCycle) {
        case BillingInterval.yearly:
          return BillingInterval.monthly;
        case BillingInterval.monthly:
          return BillingInterval.yearly;
        default:
          throw new Error('invalid billing cycle');
      }
    });
  }, []);

  const billingInterval = BillingInterval[billingCycle];

  const handlePlanChange = useCallback(async (): Promise<void> => {
    changeConfirmation.close();
    const body = { billing_interval: billingInterval };
    const url = `${baseURL}${API_V1_PATH}/products/subscriptions/${currentPlanId}/checkout`;
    setCreatingCheckoutId(currentPlanId);
    try {
      const response = await delayedExecution(api.post(url, body), 0.75);
      const payment: Payment | null = response.data;
      if (payment?.type === 'free-trial') {
        navigate('/profile/payments', {
          state: payment,
        });
      } else {
        navigate(`/checkout/subscriptions/${currentPlanId}/${billingInterval}`, {
          state: response.data,
        });
      }
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response } = error;
        if (response?.status === HttpStatusConflict) {
          conflictModal.open();
        }
      }
    } finally {
      setCreatingCheckoutId(null);
    }
  }, [currentPlanId, navigate, conflictModal, changeConfirmation, billingInterval]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const selectedPlan = subscriptions.find(
      (subscription: SubscriptionPlan): boolean => subscription.id === currentPlanId,
    );

    if (!selectedPlan) {
      console.warn("couldn't find selected subscription");
      return;
    }
    if (subscription?.id === selectedPlan.id) {
      conflictModal.open();
      return;
    }
    if (selectedPlan.id === zeroUUID) {
      handleCancelRequested();
    } else if (subscription?.id === zeroUUID) {
      await handlePlanChange();
    } else {
      changeConfirmation.open();
    }
  }, [
    conflictModal,
    currentPlanId,
    handleCancelRequested,
    subscriptions,
    subscription,
    changeConfirmation,
    handlePlanChange,
  ]);

  const resetProcessingState = useCallback((): void => {
    dispatch(actionCompleted());
  }, [dispatch]);

  const cancelSucceeded = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.success,
      SubscriptionPlansReducerActions.cancelActiveSubscription,
    );
  }, [processingState]);

  const cancelFailed = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.fatalError,
      SubscriptionPlansReducerActions.cancelActiveSubscription,
    );
  }, [processingState]);

  const handleCloseConsentModal = useCallback((): void => {
    cancelRequestModal.close();
  }, [cancelRequestModal]);

  const handleCancelActivePlan = useCallback(
    (reason: string): void => {
      handleCloseConsentModal();
      dispatch(cancelActiveSubscriptionPlanAction(reason));
    },
    [dispatch, handleCloseConsentModal],
  );

  const hideSelectedFeature = useCallback((): void => setSelectedFeature(null), []);

  return (
    <div className="flex flex-col items-center justify-between flex-grow py-6">
      <ConditionalRender renderIf={processingState.state === ProcessingStateEnum.fatalError}>
        <ErrorView
          title="No plans can be listed"
          message={
            <span>
              If you expected to see the list of plans here, please contact support at{' '}
              <a href="mailto:help@capnote.com">help@capnote.com</a>
            </span>
          }
        />
      </ConditionalRender>
      <ConditionalRender renderIf={features.length > 0}>
        <div className="flex flex-col flex-grow items-center justify-between">
          <div />
          <div className="flex gap-2 cursor-pointer select-none">
            <p>Monthly</p>
            <Toggle
              id="subscription"
              toggleId="subscription-toggle"
              label=""
              checked={billingCycle === BillingInterval.yearly}
              flat={true}
              onChange={handleBillingCycleChange}
            />
            <p>Annually</p>
          </div>
          <div />
        </div>

        <div className="flex-grow my-5">
          <div className="text-right mb-2 text-green-600">
            <p className="font-poppinsMedium">Select annual plans and get 2 months for free</p>
          </div>
          <div className="flex items-center justify-center overflow-auto">
            <div className="flex items-start justify-center gap-6">
              <div className="border-2 border-transparent py-3">
                <SubscriptionPlanItemHeaderPlaceholder />
                {features.map((feature: Feature): React.ReactElement => {
                  return (
                    <Clickable key={feature.id} clickData={feature} onClick={setSelectedFeature}>
                      <p className="h-8 leading-8 odd:bg-gray-light px-4 truncate cursor-pointer text-blue">
                        {feature.name}
                      </p>
                    </Clickable>
                  );
                })}
              </div>

              <div className="flex items-start justify-evenly gap-3 overflow-auto">
                {subscriptions.map(
                  (plan: SubscriptionPlan): React.ReactElement => (
                    <SubscriptionPlanItem
                      key={plan.id}
                      features={features}
                      busy={creatingCheckoutId === plan.id}
                      subscription={plan}
                      selected={plan.id === currentPlanId}
                      active={plan.id === subscription?.id}
                      billingInterval={billingInterval}
                      onSelected={setCurrentPlanId}
                    />
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-6">
          <p className="font-poppins text-sm text-grey mb-8 text-center">
            All paid subscriptions are billed on the first business day of the selected billing
            cycle.
          </p>
          <button
            className="bg-blue text-white font-poppinsMedium w-64 h-10"
            onClick={handleSubmit}
          >
            Select Plan
          </button>
        </div>
      </ConditionalRender>

      <Modal isOpen={cancelRequestModal.isOpen} onClose={handleCloseConsentModal}>
        <Modal.Content title="Cancel Subscription">
          <CancelConsentModal
            message="To switch to the selected plan your currently active subscription would cancelled, are you sure?"
            onClose={handleCloseConsentModal}
            onYes={handleCancelActivePlan}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={cancelSucceeded} onClose={resetProcessingState}>
        <SuccessBox
          title="Cancellation Request Submitted"
          message="We have received a request to cancel your subscription."
          onClose={resetProcessingState}
        />
      </Modal>
      <Modal isOpen={cancelFailed} onClose={resetProcessingState}>
        <ErrorBox
          title="Cancel Subscription Failed"
          message="There was an error canceling your subscription. Please try again later."
          onClose={resetProcessingState}
        />
      </Modal>

      <Modal isOpen={conflictModal.isOpen} onClose={conflictModal.close}>
        <ErrorBox
          title="You can't subscribe to this plan"
          message="You already have an active subscription to this plan."
          onClose={conflictModal.close}
          type="information"
        />
      </Modal>

      <Modal isOpen={changeConfirmation.isOpen} onClose={changeConfirmation.close}>
        <Modal.Content title="Change Plan">
          <ConfirmationBox
            message={`You will lose the benefits of your current plan which is valid till ${fromStringToFormattedDate(
              subscription?.expires_at,
            )}`}
            onYes={handlePlanChange}
            onNo={changeConfirmation.close}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={selectedFeature !== null} onClose={hideSelectedFeature}>
        <Modal.Content title={selectedFeature?.name}>
          <div className="w-modal-sm pt-5">
            <p>{selectedFeature?.description}</p>
          </div>
          <div className="flex items-center justify-end mt-8">
            <Button type="button" variant="secondary" label="Close" onClick={hideSelectedFeature} />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};
