import { UserAvatar } from 'components/userAvatar';
import React from 'react';
import { Link } from 'react-router-dom';
import { getFormatDate } from 'utils';
import { stopPropagation } from 'utils/ignoreEvent';
import { TitleRightTool } from 'views/Home/components/FeedCard/components/titleRightTool';
import { FeedCategory, FeedItem, postCategoryLabels } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;
}
export const Title: React.FC<Props> = ({ feedItem }: Props): React.ReactElement => {
  const date = getFormatDate(feedItem.date);

  const imageSrc = React.useMemo((): string => {
    return feedItem.image ?? 'images/logo.svg';
  }, [feedItem.image]);

  const Image = React.useCallback((): React.ReactElement => {
    if (typeof feedItem.publisher === 'string' && feedItem.publisher !== 'Finvar') {
      const className = imageClasses[feedItem.category];
      return <img className={className} src={imageSrc} alt="publisher icon/avatar" />;
    } else if (feedItem.publisher === 'Finvar') {
      const className = imageClasses[feedItem.category];
      return <img className={className} src="favicon.ico" alt="publisher icon/avatar" />;
    } else if (typeof feedItem.publisher !== 'string') {
      return (
        <div className="w-10 h-10 mr-3 lg:mt-0 mt-1">
          <UserAvatar size="medium" user={feedItem.publisher} />
        </div>
      );
    } else {
      return <></>;
    }
  }, [feedItem.category, feedItem.publisher, imageSrc]);

  return (
    <div className="flex items-start justify-between mb-2 gap-2 w-full">
      <div className="flex items-start w-full min-w-0">
        <Image />
        <div className="min-w-0">
          <h1 className="text-gray-darkest text-xl leading-6 truncate overflow-ellipsis">
            {feedItem.title}
          </h1>
          <div className="flex flex-row">
            <p className="text-gray text-sm truncate min-w-0">
              {date} by{' '}
              {typeof feedItem.publisher === 'string' ? (
                <span className="font-poppins">{feedItem.publisherName}</span>
              ) : (
                <Link
                  className="normal-case text-blue font-poppins"
                  to={`/users/${feedItem.publisher.id}`}
                  onClick={stopPropagation}
                >
                  {feedItem.publisherName}
                </Link>
              )}{' '}
              -{' '}
            </p>
            <p className="text-blue italic truncate min-w-0e">
              {postCategoryLabels[feedItem.category]}
            </p>
          </div>
        </div>
      </div>
      {process.env.REACT_APP_DATA_PORTAL ? null : <TitleRightTool feedItem={feedItem} />}
    </div>
  );
};

const imageClasses: { [key in FeedCategory]: string } = {
  [FeedCategory.news]: 'w-10 h-10 mr-3 lg:mt-0 mt-1 border rounded-sm border-gray-light',
  [FeedCategory.post]: 'w-10 h-10 mr-3 lg:mt-0 mt-1',
  [FeedCategory.notification]: 'w-10 h-10 mr-3 mt-1',
  [FeedCategory.none]: 'hidden',
};
