import React from 'react';

interface Props {
  readonly text: string;
  readonly className?: string;
}
export const AutoParagraph: React.FC<Props> = ({ text, className }: Props): React.ReactElement => {
  const paragraphs = text.split(/\r\n|\n/);

  return (
    <>
      {paragraphs.map(
        (p: string): React.ReactElement => (
          <p key={p} className={`${className} my-5`}>
            {p}
          </p>
        ),
      )}
    </>
  );
};
