import React from 'react';
import { PortfolioRealEstateItem, RealEstatePurpose } from 'types/portfolioItem';
import { RealEstateIndustrialExtraInputs } from 'views/Portfolios/PortfoliosModal/industrialRealEstateExtraInputs';
import { RealEstateLandExtraInputs } from 'views/Portfolios/PortfoliosModal/landRealEstateExtraInputs';
import { RealEstateCommercialExtraInputs } from 'views/Portfolios/PortfoliosModal/realEstateCommercialExtraInputs';
import { RealEstateInvestmentResidentialExtraInputs } from 'views/Portfolios/PortfoliosModal/realEstateInvestmentResidentialExtraInputs';

interface Props {
  readonly item: PortfolioRealEstateItem;
}

export const RealEstateExtraInputSelector: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  switch (item.purpose) {
    case RealEstatePurpose.land:
      return <RealEstateLandExtraInputs item={item} />;
    case RealEstatePurpose.investmentResidential:
      return <RealEstateInvestmentResidentialExtraInputs item={item} />;
    case RealEstatePurpose.commercial:
      return <RealEstateCommercialExtraInputs item={item} />;
    case RealEstatePurpose.industrial:
      return <RealEstateIndustrialExtraInputs item={item} />;
    case RealEstatePurpose.yourResidence:
      return <></>;
    default:
      return <></>;
  }
};
