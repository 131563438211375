import 'views/DataAnalysis/main.css';

import { Modal } from 'components/modal';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSelector } from 'redux/reducers/authReducer';
import { me } from 'redux/services/authServices';

interface Props {
  readonly isOpen: boolean;
  onClose(): void;
}

const paragraphClassName = 'mb-6 font-poppins text-md text-left';

const WelcomeModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback((): void => {
    const now = new Date();
    navigate('/upgrade');

    // Request the user information now because we're needing it in the
    // next screen
    dispatch(me());

    localStorage.setItem(user.id, now.toLocaleString());
    onClose();
  }, [dispatch, navigate, onClose, user.id]);

  if (process.env.REACT_APP_DATA_PORTAL) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Content>
        <div className="relative w-modal-md text-gray-darkest overflow-auto px-8 py-4">
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <img src="logo.svg" alt="application-logo" className="w-32 xl:w-48" />
            </div>
            <h2 className="font-jostSemiBold text-2xl my-8">Welcome and thank you for joining!</h2>
            <p className={paragraphClassName}>
              Capnote provides you with intelligence, productivity tools and a global community with
              the objective of improving your financial outcomes.
            </p>
            <p className={paragraphClassName}>
              To optimize your experience, take a brief moment to edit your watchlists, portfolios
              and settings. Invite your friends and colleagues. Contact us at{' '}
              <a href="mailto:team@capnote.com">team@capnote.com</a> with any questions or
              suggestions. We look forward to hearing from you.
            </p>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default WelcomeModal;
