import { Tooltip } from 'components/tooltip';
import React from 'react';
import { Link } from 'react-router-dom';
import { StreamEvent } from 'types/timeline';
import { EventCard } from 'views/SmartTools/TimelineAndEvents/components/eventItem/components/eventCard';

interface Props {
  readonly event: StreamEvent;
  readonly compact: boolean;
  readonly upcoming: boolean;
}

export const EventItem: React.FC<Props> = ({
  event,
  compact,
  upcoming,
}: Props): React.ReactElement => {
  return (
    <Tooltip content={event.summary} placement="top">
      <Link to={event.id}>
        <EventCard event={event} compact={compact} upcoming={upcoming} />
      </Link>
    </Tooltip>
  );
};
