import { ConfirmationBox } from 'components/confirmationBox';
import DeleteModal from 'components/deleteModal';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
// GENERAL COMPONENTS
import { useModal } from 'hooks/useModal';
import { useReferrerCode } from 'hooks/useReferrerCode';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletingAccountSelector,
  permissionCodeBeingUpdatedSelector,
  profilePreferencesSelector,
  UserPermission,
} from 'redux/reducers/profileReducer';
// REDUX
import {
  deleteAccount,
  disableAccountService,
  getPreferences,
  updatePermission,
} from 'redux/services/profileService';
// UTILS
import { ChangePasswordModal } from 'views/Profile/Settings/ChangePasswordModal';
import { Card } from 'views/Profile/Settings/components/card';
import { CardTitle } from 'views/Profile/Settings/components/cardTitle';
import { ReferralLink } from 'views/Profile/Settings/components/referralLink';
import { PermissionToggle } from 'views/Profile/Settings/permissionToggle';
import { text } from 'views/Profile/Settings/text';

const Settings: React.FC = () => {
  const [showDisableModal, setShowDisableModal] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);

  const preferences = useSelector(profilePreferencesSelector);
  const dispatch = useDispatch<any>();
  const deleteAccountModal = useModal();
  const deletingAccount = useSelector(deletingAccountSelector);
  const permissionCodeBeingUpdated = useSelector(permissionCodeBeingUpdatedSelector);
  const referrer = useReferrerCode();

  const { permissions } = preferences;

  const handleDeleteAccount = useCallback((): void => {
    dispatch(deleteAccount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPreferences());
  }, [dispatch]);

  const closeDisableModal = useCallback((): void => setShowDisableModal(false), []);

  const handleUpdatePermission = useCallback(
    (permission: UserPermission): void => {
      dispatch(updatePermission(permission));
    },
    [dispatch],
  );

  const handleShowPasswordModal = useCallback((): void => {
    setShowPasswordModal(true);
  }, []);

  const handleClosePasswordModal = useCallback((): void => {
    setShowPasswordModal(false);
  }, []);

  const handleDisableAccount = useCallback(() => {
    setShowDisableModal(false);
    dispatch(disableAccountService(true));
  }, [dispatch]);

  const handleShowDisableModal = useCallback((): void => {
    setShowDisableModal(true);
  }, []);

  return (
    <div className="mt-6">
      <Card>
        <CardTitle title={text.Referrals} description={<ReferralLink code={referrer.code} />} />
        <div className="flex items-center ml-auto gap-3">
          <p className="text-gray">Your referrer code</p>
          <div className="text-blue border border-blue rounded py-2 px-3">
            <h4>{referrer.code}</h4>
          </div>
        </div>
      </Card>

      <Card orientation="vertical">
        <CardTitle title={text.Preferences} />
        <div className="relative mt-2">
          {permissions.map((permission: UserPermission): React.ReactElement => {
            return (
              <div key={permission.code} className="relative hover:bg-gray-100 rounded py-3 -mx-3">
                <div className="flex justify-between items-center px-3">
                  <div className="w-1/3">
                    <h4>{permission.name}</h4>
                    <p className="text-gray">{permission.description}</p>
                  </div>
                  <PermissionToggle permission={permission} onChange={handleUpdatePermission} />
                </div>

                <SpinnerLoader visible={permissionCodeBeingUpdated === permission.code} />
              </div>
            );
          })}
        </div>
      </Card>

      <Card>
        <CardTitle title={text.ChangePassword} description={text.PasswordRules} />
        <div className="cursor-pointer text-blue ml-auto">
          <h4 onClick={handleShowPasswordModal}>{text.ChangePassword}</h4>
        </div>
      </Card>

      <Card>
        <CardTitle title={text.DisableAccount} description={text.DisableAccountWarning} />

        <div className="cursor-pointer text-red-500 ml-auto">
          <h4 onClick={handleShowDisableModal}>{text.DisableAccount}</h4>
        </div>
      </Card>

      <Card>
        <CardTitle title={text.DeleteAccount} description={text.DeleteAccountWarning} />
        <div
          className="bg-red-500 py-3 px-5 rounded cursor-pointer ml-auto"
          onClick={deleteAccountModal.open}
        >
          <h4 className="text-white">Delete Account</h4>
        </div>
      </Card>

      <Modal isOpen={showPasswordModal} variant="lighter" onClose={handleClosePasswordModal}>
        <Modal.Content>
          <ChangePasswordModal onClose={handleClosePasswordModal} />
        </Modal.Content>
      </Modal>

      <Modal isOpen={showDisableModal} onClose={closeDisableModal}>
        <Modal.Content title={text.DisableAccount}>
          <DeleteModal
            text={text.DisableAccountWarning}
            onCancel={closeDisableModal}
            onAccept={() => handleDisableAccount()}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={deleteAccountModal.isOpen} onClose={deleteAccountModal.close}>
        <Modal.Content title="Delete your account">
          <ConfirmationBox
            message="This operation is irreversible. Data will be erased permanently from our database."
            danger={true}
            onYes={handleDeleteAccount}
            onNo={deleteAccountModal.close}
          />
          <SpinnerLoader visible={deletingAccount} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default Settings;
