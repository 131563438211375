import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  oneClickMemoQuotaExceededErrorSelector,
  oneClickMemoQuotaModalDismissed,
} from 'redux/reducers/companyReducer';

export const DownloadQuotaExceededNoPaymentsModal: React.FC = (): React.ReactElement | null => {
  const downloadQuotaExceededError = useSelector(oneClickMemoQuotaExceededErrorSelector);
  const dispatch = useDispatch<any>();

  const handleQuotaExceededErrorModalClose = useCallback((): void => {
    dispatch(oneClickMemoQuotaModalDismissed());
  }, [dispatch]);

  if (!downloadQuotaExceededError) {
    return null;
  }

  return (
    <Modal isOpen={true} onClose={handleQuotaExceededErrorModalClose}>
      <Modal.Content>
        <div className="relative w-modal-sm">
          <h1>Download Limit Reached</h1>
          <p className="py-3">
            You have reached your download limit for this month. Please wait until next month and
            your download limit will be renewed.
          </p>
          <p>
            Or send an email to{' '}
            <a href="mailto:team@capnote.com" className="text-blue">
              team@capnote.com
            </a>{' '}
            requesting an increase
          </p>
          <div className="flex items-center justify-end gap-3 pt-8 px-4">
            <Button
              type="button"
              variant="secondary"
              label="Cancel"
              onClick={handleQuotaExceededErrorModalClose}
            />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
