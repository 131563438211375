import { NumericInput } from 'components/numericInput';
import { TimestampDateInput } from 'components/timestampDateInput';
import React from 'react';
import { PortfolioCompanyInvestmentBondsOrDebtItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyInvestmentBondsOrDebtItem;
}

export const InvestmentBondsOrDebtItemForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCompanyInvestmentBondsOrDebtItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <TimestampDateInput
        name="purchaseTimestamp"
        label="Purchase Date"
        value={item.purchaseTimestamp}
        onChange={updaterFor('purchaseTimestamp')}
      />
      <TimestampDateInput
        name="matirutyTimestamp"
        label="Maturity Date"
        value={item.maturityTimestamp}
        onChange={updaterFor('maturityTimestamp')}
      />

      <div className="col-span-2">
        <NumericInput
          name="annualCouponRateOrInterestRateCharged"
          label="Annual coupon rate or interest rate charged"
          value={item.annualCouponRateOrInterestRateCharged}
          onValueChange={updaterFor('annualCouponRateOrInterestRateCharged')}
        />
      </div>
      <NumericInput
        name="bondOrDebtPrincipalAmount"
        label="Bond or debt principal amount"
        value={item.bondOrDebtPrincipalAmount}
        onValueChange={updaterFor('bondOrDebtPrincipalAmount')}
      />
      <NumericInput
        name="averagePurchasePriceOfBond"
        label="Average purchase price of bond"
        value={item.averagePurchasePriceOfBond}
        onValueChange={updaterFor('averagePurchasePriceOfBond')}
      />
    </div>
  );
};
