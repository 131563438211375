import React from 'react';

export enum ColumnType {
  text = 'text',
  numeric = 'numeric',
  percent = 'percent',
  date = 'date',
  // This column type happens in a bunch of places
  level = 'level',
  // Always leave room for extensions
  custom = 'custom',
}

export enum Level {
  veryLow = 'Very Low',
  low = 'Low',
  average = 'Average',
  medium = 'Mid',
  high = 'High',
  veryHigh = 'Very High',
}

export enum Alignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

export interface ColumnDefinitionBase<T> {
  readonly type: ColumnType;
  readonly name: keyof T;
  readonly label: string;
  readonly description?: string | React.ReactElement;
  readonly sortable?: boolean;
  readonly width?: number;
  readonly weight?: number;
  readonly span?: number;
}

export interface TextColumn<T> extends ColumnDefinitionBase<T> {
  readonly type: ColumnType.text;
  readonly alignment?: Alignment;
  readonly transform?: 'uppercase';
}

export interface DateColumn<T> extends ColumnDefinitionBase<T> {
  readonly type: ColumnType.date;
  readonly alignment?: Alignment;
}

export interface NumericColumn<T> extends ColumnDefinitionBase<T> {
  readonly type: ColumnType.numeric | ColumnType.percent;
  readonly precision: number;
  readonly alignment?: Alignment;
}

export interface CustomColumn<T> extends ColumnDefinitionBase<T> {
  readonly type: ColumnType.custom;
  readonly headerAlignment?: Alignment;

  render(value: T[keyof T] | null, item: T): React.ReactNode;
}

export interface LevelColumn<T> extends ColumnDefinitionBase<T> {
  readonly type: ColumnType.level;
}

export type ColumnDefinition<T> =
  | TextColumn<T>
  | NumericColumn<T>
  | LevelColumn<T>
  | DateColumn<T>
  | CustomColumn<T>;

export interface Identifiable {
  readonly id: string;
  readonly key?: string;
}

export interface ChildrenRows<R> {
  readonly rows: readonly R[];
  readonly columns: ReadonlyArray<ColumnDefinition<R>>;
}
