import { CommonFilter } from 'components/filters/helpers';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const filters: readonly CommonFilter[] = [
  {
    name: 'group',
    type: 'backend',
    url: `${API_V1_PATH}/filters/indicators/groups`,
    label: 'Indicator Group',
  },
  {
    name: 'name',
    type: 'backend',
    url: `${API_V1_PATH}/filters/indicators/names`,
    label: 'Indicator Name',
  },
  {
    name: 'level',
    type: 'backend',
    url: `${API_V1_PATH}/filters/indicators/levels`,
    label: 'Current Level',
  },
];

export default filters;
