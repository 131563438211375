import SpinnerLoader from 'components/spinnerLoader';
import React, { useMemo } from 'react';
import {
  Statement,
  StatementGroup,
  StatementPeriod,
  StatementRow,
  StatementValue,
} from 'types/financial';
import { OrganizationDetails } from 'types/organization/types';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { StatementGroupView } from 'views/DataAnalysis/Company/Details/Financials/statementGroupView';
import StatementTableHeader from 'views/DataAnalysis/Company/Details/Financials/statementTableHeader';
import { StatementTableHeaderLegend } from 'views/DataAnalysis/Company/Details/Financials/statementTableHeaderLegend';

interface Props {
  readonly statement: Statement;
  // @deprecated: no longer used
  readonly organization: OrganizationDetails;
  readonly loading: boolean;
  readonly convertedToUSD?: boolean;

  onPeriodChange(period: StatementPeriod): void;
}

const StatementView: React.FC<Props> = ({
  statement,
  loading,
  convertedToUSD = false,
  onPeriodChange,
}: Props): React.ReactElement => {
  const { groups, notes, periods, previous_period, next_period } = statement;

  const largestNumber = useMemo((): number => {
    const combinedValues = groups.reduce((periods: number[], item: StatementGroup): number[] => {
      const { rows } = item;

      return [
        ...periods,
        ...rows.reduce((values: number[], r: StatementRow): number[] => {
          return [
            ...values,
            ...Object.values(r.values).map((v: StatementValue): number => v.value ?? -1),
          ];
        }, []),
      ];
    }, []);

    return Math.max(...combinedValues);
  }, [groups]);

  if (loading) {
    return (
      <div className="flex-grow relative">
        <SpinnerLoader visible={true} />
      </div>
    );
  } else {
    return (
      <div className="flex-1 flex flex-col font-poppins">
        <div className="flex-1 relative">
          <div className="grid grid-cols-2 py-2 items-center">
            <StatementTableHeaderLegend
              largestNumber={largestNumber}
              convertedToUSD={convertedToUSD}
            />
            <StatementTableHeader
              periods={periods}
              previousPeriod={previous_period}
              nextPeriod={next_period}
              onChange={onPeriodChange}
            />
          </div>
          <div>
            {groups.map(
              (group: StatementGroup): React.ReactElement => (
                <StatementGroupView
                  key={group.key}
                  group={group}
                  periods={periods}
                  largestValue={largestNumber}
                />
              ),
            )}
          </div>

          {notes.length > 0 && (
            <div className="border-gray-light border-t mt-2 pt-2 first:mt-0">
              <div className="py-1 grid grid-cols-4 justify-center items-center">
                <div className="text-base text-black h-6 self-start">Notes</div>
                <ul
                  className="text-base text-gray px-6 -ml-6"
                  style={{ gridColumn: 'span 3/span 3' }}
                >
                  {notes.map(
                    (note: string): React.ReactElement => (
                      <li key={note} className="list-disc p-2">
                        {note}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>

        <DataDisclaimer />
      </div>
    );
  }
};

export default StatementView;
