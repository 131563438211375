import { Checkbox } from 'components/checkbox';
import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly mode: 'select' | 'expand';
  readonly value: boolean;

  onStateChange(value: boolean): void;
}

export const StateButton: React.FC<Props> = ({
  mode,
  value,
  onStateChange,
}: Props): React.ReactElement => {
  const currentIcon: SvgIconName = useMemo(
    (): SvgIconName => (value ? 'delete-stream-icon' : 'show-more'),
    [value],
  );

  const handleClick = useCallback((): void => {
    onStateChange(!value);
  }, [onStateChange, value]);

  if (mode === 'select') {
    return <Checkbox checked={value} size="large" onChange={onStateChange} />;
  } else {
    return (
      <SVGIcon
        name={currentIcon}
        className="w-4 w-4 cursor-pointer hover:opacity-60"
        onClick={handleClick}
      />
    );
  }
};
