import { ActionButton } from 'components/header/UserHeader/components/NotificationItemView/components/actionButton';
import { DateText } from 'components/header/UserHeader/components/NotificationItemView/components/dateText';
import { DescriptionText } from 'components/header/UserHeader/components/NotificationItemView/components/descriptionText';
import { Icon } from 'components/header/UserHeader/components/NotificationItemView/components/icon';
import { ReadIndicator } from 'components/header/UserHeader/components/NotificationItemView/components/readIndicator';
import React from 'react';
import { Notification } from 'redux/reducers/notificationsReducer';

interface Props {
  readonly notification: Notification;
  readonly variant: 'bell' | 'right-panel';
}

export const NotificationItemView: React.FC<Props> = ({
  notification,
  variant,
}: Props): React.ReactElement => {
  const { date, type, description, source, seen, metadata } = notification;

  return (
    <div className="flex py-2 pr-4 hover:bg-gray-200 cursor-pointer w-full duration-300 normal-case">
      {variant === 'bell' && <ReadIndicator seen={seen} />}
      {variant === 'right-panel' && <div className="w-2" />}
      <Icon notificationType={type} source={source} />

      <div className="sm:px-2 flex items-center justify-between w-full truncate">
        <div className="font-poppins min-w-0 flex-grow-0 mr-4">
          <DescriptionText description={description} metadata={metadata} />
          <DateText notificationType={type} source={source} date={date} />
        </div>

        {variant === 'bell' && <ActionButton metadata={metadata} />}
      </div>
    </div>
  );
};
