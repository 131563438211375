import { Tabs } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  financialStatementsOptionsSelector,
  setStatementPeriod,
} from 'redux/reducers/financialStatementsReducer';
import { StatementPeriod } from 'types/financial';
import BalanceSheet from 'views/DataAnalysis/Company/Details/Financials/balanceSheet';
import CashFlow from 'views/DataAnalysis/Company/Details/Financials/cashFlowStatement';
import Highlights from 'views/DataAnalysis/Company/Details/Financials/highlights';
import IncomeStatement from 'views/DataAnalysis/Company/Details/Financials/incomeStatement';
import MetricsAndIndicators from 'views/DataAnalysis/Company/Details/Financials/metricsAndIndicators';

export const FinancialStatementTabs: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const options = useSelector(financialStatementsOptionsSelector);

  const handlePeriodChange = useCallback(
    (period: StatementPeriod): void => {
      dispatch(setStatementPeriod(period));
    },
    [dispatch],
  );

  return (
    <Tabs>
      <Tab
        label="Financial Highlights"
        element={<Highlights options={options} onPeriodChange={handlePeriodChange} />}
      />
      <Tab
        label="Income Statement"
        element={<IncomeStatement options={options} onPeriodChange={handlePeriodChange} />}
        path="income"
      />
      <Tab
        label="Cash Flow Statement"
        element={<CashFlow options={options} onPeriodChange={handlePeriodChange} />}
        path="cash-flow"
      />
      <Tab
        label="Balance Sheet"
        element={<BalanceSheet options={options} onPeriodChange={handlePeriodChange} />}
        path="balance-sheet"
      />
      <Tab
        label="Financial Metrics & Indicators"
        element={<MetricsAndIndicators options={options} onPeriodChange={handlePeriodChange} />}
        path="metrics"
      />
    </Tabs>
  );
};
