import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ReportableType {
  Posts = 'posts',
  Comments = 'feed-comments',
  News = 'news',
}

export interface ReportReason {
  readonly id: number;
  readonly label: string;
  readonly description: string;
}

export enum ProcessingState {
  none,
  loadingReasons,
  submitting,
  loadingError,
  submittingError,
  completed,
}

export interface ReportsState {
  reasons: readonly ReportReason[];
  processingState: ProcessingState;
  errorMessage: string | null;
}

const initialState: ReportsState = {
  reasons: [],
  processingState: ProcessingState.none,
  errorMessage: null,
};

const slice = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    getReportReasonsStarted: (state: ReportsState): void => {
      state.processingState = ProcessingState.loadingReasons;
      state.errorMessage = null;
    },
    getReportReasonsCompleted: (
      state: ReportsState,
      { payload }: PayloadAction<readonly ReportReason[]>,
    ): void => {
      state.processingState = ProcessingState.none;
      state.reasons = payload;
    },
    getReportReasonsFailed: (state: ReportsState, { payload }: PayloadAction<string>): void => {
      state.processingState = ProcessingState.loadingError;
      state.errorMessage = payload;
    },
    submitReportStarted: (state: ReportsState): void => {
      state.processingState = ProcessingState.submitting;
      state.errorMessage = null;
    },
    submitReportCompleted: (state: ReportsState): void => {
      state.processingState = ProcessingState.completed;
    },
    submitReportFailed: (state: ReportsState, { payload }: PayloadAction<string>): void => {
      state.processingState = ProcessingState.submittingError;
      state.errorMessage = payload;
    },
    reset: (state: ReportsState): void => {
      state.reasons = [];
      state.processingState = ProcessingState.none;
      state.errorMessage = null;
    },
  },
});

export const reportReasonsSelector = (state: { reports: ReportsState }): readonly ReportReason[] =>
  state.reports.reasons;

export const processingStateSelector = (state: { reports: ReportsState }): ProcessingState =>
  state.reports.processingState;

export const errorMessageSelector = (state: { reports: ReportsState }): string | null =>
  state.reports.errorMessage;

export const {
  getReportReasonsStarted,
  getReportReasonsCompleted,
  getReportReasonsFailed,
  submitReportStarted,
  submitReportCompleted,
  submitReportFailed,
  reset,
} = slice.actions;

export default slice.reducer;
