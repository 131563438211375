import { WithID } from 'types/withId';
import { MarketplaceItemDetails } from 'views/EarnAndBuy/api';

export enum ActionType {
  setValue,
  initialize,
  reset,
}

export interface CreateMarketplaceItemPayload {
  readonly title: string;
  readonly description: string;
  readonly tags: readonly string[];
  readonly price_amount: number | null;
  readonly price_currency: string;
  readonly content_uri: string;
  readonly category: number | null;
}

export type UpdateMarketplaceItemPayload = WithID<CreateMarketplaceItemPayload>;

interface ActionBase {
  readonly type: ActionType;
  readonly data?: any;
}

export interface SetValueAction extends ActionBase {
  readonly type: ActionType.setValue;
  readonly data: {
    readonly name: keyof CreateMarketplaceItemPayload;
    readonly value: CreateMarketplaceItemPayload[keyof CreateMarketplaceItemPayload];
  };
}

export interface ResetAction extends ActionBase {
  readonly type: ActionType.reset;
}

export interface InitializeAction extends ActionBase {
  readonly type: ActionType.initialize;
  readonly data: MarketplaceItemDetails;
}

export type Action = SetValueAction | ResetAction | InitializeAction;

export const initialState: CreateMarketplaceItemPayload = {
  title: '',
  description: '',
  tags: [],
  price_amount: null,
  price_currency: 'USD',
  category: null,
  content_uri: '',
};

export const reducer = (
  state: CreateMarketplaceItemPayload,
  action: Action,
): CreateMarketplaceItemPayload => {
  const { type, data } = action;

  switch (type) {
    case ActionType.setValue:
      return { ...state, [data.name]: data.value };
    case ActionType.reset:
      return initialState;
    case ActionType.initialize:
      return {
        title: data.title,
        description: data.description,
        tags: data.tags,
        price_amount: data.price_amount,
        price_currency: data.price_currency,
        category: data.category.id,
        content_uri: data.content_uri,
      };
    default:
      return state;
  }
};
