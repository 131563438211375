import Button from 'components/buttons/Button/Button';
import parse from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';
import texts from 'views/Landing/LandingPage/components/AboutUs/texts';

const AboutUs: React.FC<any> = () => {
  const { title, subtitle, paragraph, signUpLink } = texts;

  return (
    <div className="w-full text-center">
      <div className="mx-auto w-11/12 4xl:px-8">
        <h1 className="text-2xl md:text-4xl mb-6 text-gray-darkest">{title}</h1>
        <h3 className="text-md md:text-xl text-blue mb-6">{subtitle}</h3>
        <div className="flex flex-col lg:flex-row mb-8 lg:px-48">
          <p className="text-base md:text-md mb-5 text-gray-darkest">{parse(paragraph)}</p>
        </div>
        <div className="flex justify-center">
          <Link className="w-11/12 md:w-4/12" to="/sign-up">
            <Button rounded="rounded-sm" label={signUpLink} width="w-full" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
