import axios from 'axios';
import { Input } from 'components/forms/input';
import { TagsInput } from 'components/forms/tagsInput';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PeerGroup } from 'redux/reducers/userPeerGroupsReducer';
import {
  CreatePeerGroupPayload,
  createUserPeerGroupAction,
  PeerCompany,
  updateUserPeerGroupAction,
} from 'redux/services/userPeerGroupsService';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { CompaniesInput } from 'views/SmartTools/PeerGroups/tools/companiesInput';

interface Props {
  readonly peerGroup?: PeerGroup;

  onFetchStart?(): void;
  onFetchEnd?(): void;
}

type FormError = {
  -readonly [key in keyof Partial<Pick<CreatePeerGroupPayload, 'name' | 'companies'>>]?: string;
};

export const AddPeerGroupModal: React.FC<Props> = ({
  peerGroup,
  onFetchStart,
  onFetchEnd,
}: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const [errors, setErrors] = useState<FormError>({});
  const [companies, setCompanies] = useState<readonly PeerCompany[]>([]);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [tags, setTags] = useState<readonly string[]>([]);

  useEffect((): void | VoidFunction => {
    const cancelToken = axios.CancelToken.source();
    const fetch = async (peerGroupId: string): Promise<void> => {
      try {
        onFetchStart?.();

        const response = await api.get(`${API_V1_PATH}/smart-tools/peer-groups/${peerGroupId}`, {
          cancelToken: cancelToken.token,
        });
        const { comparisons, name, description, tags } = response.data;
        const { rows: comparisonsRows } = comparisons;

        const companies = comparisonsRows.map(
          (row: Record<string, any>, index: number): PeerCompany => {
            const { company } = row;
            return {
              id: company.id,
              name: company.name,
              ticker: company.ticker,
              focus: index === 0,
            };
          },
        );

        setCompanies(companies);
        setName(name);
        setDescription(description);
        setTags(tags);
      } catch (error: any) {
        console.warn(error);
      } finally {
        onFetchEnd?.();
      }
    };

    if (peerGroup?.id) {
      void fetch(peerGroup.id);
    }

    return (): void => {
      cancelToken.cancel();
    };
  }, [onFetchEnd, onFetchStart, peerGroup?.id]);

  const validate = useCallback((): void => {
    const errors: FormError = {};

    const trimmedName = name.trim();
    if (trimmedName.length < 3) {
      errors.name = 'Name must be at least 3 characters long.';
    }

    if (companies.length < 2 || companies.length > 10) {
      errors.companies = 'Peer group must have at least 2 companies.';
    }
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      throw new Error('form is invalid');
    }
  }, [companies.length, name]);

  const handleSubmit = useCallback((): void => {
    try {
      validate();

      const payload: CreatePeerGroupPayload = {
        name: name,
        description: description,
        companies: companies.map(
          (company: PeerCompany): Pick<PeerCompany, 'id' | 'focus'> => ({
            id: company.id,
            focus: company.focus,
          }),
        ),
        tags: tags,
      };

      if (peerGroup?.id) {
        dispatch(updateUserPeerGroupAction(peerGroup?.id, payload));
      } else {
        dispatch(createUserPeerGroupAction(payload));
      }
    } catch {
      // Intentionally do nothing
    }
  }, [companies, description, dispatch, name, peerGroup?.id, tags, validate]);

  return (
    <div className="relative w-modal-sm">
      <form autoComplete="off" className="flex flex-col gap-4 mt-6" onSubmit={ignore}>
        <div className="mt-3">
          <Input
            id="add-peer-group-name"
            name="name"
            label="Name"
            value={name}
            onChange={setName}
          />
          {!!errors.name && <p className="font-poppinsMedium text-red text-xs">{errors.name}</p>}
        </div>
        <div>
          <Input
            id="add-peer-group-description"
            name="description"
            label="Description (Optional)"
            value={description}
            onChange={setDescription}
          />
        </div>
        <TagsInput id="add-peer-group-tags" value={tags} name="tags" onChange={setTags} />

        <CompaniesInput
          id="add-peer-group-companies"
          name="companies"
          value={companies}
          error={errors.companies}
          onChange={setCompanies}
        />

        <div className="flex items-center justify-end">
          <button
            className="bg-blue text-white h-12 rounded font-poppinsMedium text-md w-20"
            onClick={handleSubmit}
          >
            {peerGroup?.id ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
};

const ignore = (event: React.FormEvent<HTMLFormElement>): void => {
  event.preventDefault();
};
