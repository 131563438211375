import {
  emptyInvestmentSharesAndEquityPublicCompanyItem,
  Nullable,
  PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem,
  PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem,
  PortfolioCompanyClientOrCustomerForeignExchangeItem,
  PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem,
  PortfolioCompanyClientOrCustomerItem,
  PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem,
  PortfolioCompanyClientOrCustomerOtherItem,
  PortfolioCompanyEmployerItem,
  PortfolioCompanyInvestmentBondsOrDebtItem,
  PortfolioCompanyInvestmentOtherItem,
  PortfolioCompanyInvestmentSharesOrEquityItem,
  PortfolioCompanyInvestmentType,
  PortfolioCompanyOtherItem,
  PortfolioCompanyPartnerItem,
  PortfolioCompanyProductType,
  PortfolioCompanyRelationshipType,
  PortfolioCompanySupplierItem,
  PortfolioETFItem,
  PortfolioItemType,
} from 'types/portfolioItem';
import {
  PortfolioItemETFSearchResult,
  PortfolioItemPrivateCompanySearchResult,
  PortfolioItemPublicCompanySearchResult,
} from 'views/Portfolios/PortfoliosModal/api';

export const createCompanyPartnerItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyPartnerItem> => {
  const currency =
    searchResult.type === PortfolioItemType.privateCompany ? 'USD' : searchResult.data.currency;

  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.partner,
    primaryCurrency: currency,
    annualMonetaryIncomeFromPartner: null,
    majorityOrMinority: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: null,
    currency: null,
    productTypeDescription: null,
    annualMonetaryIncomeFromClient: null,
    valueHistory: [],
  };
};

export const createCompanyInvestmentSharesOrEquityItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyInvestmentSharesOrEquityItem> => {
  if (searchResult.type === PortfolioItemType.privateCompany) {
    return {
      ...emptyInvestmentSharesAndEquityPublicCompanyItem,

      name: searchResult.name,
      type: searchResult.type,
      currency: 'USD',
      averagePricePerShare: null,
    };
  }
  const { price, currency } = searchResult.data;

  return {
    ...emptyInvestmentSharesAndEquityPublicCompanyItem,

    name: searchResult.name,
    type: searchResult.type,
    companyId: searchResult.id,
    currency: currency,
    averagePricePerShare: price,
  };
};

export const createCompanyInvestmentOtherItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyInvestmentOtherItem> => {
  return {
    id: null,
    name: searchResult.name,
    type: searchResult.type,
    companyId: searchResult.id,
    relationshipType: PortfolioCompanyRelationshipType.investment,
    investmentType: PortfolioCompanyInvestmentType.other,
    investmentTypeDescription: null,
    purchaseTimestamp: null,
    expectedAnnualReturnPercent: null,
    expectedHoldingPeriodMonths: null,
    valueHistory: [],
  };
};

export const createCompanyInvestmentBondsOrDebtItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyInvestmentBondsOrDebtItem> => {
  return {
    ...emptyInvestmentSharesAndEquityPublicCompanyItem,

    name: searchResult.name,
    type: searchResult.type,
    companyId: searchResult.id,
    investmentType: PortfolioCompanyInvestmentType.bondsOrDebt,

    purchaseTimestamp: null,
    maturityTimestamp: null,
    annualCouponRateOrInterestRateCharged: null,
    bondOrDebtPrincipalAmount: null,
    averagePurchasePriceOfBond: null,
  };
};

export const createCompanySupplierItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanySupplierItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.supplier,
    productsOrServicesSupplied: null,
    currency: 'USD',
    annualAmountPaidToSupplier: null,
    valueHistory: [],
  };
};

export const createCompanyEmployerItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyEmployerItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.employer,
    receiveStockOptions: null,
    valueHistory: [],
  };
};

export const createCompanyOtherItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyOtherItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.other,
    description: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerAdvisoryOrConsultingItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: PortfolioCompanyProductType.advisoryOrConsulting,
    advisoryOrConsultingType: null,
    currency: 'USD',
    annualMonetaryIncomeFromClient: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerCashManagementOrDepositItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: PortfolioCompanyProductType.cashManagementOrDeposits,
    currency: 'USD',
    annualMonetaryIncomeFromClient: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerForeignExchangeItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerForeignExchangeItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: PortfolioCompanyProductType.foreignExchange,
    currenciesOfInterest: [],
    currency: 'USD',
    annualMonetaryIncomeFromClient: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerHedgingOrRiskManagementItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: PortfolioCompanyProductType.hedgingOrRiskManagement,
    subjects: [],
    currency: 'USD',
    annualMonetaryIncomeFromClient: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerLoanOrDebtRelatedItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: PortfolioCompanyProductType.loansOrDebtRelated,
    insuranceTimestamp: Date.now(),
    maturityTimestamp: null,
    principalAmount: null,
    currency: 'USD',
    annualInterestRateCharged: null,
    valueHistory: [],
  };
};

export const createCompanyClientOrCustomerOtherItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult | PortfolioItemPrivateCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerOtherItem> => {
  return {
    id: null,
    companyId: searchResult.id,
    name: searchResult.name,
    type: searchResult.type,
    relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
    productType: PortfolioCompanyProductType.other,
    productTypeDescription: null,
    currency: 'USD',
    annualMonetaryIncomeFromClient: null,
    valueHistory: [],
  };
};

export const createETFItemFromSearchResult = (
  item: PortfolioItemETFSearchResult,
): PortfolioETFItem => {
  return {
    id: '',
    name: item.name,
    type: PortfolioItemType.etf,
    averagePricePerShareAmount: 0,
    averagePricePerShareCurrency: 'USD',
    numberOfShares: 0,
    totalAmount: 0,
    valueHistory: [],
  };
};
