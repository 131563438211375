import { MarkerDot } from 'components/capnoteMap/components/markerDot';
import { Tooltip } from 'components/tooltip';
import { DivIcon, divIcon } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Marker } from 'react-leaflet';
import { PortfoliosMapLocation, PortfoliosRevenuesMapItem } from 'redux/reducers/portfoliosReducer';
import { getLegendForFinancialStatements } from 'utils';
import VALUES_FORMATTERS from 'views/DataAnalysis/valuesFormatters';

interface Props {
  readonly marker: PortfoliosMapLocation<PortfoliosRevenuesMapItem>;
}

export const PortfoliosRevenuesMarker: React.FC<Props> = ({
  marker,
}: Props): React.ReactElement => {
  const [icon, setIcon] = useState<DivIcon>(new DivIcon());
  const { coordinates, portfolios } = marker;

  const maximum = React.useMemo(
    (): number =>
      portfolios.reduce((max: number, item: PortfoliosRevenuesMapItem): number => {
        return max > item.revenues ? max : item.revenues;
      }, 0),
    [portfolios],
  );

  useEffect(
    (): void =>
      setIcon(
        divIcon({
          html: createDot(marker, maximum),
        }),
      ),
    [marker, maximum],
  );

  return <Marker position={[coordinates.latitude, coordinates.longitude]} icon={icon} />;
};

const createDot = (
  marker: PortfoliosMapLocation<PortfoliosRevenuesMapItem>,
  maximum: number,
): HTMLElement => {
  const { portfolios } = marker;

  const element = document.createElement('div');
  const root = createRoot(element);

  root.render(
    <Tooltip
      content={
        <div>
          <h4>
            <span>{marker.region}</span>
          </h4>
          <ul className="my-2">
            {portfolios.map(
              (portfolio: PortfoliosRevenuesMapItem): React.ReactElement => (
                <li key={portfolio.id} className="flex items-center justify-between gap-5 py-1">
                  <div>
                    <p className="text-gray-darkest">{portfolio.name}</p>
                    <p className="text-gray-dark text-xs">
                      {VALUES_FORMATTERS['percentage'](portfolio.percentage_of_total)} of portfolio
                    </p>
                  </div>
                  <p className="font-poppinsSemiBold text-green-700">
                    <span>${VALUES_FORMATTERS['currency'](portfolio.revenues, maximum)}</span>
                  </p>
                </li>
              ),
            )}
          </ul>
          <p className="text-gray text-xxs whitespace-nowrap">
            {getLegendForFinancialStatements(maximum)}
          </p>
        </div>
      }
      variant="light"
      fitContent={true}
    >
      <MarkerDot size="normal" color="green" />
    </Tooltip>,
  );

  return element;
};
