import { Clickable } from 'components/clickable';
import { Tag } from 'components/tag';
import { capitalize } from 'lodash';
import React, { useCallback } from 'react';

interface Props {
  readonly tags: readonly string[];
  readonly expanded: boolean;

  onExpand(): void;
  onClick?(tag: string): void;
}

const TagsList: React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLDivElement>> =
  React.forwardRef<HTMLDivElement, Props>(
    (
      { tags, expanded, onExpand, onClick }: Props,
      ref: React.Ref<HTMLDivElement>,
    ): React.ReactElement => {
      const handleClick = useCallback((tag: string): void => onClick?.(tag), [onClick]);

      if (expanded) {
        return (
          <div className="flex flex-wrap gap-y-1.5 items-center">
            {tags.map((tag: string) => (
              <div key={tag} className="w-max inline-flex mr-3">
                <Clickable clickData={tag} onClick={handleClick}>
                  <Tag>{capitalize(tag)}</Tag>
                </Clickable>
              </div>
            ))}
            <div className="w-max inline-flex mr-3">
              <Clickable onClick={onExpand}>
                <Tag>
                  <h6>-</h6>
                </Tag>
              </Clickable>
            </div>
          </div>
        );
      } else {
        return (
          <div ref={ref} className="flex flex-wrap gap-y-1.5 items-center">
            {tags.map((tag: string) => (
              <Clickable key={tag} clickData={tag} onClick={handleClick}>
                <div className="w-max inline-flex mr-3" data-tag={tag}>
                  <Tag>{capitalize(tag)}</Tag>
                </div>
              </Clickable>
            ))}
          </div>
        );
      }
    },
  );

TagsList.displayName = 'TagList';

export { TagsList };
