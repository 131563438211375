import { Member, MemberPayload } from 'sharable/types';
import { AccessMode } from 'types/accessMode';
import { Time } from 'types/time';

// FIXME: these are more UI types, although the others make no sense without the UI
export enum TimelineLayoutType {
  year = 'year',
  month = 'month',
  week = 'week',
  day = 'day',
}

export enum TimelineViewType {
  calendar = 'calendar',
  line = 'lines',
  chart = 'chart',
}

export interface Author {
  id: string;
  first_name: string;
  last_name: string;
}

export interface Portfolio {
  name: string;
  author: Author;
}

export interface Company {
  name: string;
}

interface BaseEventDetails {
  readonly id: string;
  readonly title: string;
  readonly summary: string;
  readonly creator: Author;
  readonly detail: string;
  readonly urgency: string | null;
  readonly importance: string | null;
  readonly tags: readonly string[];
  readonly linked_events: readonly string[];
  readonly country: string;
  readonly stream_id: string;
  readonly description: string;
  readonly timezone: string;
}

type FormBaseEventDetails = Omit<
  BaseEventDetails,
  'members' | 'linked_events' | 'id' | 'title' | 'creator' | 'country' | 'detail'
>;

export interface CreateEventPayload extends FormBaseEventDetails {
  start_date: string | null;
  start_time: string | null;
  end_date: string | null;
  end_time: string | null;
  reminder: string | null;
  members: MemberPayload[];
  linked_events: string[] | undefined;
  country_id: number | undefined;
}

export interface EventDetails extends BaseEventDetails {
  readonly access_mode: AccessMode;
  readonly startDate: string;
  readonly endDate: string | null;
  readonly startTime: Time | null;
  readonly endTime: Time | null;
  readonly reminder: string;
  readonly members: Member[];
}

export interface StreamEvent {
  readonly id: string;
  readonly summary: string;
  readonly start_date: string;
  readonly start_time: string;
  readonly stream_id: string;
  readonly importance: string | null;
  readonly timezone: string;
}

export interface YearStreamFilters {
  readonly year: number;
}

export interface MonthStreamFilters extends YearStreamFilters {
  readonly month: number;
}

export interface WeekStreamFilters extends YearStreamFilters {
  readonly week_init_day: number;
  readonly month: number;
}

export interface DayStreamFilters extends MonthStreamFilters {
  readonly day: number;
}

export type TimelineFilters =
  | YearStreamFilters
  | MonthStreamFilters
  | WeekStreamFilters
  | DayStreamFilters;

export const isYearStreamFilters = (filters: TimelineFilters): filters is YearStreamFilters => {
  return !('month' in filters) && !('day' in filters) && !('week_init_day' in filters);
};

export const isMonthStreamFilters = (filters: TimelineFilters): filters is MonthStreamFilters => {
  return !('day' in filters) && !('week_init_day' in filters) && 'month' in filters;
};

export const isWeekStreamFilters = (filters: TimelineFilters): filters is WeekStreamFilters => {
  return 'week_init_day' in filters;
};

export const isDayStreamFilters = (filters: TimelineFilters): filters is DayStreamFilters => {
  return 'day' in filters;
};

export interface Coordinates {
  x: number;
  y: number;
  id: string;
}

export interface Event {
  readonly id: string;
  readonly title: string;
  readonly summary: string;
  readonly date_time: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly startTime: Time;
  readonly endTime: Time;
  readonly creator: Author;
  readonly detail: string;
  readonly urgency: string | null;
  readonly importance: string | null;
  readonly tags: string[];
  readonly linked_events: string[];
  readonly members: User[];
  readonly country: string;
  readonly stream_id: string;
  readonly description: string;
  readonly reminder: Date;
  readonly timezone: string;
}

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  first_name?: string;
  last_name?: string;
  email: string;
  account_type?: string;
  create_at?: string;
}

export interface Stream {
  id: string;
  name: string;
  category: string;
  author: string;
  events: StreamEvent[];
  summary?: string;
  members_count: number;
  tags: string[];
  access_mode: AccessMode;
}

const now = new Date();

export const defaultStreamFilters: TimelineFilters = {
  year: now.getFullYear(),
  month: now.getMonth() + 1,
};
