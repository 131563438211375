import InfoIcon from 'assets/icons/svg/info-icon.svg';
import Placeholder from 'assets/images/genderless-icon.svg';
import Button from 'components/buttons/Button/Button';
import Input from 'components/input/Input2';
import { KeyboardListener } from 'components/keyboardListener';
import { PostBodyInput } from 'components/postBodyInput';
import { PostBody, PostBodyInputAPI } from 'components/postBodyInput/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreatePostPayload } from 'redux/reducers/feedReducer';
import { userProfileSelector } from 'redux/reducers/profileReducer';
import { postService } from 'redux/services/feedService';
import { isBeta } from 'utils';
import { FormErrors, isYupError } from 'utils/forms';
import { Title } from 'views/Home/modals/title';
import * as yup from 'yup';

const schema = yup.object().shape({
  title: yup.string().required('Please enter a title for your post'),
  // FIXME: add the validation to the object fields
  body: yup.object().required('Please enter a description of your post'),
});

interface Props {
  onClose(): void;
}

const CreatePostModal: React.FC<Props> = ({ onClose }: Props): React.ReactElement => {
  const [title, setTitle] = useState<string>('');
  const [initialBody] = useState<PostBody>(PostBody.empty());
  const dispatch = useDispatch<any>();
  const userProfile = useSelector(userProfileSelector);
  const { first_name, last_name } = userProfile?.personal_information ?? {};
  const fullName = first_name && last_name ? first_name + ' ' + last_name : '';
  const [errors, setErrors] = useState<FormErrors>({});
  const postBodyInputAPIRef = useRef<PostBodyInputAPI>(new PostBodyInputAPI());

  const handleTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setTitle(value);
  }, []);

  useEffect((): VoidFunction | void => {
    if (Object.keys(errors).length === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setErrors({});
    }, 5000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [errors]);

  const onSubmit = async () => {
    const postBodyInputAPI = postBodyInputAPIRef.current;
    const payload: CreatePostPayload = {
      title: title,
      body: postBodyInputAPI.getContent(),
      tags: postBodyInputAPI.getTags(),
    };

    try {
      setErrors({});

      await schema.validate(payload, { abortEarly: false });

      dispatch(postService(payload));
      onClose();
    } catch (error: any) {
      if (isYupError(error)) {
        setErrors((errors: FormErrors): FormErrors => ({ ...errors, [error.path]: error }));
      }
    }
  };

  const handleGlobalKeyPressed = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): boolean => {
      if (event.key === 'Escape') {
        onClose();

        return true;
      }

      return false;
    },
    [onClose],
  );

  return (
    <KeyboardListener onKeyPressed={handleGlobalKeyPressed}>
      <div className="flex p-6 justify-between bg-white min-w-modal-md">
        <div className="flex flex-col w-full">
          <Title title="Create Post" onClose={onClose} />
          <div className="flex w-full mb-6">
            <img className="w-14 h-14 mr-4" src={Placeholder} alt="photo" />
            <p className="font-jostSemiBold text-lg">{fullName}</p>
          </div>
          <Input
            id="post-title-input"
            name="Post Title"
            placeholder="Post Title"
            type="text"
            onChange={handleTitleChange}
            value={title}
            className="mb-8 relative"
            labelClassName="left-0 text-gray -top-2"
            autoComplete="off"
            autoFocus={true}
            error={errors.title}
          />
          <PostBodyInput
            ref={postBodyInputAPIRef}
            className="font-poppins text-base text-gray mb-8 border-b-2 border-gray-300 overflow-y-auto scroller"
            initialValue={initialBody}
            numberOfLines={8}
            placeholder="What's Happening?"
          />

          {isBeta && (
            <p className="mb-7 font-poppinsItalic text-red">
              Note that in this beta version, all users will receive your posts on their feed
            </p>
          )}
          <div className="flex justify-between items-center">
            <div className="flex space-x-6 items-center">
              <div className="flex items-center">
                <img src={InfoIcon} className="h-4" alt="info" />
                <p className="text-sm text-gray ml-1.5 font-poppins">To add tags type a hashtag</p>
              </div>
            </div>
            <Button
              id="post-button"
              label="Post"
              lettercase="normalcase"
              onClick={onSubmit}
              width="w-24"
              padding="p-1"
              height="h-12"
            />
          </div>
        </div>
      </div>
    </KeyboardListener>
  );
};

export default CreatePostModal;
