import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { followUserAction, unfollowUserAction } from 'redux/actions/connectionsActions';
import { NotificationMetadata, NotificationType } from 'redux/reducers/sharedReducer';
import FollowButton, {
  FollowAction,
  FollowButtonLabels,
} from 'views/Connections/Overview/components/FollowButton';

interface Props {
  readonly metadata?: NotificationMetadata;
}

export const ActionButton: React.FC<Props> = ({ metadata }: Props): React.ReactElement | null => {
  const dispatch = useDispatch<any>();

  const handleFollowRequest = useCallback(
    (action: FollowAction, data: string): void => {
      if (action === FollowAction.follow) {
        dispatch(followUserAction(data));
      } else if (action === FollowAction.unfollow) {
        dispatch(unfollowUserAction(data));
      }
    },
    [dispatch],
  );

  if (isMobile || !metadata) {
    return null;
  }

  switch (metadata.type) {
    case NotificationType.Connections:
      {
        /* FIXME: followed should be read from the backend */
      }

      return (
        <FollowButton
          labels={followButtonLabels}
          followed={metadata.followed}
          clickData={metadata.follower_id}
          compact={true}
          onClick={handleFollowRequest}
        />
      );
    case NotificationType.YouMayKnow:
      {
        /* FIXME: followed should be read from the backend */
      }

      return (
        <FollowButton
          labels={followButtonLabels}
          followed={metadata.followed}
          clickData={metadata.user_id}
          compact={true}
          onClick={handleFollowRequest}
        />
      );
    case NotificationType.Organization:
    case NotificationType.FINVARNotifications:
    case NotificationType.KRF:
    case NotificationType.Account:
    case NotificationType.News:
    case NotificationType.CompanyPriceAlert:
    case NotificationType.PortfoliosNewsAndNotifications:
    case NotificationType.Portfolio:
    case NotificationType.Post:
    case NotificationType.Comment:
    case NotificationType.Event:
      break;
  }

  return null;
};

const followButtonLabels: FollowButtonLabels = {
  follow: 'Follow Back',
  unfollow: 'Following',
};
