import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SubscriptionCheckout } from 'views/Checkout/SubscriptionCheckout';

export const Checkout: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="subscriptions/:planId/:billingCycle" element={<SubscriptionCheckout />} />
    </Routes>
  );
};
