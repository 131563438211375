import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';
import { SelectOption } from 'views/Landing/Onboarding/components/Select';

export enum ConnectionToFinance {
  FinancialProfessional = 'Financial Professional',
  IndividualInvestor = 'Individual Investor',
  CorporateProfessional = 'Corporate Professional',
  Academic = 'Academic',
  Student = 'Student',
  Other = 'Other',
}

export enum CareerLevels {
  Junior = 'Junior',
  MidLevel = 'Mid-Level',
  Senior = 'Senior',
  Executive = 'Executive',
}

export interface AboutFormState {
  readonly aboutMe: string | null;
  readonly country: number | null;
  readonly state: number | null;
  readonly city: string | null;
  readonly accreditedInvestor: boolean;
  readonly connectionToFinance: ConnectionToFinance | null;
  readonly additionalTopicsOfInterest: string[];
  readonly topicsOfInterest: number[];
  readonly regionsOfInterest: number[];
}

export interface ExperienceFormState {
  readonly careerLevel: string | null;
  readonly recentJob: string | null;
  readonly recentCompany: string | null;
  readonly industry: ComboBoxItem | null;
  readonly jobPositionStartDate: string | null;
  readonly jobPositionEndDate: string | null;
  readonly certifications: string[];
}

export interface OnboardingReferenceData {
  readonly countries: ReadonlyArray<SelectOption<number>>;
  readonly regionsOfInterest: ReadonlyArray<ComboBoxItem<number>>;
  readonly topicsOfInterest: ReadonlyArray<ComboBoxItem<number>>;
}

export interface OnboardingState {
  aboutForm: AboutFormState;
  experienceForm: ExperienceFormState;
  initializing: boolean;
  referenceData: OnboardingReferenceData;
}

const initialState: OnboardingState = {
  initializing: true,
  aboutForm: {
    aboutMe: null,
    country: null,
    state: null,
    city: null,
    accreditedInvestor: false,
    connectionToFinance: null,
    additionalTopicsOfInterest: [],
    topicsOfInterest: [],
    regionsOfInterest: [],
  },
  experienceForm: {
    careerLevel: null,
    recentJob: null,
    recentCompany: null,
    industry: null,
    jobPositionStartDate: null,
    jobPositionEndDate: null,
    certifications: [],
  },
  referenceData: {
    regionsOfInterest: [],
    topicsOfInterest: [],
    countries: [],
  },
};

interface FormUpdater {
  readonly key: string;
  readonly value: any;
}

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateAboutFormValue: (
      state: OnboardingState,
      { payload }: PayloadAction<FormUpdater>,
    ): void => {
      state.aboutForm = { ...state.aboutForm, [payload.key]: payload.value };
    },
    updateCountry: (state: OnboardingState, { payload }: PayloadAction<number | null>): void => {
      state.aboutForm = { ...state.aboutForm, country: payload, state: null, city: null };
    },
    updateState: (state: OnboardingState, { payload }: PayloadAction<number | null>): void => {
      state.aboutForm = { ...state.aboutForm, state: payload, city: null };
    },
    updateCity: (state: OnboardingState, { payload }: PayloadAction<string>): void => {
      state.aboutForm = { ...state.aboutForm, city: payload };
    },
    updateAdditionalTopicsOfInterest: (
      state: OnboardingState,
      { payload }: PayloadAction<string[]>,
    ): void => {
      state.aboutForm = { ...state.aboutForm, additionalTopicsOfInterest: payload };
    },
    updateTopicsOfInterest: (
      state: OnboardingState,
      { payload }: PayloadAction<number[]>,
    ): void => {
      state.aboutForm = { ...state.aboutForm, topicsOfInterest: payload };
    },
    updateRegionsOfInterest: (
      state: OnboardingState,
      { payload }: PayloadAction<number[]>,
    ): void => {
      state.aboutForm = { ...state.aboutForm, regionsOfInterest: payload };
    },
    updateExperienceFormValue: (
      state: OnboardingState,
      { payload }: PayloadAction<FormUpdater>,
    ): void => {
      state.experienceForm = { ...state.experienceForm, [payload.key]: payload.value };
    },
    addCertifications: (state: OnboardingState, { payload }: PayloadAction<string[]>): void => {
      state.experienceForm = {
        ...state.experienceForm,
        certifications: payload,
      };
    },
    removeCertifications: (state: OnboardingState, { payload }: PayloadAction<string>): void => {
      state.experienceForm = {
        ...state.experienceForm,
        certifications: state.experienceForm.certifications.filter(
          certification => certification !== payload,
        ),
      };
    },
    updatePositionStartDate: (state: OnboardingState, { payload }: PayloadAction<string>): void => {
      state.experienceForm = { ...state.experienceForm, jobPositionStartDate: payload };
    },
    updatePositionEndDate: (state: OnboardingState, { payload }: PayloadAction<string>): void => {
      state.experienceForm = { ...state.experienceForm, jobPositionEndDate: payload };
    },
    setInitializing: (state: OnboardingState, { payload }: PayloadAction<boolean>): void => {
      state.initializing = payload;
    },
    resetState: (state: OnboardingState): void => {
      Object.assign(state, initialState);
    },
    setReferenceData: (
      state: OnboardingState,
      { payload }: PayloadAction<OnboardingReferenceData>,
    ): void => {
      state.referenceData = payload;
      state.initializing = false;
    },
  },
});

export const {
  updateAboutFormValue,
  updateCountry,
  updateState,
  updateCity,
  updateTopicsOfInterest,
  updateRegionsOfInterest,
  updateExperienceFormValue,
  addCertifications,
  removeCertifications,
  updatePositionStartDate,
  updatePositionEndDate,
  setInitializing,
  resetState,
  setReferenceData,
  updateAdditionalTopicsOfInterest,
} = slice.actions;

export const onboardingReferenceDataSelector = ({
  onboarding,
}: {
  onboarding: OnboardingState;
}): OnboardingReferenceData => onboarding.referenceData;
export const onboardingSelector = ({
  onboarding,
}: {
  onboarding: OnboardingState;
}): OnboardingState => onboarding;

export const aboutFormSelector = ({
  onboarding,
}: {
  onboarding: OnboardingState;
}): AboutFormState => onboarding.aboutForm;

export const experienceFormSelector = ({
  onboarding,
}: {
  onboarding: OnboardingState;
}): ExperienceFormState => onboarding.experienceForm;

export default slice.reducer;
