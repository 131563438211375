import { ReactComponent as High } from 'assets/icons/svg/green-arrow-up-icon.svg';
import { ReactComponent as VeryHigh } from 'assets/icons/svg/green-double-arrow-up-icon.svg';
import { ReactComponent as Average } from 'assets/icons/svg/medium-risk-icon.svg';
import { ReactComponent as Low } from 'assets/icons/svg/red-arrow-down-icon.svg';
import { ReactComponent as VeryLow } from 'assets/icons/svg/red-double-arrow-down-icon.svg';
import { AlignedText } from 'components/table/alignedText';
import { Alignment, ColumnDefinition, ColumnType, Level } from 'components/table/types';
import { defaultLocale } from 'constants/defaultLocale';
import { isValid } from 'date-fns';
import React from 'react';
import { formatDate } from 'views/DataAnalysis/valuesFormatters';

interface Props<T> {
  readonly column: ColumnDefinition<T>;
  readonly row: T;
  readonly value: T[keyof T] | null;
}

export function CellRenderer<T>({ column, value, row }: Props<T>): React.ReactElement | null {
  // Value should NEVER be undefined
  if (value === null || value === undefined) {
    if (column.type !== ColumnType.level && column.type !== ColumnType.custom) {
      return <AlignedText alignment={column.alignment}>-</AlignedText>;
    }
  }

  switch (column.type) {
    case ColumnType.level:
      return (
        <div className="flex items-center justify-center gap-2 leading-6">
          <div className="w-3 h-3">{levelIcons[value as unknown as Level]}</div>{' '}
          <span>{value ? `${value}` : '-'}</span>
        </div>
      );
    case ColumnType.text:
      if (typeof value !== 'string') {
        throw new Error(
          `attempting to render a non-string in a text cell: ( ${String(column.name)}) ${value}`,
        );
      } else {
        // Needs to be a div, or else alignment won't work
        if (column.transform === 'uppercase') {
          return (
            <AlignedText alignment={column.alignment} truncated={true}>
              {value.toUpperCase()}
            </AlignedText>
          );
        } else {
          return (
            <AlignedText alignment={column.alignment} truncated={true}>
              {value}
            </AlignedText>
          );
        }
      }
    case ColumnType.numeric:
      if (typeof value !== 'number') {
        throw new Error(
          `attempting to render a non-numeric in a numeric cell: (${String(column.name)}) ${value}`,
        );
      } else {
        const formatted = value.toLocaleString(defaultLocale, {
          maximumFractionDigits: column.precision,
          minimumFractionDigits: column.precision,
        });

        return (
          <AlignedText alignment={column.alignment ?? Alignment.right}>{formatted}</AlignedText>
        );
      }
    case ColumnType.percent:
      if (typeof value !== 'number') {
        throw new Error(
          `attempting to render a non-numeric in a numeric cell: (${String(column.name)}) ${value}`,
        );
      } else {
        const formatted = value.toLocaleString(defaultLocale, {
          style: 'percent',
          maximumFractionDigits: column.precision,
          minimumFractionDigits: column.precision,
        });

        return (
          <AlignedText alignment={column.alignment ?? Alignment.right}>{formatted}</AlignedText>
        );
      }
    case ColumnType.date:
      if (typeof value === 'string') {
        const alignment = column.alignment ?? Alignment.center;
        const date = new Date(value);
        if (!isValid(date)) {
          return <AlignedText alignment={alignment}>-</AlignedText>;
        }

        return (
          <AlignedText alignment={alignment}>
            <p className="text-gray">{formatDate(date)}</p>
          </AlignedText>
        );
      } else {
        const alignment = column.alignment ?? Alignment.center;
        console.warn('invalid value provided as date: ', value);

        return <AlignedText alignment={alignment}>-</AlignedText>;
      }
    case ColumnType.custom:
      return <>{column.render(value, row)}</>;
    default:
      return <></>;
  }
}

const levelIcons: { [key in Level]: React.ReactElement } = {
  [Level.veryLow]: <VeryLow className="w-3 h-3" />,
  [Level.low]: <Low className="w-3 h-3" />,
  [Level.average]: <Average className="w-3 h-3" />,
  [Level.medium]: <Average className="w-3 h-3" />,
  [Level.high]: <High className="w-3 h-3" />,
  [Level.veryHigh]: <VeryHigh className="w-3 h-3" />,
};
