import { QueryStatus } from '@reduxjs/toolkit/query';
import NoIcon from 'assets/icons/svg/no-portfolios-icon.svg';
import { AddObjectButton } from 'components/addObjectButton';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import EmptySection from 'components/emptySection/EmptySection';
import { ErrorBox } from 'components/errorBox';
import { Filters } from 'components/filters';
import { Modal } from 'components/modal';
import Pagination from 'components/pagination/Pagination';
import { SearchBox } from 'components/searchBox';
import SpinnerLoader from 'components/spinnerLoader';
import { SuccessBox } from 'components/successBox';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { ApplicationModule, exceedsUsageLimit, usePermission } from 'context/authorization';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UsageAction, ViewAccess } from 'redux/reducers/subscriptionPlansReducer';
import { WithOptionalID } from 'types/withId';
import {
  EarnItem,
  SpecialOffer,
  useCreateSpecialOfferMutation,
  useGetPostedSpecialOffersCountQuery,
  useListSpecialOffersQuery,
  useRemoveSpecialOfferMutation,
  useUpdateSpecialOfferMutation,
} from 'views/EarnAndBuy/api';
import { ActionsContext, EarnAndBuyActions } from 'views/EarnAndBuy/context';
import { useKeywordSearch } from 'views/EarnAndBuy/hooks/useKeywordSearch';
import { useStatusSelector } from 'views/EarnAndBuy/hooks/useStatusSelector';
import columns from 'views/EarnAndBuy/SpecialOffers/columns';
import { Details } from 'views/EarnAndBuy/SpecialOffers/components/details';
import filters from 'views/EarnAndBuy/SpecialOffers/filters';
import {
  CreateSpecialOfferPayload,
  isUpdateSpecialOfferPayload,
} from 'views/EarnAndBuy/SpecialOffers/modals/addSpecialOfferReducer';
import { SpecialOfferFormModal } from 'views/EarnAndBuy/SpecialOffers/modals/specialOfferFormModal';

enum ModalType {
  details = 'details',
  createOrEdit = 'createOrEdit',
  createOrEditSuccess = 'creatOrEditSuccess',
  createOrEditFailure = 'creatOrEditFailure',
  confirmRemove = 'confirmRemove',
  removeSuccess = 'removeSuccess',
  removeFailure = 'removeFailure',
  notAllowed = 'notAllowed',
  none = 'none',
}

export const SpecialOffers: React.FC = (): React.ReactElement => {
  const [sortBy, handleSortChange] = useSort<EarnItem>('posted_at', SortOrder.descending);
  const [create, createMutation] = useCreateSpecialOfferMutation();
  const [update, updateMutation] = useUpdateSpecialOfferMutation();
  const [remove, removeMutation] = useRemoveSpecialOfferMutation();
  const [searchKeyword, setSearchKeyword] = useKeywordSearch();
  const [currentActionType, setCurrentActionType] = useState<'update' | 'create' | null>(null);
  const [targetId, setTargetId] = useState<string | null>(null);

  const createPermission = usePermission(ApplicationModule.specialOffers, UsageAction.create);
  const viewPermission = usePermission(ApplicationModule.specialOffers, ViewAccess.viewAccess);
  const location = useLocation();
  const navigate = useNavigate();
  const handleFiltersChange = useFilters(filters);
  const queryParameters = useQueryParameters();

  const currentModal = useMemo((): ModalType => location.state ?? ModalType.none, [location.state]);

  const { data: postedCount } = useGetPostedSpecialOffersCountQuery();
  const { data: specialOffers, isLoading } = useListSpecialOffersQuery(queryParameters);
  const { specialOfferId } = useParams<{ readonly specialOfferId: string }>();

  const rows = useMemo(
    (): readonly SpecialOffer[] => specialOffers?.data ?? [],
    [specialOffers?.data],
  );

  const setCurrentModal = useCallback(
    (modalType: ModalType): void => {
      navigate('../special-offers', { state: modalType });
    },
    [navigate],
  );

  const handleRowClick = useCallback(
    (row: EarnItem): void => {
      if (viewPermission.forbidden) {
        navigate(row.id, { state: ModalType.notAllowed });
      } else {
        navigate(row.id, { state: ModalType.details });
      }
    },
    [navigate, viewPermission.forbidden],
  );

  const handleAddListing = useCallback((): void => {
    setCurrentModal(ModalType.createOrEdit);
  }, [setCurrentModal]);

  const closeCurrentModal = useCallback((): void => {
    setCurrentModal(ModalType.none);
  }, [setCurrentModal]);

  const status = useStatusSelector(createMutation, updateMutation);

  useEffect((): void => {
    switch (status) {
      case QueryStatus.fulfilled:
        setCurrentModal(ModalType.createOrEditSuccess);
        break;
      case QueryStatus.rejected:
        setCurrentModal(ModalType.createOrEditFailure);
        break;
    }
  }, [setCurrentModal, status]);

  const createOrEditCompleted = useCallback((): void => {
    if (updateMutation.status !== QueryStatus.uninitialized) {
      updateMutation.reset();
    } else if (createMutation.status !== QueryStatus.uninitialized) {
      createMutation.reset();
    }
    closeCurrentModal();
  }, [closeCurrentModal, createMutation, updateMutation]);

  const editEventHandler = useCallback(
    (id: string): void => {
      navigate(id, { state: ModalType.createOrEdit });
    },
    [navigate],
  );

  const addClickHandler = useMemo((): VoidFunction | undefined => {
    if (!exceedsUsageLimit(createPermission, postedCount?.count || Number.MAX_SAFE_INTEGER)) {
      return handleAddListing;
    }
  }, [handleAddListing, createPermission, postedCount?.count]);

  const handleSubmit = useCallback(
    async (data: WithOptionalID<CreateSpecialOfferPayload>): Promise<void> => {
      if (isUpdateSpecialOfferPayload(data)) {
        setCurrentActionType('update');
        await update(data);
      } else {
        setCurrentActionType('create');
        await create(data);
      }
    },
    [create, update],
  );

  const removeEventHandler = useCallback(
    (id: string): void => {
      setCurrentModal(ModalType.confirmRemove);
      setTargetId(id);
    },
    [setCurrentModal],
  );

  const shareEventHandler = useCallback((_: string): void => {
    return;
  }, []);

  const actionsContext = useMemo(
    (): EarnAndBuyActions => ({
      actionStatuses: {
        remove: removeMutation.status,
        edit: updateMutation.status,
        share: QueryStatus.uninitialized,
      },
      edit: editEventHandler,
      remove: removeEventHandler,
      share: shareEventHandler,
    }),
    [
      editEventHandler,
      removeEventHandler,
      shareEventHandler,
      removeMutation.status,
      updateMutation.status,
    ],
  );

  const pagesCount = useMemo(
    (): number => specialOffers?.page_count ?? 0,
    [specialOffers?.page_count],
  );

  const handleRemoveConfirmed = React.useCallback((): void => {
    if (targetId === null) {
      console.warn('remove id is not set?');
      return;
    }

    remove(targetId);
  }, [remove, targetId]);

  const removeCompleted = React.useCallback((): void => {
    removeMutation.reset();
    closeCurrentModal();

    setTargetId(null);
  }, [closeCurrentModal, removeMutation]);

  useEffect((): void => {
    switch (removeMutation.status) {
      case QueryStatus.fulfilled:
        setCurrentModal(ModalType.removeSuccess);
        break;
      case QueryStatus.rejected:
        setCurrentModal(ModalType.removeFailure);
        break;
      case QueryStatus.pending:
        closeCurrentModal();
        break;
    }
  }, [closeCurrentModal, removeMutation.status, setCurrentModal]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-end justify-between mt-4 mb-3">
        <div className="w-full">
          <div className="-mt-2 mb-3">
            <SearchBox value={searchKeyword} onChange={setSearchKeyword} />
          </div>
          <div className="flex items-center justify-between w-full">
            <Filters config={filters} onChange={handleFiltersChange} />
            <AddObjectButton verb="New" title="Special Offer" onClick={addClickHandler} />
          </div>
        </div>
      </div>

      <div className="relative flex-1">
        <ConditionalRender renderIf={pagesCount === 0 && !isLoading}>
          <EmptySection title="There are no offers" icon={NoIcon} />
        </ConditionalRender>

        <ConditionalRender renderIf={pagesCount > 0}>
          <Pagination totalPages={pagesCount}>
            <ActionsContext.Provider value={actionsContext}>
              <Table
                columns={columns}
                rows={rows}
                sortBy={sortBy}
                onRowClick={handleRowClick}
                onSortChange={handleSortChange}
              />
            </ActionsContext.Provider>
          </Pagination>
        </ConditionalRender>
        <SpinnerLoader visible={isLoading} />
      </div>

      <Modal isOpen={currentModal === ModalType.details} onClose={closeCurrentModal}>
        <Details offerId={specialOfferId} />
      </Modal>

      <SpecialOfferFormModal
        specialOfferId={specialOfferId}
        open={currentModal === ModalType.createOrEdit}
        busy={status === QueryStatus.pending}
        onSubmit={handleSubmit}
        onClose={closeCurrentModal}
      />

      <Modal
        isOpen={currentModal === ModalType.createOrEditSuccess}
        onClose={createOrEditCompleted}
      >
        <SuccessBox
          title={currentActionType === 'update' ? 'Special Offer Updated' : 'Special Offer Listed'}
          message={
            currentActionType === 'update'
              ? 'Your special offer was updated successfully'
              : 'Your special offer was added to our list'
          }
          onClose={createOrEditCompleted}
        />
      </Modal>

      <Modal
        isOpen={currentModal === ModalType.createOrEditFailure}
        onClose={createOrEditCompleted}
      >
        <ErrorBox
          title="An error occurred"
          message="We had problems trying to add your special offer to the list. Please try again later."
          onClose={createOrEditCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.removeSuccess} onClose={removeCompleted}>
        <SuccessBox
          title="Special Offer Removed"
          message="Your special offer was removed successfully"
          onClose={removeCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.removeFailure} onClose={removeCompleted}>
        <ErrorBox
          title="An error occurred"
          message="We had problems trying to remove your special offer. Please try again later."
          onClose={createOrEditCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.notAllowed} onClose={closeCurrentModal}>
        <Modal.Content>
          <div className="w-modal-sm">
            <ExceedAccess
              title="Upgrade your plan"
              description="You need to upgrade your subscription plan to view the details."
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal isOpen={currentModal === ModalType.confirmRemove} onClose={closeCurrentModal}>
        <Modal.Content title="Remove Special Offer">
          <ConfirmationBox
            message="Removing this special offer is irreversible. Are you sure?"
            danger={true}
            onYes={handleRemoveConfirmed}
            onNo={closeCurrentModal}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};
