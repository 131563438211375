import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly checked: boolean;
  readonly size?: 'large' | 'normal' | 'small';
  readonly label?: string;

  onChange(checked: boolean): void;
  onClick?(event: React.MouseEvent): void;
}

export const Checkbox: React.FC<Props> = ({
  checked,
  size = 'normal',
  label,
  onChange,
  onClick,
}: Props): React.ReactElement => {
  const handleChange = useCallback((): void => {
    onChange(!checked);
  }, [checked, onChange]);

  const inputClassName = useMemo((): string => {
    switch (size) {
      case 'large':
        return 'w-5 h-5';
      case 'normal':
        return 'w-4 h-4';
      case 'small':
        return 'w-3 h-3';
    }
  }, [size]);

  const labelClassName = useMemo((): string => {
    switch (size) {
      case 'large':
        return 'text-base font-poppins text-gray leading-none';
      case 'normal':
        return 'text-sm font-poppins text-gray leading-none';
      case 'small':
        return 'text-xs font-poppins text-gray leading-none';
    }
  }, [size]);

  return (
    <div className="flex items-center gap-1">
      <input
        className={inputClassName}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        onClick={onClick}
      />
      {label && <span className={labelClassName}>{label}</span>}
    </div>
  );
};
