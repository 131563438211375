import React, { Children, useCallback } from 'react';

interface Props {
  onChange(name: string, any: string): void;
}

export const FormValue: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onChange,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const element = Children.only(children);
  if (!React.isValidElement(element)) {
    throw new Error('child is not a valid react element');
  }

  const { props } = element;

  const handleChange = useCallback(
    (any: string): void => {
      onChange(props.name, any);
    },
    [props.name, onChange],
  );

  if (Children.count(children) !== 1) {
    throw new Error('expected exactly 1 child for this component');
  }

  return React.cloneElement(element, { ...props, onChange: handleChange });
};
