import { Clickable } from 'components/clickable';
import { Button } from 'components/genericButton';
import SVGIcon from 'components/icons/SVGIcon';
import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  addCurrencyOfInterest,
  removeCurrencyOfInterest,
} from 'redux/reducers/addPortfolioItemFormReducer';
import { PortfolioCompanyClientOrCustomerForeignExchangeItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerForeignExchangeItem;
}

export const ClientOrCustomerForeignExchangeForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyClientOrCustomerForeignExchangeItem>();

  const [selectedCurrency, setSelectedCurrency] = React.useState<string>('');
  const { currenciesOfInterest = [] } = item;

  const handleAddCurrencyOfInterest = useCallback((): void => {
    dispatch(addCurrencyOfInterest(selectedCurrency));
    setSelectedCurrency('');
  }, [dispatch, selectedCurrency]);

  const handleRemoveCurrencyOfInterest = useCallback(
    (currency: string): void => {
      dispatch(removeCurrencyOfInterest(currency));
    },
    [dispatch],
  );

  return (
    <>
      <div>
        <div className="flex items-end gap-8">
          <div className="flex-1">
            <Select
              id="currentCurrency"
              label="Currencies of interest"
              items={currencies}
              value={selectedCurrency}
              onChange={setSelectedCurrency}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            label="Add"
            onClick={handleAddCurrencyOfInterest}
          />
        </div>
        <div className="my-2 flex gap-2 flex-wrap">
          {currenciesOfInterest.map(
            (currency: string): React.ReactElement => (
              <div
                key={currency}
                className="flex items-center gap-2 bg-blue-100 px-2 rounded border border-blue text-gray"
              >
                <p className="font-poppinsMedium text-sm text-gray">{currency}</p>
                <Clickable clickData={currency} onClick={handleRemoveCurrencyOfInterest}>
                  <button className="border-none outline-none bg-none hover:text-red">
                    <SVGIcon name="close-popup-icon" className="w-2.5 fill-current" />
                  </button>
                </Clickable>
              </div>
            ),
          )}
        </div>
      </div>

      <Select
        id="currency"
        label="Currency of billing"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="annualMonetaryIcomFromClient"
        value={item.annualMonetaryIncomeFromClient}
        label="Annual monetary income from client"
        onValueChange={updaterFor('annualMonetaryIncomeFromClient')}
      />
    </>
  );
};
