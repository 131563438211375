import { AmountInput } from 'components/amountInput';
import React from 'react';
import { PortfolioLandRealStateItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioLandRealStateItem;
}

export const RealEstateLandExtraInputs: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioLandRealStateItem>();

  return (
    <AmountInput
      name="annualNetIncome"
      label="Annual Net Income"
      value={item.annualNetIncomeAmount}
      currency={item.annualNetIncomeCurrency}
      onCurrencyChange={updaterFor('annualNetIncomeCurrency')}
      onValueChange={updaterFor('annualNetIncomeAmount')}
    />
  );
};
