import React from 'react';

interface Props {
  readonly orientation?: 'horizontal' | 'vertical';
}

export const Card: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  orientation = 'horizontal',
}: React.PropsWithChildren<Props>) => {
  if (orientation === 'horizontal') {
    return <div className="flex items-center shadow-md mb-4 p-4 rounded">{children}</div>;
  } else if (orientation === 'vertical') {
    return <div className="flex flex-col shadow-md mb-4 p-4 rounded">{children}</div>;
  } else {
    return <></>;
  }
};
