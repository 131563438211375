import { QueryStatus } from '@reduxjs/toolkit/query';
import styles from 'assets/styles/spinner.module.scss';
import { Disabler } from 'components/disabler';
import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import React from 'react';
import { AccessMode } from 'types/accessMode';
import { ActionType } from 'views/EarnAndBuy/context';

export interface ActionItem {
  readonly icon: SvgIconName;
  readonly label: string;
  readonly disabled: boolean;
  readonly action: ActionType;
}

interface Props {
  readonly actions: readonly ActionItem[];
  readonly accessMode: AccessMode;

  readonly actionStatuses?: Partial<Record<ActionType, QueryStatus>>;

  onHandleAction(action: ActionType): void;
}

export const OwnerActions: React.FC<Props> = ({
  actions,
  actionStatuses = {},
  accessMode,
  onHandleAction,
}: Props): React.ReactElement => {
  return (
    <>
      {actions
        .map((item: ActionItem): ActionItem => {
          if (item.action === ActionType.share) {
            return item;
          }

          return { ...item, disabled: accessMode !== AccessMode.owner };
        })
        .map((item: ActionItem): React.ReactElement => {
          const createClickHandler = (event: React.MouseEvent): void => {
            event.stopPropagation();
            onHandleAction(item.action);
          };

          if (actionStatuses[item.action] === QueryStatus.pending) {
            const className = `${iconButtonClassName} p-2`;

            return (
              <div key={item.action} className={className}>
                <div className={[styles.spinner, styles.thick].join(' ')} />
              </div>
            );
          }

          return (
            <Disabler key={item.action} disabled={item.disabled}>
              <div className={iconButtonClassName} onClick={createClickHandler}>
                <SVGIcon name={item.icon} className="w-5 h-5 fill-current" />
              </div>
            </Disabler>
          );
        })}
    </>
  );
};

const iconButtonClassName =
  'flex items-center justify-center relative cursor-pointer w-10 h-10 flex-shrink-0 text-gray hover:bg-gray-200 rounded-full';
