import React from 'react';

export interface BaseTabProps {
  readonly type: 'tab' | 'link';
  readonly label: string;
  readonly path?: string;
  // FIXME: remove this eventually
  readonly comingSoon?: boolean;
  readonly disabled?: boolean;
}

export interface LinkTabProps extends BaseTabProps {
  readonly type: 'link';
  readonly to: string;
}

export interface TabProps extends BaseTabProps {
  readonly type: 'tab';
  readonly element: React.ReactElement | null;
}

export class BaseTabProps {
  public static isTab(props: any): props is TabProps {
    return props.type === 'tab';
  }

  public static isLinkTab(props: any): props is LinkTabProps {
    return props.type === 'link';
  }
}

export type AnyTabProps = TabProps | LinkTabProps;
