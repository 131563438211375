import { Clickable } from 'components/clickable';
import { Tag } from 'components/tag';
import { Truncate } from 'components/truncate';
import { TruncateType } from 'components/truncate/baseProps';
import { capitalize } from 'lodash';
import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly tags?: readonly string[];
  readonly lines?: number;

  onExpand?(): void;
  onClick?(tag: string): void;
}

export const TruncatedTags: React.FC<Props> = ({
  tags,
  lines = 1,
  onExpand,
  onClick,
}: Props): React.ReactElement => {
  const ellipsis = useCallback(
    (totalCount: number, renderedCount: number): React.ReactElement => {
      return (
        <Tag onClick={onExpand}>
          <span className="whitespace-nowrap">+{String(totalCount - renderedCount)}</span>
        </Tag>
      );
    },
    [onExpand],
  );

  // FIXME: this is here because the data is broken
  const uniqueTags = useMemo((): string[] => Array.from(new Set(tags)), [tags]);
  if (!tags || tags.length === 0) {
    return invisibleTag;
  }

  return (
    <Truncate ellipsis={ellipsis} contentType={TruncateType.elements} lines={lines}>
      {uniqueTags.map((tag: string) => (
        <Clickable key={tag} clickData={tag} onClick={onClick}>
          <Tag>
            <span className="whitespace-nowrap">{capitalize(tag)}</span>
          </Tag>
        </Clickable>
      )) ?? []}
    </Truncate>
  );
};

const invisibleTag = (
  <div className="leading-none py-0.5">
    <span>&nbsp;</span>
  </div>
);
