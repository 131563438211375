import axios, { AxiosResponse } from 'axios';
import { Sensitivities } from 'components/sensitivities';
import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'types/page';
import { Sensitivity, SensitivityCategory } from 'types/sensitivities';
import settings from 'utils/config/appSettings';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { toQueryString } from 'utils/toQueryString';

interface Props {
  readonly category: SensitivityCategory;
}

export const PortfoliosSensitivities: React.FC<Props> = ({
  category,
}: Props): React.ReactElement => {
  const [companiesCount, setCompaniesCount] = useState<number>(0);
  const [urlSearchParams] = useSearchParams();

  const fetchUrl = useMemo((): string => {
    const pageNumber = urlSearchParams.get('page_number') ?? '1';
    const query = {
      page_number: pageNumber,
      page_size: settings.defaultPageSize,
      order_by: urlSearchParams.get('order_by'),
    };

    return `portfolios/sensitivities/${category}/${toQueryString(query)}`;
  }, [category, urlSearchParams]);

  useEffect((): void | VoidFunction => {
    const cancelTokenSource = axios.CancelToken.source();
    api
      .get(`${API_V1_PATH}/portfolios/count`, {
        cancelToken: cancelTokenSource.token,
      })
      .then((response: AxiosResponse): void => {
        const { companies_count } = response.data;
        setCompaniesCount(companies_count);
      })
      .catch(console.warn);

    return (): void => {
      cancelTokenSource.cancel();
    };
  }, []);

  const [processingState, page] = useFetch<Page<Sensitivity>>(fetchUrl, emptyPage, 200);

  return (
    <Sensitivities
      category={category}
      processingState={processingState}
      page={page}
      group="portfolios"
      numberOfCompanies={companiesCount}
    />
  );
};

const emptyPage = Page.empty<any>();
