import styles from 'components/fullScreenLoadingSpinner/full-screen-loading-spinner.module.scss';
import SpinnerLoader from 'components/spinnerLoader';
import React from 'react';

interface Props {
  readonly visible: boolean;
}

export const FullScreenLoadingSpinner: React.FC<Props> = ({
  visible,
}: Props): React.ReactElement | null => {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.backdrop}>
      <SpinnerLoader visible={true} />
    </div>
  );
};
