import { CapnoteMap } from 'components/capnoteMap';
import { MarkerType } from 'components/capnoteMap/types';
import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import { useCompanyGeographiesAndMaps } from 'hooks/useCompanyGeographiesAndMaps';
import { useCompanyGeographiesAndMapsMarkers } from 'hooks/useCompanyGeographiesAndMapsMarkers';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { companyDetailsSelector } from 'redux/reducers/companyReducer';
import { OperationType } from 'types/operationType';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { DataTable } from 'views/SmartTools/GeographiesAndMaps/components/dataTable';
import { MarkerDetails } from 'views/SmartTools/GeographiesAndMaps/components/markerDetails';

const GeographiesMaps: React.FC = (): React.ReactElement => {
  const company = useSelector(companyDetailsSelector);

  const [currentFilter, setCurrentFilter] = useState<string>(OperationType.subsidiaries);
  const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);

  const clearSelectedMarker = React.useCallback((): void => {
    setSelectedMarker(null);
  }, []);
  const markers = useCompanyGeographiesAndMapsMarkers(currentFilter);

  useCompanyGeographiesAndMaps(company);

  return (
    <div className="flex-grow">
      <div className="flex items-center justify-end my-4">
        <DropdownFilter selectedId={currentFilter} options={options} onSelect={setCurrentFilter} />
      </div>
      <CapnoteMap markers={markers} onMarkerClick={setSelectedMarker} />

      <MarkerDetails marker={selectedMarker} onClose={clearSelectedMarker} />
      <DataTable filter={currentFilter} company={company} />
      <DataDisclaimer />
    </div>
  );
};

export default GeographiesMaps;

const options: ReadonlyArray<DropdownItem<OperationType>> = [
  { id: OperationType.subsidiaries, label: 'Show: Subsidiaries' },
  { id: OperationType.revenues, label: 'Show: Revenues' },
];
