import { Button } from 'components/genericButton';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getActiveSubscriptionForLoggedInUserAction } from 'redux/services/subscriptionPlansService';

export const Success: React.FC = (): React.ReactElement => {
  const [ready, setReady] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect((): VoidFunction => {
    const timeout: ReturnType<typeof setTimeout> = setTimeout((): void => {
      setReady(true);
    }, 1500);

    return (): void => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect((): VoidFunction | void => {
    if (!ready) {
      return;
    }
    dispatch(getActiveSubscriptionForLoggedInUserAction());
  }, [dispatch, ready]);

  const handleGoToPayments = useCallback((): void => {
    navigate('/profile/payments');
  }, [navigate]);

  const clickHandler = useMemo((): VoidFunction | undefined => {
    if (!ready) {
      return undefined;
    }

    return handleGoToPayments;
  }, [handleGoToPayments, ready]);

  return (
    <div className="flex-1 w-full flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="bg-green w-24 h-24 rounded-full my-8">
          <SVGIcon name="shared-icons-check" className="w-24 h-24" />
        </div>
        <div className="text-center mb-24">
          <h1>Subscription Successful</h1>
          <h6>You may start enjoying your new subscription plan right now.</h6>
        </div>
        <Button
          type="button"
          variant="primary"
          label="Go to your payments page"
          onClick={clickHandler}
        />
      </div>
    </div>
  );
};
