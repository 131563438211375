import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { companyDetailsSelector } from 'redux/reducers/companyReducer';
import { getLegendForFinancialStatements } from 'utils';

interface Props {
  readonly largestNumber?: number;
  readonly convertedToUSD?: boolean;
}

export const StatementTableHeaderLegend: React.FC<Props> = ({
  largestNumber,
  convertedToUSD = false,
}: Props): React.ReactElement => {
  const company = useSelector(companyDetailsSelector);

  const currency = useMemo(
    (): string => (convertedToUSD ? 'USD' : company.currency),
    [company.currency, convertedToUSD],
  );

  if (!Number.isFinite(largestNumber)) {
    return (
      <h4 className="text-gray-darkest font-poppins normal-case">
        Dashes (-) indicate unavailable data
      </h4>
    );
  } else {
    return (
      <div>
        <h4 className="text-gray-darkest font-poppins normal-case">
          {getLegendForFinancialStatements(largestNumber, currency)}
        </h4>
        <h4 className="text-gray-darkest font-poppins normal-case">
          Dashes (-) indicate unavailable data
        </h4>
      </div>
    );
  }
};
