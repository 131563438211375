import { ReactComponent as CheckIcon } from 'assets/icons/svg/new-check-icon.svg';
import React from 'react';

interface Props {
  readonly rounded: boolean;
  readonly checked: boolean;
}

export const ItemCheckbox: React.FC<Props> = ({ rounded, checked }: Props): React.ReactElement => {
  if (rounded) {
    return (
      <div className="flex flex-shrink-0 items-center justify-center w-5 h-5 bg-white border border-gray rounded-full">
        {checked && <div className={`w-1/2 h-1/2 bg-blue rounded-full`} />}
      </div>
    );
  } else {
    const border = checked ? 'blue' : 'gray-500';
    const background = checked ? 'blue' : 'white';
    return (
      <div
        className={`flex flex-shrink-0 items-center justify-center border w-5 h-5 border-${border} bg-${background}`}
        style={{ borderRadius: 3 /* There's no matching tailwind preset */ }}
      >
        {checked && <CheckIcon className="w-3/4 h-3/4" fill="white" />}
      </div>
    );
  }
};
