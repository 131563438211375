import React from 'react';

interface Props {
  readonly title: string;
  readonly description?: string | React.ReactElement;
}

export const CardTitle: React.FC<Props> = ({ title, description }: Props) => {
  return (
    <div>
      <h4 className="mt-1">{title}</h4>
      {typeof description === 'string' ? (
        <p className="text-gray-dark">{description}</p>
      ) : (
        description
      )}
    </div>
  );
};
