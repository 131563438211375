import React from 'react';
import { AccessMode } from 'types/accessMode';
import { EventDetails, Stream, StreamEvent, TimelineLayoutType } from 'types/timeline';
import { StreamItem } from 'views/SmartTools/TimelineAndEvents/components/streamItem';

export interface Props {
  events: StreamEvent[];
  date: Date;
  year: number;
  eventsLayoutType?: TimelineLayoutType;
  onPressEvent: (event: EventDetails) => void;
}

export default function SponsoredStream(_: Props): React.ReactElement {
  return <StreamItem stream={emptyStream} compact={true} readOnly={true} variant="sponsored" />;
}

const emptyStream: Stream = {
  category: '',
  events: [],
  id: '',
  members_count: 0,
  name: 'Sponsored',
  author: '',
  tags: [],
  access_mode: AccessMode.readOnly,
};
