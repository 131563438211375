import React from 'react';
import { Organization, OrganizationTypes } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { CompanyItemRenderer } from 'views/SmartTools/ValueChains/components/CompanyItemRenderer';
import { IndustryItemRenderer } from 'views/SmartTools/ValueChains/components/IndustryItemRenderer';

interface Props {
  readonly organization: Organization;
}

export const OrganizationItemRenderer: React.FC<Props> = ({
  organization,
}: Props): React.ReactElement => {
  switch (organization.type) {
    case OrganizationTypes.company:
      return <CompanyItemRenderer company={organization} />;
    case OrganizationTypes.industry:
      return <IndustryItemRenderer industry={organization} />;
    default:
      return <></>;
  }
};
