import { RECORD_SEPARATOR } from 'assets/constants/recordSeparator';
import { CancelToken } from 'axios';
import { FilterValues } from 'hooks/useFilters';
import api from 'utils/config/axiosConfig';

export interface GenericFilter {
  readonly type: 'backend' | 'local';
  readonly label: string;
  readonly name: string;
}

interface BackendFilter extends GenericFilter {
  readonly type: 'backend';
  readonly url: string;
}

interface LocalFilter extends GenericFilter {
  readonly type: 'local';
  readonly options: string[] | ((key: string) => string[]);
}

export type CommonFilter = BackendFilter | LocalFilter;

export const isLocalFilterItem = (item: GenericFilter): item is LocalFilter => {
  return item.type === 'local';
};

export const isBackendFilterItem = (item: GenericFilter): item is BackendFilter => {
  return item.type === 'backend';
};

export const resolveOptions = async (
  filterDefinition: GenericFilter,
  appliedFilters: readonly FilterValues[],
  cancelToken: CancelToken,
): Promise<string[]> => {
  if (isLocalFilterItem(filterDefinition)) {
    throw new Error("there's no fetching involved for local filters.ts");
  } else if (isBackendFilterItem(filterDefinition)) {
    const params = appliedFilters
      .filter((appliedFilter: FilterValues): boolean => appliedFilter.key !== filterDefinition.name)
      .reduce(
        (params: Record<string, string>, { key, values }: FilterValues): Record<string, string> => {
          if (values === null) {
            return params;
          }

          return { ...params, [key]: values.join(RECORD_SEPARATOR) };
        },
        {},
      );

    const response = await api.get(`${filterDefinition.url}`, { params, cancelToken });
    if (response.status !== 200) {
      return [];
    } else {
      return response.data;
    }
  }

  return [];
};
