// Libraries
import { ConditionalRender } from 'components/conditionalRenderer';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  loadingSummarySelector,
  selectedCountrySelector,
  summarySelector,
} from 'redux/reducers/industryReducer';
import { getSummaryService } from 'redux/services/industryServices';
import { getLegendForFinancialStatements } from 'utils';
import SummaryTable from 'views/DataAnalysis/common/SummaryTable';
import { getLargestSummaryNumber } from 'views/DataAnalysis/common/SummaryTable/helpers';
import { CountryDropdown } from 'views/DataAnalysis/Industry/Details/countryDropdown';

const Overview: React.FC = (): React.ReactElement => {
  const { industryId } = useParams<{ industryId: string }>();

  const loadingOverview = useSelector(loadingSummarySelector);
  const summary = useSelector(summarySelector);
  const dispatch = useDispatch<any>();
  const country = useSelector(selectedCountrySelector);

  useEffect((): void | VoidFunction => {
    if (!industryId) {
      return;
    }

    return dispatch(getSummaryService(industryId, country));
  }, [country, dispatch, industryId]);

  const largestNumber = useMemo((): number => {
    return getLargestSummaryNumber(summary);
  }, [summary]);

  return (
    <div className="relative flex-1">
      <ConditionalRender renderIf={!!summary}>
        <div className="flex items-start justify-between mt-3">
          <h4 className="text-xs md:text-sm text-gray-darkest font-poppins normal-case">
            {getLegendForFinancialStatements(largestNumber)}
          </h4>
          <CountryDropdown />
        </div>
        <SummaryTable data={summary} largestNumber={largestNumber} />
      </ConditionalRender>

      <SpinnerLoader visible={loadingOverview} />
    </div>
  );
};

export default Overview;
