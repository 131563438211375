import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';

export type Identifiable<T> = T & {
  readonly id: string;
};

export interface Coordinate {
  readonly latitude: number;
  readonly longitude: number;
}

export enum FetchingType {
  subsidiaries,
  revenues,
}

export interface CompanyRevenuesLocation {
  readonly id: string;
  readonly kind: 'revenues';
  readonly name: string;
  readonly percentage_of_total: number;
  readonly sales: number;
  readonly coordinates: ReadonlyArray<Identifiable<Coordinate>>;
}

export interface CompanySubsidiaryLocation {
  readonly id: string;
  readonly kind: 'subsidiary';
  readonly type: 'Headquarter' | 'Subsidiary';
  readonly subsidiaries: readonly string[];
  readonly country: string;
  readonly country_id: string;
  readonly coordinates: Coordinate;
}

export interface GeographiesAndMapsState {
  subsidiaries: readonly CompanySubsidiaryLocation[];
  revenues: readonly CompanyRevenuesLocation[];
  fetching: boolean;
  fetchingType: FetchingType | null;
  error: string | null;
}

const initialState: GeographiesAndMapsState = {
  subsidiaries: [],
  revenues: [],
  fetching: false,
  fetchingType: null,
  error: null,
};

const slice = createSlice({
  name: 'geographiesAndMaps',
  initialState: initialState,
  reducers: {
    fetchSubsidiariesStarted: (state: GeographiesAndMapsState): void => {
      state.fetching = true;
      state.fetchingType = FetchingType.subsidiaries;
    },
    fetchSubsidiariesSucceeded: (
      state: GeographiesAndMapsState,
      { payload }: PayloadAction<any>,
    ): void => {
      state.fetching = false;
      state.fetchingType = null;
      state.subsidiaries = payload
        .filter((item: any): boolean => item !== null)
        .map(
          (item: any): CompanySubsidiaryLocation => ({
            kind: 'subsidiary',
            ...item,
          }),
        );
    },
    fetchSubsidiariesFailed: (
      state: GeographiesAndMapsState,
      { payload }: PayloadAction<any>,
    ): void => {
      state.fetching = false;
      state.fetchingType = null;
      state.error = payload;
    },
    fetchRevenuesStarted: (state: GeographiesAndMapsState): void => {
      state.fetching = true;
      state.fetchingType = FetchingType.revenues;
    },
    fetchRevenuesSucceeded: (
      state: GeographiesAndMapsState,
      { payload }: PayloadAction<any>,
    ): void => {
      state.fetching = false;
      state.fetchingType = null;
      state.revenues = payload
        .filter((item: any): boolean => item !== null)
        .map(
          (item: any): CompanyRevenuesLocation => ({
            id: item.name,
            kind: 'revenues',
            ...item,
          }),
        );
    },
    fetchRevenuesFailed: (
      state: GeographiesAndMapsState,
      { payload }: PayloadAction<any>,
    ): void => {
      state.fetching = false;
      state.fetchingType = null;
      state.error = payload;
    },
    resetAll: (state: GeographiesAndMapsState): void => {
      Object.assign(state, initialState);
    },
  },
});

export default slice.reducer;

export const {
  fetchSubsidiariesStarted,
  fetchSubsidiariesSucceeded,
  fetchSubsidiariesFailed,
  fetchRevenuesStarted,
  fetchRevenuesSucceeded,
  fetchRevenuesFailed,

  resetAll,
} = slice.actions;

export const geographiesAndMapsSelector = (state: ApplicationState): GeographiesAndMapsState =>
  state.geographiesAndMaps;
