// Library
import { KeywordAndDateRangeFilter } from 'components/keywordAndDateRangeFilter';
import NotificationsView from 'components/notificationsView';
import { useKeywordAndDateRangeFilter } from 'hooks/useKeywordAndDateRangeFilter';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  portfolioLoadingSelector,
  portfolioNotificationsSelector,
} from 'redux/reducers/portfolioReducer';
import { getPortfolioNotificationsAction } from 'redux/services/portfolioService';
import columns from 'views/SmartTools/Notifications/columns';

const Notifications: React.FC = () => {
  const dispatch = useDispatch<any>();
  const notificationsPage = useSelector(portfolioNotificationsSelector);
  const loading = useSelector(portfolioLoadingSelector);
  const queryParameters = useQueryParameters();
  const [keyword, , , searching] = useKeywordAndDateRangeFilter(null);

  const { portfolioId } = useParams<{ portfolioId: string }>();

  useEffect((): void => {
    if (!portfolioId) {
      return;
    }

    dispatch(getPortfolioNotificationsAction(portfolioId, queryParameters));
  }, [dispatch, portfolioId, queryParameters]);

  return (
    <>
      <KeywordAndDateRangeFilter keyword={keyword} searching={searching} />
      <NotificationsView
        notificationsPage={notificationsPage}
        columns={columns}
        loading={loading}
        rootPath="/portfolios/details/:portfolioId/notifications"
      />
    </>
  );
};

export default Notifications;
