import { UserAvatar } from 'components/userAvatar';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SocialUser } from 'views/Home/types';

interface Props {
  readonly commenter: SocialUser;
  readonly date: Date;
}

export const CommentCardHeader: React.FC<Props> = ({
  commenter,
  date,
}: Props): React.ReactElement => {
  const formattedDate = useMemo((): string => {
    try {
      return format(date, 'LLL dd, yyyy - p');
    } catch {
      return 'Invalid date???';
    }
  }, [date]);

  return (
    <div className="flex items-center gap-2 text-sm">
      <UserAvatar user={commenter} />
      <div>
        <div className="flex gap-2">
          <Link to={`/profile/${commenter.id}`} className="font-poppinsSemiBold normal-case">
            {commenter.name}
          </Link>
        </div>
        <div className="text-xs">{formattedDate}</div>
      </div>
    </div>
  );
};
