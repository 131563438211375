import { Identifiable, Level } from 'components/table/types';
import { Country } from 'redux/reducers/sharedReducer';
import { CountryOfInterest } from 'views/DataAnalysis/Industry/countries';

export interface OrganizationDetails {
  id: string;
  organization_id: string;
  name: string;
  description: string;
  following: boolean;
  tags: string[];
  average_risk_rating: Level;
  average_growth_rating: Level;
  currency: string;
}

export interface Portfolio {
  id: string;
  name: string;
}

export interface Company extends Identifiable {
  company_id: string | null;
  name: string;
  ticker: string;
  market_cap: number;
  price: number;
  price_change: number;
  country_of_domicile: Country;
  ebitda_margin: number;
  pe_ratio: number;
  fifty_two_week_price_change: number;
}

export interface CompanySummaryProps {
  item: string;
  itemFields: string[];
  itemDetails: Array<string | number>;
}

export interface CompanyRow {
  values: CompanyField[];
}

export interface CompanyField {
  title: string;
  value: string | number;
}

export interface SummaryField extends FinancialField {
  group: string;
  title: string;
}

export interface SummaryInfo extends FinancialInfo {
  periods: SummaryPeriod[];
}

export interface SummaryPeriod extends FinancialPeriod {
  entries: SummaryField[];
}

export interface FinancialField {
  tag?: string;
  value: string | number;
  unit?: 'money' | 'value' | string;
  title?: string;
  group?: string;
  subgroup?: any;
  social_id?: string;
  filling_id: string;
  above?: string;
  period?: string;
}

export interface FinancialRow {
  tag?: string;
  title?: string;
  above?: string;
  group?: string;
  subgroup?: string;
  values: FinancialField[];
}

export interface FinancialPeriod {
  name: string;
  entries: FinancialField[];
}

export interface FinancialInfo {
  name: string;
  organization: string;
  periods: FinancialPeriod[];
  largestNumber?: number;
}

export enum ValueChainRelationshipType {
  Suppliers = 'Suppliers',
  Partners = 'Partners',
  Banks = 'Banks',
  Regulators = 'Regulators',
  NonControllingInvestments = 'Non-Controlling Investments',
  Subsidiaries = 'Subsidiaries',
  Investors = 'Investors',
  OtherServices = 'Other Services',
  Custormers = 'Customers',
  None = 'None',
  KeyCustomers = 'Key Customers',
  KeySuppliers = 'Key Suppliers',
  Peer = 'Peer',
}

export enum RelationshipSignificance {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum ValueChainEntityType {
  company = 'company',
  industry = 'industry',
  indicator = 'indicator',

  salesGeographies = 'sales_geographies',
  subsidiaries = 'subsidiaries',
  institutionalInvestors = 'institutional_investors',
  relatedIndustries = 'related_industries',
  regulators = 'regulators',
  peers = 'peers',
  keyCustomers = 'key_customers',
  keySuppliers = 'key_suppliers',
  purchasingIndustries = 'purchasing_industries',
  supplyingIndustries = 'supplying_industries',
  competingIndustries = 'competing_industries',
  complementaryIndustries = 'complementary_industries',
  other = 'other',
}

export interface ValueChainRequestParams {
  readonly country?: CountryOfInterest;
  readonly entity_name?: string;
  readonly page_number?: number;
  readonly page_size?: number;
}

export interface ValueChainNode {
  readonly entityId: string;
  readonly entityType: ValueChainEntityType;
  readonly label: string;
  readonly count: number | null;
  readonly ownerOrganization: ValueChainNode | null;

  readonly children?: ValueChainNode[];
}

export interface ValueChainCategoryInfo {
  readonly id: string;
  readonly entity_id: string;
  readonly label: ValueChainRelationshipType;
  readonly count: number;
  readonly entity_type: string;
}

export interface RelatedCompany {
  id: string;
  type: ValueChainRelationshipType;
  name: string;
  organization_source_id: string;
  description: string;
  significance: RelationshipSignificance;
  entity_type: string;
  count: number;
}

export type ValueChainStack = ValueChainNode[];

export interface ValueChainTableRow {
  readonly label: string;
  readonly count: number;
  readonly name: string;
  readonly entity_type: ValueChainEntityType;
  readonly id: string;
  readonly entity_id: string;
  readonly type: string;
  readonly description?: string;
  readonly marketcap: number | null;
}
