import React from 'react';
import { PortfolioCompanyInvestmentOptionsItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioCompanyInvestmentOptionsItem;
}

export const InvestmentOptionsItemForm: React.FC<Props> = ({
  item: _,
}: Props): React.ReactElement => {
  return <></>;
};
