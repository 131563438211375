import { AxiosResponse } from 'axios';
import Button from 'components/buttons/Button/Button';
import Checkbox from 'components/DEPRECATED/checkbox/Checkbox';
import { TagInput } from 'components/tagInput';
import { format, isAfter } from 'date-fns';
import { KeyValue, useFormValueSetter } from 'hooks/useFormValueSetter';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addCertifications,
  CareerLevels,
  experienceFormSelector,
  ExperienceFormState,
  onboardingSelector,
  updateExperienceFormValue,
  updatePositionEndDate,
  updatePositionStartDate,
} from 'redux/reducers/onboardingReducer';
import { OnboardingPayload, submitOnboardingForm } from 'redux/services/onboardingServices';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { DatePicker } from 'views/Landing/Onboarding/components/DatePicker';
import { DropdownInput } from 'views/Landing/Onboarding/components/DropdownInput';
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';
import { Input } from 'views/Landing/Onboarding/components/Input';
import { Select, SelectOption } from 'views/Landing/Onboarding/components/Select';

export const carrelLevelOptions = Object.values(CareerLevels).map(
  (value: CareerLevels): SelectOption<CareerLevels> => ({
    value: value,
    label: value,
  }),
);

const ExperienceForm: React.FC = () => {
  const [currentPosition, setCurrentPosition] = useState<boolean>(false);
  const onboarding = useSelector(onboardingSelector);
  const state = useSelector(experienceFormSelector);
  const dispatch = useDispatch<any>();

  const updateFormValue = useFormValueSetter(
    (keyValue: KeyValue<ExperienceFormState>): void => {
      dispatch(updateExperienceFormValue(keyValue));
    },
    { nullifyEmptyString: true },
    [dispatch],
  );

  const onChangeCurrentPosition = useCallback((): void => {
    if (!currentPosition) {
      updateFormValue('jobPositionEndDate', null);
    } else {
      updateFormValue('jobPositionEndDate', state.jobPositionStartDate);
    }
    setCurrentPosition(!currentPosition);
  }, [currentPosition, state.jobPositionStartDate, updateFormValue]);

  const onConnectionToFinanceChange = useCallback(
    (value: CareerLevels): void => {
      updateFormValue('careerLevel', value);
    },
    [updateFormValue],
  );

  const onCertificationsChange = useCallback(
    (value: string[]): void => {
      dispatch(addCertifications(value));
    },
    [dispatch],
  );

  const onStartDateChange = useCallback(
    (value: Date): void => {
      const date = format(value, 'MM/dd/yyyy');
      dispatch(updatePositionStartDate(date));
    },
    [dispatch],
  );

  const onEndDateChange = useCallback(
    (value: Date): void => {
      const date = format(value, 'MM/dd/yyyy');
      if (state.jobPositionStartDate === null) {
        dispatch(updatePositionStartDate(date));
      }
      dispatch(updatePositionEndDate(date));
    },
    [dispatch, state.jobPositionStartDate],
  );

  const onIndustryAutocomplete = useCallback(
    async (value: string): Promise<readonly ComboBoxItem[]> => {
      const trimmedValue = value.trim();
      if (trimmedValue.length > 0) {
        const response: AxiosResponse = await api.get(
          `${API_V1_PATH}/reference-data/profile/industries`,
          {
            params: { keyword: trimmedValue },
          },
        );
        if (response.status !== 200) {
          return [];
        }

        return response.data;
      } else {
        return [];
      }
    },
    [],
  );

  const onIndustryChange = useCallback(
    (value: ComboBoxItem): void => {
      updateFormValue('industry', value);
    },
    [updateFormValue],
  );

  const onRecentCompanyChange = useCallback(
    (value: string): void => {
      updateFormValue('recentCompany', value);
    },
    [updateFormValue],
  );

  const onRecentJobChange = useCallback(
    (value: string): void => {
      updateFormValue('recentJob', value);
    },
    [updateFormValue],
  );

  const isStartDateGreater = useMemo(() => {
    const startDate = state.jobPositionStartDate
      ? new Date(state.jobPositionStartDate)
      : new Date();
    const endDate = state.jobPositionEndDate ? new Date(state.jobPositionEndDate) : startDate;

    return isAfter(startDate, endDate);
  }, [state.jobPositionEndDate, state.jobPositionStartDate]);

  const minEndDate = useMemo(
    () => (state.jobPositionStartDate ? new Date(state.jobPositionStartDate) : undefined),
    [state.jobPositionStartDate],
  );

  const payloadStartDate = useMemo(
    (): string | null =>
      state.jobPositionStartDate
        ? format(new Date(state.jobPositionStartDate), 'yyyy-MM-dd')
        : null,
    [state.jobPositionStartDate],
  );

  const payloadEndDate = useMemo(
    (): string | null =>
      state.jobPositionEndDate ? format(new Date(state.jobPositionEndDate), 'yyyy-MM-dd') : null,
    [state.jobPositionEndDate],
  );

  const now = useMemo(() => new Date(), []);

  const onSubmit = useCallback((): void => {
    const { aboutForm, experienceForm } = onboarding;
    const country = aboutForm.country;
    if (!country) {
      throw new Error('country is mandatory');
    }
    const { industry } = experienceForm;
    const { state } = aboutForm;
    const payload: OnboardingPayload = {
      about: {
        about_me: aboutForm.aboutMe,
        country: country,
        state: state ?? null,
        city: aboutForm.city,
        accredited_investor: aboutForm.accreditedInvestor,
        connection_to_finance: aboutForm.connectionToFinance ?? null,
      },
      job_information: {
        career_level: experienceForm.careerLevel,
        recent_job: experienceForm.recentJob,
        recent_company: experienceForm.recentCompany,
        industry: industry?.id ?? null,
        job_position_start_date: payloadStartDate,
        job_position_end_date: payloadEndDate,
        certifications: experienceForm.certifications,
      },
      regions_of_interest: aboutForm.regionsOfInterest,
      topics_of_interest: aboutForm.topicsOfInterest,
      additional_topics_of_interest: aboutForm.additionalTopicsOfInterest,
    };
    dispatch(submitOnboardingForm(payload));
  }, [dispatch, onboarding, payloadEndDate, payloadStartDate]);

  return (
    <>
      <h2 className="text-gray-darkest mb-16">ACCOUNT SETUP - EXPERIENCE</h2>
      <div className="mb-8 mt-12">
        <Select
          id="career-level"
          value={state.careerLevel ?? ''}
          options={carrelLevelOptions}
          label="Career Level"
          onChange={onConnectionToFinanceChange}
        />
      </div>
      <div className="mb-8">
        <Input
          id="recent-job"
          name="recentJob"
          value={state.recentJob ?? ''}
          label="Most recent job"
          placeholder="Most recent job"
          onChange={onRecentJobChange}
        />
      </div>
      <div className="mb-8">
        <Input
          id="recent-company"
          name="recentCompany"
          value={state.recentCompany ?? ''}
          label="Most recent company"
          placeholder="Most recent company"
          onChange={onRecentCompanyChange}
        />
      </div>
      <div className="mb-8">
        <DropdownInput
          id="industry"
          name="industry"
          value={state.industry}
          label="Industry"
          placeholder="Industry"
          onAutocomplete={onIndustryAutocomplete}
          onChange={onIndustryChange}
        />
      </div>
      <div className="mb-8 mt-10">
        <div className="w-full mb-6 flex gap-2 items-center">
          <DatePicker
            id="start-date"
            onChange={onStartDateChange}
            value={state.jobPositionStartDate}
            maxDate={now}
            label="Start Date"
          />
          {!currentPosition && (
            <DatePicker
              id="end-date"
              onChange={onEndDateChange}
              value={state.jobPositionEndDate}
              minDate={minEndDate}
              maxDate={now}
              label="End Date"
            />
          )}
        </div>
        <div className={`${!isStartDateGreater ? 'invisible' : ''} mb-4 px-3 flex`}>
          <p className="font-poppins text-red text-xs">Start date must be lower than end date</p>
        </div>
        <div className="flex w-full px-3">
          <Checkbox
            id="current-position-checkbox"
            checked={currentPosition}
            label="Current position"
            onChange={onChangeCurrentPosition}
          />
        </div>
      </div>
      <div className="mb-8">
        <TagInput
          id="certifications-licences"
          name="certifications"
          placeholder="Certifications & Licences"
          items={state.certifications}
          onChange={onCertificationsChange}
        />
      </div>
      <div className="w-full flex justify-between">
        <Link to="/onboarding/about">
          <Button id="back-button" label="Back" width="w-36" />
        </Link>
        <Button
          id="submit-button"
          label="Submit"
          width="w-36"
          onClick={onSubmit}
          cursor={isStartDateGreater ? 'cursor-default' : 'cursor-pointer'}
          disabled={isStartDateGreater}
        />
      </div>
    </>
  );
};

export default ExperienceForm;
