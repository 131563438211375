import { CellRenderer } from 'components/table/cellRenderer';
import { RowChildren } from 'components/table/rowChildren';
import styles from 'components/table/table.module.scss';
import { ChildrenRows, ColumnDefinition, Identifiable } from 'components/table/types';
import React, { useCallback, useMemo } from 'react';

interface Props<T, R> {
  readonly columns: ReadonlyArray<ColumnDefinition<T>>;

  readonly row: T;
  readonly rowChildren?: ChildrenRows<R>;
  readonly showChildrenHeader: boolean;

  onClick?(row: T): void;
}

export function TableRow<T, R extends Identifiable = never>({
  row,
  columns,
  rowChildren,
  showChildrenHeader,
  onClick,
}: Props<T, R>): React.ReactElement {
  const handleClick = useCallback((): void => {
    onClick?.(row);
  }, [onClick, row]);

  const className = useMemo(
    (): string => (onClick ? 'group cursor-pointer' : 'group cursor-default'),
    [onClick],
  );

  return (
    <>
      <tr className={styles.separator}>
        <td colSpan={columns.length} className="h-1" />
      </tr>
      <tr className={className} onClick={handleClick}>
        {columns.map(
          (column: ColumnDefinition<T>): React.ReactElement => (
            <td key={String(column.name)} className="group" colSpan={column.span ?? 1}>
              <CellRenderer column={column} value={row[column.name]} row={row} />
            </td>
          ),
        )}
      </tr>
      <RowChildren
        data={rowChildren}
        parentSpan={columns.length}
        showHeader={showChildrenHeader}
        onClick={handleClick}
      />
    </>
  );
}
