import { CommonFilter } from 'components/filters/helpers';
import { isBeta } from 'utils';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const createFiltersConfig = (portfolioId: string): CommonFilter[] => {
  if (!portfolioId || portfolioId === '') return [];

  const currencyFilterDef: CommonFilter = {
    type: 'backend',
    label: 'Currency',
    name: 'relationship_currency',
    url: `${API_V1_PATH}/filters/portfolios/${portfolioId}/currencies`,
  };

  return [
    {
      type: 'backend',
      label: 'Company',
      name: 'company_name',
      url: `${API_V1_PATH}/filters/portfolios/${portfolioId}/companies`,
    },
    ...(isBeta ? [] : [currencyFilterDef]),
    {
      type: 'backend',
      label: 'Subsidiary Geographies',
      name: 'subsidiary_geography',
      url: `${API_V1_PATH}/filters/portfolios/${portfolioId}/subsidiary-geographies`,
    },
    {
      type: 'backend',
      label: 'Relationship',
      name: 'relationship_type',
      url: `${API_V1_PATH}/filters/portfolios/${portfolioId}/relationship-types`,
    },
    {
      type: 'backend',
      label: 'Industrial Activity',
      name: 'industrial_activity',
      url: `${API_V1_PATH}/filters/portfolios/${portfolioId}/industries`,
    },
    {
      type: 'backend',
      label: 'Tags',
      name: 'tags',
      url: `${API_V1_PATH}/filters/portfolios/${portfolioId}/tags`,
    },
  ];
};

export default createFiltersConfig;
