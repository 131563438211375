import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly disabled?: boolean;
  readonly clickData?: any;
  readonly stopPropagation?: boolean;

  onClick?(clickData?: any): void;
}

export const Clickable: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  disabled = false,
  stopPropagation = true,
  clickData,
  onClick,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  // FIXME: no onClick handler should imply disabled and there should be no need for the flag
  const className = useMemo(
    (): string =>
      disabled ? 'opacity-50 pointer-events-none' : onClick ? 'cursor-pointer' : 'cursor-default',
    [disabled, onClick],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent): void => {
      if (event === null) {
        return;
      }

      if (stopPropagation && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }

      onClick?.(clickData);
    },
    [clickData, onClick, stopPropagation],
  );

  if (!React.isValidElement(children)) {
    throw new Error('<Clickable />: expecting a valid element single child');
  }
  const { props } = children;

  // Do not create a new DOM node, that simple makes
  // performance worse. Instead, let's clone the original
  // child with some extra properties ;)
  return React.cloneElement(children, {
    ...props,
    className: [props.className, className].join(' '),
    onClick: handleClick,
  });
};
