import { Button } from 'components/genericButton';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LibraryItem } from 'views/EarnAndBuy/api';

interface Props {
  readonly item: LibraryItem;
}

export const Ownership: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const navigate = useNavigate();

  const goToCheckout = useCallback((): void => {
    navigate(`/earn-n-buy/buy/checkout/${item.id}`);
  }, [item.id, navigate]);

  switch (item.status) {
    case 'Purchased':
      return (
        <div className="flex items-center gap-2">
          <SVGIcon name="success-icon" className="w-4" />{' '}
          <p className="text-center text-gray">Purchased Content </p>
        </div>
      );
    case 'Bookmarked':
      return (
        <div className="mx-auto">
          <Button label="Buy" type="button" variant="primary" onClick={goToCheckout} />
        </div>
      );
    default:
      return <></>;
  }
};
