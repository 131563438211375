import { Elements } from '@stripe/react-stripe-js';
import { PaymentIntent, StripeElementsOptions } from '@stripe/stripe-js';
import axios, { AxiosResponse } from 'axios';
import { Clickable } from 'components/clickable';
import { ConditionalRender } from 'components/conditionalRenderer';
import SpinnerLoader from 'components/spinnerLoader';
import { useFetch } from 'hooks/useFetch';
import { useGtag } from 'hooks/useGtag';
import React, { useEffect, useMemo, useState } from 'react';
import { ProcessingState, ProcessingStateEnum } from 'types/processingState';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { initializeStripe } from 'utils/initializeStripe';
import { HttpStatusCreated, HttpStatusPaymentRequired } from 'utils/statusCodes';
import { Pack } from 'views/DataAnalysis/Company/Details/DownloadQuotaExceededModal/pack';

interface OneClickReportPack {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly price: number;
}

const gTagEventHeaders = {
  category: 'one-click-report',
  label: '1-click-report',
};

interface Props {
  onSuccess(): void;
  onFailure(): void;
  onClose(): void;
}

const globalStripe = initializeStripe();
const emptyArray: OneClickReportPack[] = [];

export const Content: React.FC<Props> = ({
  onSuccess,
  onFailure,
  onClose,
}: Props): React.ReactElement => {
  const [ready] = useState<boolean>(false);
  const [currentPack, setCurrentPack] = useState<OneClickReportPack | null>(null);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null);
  const { trackEvent } = useGtag();
  const [paymentProcessingState] = useState<ProcessingState<any>>(ProcessingState.idle());

  const [fetchPacksProcessingState, packs] = useFetch<OneClickReportPack[]>(
    'products/1-click-report-packs',
    emptyArray,
  );
  const [processing, setProcessing] = useState<boolean>(false);

  const stripeOptions = useMemo(
    (): StripeElementsOptions => ({ clientSecret: paymentIntent?.client_secret ?? undefined }),
    [paymentIntent?.client_secret],
  );

  useEffect((): void => {
    trackEvent('open_payment', gTagEventHeaders);
    return trackEvent('close_payment', gTagEventHeaders);
  }, [trackEvent]);

  useEffect((): VoidFunction | void => {
    const cancelToken = axios.CancelToken.source();
    if (!currentPack) {
      return;
    } else if (paymentIntent) {
      return;
    }

    const url = `${API_V1_PATH}/products/1-click-report-packs/checkout`;
    setProcessing(true);

    api
      .post(url, {
        product_id: currentPack.id,
      })
      .then((response: AxiosResponse): void => {
        switch (response.status) {
          case HttpStatusCreated:
            onSuccess();
            return;
          case HttpStatusPaymentRequired:
            setPaymentIntent(response.data);
            break;
        }
      })
      .catch((error: any): void => {
        console.warn(error);
        onFailure();
      })
      .finally((): void => {
        setProcessing(false);
      });

    return (): void => {
      cancelToken.cancel();
    };
  }, [currentPack, onFailure, onSuccess, paymentIntent, trackEvent]);

  // const handleReady = useCallback((): void => setReady(true), []);
  //
  // const handlePaymentSucceeded = useCallback((): void => {
  //   onSuccess();
  //   onClose();
  //   trackEvent('payment_succeeded', {
  //     ...gTagEventHeaders,
  //     pack: currentPack,
  //   });
  // }, [onClose, onSuccess, trackEvent, currentPack]);
  //
  // const handlePaymentFailed = useCallback((): void => {
  //   onFailure();
  //   onClose();
  //   trackEvent('payment_failed', {
  //     ...gTagEventHeaders,
  //     pack: currentPack,
  //   });
  // }, [onClose, onFailure, trackEvent, currentPack]);
  //
  // const handlePaymentStarted = useCallback((): void => {
  //   setPaymentProcessingState(ProcessingState.processing());
  //   trackEvent('payment_started', {
  //     ...gTagEventHeaders,
  //     pack: currentPack,
  //   });
  // }, [trackEvent, currentPack]);

  const showSpinner = useMemo(
    (): boolean =>
      fetchPacksProcessingState.state === ProcessingStateEnum.processing ||
      paymentProcessingState.state === ProcessingStateEnum.processing,
    [fetchPacksProcessingState.state, paymentProcessingState.state],
  );

  return (
    <>
      <p className="py-3">
        You have reached your download limit for this month. You can either wait until next month or
        buy one of our packs.
      </p>

      <ConditionalRender renderIf={currentPack === null}>
        <div className="flex items-center justify-center py-4 gap-8">
          {packs.map(
            (pack: OneClickReportPack): React.ReactElement => (
              <Clickable key={pack.id} clickData={pack} onClick={setCurrentPack}>
                <div>
                  <Pack
                    name={pack.name}
                    description={pack.description}
                    price={pack.price}
                    currency="USD"
                  />
                </div>
              </Clickable>
            ),
          )}
        </div>
        <div className="flex items-center justify-end gap-3 pt-8 px-4">
          <button className="px-4 h-10 text-gray hover:bg-gray-medium rounded" onClick={onClose}>
            Cancel
          </button>
        </div>
      </ConditionalRender>

      {currentPack !== null && (
        <>
          <div className="flex items-start gap-8">
            <div className="py-3">
              <Pack
                name={currentPack.name}
                description={currentPack.description}
                price={currentPack.price}
                currency="USD"
              />
            </div>
            {paymentIntent !== null && paymentIntent.client_secret !== null ? (
              <Elements stripe={globalStripe} options={stripeOptions}>
                {/*<PaymentForm*/}
                {/*  ref={formRef}*/}
                {/*  ready={ready}*/}
                {/*  onPaymentCanceled={onClose}*/}
                {/*  onPaymentStarted={handlePaymentStarted}*/}
                {/*  onPaymentSucceeded={handlePaymentSucceeded}*/}
                {/*  onPaymentFailed={handlePaymentFailed}*/}
                {/*  onPaymentSoftError={handlePaymentFailed}*/}
                {/*  onReady={handleReady}*/}
                {/*/>*/}
              </Elements>
            ) : null}
            <SpinnerLoader visible={processing} />
          </div>
          {!ready && (
            <div className="flex items-center justify-end gap-3 pt-8 px-4">
              <button className="px-4 h-10 text-gray hover:bg-gray-medium rounded">Cancel</button>
            </div>
          )}
        </>
      )}

      <SpinnerLoader visible={showSpinner} />
    </>
  );
};
