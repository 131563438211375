import { getTimezoneOffset } from 'date-fns-tz';
import { isNumber } from 'lodash';

const MS_PER_MINUTE = 1000 * 60;
const MS_PER_HOUR = 1000 * 3600;

export interface Time {
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;
}

export const isTime = (value: any): value is Time => {
  if (value === null) return true;
  return isNumber(value.hours) && isNumber(value.minutes) && isNumber(value.seconds);
};

export function formatTime(time: Time, timezone?: string): string;
export function formatTime(time: null, timezone?: string): null;
export function formatTime(time: Time | null, timezone?: string): string | null;

export function formatTime(time: Time | null, timezone?: string): string | null {
  if (!time) return null;

  const offset = timezone === undefined ? 0 : getTimezoneOffset(timezone);
  const hoursOffset = Math.round(offset / MS_PER_HOUR);
  const minuteOffset = Math.round((offset - MS_PER_HOUR * hoursOffset) / MS_PER_MINUTE);
  const date = new Date(
    1,
    0,
    1,
    time.hours + hoursOffset,
    time.minutes + minuteOffset,
    time.seconds,
    0,
  );

  return date
    .toLocaleTimeString('en', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .toUpperCase();
}

export const serializeTime = (time: Time | null): string | null => {
  if (!time) return null;

  const hour = time.hours.toString(10).padStart(2, '0');
  const minute = time.minutes.toString(10).padStart(2, '0');
  const second = time.seconds.toString(10).padStart(2, '0');

  return `${hour}:${minute}:${second}`;
};
