import { Notification } from 'redux/reducers/notificationsReducer';

export const markMultipleAsSeen = (
  allNotifications: Notification[],
  seenNotifications: Notification[],
): Notification[] => {
  const seen: { [key: string]: boolean } = seenNotifications.reduce(
    (mapped: Record<string, boolean>, item: Notification): Record<string, boolean> => ({
      ...mapped,
      [item.id]: true,
    }),
    {},
  );

  return allNotifications.map(
    (item: Notification): Notification => ({ ...item, seen: seen[item.id] || item.seen }),
  );
};
