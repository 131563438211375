import { AlgoNotesTable } from 'components/algoNotesTable';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { algoNotesSelector } from 'redux/reducers/algoNotesReducer';
import { fetchAlgoNotesAction } from 'redux/services/algoNotesServices';

export const AlgoNotes: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const { notes, loading } = useSelector(algoNotesSelector);
  const { companyId, noteId } = useParams<{
    readonly companyId: string;
    readonly noteId: string;
  }>();

  if (!companyId) {
    throw new Error('cannot reach this page without the company id in the path');
  }

  const handleRefresh = useCallback(
    (parameters: Record<string, string>): VoidFunction => {
      return dispatch(fetchAlgoNotesAction(companyId, parameters));
    },
    [companyId, dispatch],
  );

  return (
    <AlgoNotesTable
      algoNotesPage={notes}
      loading={loading}
      type="company"
      currentNoteId={noteId}
      objectId={companyId}
      refresh={handleRefresh}
    />
  );
};
