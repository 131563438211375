import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { stopPropagation } from 'utils/ignoreEvent';
import { defaultOptions } from 'utils/popper';

interface Props {
  readonly isOpen: boolean;
  readonly anchor: HTMLElement | null;
  readonly rounded?: boolean;
  readonly className?: string;

  onClose(): void;
}

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  anchor,
  children,
  rounded = false,
  className = 'z-popup',
  onClose,
}: React.PropsWithChildren<Props>): React.ReactElement | null => {
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [boundary, setBoundary] = useState<HTMLElement | null>(null);
  const popperOptions = useMemo(
    (): any => defaultOptions(boundary, { withSameWidth: true }),
    [boundary],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void => {
    update?.();
  }, [update]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={setBoundary} className={`fixed ${className} inset-0`} onClick={onClose}>
      <div
        ref={setPopup}
        className={`bg-white shadow-md min-w-calendar overflow-y-auto scroller ${
          rounded ? ' rounded-md' : ''
        }`}
        style={{ ...styles.popper }}
        {...attributes.popper}
        onClick={stopPropagation}
      >
        {children}
      </div>
    </div>,
    document.body,
  );
};
