import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React from 'react';
import { PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem;
}

export const ClientOrCustomerCashManagementOrDepositsForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem>();

  return (
    <>
      <Select
        id="currency"
        label="Currency of cash deposits"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="annualMonetaryIcomFromClient"
        value={item.annualMonetaryIncomeFromClient}
        label="Annual monetary income from client"
        onValueChange={updaterFor('annualMonetaryIncomeFromClient')}
      />
    </>
  );
};
