import Checkbox from 'components/DEPRECATED/checkbox/Checkbox';
import React, { useCallback, useMemo } from 'react';
import { title } from 'utils';
import { ActivableComboBoxItem, ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';

interface Props {
  readonly options: ReadonlyArray<ComboBoxItem<number>>;
  readonly selectedOptions: readonly number[];
  readonly deletedOptions?: ReadonlyArray<ActivableComboBoxItem<number>>;

  onChange(value: number[]): void;
  onDeletedChange?(values: Array<ActivableComboBoxItem<number>>): void;
}

export const CheckboxGroup: React.FC<Props> = ({
  options,
  selectedOptions,
  deletedOptions,
  onChange,
  onDeletedChange,
}: Props): React.ReactElement => {
  const createCheckboxChangeHandler = useCallback(
    (id: number): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;
        if (checked) {
          onChange([...selectedOptions, id]);
        } else {
          onChange(selectedOptions.filter((value: number): boolean => value !== id));
        }
      },
    [onChange, selectedOptions],
  );

  const createCheckboxDeletedChangeHandler = useCallback(
    (item: ActivableComboBoxItem<number>): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;

        const modifiedOptions =
          deletedOptions?.map(
            (value: ActivableComboBoxItem<number>): ActivableComboBoxItem<number> => ({
              ...value,
              active: value.id === item.id ? checked : value.active,
            }),
          ) ?? [];

        onDeletedChange?.(modifiedOptions);
      },
    [deletedOptions, onDeletedChange],
  );
  const selection = useMemo(
    (): Record<number, boolean> =>
      selectedOptions.reduce(
        (s: Record<number, boolean>, next: number): Record<number, boolean> => ({
          ...s,
          [next]: true,
        }),
        {},
      ),
    [selectedOptions],
  );

  const optionsMapper = useCallback(
    (value: ComboBoxItem<number>): React.ReactElement => (
      <Checkbox
        key={value.id}
        label={title(value.name)}
        checked={!!selection[value.id]}
        className="w-1/2 mb-4"
        onChange={createCheckboxChangeHandler(value.id)}
      />
    ),
    [createCheckboxChangeHandler, selection],
  );

  const deletedOptionsMapper = useCallback(
    (value: ActivableComboBoxItem<number>): React.ReactElement => (
      <Checkbox
        key={value.id}
        label={title(value.name) + '*'}
        checked={value.active}
        className="w-1/2 mb-4"
        onChange={createCheckboxDeletedChangeHandler(value)}
      />
    ),
    [createCheckboxDeletedChangeHandler],
  );

  return (
    <div className="flex flex-wrap">
      <>{options.map(optionsMapper)}</>
      <>{deletedOptions?.map(deletedOptionsMapper)}</>
    </div>
  );
};
