import { CancelToken } from 'axios';
import { Indicator } from 'redux/reducers/indicatorsReducer';
import { Page } from 'types/page';
import settings from 'utils/config/appSettings';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const listIndicators = async (
  token: CancelToken,
  parameters: Record<string, string>,
): Promise<Page<Indicator>> => {
  const response = await api.get(`${API_V1_PATH}/indicators`, {
    params: { ...parameters, page_size: settings.defaultPageSize },
    cancelToken: token,
  });
  const { data: page } = response;
  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return Page.map(page, (item: any): Indicator => ({ ...item, id: item.ticker }));
};

export const listPopularIndicators = async (
  token: CancelToken,
  parameters: Record<string, string>,
): Promise<Page<Indicator>> => {
  const response = await api.get(`${API_V1_PATH}/indicators/popular`, {
    params: { ...parameters, page_size: settings.defaultPageSize },
    cancelToken: token,
  });
  const { data: page } = response;
  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return Page.map(page, (item: any): Indicator => ({ ...item, id: item.ticker }));
};
