import { Dispatch } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import { PaymentRequiredErr } from 'constants/paymentRequiredError';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import {
  fetchValueChainFailed,
  fetchValueChainStarted,
  fetchValueChainSucceeded,
  setCurrentValueChainNodeChildren,
  setValueChainTableData,
  updateValueChainTableData,
} from 'redux/reducers/valueChainReducer';
import {
  ValueChainCategoryInfo,
  ValueChainEntityType,
  ValueChainNode,
  ValueChainRequestParams,
  ValueChainTableRow,
} from 'types/organization/types';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { getErrorMessage } from 'utils/getErrorMessage';
import { HttpStatusPaymentRequired } from 'utils/statusCodes';

export const loadMoreValueChainTableData =
  (organizationId: string, params: ValueChainRequestParams, cancelToken?: CancelToken) =>
  (dispatch: Dispatch) => {
    api
      .get(`${API_V1_PATH}/value-chain/${organizationId}/table`, {
        params,
        cancelToken,
      })
      .then(result => {
        const { data } = result.data ?? {};
        if (Array.isArray(data)) {
          const valueChainTable = result.data;

          dispatch(updateValueChainTableData(valueChainTable));
        } else throw new Error();
      })
      .catch(err => {
        if (err?.message && err.message !== 'request_cancelled')
          dispatch(
            showInfoModal({
              type: 'error',
              message: err?.message || errorMessageDefault,
            }),
          );
      });
  };

export const loadValueChainTableData =
  (organizationId: string, params: ValueChainRequestParams, cancelToken?: CancelToken) =>
  (dispatch: Dispatch) => {
    api
      .get(`${API_V1_PATH}/value-chain/${organizationId}/table`, {
        params,
        cancelToken,
      })
      .then(result => {
        const { data } = result.data ?? {};
        if (Array.isArray(data)) {
          const valueChainTable = result.data;
          dispatch(setValueChainTableData(valueChainTable));
        } else throw new Error();
      })
      .catch(err => {
        if (err?.message && err.message !== 'request_cancelled')
          dispatch(
            showInfoModal({
              type: 'error',
              message: err?.message || errorMessageDefault,
            }),
          );
      });
  };

export const loadValueChainOrganizationCategories =
  (ownerOrganization: ValueChainNode, params: ValueChainRequestParams, cancelToken?: CancelToken) =>
  async (dispatch: Dispatch) => {
    const url = `${API_V1_PATH}/value-chain/${ownerOrganization.entityId}/graph`;
    dispatch(fetchValueChainStarted());

    const toGraphNode = (categoryInfo: ValueChainCategoryInfo): ValueChainNode => {
      return {
        entityId: categoryInfo.id + ownerOrganization.entityId,
        entityType: categoryInfo.entity_type as ValueChainEntityType,
        label: categoryInfo.label,
        count: categoryInfo.count,
        ownerOrganization: ownerOrganization,
        children: [],
      };
    };

    try {
      const { data } = await api.get(url, { params, cancelToken });
      if (data) {
        const entries = Object.values<ValueChainCategoryInfo>(data);

        dispatch(setCurrentValueChainNodeChildren(entries.map(toGraphNode)));
        dispatch(fetchValueChainSucceeded());
      }
    } catch (error) {
      dispatch(fetchValueChainFailed(getErrorMessage(error)));
    }
  };

export const loadValueChainCategoryChildren =
  (category: ValueChainNode, params: ValueChainRequestParams, cancelToken?: CancelToken) =>
  async (dispatch: Dispatch) => {
    const { ownerOrganization } = category;
    if (!ownerOrganization) {
      throw new Error('a category must be owned by an organization');
    }
    dispatch(fetchValueChainStarted());

    try {
      const url = `${API_V1_PATH}/value-chain/${ownerOrganization.entityId}/category/${category.entityType}`;
      const response = await api.get(url, { params, cancelToken });
      if (response.status !== 200) {
        if (response.status === HttpStatusPaymentRequired) {
          dispatch(fetchValueChainFailed(PaymentRequiredErr));
        }
        // Do nothing in case of error, we do not want to disturb the user
        return;
      }

      const tableData = response.data;
      const rows = tableData.data;

      const children =
        rows?.map((item: ValueChainTableRow): ValueChainNode => {
          return {
            entityId: item.entity_id,
            entityType: item.entity_type as ValueChainEntityType,
            count: null,
            label: item.label,
            ownerOrganization: category.ownerOrganization,
            children: [],
          };
        }) ?? [];

      dispatch(setValueChainTableData(tableData));
      dispatch(setCurrentValueChainNodeChildren(children));
      dispatch(fetchValueChainSucceeded());
    } catch (error) {
      dispatch(fetchValueChainFailed(getErrorMessage(error)));
    }
  };
