import { Dispatch } from '@reduxjs/toolkit';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { setProcessingState, setStreams, TimelineAction } from 'redux/reducers/timelineReducer';
import { getStreamsService } from 'redux/services/timelineService';
import { ProcessingState } from 'types/processingState';
import { TimelineFilters } from 'types/timeline';

export const getStreamsAction =
  (filters: TimelineFilters): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setProcessingState(ProcessingState.processing(TimelineAction.loadingStreams)));
    try {
      const streams = await getStreamsService(filters);

      dispatch(setStreams(streams));
      dispatch(setProcessingState(ProcessingState.success()));
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
      dispatch(setProcessingState(ProcessingState.fatalError(error?.message)));
    } finally {
      dispatch(setProcessingState(ProcessingState.idle()));
    }
  };
