import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { ProcessingState, ProcessingStateEnum } from 'types/processingState';

export interface InfoModalState {
  message: string;
  visible: boolean;
  type: 'success' | 'error' | null;
  shouldLogout?: boolean;
}

export interface Feature {
  id: number;
  name: string;
  description: string;
}

export enum BillingInterval {
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum ViewAccess {
  viewAccess = 'view-access',
}

export enum UsageAction {
  create = 'create',
  addItem = 'add-item',
  downloadOneClickReport = 'download-one-click-report',
}

export enum FeatureType {
  moduleAccess = 'module-access',
  usageLimit = 'usage-limit',
}

interface ApplicationFeatureBase {
  readonly type: FeatureType;
  readonly id: string;
  readonly name: string;
  readonly description: string;
}

export interface ModuleAccessFeature extends ApplicationFeatureBase {
  readonly type: FeatureType.moduleAccess;
  readonly forbidden: boolean;
}

export interface UsageLimitFeature extends ApplicationFeatureBase {
  readonly type: FeatureType.usageLimit;
  readonly usage_limit: number;
  readonly action: UsageAction;
}

export type ApplicationFeature = ModuleAccessFeature | UsageLimitFeature;

export const DefaultFeature = (action: UsageAction | ViewAccess): ApplicationFeature => {
  switch (action) {
    case UsageAction.create:
    case UsageAction.addItem:
    case UsageAction.downloadOneClickReport:
      return {
        type: FeatureType.usageLimit,
        id: '',
        name: '',
        description: '',
        usage_limit: Infinity,
        action: action,
      };
    case ViewAccess.viewAccess:
      return {
        type: FeatureType.moduleAccess,
        id: '',
        name: '',
        description: '',
        forbidden: false,
      };
  }
};

export interface SubscriptionPlan {
  id: string;
  name: string;
  description: string;
  monthly_price: number;
  yearly_price: number;
  features: {
    [key: string]: ApplicationFeature;
  };
  free_trial_days?: number;
  upgradable: boolean;
  discount_percent?: number;
}

export enum SubscriptionPlansReducerActions {
  toggleActiveSubscriptionAutoRenewal = 'toggleActiveSubscriptionAutoRenewal',
  requestFreeTrial = 'requestFreeTrial',
  cancelActiveSubscription = 'cancelActiveSubscription',
  getSubscriptionPlans = 'getSubscriptionPlans',
  getActiveSubscription = 'getActiveSubscription',
  payment = 'payment',
}

export interface SubscriptionState {
  infoModal: InfoModalState;
  processingState: ProcessingState<any>;
  subscriptionPlans: SubscriptionPlan[];
  features: Feature[];
}

const initialState: SubscriptionState = {
  infoModal: {
    visible: false,
    message: '',
    type: null,
    shouldLogout: false,
  },
  processingState: ProcessingState.idle(),
  features: [],
  subscriptionPlans: [],
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionPlans: (
      state: SubscriptionState,
      { payload }: PayloadAction<{ plans: SubscriptionPlan[]; features: Feature[] }>,
    ): void => {
      const { processingState } = state;

      if (payload.plans) {
        state.subscriptionPlans = payload.plans;
      }

      if (payload.features) {
        state.features = payload.features;
      }

      if (
        processingState.data === SubscriptionPlansReducerActions.getSubscriptionPlans &&
        processingState.state === ProcessingStateEnum.processing
      ) {
        state.processingState = ProcessingState.idle();
      }
    },
    actionStarted: (
      state: SubscriptionState,
      action: PayloadAction<SubscriptionPlansReducerActions>,
    ): void => {
      state.processingState = ProcessingState.processing(action.payload);
    },
    actionSucceeded: (state: SubscriptionState): void => {
      state.processingState = ProcessingState.transition(
        state.processingState,
        ProcessingStateEnum.success,
      );
    },
    actionCompleted: (state: SubscriptionState): void => {
      state.processingState = ProcessingState.idle();
    },
    actionFailed: (state: SubscriptionState, { payload }: PayloadAction<unknown>): void => {
      state.processingState = ProcessingState.transition(
        state.processingState,
        ProcessingStateEnum.fatalError,
        payload,
      );
    },
  },
});

export const {
  setSubscriptionPlans,
  actionStarted,
  actionSucceeded,
  actionCompleted,
  actionFailed,
} = subscriptionSlice.actions;

export const subscriptionPlansSelector = (state: ApplicationState): readonly SubscriptionPlan[] =>
  state.subscriptions.subscriptionPlans;

export const featuresSelector = (state: ApplicationState): readonly Feature[] =>
  state.subscriptions.features;

export const processingStateSelector = (state: ApplicationState): ProcessingState<any> =>
  state.subscriptions.processingState;

export const isProcessingStateForActions = (
  processingState: ProcessingState<any>,
  state: ProcessingStateEnum,
  ...actions: SubscriptionPlansReducerActions[]
) => {
  if (processingState.state !== state) {
    return false;
  }

  return !!actions.find(
    (action: SubscriptionPlansReducerActions): boolean => processingState.data === action,
  );
};

export const isUsageLimitFeature = (feature: ApplicationFeature): feature is UsageLimitFeature =>
  feature.type === FeatureType.usageLimit;

export default subscriptionSlice.reducer;
