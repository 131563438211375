import { AmountInput } from 'components/amountInput';
import React from 'react';
import { PortfolioInvestmentResidentialRealStateItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioInvestmentResidentialRealStateItem;
}

export const RealEstateInvestmentResidentialExtraInputs: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioInvestmentResidentialRealStateItem>();

  return (
    <AmountInput
      name="rentalIncome"
      label="Annual Net Rental Income"
      value={item.rentalAnnualIncomeAmount}
      currency={item.rentalAnnualIncomeCurrency}
      onCurrencyChange={updaterFor('rentalAnnualIncomeCurrency')}
      onValueChange={updaterFor('rentalAnnualIncomeAmount')}
    />
  );
};
