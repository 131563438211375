import { Dropdown } from 'components/dropdown';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { stopPropagation } from 'utils/ignoreEvent';
import DropdownOption from 'views/Landing/Onboarding/components/DropdownOption';
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';
import { Input } from 'views/Landing/Onboarding/components/Input';

interface Props {
  readonly id: string;
  readonly value: string;
  readonly name: string;
  readonly label: string;
  readonly placeholder: string;
  readonly options: readonly ComboBoxItem[];
  readonly rightDecorator?: React.ReactElement | null;
  readonly isDropdownOpen: boolean;
  readonly centerAt?: string | null;

  onDropdownClose(): void;
  onFocus?(): void;
  onChange(value: string): void;
  onValuePicked(value: ComboBoxItem | null): void;
}

export const ComboBoxBase: React.FC<Props> = ({
  id,
  name,
  value,
  label,
  placeholder,
  options,
  rightDecorator,
  centerAt,
  isDropdownOpen,
  onDropdownClose,
  onFocus,
  onChange,
  onValuePicked,
}: Props): React.ReactElement => {
  const { current: scrollElements } = useRef<Map<string, HTMLElement>>(new Map());
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(null);

  useEffect((): void => {
    if (!isDropdownOpen) {
      return;
    }

    const getActiveElement = (): HTMLElement | null | undefined => {
      const elements = Array.from(scrollElements.values());
      if (centerAt) {
        return scrollElements.get(centerAt);
      } else {
        return elements[0];
      }
    };
    const currentElement = getActiveElement();
    // Scroll this element to be on top of the view
    currentElement?.scrollIntoView();
  }, [centerAt, scrollElements, isDropdownOpen]);

  const createScrollElementSetter = useCallback(
    (option: ComboBoxItem): React.RefCallback<HTMLDivElement> =>
      (ref: HTMLDivElement | null): void => {
        if (ref === null) {
          return;
        }
        scrollElements.set(option.id, ref);
      },
    [scrollElements],
  );

  const createValueSetter = useCallback(
    (option: ComboBoxItem): VoidFunction =>
      (): void => {
        onValuePicked(option);
      },
    [onValuePicked],
  );

  const optionsElements = useMemo(
    (): React.ReactElement => (
      <>
        {options.map((option: ComboBoxItem): React.ReactElement => {
          return (
            <DropdownOption
              key={option.id}
              ref={createScrollElementSetter(option)}
              label={option.name}
              value={option.id}
              onClick={createValueSetter(option)}
            />
          );
        })}
      </>
    ),
    [createScrollElementSetter, createValueSetter, options],
  );

  return (
    <>
      <div className="relative" ref={setDropdownAnchor}>
        <Input
          id={id}
          name={name}
          value={value}
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
        />
        <div
          className="absolute flex items-center justify-center top-0 right-0 bottom-0 p-4 pointer-events-none"
          onClick={stopPropagation}
        >
          {rightDecorator}
        </div>
      </div>
      <Dropdown
        isOpen={isDropdownOpen && options.length > 0}
        anchor={dropdownAnchor}
        onClose={onDropdownClose}
        className="z-dropdown"
      >
        {optionsElements}
      </Dropdown>
    </>
  );
};
