import { ReactComponent as DownArrow } from 'assets/icons/svg/down-arrow.svg';
import axios from 'axios';
import { Clickable } from 'components/clickable';
import { Select, SelectButtonProps, SelectItem } from 'components/DEPRECATED/select';
import { Dropdown } from 'components/filters/components/Dropdown';
import SVGIcon from 'components/icons/SVGIcon';
import Input from 'components/input/Input2';
import { UserAvatar } from 'components/userAvatar';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Connection } from 'redux/reducers/connectionsReducer';
import { Member } from 'sharable/types';
import { AccessMode, accessModeLabels } from 'types/accessMode';
import { isValidEmail } from 'utils';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';

interface SuggestionsState {
  readonly loading: boolean;
  readonly users: Connection[];
}

interface Props {
  readonly id?: string;
  readonly placeholder?: string;
  readonly membersLimit?: number;
  readonly share?: boolean;
  readonly members: readonly Member[];

  onChange(members: readonly Member[]): void;
}

const initialSuggestions: SuggestionsState = { loading: false, users: [] };

const MembersInput: React.FC<Props> = ({
  membersLimit = 5,
  members,
  onChange,
  share = false,
  id,
}) => {
  const [keyword, setKeyword] = useState('');
  const [currentMember, setCurrentMember] = useState<Member | null>(null);
  const [suggestions, setSuggestions] = useState<SuggestionsState>(initialSuggestions);
  const [accessMode, setAccessMode] = useState<AccessMode>(AccessMode.readOnly);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const [error, setError] = useState('');
  const userTypes = Object.values(AccessMode);

  const reset = (): void => {
    setTimeout((): void => {
      setAccessMode(AccessMode.readOnly);
      setSuggestions(initialSuggestions);
      setKeyword('');
      setCurrentMember(null);
    }, 0);
  };

  const handleAddMember = (member: Member) => {
    setError('');

    if (members.findIndex((item: Member): boolean => item.value === member.value) !== -1) {
      setError('Member already added to the list');
    } else {
      onChange([...members, member]);
    }

    reset();
  };

  const handleRemoveMember = (member: Member) => {
    const memberIndex = members.findIndex((item: Member): boolean => item.value === member.value);
    if (memberIndex === -1) {
      console.warn('attempting to remove an item that is not in the array');
      return;
    }

    onChange([...members.slice(0, memberIndex), ...members.slice(memberIndex + 1)]);
    reset();
  };

  const onAccessModeChange = (accessMode: AccessMode): void => {
    if (currentMember !== null) {
      setCurrentMember({ ...currentMember, accessMode });
    }

    setAccessMode(accessMode);
  };

  useEffect((): VoidFunction | void => {
    if (!keyword || keyword === '') {
      setSuggestions({ loading: false, users: [] });
      return;
    }

    setSuggestions(initialSuggestions);

    const tokenSource = axios.CancelToken.source();
    const timeout = setTimeout(() => {
      api
        .get(`${API_V1_PATH}/users/search`, {
          cancelToken: tokenSource.token,
          params: { keyword },
        })
        .then((result): void => {
          if (result.status === HttpStatusOk) {
            setSuggestions({ loading: false, users: result.data ?? [] });
          } else {
            setSuggestions(initialSuggestions);
          }
        });
    }, 500);

    return (): void => {
      tokenSource.cancel();
      clearTimeout(timeout);
    };
  }, [keyword]);

  const handleChangeValue = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentMember(null);
    setKeyword(value);
  };

  const handleSubmit = () => {
    if (members.length >= membersLimit) {
      setError(`Cannot add more than ${membersLimit}`);
      return;
    }

    if (currentMember !== null) {
      handleAddMember(currentMember);
    } else if (isValidEmail(keyword)) {
      handleAddMember({
        value: keyword,
        type: 'external',
        label: keyword,
        accessMode: accessMode,
      });
    } else {
      throw new Error('this should be impossible');
    }
  };

  const suggestionsVisible = useMemo(
    (): boolean =>
      suggestions.loading || (Array.isArray(suggestions.users) && suggestions.users.length > 0),
    [suggestions.loading, suggestions.users],
  );

  const inputValue = currentMember === null ? keyword : currentMember.label;

  const handleMemberSuggestionClick = useCallback(
    (user: Connection): void => {
      setCurrentMember({
        value: user.id,
        label: user.fullname,
        accessMode: accessMode,
        type: 'internal',
      });
      setSuggestions(initialSuggestions);
    },
    [accessMode],
  );

  return (
    <div>
      <div className="flex w-full mt-5 relative" ref={setAnchor}>
        <div className="flex w-full">
          <div className={`w-8/12 relative`}>
            <SVGIcon
              name="members-icon"
              className="top-4 left-4 absolute fill-current text-green z-2 w-4 h-4"
            />
            <Input
              id={id}
              name="member-name"
              value={inputValue}
              onChange={handleChangeValue}
              inputClassName="pl-4 py-3 bg-transparent"
              labelClassName="left-4 -top-1 text-gray"
              className="pl-6"
              placeholder="Add members"
              autoComplete="off"
              error={error !== '' ? error : undefined}
            />

            <Dropdown isOpen={suggestionsVisible} anchor={anchor} padded={false}>
              {suggestions.users.map(
                (user: Connection): React.ReactElement => (
                  <Clickable key={user.id} clickData={user} onClick={handleMemberSuggestionClick}>
                    <div className="flex items-center gap-2 p-2 hover:bg-gray-200">
                      <div>
                        <UserAvatar user={user} />
                      </div>
                      <div className="min-w-0">
                        <div className="text-gray-darkest font-jostSemiBold text-md truncate">
                          {user.fullname}
                        </div>
                        <div className="text-gray font-poppins text-xs truncate overflow-ellipsis">
                          {user.about_me}
                        </div>
                      </div>
                    </div>
                  </Clickable>
                ),
              )}
            </Dropdown>
          </div>
          <div className="w-4/12 border-b-2 border-gray-300 text-sm">
            <Select
              id="access-options-button"
              value={accessMode}
              items={userTypes.map(
                (u: AccessMode): SelectItem<AccessMode> => ({
                  value: u,
                  label: accessModeLabels[u],
                }),
              )}
              buttonComponent={SelectButton}
              onChange={onAccessModeChange}
            />
          </div>
        </div>
        <div className="w-2/12 flex justify-center self-center">
          <button
            id="add-member-button"
            disabled={currentMember === null && !isValidEmail(keyword)}
            className="text-blue disabled:text-gray-300 font-poppinsSemiBold uppercase text-base"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div>
      {members?.length > 0 && (
        <div className="w-full mt-4">
          {members.map((member, index) => {
            return (
              <div
                id={`member-user-${index}`}
                key={member.value}
                className="flex items-center w-full my-2 relative px-4"
              >
                <SVGIcon
                  onClick={() => handleRemoveMember(member)}
                  name="close-popup-icon"
                  className="cursor-pointer w-3 h-3"
                />
                <h2 className="w-3/5 text-base pl-3 truncate">{member.label}</h2>
                <p className={`${share ? 'ml-11' : '-ml-3.5'} w-2/5 text-green-light italic`}>
                  {accessModeLabels[member.accessMode]}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MembersInput;

const SelectButton: React.FC<SelectButtonProps<AccessMode>> = React.forwardRef<
  HTMLDivElement,
  SelectButtonProps<AccessMode>
>(function MemberSelectButton(
  props: SelectButtonProps<AccessMode>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement | null {
  const { value: accessMode, onClick } = props;
  if (accessMode === null) {
    return null;
  }

  return (
    <div ref={ref} className={selectButtonClassName} onClick={onClick}>
      {/* Bad typings in parent component */}
      <span className="italic">{accessModeLabels[accessMode as AccessMode]}</span>
      <DownArrow className="w-3" />
    </div>
  );
});

const selectButtonClassName = 'flex items-center justify-between h-full px-1.5 cursor-pointer';
