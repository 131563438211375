import axios from 'axios';
import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { useQueryParameters } from 'hooks/useQueryParameters';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectedCountrySelector } from 'redux/reducers/industryReducer';
import { getIndustriesService, getMyIndustries } from 'redux/services/industryServices';
import { DataAndAnalysisMainTabs, useDataAndAnalysisMainTab } from 'types/dataAndAnalysisMainTabs';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { CountryOfInterest } from 'views/DataAnalysis/Industry/countries';
import { IndustriesTable } from 'views/DataAnalysis/Industry/industriesTable';
import { MyIndustries } from 'views/DataAnalysis/Industry/myIndustries';

const Industries: React.FC = () => {
  const dispatch = useDispatch<any>();
  const queryParameters = useQueryParameters();
  const currentTab = useDataAndAnalysisMainTab();
  const selectedCountry = useSelector(selectedCountrySelector);

  useEffect(() => {
    mixpanel.track('PageView D&A Industry List');
  }, []);

  useEffect((): VoidFunction => {
    const tokenSource = axios.CancelToken.source();
    const parameters = { ...queryParameters, country: selectedCountry };

    if (currentTab === DataAndAnalysisMainTabs.My) {
      dispatch(getMyIndustries(tokenSource.token, parameters));
    } else {
      dispatch(getIndustriesService(currentTab, tokenSource.token, parameters));
    }

    return (): void => {
      tokenSource.cancel();
    };
  }, [selectedCountry, currentTab, dispatch, queryParameters]);

  const textLineContent = useMemo(
    (): React.ReactElement | string => getTextLineContent(currentTab),
    [currentTab],
  );

  return (
    <MainOutletContent>
      <div className="flex items-start">
        <div className="flex-1">
          <Breadcrumbs path={['DATA & ANALYSIS', 'INDUSTRY']} />
          <TextLine content={textLineContent} />
        </div>
      </div>
      <div className="h-2" />

      <Tabs shrinkFactor={6} variant={TabsVariant.buttons}>
        <Tab
          label="All Industries"
          element={
            <IndustriesTable
              tab={DataAndAnalysisMainTabs.All}
              currency={currencies[selectedCountry]}
            />
          }
        />
        {process.env.REACT_APP_DATA_PORTAL ? null : (
          <Tab label="My Industries" element={<MyIndustries />} path="my-list" />
        )}
        <Tab
          label="Popular Industries"
          element={
            <IndustriesTable
              tab={DataAndAnalysisMainTabs.Popular}
              currency={currencies[selectedCountry]}
            />
          }
          path="popular"
        />
      </Tabs>
    </MainOutletContent>
  );
};

export default Industries;

const currencies: Record<CountryOfInterest, 'USD' | 'GBP' | 'NGN' | 'ZAR'> = {
  US: 'USD',
  GB: 'GBP',
  NG: 'NGN',
  ZA: 'ZAR',
  '': 'USD',
};

const getTextLineContent = (currentTab: DataAndAnalysisMainTabs): React.ReactElement | string => {
  switch (currentTab) {
    case DataAndAnalysisMainTabs.Popular:
      return 'This is a selection of popular and topical industries from across the world that you may find useful as a quick starting point.';
    case DataAndAnalysisMainTabs.All:
      return 'This is a long list of all the industries available on Capnote. Use the filters.ts to narrow down the list.';
    case DataAndAnalysisMainTabs.My:
      return (
        <>
          This page considers the industries of companies in your{' '}
          <Link className="text-blue font-poppinsMedium" to="/watchlist">
            Watchlist
          </Link>{' '}
          or{' '}
          <Link className="text-blue font-poppinsMedium" to="/portfolios">
            Portfolios
          </Link>
          . Change those to adjust these stats.
        </>
      );
    default:
      throw new Error('unknown tab');
  }
};
