import { Filters } from 'components/filters';
import { PeerComparisonsView } from 'components/peerComparisonsView';
import { useLargestNumberInDataset } from 'components/peerComparisonsView/hooks/useLargestNumberInDataset';
import { useSummary } from 'components/peerComparisonsView/hooks/useSummary';
import { ComingSoon } from 'components/tooltip/comingSoon';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchingPeerComparisonsSelector,
  peerComparisonsReset,
  peerComparisonsSelector,
} from 'redux/reducers/industryReducer';
import { fetchPeerComparisons } from 'redux/services/industryServices';
import { getLegendForFinancialStatements } from 'utils';
import { CountryDropdown } from 'views/DataAnalysis/Industry/Details/countryDropdown';

export const PeerComparisons: React.FC = (): React.ReactElement => {
  const { industryId } = useParams<{ industryId: string }>();
  const loading = useSelector(fetchingPeerComparisonsSelector);
  const peerComparisons = useSelector(peerComparisonsSelector);
  const queryParameters = useQueryParameters();

  const dispatch = useDispatch();
  const summary = useSummary(peerComparisons);
  const largestValueInDataset = useLargestNumberInDataset(summary);

  useEffect((): void => {
    dispatch(peerComparisonsReset());
  }, [dispatch, industryId]);

  useEffect((): void => {
    dispatch(fetchPeerComparisons(industryId, queryParameters));
  }, [dispatch, industryId, queryParameters]);

  return (
    <>
      <div className="flex items-start justify-between my-3">
        <ComingSoon>
          <Filters config={[]} onChange={noop} />
        </ComingSoon>
        <CountryDropdown />
      </div>

      <div className="text-base text-gray-darkest font-poppins normal-case mb-4">
        <div>{getLegendForFinancialStatements(largestValueInDataset)}</div>
        <div>Dashes (-) indicate unavailable data</div>
      </div>

      <PeerComparisonsView
        type="industry"
        comparisons={peerComparisons}
        loading={loading}
        filters={[]}
        summary={summary}
        largestValueInDataset={largestValueInDataset}
      />
    </>
  );
};
