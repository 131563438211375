import React from 'react';

interface Props {
  readonly label: string;
  readonly selected: boolean;
  readonly className?: string;

  onClick?(): void;
}

const defaultClassName = 'cursor-pointer text-md text-gray w-full';

export const HeaderMenuItem: React.FC<Props> = ({
  label,
  selected,
  onClick,
  className: propsClassName = defaultClassName,
}: Props): React.ReactElement => {
  if (selected) {
    const className = `${propsClassName} font-poppinsMedium`;

    return (
      <button className={className} onClick={onClick}>
        {label}
      </button>
    );
  } else {
    const className = `${propsClassName} font-poppins`;

    return (
      <button className={className} onClick={onClick}>
        {label}
      </button>
    );
  }
};
