import { CommonFilter } from 'components/filters/helpers';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { experimentalFilter } from 'utils';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const tabToNameMap: { [tab in DataAndAnalysisMainTabs | 'watchlist']: string } = {
  [DataAndAnalysisMainTabs.Popular]: 'popular-companies',
  [DataAndAnalysisMainTabs.My]: 'my-companies',
  [DataAndAnalysisMainTabs.All]: 'all-companies',
  watchlist: 'watchlist',
};

export const createFiltersConfig = (
  tab: DataAndAnalysisMainTabs | 'watchlist',
): readonly CommonFilter[] => {
  const key = tabToNameMap[tab];

  return [
    {
      type: 'backend',
      label: 'Company Name',
      url: `${API_V1_PATH}/filters/companies/${key}/name`,
      name: 'company_name',
    },
    {
      type: 'backend',
      label: 'Country of Domicile',
      url: `${API_V1_PATH}/filters/companies/${key}/countries-of-domicile`,
      name: 'country_of_domicile',
    },
    {
      type: 'backend',
      label: 'Subsidiary Geographies',
      url: `${API_V1_PATH}/filters/companies/${key}/subsidiary-geographies`,
      name: 'subsidiary_geography',
    },
    {
      type: 'backend',
      label: 'Industry',
      url: `${API_V1_PATH}/filters/companies/${key}/industrial-activities`,
      name: 'industrial_activity',
    },
    {
      type: 'backend',
      label: 'Market Price Change',
      url: `${API_V1_PATH}/filters/companies/${key}/market-price-change`,
      name: 'market_price_change',
    },
    {
      type: 'backend',
      label: 'Size (Market Cap)',
      url: `${API_V1_PATH}/filters/companies/${key}/market-cap`,
      name: 'market_cap',
    },
    {
      type: 'backend',
      label: 'Tags',
      url: `${API_V1_PATH}/filters/companies/${key}/tags`,
      name: 'tags',
    },
    {
      type: 'backend',
      label: 'Avg Risk Profile',
      url: `${API_V1_PATH}/filters/companies/${key}/average-risk-profile`,
      name: 'average_risk_profile',
    },
    {
      type: 'backend',
      label: 'Avg Growth Profile',
      url: `${API_V1_PATH}/filters/companies/${key}/average-growth-profile`,
      name: 'average_growth_profile',
    },
    ...experimentalFilter({
      type: 'backend',
      label: 'EBITDA Margin',
      url: `${API_V1_PATH}/filters/companies/${key}/ebitda-margin`,
      name: 'ebitda_margin',
    }),
    ...experimentalFilter({
      type: 'backend',
      label: 'PE Ratio',
      url: `${API_V1_PATH}/filters/companies/${key}/pe-ratio`,
      name: 'pe_ratio',
    }),
    {
      type: 'backend',
      label: 'Key Indicators',
      url: `${API_V1_PATH}/filters/companies/${key}/key-indicators`,
      name: 'key_indicators',
    },
  ];
};

const filtersConfig: { [tab in DataAndAnalysisMainTabs | 'watchlist']: readonly CommonFilter[] } = {
  [DataAndAnalysisMainTabs.Popular]: createFiltersConfig(DataAndAnalysisMainTabs.Popular),
  [DataAndAnalysisMainTabs.My]: createFiltersConfig(DataAndAnalysisMainTabs.My),
  [DataAndAnalysisMainTabs.All]: createFiltersConfig(DataAndAnalysisMainTabs.All),
  watchlist: createFiltersConfig('watchlist'),
};

export default filtersConfig;
