import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

type ErrorType = 'information' | 'error' | 'warning';

interface Props {
  readonly title: string;
  readonly message: string;
  readonly details?: string;
  readonly type?: ErrorType;
  onClose(): void;
}

export const ErrorBox: React.FC<Props> = ({
  title,
  message,
  details,
  type = 'error',
  onClose,
}: Props): React.ReactElement => {
  return (
    <div className="min-w-modal-md font-poppins max-w-lg p-6">
      <div className="flex items-start gap-4">
        {icons[type]}
        <div>
          <h2 className="mb-2 text-2xl">{title}</h2>
          <h6 className="text-gray text-md">{message}</h6>
          {details && (
            <>
              <h4 className="text-xs text-gray mt-4 mb-1">Detalles</h4>
              <p className="text-gray text-xs font-poppinsMedium p-2 border-l-4 rounded border-gray-400 bg-gray-200">
                {details}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-2 font-poppinsSemiBold text-sm mt-3 -mb-2">
        <button className="text-blue p-2 rounded-5 hover:bg-gray-light" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const icons: Record<ErrorType, React.ReactElement> = {
  error: <SVGIcon name="exclamation-circle" className="w-14" />,
  information: <SVGIcon name="info-icon" className="w-14" />,
  warning: <SVGIcon name="info-gray" className="w-14" />,
};
