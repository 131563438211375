import SpinnerLoader from 'components/spinnerLoader';
import React from 'react';
import { FeedComment } from 'redux/reducers/feedDetailsReducer';
import { CommentCard } from 'views/Home/modals/PostDetailsModal/components/commentCard';
import { EMPTY_COMMENTS_MESSAGE } from 'views/Home/strings';

interface Props {
  readonly parentId: string;
  readonly comments: readonly FeedComment[] | null;
  readonly fetching: boolean;
  readonly depth?: number;

  onDelete(commentId: string): void;
}

export const CommentsList: React.FC<Props> = ({
  parentId,
  comments,
  fetching,
  depth = 0,

  onDelete,
}: Props): React.ReactElement => {
  const handleDelete = React.useCallback(
    (commentId: string): void => {
      onDelete(commentId);
    },
    [onDelete],
  );

  if (fetching) {
    return <SpinnerLoader visible={true} />;
  } else if (comments === null) {
    return <></>;
  } else if (comments.length === 0) {
    if (depth === 0) {
      return (
        <div className="h-full text-center text-gray-medium font-poppins text-sm py-6">
          {EMPTY_COMMENTS_MESSAGE}
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <>
        {comments?.map(
          (comment: FeedComment): React.ReactElement => (
            <CommentCard
              key={comment.id}
              parentId={parentId}
              depth={depth}
              comment={comment}
              onDelete={handleDelete}
            />
          ),
        )}
      </>
    );
  }
};
