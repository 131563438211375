import NoTimelineIcon from 'assets/icons/svg/no-streams-icon.svg';
import EmptySection from 'components/emptySection/EmptySection';
import SpinnerLoader from 'components/spinnerLoader';
import { useCurrentEvent } from 'hooks/useCurrentEvent';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  loadingPortfoliosStreamsSelector,
  portfoliosStreamsSelector,
} from 'redux/reducers/portfoliosReducer';
import { getPortfoliosTimeline, getPortfolioTimeline } from 'redux/services/portfolioService';
import { defaultStreamFilters, isYearStreamFilters, TimelineFilters } from 'types/timeline';
import { StreamsList } from 'views/Portfolios/Timeline/components/streamsList';
import { EventManager } from 'views/SmartTools/TimelineAndEvents/components/eventManager';
import { TimelineDateFilterBar } from 'views/SmartTools/TimelineAndEvents/toolbars/timelineDateFilterBar';

const PortfolioTimeline: React.FC = (): React.ReactElement => {
  const [filters, setFilters] = useState<TimelineFilters>(defaultStreamFilters);

  const dispatch = useDispatch<any>();
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const compact = useMemo((): boolean => isYearStreamFilters(filters), [filters]);
  const streams = useSelector(portfoliosStreamsSelector);
  const loading = useSelector(loadingPortfoliosStreamsSelector);
  const [currentEvent, currentStream, loadingEvent] = useCurrentEvent(streams);

  useEffect((): void => {
    // FIXME: add cancellation here
    if (portfolioId !== undefined) {
      dispatch(getPortfolioTimeline(portfolioId, filters));
    } else {
      dispatch(getPortfoliosTimeline(filters));
    }
  }, [filters, dispatch, portfolioId]);

  return (
    <>
      <div className="py-5">
        <TimelineDateFilterBar value={filters} onChange={setFilters} />
      </div>
      <StreamsList streams={streams} compact={compact} filters={filters} />

      {streams.length === 0 && !loading && (
        <EmptySection
          title="No Portfolio Timeline"
          subtitle="Timeline will be updated based on the items in your portfolio"
          icon={NoTimelineIcon}
        />
      )}

      <EventManager event={currentEvent} stream={currentStream} loading={loadingEvent} />
      <SpinnerLoader visible={loading} />
    </>
  );
};

export default PortfolioTimeline;
