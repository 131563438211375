import { add, isBefore, parseISO, sub } from 'date-fns';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProcessingState, ProcessingStateEnum } from 'types/processingState';

export function useKeywordAndDateRangeFilter(
  d: Date,
  q?: number,
  p?: ProcessingState<any>,
): [string, Date, Date, boolean];

// @deprecated we need to fix this and not use the dates here
export function useKeywordAndDateRangeFilter(
  d: null,
  q?: number,
  p?: ProcessingState<any>,
): [string, null, null, boolean];

export function useKeywordAndDateRangeFilter(
  defaultFromDate: Date | null,
  toDateDaysAfter?: number,
  processingState?: ProcessingState<any>,
): [string, Date | null, Date | null, boolean] {
  const [search] = useSearchParams();

  const fromDate = useMemo((): Date | null => {
    const from_date = search.get('from_date');
    if (!from_date) {
      return defaultFromDate;
    }

    return parseISO(from_date);
  }, [defaultFromDate, search]);

  const toDate = useMemo((): Date | null => {
    const to_date = search.get('to_date');
    const from_date = search.get('from_date');
    if (!to_date) {
      if (defaultFromDate === null) {
        return null;
      }

      return add(defaultFromDate, { days: toDateDaysAfter });
    }

    const toDate = parseISO(to_date);
    const fromDate = from_date ? parseISO(from_date) : sub(toDate, { weeks: 1 });
    if (isBefore(toDate, fromDate)) {
      return add(fromDate, { weeks: 1 });
    } else {
      return toDate;
    }
  }, [defaultFromDate, search, toDateDaysAfter]);

  const keyword = useMemo((): string => {
    const keyword = search.get('keyword');
    if (!keyword) {
      return '';
    }

    return keyword;
  }, [search]);

  const searching = useMemo(
    (): boolean =>
      processingState?.state === ProcessingStateEnum.processing && keyword.length !== 0,
    [keyword.length, processingState?.state],
  );

  return [keyword, fromDate, toDate, searching];
}
