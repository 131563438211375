export enum DiscountCodeErrors {
  expired = 'expired',
  notFound = 'notFound',
  exceededUsageLimit = 'exceededUsageLimit',
  notApplicable = 'notApplicable',
  none = 'none',
}

interface PaymentBase {
  readonly type: 'setup-intent' | 'payment-intent' | 'free-trial';
  readonly id: string;
  readonly client_secret: string;
}

interface DiscountBase {
  readonly type: 'percentage' | 'amount';
  readonly code: string;
}

interface PercentDiscount extends DiscountBase {
  readonly type: 'percentage';
  readonly percent_off: number;
}

interface AmountDiscount extends DiscountBase {
  readonly type: 'amount';
  readonly amount_off: number;
}

export type Discount = PercentDiscount | AmountDiscount;

interface Invoice {
  readonly receipt_number: string;
  readonly sub_total: number;
  readonly total: number;
  readonly amount_due: number;
  readonly discount?: Discount;
  readonly period_start: string;
  readonly period_end: string;
}

export interface PaymentIntentPayment extends PaymentBase {
  readonly type: 'payment-intent';
  readonly amount: number;
  readonly invoice: Invoice;
}

interface SetupIntentPayment extends PaymentBase {
  readonly type: 'setup-intent';
}

interface FreeTrialPayment extends PaymentBase {
  readonly type: 'free-trial';
}

export type Payment = PaymentIntentPayment | SetupIntentPayment | FreeTrialPayment;

export interface PaymentMethod {
  readonly id: string;
  readonly card_holder_name: string;
  readonly last4: string;
  readonly brand: string;
  readonly exp_month: string;
  readonly exp_year: string;
}

export const isPaymentIntentPayment = (
  payment: Payment | null | undefined,
): payment is PaymentIntentPayment => payment?.type === 'payment-intent';

export const isSetupIntentPayment = (
  payment: Payment | null | undefined,
): payment is SetupIntentPayment => payment?.type === 'setup-intent';
