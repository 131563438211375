import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { ProcessingState } from 'types/processingState';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { delayedExecution } from 'utils/delayedExecution';

export const useFetch = <T>(
  url: string,
  defaultValue: T,
  expectedSuccessStatus = 200,
): [ProcessingState<any>, T] => {
  const [value, setValue] = useState<T>(defaultValue);
  const [processingState, setProcessingState] = useState<ProcessingState<any>>(
    ProcessingState.idle(),
  );

  const execute = useCallback((): VoidFunction => {
    const cancelToken = axios.CancelToken.source();
    // Reset before fetching
    // setValue(defaultValue);
    // The async keyword helps to make the code clear and
    // avoids the callback hell
    const executeAsync = async (): Promise<void> => {
      setValue(defaultValue);
      setProcessingState(ProcessingState.processing());
      try {
        const response = await delayedExecution(
          api.get(`${API_V1_PATH}/${url}`, { cancelToken: cancelToken.token }),
          0.175,
        );
        if (response.status !== expectedSuccessStatus) {
          setProcessingState(ProcessingState.error(new Error(response.data)));
        } else {
          setValue(response.data);
          setProcessingState(ProcessingState.success());
        }
      } catch (error: any) {
        setProcessingState(ProcessingState.error(error));
      }
    };
    void executeAsync();

    return (): void => {
      cancelToken.cancel();
    };
  }, [defaultValue, expectedSuccessStatus, url]);

  useEffect(execute, [execute]);

  return [processingState, value];
};
