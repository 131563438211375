import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Comment {
  date: string;
  text: string;
  user_email: string;
  fullname: string;
}

export interface FinancialCommentsError {
  readonly code: number;
  readonly message: string;
}

export interface FinancialCommentsState {
  loading: boolean;
  lastError: string | FinancialCommentsError | null;
  comments: Comment[];
}

const initialState: FinancialCommentsState = {
  loading: false,
  lastError: null,
  comments: [],
};

const slice = createSlice({
  name: 'financialComments',
  initialState,
  reducers: {
    getCommentsStart: (state: FinancialCommentsState): void => {
      state.loading = true;
      state.lastError = null;
      state.comments = [];
    },
    getCommentsSuccess: (
      state: FinancialCommentsState,
      { payload }: PayloadAction<Comment[]>,
    ): void => {
      state.loading = false;
      state.comments = payload;
      state.lastError = null;
    },
    getCommentsFailure: (
      state: FinancialCommentsState,
      { payload }: PayloadAction<FinancialCommentsError>,
    ): void => {
      state.loading = false;
      state.lastError = payload;
    },
  },
});

export const { getCommentsStart, getCommentsSuccess, getCommentsFailure } = slice.actions;

export const financialCommentsSelector = (state: {
  financialComments: FinancialCommentsState;
}): FinancialCommentsState => state.financialComments;

export default slice.reducer;
