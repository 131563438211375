import { AxiosResponse } from 'axios';
import Button from 'components/buttons/Button/Button';
import { ConditionalRender } from 'components/conditionalRenderer';
import TagWithDelete from 'components/DEPRECATED/tags/TagWithDelete';
import Input from 'components/input/Input2';
import MembersInput from 'components/membersInput/MembersInput';
import SpinnerLoader from 'components/spinnerLoader';
import { useGtag } from 'hooks/useGtag';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  processingStateSelector,
  StreamForm,
  TimelineAction,
} from 'redux/reducers/timelineReducer';
import { createStreamService, updateStreamService } from 'redux/services/timelineService';
import { Member } from 'sharable/types';
import { ProcessingStateEnum } from 'types/processingState';
import { Stream } from 'types/timeline';
import { isBeta } from 'utils';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { FormErrors, ValidationErrors } from 'utils/forms';
import * as yup from 'yup';

interface Props {
  readonly stream?: Stream;

  onClose(): void;
}

const schema = yup.object().shape({
  name: yup.string().required('Please enter a Stream Name'),
  category: yup.string(),
});

const initialStreamForm = {
  name: '',
  category: 'User',
  members: [],
  tags: [],
};

const CreateEditStreamModal: React.FC<Props> = ({ stream, onClose }: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const processingState = useSelector(processingStateSelector);
  const { trackEvent } = useGtag();

  const [form, setForm] = useState<StreamForm>(initialStreamForm);
  const [tag, setTag] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});

  const onRemoveTag = (t: string) => () =>
    setForm(s => ({ ...s, tags: s.tags ? [...s.tags].filter(t2 => t2 !== t) : [] }));

  const handleAddTags = () => {
    if (tag) {
      const splitTags = tag.toLowerCase().replace(/, /, ',').split(',');
      const newTags: string[] = [];
      splitTags?.forEach(tag => {
        const cleanTag = tag.trim();
        if (cleanTag !== '' && form?.tags?.indexOf(cleanTag) === -1) {
          newTags.push(cleanTag);
        }
      });
      setForm(s => ({ ...s, tags: s.tags ? [...s.tags, ...newTags] : newTags }));
      setTag('');
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleAddTags();
    }
  };

  const handleSubmit = async () => {
    try {
      setErrors({});
      await schema.validate(form, { abortEarly: false });
      if (!stream) {
        dispatch(createStreamService(form));
        trackEvent('end-add-stream', {
          ...form,
        });
      } else {
        dispatch(updateStreamService(stream.id, form));
        trackEvent('end-edit-stream', {
          ...form,
        });
      }
    } catch (error: any) {
      setErrors(ValidationErrors.reduce(error.inner));
    }
  };

  useEffect(() => {
    if (stream) {
      mixpanel.track('PageView Edit Stream');

      api
        .get(`${API_V1_PATH}/timeline/${stream.id}/members`)
        .then((response: AxiosResponse<readonly Member[]>): readonly Member[] => {
          return response.data;
        })
        .then((members: readonly Member[]): void => {
          setForm({
            category: stream.category,
            members: members.map(
              (member: any): Member => ({
                value: member.id,
                label: member.fullname,
                accessMode: member.access_mode,
                type: 'internal',
              }),
            ),
            name: stream.name,
            summary: stream.summary,
            tags: stream.tags,
          });
        })
        .catch((error: any): void => {
          console.warn(error);
        });
    } else {
      mixpanel.track('PageView Create Stream');
      // Reset the form
      setForm(initialStreamForm);
    }
  }, [dispatch, stream]);

  useEffect((): void => {
    if (processingState.state === ProcessingStateEnum.success) {
      onClose();
    }
  }, [onClose, processingState]);

  const showSpinner = useMemo(
    (): boolean =>
      processingState.state === ProcessingStateEnum.processing &&
      (processingState.data === TimelineAction.updatingStream ||
        processingState.data === TimelineAction.creatingStream),
    [processingState.data, processingState.state],
  );

  return (
    <div className="relative w-full md:w-modal-xs text-gray-darkest">
      <div className="mt-6">
        <Input
          placeholder="Timeline Name"
          maxLength={40}
          name="name"
          value={form.name}
          onChange={({ target: { value } }) => setForm(prev => ({ ...prev, name: value }))}
          inputClassName="bg-transparent py-3 pl-4"
          error={errors.name}
          disabled={form.category === 'PORTFOLIO'}
        />
        <ConditionalRender renderIf={!isBeta}>
          <h4 className="my-5 text-gray-darkest">Members</h4>
          <MembersInput
            members={form.members}
            onChange={newMembers => setForm(prev => ({ ...prev, members: newMembers }))}
          />
        </ConditionalRender>
        <div className="mt-6">
          <Input
            placeholder="Short Description (optional)"
            maxLength={150}
            name="summary"
            value={form.summary || ''}
            onChange={({ target: { value } }) => setForm(prev => ({ ...prev, summary: value }))}
            inputClassName="bg-transparent py-3 pl-4"
            error={errors.summary}
          />
        </div>
        <div className="w-full my-5">
          <Input
            name="tags"
            inputClassName="bg-transparent py-3 pl-4"
            onChange={e => setTag(e.target.value)}
            onKeyDown={handleOnKeyDown}
            value={tag}
            placeholder="#Tags"
          />
        </div>

        {form.tags && form.tags.length > 0 && (
          <div className="flex w-full my-5 flex-wrap">
            {form.tags.map((t, index) => (
              <TagWithDelete key={t + index} className="mr-3.5 mb-1" onClickDelete={onRemoveTag(t)}>
                <span>{t}</span>
              </TagWithDelete>
            ))}
          </div>
        )}
        <div className="flex items-center justify-end mt-8">
          <Button
            label={stream ? 'Edit' : 'Create'}
            onClick={handleSubmit}
            width="w-24"
            padding="p-1"
            height="h-12"
          />
        </div>
      </div>
      <SpinnerLoader visible={showSpinner} />
    </div>
  );
};

export default CreateEditStreamModal;
