import { ReactComponent as CommentIcon } from 'assets/icons/svg/comment-off-icon.svg';
import { ReactComponent as DislikeIcon } from 'assets/icons/svg/dislike-icon.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/svg/like-icon.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/svg/share-icon.svg';
import React from 'react';
import { GenericActionButton } from 'views/Home/components/shared/genericActionButton';

interface Props {
  readonly icon: 'like' | 'dislike' | 'comment' | 'share';
  readonly tooltip: string;
  readonly count: number | null;
  readonly highlighted: boolean;

  onClick?(): void;
}

export const CommonActionButton: React.FC<Props> = ({
  icon,
  tooltip,
  count,
  highlighted,
  onClick,
}: Props): React.ReactElement => {
  return (
    <GenericActionButton
      icon={icons[icon]}
      tooltip={tooltip}
      count={count}
      highlighted={highlighted}
      variant="primary"
      onClick={onClick}
    />
  );
};

const icons: {
  readonly like: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  readonly dislike: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  readonly comment: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  readonly share: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
} = {
  like: LikeIcon,
  dislike: DislikeIcon,
  comment: CommentIcon,
  share: ShareIcon,
};
