import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

interface Props {
  onClick?(): void;
}

export const TodayButton: React.FC<Props> = ({ onClick }: Props): React.ReactElement => {
  return (
    <div className="text-sm min-w-max cursor-pointer" onClick={onClick}>
      <span className="inline-block font-poppinsSemiBold uppercase text-blue align-middle">
        Today
      </span>
      <div className="inline-block align-middle ml-2">
        <SVGIcon name="refresh-icon" className="w-3.5 h-3.5" />
      </div>
    </div>
  );
};
