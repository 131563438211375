import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Feature, SubscriptionPlan } from 'redux/reducers/subscriptionPlansReducer';
import { baseURL, defaultAPIHeaders } from 'utils/config/axiosConfig';

const api = createApi({
  reducerPath: 'public',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}/api/v1/public`,
    prepareHeaders: defaultAPIHeaders,
  }),
  tagTypes: [],
  endpoints: builder => ({
    subscriptionPlans: builder.query<readonly SubscriptionPlan[], void>({
      query: (): string => 'subscription-plans',
    }),
    features: builder.query<readonly Feature[], void>({
      query: (): string => 'application-features',
    }),
  }),
});

export default api;

export const { useSubscriptionPlansQuery, useFeaturesQuery } = api;
