import React from 'react';
import { Comment } from 'redux/reducers/financialCommentsReducer';
import { Comment as CommentView } from 'views/DataAnalysis/common/commentCard';

interface Props {
  readonly comments: Comment[];
  readonly loading: boolean;
}

export const CommentsPopupCommentList: React.FC<Props> = ({
  comments,
  loading,
}: Props): React.ReactElement => {
  if (comments.length > 0) {
    return (
      <>
        {comments.map((comment: Comment, index: number) => {
          return (
            <CommentView
              index={index}
              key={index}
              margin="mb-2"
              data={{
                id: index,
                name: comment.fullname || 'Anonymous',
                username: '',
                text: comment.text,
                date: new Date(comment.date),
                photo: '/images/genderless-icon.svg',
              }}
            />
          );
        })}
      </>
    );
  } else {
    return (
      <div className="text-center text-gray text-sm pt-6">
        {!loading && <p>There are no comments yet.</p>}
      </div>
    );
  }
};
