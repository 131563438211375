export enum SearchResultCategory {
  company = 'company',
  industry = 'industry',
  country = 'country',
  indicator = 'indicator',
  post = 'post',
  news = 'news',
  user = 'user',
  event = 'event',
}

interface SearchResultBase {
  readonly id: string;
  readonly name: string;
  readonly category: SearchResultCategory;
  readonly score: number;
}

export interface CompanySearchResultMetadata {
  readonly country: {
    readonly iso2: string;
    readonly name: string;
  };
}

export interface UserSearchResultMetadata {
  readonly about: string;
}

interface CompanySearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.company;
  readonly metadata: CompanySearchResultMetadata;
}

interface IndustrySearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.industry;
}

interface NewsSearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.news;
}

interface PostSearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.post;
}

interface EventSearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.event;
}

interface CountrySearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.country;
}

interface IndicatorSearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.indicator;
}

interface UserSearchResult extends SearchResultBase {
  readonly category: SearchResultCategory.user;
  readonly metadata: UserSearchResultMetadata;
}

export type SearchResult =
  | CompanySearchResult
  | IndustrySearchResult
  | CountrySearchResult
  | UserSearchResult
  | PostSearchResult
  | NewsSearchResult
  | EventSearchResult
  | IndicatorSearchResult;

export interface SearchResultMetadataProp<T> {
  readonly metadata: T;
}
