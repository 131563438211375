import { ReactComponent as MagnifyingGlassIcon } from 'assets/icons/svg/search-icon.svg';
import { Item } from 'components/filters/components/Item';
import { Loader } from 'components/filters/components/Loader';
import { NoFilters } from 'components/filters/components/NoFilters';
import { GenericFilter } from 'components/filters/helpers';
import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly options: GenericFilter[];
  readonly selectedOptions: string[];
  readonly searchable?: boolean;
  readonly loading?: boolean;
  readonly searchKeyword?: string | null;

  onChange(values: string[]): void;
  onSearch?(keyword: string): void;
  onClearAll(): void;
}

export const FilterOptionsDropdown: React.FC<Props> = ({
  options,
  selectedOptions,
  searchable = false,
  loading = false,
  searchKeyword = null,
  onClearAll,
  onChange,
  onSearch,
}: Props): React.ReactElement => {
  const isSelectAllChecked = useMemo(
    (): boolean =>
      options.length > 0 &&
      options.every(
        (item: GenericFilter | null): boolean => item === null /* selection.includes(item.name) */,
      ),
    [options],
  );

  const onSelectAll = useCallback(
    (_: string, checked: boolean): void => {
      if (checked) {
        onChange(options.map(item => item.name));
      } else {
        onChange([]);
      }
    },
    [options, onChange],
  );

  const onItemChange = useCallback(
    (value: string, checked: boolean): void => {
      if (!checked) {
        onChange(selectedOptions.filter((currentValue: string): boolean => value !== currentValue));
      } else {
        onChange([...selectedOptions, value]);
      }
    },
    [onChange, selectedOptions],
  );

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    onSearch?.(value);
  };

  return (
    <div className="relative">
      <div className="sticky">
        {searchable && (
          <div className="relative pl-2 mb-1 w-full">
            <input
              id="search-filter-input"
              name={`${Date.now()}`}
              readOnly={loading}
              value={searchKeyword ?? ''}
              placeholder="Search"
              className="text-left bg-white outline-none border-none font-poppins h-10 px-3 w-full"
              style={{ opacity: loading ? 0.25 : undefined }}
              onChange={onSearchInputChange}
            />
            <div className="absolute top-3 right-3 w-4 h-4">
              <MagnifyingGlassIcon />
            </div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <Item
            label="Select All"
            value="all"
            rounded={true}
            checked={isSelectAllChecked}
            disabled={loading}
            onChange={onSelectAll}
          />
          <div
            id="check-box-clear-all"
            className={`font-poppins font-bold text-sm text-blue cursor-pointer${
              loading ? ' opacity-25' : ''
            }`}
            onClick={onClearAll}
          >
            CLEAR ALL
          </div>
        </div>
      </div>
      <div>
        {loading && <Loader />}
        {options.length === 0 && !loading ? <NoFilters /> : null}
        {options.map(
          (item: GenericFilter): React.ReactElement => (
            <Item
              key={item.name}
              value={item.name}
              label={item.label}
              checked={selectedOptions.includes(item.name)}
              onChange={onItemChange}
            />
          ),
        )}
      </div>
    </div>
  );
};
