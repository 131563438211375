import { Action, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse, CancelToken } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import {
  addCompanyToMyWatchlistFailed,
  addCompanyToMyWatchlistSuccess,
  addCompanyToMyWatchlistUsageLimitExceeded,
  deleteCompanyWatchlistFailed,
  deleteCompanyWatchlistSuccess,
  getBalanceSheet,
  getBalanceSheetFailed,
  getBalanceSheetSuccess,
  getCashFlow,
  getCashFlowFailed,
  getCashFlowSuccess,
  getCompanies,
  getCompaniesFailed,
  getCompaniesSuccess,
  getCompanyDetails,
  getCompanyDetailsFailed,
  getCompanyDetailsSuccess,
  getFinancialHighlights,
  getFinancialHighlightsFailed,
  getFinancialHighlightsSuccess,
  getIncomeStatement,
  getIncomeStatementFailed,
  getIncomeStatementSuccess,
  getMetricsAndIndicators,
  getMetricsAndIndicatorsFailed,
  getMetricsAndIndicatorsSuccess,
  getMyWatchlist,
  getMyWatchlistFailed,
  getMyWatchlistSuccess,
  getPeerComparisonCompanies,
  getPeerComparisonCompaniesFailed,
  getPeerComparisonCompaniesSuccess,
  getSummary,
  getSummaryFailed,
  getSummarySuccess,
  oneClickMemoQuotaExceeded,
  setCompanyTimelineStream,
  startGeneratingOneClickReport,
  startLoadingCompanyTimeline,
  startUpdatingCompanyInWatchlist,
  stopGeneratingOneClickReport,
  stopLoadingCompanyTimeline,
  stopUpdatingCompanyInWatchlist,
  updateGeneratingOneClickReportProgress,
} from 'redux/reducers/companyReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ThunkLikeAction } from 'types/APIAction';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { Company } from 'types/organization/types';
import { PeerComparisons } from 'types/peerComparisons';
import { TimelineFilters } from 'types/timeline';
import { getMarketChangeValue } from 'utils';
import settings from 'utils/config/appSettings';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { delayedExecution } from 'utils/delayedExecution';
import {
  HttpStatusOk,
  HttpStatusPaymentRequired,
  HttpStatusQuotaExceeded,
} from 'utils/statusCodes';

export interface ITitleColumn {
  Header: string;
  accessor: string;
  unit: string;
}

export const getMyWatchlistService =
  (filters?: any, cancelToken?: CancelToken) => (dispatch: Dispatch) => {
    dispatch(getMyWatchlist());

    api
      .get('api/v1/watchlist', { params: filters, cancelToken: cancelToken })
      .then(res => {
        if (res.data) {
          const mappedData = res.data?.map((d: Company) => ({
            ...d,
            company_id: d.id,
            marketPriceChange: getMarketChangeValue(d.price_change),
          }));
          dispatch(getMyWatchlistSuccess(mappedData as Company[]));
        }
      })
      .catch((error: any) => {
        dispatch(getMyWatchlistFailed(error?.message || errorMessageDefault));
        dispatch(
          showInfoModal({
            type: 'error',
            message: error?.message || errorMessageDefault,
          }),
        );
      });
  };

export const getCompaniesService =
  (
    tab: DataAndAnalysisMainTabs,
    cancelToken: CancelToken,
    parameters?: Record<string, string>,
  ): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    const params = { ...parameters, page_size: settings.defaultPageSize };
    const urls: { [key in DataAndAnalysisMainTabs]: string } = {
      [DataAndAnalysisMainTabs.Popular]: 'api/v1/companies/popular',
      [DataAndAnalysisMainTabs.All]: 'api/v1/companies',
      [DataAndAnalysisMainTabs.My]: 'api/v1/companies/my',
    };
    dispatch(getCompanies());

    const response = await delayedExecution(
      api.get(urls[tab], {
        params,
        cancelToken,
      }),
      0.15,
    );
    try {
      const page = response.data;
      // If there's no data, it might be because the request was cancelled, so just ignore it
      if (!page) {
        return;
      }

      dispatch(getCompaniesSuccess(page));
    } catch (error: any) {
      dispatch(getCompaniesFailed());
      dispatch(
        showInfoModal({
          type: 'error',
          message: error?.message || errorMessageDefault,
        }),
      );
    }
  };

export const addCompanyToWatchlistService =
  (companyId: string): ((dispatch: Dispatch<any>) => void) =>
  (dispatch: Dispatch<any>): void => {
    dispatch(startUpdatingCompanyInWatchlist());
    api
      .post(`${API_V1_PATH}/watchlist/`, { id: companyId })
      .then((response: AxiosResponse): void => {
        if (response.status === HttpStatusPaymentRequired) {
          dispatch(addCompanyToMyWatchlistUsageLimitExceeded());
        } else {
          dispatch(addCompanyToMyWatchlistSuccess());
          // FIXME: ideally the BE should return the company object
          dispatch(getMyWatchlistService());
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.message || errorMessageDefault,
          }),
        );
        dispatch(addCompanyToMyWatchlistFailed(err?.message || errorMessageDefault));
      })
      .finally((): void => {
        dispatch(stopUpdatingCompanyInWatchlist());
      });
  };

export const deleteWatchlistService = (companyId: string) => (dispatch: Dispatch) => {
  dispatch(startUpdatingCompanyInWatchlist());
  api
    .delete(`${API_V1_PATH}/watchlist/${companyId}`)
    .then((): void => {
      dispatch(deleteCompanyWatchlistSuccess(companyId));
    })
    .catch((err: Error): void => {
      dispatch(deleteCompanyWatchlistFailed(err?.message || errorMessageDefault));
      dispatch(
        showInfoModal({
          type: 'error',
          message: err?.message || errorMessageDefault,
        }),
      );
    })
    .finally((): void => {
      dispatch(stopUpdatingCompanyInWatchlist());
    });
};

export const getCompanyDetailsService = (companyId: string) => (dispatch: Dispatch) => {
  dispatch(getCompanyDetails(companyId));
  api
    .get(`${API_V1_PATH}/companies/${companyId}`)
    .then(res => {
      if (res.data) {
        dispatch(getCompanyDetailsSuccess(res.data));
      } else throw new Error();
    })
    .catch(err => {
      dispatch(
        showInfoModal({
          type: 'error',
          message: err?.message || errorMessageDefault,
        }),
      );
      dispatch(getCompanyDetailsFailed());
    });
};

export interface StatementRequestOptions {
  readonly dimension: 'MRY' | 'MRQ';
  readonly periods_final_year: number;
  readonly periods_final_quarter?: number;
  readonly periods_final_key: string;
  readonly periods_count: number;
  readonly convert_to_usd?: boolean;
}

const createStatementService =
  <T>(
    statementName: string,
    start: () => Action,
    success: (statement: T) => Action,
    failure: (error?: any) => Action,
  ) =>
  (companyId: string, options: StatementRequestOptions): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(start());
    const params = { ...options };

    try {
      const response: AxiosResponse = await api.get(
        `${API_V1_PATH}/companies/${companyId}/financial/${statementName}`,
        { params },
      );
      if (response.status === HttpStatusOk) {
        dispatch(success(response.data));
      } else {
        dispatch(
          showInfoModal({
            type: 'error',
            message: errorMessageDefault,
          }),
        );
        dispatch(failure());
      }
    } catch (err: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: err?.message || errorMessageDefault,
        }),
      );
      dispatch(failure(err));
    }
  };

export const getSummaryService =
  (companyId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getSummary());

    try {
      const response: AxiosResponse = await api.get(
        `${API_V1_PATH}/companies/${companyId}/financial/profile`,
      );

      if (response.status === HttpStatusOk) {
        dispatch(getSummarySuccess(response.data));
      } else {
        dispatch(
          showInfoModal({
            type: 'error',
            message: errorMessageDefault,
          }),
        );
        dispatch(getSummaryFailed());
      }
    } catch (err: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: err?.message || errorMessageDefault,
        }),
      );
      dispatch(getSummaryFailed());
    }
  };

export const getFinancialHighlightsService = createStatementService(
  'highlights',
  getFinancialHighlights,
  getFinancialHighlightsSuccess,
  getFinancialHighlightsFailed,
);

export const getIncomeStatementService = createStatementService(
  'income',
  getIncomeStatement,
  getIncomeStatementSuccess,
  getIncomeStatementFailed,
);

export const getCashFlowService = createStatementService(
  'cash-flow',
  getCashFlow,
  getCashFlowSuccess,
  getCashFlowFailed,
);

export const getBalanceSheetService = createStatementService(
  'balance-sheet',
  getBalanceSheet,
  getBalanceSheetSuccess,
  getBalanceSheetFailed,
);

export const getMetricsAndIndicatorsService = createStatementService(
  'metrics',
  getMetricsAndIndicators,
  getMetricsAndIndicatorsSuccess,
  getMetricsAndIndicatorsFailed,
);

export const generate1ClickReportService =
  (companyId: string): any =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startGeneratingOneClickReport());
    try {
      const response: AxiosResponse = await api.get(
        `api/v1/companies/${companyId}/1-click-report`,
        {
          responseType: 'blob',
          onDownloadProgress: (event: any): void => {
            dispatch(updateGeneratingOneClickReportProgress((100 * event.loaded) / event.total));
          },
        },
      );
      const fileName = response.headers['x-download-filename'];
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(response.data);
      document.body.append(link);

      link.setAttribute('download', fileName);
      link.click();
      link.remove();
    } catch (error: any) {
      if (error.isAxiosError) {
        const { response } = error;
        if (response.status === HttpStatusQuotaExceeded) {
          dispatch(oneClickMemoQuotaExceeded());
        } else {
          dispatch(
            showInfoModal({
              type: 'error',
              message: error?.message || errorMessageDefault,
            }),
          );
        }
      } else {
        dispatch(
          showInfoModal({
            type: 'error',
            message: error?.message || errorMessageDefault,
          }),
        );
      }
    } finally {
      dispatch(stopGeneratingOneClickReport());
    }
  };

export const getPeerComparisonsService =
  (id: string, filters?: any, token?: CancelToken) => (dispatch: Dispatch) => {
    dispatch(getPeerComparisonCompanies());
    api
      .get(`api/v1/companies/${id}/peer-comparisons`, {
        params: filters,
        cancelToken: token,
      })
      .then(res => {
        if (res.status === HttpStatusOk) {
          const peerComparison = res.data as PeerComparisons;
          dispatch(getPeerComparisonCompaniesSuccess({ peerComparison: peerComparison }));
        } else {
          throw new Error();
        }
      })
      .catch((error: any): void => {
        if (error?.message && error.message !== 'request_cancelled') {
          dispatch(
            showInfoModal({
              type: 'error',
              message: error?.message || errorMessageDefault,
            }),
          );
          dispatch(getPeerComparisonCompaniesFailed());
        }
      });
  };

export const getCompanyTimeline =
  (id: string, filters: TimelineFilters): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startLoadingCompanyTimeline());
    try {
      const response = await api.get(`${API_V1_PATH}/companies/${id}/timeline`, {
        params: filters,
      });
      dispatch(setCompanyTimelineStream(response.data));
    } catch (error: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: error?.message || errorMessageDefault,
        }),
      );
    } finally {
      dispatch(stopLoadingCompanyTimeline());
    }
  };
