import { AnyTabProps } from 'components/tabs/components/baseTabProps';
import { TabBar } from 'components/tabs/components/tabBar';
import { ShrinkFactor, TabsVariant } from 'components/tabs/index';
import React from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
  readonly items: Array<React.ReactElement<AnyTabProps>>;
  readonly tabButtonVariant: TabsVariant;
  readonly shrinkFactor: ShrinkFactor;
  readonly alignment: 'center' | 'left';
}

export const TabsLayout: React.FC<Props> = ({
  items,
  tabButtonVariant,
  shrinkFactor,
  alignment,
}: Props): React.ReactElement => {
  return (
    <div data-name="TabsLayout" className="flex flex-col flex-grow w-full">
      <TabBar
        items={items}
        buttonVariant={tabButtonVariant}
        shrinkFactor={shrinkFactor}
        alignment={alignment}
      />
      <div className="flex flex-col flex-grow">
        <Outlet />
      </div>
    </div>
  );
};
