import { QueryStatus } from '@reduxjs/toolkit/query';
import NoIcon from 'assets/icons/svg/no-portfolios-icon.svg';
import { AddObjectButton } from 'components/addObjectButton';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import EmptySection from 'components/emptySection/EmptySection';
import { ErrorBox } from 'components/errorBox';
import { Filters } from 'components/filters';
import { Modal } from 'components/modal';
import Pagination from 'components/pagination/Pagination';
import { SearchBox } from 'components/searchBox';
import SpinnerLoader from 'components/spinnerLoader';
import { SuccessBox } from 'components/successBox';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import Toggle from 'components/toggle/Toggle';
import { ApplicationModule, exceedsUsageLimit, usePermission } from 'context/authorization';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  permissionCodeBeingUpdatedSelector,
  profilePreferencesSelector,
  UserPermission,
} from 'redux/reducers/profileReducer';
import { UsageAction } from 'redux/reducers/subscriptionPlansReducer';
import { getPreferences, updatePermission } from 'redux/services/profileService';
import { WithOptionalID } from 'types/withId';
import { HttpStatusPaymentRequired, HttpStatusTooManyRequests } from 'utils/statusCodes';
import {
  EarnItem,
  useApplyForListingMutation,
  useCreateEarnItemMutation,
  useGetPostedListingsCountQuery,
  useListListingsQuery,
  useRemoveEarnItemMutation,
  useUpdateEarnItemMutation,
} from 'views/EarnAndBuy/api';
import { ActionsContext, EarnAndBuyActions } from 'views/EarnAndBuy/context';
import columns from 'views/EarnAndBuy/Earn/columns';
import { Details } from 'views/EarnAndBuy/Earn/components/details';
import filters from 'views/EarnAndBuy/Earn/filters';
import {
  CreateListingPayload,
  isUpdateListingPayload,
} from 'views/EarnAndBuy/Earn/modals/addListingReducer';
import { ListingFormModal } from 'views/EarnAndBuy/Earn/modals/listingFormModal';
import { useKeywordSearch } from 'views/EarnAndBuy/hooks/useKeywordSearch';
import { useStatusSelector } from 'views/EarnAndBuy/hooks/useStatusSelector';
import { applyModalBody, applyModalTitle } from 'views/EarnAndBuy/messages';

enum ModalType {
  none = 'none',
  details = 'details',
  createOrEdit = 'createOrEdit',
  notAllowed = 'notAllowed',
  applyFailure = 'applyFailure',
  applySuccess = 'applySuccess',
  tooManyApplications = 'tooManyApplications',
  confirmApply = 'confirmApply',
  createOrEditSuccess = 'createOrEditSuccess',
  createOrEditFailure = 'createOrEditFailure',
  confirmRemove = 'confirmRemove',
  removeSuccess = 'removeSuccess',
  removeFailure = 'removeFailure',
}

export const Earn: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const [sortBy, handleSortChange] = useSort<EarnItem>('posted_at', SortOrder.descending);
  const [searchKeyword, setSearchKeyword] = useKeywordSearch();
  const [apply, applyMutation] = useApplyForListingMutation();
  const [remove, removeMutation] = useRemoveEarnItemMutation();
  const [create, createMutation] = useCreateEarnItemMutation();
  const [update, updateMutation] = useUpdateEarnItemMutation();
  const [currentActionType, setCurrentActionType] = useState<'update' | 'create' | null>(null);
  const [targetId, setTargetId] = useState<string | null>(null);

  const permission = usePermission(ApplicationModule.earnAndBuy, UsageAction.create);
  const navigate = useNavigate();
  const handleFiltersChange = useFilters(filters);
  const queryParameters = useQueryParameters();
  const updatingPermission = useSelector(permissionCodeBeingUpdatedSelector);
  const { permissions } = useSelector(profilePreferencesSelector);

  React.useEffect((): void => {
    dispatch(getPreferences());
  }, [dispatch]);

  const weeklyNotificationsPermission = useMemo(
    (): UserPermission | null =>
      permissions?.find(
        (permission: UserPermission): boolean => permission.code === 'PM_WEEKLY_JOB_LISTING_ALERTS',
      ) ?? null,
    [permissions],
  );

  const { data: postedCount } = useGetPostedListingsCountQuery();
  const { data: jobs, isLoading } = useListListingsQuery(queryParameters);
  const { listingId } = useParams<{ readonly listingId: string }>();

  const rows = React.useMemo((): readonly EarnItem[] => jobs?.data ?? [], [jobs?.data]);
  const location = useLocation();
  const currentModal = useMemo((): ModalType => location.state ?? ModalType.none, [location.state]);

  const setCurrentModal = useCallback(
    (modalType: ModalType): void => {
      if (listingId) {
        navigate('../earn', { state: modalType });
      } else {
        navigate('', { state: modalType });
      }
    },
    [listingId, navigate],
  );

  const handleRowClick = React.useCallback(
    (row: EarnItem): void => {
      navigate(row.id, { state: ModalType.details });
    },
    [navigate],
  );

  const handleApplyConfirmed = useCallback((): void => {
    if (!listingId) {
      return;
    }

    apply(listingId);
  }, [apply, listingId]);

  const handleAddListing = React.useCallback((): void => {
    setCurrentModal(ModalType.createOrEdit);
  }, [setCurrentModal]);

  const closeCurrentModal = React.useCallback((): void => {
    setCurrentModal(ModalType.none);
  }, [setCurrentModal]);

  const addClickHandler = React.useMemo((): VoidFunction | undefined => {
    if (!exceedsUsageLimit(permission, postedCount?.count || Number.MAX_SAFE_INTEGER)) {
      return handleAddListing;
    }
  }, [handleAddListing, permission, postedCount?.count]);

  const pagesCount = React.useMemo((): number => jobs?.page_count ?? 0, [jobs?.page_count]);

  const editEventHandler = useCallback(
    (id: string): void => {
      navigate(id, { state: ModalType.createOrEdit });
    },
    [navigate],
  );

  const applyEventHandler = React.useCallback(
    (id: string): void => {
      if (listingId) {
        navigate('', { state: ModalType.confirmApply });
      } else {
        navigate(id, { state: ModalType.confirmApply });
      }
    },
    [listingId, navigate],
  );

  const applyCompleted = React.useCallback((): void => {
    applyMutation.reset();
    closeCurrentModal();
  }, [applyMutation, closeCurrentModal]);

  const createOrEditCompleted = React.useCallback((): void => {
    if (updateMutation.status !== QueryStatus.uninitialized) {
      updateMutation.reset();
    } else if (createMutation.status !== QueryStatus.uninitialized) {
      createMutation.reset();
    }
    closeCurrentModal();
  }, [closeCurrentModal, createMutation, updateMutation]);

  const handleSubmit = React.useCallback(
    async (data: WithOptionalID<CreateListingPayload>): Promise<void> => {
      if (isUpdateListingPayload(data)) {
        setCurrentActionType('update');
        await update(data);
      } else {
        setCurrentActionType('create');
        await create(data);
      }
    },
    [create, update],
  );

  const removeEventHandler = useCallback(
    (id: string): void => {
      setCurrentModal(ModalType.confirmRemove);
      setTargetId(id);
    },
    [setCurrentModal],
  );

  const removeCompleted = React.useCallback((): void => {
    removeMutation.reset();
    closeCurrentModal();

    setTargetId(null);
  }, [closeCurrentModal, removeMutation]);

  const handleRemoveConfirmed = React.useCallback((): void => {
    if (targetId === null) {
      console.warn('remove id is not set?');
      return;
    }

    remove(targetId);
  }, [remove, targetId]);

  const shareEventHandler = useCallback((_: string): void => {
    return;
  }, []);

  const actionsContext = useMemo(
    (): EarnAndBuyActions => ({
      actionStatuses: {
        remove: removeMutation.status,
        edit: updateMutation.status,
        share: QueryStatus.uninitialized,
      },
      edit: editEventHandler,
      apply: applyEventHandler,
      remove: removeEventHandler,
      share: shareEventHandler,
      applyStatus: applyMutation.status,
      applyId: listingId,
    }),
    [
      listingId,
      applyEventHandler,
      editEventHandler,
      removeEventHandler,
      shareEventHandler,
      applyMutation.status,
      removeMutation.status,
      updateMutation.status,
    ],
  );

  useEffect((): void => {
    switch (applyMutation.status) {
      case QueryStatus.pending:
        closeCurrentModal();
        break;
      case QueryStatus.fulfilled:
        setCurrentModal(ModalType.applySuccess);
        break;
      case QueryStatus.rejected:
        {
          const { error } = applyMutation;
          if ('status' in error && typeof error.status === 'number') {
            switch (error.status) {
              case HttpStatusPaymentRequired:
                setCurrentModal(ModalType.notAllowed);
                break;
              case HttpStatusTooManyRequests:
                setCurrentModal(ModalType.tooManyApplications);
                break;
              default:
                setCurrentModal(ModalType.applyFailure);
                break;
            }
          } else {
            setCurrentModal(ModalType.applyFailure);
          }
        }
        break;
    }
  }, [applyMutation, applyMutation.status, closeCurrentModal, setCurrentModal]);

  const createOrEditStatus = useStatusSelector(createMutation, updateMutation);

  useEffect((): void => {
    switch (removeMutation.status) {
      case QueryStatus.fulfilled:
        setCurrentModal(ModalType.removeSuccess);
        break;
      case QueryStatus.rejected:
        setCurrentModal(ModalType.removeFailure);
        break;
      case QueryStatus.pending:
        closeCurrentModal();
        break;
    }
  }, [closeCurrentModal, removeMutation.status, setCurrentModal]);

  useEffect((): void => {
    switch (createOrEditStatus) {
      case QueryStatus.fulfilled:
        setCurrentModal(ModalType.createOrEditSuccess);
        break;
      case QueryStatus.rejected:
        setCurrentModal(ModalType.createOrEditFailure);
        break;
    }
  }, [setCurrentModal, createOrEditStatus]);

  const handleToggleSendNotifications = React.useCallback((): void => {
    if (weeklyNotificationsPermission === null) {
      return;
    }

    dispatch(
      updatePermission({
        ...weeklyNotificationsPermission,
        granted: !weeklyNotificationsPermission.granted,
      }),
    );
  }, [dispatch, weeklyNotificationsPermission]);

  const updating = React.useMemo(
    (): boolean => updatingPermission === weeklyNotificationsPermission?.code,
    [updatingPermission, weeklyNotificationsPermission?.code],
  );

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-end justify-between mt-4 mb-3">
        <div className="w-full">
          <div className="-mt-2 mb-3">
            <SearchBox value={searchKeyword} onChange={setSearchKeyword} />
          </div>
          <div className="flex items-center justify-between w-full">
            <Filters config={filters} onChange={handleFiltersChange} />
            <div className="flex items-center">
              <div className="relative w-6 h-6">
                <SpinnerLoader visible={updating} innerCssClass="w-6 h-6" />
              </div>
              <div className="mr-6 ml-3">
                <Toggle
                  label="Notify me of new jobs"
                  checked={weeklyNotificationsPermission?.granted}
                  onChange={handleToggleSendNotifications}
                />
              </div>
              <AddObjectButton verb="New" title="Listing" onClick={addClickHandler} />
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex-1">
        <ConditionalRender renderIf={pagesCount === 0 && !isLoading}>
          <EmptySection title="There are no listings" icon={NoIcon} />
        </ConditionalRender>

        <ConditionalRender renderIf={pagesCount > 0}>
          <Pagination totalPages={pagesCount}>
            <ActionsContext.Provider value={actionsContext}>
              <Table
                columns={columns}
                rows={rows}
                sortBy={sortBy}
                onRowClick={handleRowClick}
                onSortChange={handleSortChange}
              />
            </ActionsContext.Provider>
          </Pagination>
        </ConditionalRender>
        <SpinnerLoader visible={isLoading} />
      </div>

      <Modal isOpen={currentModal === ModalType.details} onClose={closeCurrentModal}>
        <ActionsContext.Provider value={actionsContext}>
          <Details listingId={listingId} />
        </ActionsContext.Provider>
      </Modal>

      <Modal isOpen={currentModal === ModalType.applySuccess} onClose={applyCompleted}>
        <SuccessBox
          title="You applied for this listing"
          message="We just sent an email to the listing owner. They will get in contact with you to proceed with your application."
          onClose={applyCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.applyFailure} onClose={applyCompleted}>
        <ErrorBox
          title="An error ocurred"
          message="There was an error trying to apply for this listing. Please try again later."
          onClose={applyCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.tooManyApplications} onClose={closeCurrentModal}>
        <Modal.Content>
          <div className="w-modal-sm">
            <ExceedAccess
              title="Upgrade your plan"
              description="You have too many pending applications."
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal isOpen={currentModal === ModalType.notAllowed} onClose={closeCurrentModal}>
        <Modal.Content>
          <div className="w-modal-sm">
            <ExceedAccess
              title="Upgrade your plan"
              description="You need to upgrade your subscription plan to apply for this listing."
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal isOpen={currentModal === ModalType.confirmApply} onClose={closeCurrentModal}>
        <Modal.Content title={applyModalTitle}>
          <ConfirmationBox
            message={applyModalBody}
            onYes={handleApplyConfirmed}
            onNo={closeCurrentModal}
          />
        </Modal.Content>
      </Modal>

      <Modal
        isOpen={currentModal === ModalType.createOrEditSuccess}
        onClose={createOrEditCompleted}
      >
        <SuccessBox
          title={currentActionType === 'update' ? 'Listing Updated' : 'Listing Listed'}
          message={
            currentActionType === 'update'
              ? 'Your listing was updated successfully'
              : 'Your listing was added to our list'
          }
          onClose={createOrEditCompleted}
        />
      </Modal>

      <Modal
        isOpen={currentModal === ModalType.createOrEditFailure}
        onClose={createOrEditCompleted}
      >
        <ErrorBox
          title="An error occurred"
          message="We had problems trying to add your listing to the list. Please try again later."
          onClose={createOrEditCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.removeSuccess} onClose={removeCompleted}>
        <SuccessBox
          title="Special Removed"
          message="Your listing was removed successfully"
          onClose={removeCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.removeFailure} onClose={removeCompleted}>
        <ErrorBox
          title="An error occurred"
          message="We had problems trying to remove your listing. Please try again later."
          onClose={createOrEditCompleted}
        />
      </Modal>

      <Modal isOpen={currentModal === ModalType.confirmRemove} onClose={closeCurrentModal}>
        <Modal.Content title="Remove Listing">
          <ConfirmationBox
            message="Removing this listing is irreversible. Are you sure?"
            danger={true}
            onYes={handleRemoveConfirmed}
            onNo={closeCurrentModal}
          />
        </Modal.Content>
      </Modal>

      <ListingFormModal
        listingId={listingId}
        open={currentModal === ModalType.createOrEdit}
        busy={createOrEditStatus === QueryStatus.pending}
        onSubmit={handleSubmit}
        onClose={closeCurrentModal}
      />
    </div>
  );
};
