import { QueryStatus } from '@reduxjs/toolkit/query';
import NoIcon from 'assets/icons/svg/no-portfolios-icon.svg';
import { AddObjectButton } from 'components/addObjectButton';
import { ConditionalRender } from 'components/conditionalRenderer';
import { Disabler } from 'components/disabler';
import EmptySection from 'components/emptySection/EmptySection';
import { ErrorBox } from 'components/errorBox';
import { Filters } from 'components/filters';
import { Modal } from 'components/modal';
import Pagination from 'components/pagination/Pagination';
import { SearchBox } from 'components/searchBox';
import SpinnerLoader from 'components/spinnerLoader';
import { SuccessBox } from 'components/successBox';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { ApplicationModule, usePermission } from 'context/authorization';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UsageAction } from 'redux/reducers/subscriptionPlansReducer';
import { WithID } from 'types/withId';
import {
  MarketplaceItem,
  useCreateMarketplaceItemMutation,
  useListMarketplaceItemsQuery,
} from 'views/EarnAndBuy/api';
import columns from 'views/EarnAndBuy/Buy/columns';
import { Details } from 'views/EarnAndBuy/Buy/components/details';
import { MarketplaceItemContext } from 'views/EarnAndBuy/Buy/context';
import filters from 'views/EarnAndBuy/Buy/filters';
import { CreateMarketplaceItemPayload } from 'views/EarnAndBuy/Buy/modals/addListingReducer';
import { ListingFormModal } from 'views/EarnAndBuy/Buy/modals/listingFormModal';
import { useKeywordSearch } from 'views/EarnAndBuy/hooks/useKeywordSearch';

export const Buy: React.FC = (): React.ReactElement => {
  const [updating] = useState<boolean>(false);
  const [sortBy, handleSortChange] = useSort<MarketplaceItem>('posted_at', SortOrder.descending);
  const [create, createMutation] = useCreateMarketplaceItemMutation();

  const queryParameters = useQueryParameters();
  const { data: page, isLoading } = useListMarketplaceItemsQuery(queryParameters);
  const { action, listingId } = useParams<{
    readonly listingId: string;
    readonly action: string;
  }>();

  const rows = useMemo((): readonly MarketplaceItem[] => page?.data ?? [], [page]);
  const pageCount = useMemo((): number => page?.page_count ?? 0, [page]);
  const navigate = useNavigate();
  const permission = usePermission(ApplicationModule.marketplace, UsageAction.create);

  const handleFiltersChange = useFilters(filters);
  const addClickHandler = useCallback((): void => {
    navigate('create');
  }, [navigate]);

  const goBack = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const handleRowClick = useCallback(
    (row: MarketplaceItem): void => {
      navigate(`details/${row.id}`);
    },
    [navigate],
  );

  const handleSubmit = useCallback(
    async (payload: WithID<CreateMarketplaceItemPayload>): Promise<void> => {
      create(payload);
    },
    [create],
  );

  const createCompleted = useCallback((): void => {
    createMutation.reset();
  }, [createMutation]);

  useEffect((): void => {
    if (action === 'create' && createMutation.status === QueryStatus.fulfilled) {
      navigate(-1);
    }
  }, [action, createMutation.status, navigate]);
  const [searchKeyword, setSearchKeyword] = useKeywordSearch();

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-end justify-between mt-4 mb-3">
        <div className="w-full">
          <div className="-mt-2 mb-3">
            <SearchBox value={searchKeyword} onChange={setSearchKeyword} />
          </div>
          <div className="flex items-center justify-between w-full">
            <Filters config={filters} onChange={handleFiltersChange} />
            <div className="flex items-center">
              <div className="relative w-6 h-6">
                <SpinnerLoader visible={updating} innerCssClass="w-6 h-6" />
              </div>

              {/* FIXME: use a limit based on the number of existing items */}
              <Disabler disabled={permission.usage_limit <= 0}>
                <AddObjectButton verb="New" title="Listing" onClick={addClickHandler} />
              </Disabler>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex-1">
        <ConditionalRender renderIf={pageCount === 0 && !isLoading}>
          <EmptySection title="There are no listings" icon={NoIcon} />
        </ConditionalRender>

        <ConditionalRender renderIf={pageCount > 0}>
          <Pagination totalPages={pageCount}>
            <MarketplaceItemContext>
              <Table
                columns={columns}
                rows={rows}
                sortBy={sortBy}
                onRowClick={handleRowClick}
                onSortChange={handleSortChange}
              />
            </MarketplaceItemContext>
          </Pagination>
        </ConditionalRender>

        <SpinnerLoader visible={isLoading} />
      </div>

      <Modal isOpen={action === 'details'} onClose={goBack}>
        <Details listingId={listingId} />
      </Modal>

      <Modal isOpen={createMutation.status === QueryStatus.fulfilled} onClose={createCompleted}>
        <SuccessBox
          title="Listing Listed"
          message="Your listing was added to our list"
          onClose={createCompleted}
        />
      </Modal>

      <Modal isOpen={createMutation.status === QueryStatus.rejected} onClose={createCompleted}>
        <ErrorBox
          title="An error occurred"
          message="We had problems trying to add your listing to the list. Please try again later."
          onClose={createCompleted}
        />
      </Modal>

      <ListingFormModal
        listingId={listingId}
        open={action === 'create'}
        busy={createMutation.status === QueryStatus.pending}
        onSubmit={handleSubmit}
        onClose={goBack}
      />
    </div>
  );
};
