import { Clickable } from 'components/clickable';
import React from 'react';
import { TimelineFilterButton } from 'views/SmartTools/TimelineAndEvents/components/timelineFilterButton';

interface Props<V> {
  readonly value: V;

  onChange(value: V): void;
}

export function TimelineFilterButtonSet<V>({
  value,
  children,
  onChange,
}: React.PropsWithChildren<Props<V>>): React.ReactElement {
  return (
    <div className="flex items-center text-sm w-full border border-blue rounded">
      {React.Children.map(children, (node: React.ReactNode): React.ReactElement => {
        if (!React.isValidElement(node) || node.type !== TimelineFilterButton) {
          throw new Error(
            'only valid react elements of type <TimelineFilterButton /> are allowed as children for <TimelineFilterButtonSet/>',
          );
        }
        const { label, value: buttonValue, disabled = false } = node.props;

        if (buttonValue === value) {
          return (
            <Clickable clickData={buttonValue} disabled={disabled} onClick={onChange}>
              <button className={selectedClassName}>{label}</button>
            </Clickable>
          );
        } else {
          return (
            <Clickable clickData={buttonValue} disabled={disabled} onClick={onChange}>
              <button className={regularClassName}>{label}</button>
            </Clickable>
          );
        }
      })}
    </div>
  );
}

const regularClassName = 'text-center font-poppins leading-0 py-1.5 uppercase text-blue w-full';
const selectedClassName =
  'text-center font-poppinsSemiBold leading-0 py-1.5 uppercase text-white bg-blue w-full';
