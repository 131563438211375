import * as yup from 'yup';

export const firstnameRegex = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ,. -][a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ,.' -]{2,}$/u;
export const lastnameRegex = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ,. -][a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ,.' -]*$/u;

export const fullNameSchema = {
  firstName: yup
    .string()
    .matches(firstnameRegex, 'Please enter a valid name and at least 3 characters')
    .required('Please enter your first name'),
  lastName: yup
    .string()
    .matches(lastnameRegex, 'Please enter a valid last name at least 1 characters')
    .required('Please enter your last name'),
};
