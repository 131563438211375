import NoStreamsIcon from 'assets/icons/svg/no-streams-icon.svg';
import React from 'react';

interface Props {
  onPressCreate?(): void;
}

export const EmptyView: React.FC<Props> = ({ onPressCreate }: Props): React.ReactElement => {
  return (
    <div className="flex flex-col col-span-4 w-full h-full text-center justify-center items-center">
      <img src={NoStreamsIcon} className="h-12 w-10 mb-8" alt="empty" />
      <p className="text-base text-gray-darkest mb-1">No Events</p>
      {onPressCreate ? (
        <p className={paragraphClassName}>
          {defaultText}. Check other dates or add an event by{' '}
          <span id="add-event-link" className="text-blue cursor-pointer" onClick={onPressCreate}>
            clicking here
          </span>
          .
        </p>
      ) : (
        <p className={paragraphClassName}>{defaultText}</p>
      )}
    </div>
  );
};

const paragraphClassName = 'text-sm text-gray';
const defaultText = 'No timeline items or events';
