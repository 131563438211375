import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { textJoiner } from 'utils/textJoiner';
import { ListingOwner, ListingStatus, SpecialOffer } from 'views/EarnAndBuy/api';
import { Status } from 'views/EarnAndBuy/common/status';
import { Actions } from 'views/EarnAndBuy/SpecialOffers/components/actions';
import { ListingOwnerLink } from 'views/EarnAndBuy/SpecialOffers/components/listingOwnerLink';

const columns: ReadonlyArray<ColumnDefinition<SpecialOffer>> = [
  {
    type: ColumnType.date,
    label: 'Date Posted',
    name: 'posted_at',
    alignment: Alignment.center,
    sortable: true,
  },
  {
    type: ColumnType.custom,
    label: 'Listing Owner',
    name: 'listing_owner',
    headerAlignment: Alignment.center,
    sortable: true,
    render: (owner: ListingOwner): React.ReactNode => {
      return <ListingOwnerLink owner={owner} />;
    },
  },
  {
    type: ColumnType.text,
    label: 'Category',
    name: 'category',
    sortable: true,
    alignment: Alignment.center,
  },
  {
    type: ColumnType.text,
    label: 'Title',
    name: 'title',
    alignment: Alignment.left,
    weight: 2,
  },
  {
    type: ColumnType.custom,
    label: 'Tier Requirement',
    name: 'requirements',
    sortable: true,
    render: (value?: readonly string[]): React.ReactNode => {
      return <p className="text-center">{textJoiner(value)}</p>;
    },
    weight: 1.25,
  },
  {
    type: ColumnType.custom,
    label: 'Status',
    name: 'status',
    sortable: true,
    headerAlignment: Alignment.center,
    render: (value: ListingStatus, _: SpecialOffer): React.ReactNode => {
      return (
        <div className="flex items-center justify-center">
          <Status status={value} variant="flat" />
        </div>
      );
    },
    width: 220,
  },
  {
    type: ColumnType.custom,
    label: '',
    name: 'id',
    render: (_: string, item: SpecialOffer): React.ReactNode => {
      return <Actions item={item} />;
    },
    width: 400,
  },
];

export default columns;
