import { ReactComponent as AddIcon } from 'assets/icons/svg/add-icon.svg';
import Button from 'components/buttons/Button/Button';
import React, { useMemo } from 'react';

interface Props {
  readonly verb?: string;
  readonly title: string;

  onClick?(): void;
}

export const AddObjectButton: React.FC<Props> = ({
  verb = 'Add',
  title,
  onClick,
}: Props): React.ReactElement => {
  const name = useMemo((): string => title.toLowerCase(), [title]);
  const statusClasses = onClick === undefined ? ' opacity-30 pointer-events-none' : '';

  return (
    <div
      className={`flex items-center add-button cursor-pointer${statusClasses} px-4`}
      onClick={onClick}
    >
      <Button
        id={`add-${name}-button`}
        className="w-9 h-9"
        width="w-sm"
        height="h-sm"
        padding="p-sm"
      >
        <AddIcon className="w-5 h-5 fill-current" />
      </Button>
      <span className="ml-2 uppercase font-poppinsSemiBold text-sm text-blue">
        {verb} {title}
      </span>
    </div>
  );
};
