import { useMemo } from 'react';
import { PeerComparisonsSummary } from 'types/peerComparisons';

interface PeerComparisonsSummaryGroup {
  readonly name: string;
  readonly data: PeerComparisonsSummary;
}

export const useSummaryGroups = (
  summary: PeerComparisonsSummary,
): readonly [
  PeerComparisonsSummaryGroup,
  PeerComparisonsSummaryGroup,
  PeerComparisonsSummaryGroup,
  PeerComparisonsSummaryGroup,
] => {
  return useMemo(
    (): any => [
      {
        name: 'High',
        data: summary.high,
      },
      {
        name: 'Low',
        data: summary.low,
      },
      {
        name: 'Median',
        data: summary.median,
      },
      {
        name: 'Mean',
        data: summary.mean,
      },
    ],
    [summary.high, summary.low, summary.mean, summary.median],
  );
};
