import { CommonActionButtons } from 'components/commonActionButtons';
import { ConfirmationBox } from 'components/confirmationBox';
import { Modal } from 'components/modal';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Portfolio as SinglePortfolio } from 'redux/reducers/portfolioReducer';
import { Portfolio } from 'redux/reducers/portfoliosReducer';
import { deletePortfolioService } from 'redux/services/portfolioService';
import { AccessMode } from 'types/accessMode';
import CreatePortfolioForm from 'views/Portfolios/PortfoliosModal/createEditPortfolio';

interface Props {
  readonly portfolio: Portfolio;
}

export const ActionButtons: React.FC<Props> = ({ portfolio }: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const edit = useModal();
  const remove = useModal();
  const share = useModal();

  const accessMode = useMemo((): AccessMode => {
    switch (portfolio.access_mode) {
      case 'read-only':
        return AccessMode.readOnly;
      case 'read-write':
        return AccessMode.readWrite;
      case 'owner':
        return AccessMode.owner;
      default:
        return AccessMode.readOnly;
    }
  }, [portfolio.access_mode]);

  const handleDelete = useCallback(
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      remove.open();
    },
    [remove],
  );

  const handleEdit = useCallback(
    (event: React.MouseEvent): void => {
      event.stopPropagation();

      if (accessMode === AccessMode.readOnly) {
        return;
      }
      edit.open();
    },
    [accessMode, edit],
  );

  const handleDeleteAccepted = useCallback((): void => {
    const ids: readonly string[] = [portfolio.id];

    dispatch(deletePortfolioService(ids));
  }, [dispatch, portfolio.id]);

  /*const mapMembers = useCallback((member: Member): any => ({
    ...member,
    access_mode: member.accessMode,
    id: member.value,
  }, []);*/

  const data = useMemo((): SinglePortfolio => {
    return {
      id: portfolio.id,
      access_mode: accessMode,
      name: portfolio.name,
      summary: portfolio.summary ?? '',
      members_count: 0, // portfolio?.members?.map(mapMembers) ?? [],
      tags: portfolio.tags,
      type: portfolio.portfolio_type as 'Public' | 'Private',
    };
  }, [
    accessMode,
    portfolio.id,
    portfolio.name,
    portfolio.portfolio_type,
    portfolio.summary,
    portfolio.tags,
  ]);

  return (
    <>
      <CommonActionButtons title="" section="" onDelete={handleDelete} onEdit={handleEdit} />

      <Modal isOpen={edit.isOpen} onClose={edit.close}>
        <CreatePortfolioForm initialData={data} onClose={edit.close} />
      </Modal>

      <Modal isOpen={remove.isOpen} onClose={remove.close}>
        <Modal.Content title="Delete Portfolio">
          <ConfirmationBox
            message={deleteQuestion}
            danger={true}
            onYes={handleDeleteAccepted}
            onNo={remove.close}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={share.isOpen} onClose={share.close}>
        <Modal.Content></Modal.Content>
      </Modal>
    </>
  );
};

const deleteQuestion =
  'Are you certain you want to delete this portfolio? ' +
  'This action is irreversible, and all associated data will be permanently lost. ' +
  'Proceed only if you are absolutely sure.';
