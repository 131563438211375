import React from 'react';

interface Props {
  readonly label: React.ReactNode;
  readonly withRing: boolean;
  readonly smaller: boolean;
}

export const GraphCentralNode: React.FC<Props> = ({
  label,
  withRing,
  smaller,
}): React.ReactElement => {
  return (
    <div className={getContainerClass(withRing)}>
      <div className="h-36 w-36 flex justify-center items-center">
        <div className={getBackgroundElementClass(withRing, smaller)}>
          <span className="text-white text-center text-sm font-poppinsSemiBold uppercase overflow-hidden p-2">
            {label}
          </span>
        </div>
      </div>
    </div>
  );
};

const getContainerClass = (hasChildren: boolean): string => {
  const base = 'border-4 rounded-full duration-300';
  if (hasChildren) {
    return [base, 'border-blue', 'p-8'].join(' ');
  } else {
    return [base, 'border-transparent'].join(' ');
  }
};

const getBackgroundElementClass = (hasChildren: boolean, smaller: boolean): string => {
  const base = 'flex items-center justify-center bg-gray-darkest rounded-full shadow-xl';
  const opacity = hasChildren ? 'opacity-100' : 'opacity-50';

  return smaller
    ? [base, opacity, 'w-32', 'h-32'].join(' ')
    : [base, opacity, 'w-full', 'h-full'].join(' ');
};
