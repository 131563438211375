import { ConditionalRender } from 'components/conditionalRenderer';
import { noop } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { clearStreamEvents, portfolioStreamEventsSelector } from 'redux/reducers/portfoliosReducer';
import { getStreamEventGroups } from 'redux/services/portfolioService';
import { AccessMode } from 'types/accessMode';
import { Stream, TimelineFilters } from 'types/timeline';
import { EventGroups } from 'views/Portfolios/Timeline/components/eventGroups';
import { StreamItem } from 'views/SmartTools/TimelineAndEvents/components/streamItem';

interface Props {
  readonly streams: Stream[];
  readonly compact: boolean;
  readonly filters: TimelineFilters;
}

export const StreamsList: React.FC<Props> = ({
  compact,
  streams,
  filters,
}: Props): React.ReactElement => {
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const dispatch = useDispatch<any>();
  const eventGroups = useSelector(portfolioStreamEventsSelector);

  const handleExpand = useCallback(
    (id: string, value: boolean): void => {
      // Get companies events
      if (value) {
        dispatch(getStreamEventGroups(id, portfolioId, filters));
      } else {
        dispatch(clearStreamEvents(id));
      }
    },
    [dispatch, filters, portfolioId],
  );

  return (
    <>
      {streams.map((stream: Stream): React.ReactElement => {
        const groups = eventGroups[stream.id];

        const isReadonly = stream.access_mode === AccessMode.readOnly;

        if (isReadonly) {
          return (
            <React.Fragment key={stream.id}>
              <StreamItem
                stream={stream}
                readOnly={true}
                mode="expand"
                expanded={groups !== undefined}
                compact={compact}
                variant="regular"
                onExpand={handleExpand}
              />
              <ConditionalRender renderIf={groups !== undefined}>
                <EventGroups groups={groups} compact={compact} />
              </ConditionalRender>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={stream.id}>
              <StreamItem
                stream={stream}
                readOnly={false}
                compact={compact}
                mode="select"
                variant="regular"
                selected={false}
                onSelect={noop}
              />
              <ConditionalRender renderIf={groups !== undefined}>
                <EventGroups groups={groups} compact={compact} />
              </ConditionalRender>
            </React.Fragment>
          );
        }
      })}
    </>
  );
};
