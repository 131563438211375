import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';
import classes from 'views/Connections/Overview/components/FollowButton/followButton.module.scss';

interface Props {
  readonly type: 'plus' | 'check' | 'spinner';
}

export const Icon: React.FC<Props> = ({ type }: Props): React.ReactElement => {
  switch (type) {
    case 'plus':
      return <SVGIcon name="add-icon-thick" className="h-4 fill-current" />;
    case 'check':
      return <SVGIcon name="check-icon-generic" className="h-3 fill-current" />;
    case 'spinner':
      return <div className={classes.spinner} />;
  }
};
