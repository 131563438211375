import React, { CSSProperties, useEffect, useRef, useState } from 'react';

interface Props {
  readonly angle: number;
}

export const GraphNode: React.FC<React.PropsWithChildren<Props>> = ({
  angle,
  children,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const container = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<CSSProperties>({
    transformOrigin: '0 50%',
  });

  useEffect((): void => {
    const element = container.current;
    if (element === null) {
      return;
    }

    setStyle({
      left: -graphSize,
      top: -element.offsetHeight / 2,
      transform: `rotate(${angle}deg)`,
      transformOrigin: '0 50%',
    });
  }, [angle]);

  return (
    <div
      className="flex items-center absolute duration-500 transform:rotate(0)"
      ref={container}
      style={style}
    >
      {/* Invisible "stick" makes the node far from the center by `graphSize` px */}
      <div className="h-1" style={{ width: graphSize }} />
      {/* Little dot that joins the line with the inner circle */}
      <div className="w-4 h-4 rounded-full bg-blue -ml-2 duration-500" />
      {/* The link line */}
      <div className="h-1 w-9 bg-blue -ml-0.5" />
      {/* The container of the content stuff */}
      <div style={{ transform: `rotate(${-angle}deg)` }}>{children}</div>
    </div>
  );
};

const graphSize = 108;
