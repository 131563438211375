import { DynamicColor } from 'components/capnoteMap/components/markerDot';
import { Identifiable } from 'components/table/types';
import {
  PortfoliosCompaniesMapItem,
  PortfoliosMapLocation,
  PortfoliosRevenuesMapItem,
} from 'redux/reducers/portfoliosReducer';
import { Portfolio } from 'types/organization/types';

export const reducers = {
  colors: (
    map: Record<string, DynamicColor>,
    marker: PortfoliosMapLocation<PortfoliosRevenuesMapItem | PortfoliosCompaniesMapItem>,
  ): Record<string, DynamicColor> => {
    const { portfolios } = marker;
    return portfolios.reduce(
      (
        map: Record<string, DynamicColor>,
        portfolio: PortfoliosRevenuesMapItem | PortfoliosCompaniesMapItem,
      ): Record<string, DynamicColor> => {
        const keys = Object.keys(map);
        if (map[portfolio.id]) {
          return map;
        }

        return {
          ...map,
          [portfolio.id]: ((keys.length + 1) % 15) as DynamicColor,
        };
      },
      map,
    );
  },
  portfolios: (
    list: readonly Portfolio[],
    marker: PortfoliosMapLocation<PortfoliosRevenuesMapItem | PortfoliosCompaniesMapItem>,
  ): readonly Portfolio[] => {
    const { portfolios } = marker;
    return distinct([...list, ...portfolios.map(fromLocationItem)]);
  },
};

function distinct<T extends Identifiable>(list: readonly T[]): readonly T[] {
  return list.filter(
    (outer: T, index: number): boolean =>
      list.findIndex((inner: Identifiable): boolean => inner.id === outer.id) === index,
  );
}

const fromLocationItem = (
  item: PortfoliosRevenuesMapItem | PortfoliosCompaniesMapItem,
): Portfolio => ({
  id: item.id,
  name: item.name,
});
