import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Organization } from 'redux/reducers/smartToolsRecentSearchesReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const useSearchInputOrganization = (key = 'organizationId'): Organization | null => {
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { [key]: organizationId } = useParams();

  useEffect((): VoidFunction | void => {
    if (organizationId) {
      const cancelToken = axios.CancelToken.source();
      api
        .get(`${API_V1_PATH}/organizations/${organizationId}`, {
          cancelToken: cancelToken.token,
        })
        .then((response: AxiosResponse): void => {
          setOrganization(response.data);
        })
        .catch(console.warn);

      return (): void => {
        cancelToken.cancel();
      };
    } else {
      setOrganization(null);
    }
  }, [organizationId]);

  return organization;
};
