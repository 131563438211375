import { CompanyLink } from 'components/peerComparisonsView/components/companyLink';
import { TableRow } from 'components/peerComparisonsView/components/tableRow';
import React, { CSSProperties, useMemo } from 'react';
import { PeerComparisonsColumn, PeerComparisonsColumnGroup } from 'types/peerComparisons';

interface Props {
  readonly rows: ReadonlyArray<Record<string, any>>;
  readonly columnGroups: readonly PeerComparisonsColumnGroup[];
  readonly gridStyle: CSSProperties;

  valueFormatter(rawValue: number, column: PeerComparisonsColumn): string;
  extractHeader(_: number, column: PeerComparisonsColumn): React.ReactElement;
}

export const Body: React.FC<Props> = ({
  rows,
  columnGroups,
  gridStyle,
  extractHeader,
  valueFormatter,
}: Props): React.ReactElement => {
  const dummyColumnGroups = useMemo((): readonly PeerComparisonsColumnGroup[] => {
    return columnGroups.map(
      ({ columns, ...group }: PeerComparisonsColumnGroup): PeerComparisonsColumnGroup => ({
        ...group,
        columns: columns.map(
          (column: PeerComparisonsColumn): PeerComparisonsColumn => ({
            ...column,
            label: '\u00a0',
          }),
        ),
      }),
    );
  }, [columnGroups]);

  const style = useMemo(
    (): CSSProperties => ({ ...gridStyle, scrollSnapType: 'y mandatory' }),
    [gridStyle],
  );

  return (
    <div className="relative w-min" style={style}>
      {/* Table Rows */}
      {rows.map((row: any): React.ReactElement => {
        const companyData = row['company'];

        return (
          <TableRow
            key={companyData.id}
            data={row}
            columnGroups={columnGroups}
            cellTitle={<CompanyLink data={companyData} clickable={true} />}
            extractValue={valueFormatter}
          />
        );
      })}

      <TableRow
        data={{}}
        columnGroups={dummyColumnGroups}
        cellTitle={<div className="h-12" />}
        extractValue={extractHeader}
      />
    </div>
  );
};
