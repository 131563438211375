import { MarkerType } from 'components/capnoteMap/types';
import React from 'react';
import { SubsidiaryMarkerDetails } from 'views/SmartTools/GeographiesAndMaps/components/subsidiaryMarkerDetails';

interface Props {
  readonly marker: MarkerType | null;
  onClose(): void;
}

export const MarkerDetails: React.FC<Props> = ({ marker, onClose }: Props): React.ReactElement => {
  if (marker === null) {
    return <></>;
  }

  switch (marker.kind) {
    case 'subsidiary':
      return <SubsidiaryMarkerDetails marker={marker} onClose={onClose} />;
    default:
      return <></>;
  }
};
