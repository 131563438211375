import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import { TimestampDateInput } from 'components/timestampDateInput';
import React from 'react';
import { PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem;
}

export const ClientOrCustomerLoanOrDebtRelatedForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <TimestampDateInput
        value={item.insuranceTimestamp}
        name="insuranceTimestamp"
        label="Insurance date for loan"
        onChange={updaterFor('insuranceTimestamp')}
      />
      <TimestampDateInput
        value={item.maturityTimestamp}
        name="maturityTimestamp"
        label="Maturity date for loan"
        onChange={updaterFor('maturityTimestamp')}
      />
      <Select
        id="currency"
        label="Currency"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="principalAmount"
        value={item.principalAmount}
        label="Principal amount"
        onValueChange={updaterFor('principalAmount')}
      />
      <NumericInput
        name="annualInterestRateCharged"
        value={item.annualInterestRateCharged}
        label="Annual interest rate charged"
        onValueChange={updaterFor('annualInterestRateCharged')}
      />
    </div>
  );
};
