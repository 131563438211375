import { Checkbox } from 'components/checkbox';
import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  addHedgingOrRiskManagementSubject,
  removeHedgingOrRiskManagementSubject,
} from 'redux/reducers/addPortfolioItemFormReducer';
import {
  HedgingOrRiskManagementSubject,
  hedgingOrRiskManagementSubjectLabels,
  PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem,
} from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem;
}

export const ClientOrCustomerHedgingOrRiskManagementForm: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem>();

  const { subjects = [] } = item;

  const createSubjectChangeHandler = React.useCallback(
    (subject: HedgingOrRiskManagementSubject): ((checked: boolean) => void) =>
      (checked: boolean): void => {
        if (checked) {
          dispatch(addHedgingOrRiskManagementSubject(subject));
        } else {
          dispatch(removeHedgingOrRiskManagementSubject(subject));
        }
      },
    [dispatch],
  );

  return (
    <>
      <div>
        <div className="mt-2 mb-4">
          <p className="font-poppinsMedium text-xs text-gray">
            Hedging or Risk Management Subjects
          </p>
        </div>
        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          {Object.values(HedgingOrRiskManagementSubject).map(
            (value: HedgingOrRiskManagementSubject): React.ReactElement => {
              const checked =
                subjects.findIndex(
                  (subject: HedgingOrRiskManagementSubject): boolean => subject === value,
                ) !== -1;

              const changeHandler = createSubjectChangeHandler(value);
              return (
                <Checkbox
                  key={value}
                  size="normal"
                  label={hedgingOrRiskManagementSubjectLabels[value]}
                  checked={checked}
                  onChange={changeHandler}
                />
              );
            },
          )}
        </div>
      </div>
      <Select
        id="currency"
        label="Currency of billing"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="annualMonetaryIcomFromClient"
        value={item.annualMonetaryIncomeFromClient}
        label="Annual monetary income from client"
        onValueChange={updaterFor('annualMonetaryIncomeFromClient')}
      />
    </>
  );
};
