import NoPortfoliosIcon from 'assets/icons/svg/no-portfolios-icon.svg';
import { AddObjectButton } from 'components/addObjectButton';
import { ConditionalRender } from 'components/conditionalRenderer';
import DeleteModal from 'components/deleteModal';
import EmptySection from 'components/emptySection/EmptySection';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import SVGIcon from 'components/icons/SVGIcon';
import { Modal } from 'components/modal';
import InfoModal from 'components/modals/InfoModal/InfoModal';
import { Table } from 'components/table';
import { Tooltip } from 'components/tooltip';
import { ApplicationModule, exceedsUsageLimit, usePermission } from 'context/authorization';
import { useGtag } from 'hooks/useGtag';
import { useModal } from 'hooks/useModal';
import { isEmpty } from 'lodash';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Portfolio, portfoliosSelector, resetSelection } from 'redux/reducers/portfoliosReducer';
import { UsageAction } from 'redux/reducers/subscriptionPlansReducer';
import { deletePortfolioService, getPortfoliosService } from 'redux/services/portfolioService';
import columns from 'views/Portfolios/Overview/columns';
import CreatePortfolioForm from 'views/Portfolios/PortfoliosModal/createEditPortfolio';
import {
  DELETE_PORTFOLIO_ACCEPT_BUTTON,
  DELETE_PORTFOLIO_ACTION_IRREVERSIBLE,
  DELETE_PORTFOLIO_CANCEL_BUTTON,
  DELETE_PORTFOLIO_MESSAGE_1,
  DELETE_PORTFOLIO_TITLE,
} from 'views/Portfolios/strings';

const PortfoliosOverview: React.FC<any> = () => {
  const { loading, portfolios, selection } = useSelector(portfoliosSelector);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const permission = usePermission(ApplicationModule.portfolios, UsageAction.create);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { trackEvent } = useGtag();
  const exceededUsageModal = useModal();

  useEffect(() => {
    dispatch(getPortfoliosService());
  }, [dispatch]);

  const closeCreateModal = useCallback((): void => setShowCreateModal(false), []);
  const closeDeleteModal = useCallback((): void => setShowDeleteModal(false), []);
  const openDeleteModal = useCallback((): void => setShowDeleteModal(true), []);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(resetSelection());
    };
  }, [dispatch]);

  const openCreateModal = useCallback((): void => {
    if (exceedsUsageLimit(permission, portfolios.length)) {
      exceededUsageModal.open();
    } else {
      setShowCreateModal(true);
      trackEvent('start-create-portfolio', {});
    }
  }, [trackEvent, exceededUsageModal, permission, portfolios]);

  const portfolioList = useMemo(() => {
    const portfolioArray: string[] = portfolios
      ?.filter((portfolio: Portfolio) => !!selection?.[portfolio.id])
      .map((portfolio: Portfolio) => portfolio.name);
    return portfolioArray ?? [];
  }, [portfolios, selection]);

  const writableSelection = useMemo((): readonly string[] => {
    return portfolios
      .filter(
        (portfolio: Portfolio) =>
          !!selection?.[portfolio.id] && portfolio.access_mode !== 'read-only',
      )
      .map((portfolio: Portfolio) => portfolio.id);
  }, [portfolios, selection]);

  const navigateToPortfolioOverview = useCallback(
    (portfolio: Portfolio): void => {
      if (portfolio.portfolio_id) {
        navigate(`/portfolios/details/${portfolio.portfolio_id}`);
      }
    },
    [navigate],
  );

  const onDeleteClicked = useCallback((): void => {
    if (isEmpty(selection)) {
      return;
    }

    openDeleteModal();
  }, [openDeleteModal, selection]);

  const handleDeletePortfolio = useCallback((): void => {
    dispatch(deletePortfolioService(writableSelection));
    closeDeleteModal();
  }, [closeDeleteModal, dispatch, writableSelection]);

  const deleteLabel = useMemo((): string => {
    if (writableSelection.length === 1) {
      return 'Delete (1) portfolio';
    }

    return `Delete (${writableSelection.length}) portfolios`;
  }, [writableSelection.length]);

  return (
    <div className="flex flex-col border-b-2 border-gray-light flex-grow h-full">
      <div className="flex justify-end items-center w-full mb-6 mt-6 gap-6">
        <ConditionalRender renderIf={writableSelection.length > 0}>
          <Tooltip content={deleteLabel}>
            <div
              className="flex items-center justify-center cursor-pointer text-red gap-2 uppercase"
              onClick={onDeleteClicked}
            >
              <SVGIcon name="trash-generic" className="w-5 h-5 fill-current" />
              {deleteLabel}
            </div>
          </Tooltip>
        </ConditionalRender>
        <AddObjectButton verb="New" title="Portfolio" onClick={openCreateModal} />
      </div>

      <div className="flex flex-col flex-1 relative">
        <ConditionalRender renderIf={portfolios.length > 0}>
          <Table rows={portfolios} columns={columns} onRowClick={navigateToPortfolioOverview} />
        </ConditionalRender>
        <ConditionalRender renderIf={portfolios.length === 0 && !loading}>
          <EmptySection
            title="No Portfolios"
            subtitle="Build a portfolio to keep track of items or themes you are interested in"
            buttonText="New Portfolio"
            onClick={openCreateModal}
            icon={NoPortfoliosIcon}
          />
        </ConditionalRender>
        <FullScreenLoadingSpinner visible={loading} />
      </div>

      <Modal isOpen={showCreateModal} onClose={closeCreateModal}>
        <CreatePortfolioForm onClose={closeCreateModal} />
      </Modal>

      <Modal isOpen={showDeleteModal} onClose={closeDeleteModal}>
        <Modal.Content title={DELETE_PORTFOLIO_TITLE}>
          <DeleteModal
            text={DELETE_PORTFOLIO_MESSAGE_1}
            acceptText={DELETE_PORTFOLIO_ACCEPT_BUTTON}
            cancelText={DELETE_PORTFOLIO_CANCEL_BUTTON}
            subText={DELETE_PORTFOLIO_ACTION_IRREVERSIBLE}
            items={portfolioList}
            itemType="portfolios"
            onCancel={closeDeleteModal}
            onAccept={handleDeletePortfolio}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={exceededUsageModal.isOpen} onClose={exceededUsageModal.close}>
        <Modal.Content>
          <ExceedAccess
            title="Unlock More Portfolios"
            description="Upgrade your plan to add new portfolios immediately."
          />
        </Modal.Content>
      </Modal>

      <InfoModal />
    </div>
  );
};

export default PortfoliosOverview;
