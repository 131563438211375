import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  clearMetricsAndIndicatorsStatement,
  companyDetailsSelector,
  loadingMetricsAndIndicatorsSelector,
  metricsAndIndicatorsSelector,
} from 'redux/reducers/companyReducer';
import { getMetricsAndIndicatorsService } from 'redux/services/companyService';
import { Props } from 'views/DataAnalysis/Company/Details/Financials/props';
import StatementView from 'views/DataAnalysis/Company/Details/Financials/statementView';

const MetricsAndIndicators: React.FC<Props> = ({
  options,
  onPeriodChange,
}: Props): React.ReactElement => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch<any>();
  const statement = useSelector(metricsAndIndicatorsSelector);
  const company = useSelector(companyDetailsSelector);
  const loading = useSelector(loadingMetricsAndIndicatorsSelector);

  useEffect((): VoidFunction | void => {
    if (!companyId) {
      return;
    }

    dispatch(getMetricsAndIndicatorsService(companyId, options));
    return (): void => {
      dispatch(clearMetricsAndIndicatorsStatement());
    };
  }, [dispatch, companyId, options]);

  return (
    <StatementView
      organization={company}
      statement={statement}
      loading={loading}
      convertedToUSD={options.convert_to_usd}
      onPeriodChange={onPeriodChange}
    />
  );
};

export default MetricsAndIndicators;
