import { useCallback } from 'react';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any;
  }
}
const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

interface UseGtag {
  trackEvent(eventName: string, eventParams: any /* why is this any? */): void;
  pageView(url: string): void;
}

export const useGtag = (): UseGtag => {
  const trackEvent = useCallback((eventName: string, eventParams: any): void => {
    if (process.env.REACT_APP_BITBUCKET_BRANCH !== 'develop') {
      window.gtag('event', eventName, eventParams);
    }
  }, []);

  const pageView = useCallback((url: string): void => {
    if (process.env.REACT_APP_BITBUCKET_BRANCH !== 'develop') {
      window.gtag('config', MEASUREMENT_ID, {
        page_path: url,
      });
    }
  }, []);

  return {
    trackEvent,
    pageView,
  };
};
