import { ConditionalRender } from 'components/conditionalRenderer';
import { PostBody } from 'components/postBodyInput/types';
import { useGtag } from 'hooks/useGtag';
import { noop } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  dislikeNewsService,
  dislikePostService,
  likeNewsService,
  likePostService,
  saveNewsService,
  savePostService,
  undoSaveNewsService,
  undoSavePostService,
} from 'redux/services/feedService';
import settings from 'utils/config/appSettings';
import { FeedItemInteractionButtons } from 'views/Home/components/shared/feedItemInteractionButtons';
import { PostBodyView } from 'views/Home/components/shared/postBodyView';
import { Tags } from 'views/Home/components/shared/tags';
import { CurrentUserInput } from 'views/Home/modals/PostDetailsModal/components/currentUserInput';
import { HeaderLink } from 'views/Home/modals/PostDetailsModal/components/headerLink';
import { Title } from 'views/Home/modals/PostDetailsModal/components/title';
import { FeedCategory, FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;
  readonly commentsCount: number;

  onCommentPosted(body: PostBody): void;
  onClose(): void;
}

export const PostView: React.FC<Props> = ({
  feedItem,
  commentsCount,
  onCommentPosted,
  onClose,
}: Props): React.ReactElement => {
  const [tagsExpanded, setTagsExpanded] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const { trackEvent } = useGtag();

  const toggleTagsExpanded = useCallback((): void => {
    setTagsExpanded(!tagsExpanded);
  }, [tagsExpanded]);

  const handleSave = useCallback((): void => {
    if (!feedItem.saved) {
      feedItem.category === FeedCategory.post
        ? dispatch(savePostService(feedItem.id))
        : dispatch(saveNewsService(feedItem.id));
      trackEvent('save_post', {
        post_id: feedItem.id,
      });
    } else {
      feedItem.category === FeedCategory.post
        ? dispatch(undoSavePostService(feedItem.id))
        : dispatch(undoSaveNewsService(feedItem.id));
      trackEvent('unsave_post', {
        post_id: feedItem.id,
      });
    }
  }, [dispatch, feedItem, trackEvent]);

  const handleLiked = useCallback((): void => {
    switch (feedItem.category) {
      case FeedCategory.post:
        dispatch(likePostService(feedItem.id));
        break;
      case FeedCategory.news:
        dispatch(likeNewsService(feedItem.id));
        break;
    }
  }, [dispatch, feedItem.category, feedItem.id]);

  const handleDisliked = useCallback((): void => {
    switch (feedItem.category) {
      case FeedCategory.post:
        dispatch(dislikePostService(feedItem.id));
        break;
      case FeedCategory.news:
        dispatch(dislikeNewsService(feedItem.id));
        break;
    }
  }, [dispatch, feedItem.category, feedItem.id]);

  return (
    <>
      <div className="relative flex flex-col md:p-6 p-3">
        <Title feedItem={feedItem} onSave={handleSave} onClose={onClose} />
        <Tags feedItem={feedItem} expanded={tagsExpanded} onExpand={toggleTagsExpanded} />

        <PostBodyView feedItem={feedItem} clamp={false} onTagClick={noop} />
      </div>
      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <>
          <div className="flex items-center gap-12 md:px-6 px-3">
            <FeedItemInteractionButtons
              likesCount={feedItem.likesCount}
              liked={feedItem.liked}
              dislikesCount={feedItem.dislikesCount}
              disliked={feedItem.disliked}
              commentsCount={commentsCount}
              commented={commentsCount > 0}
              onDisliked={handleDisliked}
              onLiked={handleLiked}
            />
            <HeaderLink feedItem={feedItem} />
          </div>
          <div className="p-2">
            <ConditionalRender renderIf={!feedItem.reported}>
              <div className="p-4">
                <CurrentUserInput
                  placeholder="Add Comment"
                  submitLabel="POST"
                  initialValue={PostBody.empty()}
                  maxLength={settings.maxCommentLength}
                  onSubmit={onCommentPosted}
                />
              </div>
            </ConditionalRender>
          </div>
        </>
      )}
    </>
  );
};
