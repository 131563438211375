import axios from 'axios';
import { AddObjectButton } from 'components/addObjectButton';
import DeleteModal from 'components/deleteModal';
import { Disabler } from 'components/disabler';
import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { Modal } from 'components/modal';
import Pagination from 'components/pagination/Pagination';
import ShareModal from 'components/shareModal';
import { SortOrder } from 'components/table/sorting';
import { ApplicationModule, exceedsUsageLimit, usePermission } from 'context/authorization';
import { useFilters } from 'hooks/useFilters';
import { useGtag } from 'hooks/useGtag';
import { useModal } from 'hooks/useModal';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// Redux Actions
import { PortfolioCompany, portfolioSelector } from 'redux/reducers/portfolioReducer';
import { UsageAction } from 'redux/reducers/subscriptionPlansReducer';
import {
  deletePortfolioService,
  getPortfolioMembersService,
  sharePortfolioService,
} from 'redux/services/portfolioService';
import { SharePayload } from 'sharable/types';
import { AccessMode } from 'types/accessMode';
import { Page } from 'types/page';
import AssetsTable from 'views/Portfolios/Details/components/AssetsTable';
import createFiltersConfig from 'views/Portfolios/Details/Overview/filters';
import { ScreenParams } from 'views/Portfolios/Details/screenParams';
import { useItemsQuery } from 'views/Portfolios/PortfoliosModal/api';
import CreatePortfolioForm from 'views/Portfolios/PortfoliosModal/createEditPortfolio';
import AddItemModal from 'views/Portfolios/PortfoliosModal/portfolioItemForm';
import {
  DELETE_PORTFOLIO_ACCEPT_BUTTON,
  DELETE_PORTFOLIO_ACTION_IRREVERSIBLE,
  DELETE_PORTFOLIO_CANCEL_BUTTON,
  DELETE_PORTFOLIO_MESSAGE_1,
  DELETE_PORTFOLIO_TITLE,
} from 'views/Portfolios/strings';

enum Modals {
  edit = 'edit',
  addItem = 'addItem',
  delete = 'delete',
  share = 'share',
  none = 'none',
}

const Overview: React.FC = (): React.ReactElement => {
  const [currentModal, setCurrentModal] = useState<Modals>(Modals.none);
  const permission = usePermission(ApplicationModule.portfolios, UsageAction.addItem);

  const navigate = useNavigate();
  const queryParameters = useQueryParameters();
  const portfolio = useSelector(portfolioSelector);
  const dispatch = useDispatch<any>();
  const { trackEvent } = useGtag();
  const exceededUsageModal = useModal();
  const filtersConfig = useMemo(
    (): CommonFilter[] => createFiltersConfig(portfolio.id),
    [portfolio.id],
  );
  const { itemId } = useParams<ScreenParams>();

  const { data: items = Page.empty(), isLoading: loading } = useItemsQuery(
    {
      ...queryParameters,
      portfolioId: portfolio.id,
    },
    {
      skip: portfolio.id === '' || !portfolio.id,
    },
  );

  const handleFiltersChange = useFilters(filtersConfig, { resetOnConfigChange: true });

  const [sorting, handleSortChange] = useSort<PortfolioCompany>('added_at', SortOrder.descending);

  const closeEditModal = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const handleDeletePortfolio = useCallback((): void => {
    dispatch(deletePortfolioService([portfolio.id]));

    navigate('/portfolios');
  }, [dispatch, navigate, portfolio.id]);

  const closeCurrentModal = useCallback((): void => {
    // Now close the modal and hope for the best
    setCurrentModal(Modals.none);
  }, []);

  const handleAddItem = useCallback((): void => {
    if (exceedsUsageLimit(permission, items.total_count)) {
      exceededUsageModal.open();
    } else {
      setCurrentModal(Modals.addItem);
      trackEvent('start-add-company', {});
    }
  }, [trackEvent, exceededUsageModal, items, permission]);

  const handleShare = useCallback(
    (members: Omit<SharePayload, 'route'>) => {
      if (!portfolio.id || portfolio.id === '') {
        return;
      }

      const payload: SharePayload = { ...members, route: `/portfolios/details/${portfolio.id}` };

      dispatch(sharePortfolioService(payload, portfolio.id));
      closeCurrentModal();
    },
    [portfolio?.id, dispatch, closeCurrentModal],
  );

  const isReadOnly = useMemo((): boolean => {
    return portfolio.access_mode === AccessMode.readOnly;
  }, [portfolio]);

  useEffect((): VoidFunction | void => {
    if (!portfolio.id) {
      return;
    }
    const tokenSource = axios.CancelToken.source();

    dispatch(getPortfolioMembersService(portfolio.id, tokenSource.token));
    return (): void => {
      tokenSource.cancel();
    };
  }, [dispatch, portfolio]);

  return (
    <div className="flex flex-col relative flex-1">
      <div className="flex justify-between w-full my-6 items-center relative z-1">
        <Disabler disabled={true}>
          <Filters config={filtersConfig} onChange={handleFiltersChange} />
        </Disabler>
        <AddObjectButton title="Item" onClick={isReadOnly ? undefined : handleAddItem} />
      </div>

      <Pagination totalPages={items.page_count}>
        <AssetsTable
          portfolio={portfolio}
          loading={loading}
          items={items}
          sorting={sorting}
          onAddCompany={handleAddItem}
          onSort={handleSortChange}
        />
      </Pagination>

      <Modal isOpen={currentModal === Modals.edit} onClose={closeCurrentModal}>
        <CreatePortfolioForm initialData={portfolio} onClose={closeCurrentModal} />
      </Modal>

      <Modal isOpen={currentModal === Modals.addItem} onClose={closeCurrentModal}>
        <AddItemModal
          name={portfolio.name}
          portfolioId={portfolio.id}
          onClose={closeCurrentModal}
        />
      </Modal>

      <Modal isOpen={currentModal === Modals.delete} onClose={closeCurrentModal}>
        <Modal.Content title={DELETE_PORTFOLIO_TITLE}>
          <DeleteModal
            text={DELETE_PORTFOLIO_MESSAGE_1}
            acceptText={DELETE_PORTFOLIO_ACCEPT_BUTTON}
            cancelText={DELETE_PORTFOLIO_CANCEL_BUTTON}
            subText={DELETE_PORTFOLIO_ACTION_IRREVERSIBLE}
            items={[portfolio.name]}
            itemType="portfolios"
            onCancel={closeCurrentModal}
            onAccept={handleDeletePortfolio}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={currentModal === Modals.share} onClose={closeCurrentModal}>
        <Modal.Content>
          <ShareModal
            title="Share Portfolio"
            text="Select the members you want to share this portfolio with."
            onShare={handleShare}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={exceededUsageModal.isOpen} onClose={exceededUsageModal.close}>
        <Modal.Content>
          <ExceedAccess
            title="Unlock More Companies"
            description="Upgrade your plan to add new companies to your portfolios."
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={!!itemId} onClose={closeEditModal}>
        <AddItemModal
          name={portfolio.name}
          portfolioId={portfolio.id}
          itemId={itemId}
          onClose={closeEditModal}
        />
      </Modal>
    </div>
  );
};

export default Overview;
