import SVGIcon from 'components/icons/SVGIcon';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { stopPropagation } from 'utils/ignoreEvent';
import { defaultOptions } from 'utils/popper';

interface BaseProps {
  readonly value: Date | null;
  readonly label: string;
  readonly className?: string;
  readonly labelFormat?: string;
  readonly nullable: boolean;
}

interface NonNullableProps extends BaseProps {
  readonly value: Date;
  readonly nullable: false;

  onChange(date: Date): void;
}

interface NullableProps extends BaseProps {
  readonly value: Date | null;
  readonly nullable: true;

  onChange(date: Date | null): void;
}

type Props = NullableProps | NonNullableProps;

export const DatePicker: React.FC<Props> = ({
  value,
  label,
  className = 'w-6/12 rounded pr-4 py-1 text-center hover:bg-gray-medium focus:bg-gray-medium focus:outline-none px-1',
  labelFormat = 'EEEE MMM dd',
  nullable,
  onChange,
}: Props): React.ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [popup, setPopup] = useState<HTMLElement | null>(null);

  const popperOptions = useMemo((): any => defaultOptions(document.body, { offset: [0, 4] }), []);
  const handleChange = useCallback(
    (date: Date | Date[]): void => {
      if (Array.isArray(date)) {
        throw new Error('we only expect 1 date to be picked');
      }

      setExpanded(false);
      onChange(date);
    },
    [onChange],
  );
  const toggleShowCalendar = useCallback((): void => setExpanded(!expanded), [expanded]);
  const clear = useCallback((): void => {
    setExpanded(false);
    if (nullable) {
      onChange(null);
    }
  }, [nullable, onChange]);

  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void => {
    update?.();
  }, [update]);

  const formattedValue = useMemo(
    (): string | null => (value ? format(value, labelFormat) : null),
    [labelFormat, value],
  );

  const finalClassName = useMemo(
    (): string =>
      [className, 'flex items-start w-72']
        .filter((item: string | undefined): boolean => item !== undefined)
        .join(' '),
    [className],
  );

  return (
    <button role="button" ref={setAnchor} className={finalClassName} onClick={toggleShowCalendar}>
      <SVGIcon name="calendar-icon" className="w-4 h-4 mt-px mr-2 fill-current text-gray-dark" />
      <div className="text-sm text-gray-darkest flex flex-col font-poppins items-start">
        <span className="uppercase font-poppinsSemiBold text-gray-darkest">{label}</span>
        {formattedValue ? (
          <span>{formattedValue}</span>
        ) : (
          <span className="text-gray-400">None selected</span>
        )}
      </div>
      {expanded &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" onClick={toggleShowCalendar}>
            <div
              ref={setPopup}
              className="bg-white p-4 rounded shadow-lg w-80"
              style={styles.popper}
              {...attributes.popper}
              onClick={stopPropagation}
            >
              <Calendar
                nextLabel={<SVGIcon name="next-icon" className="fill-current text-gray w-3 h-3" />}
                prevLabel={
                  <SVGIcon name="previous-icon" className="fill-current text-gray w-3 h-3" />
                }
                next2Label={<div />}
                prev2Label={<div />}
                onChange={handleChange}
                value={value}
                formatShortWeekday={shortWeekdayFormatter}
              />
              {nullable ? (
                <button
                  className="text-blue font-poppinsMedium text-sm w-full mt-2"
                  onClick={clear}
                >
                  Clear
                </button>
              ) : null}
            </div>
          </div>,
          document.body,
        )}
    </button>
  );
};

const shortWeekdayFormatter = (locale: string, date: Date): string => format(date, 'EEEEE');
