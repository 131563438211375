import { RECORD_SEPARATOR } from 'assets/constants/recordSeparator';
import { GenericFilter } from 'components/filters/helpers';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface FilterOptions {
  readonly resetOnConfigChange: boolean;
}

export interface FilterValues {
  readonly key: string;
  readonly values: string[] | null;
}

const protectedKeys = ['page_number', 'order_by', 'country', 'from_date', 'to_date'];

export const useFilters = (
  configuration: readonly GenericFilter[],
  options?: FilterOptions,
): ((values: readonly FilterValues[]) => void) => {
  const [_, setSearch] = useSearchParams();

  const changeHandler = useCallback(
    (filters: readonly FilterValues[]): void => {
      const state: string[] = [];

      setSearch(
        (search: URLSearchParams): URLSearchParams => {
          // Clean current search string
          for (const key of search.keys()) {
            if (!protectedKeys.includes(key)) {
              search.delete(key);
            }
          }
          // Always set to the URL
          return filters.reduce((search: URLSearchParams, item: FilterValues): URLSearchParams => {
            const { key, values } = item;

            if (values === null) {
              search.delete(key);
            } else if (values.length === 0) {
              search.delete(key);
              state.push(key);
            } else if (values.length > 0) {
              search.set(key, values.map(encodeURIComponent).join(RECORD_SEPARATOR));
              state.push(key);
            }

            return search;
          }, search);
        },
        { state: state },
      );
    },
    [setSearch],
  );

  // Reset if config changes
  useEffect((): void => {
    if (!options?.resetOnConfigChange) {
      return;
    }
  }, [configuration, options?.resetOnConfigChange]);

  return changeHandler;
};
