import React, { Dispatch } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useKeywordSearch = (): [string, Dispatch<string>] => {
  const [search, setSearch] = useSearchParams();
  const location = useLocation();

  const searchKeyword = React.useMemo((): string => {
    return search.get('keyword') ?? '';
  }, [search]);

  const handleSearchChange = React.useCallback(
    (searchKeyword: string): void => {
      setSearch(
        (search: URLSearchParams): URLSearchParams => {
          const keyword = searchKeyword.trim();
          if (keyword.length > 0) {
            // Always set to the URL
            search.set('keyword', keyword);
            // Reset to the first page to start seeing the sorted results
            // from the beginning
            search.delete('page_number');
          } else {
            search.delete('keyword');
          }

          return search;
        },
        { state: location.state, replace: true },
      );
    },
    [location.state, setSearch],
  );

  return [searchKeyword, handleSearchChange];
};
