import { ReactComponent as CheckmarkIcon } from 'assets/icons/svg/new-check-icon.svg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ComboBoxBase } from 'views/Landing/Onboarding/components/ComboBoxBase';
import classes from 'views/Landing/Onboarding/components/ComboBoxBase/onboarding-form-autocompletable-input.module.scss';
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';

interface Props {
  readonly id: string;
  readonly value: ComboBoxItem | null;
  readonly name: string;
  readonly label: string;
  readonly placeholder: string;

  onChange(value: ComboBoxItem | null): void;
  onAutocomplete?(value: string): Promise<readonly ComboBoxItem[]>;
}

export const DropdownInput: React.FC<Props> = ({
  id,
  name,
  value,
  label,
  placeholder,
  onChange,
  onAutocomplete,
}: Props): React.ReactElement => {
  const [textValue, setTextValue] = useState<string | null>(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState<readonly ComboBoxItem[]>([]);
  const [resolving, setResolving] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const displayValue = useMemo((): string => {
    if (textValue === null) {
      return value?.name ?? '';
    } else {
      return textValue;
    }
  }, [textValue, value]);

  const onChangeWrapper = useCallback(
    (textValue: string): void => {
      setTextValue(textValue);
      onChange(null);
    },
    [onChange],
  );

  const onValuePicked = useCallback(
    (value: ComboBoxItem): void => {
      setTextValue(null);
      setIsDropdownOpen(false);
      onChange(value);
    },
    [onChange],
  );

  useEffect((): VoidFunction | void => {
    if (textValue === null) {
      return;
    }

    const timer = setTimeout((): void => {
      setResolving(true);
      onAutocomplete?.(textValue)
        .then((data: readonly ComboBoxItem[]): void => {
          const matchingOption = data.find(
            ({ name }: ComboBoxItem): boolean =>
              name.toLocaleLowerCase() === textValue.toLowerCase(),
          );

          if (matchingOption !== undefined) {
            onChange(matchingOption);
            setTextValue(null);
            setIsDropdownOpen(false);
          } else {
            setAutocompleteOptions(data);
            if (data.length === 0) {
              setIsDropdownOpen(false);
            } else {
              setIsDropdownOpen(true);
            }
          }
        })
        .catch(console.warn)
        .finally((): void => setResolving(false));
    }, 300);

    return (): void => {
      clearTimeout(timer);
    };
  }, [textValue, onAutocomplete, onChange, name]);

  const onDropdownClose = useCallback((): void => {
    setIsDropdownOpen(false);
  }, []);

  const rightDecorator = useMemo((): React.ReactElement | null => {
    return resolving ? (
      <div className={classes.spinner} />
    ) : value?.id ? (
      <div className="w-3 h-3">
        <CheckmarkIcon fill="green" />
      </div>
    ) : null;
  }, [resolving, value]);

  return (
    <ComboBoxBase
      id={id}
      value={displayValue}
      name={name}
      label={label}
      placeholder={placeholder}
      options={autocompleteOptions}
      rightDecorator={rightDecorator}
      isDropdownOpen={isDropdownOpen}
      onDropdownClose={onDropdownClose}
      onChange={onChangeWrapper}
      onValuePicked={onValuePicked}
    />
  );
};
