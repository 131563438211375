import { useMemo } from 'react';

const getOffset = (count: number, isFirst: boolean): number => {
  switch (count) {
    case 1:
    case 2:
    case 3:
      return isFirst ? 0 : 45;
    case 4:
    case 5:
    case 6:
    case 7:
      return isFirst ? 0 : 135;
    case 8:
      return isFirst ? 0 : 140;
    default:
      return isFirst ? 45 : 140;
  }
};

const getIncrement = (length: number, isFirst: boolean): 0 | 40 | 45 => {
  if (isFirst) {
    return 45;
  } else if (length > 6) {
    return 40;
  } else {
    return 45;
  }
};

export const useIdealOffset = (count: number, isFirst: boolean): number => {
  return useMemo((): number => getOffset(count, isFirst), [count, isFirst]);
};

export const useIdealIncrement = (count: number, isFirst: boolean): 0 | 40 | 45 => {
  return useMemo((): 0 | 40 | 45 => getIncrement(count, isFirst), [count, isFirst]);
};
