import { QueryStatus } from '@reduxjs/toolkit/query';
import { Button } from 'components/genericButton';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import { EarnItem, ListingStatus } from 'views/EarnAndBuy/api';
import { ActionsContext, EarnAndBuyActions } from 'views/EarnAndBuy/context';
import styles from 'views/EarnAndBuy/Earn/components/styles.module.scss';

interface Props {
  readonly listing?: EarnItem;
}

export const ApplyButton: React.FC<Props> = ({ listing }: Props): React.ReactElement => {
  const context = useContext<EarnAndBuyActions | null>(ActionsContext);
  const user = useSelector(userSelector);

  const handleApply = useCallback(
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      if (listing) {
        context?.apply?.(listing.id);
      }
    },
    [context, listing],
  );

  const status = useMemo(
    (): QueryStatus => context?.applyStatus ?? QueryStatus.uninitialized,
    [context?.applyStatus],
  );

  const applyId = useMemo((): string | undefined => context?.applyId, [context?.applyId]);

  const applicable = React.useMemo((): boolean => {
    if (listing) {
      const { listing_owner } = listing;

      if (
        user.id === '' ||
        status === QueryStatus.pending ||
        status === QueryStatus.fulfilled ||
        listing.applied === true ||
        listing.status !== ListingStatus.open
      ) {
        return false;
      }

      return listing_owner.id !== user.id;
    } else {
      return false;
    }
  }, [status, listing, user.id]);

  const buttonText = React.useMemo((): string | React.ReactElement => {
    if (status === QueryStatus.pending && applyId === listing?.id) {
      return <div className={styles.spinner} />;
    }

    return 'Apply';
  }, [applyId, listing?.id, status]);

  return (
    <Button
      type="button"
      variant="primary"
      label={buttonText}
      disabled={!applicable}
      onClick={handleApply}
    />
  );
};
