import { DropdownItem } from 'components/dropdownFilter/DropdownFilter';

export enum OperationType {
  subsidiaries = 'Subsidiaries',
  revenues = 'Revenues',
}

export const operationTypeOptions: DropdownItem[] = [
  {
    id: OperationType.subsidiaries,
    label: OperationType.subsidiaries,
  },
  {
    id: OperationType.revenues,
    label: OperationType.revenues,
  },
];
