import { QueryStatus } from '@reduxjs/toolkit/query';
import { AmountInput } from 'components/amountInput';
import { Input } from 'components/forms/input';
import { TagsInput } from 'components/forms/tagsInput';
import { TextArea } from 'components/forms/textarea';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Modal } from 'components/modal';
import React, { Reducer, useEffect, useMemo } from 'react';
import { WithOptionalID } from 'types/withId';
import { useLazyGetEarnItemQuery } from 'views/EarnAndBuy/api';
import { CategoriesDropdown } from 'views/EarnAndBuy/Earn/components/categoriesDropdown';
import { TierRequirementDropdown } from 'views/EarnAndBuy/Earn/components/tierRequirementDropdown';
import {
  Action,
  ActionType,
  CreateListingPayload,
  initialState,
  reducer,
} from 'views/EarnAndBuy/Earn/modals/addListingReducer';

interface Props {
  readonly open: boolean;
  readonly busy: boolean;
  readonly listingId?: string;

  onSubmit(payload: WithOptionalID<CreateListingPayload>): Promise<void>;
  onClose(): void;
}

export const ListingFormModal: React.FC<Props> = ({
  listingId,
  open,
  busy,
  onSubmit,
  onClose,
}: Props): React.ReactElement => {
  const [state, dispatch] = React.useReducer<Reducer<CreateListingPayload, Action>>(
    reducer,
    initialState,
  );
  const [fetchListing, fetchStatus] = useLazyGetEarnItemQuery();

  useEffect((): void | VoidFunction => {
    if (listingId) {
      fetchListing(listingId)
        .then((result /* I hate that they don't care about types */): void => {
          const { data } = result;
          if (data) {
            dispatch({ type: ActionType.initialize, data: data });
          }
        })
        .catch(console.warn);
    }

    return (): void => {
      dispatch({ type: ActionType.reset });
    };
  }, [fetchListing, listingId]);

  const handleSubmit = React.useCallback(async (): Promise<void> => {
    await onSubmit({ id: listingId, ...state });
  }, [listingId, onSubmit, state]);

  const setValue = React.useCallback(function build(
    name: keyof CreateListingPayload,
  ): (_: CreateListingPayload[keyof CreateListingPayload]) => void {
    return function (value: CreateListingPayload[keyof CreateListingPayload]): void {
      dispatch({
        type: ActionType.setValue,
        data: {
          name: name,
          value: value,
        },
      });
    };
  },
  []);

  const modalTitle = useMemo(
    (): string => (listingId === undefined ? 'Add a new listing' : 'Update listing'),
    [listingId],
  );

  return (
    <Modal isOpen={open} closeOnClickOutside={false} onClose={onClose}>
      <Modal.Content title={modalTitle}>
        <form autoComplete="off" className="flex flex-col gap-4 w-modal-sm mt-3" onSubmit={ignore}>
          <div className="flex items-center gap-2">
            <div className="w-2/3">
              <Input name="title" label="Title" value={state.title} onChange={setValue('title')} />
            </div>
            <div className="w-1/3">
              <CategoriesDropdown value={state.category} onChange={setValue('category')} />
            </div>
          </div>
          <TextArea
            name="description"
            label="Description"
            value={state.description}
            onChange={setValue('description')}
          />
          <TagsInput value={state.tags} name="tags" onChange={setValue('tags')} />
          <TextArea
            name="completion_instructions"
            label="Completion Instructions"
            value={state.completion_instructions}
            onChange={setValue('completion_instructions')}
          />
          <TextArea
            name="how_to_apply"
            label="How to apply?"
            value={state.how_to_apply}
            onChange={setValue('how_to_apply')}
          />
          <AmountInput
            name="payment_amount"
            label="Payment"
            value={state.payment_amount}
            currency={state.payment_currency}
            onCurrencyChange={setValue('payment_currency')}
            onValueChange={setValue('payment_amount')}
          />
          <div className="w-full">
            <div className="w-1/2">
              <TierRequirementDropdown
                value={state.minimum_required_plan_id}
                onChange={setValue('minimum_required_plan_id')}
              />
            </div>
          </div>

          <div className="flex items-center justify-end">
            <button
              className="bg-blue text-white h-12 rounded font-poppinsMedium text-md w-20"
              onClick={handleSubmit}
            >
              {listingId ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </Modal.Content>
      <FullScreenLoadingSpinner visible={busy || fetchStatus.status === QueryStatus.pending} />
    </Modal>
  );
};

const ignore = (event: React.FormEvent<HTMLFormElement>): void => {
  event.preventDefault();
};
