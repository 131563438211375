import { Dispatch, useEffect } from 'react';
import { FormErrors } from 'utils/forms';
import { PaymentFormState } from 'views/Landing/SignUp/types';

export const useDebouncedErrorReset = (
  errors: FormErrors<PaymentFormState>,
  setErrors: Dispatch<FormErrors<PaymentFormState>>,
): void => {
  useEffect((): void | VoidFunction => {
    if (!errors.termsAndConditionsAccepted) {
      return;
    }

    const timeout = setTimeout((): void => {
      setErrors({});
    }, 3000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [errors.termsAndConditionsAccepted, setErrors]);
};
