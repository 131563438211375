import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  clearFinancialHighlightsStatement,
  companyDetailsSelector,
  financialHighlightsSelector,
  loadingFinancialHighlightsSelector,
} from 'redux/reducers/companyReducer';
import { getFinancialHighlightsService } from 'redux/services/companyService';
import { Props } from 'views/DataAnalysis/Company/Details/Financials/props';
import StatementView from 'views/DataAnalysis/Company/Details/Financials/statementView';

const Highlights: React.FC<Props> = ({ options, onPeriodChange }: Props): React.ReactElement => {
  const { companyId } = useParams<{ companyId: string }>();

  const dispatch = useDispatch<any>();
  const company = useSelector(companyDetailsSelector);
  const statement = useSelector(financialHighlightsSelector);
  const loading = useSelector(loadingFinancialHighlightsSelector);

  useEffect((): VoidFunction | void => {
    if (!companyId) {
      return;
    }

    dispatch(getFinancialHighlightsService(companyId, options));
    return (): void => {
      dispatch(clearFinancialHighlightsStatement());
    };
  }, [dispatch, companyId, options]);

  return (
    <StatementView
      organization={company}
      statement={statement}
      loading={loading}
      convertedToUSD={options.convert_to_usd}
      onPeriodChange={onPeriodChange}
    />
  );
};

export default Highlights;
