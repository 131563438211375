import { AxiosResponse } from 'axios';
import { PostBody } from 'components/postBodyInput/types';
import { FeedComment } from 'redux/reducers/feedDetailsReducer';
import { FeedItemFeedbackResponse } from 'redux/reducers/feedReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusCreated, HttpStatusOk } from 'utils/statusCodes';
import { DeleteFeedCommentResult, FeedCategory } from 'views/Home/types';

export const postComment = async (
  objectId: string,
  body: PostBody,
  category: FeedCategory,
): Promise<any> => {
  const url = ((): string => {
    switch (category) {
      case FeedCategory.post:
        return `${API_V1_PATH}/posts/${objectId}/comments`;
      case FeedCategory.news:
        return `${API_V1_PATH}/news/${objectId}/comments`;
      default:
        return '';
    }
  })();

  try {
    const response = await api.post(url, {
      body,
    });

    return response.data;
  } catch (error) {
    console.warn(error);
  }
};

const getComments = async (objectId: string, group: 'posts' | 'news'): Promise<FeedComment[]> => {
  const response = await api.get(`${API_V1_PATH}/${group}/${objectId}/comments`);
  if (response.status === HttpStatusOk) {
    const rawComments = response.data;
    return rawComments.map((comment: any): FeedComment => FeedComment.fromJson(comment));
  } else {
    throw new Error(response.data);
  }
};

export const postReply = async (commentId: string, body: PostBody): Promise<FeedComment> => {
  const response: AxiosResponse = await api.post(
    `${API_V1_PATH}/feed-comments/${commentId}/reply`,
    {
      body,
    },
  );
  if (response.status === HttpStatusCreated) {
    return FeedComment.fromJson(response.data);
  } else {
    throw new Error(response.data);
  }
};

export const likeFeedComment = async (feedCommentId: string): Promise<FeedItemFeedbackResponse> => {
  const response = await api.post(`/api/v1/feed-comments/${feedCommentId}/like`);
  if (response.status === HttpStatusOk) {
    return response.data as FeedItemFeedbackResponse;
  } else {
    throw new Error(response.data);
  }
};

export const dislikeFeedComment = async (
  feedCommentId: string,
): Promise<FeedItemFeedbackResponse> => {
  const response = await api.post(`/api/v1/feed-comments/${feedCommentId}/dislike`);
  if (response.status === HttpStatusOk) {
    return response.data as FeedItemFeedbackResponse;
  } else {
    throw new Error(response.data);
  }
};

export const getPostComments = (postId: string): Promise<readonly FeedComment[]> =>
  getComments(postId, 'posts');

export const getNewsComments = (postId: string): Promise<readonly FeedComment[]> =>
  getComments(postId, 'news');

export const getFeedCommentReplies = async (feedCommentId: string): Promise<FeedComment[]> => {
  const response = await api.get(`${API_V1_PATH}/feed-comments/${feedCommentId}/replies`);
  if (response.status === HttpStatusOk) {
    const rawComments = response.data;
    return rawComments.map((comment: any): FeedComment => FeedComment.fromJson(comment));
  } else {
    throw new Error(response.data);
  }
};

export const deleteFeedComment = async (
  feedCommentId: string,
): Promise<DeleteFeedCommentResult[]> => {
  const response: AxiosResponse = await api.delete(`/api/v1/feed-comments/${feedCommentId}`);
  if (response.status === HttpStatusOk) {
    // TODO: really the response would this very object
    return response.data;
  } else {
    throw new Error(response.data);
  }
};
