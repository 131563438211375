import { InputBase } from 'components/inputBase';
import React, { useCallback } from 'react';

interface OwnProps {
  readonly id?: string;
  readonly name: string;
  readonly label: string;
  readonly value: string;

  readonly className?: string;
  readonly error?: string;
  readonly maxLength?: number;
  readonly rows?: number;

  onChange?(value: string): void;
  onReturn?(): void;
  onTab?(): void;
}

type Props = React.PropsWithoutRef<OwnProps> & React.RefAttributes<HTMLTextAreaElement>;

export const TextArea: React.ForwardRefExoticComponent<Props> = React.forwardRef(function TextArea(
  {
    id,
    name,
    label,
    value,
    className,
    rows = 2,

    onTab,
    onReturn,
    onChange,
  }: Props,
  ref: React.Ref<HTMLTextAreaElement>,
): React.ReactElement {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <InputBase
      ref={ref}
      id={id}
      name={name}
      label={label}
      value={value}
      className={className}
      onTab={onTab}
      onReturn={onReturn}
      onChange={handleChange}
    >
      <textarea rows={rows} className={textareaClassName} />
    </InputBase>
  );
});

const textareaClassName =
  'relative p-3 w-full font-poppins text-base bg-transparent outline-none resize-none scroller leading-6';
