import React, { ReactElement } from 'react';

interface Props {
  id?: string;
  children: ReactElement;
  paddingX?: string;
  paddingY?: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  fontSize?: string;
  className?: string;
  onClick?: () => void;
}

/**
 * @deprecated
 */
const Tag: React.FC<Props> = ({
  id,
  children,
  paddingX = 'px-1',
  paddingY = 'py-0.5',
  bgColor = 'bg-blue-light',
  borderColor = 'border-blue',
  textColor = 'text-gray-dark',
  fontSize = 'text-xs',
  className = '',
  onClick,
}) => {
  const tagClass = `
    font-poppinsSemiBold
    border
    capitalize
    rounded-5
    ${paddingX} 
    ${paddingY} 
    ${textColor}
    ${bgColor}
    ${fontSize}
    ${className}
    ${borderColor}`;

  return (
    <div id={id} onClick={onClick} className={tagClass}>
      {children}
    </div>
  );
};

export default Tag;
