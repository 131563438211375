import { Dispatch } from '@reduxjs/toolkit';
import {
  Connection,
  ConnectionsCount,
  finalizeSearchUserByKeyword,
  ProcessingType,
  reset,
  setAllConnections,
  setFollowers,
  setFollowing,
  setProcessingState,
  setSocialCount,
  setUserSearchResults,
  startSearchUserByKeyword,
  userFollowed as connectionsUserFollowed,
  userUnfollowed as connectionsUserUnfollowed,
} from 'redux/reducers/connectionsReducer';
import {
  setProfileUserFollowedFlag,
  unsetProfileUserFollowedFlag,
} from 'redux/reducers/profileReducer';
import {
  showInfoModal,
  userFollowed as sharedUserFollowed,
  userUnfollowed as sharedUserUnfollowed,
} from 'redux/reducers/sharedReducer';
import {
  followUser,
  getConnections,
  getConnectionsCount,
  searchUsersByKeyword,
  unfollowUser,
} from 'redux/services/connectionsService';
import { ThunkLikeAction } from 'types/APIAction';
import { Page } from 'types/page';
import { ProcessingState } from 'types/processingState';
import { delayedExecution } from 'utils/delayedExecution';
import { HttpStatusConflict, HttpStatusQuotaExceeded } from 'utils/statusCodes';
import { ConnectionType } from 'views/Connections/Overview/types';

export const searchUsersByKeywordAction =
  (keyword: string): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startSearchUserByKeyword());
    try {
      const results = await searchUsersByKeyword(keyword);
      dispatch(setUserSearchResults(results));
    } catch (error: any) {
      // We do not want to disturb the user, but still want to know what happened
      console.warn(error);
    } finally {
      dispatch(finalizeSearchUserByKeyword());
    }
  };

export const getConnectionsAction =
  (pageNumber: number, pageSize: number, type: ConnectionType): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(reset());
    dispatch(setProcessingState(ProcessingState.processing(ProcessingType.loadingConnections)));

    try {
      const connections: Page<Connection> = await delayedExecution(
        getConnections(pageNumber, pageSize, type),
        0.5,
      );
      switch (type) {
        case ConnectionType.followers:
          dispatch(setFollowers(connections));
          break;
        case ConnectionType.followees:
          dispatch(setFollowing(connections));
          break;
        case ConnectionType.connections:
          dispatch(setAllConnections(connections));
          break;
      }
    } catch (error: any) {
      const { data } = error.response;

      dispatch(setProcessingState(ProcessingState.error()));
      dispatch(showInfoModal({ type: 'error', message: data }));
    } finally {
      dispatch(setProcessingState(ProcessingState.idle()));
    }
  };

export const getConnectionsCountAction =
  (): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const connectionsCount: ConnectionsCount = await getConnectionsCount();
      dispatch(setSocialCount(connectionsCount));
    } catch (error: any) {
      const { data } = error.response;

      dispatch(showInfoModal({ type: 'error', message: data }));
    }
  };

export const followUserAction =
  (id: string): ThunkLikeAction =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setProcessingState(ProcessingState.processing(ProcessingType.following)));
    try {
      const { data } = await followUser(id);

      dispatch(setProfileUserFollowedFlag(id));
      dispatch(connectionsUserFollowed(data));
      dispatch(sharedUserFollowed(data.id));

      dispatch(setProcessingState(ProcessingState.success()));
      dispatch(getConnectionsCountAction());
    } catch (error: any) {
      const { data, status } = error.response;

      dispatch(setProcessingState(ProcessingState.error()));
      if (status === HttpStatusQuotaExceeded) {
        dispatch(
          showInfoModal({
            type: 'error',
            message:
              'You have reached your follower limit. Please upgrade your account to continue adding followers.',
          }),
        );
      } else if (status === HttpStatusConflict) {
        // DO nothing at all
        return;
      } else {
        dispatch(showInfoModal({ type: 'error', message: data }));
      }
    } finally {
      setTimeout((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      }, 0);
    }
  };

export const unfollowUserAction =
  (id: string): ThunkLikeAction =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setProcessingState(ProcessingState.processing(ProcessingType.unfollowing)));
    try {
      await unfollowUser(id);

      dispatch(unsetProfileUserFollowedFlag(id));

      dispatch(connectionsUserUnfollowed(id));
      dispatch(sharedUserUnfollowed(id));
      dispatch(getConnectionsCountAction());
    } catch (error: any) {
      const { data } = error.response;

      dispatch(setProcessingState(ProcessingState.error()));
      dispatch(showInfoModal({ type: 'error', message: data }));
    } finally {
      dispatch(setProcessingState(ProcessingState.idle()));
    }
  };
