import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

type Props = {
  readonly id?: string;
  readonly value: string;
  readonly placeholder: string;

  onClickReset(): void;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void;
};

const KeywordSearch: React.FC<Props> = ({
  id,
  value,
  placeholder,
  onClickReset,
  onChange,
  onKeyPress,
}: Props) => {
  return (
    <div className="relative mb-3 md:mb-0 lg:w-80 md:w-60 sm:w-full xs:w-full">
      <SVGIcon name="dark-search-icon" className="top-3 absolute h-4" />
      <input
        id={id}
        className="h-10 pl-9 outline-none border-b border-gray-medium font-poppins w-full text-base"
        value={value}
        placeholder={placeholder}
        type="text"
        onChange={onChange}
        onKeyUp={onKeyPress}
      />
      {value && (
        <SVGIcon
          name="close-popup-icon"
          className="absolute top-4 right-0 cursor-pointer h-2.5"
          onClick={onClickReset}
        />
      )}
    </div>
  );
};

export default KeywordSearch;
