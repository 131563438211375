import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { NotificationType } from 'redux/reducers/sharedReducer';

interface Props {
  readonly notificationType: NotificationType;
  readonly source: string;
  readonly date: string;
}

export const DateText: React.FC<Props> = ({
  notificationType,
  source,
  date,
}: Props): React.ReactElement => {
  const prettyNotificationType = useMemo((): string => {
    if (notificationType === NotificationType.UserTag) {
      return 'User Mention';
    } else {
      return notificationType;
    }
  }, [notificationType]);

  if (isMobile) {
    return (
      <div className="text-sm text-gray">
        <div>
          {date} {source === 'Finvar' ? 'by FINVAR' : ''}
        </div>
        <div className="text-blue italic">{prettyNotificationType}</div>
      </div>
    );
  } else {
    return (
      <>
        <div className="text-sm text-gray">
          {date} {source === 'Finvar' ? 'by FINVAR' : ''} -{' '}
          <i className="text-blue">{prettyNotificationType}</i>
        </div>
      </>
    );
  }
};
