import { Placement } from '@popperjs/core';
import { Popup } from 'components/tooltip/popup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  readonly content: React.FunctionComponent | React.ReactElement | string;
  readonly placement?: Placement;
  readonly variant?: 'light' | 'dark' | 'error';
  readonly fitContent?: boolean;
}

export const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  content,
  placement = 'bottom',
  variant = 'dark',
  children,
  fitContent = false,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const [aboutToMakeVisible, setAboutToMakeVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const targetElementRef = useRef<HTMLDivElement>(null);

  const showTooltip = useCallback((event: MouseEvent): void => {
    // Overlapping tooltips can happen and this prevents the
    // deeper ones to display
    event.stopPropagation();
    event.preventDefault();

    setAboutToMakeVisible(true);
  }, []);

  const hideTooltip = useCallback((): void => {
    setAboutToMakeVisible(false);
    // It might happen that we don't actually make it and call this
    setVisible(false);
  }, []);

  useEffect((): VoidFunction | void => {
    if (!aboutToMakeVisible) {
      setVisible(false);
      return;
    }

    const timer = setTimeout((): void => {
      setVisible(true);
    }, 150);
    return (): void => {
      if (!aboutToMakeVisible) {
        setVisible(false);
      }

      clearTimeout(timer);
    };
  }, [aboutToMakeVisible]);

  useEffect((): VoidFunction | void => {
    const targetElement = targetElementRef.current;
    if (visible) {
      return;
    }

    targetElement?.addEventListener('mouseenter', showTooltip);
    return (): void => {
      targetElement?.removeEventListener('mouseenter', showTooltip);
    };
  }, [visible, showTooltip]);

  useEffect((): VoidFunction | void => {
    const targetElement = targetElementRef.current;

    targetElement?.addEventListener('mouseleave', hideTooltip);
    // We have to check if the mouse left the document too, or else some tooltips
    // could be left around forever
    document.addEventListener('mouseleave', hideTooltip);
    document.addEventListener('click', hideTooltip);
    return (): void => {
      targetElement?.removeEventListener('mouseleave', hideTooltip);
      document.removeEventListener('mouseleave', hideTooltip);
      document.removeEventListener('click', hideTooltip);
    };
  }, [hideTooltip]);

  const Title = useMemo((): React.FunctionComponent => {
    if (typeof content === 'string') {
      return function TitleComponent(): React.ReactElement {
        return (
          <>
            {/* The following element has relative position just so that the z-index of the arrow is lower */}
            <div className="relative text-xs font-poppinsMedium leading-4">{content}</div>
          </>
        );
      };
    } else if (React.isValidElement(content)) {
      return function TitleComponent(): React.ReactElement {
        return (
          <>
            {/* The following element has relative position just so that the z-index of the arrow is lower */}
            <div className="relative font-poppinsMedium text-xs">{content}</div>
          </>
        );
      };
    } else {
      return content as React.FunctionComponent<any>;
    }
  }, [content]);

  if (!React.isValidElement(children)) {
    throw new Error('tooltips can be "any" component, but they can only have 1 child');
  }

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: targetElementRef })}

      <Popup
        target={targetElementRef.current}
        placement={placement}
        variant={variant}
        isOpen={visible}
        fitContent={fitContent}
      >
        <Title />
      </Popup>
    </>
  );
};
