import { Select, SelectItem } from 'components/select';
import React from 'react';
import { PortfolioArtItem, TypeOfArt, typeOfArtLabels } from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { ArtItemFormSelector } from 'views/Portfolios/PortfoliosModal/artItemFormSelector';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioArtItem;
}

export const ArtItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioArtItem>();

  return (
    <div className="flex flex-col gap-2">
      <Select
        id="typeOfArt"
        label="Type of Art"
        items={typesOfCash}
        value={item.typeOfArt}
        onChange={updaterFor('typeOfArt')}
      />
      <ArtItemFormSelector item={item} />
    </div>
  );
};

const typesOfCash: ReadonlyArray<SelectItem<TypeOfArt>> = enumToSelectItems(
  TypeOfArt,
  typeOfArtLabels,
  (value: TypeOfArt): boolean => value !== TypeOfArt.painting,
);
