import { differenceInDays, isAfter } from 'date-fns';
import { Time } from 'types/time';
import { mustParseDate, parseTime } from 'utils/dateTime';

const SECONDS_IN_ONE_MINUTE = 60;
const SECONDS_IN_ONE_HOUR = 60 * SECONDS_IN_ONE_MINUTE;

export const eventsSortFn = (e1: any, e2: any): number => {
  const firstEventStartDate = mustParseDate(e1.start_date, e1.start_time, e1.timezone);
  const secondEventStartDate = mustParseDate(e2.start_date, e2.start_time, e2.timezone);

  if (isAfter(firstEventStartDate, secondEventStartDate)) {
    return differenceInDays(firstEventStartDate, secondEventStartDate);
  }

  const sT1: Time | null = parseTime(e1.start_date, e1.start_time, e1.timezone);
  const sT2: Time | null = parseTime(e2.start_date, e2.start_time, e2.timezone);

  if (sT1 === null) return -1;
  if (sT2 === null) return 1;

  return (
    (sT1.hours - sT2.hours) * SECONDS_IN_ONE_HOUR +
    (sT1.minutes - sT2.minutes) * SECONDS_IN_ONE_MINUTE +
    (sT1.seconds - sT2.seconds)
  );
};
