import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import { setEventDetails } from 'redux/reducers/eventDetailsReducer';
import {
  addEventToPortfolioStream,
  removeEventFromPortfolioStream,
  replaceEventInPortfolioStream,
} from 'redux/reducers/portfoliosReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import {
  addEventToStream,
  addStream,
  removeEventFromStream,
  removeStreams,
  replaceEventInStream,
  replaceStream,
  setAllAndUpcomingEvents,
  setProcessingState,
  StreamForm,
  TimelineAction,
} from 'redux/reducers/timelineReducer';
import { convertToMembersPayload } from 'sharable/helpers';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { ProcessingState } from 'types/processingState';
import { CreateEventPayload, Stream, TimelineFilters } from 'types/timeline';
import { AllAndUpcomingEventsFilters } from 'types/timelineUpcomingEvent';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const getStreamsService = async (filters: TimelineFilters): Promise<readonly Stream[]> => {
  const response = await api.get(`${API_V1_PATH}/timeline`, { params: filters });
  return response.data ?? [];
};

export const getSponsoredEventsService = () => (_: Dispatch) => {
  void _;
  /*dispatch(setProcessingState(ProcessingState.processing(TimelineProcessingType.loadingStreams)));
  api
    .get(`${API_VERSION}/events/sponsored`)
    .then(res => {
      if (res.data) {
        const events = res.data as Event[];
        dispatch(getSponsoredEventsSuccess(events));
      } else throw new Error();
    })
    .catch(() => {
      dispatch(getSponsoredEventsFailed());
    });*/
};

export const deleteStreamService =
  (stream_ids: string[]): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    const actionType = TimelineAction.deletingStream;

    dispatch(setProcessingState(ProcessingState.processing(actionType)));
    api
      .post(`${API_V1_PATH}/timeline/delete-multiple`, { stream_ids })
      .then((response: AxiosResponse): void => {
        const accepted = response.data?.accepted ?? [];

        dispatch(removeStreams(accepted));
        dispatch(setProcessingState(ProcessingState.success(actionType)));
      })
      .catch((error: any): void => {
        dispatch(
          showInfoModal({
            type: 'error',
            // FIXME: Not sure if this is a _time will resolve the issue_ situation, change the message?
            message:
              error?.message ||
              'At the current time, this stream cannot be deleted. Please check back in later.',
          }),
        );

        dispatch(setProcessingState(ProcessingState.error(error)));
      })
      .finally((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      });
  };

export const createStreamService =
  (payload: StreamForm): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    const { members, category } = payload;
    const actionType = TimelineAction.creatingStream;

    dispatch(setProcessingState(ProcessingState.processing(actionType)));
    api
      .post(`${API_V1_PATH}/timeline`, {
        ...payload,
        category: category.toUpperCase(),
        members: members.map(convertToMembersPayload),
      })
      .then((response: AxiosResponse<Stream>): void => {
        dispatch(addStream(response.data));
        dispatch(setProcessingState(ProcessingState.success(actionType)));
      })
      .catch((error: any): void => {
        dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
        dispatch(setProcessingState(ProcessingState.error(error)));
      })
      .finally((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      });
  };

export const updateStreamService =
  (streamId: string, payload: StreamForm): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    const { members, category } = payload;
    const actionType = TimelineAction.updatingStream;

    dispatch(setProcessingState(ProcessingState.processing(actionType)));
    api
      .put(`${API_V1_PATH}/timeline/${streamId}`, {
        ...payload,
        category: category.toUpperCase(),
        members: members.map(convertToMembersPayload),
      })
      .then((response: AxiosResponse<Stream>): void => {
        dispatch(replaceStream(response.data));
        dispatch(setProcessingState(ProcessingState.success(actionType)));
      })
      .catch((error: any): void => {
        dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
        dispatch(setProcessingState(ProcessingState.error(error)));
      })
      .finally((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      });
  };

export const deleteEventService =
  (eventId: string, streamId: string): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    const actionType = TimelineAction.deletingEvent;

    dispatch(setProcessingState(ProcessingState.processing(actionType)));
    api
      .delete(`${API_V1_PATH}/events/${eventId}`)
      .then((): void => {
        dispatch(removeEventFromStream({ eventId, streamId }));
        dispatch(removeEventFromPortfolioStream({ eventId, streamId }));
        dispatch(setProcessingState(ProcessingState.success(actionType)));
      })
      .catch((error: any): void => {
        dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
        dispatch(setProcessingState(ProcessingState.error(error)));
      })
      .finally((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      });
  };

export const createEventService =
  (payload: CreateEventPayload): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    const actionType = TimelineAction.creatingEvent;

    dispatch(setProcessingState(ProcessingState.processing(actionType)));
    api
      .post(`${API_V1_PATH}/events/`, payload)
      .then((response: AxiosResponse): void => {
        if (response.data) {
          const event = response.data;
          dispatch(addEventToStream(event));
          dispatch(addEventToPortfolioStream(event));
        }
        dispatch(setProcessingState(ProcessingState.success(actionType)));
      })
      .catch((error: any): void => {
        dispatch(showInfoModal({ type: 'error', message: error?.message ?? errorMessageDefault }));
        dispatch(setProcessingState(ProcessingState.error(error)));
      })
      .finally((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      });
  };

export const updateEventService =
  (eventId: string, payload: CreateEventPayload): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    const actionType = TimelineAction.updatingEvent;

    dispatch(setProcessingState(ProcessingState.processing(actionType)));
    api
      .put(`${API_V1_PATH}/events/${eventId}`, payload)
      .then((response: AxiosResponse): void => {
        const event = response.data;

        dispatch(replaceEventInStream(event));
        dispatch(replaceEventInPortfolioStream(event));
        dispatch(setEventDetails(event));
        dispatch(setProcessingState(ProcessingState.success(actionType)));
      })
      .catch((error: any): void => {
        dispatch(showInfoModal({ type: 'error', message: error?.message ?? errorMessageDefault }));
        dispatch(setProcessingState(ProcessingState.error(error)));
      })
      .finally((): void => {
        dispatch(setProcessingState(ProcessingState.idle()));
      });
  };

export const fetchAllAndUpcomingEvents = createAPIAction(function fetchAllAndUpcomingEvents(
  client: HttpClient,
): ActionsGenerator {
  return async function* (
    filters: AllAndUpcomingEventsFilters & { order_by?: string },
  ): AsyncGenerator<AnyAction> {
    const actionType = TimelineAction.fetchingAllAndUpcoming;

    yield setProcessingState(ProcessingState.processing(actionType));
    const response = await client.GET(`${API_V1_PATH}/timeline/all-and-upcoming-events`, filters);
    if (response.status !== 200) {
      // Error
      yield setProcessingState(ProcessingState.error(actionType));
    } else {
      const { data } = response;
      yield setAllAndUpcomingEvents(data);
    }
  };
});
