import 'components/membersPopUp/MembersPopup.scss';

import React from 'react';
import { Member } from 'sharable/types';

interface Props {
  id?: string;
  portfolios?: boolean;
  portfolioMembers?: Member[];
  membersCount?: number;
  streamId?: string;
}

const MembersPopup: React.FC<React.PropsWithChildren<Props>> = (): React.ReactElement => {
  // const [showMembers, setShowMembers] = useState(false);
  // const members = useSelector(streamMembersSelector);
  // const loadingStreamMembers = useSelector(loadingStreamMembersSelector);
  // const dispatch = useDispatch<any>();
  // membersCount = portfolios ? portfolioMembers.length : membersCount;
  //
  // const fetchMembers = () => {
  //   setShowMembers(true);
  //   if (streamId && !portfolios) dispatch(getStreamMembers(streamId));
  // };
  //
  // return (
  //   <div
  //     id={id}
  //     className={`relative ${portfolios && 'z-popup'}`}
  //     onMouseEnter={fetchMembers}
  //     onMouseLeave={() => membersCount <= 3 && setShowMembers(false)}
  //   >
  //     <div>{children}</div>
  //     {showMembers && (
  //       <div
  //         className="popup-container"
  //         onMouseEnter={() => setShowMembers(true)}
  //         onMouseLeave={() => setShowMembers(false)}
  //       >
  //         <div className="popup-content scroller flex flex-col space-y-4">
  //           {members.map((member, index) => {
  //             const { email, full_name } = member;
  //             return (
  //               <div key={index} className="flex items-center">
  //                 <div className="w-2/12">
  //                   <img src={ProfilePhoto} className="w-12 h-12" alt="profile" />
  //                 </div>
  //                 <div className="w-5/12 pl-2">
  //                   <h2 className="text-lg truncate">{email}</h2>
  //                 </div>
  //                 <div className="w-5/12 pl-2">
  //                   <p className="font-poppins truncate text-base">{full_name}</p>
  //                 </div>
  //               </div>
  //             );
  //           })}
  //           {loadingStreamMembers && <SpinnerLoader />}
  //           {!membersCount && (
  //             <div className="flex justify-center items-center">
  //               <p className="font-poppins text-base">{portfolios ? 'No followers' : 'No members'}</p>
  //             </div>
  //           )}
  //         </div>
  //         <div className="arrow" />
  //       </div>
  //     )}
  //   </div>
  // );
  return <div />;
};
export default MembersPopup;
