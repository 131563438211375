import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AccessMode } from 'types/accessMode';
import { Page } from 'types/page';
import { baseURL, defaultAPIHeaders } from 'utils/config/axiosConfig';

export enum ListingStatus {
  open = 'Open',
  applied = 'Applied',
}

export interface ListingOwner {
  readonly id: string;
  readonly name: string;
}

export interface NamedObject {
  readonly id: number;
  readonly name: string;
}

export interface SpecialOffer {
  readonly id: string;
  readonly posted_at: string;
  readonly listing_owner: ListingOwner;
  readonly category: NamedObject;
  readonly title: string;
  readonly requirements?: readonly string[];
  readonly status: ListingStatus;
  readonly access_mode: AccessMode;
  readonly required_feature_level: number;
  readonly minimum_required_plan_id: string;
}

export interface Listing {
  readonly id: string;
  readonly posted_at: string;
  readonly listing_owner: ListingOwner;
  readonly category: NamedObject;
  readonly title: string;
  readonly payment_amount: number;
  readonly payment_currency: string;
  readonly payment_unit: NamedObject;
  readonly requirements?: readonly string[];
  readonly status: ListingStatus;
  readonly access_mode: AccessMode;
  readonly required_feature_level: number;

  readonly minimum_required_plan_id: string;
  readonly applied?: boolean;
}

const api = createApi({
  reducerPath: 'publicEarnAndBuyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}/api/v1/public`,
    prepareHeaders: defaultAPIHeaders,
  }),
  tagTypes: ['Listings', 'SpecialOffers'],
  endpoints: builder => ({
    listListings: builder.query<Page<Listing>, void>({
      query: (): string => 'earn/?page_number=1&page_size=10',
      providesTags: ['Listings'],
    }),
    listSpecialOffers: builder.query<Page<SpecialOffer>, void>({
      query: (): string => 'special-offers/?page_number=1&page_size=10',
      providesTags: ['SpecialOffers'],
    }),
  }),
});

export default api;

export const { useListSpecialOffersQuery, useListListingsQuery } = api;
