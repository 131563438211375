import React, { useMemo } from 'react';

interface Props {
  readonly variant?: 'regular' | 'empty';
  onClick?(): void;
}

export const Tag: React.FC<React.PropsWithChildren<Props>> = ({
  variant = 'regular',
  children,
  onClick,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const className = useMemo((): string => {
    return [
      onClick ? clickableClass : regularClass,
      variant === 'empty'
        ? 'bg-gray-light text-gray-medium border-gray-medium'
        : 'bg-blue-light border-blue',
    ].join(' ');
  }, [onClick, variant]);

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

const regularClass =
  'leading-5 text-xs font-poppinsSemiBold text-gray px-1 py-0 rounded border cursor-default';
const clickableClass = `${regularClass} cursor-pointer hover:opacity-50`;
