export const isEmptyOrNullOrUndefined = <T>(value: T | null | undefined): value is T => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  return false;
};
