import { CommonFilter } from 'components/filters/helpers';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const tabToNameMap: { [tab in DataAndAnalysisMainTabs]: string } = {
  [DataAndAnalysisMainTabs.Popular]: 'popular-industries',
  [DataAndAnalysisMainTabs.My]: 'my-industries',
  [DataAndAnalysisMainTabs.All]: 'all-industries',
};

export const createFiltersConfig = (tab: DataAndAnalysisMainTabs): readonly CommonFilter[] => {
  const key = tabToNameMap[tab];

  return [
    {
      type: 'backend',
      label: 'Industry Name',
      url: `${API_V1_PATH}/filters/industries/${key}/names`,
      name: 'industry_name',
    },
    {
      type: 'backend',
      label: 'Sector',
      url: `${API_V1_PATH}/filters/industries/${key}/sectors`,
      name: 'sector',
    },
    {
      type: 'backend',
      label: 'Tags',
      url: `${API_V1_PATH}/filters/industries/${key}/tags`,
      name: 'tags',
    },
    {
      type: 'backend',
      label: 'Key Indicators',
      url: `${API_V1_PATH}/filters/industries/${key}/key-indicators`,
      name: 'key_indicators',
    },
  ];
};

const filters: { [tab in DataAndAnalysisMainTabs]: readonly CommonFilter[] } = {
  [DataAndAnalysisMainTabs.Popular]: createFiltersConfig(DataAndAnalysisMainTabs.Popular),
  [DataAndAnalysisMainTabs.My]: createFiltersConfig(DataAndAnalysisMainTabs.My),
  [DataAndAnalysisMainTabs.All]: createFiltersConfig(DataAndAnalysisMainTabs.All),
};

export default filters;
