import { CommonFilter } from 'components/filters/helpers';
import { capitalize } from 'lodash';
import { KeyFactor, KeyFactorType } from 'redux/reducers/keyFactorsReducer';

export const createFiltersConfig = (
  factorType: KeyFactorType,
  data: readonly KeyFactor[],
): readonly CommonFilter[] => [
  {
    name: 'type',
    type: 'local',
    label: `${capitalize(factorType)} Type`,
    options: Array.from(new Set(data.map((keyFactor: KeyFactor): string => keyFactor.name))),
  },
  {
    name: 'impact',
    type: 'local',
    label: 'Impact',
    options: Array.from(
      new Set(data.map((keyFactor: KeyFactor): string => keyFactor.impact as string)),
    ),
  },
  {
    name: 'probability',
    type: 'local',
    label: 'Probability',
    options: Array.from(
      new Set(data.map((keyFactor: KeyFactor): string => keyFactor.probability as string)),
    ),
  },
  {
    name: 'level',
    type: 'local',
    label: `${capitalize(factorType)} Level`,
    options: Array.from(
      new Set(data.map((keyFactor: KeyFactor): string => keyFactor.probability as string)),
    ),
  },
];
