import axios from 'axios';
import { Filters } from 'components/filters';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listIndicatorsAction, listPopularIndicatorsAction } from 'redux/actions/indicatorsActions';
import { Indicator, indicatorsSelector } from 'redux/reducers/indicatorsReducer';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { ProcessingStateEnum } from 'types/processingState';
import columns from 'views/DataAnalysis/Indicators/columns';
import filtersConfig from 'views/DataAnalysis/Indicators/filters';

interface Props {
  readonly tab: DataAndAnalysisMainTabs;
}

export const IndicatorsTable: React.FC<Props> = ({ tab }: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const queryParameters = useQueryParameters();

  const state = useSelector(indicatorsSelector);
  const [sorting, handleSortChange] = useSort<Indicator>('name');
  const { indicators: indicatorsPage } = state;

  const handleFiltersChange = useFilters(filtersConfig, { resetOnConfigChange: true });

  React.useEffect((): VoidFunction => {
    const tokenSource = axios.CancelToken.source();
    switch (tab) {
      case DataAndAnalysisMainTabs.All:
        dispatch(listIndicatorsAction(tokenSource.token, queryParameters));
        break;
      case DataAndAnalysisMainTabs.Popular:
        dispatch(listPopularIndicatorsAction(tokenSource.token, queryParameters));
        break;
      default:
        console.warn('unknown tab: cannot fetch the data');
    }

    return (): void => {
      tokenSource.cancel();
    };
  }, [dispatch, queryParameters, tab]);

  return (
    <div className="relative flex-1">
      <div className="flex items-end justify-between mt-4">
        <Filters config={filtersConfig} onChange={handleFiltersChange} />
      </div>
      <h4 className="md:text-sm text-right mt-2 mb-1 font-poppins lowercase text-gray">
        {indicatorsPage.total_count} indicators
      </h4>
      <Pagination totalPages={indicatorsPage.page_count}>
        <Table
          rows={indicatorsPage.data}
          columns={columns}
          sortBy={sorting}
          onSortChange={handleSortChange}
        />
      </Pagination>

      <SpinnerLoader visible={state.processingState.state === ProcessingStateEnum.processing} />
    </div>
  );
};
