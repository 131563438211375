import React from 'react';
import { Link } from 'react-router-dom';
import { AuthProcessingStateData } from 'redux/reducers/authReducer';
import { EmailSentMessage } from 'views/Landing/component/emailSentMessage';
import { MessageViewContent } from 'views/Landing/component/messageViewContent';

interface Props {
  readonly state?: AuthProcessingStateData;

  onClear?(): void;
  onAction?(): void;
}

export const MessageView: React.FC<Props> = ({
  state,
  onClear,
  onAction,
}: Props): React.ReactElement | null => {
  if (!state) {
    return null;
  }

  switch (state) {
    case AuthProcessingStateData.wrongPassword:
      return (
        <MessageViewContent message="Wrong password or email" variant="error" onClear={onClear} />
      );
    case AuthProcessingStateData.quotaFullOrInWaitingList:
      return (
        <MessageViewContent
          variant="information"
          message="We're full up"
          details={
            <div>
              Our Beta is currently oversubscribed, but we’ve got your name on our list of favorite
              people and we will send you a verification email once more space opens up 🙂
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.userNotVerified:
      return (
        <MessageViewContent
          variant="information"
          message="You haven't verified your account yet"
          details={
            <div>
              You must have received and email with a link which you can use to verify that you own
              the email address. If you haven&apos;t received the email{' '}
              <a role="button" className="normal-case text-blue" onClick={onAction}>
                click here
              </a>{' '}
              and we&apos;ll send it again.
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.tooManyWrongPasswordAttempts:
      return (
        <MessageViewContent
          variant="error"
          message="Your account has been locked as a result of 5 incorrect password attempts"
          details={
            <div>
              To unlock your account{' '}
              <a role="button" className="normal-case text-blue font-poppins" onClick={onAction}>
                click here
              </a>{' '}
              and we&apos;ll send you instructions.
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.accountUnlocked:
      return (
        <MessageViewContent
          variant="success"
          message="Your account has been unlocked"
          details={<div>Now you just have to log in with your email and password below.</div>}
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.emailVerified:
      return (
        <MessageViewContent
          variant="success"
          message="Your email has been verified"
          details={<div>Now you just have to log in with your email and password below.</div>}
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.passwordUpdated:
      return (
        <MessageViewContent
          variant="success"
          message="Your password was updated correctly"
          details={<div>Now you just have to log in with your email and password below.</div>}
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.registrationSucceeded:
      return (
        <MessageViewContent
          variant="success"
          message="Your registration was completed successfully"
          details={
            <div className="my-3">
              <div>
                We sent you a verification email, please check your inbox and click on the
                verification button.
              </div>
              <div className="mt-3">
                <EmailSentMessage onResend={onAction} />
              </div>
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.requestEmailVerification:
    case AuthProcessingStateData.requestPasswordReset:
    case AuthProcessingStateData.requestUnlockAccount:
      return (
        <MessageViewContent
          variant="success"
          message="An email has been sent to you"
          details={
            <div className="my-3">
              <EmailSentMessage onResend={onAction} />
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.requestPasswordResetError:
    case AuthProcessingStateData.requestEmailVerificationError:
    case AuthProcessingStateData.unlockAccountError:
      return (
        <MessageViewContent
          variant="error"
          message="We had trouble sending the email to this address."
          details={<div className="my-3">Please make sure the spelling is correct</div>}
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.unverifiedRegisteredUser:
      return (
        <MessageViewContent
          variant="warning"
          message="An account with this email has already been registered but not yet verified."
          details={
            <div className="my-3">
              <span>
                For immediate verification, please click the link in the email that was sent to you.
                Alternatively, please email us at
              </span>
              <a
                className="normal-case text-blue font-poppins"
                href="mailto:help@capnote.com"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                help@capnote.com{' '}
              </a>
              <span>and we will verify your email within 12 hours.</span>
              <div className="mt-3">
                <EmailSentMessage onResend={onAction} />
              </div>
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.conflict:
      return (
        <MessageViewContent
          variant="error"
          message="An account with this email has already been registered."
          details={
            <div className="my-3">
              <span>Please</span>{' '}
              <Link className="normal-case text-blue font-poppins" to="/login">
                click here
              </Link>{' '}
              to take you to the login page.
            </div>
          }
          onClear={onClear}
        />
      );
    case AuthProcessingStateData.userSuspended:
    case AuthProcessingStateData.userBlocked:
    default:
      return null;
  }
};
