import SpinnerLoader from 'components/spinnerLoader';
import React, { useCallback, useMemo } from 'react';
import {
  BillingInterval,
  Feature,
  SubscriptionPlan,
} from 'redux/reducers/subscriptionPlansReducer';
import { FeatureValue } from 'views/SubscriptionPlans/featureValue';
import { SubscriptionPlanItemHeader } from 'views/SubscriptionPlans/subscriptionPlanItemHeader';

interface Props {
  readonly subscription: SubscriptionPlan;
  readonly billingInterval: BillingInterval;
  readonly selected: boolean;
  readonly active: boolean;
  readonly features: readonly Feature[];

  readonly busy?: boolean;

  onSelected(id: string): void;
}

export const SubscriptionPlanItem: React.FC<Props> = ({
  active,
  subscription,
  billingInterval,
  selected,
  busy = false,
  features,

  onSelected,
}: Props): React.ReactElement => {
  const handleClick = useCallback((): void => {
    onSelected(subscription.id);
  }, [onSelected, subscription.id]);

  const containerClassName = useMemo((): string => {
    const base = 'relative w-64 rounded-md border-2 py-3 cursor-pointer transition-all';
    if (selected) {
      return [base, 'border-blue'].join(' ');
    } else {
      return [base, 'border-gray-light'].join(' ');
    }
  }, [selected]);

  return (
    <button className={containerClassName} onClick={handleClick}>
      <SubscriptionPlanItemHeader
        subscription={subscription}
        billingInterval={billingInterval}
        active={active}
        selected={selected}
      />
      {features.map(
        (feature: Feature): React.ReactElement => (
          <div key={feature.id} className={featureItemClassName}>
            <FeatureValue descriptor={subscription.features[feature.id]} />
          </div>
        ),
      )}
      <SpinnerLoader visible={busy} />
    </button>
  );
};

const featureItemClassName =
  'flex items-center justify-center h-8 leading-8 odd:bg-gray-light mx-4';
