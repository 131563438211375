import { ConditionalRender } from 'components/conditionalRenderer';
import UserHeader from 'components/header/UserHeader';
import Sidebar from 'components/sidebar';
import WelcomeModal from 'components/welcome/WelcomeModal';
import { Authorization, AuthorizationProvider } from 'context/authorization';
import { useModal } from 'hooks/useModal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { sessionSelector, userSelector } from 'redux/reducers/authReducer';
import { fetchSmartToolsRecentSearches } from 'redux/services/smartToolsRecentSearchesServices';

export const ApplicationLayout: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();

  const { id: userId, subscription } = useSelector(userSelector);
  const welcomeModal = useModal();
  const session = useSelector(sessionSelector);
  const [authorizationProvider, setAuthorizationProvider] = useState<Authorization>(
    emptyAuthorizationProvider,
  );

  useEffect((): void => {
    if (!subscription?.authorization) {
      return;
    }

    setAuthorizationProvider(new Authorization(subscription.authorization));
  }, [subscription?.authorization]);

  useEffect((): void => {
    if (userId === '') {
      return;
    }

    if (localStorage.getItem(userId)) {
      return;
    }

    welcomeModal.open();
  }, [session.session_count, userId, welcomeModal]);

  useEffect((): VoidFunction | void => {
    if (!process.env.REACT_APP_DATA_PORTAL) {
      return dispatch(fetchSmartToolsRecentSearches());
    }
  }, [dispatch]);

  return (
    <AuthorizationProvider value={authorizationProvider}>
      <div className="flex flex-col w-full h-full bg-gray-darkest">
        <div className="bg-white">
          <UserHeader />
        </div>

        <div className="relative flex-grow">
          {/* Navigation sidebar */}
          <nav className="absolute left-3 top-0 bottom-3 hidden md:block w-sidebar mx-1">
            <div className="sticky top-0 h-full bg-white rounded overflow-hidden">
              <div className="h-full p-5 overflow-y-auto scroller-squared">
                <Sidebar />
              </div>
            </div>
          </nav>

          {/* The actual application content */}
          <div
            className="absolute left-0 md:left-60 top-0 bottom-0 right-0 overflow-y-scroll"
            style={scrollSnapStyle}
          >
            <Outlet />
          </div>
        </div>
      </div>

      <ConditionalRender renderIf={!process.env.REACT_APP_DATA_PORTAL}>
        <WelcomeModal onClose={welcomeModal.close} isOpen={welcomeModal.isOpen} />
      </ConditionalRender>
    </AuthorizationProvider>
  );
};

const scrollSnapStyle = { scrollSnapType: 'y proximity' };
const emptyAuthorizationProvider = new Authorization();
