import React from 'react';

interface Props {
  readonly title: string;
  readonly subTitle?: string;
}

const SectionTitle: React.FC<Props> = ({ title, subTitle }): React.ReactElement => {
  return (
    <>
      <h2 className="text-gray-darkest text-center mb-3 mt-2 uppercase">{title}</h2>
      <p className="text-gray text-center">{subTitle}</p>
    </>
  );
};

export default SectionTitle;
