import { ConditionalRender } from 'components/conditionalRenderer';
import { BaseProps, TruncateType } from 'components/truncate/baseProps';
import { DefaultTextEllipsis } from 'components/truncate/defaultTextEllipsis';
import React, { CSSProperties, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createClassName } from 'utils/css/tools';

export interface TruncateTextProps extends BaseProps {
  readonly contentType: TruncateType.text;
  readonly ellipsis?: React.ReactElement;
  readonly lines?: number;
  readonly component: React.ComponentType | 'p' | 'div';
}

export const TruncateText: React.FC<React.PropsWithChildren<TruncateTextProps>> = ({
  children,
  ellipsis = <DefaultTextEllipsis />,
  lines = 1,
  component,
}: React.PropsWithChildren<TruncateTextProps>): React.ReactElement => {
  const [caliper, setCaliper] = useState<HTMLDivElement | null>(null);
  const [caliperStyle, setCaliperStyle] = useState<CSSProperties>({});
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | undefined>();
  const [measuring, setMeasuring] = useState<boolean>(false);
  const [visibleText, setVisibleText] = useState<string>('');

  const textContent = useMemo((): string => {
    if (typeof children === 'string') {
      return children;
    } else if (React.isValidElement(children)) {
      if (children.type !== 'p' && children.type !== 'span') {
        throw new Error(
          'only 1 <p /> or <span /> child allowed as children of <Truncate /> in text mode',
        );
      } else {
        const { children: text } = children.props;
        // Right here, `children' is certainly a string after the checks performed above we know that
        return text;
      }
    } else {
      throw new Error('unexpected children for <Truncate /> in text mode');
    }
  }, [children]);

  useLayoutEffect((): void => {
    if (container === null) {
      return;
    }

    const style = getComputedStyle(container);
    const computedHeight = lines * parseInt(style.lineHeight);

    // Set the MAX height that the component will allow, we do so by
    // measuring the first child element, and then multiplying by the
    // number of lines
    setHeight(computedHeight);
    // Update the measuring element style
    setCaliperStyle(createCaliperStyle(parseInt(style.width), computedHeight));
  }, [container, measuring, lines]);

  // If children change then, we need to measure again
  // TODO: use a resize observer to update truncated items
  //       dynamically if the size of the container changes
  useEffect((): void => {
    setVisibleText(textContent);
    setMeasuring(true);
  }, [textContent]);

  useLayoutEffect((): void => {
    if (!measuring || container === null || caliper === null) {
      return;
    }

    const localVisibleWords = textContent.split(' ');
    while (caliper.firstChild !== null && caliper.offsetHeight < caliper.scrollHeight) {
      localVisibleWords.pop();
      // As we go, remove the element before the ellipsis
      //
      // FIXME: we are not considering the case in which ONLY the
      //        ellipsis might be left, we should and then do something
      //        about it
      const textNode = document.createTextNode(localVisibleWords.join(' '));
      caliper.replaceChild(textNode, caliper.firstChild);
    }

    // WARNING: Yes!!! this will trigger another computation (because of the size computation in the effect above),
    //          but as of now I can't think of a more efficient way to do it
    setVisibleText(localVisibleWords.join(' '));
    setMeasuring(false);
  }, [container, children, height, textContent, caliper, measuring]);

  const Text = component;
  const shouldShowEllipsis = useMemo((): boolean => {
    if (visibleText.length === 0 || textContent.length === 0) {
      return false;
    }

    return visibleText.length !== textContent.length;
  }, [textContent.length, visibleText.length]);

  return (
    <div ref={setContainer} className={containerClassName} style={{ height: height }}>
      <Text>
        {visibleText}
        <ConditionalRender renderIf={shouldShowEllipsis}>{ellipsis}</ConditionalRender>
      </Text>

      {/* "Off-screen element to perform measurements */}
      <ConditionalRender renderIf={measuring}>
        <div ref={setCaliper} style={caliperStyle}>
          {textContent}
          {ellipsis}
        </div>
      </ConditionalRender>
    </div>
  );
};

const containerClassName = createClassName({
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  width: '100%',
});

const createCaliperStyle = (width: number, height: number): CSSProperties => ({
  position: 'absolute',
  whiteSpace: 'pre-wrap',
  alignItems: 'start',
  width: width,
  height: height,
  // Just make sure that it is more than 100%. With the parent having
  // overflow hidden, this means that it will be impossible to see this
  // element when measuring
  top: '101%',
});
