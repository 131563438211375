import { ReactComponent as DownArrow } from 'assets/icons/svg/down-arrow.svg';
import { ReactComponent as LowIcon } from 'assets/icons/svg/green-arrow-down-icon.svg';
import { ReactComponent as MediumIcon } from 'assets/icons/svg/medium-impact-icon.svg';
import { ReactComponent as HighIcon } from 'assets/icons/svg/red-arrow-up-icon.svg';
import { Select, SelectButtonProps, SelectItem } from 'components/DEPRECATED/select';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';

void HighIcon;
void LowIcon;
void MediumIcon;

export enum Level {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}

interface Props {
  readonly value: string | null;

  onChange(value: string): void;
}

export const LevelDropdown: React.FC<Props> = ({ value, onChange }: Props): React.ReactElement => {
  return <Select items={levelItems} value={value} buttonComponent={Button} onChange={onChange} />;
};

const Button: React.FC<SelectButtonProps<Level>> = React.forwardRef(function TimeSelectButton(
  { value, onClick }: SelectButtonProps<Level>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  // FIXME: why do we need the cast?
  const className = useMemo(
    (): string => (value ? levelClassName[value as Level] : fallbackLevelClass),
    [value],
  );
  const IconComponent = useMemo(
    (): React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null =>
      value ? icons[value as Level] : null,
    [value],
  );

  return (
    <div ref={ref} className={className} onClick={onClick}>
      <div className="flex items-center gap-2">
        {IconComponent && <IconComponent className="w-3" />}
        <span className="text-gray">{value === null ? 'Select' : capitalize(value)}</span>
      </div>
      <DownArrow className="w-3 fill-current" />
    </div>
  );
});

const levelItems = Object.values(Level).map(
  (level: Level): SelectItem<Level> => ({ value: level, label: capitalize(level) }),
);

const buttonClassName =
  'flex items-center justify-between w-full leading-8 px-3 rounded-md border text-sm cursor-pointer';

const levelClassName: { [level in Level]: string } = {
  [Level.low]: buttonClassName + ' border-green bg-green bg-opacity-20 text-green',
  [Level.medium]: buttonClassName + ' border-orange bg-orange bg-opacity-20 text-orange',
  [Level.high]: buttonClassName + ' border-red bg-red bg-opacity-20 text-red',
};

const icons: { [level in Level]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  [Level.low]: LowIcon,
  [Level.medium]: MediumIcon,
  [Level.high]: HighIcon,
};

const fallbackLevelClass = buttonClassName + ' border-blue bg-blue-light text-blue';
