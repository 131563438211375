import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetState } from 'redux/reducers/onboardingReducer';
import { loadOnboardingReferenceData } from 'redux/services/onboardingServices';
import AboutForm from 'views/Landing/Onboarding/AboutForm';
import { SimpleHeader } from 'views/Landing/Onboarding/components/SimpleHeader';

const Onboarding: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  useEffect((): void => {
    dispatch(resetState());
    dispatch(loadOnboardingReferenceData());
  }, [dispatch]);

  return (
    <div className="flex flex-col w-full h-full overflow-y-scroll">
      <SimpleHeader />

      <div className="flex flex-col flex-1 justify-center items-stretch p-4">
        <div className="relative text-center mr-auto ml-auto w-full sm:w-form">
          <AboutForm />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
