import DetailsModal from 'components/detailsModal';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  companyDetailsSelector,
  loadingSummarySelector,
  summarySelector,
} from 'redux/reducers/companyReducer';
import { getSummaryService } from 'redux/services/companyService';
import { getLegendForFinancialStatements } from 'utils';
import SummaryTable from 'views/DataAnalysis/common/SummaryTable';
import { getLargestSummaryNumber } from 'views/DataAnalysis/common/SummaryTable/helpers';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';

export const Overview: React.FC = (): React.ReactElement => {
  const { companyId } = useParams<{ companyId: string }>();

  const loadingOverview = useSelector(loadingSummarySelector);
  const summary = useSelector(summarySelector);
  const company = useSelector(companyDetailsSelector);
  const dispatch = useDispatch<any>();
  const details = useModal();

  useEffect(() => {
    if (!companyId) {
      return;
    }

    dispatch(getSummaryService(companyId));
  }, [dispatch, companyId]);

  const largestNumber = useMemo((): number => {
    return getLargestSummaryNumber(summary);
  }, [summary]);

  const handleValueExpand = useCallback(
    (name: string, _: any): void => {
      if (name === 'Description') {
        details.open();
      }
    },
    [details],
  );

  return (
    <div className="relative flex-1">
      <div className="flex items-start justify-between mt-3">
        <h4 className="text-xs md:text-sm text-gray-darkest font-poppins normal-case">
          {getLegendForFinancialStatements(largestNumber, company.currency)}
        </h4>
      </div>
      <SummaryTable
        data={summary}
        largestNumber={largestNumber}
        onValueExpand={handleValueExpand}
      />
      <SpinnerLoader visible={loadingOverview} />
      <DataDisclaimer />

      <Modal isOpen={details.isOpen} onClose={details.close}>
        <Modal.Content title={company?.name}>
          <DetailsModal description={company?.description} tags={company?.tags} />
        </Modal.Content>
      </Modal>
    </div>
  );
};
