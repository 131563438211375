import { QueryStatus } from '@reduxjs/toolkit/query';
import { Input } from 'components/forms/input';
import { TagsInput } from 'components/forms/tagsInput';
import { TextArea } from 'components/forms/textarea';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Modal } from 'components/modal';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { WithOptionalID } from 'types/withId';
import { useLazyGetSpecialOfferQuery } from 'views/EarnAndBuy/api';
import { CategoriesDropdown } from 'views/EarnAndBuy/Earn/components/categoriesDropdown';
import { TierRequirementDropdown } from 'views/EarnAndBuy/Earn/components/tierRequirementDropdown';
import {
  Action,
  ActionType,
  CreateSpecialOfferPayload,
  initialState,
  reducer,
} from 'views/EarnAndBuy/SpecialOffers/modals/addSpecialOfferReducer';

interface Props {
  readonly open: boolean;
  readonly specialOfferId?: string;
  readonly busy: boolean;

  onSubmit(data: WithOptionalID<CreateSpecialOfferPayload>): Promise<void>;
  onClose(): void;
}

export const SpecialOfferFormModal: React.FC<Props> = ({
  open,
  busy,
  specialOfferId,
  onSubmit,
  onClose,
}: Props): React.ReactElement => {
  const [state, dispatch] = useReducer<Reducer<CreateSpecialOfferPayload, Action>>(
    reducer,
    initialState,
  );

  const [fetchSpecialOffer, fetchStatus] = useLazyGetSpecialOfferQuery();

  useEffect((): void | VoidFunction => {
    if (specialOfferId) {
      fetchSpecialOffer(specialOfferId)
        .then((result /* I hate that they don't care about types */): void => {
          const { data } = result;
          if (data) {
            dispatch({ type: ActionType.initialize, data: data });
          }
        })
        .catch(console.warn);
    }

    return (): void => {
      dispatch({ type: ActionType.reset });
    };
  }, [fetchSpecialOffer, specialOfferId]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    await onSubmit({ id: specialOfferId, ...state });
  }, [onSubmit, specialOfferId, state]);

  const setValue = useCallback(function build(
    name: keyof CreateSpecialOfferPayload,
  ): (_: CreateSpecialOfferPayload[keyof CreateSpecialOfferPayload]) => void {
    return function (value: CreateSpecialOfferPayload[keyof CreateSpecialOfferPayload]): void {
      dispatch({
        type: ActionType.setValue,
        data: {
          name: name,
          value: value,
        },
      });
    };
  },
  []);

  const modalTitle = useMemo(
    (): string =>
      specialOfferId === undefined ? 'Add a new special offer' : 'Update special offer',
    [specialOfferId],
  );

  const handleClose = React.useCallback((): void => {
    dispatch({ type: ActionType.reset });
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <Modal.Content title={modalTitle}>
        <form autoComplete="off" className="flex flex-col gap-4 w-modal-sm mt-3" onSubmit={ignore}>
          <div className="flex items-center gap-2">
            <div className="w-2/3">
              <Input name="title" label="Title" value={state.title} onChange={setValue('title')} />
            </div>
            <div className="w-1/3">
              <CategoriesDropdown value={state.category} onChange={setValue('category')} />
            </div>
          </div>
          <TextArea
            name="description"
            label="Description"
            value={state.description}
            onChange={setValue('description')}
          />
          <TagsInput value={state.tags} name="tags" onChange={setValue('tags')} />
          <Input name="url" label="URL" value={state.url} onChange={setValue('url')} />

          <div className="w-full">
            <div className="w-1/2">
              <TierRequirementDropdown
                value={state.minimum_required_plan_id}
                onChange={setValue('minimum_required_plan_id')}
              />
            </div>
          </div>

          <div className="flex items-center justify-end">
            <button
              className="bg-blue text-white h-12 rounded font-poppinsMedium text-md w-20"
              onClick={handleSubmit}
            >
              {specialOfferId ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </Modal.Content>
      <FullScreenLoadingSpinner visible={busy || fetchStatus.status === QueryStatus.pending} />
    </Modal>
  );
};

const ignore = (event: React.FormEvent<HTMLFormElement>): void => {
  event.preventDefault();
};
