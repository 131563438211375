import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  readonly anchorToNext: 'tr';
}

export const PleaseUpgradeTableItem: React.FC<Props> = ({
  anchorToNext,
}: Props): React.ReactElement => {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const elementRef = useRef<HTMLDivElement>(null);

  const findParent = useCallback((element: HTMLElement, tagName: string): HTMLElement | null => {
    if (caseInsensitiveEqual(element.tagName, tagName)) {
      return element;
    }

    if (element.parentElement === null) {
      return null;
    }

    return findParent(element.parentElement, tagName);
  }, []);

  useLayoutEffect((): void => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }

    const matchingParent = findParent(element, anchorToNext);
    if (matchingParent === null) {
      return;
    }

    if (matchingParent.classList.contains('cursor-pointer')) {
      matchingParent.classList.remove('cursor-pointer');
      matchingParent.classList.add('cursor-default');
    }

    const boundingBox = {
      left: matchingParent.offsetLeft,
      top: matchingParent.offsetTop,
      width: matchingParent.offsetWidth,
      height: matchingParent.offsetHeight,
    };

    setStyle({
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: `${boundingBox.left + 8}px`,
      top: `${boundingBox.top + 8}px`,
      width: `${boundingBox.width - 16}px`,
      height: `${boundingBox.height - 16}px`,
      backgroundColor: 'rgba(255, 255, 255, 0.35)',
      backdropFilter: 'blur(3px)',
      border: '1px dashed rgba(0, 0, 0, 0.15)',
    });
  }, [anchorToNext, findParent]);

  return (
    <div ref={elementRef} style={style}>
      <span>Please&nbsp;</span>
      <Link to="/upgrade" className="text-blue">
        upgrade
      </Link>
      <span>&nbsp;to see this&hellip;</span>
    </div>
  );
};

const caseInsensitiveEqual = (s1: string, s2: string): boolean =>
  s1.toUpperCase() === s2.toUpperCase();
