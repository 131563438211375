import { Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import {
  feedItemDisliked,
  feedItemLiked,
  feedItemSaved,
  feedItemUnsaved,
} from 'redux/reducers/feedItemDetailReducer';
import {
  CreatePostPayload,
  deletingPostCompleted,
  deletingPostStarted,
  deletingPostSucceeded,
  dislikeNewsSuccess,
  dislikePostSuccess,
  FollowResponse,
  getFeed,
  getFeedFailed,
  getFeedSuccess,
  likeNewsSuccess,
  likePostSuccess,
  postingFailed,
  postingStarted,
  postingSuccess,
  saveNewsSuccess,
  savePostSuccess,
  sharingFailed,
  sharingStarted,
  sharingSucceeded,
  undoSaveNewsSuccess,
  undoSavePostSuccess,
} from 'redux/reducers/feedReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ThunkLikeAction } from 'types/APIAction';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusConflict, HttpStatusOk } from 'utils/statusCodes';
import { FeedFilterCategory, GenericFeedItem } from 'views/Home/types';

export const getFeedService = (
  page_number: number,
  filter_keyword: string,
  category: string,
): ThunkLikeAction => {
  return async (dispatch: Dispatch): Promise<void> => {
    const filterKeyword = filter_keyword || null;
    const params = {
      page_number,
      page_size: 20,
      bookmarked: category === FeedFilterCategory.bookmarks || null,
      filter_category:
        category === FeedFilterCategory.none || category === FeedFilterCategory.bookmarks
          ? null
          : category,
      filter_keyword: filterKeyword,
    };

    try {
      dispatch(getFeed());

      const response = await api.get(`${API_V1_PATH}/feed`, { params });
      if (response.data) {
        dispatch(getFeedSuccess(response.data));
      }
    } catch (err: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: err?.response?.data.Message || errorMessageDefault,
        }),
      );
      dispatch(getFeedFailed());
    }
  };
};

export const deletePostService =
  (postId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(deletingPostStarted(postId));
    try {
      const response = await api.delete(`${API_V1_PATH}/posts/${postId}`);
      if (response.status === HttpStatusOk) {
        dispatch(deletingPostSucceeded(postId));
        dispatch(
          showInfoModal({
            type: 'success',
            message: 'Post deleted successfully',
          }),
        );
      }
    } catch (error: any) {
      const { response } = error;

      if (response.status === HttpStatusConflict) {
        dispatch(
          showInfoModal({
            type: 'error',
            message:
              'It seems this post has comments. Deleting it means deleting comments that might belong to other users. We are sorry.',
          }),
        );
      } else {
        dispatch(
          showInfoModal({
            type: 'error',
            message: error?.response?.data.Message || errorMessageDefault,
          }),
        );
      }
    } finally {
      dispatch(deletingPostCompleted());
    }
  };

export const postService =
  (post: CreatePostPayload): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(postingStarted());
    try {
      const response: AxiosResponse = await api.post(`${API_V1_PATH}/posts/`, post);
      dispatch(postingSuccess(GenericFeedItem.fromJson(response.data)));
    } catch (error: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: error?.response?.data.Message || errorMessageDefault,
        }),
      );
      dispatch(postingFailed());
    }
  };

export const likePostService = (postId: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/posts/${postId}/like`)
      .then(res => {
        if (res.status === HttpStatusOk) {
          dispatch(likePostSuccess(res.data));
          dispatch(feedItemLiked(res.data));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const dislikePostService = (postId: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/posts/${postId}/dislike`)
      .then(res => {
        if (res.status === HttpStatusOk) {
          dispatch(dislikePostSuccess(res.data));
          dispatch(feedItemDisliked(res.data));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const likeNewsService = (newsId: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/news/${newsId}/like`)
      .then(res => {
        if (res.status === HttpStatusOk) {
          dispatch(likeNewsSuccess(res.data));
          dispatch(feedItemLiked(res.data));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const dislikeNewsService = (newsId: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/news/${newsId}/dislike`)
      .then(res => {
        if (res.status === HttpStatusOk) {
          dispatch(dislikeNewsSuccess(res.data));
          dispatch(feedItemDisliked(res.data));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const savePostService = (postId: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/posts/${postId}/save`)
      .then(res => {
        if (res.data) {
          dispatch(savePostSuccess({ post_id: postId } as FollowResponse));
          dispatch(feedItemSaved(postId));
        } else throw new Error();
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const saveNewsService = (newsId: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/news/${newsId}/save`)
      .then(res => {
        if (res.status === HttpStatusOk) {
          dispatch(saveNewsSuccess({ news_id: newsId } as FollowResponse));
          dispatch(feedItemSaved(newsId));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const undoSavePostService = (postId: string, category?: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/posts/${postId}/undo-save`)
      .then(res => {
        if (res.data) {
          dispatch(
            undoSavePostSuccess({
              post_id: postId,
              category,
            } as FollowResponse),
          );
          dispatch(feedItemUnsaved(postId));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const undoSaveNewsService = (newsId: string, category?: string) => {
  return (dispatch: Dispatch) => {
    api
      .post(`${API_V1_PATH}/news/${newsId}/undo-save`)
      .then(res => {
        if (res.data) {
          dispatch(
            undoSaveNewsSuccess({
              news_id: newsId,
              category,
            } as FollowResponse),
          );
          dispatch(feedItemUnsaved(newsId));
        }
      })
      .catch(err => {
        dispatch(
          showInfoModal({
            type: 'error',
            message: err?.response?.data.Message || errorMessageDefault,
          }),
        );
        dispatch(getFeedFailed());
      });
  };
};

export const sharePost =
  (postId: string, emails: readonly string[]) => async (dispatch: Dispatch) => {
    dispatch(sharingStarted());
    try {
      await api.post(`${API_V1_PATH}/posts/${postId}/share`, { emails });
      dispatch(sharingSucceeded());
    } catch {
      dispatch(sharingFailed());
    }
  };

export const shareNews =
  (newsId: string, emails: readonly string[]) => async (dispatch: Dispatch) => {
    dispatch(sharingStarted());
    try {
      await api.post(`${API_V1_PATH}/news/${newsId}/share`, { emails });
      dispatch(sharingSucceeded());
    } catch {
      dispatch(sharingFailed());
    }
  };
