import FinvarIconFinal from 'assets/images/Finvar-icon-final.png';
import { UpgradeButton } from 'components/upgradeButton';
import React from 'react';
interface Props {
  readonly title: string;
  readonly description: string;
}

const ExceedAccess: React.FC<Props> = ({ title, description }: Props): React.ReactElement => {
  return (
    <div>
      <div className="flex items-start gap-4 w-full md:w-modal-sm">
        <img src={FinvarIconFinal} className="w-16 h-16 rounded-lg" alt="Finver Logo" />
        <div>
          <h2>{title}</h2>
          <p className="mb-8">{description}</p>
        </div>
      </div>
      <div>
        <div className="w-max ml-auto">
          <UpgradeButton />
        </div>
      </div>
    </div>
  );
};

export default ExceedAccess;
