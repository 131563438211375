import { CommonFilter } from 'components/filters/helpers';

const filters: readonly CommonFilter[] = [
  {
    name: 'amount',
    label: 'Amount',
    type: 'backend',
    url: '/api/v1/filters/library/amount',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'backend',
    url: '/api/v1/filters/library/status',
  },
];

export default filters;
