import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import { defaultLocale } from 'constants/defaultLocale';
import React from 'react';
import { EarnItem, ListingStatus } from 'views/EarnAndBuy/api';
import { Status } from 'views/EarnAndBuy/common/status';
import { Actions } from 'views/EarnAndBuy/Earn/components/actions';

const columns: ReadonlyArray<ColumnDefinition<EarnItem>> = [
  {
    type: ColumnType.date,
    label: 'Date Posted',
    name: 'posted_at',
    alignment: Alignment.center,
    sortable: true,
  },
  {
    type: ColumnType.text,
    label: 'Title',
    name: 'title',
    alignment: Alignment.left,
    weight: 2,
  },
  {
    type: ColumnType.custom,
    label: 'Payment Amount',
    name: 'payment_amount',
    sortable: true,
    render: (value: number, item: EarnItem): React.ReactNode => {
      const formatter = Intl.NumberFormat(defaultLocale, {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: item.payment_currency,
      });

      return <p className="text-center">{formatter.format(value)}</p>;
    },
  },
  {
    type: ColumnType.custom,
    label: 'Status',
    name: 'status',
    sortable: true,
    headerAlignment: Alignment.center,
    render: (value: ListingStatus, job: EarnItem): React.ReactNode => {
      if (job.applied) {
        return (
          <div className="flex items-center justify-center">
            <Status status={ListingStatus.applied} variant="flat" />
          </div>
        );
      }

      return (
        <div className="flex items-center justify-center">
          <Status status={value} variant="flat" />
        </div>
      );
    },
  },
  {
    type: ColumnType.custom,
    label: '',
    name: 'id',
    render: (_: string, item: EarnItem): React.ReactNode => {
      return <Actions item={item} />;
    },
    width: 300,
  },
];

export default columns;
