// GENERAL COMPONENTS
import { ConditionalRender } from 'components/conditionalRenderer';
import DetailsModal from 'components/detailsModal';
import { MainOutletContent } from 'components/mainOutletContent';
import { Modal } from 'components/modal';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { noop } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { industryDetailsSelector } from 'redux/reducers/industryReducer';
import { getIndustryDetailsService } from 'redux/services/industryServices';
import { OverviewHeader } from 'views/DataAnalysis/common/overviewHeader';
import { FinancialMetrics } from 'views/DataAnalysis/Industry/Details/Financials';
import KeyFactors from 'views/DataAnalysis/Industry/Details/KeyFactors';
import Overview from 'views/DataAnalysis/Industry/Details/Overview';
import { PeerComparisons } from 'views/DataAnalysis/Industry/Details/PeerComparisons';
import { Timeline } from 'views/DataAnalysis/Industry/Details/Timeline';
import { Tools } from 'views/DataAnalysis/Industry/Details/tools';
import ValueChain from 'views/DataAnalysis/Industry/Details/ValueChain';

const IndustryDetails: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { industryId } = useParams<{ industryId: string }>();
  const industryDetails = useSelector(industryDetailsSelector);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    mixpanel.track('PageView D&A Industry Details');
    if (!industryId) {
      return;
    }

    dispatch(getIndustryDetailsService(industryId));
  }, [dispatch, industryId]);

  const closeDetailsModal = useCallback((): void => {
    setShowDetails(false);
  }, []);

  return (
    <MainOutletContent>
      <div className="flex items-start gap-2 mb-2">
        <OverviewHeader
          name={industryDetails?.name}
          description={industryDetails?.description}
          tags={industryDetails?.tags}
          category="industry"
          onShowDetails={noop}
        />
        <Tools followed={!!industryDetails?.followed} />
      </div>

      <ConditionalRender renderIf={window.innerWidth > 1024}>
        <Tabs variant={TabsVariant.buttons}>
          <Tab label="Overview" element={<Overview />} path="overview/*" />
          <Tab label="Value Chain" element={<ValueChain />} path="value-chain" />
          <Tab label="Financials" element={<FinancialMetrics />} path="financial-metrics" />
          <Tab label="Risk & Growth Factors" element={<KeyFactors />} path="key-factors/*" />
          <Tab label="Peer Comps" element={<PeerComparisons />} path="peer-comparisons" />
          <Tab label="Timeline & Events" element={<Timeline />} path="timeline" />
          <Route element={<Timeline />} path="timeline/:eventId" />
        </Tabs>
      </ConditionalRender>

      <ConditionalRender renderIf={window.innerWidth <= 1024}>
        <Routes>
          <Route element={<Overview />} path="overview/*" />
          <Route element={<Navigate to="overview" />} path="*" />
        </Routes>
      </ConditionalRender>

      <Modal isOpen={showDetails} onClose={closeDetailsModal}>
        <Modal.Content title={industryDetails?.name}>
          <DetailsModal description={industryDetails?.description} tags={industryDetails?.tags} />
        </Modal.Content>
      </Modal>
    </MainOutletContent>
  );
};

export default IndustryDetails;
