import { WithID, WithOptionalID } from 'types/withId';
import { EarnItemDetails } from 'views/EarnAndBuy/api';

export enum ActionType {
  setValue,
  initialize,
  reset,
}

export interface CreateListingPayload {
  readonly title: string;
  readonly description: string;
  readonly tags: readonly string[];
  readonly completion_instructions: string;
  readonly how_to_apply: string;
  readonly payment_amount: number | null;
  readonly payment_currency: string;
  readonly payment_unit?: number;
  readonly minimum_required_plan_id: string;
  readonly category: number | null;
}

export type UpdateListingPayload = WithID<CreateListingPayload>;

export const isUpdateListingPayload = (
  payload: WithOptionalID<CreateListingPayload>,
): payload is UpdateListingPayload => {
  return 'id' in payload && typeof payload.id === 'string';
};

interface ActionBase {
  readonly type: ActionType;
  readonly data?: any;
}

export interface SetValueAction extends ActionBase {
  readonly type: ActionType.setValue;
  readonly data: {
    readonly name: keyof CreateListingPayload;
    readonly value: CreateListingPayload[keyof CreateListingPayload];
  };
}

export interface ResetAction extends ActionBase {
  readonly type: ActionType.reset;
}

export interface InitializeAction extends ActionBase {
  readonly type: ActionType.initialize;
  readonly data: EarnItemDetails;
}

export type Action = SetValueAction | ResetAction | InitializeAction;

export const initialState: CreateListingPayload = {
  title: '',
  description: '',
  tags: [],
  completion_instructions: '',
  how_to_apply: '',
  payment_amount: null,
  payment_currency: 'USD',
  minimum_required_plan_id: '',
  category: null,
};

export const reducer = (state: CreateListingPayload, action: Action): CreateListingPayload => {
  const { type, data } = action;
  switch (type) {
    case ActionType.setValue:
      return { ...state, [data.name]: data.value };
    case ActionType.reset:
      return initialState;
    case ActionType.initialize:
      return {
        title: data.title,
        description: data.description,
        tags: data.tags,
        completion_instructions: data.completion_instructions,
        how_to_apply: data.how_to_apply,
        payment_amount: data.payment_amount,
        payment_currency: data.payment_currency,
        payment_unit: data.payment_unit.id,
        minimum_required_plan_id: data.minimum_required_plan_id,
        category: data.category.id,
      };
    default:
      return state;
  }
};
