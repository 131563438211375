import { PeerComparisonsView } from 'components/peerComparisonsView';
import React from 'react';
import { useSelector } from 'react-redux';
import { fetchUserPeerGroupProcessingState } from 'redux/reducers/userPeerGroupsReducer';
import { PeerComparisons } from 'types/peerComparisons';
import { ProcessingStateEnum } from 'types/processingState';

interface Props {
  readonly comparisons: PeerComparisons | undefined;
}

export const PeerGroupsComparisons: React.FC<Props> = ({
  comparisons,
}: Props): React.ReactElement | null => {
  const processingState = useSelector(fetchUserPeerGroupProcessingState);

  if (!comparisons) {
    return null;
  }

  return (
    <div className="flex flex-col flex-1 mt-4">
      <PeerComparisonsView
        comparisons={comparisons}
        loading={processingState.state === ProcessingStateEnum.processing}
        filters={[]}
        summary={comparisons.summary}
        type="company"
        largestValueInDataset={0}
      />
    </div>
  );
};
