import TagWithDelete from 'components/DEPRECATED/tags/TagWithDelete';
import Input from 'components/input/Input2';
import React, { useCallback, useState } from 'react';

interface Props {
  readonly id: string;
  readonly items: string[];
  readonly name: string;
  readonly placeholder: string;

  onChange(value: string[]): void;
}

export const TagInput: React.FC<Props> = ({
  id,
  items,
  name,
  placeholder,
  onChange,
}: Props): React.ReactElement => {
  const [inputValue, setInputValue] = useState('');

  const onChangeValue = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setInputValue(value);
  }, []);

  const createRemoveHandler = useCallback(
    (value: string): (() => void) =>
      (): void => {
        onChange(items.filter((item: string): boolean => value !== item));
      },
    [onChange, items],
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        const list = inputValue.split(',');
        const distinct: string[] = Array.from(
          new Set(
            [...items, ...list]
              .map((value: string): string => value.trim())
              .filter((value: string): boolean => value !== '')
              .filter(
                (value: string, index: number): boolean =>
                  index !==
                  list
                    .slice(index + 1)
                    .findIndex(
                      (v: string): boolean => v.toLocaleLowerCase() === value.toLocaleLowerCase(),
                    ),
              ),
          ),
        );
        onChange(distinct);
        setInputValue('');
      }
    },
    [inputValue, items, onChange],
  );

  return (
    <>
      <Input
        id={id}
        name={name}
        value={inputValue}
        onChange={onChangeValue}
        onKeyDown={handleOnKeyDown}
        className="py-1.5 px-3"
        inputClassName="bg-transparent"
        placeholder={placeholder}
      />

      <div className="flex w-full my-5 flex-wrap">
        {items.map((certification, index) => (
          <TagWithDelete
            key={certification + index}
            id={`portfolio-tag-${index}`}
            className="mr-3.5 mb-1"
            onClickDelete={createRemoveHandler(certification)}
          >
            <span>{certification}</span>
          </TagWithDelete>
        ))}
      </div>
    </>
  );
};
