import { AddObjectButton } from 'components/addObjectButton';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback } from 'react';
import { TimelineFilters } from 'types/timeline';
import { TimelineDateFilterBar } from 'views/SmartTools/TimelineAndEvents/toolbars/timelineDateFilterBar';

interface Props {
  readonly filters: TimelineFilters;

  onAddClick(): void;
  onFilterChange(filters: TimelineFilters): void;
}

export const AddStreamAndDateFilterBar: React.FC<Props> = ({
  filters,
  onFilterChange,
  onAddClick,
}: Props): React.ReactElement => {
  const { trackEvent } = useGtag();

  const handleAddClick = useCallback(() => {
    trackEvent('start-add-stream', {
      section: 'timeline',
    });
    onAddClick();
  }, [onAddClick, trackEvent]);

  return (
    <div className="flex items-center gap-3 my-3">
      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <AddObjectButton title="Timeline" onClick={handleAddClick} />
      )}
      <div className="flex-1">
        <TimelineDateFilterBar value={filters} onChange={onFilterChange} />
      </div>
    </div>
  );
};
