import { Disabler } from 'components/disabler';
import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import { Filters } from 'components/filters';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { ChildrenRows, ColumnDefinition, ColumnType } from 'components/table/types';
import { useSort } from 'hooks/useSort';
import { noop } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Page } from 'types/page';
import { ProcessingState, ProcessingStateEnum } from 'types/processingState';
import {
  createSensitivitiesColumns,
  Sensitivity,
  SensitivityCategory,
  SensitivityCompany,
  SensitivityGroup,
} from 'types/sensitivities';
import api from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';

interface Props {
  readonly page: Page<Sensitivity>;
  readonly processingState: ProcessingState<Sensitivity>;
  readonly category: SensitivityCategory;
  readonly group: SensitivityGroup;
  readonly numberOfCompanies: number;
}

export const Sensitivities: React.FC<Props> = ({
  category,
  group,
  page,
  numberOfCompanies,
  processingState,
}: Props): React.ReactElement => {
  const [sortBy, handleSortByChange] = useSort<Sensitivity>('percentage', SortOrder.descending);
  const [expandedRows, setExpandedRows] = React.useState<
    Record<string, ChildrenRows<SensitivityCompany>>
  >({});
  const { portfolioId } = useParams<{ portfolioId: string }>();

  const navigate = useNavigate();
  const handleRowExpanded = useCallback(
    async (row: Sensitivity, expanded: boolean): Promise<void> => {
      if (expanded) {
        const companies = await fetchCompanies(category, group, row.id, portfolioId);
        setExpandedRows(
          (
            rows: Record<string, ChildrenRows<SensitivityCompany>>,
          ): Record<string, ChildrenRows<SensitivityCompany>> => {
            return {
              ...rows,

              [row.id]: {
                rows: companies,
                columns: companiesColumns,
              },
            };
          },
        );
      } else {
        setExpandedRows(
          (
            rows: Record<string, ChildrenRows<SensitivityCompany>>,
          ): Record<string, ChildrenRows<SensitivityCompany>> => {
            const { [row.id]: _, ...remainingRows } = rows;
            return remainingRows;
          },
        );
      }
    },
    [category, portfolioId, group],
  );

  const columns = useMemo(
    (): ReadonlyArray<ColumnDefinition<Sensitivity>> =>
      createSensitivitiesColumns(category, group, handleRowExpanded),
    [category, handleRowExpanded, group],
  );

  const handleRowClick = useCallback(
    (row: Sensitivity): void => {
      if (category === SensitivityCategory.industries) {
        navigate(`/data-analysis/industry/details/${row.id}/overview`);
      } else {
        // Do nothing
        return;
      }
    },
    [category, navigate],
  );

  const handleFilterChange = useCallback(
    (id: SensitivityCategory): void => {
      navigate(`../${id}`);
    },
    [navigate],
  );

  return (
    <>
      <div className="flex items-center justify-between my-4">
        <Disabler disabled={true}>
          <Filters config={[]} onChange={noop} />
        </Disabler>
        <DropdownFilter
          selectedId={category}
          options={filterOptions}
          onSelect={handleFilterChange}
        />
      </div>
      <div className="relative flex-1">
        <h4 className="md:text-sm text-right mt-2 mb-1 font-poppins lowercase text-gray">
          {numberOfCompanies} companies
        </h4>
        <Pagination totalPages={page.page_count}>
          <Table
            columns={columns}
            rows={page.data}
            sortBy={sortBy}
            rowChildren={expandedRows}
            onRowClick={handleRowClick}
            onSortChange={handleSortByChange}
          />
        </Pagination>
        <SpinnerLoader visible={processingState.state === ProcessingStateEnum.processing} />
      </div>
    </>
  );
};

const filterOptions: Array<DropdownItem<SensitivityCategory>> = [
  {
    id: SensitivityCategory.tags,
    label: 'Tags',
  },
  {
    id: SensitivityCategory.industries,
    label: 'Industries',
  },
  {
    id: SensitivityCategory.indicators,
    label: 'Indicators',
  },
  {
    id: SensitivityCategory.suppliers,
    label: 'Suppliers',
  },
  {
    id: SensitivityCategory.customers,
    label: 'Customers',
  },
  {
    id: SensitivityCategory.investors,
    label: 'Investors',
  },
];

const companiesColumns: ReadonlyArray<ColumnDefinition<SensitivityCompany>> = [
  {
    type: ColumnType.text,
    name: 'name',
    label: '',
  },
  {
    type: ColumnType.custom,
    name: 'ticker',
    label: '',
    render: (_: string, row: SensitivityCompany): React.ReactElement => (
      <div className="text-center w-40">
        <span className="text-green">{row.ticker}</span> : {row.exchange}
      </div>
    ),
    weight: 0.5,
  },
  {
    type: ColumnType.text,
    name: 'description',
    label: '',
    span: 2,
  },
];

const fetchCompanies = async (
  category: SensitivityCategory,
  group: SensitivityGroup,
  entityId: string,
  groupId: string | undefined,
): Promise<readonly SensitivityCompany[]> => {
  const buildURL = (): string => {
    if (group === 'portfolio') {
      return `/api/v1/sensitivities/${group}/${groupId}/${category}/${entityId}/companies`;
    } else {
      return `/api/v1/sensitivities/${group}/${category}/${entityId}/companies`;
    }
  };

  const response = await api.get(buildURL());
  if (response.status === HttpStatusOk) {
    return response.data;
  }

  return [];
};
