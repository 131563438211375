import { OneClickDownloadButton } from 'components/1clickDownloadButton';
import { ComingSoon } from 'components/tooltip/comingSoon';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import FollowButton from 'views/Connections/Overview/components/FollowButton';

interface Props {
  readonly followed: boolean;
}

export const Tools: React.FC<Props> = ({
  followed: wasFollowedOriginally,
}: Props): React.ReactElement => {
  const [followed, setFollowed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { industryId } = useParams<{ readonly industryId: string }>();

  useEffect((): void => {
    setFollowed(wasFollowedOriginally);
  }, [wasFollowedOriginally]);

  const followIndustry = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      if (followed) {
        await api.post(`${API_V1_PATH}/industries/${industryId}/unfollow`);
        setFollowed(false);
      } else {
        await api.post(`${API_V1_PATH}/industries/${industryId}/follow`);
        setFollowed(true);
      }
    } finally {
      setLoading(false);
    }
  }, [followed, industryId]);

  return (
    <div className="flex flex-col justify-between gap-2">
      <div className="flex gap-2">
        <ComingSoon>
          <OneClickDownloadButton
            loading={false}
            progress={0}
            tooltip="Download a detailed industry summary that may be helpful when writing risk or investment memos."
            onClick={noop}
          />
        </ComingSoon>

        <FollowButton
          followed={followed}
          compact={true}
          loading={loading}
          onClick={followIndustry}
        />
      </div>
    </div>
  );
};
