import { CommonMarker } from 'components/capnoteMap/components/commonMarker';
import { DynamicColor } from 'components/capnoteMap/components/markerDot';
import { MarkerType } from 'components/capnoteMap/types';
import styles from 'components/capnoteMap/worldMap.module.scss';
import React from 'react';
import { Map, TileLayer } from 'react-leaflet';

interface Props {
  readonly markers: readonly MarkerType[];

  readonly colors?: Record<string, DynamicColor>;
  readonly active?: string | null;

  onMarkerClick(marker: MarkerType | null): void;
}

export const CapnoteMap: React.FC<Props> = ({
  markers,
  colors,
  active,
  onMarkerClick,
}: Props): React.ReactElement => {
  return (
    <Map className={styles.map} {...mapProperties}>
      <TileLayer url={tilesUrl} attribution={tilesAttribution} />
      {markers.map((marker: MarkerType): React.ReactElement => {
        return (
          <CommonMarker
            key={marker.id}
            marker={marker}
            colors={colors}
            active={active}
            onClick={onMarkerClick}
          />
        );
      })}
    </Map>
  );
};

const tilesAttribution =
  '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors';
const tilesUrl = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';
const position = { lat: 17, lng: -57 };
const mapStyle = { flex: 1, margin: 0 };
const mapProperties = {
  center: position,
  zoom: 3,
  style: mapStyle,
  maxZoom: 20,
  attributionControl: false,
  scrollWheelZoom: false,
};
