import { AnyAction, configureStore, Middleware } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import addPortfolioItemFormReducer, {
  AddPortfolioItemState,
} from 'redux/reducers/addPortfolioItemFormReducer';
import algoNotesReducer, { AlgoNotesState } from 'redux/reducers/algoNotesReducer';
import authReducer, { AuthState } from 'redux/reducers/authReducer';
import companyReducer, { CompanyState } from 'redux/reducers/companyReducer';
import connectionsReducer, { ConnectionsState } from 'redux/reducers/connectionsReducer';
import countryReducer, { CountryState } from 'redux/reducers/countryReducer';
import currencyReducer, { CurrencyState } from 'redux/reducers/currencyReducer';
import eventDetailsReducer, { EventDetailsState } from 'redux/reducers/eventDetailsReducer';
import feedbackReducer, { FeedbackState } from 'redux/reducers/feedbackReducer';
import feedItemDetailReducer, { FeedItemDetailState } from 'redux/reducers/feedItemDetailReducer';
import feedReducer, { FeedState } from 'redux/reducers/feedReducer';
import financialCommentsReducer, {
  FinancialCommentsState,
} from 'redux/reducers/financialCommentsReducer';
import financialStatementsReducer, {
  FinancialStatementsState,
} from 'redux/reducers/financialStatementsReducer';
import geographiesAndMapsReducer, {
  GeographiesAndMapsState,
} from 'redux/reducers/geographiesAndMapsReducer';
import indicatorsReducer, { IndicatorsState } from 'redux/reducers/indicatorsReducer';
import industryReducer, { IndustriesState } from 'redux/reducers/industryReducer';
import keyFactorsReducer, { KeyFactorsState } from 'redux/reducers/keyFactorsReducer';
import landingPageReducer, { LandingPageState } from 'redux/reducers/landingPageReducer';
import notificationsReducer, { NotificationsState } from 'redux/reducers/notificationsReducer';
import onboardingReducer, { OnboardingState } from 'redux/reducers/onboardingReducer';
import portfolioReducer, { PortfolioState } from 'redux/reducers/portfolioReducer';
import portfoliosReducer, { PortfoliosState } from 'redux/reducers/portfoliosReducer';
import profileReducer, { ProfileState } from 'redux/reducers/profileReducer';
import reportsReducer, { ReportsState } from 'redux/reducers/reportsReducer';
import sharedReducer, { SharedState } from 'redux/reducers/sharedReducer';
import smartToolsRecentSearchesReducer, {
  SmartToolsRecentSearchesState,
} from 'redux/reducers/smartToolsRecentSearchesReducer';
import subscriptionReducer, { SubscriptionState } from 'redux/reducers/subscriptionPlansReducer';
import timelineReducer, { TimelineState } from 'redux/reducers/timelineReducer';
import upcomingEventsReducer, { UpcomingEventsState } from 'redux/reducers/upcomingEventsReducer';
import userPeerGroupsReducer, { UserPeerGroupsState } from 'redux/reducers/userPeerGroupsReducer';
import valueChainReducer, { ValueChainState } from 'redux/reducers/valueChainReducer';
import earnAndBuyApi from 'views/EarnAndBuy/api';
import publicApi from 'views/Landing/LandingPage/api';
import publicEarnAndBuyApi from 'views/Landing/LandingPage/components/PublicEarnAndBuy/EarnAndBuy/api';
import assetsApi from 'views/Portfolios/PortfoliosModal/api';

export interface ApplicationState {
  auth: AuthState;
  sharedReducer: SharedState;
  portfolioReducer: PortfolioState;
  portfoliosReducer: PortfoliosState;
  currencyReducer: CurrencyState;
  countryReducer: CountryState;
  feedback: FeedbackState;
  feedItemDetails: FeedItemDetailState;
  eventDetails: EventDetailsState;
  industries: IndustriesState;
  keyFactors: KeyFactorsState;
  companyReducer: CompanyState;
  subscriptions: SubscriptionState;
  userPeerGroups: UserPeerGroupsState;
  financialStatements: FinancialStatementsState;
  valueChain: ValueChainState;
  algoNotes: AlgoNotesState;
  geographiesAndMaps: GeographiesAndMapsState;
  smartToolsRecentSearches: SmartToolsRecentSearchesState;
  timeline: TimelineState;
  profileReducer: ProfileState;
  feedReducer: FeedState;
  upcomingEventsReducer: UpcomingEventsState;
  notifications: NotificationsState;
  financialComments: FinancialCommentsState;
  onboarding: OnboardingState;
  landingPage: LandingPageState;
  reports: ReportsState;
  indicators: IndicatorsState;
  connections: ConnectionsState;
  addPortfolioItemForm: AddPortfolioItemState;
}

interface APIs {
  [earnAndBuyApi.reducerPath]: any;
  [publicApi.reducerPath]: any;
  [publicEarnAndBuyApi.reducerPath]: any;
  [assetsApi.reducerPath]: any;
}

const store = configureStore<
  ApplicationState & APIs,
  AnyAction,
  ReadonlyArray<Middleware<any, ApplicationState & APIs>>
>({
  reducer: {
    auth: authReducer,
    sharedReducer: sharedReducer,
    portfolioReducer,
    portfoliosReducer,
    currencyReducer: currencyReducer,
    keyFactors: keyFactorsReducer,
    timeline: timelineReducer,
    companyReducer,
    industries: industryReducer,
    countryReducer,
    profileReducer: profileReducer,
    feedReducer,
    upcomingEventsReducer: upcomingEventsReducer,
    notifications: notificationsReducer,
    financialComments: financialCommentsReducer,
    onboarding: onboardingReducer,
    landingPage: landingPageReducer,
    reports: reportsReducer,
    indicators: indicatorsReducer,
    connections: connectionsReducer,
    feedback: feedbackReducer,
    feedItemDetails: feedItemDetailReducer,
    eventDetails: eventDetailsReducer,
    subscriptions: subscriptionReducer,
    userPeerGroups: userPeerGroupsReducer,
    financialStatements: financialStatementsReducer,
    valueChain: valueChainReducer,
    algoNotes: algoNotesReducer,
    geographiesAndMaps: geographiesAndMapsReducer,
    smartToolsRecentSearches: smartToolsRecentSearchesReducer,
    addPortfolioItemForm: addPortfolioItemFormReducer,

    [earnAndBuyApi.reducerPath]: earnAndBuyApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [publicEarnAndBuyApi.reducerPath]: publicEarnAndBuyApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
  },
  middleware: (
    getDefaultMiddleware: CurriedGetDefaultMiddleware<ApplicationState & APIs>,
  ): ReadonlyArray<Middleware<any, ApplicationState & APIs>> => [
    ...getDefaultMiddleware(),

    earnAndBuyApi.middleware,
    publicApi.middleware,
    publicEarnAndBuyApi.middleware,
    assetsApi.middleware,
  ],
});

export default store;
