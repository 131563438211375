import 'components/DEPRECATED/tooltips/index.scss';

import React, { useEffect, useState } from 'react';

interface Props {
  readonly id?: string;
  readonly title: string;
  readonly error?: boolean;
  readonly isLoginError?: boolean;
  readonly comingSoon?: boolean;
  readonly position?: string;
  readonly className?: string;
  readonly containerClassName?: string;
  readonly zIndex?: string;
  readonly errorClassName?: string;
  readonly message?: boolean;
}

/**
 * @deprecated
 */
const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  children,
  title,
  error = false,
  isLoginError = false,
  comingSoon = false,
  message = false,
  position = '',
  className = '',
  containerClassName = '',
  zIndex = '',
  errorClassName = '',
}: React.PropsWithChildren<Props>) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (isLoginError) setHover(true);
  }, [isLoginError]);

  const onMouseEnterHandler = () => {
    setHover(true);
  };
  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  return (
    <div
      id={id}
      className="my-auto"
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {children}
      {hover && (
        <div
          className={`absolute ${zIndex} ${error ? errorClassName : ''} ${containerClassName}`}
          style={{ left: position }}
        >
          {error && <div className="border-arrow"></div>}
          <div
            className={`${
              comingSoon
                ? 'border border-blue text-blue bg-white font-poppinsSemiBold px-1 py-0.5 uppercase'
                : 'text-white bg-gray font-poppins p-1.5'
            } ${error ? 'bg-red' : 'mt-2'} text-xs rounded shadow-xl ${className}`}
          >
            {title} {message && <b>coming soon</b>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
