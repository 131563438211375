import { ConditionalRender } from 'components/conditionalRenderer';
import { Tooltip } from 'components/tooltip';
import React, { useMemo } from 'react';

type ComponentType =
  | React.JSXElementConstructor<React.HTMLAttributes<HTMLOrSVGElement>>
  | React.FunctionComponent;

interface Props {
  readonly icon: ComponentType | React.ReactElement;
  readonly tooltip: string;
  readonly count: number | null;
  readonly highlighted: boolean;

  readonly variant: 'primary' | 'danger';

  onClick?(): void;
}

export const GenericActionButton: React.FC<Props> = ({
  icon,
  tooltip,
  count,
  highlighted,
  variant,

  onClick,
}: Props): React.ReactElement => {
  const iconElement = React.useMemo((): React.ReactElement => {
    const IconComponent = icon as React.FunctionComponent<React.HTMLAttributes<void>>;
    return React.isValidElement(icon) ? (
      icon
    ) : (
      <IconComponent className="w-icon h-icon fill-current" />
    );
  }, [icon]);

  const color = useMemo(
    (): string =>
      highlighted ? ON_COLOR[variant] : count === null || count > 0 ? NORMAL_COLOR : OFF_COLOR,
    [count, highlighted, variant],
  );

  const containerClassName = React.useMemo(
    (): string =>
      [
        'flex',
        `cursor-${onClick ? 'pointer' : 'default'}`,
        `text-${color}`,
        onClick ? `hover:text-${color}est` : undefined,
      ]
        .filter((value: string | undefined): value is string => value !== undefined)
        .join(' '),
    [color, onClick],
  );

  return (
    <Tooltip content={tooltip}>
      <div className={containerClassName} onClick={onClick}>
        {iconElement}
        <ConditionalRender renderIf={count !== null}>
          <span className="self-end h-2 leading-none text-xs pl-1">{count}</span>
        </ConditionalRender>
        <ConditionalRender renderIf={count === null}>
          {/* This is here just to make the alignment right */}
          <span className="self-end h-2 leading-none text-xs pl-1">&nbsp;</span>
        </ConditionalRender>
      </div>
    </Tooltip>
  );
};

const ON_COLOR = { primary: 'blue', danger: 'red-500' };
const NORMAL_COLOR = 'gray-dark';
const OFF_COLOR = 'gray-dark';
