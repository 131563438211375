import SVGIcon from 'components/icons/SVGIcon';
import { Tooltip } from 'components/tooltip';
import React from 'react';
import classes from 'views/DataAnalysis/Company/Details/company.module.scss';

interface Props {
  readonly loading: boolean;
  readonly progress: number;
  readonly tooltip?: string;

  onClick(): void;
}

export const OneClickDownloadButton: React.FC<Props> = ({
  tooltip,
  loading,
  progress,
  onClick,
}: Props): React.ReactElement => {
  return (
    <Tooltip content={<div className="max-w-xs">{tooltip}</div>}>
      <button className={className} onClick={onClick}>
        <div
          className="absolute left-0 bottom-0 h-full bg-blue-light rounded-sm"
          style={{ width: `${progress}%` }}
        />
        {loading ? (
          <div className={classes.spinner} />
        ) : (
          <SVGIcon className="relative w-5 h-5 fill-current" name="download" />
        )}
        <span className="relative">Generate 1 Click Report</span>
      </button>
    </Tooltip>
  );
};

const className =
  'relative flex items-center gap-2 font-poppinsSemiBold text-blue border border-blue rounded py-0 pr-4 pl-3 text-sm leading-7 h-8 ' +
  'hover:bg-blue hover:text-white transition truncate';
