import { ReactComponent as DownArrow } from 'assets/icons/svg/down-arrow-generic.svg';
import { ReactComponent as UpArrow } from 'assets/icons/svg/up-arrow-generic.svg';
import { SortOrder } from 'components/table/sorting';
import React from 'react';

interface Props {
  readonly sortOrder?: SortOrder;

  onSortAscending(): void;
  onSortDescending(): void;
}

export const SortIndicator: React.FC<Props> = ({
  sortOrder = SortOrder.none,
  onSortAscending,
  onSortDescending,
}: Props): React.ReactElement => {
  return (
    <div className="block w-3 h-6">
      <div
        className={
          sortOrder === SortOrder.ascending
            ? 'text-blue cursor-pointer'
            : 'text-gray-medium hover:text-gray-dark cursor-pointer'
        }
        onClick={onSortAscending}
      >
        <UpArrow className="w-3 fill-current" />
      </div>
      <div
        className={
          sortOrder === SortOrder.descending
            ? 'text-blue cursor-pointer'
            : 'text-gray-medium hover:text-gray-dark cursor-pointer'
        }
        onClick={onSortDescending}
      >
        <DownArrow className="w-3 fill-current" />
      </div>
    </div>
  );
};
