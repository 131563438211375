import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Level } from 'components/table/types';
import { ApplicationState } from 'redux/reducers/store';

export type KeyFactorType = 'risk' | 'growth';

interface RelevantReports {
  id: string | number;
  name: string;
  source: string;
}

export interface CrazyKeyFactorsCompanyPortfolios {
  readonly count: number;
  readonly names: readonly string[];
}

export interface CrazyKeyFactorsCompany {
  readonly id: string;
  readonly name: string;
  readonly ticker: string | null;
  readonly exchange: string | null;
  readonly portfolios: CrazyKeyFactorsCompanyPortfolios;
  readonly probability: Level;
  readonly impact: Level;
  readonly level: Level;
}

export interface CrazyKeyFactors {
  readonly id: string;
  readonly name: string;
  readonly companies_count: number;
  readonly companies: readonly CrazyKeyFactorsCompany[];
}

export interface KeyFactor {
  readonly id: string;
  readonly name: string;
  readonly summary: string;
  readonly probability: Level;
  readonly impact: Level;
  readonly level: Level;

  readonly reports: RelevantReports[];

  readonly affiliate_link: string;
  readonly affiliate_fine_print: string;
  readonly affiliate_summary: string;
}

export interface PortfolioKeyFactor extends KeyFactor {
  readonly portfolios: number;
  readonly companies: number;
}

export interface KeyFactorsState {
  portfolioKeyFactors: CrazyKeyFactors[];
  companyKeyFactors: KeyFactor[];
  industryKeyFactors: KeyFactor[];
  countryKeyFactors: KeyFactor[];
  loading: boolean;
  error: string;
}

const initialState: KeyFactorsState = {
  loading: false,
  portfolioKeyFactors: [],
  companyKeyFactors: [],
  industryKeyFactors: [],
  countryKeyFactors: [],
  error: '',
};

const keyRiskFactorSlice = createSlice({
  name: 'keyRiskFactor',
  initialState,
  reducers: {
    // GET KEY RISK FACTOR
    getFactorsLoading: state => {
      state.loading = true;
      state.portfolioKeyFactors = [];
      state.companyKeyFactors = [];
      state.industryKeyFactors = [];
    },
    getPortfolioFactorsSuccess: (
      state: KeyFactorsState,
      { payload }: PayloadAction<CrazyKeyFactors[]>,
    ): void => {
      state.portfolioKeyFactors = payload;
      state.loading = false;
      state.error = '';
    },
    getCompanyFactorsSuccess: (state: KeyFactorsState, { payload }: PayloadAction<KeyFactor[]>) => {
      state.companyKeyFactors = payload;
      state.loading = false;
      state.error = '';
    },
    getIndustryFactorsSuccess: (
      state: KeyFactorsState,
      { payload }: PayloadAction<KeyFactor[]>,
    ): void => {
      state.industryKeyFactors = payload;
      state.loading = false;
      state.error = '';
    },
    getCountryFactorsSuccess: (
      state: KeyFactorsState,
      { payload }: PayloadAction<KeyFactor[]>,
    ): void => {
      state.countryKeyFactors = payload;
      state.loading = false;
      state.error = '';
    },
    getFactorsFailed: (state: KeyFactorsState, { payload }: PayloadAction<string>): void => {
      state.loading = false;
      state.error = payload;
    },
    // Clears Error State
    clearErrors: state => {
      state.error = '';
    },
  },
});

export const portfolioKeyFactorsSelector = (state: ApplicationState): readonly CrazyKeyFactors[] =>
  state.keyFactors.portfolioKeyFactors;
export const companyKeyFactorsSelector = (state: ApplicationState): readonly KeyFactor[] =>
  state.keyFactors.companyKeyFactors;
export const industryKeyFactorsSelector = (state: ApplicationState): readonly KeyFactor[] =>
  state.keyFactors.industryKeyFactors;
export const countryKeyRiskFactorSelector = (state: ApplicationState): readonly KeyFactor[] =>
  state.keyFactors.countryKeyFactors;
export const loadingSelector = (state: any) => state.keyFactors.loading;
export const errorSelector = (state: any) => state.keyFactors.error;

export const {
  // GET
  getFactorsLoading,
  getCompanyFactorsSuccess,
  getIndustryFactorsSuccess,
  getPortfolioFactorsSuccess,
  getFactorsFailed,

  clearErrors,
} = keyRiskFactorSlice.actions;

export default keyRiskFactorSlice.reducer;
