import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { ConditionalRender } from 'components/conditionalRenderer';
import { Button } from 'components/genericButton';
import { PaymentMethodListItem } from 'components/paymentForm/paymentMethodListItem';
import { TermsAndConditionsForRecurringPayments } from 'components/paymentForm/termsAndConditionsForRecurringPayments';
import { useDebouncedErrorReset } from 'components/paymentForm/useDebouncedErrorReset';
import React, { useCallback, useMemo, useState } from 'react';
import { Card } from 'redux/reducers/authReducer';
import { createValidationError, FormErrors, YupError } from 'utils/forms';
import { useAmountFormatter } from 'views/Checkout/SubscriptionCheckout/hooks/useAmountFormatter';
import { Payment } from 'views/Checkout/SubscriptionCheckout/payment';
import { PaymentFormState } from 'views/Landing/SignUp/types';

interface Props {
  readonly payment: Payment;
  readonly paymentMethods: readonly Card[];
  readonly withTnc?: boolean;

  onPaymentCanceled(): void;
  onPaymentStarted(): void;
  onPaymentSucceeded(): void;
  onPaymentFailed(error: StripeError): void;
  onPaymentSoftError(): void;
}

export const PaymentMethodsList: React.FC<Props> = ({
  payment,
  paymentMethods,
  withTnc = true,

  onPaymentCanceled,
  onPaymentStarted,
  onPaymentSucceeded,
  onPaymentFailed,
  onPaymentSoftError,
}: Props): React.ReactElement => {
  const stripe = useStripe();

  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState<string>(
    paymentMethods[0]?.id ?? '',
  );

  const clientSecret = useMemo((): string | undefined => {
    return payment.client_secret ?? '';
  }, [payment.client_secret]);
  const [tncChecked, setTncChecked] = useState(false);
  const [errors, setErrors] = useState<FormErrors<PaymentFormState>>({});

  const handleUseSelected = useCallback(async (): Promise<void> => {
    if (!stripe) {
      console.warn('stripe is not ready');
      return;
    }
    if (withTnc && !tncChecked) {
      setErrors({
        termsAndConditionsAccepted: createValidationError(
          'Please confirm that you agree to the Terms & Conditions to continue.',
          'termsAndConditionsAccepted',
        ) as YupError,
      });
      return;
    }

    if (!clientSecret) {
      console.warn('there is no payment secret to use');
      return;
    }

    onPaymentStarted();
    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedPaymentMethodID,
      });

      const { error, paymentIntent } = result;

      if (error) {
        if (error.type === 'validation_error') {
          onPaymentSoftError();
          return;
        }

        onPaymentFailed(error);
      } else if (paymentIntent) {
        switch (paymentIntent.status) {
          case 'succeeded':
            onPaymentSucceeded();
            break;
          case 'requires_action':
          case 'processing':
          case 'canceled':
          case 'requires_capture':
          case 'requires_confirmation':
          case 'requires_payment_method':
            break;
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }, [
    clientSecret,
    onPaymentFailed,
    onPaymentSoftError,
    onPaymentStarted,
    onPaymentSucceeded,
    selectedPaymentMethodID,
    stripe,
    tncChecked,
    withTnc,
  ]);

  const amountString = useAmountFormatter(payment);
  const submitButtonLabel = useMemo((): string => {
    return `Pay ${amountString}`;
  }, [amountString]);

  useDebouncedErrorReset(errors, setErrors);

  const handleTermsAndConditionsAcceptedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      setTncChecked(target.checked);
    },
    [],
  );

  return (
    <div className="flex flex-col justify-between flex-grow mt-8">
      <div>
        <div className="mt-5">
          {paymentMethods.map(
            (card: Card): React.ReactElement => (
              <PaymentMethodListItem
                key={card.id}
                card={card}
                selected={selectedPaymentMethodID === card.id}
                onClick={setSelectedPaymentMethodID}
              />
            ),
          )}
        </div>
      </div>
      <ConditionalRender renderIf={withTnc}>
        <TermsAndConditionsForRecurringPayments
          amountString={amountString}
          checked={tncChecked}
          error={errors.termsAndConditionsAccepted?.message}
          onChange={handleTermsAndConditionsAcceptedChange}
        />
      </ConditionalRender>
      <div className="flex items-center justify-between">
        <Button type="button" variant="flat" label="Use a new card" />
        <div className=" flex items-center gap-4">
          <Button type="button" variant="secondary" label="Cancel" onClick={onPaymentCanceled} />
          <Button
            type="button"
            variant="primary"
            label={submitButtonLabel}
            onClick={handleUseSelected}
          />
        </div>
      </div>
    </div>
  );
};
