import { Truncate } from 'components/truncate';
import { TruncateType } from 'components/truncate/baseProps';
import React from 'react';
import { ArrayRenderer } from 'views/DataAnalysis/common/SummaryTable/arrayRenderer';
import { isLinkerObject, NavLink } from 'views/DataAnalysis/common/SummaryTable/navLink';
import { ReadMore } from 'views/DataAnalysis/common/SummaryTable/readMore';
import VALUES_FORMATTERS from 'views/DataAnalysis/valuesFormatters';

interface Props {
  readonly value: string | number | readonly string[] | Date | null;
  readonly name: string;
  readonly id: string;
  readonly largestNumber: number;
  readonly unit: string;

  onExpand?(name: string, value: any): void;
}

export const Value: React.FC<Props> = ({
  value,
  name,
  id,
  largestNumber,
  unit,

  onExpand,
}: Props): React.ReactElement => {
  const formatter = VALUES_FORMATTERS[unit];
  const handleClick = React.useCallback((): void => {
    onExpand?.(name, value);
  }, [name, onExpand, value]);

  if (typeof value === 'string') {
    if (value.length === 0) {
      return <span />;
    } else if (unit === 'paragraph') {
      return (
        <Truncate
          ellipsis={<ReadMore onClick={handleClick} />}
          contentType={TruncateType.text}
          lines={3}
          component="p"
        >
          <span>{value}</span>
        </Truncate>
      );
    } else if (/https?:\/\//.test(value)) {
      // FIXME: warning, this is not ideal
      return (
        <a
          id={id}
          className="text-blue normal-case"
          rel="noopener noreferrer"
          target="_blank"
          href={value}
        >
          Click here for details
        </a>
      );
    } else if (formatter) {
      return (
        <Truncate contentType={TruncateType.text} lines={1} component="p">
          <span>{formatter(value, largestNumber)}</span>
        </Truncate>
      );
    } else {
      return (
        <Truncate contentType={TruncateType.text} lines={1} component="p">
          <span>{value}</span>
        </Truncate>
      );
    }
  } else if (typeof value === 'number') {
    if (formatter) {
      return (
        <Truncate contentType={TruncateType.text} lines={1} component="p">
          <span>{formatter(value, largestNumber)}</span>
        </Truncate>
      );
    } else {
      return (
        <Truncate contentType={TruncateType.text} lines={1} component="p">
          <span>{value}</span>
        </Truncate>
      );
    }
  } else if (Array.isArray(value)) {
    return <ArrayRenderer value={value} name={name} />;
  } else if (isLinkerObject(value)) {
    return <NavLink link={value} />;
  } else {
    return <>-</>;
  }
};
