import SuccessIcon from 'assets/icons/svg/success-icon.svg';
import ErrorCapnoteCustomIcon from 'assets/images/error_icon_capnote.png';
import { Modal } from 'components/modal';
import { errorMessageFooter } from 'components/modals/InfoModal/constants';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeInfoModal, infoModalSelector } from 'redux/reducers/sharedReducer';

const InfoModal: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { visible, type, message } = useSelector(infoModalSelector);

  const handleClose = useCallback(() => {
    dispatch(closeInfoModal());
  }, [dispatch]);

  const successfulRequest: boolean = type === 'success';

  return (
    <Modal isOpen={visible} variant="lighter" onClose={handleClose}>
      <Modal.Content>
        <div className="md:w-form sm:w-full text-center p-8">
          {type && (
            <>
              <img
                src={successfulRequest ? SuccessIcon : ErrorCapnoteCustomIcon}
                className={`m-auto mb-11 ${successfulRequest && 'w-16'}`}
                alt="descriptive icon"
              />
              <h1 className="text-blue mb-10">{message}</h1>
              {!successfulRequest && <p className="text-blue text-md">{errorMessageFooter}</p>}
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default InfoModal;
