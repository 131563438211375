import { AmountInput } from 'components/amountInput';
import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React from 'react';
import { CryptoCurrency, PortfolioCryptoCurrencyItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCryptoCurrencyItem;
}

export const CryptoCurrencyItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCryptoCurrencyItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <Select
        id="name"
        label="Name of cryptocurrency"
        items={cryptoCurrencies}
        value={item.name}
        onChange={updaterFor('name')}
      />
      <NumericInput
        name="amount"
        value={item.amount}
        label="Quantity"
        precision={9}
        onValueChange={updaterFor('amount')}
      />
      <div className="col-span-2">
        <AmountInput
          name="averagePurchasePrice"
          label="Average purchase price"
          value={item.averagePurchasePriceAmount}
          currency={item.averagePurchasePriceCurrency}
          onCurrencyChange={updaterFor('averagePurchasePriceCurrency')}
          onValueChange={updaterFor('averagePurchasePriceAmount')}
        />
        {/* This is just an indicator, no need for the user to edit */}
        <AmountInput
          name=""
          label="Current price"
          value={70000}
          currency="USD"
          readOnly={true}
          onCurrencyChange={updaterFor('averagePurchasePriceCurrency')}
          onValueChange={updaterFor('averagePurchasePriceAmount')}
        />
      </div>
    </div>
  );
};

const cryptoCurrencies: ReadonlyArray<{ readonly label: string; readonly value: CryptoCurrency }> =
  [
    {
      label: 'Bitcoin',
      value: CryptoCurrency.bitcoin,
    },
    {
      label: 'Ethereum',
      value: CryptoCurrency.ethereum,
    },
    {
      label: 'Tether',
      value: CryptoCurrency.tether,
    },
    {
      label: 'USD Coin',
      value: CryptoCurrency.usdCoin,
    },
    {
      label: 'Binance Coin',
      value: CryptoCurrency.binanceCoin,
    },
    {
      label: 'Cardano',
      value: CryptoCurrency.cardano,
    },
    {
      label: 'XRP',
      value: CryptoCurrency.xrp,
    },
    {
      label: 'Solana',
      value: CryptoCurrency.solana,
    },
    {
      label: 'Dogecoin',
      value: CryptoCurrency.dogecoin,
    },
    {
      label: 'Shiba Inu',
      value: CryptoCurrency.shibaInu,
    },
    {
      label: 'Algorand',
      value: CryptoCurrency.algorand,
    },
    {
      label: 'Others',
      value: CryptoCurrency.others,
    },
  ];
