import { Clickable } from 'components/clickable';
import SVGIcon from 'components/icons/SVGIcon';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';

export interface LLOption<T extends string | number | symbol = string> {
  readonly id: T;
  readonly label?: string;
  readonly name?: string;
}

interface Props<T extends string | number | symbol = string> {
  options?: Array<LLOption<T>>;
  searchPlaceholder?: string;
  value?: LLOption<T> | Array<LLOption<T>>;
  leadingIcon?: ReactElement;
  onSelectOption?: (newValue: LLOption<T> | Array<LLOption<T>>) => void;
  multiselect?: boolean;
}

function LargeListPicker<T extends string | number | symbol = string>({
  searchPlaceholder,
  value,
  options,
  onSelectOption,
  multiselect,
}: Props<T>) {
  const [search, setSearch] = useState('');

  const handleSelectOption = useCallback(
    (item: LLOption<T>) => {
      if (multiselect && onSelectOption) {
        if (Array.isArray(value)) {
          const optionIndex = value.findIndex(
            (option: LLOption<T>): boolean => item.id === option.id,
          );
          if (optionIndex === -1) {
            onSelectOption([...value, item]);
          } else {
            onSelectOption(
              value.map((option: LLOption<T>): LLOption<T> => {
                if (option.id === item.id) {
                  return item;
                } else {
                  return option;
                }
              }),
            );
          }
        } else {
          onSelectOption([item]);
        }
      } else {
        if (onSelectOption) {
          onSelectOption(item);
        }
      }
    },
    [onSelectOption, multiselect, value],
  );

  const valuesMap = useMemo(() => {
    if (Array.isArray(value)) {
      return value.reduce(
        (
          output: { [key: string | number | symbol]: boolean },
          option: LLOption<T>,
        ): { [key: string | number | symbol]: boolean } => {
          return { ...output, [option.id]: true };
        },
        {},
      );
    }

    return {};
  }, [value]);

  let optionsCount = 0;

  return (
    <div className="relative w-full mt-3">
      <input
        onChange={({ target: { value } }) => setSearch(value)}
        className="h-12 pl-4 pr-6 w-full mb-2 font-poppins"
        placeholder={searchPlaceholder || 'Search'}
      />
      <SVGIcon name="search-icon" className="absolute top-4 right-3 w-4 h-4" />

      {options?.map(item => {
        if (optionsCount > 4) return null;
        if (
          search &&
          (item.label?.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) == -1 ||
            item.name?.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) == -1)
        )
          return null;

        optionsCount += 1;
        return (
          <Clickable key={String(item.id)} clickData={item} onClick={handleSelectOption}>
            <div className="w-full rounded flex p-2 cursor-pointer hover:bg-gray-medium focus:bg-gray-medium focus:outline-none">
              {(!Array.isArray(value) && value?.id === item.id) || valuesMap[item.id] ? (
                <SVGIcon name="selected-icon" className="mt-px w-3 h-3" />
              ) : (
                <div className="w-3 h-3 border border-gray rounded-sm" />
              )}
              <p className="pl-2 text-xs text-left">{item.label || item.name}</p>
            </div>
          </Clickable>
        );
      })}
    </div>
  );
}

export default LargeListPicker;
