import { Tag } from 'components/tag';
import { UserAvatar } from 'components/userAvatar';
import { useGtag } from 'hooks/useGtag';
import React from 'react';
import { Link } from 'react-router-dom';
import { Connection } from 'redux/reducers/connectionsReducer';
import FollowButton, { FollowAction } from 'views/Connections/Overview/components/FollowButton';

interface Props {
  readonly user: Connection;

  onClick(followType: FollowAction, userId: string): void;
}

const FollowListItem: React.FC<Props> = ({ user, onClick }: Props): React.ReactElement => {
  const { trackEvent } = useGtag();
  const handleFollowButtonClick = (followAction: FollowAction): void => {
    onClick(followAction, user.id);
    trackEvent(`${followAction}-user`, {
      section: 'list',
      ...user,
    });
  };
  return (
    <Link className={containerClass} to={`/users/${user.id}`}>
      <UserAvatar user={user} size="medium" />

      <div className="flex-1 justify-between truncate min-w-0">
        <div className="font-jostSemiBold text-md truncate normal-case">
          <div className="inline-block align-middle">
            {user.first_name} {user.last_name}
          </div>
          <div className="inline-block align-middle mx-2">
            {user.follows_you && <Tag>Follows You</Tag>}
          </div>
        </div>
        <p className="font-poppins text-gray text-base truncate normal-case">{user.about_me}</p>
      </div>
      <div className="flex-shrink-0">
        <FollowButton
          followed={user.followed_by_you}
          clickData={user.id}
          onClick={handleFollowButtonClick}
        />
      </div>
    </Link>
  );
};

export default FollowListItem;

const containerClass = [
  'shadow-xs flex items-center justify-between gap-5 hover:bg-blue-light h-18 rounded-md bg-white mb-6 px-3',
  'hover:shadow-md duration-300 w-full cursor-pointer',
].join(' ');
