export enum AccessMode {
  readOnly = 'read-only',
  readWrite = 'read-write',
  owner = 'owner',
}

export const accessModeLabels: Record<AccessMode, string> = {
  [AccessMode.readOnly]: 'Read Only',
  [AccessMode.readWrite]: 'Read & Write',
  [AccessMode.owner]: 'Owner',
};
