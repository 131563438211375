import { Dispatch } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse, CancelToken } from 'axios';
import { updateStatementCommentsCount } from 'redux/reducers/companyReducer';
import {
  Comment,
  getCommentsFailure,
  getCommentsStart,
  getCommentsSuccess,
} from 'redux/reducers/financialCommentsReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';

export const getComments =
  (entityName: string, filingId: string, cancelToken?: CancelToken) =>
  (dispatch: Dispatch): void => {
    dispatch(getCommentsStart());
    api
      .get(`${API_V1_PATH}/${entityName}/statement-social/${filingId}`, {
        cancelToken: cancelToken,
      })
      .then((response: AxiosResponse): void => {
        const { data, status } = response;
        if (status === HttpStatusOk) {
          dispatch(getCommentsSuccess(data.comments as Comment[]));
        }
      })
      .catch(err => {
        dispatch(getCommentsFailure(err));
      });
  };

export const addComment =
  (entityName: string, statementName: string, filingId: string, commentText: string) =>
  (dispatch: Dispatch): void => {
    const payload = { comment: commentText };
    api
      .post(`${API_V1_PATH}/${entityName}/statement-comment/${filingId}`, payload)
      .then((response: AxiosResponse): Promise<AxiosResponse> => {
        if (response.status === HttpStatusOk) {
          return api.get(`/api/v1/${entityName}/statement-social/${filingId}`);
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response: AxiosResponse): void => {
        const { data, status } = response;
        if (status === HttpStatusOk) {
          const updateStatementCommentCountPayload = {
            filingId: filingId,
            statementName: statementName,
            comments: data.comments,
          };

          dispatch(getCommentsSuccess(data.comments as Comment[]));
          dispatch(updateStatementCommentsCount(updateStatementCommentCountPayload));
        }
      })
      .catch((error: AxiosError): void => {
        const { response } = error;
        if (response?.status === 406) {
          // FIXME: What is this?
          dispatch(getCommentsFailure({ message: response.data, code: 406 }));
        } else {
          // For now, ignore other errors
        }
      });
  };
