import React from 'react';

interface Props {
  readonly disabled: boolean;
}

export const Disabler: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  disabled,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  if (!React.isValidElement(children)) {
    throw new Error('Disabler: expecting a valid element single child');
  }

  const { props } = children;
  if (typeof props.className !== 'string') {
    if (!disabled) {
      return <>{children}</>;
    }
    // In this case we have no option but to add an extra <div />
    // because we have no guarantee what will happen if we use the spread
    // operator with `props.className'
    return <div className="pointer-events-none opacity-40">{children}</div>;
  }

  if (disabled) {
    // Do not create a new DOM node, that simple makes
    // performance worse. Instead, let's clone the original
    // child with some extra properties ;)
    return React.cloneElement(children, {
      ...props,
      className: [props.className, 'pointer-events-none opacity-40'].join(' '),
    });
  } else {
    return children;
  }
};
