import { OneClickDownloadButton } from 'components/1clickDownloadButton';
import { CommonViewHeader } from 'components/commonViewHeader';
import { Disabler } from 'components/disabler';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { Tooltip } from 'components/tooltip';
import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
import { userPeerGroupSelector } from 'redux/reducers/userPeerGroupsReducer';
import { fetchUserPeerGroupAction } from 'redux/services/userPeerGroupsService';
import { AlgoNotes } from 'views/SmartTools/PeerGroups/Details/algoNotes';
import { PeerGroupsComparisons } from 'views/SmartTools/PeerGroups/Details/peerComparisons';

export const PeerGroupDetails: React.FC = (): React.ReactElement => {
  const peerGroup = useSelector(userPeerGroupSelector);
  const dispatch = useDispatch();

  const { peerGroupId } = useParams<{ peerGroupId: string }>();
  const { name, description, comparisons, tags } = peerGroup;

  useEffect((): void | VoidFunction => {
    if (!peerGroupId) {
      return;
    }

    return dispatch(fetchUserPeerGroupAction(peerGroupId));
  }, [dispatch, peerGroupId]);

  return (
    <MainOutletContent>
      <div className="flex items-start justify-between mb-2">
        <CommonViewHeader
          path={['SMART TOOLS', 'COMPARE', name?.toUpperCase() ?? 'NO NAME PROVIDED']}
          description={description ?? 'No description provided'}
          tags={tags}
        />

        <Tooltip
          content={<div className="font-poppinsSemiBold uppercase">Coming Soon</div>}
          variant="light"
        >
          <div>
            <Disabler disabled={true}>
              <OneClickDownloadButton loading={false} progress={0} tooltip="" onClick={noop} />
            </Disabler>
          </div>
        </Tooltip>
      </div>

      <Tabs variant={TabsVariant.buttons} shrinkFactor={5}>
        <Tab element={<PeerGroupsComparisons comparisons={comparisons} />} label="Comparisons" />
        <Tab element={<AlgoNotes />} label="Algo Notes" path="algo-notes/*" />

        <Route element={<AlgoNotes />} path="algo-notes/:noteId" />
      </Tabs>
    </MainOutletContent>
  );
};
