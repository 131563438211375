export const POST_REPORTED_WARNING = 'You reported this post and is currently under review';
export const POST_REPORTED_SUCCESSFULLY = 'You have successfully submitted your report. Thank you.';
export const POST_ALREADY_REPORTED_ERROR = 'You have already reported this item';
export const FLAGGED_COMMENT_TEXT = 'This comment has been hidden for violating our ';
export const FLAGGED_COMMENT_LINK = 'Capnote Terms and Conditions';
export const COMMENT_ALREADY_REPORTED_TOOLTIP =
  'This comment has been reported, and is currently under review';
export const REPORT_TOOLTIP = 'Report';
export const WRITE_A_REPLY = 'Write a reply';
export const DELETE_POST_QUESTION = 'You are about to delete this post, are you sure?';
export const DELETE_POST_TITLE = 'Delete Post';
export const DELETE_COMMENT_QUESTION = 'You are about to delete this comment, are you sure?';
export const DELETE_COMMENT_TITLE = 'Delete Comment';
export const REPORT = 'Report';
export const SHARE = 'Share';
export const DELETE = 'Delete';
export const EMPTY_COMMENTS_MESSAGE =
  'No comments yet. Share your thoughts and start the conversation.';
