import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

interface Props {
  readonly code: string | null;
}

export const ReferralLink: React.FC<Props> = ({ code }: Props): React.ReactElement => {
  const copyURL = React.useCallback((): void => {
    const url = `${location.protocol}//${location.host}/sign-up/?referrer=${code}`;
    void clipboard.writeText(url);
  }, [code]);

  if (!code) {
    return (
      <p className="block text-gray-medium pt-0.5">
        You don&apos;t have a referrer code yet, please create one
      </p>
    );
  }

  return (
    <div className="flex items-center gap-3">
      <p className="block text-gray-dark">
        <span>{location.protocol}</span>
        <span>/</span>
        <span>/</span>
        <span>{location.host}</span>
        <span>/sign-up/?referrer={code}</span>
      </p>

      <div className="flex items-center text-blue gap-1 cursor-pointer" onClick={copyURL}>
        <SVGIcon name="copy" className="w-4 fill-current cursor-pointer" />
        <p className="font-jostSemiBold">Copy</p>
      </div>
    </div>
  );
};

const { location } = window;
const { clipboard } = window.navigator;
