import { Breadcrumbs } from 'components/breadcrumbs';
import { TruncatedTags } from 'components/truncatedTags';
import { noop } from 'lodash';
import React, { useMemo } from 'react';
import { Organization, OrganizationTypes } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { OrganizationSearchInput } from 'views/SmartTools/ValueChains/components/OrganizationSearchInput';

interface Props {
  readonly description: string;
  readonly path: readonly string[];
  readonly organization?: Organization | null;
  readonly allowOnly: OrganizationTypes;
  readonly tags?: readonly string[];
}

export const OrganizationSelectionHeading: React.FC<Props> = ({
  description,
  path: basePath,
  organization,
  allowOnly,
  tags,
}: Props): React.ReactElement => {
  const path = useMemo((): readonly string[] => {
    if (organization) {
      const { type, name } = organization;
      return [...basePath, type?.toUpperCase() ?? 'WTF?', name?.toUpperCase() ?? 'WTF?'];
    } else {
      return basePath;
    }
  }, [basePath, organization]);

  return (
    <>
      <div className="h-20 mb-6">
        <Breadcrumbs path={path} />
        <TextLine onExpand={noop} content={description} />
        <div className="my-2">
          <TruncatedTags tags={tags ?? []} onExpand={noop} onClick={noop} />
        </div>
      </div>

      <OrganizationSearchInput allowOnly={allowOnly} showRecentItems={!organization} />
    </>
  );
};
