import { DateRangeFilter } from 'components/dateRangeFilter';
import { HorizontalExpander } from 'components/horizontalExpander';
import { SearchBox } from 'components/searchBox';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  readonly keyword: string;
  readonly searching: boolean;
}

export const KeywordAndDateRangeFilter: React.FC<Props> = ({
  keyword,
  searching,
}: Props): React.ReactElement => {
  const [search, setSearch] = useSearchParams();
  const [visible, setVisible] = useState<boolean>(true);

  const handleKeywordChange = useCallback(
    (value: string): void => {
      if (value.trim() === '') {
        search.delete('keyword');
      } else {
        search.set('keyword', value);
      }
      setSearch(search);
    },
    [search, setSearch],
  );

  useEffect((): VoidFunction => {
    const onResize = (): void => {
      // TODO: ideally show a download link for the mobile app
      setVisible(window.innerWidth >= 1280);
    };

    window.addEventListener('resize', onResize);
    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return visible ? (
    <div className="flex items-stretch pr-3 py-2 my-2 border-b border-gray-light">
      <div className="px-3">
        <SearchBox value={keyword} searching={searching} onChange={handleKeywordChange} />
      </div>
      <HorizontalExpander />
      <DateRangeFilter />
    </div>
  ) : (
    <></>
  );
};
