import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { AlgoNote } from 'redux/reducers/algoNotesReducer';

export const createColumns = (
  type: 'company' | 'peer-group',
): ReadonlyArray<ColumnDefinition<AlgoNote>> => [
  {
    type: ColumnType.date,
    label: 'Date',
    name: 'date',
    alignment: Alignment.center,
    width: 150,
    sortable: true,
  },
  {
    type: ColumnType.text,
    label: 'Note',
    alignment: Alignment.left,
    name: 'name',
    weight: 5,
  },
  {
    type: ColumnType.custom,
    label: 'Category',
    name: 'category',
    headerAlignment: Alignment.center,
    weight: 2,
    sortable: true,
    render: (value: string, data: AlgoNote): React.ReactElement => {
      if (type === 'company') {
        const url = ((): string => {
          switch (value) {
            case 'Financial Statements':
              return `/data-analysis/company/details/${data.companyId}/financials`;
            case 'Peer Comparisons':
              return `/data-analysis/company/details/${data.companyId}/peer-comparisons`;
            default:
              throw new Error(`unknown category: ${value}`);
          }
        })();

        return (
          <div className="text-center leading-10 cursor-default">
            <Link to={url} className="text-blue font-poppins" onClick={ignore}>
              {value}
            </Link>
          </div>
        );
      } else {
        return (
          <div className="text-center leading-10">
            <p>{value}</p>
          </div>
        );
      }
    },
  },
];

const ignore = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
  event.stopPropagation();
};
