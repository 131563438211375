import { ReactComponent as SuccessIcon } from 'assets/icons/svg/success-icon.svg';
import React from 'react';

interface Props {
  readonly title: string;
  readonly message: string;

  onClose(): void;
}

export const SuccessBox: React.FC<Props> = ({
  title,
  message,
  onClose,
}: Props): React.ReactElement => {
  return (
    <div className="min-w-modal-md font-poppins max-w-lg p-6">
      <div className="flex items-start gap-4">
        <SuccessIcon className="w-14" />
        <div>
          <h2 className="mb-2 text-2xl">{title}</h2>
          <h6 className="text-gray text-md">{message}</h6>
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-2 font-poppinsSemiBold text-sm mt-3 -mb-2">
        <button className="text-blue p-2 rounded-5 hover:bg-gray-light" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};
