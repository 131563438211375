import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { InvoiceStatus, SubscriptionInvoice } from 'redux/reducers/authReducer';
import { BillingInterval } from 'redux/reducers/subscriptionPlansReducer';
import { fromStringToFormattedDate } from 'utils/dateTime';
import { AmountRenderer } from 'views/Profile/Payments/amountRenderer';
import { billingCycleLabel } from 'views/Profile/Payments/index';

const columns: ReadonlyArray<ColumnDefinition<SubscriptionInvoice>> = [
  {
    name: 'subscription_plan_name',
    type: ColumnType.text,
    label: 'Subscription Plan Name',
    weight: 2,
    alignment: Alignment.left,
  },
  {
    name: 'billing_interval',
    type: ColumnType.custom,
    label: 'Billing Interval',
    weight: 1,
    headerAlignment: Alignment.left,
    render: function (value: BillingInterval): React.ReactNode {
      return (
        <div className="text-left truncate text-current leading-8">{billingCycleLabel[value]}</div>
      );
    },
  },
  {
    name: 'status',
    type: ColumnType.custom,
    weight: 1,
    label: 'Status',
    headerAlignment: Alignment.center,
    render: (value: InvoiceStatus): React.ReactNode => {
      const data = ((): {
        readonly color: string;
        readonly text: string;
      } => {
        switch (value) {
          case InvoiceStatus.draft:
            return { color: 'gray-300', text: 'Pending' };
          case InvoiceStatus.open:
            return { color: 'yellow-500', text: 'Open' };
          case InvoiceStatus.void:
            return { color: 'red-500', text: 'Void' };
          case InvoiceStatus.paid:
            return { color: 'green-600', text: 'Paid' };
          case InvoiceStatus.uncollectible:
            return { color: 'red-500', text: 'Uncollectible' };
          case InvoiceStatus.freeTrial:
            return { color: 'blue', text: 'Free Trial' };
        }
      })();

      return (
        <div className={`text-center text-${data.color} text-sm font-poppinsMedium uppercase`}>
          {data.text}
        </div>
      );
    },
  },
  {
    name: 'total',
    type: ColumnType.custom,
    headerAlignment: Alignment.center,
    weight: 1,
    label: 'Total',
    render: function (value: number | null): React.ReactNode {
      return <AmountRenderer value={value} />;
    },
  },
  {
    name: 'amount_paid',
    type: ColumnType.custom,
    headerAlignment: Alignment.center,
    weight: 1,
    label: 'Amount Paid',
    render: function (value: number | null): React.ReactNode {
      return <AmountRenderer value={value} />;
    },
  },
  {
    name: 'amount_remaining',
    type: ColumnType.custom,
    headerAlignment: Alignment.center,
    weight: 1,
    label: 'Amount Remaining',
    render: function (value: number | null): React.ReactNode {
      return <AmountRenderer value={value} />;
    },
  },
  {
    name: 'created_at',
    type: ColumnType.custom,
    weight: 2,
    label: 'Invoice Created Date',
    headerAlignment: Alignment.center,
    render: function (value: string): React.ReactNode {
      return (
        <div className="text-center truncate text-current leading-8">
          {fromStringToFormattedDate(value)}
        </div>
      );
    },
  },
  {
    name: 'expires_at',
    type: ColumnType.custom,
    headerAlignment: Alignment.center,
    label: 'Plan Expiry Date',
    weight: 2,
    render: function (value: string): React.ReactNode {
      return (
        <div className="text-center truncate text-current leading-8">
          {fromStringToFormattedDate(value)}
        </div>
      );
    },
  },
];

export default columns;
