import { DynamicColor, MarkerDot } from 'components/capnoteMap/components/markerDot';
import { Tooltip } from 'components/tooltip';
import { DivIcon, divIcon } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Marker } from 'react-leaflet';
import {
  PortfoliosCompaniesMapItem,
  PortfoliosMapLocation,
} from 'redux/reducers/portfoliosReducer';

interface Props {
  readonly marker: PortfoliosMapLocation<PortfoliosCompaniesMapItem>;

  readonly active?: string | null;
  readonly colors?: Record<string, DynamicColor>;
}

export const PortfoliosCompaniesMarker: React.FC<Props> = ({
  marker,
  active = null,
  colors = {},
}: Props): React.ReactElement => {
  const [icon, setIcon] = useState<DivIcon>(new DivIcon());

  const { coordinates } = marker;

  useEffect(
    (): void =>
      setIcon(
        divIcon({
          html: createDot(marker, colors, active),
        }),
      ),
    [active, colors, marker],
  );

  return <Marker position={[coordinates.latitude, coordinates.longitude]} icon={icon} />;
};

const createDot = (
  marker: PortfoliosMapLocation<PortfoliosCompaniesMapItem>,
  colors: Record<string, DynamicColor>,
  _: /* active */ string | null,
): HTMLElement => {
  const { portfolios } = marker;

  const element = document.createElement('div');
  const root = createRoot(element);

  root.render(
    <div className="flex items-center justify-around flex-wrap w-12">
      {portfolios.map((portfolio: PortfoliosCompaniesMapItem): React.ReactElement => {
        const { companies } = portfolio;
        return (
          <Tooltip
            key={portfolio.id}
            content={
              <div>
                <h4 className="flex items-center justify-between mt-1">
                  <span>{portfolio.name}</span>
                  {companies.length > 0 ? (
                    <>
                      <span>&ensp;</span>
                      <span className="text-xs font-poppinsSemiBold bg-blue text-white px-1 rounded">
                        {companies.length}
                      </span>
                    </>
                  ) : null}
                </h4>
                <ul className="text-left my-2">
                  {companies.map(
                    (name: string): React.ReactElement => (
                      <li key={name} className="py-1">
                        <p className="text-gray whitespace-nowrap">{name}</p>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            }
            variant="light"
            fitContent={true}
          >
            <MarkerDot size="normal" color={colors[portfolio.id]} />
          </Tooltip>
        );
      })}
    </div>,
  );

  return element;
};
