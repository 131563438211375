import React from 'react';
import {
  isPortfolioArtDigitalArtArtItem,
  isPortfolioArtFineWinesOrBeveragesItem,
  isPortfolioArtPaintingArtItem,
  isPortfolioArtPhotographyArtItem,
  isPortfolioArtSculptureArtItem,
  isPortfolioArtVideoItem,
  PortfolioArtItem,
} from 'types/portfolioItem';
import { DigitalArtArtItemForm } from 'views/Portfolios/PortfoliosModal/digitalArtArtItemForm';
import { FineWinesOrBeveragesArtItemForm } from 'views/Portfolios/PortfoliosModal/fineWinesOrBeveragesArtItemForm';
import { PaintingArtItemForm } from 'views/Portfolios/PortfoliosModal/paintingArtItemForm';
import { PhotographyArtItemForm } from 'views/Portfolios/PortfoliosModal/photographyArtItemForm';
import { SculptureArtItemForm } from 'views/Portfolios/PortfoliosModal/sculptureArtItemForm';
import { VideoArtItemForm } from 'views/Portfolios/PortfoliosModal/videoArtItemForm';

interface Props {
  readonly item: PortfolioArtItem;
}

export const ArtItemFormSelector: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  if (isPortfolioArtPaintingArtItem(item)) {
    return <PaintingArtItemForm item={item} />;
  } else if (isPortfolioArtSculptureArtItem(item)) {
    return <SculptureArtItemForm item={item} />;
  } else if (isPortfolioArtDigitalArtArtItem(item)) {
    return <DigitalArtArtItemForm item={item} />;
  } else if (isPortfolioArtPhotographyArtItem(item)) {
    return <PhotographyArtItemForm item={item} />;
  } else if (isPortfolioArtVideoItem(item)) {
    return <VideoArtItemForm item={item} />;
  } else if (isPortfolioArtFineWinesOrBeveragesItem(item)) {
    return <FineWinesOrBeveragesArtItemForm item={item} />;
  } else {
    throw new Error('Invalid type of art');
  }
};
