import React from 'react';

interface Props {
  readonly label: string;
  readonly icon: React.FunctionComponent<any>;
  readonly disabled?: boolean;

  onClick(): void;
}

export const FeedCardMoreMenuItem: React.FC<Props> = ({
  label,
  icon,
  disabled = false,
  onClick,
}: Props): React.ReactElement => {
  const Icon = icon;

  return (
    <div className={disabled ? disabledContainerClass : containerClass} onClick={onClick}>
      <Icon className="w-4 fill-current" /> <span>{label}</span>
    </div>
  );
};

const containerClass =
  'flex font-poppins bg-gray-light items-center gap-2 pl-2 py-2 pr-7 text-gray text-sm hover:bg-gray-medium cursor-pointer';
const disabledContainerClass = `${containerClass} opacity-50 pointer-events-none`;
