import { Disabler } from 'components/disabler';
import { Button } from 'components/genericButton';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useCallback, useMemo } from 'react';
import {
  Discount,
  DiscountCodeErrors,
  isPaymentIntentPayment,
  Payment,
} from 'views/Checkout/SubscriptionCheckout/payment';

interface Props {
  readonly payment: Payment | null;
  readonly discountCode: string;
  readonly busy: boolean;
  readonly currentError: DiscountCodeErrors;

  onDiscountCodeChange(discountCode: string): void;
  onApply(): void;
}

export const DiscountInput: React.FC<Props> = ({
  payment,
  busy,
  discountCode,
  currentError,

  onDiscountCodeChange,
  onApply,
}: Props): React.ReactElement | null => {
  const discount = useMemo((): Discount | undefined => {
    if (!isPaymentIntentPayment(payment)) {
      return undefined;
    }

    return payment?.invoice?.discount;
  }, [payment]);

  const handleDiscountCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onDiscountCodeChange(value);
    },
    [onDiscountCodeChange],
  );

  if (!isPaymentIntentPayment(payment)) {
    return null;
  } else if (discount) {
    return (
      <div className="relative mt-5">
        <input
          className={discountCodeClassName}
          placeholder="Discount Code"
          readOnly={true}
          value={discount.code}
          onChange={handleDiscountCodeChange}
        />
        <div className="text-red mt-2">
          <p className="text-sm">&nbsp;</p>
        </div>
        <div className="flex items-center justify-end">
          <Button type="button" label="Apply" variant="primary" />
        </div>
      </div>
    );
  } else {
    return (
      <Disabler disabled={busy}>
        <div className="relative mt-5">
          <div className="relative">
            <input
              className={discountCodeClassName}
              placeholder="Discount Code"
              value={discountCode}
              onChange={handleDiscountCodeChange}
            />
            {currentError === DiscountCodeErrors.none ? (
              <div className="text-red mt-2">
                <p className="text-sm">&nbsp;</p>
              </div>
            ) : (
              <div className="text-red mt-2 mb-3">
                <p className="text-sm">{discountCodeErrors[currentError]}</p>
              </div>
            )}
            <div className="flex items-center justify-end">
              <Button type="button" label="Apply" variant="primary" onClick={onApply} />
            </div>
          </div>

          <SpinnerLoader visible={busy} />
        </div>
      </Disabler>
    );
  }
};

const discountCodeErrors: Record<DiscountCodeErrors, string> = {
  [DiscountCodeErrors.none]: '',
  [DiscountCodeErrors.notFound]: 'Discount code not found',
  [DiscountCodeErrors.expired]: 'Discount code has expired',
  [DiscountCodeErrors.exceededUsageLimit]: 'Discount code exceeded usage limit',
  [DiscountCodeErrors.notApplicable]: 'Cannot apply this discount code to the selected plan',
};

const discountCodeClassName =
  'block w-full p-4 text-sm text-gray-900 border border-gray-300 outline-none ' +
  'rounded bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ' +
  'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ' +
  'dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-poppinsMedium';
