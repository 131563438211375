import { CommonFilter } from 'components/filters/helpers';
import { API_V1_PATH } from 'utils/config/axiosConfig';

const filters = (companyId: string): readonly CommonFilter[] => [
  {
    name: 'industry',
    type: 'backend',
    label: 'Industry',
    url: `${API_V1_PATH}/filters/peer-comparisons/${companyId}/industrial-activities`,
  },
  {
    name: 'subsidiary_geography',
    type: 'backend',
    label: 'Subsidiary Geography',
    url: `${API_V1_PATH}/filters/peer-comparisons/${companyId}/subsidiary-geographies`,
  },
];

export default filters;
