import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';

interface OwnProps {
  readonly title: string;
  readonly icon: SvgIconName | React.ReactElement;
  readonly linkTo?: string;
  readonly badge?: string;
  readonly active?: boolean;

  onClick?(): void;
}
type Props = React.PropsWithoutRef<OwnProps> & React.RefAttributes<HTMLDivElement>;

export const HeaderButton: React.ForwardRefExoticComponent<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(function HeaderButton(
  { icon, title, badge, active = false, onClick }: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const nonLinkContainerClassName = useMemo((): string => {
    return [containerClassName, active ? 'text-blue' : 'text-gray'].join(' ');
  }, [active]);

  return (
    <div ref={ref} className={nonLinkContainerClassName} onClickCapture={onClick}>
      <div className={iconContainerClassName}>
        {badge && <div className={badgeClassName}>{badge}</div>}
        {typeof icon === 'string' ? <SVGIcon name={icon} className={iconClassName} /> : icon}
      </div>
      <p className={captionClassName}>{title}</p>
    </div>
  );
});

const containerClassName = 'flex flex-col items-center gap-3 px-2 cursor-pointer';
const iconContainerClassName = 'flex items-center justify-center relative h-7 w-7';
const iconClassName = 'fill-current h-5.5 w-5.5';
const captionClassName = 'text-xs font-poppinsMedium text-center xl:block md:hidden truncate';
const badgeClassName = [
  'absolute flex items-center justify-center -top-1.5 -right-2.5',
  'bg-red-500 min-w-snug px-1 h-snug leading-snug rounded-full text-white text-badge font-poppinsSemiBold',
].join(' ');
