export const LEVELS = [
  { id: 'Low', value: 'Low', label: 'Low' },
  { id: 'Medium', value: 'Medium', label: 'Medium' },
  { id: 'High', value: 'High', label: 'High' },
];

export const REMINDER_OPTIONS = [
  {
    id: 60,
    label: '1 hour',
  },
  {
    id: 1440,
    label: '1 day',
  },
  {
    id: 10080,
    label: '1 week',
  },
  {
    id: 43800,
    label: '1 month',
  },
  {
    id: 525600,
    label: '1 year',
  },
];

export const COUNTRIES = [
  {
    id: 'us',
    label: 'United States Of America',
  },
  {
    id: 'VE',
    label: 'Venezuela',
  },
];
