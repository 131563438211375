import { Sensitivities } from 'components/sensitivities';
import { useFetch } from 'hooks/useFetch';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { myWatchlistSelector } from 'redux/reducers/companyReducer';
import { Page } from 'types/page';
import { Sensitivity, SensitivityCategory } from 'types/sensitivities';
import settings from 'utils/config/appSettings';
import { toQueryString } from 'utils/toQueryString';

interface Props {
  readonly category: SensitivityCategory;
}

export const WatchlistSensitivities: React.FC<Props> = ({
  category,
}: Props): React.ReactElement => {
  const [urlSearchParams] = useSearchParams();
  const watchlist = useSelector(myWatchlistSelector);

  const fetchUrl = useMemo((): string => {
    const pageNumber = urlSearchParams.get('page_number') ?? '1';
    const query = {
      page_number: pageNumber,
      page_size: settings.defaultPageSize,
      order_by: urlSearchParams.get('order_by'),
    };

    return `watchlist/sensitivities/${category}/${toQueryString(query)}`;
  }, [category, urlSearchParams]);

  const [processingState, page] = useFetch<Page<Sensitivity>>(fetchUrl, emptyPage, 200);

  return (
    <Sensitivities
      category={category}
      processingState={processingState}
      page={page}
      group="watchlist"
      numberOfCompanies={watchlist.length}
    />
  );
};

const emptyPage = Page.empty<any>();
