import { ReactComponent as WarningIcon } from 'assets/icons/svg/exclamation-circle.svg';
import React from 'react';

export const CommentFlag: React.FC = (): React.ReactElement => {
  return (
    <div className="flex items-center justify-center gap-2 px-6 py-2 text-xs text-red-500">
      <WarningIcon className="w-4 h-4" />
      <div>
        Your comment has been flagged as potentially explicit. If you believe this is in error,
        please contact us at{' '}
        <a className="text-red-500 underline normal-case" href="mailto:help@capnote.com">
          help@capnote.com
        </a>{' '}
        and do not post the comment
      </div>
    </div>
  );
};
