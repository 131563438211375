import React from 'react';
import { Stream } from 'types/timeline';
import { LeftPanelDetails } from 'views/SmartTools/TimelineAndEvents/components/streamItem/components/leftPanelDetails';

interface Props {
  readonly stream: Stream;
  readonly variant: 'regular' | 'sponsored';
  readonly selectionButton: React.ReactElement | null;
  readonly addEventButton: React.ReactElement | null;
}

export const LeftPanel: React.FC<Props> = ({
  stream,
  selectionButton,
  addEventButton,
  variant,
}: Props): React.ReactElement => {
  switch (variant) {
    case 'regular':
      return (
        <div className="flex items-start flex-shrink-0 w-96 p-3 border-r border-r-red gap-4 min-h-full">
          <div className="flex items-center justify-center w-6 h-6 mt-0.5">{selectionButton}</div>
          <div className="flex-grow min-w-0">
            <h1 className="text-xl text-gray-darkest truncate overflow-ellipsis mb-3">
              {stream.name}
            </h1>
            <LeftPanelDetails stream={stream} />
          </div>
          {addEventButton}
        </div>
      );
    case 'sponsored':
      return (
        <div className="flex items-center flex-shrink-0 w-96 p-3 border-r border-r-red min-h-full">
          <h1 className="text-xl text-gray-darkest ml-10 truncate overflow-ellipsis">
            {stream.name}
          </h1>
        </div>
      );
    default:
      throw new Error('cannot render this type of left panel');
  }
};
