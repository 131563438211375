import SVGIcon from 'components/icons/SVGIcon';
import React, { ReactElement } from 'react';

interface Props {
  id?: string;
  children: ReactElement;
  onClickDelete: (e: any) => void;
  paddingX?: string;
  paddingY?: string;
  marginTop?: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  bigTag?: boolean;
  className?: string;
}

/**
 * @deprecated
 */
const TagWithDelete: React.FC<Props> = ({
  id,
  children,
  onClickDelete,
  paddingX = 'px-1',
  paddingY = 'py-0.5',
  marginTop = 'mb-0',
  bgColor = 'bg-blue-light',
  borderColor = 'border-blue',
  textColor = 'text-gray-dark',
  bigTag = false,
  className = '',
}) => {
  const tagWithDeleteClass = `
  relative
    ${!bigTag ? 'font-poppinsSemiBold' : 'font-poppins'}
    ${bigTag ? 'text-sm' : 'text-xs'}
    font-poppinsSemiBold
    capitalize
    border
    rounded-5
    flex
    flex-row
    items-center
    ${paddingX} 
    ${paddingY}
    ${marginTop}
    ${textColor}
    ${bgColor} 
    ${borderColor}
    ${className}`;

  return (
    <div className={tagWithDeleteClass}>
      <button onClick={onClickDelete} id={id} className="focus:outline-none outline-none">
        <SVGIcon name="delete-blue-icon" className="relative mr-1 h-2" />
      </button>
      {children}
    </div>
  );
};

export default TagWithDelete;
