import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostBody } from 'components/postBodyInput/types';
import { Page } from 'types/page';
import { ProcessingState } from 'types/processingState';
import {
  DeleteFeedCommentResult,
  FeedCategory,
  FeedFilterCategory,
  FeedItem,
  FeedResponse,
  GenericFeedItem,
  Post,
} from 'views/Home/types';

export interface CreatePostPayload {
  readonly title: string;
  readonly body: PostBody;
  readonly tags: readonly string[];
}

export interface FeedItemFeedbackResponse {
  id: string;
  liked: boolean;
  likes_count: number;
  disliked: boolean;
  dislikes_count: number;
}

export interface FollowResponse {
  id: string;
  post_id: string;
  user_id: string;
  news_id: string;
  category: string;
}

export interface FeedState {
  feed: FeedItem[];
  loading: boolean;
  nextPage: number | null;
  pageSize: number;
  pageNumber: number;
  targetPostId: string | null;

  sharingProcessingState: ProcessingState<any>;
}

const initialState: FeedState = {
  feed: [],
  loading: false,
  nextPage: 1,
  pageSize: 20,
  pageNumber: 0,
  targetPostId: null,
  sharingProcessingState: ProcessingState.idle(),
};

const filterByBookMarksCategory = (feed: FeedItem[], id: string, category: string): FeedItem[] => {
  if (category === FeedFilterCategory.bookmarks) {
    return feed.filter(feedItem => id !== feedItem.id);
  }

  return feed.map(feedItem => (feedItem.id === id ? { ...feedItem, saved: false } : feedItem));
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    // GET
    getFeed: state => {
      state.loading = true;
    },
    resetFeed: (state: FeedState): void => {
      state.feed = initialState.feed;
      state.nextPage = initialState.nextPage;
      state.pageSize = initialState.pageSize;
      state.pageNumber = initialState.pageNumber;
      state.loading = false;
    },
    getFeedSuccess: (state, { payload }: PayloadAction<Page<FeedResponse<any>>>) => {
      const data = payload?.data ?? [];
      const feed: FeedItem[] = data.map(GenericFeedItem.fromJson);

      const pageNumber = payload?.page_number ?? initialState.pageNumber;
      if (pageNumber <= 1) {
        state.feed = feed;
      } else {
        state.feed = [...state.feed, ...feed];
      }
      state.nextPage = payload?.next_page ?? null;
      state.pageSize = payload?.page_size ?? initialState.pageSize;
      state.pageNumber = pageNumber;
      state.loading = false;
    },
    getFeedFailed: state => {
      state.loading = false;
    },
    // Follow/Unfollow Users
    followUserSuccess: (state, { payload }: PayloadAction<FollowResponse>) => {
      state.loading = false;
      state.feed = state.feed.map(f =>
        f.id === payload.post_id ? { ...f, followed: true } : { ...f },
      );
    },
    unFollowUserSuccess: (state, { payload }: PayloadAction<FollowResponse>) => {
      state.loading = false;
      state.feed = state.feed.map(f =>
        f.id === payload.post_id ? { ...f, followed: false } : { ...f },
      );
    },
    // Save Posts
    savePostSuccess: (state, { payload }: PayloadAction<FollowResponse>) => {
      state.loading = false;
      state.feed = state.feed.map(f =>
        f.id === payload.post_id ? { ...f, saved: true } : { ...f },
      );
    },
    undoSavePostSuccess: (state, { payload }: PayloadAction<FollowResponse>) => {
      state.loading = false;
      const { category, post_id } = payload;
      state.feed = filterByBookMarksCategory(state.feed, post_id, category);
    },
    // Save News
    saveNewsSuccess: (state, { payload }: PayloadAction<FollowResponse>) => {
      state.loading = false;
      state.feed = state.feed.map(n =>
        n.id === payload.news_id ? { ...n, saved: true } : { ...n },
      );
    },
    undoSaveNewsSuccess: (state, { payload }: PayloadAction<FollowResponse>) => {
      state.loading = false;
      const { category, news_id } = payload;
      state.feed = filterByBookMarksCategory(state.feed, news_id, category);
    },
    // Creating Post in Feed
    postingStarted: state => {
      state.loading = true;
    },
    postingSuccess: (state, { payload }: PayloadAction<Post>) => {
      state.feed = [payload, ...state.feed];
      state.loading = false;
    },
    postingFailed: state => {
      state.loading = false;
    },
    likePostSuccess: (state, action) => {
      const { id, ...data }: FeedItemFeedbackResponse = action.payload;
      state.feed = state.feed.map(updateFeedItem(FeedCategory.post, id, data));
    },
    dislikePostSuccess: (state, action) => {
      const { id, ...data }: FeedItemFeedbackResponse = action.payload;
      state.feed = state.feed.map(updateFeedItem(FeedCategory.post, id, data));
    },
    likeNewsSuccess: (state, action) => {
      const { id, ...data }: FeedItemFeedbackResponse = action.payload;
      state.feed = state.feed.map(updateFeedItem(FeedCategory.news, id, data));
    },
    dislikeNewsSuccess: (state, action) => {
      const { id, ...data }: FeedItemFeedbackResponse = action.payload;
      state.feed = state.feed.map(updateFeedItem(FeedCategory.news, id, data));
    },
    commentAdded: (state: FeedState, { payload }: PayloadAction<string>): void => {
      state.feed = state.feed.map((item: FeedItem): FeedItem => {
        if (item.id !== payload) {
          return item;
        } else {
          return { ...item, commentsCount: item.commentsCount + 1, commented: true };
        }
      });
    },
    commentDeleted: (
      state: FeedState,
      { payload }: PayloadAction<DeleteFeedCommentResult>,
    ): void => {
      if (!payload) {
        console.warn(`payload not defined!!!`);
        return;
      }

      state.feed = state.feed.map((post: FeedItem): FeedItem => {
        if (post.id === payload.id) {
          return {
            ...post,
            commentsCount: payload.children_count,
            commented: payload.participated,
          };
        } else {
          return post;
        }
      });
    },
    deletingPostStarted: (state: FeedState, { payload }: PayloadAction<string>): void => {
      state.targetPostId = payload;
    },
    deletingPostSucceeded: (state: FeedState, { payload }: PayloadAction<string>): void => {
      const { feed } = state;
      state.feed = feed.filter((item: FeedItem): boolean => item.id !== payload);
    },
    deletingPostCompleted: (state: FeedState): void => {
      state.targetPostId = null;
    },
    postUserFollowed: (state: FeedState, { payload }: PayloadAction<string>): void => {
      state.feed = updateFollowedByInFeed(state.feed, payload, false);
    },
    postUserUnfollowed: (state: FeedState, { payload }: PayloadAction<string>): void => {
      state.feed = updateFollowedByInFeed(state.feed, payload, true);
    },
    postReported: (state: FeedState, { payload }: PayloadAction<string>): void => {
      const { feed } = state;

      state.feed = feed.map((post: FeedItem): FeedItem => {
        if (post.id === payload) {
          return { ...post, reported: true };
        } else {
          return post;
        }
      });
    },
    sharingStarted: (state: FeedState): void => {
      state.sharingProcessingState = ProcessingState.processing();
    },
    sharingSucceeded: (state: FeedState): void => {
      state.sharingProcessingState = ProcessingState.success();
    },
    sharingFailed: (state: FeedState): void => {
      state.sharingProcessingState = ProcessingState.error();
    },
    resetSharingProcessingState: (state: FeedState): void => {
      state.sharingProcessingState = ProcessingState.idle();
    },
  },
});

const updateFollowedByInFeed = (
  feed: readonly FeedItem[],
  targetItemId: string,
  value: boolean,
): FeedItem[] => {
  return feed.map((item: FeedItem): FeedItem => {
    if (item.id !== targetItemId) {
      return item;
    } else if (typeof item.publisher !== 'string') {
      return { ...item, publisher: { ...item.publisher, followed_by_you: value } };
    } else {
      return item;
    }
  });
};

export const feedSelector = (state: { feedReducer: FeedState }) => state.feedReducer.feed;

export const loadingFeedSelector = (state: { feedReducer: FeedState }) => state.feedReducer.loading;

export const feedPaginationSelector = (state: {
  feedReducer: FeedState;
}): Pick<FeedState, 'nextPage' | 'pageSize' | 'pageNumber'> => state.feedReducer;

export const deleteIdSelector = (state: { feedReducer: FeedState }) =>
  state.feedReducer.targetPostId;

export const sharingProcessingStateSelector = (state: {
  feedReducer: FeedState;
}): ProcessingState<any> => state.feedReducer.sharingProcessingState;

export const {
  // GET
  resetFeed,
  getFeed,
  getFeedSuccess,
  getFeedFailed,
  savePostSuccess,
  undoSavePostSuccess,
  saveNewsSuccess,
  undoSaveNewsSuccess,
  postingStarted,
  postingSuccess,
  postingFailed,
  likePostSuccess,
  dislikePostSuccess,
  likeNewsSuccess,
  dislikeNewsSuccess,
  commentAdded,
  commentDeleted,
  deletingPostStarted,
  deletingPostSucceeded,
  deletingPostCompleted,
  postReported,
  postUserUnfollowed,
  postUserFollowed,

  sharingSucceeded,
  sharingFailed,
  sharingStarted,
  resetSharingProcessingState,
} = feedSlice.actions;

export default feedSlice.reducer;

const updateFeedItem =
  (
    category: FeedCategory,
    id: string,
    data: Omit<FeedItemFeedbackResponse, 'id'>,
  ): ((item: FeedItem) => FeedItem) =>
  (item: FeedItem): FeedItem => {
    if (item.category === category && item.id === id) {
      return {
        ...item,
        liked: data.liked,
        likesCount: data.likes_count,
        disliked: data.disliked,
        dislikesCount: data.dislikes_count,
      };
    } else {
      return item;
    }
  };
