import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  readonly clickable: boolean;
  readonly data: {
    readonly name: string;
    readonly id: string;
  };
}

export const CompanyLink: React.FC<Props> = ({ data, clickable }: Props): React.ReactElement => {
  if (clickable) {
    return (
      <Link className={companyNameCellClassName} to={`/data-analysis/company/details/${data.id}`}>
        {data.name}
      </Link>
    );
  } else {
    return <div className={companyNameCellClassName}>{data.name}</div>;
  }
};

const companyNameCellClassName =
  'flex items-center font-poppinsSemiBold text-blue h-full w-64 px-2 py-2 border-b border-divider';
