export enum EventType {
  added = 'added',
  removed = 'removed',
}

export const createEventName = (parentId: string, eventType: EventType): string => {
  return `${parentId}-${eventType}`;
};

export function emit(parentId: string, eventType: EventType.removed, count: number): void;
export function emit(parentId: string, eventType: EventType.added): void;

export function emit(parentId: string, eventType: EventType, count?: number): void {
  const event = new CustomEvent(createEventName(parentId, eventType), {
    detail: count,
  });

  document.dispatchEvent(event);
}

export const listenTo = (
  targetId: string,
  parentId: string | null,
  eventType: EventType,
  handler: (event: Event) => void,
): VoidFunction => {
  const eventName = createEventName(targetId, eventType);

  const handleAndPropagate = (event: Event): void => {
    const customEvent = event as CustomEvent<number>;

    if (parentId) {
      switch (eventType) {
        case EventType.added:
          emit(parentId, eventType);
          break;
        case EventType.removed:
          emit(parentId, eventType, customEvent.detail);
          break;
      }
    }

    handler(event);
  };

  document.addEventListener(eventName, handleAndPropagate);
  return (): void => {
    document.removeEventListener(eventName, handleAndPropagate);
  };
};
