import React from 'react';
import { Link } from 'react-router-dom';

export enum LinkerType {
  company = 'company',
  industry = 'industry',
}

export interface LinkerObject {
  readonly name: string;
  readonly id: string;
  readonly type: LinkerType;
}

interface Props {
  readonly link: LinkerObject;
}

export const NavLink: React.FC<Props> = ({ link }: Props): React.ReactElement => {
  const { name, id, type } = link;
  return (
    <Link
      className="normal-case hover:text-blue font-poppins text-blue"
      to={linkTypeToPathMap[type] + id}
    >
      {name}
    </Link>
  );
};

export const isLinkerObject = (linker: any): linker is LinkerObject => {
  if (!linker || typeof linker !== 'object') {
    return false;
  }

  return (
    'id' in linker &&
    'name' in linker &&
    'type' in linker &&
    (linker.type === 'company' || linker.type === 'industry')
  );
};

const linkTypeToPathMap: { [_ in LinkerType]: string } = {
  company: '/data-analysis/company/details/',
  industry: '/data-analysis/industry/details/',
};
