import { formatDistance } from 'date-fns';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_STORAGE_KEY, setProbablyAuthenticated } from 'redux/reducers/authReducer';

export const TokenManager: React.FC<React.PropsWithChildren> = (
  props: React.PropsWithChildren,
): React.ReactElement => {
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect((): VoidFunction | void => {
    setInitialized(true);

    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (token !== null) {
      const decodedToken = token //
        .split('.')
        .slice(0, -1)
        .map(atob)
        .map((item: string): any => JSON.parse(item));

      const expiresAt = decodedToken[1]?.exp ?? 0;
      const remainingSeconds = expiresAt - Math.floor(Date.now() / 1000);

      if (remainingSeconds > 0) {
        // TODO: validate token
        dispatch(setProbablyAuthenticated());

        const timeout = setTimeout((): void => {
          // TODO: maybe refresh token?
          localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
          navigate('/');
        }, 1000 * remainingSeconds);

        const distance = formatDistance(0, 1000 * remainingSeconds, {
          includeSeconds: true,
        });

        console.info(`Session will expire in: ${distance}`);

        return (): void => {
          clearTimeout(timeout);
        };
      }
    }
  }, [dispatch, navigate]);

  if (!initialized) {
    // TODO: show something? like a loading screen?
    return <></>;
  }

  return <>{props.children}</>;
};
