import { MainOutletContent } from 'components/mainOutletContent';
import React from 'react';
import { ViewSelector } from 'views/Checkout/SubscriptionCheckout/viewSelector';

export const SubscriptionCheckout: React.FC = (): React.ReactElement => {
  return (
    <MainOutletContent>
      <ViewSelector />
    </MainOutletContent>
  );
};
