import 'components/radioInput/RadioInput.scss';

import React from 'react';

interface Props {
  label: string;
  checked: boolean;
  value: string | number;
  className?: string;
  innerClassName?: string;
  id: string;
  disabled?: boolean;

  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const RadioInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  checked,
  className = '',
  innerClassName = '',
  id,
  disabled = false,
}) => {
  return (
    <div
      className={`flex items-center ${className} ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }`}
    >
      <input
        id={id}
        type="radio"
        value={value}
        name="radio"
        onChange={onChange}
        className={`hidden`}
        checked={checked}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className={`flex items-center text-gray text-base ${
          checked ? 'font-poppinsSemiBold' : 'font-poppins'
        } ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
      >
        <span className="w-5 h-5 inline-block mr-3 rounded-full border border-gray-darkest" />
        <p className={innerClassName}>{label}</p>
      </label>
    </div>
  );
};

export default RadioInput;
