import beta from 'utils/config/firebase-beta.json';
import develop from 'utils/config/firebase-develop.json';
import local from 'utils/config/firebase-local.json';

export interface FirebaseAppConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface FirebaseConfig {
  configuration: FirebaseAppConfig;
  vapidKey: string;
}

const configuration = (): FirebaseAppConfig => {
  switch (process.env.REACT_APP_BITBUCKET_BRANCH) {
    case 'local':
      return local;
    case 'develop':
    case 'staging':
      return develop;
    case 'beta':
      return beta;
    default:
      return {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
      };
  }
};

const vapidKey = (): string => {
  switch (process.env.REACT_APP_BITBUCKET_BRANCH) {
    case 'local':
      return 'BBpTU_WwCuiNIHv4Q42rNyYnx9EhU2MO5JT23K6ZmL7Tga9PrkAytbKTclZHHR-gcpDWoKvnQe31jOFaqxWV3vI';
    case 'develop':
      return 'BBpTU_WwCuiNIHv4Q42rNyYnx9EhU2MO5JT23K6ZmL7Tga9PrkAytbKTclZHHR-gcpDWoKvnQe31jOFaqxWV3vI';
    case 'staging':
      return 'BBpTU_WwCuiNIHv4Q42rNyYnx9EhU2MO5JT23K6ZmL7Tga9PrkAytbKTclZHHR-gcpDWoKvnQe31jOFaqxWV3vI';
    case 'beta':
      return 'BAwmVG9kfm3ltFakFRgI4RxNyiUqeglqyGVSCUYX-gxj1QcDZ0RNDJ2EstE69toDFMk6ksP9-XrohCWPcZCA8T4';
    default:
      return '';
  }
};

const firebaseConfig: FirebaseConfig = {
  configuration: configuration(),
  vapidKey: vapidKey(),
};

export default firebaseConfig;
