import { ConfirmationBox } from 'components/confirmationBox';
import React, { useCallback, useState } from 'react';

interface Props {
  onClose(): void;
  onYes(reason: string): void;
  message: string;
}

export const CancelConsentModal: React.FC<Props> = ({
  onClose,
  onYes,
  message,
}: Props): React.ReactElement => {
  const [cancelReason, setCancelReason] = useState<string>('');

  const handleCancelReasonChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      const { value } = event.target;
      setCancelReason(value);
    },
    [],
  );

  const handleYes = useCallback((): void => {
    onYes(cancelReason.trim());
  }, [cancelReason, onYes]);

  return (
    <ConfirmationBox onNo={onClose} onYes={handleYes}>
      <h6 className="text-gray text-md">{message}</h6>
      <div className="mt-4">
        <label htmlFor="reason" className="block text-base font-poppinsMedium mb-2">
          Why are you canceling your subscription?
        </label>
        <textarea
          id="reason"
          autoFocus={true}
          name="reason"
          value={cancelReason}
          className="block font-poppins text-base w-full h-24 p-2 outline-none resize-none rounded-md"
          onChange={handleCancelReasonChange}
        />
      </div>
    </ConfirmationBox>
  );
};
