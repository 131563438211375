import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionInvoice } from 'redux/reducers/authReducer';
import { AccountType } from 'types/accountType';
import { Page } from 'types/page';
import { ActivableComboBoxItem, ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';

export interface PersonalInformationModalState {
  firstName: string;
  lastName: string;
  accountType: AccountType;
  about: string | null;
  country: number;
  city: string | null;
  adminZone: number | null;
  accreditedInvestor: boolean;
  connectionToFinance: string | null;
  additionalTopicsOfInterest: string[];
  topicsOfInterest: number[];
  deletedTopicsOfInterest: Array<ActivableComboBoxItem<number>>;
  regionsOfInterest: number[];
  linkedinUrl: string | null;
  websiteUrl: string | null;
}

export interface UserPermission {
  readonly code: string;
  readonly name: string;
  readonly description: string;
  readonly granted: boolean;
}

export interface Preferences {
  readonly permissions: readonly UserPermission[];
}

export interface ProfileState {
  loadingData: boolean;
  loadingTypeAccount: boolean;

  editPersonalInformationModalState: PersonalInformationModalState;

  preferences: Preferences;
  updatingPreferences: boolean;
  submittingEditForm: boolean;

  // Other user's profile
  userProfile: ProfileResponse;
  deletingAccount: boolean;

  uploadingPhoto: boolean;

  permissionCodeBeingUpdated: string | null;
  lastError: Error | null;

  subscriptionPlansHistory: Page<SubscriptionInvoice>;
  subscriptionPlansHistoryLoading: boolean;
}

export interface UserTopicsOfInterestResponse {
  readonly topics_of_interest: number[];
  readonly additional_topics_of_interest: string[];
  readonly deleted_topics_of_interest: Array<ComboBoxItem<number>>;
}

export interface UserRegionsOfInterestResponse {
  readonly regions_of_interest: number[];
}

export interface ProfileResponsePersonalInformation {
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly account_type: string;
}

export interface ProfileResponseAbout {
  readonly about_me: string;
  readonly connection_to_finance: string;
  readonly accredited_investor: boolean;
  readonly country: number;
  readonly city: string;
  readonly state: number | null;
  readonly linkedin_url?: any;
  readonly website_url?: any;
}

export interface ProfileResponseIndustry {
  readonly id: string;
  readonly name: string;
}

export interface ProfileResponseJobInformation {
  readonly career_level: string;
  readonly recent_job: string;
  readonly recent_company: string;
  readonly industry: ProfileResponseIndustry;
  readonly job_position_start_date: string;
  readonly job_position_end_date?: string;
  readonly certifications: string[];
}

export interface ProfileResponse {
  readonly personal_information: ProfileResponsePersonalInformation;
  readonly about: ProfileResponseAbout;
  readonly job_information: ProfileResponseJobInformation;
  readonly regions_of_interest: string[];
  readonly industries_of_interest: string[];
  readonly topics_of_interest: string[];
  readonly additional_topics_of_interest: string[];
  readonly followed_by_you?: boolean;
  readonly follows_you?: boolean;
  readonly photo_url?: string;
}

const emptyProfile = {
  about: {
    about_me: '',
    accredited_investor: false,
    city: '',
    connection_to_finance: '',
    country: -1,
    state: null,
    linkedin_url: '',
    website_url: '',
  },
  job_information: {
    career_level: '',
    certifications: [],
    industry: { id: '', name: '' },
    job_position_end_date: '',
    job_position_start_date: '',
    recent_company: '',
    recent_job: '',
  },
  personal_information: {
    account_type: '',
    email: '',
    first_name: '',
    last_name: '',
  },
  additional_topics_of_interest: [],
  topics_of_interest: [],
  regions_of_interest: [],
  industries_of_interest: [],
};

const initialState: ProfileState = {
  preferences: {
    permissions: [],
  },
  uploadingPhoto: false,
  deletingAccount: false,
  updatingPreferences: false,
  userProfile: emptyProfile,
  loadingData: false,
  loadingTypeAccount: false,
  permissionCodeBeingUpdated: null,
  lastError: null,
  editPersonalInformationModalState: {
    firstName: '',
    lastName: '',
    accountType: AccountType.public,
    about: null,
    country: -1,
    city: null,
    adminZone: null,
    additionalTopicsOfInterest: [],
    topicsOfInterest: [],
    deletedTopicsOfInterest: [],
    regionsOfInterest: [],
    accreditedInvestor: false,
    connectionToFinance: null,
    linkedinUrl: null,
    websiteUrl: null,
  },
  submittingEditForm: false,
  subscriptionPlansHistory: Page.empty(),
  subscriptionPlansHistoryLoading: false,
};

export interface ProfileResponse {
  [key: string]: any;
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileData: (state: ProfileState): void => {
      state.loadingData = true;
    },
    getProfileDataSuccess: (
      state: ProfileState,
      { payload }: PayloadAction<ProfileResponse>,
    ): void => {
      state.loadingData = false;
      state.userProfile = payload;
      state.editPersonalInformationModalState = {
        about: payload.about.about_me,
        accountType: payload.personal_information.account_type as AccountType,
        accreditedInvestor: payload.about.accredited_investor,
        additionalTopicsOfInterest: payload.additional_topics_of_interest,
        city: payload.about.city,
        adminZone: payload.about.state,
        connectionToFinance: payload.about.connection_to_finance,
        country: payload.about.country,
        firstName: payload.personal_information.first_name,
        lastName: payload.personal_information.last_name,
        linkedinUrl: payload.about.linkedin_url,
        regionsOfInterest: [],
        topicsOfInterest: [],
        deletedTopicsOfInterest: [],
        websiteUrl: '',
      };
    },
    getProfileDataFailed: (state: ProfileState): void => {
      state.loadingData = false;
    },
    // SET TYPE ACCOUNT
    setTypeAccount: (state: ProfileState): void => {
      state.loadingTypeAccount = true;
    },
    setTypeAccountSuccess: (state: ProfileState, { payload }): void => {
      const { userProfile } = state;

      state.loadingTypeAccount = false;

      if (state.userProfile) {
        state.userProfile = {
          ...userProfile,
          personal_information: {
            ...userProfile.personal_information,
            account_type: payload,
          },
        };
      }
    },
    setTypeAccountFailed: state => {
      state.loadingTypeAccount = false;
    },
    submitPersonalInformationStarted: (state: ProfileState): void => {
      state.submittingEditForm = true;
    },
    submitPersonalInformationCompleted: (state: ProfileState): void => {
      state.submittingEditForm = false;
    },
    updatePersonalInformationForm: (
      state: ProfileState,
      { payload }: PayloadAction<Partial<PersonalInformationModalState>>,
    ): void => {
      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        ...payload,
      };
    },
    setEditPersonalInformationModalAdditionalTopicsOfInterest: (
      state: ProfileState,
      { payload }: PayloadAction<string[]>,
    ): void => {
      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        additionalTopicsOfInterest: payload,
      };
    },
    setEditPersonalInformationModalRegionsOfInterest: (
      state: ProfileState,
      { payload }: PayloadAction<number[]>,
    ): void => {
      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        regionsOfInterest: payload,
      };
    },
    setEditPersonalInformationModalTopicsOfInterest: (
      state: ProfileState,
      { payload }: PayloadAction<number[]>,
    ): void => {
      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        topicsOfInterest: payload,
      };
    },
    setEditPersonalInformationModalDeletedTopicsOfInterest: (
      state: ProfileState,
      { payload }: PayloadAction<Array<ActivableComboBoxItem<number>>>,
    ): void => {
      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        deletedTopicsOfInterest: payload,
      };
    },
    getUserTopicsOfInterestSuccess: (
      state: ProfileState,
      { payload }: PayloadAction<UserTopicsOfInterestResponse>,
    ): void => {
      const { topics_of_interest, additional_topics_of_interest, deleted_topics_of_interest } =
        payload;

      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        topicsOfInterest: topics_of_interest,
        additionalTopicsOfInterest: additional_topics_of_interest,
        deletedTopicsOfInterest: deleted_topics_of_interest.map(
          (item: ComboBoxItem<number>): ActivableComboBoxItem<number> => ({
            ...item,
            active: true,
          }),
        ),
      };
    },
    getUserRegionsOfInterestSuccess: (
      state: ProfileState,
      { payload }: PayloadAction<UserRegionsOfInterestResponse>,
    ): void => {
      state.editPersonalInformationModalState = {
        ...state.editPersonalInformationModalState,
        regionsOfInterest: payload.regions_of_interest,
      };
    },
    getPreferencesSucceeded: (
      state: ProfileState,
      { payload }: PayloadAction<Preferences>,
    ): void => {
      state.preferences = payload;
    },
    getUserProfile: (state: ProfileState): void => {
      state.loadingData = true;
      state.userProfile = emptyProfile;
    },
    getUserProfileSucceeded: (
      state: ProfileState,
      { payload }: PayloadAction<ProfileResponse>,
    ): void => {
      state.loadingData = false;
      state.userProfile = payload;
    },
    getUserProfileFailed: (state: ProfileState): void => {
      state.loadingData = false;
    },
    setProfileUserFollowedFlag: (state: ProfileState, { payload }: PayloadAction<string>): void => {
      const { userProfile } = state;
      // FIXME: this is the user id, we probably need to check if it matches
      void payload;

      state.userProfile = { ...userProfile, followed_by_you: true };
    },
    unsetProfileUserFollowedFlag: (
      state: ProfileState,
      { payload }: PayloadAction<string>,
    ): void => {
      const { userProfile } = state;
      // FIXME: this is the user id, we probably need to check if it matches
      void payload;

      state.userProfile = { ...userProfile, followed_by_you: false };
    },
    deleteUserAccountStarted: (state: ProfileState): void => {
      state.deletingAccount = true;
    },
    deleteUserAccountFailed: (state: ProfileState, { payload }: PayloadAction<Error>): void => {
      state.deletingAccount = false;
      console.warn(payload);
    },
    deleteUserAccountSucceeded: (state: ProfileState): void => {
      state.deletingAccount = false;
    },
    uploadPhotoStarted: (state: ProfileState): void => {
      state.uploadingPhoto = true;
    },
    uploadPhotoSucceeded: (state: ProfileState): void => {
      state.uploadingPhoto = false;
    },
    uploadPhotoFailed: (state: ProfileState, { payload: _ }: PayloadAction<any>): void => {
      state.uploadingPhoto = false;
    },

    updatePermissionsStarted: (state: ProfileState, { payload }: PayloadAction<string>): void => {
      state.permissionCodeBeingUpdated = payload;
    },
    updatePermissionsFailed: (state: ProfileState, { payload }: PayloadAction<Error>): void => {
      state.permissionCodeBeingUpdated = null;
      state.lastError = payload;
    },
    updatePermissionsSucceeded: (
      state: ProfileState,
      { payload }: PayloadAction<UserPermission>,
    ): void => {
      const { permissions } = state.preferences;

      state.preferences = {
        ...state.preferences,
        permissions: permissions.map((permission: UserPermission): UserPermission => {
          if (permission.code === payload.code) {
            return payload;
          } else {
            return permission;
          }
        }),
      };
      state.permissionCodeBeingUpdated = null;
      state.lastError = null;
    },
    fetchingSubscriptionPlansHistoryStarted: (state: ProfileState): void => {
      state.subscriptionPlansHistoryLoading = true;
      state.subscriptionPlansHistory = Page.empty();
    },
    fetchingSubscriptionPlansHistorySucceeded: (
      state: ProfileState,
      { payload }: PayloadAction<Page<SubscriptionInvoice>>,
    ): void => {
      state.subscriptionPlansHistoryLoading = false;
      state.subscriptionPlansHistory = payload;
    },
    fetchingSubscriptionPlansHistoryFailed: (state: ProfileState): void => {
      state.subscriptionPlansHistoryLoading = false;
    },
  },
});

// FIXME: should move to another reducer (shared state or something)
export const userProfileSelector = (state: { profileReducer: ProfileState }) =>
  state.profileReducer.userProfile;
export const loadingDataSelector = (state: { profileReducer: ProfileState }) =>
  state.profileReducer.loadingData;

export const profilePersonalInformationModalStateSelector = (state: {
  profileReducer: ProfileState;
}): PersonalInformationModalState => state.profileReducer.editPersonalInformationModalState;
export const profilePreferencesSelector = (state: { profileReducer: ProfileState }): Preferences =>
  state.profileReducer.preferences;
export const updatingPreferencesSelector = (state: { profileReducer: ProfileState }): boolean =>
  state.profileReducer.updatingPreferences;
export const submittingEditFormSelector = (state: { profileReducer: ProfileState }): boolean =>
  state.profileReducer.submittingEditForm;
export const deletingAccountSelector = (state: { profileReducer: ProfileState }): boolean =>
  state.profileReducer.deletingAccount;
export const permissionCodeBeingUpdatedSelector = (state: {
  profileReducer: ProfileState;
}): string | null => state.profileReducer.permissionCodeBeingUpdated;
export const subscriptionPlansHistoryLoadingSelector = (state: {
  profileReducer: ProfileState;
}): boolean => state.profileReducer.subscriptionPlansHistoryLoading;

export const subscriptionPlansHistorySelector = (state: {
  profileReducer: ProfileState;
}): Page<SubscriptionInvoice> => state.profileReducer.subscriptionPlansHistory;

export const {
  getProfileData,
  getProfileDataSuccess,
  getProfileDataFailed,
  getUserTopicsOfInterestSuccess,
  getUserRegionsOfInterestSuccess,
  setEditPersonalInformationModalRegionsOfInterest,
  setEditPersonalInformationModalTopicsOfInterest,
  setEditPersonalInformationModalAdditionalTopicsOfInterest,
  setEditPersonalInformationModalDeletedTopicsOfInterest,
  updatePersonalInformationForm,
  submitPersonalInformationStarted,
  submitPersonalInformationCompleted,
  getPreferencesSucceeded,
  deleteUserAccountStarted,
  deleteUserAccountFailed,
  deleteUserAccountSucceeded,
  getUserProfile,
  getUserProfileFailed,
  getUserProfileSucceeded,
  setProfileUserFollowedFlag,
  unsetProfileUserFollowedFlag,

  updatePermissionsStarted,
  updatePermissionsFailed,
  updatePermissionsSucceeded,

  fetchingSubscriptionPlansHistoryStarted,
  fetchingSubscriptionPlansHistorySucceeded,
  fetchingSubscriptionPlansHistoryFailed,
} = profileSlice.actions;

export default profileSlice.reducer;
