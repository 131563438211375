import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export interface CurrencyState {
  currencies: Array<{ id: string; name: string }>;
  loading: boolean;
  error: string;
}

const initialState: CurrencyState = {
  currencies: [],
  loading: false,
  error: '',
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    // PORTFOLIO GENERAL LAYOUT
    getCurrencies: state => {
      state.loading = true;
    },
    getCurrenciesSuccess: (state, { payload }) => {
      state.currencies = payload;
      state.loading = false;
      state.error = '';
    },
    getCurrenciesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Clears Error State
    clearErrors: state => {
      state.error = '';
    },
  },
});

export const currenciesSelector = (state: { currencyReducer: CurrencyState }) =>
  state.currencyReducer.currencies;

export const { getCurrencies, getCurrenciesSuccess, getCurrenciesFailed, clearErrors } =
  currencySlice.actions;

export const getCurrenciesAction = createAPIAction(function getCurrenciesGenerator(
  client: HttpClient,
): ActionsGenerator {
  return async function* (): AsyncGenerator<AnyAction> {
    yield getCurrencies();
    const response = await client.GET(`${API_V1_PATH}/currencies/`);
    if (response.status === 200) {
      yield getCurrenciesSuccess(response.data);
    }
  };
});

export default currencySlice.reducer;
