import React, { useMemo } from 'react';
import { formatTime, Time } from 'types/time';

interface Props {
  readonly startTime: Time | null;
  readonly endTime: Time | null;
}

export const TimespanView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { startTime, endTime } = props;

  const formattedStartTime = useMemo((): string | null => formatTime(startTime), [startTime]);
  const formattedEndTime = useMemo((): string | null => formatTime(endTime), [endTime]);

  if (formattedStartTime === null) {
    if (formattedEndTime !== null) {
      throw new Error('end time cannot be null, if start time is null');
    } else {
      return <div className="font-poppins text-gray text-xs normal-case">Whole day</div>;
    }
  } else if (formattedEndTime === null) {
    return <div className="font-poppins text-gray text-xs">{formattedStartTime}</div>;
  } else {
    return (
      <div className="font-poppins text-gray text-xs">
        {formattedStartTime} - {formattedEndTime}
      </div>
    );
  }
};
