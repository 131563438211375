import { AnyAction } from '@reduxjs/toolkit';
import {
  fetchRevenuesFailed,
  fetchRevenuesSucceeded,
  fetchSubsidiariesFailed,
  fetchSubsidiariesStarted,
  fetchSubsidiariesSucceeded,
} from 'redux/reducers/geographiesAndMapsReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';

export const fetchSubsidiariesMap = createAPIAction(function fetchSubsidiariesMAp(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (companyId: string): AsyncGenerator<AnyAction> {
    yield fetchSubsidiariesStarted();
    try {
      const response = await httpClient.GET(`api/v1/companies/${companyId}/locations/subsidiaries`);
      yield fetchSubsidiariesSucceeded(response.data);
    } catch (error: any) {
      yield fetchSubsidiariesFailed(error);
    }
  };
});

export const fetchRevenuesMap = createAPIAction(function fetchSubsidiariesMAp(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (companyId: string): AsyncGenerator<AnyAction> {
    yield fetchSubsidiariesStarted();
    try {
      const response = await httpClient.GET(`api/v1/companies/${companyId}/locations/revenues`);
      yield fetchRevenuesSucceeded(response.data);
    } catch (error: any) {
      yield fetchRevenuesFailed(error);
    }
  };
});
