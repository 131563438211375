import { Dispatch } from '@reduxjs/toolkit';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import {
  getNotifications,
  getNotificationsFailed,
  getNotificationsSuccess,
  getSingleNotificationFailed,
  getSingleNotificationStarted,
  getSingleNotificationSuccess,
  markAsSeenSuccess,
  Notification,
} from 'redux/reducers/notificationsReducer';
import {
  getSummaryNotifications,
  getSummaryNotificationsFailure,
  getSummaryNotificationsSuccess,
  markBellNotificationsAsSeen,
  NotificationMetadata,
  NotificationType,
  showInfoModal,
} from 'redux/reducers/sharedReducer';
import { Page } from 'types/page';
import settings from 'utils/config/appSettings';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';

export const getNotificationsService =
  (parameters: Record<string, string>): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getNotifications());

    try {
      const params = { page_number: 1, ...parameters, page_size: settings.defaultPageSize };
      const response = await api.get(`${API_V1_PATH}/notifications/`, { params });
      if (response.status === HttpStatusOk) {
        const { data } = response;

        const notifications = Page.map(data, Notification.fromJson);
        dispatch(getNotificationsSuccess(notifications));
      } else {
        dispatch(getNotificationsFailed());
      }
    } catch (err: any) {
      dispatch(getNotificationsFailed());
      dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
    }
  };

export const getSummaryNotificationsAction =
  (): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getSummaryNotifications());
    try {
      const response = await api.get(`${API_V1_PATH}/notifications/summary`);
      if (response.status === HttpStatusOk) {
        const { data } = response;

        const notifications = data.map(Notification.fromJson);
        dispatch(getSummaryNotificationsSuccess(notifications));
      } else {
        dispatch(getSummaryNotificationsFailure());
      }
    } catch (err: any) {
      dispatch(getSummaryNotificationsFailure());
      dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
    }
  };

export const markAllNotificationsAsSeenAction =
  (): ((dispatch: Dispatch) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await api.post(`${API_V1_PATH}/notifications/mark-all-as-seen/`);
      if (response.status == 200) {
        dispatch(markBellNotificationsAsSeen(null));
        dispatch(markAsSeenSuccess(null));
      }
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
    }
  };

export const getUrlText = (meta?: NotificationMetadata): string | undefined => {
  switch (meta?.type) {
    case NotificationType.Portfolio:
      return meta.label;
    case NotificationType.Organization:
      return meta.label;
    case NotificationType.Post:
      return 'Post';
    case NotificationType.Comment:
      return 'Comment';
    case NotificationType.Connections:
      return meta.follower_name;
    case NotificationType.Event:
      if (meta.stream_id) {
        return 'Events stream';
      } else if (meta.event_id) {
        return 'Event';
      }
      break;
    case NotificationType.News:
      return 'Read recent news';
    case NotificationType.UserTag:
      return meta.object_type;
    default:
      return undefined;
  }

  return undefined;
};

export const getNotificationUrl = (meta?: NotificationMetadata): string | null => {
  switch (meta?.type) {
    case NotificationType.Connections:
      return `/users/${meta.follower_id}`;
    case NotificationType.Portfolio:
      return `/portfolios/details/${meta.id}`;
    case NotificationType.Organization:
      return `/data-analysis/company/details/${meta.id}`;
    case NotificationType.Post:
      return `/feed/${meta.post_id}/comments/${meta.comment_id}`;
    case NotificationType.Comment:
      return `/feed/${meta.post_id}/comments/${meta.comment_id}`;
    case NotificationType.Event:
      if (meta.stream_id) {
        return `/timeline?stream=${meta.stream_id}`;
      } else if (meta.event_id) {
        return `/timeline?event=${meta.event_id}`;
      }
      break;
    case NotificationType.UserTag:
      switch (meta.object_type) {
        case 'Post':
          return `/feed/${meta.post_id}`;
        case 'Comment':
          return `/feed/${meta.post_id}/comments/${meta.comment_id}`;
        default:
          return null;
      }
    case NotificationType.News:
      return `/?keyword=${combine(meta.filter)}`;
    default:
      return null;
  }

  return null;
};

const combine = (filters: readonly string[]): string => {
  return filters.join(' ');
};

export const getNotification =
  (id: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getSingleNotificationStarted());
    const params = { page_number: 1, page_size: 1 };

    try {
      const response = await api.get(`${API_V1_PATH}/notifications/${id}`, { params });
      if (response.status === HttpStatusOk) {
        const { data } = response;
        dispatch(getSingleNotificationSuccess(Notification.fromJson(data)));
      } else {
        dispatch(getSingleNotificationFailed());
      }
    } catch (err: any) {
      dispatch(getSingleNotificationFailed());
      dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
    }
  };
