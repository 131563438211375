import axios from 'axios';
import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import useOldTour from 'hooks/useOldTour';
import { useQueryParameters } from 'hooks/useQueryParameters';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { companiesSelector } from 'redux/reducers/companyReducer';
import { getCompaniesService } from 'redux/services/companyService';
import { DataAndAnalysisMainTabs, useDataAndAnalysisMainTab } from 'types/dataAndAnalysisMainTabs';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import CompaniesTable from 'views/DataAnalysis/Company/companiesTable';
import tourConfig from 'views/DataAnalysis/Company/tourConfig';

const CompanyList: React.FC = (): React.ReactElement => {
  const companies = useSelector(companiesSelector);
  const dispatch = useDispatch<any>();
  const currentTab = useDataAndAnalysisMainTab();
  const queryParameters = useQueryParameters();

  useEffect(() => {
    mixpanel.track('PageView D&A Company List');
  }, []);

  useEffect((): void | VoidFunction => {
    const tokenSource = axios.CancelToken.source();
    // FIXME: instead of using this method we probably need to create
    //        separate components
    dispatch(getCompaniesService(currentTab, tokenSource.token, queryParameters));

    return (): void => {
      tokenSource.cancel();
    };
  }, [currentTab, dispatch, queryParameters]);

  const [_, tour] = useOldTour(tourConfig);

  const textLineContent = useMemo(
    (): React.ReactElement | string => getTextLineContent(currentTab),
    [currentTab],
  );

  return (
    <MainOutletContent>
      {tour}
      <Breadcrumbs path={['DATA & ANALYSIS', 'COMPANY']} />
      <TextLine content={textLineContent} />
      <div className="h-2" />

      <Tabs shrinkFactor={6} variant={TabsVariant.buttons}>
        <Tab
          label="All Companies"
          element={
            <CompaniesTable
              tab={DataAndAnalysisMainTabs.All}
              page={companies}
              label="All Companies"
            />
          }
        />
        {process.env.REACT_APP_DATA_PORTAL ? null : (
          <Tab
            label="My Companies"
            element={
              <CompaniesTable
                tab={DataAndAnalysisMainTabs.My}
                page={companies}
                label="My Companies"
              />
            }
            path="my-list"
          />
        )}
        <Tab
          label="Popular Companies"
          element={
            <CompaniesTable
              tab={DataAndAnalysisMainTabs.Popular}
              page={companies}
              label="Popular Companies"
            />
          }
          path="popular"
        />
      </Tabs>
    </MainOutletContent>
  );
};

export default CompanyList;

const getTextLineContent = (currentTab: DataAndAnalysisMainTabs): React.ReactElement | string => {
  switch (currentTab) {
    case DataAndAnalysisMainTabs.Popular:
      return 'This is a selection of popular and topical companies from across the world that you may find useful as a quick starting point.';
    case DataAndAnalysisMainTabs.All:
      return 'This is a long list of all the companies available on Capnote. Use the filters.ts to narrow down the list.';
    case DataAndAnalysisMainTabs.My:
      return (
        <>
          Companies in your Watchlist or Portfolio show up here. Click on{' '}
          <Link className="text-blue font-poppinsMedium" to="/watchlist">
            Watchlist
          </Link>{' '}
          or{' '}
          <Link className="text-blue font-poppinsMedium" to="/portfolios">
            Portfolios
          </Link>{' '}
          to add or remove.
        </>
      );
    default:
      throw new Error('unknown tab');
  }
};
