import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { CustomValidationType } from 'utils/forms';
import { defaultOptions } from 'utils/popper';
import PasswordRequirementsTooltip from 'views/Landing/component/passwordRequirements';
import { ValidationError } from 'yup';

interface Props {
  readonly error?: ValidationError | string;
  readonly visible: boolean;

  readonly anchor: HTMLDivElement | null;
}

export const PasswordError: React.FC<Props> = ({
  error,
  visible,
  anchor,
}: Props): React.ReactElement | null => {
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [arrow, setArrow] = useState<HTMLDivElement | null>(null);
  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        offset: [24, 12],
        placement: 'right',
        arrow: arrow,
      }),
    [arrow],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void => {
    update?.();
  }, [update]);

  if (!visible || !error || typeof error === 'string') {
    if (typeof error === 'string') {
      console.warn('error must be yup.ValidationError');
    }

    return null;
  }

  if (error.type !== CustomValidationType.passwordRequirements) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={setPopup} className="mt-0 z-1" style={styles.popper} {...attributes.popper}>
      <div ref={setArrow} style={styles.arrow}>
        <div className="bg-gray w-4 h-4 transform rotate-45" />
      </div>

      <PasswordRequirementsTooltip id="reset-password-tooltip" error={error} />
    </div>,
    document.body,
  );
};
