import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Member } from 'sharable/types';
import { Page } from 'types/page';
import { ProcessingState } from 'types/processingState';
import { Stream, StreamEvent } from 'types/timeline';
import { AllAndUpcomingEvent } from 'types/timelineUpcomingEvent';
import { eventsSortFn } from 'utils/eventsSortFn';

export enum TimelineAction {
  loadingStreams = 'loadingStreams',
  creatingStream = 'creatingStream',
  updatingStream = 'updatingStream',
  deletingStream = 'deletingStream',
  creatingEvent = 'creatingEvent',
  updatingEvent = 'updatingEvent',
  deletingEvent = 'deletingEvent',
  fetchingAllAndUpcoming = 'fetchingAllAndUpcoming',
}

export interface StreamForm {
  readonly name: string;
  readonly category: string;
  readonly members: readonly Member[];
  readonly tags: string[];
  readonly summary?: string;
}

export interface TimelineState {
  sponsoredEvents: StreamEvent[];
  streams: readonly Stream[];
  allAndUpcomingEvents: Page<AllAndUpcomingEvent>;
  processingState: ProcessingState<any>;
}

const initialState: TimelineState = {
  sponsoredEvents: [],
  streams: [],
  allAndUpcomingEvents: Page.empty(),
  processingState: ProcessingState.idle(),
};

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setProcessingState: (
      state: TimelineState,
      { payload }: PayloadAction<ProcessingState<TimelineAction>>,
    ): void => {
      state.processingState = payload;
    },
    setAllAndUpcomingEvents: (
      state: TimelineState,
      { payload }: PayloadAction<Page<AllAndUpcomingEvent>>,
    ): void => {
      state.processingState = ProcessingState.idle();
      state.allAndUpcomingEvents = payload;
    },
    setStreams: (state: TimelineState, { payload }: PayloadAction<readonly Stream[]>): void => {
      state.streams = payload;
    },
    addStream: (state: TimelineState, { payload }: PayloadAction<Stream>): void => {
      state.streams = [...state.streams, payload];
    },
    removeEventFromStream: (
      state: TimelineState,
      { payload }: PayloadAction<{ eventId: string; streamId: string }>,
    ): void => {
      const { streams } = state;

      state.streams = streams.map((stream: Stream): Stream => {
        if (stream.id !== payload.streamId) {
          return stream;
        } else {
          const { events } = stream;
          return {
            ...stream,
            events: events.filter((event: StreamEvent): boolean => event.id !== payload.eventId),
          };
        }
      });
    },
    replaceEventInStream: (state: TimelineState, { payload }: PayloadAction<StreamEvent>): void => {
      const { streams } = state;

      state.streams = streams.map((stream: Stream): Stream => {
        const { events } = stream;

        return stream.id === payload.stream_id
          ? {
              ...stream,
              events: events.map(
                (event: StreamEvent): StreamEvent => (event.id === payload.id ? payload : event),
              ),
            }
          : stream;
      });
    },
    addEventToStream: (state: TimelineState, { payload }: PayloadAction<StreamEvent>): void => {
      const { streams } = state;

      state.streams = streams.map(
        (stream: Stream): Stream =>
          stream.id === payload.stream_id
            ? {
                ...stream,
                events: [...stream.events, payload].sort(eventsSortFn),
              }
            : stream,
      );
    },
    replaceStream: (state: TimelineState, { payload }: PayloadAction<Stream>): void => {
      const { streams } = state;
      state.streams = streams.map(
        (stream: Stream): Stream => (stream.id === payload.id ? payload : stream),
      );
    },
    removeStreams: (state: TimelineState, { payload }: PayloadAction<string[]>): void => {
      const { streams } = state;
      state.streams = streams.filter((stream: Stream): boolean => !payload.includes(stream.id));
    },
    clearEvents: (state: TimelineState): void => {
      const { streams } = state;
      state.streams = streams.map((stream: Stream): Stream => ({ ...stream, events: [] }));
    },
  },
});

export const streamsSelector = (state: { timeline: TimelineState }): readonly Stream[] =>
  state.timeline.streams;

export const sponsoredEventsSelector = (state: { timeline: TimelineState }): StreamEvent[] =>
  state.timeline.sponsoredEvents;

export const processingStateSelector = (state: {
  timeline: TimelineState;
}): ProcessingState<TimelineAction> => state.timeline.processingState;

export const allAndUpcomingEventsSelector = (state: {
  timeline: TimelineState;
}): Page<AllAndUpcomingEvent> => state.timeline.allAndUpcomingEvents;

export const {
  setProcessingState,
  setStreams,
  addStream,
  replaceStream,
  removeStreams,
  addEventToStream,
  removeEventFromStream,
  replaceEventInStream,
  clearEvents,
  setAllAndUpcomingEvents,
} = timelineSlice.actions;

export default timelineSlice.reducer;
