import axios, { AxiosResponse } from 'axios';
import {
  getDropdownStyle,
  isBeforeInputEventAvailable,
  SINGLE_SPACE,
} from 'components/postBodyInput/helpers';
import { usePostBodyAPIRef } from 'components/postBodyInput/hooks';
import styles from 'components/postBodyInput/postBodyInput.module.scss';
import { Suggestions } from 'components/postBodyInput/suggestions';
import { EditorState, NodeType, PostBody, PostBodyInputAPI } from 'components/postBodyInput/types';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { SocialUser } from 'views/Home/types';

interface Props {
  readonly initialValue: PostBody;
  readonly numberOfLines?: number;
  readonly placeholder?: string;
  readonly className?: string;

  onChange?(): void;
  onSubmit?(): void;
  onFocus?(): void;
  onBlur?(): void;
}

export const PostBodyInput: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<Props> & React.RefAttributes<PostBodyInputAPI>
> = React.forwardRef(function PostBodyInput(
  {
    numberOfLines,
    initialValue,
    placeholder,
    className,
    onChange,
    onSubmit,
    onFocus,
    onBlur,
  }: Props,
  apiRefObject: React.Ref<PostBodyInputAPI>,
): React.ReactElement {
  const elementRef = useRef<HTMLDivElement>(null);

  const [currentText, setCurrentText] = useState<string | undefined>();
  const [suggestions, setSuggestions] = useState<readonly SocialUser[] | readonly string[]>([]);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.typing);
  const [currentNode, setCurrentNode] = useState<Node | null>(null);
  const [empty, setEmpty] = useState<boolean>(true);
  const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] = useState<number>(-1);

  usePostBodyAPIRef(apiRefObject, elementRef);

  const reset = useCallback((): void => {
    setSuggestions([]);
    setEditorState(EditorState.typing);
    setCurrentText(undefined);
  }, []);

  const setCaretPosition = useCallback((node: Node, offset: number): void => {
    const selection = window.getSelection();
    if (selection !== null) {
      const range = document.createRange();

      range.selectNode(node);
      range.setStart(node, offset);
      range.collapse(true);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  const createStyledNode = useCallback(
    (type: NodeType, content: string, value: string, style: string, title?: string): Node => {
      if (currentNode === null) {
        throw new Error('there is no target node to edit');
      }

      const container = currentNode.parentNode;
      if (container === null) {
        throw new Error('this is impossible, the content is not within a container');
      }

      const span = document.createElement('span');
      const text = document.createTextNode(content);
      const next = document.createTextNode(SINGLE_SPACE);

      // Create a range to replace the contents
      const range = document.createRange();
      // It is kind of not possible that `textContent` is null, but
      // typescript thinks it can be of course
      const currentNodeText = currentNode.textContent ?? '';
      const currentTextLength = currentText?.length ?? 0;

      // We are sure that we can go 1 step back because of the @ or # symbols
      range.setStart(currentNode, currentNodeText.length - currentTextLength - 1);
      range.setEnd(currentNode, currentNodeText.length);
      range.deleteContents();

      span.append(text);

      span.setAttribute('class', style);
      span.setAttribute('data-type', type);
      span.setAttribute('data-value', value);
      if (title !== undefined) {
        span.setAttribute('title', title);
      }

      container.insertBefore(span, currentNode.nextSibling);
      container.insertBefore(next, span.nextSibling);

      return next;
    },
    [currentNode, currentText?.length],
  );

  const handleTagSelected = useCallback(
    (tag: string): void => {
      const element = elementRef.current;
      const next = createStyledNode(NodeType.tag, '#' + tag, tag, 'text-blue');

      // Reset everything
      reset();
      // Restore focus
      element?.focus();
      // Set caret position
      setCaretPosition(next, 1);
      // Notify change listeners
      onChange?.();
    },
    [createStyledNode, onChange, reset, setCaretPosition],
  );

  const handleUserSelected = useCallback(
    (user: SocialUser): void => {
      const title = `${user.name}\n\n${user.about}`;
      const element = elementRef.current;
      const next = createStyledNode(NodeType.mention, user.name, user.id, 'text-blue', title);
      // Reset everything
      reset();
      // Restore focus
      element?.focus();
      // Set caret position
      setCaretPosition(next, 1);
      // Notify change listeners
      onChange?.();
    },
    [createStyledNode, onChange, reset, setCaretPosition],
  );

  const handleSuggestionSelected = useCallback(
    (value: SocialUser | string): void => {
      switch (editorState) {
        case EditorState.insertUser:
          if (typeof value === 'string') {
            throw new Error('wrong value for current state');
          }

          handleUserSelected(value);
          break;
        case EditorState.insertTag:
          if (typeof value !== 'string') {
            throw new Error('wrong value for current state');
          }

          handleTagSelected(value);
      }
    },
    [editorState, handleTagSelected, handleUserSelected],
  );

  const dropdownStyle = useMemo((): CSSProperties | undefined => {
    if (editorState === EditorState.typing || currentNode === null) {
      return;
    }

    return getDropdownStyle(currentNode);
  }, [currentNode, editorState]);

  const editCurrentText = useCallback(
    (event: InputEvent): void => {
      const targetRanges = event.getTargetRanges();
      if (targetRanges === null) {
        throw new Error('what are you editing?');
      }
      const range = targetRanges[0];
      if (!range) {
        throw new Error('seriously, what are you editing?');
      }

      const container = range.startContainer;
      const content = container.textContent ?? '';

      // FIXME: there are a lot of INPUT TYPEs
      switch (event.inputType) {
        case 'insertText':
          setCurrentText((currentText: string | undefined): string | undefined => {
            if (currentText !== undefined) {
              return currentText + event.data;
            } else {
              return `${event.data}`;
            }
          });
          break;
        case 'deleteContentBackward':
          if (content.endsWith('@') || content.endsWith('#')) {
            reset();
            return;
          }
          setCurrentText((currentText: string | undefined): string | undefined =>
            currentText?.slice(0, -1),
          );
          break;
        case 'deleteContentForward':
          setCurrentText((currentText: string | undefined): string | undefined =>
            currentText?.slice(1),
          );
          break;
        case 'insertFromPaste':
          break;
      }
    },
    [reset],
  );

  const keyDownHandler = useCallback(
    (event: KeyboardEvent): void => {
      if (editorState == EditorState.typing) {
        if (event.key === 'Enter' || event.key === 'Return') {
          // FIXME: allow this but check for number of lines and approve
          //        the input when it is 1 and this key is pressed
          event.preventDefault();
          // Now just submit this
          onSubmit?.();
        }

        return;
      }

      switch (event.key) {
        case 'Escape':
          event.preventDefault();
          event.stopPropagation();

          reset();
          break;
        case 'ArrowUp':
          event.preventDefault();
          setHighlightedSuggestionIndex((currentIndex: number): number =>
            Math.max(currentIndex - 1, 0),
          );
          break;
        case 'ArrowDown':
          event.preventDefault();
          setHighlightedSuggestionIndex((currentIndex: number): number =>
            Math.min(currentIndex + 1, suggestions.length - 1),
          );
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          event.preventDefault();
          break;
        case 'Enter':
        case 'Return':
          event.preventDefault();

          if (highlightedSuggestionIndex !== -1) {
            const currentSuggestion = suggestions[highlightedSuggestionIndex];
            if (typeof currentSuggestion === 'string') {
              handleTagSelected(currentSuggestion);
            } else {
              handleUserSelected(currentSuggestion);
            }
            setHighlightedSuggestionIndex(-1);
          } else if (editorState === EditorState.insertTag && currentText) {
            handleTagSelected(currentText);
          }
          break;
      }
    },
    [
      currentText,
      editorState,
      handleTagSelected,
      handleUserSelected,
      highlightedSuggestionIndex,
      onSubmit,
      reset,
      suggestions,
    ],
  );

  // FIXME: enable this in the future
  const pasteHandler = useCallback((event: ClipboardEvent): void => {
    event.preventDefault();
  }, []);

  const beforeInputHandler = useCallback(
    (event: InputEvent): void => {
      const text = event.data;

      const selection = document.getSelection();
      if (selection === null || selection.rangeCount === 0) {
        throw new Error('this is unlikely, but it happened, forgive me');
      }
      const range = selection.getRangeAt(0);
      if (!range) {
        throw new Error('this really is unlikely, but it happened, forgive me');
      }

      const currentNode = range.startContainer;

      //  If we edit a _special_ node, we have to remove the SPECIAL
      // attributes from it.
      //
      // Visually, for the user there is no difference. So we don't need
      // to actually destroy the span and just add a plain text element.
      //
      //  However, the contents will be nested if a user adds a new mention
      // within it. Reason for which we do actually "convert" it to text
      //
      // FIXME: if deleting the whole node, this does a funky thing
      const parent = currentNode.parentElement;
      if (parent !== null && parent.tagName === 'SPAN') {
        const grandParent = parent.parentNode;
        // Not likely to be null, btu we must respect the type system
        const text = parent.textContent ?? '';

        grandParent?.insertBefore(document.createTextNode(text), parent);
        parent.remove();
      }

      // FIXME: try to reactivate the insertion state if it makes sense
      switch (editorState) {
        case EditorState.typing:
          if (text === '@') {
            setEditorState(EditorState.insertUser);
          } else if (text === '#') {
            setEditorState(EditorState.insertTag);
          }
          break;
        case EditorState.insertUser:
        case EditorState.insertTag:
          // FIXME: check the location of the caret (it has to be after the activation symbol)
          editCurrentText(event);
          break;
      }
    },
    [editCurrentText, editorState],
  );

  useEffect((): void => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }

    // FIXME: Should actually use the `initialValue`
    element.innerHTML = '';
  }, [initialValue]);

  useEffect((): VoidFunction | void => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }

    const adjustWidth = (): void => {
      const { style } = element;
      const boundingBox = element.getBoundingClientRect();
      // Make the MAX width fixed so that we don't have problems with
      // wrapping
      style.maxWidth = `${boundingBox.width}px`;
    };
    adjustWidth();

    // Keep an eye on the parent being resized
    const parentElement = element.parentElement;
    if (parentElement === null) {
      return;
    }
    // Create a resize observer
    const resizeObserver = new ResizeObserver(adjustWidth);

    resizeObserver.observe(element);
    return (): void => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect((): VoidFunction | void => {
    if (editorState === EditorState.insertUser) {
      const cancelToken = axios.CancelToken.source();
      const timeout = setTimeout((): void => {
        api
          .get(`${API_V1_PATH}/feed/users`, {
            params: { keyword: currentText },
            cancelToken: cancelToken.token,
          })
          .then((response: AxiosResponse): void => {
            setSuggestions(response.data ?? []);
            setHighlightedSuggestionIndex(-1);
          })
          .catch(console.warn);
      }, 100);

      return (): void => {
        clearTimeout(timeout);
        cancelToken.cancel();
      };
    }
  }, [currentText, editorState]);

  useEffect((): VoidFunction | void => {
    if (editorState === EditorState.insertTag) {
      const cancelToken = axios.CancelToken.source();
      const timeout = setTimeout((): void => {
        api
          .get(`${API_V1_PATH}/feed/tags`, {
            params: { keyword: currentText },
            cancelToken: cancelToken.token,
          })
          .then((response: AxiosResponse): void => {
            setSuggestions(response.data ?? []);
            setHighlightedSuggestionIndex(-1);
          })
          .catch(console.warn);
      }, 100);

      return (): void => {
        clearTimeout(timeout);
        cancelToken.cancel();
      };
    }
  }, [currentText, editorState]);

  useEffect((): void => {
    if (editorState === EditorState.typing) {
      setCurrentNode(null);
      return;
    } else {
      setTimeout((): void => {
        const selection = document.getSelection();
        if (selection === null || selection.rangeCount === 0) {
          throw new Error('this is unlikely, but it happened, forgive me');
        }

        const range = selection.getRangeAt(0);
        if (!range) {
          throw new Error('this really is unlikely, but it happened, forgive me');
        }

        const currentNode = range.startContainer;
        if (currentNode.nodeType === Node.ELEMENT_NODE) {
          setCurrentNode(currentNode.firstChild);
        } else {
          setCurrentNode(currentNode);
        }
      }, 0);
    }
  }, [editorState]);

  useEffect((): VoidFunction | void => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }

    const onMutate = (): void => {
      setEmpty(element.innerHTML === '');
    };

    const observer = new MutationObserver(onMutate);

    if (onChange) {
      element.addEventListener('input', onChange);
    }
    element.addEventListener('beforeinput', beforeInputHandler);
    element.addEventListener('paste', pasteHandler);
    element.addEventListener('keydown', keyDownHandler);

    observer.observe(element, { childList: true, subtree: true });
    return (): void => {
      if (onChange) {
        element.removeEventListener('input', onChange);
      }
      element.removeEventListener('beforeinput', beforeInputHandler);
      element.removeEventListener('paste', pasteHandler);
      element.removeEventListener('keydown', keyDownHandler);

      observer.disconnect();
    };
  }, [beforeInputHandler, keyDownHandler, onChange, pasteHandler]);

  const style = useMemo(
    (): CSSProperties =>
      numberOfLines ? { height: `${1.25 * numberOfLines}em` } : { height: 'auto' },
    [numberOfLines],
  );

  useLayoutEffect((): void => {
    const element = elementRef.current;
    if (element === null) {
      return;
    }

    element.focus();
  }, []);

  return (
    <>
      <div className={`${className} relative`}>
        {empty && <div className={styles.placeholder}>{placeholder}</div>}
        <div
          ref={elementRef}
          contentEditable={true}
          className={editableBoxClassName}
          style={style}
          suppressContentEditableWarning={true}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {/* Suggestions dropdown */}
      {editorState !== EditorState.typing &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" onClick={reset}>
            <Suggestions
              style={dropdownStyle}
              suggestions={suggestions}
              editorState={editorState}
              currentSuggestionIndex={highlightedSuggestionIndex}
              onSuggestionSelect={handleSuggestionSelected}
            />
          </div>,
          document.body,
        )}
    </>
  );
});

if (!isBeforeInputEventAvailable()) {
  throw new Error('unsupported browser, please use a newer browser');
}

const editableBoxClassName = [styles.editableBox, 'scroller'].join(' ');
