import SVGIcon from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';

type Variant = 'information' | 'error' | 'warning' | 'success';

interface Props {
  readonly message: string;
  readonly details?: React.ReactElement;
  readonly variant: Variant;

  onClear?(): void;
}

export const MessageViewContent: React.FC<Props> = ({
  message,
  details,
  variant,
  onClear,
}: Props): React.ReactElement => {
  const rootClass = useMemo((): string => {
    const base = 'relative p-3 font-poppinsMedium text-sm rounded';

    switch (variant) {
      case 'information':
        return [base, ' text-gray', 'bg-blue-50', 'text-gray'].join(' ');
      case 'error':
        return [base, 'bg-red-100', 'text-red-600'].join(' ');
      case 'warning':
        return [base, 'bg-yellow-100', 'text-orange'].join(' ');
      case 'success':
        return [base, 'bg-green-50', 'text-green-600'].join(' ');
    }
  }, [variant]);

  return (
    <div className={rootClass}>
      <div className="flex items-center gap-3">
        {icons[variant]}
        <div className="flex-1 mr-8 min-w-0">{message}</div>
      </div>
      {onClear && (
        <div className="absolute top-4 right-4 cursor-pointer" onClick={onClear}>
          <SVGIcon name="close-popup-icon" className="w-4 fill-current" />
        </div>
      )}

      {details && <div className="mt-4 ml-8 font-poppins">{details}</div>}
    </div>
  );
};

const icons: Record<Variant, React.ReactElement> = {
  error: <SVGIcon name="info-gray" className="w-5 fill-current" />,
  information: <SVGIcon name="info-icon" className="w-5 fill-current" />,
  warning: <SVGIcon name="info-gray" className="w-5 fill-current" />,
  success: <SVGIcon name="check-icon-generic" className="w-5 fill-current" />,
};
