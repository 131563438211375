import { StripeError } from '@stripe/stripe-js';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  processingStateSelector,
  SubscriptionPlansReducerActions,
} from 'redux/reducers/subscriptionPlansReducer';
import { ProcessingStateEnum } from 'types/processingState';
import { CheckoutForm } from 'views/Checkout/SubscriptionCheckout/checkoutForm';
import { Failure } from 'views/Checkout/SubscriptionCheckout/failure';
import { Success } from 'views/Checkout/SubscriptionCheckout/success';

export const ViewSelector: React.FC = (): React.ReactElement => {
  const processingState = useSelector(processingStateSelector);

  switch (processingState.state) {
    case ProcessingStateEnum.idle:
    case ProcessingStateEnum.processing: {
      if (processingState.data !== SubscriptionPlansReducerActions.getActiveSubscription) {
        return <CheckoutForm />;
      } else {
        return <Success />;
      }
    }
    case ProcessingStateEnum.error:
    case ProcessingStateEnum.fatalError:
      return <Failure error={processingState.error as StripeError | undefined} />;
    case ProcessingStateEnum.success:
      return <Success />;
  }
};
