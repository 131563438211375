import { ReactComponent as NoPlansIcon } from 'assets/icons/svg/info-gray.svg';
import EmptySection from 'components/emptySection/EmptySection';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscriptionPlansHistoryLoadingSelector,
  subscriptionPlansHistorySelector,
} from 'redux/reducers/profileReducer';
import { listUserSubscriptionsAction } from 'redux/services/subscriptionPlansService';
import columns from 'views/Profile/Payments/columns';

const SubscriptionsTable: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const page = useSelector(subscriptionPlansHistorySelector);
  const query = useQueryParameters();
  const loading = useSelector(subscriptionPlansHistoryLoadingSelector);

  useEffect((): VoidFunction => {
    return dispatch(listUserSubscriptionsAction(query));
  }, [dispatch, query]);

  if (page.total_count === 0 && !loading) {
    return (
      <div className="flex-grow relative">
        <EmptySection
          title="No invoices found"
          subtitle="Your invoices history is empty"
          icon={NoPlansIcon}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="relative flex-grow">
        <SpinnerLoader visible={true} />
      </div>
    );
  }

  return (
    <div className="relative flex-grow">
      <Pagination totalPages={page.page_count}>
        <Table columns={columns} rows={page.data} />
      </Pagination>
    </div>
  );
};

export default SubscriptionsTable;
