import { NotificationItemView } from 'components/header/UserHeader/components/NotificationItemView';
import SVGIcon from 'components/icons/SVGIcon';
import { UpcomingEventsView } from 'components/upcomingEventsView';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Notification } from 'redux/reducers/notificationsReducer';
import { summaryNotificationsSelector } from 'redux/reducers/sharedReducer';
import { upcomingEventsSelector } from 'redux/reducers/upcomingEventsReducer';
import { getSummaryNotificationsAction } from 'redux/services/notificationsService';
import { getUpcomingEvents } from 'redux/services/upcomingEventsService';

export const NotificationsRightBar: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const upcomingEvents = useSelector(upcomingEventsSelector);
  const notifications = useSelector(summaryNotificationsSelector);

  useEffect((): void => {
    if (process.env.REACT_APP_DATA_PORTAL) {
      return;
    }

    // TODO: make cancellable
    dispatch(getSummaryNotificationsAction());
    dispatch(getUpcomingEvents());
  }, [dispatch]);

  return (
    <div className="w-96 hidden flex-shrink-0 lg:block">
      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <div className="rounded border-blue border-2 mb-4 pt-4" style={{ minHeight: 200 }}>
          <h4 className="text-white mb-4 mx-4 text-base">QUICK TIPS</h4>
          <ul className="text-white list-disc ml-8 pl-0">
            <li>
              <div className="text-base font-poppins pr-4">
                <div className="w-full">
                  Connect the dots by using portfolios, news feeds, maps, value chains and
                  timelines.
                </div>
              </div>
            </li>
            <li>
              <div className="text-base font-poppins pr-4">
                <div className="w-full">
                  Watch out for lists of popular companies to see more complete sample sets.
                </div>
              </div>
            </li>
            <li>
              <div className="text-base font-poppins pr-4">
                <div className="w-full">
                  Contact us at any time. We look forward to hearing your feedback.
                </div>
              </div>
            </li>
          </ul>
          <div className="flex h-10 border-t-2 border-blue mt-4 items-center justify-center">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/capnotebyfinvar"
            >
              <SVGIcon name="linkedin-icon" className="fill-current text-blue w-5 h-5 mx-2" />
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/Capnote_finance">
              <SVGIcon name="twitter-icon" className="fill-current text-blue w-5 h-5 mx-2" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UCssEwipimucnWDPVsEtMBXg"
            >
              <SVGIcon name="youtube-icon" className="fill-current text-blue w-5 h-5 mx-2" />
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/capnote/">
              <SVGIcon name="instagram-icon" className="fill-current text-blue w-5 h-5 mx-2" />
            </a>
          </div>
        </div>
      )}
      <div className="sticky top-0">
        <div style={{ minHeight: 150 }} className="bg-white rounded pt-4 flex flex-col mb-4">
          <h4 className="text-gray-darkest mb-4 mx-4 text-base">NOTIFICATIONS</h4>
          <div className="h-px bg-gray-light" />
          <div className="flex-grow">
            {notifications.map((notification: Notification) => (
              <div key={notification.id} className="border-b border-gray-light">
                <NotificationItemView notification={notification} variant="right-panel" />
              </div>
            ))}
            {notifications.length === 0 && (
              <p className="text-gray text-sm font-poppins mt-4 text-center">
                No pending notifications
              </p>
            )}
          </div>
          <div className="flex justify-end p-4">
            <Link to="/notifications">
              <h4 className="text-blue">SEE ALL</h4>
            </Link>
          </div>
        </div>
        {/* TODO: Until there is no definition of layout for right sidebar there will be up to 3 upcoming events */}
        <UpcomingEventsView entries={upcomingEvents.slice(0, 3)} />
      </div>
    </div>
  );
};
