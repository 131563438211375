import ConfirmationModal from 'components/modals/ConfirmationModal';
import InfoModal from 'components/modals/InfoModal/InfoModal';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'redux/reducers/store';
import ApplicationRoutes from 'routes/ApplicationRoutes';
import smartlookClient from 'smartlook-client';
import { TokenManager } from 'TokenManager';
import ScrollToTop from 'utils/scrollToTop';

const {
  REACT_APP_MIXPANEL_PROJECT_TOKEN,
  REACT_APP_BITBUCKET_BRANCH,
  REACT_APP_SMARTLOOK_PROJECT_TOKEN,
} = process.env;

mixpanel.init(REACT_APP_MIXPANEL_PROJECT_TOKEN || '8490f5700709f663783311350eab3a08', {
  debug: REACT_APP_BITBUCKET_BRANCH === 'develop',
});

if (REACT_APP_SMARTLOOK_PROJECT_TOKEN) {
  smartlookClient.init(REACT_APP_SMARTLOOK_PROJECT_TOKEN);
}

const Capnote: React.FC = (): React.ReactElement => {
  const tag = document.querySelector('meta[name="application-version"]');
  console.info('Application version: ', tag?.getAttribute('content') ?? 'Unknown');

  return (
    <Provider store={store}>
      <Router>
        <TokenManager>
          <ScrollToTop />
          <ApplicationRoutes />
        </TokenManager>
      </Router>
      {/* The modals */}
      <InfoModal />
      <ConfirmationModal />
    </Provider>
  );
};

export default Capnote;
