import { AlignedText } from 'components/table/alignedText';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { formatValue } from 'utils';
import { Industry } from 'views/DataAnalysis/Industry/types';
import { ONE_HUNDRED_TRILLIONS } from 'views/DataAnalysis/valuesFormatters';

const createColumns = (
  largestMarketCapitalization: number,
  currencySymbol: string,
): Array<ColumnDefinition<Industry>> => {
  const zeroes = ((): string => {
    if (largestMarketCapitalization > ONE_HUNDRED_TRILLIONS) {
      return "'000 '000";
    } else {
      return "'000";
    }
  })();

  return [
    {
      name: 'name',
      type: ColumnType.text,
      sortable: true,
      label: 'Industry Name',
      alignment: Alignment.left,
      weight: 3,
    },
    {
      name: 'sectors',
      type: ColumnType.custom,
      sortable: true,
      label: 'Sector',
      headerAlignment: Alignment.left,
      render: function Sectors(value: string[]): React.ReactElement {
        return <div className="truncate leading-8">{value.join(', ')}</div>;
      },
      weight: 2,
    },
    {
      name: 'number_of_companies_as_primary_industry',
      type: ColumnType.numeric,
      sortable: true,
      label: 'Company Count\n(as Primary Industry)',
      precision: 0,
      weight: 2,
      alignment: Alignment.center,
    },
    {
      name: 'number_of_companies',
      type: ColumnType.numeric,
      sortable: true,
      label: 'Total Company\nCount',
      precision: 0,
      weight: 2,
      alignment: Alignment.center,
    },
    {
      name: 'market_capitalization',
      type: ColumnType.custom,
      sortable: true,
      label: `Total Market Cap\n(${currencySymbol} ${zeroes})`,
      weight: 2,
      headerAlignment: Alignment.center,
      // This strange syntax is because, React wants the "component" to have
      // a display name
      render: function MarketCapitalization(value: number): React.ReactElement {
        return (
          <AlignedText alignment={Alignment.center}>
            {formatValue(value, 'currency', largestMarketCapitalization)}
          </AlignedText>
        );
      },
    },
  ];
};

export default createColumns;
