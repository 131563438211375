import { defaultLocale } from 'constants/defaultLocale';
import React, { useMemo } from 'react';
import { StreamEvent } from 'types/timeline';
import { parseDate } from 'utils/dateTime';
import { Pin } from 'views/SmartTools/TimelineAndEvents/components/eventItem/components/pin';

interface Props {
  readonly event: StreamEvent;
  readonly compact: boolean;
  readonly upcoming: boolean;
}

export const EventCard: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  function EventCard(
    { compact, event, upcoming }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): React.ReactElement {
    const { importance } = event;

    const date = useMemo(
      (): Date => parseDate(event.start_date, event.start_time, event.timezone) ?? new Date(),
      [event.start_date, event.start_time, event.timezone],
    );

    const dateParts = dateFormatter
      .formatToParts(date)
      .reduce(
        (map: Record<string, string>, part: Intl.DateTimeFormatPart): Record<string, string> => {
          return { ...map, [part.type]: part.value };
        },
        {},
      );

    const containerClass = useMemo(
      (): string => getContainerClass(compact, date, importance),
      [compact, date, importance],
    );

    if (compact) {
      return (
        <div ref={ref} className={containerClass}>
          {upcoming && <Pin />}

          <div className="font-poppinsSemiBold leading-none w-9 text-center">
            {dateParts['day']}
          </div>
          <div className="text-xs leading-3">{dateParts['month']}</div>
        </div>
      );
    } else {
      return (
        <div ref={ref} className={containerClass}>
          {upcoming && <Pin />}

          <div className="flex flex-col items-center justify-center border border-current border-l-0 border-t-0 border-b-0 w-9 h-full px-2">
            <div className="font-poppinsSemiBold leading-3 mt-1">{dateParts['day']}</div>
            <div className="text-xs leading-3 h-3">{dateParts['month']}</div>
          </div>
          <div className="font-jostSemiBold truncate overflow-ellipsis w-full truncate overflow-ellipsis px-2 text-left leading-none">
            {event.summary}
          </div>
        </div>
      );
    }
  },
);

const baseContainerClass =
  'h-9 flex justify-center border rounded items-center cursor-pointer relative';
const compactContainerClass = [baseContainerClass, 'flex-col w-9'].join(' ');
const normalContainerClass = [baseContainerClass, 'text-center'].join(' ');

const dateFormatter = new Intl.DateTimeFormat(defaultLocale, {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

const getContainerClass = (compact: boolean, date: Date, importance: string | null) =>
  [
    compact ? compactContainerClass : normalContainerClass,
    importance === 'High' ? 'bg-red-100 border-red-500' : 'bg-gray-light border-gray',
    ...(date.getTime() < Date.now() ? ['opacity-70'] : []),
  ].join(' ');
