import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  balanceSheetSelector,
  clearBalanceSheetStatement,
  companyDetailsSelector,
  loadingBalanceSheetSelector,
} from 'redux/reducers/companyReducer';
import { getBalanceSheetService } from 'redux/services/companyService';
import { Props } from 'views/DataAnalysis/Company/Details/Financials/props';
import StatementView from 'views/DataAnalysis/Company/Details/Financials/statementView';

const BalanceSheet: React.FC<Props> = ({ options, onPeriodChange }: Props): React.ReactElement => {
  const { companyId } = useParams<{ companyId: string }>();

  const dispatch = useDispatch<any>();
  const company = useSelector(companyDetailsSelector);
  const statement = useSelector(balanceSheetSelector);
  const loading = useSelector(loadingBalanceSheetSelector);

  useEffect((): VoidFunction | void => {
    if (!companyId) {
      return;
    }

    dispatch(getBalanceSheetService(companyId, options));
    return (): void => {
      dispatch(clearBalanceSheetStatement());
    };
  }, [dispatch, companyId, options]);

  return (
    <StatementView
      organization={company}
      statement={statement}
      loading={loading}
      convertedToUSD={options.convert_to_usd}
      onPeriodChange={onPeriodChange}
    />
  );
};

export default BalanceSheet;
