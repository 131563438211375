import { CountryFlag } from 'components/countryFlag';
import React from 'react';
import {
  CompanySearchResultMetadata,
  SearchResult,
  SearchResultCategory,
  SearchResultMetadataProp,
  UserSearchResultMetadata,
} from 'redux/reducers/globalSearchReducer';

interface Props {
  readonly item: SearchResult;
}

const CompanyMetadataView: React.FC<SearchResultMetadataProp<CompanySearchResultMetadata>> = ({
  metadata,
}: SearchResultMetadataProp<CompanySearchResultMetadata>): React.ReactElement => {
  const { country } = metadata;

  return (
    <div className="flex items-center gap-2 px-3 text-md leading-5">
      <CountryFlag size="small" iso2={country.iso2} />
      <div className="text-sm font-poppins truncate">{country.name}</div>
    </div>
  );
};

const UserMetadataView: React.FC<SearchResultMetadataProp<UserSearchResultMetadata>> = ({
  metadata,
}: SearchResultMetadataProp<UserSearchResultMetadata>): React.ReactElement => {
  const { about } = metadata;

  return (
    <div className="flex items-center gap-2 px-3 text-md leading-5">
      <div className="text-sm font-poppins truncate">{about}</div>
    </div>
  );
};

export const SearchSuggestionMetadataView: React.FC<Props> = ({
  item,
}: Props): React.ReactElement | null => {
  switch (item.category) {
    case SearchResultCategory.industry:
      break;
    case SearchResultCategory.company:
      return <CompanyMetadataView metadata={item.metadata} />;
    case SearchResultCategory.user:
      return <UserMetadataView metadata={item.metadata} />;
  }

  return null;
};
