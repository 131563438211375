import React from 'react';

export const PressEscapeMessage: React.FC = (): React.ReactElement => {
  return (
    <li className="text-center text-sm text-gray py-2">
      <p>
        <i>Press ESC to cancel</i>
      </p>
    </li>
  );
};
