import { Clickable } from 'components/clickable';
import React from 'react';
import { ValueChainNode } from 'types/organization/types';

interface Props {
  readonly chain: ValueChainNode[];

  onClear(): void;
  onGoToNodeAt(index: number): void;
}

export const Breadcrumbs: React.FC<Props> = ({
  chain,
  onGoToNodeAt,
}: Props): React.ReactElement => {
  return (
    <div className="overflow-x-auto">
      <div className="flex items-center whitespace-nowrap">
        {chain.map((node: ValueChainNode, index: number) => {
          return (
            <React.Fragment key={node.entityId}>
              <Clickable clickData={index} onClick={onGoToNodeAt}>
                <span className={defaultTextClass}>{node.label}</span>
              </Clickable>

              <span className={`${defaultTextClass} mx-2 opacity-100 last:hidden`}>•</span>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const defaultTextClass =
  'uppercase text-blue font-poppinsSemiBold flex focus:outline-none focus:bg-gray-light opacity-75';
