import React from 'react';

interface BaseMenuItem {
  readonly kind: 'nav' | 'custom' | 'url';
  readonly label: string | React.ReactElement | React.FunctionComponent;
  readonly id: string;
  readonly hidden?: boolean;
  readonly comingSoon?: boolean;
}

interface CustomMenuItem extends BaseMenuItem {
  readonly kind: 'custom';
  readonly label: string | React.ReactElement;
  readonly tooltipText?: string;
}

interface NavMenuItem extends BaseMenuItem {
  readonly kind: 'nav';
  readonly path: string;
  readonly label: string | React.ReactElement;
  readonly tooltipText?: string;
}

interface UrlMenuItem extends BaseMenuItem {
  readonly kind: 'url';
  readonly url: string;
  readonly label: string;
  readonly tooltipText?: string;
}

export type MenuItem = CustomMenuItem | NavMenuItem | UrlMenuItem;

export interface MenuGroupItem {
  readonly id: string;
  readonly label: string | React.ReactElement;
  readonly openByDefault?: boolean;
  readonly children: MenuItem[];
  readonly path?: string;
  readonly selectable: boolean;
  readonly frozen?: boolean;
  readonly comingSoon?: boolean;
  readonly tooltipText?: string;
}

export const isNavMenuItem = (item: MenuItem): item is NavMenuItem => item.kind === 'nav';
export const isCustomMenuItem = (item: MenuItem): item is CustomMenuItem => item.kind === 'custom';
export const isUrlMenuItem = (item: MenuItem): item is UrlMenuItem => item.kind === 'url';
