/* eslint-disable max-len */
export default {
  title:
    'CAPNOTE enhances analytical decision-making, supplementing where other options fall short',
  list: [
    'Do you want a financial platform that delivers results but is more enjoyable and easier to use?',
    'Could you use advanced technological assistance that is affordable, even if your employer is not able or willing to provide expensive subscriptions?',
    'Have you ever had to adapt to a new scenario, industry, country or asset class and wished your financial tools could help you get up to speed quicker?',
    'This is now possible. Capnote aims to be the financial platform that becomes a regular and positive part of your professional journey through finance.',
  ],
};
