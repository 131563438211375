import { Modal } from 'components/modal';
import ExceedAccess from 'modals/exceedAccess';
import React, { useContext } from 'react';
import { EarnItem } from 'views/EarnAndBuy/api';
import { ActionItem, OwnerActions } from 'views/EarnAndBuy/common/ownerActions';
import { ActionsContext, ActionType, EarnAndBuyActions } from 'views/EarnAndBuy/context';
import { ApplyButton } from 'views/EarnAndBuy/Earn/components/applyButton';

interface Props {
  readonly item: EarnItem;
}

enum ModalType {
  none,
  notAllowed,
  confirmRemove,
  removeFailure,
  edit,
}

export const Actions: React.FC<Props> = ({ item: listing }: Props): React.ReactElement => {
  const [currentModal, setCurrentModal] = React.useState<ModalType>(ModalType.none);

  const context = useContext<EarnAndBuyActions | null>(ActionsContext);

  const closeCurrentModal = React.useCallback((): void => {
    setCurrentModal(ModalType.none);
  }, []);

  const handleAction = React.useCallback(
    (action: 'edit' | 'share' | 'remove'): void => {
      switch (action) {
        case 'edit':
          if (listing) {
            context?.edit(listing.id);
          }
          break;
        case 'remove':
          if (listing) {
            context?.remove(listing.id);
          }
          break;
      }
    },
    [context, listing],
  );

  return (
    <div className="flex items-center gap-2 justify-end">
      <ApplyButton listing={listing} />
      <OwnerActions
        actions={actions}
        accessMode={listing.access_mode}
        actionStatuses={context?.actionStatuses}
        onHandleAction={handleAction}
      />

      <Modal isOpen={currentModal === ModalType.notAllowed} onClose={closeCurrentModal}>
        <Modal.Content>
          <div className="w-modal-sm">
            <ExceedAccess
              title="Upgrade your plan"
              description="You need to upgrade your subscription plan to apply for this listing."
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

const actions: readonly ActionItem[] = [
  {
    label: 'Share',
    icon: 'share-icon',
    disabled: true,
    action: ActionType.share,
  },
  {
    label: 'Edit',
    icon: 'edit-icon',
    disabled: false,
    action: ActionType.edit,
  },
  {
    label: 'Remove',
    icon: 'trash-generic',
    disabled: false,
    action: ActionType.remove,
  },
];
