import { TourConfig } from 'hooks/useOldTour';
import { Step } from 'react-joyride';

const steps: Step[] = [
  {
    target: '#tab-my-companies',
    title: 'My Company',
    content:
      'Company in your Watchlist and Portfolio show up under the "My Company" ' +
      'section. Select any company to continue',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '#tab-my-companies',
    title: "That's it!",
    content:
      "We hope you've enjoyed the overview of the site. You can replay the tour at any " +
      'point by clicking "Tour the site" in Support section.',
    placement: 'center',
    disableBeacon: true,
  },
];

const tourConfig: TourConfig = {
  nextPath: '',
  steps,
  isLastTour: true,
  lastLabel: 'Finish',
};

export default tourConfig;
