import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { useFilters } from 'hooks/useFilters';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { companyDetailsSelector, getCompanyDetails } from 'redux/reducers/companyReducer';
import { valueChainStateSelector } from 'redux/reducers/valueChainReducer';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';
import ValueChain from 'views/DataAnalysis/common/ValueChain';
import createFiltersConfig from 'views/DataAnalysis/common/ValueChain/Filters/config';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';

const CompanyValueChain: React.FC = (): React.ReactElement => {
  const { companyId } = useParams<{ companyId: string }>();
  const valueChainState = useSelector(valueChainStateSelector);
  const chain = valueChainState.stack;
  const currentNode = useMemo((): ValueChainNode => chain[chain.length - 1], [chain]);

  const filtersConfig = useMemo(
    (): readonly CommonFilter[] => createFiltersConfig(currentNode),
    [currentNode],
  );

  const handleFiltersChange = useFilters(filtersConfig);
  const dispatch = useDispatch<any>();
  const companyDetails = useSelector(companyDetailsSelector);

  useEffect((): void => {
    if (companyDetails || !companyId) {
      return;
    }

    dispatch(getCompanyDetails(companyId));
  }, [dispatch, companyId, companyDetails]);

  const handleClear = useCallback((): void => {
    handleFiltersChange([]);
  }, [handleFiltersChange]);

  return (
    <>
      <div className="flex items-center justify-between my-3">
        <Filters config={filtersConfig} onChange={handleFiltersChange} />
      </div>
      <ValueChain
        rootNodeId={companyId}
        rootNodeLabel={companyDetails?.name}
        rootNodeType={ValueChainEntityType.company}
        currentNode={currentNode}
        onClear={handleClear}
      />
      <DataDisclaimer />
    </>
  );
};

export default CompanyValueChain;
