import { Level } from 'components/table/types';
import { noop } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  clearFinancialMetrics,
  industryDetailsSelector,
  industryFinancialMetricsLoadingSelector,
  industryFinancialMetricsSelector,
  selectedCountrySelector,
} from 'redux/reducers/industryReducer';
import { fetchIndustryFinancialMetricsService } from 'redux/services/industryServices';
import { OrganizationDetails } from 'types/organization/types';
import StatementView from 'views/DataAnalysis/Company/Details/Financials/statementView';
import { CountryDropdown } from 'views/DataAnalysis/Industry/Details/countryDropdown';

export const FinancialMetrics: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const statement = useSelector(industryFinancialMetricsSelector);
  const industryDetails = useSelector(industryDetailsSelector);
  const country = useSelector(selectedCountrySelector);
  const loading = useSelector(industryFinancialMetricsLoadingSelector);

  const { industryId } = useParams<{ industryId: string }>();
  const industry: OrganizationDetails = useMemo(
    (): OrganizationDetails => ({
      id: industryId ?? '',
      organization_id: industryId ?? '',
      name: industryDetails?.name ?? '',
      description: industryDetails?.description ?? '',
      following: false,
      tags: industryDetails?.tags ?? [],
      average_risk_rating: Level.low,
      average_growth_rating: Level.low,
      currency: '',
    }),
    [industryDetails?.description, industryDetails?.name, industryDetails?.tags, industryId],
  );

  useEffect((): void | VoidFunction => {
    if (!industryId) {
      return;
    }

    return dispatch(fetchIndustryFinancialMetricsService(industryId, country));
  }, [country, dispatch, industryId]);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(clearFinancialMetrics());
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-end items-center mt-3">
        <CountryDropdown />
      </div>
      <StatementView
        organization={industry}
        statement={statement}
        loading={loading}
        convertedToUSD={false}
        onPeriodChange={noop}
      />
    </>
  );
};
