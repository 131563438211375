import { Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { StoredUpcomingEvent, updateUpcomingEvents } from 'redux/reducers/upcomingEventsReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const getUpcomingEvents = () => (dispatch: Dispatch) => {
  api
    .get<any, AxiosResponse<StoredUpcomingEvent[]>>(`${API_V1_PATH}/events/upcoming`)
    .then((response: AxiosResponse<StoredUpcomingEvent[]>): void => {
      dispatch(updateUpcomingEvents(response.data));
    })
    .catch(console.warn);
};
