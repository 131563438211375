import DetailsModal from 'components/detailsModal';
import { MainOutletContent } from 'components/mainOutletContent';
import { Modal } from 'components/modal';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { useGtag } from 'hooks/useGtag';
import { useModal } from 'hooks/useModal';
import mixpanel from 'mixpanel-browser';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useNavigate, useParams } from 'react-router-dom';
// GENERAL COMPONENTS
import {
  clearCompanyDetails,
  closeUsageExceededLimitModal,
  companyDetailsSelector,
  showUsageLimitExceededModalSelector,
} from 'redux/reducers/companyReducer';
import { getCompanyDetailsService } from 'redux/services/companyService';
import { OverviewHeader } from 'views/DataAnalysis/common/overviewHeader';
import { AlgoNotes } from 'views/DataAnalysis/Company/Details/AlgoNotes';
import { DownloadQuotaExceededNoPaymentsModal } from 'views/DataAnalysis/Company/Details/downloadQuotaExceededNoPaymentsModal';
import { ExperimentalPaymentModals } from 'views/DataAnalysis/Company/Details/experimentalPaymentModals';
// COMPONENTS TABS
import Financials from 'views/DataAnalysis/Company/Details/Financials';
import GeographiesMaps from 'views/DataAnalysis/Company/Details/GeographiesMaps';
import KeyFactors from 'views/DataAnalysis/Company/Details/KeyFactors';
import { Overview } from 'views/DataAnalysis/Company/Details/Overview';
import { PeerComparisons } from 'views/DataAnalysis/Company/Details/PeerComparisons';
// UTILS
import TimelineEvents from 'views/DataAnalysis/Company/Details/Timeline';
import { Tools } from 'views/DataAnalysis/Company/Details/tools';
import ValueChain from 'views/DataAnalysis/Company/Details/ValueChain';

const CompanyDetails: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { companyId } = useParams<{ companyId: string }>();

  const company = useSelector(companyDetailsSelector);
  const showUsageLimitExceededModal = useSelector(showUsageLimitExceededModalSelector);
  const details = useModal();
  const location = useLocation();

  const { trackEvent } = useGtag();

  const handleTagClick = useCallback(
    (tag: string): void => {
      navigate(`/data-analysis/company/?tags=${encodeURIComponent(tag)}`);
    },
    [navigate],
  );

  const handleCloseShowUsageLimitExceededModal = useCallback((): void => {
    dispatch(closeUsageExceededLimitModal());
  }, [dispatch]);

  const trackTab = useCallback(
    (pathname: string): void => {
      if (!company?.id || !company?.name) {
        return;
      }

      trackEvent('company-tab-selected', {
        event_category: 'company',
        changeTab: pathname.split('/').pop(),
        company: company.id,
        name: company.name,
      });
    },
    [company?.id, company?.name, trackEvent],
  );

  useEffect((): void => {
    if (!company?.id || !company?.name) {
      return;
    }

    trackEvent('view-company', {
      category: 'company',
      action: 'View company details page',
      company: company?.id,
      viewCompanyName: company?.name,
    });
  }, [company?.name, trackEvent, company?.id]);

  useEffect((): void => {
    if (!companyId) {
      return;
    }
    trackTab(location.pathname);
  }, [location.pathname, companyId, trackTab]);

  useEffect((): void | VoidFunction => {
    mixpanel.track('PageView D&A Company Details');
    if (!companyId) {
      return;
    }

    dispatch(getCompanyDetailsService(companyId));
    return (): void => {
      dispatch(clearCompanyDetails());
      dispatch(closeUsageExceededLimitModal());
    };
  }, [dispatch, companyId]);

  return (
    <MainOutletContent>
      <div className="flex items-start gap-2 mb-2">
        <OverviewHeader
          category="company"
          name={company?.name}
          description="This company profile was system generated by Capnote."
          tags={company?.tags}
          onShowDetails={details.open}
          onTagClick={handleTagClick}
        />
        <Tools />
      </div>

      <Tabs variant={TabsVariant.buttons}>
        <Tab label="Overview" element={<Overview />} path="overview/*" />
        <Tab label="Algo Notes" element={<AlgoNotes />} path="algo-notes/*" />
        <Tab label="Value Chain" element={<ValueChain />} path="value-chain" />
        <Tab label="Financials" element={<Financials />} path="financials/*" />
        <Tab label="Geographies & Maps" element={<GeographiesMaps />} path="maps" />
        <Tab label="Risk & Growth Factors" element={<KeyFactors />} path="key-factors/*" />
        <Tab label="Peer Comps" element={<PeerComparisons />} path="peer-comparisons" />
        <Tab label="Timeline & Events" element={<TimelineEvents />} path="timeline/*" />

        <Route element={<AlgoNotes />} path="algo-notes/:noteId" />
      </Tabs>

      <Modal isOpen={details.isOpen} onClose={details.close}>
        <Modal.Content title={company?.name}>
          <DetailsModal description={company?.description} tags={company?.tags} />
        </Modal.Content>
      </Modal>

      {process.env.REACT_APP_BITBUCKET_BRANCH === 'develop' ? (
        <ExperimentalPaymentModals />
      ) : (
        <DownloadQuotaExceededNoPaymentsModal />
      )}

      <Modal isOpen={showUsageLimitExceededModal} onClose={handleCloseShowUsageLimitExceededModal}>
        <Modal.Content>
          <ExceedAccess
            title="Unlock More Companies"
            description="Upgrade your plan to add more companies to your watchlist."
          />
        </Modal.Content>
      </Modal>
    </MainOutletContent>
  );
};

export default CompanyDetails;
