import { Clickable } from 'components/clickable';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Button } from 'components/genericButton';
import { HoverHandler } from 'components/hoverHandler';
import { Modal } from 'components/modal';
import { SvgStar } from 'components/svgStar';
import { defaultLocale } from 'constants/defaultLocale';
import { noop } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AccessMode } from 'types/accessMode';
import { computeRatingValueAt } from 'utils/computeRatingValueAt';
import {
  MarketplaceItemDetails,
  useGetMarketplaceItemQuery,
  useSetMarketplaceItemStarRatingMutation,
} from 'views/EarnAndBuy/api';
import { formatDate } from 'views/EarnAndBuy/utils';
import { TagsList } from 'views/Home/components/shared/tagsList';

interface Props {
  readonly listingId?: string;
}

const emptyListing: MarketplaceItemDetails = {
  bookmarked: false,
  category: {
    id: 0,
    name: '',
  },
  content_uri: '',
  description: '',
  id: '',
  listing_owner: {
    id: '',
    name: '',
  },
  posted_at: '',
  price_amount: 0,
  price_currency: 'USD',
  review_count: 0,
  star_rating: {
    user: 0,
    average: 0,
  },
  ratings_count: 0,
  title: '',
  tags: [],
  access_mode: AccessMode.readOnly,
};

export const Details: React.FC<Props> = (props: Props): React.ReactElement => {
  const navigate = useNavigate();

  const { listingId } = props;
  const { data: item = emptyListing, isFetching: spinning } = useGetMarketplaceItemQuery(listingId);

  const [currentRating, setCurrentRating] = useState<
    readonly [number, number, number, number, number]
  >([0, 0, 0, 0, 0]);
  const [setRating] = useSetMarketplaceItemStarRatingMutation();

  if (!listingId) {
    throw new Error('buy item id cannot be null');
  }

  const amountText = useMemo((): string => {
    if (!item) {
      return '';
    }

    const { price_amount, price_currency } = item;
    const formatter = new Intl.NumberFormat(defaultLocale, {
      style: 'currency',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency: price_currency,
    });

    return `${formatter.format(price_amount)}`;
  }, [item]);

  const handleRatingHover = useCallback((index: number): void => {
    setCurrentRating([
      index >= 0 ? 1 : 0,
      index >= 1 ? 1 : 0,
      index >= 2 ? 1 : 0,
      index >= 3 ? 1 : 0,
      index >= 4 ? 1 : 0,
    ]);
  }, []);

  const handleRatingLeft = useCallback((): void => {
    setCurrentRating([0, 0, 0, 0, 0]);
  }, []);

  const handleSetRating = useCallback(
    (index: number): void => {
      const { user: user_rating } = item.star_rating;

      const value = index + 1;
      if (value === user_rating) {
        setRating({ id: listingId, rating: null });
      } else {
        setRating({
          id: listingId,
          rating: value,
        });
      }
    },
    [item.star_rating, listingId, setRating],
  );

  const handleBuy = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      navigate(`/earn-n-buy/buy/checkout/${item.id}`);
    },
    [item.id, navigate],
  );

  return (
    <Modal.Content title={item.title} closeable={true}>
      <div className="px-1 mt-8">
        <div className="relative w-modal-sm grid grid-cols-2 gap-4">
          <h6 className="font-poppinsMedium text-gray-dark">Description</h6>
          <p>{item.description}</p>
          <h6 className="font-poppinsMedium text-gray-dark">Tags</h6>
          <TagsList tags={item.tags ?? []} expanded={false} onExpand={noop} />
          <h6 className="font-poppinsMedium text-gray-dark">Price</h6>
          <p>{amountText}</p>
          <h6 className="font-poppinsMedium text-gray-dark leading-7">Category</h6>
          <p>{item.category.name}</p>
          <h6 className="font-poppinsMedium text-gray-dark leading-7">Listing Owner</h6>
          <p>
            <Link to={`/users/${item.listing_owner.id}`} className="text-blue">
              {item.listing_owner.name}
            </Link>
          </p>
          <h6 className="font-poppinsMedium text-gray-dark leading-7">Date Posted</h6>
          <p>{formatDate(item.posted_at)}</p>
          <h6 className="font-poppinsMedium text-gray-dark leading-7">Rate</h6>
          <div className="flex items-center gap-2 cursor-pointer" onMouseLeave={handleRatingLeft}>
            {[0, 1, 2, 3, 4].map((_: number, index: number): React.ReactElement => {
              const { user, average } = item.star_rating;

              const value =
                computeRatingValueAt(user, index) > 0 ? 100 : 100 * currentRating[index];
              const tintValue = computeRatingValueAt(average, index) * 100;

              return (
                <HoverHandler key={index} value={index} onHover={handleRatingHover}>
                  <Clickable clickData={index} onClick={handleSetRating}>
                    <button>
                      <SvgStar size={30} value={value} tintValue={tintValue} />
                    </button>
                  </Clickable>
                </HoverHandler>
              );
            })}
          </div>
        </div>
        <div className="flex items-center justify-end gap-4 mt-6">
          <Button type="button" variant="primary" label="Buy" onClick={handleBuy} />
        </div>
        <FullScreenLoadingSpinner visible={spinning} />
      </div>
    </Modal.Content>
  );
};
