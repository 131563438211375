import { LandingPageHeader } from 'components/header/LandingPageHeader';
import SpinnerLoader from 'components/spinnerLoader';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { authProcessingStateSelector } from 'redux/reducers/authReducer';
import { ProcessingStateEnum } from 'types/processingState';

export const Unauthenticated: React.FC = (): React.ReactElement => {
  const processingState = useSelector(authProcessingStateSelector);

  return (
    <div className="h-screen relative flex flex-col justify-start items-center w-full text-gray overflow-y-scroll scroller-light">
      <LandingPageHeader compact={true} />

      <div className="flex flex-1 items-center">
        <div className="sm:w-form xs:w-full p-5">
          <Outlet />
          <SpinnerLoader visible={processingState.state === ProcessingStateEnum.processing} />
        </div>
      </div>
    </div>
  );
};
