import { useGtag } from 'hooks/useGtag';
import { noop } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AuthProcessingStateData,
  authProcessingStateSelector,
  clearProcessingState,
  RedirectState,
} from 'redux/reducers/authReducer';
import { requestEmailVerification, signUp } from 'redux/services/authServices';
import { AccountType } from 'types/accountType';
import {
  createValidationError,
  FormErrors,
  passwordSchema,
  ValidationErrors,
  YupError,
} from 'utils/forms';
import { firstnameRegex, fullNameSchema, lastnameRegex } from 'validators/userProfile';
import { Button } from 'views/Landing/component/button';
import { MessageView } from 'views/Landing/component/messageView';
import { MessageViewContent } from 'views/Landing/component/messageViewContent';
import { GenericInput } from 'views/Landing/SignUp/components/genericInput';
import { TermsConditionsCommunicationCheckboxes } from 'views/Landing/SignUp/components/termsConditionsCommunicationCheckboxes';
import { fields, initialForm } from 'views/Landing/SignUp/staticData';
import { FormField, SignUpFormState } from 'views/Landing/SignUp/types';
import * as yup from 'yup';
import { ValidationError } from 'yup';

export const SignUpForm: React.FC = (): React.ReactElement => {
  const { trackEvent } = useGtag();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const navigate = useNavigate();

  const processingState = useSelector(authProcessingStateSelector);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [form, setForm] = useState<SignUpFormState>(initialForm);
  const [errors, setErrors] = useState<FormErrors<SignUpFormState>>({});

  const handleClearProcessingState = useCallback((): void => {
    dispatch(clearProcessingState());
  }, [dispatch]);

  const handleFieldChange = useCallback((name: keyof SignUpFormState, value: string): void => {
    setForm((form: SignUpFormState): SignUpFormState => ({ ...form, [name]: value }));
  }, []);

  useEffect((): VoidFunction => {
    let canceled = false;

    const timeout = setTimeout((): void => {
      schema
        .validate(form, { context: { isInEditMode: !submitted }, abortEarly: false })
        .then((): void => {
          if (!canceled) {
            setErrors({});
          }
        })
        .catch((error: ValidationError): void => {
          if (!canceled) {
            setErrors(ValidationErrors.reduce(error.inner));
          }
        });
    }, 300);

    return (): void => {
      canceled = true;

      clearTimeout(timeout);
    };
  }, [form, submitted]);

  const referralCode = React.useMemo((): string | null => {
    const search = new URLSearchParams(location.search);
    if (search.get('referrer')) {
      return search.get('referrer');
    }

    return null;
  }, [location.search]);

  const handleTermsConditionsCommunicationChange = useCallback(
    (termsAndConditionsAccepted: boolean, communicationAccepted: boolean): void => {
      setForm({
        ...form,
        termsAndConditionsAccepted: termsAndConditionsAccepted,
        communicationAccepted: communicationAccepted,
      });
    },
    [form],
  );

  const handleMessageAction = useCallback((): void => {
    if (
      processingState.data === AuthProcessingStateData.unverifiedRegisteredUser ||
      processingState.data === AuthProcessingStateData.registrationSucceeded
    ) {
      dispatch(requestEmailVerification(form.email));
    }
  }, [dispatch, form.email, processingState.data]);

  const focusFirstErroredInput = useCallback((errors: FormErrors): void => {
    const fieldNames = Object.keys(errors);
    if (fieldNames.length === 0) {
      return;
    }

    /**
     * Note that the `-input` suffix is added for some reason, it should not be
     * needed. But it is here, and we have to go with it.
     */
    const field = fields.find((field: FormField): boolean => field.name === fieldNames[0]);
    const fieldId = `${field?.id}-input`;
    const input = document.getElementById(fieldId);

    input?.focus();
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      mixpanel.track('SignUp Attempt');

      setSubmitted(true);
      try {
        await schema.validate(form, { context: { isInEditMode: false }, abortEarly: false });

        if (!form.termsAndConditionsAccepted) {
          setErrors({
            termsAndConditionsAccepted: createValidationError(
              'Please confirm that you agree to the Terms & Conditions to continue.',
              'termsAndConditionsAccepted',
            ) as YupError,
          });

          return;
        }
        setErrors({});

        const signUpData = {
          firstname: form.firstName,
          lastname: form.lastName,
          password: form.password,
          email: form.email.toLowerCase(),
          account_type: AccountType.public,
          allow_communications: form.communicationAccepted,
          referral_code: referralCode,
        };

        trackEvent('sign_up', {
          method: 'email',
          account_type: AccountType.public,
          from: document.referrer,
          info: {
            firstname: form.firstName,
            lastname: form.lastName,
            email: form.email.toLowerCase(),
          },
        });

        dispatch(signUp(signUpData));
      } catch (error: any) {
        const errors = ValidationErrors.reduce(error.inner);
        setErrors(errors);
        focusFirstErroredInput(errors);
      }
    },
    [dispatch, focusFirstErroredInput, form, referralCode, trackEvent],
  );

  useEffect(() => {
    const state = location.state;
    if (!state) {
      return;
    }

    setForm({ ...initialForm, ...(state as Partial<SignUpFormState>) });
  }, [location]);

  useEffect((): void => {
    if (errors.password) {
      mixpanel.track('SignUp invalid password');
    }
  }, [errors.password]);

  useEffect((): void | VoidFunction => {
    if (!errors.termsAndConditionsAccepted) {
      return;
    }

    const timeout = setTimeout((): void => {
      setErrors({});
    }, 3000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [errors.termsAndConditionsAccepted]);

  useEffect((): void => {
    setTimeout((): void => {
      if (processingState.data === AuthProcessingStateData.registrationSucceeded) {
        const state: RedirectState = {
          processingState: AuthProcessingStateData.registrationSucceeded,
          email: form.email,
        };

        navigate('/login', { state: state });
      }
    }, 0);
  }, [form.email, navigate, processingState.data]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <div className="my-5">
        <MessageView
          state={processingState.data}
          onAction={handleMessageAction}
          onClear={handleClearProcessingState}
        />
      </div>
      {referralCode ? (
        <div className="my-5">
          <MessageViewContent
            variant="information"
            message="Signing up with a referral code"
            details={<div>Your referral code is: {referralCode}</div>}
            onClear={noop}
          />
        </div>
      ) : null}
      <fieldset className="my-5">
        {fields.map(
          (field: FormField): React.ReactElement => (
            <GenericInput
              id={field.id}
              key={field.id}
              name={field.name}
              placeholder={field.placeholder}
              type={field.type}
              value={form[field.name]}
              error={errors[field.name]}
              onChange={handleFieldChange}
            />
          ),
        )}
        <TermsConditionsCommunicationCheckboxes
          termsAndConditionsAccepted={form.termsAndConditionsAccepted}
          communicationAccepted={form.communicationAccepted}
          error={errors.termsAndConditionsAccepted}
          onChange={handleTermsConditionsCommunicationChange}
        />
        <div className="w-full text-center">
          <Button id="signup-submission-button" label="SIGN UP" />
        </div>
      </fieldset>
      <div className="text-sm font-poppins text-gray text-center my-5">
        Already have an account?{' '}
        <Link to="/login" className="text-sm text-blue font-poppinsSemiBold normal-case">
          Log In
        </Link>
      </div>
    </form>
  );
};

type Schema = {
  [key in keyof Omit<SignUpFormState, 'communicationAccepted' | 'termsAndConditionsAccepted'>]: any;
};

const requiredIfNotInEditMode = (message: string, regexp?: RegExp, regexpErrorMessage?: string) => {
  return (isInEditMode: boolean, schema: any) => {
    return isInEditMode
      ? schema
      : (regexp ? schema.matches(regexp, regexpErrorMessage) : schema).required(message);
  };
};

const schema = yup.object().shape<Schema>({
  ...fullNameSchema,
  firstName: yup
    .string()
    .when(
      '$isInEditMode',
      requiredIfNotInEditMode(
        'Please enter your first name',
        firstnameRegex,
        'Please enter a valid name and at least 3 characters',
      ),
    ),
  lastName: yup
    .string()
    .when(
      '$isInEditMode',
      requiredIfNotInEditMode(
        'Please enter your last name',
        lastnameRegex,
        'Please enter a valid last name and at least 1 character',
      ),
    ),
  email: yup
    .string()
    .email('Please enter a valid email address. For example johndoe@mail.com')
    .when('$isInEditMode', requiredIfNotInEditMode('Please enter your email address')),
  password: passwordSchema,
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Password does not match'),
  accountType: yup.string().oneOf(Object.values(AccountType)),
});
