import React from 'react';
import { Company } from 'redux/reducers/smartToolsRecentSearchesReducer';

interface Props {
  readonly company: Company;
}

export const CompanyItemRenderer: React.FC<Props> = ({ company }: Props): React.ReactElement => {
  return (
    <div>
      <p className="block">
        {company.name} <span className="text-blue italic">{company.ticker}</span>
      </p>
      <p className="text-xs text-gray block">Company</p>
    </div>
  );
};
