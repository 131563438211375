// Libraries
import 'views/Home/Home.scss';

import { useGtag } from 'hooks/useGtag';
// Components
import { useScrollEffect } from 'hooks/useScrollEffect';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Assets
// Utils
import {
  feedPaginationSelector,
  feedSelector,
  loadingFeedSelector,
  resetFeed,
} from 'redux/reducers/feedReducer';
// Redux services
import { getFeedService } from 'redux/services/feedService';
import { AddPostButton } from 'views/Home/components/addPostButton';
import { Feed } from 'views/Home/components/feed';
import { FeedFilter, FiltersBar } from 'views/Home/components/filtersBar';
import { NotificationsRightBar } from 'views/Home/components/notificationsRightBar';
import { RefreshButton } from 'views/Home/components/refreshButton';
import PostDetailsModal from 'views/Home/modals/PostDetailsModal';
import { FeedFilterCategory, FeedItem } from 'views/Home/types';

const Home: React.FC = (): React.ReactElement => {
  const { trackEvent } = useGtag();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const loadingFeed = useSelector(loadingFeedSelector);
  const feed = useSelector(feedSelector);

  const { feedItemId, commentId } = useParams();
  const { nextPage } = useSelector(feedPaginationSelector);

  // FIXME: we should use this to show the comment to the user
  //        when they come directly to it through the url
  void commentId;

  const [search, setSearch] = useSearchParams();
  const feedRef = useRef<HTMLDivElement>(null);
  const filterValue = useMemo((): FeedFilter => {
    return {
      keyword: search.get('keyword') ?? '',
      category: (search.get('category') ?? FeedFilterCategory.myInterests) as FeedFilterCategory,
    };
  }, [search]);

  const loadMore = useCallback((): void => {
    if (nextPage) {
      dispatch(getFeedService(nextPage, filterValue.keyword, filterValue.category));
    }
  }, [dispatch, filterValue.category, filterValue.keyword, nextPage]);

  const handleRefreshFeed = useCallback((): void => {
    feedRef.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(getFeedService(1, filterValue.keyword, filterValue.category));
  }, [dispatch, filterValue.category, filterValue.keyword]);

  const handleFilter = useCallback(
    (filter: FeedFilter): void => {
      search.set('keyword', filter.keyword);
      if (filter.category !== FeedFilterCategory.myInterests) {
        search.set('category', filter.category);
      } else {
        search.delete('category');
      }

      setSearch(search);
    },
    [search, setSearch],
  );

  const handleTagClick = useCallback(
    (tag: string): void => {
      search.set('keyword', tag);
      setSearch(search);
    },
    [search, setSearch],
  );

  const handleFeedItemClick = useCallback(
    (feedItem: FeedItem): void => {
      // Update the history as well
      trackEvent('postView', {
        post_id: feedItem.id,
      });
      navigate(`/feed/${feedItem.id}`);
    },
    [navigate, trackEvent],
  );

  const closeDetailsModal = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  useEffect((): void => {
    mixpanel.track('PageView Feed');
  }, []);

  useEffect((): VoidFunction => {
    dispatch(getFeedService(1, filterValue.keyword, filterValue.category));

    return () => {
      // When this component is mounted with an empty feed, the intersected div elem 'loadingFeed.current' is on screen, dispatching FEED service.
      // Rendered feed data might take enough space to push the referenced div off-screen.
      // To ensure Feed is reset after mounted again, It should be emptied so the referenced div is on screen at start.
      dispatch(resetFeed());
    };
  }, [dispatch, filterValue.keyword, filterValue.category]);

  const onScroll = useCallback(
    (viewport: HTMLDivElement): void => {
      if (
        Math.ceil(viewport.scrollTop) >= Math.floor(viewport.scrollHeight - viewport.offsetHeight)
      ) {
        loadMore();
      }
    },
    [loadMore],
  );
  useScrollEffect(feedRef.current, onScroll, 400);

  // This particular view is different in many ways, so it does not use
  // <MainOutletContent /> because it needs kind of a custom layout
  return (
    <div ref={feedRef} className="relative flex gap-4 min-h-full w-full md:pr-3 z-0">
      <div className="flex flex-col flex-grow flex-shrink min-h-full min-w-0 gap-2">
        <AddPostButton />
        <RefreshButton viewport={feedRef.current} onRefreshFeed={handleRefreshFeed} />

        <div className="relative flex flex-col flex-1 bg-white md:rounded md:mb-3">
          <FiltersBar filter={filterValue} onFilter={handleFilter} />
          <Feed
            feed={feed}
            loading={loadingFeed}
            onPostClick={handleFeedItemClick}
            onTagClick={handleTagClick}
          />
        </div>
      </div>

      <NotificationsRightBar />
      <PostDetailsModal feedItemId={feedItemId ?? null} onClose={closeDetailsModal} />
    </div>
  );
};

export default Home;
