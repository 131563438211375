import { QueryStatus } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

interface MutationStatus {
  readonly status: QueryStatus;
}

export const useStatusSelector = (
  createMutation: MutationStatus,
  updateMutation: MutationStatus,
): QueryStatus => {
  return useMemo((): QueryStatus => {
    if (
      updateMutation.status !== QueryStatus.uninitialized &&
      createMutation.status !== QueryStatus.uninitialized
    ) {
      console.warn('both mutations cannot be in a non-uninitialized status');
      return QueryStatus.uninitialized;
    }

    if (updateMutation.status !== QueryStatus.uninitialized) {
      return updateMutation.status;
    } else if (createMutation.status !== QueryStatus.uninitialized) {
      return createMutation.status;
    } else {
      return QueryStatus.uninitialized;
    }
  }, [createMutation.status, updateMutation.status]);
};
