import { useMemo } from 'react';
import { PeerComparisonsSummary } from 'types/peerComparisons';

export const useLargestNumberInDataset = (summary: PeerComparisonsSummary): number => {
  return useMemo((): number => {
    return Object.values(summary.high).reduce(
      (maxValue: number, data: Record<string, number | null>): number => {
        const numbers: readonly number[] = Object.values(data).filter(
          (value: number | null): boolean => value !== null,
        ) as readonly number[];
        const newMaxValue = Math.max(...numbers);

        return newMaxValue > maxValue ? newMaxValue : maxValue;
      },
      0,
    );
  }, [summary]);
};
