import { GraphCentralNode } from 'components/valueChain/graphCentralNode';
import { GraphNode } from 'components/valueChain/graphNode';
import { useIdealIncrement, useIdealOffset } from 'components/valueChain/hooks';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly label: React.ReactNode;
  readonly isFirst: boolean;
}

export const GraphContainer: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  isFirst,
  children,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const ref = useScrollIntoView<HTMLDivElement>(true);
  const childrenArray = React.Children.toArray(children);
  const increment = useIdealIncrement(childrenArray.length, isFirst);
  const offset = useIdealOffset(childrenArray.length, isFirst);
  const computeAngle = useCallback(
    (index: number): number => increment * index - offset,
    [increment, offset],
  );
  const hasChildren = useMemo((): boolean => childrenArray.length > 0, [childrenArray.length]);

  return (
    <div ref={ref} className={getContainerClass(hasChildren)}>
      <GraphCentralNode label={label} withRing={hasChildren} smaller={!isFirst} />

      <div className="relative">
        {childrenArray.map((child: React.ReactNode, index: number): React.ReactNode => {
          const angle = computeAngle(index);

          return (
            <GraphNode key={index} angle={angle}>
              {child}
            </GraphNode>
          );
        })}
      </div>
    </div>
  );
};

const getContainerClass = (withHorizontalMargin: boolean): string => {
  return (
    'flex duration-500 font-poppins items-center relative my-44 ' +
    (withHorizontalMargin ? 'mx-36' : '')
  );
};
