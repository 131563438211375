import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Content } from 'components/modal/content';
import { noop } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { stopPropagation } from 'utils/ignoreEvent';

interface Props {
  readonly isOpen: boolean;
  readonly variant?: 'normal' | 'lighter';

  readonly autoOverflow?: boolean;
  readonly closeOnClickOutside?: boolean;

  readonly spinning?: boolean;

  onClose(): void;
}

const Context = React.createContext<VoidFunction>(noop);

export class Modal extends React.Component<React.PropsWithChildren<Props>> {
  public static Content = Content;
  public static Context = Context;

  private closeHandler = (event?: React.MouseEvent): void => {
    const { onClose } = this.props;
    if (event) {
      event.stopPropagation();
    }

    onClose();
  };

  private handleClickOutside = (event: React.MouseEvent): void => {
    const { closeOnClickOutside = true, onClose } = this.props;

    event.stopPropagation();
    event.preventDefault();

    if (closeOnClickOutside) {
      onClose();
    }
  };

  public render(): React.ReactElement | null {
    const { isOpen, variant = 'normal', autoOverflow = true, children, spinning } = this.props;

    const content = (): React.ReactElement => {
      if (!React.isValidElement(children)) {
        return <>{children}</>;
      }

      return React.cloneElement<any>(children, { variant: variant });
    };

    if (!isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <Context.Provider value={this.closeHandler}>
        <div
          className="fixed flex flex-col items-center justify-center inset-0 z-1 bg-backdrop"
          onClick={this.handleClickOutside}
        >
          <div className="py-3 rounded-md overflow-hidden">
            <div className={getWindowClasses(variant, autoOverflow)} onClick={stopPropagation}>
              {content()}
              <FullScreenLoadingSpinner visible={!!spinning} />
            </div>
          </div>
        </div>
      </Context.Provider>,
      document.body,
    );
  }
}

const commonWindowClasses = [
  'relative',
  'shadow-lg',
  'max-h-modal',
  'md:w-max',
  'md:rounded-md',
  'sm:w-full',
  'scroller-squared',
  '-my-3',
];

const getWindowClasses = (variant: 'lighter' | 'normal', autoOverflow: boolean): string => {
  const backgroundColor = variant === 'lighter' ? ['bg-white'] : ['bg-gray-light'];
  if (autoOverflow) {
    return [...commonWindowClasses, ...backgroundColor, 'overflow-y-auto'].join(' ');
  } else {
    return [...commonWindowClasses, ...backgroundColor].join(' ');
  }
};
