import React, { useMemo } from 'react';

interface Props {
  readonly id?: string;
  readonly label: string;

  readonly disabled?: boolean;
  readonly variant?: 'primary' | 'secondary';

  onClick?(): void;
}

export const Button: React.FC<Props> = ({
  id,
  label,
  disabled = false,
  variant = 'primary',
  onClick,
}: Props): React.ReactElement => {
  const className = useMemo((): string => {
    const classes = [
      'px-5',
      'h-10',
      'text-sm',
      'font-poppinsMedium',
      'rounded',
      'min-w-button',
      'max-w-min',
    ];

    switch (variant) {
      case 'primary':
        classes.push('bg-blue');
        classes.push('text-white');
        break;
      case 'secondary':
        classes.push('bg-gray-200');
        classes.push('text-blue');
        break;
    }

    if (disabled) {
      classes.push('opacity-10');
    }

    return classes.join(' ');
  }, [disabled, variant]);

  return (
    <button id={id} className={className} disabled={disabled} onClick={onClick}>
      {label}
    </button>
  );
};
