import { OneClickDownloadButton } from 'components/1clickDownloadButton';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  companyDetailsSelector,
  generatingOneClickReportProgressSelector,
  generatingOneClickReportSelector,
  updatingCompanyInWatchlist,
} from 'redux/reducers/companyReducer';
import {
  addCompanyToWatchlistService,
  deleteWatchlistService,
  generate1ClickReportService,
} from 'redux/services/companyService';
import FollowButton, { FollowAction } from 'views/Connections/Overview/components/FollowButton';

export const Tools: React.FC = (): React.ReactElement => {
  const { trackEvent } = useGtag();

  const dispatch = useDispatch<any>();
  const company = useSelector(companyDetailsSelector);

  const updatingWatchlist = useSelector(updatingCompanyInWatchlist);
  const generatingOneClickReportProgress = useSelector(generatingOneClickReportProgressSelector);
  const generatingOneClickReport = useSelector(generatingOneClickReportSelector);

  const handleGenerate1ClickReport = useCallback((): void => {
    if (!company?.id || !company?.name) {
      // Silently ignore this, as it is not really possible
      return;
    }

    trackEvent('generate-report', {
      event_category: 'company',
      reportCompanyId: company.id,
      reportCompanyName: company.name,
    });

    dispatch(generate1ClickReportService(company.id));
  }, [company?.id, dispatch, trackEvent, company?.name]);

  const handleFollow = useCallback(
    (followAction: FollowAction): void => {
      if (company?.id === undefined) {
        throw new Error('should not be possible to trigger this error');
      }

      if (followAction === FollowAction.follow) {
        dispatch(addCompanyToWatchlistService(company?.id));
        trackEvent('follow', {
          event_category: 'company',
          followCompany: company?.id,
          name: company?.name,
        });
      } else if (followAction === FollowAction.unfollow) {
        dispatch(deleteWatchlistService(company?.id));
        trackEvent('unfollow', {
          event_category: 'company',
          unfollowCompany: company?.id,
          name: company?.name,
        });
        return;
      }
    },
    [company?.id, dispatch, company?.name, trackEvent],
  );

  return (
    <div className="flex items-center gap-2">
      <OneClickDownloadButton
        loading={generatingOneClickReport}
        progress={generatingOneClickReportProgress}
        tooltip="Download a detailed company summary that may be helpful when writing risk or investment memos."
        onClick={handleGenerate1ClickReport}
      />
      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <FollowButton
          followed={company?.following}
          compact={true}
          loading={updatingWatchlist}
          onClick={handleFollow}
        />
      )}
    </div>
  );
};
