import React, { useMemo } from 'react';
import { StatementGroup, StatementPeriod, StatementRow } from 'types/financial';
import { StatementCellView } from 'views/DataAnalysis/Company/Details/Financials/statementCellView';

interface Props {
  readonly group: StatementGroup;
  readonly periods: StatementPeriod[];

  readonly largestValue: number;
}

export const StatementGroupView: React.FC<Props> = ({
  group,
  periods,
  largestValue,
}: Props): React.ReactElement => {
  const { rows, name } = group;

  const span = rows.length;
  const itemStyle = useMemo(
    (): React.CSSProperties => ({ gridRow: `span ${span} / span ${span}` }),
    [span],
  );

  return (
    <div className="border-divider border-t first:mt-0">
      <div className="grid grid-cols-4 justify-center items-center">
        <div className="text-base text-black h-6 self-start py-2" style={itemStyle}>
          {name}
        </div>
        {rows.map((row: StatementRow): React.ReactElement => {
          const gridStyle = { gridTemplateColumns: `repeat(${periods.length}, minmax(0, 1fr)` };

          return (
            <React.Fragment key={row.key}>
              <span className="text-base text-gray m-0 px-2">{row.name}</span>
              <div style={gridStyle} className="grid col-span-2 px-6">
                {periods.map(
                  (column: StatementPeriod): React.ReactElement => (
                    <StatementCellView
                      key={column.access_key}
                      statement={group.name}
                      data={row.values[column.access_key]}
                      largestValue={largestValue}
                    />
                  ),
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
