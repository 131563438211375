import DeleteModal from 'components/deleteModal';
// GENERAL COMPONENTS
import { Modal } from 'components/modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// REDUX
import { closeConfirmationModal, confirmationModalSelector } from 'redux/reducers/sharedReducer';

const ConfirmationModal: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { visible, title, message, onAccept, onCancel } = useSelector(confirmationModalSelector);

  const onClose = () => {
    dispatch(closeConfirmationModal());
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const handleOnAccept = () => {
    if (onAccept) onAccept();
    onClose();
  };

  return (
    <Modal isOpen={visible} onClose={onClose}>
      <Modal.Content title={title}>
        <DeleteModal text={message} onCancel={handleCancel} onAccept={handleOnAccept} />
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmationModal;
