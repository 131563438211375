import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  onResend?(): void;
}

export const EmailSentMessage: React.FC<Props> = ({ onResend }: Props): React.ReactElement => {
  const [timeRemainingToAllowResend, setTimeRemainingToAllowResend] = useState<number>(0);

  const timerMessage = useMemo((): string | null => {
    if (timeRemainingToAllowResend > 0) {
      return `Re-sent, please wait (${timeRemainingToAllowResend}s) to try again...`;
    }

    return null;
  }, [timeRemainingToAllowResend]);

  const handleResend = useCallback((): void => {
    setTimeRemainingToAllowResend(10);
    onResend?.();
  }, [onResend]);

  useEffect((): VoidFunction | void => {
    if (timeRemainingToAllowResend === 0) {
      return;
    }

    const timeout = setTimeout((): void => {
      setTimeRemainingToAllowResend(timeRemainingToAllowResend - 1);
    }, 1000);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [timeRemainingToAllowResend]);

  return (
    <>
      <div className="font-poppins mr-2">
        If you didn&apos;t receive the email please check your spam folder. If you still cannot find
        it,{' '}
        <button
          className="normal-case text-blue disabled:text-gray disabled:cursor-default"
          disabled={timeRemainingToAllowResend > 0}
          onClick={handleResend}
        >
          click here
        </button>{' '}
        and we&apos;ll try to send it again.
      </div>
      <div className="mt-4">{timerMessage}</div>
    </>
  );
};
