import { ConditionalRender } from 'components/conditionalRenderer';
import { BaseProps, EllipsisRenderingFn, TruncateType } from 'components/truncate/baseProps';
import React, { CSSProperties, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createClassName } from 'utils/css/tools';

export interface TruncateElementsProps extends BaseProps {
  readonly lines?: number;
  readonly contentType: TruncateType.elements;
  readonly ellipsis: EllipsisRenderingFn | React.ReactElement;
  readonly spacing?: number;
}

// FIXME: there is some "flicker" when computing the ideal number of items
export const TruncateElements: React.FC<React.PropsWithChildren<TruncateElementsProps>> = ({
  children,
  ellipsis,
  spacing = itemGap,
  lines = 1,
}: React.PropsWithChildren<TruncateElementsProps>): React.ReactElement | null => {
  const childrenArray = useMemo(
    (): React.ReactNode[] => React.Children.toArray(children),
    [children],
  );

  const [caliper, setCaliper] = useState<HTMLDivElement | null>(null);
  const [caliperStyle, setCaliperStyle] = useState<CSSProperties>({});
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | undefined>();
  const [visibleCount, setVisibleCount] = useState<number>(childrenArray.length);
  const [measuring, setMeasuring] = useState<boolean>(false);

  const truncatedChildren = useMemo(
    (): React.ReactNode[] => childrenArray.slice(0, visibleCount),
    [visibleCount, childrenArray],
  );

  // If children change then, we need to measure again
  // TODO: use a resize observer to update truncated items
  //       dynamically if the size of the container changes
  useEffect((): void => setMeasuring(true), [children]);

  // Get the optimal height (number of lines times line height)
  // TODO: perhaps we could add a mutation observer here to make it
  //       re-compute if needed
  useLayoutEffect((): void => {
    if (container === null) {
      return;
    }

    const child = container.firstElementChild;
    if (child instanceof HTMLElement) {
      const boundingRectangle = child.getBoundingClientRect();
      const computedHeight = lines * boundingRectangle.height + itemGap * (lines - 1);
      // Set the MAX height that the component will allow, we do so by
      // measuring the first child element, and then multiplying by the
      // number of lines
      //
      // Because we want to leave ${itemGap}px between rows, we need to add
      // the extra required height to the container
      setHeight(computedHeight);
      // Update the measuring element style
      setCaliperStyle(createCaliperStyle(container.offsetWidth, computedHeight, spacing));
    }
  }, [container, measuring, lines, spacing]);

  useLayoutEffect((): void => {
    if (!measuring || container === null || caliper === null) {
      return;
    }

    while (caliper.childElementCount > 2 && caliper.offsetHeight < caliper.scrollHeight) {
      // As we go, remove the element before the ellipsis
      //
      // FIXME: we are not considering the case in which ONLY the
      //        ellipsis might be left, we should and then do something
      //        about it
      caliper.removeChild(caliper.children[caliper.childElementCount - 2]);
    }
    setVisibleCount(caliper.childElementCount - 1);
    setMeasuring(false);
  }, [caliper, container, measuring]);

  const containerClassName = useMemo((): string => createContainerClassName(spacing), [spacing]);

  return (
    <div ref={setContainer} className={containerClassName} style={{ height: height }}>
      {truncatedChildren}

      <ConditionalRender renderIf={visibleCount < childrenArray.length}>
        {typeof ellipsis === 'function' ? ellipsis(childrenArray.length, visibleCount) : ellipsis}
      </ConditionalRender>

      {/* "Off-screen element to perform measurements */}
      <ConditionalRender renderIf={measuring}>
        <div ref={setCaliper} style={caliperStyle}>
          {children}
          {typeof ellipsis === 'function' ? ellipsis(childrenArray.length, visibleCount) : ellipsis}
        </div>
      </ConditionalRender>
    </div>
  );
};

const itemGap = 6;

const createContainerClassName = (spacing: number): string =>
  createClassName({
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    gap: `0 ${spacing}px`,
    overflow: 'hidden',
    width: '100%',
  });

const createCaliperStyle = (width: number, height: number, spacing: number): CSSProperties => ({
  position: 'absolute',
  display: 'flex',
  flexWrap: 'wrap',
  gap: `0 ${spacing}px`,
  alignItems: 'start',
  width: width,
  height: height,
  // Just make sure that it is more than 100%. With the parent having
  // overflow hidden, this means that it will be impossible to see this
  // element when measuring
  top: '101%',
});
