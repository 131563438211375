import { AmountInput } from 'components/amountInput';
import React from 'react';
import { PortfolioCommercialRealStateItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCommercialRealStateItem;
}

export const RealEstateCommercialExtraInputs: React.FC<Props> = ({
  item,
}: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCommercialRealStateItem>();

  return (
    <AmountInput
      name="annualIncome"
      label="Annual Net Income"
      value={item.annualNetIncomeAmount}
      currency={item.annualNetIncomeCurrency}
      onCurrencyChange={updaterFor('annualNetIncomeCurrency')}
      onValueChange={updaterFor('annualNetIncomeAmount')}
    />
  );
};
