import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';
import { CardBrand } from 'redux/reducers/authReducer';

export const cardBrandHumanLabel: { [key in CardBrand]: string } = {
  visa: 'Visa',
  mastercard: 'Master Card',
};

export const cardBrandIcon: { [key in CardBrand]: React.ReactElement } = {
  visa: <SVGIcon name="visa" className="w-8 h-8 bg-visa rounded" />,
  mastercard: <SVGIcon name="mastercard" className="w-8 h-8 bg-black rounded" />,
};
