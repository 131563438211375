import HeaderCell from 'components/table/headerCell';
import { Sorting, SortOrder } from 'components/table/sorting';
import { ColumnDefinition } from 'components/table/types';
import { Tooltip } from 'components/tooltip';
import React from 'react';

interface Props<T> {
  readonly columns: ReadonlyArray<ColumnDefinition<T>>;
  readonly sorting?: Sorting<T>;

  onSortChange?(name: keyof T, order: SortOrder): void;
}

export function TableHead<T>({ columns, sorting, onSortChange }: Props<T>): React.ReactElement {
  return (
    <thead>
      <tr>
        {columns.map(
          (column: ColumnDefinition<T>): React.ReactElement => (
            <th key={String(column.name)}>
              {column.description ? (
                <Tooltip content={column.description} fitContent={true}>
                  <HeaderCell
                    sortOrder={sorting?.[column.name] ?? SortOrder.none}
                    column={column}
                    onSortChange={onSortChange}
                  />
                </Tooltip>
              ) : (
                <HeaderCell
                  sortOrder={sorting?.[column.name] ?? SortOrder.none}
                  column={column}
                  onSortChange={onSortChange}
                />
              )}
            </th>
          ),
        )}
      </tr>
    </thead>
  );
}
