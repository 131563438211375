import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedItemFeedbackResponse } from 'redux/reducers/feedReducer';
import { ApplicationState } from 'redux/reducers/store';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { ProcessingState } from 'types/processingState';
import { API_V1_PATH, isAxiosError } from 'utils/config/axiosConfig';
import { FeedItem, GenericFeedItem } from 'views/Home/types';

export interface FeedItemDetailState {
  feedItem: FeedItem | null;
  processingState: ProcessingState<any>;
}

const initialState: FeedItemDetailState = {
  feedItem: null,
  processingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'feedItemDetails',
  initialState: initialState,
  reducers: {
    started: (state: FeedItemDetailState): void => {
      state.processingState = ProcessingState.processing();
      state.feedItem = null;
    },
    failed: (state: FeedItemDetailState, { payload }: PayloadAction<any>): void => {
      state.processingState = ProcessingState.error(payload);
    },
    fetchItemSucceeded: (
      state: FeedItemDetailState,
      { payload }: PayloadAction<FeedItem>,
    ): void => {
      state.feedItem = payload;
      state.processingState = ProcessingState.idle();
    },
    // feedItemCommentDeleted: (
    //   state: FeedItemDetailState,
    //   { payload }: PayloadAction<string>,
    // ): void => {
    //   const { feedItem } = state;
    //   if (!feedItem || feedItem?.id !== payload) {
    //     return;
    //   }
    //   const newCount = feedItem.commentsCount - 1;
    //   state.feedItem = { ...feedItem, commentsCount: newCount, commented: newCount > 0 };
    // },
    // feedItemCommentAdded: (
    //   state: FeedItemDetailState,
    //   { payload }: PayloadAction<string>,
    // ): void => {
    //   const { feedItem } = state;
    //   if (!feedItem || feedItem.id !== payload) {
    //     return;
    //   }
    //   const newCount = feedItem.commentsCount + 1;
    //   state.feedItem = { ...feedItem, commentsCount: newCount, commented: newCount > 0 };
    // },
    feedItemSaved: (state: FeedItemDetailState, { payload }: PayloadAction<string>): void => {
      if (state.feedItem?.id === payload) {
        state.feedItem = { ...state.feedItem, saved: true };
      }
    },
    feedItemUnsaved: (state: FeedItemDetailState, { payload }: PayloadAction<string>): void => {
      if (state.feedItem?.id === payload) {
        state.feedItem = { ...state.feedItem, saved: false };
      }
    },
    feedItemLiked: (
      state: FeedItemDetailState,
      { payload }: PayloadAction<FeedItemFeedbackResponse>,
    ): void => {
      if (state.feedItem?.id === payload.id) {
        state.feedItem = {
          ...state.feedItem,
          liked: payload.liked,
          likesCount: payload.likes_count,
          disliked: payload.disliked,
          dislikesCount: payload.dislikes_count,
        };
      }
    },
    feedItemDisliked: (
      state: FeedItemDetailState,
      { payload }: PayloadAction<FeedItemFeedbackResponse>,
    ): void => {
      if (state.feedItem?.id === payload.id) {
        state.feedItem = {
          ...state.feedItem,
          liked: payload.liked,
          likesCount: payload.likes_count,
          disliked: payload.disliked,
          dislikesCount: payload.dislikes_count,
        };
      }
    },
    unfetchFeedItem: (state: FeedItemDetailState): void => {
      state.feedItem = null;
    },
  },
});

const actions = slice.actions;

export const feedItemSelector = (state: ApplicationState): FeedItem | null => {
  return state.feedItemDetails.feedItem;
};

export const processingStateSelector = (state: ApplicationState): ProcessingState<any> => {
  return state.feedItemDetails.processingState;
};

export const { feedItemSaved, feedItemUnsaved, unfetchFeedItem, feedItemLiked, feedItemDisliked } =
  slice.actions;

export default slice.reducer;

export const fetchFeedItem = createAPIAction(function fetchFeedItem(
  client: HttpClient,
): ActionsGenerator {
  return async function* (id: string): AsyncGenerator<AnyAction> {
    yield actions.started();

    try {
      const response = await client.GET(`${API_V1_PATH}/feed/${id}`);
      yield actions.fetchItemSucceeded(GenericFeedItem.fromOtherObject(response.data));
    } catch (error: any) {
      if (isAxiosError(error)) {
        yield actions.failed(error.response?.status);
      } else {
        yield actions.failed(error);
      }
    }
  };
});
