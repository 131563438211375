import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { ProcessingState } from 'types/processingState';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export interface FeedbackQuestion {
  readonly id: number;
  readonly code: string;
  readonly question: string;
}

export interface FeedbackAnswer {
  readonly question_id: number;
  readonly answer: string;
}

export interface FeedbackAnswers {
  readonly answers: readonly FeedbackAnswer[];
}

export interface FeedbackState {
  questions: readonly FeedbackQuestion[];
  processingState: ProcessingState<never>;
}

const initialState: FeedbackState = {
  questions: [],
  processingState: ProcessingState.idle(),
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    started: (state: FeedbackState): void => {
      state.processingState = ProcessingState.processing();
    },
    failed: (state: FeedbackState): void => {
      state.processingState = ProcessingState.error();
    },
    succeeded: (state: FeedbackState): void => {
      state.processingState = ProcessingState.success();
    },
    reset: (state: FeedbackState): void => {
      state.processingState = ProcessingState.idle();
    },

    setQuestions: (
      state: FeedbackState,
      { payload }: PayloadAction<readonly FeedbackQuestion[]>,
    ): void => {
      state.processingState = ProcessingState.idle();
      state.questions = payload;
    },
  },
});

const actions = slice.actions;

export const fetchFeedbackQuestions = createAPIAction(function fetchQuestions(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      const response = await httpClient.GET(`${API_V1_PATH}/feedback/questions`);
      yield actions.setQuestions(response.data);
    } catch (error: any) {
      console.warn(error);
      yield actions.failed();
    }
  };
});

export const submitFeedback = createAPIAction(function submitFeedback(
  httpClient: HttpClient,
): ActionsGenerator {
  return async function* (answers: FeedbackAnswers): AsyncGenerator<AnyAction> {
    yield actions.started();
    try {
      await httpClient.POST(`${API_V1_PATH}/feedback/answers`, answers);
      yield actions.succeeded();
    } catch (error: any) {
      console.warn(error);
      yield actions.failed();
    }
  };
});

export const feedbackSelector = (applicationState: ApplicationState): FeedbackState =>
  applicationState.feedback;

export const { reset } = actions;

export default slice.reducer;
