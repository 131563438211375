import { isInternalMember, Member, MemberPayload } from 'sharable/types';

export const convertToMembersPayload = (member: Member): MemberPayload =>
  isInternalMember(member)
    ? {
        access_mode: member.accessMode,
        id: member.value,
      }
    : {
        access_mode: member.accessMode,
        email: member.value,
      };
