import React from 'react';
import { PeerComparisonsColumnGroup } from 'types/peerComparisons';

interface Props {
  readonly columnGroups: readonly PeerComparisonsColumnGroup[];
}

export const QuickLinks: React.FC<Props> = ({ columnGroups }: Props): React.ReactElement => {
  const createGoToHandler =
    (id: string, block: 'nearest' | 'end' = 'nearest'): VoidFunction =>
    (): void => {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: 'smooth', block: block, inline: 'center' });
    };

  return (
    <div className="sticky left-0 flex gap-3 w-max">
      {columnGroups.map(
        (group: PeerComparisonsColumnGroup): React.ReactElement => (
          <p key={group.name} className={linkClassName} onClick={createGoToHandler(group.name)}>
            {group.label}
          </p>
        ),
      )}
      <p className={linkClassName} onClick={createGoToHandler('summary-statistics', 'end')}>
        Summary Statistics
      </p>
      <p className={linkClassName} onClick={createGoToHandler('automated-notes', 'end')}>
        Automated Notes
      </p>
    </div>
  );
};

const linkClassName = 'text-blue cursor-pointer px-1';
