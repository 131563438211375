import { Input } from 'components/forms/input';
import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React from 'react';
import { PortfolioCompanyClientOrCustomerOtherItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerOtherItem;
}

export const ClientOrCustomerOtherForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanyClientOrCustomerOtherItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <div className="col-span-2">
        <Input
          name="productTypeDescription"
          label="Details of Services or Products"
          value={item.productTypeDescription}
          onChange={updaterFor('productTypeDescription')}
        />
      </div>
      <Select
        id="currency"
        label="Currency of billing"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="annualMonetaryIncomeFromClient"
        value={item.annualMonetaryIncomeFromClient}
        label="Annual interest rate earned on cash"
        type="percent"
        onValueChange={updaterFor('annualMonetaryIncomeFromClient')}
      />
    </div>
  );
};
