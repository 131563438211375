import { CSSProperties } from 'react';

export const percent = (numerator: number, denominator: number): string =>
  Math.round((100 * numerator) / denominator) + '%';

export const createClassName = (
  properties: CSSProperties,
  otherClasses?: readonly string[],
): string => {
  const stylesheet = Object.entries(properties)
    .filter(([, value]: [string, any]): boolean => value !== undefined)
    .map(([key, value]: [string, any]): [string, string] => {
      if (typeof value === 'string') {
        return [camelCaseToKebabCase(key), value];
      } else if (typeof value === 'number') {
        return [camelCaseToKebabCase(key), `${value}px`];
      } else {
        throw new Error('unsupported value type');
      }
    })
    .map(([property, value]: [string, string]): string => `${property}: ${value}`)
    .join(';');

  const className =
    'cls-' +
    new Array(5)
      .fill('')
      .map((): string => Math.floor(Math.random() * 0xff).toString(16))
      .join('');
  const newRule = `.${className} {${stylesheet}}`;

  const style = getStyleSheet();
  const sheet = style.sheet;
  if (sheet === null) {
    style.append(document.createTextNode(newRule));
    // We're done
    return [className, ...(otherClasses ?? [])].join(' ');
  } else {
    sheet.insertRule(newRule);

    return [className, ...(otherClasses ?? [])].join(' ');
  }
};

const camelCaseToKebabCase = (value: string): string =>
  value
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();

const getStyleSheet = (): HTMLStyleElement => {
  const { head } = document;

  const element = document.getElementById('truncate-classes');
  if (element) {
    if (element.tagName !== 'STYLE') {
      throw new Error('something strange just happened');
    }

    return element as HTMLStyleElement;
  } else {
    const createdElement = document.createElement('style');

    createdElement.setAttribute('id', 'truncate-classes');
    head.append(createdElement);

    return createdElement as HTMLStyleElement;
  }
};
