import styles from 'assets/styles/spinner.module.scss';
import { Select, SelectItem } from 'components/select';
import React from 'react';
import { textJoiner } from 'utils/textJoiner';
import { Requirement, useRequirementsQuery } from 'views/EarnAndBuy/api';

interface Props {
  readonly value: string;
  onChange(value: string): void;
}

export const TierRequirementDropdown: React.FC<Props> = ({
  value,
  onChange,
}: Props): React.ReactElement => {
  const { data, isFetching } = useRequirementsQuery();

  const requirements = React.useMemo((): Array<SelectItem<string>> => {
    return (
      data?.map((item: Requirement): SelectItem<string> => {
        const { names } = item;

        return {
          value: item.id,
          label: textJoiner(names),
        };
      }) ?? []
    );
  }, [data]);

  React.useEffect((): void => {
    if (value === '' && requirements.length > 0) {
      onChange(requirements[0].value);
    }
  }, [onChange, requirements, value]);

  const fallbackLabel = React.useMemo((): string | React.ReactElement => {
    if (isFetching) {
      return (
        <div className="flex items-center gap-2">
          <div className="w-4 h-4">
            <div className={styles.spinner} />
          </div>
          <p>Loading&hellip;</p>
        </div>
      );
    }

    return 'Choose one';
  }, [isFetching]);

  return (
    <Select
      label="Tier Requirement"
      items={requirements}
      value={value}
      fallbackLabel={fallbackLabel}
      onChange={onChange}
    />
  );
};
