import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePager = (): ((page: number) => void) => {
  const location = useLocation();
  const search = useMemo(
    (): URLSearchParams => new URLSearchParams(location.search),
    [location.search],
  );
  const navigate = useNavigate();
  const currentPageNumber = Number(search.get('page_number'));

  const handler = useCallback(
    (page: number): void => {
      search.set('page_number', page.toFixed(0));

      // Update the URL now
      navigate({ search: search.toString() });
    },
    [navigate, search],
  );

  if (isNaN(currentPageNumber) || currentPageNumber === 0) {
    return handler;
  }

  return handler;
};
