import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export enum DataAndAnalysisMainTabs {
  Popular,
  All,
  My,
}

export const useDataAndAnalysisMainTab = (): DataAndAnalysisMainTabs => {
  const location = useLocation();

  return useMemo((): DataAndAnalysisMainTabs => {
    const { pathname } = location;

    if (pathname.includes('popular')) {
      return DataAndAnalysisMainTabs.Popular;
    } else if (pathname.includes('my')) {
      return DataAndAnalysisMainTabs.My;
    } else {
      return DataAndAnalysisMainTabs.All;
    }
  }, [location]);
};
