import React, { useCallback } from 'react';

export function LegendItem<T, P>({
  data,
  children,
  onHover,
}: React.PropsWithChildren<{
  readonly data: T;
  onHover(data: T | null): void;
}>): React.ReactElement<P> {
  const onMouseEnter = useCallback((): void => onHover(data), [data, onHover]);
  const onMouseLeave = useCallback((): void => onHover(null), [onHover]);

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
}
