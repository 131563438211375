import { ItemCheckbox } from 'components/filters/components/ItemCheckbox';
import React, { useCallback } from 'react';

interface Props {
  readonly label: string;
  readonly value: string;
  readonly checked?: boolean;
  readonly rounded?: boolean;
  readonly disabled?: boolean;

  onChange(value: string, checked: boolean): void;
}

export const Item: React.FC<Props> = ({
  label,
  value,
  checked = false,
  rounded = false,
  disabled = false,
  onChange,
}: Props): React.ReactElement => {
  const onClick = useCallback((): void => {
    if (disabled) {
      return;
    }

    onChange(value, !checked);
  }, [checked, value, disabled, onChange]);

  return (
    <div
      className={`flex items-center font-poppins text-gray text-sm gap-3 p-2${
        disabled ? ' opacity-25' : ''
      }`}
      onClick={onClick}
    >
      <ItemCheckbox checked={checked} rounded={rounded} />
      <label>{label}</label>
    </div>
  );
};
