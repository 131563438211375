import { RECORD_SEPARATOR } from 'assets/constants/recordSeparator';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const searchToObject = (search: URLSearchParams): Record<string, string> => {
  return Object.fromEntries(
    Array.from(search.entries()).map(([key, value]: [string, string]): [string, string] => [
      key,
      value
        .split(RECORD_SEPARATOR)
        .map((value: string): string => {
          if (value.startsWith(' ')) {
            return `+${decodeURIComponent(value.slice(1))}`;
          }

          return decodeURIComponent(value);
        })
        .sort()
        .join(RECORD_SEPARATOR),
    ]),
  );
};

export const useQueryParameters = (): Record<string, string> => {
  const [search] = useSearchParams();

  return React.useMemo((): Record<string, string> => searchToObject(search), [search]);
};
