import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { PeerGroup } from 'redux/reducers/userPeerGroupsReducer';
import { ActionButtons } from 'views/SmartTools/PeerGroups/tools/actionButtons';

const columns: ReadonlyArray<ColumnDefinition<PeerGroup>> = [
  {
    name: 'name',
    label: 'Comp Set',
    type: ColumnType.text,
    alignment: Alignment.left,
    weight: 4,
  },
  {
    name: 'description',
    label: 'Description',
    type: ColumnType.text,
    alignment: Alignment.left,
    weight: 6,
  },
  {
    name: 'number_of_companies',
    label: 'Number of Companies',
    type: ColumnType.numeric,
    alignment: Alignment.center,
    precision: 0,
    weight: 3,
  },
  {
    name: 'id',
    label: '',
    type: ColumnType.custom,
    render: (_: PeerGroup['id'], peerGroup: PeerGroup): React.ReactNode => {
      return <ActionButtons peerGroup={peerGroup} />;
    },
    headerAlignment: Alignment.center,
    width: 153,
  },
];

export default columns;
