import { Clickable } from 'components/clickable';
import { ConditionalRender } from 'components/conditionalRenderer';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/forms/input';
import SVGIcon from 'components/icons/SVGIcon';
import { InfoLabel } from 'components/infoLabel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PeerCompany } from 'redux/services/userPeerGroupsService';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

interface Props {
  readonly id: string;
  readonly value: readonly PeerCompany[];
  readonly name: string;
  readonly error?: string;

  onChange(value: readonly PeerCompany[]): void;
}

export const CompaniesInput: React.FC<Props> = ({
  id,
  value,
  name,
  error,
  onChange,
}: Props): React.ReactElement => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<PeerCompany | null>(null);
  const [searchResult, setSearchResult] = useState<PeerCompany[]>([]);
  const anchor = useRef<HTMLInputElement>(null);

  useEffect((): void => {
    if (selectedCompany) {
      setSearchKeyword(selectedCompany.name);
    }
  }, [selectedCompany]);

  useEffect((): void => {
    const found = value.find((company: PeerCompany): boolean => company.focus);
    if (!found) {
      const first = value[0];
      if (first) {
        onChange([{ ...first, focus: true }, ...value.slice(1)]);
      }
    }
  }, [onChange, value, value.length]);

  useEffect((): void | VoidFunction => {
    if (searchKeyword === '' || selectedCompany) {
      setSearchResult([]);
      return;
    }

    const search = async (): Promise<void> => {
      try {
        const response = await api.get(`${API_V1_PATH}/companies/search/`, {
          params: { keyword: searchKeyword },
        });

        setSearchResult(response.data);
      } catch {
        setSearchResult([]);
      }
      return;
    };

    const timeout = setTimeout(search, 300);
    return (): void => {
      clearTimeout(timeout);
    };
  }, [searchKeyword, selectedCompany]);

  const closeSearchResult = useCallback((): void => setSearchResult([]), []);
  const removeCompanyAt = useCallback(
    (index: number): void => {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    },
    [onChange, value],
  );

  const handleAddCompany = useCallback((): void => {
    if (selectedCompany === null) {
      return;
    }

    if (value.length === 0) {
      onChange([{ ...selectedCompany, focus: true }]);
    } else {
      onChange([...value, selectedCompany]);
    }

    setSelectedCompany(null);
    setSearchKeyword('');
  }, [onChange, selectedCompany, value]);

  const handleFocusCompanyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value: companyId } = event.target;

      onChange(
        value.map(
          (company: PeerCompany): PeerCompany => ({
            ...company,
            focus: company.id === companyId,
          }),
        ),
      );
    },
    [onChange, value],
  );

  return (
    <>
      <div className="flex items-center gap-4">
        <Input
          id={id}
          ref={anchor}
          name={name}
          label="Type to search for companies"
          className="flex-1"
          value={searchKeyword}
          onChange={setSearchKeyword}
        />
        <button
          className={buttonClassName}
          disabled={selectedCompany === null}
          onClick={handleAddCompany}
        >
          Add
        </button>
      </div>
      <Dropdown
        isOpen={searchResult.length > 0}
        anchor={anchor.current}
        onClose={closeSearchResult}
      >
        {searchResult.map(
          (company: PeerCompany): React.ReactElement => (
            <Clickable key={company.id} clickData={company} onClick={setSelectedCompany}>
              <div className="flex items-center justify-between p-3 hover:bg-gray-light">
                <p className="font-poppinsMedium">{company.name}</p>
                <p className="font-poppinsMedium text-blue">{company.ticker}</p>
              </div>
            </Clickable>
          ),
        )}
      </Dropdown>
      <div className="flex flex-col justify-center">
        <div className="flex items-center gap-3">
          <InfoLabel label={`Selected Companies (${value.length})`} tooltipText={tooltipText} />
          {!!error && <p className="text-red text-xs font-poppinsMedium">{error}</p>}
        </div>

        <ConditionalRender renderIf={value.length === 0}>
          <div className="flex items-center justify-center h-44 mt-2">
            <p className="w-full text-center text-gray-medium italic px-32">
              No value added. You need at least two (2) and up to ten (10) value to build the
              comparison group.
            </p>
          </div>
        </ConditionalRender>
        <ConditionalRender renderIf={value.length > 0}>
          <div className="h-44 mt-2 overflow-y-auto scroller">
            {value.map(
              (company: PeerCompany, index: number): React.ReactElement => (
                <div
                  key={company.id}
                  className="flex items-center gap-4 pr-3 py-2 last:border-b-0 border-b border-gray-300"
                >
                  <input
                    type="radio"
                    name="focus-company"
                    checked={company.focus}
                    id={company.id}
                    value={company.id}
                    className="w-5 h-5"
                    onChange={handleFocusCompanyChange}
                  />
                  <div>
                    <p className="font-poppinsMedium">
                      {company.name}
                      {company.focus && (
                        <span className="uppercase text-xs text-blue font-poppinsSemiBold italic ml-2">
                          Focus Company
                        </span>
                      )}
                    </p>
                    <p className="font-poppinsMedium text-gray">{company.ticker}</p>
                  </div>

                  <Clickable clickData={index} onClick={removeCompanyAt}>
                    <div className="ml-auto">
                      <SVGIcon
                        name="trash-generic"
                        className="fill-current w-4 h-4 hover:text-red-600"
                      />
                    </div>
                  </Clickable>
                </div>
              ),
            )}
          </div>
        </ConditionalRender>
      </div>
    </>
  );
};

const buttonClassName =
  'font-poppinsMedium text-base px-3 h-12 text-blue uppercase mt-6 disabled:text-gray-medium';

const tooltipText = (
  <div className="py-1 text-xs font-poppinsMedium">
    <span className="block mb-3">
      One of the companies needs to be selected as the focus company.
    </span>
    <span className="block">
      The focus company is the company that will be used as the base for the comparison.
    </span>
  </div>
);
