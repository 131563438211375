import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isRedirectState } from 'redux/reducers/authReducer';
import { useUrlEmail } from 'views/Landing/hooks/useUrlEmail';

export const useLocationEmail = (): string => {
  const location = useLocation();
  const codeEmail = useUrlEmail('code');

  return useMemo((): string => {
    const { state } = location;

    if (isRedirectState(state)) {
      return state.email;
    }

    return codeEmail;
  }, [codeEmail, location]);
};
