import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { ApplicationModule } from 'context/authorization';
import { withViewAccess } from 'hoc/withViewAccess';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { IndicatorsTable } from 'views/DataAnalysis/Indicators/indicatorsTable';

const unlockMessage = {
  title: 'Unlock Indicators',
  body: 'Please upgrade your plan. Your current plan does not include access to indicators.',
};

const hoc = withViewAccess(ApplicationModule.allIndicators, unlockMessage);

export const Indicators: React.FC = hoc((): React.ReactElement => {
  useEffect(() => {
    mixpanel.track('PageView D&A Indicators');
  }, []);

  return (
    <MainOutletContent>
      <Breadcrumbs path={['DATA & ANALYSIS', 'INDICATORS']} />
      <TextLine content={textLineText} />
      <div className="h-2" />
      <Tabs shrinkFactor={6} variant={TabsVariant.buttons}>
        <Tab
          label="All Indicators"
          element={<IndicatorsTable tab={DataAndAnalysisMainTabs.All} />}
        />
        <Tab
          label="Popular Indicators"
          path="popular"
          element={<IndicatorsTable tab={DataAndAnalysisMainTabs.Popular} />}
        />
      </Tabs>
    </MainOutletContent>
  );
});

const textLineText =
  'Find and keep track of a variety of commodities and indicators. Discover current levels relative to historical averages.';
