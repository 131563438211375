import React from 'react';
import { EventsGroup } from 'redux/reducers/portfoliosReducer';
import { StreamEvent } from 'types/timeline';
import { EventItem } from 'views/SmartTools/TimelineAndEvents/components/eventItem';

interface Props {
  readonly groups?: EventsGroup[];
  readonly compact: boolean;
}

export const EventGroups: React.FC<Props> = ({ groups, compact }: Props): React.ReactElement => {
  return (
    <div className="font-poppins text-gray">
      {groups?.map((group: EventsGroup): React.ReactElement => {
        const { events } = group;

        return (
          <div key={group.id} className="grid grid-cols-2 p-4 border-b">
            <div className="">{group.name}</div>
            <div className={eventListClasses[compact ? 0 : 1]}>
              {events.map(
                (event: StreamEvent): React.ReactElement => (
                  <EventItem key={event.id} event={event} compact={compact} upcoming={false} />
                ),
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const eventListClasses = [
  'flex items-center flex-wrap gap-2',
  'grid grid-cols-4 auto-rows-stream gap-2',
];
