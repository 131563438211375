import { Clock } from 'components/header/UserHeader/components/clock';
import { UserAvatar } from 'components/userAvatar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sessionSelector } from 'redux/reducers/authReducer';
import { defaultOptions } from 'utils/popper';

interface Props {
  onLogout?(): void;
}

export const UserMenu: React.FC<Props> = ({ onLogout }: Props): React.ReactElement => {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [arrow, setArrow] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        withSameWidth: true,
        offset: [8, 12],
        arrow: arrow,
        placement: 'bottom-end',
      }),
    [arrow],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);
  const { user } = useSelector(sessionSelector);
  const { firstname, lastname, subscription } = user;

  const toggleDropdown = useCallback((): void => setIsOpen(!isOpen), [isOpen]);

  useEffect((): void => {
    update?.();
  }, [update]);

  return (
    <div
      className="flex items-center gap-x-2 cursor-pointer relative pr-2"
      onClick={toggleDropdown}
    >
      <UserAvatar ref={setAnchor} user={user} />
      <div className="flex flex-col justify-evenly gap-y-1 pr-3 md:min-w-user-menu">
        <span className="text-gray-darkest pr-2 font-jostSemiBold md:text-lg text-md truncate">
          {firstname} {lastname}
        </span>
        <progress className="w-full" value="100" max="100" />
      </div>
      {/* FIXME: this should not be a dropdown on mobile */}
      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" onClick={(): void => setIsOpen(false)}>
            <div
              ref={setPopup}
              className="profile-modal"
              style={styles.popper}
              {...attributes.popper}
            >
              <div ref={setArrow} style={styles.arrow} className="triangle" />
              <div className="flex-col rounded overflow-hidden bg-white p-3">
                <div className="flex gap-2">
                  <UserAvatar user={user} />
                  <div className="flex flex-col min-w-0 text-left font-poppinsSemiBold text-xs justify-start w-full">
                    <span className="text-black pr-2 font-jostSemiBold profile-name truncate">
                      {firstname} {lastname}
                    </span>
                    <progress className="w-full my-2" value="100" max="100" />
                  </div>
                </div>
                {process.env.REACT_APP_DATA_PORTAL ? null : (
                  <>
                    <hr className="my-3" />
                    <div className="flex items-center text-gray text-sm truncate">
                      <span className="pr-2 flex-1 font-poppinsMedium">Current Plan</span>
                      <p className="rounded-full px-3 bg-blue-600 text-white text-center text-xs py-1.5 font-poppinsSemiBold">
                        {subscription?.name}
                      </p>
                    </div>
                  </>
                )}
                <div className="font-poppins text-xs text-gray text-center py-2 border-t border-b my-3">
                  <Clock />
                </div>
                <div className="flex mt-3 flex-col text-center">
                  {process.env.REACT_APP_DATA_PORTAL
                    ? null
                    : !isMobile && (
                        <>
                          <Link to="profile" className="mb-2">
                            <span className="font-poppinsSemiBold text-blue">View Profile</span>
                          </Link>
                          <Link to="upgrade" className="mb-2">
                            <span className="font-poppinsSemiBold text-blue">Change Plan</span>
                          </Link>
                        </>
                      )}
                  <span
                    onClick={onLogout}
                    className="font-poppins text-gray text-sm cursor-pointer hover:text-blue"
                  >
                    Logout
                  </span>
                </div>
              </div>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};
