import SpinnerLoader from 'components/spinnerLoader';
import { loadingNode } from 'components/valueChain/specialLoadingNode';
import ValueChainMain from 'components/valueChain/valueChainMain';
import { useGtag } from 'hooks/useGtag';
import React, { useEffect, useMemo } from 'react';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';

interface Props {
  readonly rootNodeId?: string;
  readonly rootNodeLabel?: string;
  readonly rootNodeType: ValueChainEntityType;
  readonly currentNode: ValueChainNode | undefined;

  onClear(): void;
}

const ValueChain: React.FC<Props> = ({
  rootNodeId,
  rootNodeLabel,
  rootNodeType,
  currentNode,
  onClear,
}: Props): React.ReactElement => {
  const { trackEvent } = useGtag();

  const rootNode = useMemo((): ValueChainNode | null => {
    if (!rootNodeId || !rootNodeLabel) {
      return null;
    }

    return {
      entityId: rootNodeId,
      label: rootNodeLabel,
      entityType: rootNodeType,
      count: null,
      ownerOrganization: null,
      children: [loadingNode],
    };
  }, [rootNodeId, rootNodeLabel, rootNodeType]);

  useEffect((): void => {
    if (!rootNode || !currentNode?.entityId) {
      return;
    }

    trackEvent('value-chain', {
      rootCompany: rootNode.label,
      entityType: currentNode?.entityType,
      entityId: currentNode?.entityId,
      entityName: currentNode?.label,
    });
  }, [currentNode?.entityId, currentNode?.label, currentNode?.entityType, rootNode, trackEvent]);

  return (
    <>
      <ValueChainMain rootNode={rootNode} onClear={onClear} />
      <SpinnerLoader visible={!rootNode} />
    </>
  );
};

export default ValueChain;
