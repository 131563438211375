import React, { useMemo } from 'react';

export type DynamicColor = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

interface OwnProps {
  readonly size: 'small' | 'normal';
  readonly color: 'blue' | 'green' | DynamicColor;

  readonly disableHover?: boolean;

  onClick?(): void;
}

type Props = React.PropsWithoutRef<React.PropsWithChildren<OwnProps>> &
  React.RefAttributes<HTMLDivElement>;

export const MarkerDot: React.FC<Props> = React.forwardRef(function MarkerDot(
  { children, size, color, disableHover = false, onClick }: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const computedColor = useMemo((): string => {
    switch (color) {
      case 'blue':
        return 'bg-blue';
      case 'green':
        return 'bg-green-700';
      default:
        return `bg-marker-${color}`;
    }
  }, [color]);

  const computedSize = useMemo((): number => {
    switch (size) {
      case 'small':
        return 4;
      case 'normal':
        return 5;
    }
  }, [size]);

  const outerClassName = useMemo((): string => {
    const classes = [
      'relative',
      `w-${computedSize - 2}`,
      `h-${computedSize - 2}`,
      'm-1',
      'rounded-full',
      computedColor,
      ...(disableHover ? [] : ['hover:transform', 'hover:scale-150', 'transition-all']),
      'z-1',
    ];

    return classes.join(' ');
  }, [computedColor, computedSize, disableHover]);

  const innerClassName = useMemo((): string => {
    const classes = [
      'absolute',
      `w-${computedSize}`,
      `h-${computedSize}`,
      'rounded-full',
      'bg-inherit',
      'opacity-30',
      '-top-1',
      '-left-1',
      'z-0',
    ];

    return classes.join(' ');
  }, [computedSize]);

  return (
    <div ref={ref} className={outerClassName} onClick={onClick}>
      <div className={innerClassName} />
      <>{children}</>
    </div>
  );
});
