import React, { useMemo } from 'react';
import { amountFormatter } from 'views/Checkout/SubscriptionCheckout/formatters';
import { Discount, PaymentIntentPayment } from 'views/Checkout/SubscriptionCheckout/payment';

interface Props {
  readonly payment: PaymentIntentPayment;
}

interface SummaryEntry {
  readonly type: 'subtotal' | 'discount' | 'total';
  readonly label: string;
  readonly amount: number;
}

export const InvoiceView: React.FC<Props> = ({ payment }: Props): React.ReactElement => {
  const { invoice } = payment;

  const entries = useMemo((): readonly SummaryEntry[] => {
    const discountLabel = getDiscountLabel(invoice.discount);

    return [
      {
        type: 'subtotal',
        label: 'Subtotal',
        amount: invoice.sub_total,
      },
      ...(discountLabel
        ? [
            {
              type: 'discount' as const,
              label: discountLabel,
              amount: invoice.total - invoice.sub_total,
            },
          ]
        : []),
      {
        type: 'total',
        label: 'Total',
        amount: invoice.total,
      },
    ];
  }, [invoice.discount, invoice.sub_total, invoice.total]);

  return (
    <div className="text-white border-t mt-6 pt-4 leading-8">
      {entries.map((entry: SummaryEntry) => (
        <div key={entry.label} className={entryClassName[entry.type]}>
          <span className="font-poppinsMedium">{entry.label}</span>
          <span className="font-poppinsSemiBold text-right">
            {amountFormatter.format(entry.amount / 100)}
          </span>
        </div>
      ))}
    </div>
  );
};

const entryClassName: { [key in SummaryEntry['type']]: string } = {
  subtotal: 'flex items-center justify-between',
  discount: 'flex items-center justify-between opacity-60',
  total: 'flex items-center justify-between text-xl mt-4',
};

const getDiscountLabel = (discount: Discount | undefined): string | undefined => {
  if (!discount) {
    return undefined;
  }

  switch (discount.type) {
    case 'percentage':
      return `Discount (${discount.percent_off}% off)`;
    case 'amount':
      return `Discount (${amountFormatter.format(discount.amount_off / 100)} off)`;
    default:
      return undefined;
  }
};
