import { ConditionalRender } from 'components/conditionalRenderer';
import { Disabler } from 'components/disabler';
import { HorizontalExpander } from 'components/horizontalExpander';
import SVGIcon from 'components/icons/SVGIcon';
import Toggle from 'components/toggle/Toggle';
import { noop } from 'lodash';
import React from 'react';

interface Props {
  readonly noSelectableItems: boolean;
  readonly allSelected: boolean;

  onSelectAll(): void;
}

export const SelectAllAndToggleLinkedItemsBar: React.FC<Props> = ({
  noSelectableItems,
  allSelected,
  onSelectAll,
}: Props): React.ReactElement => {
  return (
    <div className="flex items-center mb-4 font-poppins text-sm text-gray">
      <ConditionalRender renderIf={!noSelectableItems}>
        <button
          className="font-poppinsSemiBold text-sm text-blue cursor-pointer uppercase"
          onClick={onSelectAll}
        >
          {allSelected ? 'Unselect All' : 'Select All'}
        </button>
      </ConditionalRender>
      <HorizontalExpander />

      <div>
        <div className="inline-block align-middle">
          <SVGIcon name="upcoming-events-icon" className="h-5" />
        </div>
        <span className="inline-block align-middle ml-2">Upcoming Events</span>
      </div>

      <Disabler disabled={true}>
        <div className="ml-8">
          <Toggle label="Linked Items" onChange={noop} disabled={true} />
        </div>
      </Disabler>
    </div>
  );
};
