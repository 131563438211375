import axios from 'axios';
import SVGIcon from 'components/icons/SVGIcon';
import { Modal } from 'components/modal';
import { Tooltip } from 'components/tooltip';
import { Breadcrumbs } from 'components/valueChain/breadcrumbs';
import { ValueChain } from 'components/valueChain/index';
import { loadingNode } from 'components/valueChain/specialLoadingNode';
import ValueChainTable from 'components/valueChain/table';
import { PaymentRequiredErr } from 'constants/paymentRequiredError';
import { useModal } from 'hooks/useModal';
import { useQueryParameters } from 'hooks/useQueryParameters';
import mixpanel from 'mixpanel-browser';
import ExceedAccess from 'modals/exceedAccess';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addValueChainNode,
  destroyValueChain,
  goToValueChainNodeAt,
  resetValueChain,
  resetValueChainError,
  valueChainStateSelector,
} from 'redux/reducers/valueChainReducer';
import {
  loadMoreValueChainTableData,
  loadValueChainCategoryChildren,
  loadValueChainOrganizationCategories,
  loadValueChainTableData,
} from 'redux/services/valueChainServices';
import { ValueChainEntityType, ValueChainNode } from 'types/organization/types';

interface Props {
  readonly rootNode: ValueChainNode | null;

  onClear(): void;
}

const ValueChainMain: React.FC<Props> = ({ rootNode, onClear }: Props): React.ReactElement => {
  const queryParameters = useQueryParameters();
  const dispatch = useDispatch<any>();
  const state = useSelector(valueChainStateSelector);
  const valueChainRef = useRef<HTMLDivElement>(null);
  const accessModal = useModal();

  const { loading, stack: chain, table, error } = state;

  const currentNode = useMemo((): ValueChainNode | null => {
    if (chain.length === 0) {
      return null;
    }

    return chain[chain.length - 1];
  }, [chain]);

  const closestOrganizationNode = useMemo((): ValueChainNode | null => {
    return (
      chain
        .slice() //
        .reverse()
        .find(isCompanyNode) ?? null
    );
  }, [chain]);

  const organizationName = useMemo(
    (): string => closestOrganizationNode?.label ?? '',
    [closestOrganizationNode?.label],
  );

  const organizationId = useMemo(
    (): string => closestOrganizationNode?.entityId ?? '',
    [closestOrganizationNode?.entityId],
  );

  const handleClear = useCallback((): void => {
    dispatch(resetValueChain());
    onClear();
  }, [dispatch, onClear]);

  const handleLoadMore = useCallback((): void => {
    dispatch(
      loadMoreValueChainTableData(organizationId, {
        page_number: table.page_number + 1,
        page_size: 20,
      }),
    );
  }, [dispatch, organizationId, table.page_number]);

  const handleGoBackTo = useCallback(
    (nodePosition: number): void => {
      dispatch(goToValueChainNodeAt(nodePosition + 1));
    },
    [dispatch],
  );

  const handleChildNodeClick = useCallback(
    (node: ValueChainNode) => {
      dispatch(addValueChainNode({ ...node, children: [loadingNode] }));
    },
    [dispatch],
  );

  const loadChildren = useCallback(
    (node: ValueChainNode, parameters: Record<string, string> = {}): VoidFunction => {
      const { entityType, ownerOrganization: parent } = node;
      const query = { page_number: 1, page_size: 20, ...parameters };
      const tokenSource = axios.CancelToken.source();

      switch (entityType) {
        case ValueChainEntityType.company:
          dispatch(loadValueChainOrganizationCategories(node, parameters, tokenSource.token));
          dispatch(loadValueChainTableData(node.entityId, query, tokenSource.token));
          break;
        case ValueChainEntityType.industry:
          dispatch(loadValueChainOrganizationCategories(node, parameters, tokenSource.token));
          dispatch(loadValueChainTableData(node.entityId, query, tokenSource.token));
          break;
        default:
          if (parent !== null) {
            dispatch(loadValueChainCategoryChildren(node, query, tokenSource.token));
          }
          break;
      }

      return (): void => {
        tokenSource.cancel();
      };
    },
    [dispatch],
  );

  const initialize = useCallback((): void => {
    dispatch(destroyValueChain());
    if (rootNode !== null) {
      dispatch(addValueChainNode(rootNode));
    }
  }, [dispatch, rootNode]);

  const closeAccessModal = useCallback((): void => {
    dispatch(resetValueChainError());
    accessModal.close();
  }, [accessModal, dispatch]);

  const newNode = useMemo(
    (): ValueChainNode => ({
      entityId: currentNode?.entityId ?? '',
      label: currentNode?.label ?? '',
      ownerOrganization: currentNode?.ownerOrganization ?? null,
      entityType: currentNode?.entityType ?? ValueChainEntityType.other,
      count: null,
    }),
    [
      currentNode?.entityId,
      currentNode?.entityType,
      currentNode?.ownerOrganization,
      currentNode?.label,
    ],
  );

  useEffect((): void => {
    if (error === null) {
      return;
    }

    if (error === PaymentRequiredErr) {
      accessModal.open();
    } else {
      console.warn(error);
    }
  }, [accessModal, error]);

  useEffect((): void => {
    valueChainRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [chain.length]);

  useEffect((): VoidFunction => {
    return loadChildren(newNode, queryParameters);
  }, [newNode, loadChildren, queryParameters]);

  useEffect((): void => {
    mixpanel.track('PageView D&A Company Value Chain');
  }, []);

  // Load the initial object
  useEffect(initialize, [initialize]);

  const totalCount = useMemo((): number => {
    if (chain.length === 1) {
      return chain[0].children?.length ?? 0;
    }

    return 0;
  }, [chain]);

  if (rootNode === null) {
    return <div />;
  }

  return (
    <>
      <div className="relative flex-grow">
        <div className="flex items-center">
          <Breadcrumbs chain={chain} onClear={handleClear} onGoToNodeAt={handleGoBackTo} />
          <Tooltip
            content={<p className="p-3 w-96 leading-6">{informationTooltipText}</p>}
            fitContent={true}
          >
            <div className="w-5 h-5 mx-2">
              <SVGIcon name="info-icon" />
            </div>
          </Tooltip>
        </div>
        <button
          onClick={handleClear}
          className="font-bold font-poppins text-sm text-blue mt-6 flex items-center focus:outline-none"
        >
          RESET
          <SVGIcon name="refresh-icon" className="w-3.5 h-3.5 mx-2" />
        </button>
        <div className="w-full overflow-x-auto overflow-y-hidden">
          <ValueChain
            chain={chain}
            totalCount={totalCount}
            loading={loading}
            onChildClick={handleChildNodeClick}
            onGoToNodeAt={handleGoBackTo}
          />
        </div>
        <ValueChainTable
          page={table}
          title={organizationName}
          linkPath="/data-analysis/company/details"
          onLoadMore={handleLoadMore}
        />
      </div>
      <Modal isOpen={accessModal.isOpen} onClose={closeAccessModal}>
        <Modal.Content>
          <ExceedAccess
            title="Unlock Value Chains"
            description="Upgrade your plan to navigate through the value chain."
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ValueChainMain;

const isCompanyNode = (node: ValueChainNode): node is ValueChainNode =>
  node.entityType === ValueChainEntityType.company;

const informationTooltipText =
  'This is an interactive value chain that shows some possible relationships between select ' +
  'elements or stakeholders.\n' +
  'Click on the circles to discover the next link in the chain.\n' +
  'Check the table below for details or more items and add those to the value chain as applicable. ' +
  'Note that this list is non-exhaustive, for educational purposes and may not be up to date.\n' +
  'Do your own research to verify and speak to a financial advisor as required.';
