import React from 'react';
import { PortfolioArtFineWinesOrBeveragesItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioArtFineWinesOrBeveragesItem;
}

export const FineWinesOrBeveragesArtItemForm: React.FC<Props> = ({
  item: _,
}: Props): React.ReactElement => {
  return <></>;
};
