import { CellRenderer } from 'components/table/cellRenderer';
import styles from 'components/table/table.module.scss';
import { ChildrenRows, ColumnDefinition, ColumnType, Identifiable } from 'components/table/types';
import React from 'react';

interface Props<R extends Identifiable> {
  readonly data?: ChildrenRows<R>;
  readonly showHeader: boolean;
  readonly parentSpan: number;
  onClick(): void;
}

const alignment = (column: ColumnDefinition<any>): string | undefined => {
  switch (column.type) {
    case ColumnType.level:
      return undefined;
    case ColumnType.text:
    case ColumnType.numeric:
    case ColumnType.percent:
      if (!column.alignment) {
        return undefined;
      }

      return styles[column.alignment];
    case ColumnType.custom:
      if (!column.headerAlignment) {
        return undefined;
      }

      return styles[column.headerAlignment];
  }
};

export function RowChildren<R extends Identifiable>({
  data,
  showHeader,
  parentSpan,
  onClick,
}: Props<R>): React.ReactElement {
  const { columns = [], rows = [] } = data ?? {};

  if (!data) {
    return <></>;
  }

  return (
    <tr className={styles.wrapper}>
      <td colSpan={parentSpan}>
        <table>
          {showHeader && (
            <thead>
              <tr className={styles.childrenHeader}>
                {columns.map(
                  (column: ColumnDefinition<R>): React.ReactElement => (
                    <th key={String(column.name)} className={alignment(column)}>
                      {column.label}
                    </th>
                  ),
                )}
              </tr>
            </thead>
          )}
          <tbody>
            {rows.map((row: R): React.ReactElement => {
              return (
                <tr key={row.id} className={styles.custom} onClick={onClick}>
                  {columns.map(
                    (column: ColumnDefinition<R>): React.ReactElement => (
                      <td key={String(column.name)} className="group" colSpan={column.span ?? 1}>
                        <CellRenderer column={column} value={row[column.name]} row={row} />
                      </td>
                    ),
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    </tr>
  );
}
