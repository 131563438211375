import { Input } from 'components/forms/input';
import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React from 'react';
import { PortfolioCompanySupplierItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanySupplierItem;
}

export const SupplierItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCompanySupplierItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <Input
        name="producsOrServicesSupplied"
        label="Products or services supplied"
        value={item.productsOrServicesSupplied}
        onChange={updaterFor('productsOrServicesSupplied')}
      />
      <Select
        id="currency"
        label="Currency of billing"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <div />
      <NumericInput
        name="annualAmountPaidToSupplier"
        value={item.annualAmountPaidToSupplier}
        label="Annual amount paid to supplier"
        onValueChange={updaterFor('annualAmountPaidToSupplier')}
      />
    </div>
  );
};
