import decreaseIcon from 'assets/icons/svg/decrease-icon.svg';
import increaseIcon from 'assets/icons/svg/increase-icon.svg';
import React, { useMemo } from 'react';
import { formatPercentage } from 'utils';

interface Props {
  readonly value?: number;
}

export const ChangeCell: React.FC<Props> = ({ value }: Props): React.ReactElement | null => {
  const iconAndColor = useMemo((): any => getMarketIconAndColor(value), [value]);
  const formattedValue = useMemo((): string | null => {
    if (value === null || value === undefined) {
      return null;
    } else if (value > 0 || value < 0) {
      return `${formatPercentage(value)}`;
    } else {
      return '0';
    }
  }, [value]);

  if (value === undefined) {
    return null;
  }

  return (
    <>
      <span className={`${iconAndColor.color} px-2`}>{formattedValue}</span>
      {iconAndColor.icon ? (
        <img src={iconAndColor.icon} alt="direction icon" className="w-5 h-5" />
      ) : (
        <div className="w-5 h-5" />
      )}
    </>
  );
};

const getMarketIconAndColor = (marketPrice: number | undefined | null) => {
  if (marketPrice === null || marketPrice === undefined) {
    return { icon: null };
  } else if (marketPrice > 0) {
    return { color: 'text-green', icon: increaseIcon };
  } else if (marketPrice < 0) {
    return { color: 'text-red', icon: decreaseIcon };
  } else {
    return { color: 'text-gray-medium', icon: null };
  }
};
