import { Clickable } from 'components/clickable';
import { DefaultButton } from 'components/DEPRECATED/select/defaultButton';
import { DefaultItem } from 'components/DEPRECATED/select/defaultItem';
import { Input } from 'components/forms/input';
import { InputBase } from 'components/inputBase';
import { KeyboardListener } from 'components/keyboardListener';
import classes from 'components/select/select-style.module.scss';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { defaultOptions } from 'utils/popper';

export interface SelectItem<T> {
  readonly value: NonNullable<T>;
  readonly label: string;

  readonly disabled?: boolean;
}

export type SelectButtonProps<T> = {
  readonly fallbackLabel?: string;
  readonly value: T | null;
  readonly readOnly?: boolean;

  onClick?(): void;
} & React.PropsWithRef<any>;

export interface SelectItemProps<T> {
  readonly label: string;
  readonly value: NonNullable<T>;
  readonly disabled?: boolean;

  onClick?(): void;
}

interface Props<T> {
  readonly id?: string;

  readonly items: ReadonlyArray<SelectItem<NonNullable<T>>>;
  readonly value: T | null;
  readonly label: string;

  readonly buttonComponent?: React.FunctionComponent<React.PropsWithRef<SelectButtonProps<T>>>;
  readonly itemComponent?: React.FunctionComponent<SelectItemProps<T>>;

  readonly fallbackLabel?: string | React.ReactElement;
  readonly readOnly?: boolean;

  onChange(value: T): void;
}

export function Select<T>({
  id,
  items,
  label: selectLabel,
  value,
  readOnly,
  fallbackLabel,
  buttonComponent,
  itemComponent,
  onChange,
}: Props<T>): ReactElement {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [popup, setPopup] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popperOptions = useMemo(
    (): any => defaultOptions(document.body, { withSameWidth: true, offset: [0, 4] }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  const togglePopup = useCallback((): void => {
    if (readOnly) {
      return;
    }

    setIsOpen((isOpen: boolean): boolean => !isOpen);
  }, [readOnly]);

  const closePopup = useCallback((): void => {
    setIsOpen(false);
  }, []);

  const handleClick = useCallback(
    (value: T): void => {
      anchor?.focus();

      onChange(value);
      closePopup();
    },
    [anchor, closePopup, onChange],
  );

  const Button = useMemo(
    (): React.FunctionComponent<SelectButtonProps<T>> => buttonComponent ?? DefaultButton,
    [buttonComponent],
  );

  const ItemComponent = useMemo((): React.FunctionComponent<SelectItemProps<T>> => {
    return itemComponent ?? DefaultItem;
  }, [itemComponent]);

  useEffect((): void => {
    update?.();
  }, [update]);

  const label = React.useMemo((): string | React.ReactElement => {
    const found = items.find((item: SelectItem<T>): boolean => item.value === value);
    if (!found) {
      return <span className="text-gray-400 italic">{fallbackLabel}&nbsp;</span>;
    } else {
      return found.label;
    }
  }, [fallbackLabel, items, value]);

  const inputValue = useMemo((): string => {
    if (!value) {
      return ' ';
    } else if (typeof value === 'number') {
      return value.toString();
    } else if (typeof value === 'string') {
      return value;
    } else {
      return '';
    }
  }, [value]);

  const handleKeyPressed = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): boolean => {
      if (event.key === 'Escape') {
        closePopup();
      }

      return false;
    },
    [closePopup],
  );

  const itemValue = useMemo((): string => {
    return (
      items.find((itemType: SelectItem<any>): boolean => itemType.value === value)?.label ??
      'Unknown Type'
    );
  }, [items, value]);

  if (readOnly) {
    return <Input value={itemValue} name="" readOnly={true} label={selectLabel} />;
  }

  return (
    <KeyboardListener onKeyPressed={handleKeyPressed}>
      <InputBase
        id={id}
        ref={setAnchor}
        readOnly={readOnly}
        name=""
        value={inputValue}
        label={selectLabel}
      >
        <Button
          label={label}
          fallbackLabel={fallbackLabel}
          readOnly={readOnly}
          onClick={togglePopup}
        />
      </InputBase>

      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" tabIndex={-1} onClick={closePopup}>
            <div
              ref={setPopup}
              style={styles.popper}
              className={popupClassName}
              {...attributes.popper}
            >
              <div className={classes.popup}>
                {items.map((item: SelectItem<T>): React.ReactElement => {
                  return (
                    <Clickable key={item.label} clickData={item.value} onClick={handleClick}>
                      <ItemComponent
                        label={item.label}
                        value={item.value}
                        disabled={item.disabled}
                      />
                    </Clickable>
                  );
                })}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </KeyboardListener>
  );
}

const popupClassName = 'flex flex-col rounded-b overflow-hidden shadow-md bg-white';
