import { Select, SelectItem } from 'components/select';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setProductType } from 'redux/reducers/addPortfolioItemFormReducer';
import {
  PortfolioCompanyClientOrCustomerItem,
  PortfolioCompanyProductType,
  portfolioCompanyProductTypeLabels,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { ClientOrCustomerItemFormSelector } from 'views/Portfolios/PortfoliosModal/clientOrCustomerItemFormSelector';

interface Props {
  readonly item: PortfolioCompanyClientOrCustomerItem;
}

export const ClientOrCustomerItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const handleProductTypeChange = useCallback(
    (value: PortfolioCompanyProductType): void => {
      dispatch(setProductType(value));
    },
    [dispatch],
  );

  return (
    <>
      <Select
        id="typeOfProduct"
        label="Type of Product"
        items={productTypes}
        value={item.productType}
        onChange={handleProductTypeChange}
      />

      <ClientOrCustomerItemFormSelector item={item} />
    </>
  );
};

const productTypes: ReadonlyArray<SelectItem<PortfolioCompanyProductType>> = enumToSelectItems(
  PortfolioCompanyProductType,
  portfolioCompanyProductTypeLabels,
);
