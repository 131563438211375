import React from 'react';
import { PortfolioRealEstateItem, RealEstatePurpose } from 'types/portfolioItem';
import { CommercialRealEstateForm } from 'views/Portfolios/PortfoliosModal/commercialRealEstateForm';
import { IndustrialRealEstateForm } from 'views/Portfolios/PortfoliosModal/industrialRealEstateForm';
import { InvestmentResidentialRealEstateForm } from 'views/Portfolios/PortfoliosModal/investmentResidentialRealEstateForm';
import { YourResidenceRealEstateForm } from 'views/Portfolios/PortfoliosModal/yourResidenceRealEstateForm';

interface Props {
  readonly item: PortfolioRealEstateItem;
}

export const RealEstatePurposeSelector: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  switch (item.purpose) {
    case RealEstatePurpose.land:
      return <></>;
    case RealEstatePurpose.investmentResidential:
      return <InvestmentResidentialRealEstateForm item={item} />;
    case RealEstatePurpose.commercial:
      return <CommercialRealEstateForm item={item} />;
    case RealEstatePurpose.industrial:
      return <IndustrialRealEstateForm item={item} />;
    case RealEstatePurpose.yourResidence:
      return <YourResidenceRealEstateForm item={item} />;
    default:
      return <></>;
  }
};
