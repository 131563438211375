import { CountryFlag } from 'components/countryFlag';
import React from 'react';

interface Props {
  readonly name: string;
  readonly iso2: string | null;
}

export const CountryNameWithFlag: React.FC<Props> = ({ name, iso2 }: Props): React.ReactElement => {
  return (
    <div className="flex items-center">
      <div className="w-8 text-center">
        <CountryFlag iso2={iso2} />
      </div>
      <div className="truncate flex-1 overflow-ellipsis leading-5 min-w-0">{name}</div>
    </div>
  );
};
