import { ReactComponent as DownArrow } from 'assets/icons/svg/basic-down-arrow.svg';
import { Dropdown } from 'components/dropdownFilter/Dropdown';
import { Item } from 'components/dropdownFilter/Item';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ModifierOptions } from 'utils/popper';

export interface DropdownItem<T extends string = string> {
  readonly id: T;
  readonly label: string;
  readonly icon?: React.FunctionComponent;
}

interface Props<T extends string = string> {
  readonly selectedId: string;
  readonly options: ReadonlyArray<DropdownItem<T>>;
  readonly id?: string;
  readonly labelText?: string;
  readonly fullWidth?: boolean;
  readonly preventOverflow?: boolean;

  onSelect(id: string): void;
}

function DropdownFilter<T extends string = string>({
  id,
  options,
  selectedId,
  fullWidth = false,
  preventOverflow = true,
  labelText = '',
  onSelect,
}: Props<T>): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const hideDropdown = useCallback((): void => setIsOpen(false), []);
  const showDropdown = useCallback((): void => setIsOpen(true), []);
  const dropdownOptions = useMemo(
    (): ModifierOptions => ({
      preventOverflow: preventOverflow,
      withSameWidth: true,
    }),
    [preventOverflow],
  );

  const selectedOption = useMemo(
    (): DropdownItem<T> | undefined =>
      options.find((item: DropdownItem<T>): boolean => item.id === selectedId),
    [options, selectedId],
  );

  const handleItemClick = useCallback(
    (id: T): void => {
      hideDropdown();
      onSelect(id);
    },
    [onSelect, hideDropdown],
  );

  const containerClassName = useMemo(
    (): string => `relative ${fullWidth ? 'w-full' : 'w-48'}`,
    [fullWidth],
  );

  return (
    <div id={id} ref={ref} onClick={showDropdown} className={containerClassName}>
      <div className={contentClassName}>
        <p className="text-white font-poppinsMedium">
          {labelText}
          {selectedOption?.label ?? 'Unknown'}
        </p>
        <div className="text-white">
          <DownArrow className="w-3 fill-current" />
        </div>
      </div>
      <Dropdown
        isOpen={isOpen}
        anchor={ref.current}
        options={dropdownOptions}
        onClose={hideDropdown}
      >
        {options.map(
          (option: DropdownItem<T>): React.ReactElement => (
            <Item key={option.id} item={option} onClick={handleItemClick} />
          ),
        )}
      </Dropdown>
    </div>
  );
}

export default DropdownFilter;

const contentClassName =
  'flex items-center justify-between bg-blue py-1.5 px-3 border border-blue rounded cursor-pointer';
