import SVGIcon from 'components/icons/SVGIcon';
import { Modal } from 'components/modal';
import { useModal } from 'hooks/useModal';
import React from 'react';

export const QuickStartButton: React.FC = (): React.ReactElement => {
  const modal = useModal();

  return (
    <>
      <div className="flex items-center">
        <p className="font-poppinsSemiBold text-gray uppercase text-sm cursor-default cursor-pointer">
          QUICK START
        </p>
        <SVGIcon name="info-icon" className="w-4 mx-1.5 cursor-pointer" onClick={modal.open} />
      </div>
      <Modal isOpen={modal.isOpen} onClose={modal.close}>
        <Modal.Content>
          <div className="w-modal-sm">
            <h1>Quick Start</h1>
            <p className="py-3">
              Created by financial professionals, Capnote is the modern productivity platform for
              finance and investing! It uses patented technology to help you automate financial
              analysis, save time and perform better.
            </p>
            <p>Below are a few suggested steps to help you get started.</p>
            <ul className="px-4 font-poppins text-sm list-disc">
              <li className="my-3">
                Build your watchlist and portfolios for better usage. Capnote personalizes your
                experience based on the items you have in these. For example, you will receive
                timeline and event notifications based on the items in your watchlist and
                portfolios.
              </li>
              <li className="my-3">
                Scroll down the feed to see news and notifications that may impact your finances.
                You will get recent news, posts from your connections and system notifications from
                Capnote informing you about things you should pay attention to.
              </li>
              <li className="my-3">
                Use the search bar at the top of the page to find companies and industries for your
                research or connections to expand your network. You can also find these by clicking
                on Data & Analysis and using the appropriate filters. Scroll to the bottom of each
                financial statement for automated notes generated using our algorithms. We keep
                adding to these all the time! Automate your research and report writing by
                downloading a 1-Click Report. You can find this on every company page. Professionals
                and investors use the 1-Click Report as a basis for risk or investment memos. It
                will save you up to a week’s worth of time.
              </li>
              <li className="my-3">
                Complete your profile and set your preferences. Capnote aims to deliver a highly
                personalized experience that supports your objectives. By letting us know your
                interests, we can get better at supporting you over time. Welcome to Capnote - the
                modern productivity platform for finance and investing!
              </li>
            </ul>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
