import { Time } from 'types/time';
import { EventDateTime } from 'utils/dateTime';

const timeStringFromParts = (parts: Intl.DateTimeFormatPart[]): string => {
  const dayPeriod = parts.find(p => p.type === 'dayPeriod')?.value;
  const hour = parts.find(p => p.type === 'hour')?.value;
  const minute = parts.find(p => p.type === 'minute')?.value;

  if (hour === undefined || minute === undefined || dayPeriod === undefined) {
    throw new Error('invalid time');
  }

  return `${hour}:${minute} ${dayPeriod}`;
};

const dateStringFromParts = (parts: Intl.DateTimeFormatPart[]): string => {
  const weekday = parts.find(p => p.type === 'weekday')?.value;
  const day = parts.find(p => p.type === 'day')?.value;
  const month = parts.find(p => p.type === 'month')?.value;
  const year = parts.find(p => p.type === 'year')?.value;

  return `${weekday}, ${month} ${day} ${year}`.toUpperCase();
};

interface FormattedDateTime {
  readonly date: string | null;
  readonly time: string | null;
}

const combineDateAndTime = (date: Date, time: Time): Date =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.hours,
    time.minutes,
    time.seconds,
  );

const getFormattedDateAndTime = (
  originalDate: Date | null,
  time: Time | null,
  originalTimezone: string,
  timezone: string,
): FormattedDateTime => {
  const formatter = new Intl.DateTimeFormat('en', {
    timeZone: timezone,
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: 'short',
    year: '2-digit',
    weekday: 'long',
  });
  // If date is `null` it is safe to use any date, so we choose
  // current date
  const date = originalDate ?? new Date();
  // Build a local date with the exact same values as the original day + time
  const utc = time !== null ? combineDateAndTime(date, time) : date;

  try {
    // Convert to UTC
    const parts = formatter.formatToParts(utc);

    return {
      date: originalDate !== null ? dateStringFromParts(parts) : null,
      time: time !== null ? timeStringFromParts(parts) : null,
    };
  } catch {
    return {
      date: '',
      time: null,
    };
  }
};

export const useEventDateTime = (
  startDate: Date,
  startTime: Time | null,
  endDate: Date | null,
  endTime: Time | null,
  originalTimezone: string,
  currentTimezone: string,
): EventDateTime => {
  const isWholeDay = endDate === null && startTime === null && endTime === null;
  const formattedStartDateTime = getFormattedDateAndTime(
    startDate,
    startTime,
    originalTimezone,
    currentTimezone,
  );
  const formattedEndDateTime = getFormattedDateAndTime(
    endDate,
    endTime,
    originalTimezone,
    currentTimezone,
  );

  const formattedStartDate = formattedStartDateTime.date;
  if (formattedStartDate === null) {
    throw new Error('start date is mandatory');
  }

  return {
    startDate: formattedStartDate,
    startTime: formattedStartDateTime.time,
    endDate: formattedEndDateTime.date,
    endTime: formattedEndDateTime.time,
    isWholeDay: isWholeDay,
    timezone: currentTimezone,
  };
};
