import { GraphNodeLabel } from 'components/valueChain/graphNodeLabel';
import { loadingNode } from 'components/valueChain/specialLoadingNode';
import React, { useCallback, useMemo } from 'react';
import { ValueChainNode } from 'types/organization/types';

type ChildNodeFn = (node: ValueChainNode) => void;

interface Props {
  readonly node: ValueChainNode;
  readonly withRing: boolean;

  onClick?(node: ValueChainNode): void;
}

export const GraphNodeContent: React.FC<Props> = ({
  node,
  withRing,
  onClick,
}: Props): React.ReactElement => {
  const { label, count } = node;

  const containerClassName = useMemo(
    (): string => getContainerClassName(count, onClick),
    [count, onClick],
  );

  const labelClassName = useMemo((): string => getLabelClassName(count), [count]);

  const handleClick = useCallback((): void => onClick?.(node), [node, onClick]);
  if (node.entityId === loadingNode.entityId) {
    return <div className="w-24 h-24 rounded-full bg-gray-light" />;
  }

  return (
    <div className={withRing ? 'p-2 border-4 border-blue rounded-full' : undefined}>
      <div className={containerClassName} onClick={handleClick}>
        <div className={labelClassName}>
          <GraphNodeLabel label={label} count={count} />
        </div>
      </div>
    </div>
  );
};

const getContainerClassName = (count: number | null, onClick?: ChildNodeFn): string => {
  const base = 'flex align-center justify-center w-24 h-24 rounded-full bg-white shadow-md p-1';
  if (count !== 0 && onClick === undefined) {
    return [base, 'cursor-default'].join(' ');
  } else if (count === 0) {
    return [base, 'cursor-default bg-gray-light'].join(' ');
  } else {
    return [base, 'hover:bg-blue-light cursor-pointer'].join(' ');
  }
};

const getLabelClassName = (count: number | null): string => {
  const base = 'flex p-2 items-center justify-center text-xs text-gray text-center uppercase';
  if (count === 0) {
    return [base, 'opacity-60'].join(' ');
  }
  return base;
};
