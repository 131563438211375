import { SvgStar } from 'components/svgStar';
import React from 'react';
import { computeRatingValueAt } from 'utils/computeRatingValueAt';

interface Props {
  readonly value: number;
  readonly color?: string;
}

export const StarRating: React.FC<Props> = ({ value, color }: Props): React.ReactElement => {
  return (
    <div className="flex items-center justify-center gap-2">
      <div className="flex items-center justify-center gap-2">
        {[1, 1, 1, 1, 1].map((_: number, index: number): React.ReactElement => {
          return (
            <div key={index}>
              <SvgStar value={100 * computeRatingValueAt(value, index)} size={24} color={color} />
            </div>
          );
        })}
      </div>
      <span className="block font-poppinsSemiBold leading-6 text-sm w-12 -mr-12 text-center">
        (
        {Intl.NumberFormat(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value)}
        )
      </span>
    </div>
  );
};
