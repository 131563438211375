import React, { useCallback } from 'react';
import { Input } from 'views/Landing/component/input';
import { PasswordInput } from 'views/Landing/component/passwordInput';
import { SignUpFormState } from 'views/Landing/SignUp/types';
import { ValidationError } from 'yup';

interface Props {
  readonly id: string;
  readonly name: keyof Omit<
    SignUpFormState,
    'termsAndConditionsAccepted' | 'communicationAccepted'
  >;
  readonly placeholder: string;
  readonly value: string;
  readonly error?: ValidationError;
  readonly type?: 'email' | 'text' | 'password' | 'new-password';

  onChange(name: string, value: string): void;
}

export const GenericInput: React.FC<Props> = ({
  id,
  name,
  placeholder,
  type,
  value,
  error,
  onChange,
}: Props): React.ReactElement => {
  const handleChange = useCallback(
    (value: string): void => {
      onChange(name, value);
    },
    [name, onChange],
  );

  if (type === 'password') {
    return (
      <div className="mb-4">
        <PasswordInput
          id={id}
          label={placeholder}
          value={value}
          create={true}
          withHelp={false}
          error={error}
          onChange={handleChange}
        />
      </div>
    );
  } else if (type === 'new-password') {
    return (
      <div className="mb-4">
        <PasswordInput
          id={id}
          label={placeholder}
          value={value}
          create={true}
          withHelp={true}
          error={error}
          onChange={handleChange}
        />
      </div>
    );
  } else {
    return (
      <div className="mb-4">
        <Input
          id={id}
          label={placeholder}
          type={type}
          value={value}
          error={error}
          onChange={handleChange}
        />
      </div>
    );
  }
};
