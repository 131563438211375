import { Action, AnyAction, Dispatch } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import {
  getFinancialMetrics,
  getFinancialMetricsFailed,
  getFinancialMetricsSuccess,
  getIndustries,
  getIndustriesFailed,
  getIndustriesSuccess,
  getIndustryDetails,
  getIndustryDetailsFailed,
  getIndustryDetailsSuccess,
  getMyIndustriesSuccess,
  getSummary,
  getSummaryFailed,
  getSummarySuccess,
  peerComparisonsFetchingFailed,
  peerComparisonsFetchingStarted,
  peerComparisonsFetchingSucceeded,
  timelineFetchingFailed,
  timelineFetchingStarted,
  timelineFetchingSucceeded,
} from 'redux/reducers/industryReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import settings from 'utils/config/appSettings';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';
import { CountryOfInterest } from 'views/DataAnalysis/Industry/countries';
import { IndustryDetails } from 'views/DataAnalysis/Industry/types';

export const getMyIndustries =
  (cancelToken: CancelToken, parameters?: Record<string, string>) => async (dispatch: Dispatch) => {
    const params = { ...parameters, page_size: settings.defaultPageSize };
    dispatch(getIndustries());

    try {
      const response = await api.get('api/v1/industries/my', {
        params,
        cancelToken,
      });
      if (response.status === HttpStatusOk) {
        dispatch(getMyIndustriesSuccess(response.data));
      } else {
        dispatch(getIndustriesFailed());
      }
    } catch {
      dispatch(getIndustriesFailed());
    }
  };

export const getIndustriesService =
  (tab: DataAndAnalysisMainTabs, cancelToken: CancelToken, parameters?: Record<string, string>) =>
  (dispatch: Dispatch) => {
    const params = { ...parameters, page_size: settings.defaultPageSize };
    const urls: { [key: string]: string } = {
      [DataAndAnalysisMainTabs.Popular]: 'api/v1/industries/popular',
      [DataAndAnalysisMainTabs.All]: 'api/v1/industries',
    };
    dispatch(getIndustries());

    api
      .get(urls[tab], { params, cancelToken })
      .then(res => {
        const page = res.data;
        // If there's no data, it might be because the request was cancelled, so just ignore it
        if (!page) {
          return;
        }

        dispatch(getIndustriesSuccess({ ...page, data: page.data ?? [] }));
      })
      .catch(err => {
        dispatch(getIndustriesFailed());
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
      });
  };

// DETAILS
export const getIndustryDetailsService = (industryId: string) => (dispatch: Dispatch) => {
  dispatch(getIndustryDetails(industryId));
  api
    .get(`${API_V1_PATH}/industries/${industryId}`)
    .then(res => {
      if (res.data) {
        dispatch(getIndustryDetailsSuccess(res.data as IndustryDetails));
        // FIXME: this logic should be moved to the component using gTag Hook, this is not a service logic
        window.gtag('event', 'viewIndustryDetails', {
          category: 'industry',
          action: 'View industry details page',
          industrie: industryId,
          name: res.data.name,
        });
      } else {
        dispatch(getIndustryDetailsFailed());
      }
    })
    .catch(err => {
      dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
      dispatch(getIndustryDetailsFailed());
    });
};

const createStatementService =
  <T>(
    statementName: string,
    start: () => Action,
    success: (statement: T) => Action,
    failure: (error: any) => Action,
  ) =>
  (industryId: string, country?: CountryOfInterest) =>
  (dispatch: Dispatch) => {
    dispatch(start());
    api
      .request({
        url: `${API_V1_PATH}/industries/${industryId}/financial/${statementName}`,
        method: 'GET',
        params: country ? { country } : undefined,
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          dispatch(success(response.data));
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(failure(err));
      });
  };

export const fetchIndustryFinancialMetricsService = createStatementService(
  'financial-metrics',
  getFinancialMetrics,
  getFinancialMetricsSuccess,
  getFinancialMetricsFailed,
);

export const getSummaryService = createStatementService(
  'profile',
  getSummary,
  getSummarySuccess,
  getSummaryFailed,
);

export const fetchPeerComparisons = createAPIAction(function fetchPeerComparisons(
  client: HttpClient,
): ActionsGenerator {
  return async function* (industryId: string, filters: any): AsyncGenerator<AnyAction> {
    yield peerComparisonsFetchingStarted();

    const response = await client.GET(
      `${API_V1_PATH}/industries/${industryId}/peer-comparisons`,
      filters,
    );
    if (response.status !== 200) {
      // Error
      yield peerComparisonsFetchingFailed(response.data);
    } else {
      const { data } = response;
      yield peerComparisonsFetchingSucceeded(data);
    }
  };
});

export const fetchIndustryTimeline = createAPIAction(function fetchTimeline(
  client: HttpClient,
): ActionsGenerator {
  return async function* (industryId: string, filters: any): AsyncGenerator<AnyAction> {
    yield timelineFetchingStarted();

    const response = await client.GET(`${API_V1_PATH}/industries/${industryId}/timeline`, filters);
    if (response.status !== 200) {
      // Error
      yield timelineFetchingFailed(response.data);
    } else {
      const { data } = response;
      yield timelineFetchingSucceeded(data);
    }
  };
});
