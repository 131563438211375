import 'components/DEPRECATED/checkbox/Checkbox.scss';

import React from 'react';

interface Props {
  label?: string;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  children?: React.ReactElement;
  isSelectAll?: boolean;
  id?: string;
  small?: boolean;
  smallClassName?: string;
}

/**
 *
 * @deprecated
 */
const Checkbox: React.FC<Props> = ({
  label = '',
  onChange,
  checked,
  id,
  className = '',
  children = null,
  isSelectAll = false,
  small = false,
  smallClassName = 'text-xs mt-1',
}) => {
  const checkbox = (
    <>
      <div className="h-5 w-5">
        <input
          id={id}
          type="checkbox"
          className="cursor-pointer"
          style={!small ? { height: '20px', width: '20px' } : { height: '12px', width: '12px' }}
          name="checkbox"
          onChange={onChange}
          checked={checked}
        />
      </div>
      <label
        className={`flex items-center cursor-pointer font-poppins text-gray text-left ${
          small ? smallClassName : 'ml-3 text-base'
        } leading 5`}
      >
        {label}
      </label>
      {children}
    </>
  );
  const selectAllCheckbox = (
    <>
      <input
        id={id}
        type="checkbox"
        className="hidden"
        style={{ height: '20px', width: '20px' }}
        name="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <label
        htmlFor={id}
        className="flex items-center cursor-pointer font-poppins text-gray text-left text-base leading 5"
      >
        <span className="w-5 h-5 inline-block mr-3 rounded-full bg-white border border-gray-darkest"></span>
        {label}
      </label>
    </>
  );
  return (
    <div className={`flex items-start ${className}`}>
      {isSelectAll ? selectAllCheckbox : checkbox}
    </div>
  );
};

export default Checkbox;
