import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import React from 'react';

interface Props {
  readonly icon: SvgIconName;
  readonly disabled?: boolean;

  onClick(): void;
}

export const MobileActionButton: React.FC<Props> = ({
  disabled = false,
  icon,
  onClick,
}: Props): React.ReactElement => {
  if (disabled) {
    return <SVGIcon className="h-5 w-5 fill-current" name={icon} />;
  } else {
    return <SVGIcon className="h-5 w-5 fill-current" name={icon} onClick={onClick} />;
  }
};
