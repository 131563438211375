import { useDispatch } from 'react-redux';
import { followUserAction, unfollowUserAction } from 'redux/actions/connectionsActions';
import { FollowAction } from 'views/Connections/Overview/components/FollowButton';

export const useFollowHandler = (): ((followAction: FollowAction, userId: string) => void) => {
  const dispatch = useDispatch<any>();

  return (followAction: FollowAction, userId: string): void => {
    switch (followAction) {
      case FollowAction.follow:
        dispatch(followUserAction(userId));
        break;
      case FollowAction.unfollow:
        dispatch(unfollowUserAction(userId));
        break;
      default:
        throw new Error(
          'this is impossible, an unknown action requested instead of follow/unfollow',
        );
    }
  };
};
