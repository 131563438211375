import SVGIcon from 'components/icons/SVGIcon';
import { Tooltip } from 'components/tooltip';
import React from 'react';

interface Props {
  readonly label: string;
  readonly tooltipText: string | React.ReactElement;
}

export const InfoLabel: React.FC<Props> = ({ label, tooltipText }: Props): React.ReactElement => {
  return (
    <div className="flex items-center">
      <h4 className="text-gray-darkest mr-2">{label}</h4>
      <Tooltip content={tooltipText} placement="bottom-start">
        <SVGIcon name="info-icon" className="cursor-pointer w-4 h-4" />
      </Tooltip>
    </div>
  );
};
