import { cardBrandHumanLabel, cardBrandIcon } from 'constants/payments';
import React, { useCallback, useMemo } from 'react';
import { Card } from 'redux/reducers/authReducer';

interface Props {
  readonly card: Card;
  readonly selected: boolean;

  onClick(id: string): void;
}

export const PaymentMethodListItem: React.FC<Props> = ({
  card,
  selected,

  onClick,
}: Props): React.ReactElement => {
  const expireDate = useMemo((): string => {
    const date = new Date(card.exp_year, card.exp_month - 1, 1);
    return formatter.format(date);
  }, [card.exp_month, card.exp_year]);

  const containerClassName = useMemo((): string => {
    const base =
      'flex items-center gap-2 bg-gray-100 rounded-md p-4 border-2 border-transparent cursor-pointer';

    return [base, selected ? 'border-blue' : undefined].join(' ');
  }, [selected]);

  const handleClick = useCallback((): void => {
    onClick(card.id);
  }, [card.id, onClick]);

  return (
    <div className={containerClassName} onClick={handleClick}>
      <div className="flex items-start gap-2">
        {cardBrandIcon[card.brand]}
        <div className="flex items-center gap-2">
          <h4>{cardBrandHumanLabel[card.brand]}</h4>
          <p>**** {card.last4}</p>
        </div>
      </div>
      <div className="ml-auto">
        <h6>Expires</h6>
        <p>{expireDate}</p>
      </div>
    </div>
  );
};

const formatter = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  year: 'numeric',
});
