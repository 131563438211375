import { KeyboardListener } from 'components/keyboardListener';
import { Modal } from 'components/modal';
import { PostBody } from 'components/postBodyInput/types';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeedComment } from 'redux/reducers/feedDetailsReducer';
import {
  feedItemSelector,
  fetchFeedItem,
  processingStateSelector,
  unfetchFeedItem,
} from 'redux/reducers/feedItemDetailReducer';
import {
  deleteFeedComment,
  getNewsComments,
  getPostComments,
  postComment,
} from 'redux/services/feedDetailsService';
import { ProcessingStateEnum } from 'types/processingState';
import { CommentsListContainer } from 'views/Home/modals/PostDetailsModal/components/commentsListContainer';
import { PostView } from 'views/Home/modals/PostDetailsModal/components/postView';
import { emit, EventType, listenTo } from 'views/Home/modals/PostDetailsModal/utils/events';
import { FeedCategory, FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItemId: string;

  onClose(): void;
}

export const PostDetailsModalContent: React.FC<Props> = ({
  feedItemId,
  onClose,
}: Props): React.ReactElement => {
  const feedItem = useSelector(feedItemSelector);
  const processingState = useSelector(processingStateSelector);
  const dispatch = useDispatch<any>();

  const [commentsCount, setCommentsCount] = React.useState<number>(0);
  const [comments, setComments] = React.useState<readonly FeedComment[] | null>(null);

  useEffect((): void => {
    if (!feedItem) {
      dispatch(fetchFeedItem(feedItemId));
    }
  }, [dispatch, feedItem, feedItemId]);

  const closeModal = useCallback((): void => {
    dispatch(unfetchFeedItem());
    onClose();
  }, [dispatch, onClose]);

  const handleGlobalKeyPressed = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): boolean => {
      if (event.key === 'Escape') {
        closeModal();

        return true;
      }

      return false;
    },
    [closeModal],
  );

  const handleCommentPosted = useCallback(
    async (body: PostBody): Promise<void> => {
      if (feedItem) {
        const postedComment = await postComment(feedItem.id, body, feedItem.category);
        setComments(
          (currentComments: readonly FeedComment[] | null): readonly FeedComment[] | null => {
            if (currentComments === null) {
              return [postedComment];
            }

            return [FeedComment.fromJson(postedComment), ...currentComments];
          },
        );
        // Emit now
        emit(feedItem.id, EventType.added);
      }
    },
    [feedItem],
  );

  const fetchFeedItemComments = React.useCallback(async (feedItem: FeedItem): Promise<void> => {
    switch (feedItem.category) {
      case FeedCategory.post:
        setComments(await getPostComments(feedItem.id));
        break;
      case FeedCategory.news:
        setComments(await getNewsComments(feedItem.id));
        break;
    }
  }, []);

  React.useEffect((): void => {
    if (!comments && !!feedItem) {
      void fetchFeedItemComments(feedItem);
    }
  }, [comments, feedItem, fetchFeedItemComments]);

  React.useEffect((): void => {
    if (feedItem) {
      setCommentsCount(feedItem.commentsCount);
    }
  }, [feedItem]);

  useEffect((): VoidFunction | void => {
    if (!feedItem) {
      return;
    }

    const handleRemoved = (event: Event): void => {
      const customEvent = event as CustomEvent<number>;
      setCommentsCount((count: number): number => count - customEvent.detail);
    };

    return listenTo(feedItem.id, null, EventType.removed, handleRemoved);
  }, [feedItem, onClose]);

  useEffect((): VoidFunction | void => {
    if (!feedItem) {
      return;
    }

    const handleAdded = (): void => {
      setCommentsCount((count: number): number => count + 1);
    };

    return listenTo(feedItem.id, null, EventType.added, handleAdded);
  }, [feedItem, onClose]);

  const handleDelete = useCallback(async (commentId: string): Promise<void> => {
    await deleteFeedComment(commentId);

    setComments((currentComments: readonly FeedComment[] | null): readonly FeedComment[] | null => {
      if (currentComments === null) {
        return null;
      }

      return currentComments.filter((comment: FeedComment): boolean => comment.id !== commentId);
    });
  }, []);

  const headerClassName = useMemo(
    (): string => `${feedItem ? '' : 'min-h-88'} md:rounded-md`,
    [feedItem],
  );

  return (
    <KeyboardListener onKeyPressed={handleGlobalKeyPressed}>
      <Modal isOpen={true} autoOverflow={false} onClose={closeModal}>
        <div className="flex flex-col flex-1 min-h-0 lg:w-modal-lg pb-4">
          <div className={headerClassName}>
            {feedItem && (
              <PostView
                feedItem={feedItem}
                commentsCount={commentsCount}
                onCommentPosted={handleCommentPosted}
                onClose={closeModal}
              />
            )}
            <SpinnerLoader visible={processingState.state === ProcessingStateEnum.processing} />
          </div>
          {!process.env.REACT_APP_DATA_PORTAL && !!feedItem && (
            <CommentsListContainer
              parentId={feedItem.id}
              comments={comments}
              onDelete={handleDelete}
            />
          )}
        </div>
      </Modal>
    </KeyboardListener>
  );
};
