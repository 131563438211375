import React, { useCallback } from 'react';

interface Props<T> {
  readonly value: T;
  onHover(value: T): void;
}

export function HoverHandler<T>(props: React.PropsWithChildren<Props<T>>): React.ReactElement {
  const { value, onHover, children } = props;

  const handleHover = useCallback((): void => onHover(value), [onHover, value]);
  if (!React.isValidElement(children)) {
    throw new Error('must have 1 valid child');
  }

  return <div onMouseEnter={handleHover}>{children}</div>;
}
