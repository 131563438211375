import { useCallback, useState } from 'react';

export interface ModalManager {
  readonly isOpen: boolean;

  open(): void;
  close(): void;
}

export const useModal = (parentModal?: ModalManager): ModalManager => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback((): void => {
    // Close parent if there
    parentModal?.close();
    // Now open this one
    setIsOpen(true);
  }, [parentModal]);

  const close = useCallback((): void => {
    // Open parent back
    parentModal?.open();
    // Now close this one
    setIsOpen(false);
  }, [parentModal]);

  return {
    isOpen,
    open,
    close,
  };
};
