import { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import React, { useCallback, useMemo } from 'react';
import { formatToTrainCase } from 'utils';

interface Props {
  readonly item: DropdownItem;

  onClick?(id: string): void;
}

export const Item: React.FC<Props> = ({ item, onClick }: Props): React.ReactElement => {
  const handleClick = useCallback((): void => {
    onClick?.(item.id);
  }, [item.id, onClick]);

  const elementId = useMemo((): string => `${formatToTrainCase(item.label)}-filter`, [item.label]);

  return (
    <div id={elementId} key={item.id} onClick={handleClick} className={containerClass}>
      <p className="font-poppins text-gray">{item.label}</p>
    </div>
  );
};

const containerClass = 'w-full bg-gray-light hover:bg-gray-medium py-2 px-3 cursor-pointer';
