import SVGIcon from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';

interface Props {
  readonly expiresAt?: string;
}

export const CancellationNotice: React.FC<Props> = ({ expiresAt }: Props): React.ReactElement => {
  const expirationDateString = useMemo((): string => {
    if (!expiresAt) {
      return '';
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    });

    const date = new Date(expiresAt);
    return formatter.format(date);
  }, [expiresAt]);

  return (
    <div className="flex items-center gap-4 text-gray">
      <div>
        <div className="flex items-center justify-center gap-2">
          <SVGIcon name="clock" className="block fill-current w-4 h-4" />
          <p>Scheduled for cancellation</p>
        </div>
        <div className="ml-6">
          <p>on {expirationDateString}</p>
        </div>
      </div>
    </div>
  );
};
