import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { valueChainStateSelector } from 'redux/reducers/valueChainReducer';
import { ValueChainTableRow } from 'types/organization/types';
import { formatValue } from 'utils';

interface Props {
  readonly value: number | null;
}

const MarketCapColumn: React.FC<Props> = ({ value }: Props): React.ReactElement => {
  const { table } = useSelector(valueChainStateSelector);
  const { data } = table;

  const largestMarketCapitalization = useMemo((): number => {
    const values = data.map((row: ValueChainTableRow): number => Number(row.marketcap));
    return Math.max(...values);
  }, [data]);

  return (
    <div className="font-poppins text-center">
      {formatValue(value, 'currency', largestMarketCapitalization)}
    </div>
  );
};

export default MarketCapColumn;
