import { QueryStatus } from '@reduxjs/toolkit/query';
import React from 'react';

export enum ActionType {
  remove = 'remove',
  edit = 'edit',
  share = 'share',
}

export interface EarnAndBuyActions {
  readonly applyStatus?: QueryStatus;
  readonly applyId?: string;
  readonly actionStatuses: Partial<Record<ActionType, QueryStatus>>;

  apply?(id: string): void;
  edit(id: string): void;
  remove(id: string): void;
  share(id: string): void;
}

export const ActionsContext = React.createContext<EarnAndBuyActions | null>(null);
