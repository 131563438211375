import FinvarIconFinal from 'assets/images/Finvar-icon-final.png';
import { MainOutletContent } from 'components/mainOutletContent';
import { UpgradeButton } from 'components/upgradeButton';
import React from 'react';
import SectionTitle from 'views/Landing/Login/components/sectionTitle';

interface Props {
  readonly title: string;
  readonly body: string;
}

const NoAccessWithCurrentSubscription: React.FC<Props> = ({
  title,
  body,
}: Props): React.ReactElement => {
  return (
    <MainOutletContent>
      <div className="flex flex-col items-center justify-center flex-1">
        <img
          src={FinvarIconFinal}
          className={`w-20 h-20 rounded-lg mx-auto block mb-4`}
          alt="Finver Logo"
        />
        <SectionTitle title={title} subTitle={body} />
        <UpgradeButton />
      </div>
    </MainOutletContent>
  );
};

export default NoAccessWithCurrentSubscription;
