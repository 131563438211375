import { ReactComponent as InfoIcon } from 'assets/icons/svg/info-icon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/svg/location-icon.svg';
import Button from 'components/buttons/Button/Button';
import DateTimePicker from 'components/dateTimePicker/dateTimePicker';
import Checkbox from 'components/DEPRECATED/checkbox/Checkbox';
import UnboxedPicker from 'components/DEPRECATED/dropdown/UnboxedPicker';
import TagWithDelete from 'components/DEPRECATED/tags/TagWithDelete';
import { Disabler } from 'components/disabler';
import SVGIcon from 'components/icons/SVGIcon';
import Input2 from 'components/input/Input2';
import LargeListPicker, { LLOption } from 'components/largeListPicker/LargeListPicker';
import MembersInput from 'components/membersInput/MembersInput';
import SpinnerLoader from 'components/spinnerLoader';
import { isAfter, isSameDay } from 'date-fns';
import { useGtag } from 'hooks/useGtag';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { countriesSelector, Country, getCountriesAction } from 'redux/reducers/sharedReducer';
import { createEventService, updateEventService } from 'redux/services/timelineService';
import { convertToMembersPayload } from 'sharable/helpers';
import { Member } from 'sharable/types';
import { serializeTime, Time } from 'types/time';
import { CreateEventPayload, EventDetails, Stream, StreamEvent } from 'types/timeline';
import { isTimeAfter, ReminderValue, serializeDate } from 'utils/dateTime';
import { createValidationError, FormErrors, YupError } from 'utils/forms';
import { unserialize } from 'utils/serializer';
import { REMINDER_OPTIONS } from 'views/SmartTools/TimelineAndEvents/data';
import { LevelDropdown } from 'views/SmartTools/TimelineAndEvents/toolbars/levelDropdown';
import { getReminderObject } from 'views/SmartTools/TimelineAndEvents/utils';
import * as yup from 'yup';

const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const isStartDateAfterEndDate = (form: EventFormState): boolean => {
  const { endDate } = form;
  if (endDate === null) {
    return false;
  }

  return isAfter(form.startDate, endDate);
};

const isStartTimeAfterEndTime = (form: EventFormState): boolean => {
  const { endDate } = form;
  if (endDate === null || isSameDay(form.startDate, endDate)) {
    return isTimeAfter(form.startTime, form.endTime);
  }

  return false;
};

interface StreamModalProps {
  readonly stream: Stream;
  readonly event: EventDetails | null;
}

interface EventFormState {
  readonly summary: string;
  readonly stream?: Stream | null;
  readonly members: readonly Member[];
  readonly tags: readonly string[];
  readonly description: string;
  readonly importance: string | null;
  readonly urgency: string | null;
  readonly reminder: ReminderValue | null;
  readonly startDate: Date;
  readonly endDate: Date | null;
  readonly startTime: Time | null;
  readonly endTime: Time | null;
  readonly allDay: boolean;
  readonly location?: LLOption<number>;
  readonly linkedEvents?: LLOption[];
  readonly syncToFollowingUsers: boolean;
  readonly event?: Event;
  readonly timezone: string;
}

const schema = yup.object().shape({
  summary: yup.string().required('Please enter a summary'),
  stream_id: yup.string().required('Unable to submit without a valid stream id'),
});

const CreateEditEventModal: React.FC<StreamModalProps> = ({
  event,
  stream,
}: StreamModalProps): React.ReactElement => {
  const [eventForm, setEventForm] = useState<EventFormState>({
    summary: event?.title || '',
    stream,
    members: [],
    tags: [],
    description: 'Low',
    importance: null,
    urgency: null,
    startDate: new Date(),
    startTime: null,
    endDate: null,
    endTime: null,
    allDay: false,
    syncToFollowingUsers: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    reminder: null,
  });
  const dispatch = useDispatch<any>();

  const [tag, setTag] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});
  const { trackEvent } = useGtag();

  // FIXME: enable this again
  const [showSelectCountry, setShowSelectCountry] = useState(false);
  const [showLinkedEvents] = useState(false);
  const countries = useSelector(countriesSelector);
  const isCreatingEvent = false; // useSelector(isCreatingEventSelector);

  const handleAddLocation = useCallback((): void => {
    setShowSelectCountry(true);
  }, []);

  const handleCreate = useCallback(
    (payload: CreateEventPayload): void => {
      dispatch(createEventService(payload));
      trackEvent('end-add-event', {
        ...payload,
      });
    },
    [dispatch, trackEvent],
  );

  const handleEdit = useCallback(
    (id: string, payload: CreateEventPayload): void => {
      dispatch(updateEventService(id, payload));
      trackEvent('end-edit-event', {
        ...payload,
      });
    },
    [dispatch, trackEvent],
  );

  const onRemoveTag = (t: string) => () =>
    setEventForm(s => ({ ...s, tags: s.tags ? [...s.tags].filter(t2 => t2 !== t) : [] }));

  const handleAddTags = () => {
    if (tag) {
      const splitTags = tag.toLowerCase().replace(/, /, ',').split(',');
      const newTags: string[] = [];
      splitTags?.forEach(tag => {
        const cleanTag = tag.trim();
        if (cleanTag !== '' && eventForm?.tags?.indexOf(cleanTag) === -1) {
          newTags.push(cleanTag);
        }
      });
      setEventForm(s => ({ ...s, tags: s.tags ? [...s.tags, ...newTags] : newTags }));
      setTag('');
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleAddTags();
    }
  };

  const handleSubmit = useCallback(async () => {
    const endDate = eventForm.endDate;
    if (isStartDateAfterEndDate(eventForm)) {
      setErrors({
        startDate: createValidationError("Start date can't be after end date", 'startDate'),
      });
      return;
    } else if (isStartTimeAfterEndTime(eventForm)) {
      setErrors({
        startTime: createValidationError(
          "Start time can't be after end time in the same day",
          'startTime',
        ),
      });
      return;
    }

    if (eventForm.startTime !== null && eventForm.endTime === null) {
      setErrors({
        startTime: createValidationError(
          'You must specify an end time if you chose a start time',
          'startTime',
        ),
      });
      return;
    }

    const eventData: CreateEventPayload = {
      summary: eventForm.summary,
      stream_id: stream?.id ?? '',
      description: eventForm.description,
      start_date: serializeDate(eventForm.startDate),
      start_time: serializeTime(eventForm.startTime),
      end_date: endDate ? serializeDate(eventForm.endDate) : null,
      end_time: serializeTime(eventForm.endTime),
      country_id: eventForm.location?.id,
      urgency: eventForm.urgency,
      importance: eventForm.importance,
      members: eventForm.members.map(convertToMembersPayload),
      linked_events: eventForm.linkedEvents?.length
        ? eventForm.linkedEvents.map(item => item.id)
        : undefined,
      reminder: null,
      tags: eventForm.tags,
      timezone: eventForm.timezone,
    };

    try {
      setErrors({});

      await schema.validate(eventData, { abortEarly: false });
      if (event) {
        handleEdit(event.id, eventData);
      } else {
        handleCreate(eventData);
      }
    } catch (error: any) {
      const { inner } = error;
      if (inner instanceof Array) {
        setErrors(
          inner.reduce((errors: FormErrors, item: YupError): FormErrors => {
            return { ...errors, [item.path]: item };
          }, {}),
        );
      } else {
        console.warn(error);
      }
    }
  }, [event, eventForm, handleCreate, handleEdit, stream?.id]);

  /*const handleRemoveLink = useCallback((eventId: string) => {
    setEventForm(prev => {
      const newLinkedEvents = JSON.parse(JSON.stringify(prev.linkedEvents)) as LLOption[];
      const eventIndex = newLinkedEvents.findIndex(item => item.id === eventId);
      newLinkedEvents.splice(eventIndex, 1);
      return {
        ...prev,
        linkedEvents: newLinkedEvents,
      };
    });
  }, []);*/

  // FIXME: this should not be needed
  useEffect(() => {
    if (!countries || countries.length === 0) {
      dispatch(getCountriesAction());
    }
  }, [countries, dispatch]);

  useEffect(() => {
    if (event) {
      mixpanel.track('PageView Edit Event');

      const linkedEvents: StreamEvent[] = [];
      const reminder = getReminderObject(event.reminder, event.startDate);

      setEventForm({
        stream,
        summary: event?.summary ?? '',
        linkedEvents: linkedEvents,
        members: event.members ?? [],
        tags: event.tags,
        description: event?.description ?? '',
        importance: event?.importance ?? null,
        urgency: event?.urgency ?? null,
        startDate: unserialize(event.startDate),
        endDate: event.endDate ? unserialize(event.endDate) : null,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        // FIXME: would be better if the id comes from the backend
        location: countries.find((country: Country): boolean => country.name === event.country),
        syncToFollowingUsers: true,
        reminder,
        timezone: event?.timezone,
      });
    }

    if (!event) {
      mixpanel.track('PageView Create Event');
      setEventForm({
        summary: '',
        members: [],
        tags: [],
        description: '',
        importance: null,
        urgency: null,
        startDate: new Date(),
        startTime: null,
        endDate: null,
        endTime: null,
        allDay: false,
        syncToFollowingUsers: true,
        timezone: LOCAL_TIMEZONE,
        reminder: null,
      });
    }
  }, [event, stream, countries]);

  const handleMembersChange = useCallback((members: readonly Member[]): void => {
    // FIXME: temporary quick trick to remove "readonly-ability"
    setEventForm(prev => ({ ...prev, members: members as Member[] }));
  }, []);

  const handleImportanceChange = useCallback((importance: string): void => {
    setEventForm(prev => ({ ...prev, importance }));
  }, []);

  const handleUrgencyChange = useCallback((urgency: string): void => {
    setEventForm(prev => ({ ...prev, urgency }));
  }, []);

  // Needs a search thing
  const eventsToLink: Array<{ id: string; label: string }> = useMemo(() => {
    /*const output: Array<{ id: string; label: string }> = [];
    if (Array.isArray(allEventsList)) {
      allEventsList.forEach(item => {
        if (item.id !== event?.id) {
          output.push({ id: item.id || '1', label: item.summary });
        }
      });
    }
    return output;*/

    return [];
  }, []);

  return (
    <div className="w-full md:w-modal-md text-gray-darkest overflow-auto bg-gray-light h-full">
      <div className="flex justify-between items-start">
        <h3 className="font-jostSemiBold my-0 text-2xl flex-1 min-w-0">
          {event ? 'Edit Item' : 'New Item'} for {stream?.name}
        </h3>
      </div>
      <div className="flex mt-6">
        <div className="flex-1 mt-5">
          <Input2
            placeholder="Short Description"
            name="summary"
            maxLength={150}
            value={eventForm.summary}
            onChange={({ target: { value } }) =>
              setEventForm(prev => ({ ...prev, summary: value }))
            }
            error={errors.summary}
            inputClassName="bg-transparent py-3 pl-4"
          />
          <div className="w-100 mt-4">
            <DateTimePicker
              startDate={eventForm.startDate}
              endDate={eventForm.endDate}
              endTime={eventForm.endTime}
              startTime={eventForm.startTime}
              allDay={eventForm.allDay}
              timezone={eventForm.timezone}
              startDateError={errors.startDate}
              startTimeError={errors.startTime}
              onChangeStartDate={startDate => setEventForm(prev => ({ ...prev, startDate }))}
              onChangeEndDate={endDate => setEventForm(prev => ({ ...prev, endDate }))}
              onChangeEndTime={endTime => setEventForm(prev => ({ ...prev, endTime }))}
              onChangeStartTime={startTime => setEventForm(prev => ({ ...prev, startTime }))}
              onChangeAllDay={allDay => setEventForm(prev => ({ ...prev, allDay }))}
              onChangeTimezone={timezone => setEventForm(prev => ({ ...prev, timezone }))}
            />
          </div>
          <div className="flex items-center">
            <button
              className="flex items-center mt-3 py-1 px-1 gap-1 duration-300 focus:bg-gray-medium hover:bg-gray-medium rounded text-blue"
              onClick={handleAddLocation}
            >
              <LocationIcon className="h-4 fill-current" />
              <p className="text-sm uppercase font-poppinsSemiBold">
                {eventForm.location ? eventForm.location.name : 'Add Location (Country)'}
              </p>
            </button>
            {errors.country_id && (
              <>
                <div
                  className="relative capitalized ml-2 pt-3"
                  data-tip={errors.country_id}
                  data-for="country"
                  data-effect="solid"
                  data-background-color="rgba(234, 68, 68, 1)"
                >
                  <SVGIcon name="exclamation-circle" className="w-5 h-5" />
                </div>
                <ReactTooltip
                  id="country"
                  place="bottom"
                  className="font-poppins text-white capitalized"
                />
              </>
            )}
          </div>
          {showSelectCountry && (
            <LargeListPicker
              value={eventForm.location}
              onSelectOption={newValue => {
                if (!Array.isArray(newValue)) {
                  setEventForm(prev => ({ ...prev, location: newValue }));
                  setShowSelectCountry(false);
                }
              }}
              options={countries.map(
                (country: Country): LLOption<number> => ({
                  id: country.id,
                  label: country.name,
                  name: country.name,
                }),
              )}
            />
          )}
          <div className="mt-6 hidden">
            <UnboxedPicker
              className="z-40"
              placeholder="Reminder"
              value={eventForm.reminder?.label}
              options={REMINDER_OPTIONS}
              onSelectOption={option =>
                setEventForm(prev => ({ ...prev, reminder: option as ReminderValue }))
              }
              leadingIcon={
                <SVGIcon name="reminder-icon" className="w-3 h-3 fill-current text-gray-dark" />
              }
            />
          </div>
          <div className="flex justify-evenly gap-4">
            <div className="flex-1">
              <h4 className="mt-5 mb-1">IMPORTANCE LEVEL</h4>
              <LevelDropdown value={eventForm.importance} onChange={handleImportanceChange} />
            </div>
            <div className="flex-1">
              <h4 className="mt-5 mb-1">URGENCY LEVEL</h4>
              <LevelDropdown value={eventForm.urgency} onChange={handleUrgencyChange} />
            </div>
          </div>
          <div className="flex-1 mb-4">
            {/*<button
              onClick={() => setShowLinkedEvents(prev => !prev)}
              className="flex items-center mt-5 p-1 focus:outline-none duration-300 focus:bg-gray-medium rounded hover:bg-gray-medium"
            >
              {!showLinkedEvents && (
                <div className="p-1 bg-blue rounded  mr-2.5">
                  <LinkedButtonIcon className="h-3" />
                </div>
              )}
              <p
                className={`text-sm ${
                  showLinkedEvents ? 'text-gray-darkest' : 'text-blue'
                } uppercase font-poppinsSemiBold`}
              >
                Link to other event
              </p>
              {showLinkedEvents && <SVGIcon name="close-popup-icon" className="h-3 ml-2" />}
            </button>*/}
          </div>
          <div className="flex-1">
            {showLinkedEvents && (
              <LargeListPicker
                value={eventForm.linkedEvents}
                multiselect
                onSelectOption={newValue => {
                  if (Array.isArray(newValue)) {
                    setEventForm(prev => ({ ...prev, linkedEvents: newValue }));
                  }
                }}
                options={eventsToLink}
              />
            )}
            <div className="max-h-60 scroller overflow-auto">
              <div className="grid grid-cols-2 gap-1 pt-2">
                {/*eventForm.linkedEvents?.map(item => {
                  const event: StreamEvent = allEventsMap[item.id];
                  if (!event) return null;
                  return (
                    <Clickable key={event.id} clickData={event.id} onClick={handleRemoveLink}>
                      <EventItem event={event} compact={false} upcoming={false} />
                    </Clickable>
                  );
                })*/}
              </div>
            </div>
          </div>
        </div>
        <div className="w-20" />
        <div className="flex-1">
          <MembersInput members={eventForm.members} onChange={handleMembersChange} />
          <Disabler disabled={true}>
            <Checkbox
              small
              className="mt-6 pl-1"
              onChange={() =>
                setEventForm(prev => ({
                  ...prev,
                  syncToFollowingUsers: !prev.syncToFollowingUsers,
                }))
              }
              label="Sync to following users"
              checked={eventForm.syncToFollowingUsers}
              smallClassName="text-sm mt-1"
            />
          </Disabler>
          <div className="mt-6">
            <Input2
              placeholder="Additional notes"
              name="description"
              value={eventForm.description}
              onChange={({ target: { value } }) =>
                setEventForm(prev => ({ ...prev, description: value }))
              }
              inputClassName="bg-transparent py-3 pl-4"
              error={errors.description}
            />
          </div>
          <div className="w-full my-5">
            <Input2
              name="tags"
              inputClassName="bg-transparent py-3 pl-4"
              onChange={e => setTag(e.target.value)}
              onKeyDown={handleOnKeyDown}
              value={tag}
              placeholder="#Tags"
            />
            <div className="flex items-center mt-3 py-1">
              <InfoIcon className="h-4" />
              <p className="text-sm text-blue ml-2.5 font-poppins">
                To add tags separate them by a comma (,) and press enter
              </p>
            </div>
          </div>

          {eventForm.tags.length > 0 && (
            <div className="flex w-full my-5 flex-wrap">
              {eventForm.tags.map((t, index) => (
                <TagWithDelete
                  key={t + index}
                  className="mr-3.5 mb-1"
                  onClickDelete={onRemoveTag(t)}
                >
                  <span>{t}</span>
                </TagWithDelete>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={`flex items-center justify-end ${showLinkedEvents ? 'mt-2' : 'mt-10'}`}>
        <Button
          label={event ? 'Edit' : 'Create'}
          onClick={handleSubmit}
          width="w-24"
          padding="p-1"
          height="h-12"
        />
      </div>
      <SpinnerLoader visible={isCreatingEvent} />
    </div>
  );
};

export default CreateEditEventModal;
