import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/reducers/store';
import { Page } from 'types/page';

export interface Country {
  readonly id: string;
  readonly iso: string;
  readonly name: string;
  readonly nominal_gdp: number;
  readonly gdp_growth: number;
  readonly population_size: number;
  readonly exports_value: number;
  readonly imports_value: number;
  readonly moodys_credit_rating: string;
  readonly standard_and_poors_credit_rating: string;
  readonly fitch_credit_rating: string;
}

export interface CountryState {
  currentList: Page<Country>;
  fetchingList: boolean;
}

const initialState: CountryState = {
  currentList: Page.empty(),
  fetchingList: false,
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    startFetchingCountries: (state: CountryState): void => {
      state.fetchingList = true;
      state.currentList = Page.empty();
    },
    setCountriesList: (state: CountryState, { payload }: PayloadAction<Page<Country>>): void => {
      state.currentList = payload;
    },
    stopFetchingCountries: (state: CountryState): void => {
      state.fetchingList = false;
    },
  },
});

export const countriesListSelector = (state: ApplicationState): Page<Country> =>
  state.countryReducer.currentList;

export const fetchingCountriesSelector = (state: ApplicationState): boolean =>
  state.countryReducer.fetchingList;

export const { startFetchingCountries, setCountriesList, stopFetchingCountries } =
  countrySlice.actions;

export default countrySlice.reducer;
