import { Dispatch } from '@reduxjs/toolkit';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import {
  setCountriesList,
  startFetchingCountries,
  stopFetchingCountries,
} from 'redux/reducers/countryReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ThunkLikeAction } from 'types/APIAction';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const fetchCountriesListService =
  (type: DataAndAnalysisMainTabs, pageSize: number, filters?: any): ThunkLikeAction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startFetchingCountries());
    try {
      const url = ((): string => {
        switch (type) {
          case DataAndAnalysisMainTabs.Popular:
            return `${API_V1_PATH}/countries/popular`;
          case DataAndAnalysisMainTabs.All:
            return `${API_V1_PATH}/countries`;
          case DataAndAnalysisMainTabs.My:
            return `${API_V1_PATH}/countries/my`;
        }
      })();

      const response = await api.get(url, {
        params: {
          page_size: pageSize,
          ...filters,
        },
      });
      dispatch(setCountriesList(response.data));
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message ?? errorMessageDefault }));
    } finally {
      dispatch(stopFetchingCountries());
    }
  };
