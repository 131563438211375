import { Filters } from 'components/filters';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  countriesListSelector,
  Country,
  fetchingCountriesSelector,
} from 'redux/reducers/countryReducer';
import { fetchCountriesListService } from 'redux/services/countryServices';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import columns from 'views/DataAnalysis/Country/columns';
import filters from 'views/DataAnalysis/Country/filters';

interface Props {
  readonly tab: DataAndAnalysisMainTabs;
}

export const CountriesTable: React.FC<Props> = ({ tab }: Props): React.ReactElement => {
  const [sorting, handleSortingChange] = useSort<Country>('nominal_gdp', SortOrder.descending);

  const fetching = useSelector(fetchingCountriesSelector);
  const dispatch = useDispatch<any>();
  const queryParameters = useQueryParameters();
  const countriesPage = useSelector(countriesListSelector);
  const handleFiltersChange = useFilters(filters);

  useEffect((): void => {
    dispatch(fetchCountriesListService(tab, 20, queryParameters));
  }, [dispatch, queryParameters, tab]);

  return (
    <div className="flex-1 relative">
      <div className="flex items-end justify-between mt-4">
        <Filters config={filters} onChange={handleFiltersChange} />
      </div>
      <h4 className="md:text-sm mt-2 mb-1 text-right font-poppins lowercase text-gray">
        {countriesPage.total_count} countries
      </h4>
      <Pagination totalPages={countriesPage.page_count}>
        <Table
          columns={columns}
          rows={countriesPage.data}
          sortBy={sorting}
          onSortChange={handleSortingChange}
        />
      </Pagination>
      <SpinnerLoader visible={fetching} />
    </div>
  );
};
