import { SignUpFormState } from 'views/Landing/SignUp/types';
import * as yup from 'yup';
import { TestContext, ValidationError } from 'yup';

// FIXME: this should reflect the fact that "path" is not really required
export type YupError = Required<ValidationError>;
export type FormErrors<T = any> = { [key in keyof T]?: YupError };

interface OptionsContext {
  readonly isInEditMode: boolean;
}

const isOptionsContext = (optionsContext: any): optionsContext is OptionsContext => {
  return 'isInEditMode' in optionsContext && typeof optionsContext.isInEditMode === 'boolean';
};

export enum CustomValidationType {
  passwordRequirements = 'passwordRequirements',
}

export enum PasswordRequirements {
  minimumLength = 1 << 0,
  containsSpecialCharacters = 1 << 1,
  containsAtLeastOneNumber = 1 << 2,
  containsAtLeastOneLowerCaseLetter = 1 << 3,
  containsAtLeastOneUpperCaseLetter = 1 << 4,
}

const PasswordNoSpecialCharactersError =
  'Password must contain at least a special character (! @ # $ % &)';
const PasswordNoNumberError = 'Password must contain at least a number';
const PasswordNoLowerCaseLettersError = 'Password must contain at least a lowercase letter';
const PasswordNoUpperCaseLettersError = 'Password must contain at least a capital letter';
const PasswordMustHaveAtLeast8Symbols = 'Password must have at least 8 letters and symbols';

export const passwordSchema = yup
  .string()
  .test((value: string | undefined, testContext: TestContext): boolean | ValidationError => {
    const { context: optionsContext = {} } = testContext.options;

    if (value === undefined) {
      return true;
    }

    if (value.length === 0) {
      if (isOptionsContext(optionsContext) && optionsContext.isInEditMode) {
        return true;
      }

      return testContext.createError({ message: 'Please enter your password' });
    }

    const regularExpressions = {
      '.{8,}': PasswordRequirements.minimumLength,
      '[!@#$%&]': PasswordRequirements.containsSpecialCharacters,
      '\\d': PasswordRequirements.containsAtLeastOneNumber,
      '[a-z]': PasswordRequirements.containsAtLeastOneLowerCaseLetter,
      '[A-Z]': PasswordRequirements.containsAtLeastOneUpperCaseLetter,
    };

    const errorFlag = Object.entries(regularExpressions).reduce(
      (flag: number, [regexString, requirement]: [string, PasswordRequirements]): number => {
        const regexp = new RegExp(regexString);
        if (regexp.test(value)) {
          return flag;
        } else {
          return flag | (requirement as number);
        }
      },
      0,
    );

    if (errorFlag === 0) {
      return true;
    }

    return testContext.createError({
      message: getBestMessageForError(errorFlag),
      type: CustomValidationType.passwordRequirements,
      params: {
        value: errorFlag,
      },
    });
  });

export class ValidationErrors {
  public static reduce<T>(inner: readonly ValidationError[]): FormErrors<T> {
    return inner.reduce(
      (
        errors: FormErrors<SignUpFormState>,
        error: ValidationError,
      ): FormErrors<SignUpFormState> => {
        const name = error.path;
        if (!name) {
          console.warn('ignoring error with no path: ', error);
          return errors;
        }

        return { ...errors, [name]: error };
      },
      {},
    );
  }
}

export const isYupError = (error: unknown): error is YupError => {
  if (!(error instanceof ValidationError)) {
    return false;
  }

  return 'path' in error && typeof error.path === 'string';
};

export const createValidationError = (message: string, path: string): YupError => {
  const value = new ValidationError(message, '', path);
  if (!isYupError(value)) {
    throw new Error('error constructor not properly defined');
  }

  return value;
};

const getBestMessageForError = (errorFlag: number): string => {
  if (
    (errorFlag & PasswordRequirements.containsSpecialCharacters) ===
    PasswordRequirements.containsSpecialCharacters
  ) {
    return PasswordNoSpecialCharactersError;
  } else if (
    (errorFlag & PasswordRequirements.containsAtLeastOneNumber) ===
    PasswordRequirements.containsAtLeastOneNumber
  ) {
    return PasswordNoNumberError;
  } else if (
    (errorFlag & PasswordRequirements.containsAtLeastOneLowerCaseLetter) ===
    PasswordRequirements.containsAtLeastOneLowerCaseLetter
  ) {
    return PasswordNoLowerCaseLettersError;
  } else if (
    (errorFlag & PasswordRequirements.containsAtLeastOneUpperCaseLetter) ===
    PasswordRequirements.containsAtLeastOneUpperCaseLetter
  ) {
    return PasswordNoUpperCaseLettersError;
  } else if (
    (errorFlag & PasswordRequirements.minimumLength) ===
    PasswordRequirements.minimumLength
  ) {
    return PasswordMustHaveAtLeast8Symbols;
  }

  return 'Your password does not conform to the requirements';
};
