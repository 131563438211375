import { ReactComponent as CloseIcon } from 'assets/icons/svg/close-popup-icon.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/svg/info-gray.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/svg/save-icon.svg';
import { ReactComponent as SavedIcon } from 'assets/icons/svg/saved-icon.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import { Tooltip } from 'components/tooltip';
import React from 'react';
import { POST_REPORTED_WARNING } from 'views/Home/strings';
import { FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;

  onSave(): void;
  onClose(): void;
}

export const TitleRightTool: React.FC<Props> = ({
  feedItem,
  onSave,
  onClose,
}: Props): React.ReactElement => {
  return (
    <div className="flex flex-row gap-2 items-center mt-1 ml-3">
      <ConditionalRender renderIf={feedItem.reported}>
        <Tooltip content={POST_REPORTED_WARNING}>
          <div className="w-5 h-5 text-red-500">
            <ReportIcon className="fill-current" />
          </div>
        </Tooltip>
      </ConditionalRender>
      {!process.env.REACT_APP_DATA_PORTAL && (
        <div className="w-5 h-5 text-blue cursor-pointer" onClick={onSave}>
          {feedItem.saved ? (
            <SavedIcon className="w-5 h-5 fill-current" />
          ) : (
            <SaveIcon className="w-5 h-5 fill-current" />
          )}
        </div>
      )}
      <div className="text-gray p-1 hover:text-red-500 rounded-full">
        <CloseIcon className="w-4 h-4 fill-current cursor-pointer" onClick={onClose} />
      </div>
    </div>
  );
};
