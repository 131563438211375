import { QueryStatus } from '@reduxjs/toolkit/query';
import { CommonSpinner } from 'components/commonSpinner';
import { Disabler } from 'components/disabler';
import { Button } from 'components/genericButton';
import SVGIcon from 'components/icons/SVGIcon';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessMode } from 'types/accessMode';
import {
  MarketplaceItem,
  useBookmarkMarketplaceItemMutation,
  useUnbookmarkMarketplaceItemMutation,
} from 'views/EarnAndBuy/api';

interface Props {
  readonly item: MarketplaceItem;
}

export const Actions: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const navigate = useNavigate();

  const [unbookmark, unbookmarkMutation] = useUnbookmarkMarketplaceItemMutation();
  const [bookmark, bookmarkMutation] = useBookmarkMarketplaceItemMutation();

  const currentStatus = React.useMemo((): any => {
    if (bookmarkMutation.status !== QueryStatus.uninitialized) {
      if (unbookmarkMutation.status !== QueryStatus.uninitialized) {
        throw new Error('both cannot be true at the same time');
      }

      return bookmarkMutation.status;
    } else if (unbookmarkMutation.status !== QueryStatus.uninitialized) {
      if (bookmarkMutation.status !== QueryStatus.uninitialized) {
        throw new Error('both cannot be true at the same time');
      }

      return unbookmarkMutation.status;
    } else {
      return undefined;
    }
  }, [bookmarkMutation, unbookmarkMutation]);

  const toggleBookmark = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      if (item.bookmarked) {
        unbookmark(item.id);
      } else {
        bookmark(item.id);
      }
      return;
    },
    [bookmark, item.bookmarked, item.id, unbookmark],
  );

  const handleRemove = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      navigate(`remove/${item.id}`);
    },
    [item.id, navigate],
  );

  const handleEdit = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      navigate(`edit/${item.id}`);
    },
    [item.id, navigate],
  );

  useEffect((): void => {
    if (bookmarkMutation.status === QueryStatus.fulfilled) {
      bookmarkMutation.reset();
    } else if (unbookmarkMutation.status === QueryStatus.fulfilled) {
      unbookmarkMutation.reset();
    }
  }, [bookmarkMutation, currentStatus, unbookmarkMutation]);

  const deleting = useMemo((): boolean => item.status === 'deleting', [item.status]);

  const handleBuy = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();

      navigate(`checkout/${item.id}`);
    },
    [item.id, navigate],
  );

  return (
    <>
      <div className="flex items-center justify-center gap-1">
        <button className="border-none text-blue bg-none mr-2" onClick={toggleBookmark}>
          <div className={iconButtonClassName} onClick={noop}>
            {item.bookmarked ? (
              <SVGIcon name="saved-icon" className="w-5 h-5 fill-current" />
            ) : (
              <SVGIcon name="save-icon" className="w-5 h-5 fill-current" />
            )}
          </div>
        </button>

        <Disabler disabled={item.access_mode === AccessMode.owner}>
          <Button type="button" variant="primary" label="Buy" onClick={handleBuy} />
        </Disabler>
        <div className="w-8" />

        <Disabler disabled={item.access_mode !== AccessMode.owner}>
          <button className="border-none bg-none" onClick={handleEdit}>
            <div className={iconButtonClassName}>
              <SVGIcon name="edit-icon" className="w-5 h-5 fill-current" />
            </div>
          </button>
        </Disabler>

        <Disabler disabled={item.access_mode !== AccessMode.owner}>
          {deleting ? (
            <div className={iconButtonContainerClassName}>
              <div className="w-6 h-6">
                <CommonSpinner />
              </div>
            </div>
          ) : (
            <button className="border-none bg-none" onClick={handleRemove}>
              <div className={iconButtonClassName}>
                <SVGIcon name="trash-generic" className="w-5 h-5 fill-current" />
              </div>
            </button>
          )}
        </Disabler>
        <div className="w-6" />
      </div>
    </>
  );
};

const iconButtonContainerClassName =
  'flex items-center justify-center relative cursor-pointer w-10 h-10 flex-shrink-0';

const iconButtonClassName = [iconButtonContainerClassName, 'hover:bg-gray-200 rounded-full'].join(
  ' ',
);
