import { TableRow } from 'components/table/tableRow';
import { ChildrenRows, ColumnDefinition, Identifiable } from 'components/table/types';
import React from 'react';

interface Props<T extends Identifiable, R extends Identifiable = any> {
  readonly columns: ReadonlyArray<ColumnDefinition<T>>;
  readonly rows: readonly T[];

  readonly rowChildren?: Record<string, ChildrenRows<R>>;
  readonly showChildrenHeader: boolean;

  onRowClick?(row: T): void;
}

export function TableBody<T extends Identifiable>({
  rows,
  columns,
  rowChildren,
  showChildrenHeader,
  onRowClick,
}: Props<T>): React.ReactElement {
  return (
    <tbody>
      {rows.map(
        (row: T): React.ReactElement => (
          <TableRow
            key={row.id}
            columns={columns}
            row={row}
            rowChildren={rowChildren?.[row.id]}
            showChildrenHeader={showChildrenHeader}
            onClick={onRowClick}
          />
        ),
      )}
    </tbody>
  );
}
