import { FinancialQuotes } from 'components/header/FinancialQuotes';
import { GlobalSearch } from 'components/header/UserHeader/components/globalSearch';
import { useLogoURI } from 'hooks/useLogoURI';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

interface Props {
  readonly actionButtons?: readonly React.ReactElement[];
  readonly menuOpen?: boolean;
  readonly searchOpen?: boolean;
}

export const MobileHeaderBase: React.FC<React.PropsWithChildren<Props>> = ({
  searchOpen = false,
  menuOpen = false,
  actionButtons = [],
  children,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const [drawerTop, setDrawerTop] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const logoURI = useLogoURI();

  const menuStyle = useMemo((): CSSProperties => ({ top: `${drawerTop}px` }), [drawerTop]);
  const menuClass = useMemo((): string => {
    const menuClassName =
      'fixed bg-white bottom-0 left-0 w-full transform translate-y-full transition-transform p-4 z-1';
    const menuVisibleClassName =
      'fixed bg-white bottom-0 left-0 w-full transform translate-y-0 transition-transform p-4 z-1';

    return menuOpen ? menuVisibleClassName : menuClassName;
  }, [menuOpen]);

  useEffect(() => {
    const { classList } = document.body;
    if (menuOpen) {
      classList.remove('overflow-auto');
      classList.add('overflow-hidden');
    } else {
      classList.remove('overflow-hidden');
      classList.add('overflow-auto');
    }
  }, [menuOpen]);

  useEffect((): void => {
    const { current: header } = headerRef;
    if (header === null) {
      return;
    }

    setDrawerTop(header.offsetHeight);
  }, []);

  return (
    <div className="sticky top-0 bg-white w-full z-1">
      <div ref={headerRef} className="flex justify-between items-center py-4 px-5 xg:hidden w-100">
        <div>
          <Link to="/">
            <img src={logoURI} alt="applicaiton-logo" className="text-green h-9" />
          </Link>
        </div>
        <div className="flex items-center gap-5">{actionButtons}</div>
      </div>

      {/* FIXME: this should not be part of the base component */}
      <div
        className={
          searchOpen
            ? 'max-h-24 transition-all duration-300 overflow-hidden'
            : 'max-h-0 transition-all duration-300 overflow-hidden'
        }
      >
        <GlobalSearch />
      </div>
      <FinancialQuotes />

      {ReactDOM.createPortal(
        <div className={menuClass} style={menuStyle}>
          {children}
        </div>,
        document.body,
      )}
    </div>
  );
};
