import { getColumnAlignment } from 'components/table/helpers';
import { SortIndicator } from 'components/table/sortIndicator';
import { SortOrder } from 'components/table/sorting';
import { Alignment, ColumnDefinition } from 'components/table/types';
import React, { useCallback, useMemo } from 'react';

interface Props<T> {
  readonly column: ColumnDefinition<T>;
  readonly sortOrder: SortOrder;

  onSortChange?(name: keyof T, sortOrder: SortOrder): void;
}

function HeaderCell<T>(
  { column, sortOrder, onSortChange }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement | null {
  const sortAscending = useCallback((): void => {
    switch (sortOrder) {
      case SortOrder.ascending:
        break;
      case SortOrder.descending:
      case SortOrder.none:
        onSortChange?.(column.name, SortOrder.ascending);
        break;
    }
  }, [column.name, onSortChange, sortOrder]);

  const sortDescending = useCallback((): void => {
    switch (sortOrder) {
      case SortOrder.descending:
        break;
      case SortOrder.ascending:
      case SortOrder.none:
        onSortChange?.(column.name, SortOrder.descending);
        break;
    }
  }, [column.name, onSortChange, sortOrder]);

  const alignment = useMemo((): Alignment => getColumnAlignment(column), [column]);

  if (column.sortable) {
    return (
      <div ref={ref} className={classes[alignment]}>
        {alignment === Alignment.center ? <div className="w-3 h-6" /> : null}
        <h4 className="text-xs leading-4">{column.label}</h4>
        <SortIndicator
          sortOrder={sortOrder}
          onSortAscending={sortAscending}
          onSortDescending={sortDescending}
        />
      </div>
    );
  } else {
    return (
      <div ref={ref} className={classes[alignment]}>
        <h4 className="text-xs leading-4">{column.label}</h4>
      </div>
    );
  }
}

const classes: { [key in Alignment]: string } = {
  [Alignment.left]: 'flex items-center justify-start cursor-default whitespace-pre-wrap gap-2',
  [Alignment.center]: 'flex items-center justify-center cursor-default whitespace-pre-wrap gap-2',
  [Alignment.right]: 'flex items-center justify-end cursor-default whitespace-pre-wrap gap-2',
};

export default React.forwardRef<HTMLDivElement, Props<any>>(HeaderCell);
