import { ConditionalRender } from 'components/conditionalRenderer';
import { ErrorBox } from 'components/errorBox';
import { Modal } from 'components/modal';
import { SuccessBox } from 'components/successBox';
import { useModal } from 'hooks/useModal';
import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubscriptionStatus, userSelector } from 'redux/reducers/authReducer';
import {
  actionCompleted,
  isProcessingStateForActions,
  processingStateSelector,
  SubscriptionPlansReducerActions,
} from 'redux/reducers/subscriptionPlansReducer';
import {
  cancelActiveSubscriptionPlanAction,
  getActiveSubscriptionForLoggedInUserAction,
  toggleActiveSubscriptionPlanAutoRenewalAction,
} from 'redux/services/subscriptionPlansService';
import { ProcessingStateEnum } from 'types/processingState';
import { amountFormatter } from 'views/Checkout/SubscriptionCheckout/formatters';
import { AutoRenewalToggle } from 'views/Profile/Payments/autoRenewalToggle';
import { CancelConsentModal } from 'views/Profile/Payments/cancelConsentModal';
import { CancellationNotice } from 'views/Profile/Payments/cancellationNotice';
import { billingCycleLabel } from 'views/Profile/Payments/index';
import { SubscriptionRenewStatus } from 'views/Profile/Payments/subscriptionRenewStatus';
import { zeroUUID } from 'views/SubscriptionPlans/individualsTab';

export const ActiveSubscription: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const { subscription: activeSubscription } = useSelector(userSelector);
  const [temporaryAutoRenew, setTemporaryAutoRenew] = React.useState<boolean | null>(null);

  const cancelRequestModal = useModal();
  const processingState = useSelector(processingStateSelector);
  const automaticRenewalChecked = useMemo((): boolean => {
    if (temporaryAutoRenew !== null) {
      return temporaryAutoRenew;
    }

    return !!activeSubscription?.is_automatic_renewal_on;
  }, [activeSubscription?.is_automatic_renewal_on, temporaryAutoRenew]);

  const cancelSucceeded = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.success,
      SubscriptionPlansReducerActions.cancelActiveSubscription,
    );
  }, [processingState]);

  const cancelFailed = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.fatalError,
      SubscriptionPlansReducerActions.cancelActiveSubscription,
    );
  }, [processingState]);

  const toggleAutoRenewSucceeded = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.success,
      SubscriptionPlansReducerActions.toggleActiveSubscriptionAutoRenewal,
    );
  }, [processingState]);

  const toggleAutoRenewFailed = useMemo((): boolean => {
    return isProcessingStateForActions(
      processingState,
      ProcessingStateEnum.fatalError,
      SubscriptionPlansReducerActions.toggleActiveSubscriptionAutoRenewal,
    );
  }, [processingState]);

  const resetProcessingState = useCallback((): void => {
    dispatch(actionCompleted());
  }, [dispatch]);

  const handleCloseConsentModal = useCallback((): void => {
    cancelRequestModal.close();
  }, [cancelRequestModal]);

  const expirationDateString = useMemo((): string => {
    if (!activeSubscription?.expires_at) {
      return '';
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    });

    const date = new Date(activeSubscription.expires_at);
    return `Expires on ${formatter.format(date)}`;
  }, [activeSubscription?.expires_at]);

  const handleToggleAutoRenewal = useCallback((): void => {
    setTemporaryAutoRenew(!activeSubscription?.is_automatic_renewal_on);

    setTimeout((): void => {
      dispatch(toggleActiveSubscriptionPlanAutoRenewalAction());
    }, 0);
  }, [activeSubscription?.is_automatic_renewal_on, dispatch]);

  const handleCancelActivePlan = useCallback(
    (reason: string): void => {
      handleCloseConsentModal();
      dispatch(cancelActiveSubscriptionPlanAction(reason));
    },
    [dispatch, handleCloseConsentModal],
  );

  const price = useMemo((): string => {
    const price = activeSubscription?.price;
    if (price === 0 || !price) {
      return '';
    } else {
      return amountFormatter.format(price);
    }
  }, [activeSubscription?.price]);

  const handleCancelRequested = useCallback((): void => {
    cancelRequestModal.open();
  }, [cancelRequestModal]);

  const billingCycleText = useMemo((): string => {
    return activeSubscription?.billing_cycle
      ? billingCycleLabel[activeSubscription?.billing_cycle]
      : '';
  }, [activeSubscription?.billing_cycle]);

  const canToggleAutoRenewOrCancel = useMemo((): boolean => {
    if (activeSubscription?.id === zeroUUID) {
      return false;
    }

    return activeSubscription?.status !== SubscriptionStatus.canceled;
  }, [activeSubscription?.id, activeSubscription?.status]);

  useEffect((): VoidFunction => {
    return dispatch(getActiveSubscriptionForLoggedInUserAction());
  }, [dispatch]);

  return (
    <>
      <div className="relative flex items-center gap-6 px-5 py-4 my-4 rounded-md shadow-md">
        <div className="flex items-center gap-6 mr-auto">
          <div>
            <h3 className="font-poppinsSemiBold text-base uppercase">
              <span>Current Plan</span>
              <ConditionalRender renderIf={activeSubscription?.is_free_trial === true}>
                <span className="text-gray font-poppinsMedium italic mx-1">
                  Free {activeSubscription?.free_trial_days}-Day Trial
                </span>
              </ConditionalRender>
            </h3>
            <h3 className="flex flex-wrap	items-center mb-0.5 gap-1">
              <span>
                {activeSubscription?.name} {price}
              </span>
              <span className="text-gray font-poppins text-base">{billingCycleText}</span>
            </h3>
            <p className="text-gray break-words w-full">{expirationDateString}</p>
          </div>

          <ConditionalRender renderIf={!!activeSubscription?.is_automatic_renewal_on}>
            <SubscriptionRenewStatus
              card={activeSubscription?.payment_method}
              expiresAt={activeSubscription?.expires_at}
            />
          </ConditionalRender>

          <ConditionalRender renderIf={activeSubscription?.status === SubscriptionStatus.canceled}>
            <CancellationNotice expiresAt={activeSubscription?.expires_at} />
          </ConditionalRender>
        </div>

        <ConditionalRender renderIf={canToggleAutoRenewOrCancel}>
          <div className="grid" style={gridStyle}>
            <AutoRenewalToggle
              processingState={processingState}
              checked={automaticRenewalChecked}
              onChange={handleToggleAutoRenewal}
            />

            <div />
            <h3
              className="font-poppinsSemiBold text-base text-red cursor-pointer rounded-full"
              onClick={handleCancelRequested}
            >
              Cancel
            </h3>
            <div />
          </div>
        </ConditionalRender>

        <Link
          to="/upgrade"
          className="font-poppinsSemiBold text-base bg-blue text-white cursor-pointer px-3 leading-10 rounded min-w-max text-center"
        >
          Change Plan
        </Link>
      </div>

      <Modal isOpen={cancelRequestModal.isOpen} onClose={handleCloseConsentModal}>
        <Modal.Content title="Cancel Subscription">
          <CancelConsentModal
            message="Are you sure you want to cancel your currently active subscription?"
            onClose={handleCloseConsentModal}
            onYes={handleCancelActivePlan}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={cancelSucceeded} onClose={resetProcessingState}>
        <SuccessBox
          title="Cancellation Request Submitted"
          message="We have received a request to cancel your subscription."
          onClose={resetProcessingState}
        />
      </Modal>

      <Modal isOpen={cancelFailed} onClose={resetProcessingState}>
        <ErrorBox
          title="Cancel Subscription Failed"
          message="There was an error canceling your subscription. Please try again later."
          onClose={resetProcessingState}
        />
      </Modal>

      <Modal isOpen={toggleAutoRenewSucceeded} onClose={resetProcessingState}>
        <SuccessBox
          title="Auto Renewal Changed"
          message="Auto renewal was changed correctly"
          onClose={resetProcessingState}
        />
      </Modal>

      <Modal isOpen={toggleAutoRenewFailed} onClose={resetProcessingState}>
        <ErrorBox
          title="Toggle Auto Renewal Failed"
          message="There was an error changing auto renewal. Please try again later."
          onClose={resetProcessingState}
        />
      </Modal>
    </>
  );
};

const gridStyle: CSSProperties = {
  gridTemplateColumns: 'auto auto auto',
  gridTemplateRows: 'repeat(2, 1fr)',
  alignItems: 'center',
  columnGap: 8,
};
