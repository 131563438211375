import { MarkerDot } from 'components/capnoteMap/components/markerDot';
import SVGIcon from 'components/icons/SVGIcon';
import { Tooltip } from 'components/tooltip';
import { DivIcon, divIcon } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Marker } from 'react-leaflet';
import { CompanySubsidiaryLocation } from 'redux/reducers/geographiesAndMapsReducer';

interface Props {
  readonly marker: CompanySubsidiaryLocation;

  onClick(marker: CompanySubsidiaryLocation): void;
}

export const SubsidiaryMarker: React.FC<Props> = ({
  marker,
  onClick,
}: Props): React.ReactElement => {
  const [icon, setIcon] = useState<DivIcon>(new DivIcon());
  const { coordinates } = marker;

  const handleClick = React.useCallback((): void => {
    const { subsidiaries } = marker;
    if (subsidiaries.length > 1) {
      onClick(marker);
    }
  }, [marker, onClick]);

  useEffect(
    (): void =>
      setIcon(
        divIcon({
          html: createDot(marker, handleClick),
        }),
      ),
    [handleClick, marker],
  );

  if (coordinates === null) {
    return <></>;
  }

  return <Marker position={[coordinates.latitude, coordinates.longitude]} icon={icon} />;
};

const createDot = (marker: CompanySubsidiaryLocation, clickHandler: VoidFunction): HTMLElement => {
  const { subsidiaries } = marker;

  if (subsidiaries.length === 0) {
    throw new Error('this does not seem to be a subsidiaries location dot');
  }

  const element = document.createElement('div');
  const root = createRoot(element);

  if (subsidiaries.length === 1) {
    const [first] = subsidiaries;

    root.render(
      <Tooltip
        content={<p className="text-gray font-jostSemiBold whitespace-nowrap">{first}</p>}
        variant="light"
        fitContent={true}
      >
        <MarkerDot color="blue" size="small" />
      </Tooltip>,
    );
  } else {
    // Render this object
    root.render(
      <MarkerDot color="blue" size="small" onClick={clickHandler}>
        <div className="absolute flex items-center gap-1 bottom-full left-full m-0.5 bg-blue rounded-t rounded-br p-1">
          <SVGIcon name="building-icon-white" className="w-3" />
          <p className="text-xs text-white">{subsidiaries.length}</p>
        </div>
      </MarkerDot>,
    );
  }

  return element;
};
