import { Input } from 'components/forms/input';
import { NumericInput } from 'components/numericInput';
import { TimestampDateInput } from 'components/timestampDateInput';
import React from 'react';
import { PortfolioCompanyInvestmentOtherItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyInvestmentOtherItem;
}

export const InvestmentOtherItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCompanyInvestmentOtherItem>();

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <Input name="investmentType" label="Investment Type" value="" />
      <TimestampDateInput
        name="purchaseTimestamp"
        label="Purchase Date"
        value={item.purchaseTimestamp}
        onChange={updaterFor('purchaseTimestamp')}
      />
      <NumericInput
        name="expectedAnnualReturn"
        label="Expected annual return"
        value={item.expectedAnnualReturnPercent}
        type="percent"
        onValueChange={updaterFor('expectedAnnualReturnPercent')}
      />
      <NumericInput
        name="expectedHoldingPeriod"
        label="Expected holding period (months)"
        value={item.expectedHoldingPeriodMonths}
        precision={0}
        onValueChange={updaterFor('expectedHoldingPeriodMonths')}
      />
    </div>
  );
};
