import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { Buy } from 'views/EarnAndBuy/Buy';
import { MarketplaceItemCheckout } from 'views/EarnAndBuy/Buy/components/checkout';
import { Earn } from 'views/EarnAndBuy/Earn';
import { Library } from 'views/EarnAndBuy/Library';
import { SpecialOffers } from 'views/EarnAndBuy/SpecialOffers';

export const EarnAndBuy: React.FC = (): React.ReactElement => {
  const location = useLocation();

  const currentTab = React.useMemo((): string => {
    const currentPath = location.pathname;
    if (currentPath.endsWith('special-offers')) {
      return 'SPECIAL OFFERS';
    } else {
      return 'EARN';
    }
  }, [location.pathname]);

  return (
    <MainOutletContent>
      <Breadcrumbs path={['EARN & BUY', currentTab]} />
      <TextLine content="Earn income from your contributions and expertise. Click below to apply for tasks." />
      <div className="h-2" />
      <Tabs variant={TabsVariant.buttons} shrinkFactor={6}>
        <Tab label="Buy" path="buy/*" element={<Buy />} />
        <Route path="buy/:action/:listingId?" element={<Buy />} />
        <Route path="buy/checkout/:listingId" element={<MarketplaceItemCheckout />} />

        <Tab label="Earn" path="earn/*" element={<Earn />} />
        <Route path="earn/:listingId?" element={<Earn />} />

        <Tab label="Special Offers" path="special-offers/*" element={<SpecialOffers />} />
        <Route path="special-offers/:specialOfferId" element={<SpecialOffers />} />

        <Tab label="Library" path="library" element={<Library />} />

        <Route path="" element={<Navigate to="earn" />} />
      </Tabs>
    </MainOutletContent>
  );
};
