export enum EditorState {
  typing = 'typing',
  insertUser = 'insertUser',
  insertTag = 'insertTag',
}

export enum NodeType {
  mention = 'mention',
  tag = 'tag',
  link = 'link',
  text = 'text',
}

export interface BaseBodyElement {
  readonly type: NodeType;
  readonly content: string;
}

export interface TextBodyElement extends BaseBodyElement {
  readonly type: NodeType.text;
}

export interface TagBodyElement extends BaseBodyElement {
  readonly type: NodeType.tag;
}

export interface MentionBodyElement extends BaseBodyElement {
  readonly type: NodeType.mention;
  readonly user_id: string;
}

export interface LinkBodyElement extends BaseBodyElement {
  readonly type: NodeType.link;
  readonly url: string;
}

export type BodyElement = TextBodyElement | TagBodyElement | MentionBodyElement | LinkBodyElement;

export interface PostBody {
  readonly elements: BodyElement[];
}

export class PostBody {
  public static empty(): PostBody {
    return {
      elements: [],
    };
  }
}

export interface PostBodyInputAPI {
  getContent(): PostBody;
  getTags(): readonly string[];
  getContentLength(): number;
}

export class PostBodyInputAPI {}

export class TextBodyElement {
  public static fromNode(node: Node): TextBodyElement {
    if (node.textContent === null) {
      throw new Error('trying to build a body element from an invalid text node');
    }

    return {
      type: NodeType.text,
      content: node.textContent,
    };
  }
}

export class TagBodyElement {
  public static fromElement(element: Element): TagBodyElement {
    const tag = element.getAttribute('data-value');
    if (element.textContent === null || tag === null) {
      throw new Error('trying to build a body element from an invalid text node');
    }

    return { type: NodeType.tag, content: tag };
  }
}

export class MentionBodyElement {
  public static fromElement(element: Element): MentionBodyElement {
    const content = element.textContent;
    const userID = element.getAttribute('data-value');
    if (content === null || userID === null) {
      throw new Error('trying to build a mention body element from an invalid node');
    }

    return {
      type: NodeType.mention,
      content: content,
      user_id: userID,
    };
  }
}

export class LinkBodyElement {
  public static fromElement(element: Element): LinkBodyElement {
    const content = element.textContent;
    if (content === null) {
      throw new Error('trying to build a link body element from a node without contents');
    }

    return {
      type: NodeType.link,
      // We only build them with the HREF text as of now
      content: content,
      url: content,
    };
  }
}

export const bodyElementFromNode = (node: Node): BodyElement => {
  if (node.nodeType === Node.TEXT_NODE) {
    return TextBodyElement.fromNode(node);
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as Element;
    switch (element.getAttribute('data-type')) {
      case NodeType.mention:
        return MentionBodyElement.fromElement(element);
      case NodeType.tag:
        return TagBodyElement.fromElement(element);
      case NodeType.link:
        return LinkBodyElement.fromElement(element);
    }
  }
  return {} as BodyElement;
};
