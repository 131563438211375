import Sidebar from 'components/sidebar';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EarnAndBuy } from 'views/Landing/LandingPage/components/PublicEarnAndBuy/EarnAndBuy';

export const PublicEarnAndBuy: React.FC = (): React.ReactElement => {
  const [sidebarContainer, setSidebarContainer] = useState<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      const { target } = event;
      if (target instanceof HTMLAnchorElement) {
        return;
      }

      event.stopPropagation();
      navigate('/sign-up');
    },
    [navigate],
  );

  useLayoutEffect((): void | VoidFunction => {
    if (sidebarContainer === null) {
      return;
    }

    const links = Array.from(sidebarContainer.querySelectorAll('a'));
    const clickHandler = (event: MouseEvent): void => {
      event.stopPropagation();
      navigate('/sign-up');
    };

    for (const link of links) {
      link.removeAttribute('href');
      link.addEventListener('click', clickHandler);
    }

    return (): void => {
      for (const link of links) {
        link.removeEventListener('click', clickHandler);
      }
    };
  }, [handleClick, navigate, sidebarContainer]);

  return (
    <div className="h-full bg-gray-darkest px-4 pb-3 flex gap-3" onClickCapture={handleClick}>
      <div
        ref={setSidebarContainer}
        className="h-full flex-shrink-0 bg-white w-sidebar p-5 rounded"
      >
        <Sidebar />
      </div>
      <div className="h-full w-full bg-white rounded">
        <EarnAndBuy />
      </div>
    </div>
  );
};
