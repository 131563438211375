import { Clickable } from 'components/clickable';
import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import { Tag } from 'components/tag';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sharingProcessingStateSelector } from 'redux/reducers/feedReducer';
import { shareNews, sharePost } from 'redux/services/feedService';
import { ProcessingStateEnum } from 'types/processingState';
import { isValidEmail } from 'utils';
import { FeedCategory, FeedItem } from 'views/Home/types';
import { Button } from 'views/Landing/component/button';
import { Input } from 'views/Landing/component/input';

interface Props {
  readonly feedItem: FeedItem;

  onClose(): void;
}

export const ShareModal: React.FC<Props> = ({ feedItem, onClose }: Props): React.ReactElement => {
  const [value, setValue] = React.useState<string>('');
  const [list, setList] = React.useState<readonly string[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  const processingState = useSelector(sharingProcessingStateSelector);
  const dispatch = useDispatch<any>();

  useEffect((): void => {
    switch (processingState.state) {
      case ProcessingStateEnum.idle:
      case ProcessingStateEnum.processing:
        break;
      case ProcessingStateEnum.error:
      case ProcessingStateEnum.fatalError:
      case ProcessingStateEnum.success:
        onClose();
    }
  }, [onClose, processingState.state]);

  const handleRemove = useCallback((email: string): void => {
    setList((currentList: readonly string[]): readonly string[] =>
      currentList.filter((item: string): boolean => item !== email),
    );
  }, []);

  const handleAdd = useCallback((): void => {
    setError(null);

    if (isValidEmail(value)) {
      setValue('');
      setList((currentList: readonly string[]): readonly string[] =>
        Array.from(new Set([...currentList, value])),
      );
    } else {
      setError('Invalid email address, example: user@domain.com');
    }
  }, [value]);

  const handleShare = useCallback((): void => {
    switch (feedItem.category) {
      case FeedCategory.post:
        dispatch(sharePost(feedItem.id, list));
        break;
      case FeedCategory.news:
        dispatch(shareNews(feedItem.id, list));
        break;
      case FeedCategory.notification:
        break;
    }
  }, [dispatch, feedItem.category, feedItem.id, list]);

  return (
    <div className="md:w-modal-sm">
      <h1>Share Post</h1>
      <div className="flex items-end mt-4 gap-4">
        <div className="flex-1">
          <Input
            value={value}
            label="Enter email address"
            onChange={setValue}
            onSubmit={handleAdd}
          />
        </div>

        <div className="text-right">
          <button
            className="bg-blue text-white text-sm font-poppinsSemiBold w-24 h-14 rounded"
            onClick={handleAdd}
          >
            Add
          </button>
        </div>
      </div>
      <div className="mb-4">
        {error && <span className="font-poppinsSemiBold text-xs text-red-500">{error}</span>}
      </div>

      <div className="flex items-start gap-4 py-3">
        {list.length > 0 && <p>Send to:</p>}
        <div className="flex items-center gap-2 flex-wrap">
          {list.map(
            (email: string): React.ReactElement => (
              <Tag key={email}>
                <div className="flex items-center justify-between gap-2">
                  <span>{email}</span>
                  <Clickable clickData={email} onClick={handleRemove}>
                    <div className="cursor-pointer text-gray-darker hover:text-red">
                      <SVGIcon name="close-popup-icon" className="w-2.5 fill-current" />
                    </div>
                  </Clickable>
                </div>
              </Tag>
            ),
          )}
        </div>
      </div>

      <div className="flex justify-end items-center gap-3 mt-12 w-full">
        <Button label="Close" variant="secondary" onClick={onClose} />
        <Button label="Share" disabled={list.length === 0} onClick={handleShare} />
      </div>

      <SpinnerLoader visible={processingState.state === ProcessingStateEnum.processing} />
    </div>
  );
};
