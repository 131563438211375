import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import {
  clearErrors,
  getCompanyFactorsSuccess,
  getFactorsFailed,
  getFactorsLoading,
  getIndustryFactorsSuccess,
  getPortfolioFactorsSuccess,
  KeyFactorType,
} from 'redux/reducers/keyFactorsReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';

export type FactorPath = 'krf' | 'kgf';

export const getPortfolioFactorsService = createAPIAction(function getPortfolioFactorsService(
  client: HttpClient,
): ActionsGenerator {
  return async function* (
    id: string | undefined,
    params: any,
    type: KeyFactorType,
  ): AsyncGenerator<AnyAction> {
    yield getFactorsLoading();
    try {
      const kxf = type === 'risk' ? 'krf' : 'kgf';
      const url = id
        ? `${API_V1_PATH}/portfolios/${id}/${kxf}`
        : `${API_V1_PATH}/portfolios/${kxf}`;
      const response = await client.GET(url, { params: params });
      yield getPortfolioFactorsSuccess(response.data);
    } catch (error: any) {
      yield getFactorsFailed(error);
    }
  };
});

// export const getPortfolioFactorsService = (
//   id: string | undefined,
//   params: any,
//   type: KeyFactorType,
//   cancelToken: CancelToken,
// ): ((dispatch: Dispatch) => void) => {
//   return (dispatch: Dispatch): void => {
//     dispatch(clearErrors());
//     dispatch(getFactorsLoading());
//     const kxf = type === 'risk' ? 'krf' : 'kgf';
//     const url = id ? `${API_V1_PATH}/portfolios/${id}/${kxf}` : `${API_V1_PATH}/portfolios/${kxf}`;
//     api
//       .get(url, { params: params, cancelToken: cancelToken })
//       .then((response: AxiosResponse): void => {
//         if (response.status === HttpStatusOk) {
//           dispatch(getPortfolioFactorsSuccess(response.data));
//         }
//       })
//       .catch((error: any): void => {
//         dispatch(
//           showInfoModal({
//             type: 'error',
//             message: error?.message || errorMessageDefault,
//           }),
//         );
//         dispatch(getFactorsFailed(error));
//       });
//   };
// };

export const getCompanyFactorsService =
  (companyId: string, factorPath: FactorPath) => (dispatch: Dispatch) => {
    dispatch(clearErrors());
    dispatch(getFactorsLoading());
    api
      .get(`api/v1/companies/${companyId}/${factorPath}`)
      .then((response: AxiosResponse) => {
        if (response.status === HttpStatusOk) {
          dispatch(getCompanyFactorsSuccess(response.data));
        } else throw new Error();
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(getFactorsFailed(err));
      });
  };

export const getIndustryFactorsService =
  (id: string, factorPath: FactorPath) => (dispatch: Dispatch) => {
    dispatch(clearErrors());
    dispatch(getFactorsLoading());
    api
      .get(`api/v1/industries/${id}/${factorPath}`)
      .then((response: AxiosResponse) => {
        if (response.status === HttpStatusOk) {
          dispatch(getIndustryFactorsSuccess(response.data));
        } else throw new Error();
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(getFactorsFailed(err));
      });
  };
