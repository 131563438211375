import { ReactComponent as LoadingArrow } from 'assets/graphics/svg/comments-loading-popup-arrow.svg';
import { ReactComponent as IdleArrow } from 'assets/graphics/svg/comments-popup-arrow.svg';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { financialCommentsSelector } from 'redux/reducers/financialCommentsReducer';
import { addComment, getComments } from 'redux/services/financialCommentsServices';
import { stopPropagation } from 'utils/ignoreEvent';
import CommentsPopupContent from 'views/DataAnalysis/common/commentsPopupContent';

interface Props {
  readonly anchor: HTMLElement | null;
  readonly filingId: string;
  readonly isOpen: boolean;
  readonly entityName: 'organizations';
  readonly statement: string;

  onClose(): void;
}

export const CommentsPopup: React.FC<Props> = ({
  anchor,
  filingId,
  entityName,
  statement,
  isOpen,
  onClose,
}: Props): React.ReactElement | null => {
  const [popup, setPopup] = useState<HTMLElement | null>(null);
  const [arrow, setArrow] = useState<HTMLElement | null>(null);
  const [portalContainer] = useState<HTMLElement>(document.createElement('div'));

  const { styles, attributes, update } = usePopper(anchor, popup, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrow,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 15],
        },
      },
    ],
  });
  const state = useSelector(financialCommentsSelector);
  const dispatch = useDispatch<any>();

  const onAddComment = (commentText: string): void => {
    dispatch(addComment(entityName, statement, filingId, commentText));
  };

  useEffect((): VoidFunction | void => {
    const cancelTokenSource = axios.CancelToken.source();
    const { body } = document;
    if (!isOpen) {
      return;
    }
    dispatch(getComments(entityName, filingId, cancelTokenSource.token));

    body.appendChild(portalContainer);
    return (): void => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('request_cancelled');
      }

      body.removeChild(portalContainer);
    };
    /* filingId, entityName, portalContainer, isOpen, dispatch, cancelTokenSource */
  }, [dispatch, entityName, filingId, isOpen, portalContainer]);

  useEffect((): void => {
    if (update === null) {
      return;
    }

    void update();
  }, [update]);

  const placement = useMemo(
    (): string => attributes.popper?.['data-popper-placement'] ?? '',
    [attributes.popper],
  );
  const arrowSvgClass = useMemo(
    (): string => `${placement === 'top' ? 'transform rotate-180' : ''}`,
    [placement],
  );
  const arrowClass = useMemo((): string | undefined => {
    switch (placement) {
      case 'top':
        return 'left-0 -bottom-8 w-8 h-8 overflow-hidden';
      case 'bottom':
        return 'left-0 -top-8 w-8 h-8 overflow-hidden';
      default:
        return undefined;
    }
  }, [placement]);

  const Arrow = useMemo(() => {
    if (state.loading) {
      return LoadingArrow;
    } else {
      return IdleArrow;
    }
  }, [state.loading]);

  if (!isOpen || anchor === null) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-1 bg-transparent" onClick={onClose}>
      <div ref={setPopup} style={styles.popper} {...attributes.popper} onClick={stopPropagation}>
        <CommentsPopupContent
          comments={state.comments}
          loading={state.loading}
          error={state.lastError}
          onAddComment={onAddComment}
        />
        <div ref={setArrow} style={styles.arrow} className={arrowClass}>
          <Arrow className={arrowSvgClass} />
        </div>
      </div>
    </div>,
    portalContainer,
  );
};
