import { ReactComponent as InfoIcon } from 'assets/icons/svg/info-icon.svg';
import RadioInput from 'components/radioInput/RadioInput';
import { Tooltip } from 'components/tooltip';
import React, { useCallback } from 'react';
import { AccountType } from 'types/accountType';
import { isBeta } from 'utils';

interface Props {
  readonly value: AccountType;

  onChange(value: AccountType): void;
}

export const AccountTypeSelector: React.FC<Props> = ({
  value,
  onChange,
}: Props): React.ReactElement => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      if (target.checked) {
        const { value } = target;
        onChange(value as AccountType);
      }
    },
    [onChange],
  );

  return (
    <div className="flex flex-col items-center">
      <div className="flex w-full items-center gap-2">
        <span className="font-poppins text-gray tracking-normal leading-5 text-base">
          Account type:{' '}
        </span>
        <Tooltip
          content={
            <div className="py-2">
              <p className="text-xs mb-2 normal-case">
                <b>Public</b>: Your profile and content may be seen by any Capnote user
              </p>
              <p className="text-xs normal-case">
                <b>Private</b>: Your profile and content may only be seen by Capnote users that you
                have connected with
              </p>
            </div>
          }
        >
          <InfoIcon width={16} />
        </Tooltip>
      </div>
      <div className="flex items-center justify-start mt-6 w-full">
        <div className="flex gap-12">
          {Object.entries(availableTypes)
            .filter(
              ([, descriptor]: [string, AccountTypeDescriptor]): boolean => descriptor.available,
            )
            .map(
              ([key, descriptor]: [string, AccountTypeDescriptor]): React.ReactElement => (
                <RadioInput
                  key={key}
                  id={`radio-${key.toLowerCase()}`}
                  label={descriptor.label}
                  value={key as AccountType}
                  checked={value === key}
                  onChange={handleChange}
                />
              ),
            )}
        </div>
      </div>
    </div>
  );
};

interface AccountTypeDescriptor {
  readonly label: string;
  readonly available: boolean;
  readonly enabled: boolean;
  readonly disabledNotice?: string;
}

const availableTypes: Partial<{ [key in AccountType]: AccountTypeDescriptor }> = {
  [AccountType.public]: {
    label: 'Public',
    enabled: true,
    available: true,
  },
  [AccountType.private]: {
    label: 'Private',
    enabled: !isBeta,
    disabledNotice: '(Not available during BETA)',
    available: true,
  },
  [AccountType.hidden]: {
    label: 'Hidden',
    enabled: false,
    available: false,
    disabledNotice: '',
  },
};
