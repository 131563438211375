import { QueryStatus } from '@reduxjs/toolkit/query';
import { ErrorBox } from 'components/errorBox';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import { Select, SelectItem } from 'components/select';
import { isBefore, parse } from 'date-fns';
import { useGtag } from 'hooks/useGtag';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPortfolioItemItemSelector,
  addPortfolioItemModifiedSelector,
  reset,
  setItem,
  setItemType,
} from 'redux/reducers/addPortfolioItemFormReducer';
import { Page } from 'types/page';
import {
  PortfolioItemType,
  portfolioItemTypeLabels,
  PortfolioTableItem,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import api, {
  useAddItemMutation,
  useItemQuery,
  useUpdateItemMutation,
} from 'views/Portfolios/PortfoliosModal/api';
import { ItemDetailsFormSelector } from 'views/Portfolios/PortfoliosModal/itemDetailsFormSelector';

interface Props {
  readonly portfolioId: string;
  readonly relationshipId?: string;
  readonly itemId?: string;

  onClose(): void;
}

export const PortfolioItemFormEditCreateForm: React.FC<Props> = ({
  portfolioId,
  itemId,
  onClose,
}: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const item = useSelector(addPortfolioItemItemSelector);
  const queryParameters = useQueryParameters();
  const modified = useSelector(addPortfolioItemModifiedSelector);

  const [addItem, addItemMutation] = useAddItemMutation();
  const [updateItem, updateItemMutation] = useUpdateItemMutation();
  const [closeAfterSave, setCloseAfterSave] = useState<boolean>(false);
  const { data: currentItem, isFetching } = useItemQuery(
    {
      portfolioId: portfolioId,
      itemId: itemId,
    },
    {
      skip: isEmptyOrNullOrUndefined(itemId),
    },
  );

  const { trackEvent } = useGtag();
  const mutation = React.useMemo((): any => {
    if (itemId) {
      return updateItemMutation;
    } else {
      return addItemMutation;
    }
  }, [addItemMutation, updateItemMutation, itemId]);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleSave = useCallback((): void => {
    if (!itemId) {
      addItem({
        portfolioId: portfolioId,
        item: item,
      });

      trackEvent('added-portfolio-item', {
        item: item,
      });
    } else {
      setCloseAfterSave(true);
      updateItem({
        portfolioId: portfolioId,
        item: item,
      });

      trackEvent('updated-portfolio-item', {
        item: item,
      });
    }

    return;
  }, [addItem, item, itemId, portfolioId, trackEvent, updateItem]);

  const handleSaveAndClose = useCallback((): void => {
    setCloseAfterSave(true);
    handleSave();
  }, [handleSave]);

  useEffect((): void => {
    const { util } = api;
    if (!util) {
      console.warn('the `util` object is not defined on the api object');
      return;
    }

    if (mutation.status === QueryStatus.fulfilled) {
      dispatch(reset());

      dispatch(
        util.updateQueryData(
          'items',
          { ...queryParameters, portfolioId },
          (page: Page<PortfolioTableItem>): Page<PortfolioTableItem> => {
            const newItem = mutation.data;
            if (newItem === null) {
              return page;
            }

            const isSame = (item: PortfolioTableItem): boolean => {
              return item.id === newItem.id;
            };
            const sortFn = (a: PortfolioTableItem, b: PortfolioTableItem): number => {
              const { added_at: aAddedAt } = a;
              const { added_at: bAddedAt } = b;

              const aAddedAtDate = parse(aAddedAt, 'yyyy-MM-dd', new Date());
              const bAddedAtDate = parse(bAddedAt, 'yyyy-MM-dd', new Date());

              return isBefore(aAddedAtDate, bAddedAtDate) ? 1 : -1;
            };
            // Need to map the data to the PortfolioItem type
            return Page.addOrReplace<PortfolioTableItem>(page, newItem, isSame, sortFn);
          },
        ),
      );
    }
  }, [dispatch, itemId, mutation.data, mutation.status, portfolioId, queryParameters]);

  const handleSaveFn = useMemo((): VoidFunction | undefined => {
    if (modified) {
      return handleSave;
    }

    return undefined;
  }, [handleSave, modified]);

  const handleSaveAndCloseFn = useMemo((): VoidFunction | undefined => {
    if (modified) {
      return handleSaveAndClose;
    }

    return undefined;
  }, [handleSaveAndClose, modified]);

  useEffect((): void => {
    if (!closeAfterSave) {
      return;
    }

    if (mutation.status === QueryStatus.fulfilled) {
      onClose();
    }
  }, [closeAfterSave, mutation.status, onClose]);

  useEffect((): void => {
    if (currentItem) {
      dispatch(setItem(currentItem));
    }
  }, [currentItem, dispatch]);

  const handleItemTypeChange = useCallback(
    (value: PortfolioItemType): void => {
      dispatch(setItemType(value));
    },
    [dispatch],
  );

  const closeFailureMessage = useCallback((): void => {
    mutation.reset();
  }, [mutation]);

  const showSpinner = useMemo((): boolean => {
    if (isFetching) {
      return true;
    }

    return mutation.status === QueryStatus.pending;
  }, [isFetching, mutation.status]);

  const errorTitle = useMemo((): string => {
    if (itemId) {
      return 'There was a problem updating the portfolio item';
    } else {
      return 'There was a problem adding the item to the portfolio';
    }
  }, [itemId]);

  return (
    <>
      <Select
        items={portfolioItemTypes}
        value={item.type}
        label="Item Type"
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onChange={handleItemTypeChange}
      />

      <form autoComplete="off">
        <div className="flex flex-col gap-4 w-full flex-1">
          <ItemDetailsFormSelector item={item} />
        </div>
      </form>

      <div className="flex items-center gap-3 pt-12 bg-gray-light sticky bottom-0">
        <div className="mr-auto">
          <Button type="button" variant="secondary" label="Cancel" onClick={onClose} />
        </div>
        {!isEmptyOrNullOrUndefined(item.id) ? (
          <>
            <Button
              type="button"
              variant="primary"
              label="Save"
              disabled={false}
              onClick={handleSaveFn}
            />
          </>
        ) : (
          <>
            <Button
              type="button"
              variant="secondary"
              label="Save & Add"
              disabled={false}
              onClick={handleSaveFn}
            />
            <Button
              type="button"
              variant="primary"
              label="Save & Close"
              disabled={false}
              onClick={handleSaveAndCloseFn}
            />
          </>
        )}
      </div>
      <FullScreenLoadingSpinner visible={showSpinner} />

      <Modal isOpen={mutation.status === QueryStatus.rejected} onClose={closeFailureMessage}>
        <Modal.Content>
          <ErrorBox
            title={errorTitle}
            message="We are not sure what went wrong. Please try again. If the problem persists, please contact support."
            onClose={closeFailureMessage}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const portfolioItemTypes: Array<SelectItem<PortfolioItemType>> = enumToSelectItems(
  PortfolioItemType,
  portfolioItemTypeLabels,
  (value: PortfolioItemType): boolean =>
    value !== PortfolioItemType.publicCompany &&
    value !== PortfolioItemType.privateCompany &&
    value !== PortfolioItemType.cash &&
    value !== PortfolioItemType.cryptoCurrency &&
    value !== PortfolioItemType.etf &&
    value !== PortfolioItemType.commodity &&
    value !== PortfolioItemType.realEstate &&
    value !== PortfolioItemType.other &&
    value !== PortfolioItemType.art,
);
