import { PostBody } from 'components/postBodyInput/types';
import { formatISO } from 'date-fns';
import { SocialUser } from 'views/Home/types';

export interface FeedComment {
  readonly type: 'comment';
  readonly id: string;
  readonly body: PostBody;
  readonly commenter: SocialUser;
  readonly createdAt: string;
  readonly editedAt: string | null;
  readonly repliesCount: number;
  readonly likesCount: number;
  readonly dislikesCount: number;
  readonly liked: boolean;
  readonly disliked: boolean;
  readonly flagged: boolean;
  readonly replied: boolean;
  readonly reported: boolean;
  readonly timeLeftToEdit: number;
}

export class FeedComment {
  private constructor() {
    // Just don't let anyone construct classes of this type
  }

  public static fromJson(json: Record<string, any>): FeedComment {
    return {
      type: 'comment',
      id: json.id as string,
      body: json.body as PostBody,
      commenter: json.commenter as SocialUser,
      createdAt: json.created_at as string,
      editedAt: json.edited_at as string | null,
      repliesCount: json.replies_count as number,
      likesCount: json.likes_count as number,
      dislikesCount: json.dislikes_count as number,
      liked: json.liked as boolean,
      disliked: json.disliked as boolean,
      flagged: json.flagged as boolean,
      replied: json.replied as boolean,
      reported: json.reported as boolean,
      timeLeftToEdit: json.time_left_to_edit_ms as number,
    };
  }

  public static empty(): FeedComment {
    return {
      type: 'comment',
      id: '',
      body: PostBody.empty(),
      commenter: {
        id: '',
        name: '',
        about: '',
        followed_by_you: false,
        follows_you: false,
        accredited_investor: false,
      },
      createdAt: formatISO(new Date()),
      editedAt: null,
      repliesCount: 0,
      likesCount: 0,
      dislikesCount: 0,
      liked: false,
      disliked: false,
      flagged: false,
      replied: false,
      reported: false,
      timeLeftToEdit: 0,
    };
  }
}

export interface FeedDetailState {
  fetchingComments: boolean;
  postingComment: boolean;
  fetchingRepliesFor: string | null;
}
