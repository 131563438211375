import React from 'react';
import { PortfolioCompanyRelationshipType } from 'types/portfolioItem';

interface Props {
  readonly relationshipType: PortfolioCompanyRelationshipType;
}

export const RelationshipType: React.FC<Props> = ({
  relationshipType,
}: Props): React.ReactElement => {
  switch (relationshipType) {
    case PortfolioCompanyRelationshipType.clientOrCustomer:
      return <p>Client or Customer</p>;
    case PortfolioCompanyRelationshipType.partner:
      return <p>Partner</p>;
    case PortfolioCompanyRelationshipType.supplier:
      return <p>Supplier</p>;
    case PortfolioCompanyRelationshipType.employer:
      return <p>Employer</p>;
    case PortfolioCompanyRelationshipType.other:
      return <p>Other</p>;
    case PortfolioCompanyRelationshipType.investment:
      return <p>Investment</p>;
    default:
      return <></>;
  }
};
