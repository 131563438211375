import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const twoDigits = (n: number): string => {
  return String(n).padStart(2, '0');
};

const format = (date: Date): string => {
  const numbers = [date.getFullYear(), date.getUTCMonth() + 1, date.getUTCDate()];
  return [numbers[0], ...numbers.slice(1).map(twoDigits)].join('-');
};

const parse = (value: string): Date | null => {
  const parts = value.split('-');
  if (parts.length !== 3) {
    return null;
  }
  const numbers = parts.map(Number);
  if (numbers.some(isNaN)) {
    return null;
  }

  return new Date(numbers[0], numbers[1] - 1, numbers[2]);
};

export const useSearchParametersDate = (
  key: string,
): [Date | null, (data: Date | null) => void] => {
  const location = useLocation();

  const [path] = useState<string>(location.pathname);
  const [date, setDate] = useState<Date | null>(null);
  const [search, setSearch] = useSearchParams();

  useEffect((): void => {
    if (location.pathname !== path) {
      return;
    }

    const value = search.get(key);
    if (value === null) {
      setDate(null);
      return;
    }

    const date = parse(value);
    if (date !== null) {
      setDate(date);
    } else {
      search.delete(key);
      setSearch(search);

      setDate(null);
    }
  }, [key, location.pathname, path, search, setSearch]);

  const handleChange = useCallback(
    (value: Date | null): void => {
      if (value === null) {
        search.delete(key);
      } else {
        search.set(key, format(value));
      }
      setSearch(search);
    },
    [key, search, setSearch],
  );

  return [date, handleChange];
};
