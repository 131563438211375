import { format, parse, parseISO } from 'date-fns';

export const formatDate = (dateString: string): string => {
  try {
    const date = parseISO(dateString);
    return format(date, 'dd MMM yy');
  } catch {
    try {
      const date = parse(dateString, 'MM/dd/yyyy', new Date());
      return format(date, 'dd MMM yy');
    } catch {
      return dateString;
    }
  }
};
