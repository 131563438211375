import { TextArea } from 'components/forms/textarea';
import React from 'react';
import { PortfolioCompanyOtherItem } from 'types/portfolioItem';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyOtherItem;
}

export const OtherInvestmentItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCompanyOtherItem>();

  return (
    <>
      <TextArea
        rows={4}
        name="description"
        label="More details"
        value={item.description}
        onChange={updaterFor('description')}
      />
    </>
  );
};
