import { Table } from 'components/table';
import { Sorting, SortOrder } from 'components/table/sorting';
import { ColumnDefinition, Level } from 'components/table/types';
import React, { useCallback, useMemo, useState } from 'react';
import { KeyFactor, KeyFactorType } from 'redux/reducers/keyFactorsReducer';
import { AverageProfile } from 'views/DataAnalysis/common/KeyFactors/averageProfile';
import { createColumns } from 'views/DataAnalysis/common/KeyFactors/columns';
import DetailsModal from 'views/DataAnalysis/common/KeyFactors/detailsModal';

interface Props {
  readonly keyFactors: readonly KeyFactor[];
  readonly factorType: KeyFactorType;
  readonly average: Level;
  readonly sorting: Sorting<KeyFactor>;

  onSort(key: keyof KeyFactor, sortOrder: SortOrder): void;
}

export const KeyFactors: React.FC<Props> = ({
  factorType,
  average,
  keyFactors,
  sorting,
  onSort,
}: Props): React.ReactElement => {
  const [selectedKeyFactor, setSelectedKeyFactor] = useState<KeyFactor | null>(null);

  const columns = useMemo(
    (): ReadonlyArray<ColumnDefinition<KeyFactor>> => createColumns(factorType),
    [factorType],
  );

  const closeDetailsModal = useCallback((): void => {
    setSelectedKeyFactor(null);
  }, []);

  const handleRowClick = useCallback((keyFactor: KeyFactor): void => {
    setSelectedKeyFactor(keyFactor);
  }, []);

  return (
    <div className="relative flex-1 pt-2">
      <div className="absolute right-3 top-0">
        <AverageProfile level={average} factorType={factorType} />
      </div>

      <Table
        columns={columns}
        rows={keyFactors}
        sortBy={sorting}
        onSortChange={onSort}
        onRowClick={handleRowClick}
      />
      <DetailsModal data={selectedKeyFactor} factorType={factorType} onClose={closeDetailsModal} />
    </div>
  );
};
