import { UserAvatar } from 'components/userAvatar';
import React from 'react';
import { Link } from 'react-router-dom';
import { getFormatDate } from 'utils';
import { TitleRightTool } from 'views/Home/modals/PostDetailsModal/components/titleRightTool';
import { FeedCategory, FeedItem, postCategoryLabels } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;

  onSave(): void;
  onClose(): void;
}
export const Title: React.FC<Props> = ({
  feedItem: feedItem,
  onSave,
  onClose,
}: Props): React.ReactElement => {
  const date = getFormatDate(feedItem.date);

  return (
    <div className="flex items-start gap-3 justify-between my-2">
      <div className="flex flex-row items-start gap-3">
        {typeof feedItem.publisher === 'string' ? (
          <img
            className={imageClasses[feedItem.category]}
            src={feedItem.image}
            alt="publisher icon/avatar"
          />
        ) : (
          <div className="w-10 h-10 lg:mt-0 mt-1">
            <UserAvatar user={feedItem.publisher} size="medium" fillColor="gray-light" />
          </div>
        )}
        <div className="flex flex-col">
          <h1 className="text-gray-darkest text-xl leading-6">{feedItem.title}</h1>
          <div className="flex flex-row">
            <p className="text-gray text-sm">
              <span>{date} by </span>
              {typeof feedItem.publisher === 'string' ? (
                <span className="font-poppins">{feedItem.publisherName}</span>
              ) : (
                <Link
                  className="normal-case text-blue font-poppins"
                  to={`/users/${feedItem.publisher.id}`}
                >
                  {feedItem.publisherName}
                </Link>
              )}
              <span> &mdash; </span>
            </p>
            <p className="text-blue italic">{postCategoryLabels[feedItem.category]}</p>
          </div>
        </div>
      </div>

      <TitleRightTool feedItem={feedItem} onSave={onSave} onClose={onClose} />
    </div>
  );
};

const imageClasses: { [key in FeedCategory]: string } = {
  [FeedCategory.news]: 'w-10 h-10 lg:mt-0 mt-1 border rounded-sm border-gray-light',
  [FeedCategory.post]: 'w-10 h-10 lg:mt-0 mt-1',
  [FeedCategory.notification]: 'w-10 h-10 mt-1',
  [FeedCategory.none]: 'hidden',
};
