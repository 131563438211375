import { InputBase } from 'components/inputBase';
import React, { useCallback } from 'react';

interface OwnProps {
  readonly id?: string;
  readonly name: string;
  readonly label: string;
  readonly value: string;
  readonly alignment?: 'center' | 'left' | 'right';

  readonly className?: string;
  readonly rightDecorator?: React.ReactNode;
  readonly error?: string;
  readonly maxLength?: number;
  readonly placeholder?: string;
  readonly color?: 'default' | 'success' | 'error';
  readonly readOnly?: boolean;

  onChange?(value: string): void;
  onReturn?(): void;
  onTab?(): void;

  onFocus?(): void;
  onBlur?(): void;
}

type Props = React.PropsWithoutRef<OwnProps> & React.RefAttributes<HTMLInputElement>;

export const Input: React.ForwardRefExoticComponent<Props> = React.forwardRef(function Input(
  {
    id,
    name,
    label,
    value,
    className,
    rightDecorator,
    alignment = 'left',
    placeholder,
    readOnly = false,
    error,
    color,
    onTab,
    onReturn,
    onChange,
    onFocus,
    onBlur,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <InputBase
      id={id}
      ref={ref}
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      color={color}
      error={error}
      className={className}
      rightDecorator={rightDecorator}
      alignment={alignment}
      readOnly={readOnly}
      onChange={handleChange}
      onTab={onTab}
      onReturn={onReturn}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <input />
    </InputBase>
  );
});
