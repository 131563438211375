import { AccountType } from 'types/accountType';
import { FormField, SignUpFormState } from 'views/Landing/SignUp/types';

export const initialForm: SignUpFormState = {
  termsAndConditionsAccepted: false,
  communicationAccepted: false,
  accountType: AccountType.public,
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
};

export const fields: readonly FormField[] = [
  { label: 'First Name', placeholder: 'First Name', name: 'firstName', id: 'new-first-name' },
  { label: 'Last Name', placeholder: 'Last Name', name: 'lastName', id: 'new-last-name' },
  { label: 'Email', placeholder: 'Email Address', name: 'email', type: 'email', id: 'new-email' },
  {
    label: 'Password',
    type: 'new-password',
    placeholder: 'Set Password',
    name: 'password',
    id: 'new-password',
  },
  {
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    name: 'passwordConfirmation',
    type: 'password',
    id: 'confirm-password',
  },
];
