// import { Link } from 'react-router-dom';
import Button from 'components/buttons/Button/Button';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import columns from 'components/valueChain/columns';
import { useSort } from 'hooks/useSort';
import * as React from 'react';
import { useMemo } from 'react';
import { ValueChainTableRow } from 'types/organization/types';
import { Page } from 'types/page';

interface Props {
  title: string;
  page: Page<ValueChainTableRow>;
  linkPath: string;
  onLoadMore: () => void;
}

const ValueChainTable: React.FC<Props> = ({ title, page, onLoadMore }) => {
  const [sorting, handleSortChange] = useSort<ValueChainTableRow>(
    'marketcap',
    SortOrder.descending,
  );
  const { data } = page;

  const hasMoreRows = useMemo(
    (): boolean => data.length >= page.page_size && page.next_page !== null,
    [data.length, page],
  );

  return (
    <div className="my-16">
      <h4 className="font-poppins text-md p-2 text-gray-darkest border-b">
        {title === '' || title === null || title === undefined ? (
          <>
            <span className="font-poppinsSemiBold">{title}</span>: VALUE CHAIN TABLE
          </>
        ) : null}
      </h4>
      <Table columns={columns} rows={data} sortBy={sorting} onSortChange={handleSortChange} />
      {hasMoreRows && (
        <div className="flex justify-center w-full mt-4">
          <div className="shadow-lg rounded-full">
            <Button
              onClick={onLoadMore}
              width="w-44"
              bgHoverColor="hover:bg-blue"
              textColorHover="hover:text-white"
              rounded="rounded-full"
              height="h-12"
            >
              <p className="font-poppinsSemiBold">LOAD MORE</p>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ValueChainTable;
