import SpinnerLoader from 'components/spinnerLoader';
import React from 'react';

type type = 'submit' | 'reset' | 'button';
interface Props {
  id?: string;
  label?: string;
  width?: string;
  height?: string;
  rounded?: string;
  padding?: string;
  margin?: string;
  bgColor?: string;
  borderBgColor?: string;
  type?: type;
  textColor?: string;
  bgHoverColor?: string;
  textColorHover?: string;
  borderColorHover?: string;
  onClick?: (e: any) => void;
  className?: string;
  useClassNameOnly?: boolean;
  truncate?: boolean;
  disabled?: boolean;
  isSpin?: boolean;
  fontSize?: string;
  showSpinner?: boolean;
  spinnerClassName?: string;
  cursor?: string;
  opacity?: string;
  lettercase?: string;
}

/**
 * @deprecated Old and ugly implementation
 */
const Button: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  children,
  label,
  type = 'button',
  width = 'w-1/12',
  height = 'h-10',
  rounded = 'rounded-5',
  padding = 'p-2',
  margin = 'm-0',
  bgColor = 'bg-blue',
  fontSize = 'text-sm',
  borderBgColor = 'border-blue',
  textColor = 'text-white',
  bgHoverColor = 'hover:bg-white',
  textColorHover = 'hover:text-blue',
  borderColorHover = 'hover:border-blue',
  truncate = false,
  cursor = 'cursor-pointer',
  opacity = '',
  className = '',
  useClassNameOnly = false,
  onClick,
  disabled = false,
  isSpin = false,
  showSpinner = false,
  spinnerClassName = 'relative w-6',
  lettercase = 'uppercase',
}: React.PropsWithChildren<Props>) => {
  let buttonClass = `
    flex 
    justify-center 
    items-center
    transition-colors
    font-poppinsMedium
    focus:outline-none
    border
    ${lettercase}
    ${disabled ? 'cursor-default disabled:opacity-50' : ''}
    ${cursor}
    ${rounded}
    ${width}
    ${height}
    ${padding} 
    ${margin}
    ${opacity}
    ${textColor}
    ${fontSize}
    ${bgColor} 
    ${borderBgColor}
    ${!disabled ? bgHoverColor : ''} 
    ${!disabled ? textColorHover : ''}
    ${!disabled ? borderColorHover : ''}`;

  if (useClassNameOnly) {
    buttonClass = className;
  }
  return (
    <>
      {showSpinner && (
        <div className={spinnerClassName}>
          <SpinnerLoader visible={isSpin} innerCssClass="w-6 h-6" />
        </div>
      )}
      <button id={id} className={buttonClass} disabled={disabled} type={type} onClick={onClick}>
        <span className={`text-base ${truncate ? 'truncate' : ''}`}>{label || children}</span>
      </button>
    </>
  );
};

export default Button;
