import { HeaderMenuItemData, menu } from 'components/header/LandingPageHeader/menu';
import { HeaderMenuItem } from 'components/header/LandingPageHeader/menuItem';
import { MobileHeaderBase } from 'components/header/mobileHeaderBase';
import { MobileActionButton } from 'components/header/UserHeader/components/mobileActionButton';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  FeatureKey,
  landingPageSelector,
  setSelectedFeature,
} from 'redux/reducers/landingPageReducer';

export const MobileLoginHeader: React.FC = (): React.ReactElement => {
  const { featuresList } = useSelector(landingPageSelector);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const location = useLocation();
  const dispatch = useDispatch<any>();

  const toggleMenu = useCallback(
    (): void => setMenuOpen((menuOpen: boolean): boolean => !menuOpen),
    [],
  );

  const actionButtons = useMemo((): readonly React.ReactElement[] => {
    if (location.pathname === '/') {
      return [
        <MobileActionButton
          key="menu"
          icon={menuOpen ? 'close-popup-icon' : 'mobile-menu-icon'}
          onClick={toggleMenu}
        />,
      ];
    } else {
      return [];
    }
  }, [location.pathname, menuOpen, toggleMenu]);

  const createGoToCallback = useCallback(
    (id: string, feature?: FeatureKey): VoidFunction => {
      return (): void => {
        const element = document.getElementById(id);
        if (!element) {
          return;
        }

        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (feature) {
          dispatch(setSelectedFeature(feature));
        }

        setMenuOpen(false);
      };
    },
    [dispatch],
  );

  return (
    <MobileHeaderBase menuOpen={menuOpen} actionButtons={actionButtons}>
      <div className={menuClassName}>
        <div className="flex flex-col items-start space-y-8 w-full">
          <div>
            <h6 className="text-gray text-base font-bold mb-4">Features</h6>
            <div className="flex flex-col space-y-6 items-start ml-4">
              {featuresList.map(item => {
                return (
                  <button
                    key={item.id}
                    className="text-base font-poppins text-gray capitalize cursor-pointer"
                    onClick={createGoToCallback('features', item.id)}
                  >
                    {item.label}
                  </button>
                );
              })}
            </div>
          </div>
          {menu.map(
            (item: HeaderMenuItemData): React.ReactElement => (
              <HeaderMenuItem
                key={item.id}
                label={item.label}
                className="text-base font-poppinsMedium text-gray"
                selected={true}
                onClick={createGoToCallback(item.id)}
              />
            ),
          )}
        </div>
        <div className="absolute bottom-0 left-0 w-full flex items-center justify-evenly md:items-center">
          <Link className="block text-white bg-blue px-6 py-2 rounded-5" to="/login">
            Log In
          </Link>
          <Link className="block text-blue" to="/sign-up">
            Sign Up
          </Link>
        </div>
      </div>
    </MobileHeaderBase>
  );
};

const menuClassName =
  'relative bg-white flex flex-col h-full overflow-y-scroll landscape:overflow-y-scroll';
