import SpinnerLoader from 'components/spinnerLoader';
import { useCurrentEvent } from 'hooks/useCurrentEvent';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchingIndustryTimelineSelector,
  industryTimelineSelector,
} from 'redux/reducers/industryReducer';
import { fetchIndustryTimeline } from 'redux/services/industryServices';
import { defaultStreamFilters, TimelineFilters } from 'types/timeline';
import { EventManager } from 'views/SmartTools/TimelineAndEvents/components/eventManager';
import { StreamItem } from 'views/SmartTools/TimelineAndEvents/components/streamItem';
import { TimelineDateFilterBar } from 'views/SmartTools/TimelineAndEvents/toolbars/timelineDateFilterBar';

export const Timeline: React.FC = (): React.ReactElement => {
  const { industryId } = useParams<{ industryId: string }>();

  const [filters, setFilters] = useState<TimelineFilters>(defaultStreamFilters);
  const [currentEvent, _, loadingCurrentEvent] = useCurrentEvent([]);

  const dispatch = useDispatch<any>();
  const fetching = useSelector(fetchingIndustryTimelineSelector);
  const timeline = useSelector(industryTimelineSelector);

  useEffect((): VoidFunction => {
    return dispatch(fetchIndustryTimeline(industryId, filters));
  }, [dispatch, filters, industryId]);

  return (
    <div className="flex-1 relative">
      <div className="pt-5">
        <TimelineDateFilterBar value={filters} onChange={setFilters} />
      </div>

      <StreamItem readOnly={true} stream={timeline} compact={true} variant="regular" />
      <EventManager event={currentEvent} stream={timeline} loading={loadingCurrentEvent} />

      <SpinnerLoader visible={fetching} />
    </div>
  );
};
