import { NumericInput } from 'components/numericInput';
import { Select, SelectItem } from 'components/select';
import React from 'react';
import { useSelector } from 'react-redux';
import { countriesSelector, Country } from 'redux/reducers/sharedReducer';
import { PortfolioCashItem, TypeOfCash, typeOfCashLabels } from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCashItem;
}

export const CashItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const currencies = useCurrencies();
  const updaterFor = useUpdaterFor<PortfolioCashItem>();
  const rawCountries = useSelector(countriesSelector);

  const countries = React.useMemo((): ReadonlyArray<SelectItem<string>> => {
    return rawCountries.map(
      (country: Country): SelectItem<string> => ({
        value: country.iso2,
        label: country.name,
      }),
    );
  }, [rawCountries]);

  return (
    <div className="grid grid-cols-2 gap-x-3 gap-y-2">
      <Select
        id="typeOfCash"
        label="Type of Cash"
        items={typesOfCash}
        value={item.typeOfCash}
        onChange={updaterFor('typeOfCash')}
      />
      <Select
        id="currency"
        label="Currency of cash balance"
        items={currencies}
        value={item.currency}
        onChange={updaterFor('currency')}
      />
      <NumericInput
        name="size"
        value={item.size}
        label="Size of cash balance"
        onValueChange={updaterFor('size')}
      />
      <NumericInput
        name="annualInterestRateEarnedOnCash"
        value={item.annualInterestRateEarnedOnCash}
        label="Annual interest rate earned on cash"
        type="percent"
        onValueChange={updaterFor('annualInterestRateEarnedOnCash')}
      />
      <Select
        id="country"
        label="Location of cash account"
        items={countries}
        value={item.locationOfCashAccount}
        onChange={updaterFor('locationOfCashAccount')}
      />
    </div>
  );
};

const typesOfCash: ReadonlyArray<SelectItem<TypeOfCash>> = enumToSelectItems(
  TypeOfCash,
  typeOfCashLabels,
);
