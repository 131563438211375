import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Time } from 'types/time';
import { mustParseDate, parseDate, parseTime } from 'utils/dateTime';
import { serialize } from 'utils/serializer';

const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export interface StoredUpcomingEvent {
  readonly id: string;
  readonly summary: string;
  readonly startDate: string;
  readonly startTime: Time | null;
  readonly endDate: string | null;
  readonly endTime: Time | null;
  readonly membersCount: number | null;
}

export interface ParsedUpcomingEvent extends Omit<StoredUpcomingEvent, 'startDate' | 'endDate'> {
  readonly startDate: string;
  readonly endDate: string | null;
  readonly streamId: string;
}

export interface UpcomingEventsEntry {
  readonly day: string;
  readonly events: ParsedUpcomingEvent[];
}

export interface UpcomingEventsState {
  upcomingEvents: UpcomingEventsEntry[];
}

const initialState: UpcomingEventsState = {
  upcomingEvents: [],
};

const rawEventToUpcomingEvent = (raw: Record<string, any>): ParsedUpcomingEvent => ({
  id: raw.id,
  summary: raw.summary,
  startDate: serialize(mustParseDate(raw.start_date, raw.start_time, raw.timezone)),
  startTime: parseTime(raw.start_date, raw.start_time, raw.timezone),
  endDate: serialize(parseDate(raw.end_date, raw.end_time, raw.timezone)),
  endTime: parseTime(raw.start_date, raw.end_time, raw.timezone),
  membersCount: raw.members_count,
  streamId: raw.stream_id,
});

const transformedEvents = (upcomingEvents: StoredUpcomingEvent[]): UpcomingEventsEntry[] => {
  const transformedEvents = upcomingEvents
    .map(rawEventToUpcomingEvent)
    .reduce(
      (
        entries: Record<string, ParsedUpcomingEvent[]>,
        event: ParsedUpcomingEvent,
      ): Record<string, ParsedUpcomingEvent[]> => {
        const key = format(utcToZonedTime(event.startDate, LOCAL_TIMEZONE), 'yyyy-MM-dd');
        return { ...entries, [key]: [event, ...(entries[key] ?? [])] };
      },
      {},
    );

  return Object.entries(transformedEvents).map(
    ([day, events]: [string, ParsedUpcomingEvent[]]): UpcomingEventsEntry => ({
      day,
      events,
    }),
  );
};

const upcomingEventsSlice = createSlice({
  name: 'upcomingEvents',
  initialState: initialState,
  reducers: {
    updateUpcomingEvents: (
      state: UpcomingEventsState,
      action: PayloadAction<StoredUpcomingEvent[]>,
    ) => {
      state.upcomingEvents = transformedEvents(action.payload);
    },
  },
});

export const { updateUpcomingEvents } = upcomingEventsSlice.actions;

export const upcomingEventsSelector = (state: {
  upcomingEventsReducer: UpcomingEventsState;
}): UpcomingEventsEntry[] => state.upcomingEventsReducer.upcomingEvents;

export default upcomingEventsSlice.reducer;
