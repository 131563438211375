import { QueryStatus } from '@reduxjs/toolkit/query';
import { AmountInput } from 'components/amountInput';
import { Input } from 'components/forms/input';
import { TagsInput } from 'components/forms/tagsInput';
import { TextArea } from 'components/forms/textarea';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Modal } from 'components/modal';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { WithID } from 'types/withId';
import { useLazyGetMarketplaceItemQuery } from 'views/EarnAndBuy/api';
import {
  Action,
  ActionType,
  CreateMarketplaceItemPayload,
  initialState,
  reducer,
} from 'views/EarnAndBuy/Buy/modals/addListingReducer';
import { CategoriesDropdown } from 'views/EarnAndBuy/Earn/components/categoriesDropdown';

interface BaseProps {
  readonly open: boolean;
  readonly busy: boolean;

  onClose(): void;
}

interface CreateProps extends BaseProps {
  onSubmit(payload: CreateMarketplaceItemPayload): Promise<void>;
}

interface UpdateProps extends BaseProps {
  readonly listingId: string;
  onSubmit(payload: WithID<CreateMarketplaceItemPayload>): Promise<void>;
}

type Props = CreateProps | UpdateProps;

export const ListingFormModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { open, busy, onClose } = props;
  const [state, dispatch] = useReducer<Reducer<CreateMarketplaceItemPayload, Action>>(
    reducer,
    initialState,
  );
  const [fetchListing, fetchStatus] = useLazyGetMarketplaceItemQuery();

  useEffect((): void | VoidFunction => {
    if (!open) {
      return;
    }

    if (isUpdateProps(props)) {
      const { listingId } = props;

      fetchListing(listingId)
        .then((result: any /* I hate that they don't care about types */): void => {
          const { data } = result;

          if (data) {
            dispatch({ type: ActionType.initialize, data: data });
          }
        })
        .catch(console.warn);
    }

    return (): void => {
      dispatch({ type: ActionType.reset });
    };
  }, [fetchListing, open, props]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (isUpdateProps(props)) {
      const { onSubmit } = props;
      await onSubmit({ id: props.listingId, ...state });
    } else {
      const { onSubmit } = props;
      await onSubmit(state);
    }
  }, [props, state]);

  const setValue = useCallback(function build(
    name: keyof CreateMarketplaceItemPayload,
  ): (_: CreateMarketplaceItemPayload[keyof CreateMarketplaceItemPayload]) => void {
    return function (
      value: CreateMarketplaceItemPayload[keyof CreateMarketplaceItemPayload],
    ): void {
      dispatch({
        type: ActionType.setValue,
        data: {
          name: name,
          value: value,
        },
      });
    };
  },
  []);

  const modalTitle = useMemo((): string => {
    if (isUpdateProps(props)) {
      return 'Update listing';
    } else {
      return 'Add a new listing';
    }
  }, [props]);

  return (
    <Modal isOpen={open} closeOnClickOutside={false} onClose={onClose}>
      <Modal.Content title={modalTitle}>
        <form autoComplete="off" className="flex flex-col gap-4 w-modal-sm mt-3" onSubmit={ignore}>
          <div className="flex items-center gap-2">
            <div className="w-2/3">
              <Input name="title" label="Title" value={state.title} onChange={setValue('title')} />
            </div>
            <div className="w-1/3">
              <CategoriesDropdown value={state.category} onChange={setValue('category')} />
            </div>
          </div>
          <TextArea
            name="description"
            label="Description"
            value={state.description}
            onChange={setValue('description')}
          />

          <div className="flex items-center gap-2">
            <div className="w-full">
              {/* FIXME: show a https:// or something, or load a file */}
              <Input
                name="content_uri"
                label="Content URI"
                value={state.content_uri}
                placeholder="https://example.com"
                onChange={setValue('content_uri')}
              />
            </div>
          </div>
          <TagsInput value={state.tags} name="tags" onChange={setValue('tags')} />

          <AmountInput
            name="price_amount"
            label="Price"
            value={state.price_amount}
            currency={state.price_currency}
            onCurrencyChange={setValue('price_currency')}
            onValueChange={setValue('price_amount')}
          />

          <div className="flex items-center justify-end">
            <button
              className="bg-blue text-white h-12 rounded font-poppinsMedium text-md w-20"
              onClick={handleSubmit}
            >
              {isUpdateProps(props) ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </Modal.Content>

      <FullScreenLoadingSpinner visible={busy || fetchStatus.status === QueryStatus.pending} />
    </Modal>
  );
};

const ignore = (event: React.FormEvent<HTMLFormElement>): void => {
  event.preventDefault();
};

const isUpdateProps = (props: Props): props is UpdateProps => {
  return (props as UpdateProps).listingId !== undefined;
};
