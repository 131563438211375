import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import Toggle from 'components/toggle/Toggle';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyDetailsSelector } from 'redux/reducers/companyReducer';
import {
  financialStatementsOptionsSelector,
  setConvertToUSDFlag,
  setStatementDimension,
} from 'redux/reducers/financialStatementsReducer';
import { ByPeriodFinancialStatementSelector } from 'views/DataAnalysis/Company/Details/Financials/byPeriodFinancialStatementSelector';

const Financials: React.FC = () => {
  const dispatch = useDispatch();
  const company = useSelector(companyDetailsSelector);
  const options = useSelector(financialStatementsOptionsSelector);

  const reportsInUSD = useMemo((): boolean => company.currency === 'USD', [company.currency]);

  const displayUSDChecked = useMemo(
    (): boolean => reportsInUSD || !!options.convert_to_usd,
    [reportsInUSD, options.convert_to_usd],
  );

  const handleConvertedToUSDFlag = useCallback(
    (checked: boolean): void => {
      dispatch(setConvertToUSDFlag(checked));
    },
    [dispatch],
  );

  const handlePeriodSelectorChanged = useCallback(
    (value: 'MRY' | 'MRQ'): void => {
      dispatch(setStatementDimension(value));
    },
    [dispatch],
  );

  useEffect(() => {
    mixpanel.track('PageView Company Financials');
  }, []);

  return (
    <div className="relative flex-grow flex flex-col">
      <div className="flex items-start justify-between mb-2 mt-3">
        <Toggle
          label="Display in USD"
          checked={displayUSDChecked}
          disabled={reportsInUSD}
          onChange={handleConvertedToUSDFlag}
        />
        <DropdownFilter
          id="period-selector"
          options={periodOptions}
          selectedId={options.dimension}
          labelText="Period: "
          preventOverflow={false}
          onSelect={handlePeriodSelectorChanged}
        />
      </div>
      <ByPeriodFinancialStatementSelector />
    </div>
  );
};

export default Financials;

const periodOptions: Array<DropdownItem<'MRY' | 'MRQ'>> = [
  {
    id: 'MRY',
    label: 'Annual',
  },
  {
    id: 'MRQ',
    label: 'Quarterly',
  },
];
