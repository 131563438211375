import { AccessMode } from 'types/accessMode';

export interface BackendMember {
  readonly id: string;
  readonly fullname: string;
  readonly email: string;
  readonly access_mode: AccessMode;
}

export interface Member {
  value: string;
  label: string;
  accessMode: AccessMode;

  type: 'internal' | 'external';
}

export interface ExternalMember extends Member {
  type: 'external';
}

export interface InternalMember extends Member {
  type: 'internal';
}

export interface InternalMemberPayload {
  readonly id: string;
  readonly access_mode: AccessMode;
}

export interface ExternalMemberPayload {
  readonly email: string;
  readonly access_mode: AccessMode;
}

export type MemberPayload = InternalMemberPayload | ExternalMemberPayload;

export interface SharePayload {
  readonly members: InternalMemberPayload[];
  readonly external_members: ExternalMemberPayload[];
  readonly route: string;
}

export const toInternalMemberPayload = (internalMember: InternalMember): InternalMemberPayload => {
  return {
    access_mode: internalMember.accessMode,
    id: internalMember.value,
  };
};

export const toExternalMemberPayload = (externalMember: ExternalMember): ExternalMemberPayload => {
  return {
    access_mode: externalMember.accessMode,
    email: externalMember.value,
  };
};

export const isExternalMember = (member: Member): member is ExternalMember =>
  member.type === 'external';
export const isInternalMember = (member: Member): member is InternalMember =>
  member.type === 'internal';
