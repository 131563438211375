import { MenuGroup } from 'components/sidebar/components/MenuGroup';
import { groups } from 'components/sidebar/menu';
import { PoweredBy } from 'components/sidebar/poweredBy';
import { MenuGroupItem } from 'components/sidebar/types';
import React from 'react';

const Sidebar: React.FC = (): React.ReactElement => {
  return (
    <>
      {groups.map((group: MenuGroupItem): React.ReactElement => {
        return <MenuGroup key={group.id} group={group} />;
      })}
      <PoweredBy />
    </>
  );
};

export default Sidebar;
