import { NumericInput } from 'components/numericInput';
import { RadioButton } from 'components/radioButton';
import { Select } from 'components/select';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setFormValue } from 'redux/reducers/addPortfolioItemFormReducer';
import { MajorityOrMinority, PortfolioCompanyPartnerItem } from 'types/portfolioItem';
import { useCurrencies } from 'views/Portfolios/PortfoliosModal/hooks/useCurrencies';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCompanyPartnerItem;
}

export const PartnerItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const updaterFor = useUpdaterFor<PortfolioCompanyPartnerItem>();
  const currencies = useCurrencies();

  const handleSetMajority = React.useCallback((): void => {
    dispatch(setFormValue({ key: 'majorityOrMinority', value: MajorityOrMinority.majority }));
  }, [dispatch]);

  const handleSetMinority = React.useCallback((): void => {
    dispatch(setFormValue({ key: 'majorityOrMinority', value: MajorityOrMinority.minority }));
  }, [dispatch]);

  return (
    <>
      <div>
        <div className="mt-2 mb-4">
          <p className="font-poppinsMedium text-xs text-gray">Majority or minority partner?</p>
        </div>
        <div className="grid grid-cols-4 gap-x-8">
          <RadioButton
            id="majority"
            label="Majority"
            value={MajorityOrMinority.majority}
            checked={item.majorityOrMinority === MajorityOrMinority.majority}
            onChange={handleSetMajority}
          />
          <RadioButton
            id="minority"
            label="Minority"
            value={MajorityOrMinority.minority}
            checked={item.majorityOrMinority === MajorityOrMinority.minority}
            onChange={handleSetMinority}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-3">
        <Select
          id="currency"
          label="Primary currency of partnership"
          items={currencies}
          value={item.primaryCurrency}
          onChange={updaterFor('primaryCurrency')}
        />
        <NumericInput
          name="annualMonetaryIncomeFromPartnership"
          value={item.annualMonetaryIncomeFromPartner}
          label="Annual interest rate earned on cash"
          onValueChange={updaterFor('annualMonetaryIncomeFromPartner')}
        />
      </div>
    </>
  );
};
