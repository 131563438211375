import React from 'react';
import { TypeOfCash as TypeOfCashEnum } from 'types/portfolioItem';

interface Props {
  readonly typeOfCash: TypeOfCashEnum;
}

export const TypeOfCash: React.FC<Props> = ({ typeOfCash }: Props): React.ReactElement => {
  switch (typeOfCash) {
    case TypeOfCashEnum.currentAccount:
      return <p>Current Account</p>;
    case TypeOfCashEnum.savings:
      return <p>Savings</p>;
    case TypeOfCashEnum.investment:
      return <p>Investment</p>;
    case TypeOfCashEnum.other:
      return <p>Other</p>;
    default:
      return <></>;
  }
};
