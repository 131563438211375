export interface ComboBoxItem<T = string> {
  readonly id: T;
  readonly name: string;
}

export interface ActivableComboBoxItem<T = string> extends ComboBoxItem<T> {
  readonly active: boolean;
}

export const ACCREDITED_INVESTOR_URL =
  'https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor';
