import axios, { AxiosResponse } from 'axios';
import Autocomplete from 'components/autocomplete';
import { SearchSuggestion } from 'components/header/UserHeader/searchSuggestion';
import UserHeaderText from 'components/header/UserHeader/Text';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback, useEffect, useState } from 'react';
import { SearchResult } from 'redux/reducers/globalSearchReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusOk } from 'utils/statusCodes';

export const GlobalSearch: React.FC = (): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [suggestions, setSuggestions] = useState<readonly SearchResult[]>([]);
  const { trackEvent } = useGtag();

  useEffect((): VoidFunction | void => {
    const trimmedKeyword = searchKeyword.trim();
    if (trimmedKeyword.length < 3) {
      setSuggestions([]);
      return;
    }

    const tokenSource = axios.CancelToken.source();
    const timeout = setTimeout((): void => {
      setSuggestions([]);
      setLoading(true);
      api
        .get(`${API_V1_PATH}/search`, {
          cancelToken: tokenSource.token,
          params: { keyword: searchKeyword },
        })
        .then((response: AxiosResponse<readonly SearchResult[]>): void => {
          if (response.status === HttpStatusOk) {
            setSuggestions(response.data);
            trackEvent('search', {
              search_term: searchKeyword,
              type: 'global_search',
            });
          }
        })
        .finally((): void => {
          setLoading(false);
        });
    }, 200);

    return (): void => {
      clearTimeout(timeout);
      tokenSource.cancel();
    };
  }, [searchKeyword, trackEvent]);

  const handleClearSearch = useCallback((): void => {
    setSearchKeyword('');
    setSuggestions([]);
  }, []);

  return (
    <div className="flex-grow flex-shrink font-poppins text-gray max-w-search">
      <Autocomplete
        placeholder={UserHeaderText.autocomplete.placeholder}
        suggestions={suggestions}
        loading={loading}
        suggestionComponent={SearchSuggestion}
        notFoundTitle={UserHeaderText.notFound.title}
        notFoundText={UserHeaderText.notFound.text}
        onChange={setSearchKeyword}
        onClear={handleClearSearch}
      />
    </div>
  );
};
