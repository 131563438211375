import Button from 'components/buttons/Button/Button';
import MembersInput from 'components/membersInput/MembersInput';
import React, { useState } from 'react';
import {
  isExternalMember,
  isInternalMember,
  Member,
  SharePayload,
  toExternalMemberPayload,
  toInternalMemberPayload,
} from 'sharable/types';

interface Props {
  readonly title?: string;
  readonly text?: string;

  onShare(partialPayload: Omit<SharePayload, 'route'>): void;
}

const ShareModal: React.FC<Props> = ({ title = 'Share', text, onShare }: Props) => {
  const [members, setMembers] = useState<readonly Member[]>([]);

  const onShareWrapper = (): void => {
    onShare({
      members: members.filter(isInternalMember).map(toInternalMemberPayload),
      external_members: members.filter(isExternalMember).map(toExternalMemberPayload),
    });
  };

  return (
    <div className="flex flex-col justify-between w-full md:w-modal-sm">
      <div className="text-left flex-col">
        <h1 className="mb-7">{title}</h1>
        <span className="text-gray font-poppins">{text}</span>
        <MembersInput
          id="share-post-members-input"
          members={members}
          onChange={members => setMembers(members)}
          share
        />
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          id="share-button"
          onClick={onShareWrapper}
          disabled={members.length <= 0}
          rounded="rounded-sm"
          label="SHARE"
          width="w-28"
          height="h-full"
          padding="p-4"
        />
      </div>
    </div>
  );
};

export default ShareModal;
