import { ConfirmationBox } from 'components/confirmationBox';
import React, { useCallback } from 'react';
import { useRemoveItemMutation } from 'views/Portfolios/PortfoliosModal/api';

interface Props {
  readonly portfolioId: string;
  readonly itemId: string | null;

  onClose(): void;
}

export const DeleteItem: React.FC<Props> = ({
  itemId,
  portfolioId,
  onClose,
}: Props): React.ReactElement => {
  const [remove] = useRemoveItemMutation();

  const deleteCompany = useCallback(
    (itemId: string | null) => {
      if (itemId === null) {
        return;
      }

      remove({ portfolioId, itemId });
      onClose();
    },
    [onClose, portfolioId, remove],
  );

  const handleYes = useCallback((): void => deleteCompany(itemId), [itemId, deleteCompany]);

  if (itemId === null) {
    throw new Error('trying to delete a company, but the user had not selected one');
  }

  return (
    <ConfirmationBox
      message="Are you sure you want to delete this company from your portfolio?"
      danger={true}
      onYes={handleYes}
      onNo={onClose}
    />
  );
};
