import MembersIcon from 'assets/icons/svg/members-icon.svg';
import { format, parse } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { ParsedUpcomingEvent } from 'redux/reducers/upcomingEventsReducer';
import { TimespanView } from 'views/SmartTools/TimelineAndEvents/timespanView';

interface Props {
  day: string;
  events: ParsedUpcomingEvent[];
  itemId?: string;
}

const UpcomingEventItem: React.FC<Props> = ({ day, events, itemId }) => {
  const dayDate = parse(day, 'yyyy-MM-dd', 0);

  return (
    <div className="flex py-2 px-4 relative border-b border-gray-light">
      <div
        style={{ minWidth: 48 }}
        className="bg-gray-light rounded w-12 h-12 flex flex-col items-center justify-center "
      >
        <span className="text-gray font-poppinsSemiBold text-xl leading-none">
          {dayDate.getDate()}
        </span>
        <span className="text-gray text-xs">{format(dayDate, 'MMM')}</span>
      </div>
      <div className="pl-6 flex-grow">
        {events.map((event, index) => {
          return (
            // itemId prop passed on by FEED is 'feed-upcoming-event'.
            // For instance, the output id for the first element will be 'feed-upcoming-event-0'
            <Link key={event.id} to={`/timeline/${event.streamId}/${event.id}`}>
              <div className="py-1" id={itemId && `${itemId}-${index}`}>
                <p className="font-jostSemiBold text-lg normal-case leading-5 h-5 overflow-hidden text-gray-darkest">
                  {event.summary}
                </p>
                <div className="flex gap-2 mt-1 items-center">
                  <div className="flex">
                    <img alt="members" src={MembersIcon} className="w-3 mr-1" />
                    <p className="text-xs text-gray-darkest">{event.membersCount}</p>
                  </div>
                  <TimespanView startTime={event.startTime} endTime={event.endTime} />
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default UpcomingEventItem;
