import { ReactComponent as CheckIcon } from 'assets/icons/svg/check-icon-generic.svg';
import FallbackPhoto from 'assets/images/genderless-icon.svg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { User } from 'redux/reducers/authReducer';
import { Connection } from 'redux/reducers/connectionsReducer';
import { SocialUser } from 'views/Home/types';

interface Props {
  readonly user: User | SocialUser | Connection;
  readonly size?: 'small' | 'medium' | 'large';

  readonly fillColor?: 'white' | 'gray-light';

  onEdit?(): void;
}

export const UserAvatar: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<Props> & React.RefAttributes<HTMLDivElement>
> = React.forwardRef<HTMLDivElement, Props>(function UserAvatar(
  { user, fillColor = 'white', size = 'small', onEdit }: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const { photo_url = FallbackPhoto } = user;
  const [photoURL, setPhotoURL] = useState<string>(photo_url);

  const setFallbackPhoto = useCallback((): void => {
    setPhotoURL(FallbackPhoto);
  }, []);

  useEffect((): VoidFunction => {
    if (photo_url !== '') {
      setPhotoURL(photo_url);
    }

    return (): void => {
      setPhotoURL(FallbackPhoto);
    };
  }, [photo_url]);

  const checkmarkClassName = useMemo((): string => {
    switch (size) {
      case 'small':
        return [checkmarkClassNameBase, `bg-${fillColor}`, 'w-3.5', 'h-3.5'].join(' ');
      case 'medium':
        return [checkmarkClassNameBase, `bg-${fillColor}`, 'w-4', 'h-4'].join(' ');
      case 'large':
        return [checkmarkClassNameBase, `bg-${fillColor}`, 'w-8', 'h-8'].join(' ');
    }
  }, [fillColor, size]);

  const rootClassName = useMemo((): string => {
    switch (size) {
      case 'small':
        return [rootClassNameBase, 'w-9', 'h-9'].join(' ');
      case 'medium':
        return [rootClassNameBase, 'w-10', 'h-10'].join(' ');
      case 'large':
        return [rootClassNameBase, 'w-24', 'h-24'].join(' ');
    }
  }, [size]);

  return (
    <div ref={ref} className={rootClassName}>
      <img
        src={photoURL}
        className="w-full h-full rounded-full overflow-hidden shadow-sm"
        alt="user photo"
        onError={setFallbackPhoto}
      />
      {user.accredited_investor && (
        <div className={checkmarkClassName}>
          <CheckIcon className="fill-current w-2/3 h-2/3" />
        </div>
      )}
      {!!onEdit && (
        <div
          className="hover:bg-white cursor-pointer opacity-20 absolute inset-0 rounded-full"
          onClick={onEdit}
        />
      )}
    </div>
  );
});

const checkmarkClassNameBase =
  'absolute flex items-center justify-center right-0 bottom-0 rounded-full text-green-700 border border-transparent';

const rootClassNameBase = 'relative flex-shrink-0 flex-grow-0';
