import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { ContactForm } from 'components/contactUs/ContactUs';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  AuthProcessingStateData,
  failed,
  loginSucceeded,
  logoutCompleted,
  meSucceeded,
  Session,
  started,
  succeeded,
} from 'redux/reducers/authReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { API_V1_PATH, isAxiosError } from 'utils/config/axiosConfig';
import { SignUpFormState } from 'views/Landing/SignUp/types';

interface JWTToken {
  exp: number;
}

export const parseJWT = (token: string): JWTToken | null => {
  if (token.trim() === '') {
    return null;
  }

  const parts = token.split('.');
  if (parts.length !== 3) {
    return null;
  }

  return JSON.parse(atob(parts[1]));
};

export const logout =
  (): ((dispatch: Dispatch) => void) =>
  (dispatch: Dispatch): void => {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    dispatch(logoutCompleted());
  };

export const login = createAPIAction(function login(client: HttpClient): ActionsGenerator {
  return async function* (email: string, password: string): AsyncGenerator<AnyAction> {
    yield started();

    try {
      const response = await client.POST(`${API_V1_PATH}/auth/login`, {
        email,
        password,
      });
      const session: Session = response.data;
      const { token } = session;

      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);

      yield loginSucceeded(response.data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        yield failed(error.response?.status ?? AuthProcessingStateData.none);
      } else {
        yield failed(AuthProcessingStateData.unknownError);
      }
    }
  };
});

export const signUp = createAPIAction(function login(client: HttpClient): ActionsGenerator {
  return async function* (data: SignUpFormState): AsyncGenerator<AnyAction> {
    yield started();

    try {
      await client.POST(`${API_V1_PATH}/auth/register`, data);
      yield succeeded(AuthProcessingStateData.registrationSucceeded);
    } catch (error: any) {
      if (isAxiosError(error)) {
        yield failed(error.response?.status ?? AuthProcessingStateData.none);
      } else {
        yield failed(AuthProcessingStateData.unknownError);
      }
    }
  };
});

export const requestEmailVerification = createAPIAction(function requestEmailVerification(
  client: HttpClient,
): ActionsGenerator {
  return async function* (email: string): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.POST(`${API_V1_PATH}/auth/request-verify-email`, { email });
      yield succeeded(AuthProcessingStateData.requestEmailVerification);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.requestEmailVerificationError);
    }
  };
});

export const unlockAccount = createAPIAction(function unlockAccount(
  client: HttpClient,
): ActionsGenerator {
  return async function* (code: string): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.GET(`${API_V1_PATH}/auth/unlock-account`, { code });
      yield succeeded(AuthProcessingStateData.accountUnlocked);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.unlockAccountError);
    }
  };
});

export const verifyEmail = createAPIAction(function verifyEmail(
  client: HttpClient,
): ActionsGenerator {
  return async function* (code: string): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.GET(`${API_V1_PATH}/auth/verify-email`, { code });
      yield succeeded(AuthProcessingStateData.emailVerified);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.verifyEmailError);
    }
  };
});

export const requestPasswordReset = createAPIAction(function requestPasswordReset(
  client: HttpClient,
): ActionsGenerator {
  return async function* (email: string): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.POST(`${API_V1_PATH}/auth/request-reset-password`, { email });
      yield succeeded(AuthProcessingStateData.requestPasswordReset);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.requestPasswordResetError);
    }
  };
});

export const updatePassword = createAPIAction(function updatePassword(
  client: HttpClient,
): ActionsGenerator {
  return async function* (code: string, password: string): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.POST(`${API_V1_PATH}/auth/reset-password`, {
        password,
        code,
      });
      yield succeeded(AuthProcessingStateData.passwordUpdated);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.updatePasswordError);
    }
  };
});

// @deprecated: update URL
export const contactUs = createAPIAction(function contactUs(client: HttpClient): ActionsGenerator {
  return async function* (data: ContactForm): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.POST(`${API_V1_PATH}/auth/contact-us`, data);
      yield succeeded(AuthProcessingStateData.contactUsSucceeded);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.contactUsError);
    }
  };
});

export const requestUnlockAccount = createAPIAction(function requestUnlockAccount(
  client: HttpClient,
): ActionsGenerator {
  return async function* (email: string): AsyncGenerator<AnyAction> {
    yield started();
    try {
      await client.POST(`${API_V1_PATH}/auth/request-unlock-account`, { email });
      yield succeeded(AuthProcessingStateData.requestUnlockAccount);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.requestUnlockAccountError);
    }
  };
});

export const refresh = createAPIAction(function refresh(client: HttpClient): ActionsGenerator {
  return async function* (): AsyncGenerator<AnyAction> {
    yield started();

    try {
      const response = await client.GET(`${API_V1_PATH}/accounts/refresh`);
      yield loginSucceeded(response.data);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.refresh);
    }
  };
});

export const me = createAPIAction(function me(client: HttpClient): ActionsGenerator {
  return async function* (): AsyncGenerator<AnyAction> {
    yield started();

    try {
      const response = await client.GET(`${API_V1_PATH}/accounts/me`);
      yield meSucceeded(response.data);
    } catch (error: any) {
      yield failed(AuthProcessingStateData.me);
    }
  };
});
