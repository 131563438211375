import { ReactComponent as XIcon } from 'assets/icons/svg/x-icon.svg';
import { DesktopHeaderBase } from 'components/header/desktopHeaderBase';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/services/authServices';
import { goToMainPage } from 'utils/goToMainPage';

export const SimpleHeader: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  const onLogout = useCallback((): void => {
    dispatch(logout());
    goToMainPage();
  }, [dispatch]);

  return (
    <DesktopHeaderBase>
      <div
        className="flex items-center mx-2 cursor-pointer hover:bg-gray-light px-6 py-4"
        onClick={onLogout}
      >
        <XIcon className="w-6 h-6" stroke="crimson" />
        <span className="hidden md:block uppercase ml-4 font-poppins">Sign Out</span>
      </div>
    </DesktopHeaderBase>
  );
};
