import React from 'react';

interface Props {
  readonly text?: string;
}

export const Paragraph: React.FC<Props> = (props: Props): React.ReactElement => {
  const { text = '' } = props;
  const lines = React.useMemo((): readonly string[] => text.split(/\n|\r\n|\\n/), [text]);
  return (
    <div className="-my-4">
      {lines.map(
        (line: string): React.ReactElement => (
          <p key={line} className="my-4">
            {line}
          </p>
        ),
      )}
    </div>
  );
};
