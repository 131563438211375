import { Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { postReported } from 'redux/reducers/feedReducer';
import {
  getReportReasonsCompleted,
  getReportReasonsFailed,
  getReportReasonsStarted,
  ReportableType,
  submitReportCompleted,
  submitReportFailed,
  submitReportStarted,
} from 'redux/reducers/reportsReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { HttpStatusConflict, HttpStatusOk } from 'utils/statusCodes';
import { POST_ALREADY_REPORTED_ERROR, POST_REPORTED_SUCCESSFULLY } from 'views/Home/strings';

export const getReportReasons =
  (): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getReportReasonsStarted());
    try {
      const response: AxiosResponse = await api.get(`${API_V1_PATH}/reports/reasons`);
      if (response.status === HttpStatusOk) {
        dispatch(getReportReasonsCompleted(response.data));
      }
    } catch (err: any) {
      const { data } = err.response;

      dispatch(getReportReasonsFailed(data));
      dispatch(showInfoModal({ type: 'error', message: data }));
    }
  };

export const submitReport =
  (
    reportableId: string,
    reportableType: ReportableType,
    reasonId: number,
    explanation: string | null,
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(submitReportStarted());
    try {
      const response: AxiosResponse = await api.post(
        `${API_V1_PATH}/reports/${reportableType}/${reportableId}`,
        {
          reason_id: reasonId,
          ...(reasonId === 0 ? { explanation } : {}),
        },
      );

      if (response.status === HttpStatusOk) {
        dispatch(submitReportCompleted());
        dispatch(showInfoModal({ type: 'success', message: POST_REPORTED_SUCCESSFULLY }));

        // Pick the correct action now
        switch (reportableType) {
          case ReportableType.Posts:
          case ReportableType.News:
            dispatch(postReported(reportableId));
            break;
          case ReportableType.Comments:
            break;
        }
      }
    } catch (err: any) {
      const { status, data } = err.response;

      if (status === HttpStatusConflict) {
        dispatch(submitReportFailed(POST_ALREADY_REPORTED_ERROR));
        dispatch(showInfoModal({ type: 'error', message: POST_ALREADY_REPORTED_ERROR }));
      } else {
        dispatch(submitReportFailed(data));
        dispatch(showInfoModal({ type: 'error', message: data }));
      }
    }
  };
