import {
  BodyElement,
  MentionBodyElement,
  NodeType,
  PostBody,
} from 'components/postBodyInput/types';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  readonly body: PostBody;
}

export const CommentContentView: React.FC<Props> = ({ body }: Props): React.ReactElement => {
  const { elements } = body;

  return (
    <div className="flex-grow my-3">
      <p className="text-gray clamp-3 leading-5 ">
        {elements.map((item: BodyElement, index: number): React.ReactElement => {
          switch (item.type) {
            case NodeType.text:
              return <span key={index}>{item.content}</span>;
            case NodeType.tag:
              return (
                <span key={index} className="text-blue cursor-pointer">
                  #{item.content}
                </span>
              );
            case NodeType.mention:
              return (
                <Link key={index} className="text-blue font-poppins" to={getUserUrl(item)}>
                  {item.content}
                </Link>
              );
            case NodeType.link:
              return (
                <a key={index} href={item.url}>
                  {item.content}
                </a>
              );
          }
        })}
      </p>
    </div>
  );
};

const getUserUrl = (item: MentionBodyElement): string => {
  return `/users/${item.user_id}`;
};
