import { RECORD_SEPARATOR } from 'assets/constants/recordSeparator';
import { ReactComponent as Icon } from 'assets/icons/svg/filter-icon.svg';
import { Categories } from 'components/filters/components/CategoriesButtons';
import { Dropdown } from 'components/filters/components/Dropdown';
import { FilterOptionsDropdown } from 'components/filters/components/FilterOptionsDropdown';
import { CommonFilter, GenericFilter } from 'components/filters/helpers';
import { FilterValues } from 'hooks/useFilters';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface Props {
  readonly config: readonly CommonFilter[];

  onChange(values: readonly FilterValues[]): void;
}

// filters.ts is an uncontrolled component by design, it is just simpler to manage the state
// this way.
export const Filters: React.FC<Props> = ({ config, onChange }: Props): React.ReactElement => {
  const location = useLocation();
  const [search] = useSearchParams();

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const values = useMemo((): readonly FilterValues[] => {
    const stateCategories = Array.isArray(location.state) ? location.state : [];
    const searchCategories = Array.from(search.keys());
    const categories = Array.from(new Set([...stateCategories, ...searchCategories]));

    return categories
      .filter((name: string): boolean => name !== 'page_number' && name !== 'order_by')
      .reduce((filters: readonly FilterValues[], key: string): readonly FilterValues[] => {
        const values = search.get(key)?.split(RECORD_SEPARATOR) ?? [];

        return [...filters, { key: key, values: values.map(decodeURIComponent) }];
      }, []);
  }, [location.state, search]);

  const selectedCategories = useMemo(
    (): string[] => values.map((item: FilterValues): string => item.key),
    [values],
  );
  const categories = useMemo((): GenericFilter[] => Object.values(config), [config]);

  const showPopup = useCallback((): void => setIsDropdownOpen(true), []);
  const hidePopup = useCallback((): void => setIsDropdownOpen(false), []);

  const onFilterChange = useCallback(
    (filterValues: readonly FilterValues[]): void => {
      onChange(filterValues);
    },
    [onChange],
  );

  const onRemoveCategory = useCallback(
    (name: string): void => {
      onChange([
        ...values.filter((item: FilterValues): boolean => item.key !== name),
        { key: name, values: null },
      ]);
    },
    [onChange, values],
  );

  const onCategoriesChange = useCallback(
    (categories: string[]): void => {
      const filterValues = categories.map((key: string): FilterValues => {
        const found = values.find((item: FilterValues): boolean => item.key === key);

        return { key: key, values: found?.values ?? [] };
      });

      onChange(filterValues);
    },
    [onChange, values],
  );

  const categoriesItems = useMemo(
    (): GenericFilter[] =>
      selectedCategories
        .map((name: string): GenericFilter | undefined => {
          return config.find((filter: GenericFilter): boolean => filter.name === name);
        })
        .filter((filter: GenericFilter | undefined): filter is GenericFilter => {
          return filter !== undefined;
        }),
    [selectedCategories, config],
  );

  const onClearAll = useCallback((): void => {
    onChange([]);
  }, [onChange]);

  return (
    <div className="flex md:items-stretch gap-1 flex-col md:flex-row">
      <button ref={setDropdownAnchor} className={rootClasses.join(' ')} onClick={showPopup}>
        <Icon className="w-5 h-3" title="Filter" />
        <span className="text-sm font-poppinsMedium">Filters</span>
      </button>
      <Dropdown isOpen={isDropdownOpen} anchor={dropdownAnchor} onClose={hidePopup}>
        <FilterOptionsDropdown
          options={categories}
          selectedOptions={selectedCategories}
          onChange={onCategoriesChange}
          onClearAll={onClearAll}
        />
      </Dropdown>
      <Categories
        items={categoriesItems}
        values={values}
        onChange={onFilterChange}
        onRemove={onRemoveCategory}
      />
    </div>
  );
};

const rootClasses = [
  'bg-blue',
  'text-white',
  'rounded-5',
  'flex',
  'items-center',
  'justify-center',
  'font-poppinsSemiBold',
  'pl-4',
  'pr-6',
  'h-sm',
  'gap-3',
  'border',
  'border-blue',
];
