import { preRegisterMode } from 'utils';

export default {
  title: 'About Us',
  subtitle: 'We are on a mission to improve financial outcomes for all',
  paragraph: `Capnote is a financial analysis platform built by financial professionals to democratize access to high quality, 
    insightful information previously unavailable to most.  
    The platform extracts insights from data using proven financial theory, 
    algorithms and machine learning, saving our users time and improving decision-making.  
    Capnote is used by financial professionals and investors around the world to gain an edge and boost their financial outcomes.
    <br/>
    <br/>
    Capnote was created by Finvar Corp, a fintech company dedicated to developing technological products that enhance finance and investing. 
    Finvar was set-up by financial, investment and technology professionals with a focus on four key areas 
    – financial processes, access to capital, financial education and social collaboration.
    <br/>
    <br/>
    Read more at <a class="text-blue lowercase" target="_blank" href="https://www.finvar.com" >www.finvar.com</a>
    `,
  features: [
    'Broad data and news aggregation, tagging and filtering',
    'Track and analyze key trends and indicators',
    'Grow your network and collaborate with like-minded professionals',
    'Innovative visualizations that adjust to your analytical approach',
  ],
  signUpLink: preRegisterMode ? 'SIGN UP FOR EARLY ACCESS' : 'Sign-up for free to learn more',
};
