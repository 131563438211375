import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import { UserAvatar } from 'components/userAvatar';
import { useGtag } from 'hooks/useGtag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  followUserAction,
  searchUsersByKeywordAction,
  unfollowUserAction,
} from 'redux/actions/connectionsActions';
import {
  clearSearchResults,
  Connection,
  searchingUsersSelector,
  userSearchResultsSelector,
} from 'redux/reducers/connectionsReducer';
import { defaultOptions } from 'utils/popper';
import FollowButton, { FollowAction } from 'views/Connections/Overview/components/FollowButton';

interface Props {
  readonly container: HTMLElement | null;
}

export const SearchBox: React.FC<Props> = ({ container }: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const { trackEvent } = useGtag();

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [popup, setPopup] = useState<HTMLElement | null>(null);
  const popperOptions = useMemo(
    (): any => defaultOptions(container, { withSameWidth: true, offset: [0, 4] }),
    [container],
  );
  const [isSuggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);

  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  const searchResults = useSelector(userSearchResultsSelector);
  const searching = useSelector(searchingUsersSelector);

  const closeSuggestionsPopup = useCallback((): void => setSuggestionsOpen(false), []);
  const handleFollowButtonClick = useCallback(
    (followAction: FollowAction, user?: Connection): void => {
      if (!user) {
        // This should NEVER happen
        return;
      }

      if (followAction === FollowAction.unfollow) {
        dispatch(unfollowUserAction(user.id));
        trackEvent('unfollow-user', {
          section: 'search-box',
          ...user,
        });
      } else if (followAction === FollowAction.follow) {
        dispatch(followUserAction(user.id));
        trackEvent('follow-user', {
          section: 'search-box',
          ...user,
        });
      } else {
        throw new Error('this just should never happen');
      }
    },
    [dispatch, trackEvent],
  );

  const clearSearchKeyword = useCallback((): void => {
    setSearchKeyword(null);
  }, []);

  const cleanSearchResults = useCallback((): void => {
    setSuggestionsOpen(false);
    setSearchKeyword(null);
    dispatch(clearSearchResults());
  }, [dispatch]);

  const handleSearchKeywordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      if (value.trim() === '') {
        dispatch(clearSearchResults());
        setSearchKeyword(null);
      } else {
        setSearchKeyword(value);
      }
    },
    [dispatch],
  );

  useEffect((): void => {
    dispatch(clearSearchResults());
    setSearchKeyword(null);
  }, [dispatch]);

  useEffect((): void => {
    if (searchResults.length > 0) {
      setSuggestionsOpen(searchResults.length > 0);
      trackEvent('search-connections', {
        keyword: searchKeyword,
      });
    }
  }, [searchResults.length, trackEvent, searchKeyword]);

  useEffect((): VoidFunction | void => {
    if (searchKeyword === null) {
      return;
    }

    const timeout = setTimeout((): void => {
      dispatch(searchUsersByKeywordAction(searchKeyword));
    }, 400);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [dispatch, searchKeyword]);

  useEffect((): void => {
    update?.();
  }, [update]);

  return (
    <div ref={setAnchor} className="relative w-full">
      <SVGIcon name="dark-search-icon" className="absolute w-4 h-4 left-2.5 top-3 bottom-0" />
      <input
        className="leading-10 font-poppins pr-4 pl-10 border-b border-gray-medium text-sm outline-none focus:border-blue w-full"
        placeholder="Search users"
        value={searchKeyword ?? ''}
        readOnly={searching}
        onChange={handleSearchKeywordChange}
      />

      <div
        className="absolute w-10 flex items-center justify-center right-0 top-0 bottom-0 text-gray cursor-pointer hover:text-red-500"
        onClick={clearSearchKeyword}
      >
        <SpinnerLoader visible={searching} innerCssClass="w-5 h-5" />

        {searchKeyword !== null && searchKeyword.trim() !== '' ? (
          <SVGIcon
            name="close-popup-icon"
            onClick={cleanSearchResults}
            className="fill-current w-3 h-3"
          />
        ) : null}
      </div>

      {isSuggestionsOpen &&
        ReactDOM.createPortal(
          <div className="fixed left-0 top-0 h-screen w-screen" onClick={closeSuggestionsPopup}>
            <div ref={setPopup} style={styles.popper} {...attributes.popper}>
              <div className="bg-white shadow-md rounded">
                {searchResults.map(
                  (user: Connection): React.ReactElement => (
                    <Link
                      key={user.id}
                      className="flex items-center p-4 gap-3 hover:bg-blue-light first:rounded-t last:rounded-b normal-case"
                      to={`/users/${user.id}`}
                    >
                      <div>
                        <UserAvatar user={user} />
                      </div>
                      <div className="min-w-0">
                        <div className="text-gray-darkest font-jostSemiBold text-md truncate">
                          {user.fullname}
                        </div>
                        <div className="text-gray font-poppins text-xs truncate overflow-ellipsis">
                          {user.about_me}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <FollowButton
                          followed={user.followed_by_you}
                          compact={true}
                          clickData={user}
                          onClick={handleFollowButtonClick}
                        />
                      </div>
                    </Link>
                  ),
                )}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};
