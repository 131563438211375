import 'views/DataAnalysis/main.css';

import { AutoParagraph } from 'components/autoParagraph/AutoParagraph';
import { Clickable } from 'components/clickable';
import { Tag } from 'components/tag';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  readonly description?: string;
  readonly tags?: readonly string[];
  // FIXME: these properties are suspicious
  readonly mapDetails?: boolean;
  readonly mapListDetails?: string[];
}

const DetailsModal: React.FC<Props> = ({
  description,
  tags,
  mapDetails = false,
  mapListDetails,
}: Props): React.ReactElement => {
  const navigate = useNavigate();

  const handleTagClick = useCallback(
    (tag: string): void => {
      navigate(`/data-analysis/company/?tags=${encodeURIComponent(tag)}`);
    },
    [navigate],
  );

  return (
    <div className="text-gray-darkest max-h-screen pb-5 bg-gray-light font-poppins w-full md:w-modal-sm">
      {mapDetails ? (
        <div className="max-h-80 scroller overflow-auto">
          <ul className="list-none mb-6 flex flex-col space-y-1">
            {mapListDetails?.map((item: string, index: number) => (
              <li
                id={`map-list-detail-${index}`}
                key={item + index}
                className="font-poppins text-base truncate"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <AutoParagraph className="text-sm mb-4" text={description ?? 'No description provided'} />
          <div className="flex flex-wrap gap-2 pb-4">
            {tags?.map(
              (tag: string): React.ReactElement => (
                <Clickable key={tag} clickData={tag} onClick={handleTagClick}>
                  <Tag>{tag}</Tag>
                </Clickable>
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsModal;
