import { StripeError } from '@stripe/stripe-js';
import { NewPaymentMethod } from 'components/paymentForm/newPaymentMethod';
import { PaymentMethodsList } from 'components/paymentForm/paymentMethodsList';
import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import { Payment } from 'views/Checkout/SubscriptionCheckout/payment';

interface Props {
  readonly payment: Payment;
  readonly withTnc?: boolean;

  onPaymentStarted(): void;
  onPaymentCanceled(): void;
  onPaymentSucceeded(): void;
  onPaymentFailed(error: StripeError): void;
  onPaymentSoftError(): void;
}

export const PaymentForm: React.FC<Props> = ({
  payment,
  withTnc,
  onPaymentCanceled,
  onPaymentSucceeded,
  onPaymentFailed,
  onPaymentStarted,
  onPaymentSoftError,
}: Props): React.ReactElement => {
  const { payment_methods = [] } = useSelector(userSelector);

  if (payment_methods.length > 0) {
    return (
      <PaymentMethodsList
        payment={payment}
        withTnc={withTnc}
        paymentMethods={payment_methods}
        onPaymentStarted={onPaymentStarted}
        onPaymentCanceled={onPaymentCanceled}
        onPaymentSucceeded={onPaymentSucceeded}
        onPaymentFailed={onPaymentFailed}
        onPaymentSoftError={onPaymentSoftError}
      />
    );
  } else {
    return (
      <NewPaymentMethod
        payment={payment}
        withTnc={withTnc}
        onPaymentStarted={onPaymentStarted}
        onPaymentCanceled={onPaymentCanceled}
        onPaymentSucceeded={onPaymentSucceeded}
        onPaymentFailed={onPaymentFailed}
        onPaymentSoftError={onPaymentSoftError}
      />
    );
  }
};
