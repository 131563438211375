import Input from 'components/input/Input2';
import SpinnerLoader from 'components/spinnerLoader';
import { useGtag } from 'hooks/useGtag';
import React, { ChangeEvent, useRef, useState } from 'react';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

interface Company {
  id: string;
  name: string;
}

interface SuggestionsState {
  loading: boolean;
  companies: Company[];
}

interface Props {
  name: string;
  value: string;
  placeholder?: string;
  setCompany: (value: string) => void;
  setCompanyId?: (value: string) => void;
  showBorder?: boolean;
  labelClassName?: string;
  paddingLeft?: string;
  queryParams?: Company;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  searchIcon?: boolean;
  showLabel?: boolean;
  containerClassName?: string;
  error?: any;
  section: 'watchlist' | 'portfolio';
}

const CompaniesInput: React.FC<Props> = ({
  placeholder = '',
  setCompany,
  name,
  value,
  setCompanyId = undefined,
  onKeyDown,
  labelClassName,
  paddingLeft = '',
  containerClassName = 'w-64',
  queryParams = { name: '', id: '' },
  searchIcon = false,
  showLabel = true,
  error,
  section = 'watchlist',
}) => {
  const [suggestions, setSuggestions] = useState<SuggestionsState>({
    loading: false,
    companies: [],
  });
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const { trackEvent } = useGtag();

  const handleChangeValue = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCompany(value);
    if (timeout.current) clearTimeout(timeout.current);
    if (value) {
      timeout.current = setTimeout(async () => {
        try {
          setSuggestions({
            loading: true,
            companies: [],
          });
          const result = await api.get(`${API_V1_PATH}/companies/search/`, {
            params: { keyword: value, [queryParams.name]: queryParams.id },
          });
          trackEvent(`add-company-${section}`, {
            keyword: value,
          });
          setSuggestions({
            loading: false,
            companies: result.data,
          });
        } catch (error) {
          setSuggestions({
            loading: false,
            companies: [],
          });
        }
      }, 500);
    } else {
      setSuggestions({
        loading: false,
        companies: [],
      });
    }
  };

  return (
    <div className="w-full">
      <Input
        id="search-company-input"
        name={name}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={handleChangeValue}
        className=""
        autoComplete="off"
        inputClassName={`${paddingLeft} py-2 bg-transparent`}
        labelClassName={labelClassName}
        onKeyDown={onKeyDown}
        searchIcon={searchIcon}
        showLabel={showLabel}
        label="Company"
        error={error}
      />
      {suggestions.loading ||
      (Array.isArray(suggestions.companies) && suggestions.companies.length) ? (
        <div
          style={{ zIndex: 1200 }}
          className={`absolute ${containerClassName} bg-white rounded-sm shadow-md top-18 mt-1 max-h-60 overflow-auto scroller py-1`}
        >
          {Array.isArray(suggestions.companies) &&
            suggestions.companies.map((company, index) => (
              <button
                id={`company-suggestion-${index}`}
                key={company.id}
                onClick={() => {
                  setCompany(company.name);
                  setSuggestions({ loading: false, companies: [] });
                  if (setCompanyId) {
                    setCompanyId(company.id);
                  }
                }}
                className="py-2 px-4 focus:outline-none hover:bg-gray-light rounded-sm font-poppins text-gray w-full text-left truncate"
              >
                {company.name}
              </button>
            ))}
          {suggestions.loading && (
            <>
              <div className="h-28" />
              <SpinnerLoader visible={true} />
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CompaniesInput;
