import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { IndustryStats } from 'redux/reducers/industryReducer';

const columns: Array<ColumnDefinition<IndustryStats>> = [
  {
    name: 'name',
    type: ColumnType.text,
    sortable: true,
    label: 'Industry Name',
    alignment: Alignment.left,
  },
  {
    name: 'total_number_of_companies',
    type: ColumnType.numeric,
    sortable: true,
    label: 'Count of My Companies\nIn Industry',
    alignment: Alignment.center,
    precision: 0,
  },
  {
    name: 'percentage_of_total_number_of_companies',
    type: ColumnType.percent,
    sortable: true,
    label: 'Percentage of My Companies\nIn Industry',
    alignment: Alignment.center,
    precision: 2,
  },
  {
    name: 'sectors',
    type: ColumnType.custom,
    label: '',
    weight: 2,
    render: (): React.ReactNode => {
      return null;
    },
    headerAlignment: Alignment.left,
  },
];

export default columns;
