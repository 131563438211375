import { BodyElement, MentionBodyElement, NodeType } from 'components/postBodyInput/types';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;
  readonly clamp: boolean;

  onTagClick(tag: string): void;
}

export const PostBodyView: React.FC<Props> = ({
  feedItem,
  clamp,
  onTagClick,
}: Props): React.ReactElement => {
  const body = feedItem.body;
  const { elements = [] } = body;

  const tagClicker = useCallback(
    (tag: string): VoidFunction => {
      return (): void => {
        onTagClick(tag);
      };
    },
    [onTagClick],
  );

  const bodyClassName = useMemo((): string => {
    if (!clamp) {
      return 'text-gray leading-5 whitespace-pre-wrap';
    }

    return 'text-gray clamp-3 leading-5 whitespace-pre-wrap';
  }, [clamp]);

  return (
    <div className="flex-grow my-3">
      <p className={bodyClassName}>
        {elements.map((item: BodyElement, index: number): React.ReactElement => {
          switch (item.type) {
            case NodeType.text:
              return <span key={index}>{item.content}</span>;
            case NodeType.tag:
              return (
                <span
                  key={index}
                  className="text-blue cursor-pointer"
                  onClick={tagClicker(item.content)}
                >
                  #{item.content}
                </span>
              );
            case NodeType.mention:
              return (
                <Link key={index} className="text-blue font-poppins" to={getUserUrl(item)}>
                  {item.content}
                </Link>
              );
            case NodeType.link:
              return (
                <a key={index} href={item.url}>
                  {item.content}
                </a>
              );
          }
        })}
      </p>
    </div>
  );
};

const getUserUrl = (item: MentionBodyElement): string => {
  return `/users/${item.user_id}`;
};
