import { PostBody } from 'components/postBodyInput/types';

export enum CommentCardEditMode {
  none,
  replying,
  editing,
}

export enum CommentCardModal {
  none,
  share,
  report,
  delete,
}

export interface CommentCardState {
  readonly reported: boolean;
  readonly editMode: CommentCardEditMode;
  readonly inputInitialValue: PostBody;
  readonly editable: boolean;
  readonly repliesVisible: boolean;
  readonly modal: CommentCardModal;
}

export const initialState: CommentCardState = {
  reported: false,
  editMode: CommentCardEditMode.none,
  inputInitialValue: PostBody.empty(),
  editable: false,
  repliesVisible: false,
  modal: CommentCardModal.none,
};
