import { ConditionalRender } from 'components/conditionalRenderer';
import React, { useCallback } from 'react';

interface Props {
  readonly value: string;
  readonly id: string;
  readonly disabled: boolean;
  readonly label?: string;
  readonly limit: number;

  onChange(value: string): void;
}

export const Textarea: React.ForwardRefExoticComponent<
  Props & React.RefAttributes<HTMLTextAreaElement>
> = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    { id, label, value, disabled, limit, onChange }: Props,
    ref: React.Ref<HTMLTextAreaElement>,
  ): React.ReactElement => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const { value } = event.target;
        onChange(value.slice(0, limit));
      },
      [limit, onChange],
    );

    return (
      <div className={disabled ? disabledClass : enabledClass}>
        <h5 className="normal-case text-gray mb-1">{label}</h5>
        <textarea
          id={id}
          ref={ref}
          className={disabled ? disabledInputClass : enabledInputClass}
          rows={4}
          name="explanation"
          value={value}
          onChange={handleChange}
        />
        <ConditionalRender renderIf={!disabled}>
          <div className="absolute text-xs text-gray-medium right-3 bottom-4">
            {limit - value.length} characters left
          </div>
        </ConditionalRender>
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

const enabledClass = 'relative font-poppins';
const disabledClass = 'relative opacity-30 pointer-events-none font-poppins';
const enabledInputClass =
  'border border-gray-light rounded-md p-3 w-full bg-transparent resize-none outline-none focus:border-blue';
const disabledInputClass =
  'border border-gray-light rounded-md p-3 w-full bg-transparent resize-none outline-none bg-gray-light';
