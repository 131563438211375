import { Modal } from 'components/modal';
import { Truncate } from 'components/truncate';
import { TruncateType } from 'components/truncate/baseProps';
import { DefaultTextEllipsis } from 'components/truncate/defaultTextEllipsis';
import React, { useState } from 'react';
import {
  isLinkerObject,
  LinkerObject,
  NavLink,
} from 'views/DataAnalysis/common/SummaryTable/navLink';

interface Props {
  readonly value: readonly string[];
  readonly name: string;
}

export const ArrayRenderer: React.FC<Props> = ({ value, name }: Props): React.ReactElement => {
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState<boolean>(false);

  const closeDetailsModal = React.useCallback((): void => {
    setDetailsModalIsOpen(false);
  }, []);

  const renderItem = React.useCallback((value: any): React.ReactNode => {
    if (isLinkerObject(value)) {
      return <NavLink link={value} />;
    } else if (typeof value === 'string') {
      return <span>{value}</span>;
    }

    return '?';
  }, []);

  const renderDetailsItem = React.useCallback((item: string | LinkerObject): React.ReactElement => {
    if (isLinkerObject(item)) {
      return <NavLink key={item.id} link={item} />;
    } else {
      return <p key={item}>{item}</p>;
    }
  }, []);

  const getKey = React.useCallback((value: any): string => {
    if (isLinkerObject(value)) {
      return value.id;
    } else if (typeof value === 'string') {
      return value;
    } else {
      // FIXME: this should actually be checked
      return '';
    }
  }, []);

  const showMore = React.useCallback((): void => {
    setDetailsModalIsOpen(true);
  }, []);

  return (
    <>
      <Truncate
        contentType={TruncateType.elements}
        ellipsis={<DefaultTextEllipsis onClick={showMore} />}
        lines={1}
        spacing={0}
      >
        {renderItem(value[0])}
        {value.slice(1).map((item: any): React.ReactElement => {
          const key = getKey(item);

          return (
            <div key={key}>
              {', '}
              {renderItem(item)}
            </div>
          );
        })}
      </Truncate>

      <Modal isOpen={detailsModalIsOpen} onClose={closeDetailsModal}>
        <Modal.Content title={name}>
          <div className="flex flex-wrap items-center md:w-modal-xs w-full text-base">
            {value?.slice(0, -1).map(
              (item: string | LinkerObject): React.ReactElement => (
                <div key={typeof item === 'string' ? item : item.id} className="flex items-center">
                  <span>{renderDetailsItem(item)}</span>
                  <span className="mr-1">,</span>
                </div>
              ),
            )}
            {value.length > 0 && renderDetailsItem(value[value.length - 1])}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
