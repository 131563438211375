import React from 'react';
import { isMobile } from 'react-device-detect';

export const PoweredBy: React.FC = (): React.ReactElement | null => {
  if (!process.env.REACT_APP_DATA_PORTAL) {
    return null;
  }

  return (
    <div className={className}>
      <p className="font-jostSemiBold text-xxs">Powered By</p>
      <img src="logo.svg" alt="powered by capnote" />
    </div>
  );
};

const className = ((): string => {
  if (isMobile) {
    return 'fixed bottom-4 right-5 pointer-events-none';
  } else {
    return 'absolute bottom-2 right-2 pointer-events-none';
  }
})();
