import { Clickable } from 'components/clickable';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback, useEffect, useState } from 'react';
import styles from 'views/Landing/tems-and-conditions-and-privacy-policy.module.scss';

interface Props {
  readonly content: Content;
}

export const LegalDocument: React.FC<Props> = ({ content }: Props): React.ReactElement => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const handleSectionClick = useCallback((sectionId: string) => {
    setActiveSection(sectionId);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className="py-20 text-center text-gray-darkest w-full">
        <h1 className="font-jostSemiBold text-2xl">{content.title}</h1>
        <div className="flex pt-16 justify-center w-full">
          <div className="w-11/12 md:w-7/12">
            <div className="text-center md:text-left font-poppins text-base">{content.header}</div>

            {content.tabs.map(item => {
              return (
                <div key={item.label}>
                  <Clickable clickData={item.id} onClick={handleSectionClick}>
                    <div className="grid grid-cols-4 bg-gray-light rounded-sm mt-8 p-3 cursor-pointer">
                      <div className="col-span-3 text-base text-left font-jostSemiBold pl-3">
                        {item.label}
                      </div>
                      <div className="flex justify-end items-center pr-3">
                        <SVGIcon
                          name="down-arrow"
                          className={`cursor-pointer duration-500 w-4 h-4 ${
                            activeSection === item.id ? 'transform rotate-180' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </Clickable>
                  {activeSection === item.id && (
                    <div className="mt-4 px-4">
                      <div className="text-left font-poppins text-base">{item.description}</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
