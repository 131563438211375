import DatePicker from 'components/datePicker/DatePicker';
import { HorizontalExpander } from 'components/horizontalExpander';
import React, { useCallback, useMemo } from 'react';
import {
  isDayStreamFilters,
  isMonthStreamFilters,
  isWeekStreamFilters,
  isYearStreamFilters,
  TimelineFilters,
  TimelineLayoutType,
} from 'types/timeline';
import { TimelineFilterButton } from 'views/SmartTools/TimelineAndEvents/components/timelineFilterButton';
import { TimelineFilterButtonSet } from 'views/SmartTools/TimelineAndEvents/components/timelineFilterButtonSet';
import { TodayButton } from 'views/SmartTools/TimelineAndEvents/components/todayButton';

interface Props {
  readonly value: TimelineFilters;

  onChange(filters: TimelineFilters): void;
}

export const TimelineDateFilterBar: React.FC<Props> = ({
  value,
  onChange,
}: Props): React.ReactElement => {
  const callOnChange = useCallback(
    (date: Date, type: TimelineLayoutType): void => {
      const year = date.getFullYear();

      switch (type) {
        case TimelineLayoutType.year:
          onChange({ year: year });
          break;
        case TimelineLayoutType.month:
          onChange({ year: year, month: date.getMonth() + 1 });
          break;
        case TimelineLayoutType.week:
          onChange({ year: year, month: date.getMonth() + 1, week_init_day: date.getDate() });
          break;
        case TimelineLayoutType.day:
          onChange({ year: year, month: date.getMonth() + 1, day: date.getDate() });
          break;
      }
    },
    [onChange],
  );

  const setToday = useCallback((): void => {
    callOnChange(new Date(), TimelineLayoutType.day);
  }, [callOnChange]);

  const filterType = useMemo((): TimelineLayoutType => {
    if (isYearStreamFilters(value)) {
      return TimelineLayoutType.year;
    } else if (isMonthStreamFilters(value)) {
      return TimelineLayoutType.month;
    } else if (isWeekStreamFilters(value)) {
      return TimelineLayoutType.week;
    } else if (isDayStreamFilters(value)) {
      return TimelineLayoutType.day;
    } else {
      throw new Error('invalid filter type detected');
    }
  }, [value]);

  const date = useMemo((): Date => {
    if (isMonthStreamFilters(value)) {
      return new Date(value.year, value.month - 1, today.getDate());
    } else if (isWeekStreamFilters(value)) {
      return new Date(value.year, value.month - 1, value.week_init_day);
    } else if (isDayStreamFilters(value)) {
      return new Date(value.year, value.month - 1, value.day);
    } else if (isYearStreamFilters(value)) {
      return new Date(value.year, today.getMonth(), today.getDate());
    } else {
      throw new Error('invalid filter type detected');
    }
  }, [value]);

  const handleDateChange = useCallback(
    (date: Date): void => {
      callOnChange(date, filterType);
    },
    [filterType, callOnChange],
  );

  const handleTypeChange = useCallback(
    (type: TimelineLayoutType): void => {
      callOnChange(date, type);
    },
    [date, callOnChange],
  );

  // FIXME: this looks wrong
  const yearRange = useMemo(
    (): any => ({ start: today.getFullYear() - 10, end: today.getFullYear() }),
    [],
  );

  return (
    <div className="flex items-center gap-4">
      <DatePicker
        value={date}
        initialValue={today}
        yearRangeValue={yearRange}
        type={filterType}
        onChange={handleDateChange}
      />
      <HorizontalExpander />

      <TodayButton onClick={setToday} />

      <div className="w-80">
        <TimelineFilterButtonSet value={filterType} onChange={handleTypeChange}>
          <TimelineFilterButton value={TimelineLayoutType.year} label="Year" />
          <TimelineFilterButton value={TimelineLayoutType.month} label="Month" />
          <TimelineFilterButton value={TimelineLayoutType.week} label="Week" />
          <TimelineFilterButton value={TimelineLayoutType.day} label="Day" />
        </TimelineFilterButtonSet>
      </div>
    </div>
  );
};

const today = new Date();
