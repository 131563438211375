import React from 'react';

export const GraphChain: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren): React.ReactElement | null => {
  const array = React.Children.toArray(children);
  if (array.length === 0) {
    return null;
  }

  const first = array[0];
  const middle = array.slice(1, array.length - 1);
  const last = array[array.length - 1];

  return (
    <div className="relative flex items-center">
      <div key="node-first" className="-mr-1">
        {first}
      </div>
      {middle.map((child: React.ReactNode, index: number): React.ReactNode => {
        return (
          <React.Fragment key={`chain-${index}`}>
            <div key={`line-${index}`} className="h-1 w-9 bg-blue" />
            <div key={`node-${index}`} className="-mx-3">
              {child}
            </div>
          </React.Fragment>
        );
      })}
      {last && last !== first && (
        <>
          <div key="line-last" className="h-1 w-9 bg-blue" />
          <div key="node-last" className="-mx-36">
            {last}
          </div>
        </>
      )}
    </div>
  );
};
