import DropdownFilter, { DropdownItem } from 'components/dropdownFilter/DropdownFilter';
import React from 'react';
import { FeedFilterCategory } from 'views/Home/types';

interface Props {
  readonly category: FeedFilterCategory;
  onChange(category: FeedFilterCategory): void;
}

export const FilterDropdown: React.FC<Props> = ({
  category,
  onChange,
}: Props): React.ReactElement => {
  return (
    <div className="lg:w-48 md:w-48 w-full md:mb-0 mb-4">
      <DropdownFilter
        id="feed-dropdown-filter"
        onSelect={onChange}
        options={categories}
        selectedId={category}
        labelText="Filter: "
        fullWidth={true}
      />
    </div>
  );
};

const categories: DropdownItem[] = [
  {
    id: FeedFilterCategory.none,
    label: 'All',
  },
  {
    id: FeedFilterCategory.news,
    label: 'News & Events',
  },
  {
    id: FeedFilterCategory.post,
    label: 'User Posts',
  },
  {
    id: FeedFilterCategory.bookmarks,
    label: 'Bookmarks',
  },
  {
    id: FeedFilterCategory.myInterests,
    label: 'My Interests',
  },
];
