import { QueryStatus } from '@reduxjs/toolkit/query';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import { defaultLocale } from 'constants/defaultLocale';
import { noop } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/authReducer';
import {
  ListingStatus,
  useGetEarnItemQuery,
  useUpdateListingStatusMutation,
} from 'views/EarnAndBuy/api';
import { Status } from 'views/EarnAndBuy/common/status';
import { ApplyButton } from 'views/EarnAndBuy/Earn/components/applyButton';
import { Paragraph } from 'views/EarnAndBuy/Earn/components/paragraph';
import { TagsList } from 'views/Home/components/shared/tagsList';

interface Props {
  readonly listingId?: string;
}

export const Details: React.FC<Props> = (props: Props): React.ReactElement => {
  const { listingId } = props;
  const { data: listing, isFetching } = useGetEarnItemQuery(listingId);
  const [updateStatus, mutation] = useUpdateListingStatusMutation();
  const user = useSelector(userSelector);
  const onClose = useContext<VoidFunction>(Modal.Context);

  if (!listingId) {
    throw new Error('job id cannot be null');
  }

  const amountText = React.useMemo((): string => {
    if (!listing) {
      return '';
    }

    const { payment_amount, payment_currency } = listing;
    const formatter = new Intl.NumberFormat(defaultLocale, {
      style: 'currency',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency: payment_currency,
    });

    return `${formatter.format(payment_amount)}`;
  }, [listing]);

  const status = React.useMemo((): ListingStatus | undefined => {
    if (listing?.applied) {
      return ListingStatus.applied;
    }

    return listing?.status ?? undefined;
  }, [listing?.applied, listing?.status]);

  const [currentStatus, setCurrentStatus] = useState<ListingStatus>(status ?? ListingStatus.open);

  const editable = useMemo(
    (): boolean => user.id === listing?.listing_owner?.id,
    [listing?.listing_owner?.id, user.id],
  );

  const handleSave = useCallback((): void => {
    if (!listingId) {
      console.warn('listing id is null or undefined');
      return;
    }

    updateStatus({
      id: listingId,
      status: currentStatus,
    });
  }, [currentStatus, listingId, updateStatus]);

  const spinning = useMemo(
    (): boolean => isFetching || mutation.status === QueryStatus.pending,
    [isFetching, mutation.status],
  );

  const saveCallback = useMemo(
    (): VoidFunction | undefined => (currentStatus === status ? undefined : handleSave),
    [currentStatus, handleSave, status],
  );

  useEffect((): void => {
    if (mutation.status === QueryStatus.fulfilled) {
      onClose();
    }
  }, [mutation.status, onClose]);

  return (
    <Modal.Content title={listing?.title} closeable={true}>
      <div className="px-1 mt-8">
        <div className="relative w-modal-sm grid grid-cols-2 gap-4">
          <h6 className="font-poppinsMedium text-gray-dark">Description</h6>
          <p>{listing?.description}</p>
          <h6 className="font-poppinsMedium text-gray-dark">Tags</h6>
          <TagsList tags={listing?.tags ?? []} expanded={false} onExpand={noop} />
          <h6 className="font-poppinsMedium text-gray-dark">Completion Instructions</h6>
          <Paragraph text={listing?.completion_instructions} />
          <h6 className="font-poppinsMedium text-gray-dark">How to apply</h6>
          <Paragraph text={listing?.how_to_apply} />
          <h6 className="font-poppinsMedium text-gray-dark">Completion Payout Amount</h6>
          <p>{amountText}</p>
          <h6 className="font-poppinsMedium text-gray-dark leading-7">Status</h6>
          <div>
            <Status status={currentStatus} editable={editable} onChange={setCurrentStatus} />
          </div>
        </div>
        <div className="flex items-center justify-end gap-4 mt-6">
          {editable ? (
            <Button type="button" variant="primary" label="Save" onClick={saveCallback} />
          ) : (
            <ApplyButton listing={listing} />
          )}
        </div>
        <FullScreenLoadingSpinner visible={spinning} />
      </div>
    </Modal.Content>
  );
};
