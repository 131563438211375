import axios, { AxiosResponse } from 'axios';
import React from 'react';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

interface ReferrerCodeHandler {
  readonly code: string | null;
  readonly busy: boolean;
}

export const useReferrerCode = (): ReferrerCodeHandler => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [code, setCode] = React.useState<string | null>(null);

  React.useEffect((): VoidFunction | void => {
    if (process.env.REACT_APP_DATA_PORTAL) {
      return;
    }

    const tokenSource = axios.CancelToken.source();
    setBusy(true);

    api
      .get(`${API_V1_PATH}/referrals/code`, {
        cancelToken: tokenSource.token,
      })
      .then((response: AxiosResponse) => {
        const { code } = response.data;
        setCode(code);
      })
      .catch(console.warn)
      .finally((): void => {
        setBusy(false);
      });

    return (): void => {
      tokenSource.cancel();
    };
  }, []);

  return {
    code: code,
    busy: busy,
  };
};
