import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';
import { ApplicationFeature, FeatureType } from 'redux/reducers/subscriptionPlansReducer';

interface Props {
  readonly features: readonly ApplicationFeature[];
}

export const PaymentViewPlanFeatureSummary: React.FC<Props> = ({
  features,
}: Props): React.ReactElement => {
  return (
    <>
      {features.map((feature: ApplicationFeature): React.ReactElement => {
        const text = ((): string => {
          switch (feature.type) {
            case FeatureType.moduleAccess:
              return feature.forbidden ? 'No' : 'Yes';
            case FeatureType.usageLimit:
              if (!isFinite(feature.usage_limit)) {
                return 'Unlimited';
              }

              return String(feature.usage_limit);
            default:
              return '';
          }
        })();

        return (
          <div key={feature.id} className="flex items-center mb-2">
            <SVGIcon name="shared-icons-check" className="w-6 h-6" />
            <div className="grid grid-cols-5 w-full">
              <h6 className="text-white col-span-4 text-smml-2">{feature.name}</h6>
              <h6 className="text-white text-sm	ml-2 font-poppinsMedium">{text}</h6>
            </div>
          </div>
        );
      })}
    </>
  );
};
