import { AnyAction, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import { format } from 'date-fns';
import { Notification, NotificationDTO } from 'redux/reducers/notificationsReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import API, { API_V1_PATH } from 'utils/config/axiosConfig';
import { markMultipleAsSeen } from 'utils/notifications';
import { HttpStatusOk } from 'utils/statusCodes';

export interface InfoModalState {
  message: string;
  visible: boolean;
  type: 'success' | 'error' | null;
  shouldLogout?: boolean;
}

interface ConfirmationModalAction {
  title: string;
  message?: string;
  onCancel?: () => void;
  onAccept?: () => void;
}

export interface ConfirmationModalState extends ConfirmationModalAction {
  visible: boolean;
}

export interface Country {
  id: number;
  name: string;
  iso2: string;
}

export interface UserType {
  id: string;
  type: string;
}

export interface SourceType {
  id: string;
  type: string;
}

// FIXME: replace with library
export enum NotificationType {
  // Unknown
  PortfoliosNewsAndNotifications = 'Portfolios News & Notifications',
  FINVARNotifications = 'FINVAR Notifications',
  Account = 'Account',
  Connections = 'Connections',
  YouMayKnow = 'You May Know',
  CompanyPriceAlert = 'Company Price Alert',
  KRF = 'KRF',
  News = 'News',

  // Currently supported
  Organization = 'Organization',
  Portfolio = 'Portfolio',
  Event = 'Event',
  Post = 'Post',
  Comment = 'Comment',
  UserTag = 'UserTagNotification',
  Indicators = 'Indicators',
}

interface NotificationMetadataBase {
  readonly type: NotificationType;
  readonly label?: string;
}

interface UnknownNotificationMetadata extends NotificationMetadataBase {
  readonly type:
    | NotificationType.FINVARNotifications
    | NotificationType.KRF
    | NotificationType.Account
    | NotificationType.CompanyPriceAlert
    | NotificationType.PortfoliosNewsAndNotifications;

  readonly [key: string]: unknown;
}

interface PortfolioNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.Portfolio;
  readonly id: string;
  readonly label: string;
}

interface OrganizationNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.Organization;
  readonly id: string;
  readonly label: string;
}

interface PostNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.Post;
  readonly post_id: string;
  readonly comment_id: string;
  readonly commenter_name: string;
}

interface CommentNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.Comment;
  readonly comment_id: string;
  readonly post_id: string;
  readonly commenter_name: string;
}

interface EventNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.Event;
  readonly event_id?: string;
  readonly stream_id?: string;
}

interface ConnectionsNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.Connections;
  readonly follower_id: string;
  readonly followed_id: string;
  readonly follower_name: string;
  readonly followed: boolean;
}

interface YouMayKnowMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.YouMayKnow;
  readonly user_id: string;
  readonly followed: boolean;
}

interface UserTagNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.UserTag;
  readonly object_type: string;
}

interface UserTagPostNotificationMetadata extends UserTagNotificationMetadata {
  readonly type: NotificationType.UserTag;
  readonly object_type: 'Post';
  readonly post_id: string;
}

interface NewsNotificationMetadata extends NotificationMetadataBase {
  readonly type: NotificationType.News;
  readonly filter: string[];
}

interface UserTagCommentNotificationMetadata extends UserTagNotificationMetadata {
  readonly type: NotificationType.UserTag;
  readonly object_type: 'Comment';
  readonly comment_id: string;
  readonly post_id: string;
}

export type NotificationMetadata =
  | UnknownNotificationMetadata
  | OrganizationNotificationMetadata
  | PortfolioNotificationMetadata
  | PostNotificationMetadata
  | CommentNotificationMetadata
  | EventNotificationMetadata
  | ConnectionsNotificationMetadata
  | UserTagPostNotificationMetadata
  | UserTagCommentNotificationMetadata
  | NewsNotificationMetadata
  | YouMayKnowMetadata;

const isSameUserMetadata = (
  metadata: NotificationMetadata | undefined,
  userId: string,
): metadata is YouMayKnowMetadata | ConnectionsNotificationMetadata => {
  if (!metadata) {
    return false;
  }

  return (
    (metadata.type === NotificationType.Connections && metadata.follower_id === userId) ||
    (metadata.type === NotificationType.YouMayKnow && metadata.user_id === userId)
  );
};

export interface MarketEntry {
  readonly price: number;
  readonly change: number;
  readonly name: string;
}

interface FinancialQuotes {
  date?: string;
  data: MarketEntry[];
}

export interface UserNotifications {
  notifications: Notification[];
  total_count: number;
}

export interface FaqItem {
  question: string;
  answer: string;
}

export interface Faq {
  section: string;
  description: string;
  faqs: FaqItem[];
}

export interface SharedState {
  infoModal: InfoModalState;
  countries: Country[];
  userTypes: UserType[];
  sourceTypes: SourceType[];
  loading: boolean;
  userNotifications: UserNotifications;
  loadingUserNotifications: boolean;
  summaryNotifications: readonly Notification[];
  loadingSummaryNotifications: boolean;
  confirmationModal: ConfirmationModalState;
  financialQuotes: FinancialQuotes;
  loadingFaqs: boolean;
  faqs: Faq[];
}

const initialState: SharedState = {
  infoModal: {
    visible: false,
    message: '',
    type: null,
    shouldLogout: false,
  },
  countries: [],
  userTypes: [],
  sourceTypes: [],
  loading: true,
  loadingUserNotifications: false,
  loadingFaqs: true,
  userNotifications: { notifications: [], total_count: 0 },
  confirmationModal: {
    visible: false,
    title: '',
    message: '',
    onAccept: undefined,
    onCancel: undefined,
  },
  financialQuotes: { data: [] },
  faqs: [],
  summaryNotifications: [],
  loadingSummaryNotifications: false,
};

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    showInfoModal: (
      state,
      action: PayloadAction<{
        message: string;
        type: 'success' | 'error' | null;
        showIcon?: boolean;
        shouldLogout?: boolean;
      }>,
    ) => {
      state.infoModal.visible = true;
      state.infoModal.message = action.payload.message;
      state.infoModal.type = action.payload.type;
      state.infoModal.shouldLogout = action.payload.shouldLogout;
    },
    closeInfoModal: state => {
      state.infoModal.visible = false;
      state.infoModal.shouldLogout = false;
    },
    showConfirmationModal: (state, action: PayloadAction<ConfirmationModalAction>) => {
      const { title, message, onAccept, onCancel } = action.payload;
      state.confirmationModal.visible = true;
      state.confirmationModal.title = title;
      state.confirmationModal.message = message;
      state.confirmationModal.onAccept = onAccept;
      state.confirmationModal.onCancel = onCancel;
    },
    closeConfirmationModal: state => {
      state.confirmationModal.visible = false;
      state.confirmationModal.onAccept = undefined;
      state.confirmationModal.onCancel = undefined;
    },
    getCountriesSuccess: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
    getSourceTypesSuccess: (state, action: PayloadAction<SourceType[]>) => {
      state.sourceTypes = action.payload;
    },
    getFinancialQuotesSuccess: (state, action: PayloadAction<FinancialQuotes>) => {
      state.financialQuotes = action.payload;
    },
    getUserNotificationsBellAction: state => {
      state.loadingUserNotifications = true;
    },
    getUserNotificationsBellSuccess: (state, action: PayloadAction<UserNotifications>) => {
      state.loadingUserNotifications = false;
      state.userNotifications = action.payload;
    },
    getUserNotificationsBellError: state => {
      state.loadingUserNotifications = false;
    },
    pushNotificationReceived: (state, action: PayloadAction<NotificationDTO>): void => {
      const { total_count, notifications } = state.userNotifications;
      const notification = Notification.fromJson(action.payload);

      state.userNotifications = {
        total_count: total_count + 1,
        notifications: [notification, ...notifications].slice(0, 10),
      };
      state.summaryNotifications = [notification, ...notifications].slice(0, 3);
    },
    getFaqs: state => {
      state.loadingFaqs = true;
    },
    getFaqsSuccess: (state, { payload }: PayloadAction<Faq[]>) => {
      state.loadingFaqs = false;
      state.faqs = payload;
    },
    getSummaryNotifications: (state: SharedState): void => {
      state.loadingSummaryNotifications = true;
    },
    getSummaryNotificationsFailure: (state: SharedState): void => {
      state.loadingSummaryNotifications = true;
    },
    getSummaryNotificationsSuccess: (
      state: SharedState,
      { payload }: PayloadAction<Notification[]>,
    ): void => {
      state.loadingSummaryNotifications = false;
      state.summaryNotifications = payload;
    },
    userFollowed: (state: SharedState, { payload }: PayloadAction<string>): void => {
      const { notifications } = state.userNotifications;

      state.userNotifications = {
        ...state.userNotifications,
        notifications: notifications.map((notification: Notification): Notification => {
          const { metadata } = notification;
          if (isSameUserMetadata(metadata, payload)) {
            return {
              ...notification,
              metadata: { ...metadata, followed: true },
            };
          }

          return notification;
        }),
      };
    },
    userUnfollowed: (state: SharedState, { payload }: PayloadAction<string>): void => {
      const { notifications } = state.userNotifications;

      state.userNotifications = {
        ...state.userNotifications,
        notifications: notifications.map((notification: Notification): Notification => {
          const { metadata } = notification;
          if (isSameUserMetadata(metadata, payload)) {
            return {
              ...notification,
              metadata: { ...metadata, followed: false },
            };
          }

          return notification;
        }),
      };
    },
    markBellNotificationsAsSeen: (
      state: SharedState,
      { payload }: PayloadAction<Notification[] | null>,
    ): void => {
      // The special value `null` means ALL notifications where marked as read
      if (payload === null) {
        state.userNotifications = { total_count: 0, notifications: [] };
      } else {
        const { notifications, total_count } = state.userNotifications;
        const markedAsSeen = markMultipleAsSeen(notifications, payload);
        const filtered = markedAsSeen.filter(
          (notification: Notification): boolean => !notification.seen,
        );

        state.userNotifications = {
          total_count: total_count - (notifications.length - filtered.length),
          notifications: filtered,
        };
      }
    },
  },
});

export const {
  showInfoModal,
  closeInfoModal,
  getCountriesSuccess,
  getUserNotificationsBellAction,
  getUserNotificationsBellError,
  getUserNotificationsBellSuccess,
  pushNotificationReceived,
  getFinancialQuotesSuccess,
  closeConfirmationModal,
  getFaqs,
  getFaqsSuccess,
  getSummaryNotifications,
  getSummaryNotificationsFailure,
  getSummaryNotificationsSuccess,
  markBellNotificationsAsSeen,

  userFollowed,
  userUnfollowed,
} = sharedSlice.actions;

export const getCountriesAction = createAPIAction(function getCountries(
  client: HttpClient,
): ActionsGenerator {
  return async function* (): AsyncGenerator<AnyAction> {
    const response = await client.GET(`${API_V1_PATH}/reference-data/countries`);
    if (response.status === 200) {
      yield getCountriesSuccess(response.data);
    }
  };
});

export const getUserNotificationsBell =
  (): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getUserNotificationsBellAction());
    try {
      const response = await API.get(`${API_V1_PATH}/notifications/bell`);
      if (response.status === HttpStatusOk) {
        const { notifications, total_count } = response.data;

        dispatch(
          getUserNotificationsBellSuccess({
            total_count: total_count,
            notifications: notifications.map(Notification.fromJson),
          }),
        );
      } else {
        dispatch(getUserNotificationsBellError());
      }
    } catch (error) {
      dispatch(getUserNotificationsBellError());
    }
  };

export function getFinancialQuotesAction() {
  return async (dispatch: Dispatch) => {
    try {
      const result = await API.get(`${API_V1_PATH}/external-services/financial-quotes`);
      if (Array.isArray(result.data)) {
        const payload: FinancialQuotes = {
          data: result.data,
          date: format(new Date(), 'yyyy-MM-dd'),
        };
        dispatch(getFinancialQuotesSuccess(payload));
      }
    } catch (error: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: error?.message || errorMessageDefault,
        }),
      );
    }
  };
}

export const getFaqsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getFaqs());
    try {
      const result = await API.get(`${API_V1_PATH}/faqs`);
      if (result.status >= 200 && result.status < 300) {
        dispatch(getFaqsSuccess(result.data as Faq[]));
      }
    } catch (error: any) {
      dispatch(
        showInfoModal({
          type: 'error',
          message: error?.message || errorMessageDefault,
        }),
      );
    }
  };
};

export const infoModalSelector = (state: { sharedReducer: SharedState }) =>
  state.sharedReducer.infoModal;
export const confirmationModalSelector = (state: { sharedReducer: SharedState }) =>
  state.sharedReducer.confirmationModal;
export const countriesSelector = (state: { sharedReducer: SharedState }): readonly Country[] =>
  state.sharedReducer.countries;
export const loadingSelector = (state: { sharedReducer: SharedState }) =>
  state.sharedReducer.loading;
export const financialQuotesSelector = (state: { sharedReducer: SharedState }) =>
  state.sharedReducer.financialQuotes;
export const userNotificationsSelector = (state: { sharedReducer: SharedState }) =>
  state.sharedReducer.userNotifications;
export const summaryNotificationsSelector = (state: {
  sharedReducer: SharedState;
}): readonly Notification[] => state.sharedReducer.summaryNotifications;

export const faqsSelector = (state: { sharedReducer: SharedState }) => state.sharedReducer.faqs;
export const loadingFaqsSelector = (state: { sharedReducer: SharedState }) =>
  state.sharedReducer.loadingFaqs;

export default sharedSlice.reducer;
