import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from 'redux/reducers/authReducer';
import { computeBaseUrl } from 'utils/config/computeBaseUrl';
import { HttpStatusPaymentRequired } from 'utils/statusCodes';

const baseURL: string = computeBaseUrl(process.env.REACT_APP_API_URL);

const instance = axios.create({
  baseURL: baseURL,
  timeout: 90000,
});

instance.interceptors.request.use(
  (originalConfiguration: AxiosRequestConfig): AxiosRequestConfig => {
    const configurationCopy = { ...originalConfiguration };
    const authenticationToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (authenticationToken) {
      configurationCopy.headers['Authorization'] = `Bearer ${authenticationToken}`;
    }
    configurationCopy.validateStatus = (status: number): boolean => {
      if (status >= 200 && status < 300) {
        return true;
      } else {
        return status === HttpStatusPaymentRequired;
      }
    };

    return configurationCopy;
  },
  (error: any): any => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // TODO: Convert to camel case here (right?)
    return response;
  },
  (error: any): any => {
    if (axios.isCancel(error)) {
      // Do nothing, cancelling is about not doing anything after that
      return { message: 'Request Canceled' };
    }

    // FIXME: Create a generic error here no?
    const status = error.response ? error.response.status : -1;

    switch (status) {
      case 403:
        /* Should return an empty object to avoid entering the request catch and show the error message */
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);

        // FIXME: not necessarily correct
        window.location.href = '/';
        return {};
      default:
        break;
    }

    return Promise.reject({ ...error });
  },
);

export { baseURL };

export default instance;

export const isAxiosError = (error: any): error is AxiosError => {
  return 'isAxiosError' in error && error.isAxiosError === true;
};

export const API_V1_PATH = '/api/v1';

export const defaultAPIHeaders = async (headers: Headers): Promise<Headers> => {
  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  if (token !== null) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};
