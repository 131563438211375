import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlgoNoteCategory } from 'redux/reducers/companyReducer';
import { ApplicationState } from 'redux/reducers/store';
import { Page } from 'types/page';

export interface AlgoNote {
  id: string;
  name: string;
  content: string;
  category: AlgoNoteCategory;
  date: string;
  companyId: string;
}

export interface AlgoNotesState {
  notes: Page<AlgoNote>;
  loading: boolean;
}

const initialState: AlgoNotesState = {
  notes: Page.empty(),
  loading: false,
};

const slice = createSlice({
  name: 'algo-notes',
  initialState,
  reducers: {
    fetchAlgoNotesStarted: (state: AlgoNotesState): void => {
      state.loading = true;
      state.notes = Page.empty();
    },
    fetchAlgoNotesFailed: (state: AlgoNotesState): void => {
      state.loading = false;
    },
    fetchAlgoNotesSucceeded: (
      state: AlgoNotesState,
      { payload }: PayloadAction<Page<AlgoNote>>,
    ): void => {
      state.notes = payload;
      state.loading = false;
    },
  },
});

export const algoNotesSelector = (state: ApplicationState): AlgoNotesState => state.algoNotes;

export const { fetchAlgoNotesSucceeded, fetchAlgoNotesStarted, fetchAlgoNotesFailed } =
  slice.actions;

export default slice.reducer;
