import SVGIcon from 'components/icons/SVGIcon';
import { cardBrandHumanLabel } from 'constants/payments';
import { format, isToday, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { Card } from 'redux/reducers/authReducer';

interface Props {
  readonly card?: Card;
  readonly expiresAt?: string;
}

export const SubscriptionRenewStatus: React.FC<Props> = ({
  card,
  expiresAt,
}: Props): React.ReactElement => {
  const expirationDate = useMemo((): string => {
    if (!expiresAt) {
      return '';
    }

    const date = parseISO(expiresAt);
    if (isToday(date)) {
      return format(date, 'MMM dd, yyyy hh:mm:ss aaa');
    }

    return format(date, 'MMM dd, yyyy');
  }, [expiresAt]);

  if (!card) {
    return <></>;
  }

  return (
    <div className="flex items-start gap-2 font-poppins text-sm text-gray mr-auto">
      <SVGIcon name="info-icon" className="w-5" />
      <div>
        <div>Your subscription will be renewed automatically using</div>
        <div className="flex flex-wrap items-center gap-2">
          <span>{cardBrandHumanLabel[card.brand]}</span> <span>**** {card.last4}</span>
          <span className="w-full">
            (exp. {card.exp_month}/{card.exp_year}) on {expirationDate}
          </span>
        </div>
      </div>
    </div>
  );
};
