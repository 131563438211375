import Footer from 'components/footer/Footer';
import { LandingPageHeader } from 'components/header/LandingPageHeader';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export const Layout: React.FC = (): React.ReactElement => {
  const location = useLocation();

  useEffect((): void => {
    mixpanel.track('PageView Landing Page');
  }, []);

  const compactHeader = useMemo(
    (): boolean => location.pathname !== '' && location.pathname !== '/',
    [location],
  );

  return (
    <div className="flex flex-col relative h-screen overflow-y-auto">
      <div className="sticky top-0 z-1">
        <LandingPageHeader compact={compactHeader} />
      </div>

      <div className="flex-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
