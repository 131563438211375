import { ConditionalRender } from 'components/conditionalRenderer';
import { SearchBox } from 'components/searchBox';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Organization,
  OrganizationTypes,
  smartToolsRecentSearchesSelector,
  smartToolsResetSearchResults,
} from 'redux/reducers/smartToolsRecentSearchesReducer';
import {
  removeSmartToolsRecentSearches,
  smartToolsSearchOrganizations,
  updateSmartToolsRecentSearches,
} from 'redux/services/smartToolsRecentSearchesServices';
import { defaultOptions } from 'utils/popper';
import { RecentItems } from 'views/SmartTools/ValueChains/components/RecentItems';
import { SearchResultsDropdown } from 'views/SmartTools/ValueChains/components/SearchResultsDropdown';

interface Props {
  readonly allowOnly: OrganizationTypes;
  readonly showRecentItems: boolean;
}

export const OrganizationSearchInput: React.FC<Props> = ({
  allowOnly,
  showRecentItems,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, searching, recentSearches, searchResults } = useSelector(
    smartToolsRecentSearchesSelector,
  );

  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [currentValue, setCurrentValue] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');

  const containerRef = useRef<HTMLDivElement>(null);

  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        withSameWidth: true,
        offset: [0, 0],
        preventOverflow: true,
      }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void => {
    update?.();
  }, [update]);

  useEffect((): void | VoidFunction => {
    if (keyword === '') {
      dispatch(smartToolsResetSearchResults());
      return;
    }

    return dispatch(smartToolsSearchOrganizations(keyword, allowOnly));
  }, [allowOnly, dispatch, keyword]);

  useEffect((): void | VoidFunction => {
    if (currentValue === '') {
      setKeyword('');
      return;
    }

    const timeout = setTimeout((): void => {
      setKeyword(currentValue);
    }, 220);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [currentValue]);

  const handleOrganizationSelected = useCallback(
    (organization: Organization): void => {
      dispatch(updateSmartToolsRecentSearches(organization.id));
      setCurrentValue('');

      navigate(organization.id);
    },
    [dispatch, navigate],
  );

  const placeholder = useMemo((): string => {
    switch (allowOnly) {
      case OrganizationTypes.company:
        return 'Search for a company';
      case OrganizationTypes.industry:
        return 'Search for an industry';
      default:
        // FIXME: For now this should not show all results as it is
        //        kind of tricky.
        //        The solution involves passing the category to the backend,
        //        but there is a catch. How do we keep the 10 items limit with this
        //        wierd combination?
        return 'Search for a company';
    }
  }, [allowOnly]);

  const handleRemoveRecent = useCallback(
    (id: string): void => {
      dispatch(removeSmartToolsRecentSearches(id));
    },
    [dispatch],
  );

  const shouldRenderRecentItems = useMemo((): boolean => {
    return showRecentItems && recentSearches.length > 0;
  }, [recentSearches.length, showRecentItems]);

  return (
    <div ref={containerRef} className="flex flex-col lg:w-1/3 md:w/12 z-1">
      <SearchBox
        ref={setAnchor}
        value={currentValue}
        placeholder={placeholder}
        fullWidth={true}
        searching={searching}
        onChange={setCurrentValue}
      />
      {searchResults.length > 0 &&
        ReactDOM.createPortal(
          <SearchResultsDropdown
            ref={setPopup}
            organizations={searchResults}
            style={styles.popper}
            properties={attributes.popper}
            onItemClicked={handleOrganizationSelected}
          />,
          document.body,
        )}
      <ConditionalRender renderIf={shouldRenderRecentItems}>
        <RecentItems
          items={recentSearches}
          fetching={loading}
          onItemClicked={handleOrganizationSelected}
          onItemDeleted={handleRemoveRecent}
        />
      </ConditionalRender>
    </div>
  );
};
