import DeleteModal from 'components/deleteModal';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { processingStateSelector } from 'redux/reducers/timelineReducer';
import { ProcessingStateEnum } from 'types/processingState';
import { Stream } from 'types/timeline';

interface Props {
  readonly streams: Stream[];

  onAccept(): void;
  onClose(): void;
}

export const DeleteStreamModal: React.FC<Props> = ({
  streams,
  onAccept,
  onClose,
}: Props): React.ReactElement => {
  const processingState = useSelector(processingStateSelector);

  useEffect((): void => {
    if (processingState.state === ProcessingStateEnum.success) {
      onClose();
    }
  }, [onClose, processingState.state]);

  return (
    <DeleteModal
      text="You are about to delete the following streams:"
      subText="This action is irreversible"
      items={streams.map((s: Stream): string => s.name)}
      onCancel={onClose}
      onAccept={onAccept}
    />
  );
};
