import 'views/Landing/LandingPage/components/FinvarEnhances/Enhances.scss';

import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';
import Text from 'views/Landing/LandingPage/components/FinvarEnhances/text';

const FinvarEnhances: React.FC = () => {
  return (
    <div>
      <h1 className="lg:text-left text-gray-darkest text-2xl md:text-4xl title">{Text.title}</h1>
      <ul className="mt-12">
        {Text.list.map((enhancesText, i) => (
          <li
            key={i}
            className="flex flex-row items-baseline leading-5 md:leading-7 text-left mb-6"
          >
            <span>
              <SVGIcon name="check-icon" className="w-5 h-3" />
            </span>
            <span className="ml-4 text-gray-darkest font-poppins md:text-base lg:text-md">
              {enhancesText}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FinvarEnhances;
