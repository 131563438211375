import { ConfirmationBox } from 'components/confirmationBox';
import { DesktopHeaderBase } from 'components/header/desktopHeaderBase';
import { GlobalSearch } from 'components/header/UserHeader/components/globalSearch';
import { UserMenu } from 'components/header/UserHeader/components/userMenu';
import { NavButtons } from 'components/header/UserHeader/navButtons';
import { Modal } from 'components/modal';
import { useModal } from 'hooks/useModal';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'redux/services/authServices';
import { goToMainPage } from 'utils/goToMainPage';

export const DesktopHeader: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const logoutModal = useModal();
  const navigate = useNavigate();

  const handleLogout = useCallback((): void => {
    dispatch(logout());
    goToMainPage();
  }, [dispatch]);

  const navigateToIndicators = useCallback((): void => {
    navigate('/data-analysis/indicators/popular');
  }, [navigate]);

  return (
    <DesktopHeaderBase onIndicatorsClick={navigateToIndicators}>
      <div className="flex items-center justify-between flex-1 min-w-0">
        <GlobalSearch />
        {process.env.REACT_APP_DATA_PORTAL ? (
          <div className="w-12" />
        ) : (
          <div className="flex items-center gap-4">
            <NavButtons />
            <UserMenu onLogout={logoutModal.open} />
          </div>
        )}
      </div>

      <Modal isOpen={logoutModal.isOpen} onClose={logoutModal.close}>
        <Modal.Content title="Log Out">
          <ConfirmationBox
            message="Are you sure you want to logout?"
            onNo={logoutModal.close}
            onYes={handleLogout}
          />
        </Modal.Content>
      </Modal>
    </DesktopHeaderBase>
  );
};
