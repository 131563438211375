import 'components/DEPRECATED/dropdown/dropdown.scss';

import SVGIcon from 'components/icons/SVGIcon';
import React, { ReactElement, useCallback, useState } from 'react';

interface IOption {
  id: string | number;
  label: string;
}

interface Props {
  options?: IOption[] | string[];
  placeholder: string;
  value?: string;
  leadingIcon?: ReactElement;
  onSelectOption?: (option: IOption | string) => void;
  className?: string;
}

function isIOption(item: any): item is IOption {
  return item.id !== undefined;
}

const UnboxedPicker: React.FC<Props> = ({
  placeholder,
  value,
  leadingIcon,
  options,
  onSelectOption,
  className,
}) => {
  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const toggleShowList = useCallback((): void => {
    setIsListVisible(!isListVisible);
  }, [isListVisible]);

  const handleSelectOption = useCallback(
    (item: IOption | string): void => {
      setIsListVisible(false);
      if (onSelectOption) onSelectOption(item);
    },
    [onSelectOption, setIsListVisible],
  );

  return (
    <div className="relative">
      <div
        onClick={toggleShowList}
        className="flex items-center hover:opacity-50 mx-4 cursor-pointer duration-300-2 text-blue"
      >
        {leadingIcon}
        <span
          className={`text-gray-darkest uppercase ${
            leadingIcon ? 'ml-2' : ''
          } pt-1.5 mr-2 text-xs font-poppinsSemiBold`}
        >
          {value || placeholder}
        </span>
        <SVGIcon name="down-arrow" className="pt-1 w-3 h-3" />
      </div>
      {isListVisible && (
        <div
          className={`absolute top-6 bg-white rounded-sm shadow-md py-2 min-w-min ${className} max-h-80 overflow-auto`}
        >
          {Array.isArray(options) &&
            options.map((item: IOption | string) => (
              <p
                onClick={() => handleSelectOption(item)}
                key={isIOption(item) ? item.id : item}
                className="py-2  px-4 text-gray text-xs hover:text-blue duration-300 cursor-pointer"
              >
                {isIOption(item) ? item.label : item}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

export default UnboxedPicker;
