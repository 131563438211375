import { Identifiable } from 'components/table/types';
import settings from 'utils/config/appSettings';

export interface Page<T> {
  page_number: number;
  page_size: number;
  page_count: number;
  total_count: number;
  next_page: number | null;

  data: T[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Page<T> {
  public static map<T, R>(page: Page<T>, mapper: (item: T) => R): Page<R> {
    const { data } = page;

    return {
      page_number: page.page_number,
      page_size: page.page_size,
      page_count: page.page_count,
      total_count: page.total_count,
      next_page: page.next_page,
      data: data.map<R>(mapper),
    };
  }

  public static empty<T>(): Page<T> {
    return {
      page_size: settings.defaultPageSize,
      page_number: 1,
      page_count: 0,
      data: [],
      total_count: 0,
      next_page: null,
    };
  }

  public static filter<T>(page: Page<T>, filterFn: (item: T) => boolean): Page<T> {
    const { data } = page;

    const originalPageCount = data.length;
    const filteredData = data.filter(filterFn);
    const newPageSize = filteredData.length;
    const newTotalCount = page.total_count - (originalPageCount - newPageSize);
    const pageSize = Math.max(page.page_size, settings.defaultPageSize);

    return {
      ...page,
      data: page.data.filter(filterFn),
      total_count: newTotalCount,
      page_count: Math.ceil(newTotalCount / pageSize),
    };
  }

  public static addOrReplace<T extends Identifiable>(
    page: Page<T>,
    targetItem: T,
    isSameItem: (potentialDuplicate: T) => boolean = (): boolean => true,
    sortFn?: (a: T, b: T) => number,
  ): Page<T> {
    const { data } = page;
    if (data?.some(isSameItem)) {
      return {
        ...page,
        data: page.data.map((item: T): T => {
          if (isSameItem(item)) {
            return targetItem;
          } else {
            return item;
          }
        }),
      };
    }

    // TODO: investigate what is happening here? `page.page_size` is for some reason zero
    const pageSize = Math.max(page.page_size, settings.defaultPageSize);
    return {
      ...page,
      total_count: page.total_count + 1,
      page_count: Math.ceil((page.total_count + 1) / pageSize),
      data: sortFn ? [...page.data, targetItem].sort(sortFn) : [...page.data, targetItem],
    };
  }
}

export const hasSameId =
  <T extends Identifiable>(item: T): ((potentialDuplicate: T) => boolean) =>
  (potentialDuplicate: T): boolean =>
    potentialDuplicate.id === item.id;
