import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { searchToObject } from 'hooks/useQueryParameters';
import { AccessMode } from 'types/accessMode';
import { Page } from 'types/page';
import { WithID } from 'types/withId';
import settings from 'utils/config/appSettings';
import { baseURL, defaultAPIHeaders } from 'utils/config/axiosConfig';
import { toQueryString } from 'utils/toQueryString';
import { PaymentIntentPayment } from 'views/Checkout/SubscriptionCheckout/payment';
import { CreateMarketplaceItemPayload } from 'views/EarnAndBuy/Buy/modals/addListingReducer';
import {
  CreateListingPayload,
  UpdateListingPayload,
} from 'views/EarnAndBuy/Earn/modals/addListingReducer';
import {
  CreateSpecialOfferPayload,
  UpdateSpecialOfferPayload,
} from 'views/EarnAndBuy/SpecialOffers/modals/addSpecialOfferReducer';

export enum ListingStatus {
  open = 'Open',
  closed = 'Closed',
  workInProgress = 'Work in progress',
  completed = 'Completed',

  applied = 'Applied',
}

export interface Category {
  readonly id: number;
  readonly name: string;
}

export interface Count {
  readonly count: number;
}

export interface Requirement {
  readonly id: string;
  readonly names: readonly string[];
}

export interface ListingOwner {
  readonly id: string;
  readonly name: string;
}

export interface NamedObject {
  readonly id: number;
  readonly name: string;
}

export interface SpecialOffer {
  readonly id: string;
  readonly posted_at: string;
  readonly listing_owner: ListingOwner;
  readonly category: NamedObject;
  readonly title: string;
  readonly requirements?: readonly string[];
  readonly status: ListingStatus;
  readonly access_mode: AccessMode;
  readonly required_feature_level: number;
  readonly minimum_required_plan_id: string;
}

export interface ItemRating {
  user: number;
  average: number;
}

export enum LibraryItemStatus {
  purchased = 'Purchased',
  bookmarked = 'Bookmarked',
}

export interface LibraryItem {
  readonly id: string;
  readonly title: string;
  readonly listing_owner: ListingOwner;
  readonly status: LibraryItemStatus;
  readonly added_at: string;
  readonly price_amount: number;
  readonly price_currency: string;
  readonly star_rating: number;
  readonly content_uri: string;
}

export interface MarketplaceItem {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly listing_owner: ListingOwner;
  readonly posted_at: string;
  readonly category: NamedObject;
  readonly price_amount: number;
  readonly price_currency: string;
  readonly content_uri: string;
  readonly review_count: number;
  readonly access_mode: AccessMode;

  status?: 'deleting';
  ratings_count: number;
  star_rating: ItemRating;
  bookmarked: boolean;
}

export interface EarnItem {
  readonly id: string;
  readonly posted_at: string;
  readonly listing_owner: ListingOwner;
  readonly category: NamedObject;
  readonly title: string;
  readonly payment_amount: number;
  readonly payment_currency: string;
  readonly payment_unit: NamedObject;
  readonly requirements?: readonly string[];
  readonly status: ListingStatus;
  readonly access_mode: AccessMode;
  readonly required_feature_level: number;

  readonly minimum_required_plan_id: string;
  readonly applied?: boolean;
}

export interface SpecialOfferDetails extends SpecialOffer {
  readonly description: string;
  readonly tags: readonly string[];
  readonly url: string;
}

export interface EarnItemDetails extends EarnItem {
  readonly description: string;
  readonly completion_instructions: string;
  readonly how_to_apply: string;
  readonly tags: readonly string[];
}

export interface MarketplaceItemDetails extends MarketplaceItem {
  readonly description: string;
  readonly tags: readonly string[];
}

interface UpdateListingStatusPayload {
  readonly id: string;
  readonly status: ListingStatus;
}

const api = createApi({
  reducerPath: 'earnAndBuyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}/api/v1/earn-and-buy`,
    prepareHeaders: defaultAPIHeaders,
  }),
  tagTypes: [
    'EarnItems',
    'CurrentEarnItem',
    'SpecialOffers',
    'CurrentSpecialOffer',
    'MarketplaceItems',
    'CurrentMarketplaceItem',
    'Library',
  ],
  endpoints: builder => ({
    listListings: builder.query<Page<EarnItem>, Record<string, string>>({
      query: (sourceQuery: Record<string, string>): string => {
        const query = {
          ...sourceQuery,
          page_number: sourceQuery.page_number ?? 1,
          page_size: settings.defaultPageSize,
        };

        return `earn/${toQueryString(query)}`;
      },
      providesTags: ['EarnItems'],
    }),
    getEarnItem: builder.query<EarnItemDetails, string | undefined>({
      query: (id: string | undefined): string => {
        if (id === undefined) {
          throw new Error('cannot fetch for undefined id');
        }

        return `earn/${id}`;
      },
      providesTags: ['CurrentEarnItem'],
    }),
    removeEarnItem: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `earn/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EarnItems'],
    }),
    updateEarnItem: builder.mutation<EarnItem, UpdateListingPayload>({
      query: ({ id, ...payload }: UpdateListingPayload) => ({
        url: `earn/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['EarnItems', 'CurrentEarnItem'],
    }),
    createEarnItem: builder.mutation<EarnItem, CreateListingPayload>({
      query: (payload: CreateListingPayload) => ({
        url: 'earn',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['EarnItems'],
    }),
    library: builder.query<Page<LibraryItem>, Record<string, string>>({
      query: (sourceQuery: Record<string, string>): string => {
        const query = {
          ...sourceQuery,
          page_number: sourceQuery.page_number ?? 1,
          page_size: settings.defaultPageSize,
        };

        return `marketplace/library${toQueryString(query)}`;
      },
      providesTags: ['Library'],
    }),
    checkoutMarketplaceItem: builder.query<PaymentIntentPayment, string>({
      query: (id: string): string => `marketplace/${id}/checkout`,
    }),
    listMarketplaceItems: builder.query<Page<MarketplaceItem>, Record<string, string>>({
      query: (sourceQuery: Record<string, string>): string => {
        const query = {
          ...sourceQuery,
          page_number: sourceQuery.page_number ?? 1,
          page_size: settings.defaultPageSize,
        };

        return `marketplace${toQueryString(query)}`;
      },
      providesTags: ['MarketplaceItems'],
    }),
    getMarketplaceItem: builder.query<MarketplaceItemDetails, string | undefined>({
      query: (id: string | undefined): string => {
        if (id === undefined) {
          throw new Error('cannot fetch for undefined id');
        }

        return `marketplace/${id}`;
      },
      providesTags: ['CurrentMarketplaceItem'],
    }),
    removeMarketplaceItem: builder.mutation<void, string>({
      query: (itemId: string) => ({
        url: `marketplace/${itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MarketplaceItems'],
      onQueryStarted: async (id: string, { dispatch, queryFulfilled }): Promise<void> => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'listMarketplaceItems',
            {},
            (draft: Page<MarketplaceItem>): void => {
              const { data } = draft;

              draft.data = data.map((item: MarketplaceItem): MarketplaceItem => {
                if (item.id === id) {
                  item.status = 'deleting';
                }

                return item;
              });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateMarketplaceItem: builder.mutation<MarketplaceItem, WithID<CreateMarketplaceItemPayload>>({
      query: (payload: WithID<CreateMarketplaceItemPayload>) => ({
        url: `marketplace/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['MarketplaceItems', 'CurrentMarketplaceItem'],
    }),
    setMarketplaceItemStarRating: builder.mutation<
      MarketplaceItem,
      { readonly id: string; readonly rating: number | null }
    >({
      query: ({ id, rating }: { readonly id: string; readonly rating: number }) => ({
        url: `marketplace/${id}/rating`,
        method: 'PUT',
        body: { rating },
      }),
      onQueryStarted: async (
        { id, rating }: { readonly id: string; readonly rating: number },
        { dispatch, queryFulfilled },
      ): Promise<void> => {
        const patchResult1 = dispatch(
          api.util.updateQueryData('getMarketplaceItem', id, (draft: MarketplaceItem): void => {
            const {
              star_rating: { average, user },
              ratings_count: count,
            } = draft;

            if (rating === null) {
              draft.star_rating = {
                user: 0,
                average: (average * count - user) / (count - 1),
              };
              // New rating is irrelevant here
            } else {
              draft.star_rating = {
                user: rating,
                average: (average * count + rating) / (count + 1),
              };
            }
          }),
        );

        try {
          const { data: newItem } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData(
              'listMarketplaceItems',
              {},
              (draft: Page<MarketplaceItem>): void => {
                const { data } = draft;

                draft.data = data.map((item: MarketplaceItem): MarketplaceItem => {
                  if (item.id === id) {
                    return newItem;
                  }

                  return item;
                });
              },
            ),
          );
        } catch {
          patchResult1.undo();
        }
      },
    }),
    createMarketplaceItem: builder.mutation<MarketplaceItem, CreateMarketplaceItemPayload>({
      query: (payload: CreateMarketplaceItemPayload) => ({
        url: `marketplace/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['MarketplaceItems'],
    }),
    bookmarkMarketplaceItem: builder.mutation<MarketplaceItem, string>({
      query: (listingId: string) => ({
        url: `marketplace/${listingId}/bookmarks`,
        method: 'POST',
      }),
      onQueryStarted: async (id: string, { dispatch, queryFulfilled }): Promise<void> => {
        const sourceQuery = searchToObject(new URLSearchParams(location.search));
        const patchResult = dispatch(
          api.util.updateQueryData(
            'listMarketplaceItems',
            sourceQuery,
            (draft: Page<MarketplaceItem>): void => {
              const { data } = draft;

              data.forEach((item: MarketplaceItem): void => {
                if (item.id === id) {
                  item.bookmarked = true;
                }
              });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Library'],
    }),
    unbookmarkMarketplaceItem: builder.mutation<MarketplaceItem, string>({
      query: (listingId: string) => ({
        url: `marketplace/${listingId}/bookmarks`,
        method: 'DELETE',
      }),
      onQueryStarted: async (id: string, { dispatch, queryFulfilled }): Promise<void> => {
        const sourceQuery = searchToObject(new URLSearchParams(location.search));
        const patchResult = dispatch(
          api.util.updateQueryData(
            'listMarketplaceItems',
            sourceQuery,
            (draft: Page<MarketplaceItem>): void => {
              const { data } = draft;

              data.forEach((item: MarketplaceItem): void => {
                if (item.id === id) {
                  item.bookmarked = false;
                }
              });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Library'],
    }),
    applyForListing: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `earn/${id}/apply`,
        method: 'POST',
      }),
      invalidatesTags: ['EarnItems'],
    }),
    getPostedListingsCount: builder.query<Count, void>({
      query: (): string => 'earn/posted/count',
    }),
    categories: builder.query<readonly Category[], void>({
      query: (): string => '/categories',
    }),
    requirements: builder.query<readonly Requirement[], void>({
      query: (): string => '/requirements',
    }),
    listSpecialOffers: builder.query<Page<SpecialOffer>, Record<string, string>>({
      query: (sourceQuery: Record<string, string>): string => {
        const query = {
          ...sourceQuery,
          page_number: sourceQuery.page_number ?? 1,
          page_size: settings.defaultPageSize,
        };

        return `special-offers/${toQueryString(query)}`;
      },
      providesTags: ['SpecialOffers'],
    }),
    getSpecialOffer: builder.query<SpecialOfferDetails, string | undefined>({
      query: (id: string | undefined): string => {
        if (id === undefined) {
          throw new Error('cannot fetch for undefined id');
        }

        return `special-offers/${id}`;
      },
      providesTags: ['CurrentSpecialOffer'],
    }),
    removeSpecialOffer: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `special-offers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SpecialOffers'],
    }),
    updateSpecialOffer: builder.mutation<EarnItem, UpdateSpecialOfferPayload>({
      query: ({ id, ...payload }: UpdateSpecialOfferPayload) => ({
        url: `special-offers/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['SpecialOffers', 'CurrentSpecialOffer'],
    }),
    createSpecialOffer: builder.mutation<EarnItem, CreateSpecialOfferPayload>({
      query: (payload: CreateSpecialOfferPayload) => ({
        url: 'special-offers',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SpecialOffers'],
    }),
    getPostedSpecialOffersCount: builder.query<Count, void>({
      query: (): string => 'special-offers/posted/count',
    }),
    updateListingStatus: builder.mutation<void, UpdateListingStatusPayload>({
      query: ({ id, status }: UpdateListingStatusPayload) => ({
        url: `earn/${id}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['EarnItems', 'CurrentEarnItem'],
    }),
  }),
});

export default api;

export const {
  useListListingsQuery,
  useLazyGetEarnItemQuery,
  useGetEarnItemQuery,
  useCategoriesQuery,
  useRequirementsQuery,
  useCreateEarnItemMutation,
  useUpdateEarnItemMutation,
  useRemoveEarnItemMutation,
  useApplyForListingMutation,
  useGetPostedListingsCountQuery,

  useGetPostedSpecialOffersCountQuery,
  useListSpecialOffersQuery,
  useLazyGetSpecialOfferQuery,
  useGetSpecialOfferQuery,
  useCreateSpecialOfferMutation,
  useUpdateSpecialOfferMutation,
  useRemoveSpecialOfferMutation,

  useUpdateListingStatusMutation,
  useListMarketplaceItemsQuery,
  useGetMarketplaceItemQuery,
  useLazyGetMarketplaceItemQuery,
  useCreateMarketplaceItemMutation,
  useBookmarkMarketplaceItemMutation,
  useUnbookmarkMarketplaceItemMutation,
  useUpdateMarketplaceItemMutation,
  useRemoveMarketplaceItemMutation,
  useSetMarketplaceItemStarRatingMutation,

  useCheckoutMarketplaceItemQuery,
  useLibraryQuery,
  useLazyLibraryQuery,
} = api;
