import { Truncate } from 'components/truncate';
import { TruncateType } from 'components/truncate/baseProps';
import { DefaultTextEllipsis } from 'components/truncate/defaultTextEllipsis';
import React, { useMemo } from 'react';

interface Props {
  readonly content: string | React.ReactElement;
  onExpand?(): void;
}

export const TextLine: React.FC<Props> = ({ content, onExpand }: Props): React.ReactElement => {
  const ellipsis = useMemo(
    (): React.ReactElement => <DefaultTextEllipsis onClick={onExpand} />,
    [onExpand],
  );

  if (typeof content === 'string') {
    return (
      <div className={wrapperClassName}>
        <Truncate lines={1} ellipsis={ellipsis} contentType={TruncateType.text} component="p">
          <p>{content}</p>
        </Truncate>
      </div>
    );
  } else {
    return (
      <div className={wrapperClassName}>
        <Truncate lines={1} ellipsis={ellipsis} contentType={TruncateType.elements}>
          {content}
        </Truncate>
      </div>
    );
  }
};

const wrapperClassName =
  'flex w-full items-center justify-between font-poppins text-sm text-gray my-2';
