import React from 'react';

export interface TimelineFilterButtonProps<V> {
  readonly label: string;
  readonly value: V;
  readonly disabled?: boolean;
}

export class TimelineFilterButton<V> extends React.PureComponent<TimelineFilterButtonProps<V>> {
  public render(): React.ReactElement | null {
    return null;
  }
}
