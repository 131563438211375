import SpinnerLoader from 'components/spinnerLoader';
import { Graph } from 'components/valueChain/graph';
import { GraphChain } from 'components/valueChain/graphChain';
import React, { useMemo } from 'react';
import { ValueChainNode } from 'types/organization/types';

interface Props {
  readonly chain: ValueChainNode[];
  readonly totalCount: number;
  readonly loading: boolean;

  onGoToNodeAt(index: number): void;
  onChildClick(node: ValueChainNode): void;
}

export const ValueChain: React.FC<Props> = ({
  chain,
  totalCount,
  loading,
  onGoToNodeAt,
  onChildClick,
}: Props): React.ReactElement | null => {
  const last: ValueChainNode | undefined = useMemo(
    (): ValueChainNode => chain[chain.length - 1],
    [chain],
  );
  if (!last) {
    // Means that there's nothing to show
    return null;
  }

  return (
    <div className="relative">
      <GraphChain>
        {chain.slice(0, chain.length - 1).map(
          (node: ValueChainNode, index: number): React.ReactElement => (
            <Graph
              key={node.entityId}
              node={node}
              isFirst={index === 0}
              isLast={false}
              totalCount={totalCount}
              onCentralNodeClick={(): void => onGoToNodeAt(index)}
              onChildNodeClick={onChildClick}
            />
          ),
        )}

        {/* Last item must be always mounted <- Why? */}
        <Graph
          key={last.entityId}
          node={last}
          isFirst={chain.length === 1}
          isLast={true}
          totalCount={totalCount}
          onCentralNodeClick={(): void => onGoToNodeAt(chain.length - 1)}
          onChildNodeClick={onChildClick}
        />
      </GraphChain>

      <SpinnerLoader visible={loading} />
    </div>
  );
};
