import 'components/header/LandingPageHeader/loginHeader.scss';

import Button from 'components/buttons/Button/Button';
import { ConditionalRender } from 'components/conditionalRenderer';
import { DesktopHeaderBase } from 'components/header/desktopHeaderBase';
import { FeaturesButton } from 'components/header/LandingPageHeader/featuresButton';
import { HeaderMenuItemData, menu } from 'components/header/LandingPageHeader/menu';
import { HeaderMenuItem } from 'components/header/LandingPageHeader/menuItem';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FeatureKey, setSelectedFeature } from 'redux/reducers/landingPageReducer';
import { getFinancialQuotesAction } from 'redux/reducers/sharedReducer';
import { preRegisterMode } from 'utils';

interface Props {
  readonly compact: boolean;
}

const DesktopLandingPageHeader: React.FC<Props> = ({ compact }: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const headerRef = useRef<HTMLDivElement>(null);

  const [selectedMenuItemId, setSelectedMenuItemId] = useState('');

  useEffect(() => {
    dispatch(getFinancialQuotesAction());
  }, [dispatch]);

  const goTo = useCallback((id: string): void => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    setSelectedMenuItemId(id);
  }, []);

  const createGoToCallback = useCallback(
    (id: string): VoidFunction => {
      return (): void => goTo(id);
    },
    [goTo],
  );

  const goToFeature = useCallback(
    (key: FeatureKey): void => {
      dispatch(setSelectedFeature(key));
      goTo('features');
    },
    [goTo, dispatch],
  );

  return (
    <DesktopHeaderBase ref={headerRef}>
      <nav className="flex items-center gap-x-10 mr-8">
        <ConditionalRender renderIf={!compact}>
          <FeaturesButton
            selected={selectedMenuItemId === 'features'}
            onFeatureClick={goToFeature}
          />

          {menu.map(
            (item: HeaderMenuItemData): React.ReactElement => (
              <HeaderMenuItem
                key={item.id}
                label={item.label}
                selected={selectedMenuItemId === item.id}
                onClick={createGoToCallback(item.id)}
              />
            ),
          )}
        </ConditionalRender>

        <Link className="font-poppins text-md text-gray whitespace-nowrap" to="our-plans">
          Our Plans
        </Link>

        <Link className="font-poppins text-md text-gray whitespace-nowrap" to="earn-n-buy">
          Earn &amp; Buy
        </Link>

        <ConditionalRender renderIf={preRegisterMode}>
          <Link id="sign-up" className="truncate text-md text-blue ml-auto" to="/sign-up">
            <Button label="SIGN UP FOR EARLY ACCESS" className="truncate" width="w-max" />
          </Link>
        </ConditionalRender>

        <ConditionalRender renderIf={!preRegisterMode}>
          <div className="flex items-center ml-auto gap-x-6">
            <Link
              className="px-5 font-poppinsMedium leading-10 bg-blue rounded text-white whitespace-nowrap"
              to="/login"
            >
              Log In
            </Link>
            <Link className="font-poppinsSemiBold text-blue whitespace-nowrap" to="/sign-up">
              Sign Up
            </Link>
          </div>
        </ConditionalRender>
      </nav>
    </DesktopHeaderBase>
  );
};

export default DesktopLandingPageHeader;
