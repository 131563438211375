import { ReactComponent as EnvelopeIcon } from 'assets/icons/svg/envelope-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/svg/error-icon.svg';
import { MainOutletContent } from 'components/mainOutletContent';
import SpinnerLoader from 'components/spinnerLoader';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';

export const Unsubscribe: React.FC = (): React.ReactElement => {
  const [status, setStatus] = useState<'unset' | 'success' | 'error'>('unset');

  useEffect((): void => {
    api
      .post(`${API_V1_PATH}/auth/unsubscribe/all`)
      .then((): void => {
        setStatus('success');
      })
      .catch((error: Error): void => {
        console.warn(error);
        setStatus('error');
      });
  }, []);

  const Content = React.useCallback((): React.ReactElement => {
    switch (status) {
      case 'unset':
        return <SpinnerLoader visible={true} />;
      case 'success':
        return (
          <>
            <EnvelopeIcon className="mx-auto" height={64} />
            <h3 className="mt-6 mb-2">Successfully Unsubscribed</h3>
            <p>
              You have been unsubscribed from all our notifications. Before you go, we&apos;d hate
              for you to miss out on our useful updates and offers! Head over to your account{' '}
              <Link to="/profile/settings" className="text-blue">
                settings page
              </Link>{' '}
              to ensure you&apos;re still in the loop.
            </p>
            <p className="mt-4">
              Simply toggle on the notifications you&apos;d like to receive, and stay connected with
              all the latest news.
            </p>
            <Link to="/feed" className="block bg-blue text-white px-4 mt-6 leading-10 rounded">
              Back to your feed
            </Link>
          </>
        );
      case 'error':
        return (
          <>
            <ErrorIcon className="mx-auto" height={64} />
            <h3 className="mt-6 mb-2">Failed to Unsubscribed</h3>
            <p>
              We&apos;re sorry, it seems we encountered an issue while processing your unsubscribe
              request. Please try again later or contact our support team for further assistance. In
              the meantime, you can manage your notification preferences by visiting your account{' '}
              <Link to="/profile/settings" className="text-blue">
                settings page
              </Link>
              .
            </p>
            <p className="mt-4">
              We apologize for any inconvenience this may cause You have been unsubscribed from all
              our notifications. Before you go, we&apos;d hate for you to miss out on our useful
              updates and offers! Head over to your account
            </p>
            <Link to="/feed" className="block bg-blue text-white px-4 mt-6 leading-10 rounded">
              Back to your feed
            </Link>
          </>
        );
    }
  }, [status]);

  return (
    <MainOutletContent>
      <div className="flex flex-col items-center justify-center flex-1 max-w-md mx-auto">
        <Content />
      </div>
    </MainOutletContent>
  );
};
