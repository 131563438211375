import { useMemo } from 'react';
import { amountFormatter } from 'views/Checkout/SubscriptionCheckout/formatters';
import { isPaymentIntentPayment, Payment } from 'views/Checkout/SubscriptionCheckout/payment';

export const useAmountFormatter = (payment: Payment | null): string => {
  return useMemo((): string => {
    if (!payment) {
      return '';
    }

    if (isPaymentIntentPayment(payment)) {
      return amountFormatter.format(payment.amount / 100);
    } else {
      return amountFormatter.format(0);
    }
  }, [payment]);
};
