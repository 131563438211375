import React, { useEffect, useRef } from 'react';

interface Props<T> {
  readonly value: T;
  readonly label: string;
  readonly highlighted?: boolean;

  onClick(): void;
}

function DropdownOption<T extends string | number>(
  {
    label,
    highlighted = false,
    onClick,
  }: React.PropsWithoutRef<Props<T>> & React.RefAttributes<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement> | React.RefCallback<HTMLDivElement>,
): React.ReactElement {
  const localRef = useRef<HTMLDivElement>(null);
  const baseClass =
    'font-poppins text-base text-gray px-3 py-4 cursor-default hover:bg-gray-100 leading-none';

  useEffect((): void => {
    const { current } = localRef;
    if (!highlighted) {
      return;
    }

    current?.scrollIntoView({ block: 'center' });
  }, [highlighted, localRef]);

  return (
    <div ref={localRef}>
      <div
        ref={ref}
        className={`${baseClass}${highlighted ? ' bg-gray-100' : ''}`}
        onClick={onClick}
      >
        {label}
      </div>
    </div>
  );
}

export default React.forwardRef(DropdownOption);
