// Library
import { Breadcrumbs } from 'components/breadcrumbs';
import { KeywordAndDateRangeFilter } from 'components/keywordAndDateRangeFilter';
import { MainOutletContent } from 'components/mainOutletContent';
import { Modal } from 'components/modal';
import NotificationsView from 'components/notificationsView';
import NotificationDetails from 'components/notificationsView/notificationDetails';
import { useKeywordAndDateRangeFilter } from 'hooks/useKeywordAndDateRangeFilter';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadingNotificationsSelector,
  notificationsSelector,
} from 'redux/reducers/notificationsReducer';
import { userNotificationsSelector } from 'redux/reducers/sharedReducer';
import {
  getNotificationsService,
  markAllNotificationsAsSeenAction,
} from 'redux/services/notificationsService';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import columns from 'views/SmartTools/Notifications/columns';

const Notifications: React.FC = () => {
  const { notificationId = null } = useParams<{ notificationId: string }>();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const notificationsPage = useSelector(notificationsSelector);
  const loading = useSelector(loadingNotificationsSelector);
  const queryParameters = useQueryParameters();
  const userNotifications = useSelector(userNotificationsSelector);

  const [keyword, , , searching] = useKeywordAndDateRangeFilter(null);

  useLayoutEffect((): void => {
    dispatch(getNotificationsService(queryParameters));
  }, [dispatch, queryParameters]);

  const handleMarkAllAsRead = useCallback((): void => {
    dispatch(markAllNotificationsAsSeenAction());
  }, [dispatch]);

  const handleModalClose = useCallback((): void => {
    navigate('.');
  }, [navigate]);

  return (
    <MainOutletContent>
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <Breadcrumbs path={['SMART TOOLS', 'ALERTS & NOTIFICATIONS']} />
          <TextLine content={description} />
        </div>
        {!process.env.REACT_APP_DATA_PORTAL && (
          <button className={buttonClass} onClick={handleMarkAllAsRead}>
            {userNotifications.total_count === 0 ? null : (
              <span className="text-xs font-poppinsSemiBold bg-white text-blue px-1 rounded-full mr-2">
                {userNotifications.total_count}
              </span>
            )}
            Mark all as read
          </button>
        )}
      </div>

      <div className="flex flex-col flex-1 relative">
        <KeywordAndDateRangeFilter keyword={keyword} searching={searching} />

        <NotificationsView
          notificationsPage={notificationsPage}
          columns={columns}
          loading={loading}
          rootPath="/notifications"
        />
      </div>

      <Modal isOpen={notificationId !== null} onClose={handleModalClose}>
        <Modal.Content>
          <NotificationDetails id={notificationId} />
        </Modal.Content>
      </Modal>
    </MainOutletContent>
  );
};

export default Notifications;

const description =
  'This is a dynamic list of items for your attention. Use the filters and sort the table to find specific items.';
const buttonClass =
  'font-poppinsMedium text-sm text-white bg-blue rounded py-1 px-4 transition border border-blue ' +
  'hover:text-blue hover:bg-white disabled:pointer-events-none disabled:opacity-20';
