import { detectOverflow, Modifier, ModifierArguments } from '@popperjs/core';
import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';

export interface ModifierOptions {
  readonly withSameWidth?: boolean;
  readonly offset?: [number, number] | OffsetsFunction;
  readonly flip?: boolean;
  readonly arrow?: HTMLElement | null;
  readonly preventOverflow?: boolean;
  readonly placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
}

export const sameWidth: Modifier<any, any> = {
  name: 'sameWidth',
  enabled: true,
  fn: ({ state }: ModifierArguments<any>) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  effect: ({ state }: ModifierArguments<any>) => {
    const boundingRect = state.elements.reference.getBoundingClientRect();
    state.elements.popper.style.width = `${boundingRect.width}px`;
  },
};

export const maxHeight = (boundary: HTMLElement | null): Modifier<any, any> => ({
  name: 'maxHeight',
  enabled: true,
  phase: 'beforeWrite',
  fn({ state }: ModifierArguments<any>) {
    if (boundary === null) {
      return;
    }

    const overflow = state
      ? detectOverflow(state, {
          padding: 8,
          boundary: boundary ?? undefined,
        })
      : { bottom: -1, top: 0 };

    if (!overflow) {
      return;
    }
    // This seems to ALWAYS be correct
    if (overflow.bottom > 0) {
      const { popper: popperStyle } = state.styles;
      const maxHeight = boundary.offsetHeight + overflow.top - 16;

      popperStyle.maxHeight = `${maxHeight}px`;
    }
  },
});

export const defaultOptions = (boundary: HTMLElement | null, options?: ModifierOptions) => {
  return {
    placement: options?.placement ?? 'bottom-start',
    modifiers: [
      maxHeight(boundary),
      { ...sameWidth, enabled: !!options?.withSameWidth },
      {
        name: 'flip',
        enabled: !!options?.flip,
      },
      {
        name: 'offset',
        options: {
          offset: options?.offset,
        },
      },
      {
        name: 'preventOverflow',
        enabled: !!options?.preventOverflow,
        options: {
          boundary: boundary,
          padding: 8,
        },
      },
      {
        name: 'arrow',
        options: {
          element: options?.arrow,
        },
      },
    ],
  };
};
