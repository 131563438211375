import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { useFilters } from 'hooks/useFilters';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { industryDetailsSelector } from 'redux/reducers/industryReducer';
import { industryKeyFactorsSelector, KeyFactorType } from 'redux/reducers/keyFactorsReducer';
import { getIndustryFactorsService } from 'redux/services/keyFactorsService';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { createFiltersConfig } from 'views/DataAnalysis/Company/Details/KeyFactors/filters';
import { KeyFactorsSection } from 'views/DataAnalysis/Company/Details/KeyFactors/section';

const KeyFactors: React.FC = (): React.ReactElement => {
  const { pathname } = useLocation();
  const factorsData = useSelector(industryKeyFactorsSelector);

  const factorType = useMemo(
    (): KeyFactorType => (pathname.endsWith('risk') ? 'risk' : 'growth'),
    [pathname],
  );

  const filtersConfig = useMemo(
    (): readonly CommonFilter[] => createFiltersConfig(factorType, factorsData),
    [factorType, factorsData],
  );

  const handleFiltersChange = useFilters(filtersConfig);

  return (
    <>
      <div className="flex items-center justify-between my-3">
        <Filters config={filtersConfig} onChange={handleFiltersChange} />
      </div>
      <KeyFactorsSection
        dataSelector={industryKeyFactorsSelector}
        detailsSelector={industryDetailsSelector}
        service={getIndustryFactorsService}
        paramsKey={'industryId'}
      />
      <DataDisclaimer />
    </>
  );
};

export default KeyFactors;
