import { AxiosResponse } from 'axios';
// import { AccountTypeInfo } from '../../../views/SignUp';
import Button from 'components/buttons/Button/Button';
import { CheckboxGroup } from 'components/checkboxGroup';
import { ConditionalRender } from 'components/conditionalRenderer';
import Input from 'components/input/Input2';
// UTILS
import TextAreaWithTags from 'components/input/TextAreaWithTags';
// GENERAL COMPONENT
import { TagInput } from 'components/tagInput';
import Toggle from 'components/toggle/Toggle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingReferenceDataSelector } from 'redux/reducers/onboardingReducer';
import {
  profilePersonalInformationModalStateSelector,
  setEditPersonalInformationModalAdditionalTopicsOfInterest,
  setEditPersonalInformationModalDeletedTopicsOfInterest,
  setEditPersonalInformationModalRegionsOfInterest,
  setEditPersonalInformationModalTopicsOfInterest,
  updatePersonalInformationForm,
} from 'redux/reducers/profileReducer';
import { loadOnboardingReferenceData } from 'redux/services/onboardingServices';
import {
  getMyProfileService,
  getUsersRegionsOfInterest,
  getUsersTopicsOfInterest,
  submitPersonalInformationForm,
} from 'redux/services/profileService';
import { AccountType } from 'types/accountType';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { FormErrors, ValidationErrors } from 'utils/forms';
import { fullNameSchema } from 'validators/userProfile';
import { connectionToFinanceOptions } from 'views/Landing/Onboarding/AboutForm';
import { AutocompleteInput } from 'views/Landing/Onboarding/components/AutocompleteInput';
import {
  ACCREDITED_INVESTOR_URL,
  ActivableComboBoxItem,
  ComboBoxItem,
} from 'views/Landing/Onboarding/components/helpers';
import { Select, SelectOption } from 'views/Landing/Onboarding/components/Select';
import { AccountTypeSelector } from 'views/Landing/SignUp/components/accountTypeSelector';
import * as yup from 'yup';

interface Props {
  onClose(): void;
}

const EditProfileModal: React.FC<Props> = ({ onClose }: Props): React.ReactElement => {
  const state = useSelector(profilePersonalInformationModalStateSelector);

  const [errors, setErrors] = useState<FormErrors>({});
  const [states, setStates] = useState<ReadonlyArray<SelectOption<number>>>([]);
  const referenceData = useSelector(onboardingReferenceDataSelector);
  const { topicsOfInterest, regionsOfInterest, countries } = referenceData;

  const dispatch = useDispatch<any>();

  const {
    accountType,
    firstName,
    lastName,
    about,
    linkedinUrl,
    websiteUrl,
    accreditedInvestor,
    connectionToFinance,
    country,
    city,
    adminZone,
  } = state;

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { target } = event;
      dispatch(updatePersonalInformationForm({ [target.name]: target.value }));
    },
    [dispatch],
  );

  useEffect((): void => {
    dispatch(loadOnboardingReferenceData());
    dispatch(getMyProfileService());
    dispatch(getUsersTopicsOfInterest());
    dispatch(getUsersRegionsOfInterest());
  }, [dispatch]);

  const updateAccountType = useCallback(
    (type: AccountType): void => {
      dispatch(updatePersonalInformationForm({ accountType: type }));
    },
    [dispatch],
  );

  const onAccreditedInvestorChange = useCallback((): void => {
    dispatch(updatePersonalInformationForm({ accreditedInvestor: !accreditedInvestor }));
  }, [accreditedInvestor, dispatch]);

  const onDropdownChange = useCallback(
    (connectionToFinance: string): void => {
      dispatch(updatePersonalInformationForm({ connectionToFinance }));
    },
    [dispatch],
  );

  const onAdminZoneChange = useCallback(
    (adminZone: number): void => {
      dispatch(updatePersonalInformationForm({ adminZone }));
    },
    [dispatch],
  );

  const onCountryChange = useCallback(
    (country: number): void => {
      dispatch(updatePersonalInformationForm({ country }));
    },
    [dispatch],
  );

  const onCityChange = useCallback(
    (city: string): void => {
      dispatch(updatePersonalInformationForm({ city }));
    },
    [dispatch],
  );

  const onCityAutocomplete = useCallback(
    async (value: string): Promise<readonly ComboBoxItem[]> => {
      if (country === null) {
        return [];
      }

      const trimmedValue = value.trim();
      if (trimmedValue.length > 0) {
        const url = `${API_V1_PATH}/reference-data/countries/${country}/cities`;

        const response: AxiosResponse = await api.get(url, {
          params: { keyword: trimmedValue },
        });
        if (response.status !== 200) {
          return [];
        }

        return response.data;
      } else {
        return [];
      }
    },
    [country],
  );

  const disabledSubmitButton = useMemo(
    (): boolean => firstName === '' || lastName === '' || country === null,
    [country, firstName, lastName],
  );

  const onSubmit = useCallback(async (): Promise<void> => {
    try {
      await schema.validate({ firstName, lastName }, { abortEarly: false });

      dispatch(submitPersonalInformationForm(state));
      setErrors({});
      onClose();
    } catch (error) {
      const { inner } = error as yup.ValidationError;
      setErrors(ValidationErrors.reduce(inner));
    }
  }, [firstName, lastName, dispatch, state, onClose]);

  const onTopicsOfInterestChange = useCallback(
    (values: number[]): void => {
      dispatch(setEditPersonalInformationModalTopicsOfInterest(values));
    },
    [dispatch],
  );

  const onRegionsOfInterestChange = useCallback(
    (values: number[]): void => {
      dispatch(setEditPersonalInformationModalRegionsOfInterest(values));
    },
    [dispatch],
  );

  const onAdditionalTopicsOfInterestChange = useCallback(
    (values: string[]): void => {
      dispatch(setEditPersonalInformationModalAdditionalTopicsOfInterest([...values]));
    },
    [dispatch],
  );

  const onDeletedTopicsOfInterestChange = useCallback(
    (values: Array<ActivableComboBoxItem<number>>): void => {
      dispatch(setEditPersonalInformationModalDeletedTopicsOfInterest(values));
    },
    [dispatch],
  );

  useEffect((): void => {
    setStates([]);

    api
      .get(`${API_V1_PATH}/reference-data/countries/${country}/admin-zones`)
      .then((response: AxiosResponse): void => {
        if (response.status !== 200) {
          return;
        }

        const { data } = response;

        setStates(
          data.map(
            (item: { readonly id: string; readonly name: string }): SelectOption<string> => ({
              label: item.name,
              value: item.id,
            }),
          ),
        );
      })
      .catch(console.warn);
  }, [country]);

  return (
    <div className="w-full md:w-modal-sm">
      <div className="my-8 px-3">
        <AccountTypeSelector value={accountType} onChange={updateAccountType} />
      </div>
      <div className="my-8 relative">
        <TextAreaWithTags
          name="about"
          rows={3}
          maxLength={300}
          placeholder="About Me"
          className="px-3"
          innerClassName="resize-none bg-gray-light"
          showRemainingChars
          value={about ?? ''}
          onChange={onChange}
        />
      </div>
      <div className="my-8 relative">
        <Input
          name="firstName"
          inputClassName="bg-gray-light"
          className="px-3 py-1"
          value={firstName}
          onChange={onChange}
          placeholder="First Name (required)"
          error={errors.firstName}
        />
      </div>
      <div className="my-8 relative">
        <Input
          name="lastName"
          inputClassName="bg-gray-light"
          className="px-3 py-1"
          value={lastName}
          onChange={onChange}
          placeholder="Last Name (required)"
          error={errors.lastName}
        />
      </div>
      <div className="my-8 relative">
        <Select
          id="country"
          value={country}
          label="Country"
          options={countries}
          required={true}
          onChange={onCountryChange}
        />
      </div>
      <ConditionalRender renderIf={states.length > 0}>
        <div className="my-8 relative">
          <Select
            id="state"
            value={adminZone}
            label="State"
            options={states}
            onChange={onAdminZoneChange}
          />
        </div>
      </ConditionalRender>
      <div className="my-8 relative">
        <AutocompleteInput
          id="city"
          name="city"
          value={city}
          label="City"
          placeholder="City"
          onChange={onCityChange}
          onEdit={onCityAutocomplete}
        />
      </div>
      <div className="my-8 relative">
        <Select
          id="connection-finance"
          value={connectionToFinance ?? ''}
          options={connectionToFinanceOptions}
          label="Connection to Finance"
          onChange={onDropdownChange}
        />
      </div>
      <div className="my-5 relative px-3">
        <Toggle
          id="accredited-investor"
          toggleId="accredited-investor-toggle"
          checked={accreditedInvestor}
          label={
            accreditedInvestor
              ? "Yes, I'm an Accredited Investor"
              : "No, I'm not an Accredited Investor"
          }
          flat={true}
          fullWidth={true}
          onChange={onAccreditedInvestorChange}
        />
        <p className="font-poppins text-xs text-gray mt-3">
          Click
          <span className="font-poppins text-xs text-blue">
            <a
              href={ACCREDITED_INVESTOR_URL}
              rel="noopener noreferrer"
              target="_blank"
              className="normal-case mx-1"
            >
              here
            </a>
          </span>
          for more details
        </p>
      </div>
      <div className="my-8 relative">
        <Input
          name="linkedinUrl"
          inputClassName="bg-gray-light text-blue"
          className="px-3 py-1"
          value={linkedinUrl ?? ''}
          onChange={onChange}
          placeholder="LinkedIn URL"
        />
      </div>
      <div className="my-8 relative">
        <Input
          name="websiteUrl"
          inputClassName="bg-gray-light text-blue"
          className="px-3 py-1"
          value={websiteUrl ?? ''}
          onChange={onChange}
          placeholder="Website URL"
        />
      </div>
      <div className="edit-profile-modal__input-element">
        <h3 className="text-base mb-4">Topics of Interest</h3>
        <CheckboxGroup
          options={topicsOfInterest}
          selectedOptions={state.topicsOfInterest}
          deletedOptions={state.deletedTopicsOfInterest}
          onChange={onTopicsOfInterestChange}
          onDeletedChange={onDeletedTopicsOfInterestChange}
        />
        <TagInput
          id="additional-topics-of-interest"
          items={state.additionalTopicsOfInterest}
          name="additional-topics-of-interest"
          placeholder="Other Interests"
          onChange={onAdditionalTopicsOfInterestChange}
        />
      </div>
      <div className="edit-profile-modal__input-element">
        <h3 className="text-base mb-4">Regions of Interest</h3>
        <CheckboxGroup
          options={regionsOfInterest}
          selectedOptions={state.regionsOfInterest}
          onChange={onRegionsOfInterestChange}
        />
      </div>

      <div className="w-full flex justify-end">
        <Button
          id="submit-button"
          label="Submit"
          width="w-36"
          onClick={onSubmit}
          cursor={disabledSubmitButton ? 'cursor-default' : 'cursor-pointer'}
          disabled={disabledSubmitButton}
        />
      </div>
    </div>
  );
};

export default EditProfileModal;

const schema = yup.object().shape({
  ...fullNameSchema,
});
