import SVGIcon from 'components/icons/SVGIcon';
import React, { useMemo } from 'react';
import { getPeriodLabel, StatementPeriod } from 'types/financial';

export type SelectedPeriodsMap = { [key: string]: boolean };

interface Props {
  readonly periods: StatementPeriod[];
  readonly previousPeriod: StatementPeriod | null;
  readonly nextPeriod: StatementPeriod | null;

  onChange(periodsIndex: StatementPeriod): void;
}

const StatementTableHeader: React.FC<Props> = ({
  periods,
  previousPeriod,
  nextPeriod,
  onChange,
}) => {
  const prevDisabled = useMemo((): boolean => previousPeriod === null, [previousPeriod]);
  const nextDisabled = useMemo((): boolean => nextPeriod === null, [nextPeriod]);

  const handlePrevious = (): void => {
    if (previousPeriod !== null) {
      onChange(previousPeriod);
    }
  };

  const handleNext = (): void => {
    if (nextPeriod !== null) {
      onChange(nextPeriod);
    }
  };

  return (
    <div className="flex items-center w-full">
      <button
        id="previous-period"
        disabled={prevDisabled}
        onClick={handlePrevious}
        className={`h-9 hover:bg-gray-light focus:bg-gray-light rounded focus:outline-none px-1 ${
          prevDisabled ? 'opacity-30' : ''
        }`}
      >
        <SVGIcon name="previous-icon" className="w-4 h-4" />
      </button>

      <div
        style={{ gridTemplateColumns: `repeat(${periods.length}, minmax(0, 1fr)` }}
        className="grid grid-cols-5 bg-blue-light border border-blue rounded-sm h-9 items-center text-center flex-grow"
      >
        {periods.map(
          (period: StatementPeriod): React.ReactElement => (
            <div
              id={period.access_key}
              key={period.access_key}
              className="font-jostSemiBold text-gray-darkest text-lg"
            >
              {getPeriodLabel(period)}
            </div>
          ),
        )}
      </div>
      <button
        id="next-period"
        disabled={nextDisabled}
        onClick={handleNext}
        className={`h-9 hover:bg-gray-light focus:bg-gray-light rounded focus:outline-none px-1 ${
          nextDisabled ? 'opacity-30' : ''
        }`}
      >
        <SVGIcon name="next-icon" className="w-4 h-4" />
      </button>
    </div>
  );
};

export default StatementTableHeader;
