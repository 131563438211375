import { QueryStatus } from '@reduxjs/toolkit/query';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ConfirmationBox } from 'components/confirmationBox';
import { ErrorBox } from 'components/errorBox';
import { Modal } from 'components/modal';
import { SuccessBox } from 'components/successBox';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useRemoveMarketplaceItemMutation,
  useUpdateMarketplaceItemMutation,
} from 'views/EarnAndBuy/api';
import { UpdateMarketplaceItemPayload } from 'views/EarnAndBuy/Buy/modals/addListingReducer';
import { ListingFormModal } from 'views/EarnAndBuy/Buy/modals/listingFormModal';

export const MarketplaceItemContext: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren): React.ReactElement => {
  const navigate = useNavigate();

  const [update, updateMutation] = useUpdateMarketplaceItemMutation();
  const [remove, removeMutation] = useRemoveMarketplaceItemMutation();

  const { action, listingId } = useParams<{
    readonly action: string;
    readonly listingId: string;
  }>();

  const goBack = React.useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const editing = useMemo((): boolean => action === 'edit', [action]);

  const handleSubmit = useCallback(
    async (payload: UpdateMarketplaceItemPayload): Promise<void> => {
      update(payload);
    },
    [update],
  );

  const removing = useMemo((): boolean => action === 'remove', [action]);

  const handleUpdateCompleted = useCallback((): void => {
    updateMutation.reset();
  }, [updateMutation]);

  const handleRemoveCompleted = useCallback((): void => {
    removeMutation.reset();
  }, [removeMutation]);

  const handleRemoveConfirmed = useCallback((): void => {
    if (!listingId) {
      throw new Error('listing id cannot be null at this point');
    }

    remove(listingId);
    navigate(-1);
  }, [listingId, navigate, remove]);

  const removeSucceeded = useMemo(
    (): boolean => removeMutation.status === QueryStatus.fulfilled,
    [removeMutation.status],
  );

  const updateSucceeded = useMemo(
    (): boolean => updateMutation.status === QueryStatus.fulfilled,
    [updateMutation.status],
  );

  const removeFailed = useMemo(
    (): boolean => removeMutation.status === QueryStatus.rejected,
    [removeMutation.status],
  );

  const updateFailed = useMemo(
    (): boolean => updateMutation.status === QueryStatus.rejected,
    [updateMutation.status],
  );

  useEffect((): void => {
    if (editing && updateMutation.status === QueryStatus.fulfilled) {
      navigate(-1);
    }
  }, [editing, navigate, updateMutation.status]);

  return (
    <>
      <>{children}</>

      <ConditionalRender renderIf={editing}>
        <ListingFormModal
          listingId={listingId}
          open={true}
          busy={updateMutation.status === QueryStatus.pending}
          onSubmit={handleSubmit}
          onClose={goBack}
        />
      </ConditionalRender>

      <Modal isOpen={updateSucceeded} onClose={handleUpdateCompleted}>
        <SuccessBox
          title="Item Updated"
          message="Your item was updated successfully"
          onClose={handleUpdateCompleted}
        />
      </Modal>

      <Modal isOpen={updateFailed} onClose={handleUpdateCompleted}>
        <ErrorBox
          title="Failed to Update Item"
          message="Your item was not updated, an error happened. Please try again later."
          onClose={handleUpdateCompleted}
        />
      </Modal>

      <Modal isOpen={removeSucceeded} onClose={handleRemoveCompleted}>
        <SuccessBox
          title="Item Removed"
          message="Your item was removed successfully"
          onClose={handleRemoveCompleted}
        />
      </Modal>

      <Modal isOpen={removeFailed} onClose={handleRemoveCompleted}>
        <ErrorBox
          title="An error occurred"
          message="Your item was not removed, an error happened. Please try again later."
          onClose={handleRemoveCompleted}
        />
      </Modal>

      <Modal isOpen={removing} onClose={goBack}>
        <Modal.Content title="Remove Listing">
          <ConfirmationBox
            message="Removing this listing is irreversible. Are you sure?"
            danger={true}
            onYes={handleRemoveConfirmed}
            onNo={goBack}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
