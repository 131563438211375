import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import { capitalize } from 'lodash';
import React from 'react';
import { KeyFactor, KeyFactorType } from 'redux/reducers/keyFactorsReducer';
import { LevelCell } from 'views/DataAnalysis/common/KeyFactors/levelCell';

export const createColumns = (
  factorType: KeyFactorType,
): ReadonlyArray<ColumnDefinition<KeyFactor>> => [
  {
    type: ColumnType.text,
    name: 'name',
    label: `${capitalize(factorType)} Factor`,
    alignment: Alignment.left,
    weight: 3,
    sortable: true,
  },
  {
    type: ColumnType.custom,
    name: 'level',
    label: `${capitalize(factorType)} Level`,
    headerAlignment: Alignment.left,
    render: (value: KeyFactor['probability']): React.ReactNode => {
      return <LevelCell factorType={factorType} value={value} type="level" />;
    },
    weight: 1,
    sortable: true,
  },
  {
    type: ColumnType.text,
    name: 'summary',
    label: 'Summary',
    alignment: Alignment.left,
    weight: 5,
  },
];
