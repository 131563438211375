// Libraries
import MoreIcon from 'assets/icons/svg/more-icon.svg';
import { Modal } from 'components/modal';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Assets
// Redux services
import {
  dislikeNewsService,
  dislikePostService,
  likeNewsService,
  likePostService,
} from 'redux/services/feedService';
import { Title } from 'views/Home/components/FeedCard/components/title';
import { FeedItemInteractionButtons } from 'views/Home/components/shared/feedItemInteractionButtons';
import { PostBodyView } from 'views/Home/components/shared/postBodyView';
import { Tags } from 'views/Home/components/shared/tags';
import { EventType, listenTo } from 'views/Home/modals/PostDetailsModal/utils/events';
import { ShareModal } from 'views/Home/modals/Share';
// Components
import { FeedCategory, FeedItem } from 'views/Home/types';

interface Props {
  readonly filterCategory?: string;
  readonly feedItem: FeedItem;

  onTagClick(label: string): void;
  onPostClick(post: FeedItem): void;
}

const FeedCard: React.FC<Props> = ({
  feedItem,
  onTagClick,
  onPostClick,
}: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const shareModal = useModal();
  const [commentsCount, setCommentsCount] = useState<number>(feedItem.commentsCount);

  const handleShowDetails = useCallback((): void => {
    onPostClick(feedItem);
  }, [feedItem, onPostClick]);

  const handleLiked = useCallback((): void => {
    if (feedItem.category === FeedCategory.post) {
      dispatch(likePostService(feedItem.id));
    } else if (feedItem.category === FeedCategory.news) {
      dispatch(likeNewsService(feedItem.id));
    }
  }, [dispatch, feedItem.category, feedItem.id]);

  const handleDisliked = useCallback((): void => {
    if (feedItem.category === FeedCategory.post) {
      dispatch(dislikePostService(feedItem.id));
    } else if (feedItem.category === FeedCategory.news) {
      dispatch(dislikeNewsService(feedItem.id));
    }
  }, [dispatch, feedItem.category, feedItem.id]);

  useEffect((): void => {
    setCommentsCount(feedItem.commentsCount);
  }, [feedItem]);

  useEffect((): VoidFunction => {
    const handleRemoved = (event: Event): void => {
      const customEvent = event as CustomEvent<number>;
      setCommentsCount((count: number): number => count - customEvent.detail);
    };

    return listenTo(feedItem.id, null, EventType.removed, handleRemoved);
  });

  useEffect((): VoidFunction => {
    const handleAdded = (): void => {
      setCommentsCount((count: number): number => count + 1);
    };

    return listenTo(feedItem.id, null, EventType.added, handleAdded);
  });

  return (
    <>
      <div
        className="w-full md:hover:bg-blue-light h-60 relative border-b border-gray-light last:border-0"
        tabIndex={-1}
      >
        <div className="flex flex-col flex-1 justify-between lg:px-6 px-4 lg:py-4 md:py-4 py-3 h-full w-full">
          <Title feedItem={feedItem} />
          <Tags
            feedItem={feedItem}
            expanded={false}
            onClick={onTagClick}
            onExpand={handleShowDetails}
          />
          <PostBodyView feedItem={feedItem} clamp={true} onTagClick={onTagClick} />
          {process.env.REACT_APP_DATA_PORTAL ? (
            <div className="flex items-center mt-2 gap-12">
              <div
                className="flex items-center text-blue cursor-pointer"
                onClick={handleShowDetails}
              >
                <p className="font-poppinsMedium">Read More</p>
                <img src={MoreIcon} className="w-3 h-3 ml-3" alt="more" />
              </div>
            </div>
          ) : (
            <div className="flex items-center mt-2 gap-12">
              <FeedItemInteractionButtons
                likesCount={feedItem.likesCount}
                liked={feedItem.liked}
                dislikesCount={feedItem.dislikesCount}
                disliked={feedItem.disliked}
                commentsCount={commentsCount}
                commented={commentsCount > 0}
                onLiked={handleLiked}
                onDisliked={handleDisliked}
                onComment={handleShowDetails}
                onShare={shareModal.open}
              />
              <div
                className="flex items-center text-blue cursor-pointer"
                onClick={handleShowDetails}
              >
                <p className="font-poppinsMedium">Read More</p>
                <img src={MoreIcon} className="w-3 h-3 ml-3" alt="more" />
              </div>
            </div>
          )}
        </div>
      </div>

      {process.env.REACT_APP_DATA_PORTAL ? null : (
        <Modal isOpen={shareModal.isOpen} onClose={shareModal.close}>
          <Modal.Content closeable={true}>
            <ShareModal feedItem={feedItem} onClose={shareModal.close} />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default FeedCard;
