import { getNotificationIcon } from 'components/notificationsView/helpers';
import React from 'react';
import { NotificationType } from 'redux/reducers/sharedReducer';

interface Props {
  readonly notificationType: NotificationType;
  readonly source: string;
}

export const Icon: React.FC<Props> = ({ source, notificationType }: Props): React.ReactElement => {
  const IconComponent = getNotificationIcon(source, notificationType);

  return (
    <div className="rounded w-10 h-10 mr-2 flex items-center justify-center text-gray-light">
      <IconComponent className="w-10 h-10 fill-current" />
    </div>
  );
};
