import { StarRating } from 'components/starRating';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import { defaultLocale } from 'constants/defaultLocale';
import React from 'react';
import { ItemRating, ListingOwner, MarketplaceItem } from 'views/EarnAndBuy/api';
import { Actions } from 'views/EarnAndBuy/Buy/components/actions';
import { ListingOwnerLink } from 'views/EarnAndBuy/Earn/components/listingOwnerLink';

const columns: ReadonlyArray<ColumnDefinition<MarketplaceItem>> = [
  {
    type: ColumnType.date,
    label: 'Date Posted',
    name: 'posted_at',
    alignment: Alignment.center,
    sortable: true,
  },

  {
    type: ColumnType.text,
    label: 'Title',
    name: 'title',
    alignment: Alignment.left,
    weight: 2,
  },
  {
    type: ColumnType.custom,
    label: 'Price',
    name: 'price_amount',
    sortable: true,
    render: (value: number, item: MarketplaceItem): React.ReactNode => {
      const formatter = Intl.NumberFormat(defaultLocale, {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: item.price_currency,
      });

      return <p className="text-center">{formatter.format(value)}</p>;
    },
  },
  {
    type: ColumnType.custom,
    label: 'Rating',
    name: 'star_rating',
    sortable: true,
    headerAlignment: Alignment.center,
    weight: 2,
    render: (rating: ItemRating): React.ReactNode => {
      return <StarRating value={rating.average} />;
    },
  },
  {
    type: ColumnType.custom,
    label: 'Listing Owner',
    name: 'listing_owner',
    headerAlignment: Alignment.center,
    sortable: true,
    width: 300,
    render: (owner: ListingOwner): React.ReactNode => {
      return <ListingOwnerLink owner={owner} />;
    },
  },
  {
    type: ColumnType.custom,
    label: '',
    name: 'id',
    headerAlignment: Alignment.center,
    render: (_: string, item: MarketplaceItem): React.ReactNode => {
      return <Actions key={item.id} item={item} />;
    },
    width: 360,
  },
];

export default columns;
