import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

interface Props {
  readonly label: string | React.ReactElement;
  readonly readOnly?: boolean;

  onClick?(): void;
  onFocus?(event: React.FocusEvent<HTMLButtonElement>): void;
  onBlur?(): void;
}

export const DefaultButton: React.FC<React.PropsWithRef<Props>> = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithRef<Props>
>(function DefaultButton(
  { label, readOnly = false, onClick, onFocus, onBlur }: Props,
  ref: React.ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  return (
    <button
      ref={ref}
      type="button"
      className={buttonClassName}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {typeof label === 'string' ? <p className="truncate overflow-ellipsis">{label}</p> : label}
      {!readOnly && (
        <SVGIcon name="down-arrow" className="fill-current focus:outline-none w-3 h-3" />
      )}
    </button>
  );
});

const buttonClassName =
  'px-2 w-full leading-10 py-1 group rounded-md items-center focus:outline-none flex justify-between text-sm';
