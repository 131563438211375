import { AnyAction } from '@reduxjs/toolkit';
import { defaultLocale } from 'constants/defaultLocale';
import {
  AlgoNote,
  fetchAlgoNotesFailed,
  fetchAlgoNotesStarted,
  fetchAlgoNotesSucceeded,
} from 'redux/reducers/algoNotesReducer';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { Page } from 'types/page';
import settings from 'utils/config/appSettings';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export const fetchAlgoNotesAction = createAPIAction(function fetchAlgoNotes(
  client: HttpClient,
): ActionsGenerator {
  return async function* (
    companyId: string,
    filters: Record<string, string>,
  ): AsyncGenerator<AnyAction> {
    yield fetchAlgoNotesStarted();

    try {
      const response = await client.GET(`${API_V1_PATH}/algo-notes/companies/${companyId}`, {
        page_number: 1,
        page_size: settings.defaultPageSize,
        ...filters,
      });
      if (response.status === 200) {
        const page = response.data as Page<Exclude<AlgoNote, 'companyId'>>;
        yield fetchAlgoNotesSucceeded(
          Page.map(page, (note: Exclude<AlgoNote, 'companyId'>): AlgoNote => {
            return {
              ...note,
              companyId: companyId,
              date: new Date(note.date).toLocaleString(defaultLocale, {
                day: '2-digit',
                month: 'short',
                year: '2-digit',
              }),
            };
          }),
        );
      }
    } catch (error: any) {
      yield fetchAlgoNotesFailed(error);
    }
  };
});
