import { VirtualElement } from '@popperjs/core';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { stopPropagation } from 'utils/ignoreEvent';
import { defaultOptions } from 'utils/popper';

interface Props {
  readonly isOpen: boolean;
  readonly anchor: Element | VirtualElement | null;

  /*
   * @deprecated Should add padding to the content instead
   */
  readonly padded?: boolean;

  onClose?(): void;
}

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  anchor,
  padded = true,
  isOpen,
  onClose,
}: React.PropsWithChildren<Props>): React.ReactElement | null => {
  const [popup, setPopup] = useState<HTMLElement | null>(null);
  const [boundary, setBoundary] = useState<HTMLElement | null>(null);

  const popperOptions = useMemo((): any => defaultOptions(boundary), [boundary]);
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void => {
    update?.();
  }, [update, children]);

  const popupClassName = useMemo((): string => {
    if (padded) {
      return 'bg-gray-light p-4 rounded-5 shadow-lg w-80 overflow-y-auto scroller';
    } else {
      return 'bg-gray-light rounded-5 shadow-lg w-80 overflow-y-auto scroller';
    }
  }, [padded]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={setBoundary}
      className="fixed inset-0 bg-transparent cursor-pointer z-1"
      onClick={onClose}
    >
      <div
        ref={setPopup}
        className={popupClassName}
        {...attributes.popper}
        style={styles.popper}
        onClick={stopPropagation}
      >
        {children}
      </div>
    </div>
  );
};
