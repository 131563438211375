// Libraries
import React from 'react';
// Assets
// Redux Services
// Components
// Utils
import { PostDetailsModalContent } from 'views/Home/modals/PostDetailsModal/components/postDetailsModalContent';

interface Props {
  readonly feedItemId: string | null;
  onClose(): void;
}

const PostDetailsModal: React.FC<Props> = ({ feedItemId, onClose }): React.ReactElement | null => {
  if (feedItemId === null) {
    return null;
  } else {
    return <PostDetailsModalContent feedItemId={feedItemId} onClose={onClose} />;
  }
};

export default PostDetailsModal;
