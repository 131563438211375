import SearchIcon from 'assets/icons/svg/search-icon.svg';
import Tooltip from 'components/DEPRECATED/tooltips/tooltip';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useEffect, useState } from 'react';

interface Props {
  name: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value: string | number;
  required?: boolean;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  error?: any;
  className?: string;
  autoComplete?: string;
  isLogin?: boolean;
  showLabel?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  textColor?: string;
  errorClassName?: string;
  disabled?: boolean;
  label?: string;
  searchIcon?: boolean;
  autoFocus?: boolean;
}

/**
 * @deprecated As bad as the bad one
 */

const Input: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  name,
  children,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  value,
  required = false,
  placeholder = '',
  type = 'text',
  maxLength,
  error = null,
  className = '',
  autoComplete = 'on',
  isLogin = false,
  showLabel = true,
  labelClassName = 'text-gray -top-1 left-3',
  inputClassName = '',
  textColor = 'text-gray',
  errorClassName = 'transform translate-y-3 -right-3 w-max',
  disabled = false,
  label = '',
  searchIcon = false,
  autoFocus = false,
}: React.PropsWithChildren<Props>) => {
  const [togglePassword, setTogglePassword] = useState(true);
  const [inputType, setInputType] = useState(type);
  const [isLoginError, setIsLoginError] = useState(false);

  const onClick = () => {
    setTogglePassword(!togglePassword);
    setInputType(togglePassword ? 'text' : 'password');
  };

  useEffect(() => {
    if (isLogin) {
      setIsLoginError(true);
    }
  }, [error, isLogin]);

  const inputClass = `font-poppins text-base leading-8 w-full font-normal outline-none border-none
    ${disabled ? 'text-gray-medium' : ''} ${inputClassName} ${textColor}`;

  return (
    <div
      className={`flex flex-row w-full items-center relative border-b-2 ${
        error ? 'border-red' : 'border-gray-300'
      } ${className}`}
    >
      {required && (
        <div className="absolute">
          <span className="text-gray font-poppins">*</span>
        </div>
      )}
      {searchIcon && (
        <div className="absolute">
          <img src={SearchIcon} className="h-3.5" />
        </div>
      )}

      {value !== '' && showLabel ? (
        <span className={`absolute text-xxs font-poppins -top-2 ${labelClassName}`}>
          {label !== '' ? label : placeholder}
        </span>
      ) : null}
      <input
        id={id}
        type={inputType}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        required={required}
        className={inputClass}
        maxLength={maxLength}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onFocus={onFocus}
        disabled={disabled}
      />
      {children}
      <div className="absolute space-x-2 flex right-5/100 items-center w-min">
        {type === 'password' && (
          <SVGIcon
            id="password-icon"
            onClick={onClick}
            name="see-password-icon"
            className="cursor-pointer w-6 h-5"
          />
        )}
        {error && (
          <Tooltip title={error} error errorClassName={errorClassName} isLoginError={isLoginError}>
            <SVGIcon id={`${id}-high-risk-icon`} name="exclamation-circle" className="w-5 h-5" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Input;
