import { Select } from 'components/select';
import React, { useEffect } from 'react';
import {
  CommercialResidenceType,
  PortfolioCommercialRealStateItem,
  RealEstatePurpose,
} from 'types/portfolioItem';
import { enumToSelectItems, isEnumOf } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioCommercialRealStateItem;
}

export const CommercialRealEstateForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioCommercialRealStateItem>();

  useEffect((): void => {
    if (item.purpose !== RealEstatePurpose.commercial) {
      return;
    } else if (!isEnumOf(CommercialResidenceType, item.typeOfResidence)) {
      const updateTypeOfResidence = updaterFor('typeOfResidence');
      updateTypeOfResidence(CommercialResidenceType.officeBuildings);
    }
  }, [item.purpose, item.typeOfResidence, updaterFor]);

  return (
    <>
      <Select
        items={residenceTypes}
        value={item.typeOfResidence}
        label="Select type of commercial real estate"
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onChange={updaterFor('typeOfResidence')}
      />
    </>
  );
};

const residenceTypes = enumToSelectItems(CommercialResidenceType, {
  [CommercialResidenceType.officeBuildings]: 'Office Buildings',
  [CommercialResidenceType.retail]: 'Retail',
  [CommercialResidenceType.logistics]: 'Logistics',
  [CommercialResidenceType.hospitalsOrMedical]: 'Hospital or Medical',
  [CommercialResidenceType.agriculture]: 'Agriculture',
  [CommercialResidenceType.otherSpecializedCommercial]: 'Other Specialized Commercial',
});
