import { Placement } from '@popperjs/core';
import { Rect } from '@popperjs/core/lib/types';
import { HeaderButton } from 'components/header/UserHeader/components/headerButton';
import NotificationsPopup from 'components/header/UserHeader/components/notificationsPopup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { userNotificationsSelector } from 'redux/reducers/sharedReducer';
import { defaultOptions } from 'utils/popper';

interface Props {
  readonly active: boolean;
}

export const NotificationsIcon: React.FC<Props> = ({ active }: Props): React.ReactElement => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        withSameWidth: false,
        placement: 'bottom-start',
        offset: ({
          popper,
        }: {
          popper: Rect;
          reference: Rect;
          placement: Placement;
        }): [number, number] => {
          return [-popper.width + 48, 16];
        },
      }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchorRef.current, popup, popperOptions);

  const userNotifications = useSelector(userNotificationsSelector);
  const { total_count } = userNotifications;

  const notificationsCount = useMemo(
    (): string | undefined =>
      total_count === 0 ? undefined : total_count > 10 ? '10+' : total_count.toString(),
    [total_count],
  );

  const openNotificationsList = useCallback((): void => setIsOpen(true), []);

  const toggleOpen = useCallback((): void => setIsOpen((isOpen: boolean): boolean => !isOpen), []);

  useEffect((): void => {
    update?.();
  }, [update]);

  return (
    <>
      <HeaderButton
        ref={anchorRef}
        onClick={openNotificationsList}
        title="Notifications"
        badge={notificationsCount}
        icon="reminder-icon"
        active={active}
      />
      {isOpen &&
        (isMobile
          ? ReactDOM.createPortal(
              <div className="fixed inset-0 z-1">
                <NotificationsPopup onClose={toggleOpen} />
              </div>,
              document.body,
            )
          : ReactDOM.createPortal(
              <div className="fixed inset-0 z-1" onClick={toggleOpen}>
                <div ref={setPopup} style={styles.popper} {...attributes.popper}>
                  <NotificationsPopup />
                </div>
              </div>,
              document.body,
            ))}
    </>
  );
};
