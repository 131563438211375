import { isAxiosError } from 'utils/config/axiosConfig';

export const getErrorMessage = (error: any): string => {
  if (isAxiosError(error)) {
    return error?.response?.data;
  } else if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};
