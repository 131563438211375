import { Select } from 'components/select';
import React, { useEffect } from 'react';
import {
  IndustrialResidenceType,
  PortfolioIndustrialRealStateItem,
  RealEstatePurpose,
} from 'types/portfolioItem';
import { enumToSelectItems, isEnumOf } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';

interface Props {
  readonly item: PortfolioIndustrialRealStateItem;
}

export const IndustrialRealEstateForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioIndustrialRealStateItem>();

  useEffect((): void => {
    if (item.purpose !== RealEstatePurpose.industrial) {
      return;
    } else if (!isEnumOf(IndustrialResidenceType, item.typeOfResidence)) {
      const updateTypeOfResidence = updaterFor('typeOfResidence');
      updateTypeOfResidence(IndustrialResidenceType.manufacturing);
    }
  }, [item.purpose, item.typeOfResidence, updaterFor]);

  return (
    <>
      <Select
        items={residenceTypes}
        value={item.typeOfResidence}
        label="Select type of commercial real estate"
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onChange={updaterFor('typeOfResidence')}
      />
    </>
  );
};

const residenceTypes = enumToSelectItems(IndustrialResidenceType, {
  [IndustrialResidenceType.manufacturing]: 'Manufacturing',
  [IndustrialResidenceType.wasteHouse]: 'Waste House',
  [IndustrialResidenceType.energyRelated]: 'Energy-Related',
  [IndustrialResidenceType.otherSpecializedIndustrial]: 'Other Specialized Industrial',
});
