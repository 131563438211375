import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DataDisclaimer } from 'views/DataAnalysis/Company/dataDisclaimer';
import { CommonTimelineRoute } from 'views/DataAnalysis/Company/Details/Timeline/common';

const CompanyTimeline: React.FC = (): React.ReactElement => {
  return (
    <>
      <Routes>
        <Route element={<CommonTimelineRoute />} index={true} />
        <Route path="/:eventId" element={<CommonTimelineRoute />} />
      </Routes>
      <DataDisclaimer />
    </>
  );
};

export default CompanyTimeline;
