import React, { useEffect, useRef, useState } from 'react';

export const useBoundingRectangle = <T extends Element>(): [React.Ref<T>, DOMRect] => {
  const [rectangle, setRectangle] = useState<DOMRect>(new DOMRect());
  const elementReference = useRef<T>(null);
  const timeoutReference = useRef<any>(null);

  useEffect((): void | VoidFunction => {
    const { current: element } = elementReference;
    if (element === null) {
      return;
    }

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]): void => {
      clearTimeout(timeoutReference.current);

      if (entries.length !== 1) {
        throw new Error('unexpected event, more than a single entry in the observer entries');
      }
      const element = <HTMLElement>entries[0].target;

      timeoutReference.current = setTimeout((): void => {
        setRectangle(element.getBoundingClientRect());
      }, 0);
    });

    observer.observe(element, { box: 'content-box' });
    return (): void => {
      observer.disconnect();
    };
  }, [elementReference]);

  return [elementReference, rectangle];
};
