import { VirtualElement } from '@popperjs/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { stopPropagation } from 'utils/ignoreEvent';
import { defaultOptions, ModifierOptions } from 'utils/popper';

interface Props {
  readonly isOpen: boolean;
  readonly anchor: Element | VirtualElement | null;
  readonly options?: ModifierOptions;

  onClose?(): void;
}

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  anchor,
  isOpen,
  options = popperDefaultOptions,
  onClose,
}: React.PropsWithChildren<Props>): React.ReactElement | null => {
  const [popup, setPopup] = useState<HTMLElement | null>(null);
  const [boundary, setBoundary] = useState<HTMLElement | null>(null);

  const popperOptions = useMemo((): any => defaultOptions(boundary, options), [boundary, options]);
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  const handleBackdropClick = useCallback(
    (event: React.MouseEvent): void => {
      // Clicks should NEVER go beyond this div
      event.stopPropagation();
      onClose?.();
    },
    [onClose],
  );

  useEffect((): void => {
    update?.();
  }, [update]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={setBoundary}
      className="fixed inset-0 bg-transparent cursor-pointer z-1"
      onClick={handleBackdropClick}
    >
      {ReactDOM.createPortal(
        <div
          ref={setPopup}
          className="bg-gray-light rounded-b shadow-lg overflow-hidden z-1"
          {...attributes.popper}
          style={styles.popper}
          onClick={stopPropagation}
        >
          {children}
        </div>,
        document.body,
      )}
    </div>
  );
};

const popperDefaultOptions: ModifierOptions = {
  placement: 'bottom-start',
  offset: [0, 8],
  withSameWidth: true,
  preventOverflow: true,
};
