import { AxiosResponse } from 'axios';
import Button from 'components/buttons/Button/Button';
import Checkbox from 'components/DEPRECATED/checkbox/Checkbox';
import Input from 'components/input/Input2';
// GENERAL COMPONENT
import { TagInput } from 'components/tagInput';
import { format, isAfter } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileSelector } from 'redux/reducers/profileReducer';
import { updateJobInformation } from 'redux/services/profileService';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { DatePicker } from 'views/Landing/Onboarding/components/DatePicker';
import { DropdownInput } from 'views/Landing/Onboarding/components/DropdownInput';
// UTILS
import { ComboBoxItem } from 'views/Landing/Onboarding/components/helpers';
import { Select } from 'views/Landing/Onboarding/components/Select';
import { carrelLevelOptions } from 'views/Landing/Onboarding/ExperienceForm';

interface Props {
  onClose(): void;
}

interface JobInformation {
  careerLevel: string | null;
  recentJob: string | null;
  recentCompany: string | null;
  industry: ComboBoxItem | null;
  startDate: string | null;
  endDate: string | null;
  certifications: string[];
}

const EditJobInformationModal: React.FC<Props> = ({ onClose }: Props): React.ReactElement => {
  const userProfile = useSelector(userProfileSelector);

  const initialState = useMemo((): JobInformation => {
    const { job_information } = userProfile ?? {};

    return {
      careerLevel: job_information?.career_level ?? null,
      recentJob: job_information?.recent_job ?? null,
      recentCompany: job_information?.recent_company ?? null,
      industry: job_information?.industry ?? null,
      startDate: job_information?.job_position_start_date ?? null,
      endDate: job_information?.job_position_end_date ?? null,
      certifications: job_information?.certifications ?? [],
    };
  }, [userProfile]);

  const [jobInformation, setJobInformation] = useState<JobInformation>(initialState);
  const [currentPosition, setCurrentPosition] = useState<boolean>(jobInformation.endDate === null);
  const { careerLevel, recentCompany, industry, startDate, endDate, certifications, recentJob } =
    jobInformation;
  const dispatch = useDispatch<any>();

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { target } = event;
    setJobInformation((data: JobInformation) => ({ ...data, [target.name]: target.value }));
  };

  const isStartDateGreater = useMemo(() => {
    const jobStartDate = startDate ? new Date(startDate) : new Date();
    const jobEndDate = endDate ? new Date(endDate) : jobStartDate;

    return isAfter(jobStartDate, jobEndDate);
  }, [startDate, endDate]);

  const minEndDate = useMemo(() => (startDate ? new Date(startDate) : undefined), [startDate]);

  const payloadStartDate = useMemo(
    (): string | null => (startDate ? format(new Date(startDate), 'yyyy-MM-dd') : null),
    [startDate],
  );

  const payloadEndDate = useMemo(
    (): string | null => (endDate ? format(new Date(endDate), 'yyyy-MM-dd') : null),
    [endDate],
  );

  const now = useMemo(() => new Date(), []);

  const onIndustryAutocomplete = useCallback(
    async (value: string): Promise<readonly ComboBoxItem[]> => {
      const trimmedValue = value.trim();
      if (trimmedValue.length > 0) {
        const response: AxiosResponse = await api.get(
          `${API_V1_PATH}/reference-data/profile/industries`,
          {
            params: { keyword: trimmedValue },
          },
        );
        if (response.status !== 200) {
          return [];
        }

        return response.data;
      } else {
        return [];
      }
    },
    [],
  );

  const onDropdownChange = (option: string): void => {
    setJobInformation((data: JobInformation) => ({ ...data, careerLevel: option }));
  };

  const onIndustryChange = (value: ComboBoxItem): void => {
    setJobInformation(data => ({ ...data, industry: value }));
  };

  const onStartDateChange = (value: Date): void => {
    const date = format(value, 'MM/dd/yyyy');
    setJobInformation((data: JobInformation) => ({ ...data, startDate: date }));
  };

  const onEndDateChange = (value: Date): void => {
    const date = format(value, 'MM/dd/yyyy');
    if (startDate === null) {
      setJobInformation((data: JobInformation) => ({ ...data, startDate: date }));
    }
    setJobInformation((data: JobInformation) => ({ ...data, endDate: date }));
  };

  const onChangeCurrentPosition = useCallback((): void => {
    if (!currentPosition) {
      setJobInformation((data: JobInformation) => ({ ...data, endDate: null }));
    } else {
      setJobInformation((data: JobInformation) => ({ ...data, endDate: startDate }));
    }
    setCurrentPosition(!currentPosition);
  }, [currentPosition, startDate]);

  const onCertificationsChange = useCallback((value: string[]): void => {
    setJobInformation((data: JobInformation) => ({ ...data, certifications: [...value] }));
  }, []);

  const onSubmit = (): void => {
    const payload = {
      career_level: careerLevel,
      recent_job: recentJob,
      recent_company: recentCompany,
      industry: industry?.id === '' ? null : industry?.id ?? null,
      job_position_start_date: payloadStartDate,
      job_position_end_date: payloadEndDate,
      certifications: certifications,
    };

    dispatch(updateJobInformation(payload));
    onClose();
  };

  return (
    <div className="w-full md:w-modal-sm">
      <div className="my-8 relative">
        <Select
          id="career-level"
          value={careerLevel ?? ''}
          options={carrelLevelOptions}
          label="Career Level"
          onChange={onDropdownChange}
        />
      </div>
      <div className="my-8 relative">
        <Input
          name="recentJob"
          inputClassName="bg-gray-light"
          className="px-3 py-1"
          value={recentJob ?? ''}
          onChange={onChange}
          placeholder="Recent job"
        />
      </div>
      <div className="my-8 relative">
        <Input
          name="recentCompany"
          inputClassName="bg-gray-light"
          className="px-3 py-1"
          value={recentCompany ?? ''}
          onChange={onChange}
          placeholder="Recent company"
        />
      </div>
      <div className="my-8 relative">
        <DropdownInput
          id="industry"
          name="industry"
          value={industry}
          label="Industry"
          placeholder="Industry"
          onAutocomplete={onIndustryAutocomplete}
          onChange={onIndustryChange}
        />
      </div>
      <div className="my-8 relative">
        <div className="flex gap-2 items-center z-dropdown">
          <DatePicker
            id="start-date"
            onChange={onStartDateChange}
            value={startDate}
            maxDate={now}
            label="Start Date"
          />
          {!currentPosition && (
            <DatePicker
              id="end-date"
              onChange={onEndDateChange}
              value={endDate}
              minDate={minEndDate}
              maxDate={now}
              label="End Date"
            />
          )}
        </div>
        <div className={`${!isStartDateGreater ? 'invisible' : ''} mb-4 px-3 flex`}>
          <p className="font-poppins text-red text-xs">Start date must be lower than end date</p>
        </div>
        <div className="flex w-full px-3">
          <Checkbox
            id="current-position-checkbox"
            checked={currentPosition}
            label="Current position"
            onChange={onChangeCurrentPosition}
          />
        </div>
      </div>
      <div className="my-8 relative">
        <TagInput
          id="certifications-licences"
          name="certifications"
          placeholder="Certifications & Licences"
          items={certifications}
          onChange={onCertificationsChange}
        />
      </div>
      <div className="w-full flex justify-end">
        <Button
          id="submit-button"
          label="Submit"
          width="w-36"
          onClick={onSubmit}
          cursor={isStartDateGreater ? 'cursor-default' : 'cursor-pointer'}
          disabled={isStartDateGreater}
        />
      </div>
    </div>
  );
};

export default EditJobInformationModal;
