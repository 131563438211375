import { ConditionalRender } from 'components/conditionalRenderer';
import Toggle from 'components/toggle/Toggle';
import React, { useMemo } from 'react';
import { SubscriptionPlansReducerActions } from 'redux/reducers/subscriptionPlansReducer';
import { ProcessingState, ProcessingStateEnum } from 'types/processingState';
import styles from 'views/Profile/Payments/spinner.module.scss';

interface Props {
  readonly processingState: ProcessingState<any>;
  readonly checked: boolean;

  onChange(): void;
}

export const AutoRenewalToggle: React.FC<Props> = ({
  processingState,
  checked,

  onChange,
}: Props): React.ReactElement => {
  const spinner = useMemo(
    (): boolean =>
      processingState.data ===
        SubscriptionPlansReducerActions.toggleActiveSubscriptionAutoRenewal &&
      processingState.state === ProcessingStateEnum.processing,
    [processingState.data, processingState.state],
  );

  return (
    <>
      <Toggle label="" checked={checked} onChange={onChange} />
      <p>Renew automatically</p>
      <div className="relative w-5 h-5">
        <ConditionalRender renderIf={spinner}>
          <div className={styles.spinner} />
        </ConditionalRender>
      </div>
    </>
  );
};
