import React from 'react';

export type EllipsisRenderingFn = (totalCount: number, renderedCount: number) => React.ReactElement;

export enum TruncateType {
  text = 'text',
  elements = 'elements',
}

export interface BaseProps {
  readonly contentType: TruncateType;

  onTruncate?(wasTruncated: boolean): any;
}
