import { ReactComponent as NoCompaniesIcon } from 'assets/icons/svg/no-companies-icon.svg';
import { createColumns } from 'components/algoNotesTable/columns';
import { createFilters } from 'components/algoNotesTable/filters';
import { ConditionalRender } from 'components/conditionalRenderer';
import { DateRangeFilter } from 'components/dateRangeFilter';
import EmptySection from 'components/emptySection/EmptySection';
import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { Modal } from 'components/modal';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { ColumnDefinition } from 'components/table/types';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useSort } from 'hooks/useSort';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlgoNote } from 'redux/reducers/algoNotesReducer';
import { Page } from 'types/page';
import { DetailsModalContent } from 'views/DataAnalysis/Company/Details/AlgoNotes/detailsModalContent';

interface Props {
  readonly algoNotesPage: Page<AlgoNote>;
  readonly loading: boolean;
  readonly objectId?: string;
  readonly currentNoteId?: string;
  readonly type: 'company' | 'peer-group';

  refresh(filters: Record<string, string>): VoidFunction;
}

export const AlgoNotesTable: React.FC<Props> = ({
  algoNotesPage,
  loading,
  objectId,
  currentNoteId,
  type,
  refresh,
}: Props): React.ReactElement => {
  const [sortBy, onSort] = useSort<AlgoNote>('date', SortOrder.descending);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const navigate = useNavigate();
  const filters = useMemo((): readonly CommonFilter[] => {
    if (!objectId) {
      return [];
    }

    return createFilters(objectId, type);
  }, [objectId, type]);

  const handleFiltersChange = useFilters(filters);
  const queryParameters = useQueryParameters();
  const columns = useMemo(
    (): ReadonlyArray<ColumnDefinition<AlgoNote>> => createColumns(type),
    [type],
  );

  const handleRowClick = useCallback(
    (row: AlgoNote): void => {
      navigate(row.id, { relative: 'path' });
    },
    [navigate],
  );

  const handleModalClose = useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const handleNoteFetchCompleted = useCallback((): void => {
    setLoadingNote(false);
  }, []);

  const handleNoteFetchStarted = useCallback((): void => {
    setLoadingNote(true);
  }, []);

  useLayoutEffect((): VoidFunction => {
    return refresh(queryParameters);
  }, [queryParameters, refresh, objectId]);

  if (!objectId) {
    return (
      <ConditionalRender renderIf={algoNotesPage.total_count === 0}>
        <div className="relative flex-grow">
          <EmptySection
            title="Get started by searching for a company above"
            icon={NoCompaniesIcon}
          />
        </div>
      </ConditionalRender>
    );
  }

  return (
    <div className="relative flex-1">
      <div className="flex items-start justify-between mt-3">
        {type === 'company' ? <Filters config={filters} onChange={handleFiltersChange} /> : <div />}
        <DateRangeFilter />
      </div>

      <div className="w-full flex justify-end mt-2">
        <h4 className="text-sm font-poppins lowercase text-gray">
          {algoNotesPage.total_count ?? 0} notes
        </h4>
      </div>

      <Pagination totalPages={algoNotesPage.page_count}>
        <Table
          columns={columns}
          rows={algoNotesPage.data}
          sortBy={sortBy}
          onRowClick={handleRowClick}
          onSortChange={onSort}
        />
      </Pagination>

      <Modal isOpen={currentNoteId !== undefined} spinning={loadingNote} onClose={handleModalClose}>
        <DetailsModalContent
          noteId={currentNoteId}
          onFetchStarted={handleNoteFetchStarted}
          onFetchCompleted={handleNoteFetchCompleted}
        />
      </Modal>

      <SpinnerLoader visible={loading} />
    </div>
  );
};
