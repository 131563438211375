import SVGIcon from 'components/icons/SVGIcon';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { AllAndUpcomingEvent } from 'types/timelineUpcomingEvent';

const columns: ReadonlyArray<ColumnDefinition<AllAndUpcomingEvent>> = [
  {
    type: ColumnType.custom,
    name: 'id',
    label: '',
    render: (
      _: AllAndUpcomingEvent[keyof AllAndUpcomingEvent] | null,
      __: AllAndUpcomingEvent,
    ): React.ReactNode => {
      return <SVGIcon name="calendar-icon" className="w-4 text-grey fill-current" />;
    },
    width: 50,
  },
  {
    type: ColumnType.text,
    label: 'Start Date',
    name: 'start_date',
    alignment: Alignment.left,
    sortable: true,
  },
  {
    type: ColumnType.text,
    alignment: Alignment.left,
    label: 'Title',
    name: 'title',
    sortable: true,
    weight: 7,
  },
];

export default columns;
