import 'components/pagination/index.scss';

import NextIcon from 'assets/icons/svg/next-icon.svg';
import PreviousIcon from 'assets/icons/svg/previous-icon.svg';
import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';

interface Props {
  readonly totalPages: number;
}

interface PageData {
  selected: number;
}

const Pagination: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  totalPages,
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const [search, setSearchParams] = useSearchParams();

  const onPageChange = ({ selected }: PageData) => {
    const selectedPage = selected + 1;

    setSearchParams((current: URLSearchParams): URLSearchParams => {
      current.set('page_number', String(selectedPage));
      return current;
    });
  };

  const currentPage = useMemo((): number => {
    if (totalPages === 0) {
      return -1;
    }

    const pageNumber = search.get('page_number');
    if (!pageNumber) {
      return 0;
    }

    const value = Number(pageNumber);
    if (isNaN(value)) {
      return 0;
    }

    return value - 1;
  }, [search, totalPages]);

  if (totalPages <= 1) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <ReactPaginate
        forcePage={currentPage}
        disableInitialCallback
        previousLabel={previousIconLabel}
        nextLabel={nextIconLabel}
        breakLabel="..."
        pageCount={totalPages}
        pageLinkClassName="page-link"
        pageClassName="page"
        nextClassName="nav"
        previousClassName="nav"
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="container-pagination"
        activeClassName="active-tab"
        disabledClassName="disabled-link"
        renderOnZeroPageCount={nullRenderer}
      />
    </>
  );
};

export default Pagination;

const nullRenderer = (): null => null;
const previousIconLabel = <img src={PreviousIcon} alt="icon" className="h-3 w-3" />;
const nextIconLabel = <img src={NextIcon} alt="icon" className="h-3 w-3" />;
