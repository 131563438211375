export interface StatementValue {
  readonly id: string;
  readonly value: number | null;
  readonly comments_count: number;
  readonly unit: string;
}

export interface StatementRow {
  readonly name: string;
  readonly key: string;
  readonly values: Record<string, StatementValue>;
}

export interface StatementGroup {
  readonly name: string;
  readonly key: string;
  readonly rows: StatementRow[];
}

export interface StatementPeriodBase {
  readonly dimension: 'MRY' | 'MRQ' | 'N/A';
  readonly access_key: string;
}

interface StatementPeriodMRY extends StatementPeriodBase {
  readonly dimension: 'MRY';
  readonly year: number;
}

export interface StatementPeriodMRQ extends StatementPeriodBase {
  readonly dimension: 'MRQ';
  readonly year: number;
  readonly quarter?: number;
}

export interface StatementPeriodNotApplicable extends StatementPeriodBase {
  readonly dimension: 'N/A';
}

export type StatementPeriod =
  | StatementPeriodMRY
  | StatementPeriodMRQ
  | StatementPeriodNotApplicable;

export interface Statement {
  readonly groups: StatementGroup[];
  readonly periods: StatementPeriod[];
  readonly previous_period: StatementPeriod | null;
  readonly next_period: StatementPeriod | null;
  readonly notes: string[];
}

export class Statement {
  public static empty(): Statement {
    return {
      groups: [],
      periods: [],
      notes: [],
      previous_period: null,
      next_period: null,
    };
  }
}

export interface ProfileStatementRow {
  readonly name: string;
  readonly key: string;
  readonly value: string | string[] | number | Date | null;
  readonly unit: string;
}

export interface ProfileStatementGroup {
  readonly name: string;
  readonly key: string;
  readonly rows: ProfileStatementRow[];
}

export const getPeriodLabel = (period: StatementPeriod): string => {
  switch (period.dimension) {
    case 'MRQ':
      return `${period.access_key}`;
    case 'MRY':
      if (Number(period.year) === new Date().getFullYear()) {
        return `${period.year} (YTD)`;
      }

      return `${period.year}`;
    case 'N/A':
      return 'N/A';
  }
};
