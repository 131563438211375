import { ProfileStatementGroup, ProfileStatementRow } from 'types/financial';

export const getLargestSummaryNumber = (summary: ProfileStatementGroup[]): number => {
  const maxValues = summary.map((g: ProfileStatementGroup): number => {
    const values = g.rows.reduce((array: number[], r: ProfileStatementRow): number[] => {
      if (typeof r.value === 'number') {
        return [...array, r.value];
      } else {
        return array;
      }
    }, []);

    return Math.max(...values);
  });

  return Math.max(...maxValues);
};
