import React, { CSSProperties } from 'react';
import { FeedComment } from 'redux/reducers/feedDetailsReducer';
import classes from 'views/Home/modals/PostDetailsModal/components/cardListContainer.module.scss';
import { CommentsList } from 'views/Home/modals/PostDetailsModal/components/commentsList';

interface Props {
  readonly comments: readonly FeedComment[] | null;
  readonly parentId: string;

  onDelete(commentId: string): void;
}

export const CommentsListContainer: React.FC<Props> = ({
  parentId,
  comments,

  onDelete,
}: Props): React.ReactElement => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [style, setStyle] = React.useState<CSSProperties>(initialStyle);

  React.useEffect(() => {
    const { current: container } = ref;

    if (container) {
      const first = container.firstElementChild as HTMLElement;
      if (!first) {
        console.warn('No first element');
        return;
      }

      setStyle({ maxHeight: 0.8 * window.innerHeight - first.offsetHeight });
    }
  }, [comments]);

  return (
    <div ref={ref} style={style} className={classes.container}>
      <CommentsList
        comments={comments}
        depth={0}
        parentId={parentId}
        fetching={false}
        onDelete={onDelete}
      />
    </div>
  );
};

const initialStyle: CSSProperties = { maxHeight: 600 };
