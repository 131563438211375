import { Clickable } from 'components/clickable';
import React from 'react';
import { Organization } from 'redux/reducers/smartToolsRecentSearchesReducer';
import { OrganizationItemRenderer } from 'views/SmartTools/ValueChains/components/OrganizationItemRenderer';

interface OwnProps {
  readonly organizations: readonly Organization[];
  readonly style: React.CSSProperties;
  readonly properties: any;

  onItemClicked(organization: Organization): void;
}

type Props = React.PropsWithoutRef<OwnProps> & React.RefAttributes<HTMLDivElement>;

export const SearchResultsDropdown: React.FC<Props> = React.forwardRef(
  function SearchResultsDropdown(
    { organizations, properties, style, onItemClicked }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactElement {
    return (
      <div ref={ref} className={containerClassName} style={style} {...properties}>
        <ul className="flex-1 overflow-y-auto scroller">
          {organizations.map(
            (organization: Organization): React.ReactElement => (
              <Clickable key={organization.id} clickData={organization} onClick={onItemClicked}>
                <li className="cursor-pointer py-3 px-8 hover:bg-gray-light">
                  <OrganizationItemRenderer organization={organization} />
                </li>
              </Clickable>
            ),
          )}
        </ul>
      </div>
    );
  },
);

const containerClassName = 'flex flex-col rounded-b bg-white shadow-lg overflow-hidden z-1';
