import React from 'react';
import { PortfolioItem, PortfolioItemType } from 'types/portfolioItem';
import { ArtItemForm } from 'views/Portfolios/PortfoliosModal/artItemForm';
import { CashItemForm } from 'views/Portfolios/PortfoliosModal/cashItemForm';
import { CommodityItemForm } from 'views/Portfolios/PortfoliosModal/commodityItemForm';
import { CompanyItemForm } from 'views/Portfolios/PortfoliosModal/companyItemForm';
import { CryptoCurrencyItemForm } from 'views/Portfolios/PortfoliosModal/cryptoCurrencyItemForm';
import { ETFItemForm } from 'views/Portfolios/PortfoliosModal/etfItemForm';
import { RealEstateItemForm } from 'views/Portfolios/PortfoliosModal/realEstateItemForm';

interface Props {
  readonly item: PortfolioItem;
}

export const ItemDetailsFormSelector: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  switch (item.type) {
    case PortfolioItemType.art:
      return <ArtItemForm item={item} />;
    case PortfolioItemType.cash:
      return <CashItemForm item={item} />;
    case PortfolioItemType.cryptoCurrency:
      return <CryptoCurrencyItemForm item={item} />;
    case PortfolioItemType.etf:
      return <ETFItemForm item={item} />;
    case PortfolioItemType.commodity:
      return <CommodityItemForm item={item} />;
    case PortfolioItemType.realEstate:
      return <RealEstateItemForm item={item} />;
    case PortfolioItemType.publicCompany:
    case PortfolioItemType.privateCompany:
      return <CompanyItemForm item={item} />;
    default:
      return <></>;
  }
};
