import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import React from 'react';
import { IndividualsTab } from 'views/SubscriptionPlans/individualsTab';

export const SubscriptionPlans: React.FC = (): React.ReactElement => {
  return (
    <div className="flex flex-col flex-grow mt-2">
      <Tabs variant={TabsVariant.buttons} shrinkFactor={6} alignment="center">
        <Tab label="INDIVIDUALS" element={<IndividualsTab />} path="individuals" />
        <Tab label="ORGANIZATIONS" element={null} path="organizations" comingSoon={true} />
      </Tabs>
    </div>
  );
};
