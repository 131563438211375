import 'components/datePicker/DatePicker.scss';

import SVGIcon from 'components/icons/SVGIcon';
import {
  add,
  addYears,
  endOfWeek,
  format,
  isSameMonth,
  isSameYear,
  setMonth,
  setYear,
  startOfWeek,
  sub,
} from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar, { Detail } from 'react-calendar';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { TimelineLayoutType } from 'types/timeline';
import { defaultOptions } from 'utils/popper';

export interface YearRange {
  readonly start: number | undefined;
  readonly end: number | undefined;
}

export interface IDatePickerProps {
  initialValue: Date;
  yearRangeValue: YearRange;
  value: Date;

  type: TimelineLayoutType;

  onChange(date: Date): void;
}

const getButtonWidth = (layoutType: TimelineLayoutType) => {
  switch (layoutType) {
    case TimelineLayoutType.month:
      return 'w-52';
    case TimelineLayoutType.week:
      return 'w-52';
    default:
      return 'w-44';
  }
};

export default function DatePicker(props: IDatePickerProps) {
  const { type, value, onChange, yearRangeValue } = props;
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popperOptions = useMemo(
    (): any => defaultOptions(document.body, { withSameWidth: true, offset: [0, 0] }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  const [yearRange, setYearRange] = useState<YearRange>(yearRangeValue);
  const [calendarView, setCalendarView] = useState<'month' | 'year' | 'decade' | undefined>(
    undefined,
  );

  const closePopup = useCallback((): void => {
    setIsOpen(false);
  }, []);

  const togglePopup = useCallback((): void => {
    setIsOpen((isOpen: boolean): boolean => !isOpen);
  }, []);

  const dateDelta = useMemo((): Duration => {
    switch (type) {
      case TimelineLayoutType.year:
        return { years: 1 };
      case TimelineLayoutType.month:
        return { months: 1 };
      case TimelineLayoutType.week:
        return { weeks: 1 };
      case TimelineLayoutType.day:
        return { days: 1 };
    }
  }, [type]);

  useEffect((): void => {
    update?.();
  }, [update]);

  useEffect(() => {
    if (
      (!isOpen && !yearRange.end) ||
      (yearRange.end && yearRangeValue.end && yearRange.end !== yearRangeValue.end)
    ) {
      setYearRange(yearRangeValue);
    }
  }, [isOpen, yearRange, yearRangeValue]);

  useEffect((): void => {
    if (type === TimelineLayoutType.month) {
      setCalendarView('year');
    }
  }, [type]);

  const handleNext = useCallback((): void => {
    onChange(add(value, dateDelta));
  }, [dateDelta, onChange, value]);

  const handlePrevious = useCallback((): void => {
    onChange(sub(value, dateDelta));
  }, [dateDelta, onChange, value]);

  const handleClickYear = useCallback(
    (date: Date): void => {
      onChange(setYear(value, date.getFullYear()));

      setTimeout((): void => {
        switch (type) {
          case TimelineLayoutType.month:
            setCalendarView('year');
            break;
          case TimelineLayoutType.year:
            closePopup();
            break;
          case TimelineLayoutType.week:
          case TimelineLayoutType.day:
            break;
        }
      }, 0);
    },
    [closePopup, onChange, type, value],
  );

  const handleClickMonth = useCallback(
    (date: Date): void => {
      if (type === TimelineLayoutType.month) {
        closePopup();
      }

      setTimeout((): void => {
        onChange(setMonth(value, date.getMonth()));
      }, 0);
    },
    [closePopup, onChange, type, value],
  );

  const handleCalendarChange = useCallback(
    (date: Date | Date[]) => {
      if (!Array.isArray(date)) {
        // Adjust value for week view
        const value = type === TimelineLayoutType.week ? add(startOfWeek(date), { days: 1 }) : date;
        if (type === TimelineLayoutType.week || type === TimelineLayoutType.day) {
          closePopup();
        }

        setTimeout((): void => {
          onChange(value);
        }, 0);
      } else {
        throw new Error('selecting multiple dates should not be possible');
      }
    },
    [closePopup, onChange, type],
  );

  const calendarViewType = useMemo((): Detail | undefined => {
    switch (type) {
      case TimelineLayoutType.year:
        return 'decade';
      case TimelineLayoutType.month:
        return calendarView;
    }

    return undefined;
  }, [calendarView, type]);

  const buttonWidth = useMemo((): string => getButtonWidth(type), [type]);
  const buttonLabel = useMemo((): string => {
    const upperDate =
      type === TimelineLayoutType.week
        ? add(endOfWeek(value), { days: 1 })
        : add(value, { days: 2 });
    const lowerDate =
      type === TimelineLayoutType.week ? add(startOfWeek(value), { days: 1 }) : value;

    switch (type) {
      case TimelineLayoutType.year:
        return format(value, 'yyyy');
      case TimelineLayoutType.month:
        return format(value, 'MMMM, yyyy');
      case TimelineLayoutType.week:
        if (!isSameYear(lowerDate, upperDate))
          return `${format(lowerDate, 'MMM dd, yyyy')} - ${format(upperDate, 'MMM dd, yyyy')}`;
        if (!isSameMonth(lowerDate, upperDate))
          return `${format(lowerDate, 'MMM dd')} - ${format(upperDate, 'MMM dd')}`;
        return `${format(lowerDate, 'dd')} - ${format(
          upperDate,
          'dd MMM',
        )}, ${value.getFullYear()}`;
      case TimelineLayoutType.day:
        return format(value, 'MMM dd, yyyy');
      default:
        return format(value, 'yyyy');
    }
  }, [type, value]);

  const handleDrillUp = useCallback((): void => {
    if (type === TimelineLayoutType.month) {
      setCalendarView('decade');
    }
  }, [type]);

  const swallowClick = useCallback((event: React.MouseEvent): void => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <div ref={setAnchor} className="flex items-center relative">
        <button
          id="prior-date-button"
          role="button"
          onClick={handlePrevious}
          className="p-1 flex items-center h-8"
        >
          <span className="m-1 font-poppins text-gray text-sm">PRIOR</span>
          <SVGIcon name="previous-icon" className="w-3 h-3" />
        </button>
        <button
          id="date-picker-button"
          onClick={togglePopup}
          role="button"
          className={`border border-blue bg-blue-light rounded m-1 px-4 py-1 text-lg text-center font-jostSemiBold ${buttonWidth} text-gray-darkest`}
        >
          {buttonLabel}
        </button>
        <button
          id="next-date-button"
          role="button"
          onClick={handleNext}
          className="p-1 flex items-center h-8"
        >
          <SVGIcon name="next-icon" className="w-3 h-3" />
          <span className="m-1 font-poppins text-gray text-sm">NEXT</span>
        </button>
      </div>

      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" onClick={closePopup}>
            <div
              ref={setPopup}
              className="bg-gray-light top-12 p-4 shadow-md rounded-md w-full"
              style={styles.popper}
              onClick={swallowClick}
              {...attributes.popper}
            >
              <Calendar
                nextLabel={nextIcon}
                prevLabel={prevIcon}
                next2Label=""
                prev2Label=""
                maxDate={absoluteMaxDate}
                minDate={absoluteMinDate}
                view={calendarViewType}
                value={value}
                minDetail="decade"
                onChange={handleCalendarChange}
                onClickYear={handleClickYear}
                onClickMonth={handleClickMonth}
                onDrillUp={handleDrillUp}
                formatShortWeekday={shortWeekDayFormatter}
              />
            </div>
          </div>,
          document.body,
        )}
    </>
  );
}

const absoluteMaxDate = addYears(new Date(), 200);
const absoluteMinDate = addYears(new Date(), -200);

const nextIcon = <SVGIcon name="next-icon" className="fill-current text-gray w-3 h-3 ml-2" />;
const prevIcon = <SVGIcon name="previous-icon" className="fill-current text-gray w-3 h-3 mr-2" />;

const shortWeekDayFormatter = (locale: string, date: Date): string => format(date, 'EEEEE');
