import { CommonFilter } from 'components/filters/helpers';

const filters: readonly CommonFilter[] = [
  {
    name: 'category',
    label: 'Category',
    type: 'backend',
    url: '/api/v1/filters/special-offers/category',
  },
  {
    name: 'tier',
    label: 'Tier',
    type: 'backend',
    url: '/api/v1/filters/special-offers/tier',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'backend',
    url: '/api/v1/filters/special-offers/status',
  },
];

export default filters;
