export type Method =
  | 'setPath'
  | 'trackPageView'
  | 'identify'
  | 'trackEvent'
  | 'revokeCookieConsent'
  | 'addPrivacyConsentListener'
  | 'doNotTrack'
  | 'addIdentityListener'
  | 'setContentType'
  | 'refreshPageHandlers';

export type paramPush = any;

export type PropsUseTrackingCode = {
  initialPath: string;
};

export type PropsUseSetTrackEvent = {
  eventId: string;
  value?: number | string;
};

type Params = [Method, paramPush];

declare global {
  interface Window {
    _hsq: { push: (callParam: Params) => void };
  }
}

export type UseTrackingCode = {
  setContentType: (contentType: string) => void;
  setPathPageView: (path: string) => void;
  setTrackPageView: () => void;
  setIdentity: (email: string, customPropertities?: any) => void;
  setTrackEvent: ({ eventId, value }: PropsUseSetTrackEvent) => void;
};

export const useTrackingCode = (): UseTrackingCode => {
  const _hsq = window._hsq || [];

  const setContentType = (contentType: string): void => {
    _hsq.push(['setContentType', contentType]);
  };

  const setTrackPageView = () => {
    _hsq.push(['trackPageView', '']);
  };

  const setPathPageView = (path: string): void => {
    // This function updates the path
    _hsq.push(['setPath', path]);
    // This function track the current updated page path
    setTrackPageView();
  };

  const setIdentity = (email: string, customPropertities?: any) => {
    _hsq.push([
      'identify',
      {
        email,
        ...customPropertities,
      },
    ]);
  };

  const setTrackEvent = ({ eventId, value }: PropsUseSetTrackEvent) => {
    _hsq.push([
      'trackEvent',
      {
        id: eventId,
        value,
      },
    ]);
  };

  return {
    setContentType,
    setPathPageView,
    setTrackPageView,
    setIdentity,
    setTrackEvent,
  };
};
