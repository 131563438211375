import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { ColumnDefinition } from 'components/table/types';
import { useFilters } from 'hooks/useFilters';
import { useSort } from 'hooks/useSort';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { industriesSelector, loadingIndustriesSelector } from 'redux/reducers/industryReducer';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import createColumns from 'views/DataAnalysis/Industry/createColumns';
import { CountryDropdown } from 'views/DataAnalysis/Industry/Details/countryDropdown';
import { createFiltersConfig } from 'views/DataAnalysis/Industry/filters';
import { Industry } from 'views/DataAnalysis/Industry/types';

interface Props {
  readonly currency: 'USD' | 'GBP' | 'NGN' | 'ZAR';
  readonly tab: DataAndAnalysisMainTabs;
}

export const IndustriesTable: React.FC<Props> = ({ tab, currency }: Props): React.ReactElement => {
  const [sorting, handleSortingChange] = useSort<Industry>('name');

  const filters = useMemo((): readonly CommonFilter[] => createFiltersConfig(tab), [tab]);
  const navigate = useNavigate();
  const loading = useSelector(loadingIndustriesSelector);
  const industriesPage = useSelector(industriesSelector);

  const handleFiltersChange = useFilters(filters);
  const handleRowClick = useCallback(
    (row: Industry): void => {
      navigate(`/data-analysis/industry/details/${row.id}`);
    },
    [navigate],
  );

  const largestMarketCapitalization = useMemo((): number => {
    const industries = industriesPage.data;
    return Math.max(
      ...industries.map((industry: Industry): number => industry.market_capitalization),
    );
  }, [industriesPage.data]);

  const columns = useMemo(
    (): ReadonlyArray<ColumnDefinition<Industry>> =>
      createColumns(largestMarketCapitalization, currency),
    [currency, largestMarketCapitalization],
  );

  return (
    <div className="relative flex-1">
      <div className="flex items-end justify-between mt-4">
        <Filters config={filters} onChange={handleFiltersChange} />
        <CountryDropdown />
      </div>
      <h4 className="md:text-sm text-right mt-2 mb-1 font-poppins lowercase text-gray">
        {industriesPage.total_count} industries
      </h4>
      <Pagination totalPages={industriesPage.page_count}>
        <Table
          columns={columns}
          rows={industriesPage.data}
          sortBy={sorting}
          onSortChange={handleSortingChange}
          onRowClick={handleRowClick}
        />
      </Pagination>

      <SpinnerLoader visible={loading} />
    </div>
  );
};
