import { Disabler } from 'components/disabler';
import { TabsVariant } from 'components/tabs/index';
import { ClassResolverFn } from 'components/tabs/types';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

interface PropsBase {
  readonly label: string;
  readonly path?: string;
  readonly disabled?: boolean;
  readonly variant: TabsVariant;
}

interface ClickableProps extends PropsBase {
  readonly active: boolean;
  readonly value: number;
  onClick?(value: number): void;
}

type Props = PropsBase | ClickableProps;

export const TabButton: React.FC<Props> = (props: Props): React.ReactElement => {
  const { label, path, disabled = false, variant } = props;
  const classResolver = useMemo((): ClassResolverFn => classResolvers[variant], [variant]);
  const inactiveClass = useMemo((): string => classResolver({ isActive: false }), [classResolver]);

  if ('onClick' in props && (typeof props.onClick === 'function' || props.onClick === undefined)) {
    const { value, active, onClick } = props;

    const handleClick = (): void => {
      onClick?.(value);
    };

    return (
      <button
        className={classResolver({ isActive: active })}
        onClick={handleClick}
        disabled={!onClick}
      >
        {label.toUpperCase()}
      </button>
    );
  } else {
    if (disabled) {
      return (
        <Disabler disabled={disabled}>
          <NavLink className={inactiveClass} to={pathToUrl(path)} end={path === undefined}>
            {label}
          </NavLink>
        </Disabler>
      );
    } else {
      return (
        <NavLink className={classResolver} to={pathToUrl(path)} end={path === undefined}>
          {label}
        </NavLink>
      );
    }
  }
};

const pathToUrl = (path?: string): string => {
  if (path === undefined) {
    return '';
  }

  return path.replace(/\/?\*$/, '');
};

const classResolvers: { [key in TabsVariant]: ClassResolverFn } = {
  buttons: ({ isActive }: { isActive: boolean }): string => {
    const baseClass = 'block w-full h-full text-center leading-8 py-px truncate overflow-ellipsis';

    if (isActive) {
      return [baseClass, 'font-poppinsSemiBold text-white bg-blue'].join(' ');
    } else {
      return [baseClass, 'font-poppins text-blue'].join(' ');
    }
  },
  tabs: ({ isActive }: { isActive: boolean }): string => {
    const baseClass =
      'block w-full h-full text-center border-b leading-10 py-1 truncate overflow-ellipsis';
    if (isActive) {
      return [baseClass, 'font-poppinsSemiBold text-blue border-blue'].join(' ');
    } else {
      return [baseClass, 'font-poppins text-gray border-gray-light'].join(' ');
    }
  },
};
