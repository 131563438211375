import LowIcon from 'assets/icons/svg/green-arrow-down-icon.svg';
import LocationIcon from 'assets/icons/svg/location-icon.svg';
import MediumIcon from 'assets/icons/svg/medium-icon.svg';
import MembersIcon from 'assets/icons/svg/members-icon.svg';
import HighIcon from 'assets/icons/svg/red-arrow-up-icon.svg';
import Tag from 'components/DEPRECATED/tags/Tag';
import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import { useEventDateTime } from 'hooks/useEventDateTime';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Country } from 'redux/reducers/sharedReducer';
import { EventDetails } from 'types/timeline';
import { ReminderValue } from 'utils/dateTime';
import { unserialize } from 'utils/serializer';
import { getReminderObject } from 'views/SmartTools/TimelineAndEvents/utils';

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

interface Props {
  readonly details: EventDetails | null;
  readonly loading: boolean;
}

const EventDetailsModal: React.FC<Props> = ({ details, loading }: Props): React.ReactElement => {
  const [currentTimezone, setCurrentTimezone] = useState<string>(localTimezone);
  // FIXME: enable this again
  // const countries = useSelector(countriesSelector);

  useEffect(() => {
    mixpanel.track('PageView Event Details');
  }, []);

  const findCountryISO = useCallback((_: string): Country | undefined => {
    return undefined; // FIXME: put this back -> countries.find(c => c.id === country);
  }, []);

  const getReminder = useCallback((reminder: string, startsDate: string): ReminderValue | null => {
    return getReminderObject(reminder, startsDate);
  }, []);

  const EventDetailView: React.FC<EventDetails> = (event: EventDetails) => {
    const {
      country,
      description,
      importance,
      linked_events,
      members,
      startDate,
      startTime,
      endDate,
      endTime,
      summary,
      tags,
      timezone,
      urgency,
    } = event;

    const eventDateTime = useEventDateTime(
      unserialize(startDate),
      startTime,
      endDate ? unserialize(endDate) : null,
      endTime,
      timezone,
      currentTimezone,
    );
    const countryISO = findCountryISO(country);
    const reminder = getReminder(event.reminder, startDate);

    const toggleTextMsg = useMemo((): string => {
      if (event.timezone === localTimezone) {
        return '';
      }
      return currentTimezone === event.timezone
        ? 'See in your timezone'
        : 'See in original timezone';
    }, [event.timezone]);

    return (
      <>
        <h3 className="flex-shrink-0 flex-grow font-jostSemiBold my-0 text-2xl break-normal">
          {summary}
        </h3>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="flex mt-4">
              <div className={'w-60 border-gray' + eventDateTime.isWholeDay ? '' : ' border-r'}>
                <div className="flex">
                  <SVGIcon
                    name="calendar-icon"
                    className="w-4 h-4 mt-px mr-2 fill-current text-gray-dark"
                  />
                  <h4 className="text-gray-darkest">
                    {eventDateTime.isWholeDay ? 'DATE' : 'START DATE'}
                  </h4>
                </div>
                <p>
                  <span className="mr-3 text-gray">{eventDateTime.startDate}</span>
                </p>
              </div>
              {eventDateTime.endDate !== null && (
                <div className="pl-6">
                  <div className="flex">
                    <SVGIcon
                      name="calendar-icon"
                      className="w-4 h-4 mt-px mr-2 fill-current text-gray-dark"
                    />
                    <h4 className="text-gray-darkest">END DATE</h4>
                  </div>
                  <p>
                    <span className="mr-3 text-gray">{eventDateTime.endDate}</span>
                  </p>
                </div>
              )}
            </div>
            {eventDateTime.startTime !== null && eventDateTime.endTime !== null && (
              <>
                <div className="flex items-center  mt-3 ">
                  <p className="text-sm text-gray">Timezone - {eventDateTime.timezone}</p>
                  <button
                    onClick={() =>
                      setCurrentTimezone(prev =>
                        prev === localTimezone ? event.timezone : localTimezone,
                      )
                    }
                    className="text-blue font-poppins focus:outline-none hover:opacity-50 ml-4 text-sm mt-0.5"
                  >
                    {toggleTextMsg}
                  </button>
                </div>
                <div className="flex items-center font-poppins mt-3 text-gray">
                  <SVGIcon name="time-icon" className="w-4 h-4 mr-2 fill-current text-gray-dark" />
                  <span className="text-sm">
                    {eventDateTime.startTime} - {eventDateTime.endTime}
                  </span>
                </div>
              </>
            )}
            {country && (
              <div className="flex items-center mt-4">
                <img src={LocationIcon} className="h-4" alt="location" />
                <p className="text-gray-dark ml-2">{countryISO?.name || country}</p>
              </div>
            )}
            <div className="flex mt-4">
              <div className="mr-8">
                <p className="text-base font-poppinsSemiBold">IMPORTANCE LEVEL</p>
                <div className="flex items-center">
                  {importance && (
                    <img src={iconLevel(importance)} className="h-3 mr-1" alt="importance" />
                  )}
                  <p className="text-gray text-base">{importance ?? 'To be determined'}</p>
                </div>
              </div>
              <div>
                <p className="text-base font-poppinsSemiBold">URGENCY LEVEL</p>
                <div className="flex items-center">
                  {urgency && <img src={iconLevel(urgency)} className="h-3 mr-1" alt="urgency" />}
                  <p className="text-gray text-base">{urgency ?? 'To be determined'}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center pt-4">
              <img src={MembersIcon} className="h-4" alt="location" />
              <p className="text-gray-dark ml-2 text-base">{`${members?.length} TEAM ${
                members?.length > 1 ? 'MATES' : 'MATE'
              }`}</p>
            </div>
            <div className="flex items-center pt-4">
              <SVGIcon name="reminder-icon" className="h-4 fill-current text-gray-dark" />
              <p className="text-gray ml-2 text-base">{reminder?.label ?? 'No reminder'}</p>
            </div>
            {tags?.length > 0 && (
              <>
                <p className="text-base font-poppinsSemiBold mt-4"># TAGS</p>
                <div className="flex pt-2">
                  {tags.map(tag => (
                    <Tag key={tag} className="mr-2 px-2">
                      <h6 className="text-xxs whitespace-nowrap">{tag}</h6>
                    </Tag>
                  ))}
                </div>
              </>
            )}
            {linked_events?.length > 0 && (
              <>
                <p className="text-base font-poppinsSemiBold mt-4">LINKED EVENTS</p>
                <div className="flex pt-2">
                  {/*linked_events.map(eventId => {
                    const linkedEvent = listEvents?.find(listEvent => listEvent.id === eventId);
                    if (linkedEvent) {
                      return (
                        <div className="w-78 mr-3" key={linkedEvent.summary}>
                          <EventItem
                            linkedStyle
                            title={linkedEvent.summary}
                            startDate={linkedEvent.startDate}
                            endDate={linkedEvent.endDate}
                            startTime={linkedEvent.startTime}
                            endTime={linkedEvent.endTime}
                            onPress={() => null}
                          />
                        </div>
                      );
                    }
                  })*/}
                </div>
              </>
            )}
            <p className="text-base font-poppinsSemiBold mt-4 mb-1">DESCRIPTION</p>
            <p className="text-base text-gray">{description}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="md:w-modal-md w-full text-gray-darkest overflow-auto bg-gray-light">
      {details ? <EventDetailView {...details} /> : <div className="h-96" />}
      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default EventDetailsModal;

const iconLevel = (level: string) => {
  switch (level?.toLowerCase()) {
    case 'high':
      return HighIcon;
    case 'low':
      return LowIcon;
    case 'medium':
      return MediumIcon;
    default:
      return MediumIcon;
  }
};
