import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Identifiable } from 'components/table/types';
import { Notification } from 'redux/reducers/notificationsReducer';
import { Page } from 'types/page';
import { Stream, StreamEvent } from 'types/timeline';

export interface Location {
  id: string;
  name: string;
  country: string;
  lo_type: string;
  coordinates: Coordinate | null;
}

export interface Companies {
  id: string;
  currency: string;
  company: string;
  product: string;
  relationship: string;
  size_of_relationship: number | string;
  locations: Location[];
}

export interface Company {
  id: string;
  name: string;
  locations: Coordinate | null;
  location: Coordinate | null;
  industry: string;
}

export interface Portfolio extends Identifiable {
  portfolio_id: string | null;
  name: string;
  summary: string | null;
  description: string | null;
  portfolio_type: string;
  tags: string[];
  user_email: string;
  count: number | null;
  access_mode: string;
  organizations: Company[];
  notifications: Page<Notification>;
}

export interface Coordinate {
  readonly latitude: number;
  readonly longitude: number;
}

export interface MemberBE {
  user_id: string;
  type_id?: string;
}

interface Kindable {
  readonly kind: string;
}

export interface PortfolioMapItemBase {
  readonly id: string;
  readonly name: string;
}

export interface PortfoliosCompaniesMapItem extends PortfolioMapItemBase {
  readonly kind: 'portfoliosCompanies';
  readonly companies: string[];
}

export interface PortfoliosRevenuesMapItem extends PortfolioMapItemBase {
  readonly kind: 'portfoliosRevenues';
  readonly revenues: number;
  readonly percentage_of_total: number;
  readonly region: string;
}

export interface PortfoliosMapLocation<T extends Kindable> {
  readonly id: string;
  readonly kind: T['kind'];
  readonly coordinates: Coordinate;
  readonly portfolios: T[];
  readonly region: string;
}

export interface EventsGroup {
  readonly id: string;
  readonly name: string;
  readonly events: readonly StreamEvent[];
}

export interface PortfoliosState {
  portfolios: Portfolio[];
  companies: Array<{ id: string; name: string }> | null;
  industries: Array<{ id: string; name: string }>;
  comments: string[] | null;
  members: MemberBE[] | null;
  membersType: Array<{ id: string; portfolio_type: string }> | null;
  notes: string[] | null;
  loading: boolean;
  error: string;
  loadingPortfoliosNotifications: boolean;
  portfoliosNotifications: Page<Notification>;

  loadingStreams: boolean;
  streams: Stream[];
  eventGroups: Record<string, EventsGroup[]>;

  companiesMap: Array<PortfoliosMapLocation<PortfoliosCompaniesMapItem>>;
  revenuesMap: Array<PortfoliosMapLocation<PortfoliosRevenuesMapItem>>;
  selection: Record<string, boolean>;
}

// Initial State
const initialState: PortfoliosState = {
  portfolios: [],
  companies: null,
  industries: [],
  comments: null,
  members: null,
  membersType: null,
  notes: null,
  loading: false,
  error: '',
  loadingPortfoliosNotifications: false,
  portfoliosNotifications: Page.empty(),

  loadingStreams: false,
  streams: [],
  eventGroups: {},

  companiesMap: [],
  revenuesMap: [],
  selection: {},
};

const portfoliosSlice = createSlice({
  name: 'portfolio',
  initialState: initialState,
  reducers: {
    // PORTFOLIO GENERAL LAYOUT
    getPortfolios: state => {
      state.portfolios = [];
      state.loading = true;
    },
    getPortfoliosSuccess: (state, { payload }) => {
      state.portfolios = payload;
      state.notes = payload?.Notes;
      state.comments = payload?.Comments;
      state.loading = false;
      state.error = '';
    },
    getPortfoliosFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getPortfoliosNotifications: state => {
      state.loadingPortfoliosNotifications = true;
      state.portfoliosNotifications = Page.empty();
    },
    getPortfoliosNotificationsSuccess: (state, action: PayloadAction<Page<Notification>>) => {
      state.loadingPortfoliosNotifications = false;
      state.portfoliosNotifications = action.payload;
    },
    getPortfoliosNotificationsFailed: state => {
      state.loadingPortfoliosNotifications = false;
    },
    // Add Portfolio to User's Profile
    addPortfolioSuccess: (state, { payload }) => {
      state.portfolios = [...state.portfolios, payload];
      state.loading = false;
      state.error = '';
    },
    addPortfolioFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Edit portfolio from portfolios screen
    editPortfolioSuccess: (state, { payload }) => {
      const { portfolios } = state;

      state.portfolios = portfolios.map((p: any) =>
        p.id === payload.id ? { ...p, ...payload } : p,
      );
      state.loading = false;
      state.error = '';
    },
    editPortfolioFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Delete Portfolio in User's Profile
    deletePortfolioSuccess: (state, { payload }) => {
      const { portfolios } = state;

      state.portfolios = portfolios.filter((p: any) => p.id !== payload.id);
      state.loading = false;
      state.error = '';
    },
    deletePortfolioFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Get all companies
    getCompanies: state => {
      state.loading = true;
    },
    getCompaniesSuccess: (state, { payload }) => {
      state.companies = payload;
      state.loading = false;
      state.error = '';
    },
    getCompaniesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Get Industry
    getIndustries: state => {
      state.loading = true;
    },
    getIndustriesSuccess: (state, { payload }) => {
      state.industries = payload;
      state.loading = false;
      state.error = '';
    },
    getIndustriesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    //
    // Clears Error State
    clearErrors: state => {
      state.error = '';
    },
    getPortfoliosMap: state => {
      state.loading = true;
    },
    getPortfoliosMapSuccess: (state, { payload }) => {
      state.companiesMap = payload.map(
        (item: any): PortfoliosMapLocation<PortfoliosCompaniesMapItem> => ({
          ...item,
          kind: 'portfoliosCompanies',
        }),
      );
      state.loading = false;
    },
    getPortfoliosMapFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getPortfoliosRevenuesMap: state => {
      state.loading = true;
    },
    getPortfoliosRevenuesMapSuccess: (state, { payload }) => {
      state.revenuesMap = payload.map(
        (item: any): PortfoliosMapLocation<PortfoliosRevenuesMapItem> => ({
          ...item,
          kind: 'portfoliosRevenues',
        }),
      );
      state.loading = false;
    },
    getPortfoliosRevenuesMapFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    startLoadingPortfoliosStreams: (state: PortfoliosState): void => {
      const { streams } = state;

      state.loadingStreams = true;
      // Streams will probably not change, their events will though
      state.streams = streams.map((stream: Stream): Stream => {
        return { ...stream, events: [] };
      });
      state.eventGroups = {};
    },
    setPortfoliosStreams: (state: PortfoliosState, { payload }: PayloadAction<Stream[]>): void => {
      state.streams = payload;
    },
    addEventToPortfolioStream: (
      state: PortfoliosState,
      { payload }: PayloadAction<StreamEvent>,
    ): void => {
      const { streams } = state;
      state.streams = streams.map((stream: Stream): Stream => {
        if (stream.id !== payload.stream_id) {
          return stream;
        } else {
          return { ...stream, events: [...stream.events, payload] };
        }
      });
    },
    removeEventFromPortfolioStream: (
      state: PortfoliosState,
      { payload }: PayloadAction<{ streamId: string; eventId: string }>,
    ): void => {
      const { streams } = state;
      state.streams = streams.map((stream: Stream): Stream => {
        const { events } = stream;
        if (stream.id !== payload.streamId) {
          return stream;
        } else {
          return {
            ...stream,
            events: events.filter((event: StreamEvent): boolean => event.id !== payload.eventId),
          };
        }
      });
    },
    replaceEventInPortfolioStream: (
      state: PortfoliosState,
      { payload }: PayloadAction<StreamEvent>,
    ): void => {
      const { streams } = state;
      state.streams = streams.map((stream: Stream): Stream => {
        const { events } = stream;
        if (stream.id !== payload.stream_id) {
          return stream;
        } else {
          return {
            ...stream,
            events: events.map((event: StreamEvent): StreamEvent => {
              if (event.id !== payload.id) {
                return event;
              } else {
                return payload;
              }
            }),
          };
        }
      });
    },
    completeLoadingPortfoliosStreams: (state: PortfoliosState): void => {
      state.loadingStreams = false;
    },
    startLoadingPortfolioStreamEventGroups: (
      state: PortfoliosState,
      { payload }: PayloadAction<string>,
    ): void => {
      state.loadingStreams = true;
      state.eventGroups[payload] = [];
    },
    setPortfolioStreamEventGroups: (
      state: PortfoliosState,
      { payload }: PayloadAction<{ id: string; groups: EventsGroup[] }>,
    ): void => {
      state.eventGroups = { ...state.eventGroups, [payload.id]: payload.groups };
    },
    completeLoadingPortfolioStreamEventGroups: (state: PortfoliosState): void => {
      state.loadingStreams = false;
    },
    clearStreamEvents: (state: PortfoliosState, { payload }: PayloadAction<string>): void => {
      const { [payload]: _, ...remaining } = state.eventGroups;
      state.eventGroups = remaining;
    },
    resetSelection: (state: PortfoliosState): void => {
      state.selection = {};
    },
    updateSelection: (
      state: PortfoliosState,
      action: PayloadAction<{ readonly id: string; readonly selected: boolean }>,
    ): void => {
      const { id, selected } = action.payload;
      const { [id]: _, ...currentSelection } = state.selection;

      if (selected) {
        state.selection = { ...currentSelection, [id]: true };
      } else {
        state.selection = currentSelection;
      }
    },
  },
});

export const portfoliosSelector = (state: any) => state.portfoliosReducer;

export const portfoliosNotificationsSelector = (state: {
  portfoliosReducer: PortfoliosState;
}): Page<Notification> => state.portfoliosReducer.portfoliosNotifications;
export const portfoliosStreamsSelector = (state: {
  portfoliosReducer: PortfoliosState;
}): Stream[] => state.portfoliosReducer.streams;
export const loadingPortfoliosStreamsSelector = (state: {
  portfoliosReducer: PortfoliosState;
}): boolean => state.portfoliosReducer.loadingStreams;

export const portfolioStreamEventsSelector = (state: {
  portfoliosReducer: PortfoliosState;
}): Record<string, EventsGroup[]> => state.portfoliosReducer.eventGroups;

export const loadingPortfoliosNotificationsSelector = (state: {
  portfoliosReducer: PortfoliosState;
}): boolean => state.portfoliosReducer.loading;

// Actions
export const {
  // Portfolio
  getPortfolios,
  getPortfoliosSuccess,
  getPortfoliosFailed,
  addPortfolioSuccess,
  addPortfolioFailed,
  editPortfolioSuccess,
  editPortfolioFailed,
  deletePortfolioFailed,
  // getCompanies,
  // getCompaniesSuccess,
  // getCompaniesFailed,
  // getIndustries,
  // getIndustriesSuccess,
  // getIndustriesFailed,
  clearErrors,
  getPortfoliosNotifications,
  getPortfoliosNotificationsSuccess,
  getPortfoliosNotificationsFailed,
  getPortfoliosMap,
  getPortfoliosMapSuccess,
  getPortfoliosMapFailed,
  getPortfoliosRevenuesMap,
  getPortfoliosRevenuesMapSuccess,
  getPortfoliosRevenuesMapFailed,
  startLoadingPortfoliosStreams,
  setPortfoliosStreams,
  completeLoadingPortfoliosStreams,
  startLoadingPortfolioStreamEventGroups,
  setPortfolioStreamEventGroups,
  completeLoadingPortfolioStreamEventGroups,
  clearStreamEvents,
  addEventToPortfolioStream,
  replaceEventInPortfolioStream,
  removeEventFromPortfolioStream,

  updateSelection,
  resetSelection,
} = portfoliosSlice.actions;

export default portfoliosSlice.reducer;
