import React from 'react';
import { useSelector } from 'react-redux';
import {
  CompanyRevenuesLocation,
  CompanySubsidiaryLocation,
  geographiesAndMapsSelector,
} from 'redux/reducers/geographiesAndMapsReducer';
import { OperationType } from 'types/operationType';

export const useCompanyGeographiesAndMapsMarkers = (
  type: string | null,
): ReadonlyArray<CompanySubsidiaryLocation | CompanyRevenuesLocation> => {
  const { subsidiaries, revenues } = useSelector(geographiesAndMapsSelector);

  return React.useMemo((): ReadonlyArray<CompanySubsidiaryLocation | CompanyRevenuesLocation> => {
    switch (type) {
      case OperationType.subsidiaries:
        return subsidiaries.slice(1);
      case OperationType.revenues:
        return revenues;
      default:
        return [];
    }
  }, [revenues, subsidiaries, type]);
};
