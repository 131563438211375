import React from 'react';

export default {
  title: 'Privacy Policy',
  header: (
    <>
      <p>last updated October 2022.</p>
      <p data-renderer-start-pos="787">
        Thank you for visiting{' '}
        <a
          href="https://capnote.com"
          title="https://capnote.com"
          data-renderer-mark="true"
          className="css-1rn59kg"
        >
          https://capnote.com
        </a>
        , one of the Finvar websites, or using our apps or services (together; &apos;the Site&apos;)
        operated by Finvar Corporation.We provide this privacy policy (&apos;Privacy Policy&apos;)
        to explain our online information practices and the choices you can make about the way your
        information is collected and used on the Site.
      </p>
    </>
  ),
  tabs: [
    {
      label: 'I. Introduction',
      id: 'introduction',
      description: (
        <p>
          Finvar is a provider of news, ratings, data, reports, analytics, productivity tools and
          crowd-sourced content service website for financial markets, professionals and investors.
          Our services empower financial professionals and investors to make the best investment
          decisions by leveraging our independent and balanced research, analysis tools, reliable
          news and actionable market data. For more information about us, please see the “About Us”
          section of our Site.
        </p>
      ),
    },
    {
      label: 'II. Personal Information Collected by Finvar',
      id: 'collectedPersonalInformation',
      description: (
        <>
          <p>
            When you visit our Site or register for our products and services (including as a
            subscriber or a contributor), Finvar collects personal information about you. We use
            this personal information for the business purpose of providing our products and
            services, as well as marketing our products and services to new and potential customers.
            This includes providing you with personalized content and advertisements on our Site
            that are tailored to your interests.
          </p>
          <p>The categories of personal information we collect are:</p>
          <ul>
            <li>
              Contact information such as your name, nickname, username, email address, address.
            </li>
            <li>
              Investing and Trading information such as Stocks and ETFs in your investment
              portfolio, your trading pattern, list of contributors and users you follow, investment
              style preference, broker information.
            </li>
            <li>Background information such as your employment, education.</li>
            <li>
              Device information such as IP address, broad geolocation (country only), device screen
              size, device type (unique device identifiers), browser information, and preferred
              language used to display our website.
            </li>
            <li>
              User ID information such as cookies or other identifiers which we assigned to uniquely
              identify you or which others assigned to uniquely identify you. These identifiers
              enable us to collect personal information described above such as your activity on our
              Site, your professional interests, device information and investing and trading
              information.
            </li>
            <li>
              Subscription information if you subscribed to our services including premium and
              enterprise subscriptions, such as full name, zip code, premium and enterprise
              subscriptions detail, phone number, email address, geolocation, IP address, and
              payment information.
            </li>
            <li>
              Contributor information if you registered as a contributor, such as name, email
              address, postal address, telephone number, CFA status, profile picture, site name and
              URL, biographical information you provided (including education, employment,
              professional interests and areas of expertise), and payment information to enable
              payments to you (date of birth, bank account details, current and historical site
              revenue).
            </li>
          </ul>
          <p>We collect this information directly from users of and visitors to our Site.</p>
        </>
      ),
    },
    {
      label: 'III. Personal Information Finvar discloses',
      id: 'disclosedPersonalInformation',
      description: (
        <>
          <p>
            We share personal information with other Finvar companies as well as third parties who
            assist us in providing our products and services.
          </p>
          <p>
            We share personal information for the business purposes of providing our products and
            services, and marketing our products and services to existing and potential customers.
            The categories of these third parties with whom we share personal information are:
          </p>
          <ul>
            <li>Cloud hosting, database and infrastructure providers.</li>
            <li>IT services providers</li>
            <li>Email management and marketing providers</li>
            <li>Advertising partners</li>
            <li>Payment processors</li>
          </ul>
          <p>
            We share all of the categories of personal information with these third parties when we
            need to do so in order to provide you with Finvar products and services.
          </p>
        </>
      ),
    },
    {
      label: 'IV. Personal Information we sell under the CCPA',
      id: 'underCCPAPersonalInformation',
      description: (
        <p>
          Under the California Consumer Privacy Act (CCPA), &ldquo;sale&rsquo; is defined very
          broadly and includes a wide array of data sharing. Under this definition, we sell certain
          categories of personal information to third parties who assist us in delivering targeted
          advertising on the Site. We sell User ID Information (as described above) to these third
          parties. We do not have any actual knowledge that we sell personal information of
          consumers under 16 years of age.
        </p>
      ),
    },
    {
      label: 'V. Cookies',
      id: 'cookies',
      description: (
        <p>
          We use cookies and similar tracking technology (collectively, “Cookies”) to collect and
          use personal information about you, including to serve interest-based advertising.
        </p>
      ),
    },
    {
      label: "VI. Children's Privacy",
      id: 'childrenPrivacy',
      description: (
        <p>
          In accordance with our Terms of Use, the Site is not designed for use by individuals under
          the age of 18. Accordingly, we do not knowingly collect personal information from anyone
          we know to be under 18 years of age.
        </p>
      ),
    },
    {
      label: 'VII. Consumer Privacy Rights (California residents only)',
      id: 'consumerPrivacy',
      description: (
        <>
          <p>If you are a California resident, you have the following consumer privacy rights:</p>
          <ul>
            <li>
              You can ask us to disclose to you the specific pieces of personal information we have
              collected about you, free of charge. If you make this request electronically, we will
              provide you with this personal information in an electronic format.
            </li>
            <li>
              You can request that we disclose to you the categories of personal information we
              collected about you in the prior 12 months, the categories of sources from which we
              collected it, the categories of third parties with whom we share personal information,
              the categories of personal information that we sold about you, the business or
              commercial purpose for collecting or selling personal information, the categories of
              third parties with whom we shared your personal information, and the categories of
              third parties to whom we sold your personal information.
            </li>
            <li>
              <p>
                You can request that we delete personal information about you.&nbsp; Although you
                may request deletion, we are allowed, under California law, to keep certain personal
                information.&nbsp; You can submit a request to delete&nbsp; by emailing us at&nbsp;
                <a href="mailto:team@capnote.com">
                  <u>team@capnote.com</u>
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                You can&nbsp;opt out of the sale&nbsp;of your personal information by emailing us
                at&nbsp;
                <a href="mailto:team@capnote.com">
                  <u>team@capnote.com</u>
                </a>
                .
              </p>
            </li>
          </ul>

          <p>
            You can exercise any of these rights by contacting us at&nbsp;
            <a href="mailto:team@capnote.com">
              <u>team@capnote.com</u>
            </a>
            &nbsp;providing us with your name, email address and address (including zip code) or by
            submitting a request to us through your Finvar account.&nbsp; We may verify your
            identity by asking you to submit a request through your password protected Finvar
            account and by providing us with your California zip code or other proof of California
            residency. You can authorize an agent to submit a request on your behalf via
            email.&nbsp; Where you use an agent, we will ask for verification of your authorization,
            namely a copy of your written authorization for the agent to submit the request on your
            behalf.
          </p>

          <p>
            We respond to all requests we receive from individuals wishing to exercise their data
            protection rights in accordance with applicable data protection laws.&nbsp; We do not
            discriminate against any consumer for exercising their consumer rights.
          </p>
        </>
      ),
    },
    {
      label: 'VIII. How to contact us',
      id: 'howToContactUs',
      description: (
        <p>
          If you have any comments or questions regarding our Privacy Policy, or if you want to
          exercise one of your consumer rights, please contact us at&nbsp;
          <a href="mailto:team@capnote.com">
            <u>team@capnote.com</u>
          </a>
          . We will address any issue to the best of our abilities as soon as possible, and respond
          to consumer requests within the time required under the CCPA.
        </p>
      ),
    },
  ],
};
