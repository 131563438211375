import { CommonFilter } from 'components/filters/helpers';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export const createFilters = (objectId: string, type: 'company' | 'peer-group'): CommonFilter[] => [
  {
    name: 'category',
    type: 'backend',
    label: 'Category',
    url: createUrl(objectId, type),
  },
];

const createUrl = (objectId: string, type: 'company' | 'peer-group') => {
  if (type === 'company') {
    return `${API_V1_PATH}/filters/algo-notes/companies/${objectId}/category`;
  } else if (type === 'peer-group') {
    return `${API_V1_PATH}/filters/algo-notes/peer-groups/${objectId}/category`;
  } else {
    throw new Error('invalid type');
  }
};
