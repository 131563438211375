import React from 'react';

interface Props {
  onClick?(): void;
}

export const ReadMore: React.FC<Props> = ({ onClick }: Props): React.ReactElement => {
  return (
    <button className="text-blue text-sm font-poppinsMedium mx-1.5" onClick={onClick} type="button">
      Read more &raquo;
    </button>
  );
};
