import { getNotificationIcon } from 'components/notificationsView/helpers';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Notification, NotificationKind, QuickLink } from 'redux/reducers/notificationsReducer';

const columns: Array<ColumnDefinition<Notification>> = [
  {
    name: 'date',
    label: 'Date',
    type: ColumnType.date,
    alignment: Alignment.center,
    weight: 1,
    sortable: true,
  },
  {
    name: 'seen',
    label: '',
    type: ColumnType.custom,
    render: function SeenIndicator(seen: boolean): React.ReactElement {
      return seen ? (
        <div className="w-2 h-2" />
      ) : (
        <div className="w-2 h-2 mx-auto rounded-full bg-blue" />
      );
    },
    width: 44,
  },
  {
    name: 'kind',
    label: '',
    type: ColumnType.custom,
    render: function Icon(kind: NotificationKind): React.ReactElement {
      const Icon = getNotificationIcon(kind.source, kind.type);

      return (
        <div className="rounded-md text-gray-light">
          <Icon className="block mx-auto w-10 h-10 fill-current" />
        </div>
      );
    },
    width: 48,
  },
  {
    name: 'description',
    label: 'Description',
    type: ColumnType.custom,
    headerAlignment: Alignment.left,
    render: (description: string): React.ReactElement => {
      return <div className="truncate leading-6">{description}</div>;
    },
    weight: 6,
  },
  {
    name: 'quickLink',
    label: 'Quick Link',
    type: ColumnType.custom,
    headerAlignment: Alignment.left,
    render: (quickLink?: QuickLink): React.ReactElement | null => {
      if (!quickLink) {
        return null;
      }

      const swallowClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
      };

      return (
        <div onClick={swallowClick}>
          <Link to={quickLink.url} className="text-blue font-poppins">
            {quickLink.label}
          </Link>
        </div>
      );
    },
    weight: 2,
  },
];

export default columns;
