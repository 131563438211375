// GENERAL COMPONENTS
import axios from 'axios';
import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import { PeerComparisonsView } from 'components/peerComparisonsView';
import { useLargestNumberInDataset } from 'components/peerComparisonsView/hooks/useLargestNumberInDataset';
import { useSummary } from 'components/peerComparisonsView/hooks/useSummary';
import { useFilters } from 'hooks/useFilters';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  loadingPeerComparisonSelector,
  peerComparisonSelector,
  resetPeerComparisons,
} from 'redux/reducers/companyReducer';
import { getPeerComparisonsService } from 'redux/services/companyService';
import { getLegendForFinancialStatements } from 'utils';
import createFiltersConfig from 'views/DataAnalysis/Company/Details/PeerComparisons/filters';

export const PeerComparisons: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const peerComparisons = useSelector(peerComparisonSelector);
  const loading = useSelector(loadingPeerComparisonSelector);
  const dispatch = useDispatch<any>();
  const queryParameters = useQueryParameters();
  const filters = useMemo(
    (): readonly CommonFilter[] => (!companyId ? [] : createFiltersConfig(companyId)),
    [companyId],
  );
  const handleFiltersChange = useFilters(filters);

  useEffect((): void => {
    if (!companyId) {
      return;
    }

    dispatch(resetPeerComparisons());
  }, [companyId, dispatch]);

  useEffect((): void | VoidFunction => {
    if (!companyId) {
      return;
    }

    const tokenSource = axios.CancelToken.source();

    dispatch(getPeerComparisonsService(companyId, queryParameters, tokenSource.token));
    return (): void => {
      tokenSource.cancel();
    };
  }, [companyId, dispatch, queryParameters]);

  const summary = useSummary(peerComparisons);
  const largestValueInDataset = useLargestNumberInDataset(summary);

  const filtersConfig = useMemo((): readonly CommonFilter[] => {
    if (!companyId) {
      return [];
    }

    return createFiltersConfig(companyId);
  }, [companyId]);

  return (
    <>
      <div className="flex items-start justify-between my-3">
        <Filters config={filtersConfig} onChange={handleFiltersChange} />
      </div>

      <div className="text-base text-gray-darkest font-poppins normal-case mb-4">
        <div>{getLegendForFinancialStatements(largestValueInDataset)}</div>
        <div>Dashes (-) indicate unavailable data</div>
      </div>

      <PeerComparisonsView
        type="company"
        comparisons={peerComparisons}
        summary={summary}
        loading={loading}
        filters={filtersConfig}
        largestValueInDataset={largestValueInDataset}
      />
    </>
  );
};
