import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { RequestPasswordReset } from 'views/Landing/ForgotPassword/components/requestPasswordReset';
import ResetPassword from 'views/Landing/ForgotPassword/components/resetPassword';

const ForgotPassword: React.FC = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  if (code !== null) {
    return <ResetPassword code={code} />;
  } else {
    return <RequestPasswordReset />;
  }
};

export default ForgotPassword;
