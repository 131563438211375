import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  clearSelectedEvent,
  eventDetailsEventSelector,
  eventDetailsLoadingSelector,
  eventDetailsStreamSelector,
  fetchEvent,
  setStream,
} from 'redux/reducers/eventDetailsReducer';
import { EventDetails, Stream } from 'types/timeline';

export const useCurrentEvent = (
  streams: readonly Stream[],
): [EventDetails | null, Stream | null, boolean] => {
  const { eventId } = useParams<{ eventId: string }>();

  const dispatch = useDispatch<any>();
  const event = useSelector(eventDetailsEventSelector);
  const loading = useSelector(eventDetailsLoadingSelector);
  const stream = useSelector(eventDetailsStreamSelector);

  useEffect((): void | VoidFunction => {
    if (eventId === undefined) {
      dispatch(clearSelectedEvent());
    } else {
      return dispatch(fetchEvent(eventId));
    }
  }, [dispatch, eventId]);

  useEffect((): void => {
    if (event === null) {
      return;
    }

    const found = streams.find((stream: Stream): boolean => stream.id === event.stream_id);
    if (!found) {
      dispatch(setStream(null));
    } else {
      dispatch(setStream(found));
    }
  }, [streams, event, dispatch]);

  return [event, stream, loading];
};
