import { ReactComponent as NoEventsIcon } from 'assets/icons/svg/calendar-icon.svg';
import EmptySection from 'components/emptySection/EmptySection';
import { KeywordAndDateRangeFilter } from 'components/keywordAndDateRangeFilter';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { formatISO } from 'date-fns';
import { useCurrentEvent } from 'hooks/useCurrentEvent';
import { useKeywordAndDateRangeFilter } from 'hooks/useKeywordAndDateRangeFilter';
import { useSort } from 'hooks/useSort';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  allAndUpcomingEventsSelector,
  processingStateSelector,
} from 'redux/reducers/timelineReducer';
import { fetchAllAndUpcomingEvents } from 'redux/services/timelineService';
import { AccessMode } from 'types/accessMode';
import { ProcessingStateEnum } from 'types/processingState';
import { EventDetails, Stream } from 'types/timeline';
import { AllAndUpcomingEvent } from 'types/timelineUpcomingEvent';
import settings from 'utils/config/appSettings';
import columns from 'views/SmartTools/TimelineAndEvents/AllAndUpcomingEvents/columns';
import { EventManager } from 'views/SmartTools/TimelineAndEvents/components/eventManager';

const today = new Date();

export const AllAndUpcomingEvents: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const processingState = useSelector(processingStateSelector);
  const page = useSelector(allAndUpcomingEventsSelector);
  const [sorting, handleSort] = useSort<AllAndUpcomingEvent>('start_date', SortOrder.descending);
  const [keyword, fromDate, toDate, searching] = useKeywordAndDateRangeFilter(
    today,
    7,
    processingState,
  );

  const handleDismissCurrentEvent = useCallback((): void => {
    // FIXME: this number was found empirically to be -3, otherwise
    //        the user has to click twice to get back to the original
    //        path
    navigate(-3);
    return;
  }, [navigate]);

  const [currentEvent, currentStream, eventLoading] = useCurrentEvent([]);

  const readOnlyStream = useMemo(
    (): Stream | null =>
      currentStream ? { ...currentStream, access_mode: AccessMode.readOnly } : null,
    [currentStream],
  );

  const readOnlyEvent = useMemo(
    (): EventDetails | null =>
      currentEvent ? { ...currentEvent, access_mode: AccessMode.readOnly } : null,
    [currentEvent],
  );

  useEffect((): void => {
    const orderByValue = search.get('order_by');

    dispatch(
      fetchAllAndUpcomingEvents({
        from_date: formatISO(fromDate),
        to_date: formatISO(toDate),
        page_number: search.get('page_number') ?? 1,
        page_size: settings.defaultPageSize,
        keyword: keyword,
        ...(orderByValue ? { order_by: orderByValue } : {}),
      }),
    );
  }, [dispatch, fromDate, keyword, search, toDate]);

  const handleRowClick = useCallback(
    (row: AllAndUpcomingEvent): void => {
      navigate({
        pathname: row.id,
        search: search.toString(),
      });
      return;
    },
    [navigate, search],
  );

  return (
    <div className="flex flex-col flex-grow">
      <KeywordAndDateRangeFilter keyword={keyword} searching={searching} />
      <div className="relative flex-grow">
        {processingState.state === ProcessingStateEnum.idle ? (
          page.total_count === 0 ? (
            <EmptySection
              title="There are no events in this date range or matching your search"
              subtitle="Try refining your search by changing the date range or typing something else in the search box."
              icon={NoEventsIcon}
            />
          ) : (
            <>
              <Table
                columns={columns}
                rows={page.data}
                sortBy={sorting}
                onSortChange={handleSort}
                onRowClick={handleRowClick}
              />
              <Pagination totalPages={page.page_count} />
            </>
          )
        ) : (
          <SpinnerLoader visible={true} />
        )}
      </div>
      <EventManager
        event={readOnlyEvent}
        stream={readOnlyStream}
        loading={eventLoading}
        onDismiss={handleDismissCurrentEvent}
      />
    </div>
  );
};
