import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';

const ProfileLinksErrorModal: React.FC = (): React.ReactElement => {
  const message =
    'The link for this field has not yet been provided. Please click the pencil icon to update your profile with the link.';
  return (
    <div className="flex flex-col items-center justify-center space-y-6 md:w-96 sm:w-full">
      <div className="text-gray w-24 my-8">
        <SVGIcon name="info-gray" className="fill-current" />
      </div>
      <h1 className="font-Jost-SemiBold text-center pl-10 pr-10 pb-10">{message}</h1>
    </div>
  );
};

export default ProfileLinksErrorModal;
