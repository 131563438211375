import { ReactComponent as NoPeerGroupsIcon } from 'assets/icons/svg/no-companies-icon.svg';
import { AddObjectButton } from 'components/addObjectButton';
import { Breadcrumbs } from 'components/breadcrumbs';
import EmptySection from 'components/emptySection/EmptySection';
import { ErrorBox } from 'components/errorBox';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { MainOutletContent } from 'components/mainOutletContent';
import { Modal } from 'components/modal';
import Pagination from 'components/pagination/Pagination';
import { SuccessBox } from 'components/successBox';
import { Table } from 'components/table';
import { TruncatedTags } from 'components/truncatedTags';
import { useModal } from 'hooks/useModal';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { noop } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createUserPeerGroupProcessingStateSelector,
  fetchUserPeerGroupsProcessingState,
  PeerGroup,
  userPeerGroupsSelector,
} from 'redux/reducers/userPeerGroupsReducer';
import { fetchUserPeerGroupsAction } from 'redux/services/userPeerGroupsService';
import { ProcessingStateEnum } from 'types/processingState';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import columns from 'views/SmartTools/PeerGroups/columns';
import { AddPeerGroupModal } from 'views/SmartTools/PeerGroups/modals/addPeerGroup';

export const PeerGroups: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const peerGroups = useSelector(userPeerGroupsSelector);
  const fetchGroupsProcessingState = useSelector(fetchUserPeerGroupsProcessingState);
  const createPeerGroupProcessingState = useSelector(createUserPeerGroupProcessingStateSelector);
  const addModal = useModal();
  const successModal = useModal();
  const failureModal = useModal();
  const query = useQueryParameters();
  const navigate = useNavigate();

  useEffect((): VoidFunction => {
    return dispatch(fetchUserPeerGroupsAction(query));
  }, [dispatch, query]);

  useEffect((): void => {
    switch (createPeerGroupProcessingState.state) {
      case ProcessingStateEnum.idle:
        break;
      case ProcessingStateEnum.processing:
        break;
      case ProcessingStateEnum.error:
      case ProcessingStateEnum.fatalError:
        addModal.close();
        failureModal.open();
        break;
      case ProcessingStateEnum.success:
        addModal.close();
        successModal.open();
        break;
    }
  }, [addModal, createPeerGroupProcessingState.state, failureModal, successModal]);

  const handleRowClick = useCallback(
    (row: PeerGroup): void => {
      navigate(`./details/${row.id}`);
    },
    [navigate],
  );

  return (
    <MainOutletContent>
      <div>
        <Breadcrumbs path={['SMART TOOLS', 'COMPARE']} />
        <TextLine content={description} />

        <div className="my-2">
          <TruncatedTags
            tags={[
              'Valuation',
              'Growth',
              'Efficiency',
              'Returns',
              'Solvency',
              'Statistics',
              'Automated notes',
            ]}
            onExpand={noop}
            onClick={noop}
          />
        </div>
      </div>

      <div className="flex items-center justify-end">
        <AddObjectButton verb={'New'} title="Comp Set" onClick={addModal.open} />
      </div>

      <div className="relative flex-1">
        <Pagination totalPages={peerGroups.page_count}>
          {peerGroups.total_count === 0 ? (
            fetchGroupsProcessingState.state !== ProcessingStateEnum.processing && (
              <EmptySection title="Get started by creating a comp set" icon={NoPeerGroupsIcon} />
            )
          ) : (
            <Table columns={columns} rows={peerGroups.data} onRowClick={handleRowClick} />
          )}
        </Pagination>
        <FullScreenLoadingSpinner
          visible={fetchGroupsProcessingState.state === ProcessingStateEnum.processing}
        />
      </div>

      <Modal
        isOpen={addModal.isOpen}
        closeOnClickOutside={false}
        onClose={addModal.close}
        spinning={createPeerGroupProcessingState.state === ProcessingStateEnum.processing}
      >
        <Modal.Content title="New Comp Set">
          <AddPeerGroupModal />
        </Modal.Content>
      </Modal>

      <Modal isOpen={successModal.isOpen} onClose={successModal.close}>
        <SuccessBox
          title="Added successfully"
          message="Your comp set was added to the list of comp sets"
          onClose={successModal.close}
        />
      </Modal>

      <Modal isOpen={failureModal.isOpen} onClose={failureModal.close}>
        <ErrorBox
          title="An error occured"
          message="Something went wrong when we tried to create you comp set"
          onClose={failureModal.close}
        />
      </Modal>
    </MainOutletContent>
  );
};

const description =
  "Easily compare 2 or more companies by building and saving a comp set. You'll get automated notes and updates of metrics.";
