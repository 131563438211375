import 'views/Landing/LandingPage/components/NextGeneration/NextGeneration.scss';

import Button from 'components/buttons/Button/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'views/Landing/LandingPage/components/NextGeneration/text';

const NextGeneration: React.FC = () => {
  return (
    <div className="next-gen-container xl:mt-10">
      <h1 className="text-left text-4xl text-gray-darkest next-title mb-8 font-jost font-semibold">
        {Text.title}
      </h1>
      <div className="flex md:justify-center lg:justify-start lg:w-68/100 mb-6">
        <p className="text-md lg:text-lg">{Text.paragraph}</p>
      </div>
      <div className="mt-12 sign-up-btn flex justify-center md:justify-start">
        <Link to="/sign-up">
          <Button rounded="rounded-sm" label={Text.signUpBtn} width="w-60" />
        </Link>
      </div>
    </div>
  );
};

export default NextGeneration;
