import { useScrollIntoView } from 'hooks/useScrollIntoView';
import React, { useCallback, useMemo } from 'react';

interface Props {
  readonly tag: string;
  readonly highlighted?: boolean;

  onClick(value: string): void;
}

export const TagSuggestionItem: React.FC<Props> = ({
  tag,
  highlighted = false,
  onClick,
}: Props): React.ReactElement => {
  const containerRef = useScrollIntoView<HTMLParagraphElement>(highlighted);

  const handleClick = useCallback((): void => {
    onClick(tag);
  }, [onClick, tag]);

  const className = useMemo((): string => {
    const base = 'p-2 hover:bg-gray-50 cursor-pointer';
    if (highlighted) {
      return `${base} bg-gray-100`;
    }

    return base;
  }, [highlighted]);

  return (
    <p ref={containerRef} className={className} onClick={handleClick}>
      {tag}
    </p>
  );
};
