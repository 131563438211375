import { Dropdown } from 'components/dropdown';
import { Input } from 'components/forms/input';
import SVGIcon from 'components/icons/SVGIcon';
import { format, isValid, parse } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';

interface Props {
  readonly value: number | null | undefined;
  readonly name: string;
  readonly label: string;

  onChange(value: number): void;
}

export const TimestampDateInput: React.FC<Props> = ({
  name,
  label,
  value,
  onChange,
}: Props): React.ReactElement => {
  const [currentValue, setCurrentValue] = useState<string>('');
  const [input, setInput] = useState<HTMLInputElement | null>(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);

  useEffect((): void => {
    if (value === null || value === undefined) {
      setCurrentValue('');
      return;
    }

    const date = new Date(value);
    if (isValid(date)) {
      setCurrentValue(format(date, 'dd/MM/yyyy'));
    }
  }, [value]);

  const openCalendar = useCallback((): void => {
    setCalendarIsOpen(true);
  }, []);

  const closeCalendar = useCallback((): void => {
    setCalendarIsOpen(false);
  }, []);

  const handleValueChange = useCallback((value: string): void => {
    setCurrentValue(value);
  }, []);

  const errorMessage = useMemo((): string | undefined => {
    if (currentValue === '') {
      return undefined;
    }

    if (!/\d{2}\/\d{2}\/\d{4}/.test(currentValue)) {
      return 'Invalid date format';
    }

    const date = parse(currentValue, 'dd/MM/yyyy', 0);
    if (isValid(date)) {
      return undefined;
    }

    return 'Invalid date';
  }, [currentValue]);

  const inputColor = useMemo((): 'default' | 'error' => {
    if (errorMessage) {
      return 'error';
    } else {
      return 'default';
    }
  }, [errorMessage]);

  const handleCalendarChange = useCallback(
    (value: Date): void => {
      onChange(value.getTime());
    },
    [onChange],
  );

  const currentDate = useMemo((): Date | null => {
    if (!/\d{2}\/\d{2}\/\d{4}/.test(currentValue)) {
      return null;
    }

    const date = parse(currentValue, 'dd/MM/yyyy', 0);
    if (isValid(date)) {
      return date;
    }

    return null;
  }, [currentValue]);

  const iconClassName = useMemo((): string => {
    if (inputColor === 'error') {
      return 'w-4 h-4 fill-current text-red';
    }

    return 'w-4 h-4 fill-current';
  }, [inputColor]);

  return (
    <>
      <Input
        ref={setInput}
        name={name}
        label={label}
        value={currentValue}
        placeholder="DD/MM/YYYY"
        color={inputColor}
        error={errorMessage}
        rightDecorator={
          <button className={iconClassName} type="button" onClick={openCalendar}>
            <SVGIcon name="calendar-icon" className={iconClassName} />
          </button>
        }
        onChange={handleValueChange}
      />
      <Dropdown isOpen={calendarIsOpen} anchor={input} onClose={closeCalendar}>
        <div className="p-2">
          <Calendar
            value={currentDate}
            nextLabel={<SVGIcon name="next-icon" className="fill-current text-gray w-3 h-3" />}
            prevLabel={<SVGIcon name="previous-icon" className="fill-current text-gray w-3 h-3" />}
            next2Label={<div />}
            prev2Label={<div />}
            onChange={handleCalendarChange}
            formatShortWeekday={shortWeekdayFormatter}
          />
        </div>
      </Dropdown>
    </>
  );
};

const shortWeekdayFormatter = (_: string, date: Date): string => format(date, 'EEEEE');
