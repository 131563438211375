import CheckIcon from 'assets/icons/svg/check-icon.svg';
import DeleteIcon from 'assets/icons/svg/delete-icon.svg';
import React from 'react';
import { ApplicationFeature, FeatureType } from 'redux/reducers/subscriptionPlansReducer';

interface Props {
  readonly descriptor?: ApplicationFeature;
}

export const FeatureValue: React.FC<Props> = ({ descriptor }: Props): React.ReactElement | null => {
  if (!descriptor) {
    return null;
  }

  switch (descriptor.type) {
    case FeatureType.moduleAccess:
      if (descriptor.forbidden) {
        return <img src={DeleteIcon} className="w-5 h-3" alt="status" />;
      } else {
        return <img src={CheckIcon} className="w-5 h-3" alt="status" />;
      }

    case FeatureType.usageLimit:
      if (!isFinite(descriptor.usage_limit)) {
        return <p className="font-poppinsMedium">Unlimited</p>;
      }

      return <p className="font-poppinsMedium">{descriptor.usage_limit}</p>;
    default:
      throw new Error('unknown feature type');
  }
};
