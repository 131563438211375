import { Dispatch } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AuthProcessingStateData,
  authProcessingStateSelector,
  RedirectState,
} from 'redux/reducers/authReducer';
import { updatePassword } from 'redux/services/authServices';
import { APIAction } from 'types/APIAction';
import { isYupError, passwordSchema, YupError } from 'utils/forms';
import { Button } from 'views/Landing/component/button';
import { PasswordInput } from 'views/Landing/component/passwordInput';
import { useUrlEmail } from 'views/Landing/hooks/useUrlEmail';
import SectionTitle from 'views/Landing/Login/components/sectionTitle';

interface Props {
  readonly code: string;
}

const ResetPassword: React.FC<Props> = ({ code }: Props): React.ReactElement => {
  const dispatch = useDispatch<Dispatch<APIAction>>();
  const navigate = useNavigate();
  const processingState = useSelector(authProcessingStateSelector);
  const urlEmail = useUrlEmail('code');

  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<YupError | undefined>(undefined);

  const handleSubmit = useCallback(
    async (event: React.FormEvent): Promise<void> => {
      event.preventDefault();
      try {
        await passwordSchema.validate(password);
        dispatch(updatePassword(code, password));
      } catch (error: any) {
        if (isYupError(error)) {
          setError(error);
        }
      }
    },
    [code, dispatch, password],
  );

  useEffect((): void => {
    if (processingState.data === AuthProcessingStateData.passwordUpdated) {
      const state: RedirectState = {
        processingState: AuthProcessingStateData.passwordUpdated,
        email: urlEmail,
      };

      navigate('/login', { state: state });
    }
  }, [urlEmail, navigate, processingState.data]);

  useEffect((): VoidFunction => {
    let canceled = false;

    const timeout = setTimeout((): void => {
      passwordSchema
        .validate(password, { context: { isInEditMode: true } })
        .then((): void => {
          if (!canceled) {
            setError(undefined);
          }
        })
        .catch((error: YupError): void => {
          if (!canceled) {
            setError(error);
          }
        });
    }, 300);

    return (): void => {
      canceled = true;
      clearTimeout(timeout);
    };
  }, [password]);

  return (
    <form className="text-center" onSubmit={handleSubmit}>
      <SectionTitle title="Set a new password" subTitle="Enter a new password" />
      <div className="w-101 pb-8 relative">
        <PasswordInput
          id="reset-password"
          value={password}
          create={true}
          withHelp={true}
          onChange={setPassword}
          error={error}
          onError={setError}
        />

        <div className="my-5">
          <Button label="Submit" />
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
