import 'components/header/UserHeader/UserHeader.scss';

// UTILS
// Components
import { DesktopHeader } from 'components/header/UserHeader/desktopHeader';
import { MobileHeader } from 'components/header/UserHeader/mobileHeader';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { getFinancialQuotesAction } from 'redux/reducers/sharedReducer';

const UserHeader: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getFinancialQuotesAction());
  }, [dispatch]);

  if (isMobile) {
    return <MobileHeader />;
  } else {
    return <DesktopHeader />;
  }
};

export default UserHeader;
