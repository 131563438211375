import { Filters } from 'components/filters';
import { CommonFilter } from 'components/filters/helpers';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { SortOrder } from 'components/table/sorting';
import { useFilters } from 'hooks/useFilters';
import { useSort } from 'hooks/useSort';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadingCompaniesSelector } from 'redux/reducers/companyReducer';
import { DataAndAnalysisMainTabs } from 'types/dataAndAnalysisMainTabs';
import { Company } from 'types/organization/types';
import { Page } from 'types/page';
import columns from 'views/DataAnalysis/Company/columns';
import { createFiltersConfig } from 'views/DataAnalysis/Company/filters';

interface Props {
  readonly tab: DataAndAnalysisMainTabs;
  readonly label: string;
  readonly page: Page<Company>;
}

const CompaniesTable: React.FC<Props> = ({ tab, page }: Props): React.ReactElement => {
  const [sorting, handleSortingChange] = useSort<Company>('market_cap', SortOrder.descending);

  const loading = useSelector(loadingCompaniesSelector);
  const { page_count, total_count, data } = page;
  const navigate = useNavigate();
  const filters = useMemo((): readonly CommonFilter[] => createFiltersConfig(tab), [tab]);
  const handleFiltersChange = useFilters(filters);

  const handleRowClicked = useCallback(
    (row: Company): void => {
      navigate(`/data-analysis/company/details/${row.id}`);
    },
    [navigate],
  );

  return (
    <div className="relative flex-1">
      <div className="flex items-end justify-between mt-4">
        <Filters config={filters} onChange={handleFiltersChange} />
      </div>
      <h4 className="md:text-sm text-right mt-2 mb-1 font-poppins lowercase text-gray">
        {total_count} companies
      </h4>
      <Pagination totalPages={page_count}>
        <Table
          columns={columns}
          rows={data}
          sortBy={sorting}
          onSortChange={handleSortingChange}
          onRowClick={handleRowClicked}
        />
      </Pagination>
      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default CompaniesTable;
