import { Sorting, SortOrder } from 'components/table/sorting';
import { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useSort = <T>(
  defaultColumn: keyof T,
  defaultOrder: SortOrder = SortOrder.ascending,
): [Sorting<T>, (name: keyof T, order: SortOrder) => void] => {
  const [_, setSearch] = useSearchParams();
  const location = useLocation();

  // Always derived  from the URL
  const sorting = useMemo((): Exclude<Sorting<T>, void> => {
    const search = new URLSearchParams(location.search);
    const orderByParameter = search.get('order_by');
    const orderByColumn = getOrderByColumn(orderByParameter, defaultColumn);
    const orderByOrder = getOrderByOrder(orderByParameter, defaultOrder);
    const key = orderByColumn as keyof T;

    return { [key]: orderByOrder } as Exclude<Sorting<T>, void>;
  }, [defaultColumn, defaultOrder, location.search]);

  const handler = useCallback(
    (name: keyof T, order: SortOrder): void => {
      setSearch(
        (search: URLSearchParams): URLSearchParams => {
          if (order !== SortOrder.none) {
            // Always set to the URL
            search.set('order_by', `${order}${String(name)}`);
            // Reset to the first page to start seeing the sorted results
            // from the beginning
            search.delete('page_number');
          } else {
            search.delete('order_by');
          }

          return search;
        },
        { state: location.state },
      );
    },
    [location.state, setSearch],
  );

  return [sorting, handler];
};

const getOrderByColumn = (
  value: string | null,
  fallback: string | number | symbol,
): string | number | symbol => {
  if (value === null) {
    return fallback;
  }

  if (value.startsWith(SortOrder.ascending) || value.startsWith(SortOrder.descending)) {
    return value.slice(1);
  }

  return fallback;
};

const getOrderByOrder = (value: string | null, fallback: SortOrder): SortOrder => {
  if (value === null) {
    return fallback;
  }

  if (value.startsWith(SortOrder.descending)) {
    return SortOrder.descending;
  } else if (value.startsWith(SortOrder.ascending)) {
    return SortOrder.ascending;
  } else {
    throw new Error(`invalid sort order '${value.slice(0, 1)}'`);
  }
};
