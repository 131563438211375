import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import axios, { AxiosResponse, CancelToken } from 'axios';
import { errorMessageDefault } from 'components/modals/InfoModal/constants';
import { Notification } from 'redux/reducers/notificationsReducer';
import {
  addCompany,
  addCompanyFailed,
  editCompany,
  editCompanyFailed,
  editCompanySuccess,
  editPortfolioDetailsFailed,
  editPortfolioDetailsSuccess,
  getPortfolioById,
  getPortfolioByIdFailed,
  getPortfolioByIdSuccess,
  getPortfolioCompanies,
  getPortfolioCompaniesFailed,
  getPortfolioCompaniesSucceeded,
  getPortfolioMembers,
  getPortfolioMembersFailed,
  getPortfolioMembersSuccess,
  getPortfolioNotifications,
  getPortfolioNotificationsFailed,
  getPortfolioNotificationsSuccess,
  getPortfolioRevenuesMap,
  getPortfolioRevenuesMapSuccess,
  getPortfoliosCompaniesServiceFailedCompanies,
  getPortfolioSubsidiariesMap,
  getPortfolioSubsidiariesMapSuccess,
} from 'redux/reducers/portfolioReducer';
import {
  addPortfolioFailed,
  addPortfolioSuccess,
  clearErrors,
  completeLoadingPortfoliosStreams,
  completeLoadingPortfolioStreamEventGroups,
  deletePortfolioFailed,
  editPortfolioFailed,
  editPortfolioSuccess,
  getPortfolios,
  getPortfoliosFailed,
  getPortfoliosMap,
  getPortfoliosMapFailed,
  getPortfoliosMapSuccess,
  getPortfoliosNotifications,
  getPortfoliosNotificationsFailed,
  getPortfoliosNotificationsSuccess,
  getPortfoliosRevenuesMap,
  getPortfoliosRevenuesMapFailed,
  getPortfoliosRevenuesMapSuccess,
  getPortfoliosSuccess,
  Portfolio,
  setPortfoliosStreams,
  setPortfolioStreamEventGroups,
  startLoadingPortfoliosStreams,
  startLoadingPortfolioStreamEventGroups,
} from 'redux/reducers/portfoliosReducer';
import { showInfoModal } from 'redux/reducers/sharedReducer';
import { ExternalMemberPayload, InternalMemberPayload, SharePayload } from 'sharable/types';
import { ActionsGenerator, createAPIAction, HttpClient } from 'types/APIAction';
import { Page } from 'types/page';
import { TimelineFilters } from 'types/timeline';
import settings from 'utils/config/appSettings';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import { createErrorFromFailedCompaniesResponse } from 'utils/handleAddCompaniesToPortfoliosResponse';

export interface PortfolioCompaniesFilters {
  company_name?: string;
  relationship_type?: string;
  relationship_currency?: string;
  subsidiary_geography?: string;
}

export interface AddPortfolioPayload {
  id?: string;
  name: string;
  type: string;
  tags: string[];
  external_members: ExternalMemberPayload[];
  members: InternalMemberPayload[];
  route: string;
}

export type CompanyData = {
  organization_id: string;
  product: string | number | null;
  relationship: string | number | null;
  currency_id: string | null;
  size_of_relationship: string | number | null;
};

interface AddCompanyData {
  organizations: CompanyData[];
}

interface EditCompanyData {
  product: string | null;
  relationship: string | null;
  currency_id: string | null;
  size_of_relationship: number | null;
}

export interface AddedCompany {
  id: string;
  name: string;
  currency: string | null;
}

interface AddCompanyResponse {
  data: { accepted: CompanyData[]; rejected: CompanyData[] };
}

export const getPortfoliosCompaniesMapAction = createAPIAction(
  function getPortfoliosCompaniesMapAction(client: HttpClient): ActionsGenerator {
    return async function* (): AsyncGenerator<AnyAction> {
      yield getPortfoliosMap();
      try {
        const response = await client.GET(`${API_V1_PATH}/portfolios/map/`);
        yield getPortfoliosMapSuccess(response.data ?? []);
      } catch (error: any) {
        // TODO: make the error "serializable"
        yield getPortfoliosMapFailed(error);
      }
    };
  },
);

export const getPortfoliosRevenuesMapAction = createAPIAction(
  function getPortfoliosRevenuesMapAction(client: HttpClient): ActionsGenerator {
    return async function* (): AsyncGenerator<AnyAction> {
      yield getPortfoliosRevenuesMap();
      try {
        const response = await client.GET(`${API_V1_PATH}/portfolios/map/revenues/`);
        yield getPortfoliosRevenuesMapSuccess(response.data ?? []);
      } catch (error: any) {
        // TODO: make the error "serializable"
        yield getPortfoliosRevenuesMapFailed(error);
      }
    };
  },
);

export const getPortfolioRevenuesMapService = (portfolioId: string) => (dispatch: Dispatch) => {
  dispatch(getPortfolioRevenuesMap());
  api
    .get(`${API_V1_PATH}/portfolios/${portfolioId}/map/revenues`)
    .then((response: AxiosResponse): void => {
      if (response.status == 200) {
        dispatch(getPortfolioRevenuesMapSuccess(response.data));
      } else {
        // FIXME: do something some times
      }
    })
    .catch((error: any): void => {
      console.warn(error);
    });
};

export const getPortfolioSubsidiariesMapService = (portfolioId: string) => (dispatch: Dispatch) => {
  dispatch(getPortfolioSubsidiariesMap());
  api
    .get(`${API_V1_PATH}/portfolios/${portfolioId}/map/subsidiaries`)
    .then((response: AxiosResponse): void => {
      if (response.status == 200) {
        dispatch(getPortfolioSubsidiariesMapSuccess(response.data));
      } else {
        // FIXME: do something some times
      }
    })
    .catch((error: any): void => {
      console.warn(error);
    });
};

export const getPortfoliosService = () => (dispatch: Dispatch) => {
  dispatch(clearErrors());
  dispatch(getPortfolios());
  api
    .get(`${API_V1_PATH}/portfolios`)
    .then((response: AxiosResponse): void => {
      if (response.data) {
        const mappedData = response.data?.map((item: Portfolio) => ({
          ...item,
          portfolio_id: item.id,
        }));
        dispatch(getPortfoliosSuccess(mappedData as Portfolio[]));
      }
    })
    .catch((error: any): void => {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
      dispatch(getPortfoliosFailed(error));
    });
};

export const getPortfolioMembersService =
  (portfolioId: string, cancelToken?: CancelToken) => (dispatch: Dispatch) => {
    dispatch(getPortfolioMembers());
    api
      .get(`${API_V1_PATH}/portfolios/${portfolioId}/members`, { cancelToken: cancelToken })
      .then((response: AxiosResponse): void => {
        if (response.data) {
          dispatch(getPortfolioMembersSuccess(response.data));
        }
      })
      .catch((error: any): void => {
        dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
        dispatch(getPortfolioMembersFailed(error));
      });
  };

export const getPortfolioCompaniesService = createAPIAction(function getPortfolioCompaniesAction(
  client: HttpClient,
): ActionsGenerator {
  return async function* (
    portfolioId: string,
    filters: PortfolioCompaniesFilters,
  ): AsyncGenerator<AnyAction> {
    yield getPortfolioCompanies();
    try {
      const response = await client.GET(
        `${API_V1_PATH}/portfolios/${portfolioId}/companies`,
        filters,
      );

      yield getPortfolioCompaniesSucceeded(response.data);
    } catch (error: any) {
      yield getPortfolioCompaniesFailed();
    }
  };
});

export const addPortfolioService =
  (addPortfolioData: AddPortfolioPayload) => (dispatch: Dispatch) => {
    dispatch(clearErrors());
    api
      .post(`${API_V1_PATH}/portfolios/`, addPortfolioData)
      .then(res => dispatch(addPortfolioSuccess(res.data)))
      .then(() => {
        // FIXME: we should not need to call the getPortfoliosService() here
        //        because the BE should return the newly created portfolio
        //        with it's brand new id
        return dispatch(getPortfoliosService() as any);
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(addPortfolioFailed(err));
      });
  };

export const editPortfolioService =
  (editPortfolioData: AddPortfolioPayload, id: string) => async (dispatch: Dispatch) => {
    dispatch(clearErrors());
    api
      .put(`${API_V1_PATH}/portfolios/${id}`, editPortfolioData)
      .then(res => {
        const portfolio = res.data;
        if (res.data?.id) {
          dispatch(editPortfolioSuccess(portfolio));
          dispatch(editPortfolioDetailsSuccess(portfolio));
        } else {
          // TODO: Handle error or log it with sentry or a similar tool
        }
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(editPortfolioFailed(err));
        dispatch(editPortfolioDetailsFailed(err));
      });
  };

export const deletePortfolioService =
  (ids: readonly string[]) => async (dispatch: Dispatch<any>) => {
    dispatch(clearErrors());
    api
      .post('api/v1/portfolios/delete-multiple/', { portfolio_ids: ids })
      .then(() => {
        // FIXME: we should not need to call the getPortfoliosService() here
        //        because the BE should return the newly created portfolio
        //        with it's brand new id
        dispatch(getPortfoliosService());
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(deletePortfolioFailed(err));
      });
  };

export const getPortfolioByIdService = (id: string) => (dispatch: Dispatch) => {
  dispatch(clearErrors());
  dispatch(getPortfolioById());
  api
    .get(`${API_V1_PATH}/portfolios/${id}/overview`)
    .then((res: any) => {
      dispatch(getPortfolioByIdSuccess({ ...res.data, id }));
    })
    .catch((error: any) => {
      if (!axios.isCancel(error)) {
        dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
        dispatch(getPortfolioByIdFailed(error));
      } else {
        dispatch(getPortfolioByIdFailed(null));
      }
    });
};

export const addCompanyService =
  (addPortfolioData: AddCompanyData, id: string, addedCompanies: AddedCompany[]) =>
  (dispatch: Dispatch) => {
    dispatch(clearErrors());
    dispatch(addCompany());
    api
      .post(`${API_V1_PATH}/portfolios/${id}/companies/`, addPortfolioData)
      .then((res: AddCompanyResponse) => {
        const { rejected = [] } = res.data;
        if (rejected.length > 0) {
          const rejectedCompanies = addedCompanies.filter(company => {
            return res.data.rejected.some(rejected => rejected.organization_id === company.id);
          });

          const errorMessage = createErrorFromFailedCompaniesResponse(rejectedCompanies);

          dispatch(getPortfoliosCompaniesServiceFailedCompanies(errorMessage));
        }

        dispatch(getPortfolioCompaniesService(id) as any);
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(addCompanyFailed(err));
      });
  };

export const editCompanyService =
  (editCompanyData: EditCompanyData | null, organizationId: string, portfolioId: string) =>
  (dispatch: Dispatch) => {
    dispatch(clearErrors());
    dispatch(editCompany());
    api
      .put(`${API_V1_PATH}/portfolios/${portfolioId}/companies/${organizationId}`, editCompanyData)
      .then(response => {
        dispatch(editCompanySuccess(response.data));
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
        dispatch(editCompanyFailed(err));
      });
  };

export const sharePortfolioService =
  (payload: SharePayload, portfolioId: string) => (dispatch: Dispatch) => {
    dispatch(clearErrors());

    api
      .post(`${API_V1_PATH}/portfolios/${portfolioId}/share`, payload)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(
            showInfoModal({
              type: 'success',
              message: 'Successfully shared Portfolio with selected members',
            }),
          );
        }
      })
      .catch(err => {
        dispatch(showInfoModal({ type: 'error', message: err?.message || errorMessageDefault }));
      });
  };

export const getPortfoliosNotificationsAction =
  (
    // FIXME: make this specific
    parameters: Record<string, string>,
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getPortfoliosNotifications());
    try {
      const params = { page_number: 1, ...parameters, page_size: settings.defaultPageSize };
      const response = await api.get(`${API_V1_PATH}/portfolios/notifications`, { params });

      dispatch(getPortfoliosNotificationsSuccess(Page.map(response.data, Notification.fromJson)));
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
      dispatch(getPortfoliosNotificationsFailed());
    }
  };

export const getPortfolioNotificationsAction =
  (
    portfolioId: string,
    // FIXME: make this specific
    parameters: Record<string, string>,
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getPortfolioNotifications());
    try {
      const params = { page_number: 1, ...parameters, page_size: settings.defaultPageSize };
      const response = await api.get(`${API_V1_PATH}/portfolios/${portfolioId}/notifications`, {
        params,
      });

      dispatch(getPortfolioNotificationsSuccess(Page.map(response.data, Notification.fromJson)));
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
      dispatch(getPortfolioNotificationsFailed());
    }
  };

export const getPortfoliosTimeline =
  (filters: TimelineFilters): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startLoadingPortfoliosStreams());
    try {
      const response = await api.get(`${API_V1_PATH}/portfolios/timeline`, { params: filters });
      dispatch(setPortfoliosStreams(response.data));
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
    } finally {
      dispatch(completeLoadingPortfoliosStreams());
    }
  };

export const getPortfolioTimeline =
  (portfolioId: string, filters: TimelineFilters): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startLoadingPortfoliosStreams());
    try {
      const response = await api.get(`${API_V1_PATH}/portfolios/${portfolioId}/timeline`, {
        params: filters,
      });
      dispatch(setPortfoliosStreams(response.data));
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
    } finally {
      dispatch(completeLoadingPortfoliosStreams());
    }
  };

export const getStreamEventGroups =
  (
    streamId: string,
    portfolioId: string | undefined,
    filters: TimelineFilters,
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(startLoadingPortfolioStreamEventGroups(streamId));

    const url = portfolioId
      ? `${API_V1_PATH}/portfolios/timeline/${portfolioId}/event-groups`
      : `${API_V1_PATH}/portfolios/timeline/event-groups`;

    try {
      const response = await api.get(url, {
        params: filters,
      });
      if (response.data) {
        dispatch(setPortfolioStreamEventGroups({ id: streamId, groups: response.data }));
      }
    } catch (error: any) {
      dispatch(showInfoModal({ type: 'error', message: error?.message || errorMessageDefault }));
    } finally {
      dispatch(completeLoadingPortfolioStreamEventGroups());
    }
  };
