import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';
import { PasswordRequirements } from 'utils/forms';
import { ValidationError } from 'yup';

const requirementLabels: Record<PasswordRequirements, string> = {
  [PasswordRequirements.minimumLength]: '8 characters',
  [PasswordRequirements.containsAtLeastOneUpperCaseLetter]: '1 upper case',
  [PasswordRequirements.containsAtLeastOneLowerCaseLetter]: '1 lower case',
  [PasswordRequirements.containsAtLeastOneNumber]: '1 number',
  [PasswordRequirements.containsSpecialCharacters]: '1 special character (! @ # $ % &)',
};

interface Props {
  readonly id: string;
  readonly error: ValidationError;
}

const requirements = Object.keys(requirementLabels).map(
  (value: string): PasswordRequirements => Number(value) as PasswordRequirements,
);

const PasswordRequirementsTooltip: React.FC<Props> = ({ id, error }: Props) => {
  const { params: { value } = { value: 0 } } = error;

  return (
    <div id={id} className="bg-gray text-white p-2 rounded max-w-max ml-2 z-10">
      <div className="font-poppinsSemiBold text-xs text-left mb-2 normal-case">
        Password must contain (at least):
      </div>
      <ul className="list-none px-1">
        {requirements.map((requirement: PasswordRequirements): React.ReactElement => {
          return (
            <li
              key={String(requirement)}
              className="flex items-center gap-3 font-poppins text-xs normal-case py-1"
            >
              {isSatisfied(requirement, value) ? (
                <SVGIcon name="check-icon-generic" className="text-green fill-current w-4" />
              ) : (
                <SVGIcon name="error-icon" className="w-4" />
              )}
              {requirementLabels[requirement]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const isSatisfied = (requirement: PasswordRequirements, value: unknown): boolean => {
  if (typeof value !== 'number') {
    return false;
  }

  return (requirement & value) === 0;
};

export default PasswordRequirementsTooltip;
