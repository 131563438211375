import { Expander } from 'components/table/expander';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import { capitalize } from 'lodash';
import React from 'react';
import { CrazyKeyFactors, KeyFactorType } from 'redux/reducers/keyFactorsReducer';

export const createColumns = (
  factorType: KeyFactorType,
  expandHandler: (row: CrazyKeyFactors, expanded: boolean) => void,
): ReadonlyArray<ColumnDefinition<CrazyKeyFactors>> => [
  {
    name: 'name',
    type: ColumnType.text,
    label: `${capitalize(factorType)} Factor`,
    weight: 3,
    alignment: Alignment.left,
    sortable: false,
  },
  {
    name: 'companies_count',
    type: ColumnType.numeric,
    label: 'Companies',
    precision: 0,
    alignment: Alignment.center,
    weight: 1,
    sortable: false,
  },
  {
    type: ColumnType.custom,
    name: 'id',
    label: '',
    weight: 1,
    render(_: string, row: any): React.ReactNode {
      return <Expander row={row} onExpand={expandHandler} />;
    },
  },
];
