import { Clickable } from 'components/clickable';
import { DefaultButton } from 'components/DEPRECATED/select/defaultButton';
import { DefaultItem } from 'components/DEPRECATED/select/defaultItem';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { defaultOptions } from 'utils/popper';

export interface SelectItem<T> {
  readonly value: NonNullable<T>;
  readonly label: string;
}

export type SelectButtonProps<T> = {
  readonly fallbackLabel?: string;
  readonly value: T | null;

  onClick?(): void;
} & React.PropsWithRef<any>;

export interface SelectItemProps<T> {
  readonly label: string;
  readonly value: NonNullable<T>;

  onClick?(): void;
}

interface Props<T> {
  readonly id?: string;

  readonly items: Array<SelectItem<NonNullable<T>>>;
  readonly value: T | null;

  // FIXME: make these React components instead of elements so that
  //        we don't need to clone them
  readonly buttonComponent?: React.FunctionComponent<React.PropsWithRef<SelectButtonProps<T>>>;
  readonly itemComponent?: React.FunctionComponent<SelectItemProps<T>>;

  readonly fallbackLabel?: string | React.ReactElement;

  onChange(value: T): void;
}

/**
 * @deprecated Use new version of select
 */
export function Select<T>({
  id,
  items,
  value,
  fallbackLabel,
  buttonComponent,
  itemComponent,
  onChange,
}: Props<T>): ReactElement {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [popup, setPopup] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popperOptions = useMemo(
    (): any => defaultOptions(document.body, { withSameWidth: true, offset: [0, 4] }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  const togglePopup = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const closePopup = useCallback((): void => {
    setIsOpen(false);
  }, []);

  const handleClick = useCallback(
    (value: T): void => {
      onChange(value);
      closePopup();
    },
    [closePopup, onChange],
  );

  const Button = useMemo(
    (): React.FunctionComponent<SelectButtonProps<T>> =>
      buttonComponent ? buttonComponent : DefaultButton,
    [buttonComponent],
  );

  const ItemComponent = useMemo((): React.FunctionComponent<SelectItemProps<T>> => {
    return itemComponent ? itemComponent : DefaultItem;
  }, [itemComponent]);

  useEffect((): void => {
    update?.();
  }, [update]);

  const label = React.useMemo((): string | React.ReactElement => {
    const found = items.find((item: SelectItem<T>): boolean => item.value === value);
    if (!found) {
      return <span className="text-gray-400 italic">{fallbackLabel ?? '-'}</span>;
    } else {
      return found.label;
    }
  }, [fallbackLabel, items, value]);

  return (
    <div id={id} className="w-full h-full font-poppins">
      {/* FIXME: if current item is not "set" then this should be the fallback value */}
      <Button
        ref={setAnchor}
        value={value}
        label={label}
        fallbackLabel={fallbackLabel}
        onClick={togglePopup}
      />

      {isOpen &&
        ReactDOM.createPortal(
          <div className="fixed inset-0 z-1" tabIndex={-1} onClick={closePopup}>
            <div
              ref={setPopup}
              className="items-start grid bg-white rounded shadow-lg font-poppins overflow-y-auto scroller"
              style={styles.popper}
              {...attributes.popper}
            >
              {items.map((item: SelectItem<T>): React.ReactElement => {
                if (item.value === null) {
                  throw new Error('null is not allowed as an item value');
                }

                return (
                  <Clickable key={item.label} clickData={item.value} onClick={handleClick}>
                    <ItemComponent label={item.label} value={item.value} />
                  </Clickable>
                );
              })}
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
}
