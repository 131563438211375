import { SearchSuggestionMetadataView } from 'components/header/UserHeader/searchSuggestionMetadataView';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SearchResult, SearchResultCategory } from 'redux/reducers/globalSearchReducer';

interface Props {
  readonly item: SearchResult;
  readonly inputValue: string;
}

export const SearchSuggestion: React.FC<Props> = ({
  inputValue,
  item,
}: Props): React.ReactElement => {
  const { name } = item;
  const matchedValues = useMemo(
    (): string[] => name.split(new RegExp(`(${inputValue})`, 'i')),
    [inputValue, name],
  );

  const url = useMemo((): string => {
    const template = urlPrefix[item.category];

    return template.replace(':id', item.id);
  }, [item.category, item.id]);

  return (
    <Link key={item.name} className={itemClass} to={url}>
      <div className="text-gray-darkest font-poppinsSemiBold text-sm">
        {categoryLabels[item.category]}
      </div>
      <div className="col-span-3 text-gray truncate overflow-ellipsis overflow-hidden font-poppins text-sm">
        {matchedValues.map((part: string, index: number): React.ReactElement => {
          const key = `${part}${index}`;
          if (part.toLowerCase() === inputValue.toLowerCase()) {
            return <strong key={key}>{part}</strong>;
          } else {
            return <span key={key}>{part}</span>;
          }
        })}
      </div>
      <div className="col-span-2">
        <SearchSuggestionMetadataView item={item} />
      </div>
    </Link>
  );
};

const itemClass =
  'grid grid-cols-6 items-center min-h-11 items-center p-5 cursor-pointer hover:bg-blue-light border-b last:border-b-none normal-case';

export const categoryLabels: { [key in SearchResultCategory]: string } = {
  event: 'Event',
  news: 'News Article',
  user: 'User',
  company: 'Company',
  industry: 'Industry',
  post: 'User Post',
  country: 'Country',
  indicator: 'Indicator',
};

export const urlPrefix: { [key in SearchResultCategory]: string } = {
  event: '',
  news: '/feed/:id',
  user: '/users/:id',
  country: '/data-analysis/country',
  industry: '/data-analysis/industry/details/:id',
  post: '/feed/:id',
  company: '/data-analysis/company/details/:id',
  indicator: '/data-analysis/indicators',
};
