import { StripeError } from '@stripe/stripe-js';
import SVGIcon from 'components/icons/SVGIcon';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  readonly error: StripeError | undefined;
}

export const Failure: React.FC<Props> = ({ error }: Props): React.ReactElement => {
  return (
    <div className="flex-1 w-full flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="bg-red w-24 h-24 rounded-full my-8">
          <SVGIcon name="error-icon" className="w-24 h-24" />
        </div>
        <div className="text-center mb-24">
          <h1>Payment Failed</h1>
          <h6>{error?.message ?? 'Unexpected error'}</h6>
          <h6>Please try again later.</h6>
        </div>
        <Link to="/upgrade/individuals">
          <span className="flex items-center font-poppinsMedium bg-gray-dark text-white h-12 px-12 rounded-full">
            <span className="block">Go back</span>
          </span>
        </Link>
      </div>
    </div>
  );
};
