import { ReactComponent as MoreIcon } from 'assets/icons/svg/more-options-icon.svg';
import { Dropdown } from 'components/dropdownFilter/Dropdown';
import React, { useCallback, useRef, useState } from 'react';
import { ModifierOptions } from 'utils/popper';

export const MoreButton: React.FC<React.PropsWithChildren<any>> = ({
  children,
}: React.PropsWithChildren<any>): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const hideDropdown = useCallback((): void => setIsOpen(false), []);
  const showDropdown = useCallback((): void => setIsOpen(true), []);

  return (
    <div ref={ref} className="w-5 h-5 cursor-pointer text-gray" onClick={showDropdown}>
      <MoreIcon className="fill-current" />
      <Dropdown
        isOpen={isOpen}
        anchor={ref.current}
        options={dropdownOptions}
        onClose={hideDropdown}
      >
        <div onClick={hideDropdown}>{children}</div>
      </Dropdown>
    </div>
  );
};

const dropdownOptions: ModifierOptions = {
  placement: 'bottom',
  offset: [0, 8],
  withSameWidth: false,
};
