import { format, parse } from 'date-fns';

const formatString = 'yyyy-MM-dd HH:mm:ss';

export const serialize = (value: any): string => {
  if (value instanceof Date) {
    return format(value, formatString);
  }

  return value;
};

export const unserialize = (value: string): Date => parse(value, formatString, 0);
