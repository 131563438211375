import React, { useMemo } from 'react';
import { PeerComparisonsColumn, PeerComparisonsColumnGroup } from 'types/peerComparisons';

interface Props {
  readonly data: any;
  readonly columnGroups: readonly PeerComparisonsColumnGroup[];
  readonly cellTitle: React.ReactElement | string;
  readonly highlight?: boolean;

  extractValue(value: any, column: PeerComparisonsColumn): string | React.ReactElement;
}

export const TableRow: React.FC<Props> = ({
  data,
  columnGroups,
  cellTitle,
  highlight = false,
  extractValue,
}: Props): React.ReactElement => {
  const currentCellClassName = useMemo(
    (): string => (highlight ? highlightedCellClassName : cellClassName),
    [highlight],
  );

  const currentTitleClassName = useMemo(
    (): string =>
      highlight
        ? [titleClassName, 'bg-gray-100'].join(' ')
        : [titleClassName, 'bg-white'].join(' '),
    [highlight],
  );

  return (
    <React.Fragment>
      <div className={currentTitleClassName}>{cellTitle}</div>

      {columnGroups.map(
        (group: PeerComparisonsColumnGroup, groupIndex: number): React.ReactNode => {
          const { columns } = group;

          return columns.map((column: PeerComparisonsColumn, index: number): React.ReactElement => {
            const rawValue = data[group.name]?.[column.name] ?? null;
            const dividerClassName = groupIndex > 0 && index === 0 ? 'border-l' : undefined;
            const value = extractValue(rawValue, column);
            if (typeof value === 'string') {
              const className = [currentCellClassName, dividerClassName].join(' ');

              return (
                <div key={column.name} className={className}>
                  {extractValue(rawValue, column)}
                </div>
              );
            } else {
              const className = [extraHeaderClassName, dividerClassName].join(' ');

              return (
                <div key={column.name} className={className}>
                  {value}
                </div>
              );
            }
          });
        },
      )}
    </React.Fragment>
  );
};

const cellClassName = 'flex items-center justify-center h-full border-b border-divider px-4 py-4';
const highlightedCellClassName = `${cellClassName} bg-gray-100`;
const extraHeaderClassName = 'flex items-center h-full border-divider';
const titleClassName = 'sticky left-0 h-full border-r border-divider pr-0';
