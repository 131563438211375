import React from 'react';
import { PortfolioArtPhotographyItem } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioArtPhotographyItem;
}

export const PhotographyArtItemForm: React.FC<Props> = ({ item: _ }: Props): React.ReactElement => {
  return <></>;
};
