import { NotificationItemView } from 'components/header/UserHeader/components/NotificationItemView';
import SVGIcon from 'components/icons/SVGIcon';
import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Notification } from 'redux/reducers/notificationsReducer';
import { userNotificationsSelector } from 'redux/reducers/sharedReducer';
import { markAllNotificationsAsSeenAction } from 'redux/services/notificationsService';

interface Props {
  onClose?(): void;
}

const NotificationsPopup: React.FC<Props> = ({ onClose }: Props): React.ReactElement => {
  const userNotifications = useSelector(userNotificationsSelector);
  const dispatch = useDispatch<any>();

  const { notifications } = userNotifications;

  const ignore = useCallback((event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleMarkAllAsRead = useCallback((): void => {
    dispatch(markAllNotificationsAsSeenAction());
  }, [dispatch]);

  return (
    <div className={containerClass}>
      <div className="sticky top-0 w-full px-6 py-6 border-b border-gray-100 bg-white">
        <h4 className="text-gray-darkest text-lg leading-5">NOTIFICATIONS</h4>
        {onClose && (
          <div className="w-full h-full">
            <SVGIcon
              name="close-popup-icon"
              className="absolute right-5 top-6 w-5 h-5"
              onClick={onClose}
            />
          </div>
        )}
      </div>

      <div className="w-full pb-4">
        <div className="flex justify-between items-center p-6 pb-4">
          <h6 className="uppercase text-gray-darkest font-poppinsSemiBold text-sm">Latest</h6>
          <button
            onClick={handleMarkAllAsRead}
            className="focus:outline-none hover:bg-gray-light rounded p-1"
          >
            <h6 className="text-blue uppercase font-poppinsSemiBold text-base">Mark all as read</h6>
          </button>
        </div>
        {/* actionButton={
                      notification.type === NotificationType.Connections ? (
                        <FollowButton compact followed={false} />
                      ) : undefined
                    }*/}
        {notifications.map((notification: Notification): React.ReactElement => {
          if (isMobile) {
            return (
              <div key={notification.id} onClick={ignore}>
                <NotificationItemView notification={notification} variant="bell" />
              </div>
            );
          } else {
            return (
              <Link key={notification.id} to="/notifications/" state={notification.id}>
                <NotificationItemView notification={notification} variant="bell" />
              </Link>
            );
          }
        })}
        {!userNotifications ||
          (notifications.length === 0 && (
            <p className="m-8 text-gray text-sm font-poppins text-center">
              No pending notifications
            </p>
          ))}
      </div>
      <Link to="/notifications">
        <div className="flex justify-center p-2 border-t border-gray-300">
          <h3 className="text-blue">SEE ALL</h3>
        </div>
      </Link>
    </div>
  );
};

export default NotificationsPopup;

const containerClass = 'w-full h-full bg-white rounded overflow-y-auto md:shadow-md md:w-modal-sm';
