import { Sorting, SortOrder } from 'components/table/sorting';
import classes from 'components/table/table.module.scss';
import { TableBody } from 'components/table/tableBody';
import { TableColumns } from 'components/table/tableColumns';
import { TableHead } from 'components/table/tableHead';
import { ChildrenRows, ColumnDefinition, Identifiable } from 'components/table/types';
import { useBoundingRectangle } from 'hooks/useBoundingRectangle';
import React from 'react';

interface Props<T extends Identifiable, R extends Identifiable = any> {
  readonly columns: ReadonlyArray<ColumnDefinition<T>>;
  readonly rows: readonly T[];
  readonly sortBy?: Sorting<T>;

  readonly rowStyle?: 'elevated' | 'flat';
  readonly rowChildren?: Record<string, ChildrenRows<R>>;

  readonly showChildrenHeader?: boolean;

  onSortChange?(name: keyof T, order: SortOrder): void;
  onRowClick?(row: T): void;
}

export function Table<T extends Identifiable>({
  columns,
  rows,
  rowStyle = 'elevated',
  showChildrenHeader = false,
  sortBy,
  rowChildren,
  onSortChange,
  onRowClick,
}: Readonly<Props<T>>): React.ReactElement {
  const [containerReference, boundingRectangle] = useBoundingRectangle<HTMLTableElement>();

  const className = [classes.container, classes[rowStyle]].join(' ');

  return (
    <div ref={containerReference} className={className}>
      <table width="100%">
        <TableColumns columns={columns} boundingRectangle={boundingRectangle} />
        <TableHead columns={columns} sorting={sortBy} onSortChange={onSortChange} />
      </table>
      <div className="relative">
        <table width="100%">
          <TableColumns columns={columns} boundingRectangle={boundingRectangle} />
          <TableBody
            columns={columns}
            rows={rows}
            rowChildren={rowChildren}
            showChildrenHeader={showChildrenHeader}
            onRowClick={onRowClick}
          />
        </table>
      </div>
    </div>
  );
}
export { levelRank } from 'components/table/helpers';
