import SVGIcon, { SvgIconName } from 'components/icons/SVGIcon';
import { Level } from 'components/table/types';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { KeyFactorType } from 'redux/reducers/keyFactorsReducer';

interface Props {
  readonly level: Level;
  readonly factorType: KeyFactorType;
}

export const AverageProfile: React.FC<Props> = ({
  level,
  factorType,
}: Props): React.ReactElement => {
  const iconName = useMemo((): SvgIconName => getIcon(factorType, level), [factorType, level]);

  return (
    <div className="flex font-poppinsMedium text-base items-center justify-end gap-3">
      <span>Capnote Avg {capitalize(factorType)} Profile: </span>
      <span className="flex items-center gap-1">
        <SVGIcon name={iconName} className="w-4" /> {level}
      </span>
    </div>
  );
};

const getIcon = (factorType: KeyFactorType, level: string): SvgIconName => {
  switch (level) {
    case Level.veryHigh:
      return factorType === 'risk' ? 'red-double-arrow-up-icon' : 'green-double-arrow-up-icon';
    case Level.high:
      return factorType === 'risk' ? 'exclamation-circle' : 'low-risk-icon';
    case Level.veryLow:
      return factorType === 'risk' ? 'green-double-arrow-up-icon' : 'red-double-arrow-down-icon';
    case Level.low:
      return factorType === 'risk' ? 'low-risk-icon' : 'exclamation-circle';
    default:
      return 'medium-risk-icon';
  }
};
