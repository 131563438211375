import React, { useMemo } from 'react';

interface Props {
  readonly size: number;
  readonly value: number;
  readonly color?: string;

  readonly tintValue?: number;
}

export const SvgStar: React.FC<Props> = ({
  size,
  value,
  tintValue = 0,
  color = '#fdd465',
}: Props): React.ReactElement => {
  const points = React.useMemo(
    (): Array<[string, string]> =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number): [string, string] => {
        const r = size / dividers[value % 2];
        const x = r * Math.cos((2 * Math.PI * value) / 10 - Math.PI / 2);
        const y = r * Math.sin((2 * Math.PI * value) / 10 - Math.PI / 2);

        return [x.toFixed(2), y.toFixed(2)];
      }),
    [size],
  );

  const path = React.useMemo((): string => {
    return (
      `M${points[0]} ${points[1]} ` +
      points
        .slice(1)
        .map(([x, y]: [string, string]): string => `L${x} ${y}`)
        .join(' ') +
      'Z'
    );
  }, [points]);

  const width = useMemo((): number => (size * value) / 100, [size, value]);
  const cx = useMemo((): number => size / 2, [size]);
  const strokeWidth = useMemo((): number => size / 15, [size]);
  const tintWidth = useMemo((): number => (size * tintValue) / 100, [size, tintValue]);

  return (
    <svg xmlns={ns} width={size} height={size} viewBox={`-1 -2 ${size + 1} ${size + 2}`}>
      <defs>
        <mask id="mask">
          <path d={path} fill="#ffffff" strokeWidth={strokeWidth / 2} stroke="#ffffff" />
        </mask>
      </defs>
      <g transform={`translate(${cx}, ${cx})`}>
        <g mask="url(#mask)">
          {tintValue > value ? (
            <rect
              x={-cx - strokeWidth}
              y={-cx - strokeWidth}
              width={tintWidth + 2 * strokeWidth}
              height={size + 2 * strokeWidth}
              fill="#00000016"
            />
          ) : null}
          <rect
            x={-cx - strokeWidth}
            y={-cx - strokeWidth}
            width={width + 2 * strokeWidth}
            height={size + 2 * strokeWidth}
            fill={color}
          />
        </g>
        <path d={path} fill="none" strokeWidth={strokeWidth} stroke={darken(color, 25)} />
      </g>
    </svg>
  );
};

const ns = 'http://www.w3.org/2000/svg';
const dividers = [2, 4];

const darken = (color: string, amount: number): string => {
  const matches = color.match(/[0-9a-f]{2}/g);
  if (!matches) {
    return color;
  }

  return (
    '#' +
    matches
      .map((x: string): number => parseInt(x, 16))
      .map((x: number): string => Math.round(Math.max(0, x - (x * amount) / 100)).toString(16))
      .join('')
  );
};
