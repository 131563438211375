import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Country } from 'redux/reducers/countryReducer';
import { CountryNameWithFlag } from 'views/DataAnalysis/Country/countryNameWithFlag';

const columns: ReadonlyArray<ColumnDefinition<Country>> = [
  {
    name: 'name',
    type: ColumnType.custom,
    sortable: true,
    label: 'Country',
    headerAlignment: Alignment.left,
    render: function NameWithFlag(value: string, row: Country): React.ReactNode {
      return <CountryNameWithFlag name={value} iso2={row.iso} />;
    },
  },
  {
    name: 'nominal_gdp',
    type: ColumnType.numeric,
    alignment: Alignment.center,
    precision: 0,
    sortable: true,
    label: 'Nominal GDP\n(USD Millions)',
  },
  {
    name: 'gdp_growth',
    type: ColumnType.percent,
    alignment: Alignment.center,
    precision: 0,
    sortable: true,
    label: 'GDP Growth\n(previous year)',
  },
  {
    name: 'population_size',
    type: ColumnType.numeric,
    alignment: Alignment.center,
    precision: 0,
    sortable: true,
    label: "Population\nSize (000's)",
  },
  {
    name: 'exports_value',
    type: ColumnType.numeric,
    alignment: Alignment.center,
    precision: 0,
    sortable: true,
    label: 'Exports Value\n(USD Millions)',
  },
  {
    name: 'imports_value',
    type: ColumnType.numeric,
    alignment: Alignment.center,
    precision: 0,
    sortable: true,
    label: 'Imports Value\n(USD Millions)',
  },
  {
    name: 'moodys_credit_rating',
    type: ColumnType.text,
    alignment: Alignment.center,
    sortable: false,
    label: "Credit Rating\n(Moody's)",
    width: 120,
  },
  {
    name: 'standard_and_poors_credit_rating',
    type: ColumnType.text,
    alignment: Alignment.center,
    sortable: false,
    label: 'Credit Rating (S&P)',
    width: 120,
  },
  {
    name: 'fitch_credit_rating',
    type: ColumnType.text,
    alignment: Alignment.center,
    sortable: false,
    label: 'Credit Rating (Fitch)',
    width: 120,
  },
];

export default columns;
