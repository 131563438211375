import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import React from 'react';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import { Faqs } from 'views/TourAndFAQs/faqs';

const TourAndFAQs: React.FC = () => {
  return (
    <MainOutletContent>
      <Breadcrumbs path={['FREQUENTLY ASKED QUESTIONS']} />
      <TextLine content="Discover how to use Capnote" />
      <div className="h-2" />

      <Faqs />
    </MainOutletContent>
  );
};

export default TourAndFAQs;
