import { CommonFilter } from 'components/filters/helpers';
import { capitalize } from 'lodash';
import { KeyFactorType } from 'redux/reducers/keyFactorsReducer';
import { API_V1_PATH } from 'utils/config/axiosConfig';

export const createFiltersConfig = (
  id: string | undefined,
  factorType: KeyFactorType,
): CommonFilter[] => {
  const factorPath = factorType === 'risk' ? 'krf' : 'kgf';
  const middlePart = id ? `${id}/${factorPath}` : factorPath;

  return [
    {
      type: 'backend',
      label: `${capitalize(factorType)} Type`,
      url: `${API_V1_PATH}/filters/portfolios/${middlePart}/names`,
      name: 'name',
    },
    {
      type: 'backend',
      label: 'Impact',
      url: `${API_V1_PATH}/filters/portfolios/${middlePart}/impacts`,
      name: 'impact',
    },
    {
      type: 'backend',
      label: 'Probability',
      url: `${API_V1_PATH}/filters/portfolios/${middlePart}/probabilities`,
      name: 'probability',
    },
    {
      type: 'backend',
      label: `${capitalize(factorType)} Level`,
      url: `${API_V1_PATH}/filters/portfolios/${middlePart}/risks`,
      name: 'risk',
    },
  ];
};
