import MoreIcon from 'assets/icons/svg/more-icon.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { FeedCategory, FeedItem } from 'views/Home/types';

interface Props {
  readonly feedItem: FeedItem;
}

export const HeaderLink: React.FC<Props> = ({ feedItem }: Props): React.ReactElement | null => {
  if (feedItem.category === FeedCategory.notification) {
    return (
      <div className="flex flex-row items-center">
        <Link to="/portfolios/notifications">
          <h4 className="text-blue normal-case">{linkText(feedItem.category)}</h4>
        </Link>
        <img src={MoreIcon} className="w-3 h-3 ml-3" alt="more" />
      </div>
    );
  } else if (feedItem.category === FeedCategory.news) {
    return (
      <a
        className="flex flex-row items-center normal-case"
        href={feedItem.url}
        target="_blank"
        rel="noreferrer"
      >
        <h4 className="text-blue normal-case">{linkText(feedItem.category)}</h4>
        <img src={MoreIcon} className="w-3 h-3 ml-3" alt="more" />
      </a>
    );
  } else {
    return null;
  }
};

const linkText = (type: FeedCategory) => {
  switch (type) {
    case FeedCategory.notification:
      return 'Portfolios';
    case FeedCategory.news:
      return 'Go to Source';
    default:
      return '';
  }
};
