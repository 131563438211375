import { MobileHeaderBase } from 'components/header/mobileHeaderBase';
import { HeaderButton } from 'components/header/UserHeader/components/headerButton';
import { MobileActionButton } from 'components/header/UserHeader/components/mobileActionButton';
import { NotificationsIcon } from 'components/header/UserHeader/components/notificationsIcon';
import { UserMenu } from 'components/header/UserHeader/components/userMenu';
import { useHeaderButtons } from 'components/header/UserHeader/hooks';
import Sidebar from 'components/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout } from 'redux/services/authServices';
import { goToMainPage } from 'utils/goToMainPage';

export const MobileHeader: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const dispatch = useDispatch<any>();

  const [handlePrint, handleExport] = useHeaderButtons();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(
    (): void => setMenuOpen((menuOpen: boolean): boolean => !menuOpen),
    [],
  );

  const toggleSearch = useCallback(
    (): void => setSearchOpen((searchOpen: boolean): boolean => !searchOpen),
    [],
  );

  const actionButtons = useMemo((): readonly React.ReactElement[] => {
    return [
      <MobileActionButton
        key="search"
        icon="search-icon"
        disabled={menuOpen}
        onClick={toggleSearch}
      />,
      <MobileActionButton
        key="menu"
        icon={menuOpen ? 'close-popup-icon' : 'mobile-menu-icon'}
        disabled={searchOpen}
        onClick={toggleMenu}
      />,
    ];
  }, [menuOpen, searchOpen, toggleMenu, toggleSearch]);

  const handleLogout = useCallback((): void => {
    dispatch(logout());
    goToMainPage();
  }, [dispatch]);

  useEffect((): void => {
    setMenuOpen(false);
  }, [location]);

  return (
    <MobileHeaderBase menuOpen={menuOpen} searchOpen={searchOpen} actionButtons={actionButtons}>
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between">
          <UserMenu onLogout={handleLogout} />

          <div className="flex items-center gap-4">
            <NotificationsIcon active={false} />

            <HeaderButton title="Direct Message" icon="direct-message-icon" />
            <HeaderButton title="Print Screen" icon="print-icon" onClick={handlePrint} />
            <HeaderButton title="Export to PDF" icon="export-icon" onClick={handleExport} />
          </div>
        </div>
        <div className="my-4 overflow-y-auto flex-1">
          <Sidebar />
        </div>
      </div>
    </MobileHeaderBase>
  );
};
